<form [formGroup]="editDentalFG" class="vis-form vis-patients-edit overflow-x-auto position-relative" style="height:inherit;overflow-y: scroll;">
  <!-- ./ chi-top -->
  <div formArrayName="orders" *ngIf="orderArray" [class]="layoutCssClass" [id]="areaId" class="position-relative" [ngStyle]="{width:tableWidth}">
    <!-- default grid -->
    <table [class]="'odr-tb'" class="position-relative w-100">
      <thead style="z-index: 990;" class="top-0 position-sticky">
        <tr>
          <ng-container>
            <th class="s-mark text-center" (contextmenu)="openBatchEdit($event,'SPRule')" i18n="@@OrderMark" style="cursor: pointer; width: 4cqb;" title="點擊滑鼠右鍵批次修改">標記</th>
            <th class="code text-center" title="處置">處置</th>
            <th class="name text-center" style="width: 80px;" [ngStyle]="{minWidth:namePlusWidth}" title="處置名稱">處置名稱</th>
            <th class="toothPos text-center" title="牙位輸入">牙位</th>
            <th class="toothFace text-center" title="牙面">牙面</th>
            <th class="time text-center" title="PCS">PCS</th>
            <th class="unit text-center" title="療卡">療卡</th>
            <th class="date text-center" title="療程開始日">療程開始日</th>
            <th class="unit text-center" title="加成">加成</th>
            <th class="unit text-center" title="單價">單價</th>
            <th class="code text-center" title="小計">小計</th>
            <!-- <th class="name text-center" style="cursor: pointer;" title="代檢院所">代檢院所</th> -->
          </ng-container>
        </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let odr of orderArray.controls; let idx = index" [formGroupName]="idx">
          <tr [ngStyle]="{'cursor' : !dragDisabled ? 'grab' : 'default'}" [cdkDragDisabled]="dragDisabled" cdkDrag>
            <td class="s-mark position-relative">
              <div class="extend">
                <ng-container *ngIf="idx < findLastEmptyCodePosition()">
                  <div class="title" style="display: flex">
                    <input type="tel" formControlName="SPRule" class="odr-cell" tabindex="{{idx * 1000 + 0}}"
                        [id]="spRuleIdPrefix + idx" [attr.disabled]="inputDisabled()"
                        (keydown)="onSPRuleKeyDown($event, odr, spRuleIdPrefix + idx, idx)"
                        (blur)="onSPRuleChenge(idx,odr)"
                        autocomplete="off" [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true"
                        appOrderGridInput appHistFocus [data]="_tab.rxType" />
                    <button type="button" *ngIf="editCol==''" class="title-btn2" [matMenuTriggerFor]="menu" [attr.disabled]="checkDisabled()"
                        [matMenuTriggerData]="spRuleIdPrefix + idx" aria-label="popup menu">
                        <app-icon-etc></app-icon-etc> <!-- <mat-icon class="popup-menu">more_vert</mat-icon> -->
                     </button>
                    <mat-checkbox *ngIf="editCol=='SPRule'&&odr.value.RxCode" (click)="$event.preventDefault()"
                     (mousedown)="checkMouseDown($event, check, odr)" (mouseenter)="checkMouseOver($event, check, odr)"
                      (change)="batchCheckedChange($event, odr)" [checked]="isBatchEditCheck(odr)"  #check></mat-checkbox>
                  </div>
                  <mat-menu #menu="matMenu">
                    <ng-template matMenuContent >
                      <button [attr.disabled]="checkDisabled()"
                        *ngFor="let item of spRulemenu"
                        (click)="onSPRuleSelect(odr, item, spRuleIdPrefix + idx, idx)"
                        mat-menu-item>
                        <span class="managedBy-value">{{item.text}}</span>
                      </button>
                    </ng-template>
                  </mat-menu>
                </ng-container>
              </div>
            </td>
            <td class="code position-relative">
              <div style="display: flex" class="extend">
                <input type="tel" formControlName="RxCode" class="odr-cell" [id]="codeIdPrefix + idx" [attr.disabled]="inputDisabled()"
                  (focus)="onCodeFocus($event, idx)" (blur)="onCodeBlur($event, idx)" (keyup)="onCodeKeyUp($event, idx)" tabindex="{{idx * 1000 + 1}}"
                  (keydown)="onCodeKeyDown($event, idx)"
                  autocomplete="off" [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true"
                  appOrderGridInput appHistFocus [data]="_tab.rxType" #codeInput/>
                  <button type="button" *ngIf="isActive(codeInput)&&codeInput.value" (mousedown)="deleteRowAt(idx)" class="title-btn2" [attr.disabled]="checkDisabled()">刪</button>
              </div>
            </td>
            <td class="name position-relative">
              <div style="display: flex;" class="extend">
                <input formControlName="ProdName" class="odr-cell" [id]="nameIdPrefix + idx" [attr.disabled]="inputDisabled()" 
                title="{{ getOrderName(idx) }}" readonly="readonly" [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" (click)="onClickAtName(idx)"
                appOrderGridInput appHistFocus [data]="_tab.rxType"/>
              </div>
            </td>
            <td class="tooth-pos position-relative">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="ToothPos" class="odr-cell" [id]="toothPosPrefix + idx" autocomplete="off" (keyup)="onToothPosKeyEvent($event, idx)"
                [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" (click)="onClickAtToothPos(idx)" (blur)="onToothPosBlur($event, idx)"
                appOrderGridInput appHistFocus />
              </div>
            </td>
            <td class="way position-relative">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="Way" class="odr-cell" [id]="wayIdPrefix + idx" autocomplete="off" (keyup)="onToothFaceKeyEvent($event, idx)"
                [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" (click)="onClickAtToothFace(idx)" (blur)="onToothFaceBlur($event, idx)" 
                appOrderGridInput appHistFocus />
              </div>
            </td>
            <td class="time position-relative">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="PCS" class="odr-cell" [id]="pcsPrefix + idx" autocomplete="off"
                [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" (click)="onClickAtPCS(idx)" (blur)="onPCSBlur()"
                appOrderGridInput appHistFocus />
              </div>
            </td>
            <td class="unit position-relative">
              <div class="extend" style="display: flex;">
                <input type="tel" formControlName="TreatCard" class="odr-cell" [id]="treatCardPrefix + idx" autocomplete="off"
                [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" appOrderGridInput appHistFocus />
                <span></span>
              </div>
            </td>
            <td class="st-date position-relative">
              <div class="extend" style="display: flex;">
                <app-vis-datepicker formControlName="StartTreatDate" class="odr-cell" tabindex="{{idx * 1000 + 10}}"
                    [id]="startTreatDatePrefix + idx" autocomplete="off" [attr.disabled]="checkDisabled()" [hidden]="idx >= findLastEmptyCodePosition()"
                    [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" appOrderGridInput appHistFocus appNumberOnly></app-vis-datepicker>
              </div>
            </td>
            <td class="unit position-relative">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="Plus" class="odr-cell" [id]="plusPrefix + idx" autocomplete="off"
                [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" appOrderGridInput appHistFocus appNumberOnly />
              </div>
            </td>
            <td class="qty position-relative">
              <div class="extend" style="display: flex;">
                <input type="tel" formControlName="IPrice" class="odr-cell" [id]="ipricePrefix + idx" autocomplete="off"
                [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" appOrderGridInput appHistFocus />
              </div>
            </td>
            <td class="qty position-relative">
              <div type="tel" class="extend" style="display: flex;">
                <input class="odr-cell" formControlName="CalPrice" class="odr-cell" [id]="calPricePrefix + idx" autocomplete="off"
                [enterLastColIdx]="enterLastColIdx" [isDenatlInput]="true" appOrderGridInput appHistFocus appNumberOnly />
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <!--formArrayName-->
</form>
  