import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HistService } from 'src/app/hist/services/hist.service';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { RegistEditOpt, RegisterApi } from 'src/app/services/api-service/register/register-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { ReceiptOwnFees } from '../../../../system/report/components/receipt/receipt.component';
import { DispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';

@Component({
  selector: 'app-receipt-unit',
  templateUrl: './receipt-unit.component.html',
  styleUrls: ['./receipt-unit.component.css']
})
export class ReceiptUnitComponent implements OnInit {
  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;

  @Input()
  regId: number;

  @Input()
  fontFamily: string = "細明體";

  @Output()
  getDataFinished: EventEmitter<void> = new EventEmitter();

  hist: WholeHist;
  clinic: Clinic;
  receiptOwnFees: ReceiptOwnFees;
  hrxes: HrxViewModel[] = [];
  isqno: string;
  editOptions: RegistEditOpt;
  hasPharmacist:boolean = false;
  clinicAddress: string;

  constructor(private histApi: HistApi,
    private regApi: RegisterApi,
    private histService: HistService,
    private clinicDataService: ClinicDataService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    
  }


  async setRegId(regId) {
    this.regId = regId;
    this.receiptOwnFees = new ReceiptOwnFees(null);
    this.clinic = UserCache.getLoginUser().Clinic;
    let clinicHasPharmacist = UserCache.getLoginUser().Clinic.HasPharmacist;
    this.clinicAddress = (this.clinic.City || '') + (this.clinic.Area || '') + (this.clinic.Street || '');

    this.hist = await this.histApi.getWholeHist({ regId: this.regId });
    let disptp = this.hist?.Hist?.DispTP || '';
    this.hasPharmacist = clinicHasPharmacist && (disptp == DispensingTypeEnum.Clinic || disptp == DispensingTypeEnum.EmerSelf)


    await this.getOtherData();

    this.cdr.detectChanges();

    //this.getDataFinished.emit();
  }

  async getOtherData() {
    this.editOptions = await this.regApi.GetEditOptions();
    const histAndIc = await this.histApi.getReceiptInfo(this.regId);

    if (!histAndIc) return;

    this.isqno = histAndIc.NHIRegIC?.Isqno;
    this.hist = histAndIc.WholeHist;
    this.hist.Hist.HRxs = this.hist.Hist.HRxs.filter(x => x.SPRule != 5);

    const ownfees = new ReceiptOwnFees(null);
    ownfees.OwnOther = this.hist.Hist.AOwn ?? 0;
    this.hrxes = this.hist?.Hist?.HRxs?.map(x => {
      const rx = histAndIc.Rx.find(r => r.RxCode == x.RxCode);

      return {
        SPRule: x.SPRule === 0 ? '' : x.SPRule.toString(),
        ProdName: this.stringFactory(x.ProdName, 27),
        Freq: x.Freq,
        Days: x.Days,
        QTYDose: x.Dose,
        TotalDose: x.TotalDose,
        Unit: rx.SyrupNote=='a1'? x.BoxUnit : x.Unit,
        Indications: rx.Indications,
        SideEffects: rx.SideEffects,
        Warnings: rx.Warnings
      };
    }) || [];

    for (const hrx of this.hist?.Hist?.HRxs || []) {
      const opt = this.histService.EditOptions.dosage.find(opt => opt.Code == hrx.Freq);
      hrx.Freq = opt?.Name ?? hrx.Freq;

      if (hrx.SPRule == 2) {
        switch (hrx.RxType) {
          case 2:
          case 3:
          case 4:
            ownfees.OwnRx += hrx.Price;
            break;
          case 5:
            ownfees.OwnTx5 += hrx.Price;
            break;
          case 6:
          case 7:
            ownfees.OwnTx67 += hrx.Price;
            break;
        }
      }

      switch (hrx.PaymentType) {
        case 'A30':
          ownfees.OwnTx30 += hrx.Price;
          break;
        case 'A31':
          ownfees.OwnTx31 += hrx.Price;
          break;
        case 'A32':
          ownfees.OwnTx32 += hrx.Price;
          break;
      }
    }

    ownfees.OwnOther -= (ownfees.OwnRx + ownfees.OwnTx5 + ownfees.OwnTx67 + ownfees.OwnTx30 + ownfees.OwnTx31 + ownfees.OwnTx32);
    this.receiptOwnFees = ownfees;
  }


  stringFactory(src: string, maxlength: number): string {
    if (!src) return '';

    let length = 0;
    for (let ind = 0; ind < src.length; ind++) {
      length += src[ind].match(/[\u4e00-\u9fa5]/) ? 2 : 1;
      if (length >= maxlength) {
        return src.slice(0, ind + 1) + '...';
      }
    }
    return src;
  }

  getMedDeptName(src: string): string {
    let medDep = this.editOptions?.section.find(x => x.value == src);
    if (medDep) {
      return medDep.text;
    } else {
      return src;
    }
  }
}



interface HrxViewModel {
  SPRule: string;
  ProdName: string;
  Freq: string;
  Days: number;
  QTYDose: number;
  TotalDose: number;
  Unit: string;
  Indications: string;
  SideEffects: string;
  Warnings: string;
}
