<div *ngIf="histView" [ngStyle]="customStyle" class="hist-View" [ngClass]="isNarrow?'hist-View-Narrow':customClass">
  <div style="display: flex;column-gap: 10px;margin-left: 5px;">
    <ng-container *ngIf="ClinicName">
      <div class="info"><span i18n="@@ClinicName">診所</span>：{{ClinicName}}</div>
    </ng-container>
    <ng-container *ngIf="ClinicName && histView.PatientNo">
      <div class="info"><span i18n="@@PatientNo">病歷號</span>：{{histView.PatientNo}}</div>
    </ng-container>
    <ng-container *ngIf="ClinicName && histView.PatientName">
      <div class="info"><span i18n="@@PatientName">病患</span>：{{histView.PatientName}}</div>
    </ng-container>
    <ng-container *ngIf="ClinicName && histView.PatientName">
      <div class="info" [title]="histView.PatientBirthday | fullage "><span i18n="@@PatientBirthday">生日</span>：{{histView.PatientBirthday | formatDate:''}}</div>
    </ng-container>
  </div>

  <!-- <ng-container *ngIf="histView.Register">
    <div class="info ml-1 mr-2"><span i18n="@@RegDate">看診日期</span>：{{histView.Register.RegDate | formatROCTime:'bar,ispadstart'}}  <span i18n="@@Doctor" class="ml-3">醫師</span>：{{histView.Register.DoctorName}}</div>
  </ng-container> -->
    <!-- <ng-container *ngIf="histView.Register">
    <div class="info ml-1 mr-2"> <span i18n="@@Room">診間</span>：{{histView.Register.RoomName}} <span i18n="@@MedDept" class="ml-3">科別</span>：{{histView.Hist.Dept?histView.Hist.Dept:histView.Register.MedDeptCode}} <span i18n="@@MedDept" class="ml-3">身分</span>：{{histView.Register.ICode}}-{{histView.Register.ICodeName}}</div>
  </ng-container> -->

  <div *ngFor="let order of histOrder">
    <!-- 1. 基本資訊: 基資+掛號資料+費用 -->
    <ng-container *ngIf="order===1&&!isTmplView && !minHist">
      <table class="no-border info ml-1 mr-2 tml-view" style="width: 99%;">
        <tr>
          <td colspan="4" [title]="histView.RegDate | formatROCTime:'bar,ispadstart'">看診日期：{{histView.RegDate | formatROCTime:'bar,ispadstart'}}</td>
          <td [ngClass]="isNarrow?'pl-2':'pl-3'">醫師：</td>
          <td [title]="('醫師：'+histView.DoctorName)+(histView.DeclareDoctor&&histView.DeclareDoctor!=histView.DoctorName?'，申報醫師：'+histView.DeclareDoctor:'')">{{histView.DeclareDoctor?histView.DeclareDoctor:histView.DoctorName}}</td>
        </tr>
        <tr>
          <td [ngClass]="isNarrow?'mw60Narrow':'mw60'">身分：</td>
          <td [ngClass]="isNarrow?'w120Narrow':'w120'" [title]="histView.ICode"><div class="hist-ellipsis-content">{{histView.ICode}}</div></td>
          <td [ngClass]="isNarrow?'mw70Narrow pl-2':'mw70 pl-3'">診間：</td>
          <td style="min-width: 15%;" [title]="histView.RoomName">{{histView.RoomName}}</td>
          <td [ngClass]="isNarrow?'mw70Narrow pl-2':'mw70 pl-3'">科別：</td>
          <td style="min-width: 15%;" [title]="histView.DeptCode">{{histView.DeptCode}}</td>
        </tr>
        <tr>
          <td>案類：</td>
          <td [title]="histView.Case"><div class="hist-ellipsis-content"> {{histView.Case}}</div></td>
          <td [ngClass]="isNarrow?'pl-2':'pl-3'">卡序：</td>
          <td [title]="histView.IsqNo" >{{histView.IsqNo}}</td>
          <td [ngClass]="isNarrow?'pl-2':'pl-3'">次別：</td>
          <td [title]="(histView.Cus?'療次：'+histView.Cus:'')+(histView.Pres?'　慢次：'+histView.Pres:'')">{{(histView.Cus?((histView.Pres?'療':'')+histView.Cus):'')+(histView.Pres?((histView.Cus?' 慢':'')+histView.Pres):'')}}</td>
        </tr>
        <tr>
          <td>給類：</td>
          <td [title]="histView.PayTP"><div class="hist-ellipsis-content">{{histView.PayTP}}</div></td>
          <td [ngClass]="isNarrow?'pl-2':'pl-3'">調劑：</td>
          <td [title]="histView.DispTP">{{histView.DispTP}}</td>
          <td [ngClass]="isNarrow?'pl-2':'pl-3'">主治：</td>
          <td [title]="histView.SPTX">{{histView.SPTX}}</td>
        </tr>
        <tr>
          <td>狀態：</td>
          <td [title]="histView.RegIType==='13'?'還卡日:'+(histView.ReCardDate| formatROCTime:'bar,ispadstart'):histView.RegIType"><div class="hist-ellipsis-content">{{(histView.RegIType|valueToText:regITypeOpt)}}</div></td>
          <td [ngClass]="isNarrow?'pl-2':'pl-3'">日份：</td>
          <td [title]="histView.DeclareDays">{{histView.DeclareDays}}</td>
          <td [ngClass]="isNarrow?'pl-2':'pl-3'" *ngIf="this.isHomeCare">居護收案：</td>
          <td *ngIf="this.isHomeCare" [title]="histView.PaySysA"><div class="hist-ellipsis-content">{{(histView.PaySysA?histView.PaySysA+' | ':'')+(histView.PaySysA|valueToText:paySysAOpt)}}</div></td>
        </tr>
        <tr  *ngIf="histView.Pres">
          <td colspan="3" [title]="histView.RegDate | formatROCFullDate:'-'">用藥起訖日：{{(histView.PresStartDate | formatROCFullDate:'-')+'~'+ (histView.PresDeadline | formatROCFullDate:'-')}}</td>
          <td colspan="2" [title]="histView.RegDate | formatROCFullDate:'-'">慢籤截止日：{{ histView.PresDeadline | formatROCFullDate:'-'}}</td>
        </tr>
        <tr *ngIf="histView.DeptCode==='2B' && KDvalue">
          <td colspan="6" [title]="KDvalue"> 洗腎：{{KDvalue}}</td>
        </tr>
      </table>
    </ng-container>
    <ng-container *ngIf="order===1&&showNhiPay">
      <div class="ml-1 mr-2 content-block ng-star-inserted" style="width: 100%; display: flex; flex-wrap: wrap; column-gap: 10px;">
        <div *ngFor="let item of nhiPayDetail">{{item.text}}: {{item.value}}　</div>
      </div>
    </ng-container>

    <!-- 藥物過敏 -->
    <ng-container *ngIf="order===6&&histView.DrugAllergy">
      <!-- <div class="title ml-1 mr-2">藥物過敏</div> -->
      <div class="content-bottom ml-1 mr-2 hist-ellipsis-content break-pre-line">藥物過敏：{{displayAllergies()}}</div>
    </ng-container>

    <!-- 特別記載 -->
    <ng-container *ngIf="order===7&&histView.Remark">
      <!-- <div class="title ml-1 mr-2">特別記載</div> -->
      <div class="content-bottom ml-1 mr-2 hist-ellipsis-content break-pre-line">特別記載：{{histView.Remark}}</div>
    </ng-container>

    <!-- 主訴 -->
    <ng-container *ngIf="order===2&&histView.CC">
      <!-- <div class="title ml-1" i18n="@@ChiefComplaint">主訴</div> -->
      <div class="content ml-1 mr-2 hist-ellipsis-content break-pre-line">{{histView.CC}}</div>
    </ng-container>

    <ng-container *ngIf="order===2&&histView.ChiAsk">
        <div class="title ml-1 mr-2">問</div>
        <div class="content ml-1 mr-2 hist-ellipsis-content break-pre-line">{{histView.ChiAsk}}</div>
    </ng-container>
    <ng-container *ngIf="order===2&&histView.ChiLook">
        <div class="title ml-1 mr-2">望</div>
        <div class="content ml-1 mr-2 hist-ellipsis-content break-pre-line">{{histView.ChiLook}}</div>
    </ng-container>
    <ng-container *ngIf="order===2&&histView.ChiPulse">
        <div class="title ml-1 mr-2">脈</div>
        <div class="content ml-1 mr-2 hist-ellipsis-content break-pre-line">{{histView.ChiPulse}}</div>
    </ng-container>
    <ng-container *ngIf="order===2&&histView.ChiBianZheng">
        <div class="title ml-1 mr-2">辨證</div>
        <div class="content ml-1 mr-2 hist-ellipsis-content break-pre-line">{{histView.ChiBianZheng}}</div>
    </ng-container>
    <ng-container *ngIf="order===2&&histView.ChiTreatRule">
        <div class="title ml-1 mr-2">治則</div>
        <div class="content ml-1 mr-2 hist-ellipsis-content break-pre-line">{{histView.ChiTreatRule}}</div>
    </ng-container>

    <!-- 3. 理學檢查 -->
    <ng-container *ngIf="order===3&&histView.PE">
        <div class="title ml-1 mr-2" i18n="@@PhysicalExamination">理學檢查</div>
        <div class="content ml-1 mr-2 hist-ellipsis-content break-pre-line">{{histView.PE}}</div>
    </ng-container>

    <!-- 4. 診斷清單 -->
    <ng-container *ngIf="order===4">
      <ng-container *ngFor="let dx of histView.DxName;let i=index">
        <div [ngClass]="i%2==0?'content-block':'content'" class="ml-1 mr-2 hist-ellipsis-content">{{i+1}}. {{dx}}</div>
      </ng-container>
      <ng-container *ngFor="let sur of histView.SurName;let i=index">
        <div [ngClass]="i%2==0?'content-block':'content'" class="ml-1 mr-2 hist-ellipsis-content">手術 {{i+1}}. {{sur}}</div>
      </ng-container>
    </ng-container>

    <!-- 5. 醫令清單 -->
    <ng-container *ngIf="order===5&&histView.HRxs && histView.HRxs.length > 0">
      <!-- <div class="title ml-1" i18n="@@Orders">處方</div> -->
      <app-vis-grid [customClass]="'vis-history-bg extend overflow-x-auto position-relative'"
        [data]="histView.HRxs">
        <!-- class="k-grid-no-wrap"  -->
        <app-vis-grid-column [width]="30" min-width="35"
        field="SPRule" title="N" i18n-title="@@SPRule" class="text-center"></app-vis-grid-column>
        <app-vis-grid-column
        field="ProdName" title="醫令" i18n-title="@@Name" class="custom-name">
        <ng-template let-dataItem>
          <div class="ellipsis-content" [title]="dataItem.RxCode+' '+dataItem.ProdName">{{ dataItem.RxCode }} {{ dataItem.ProdName }}</div>
        </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column class="text-right" [width]="40" *ngIf="(isShowDose == dosageEnum.Dose || isShowDose == dosageEnum.OtherDose) && !(isTmplView)"
        field="Dose" title="次量" i18n-title="@@Dose"></app-vis-grid-column>
        <app-vis-grid-column class="text-right" [width]="40" *ngIf="isShowDose == dosageEnum.DailyDose&&isTmplView"
        field="DailyDose" title="日量" i18n-title="@@DailyDose"></app-vis-grid-column>
        <app-vis-grid-column class="text-right" [width]="40" *ngIf="isShowDose == dosageEnum.TotalDose"
        field="TotalDose" title="總量" i18n-title="@@Total"></app-vis-grid-column>
        <app-vis-grid-column class="text-right" [width]="40" min-width="40"
        field="Freq" title="頻率" i18n-title="@@Freq" *ngIf="!isShowRxUser"></app-vis-grid-column>
        <app-vis-grid-column class="text-right" [width]="30"
        field="Days" title="日" i18n-title="@@Days"></app-vis-grid-column>
        <app-vis-grid-column class="text-right" [width]="40" *ngIf="isShowDose != dosageEnum.TotalDose"
        field="TotalDose" title="總量" i18n-title="@@Total"></app-vis-grid-column>
        <app-vis-grid-column class="text-right" [width]="40" *ngIf="isShowDose == dosageEnum.TotalDose"
        field="Dose" title="次量" i18n-title="@@Dose"></app-vis-grid-column>
        <app-vis-grid-column [width]="40"
        field="Remark" title="註" i18n-title="@@Remark"></app-vis-grid-column>
        <app-vis-grid-column [width]="30"
        field="DispTP" title="調" i18n-title="@@DispTP"></app-vis-grid-column>
        <app-vis-grid-column [width]="120" min-width="40" class="pl-2"
        field="MedIDNameAndDateTime" title="執行人/時間" i18n-title="@@MedIDNameAndDateTime"*ngIf="isShowRxUser">
          <ng-template let-dataItem>
            <div style="white-space: pre-wrap;" [title]="dataItem.MedIDNameAndDateTime">{{dataItem.MedIDNameAndDateTime}}</div>
          </ng-template>
        </app-vis-grid-column>
      </app-vis-grid>

    </ng-container>
  </div>
</div>

