
import { PageData2 } from "src/app/shared/models/page-data";
import { Hspid } from "../hspid/hspid";
import { RegisterEditDto } from "../register/RegisterEditDto";
import { NHIRegIC } from "../register/nhiRegIc";
import { RegisterminiDto } from "../register/rgisterminiDto";
import { Rx } from "../rx/rx";
import { WholeHist } from "./whole-hist";

export class WholeLargeHist {
    wholeHist:WholeHist;
    miniRegister:RegisterminiDto;
    nhiRegIC:NHIRegIC;
    normalRegister : RegisterEditDto;
}


export class RepeatedPrescriptionDto{
    WholeHist:WholeHist;
    NHIRegIC:NHIRegIC;
    BackRegDate?:Date;
    Deadline?:Date;
    PresDateStartAndEnds :PresDateStartAndEndDto[];
    Data:string;
    HspidList:Hspid[] = [];
    FirstRegDate?:Date;
}

export class ReceiptInfoDto{
    WholeHist:WholeHist;
    NHIRegIC:NHIRegIC;
    Rx:Rx[]
}

export class DrugBagInfo{
    PatientNo:string;
    PatientName:string;
    SexName:string;
    Birthday:Date;
    RegDate:Date;
    ICodeName:string;
    MedDeptName:string;
    Pharmacist:string;
    DoctorName:string;
    /** 是否有H8 (慢箋出國一次領) */
    IsH8:boolean;
    /** 總袋數 */
    TotalBag:number;
    /** 第幾袋 */
    BagNo:number;
    /** 藥袋格式 1:展望標準 */
    DrugbagType:number;
    /** 給藥超過  ?? 日印建議回診日 */
    PrintReConsultateLimit:number;
    /** 提前領藥天數 */
    GetDrugInAdvanceLimit:number;
    /** 是否不印 診所抬頭 */
    DontPrintDrugBagTitle:boolean;
    /** 是否為慢箋 */
    IsSameTreat8:boolean;
    CntPresNo:string;
    CntPresTot:string;
    OneDrugOneBag:boolean;
    DrugBagWidth:number;
    DrugBagHeight:number;
    FormType:number;
    BackDate:Date;
    Deadline:Date;
    PresDateStartAndEnds:PresDateStartAndEndDto[];
    /** 起始點微調(公分) */
    StartBlankHeight:string;
    /** 開藥天數超過幾天就列印一藥一袋 */
    DaysToSplitDrugBag:number;
    DrugProdName:number;
    DrugIIName:number;
    HrxFontSize:number;
    PrintPatientBirthday: boolean;
    PrintNextPickupDrugDate: boolean;
    Hrx:PageData2<DrugBagInfoDetail>[]
}
export class DrugBagInfoDetail
{
    HRxId:number;
    Name:string;
    IIName:string;
    WayName:string;
    FreqName:string;
    Days:number;
    TotalDose:number;
    Dose:number;
    Unit:string;
    TotalUnit:string;
    Warning:string;
    Indication:string;
    SideEffect:string;
    RxId:number;
}
export class PresDateStartAndEndDto{
    CntPresNo:number;
    StartDate?:Date;
    EndDate?:Date;

}

export class RepeatedPrescriptionQRcode{
    SignData:string;
    Data:string[];
}
export class RepeatedPrescriptionRequest{
    Sign:string;
    Data:string;
}

export class HistPrintDrugDto{
  /** 調劑方式 */
  DispTP:string;
  /** 是否有管制用藥 */
  IsUseCTRDrugs:boolean;
  /** 是否有檢驗項目 */
  IsRxTypeLab:boolean;
  /** 是否有藥品項目  歸屬2,3,4,12 */
  IsRxTypeDrug:boolean;
  /** 慢箋領藥次別 */
  CntPresNo:string;
}
