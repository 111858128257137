import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DxReportQueryDto, DxReportSummaryDto } from 'src/app/regreport/models/dxreport';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';

@Component({
  selector: 'app-dxanalyze',
  templateUrl: './dxanalyze.component.html',
  styleUrls: ['./dxanalyze.component.css']
})
export class DxanalyzeComponent implements OnInit, AfterViewInit {

  constructor(
    private localPrintService: LocalPrintService,
    private hcrService: HcrService,
    private notification: EasyNotificationService) { }

  @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef<HTMLDivElement>;

  reportName = '診斷分析報表';
  sizePage = {
    width: 20, //cm
    height: 28.5 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };
  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );

  forExcel = true;
  listItems: DxReportSummaryDto[];  //data

  nowdateStr: string = '';  //印表日期
  nowtimeStr: string = '';  //印表時間
  totalRecord: number = 0;  //總筆數
  _sDate: string = '';   //查詢期間起
  _eDate: string = '';   //查詢期間訖

  elContainer: HTMLElement;

  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]
  upperboundIndex: number = 0;

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    var style = document.createElement('style');
      style.textContent =
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n' ;

      this.reportContainer.nativeElement.appendChild(style);
  }

  clearData() {
    if (this.elContainer)
      this.elContainer.innerHTML = '';
    const parentElement = document.getElementById("pres-report2");
    if (parentElement)
      parentElement.firstElementChild.innerHTML = '';
  }

  async exportAsExcel() {
    if (this.totalRecord > 0) {
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(this.reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  async onPrint() {
    if (this.totalRecord > 0) {

      var html = this.reportContainer.nativeElement.innerHTML;
      for (var i = 0; i <= this.upperboundIndex; i++) {
        html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
      }

      const printContents = html;
      var ret = await this.localPrintService.printHtml(444, this.reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(this.reportName + '已送出列印!', true);
      } else {
        this.notification.showError(this.reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async fetchData(data: DxReportSummaryDto[],input: DxReportQueryDto,sDate: Date,eDate: Date ) {

    this.elContainer = document.getElementById("container");

    //以下是報表表頭欄位
    this.nowdateStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.nowtimeStr = DateHelper.getTimeString(new Date(), ':', true);
    this._sDate = DateHelper.getROCDateStringWithSepetator(sDate);
    this._eDate = DateHelper.getROCDateStringWithSepetator(eDate);

    this.listItems = data;
    this.totalRecord = this.listItems.length;
    this.insertListData();
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }


  // #region 組報表
  // 組報表的html內容
  insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白

    // var html_total = this.createHTMLTotal();
    var html_blank = this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.totalRecord; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listItems[i],i);
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.totalRecord - 1) {
        prevTableContent = elTableContent.innerHTML;
        // elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          //   elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if ((this.listItems[i].IsSummary || this.listItems[i].IsDoctorTotal) && !(i == this.totalRecord - 1))
      {
        // afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        //elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;

    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 2px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

  createHTMLPageHeader(iPage) {
    return `<div id="header" style="margin: 0px 20px;">
                <div style="display: flex;column-gap: 5px;">
                  <table style="width: 100%; text-align: center; border: none;">
                    <tr>
                      <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      ` + this.reportName + `(總表)
                      </td>
                    </tr>
                    <tr>
                        <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表日期 ` + this.nowdateStr + `
                        </td>
                        <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        期間：` + this._sDate + ` 至 ` + this._eDate + `
                        </td>
                        <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        總筆數 ` + this.totalRecord + `
                        </td>
                    </tr>
                    <tr>
                        <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表時間 ` + this.nowtimeStr + `
                        </td>
                        <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                        </td>
                        <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                        </td>

                    </tr>
                  </table>
                </div>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                  <label style="font-weight: bold; height: 23px;">　</label>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 5px;">
                      <table id="table-` + iPage + `-content" style="width: 100%; font-size: 14pt; text-align: center; border: none; line-height: 1;">
                      </table>
                  </div>
              </div>`;
  }

  createHTMLListHeader() {
    return `<tr>
                <th style="text-align: left  ; width:  5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">排行</th>
                <th style="text-align: center; width:  5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">慢病</th>
                <th style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">代碼</th>
                <th style="text-align: center; width: 45%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">診斷</th>
                <th style="text-align: center; width: 6%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">男性</th>
                <th style="text-align: center; width: 6%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">女性</th>
                <th style="text-align: center; width: 7%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">總人數</th>
                <th style="text-align: center ; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">比率</th>
                <th style="text-align: left ; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black; padding-bottom: 8px;">平均年齡</th>
            </tr>`;
  }

  createHTMLBlock(data: DxReportSummaryDto, i: number) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    return `
        <tr>
          <td style="text-align: right ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${i+1}</td>
          <td style="text-align: center; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.DxType}</td>
          <td style="text-align: left  ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.DxCode}</td>
          <td style="text-align: left  ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.DxName}</td>
          <td style="text-align: right ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.MaleCount}</td>
          <td style="text-align: right ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.FemaleCount}</td>
          <td style="text-align: right ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.TotalCount}</td>
          <td style="text-align: right ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.Percentage}%</td>
          <td style="text-align: center ; padding-top: 5px; ${commonStyle}" ${commonClassText}>${data.AverageAge}</td>
        </tr>`;
  }

    //#endregion
}
