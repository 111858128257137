<div #reportContainer>
  <div class="container page-container">
    <div class="row custom-row-margin w-100">
      <div class="col-6">
        <app-medication-way-report [fontFamily]="fontType" #wayReport></app-medication-way-report>
      </div>
      <div class="col-5" style="margin-left: 1cm; margin-top:8px">
        <app-receipt-unit *ngIf="doubleSidedPrintMedicationWayAndReceipt" [fontFamily]="fontType" #recpReport></app-receipt-unit>
      </div>
    </div>
  </div>

</div>
