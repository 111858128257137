import { OrderSpecialRuleEnum } from "src/app/enums/OrderSpecialRuleEnum";
import { OrderTypeEnum } from "src/app/enums/OrderTypeEnum";
import { Entity } from "src/app/shared/models/entity";

export class Rx {
  Id: number;
  CreateTime: Date;
  Creater: string;
  EditTime: Date;
  Editor: string;
  IsEnable: boolean;
  ClinicId: string;
  RxCode: string;
  ShortNo: string;
  Type: number;
  StdCode: string;
  ProdName: string;
  IIName: string;
  GXName: string;
  ChineseName: string;
  DoseForm: string;
  DosageCode: string;
  SyrupNote: string;
  UseUnit: string;
  ATC: string;
  DTP: string;
  StorageMethod: string;
  ValidityPeriod: string;
  Warnings: string;
  Indications: string;
  SideEffects: string;
  OtherInstructions: string;
  ClinicalWay: string;
  Exterior: string;
  ImportantTip: string;
  NumberWarning: number;
  DayWarning: number;
  MonthWarning: number;
  WarningDays: number;
  IntervalDays: number;
  BelowtheAge: number;
  HigherthanAge: number;
  CTRDrugs: string;
  DrugControlNo: string;
  AllergyGroup: string;
  PriceChgDate: Date;
  IPrice: number;
  LastIPrice: number;
  Price: number;
  DiffPrice: number;
  MainSetId: number;
  RsCode: string;
  Spec: string;
  SpecAmount: number;
  SpecUnit: string;
  ManufacturerName: string;
  DefaultDose: number;
  DefaultDailyDose: number;
  DefaultTotalDose: number;
  DefaultFrequency: string;
  DefaultSurgery:string;
  DefaultDays: number;
  DefaultWay: string;
  DrugsPerBox: number;
  BoxUnit: string;
  IsByOral: boolean;
  SPRule: OrderSpecialRuleEnum;
  DispTP: string;
  LabHosp: string;
  AccSubject: string;
  AlternativeRxCode?: string;
  AlternativeRxId?: number;
  PaymentType: string;
  AdditionMAT: number;
  AdditionEME: number;
  Addition2Y: number;
  Addition6Y: number;
  AdditionMAG: number;
  AdditionSND: number;
  Addition6M: number;
  AdditionP6M: number;
  AdditionP2Y: number;
  AdditionP6Y: number;
  LabItemCode: string;
  IsStock: boolean;
  IsDrugStatus: boolean;
  DrugStopDate: Date;
  isCustomOrder?: boolean = false

  RxDosages:RxDosage[] = []

  static fromData(drugData: any): Rx {
    return Object.assign(new Rx(), drugData);
  }
  static isCommonDrug(drug: Rx): boolean {
    if (drug.Type === OrderTypeEnum.T2_OralDrugFee) {
      return true;
    } else {
      return false;
    }
  }
  static isOrderSet(nhiDrugType: number): boolean {
    if (nhiDrugType === OrderTypeEnum.T202_DrugSet //||
      //nhiDrugType === OrderTypeEnum.ChineseSoupSet
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export class RxListSimpleDto {
  Id: number;
  StdCode: string;
  RxCode: string;
  ProdName: string;
}
export class RxListWithRxDosageDto {
  Id: number;
  //StdCode: string;
  RxCode: string;
  ProdName: string;
  RxType:OrderTypeEnum;
  RxDosages:{Range:number,Dose:number}[];
}

export class RxListSimpleDtoWithCheck extends RxListSimpleDto {
  check: boolean = false;
}

export class RxDosage extends Entity{
  Range?:number;
  Dose?:number;
}