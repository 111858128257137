<div style="position: relative;background-color: white;overflow: scroll;border: 1px solid black;" >
    <!--半版預覽用虛線-->
    <div *ngIf="cmsData?.isHelf" 
      style="position: absolute; border-left: 1px dashed gray;height: 100%;"
      [ngStyle]="{left:'calc('+this.cmsData.width+' / 2)'}"></div>
    
    <div style="border-bottom: 1px dashed black; border-right: 1px solid black;" [ngStyle]="sizeStyle()" #reportContainer>
      <div style="height: 100%; width: 100%;">
        <ng-container *ngFor="let row of cmsData.rowsBeforeRx">
          <ng-container *ngTemplateOutlet="tmpRow; context: { $implicit: row, st: mergeStyle(cmsData.style, row.style) }"></ng-container>
        </ng-container>
    
        <ng-container *ngFor="let hrx of getHRx(); let i = index">
          <ng-container *ngFor="let row of cmsData.rowsOfRx">
            <ng-container *ngTemplateOutlet="tmpRow; context: { $implicit: row, st: mergeStyle(cmsData.style, row.style), arg: { ind: i, tp: 'hrx' } }"></ng-container>
          </ng-container>
        </ng-container>
    
        <ng-container *ngFor="let row of cmsData.rowsAfterRx">
          <ng-container *ngTemplateOutlet="tmpRow; context: { $implicit: row, st: mergeStyle(cmsData.style, row.style) }"></ng-container>
        </ng-container>
      </div>
    </div>
</div>   
<!-- 根據 groupType 決定使用的模板 -->
<ng-template let-row let-arg="arg" let-st="st" #tmpRow>
  <table style="width: 100%;border-collapse: collapse; border-spacing: 0; " [ngStyle]="rowStyle(row, st)">
    <tbody>
      <tr>
        <ng-container *ngFor="let group of row.groups">
          <!-- 插入 A 或 B 模板 -->
          <ng-container *ngTemplateOutlet="tmpGroups; context: { $implicit: group, st:  mergeStyle(st, group.style), arg: arg }"></ng-container>
        </ng-container>
        <ng-container *ngIf="row.groups.length == 0">
          <td [ngStyle]="style(st)">&nbsp;</td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</ng-template>

<!-- Template for a single column (converted to table cell) -->
<ng-template let-col let-arg="arg" let-st="st" #tmpCol>
  <td [ngStyle]="style(st, getData(col, arg))" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
    <span>{{ col.label?col.label :'' }}{{ col.dataSource && col.label   ? ':' : '' }}</span>
    <span *ngIf="col.textBefore">{{ col.textBefore }}</span>
    <span *ngIf="col.dataSource">{{ getData(col, arg) }}</span>
    <span *ngIf="col.textAfter">{{ col.textAfter }}</span>
  </td>
</ng-template>
<ng-template let-col let-arg="arg" let-st="st" #tmpColB>
  <div [ngStyle]="style(st, getData(col, arg),true)" style="width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
    <span>{{ col.label?col.label :'　' }}{{ col.dataSource && col.label && !col.isBarCode && !col.isQRcode  ? ':' : '' }}</span>
    <span *ngIf="col.textBefore">{{ col.textBefore }}</span>
    <span *ngIf="!col.isBarCode && !col.isQRcode &&  col.dataSource">{{ getData(col, arg) }}</span>
    <span *ngIf="col.textAfter">{{ col.textAfter }}</span>
    <span *ngIf="col.isBarCode && col.dataSource">
      <ngx-barcode6 style="padding-top: 20px;" [bc-format]="'CODE39'" [bc-width]="0.9" [bc-height]="30" [bc-value]="getData(col, arg)"></ngx-barcode6>
    </span>
    <span *ngIf="col.isQRcode&& col.dataSource" >
      <img style="width:80px;height: auto;padding-top: 0px;" #qrcodeItreatnumImg>
    </span>
    <!-- <span *ngIf="col.label?.length < 1 &&  col.textAfter?.length < 1 && col.dataSource?.length < 1&& col.textBefore?.length < 1">　</span> -->
  </div>
</ng-template>
<!-- Template for type A (with rowspan-like effect) -->
<ng-template #templateA let-group let-st="st" let-arg="arg">
  <!-- 使用 rowspan 来合并列 -->
  <td class="category" [ngStyle]="style(st, getData(group, arg))" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
    <ng-container *ngFor="let col of group.Cols">
      <ng-container *ngTemplateOutlet="tmpColB; context: { $implicit: col, st: mergeStyle(st, col.style,true), arg: arg }"></ng-container>
    </ng-container>
  </td>
</ng-template>

<!-- Template for type B (single row per item) -->
<ng-template #templateB let-group let-st="st" let-arg="arg">
  <!-- 渲染 group.Cols 中的每个 col，並把每個 col 的内容放在各自的 td 中 -->
  <ng-container *ngFor="let col of group.Cols">
    <!-- 渲染每一个列，直接作为 table 的一部分，确保所有 col 在同一个 tr 中 -->
    <ng-container *ngTemplateOutlet="tmpCol; context: { $implicit: col, st: mergeStyle(st, col.style), arg: arg }"></ng-container>
  </ng-container>
</ng-template>

<!-- 根據 groupType 決定使用的模板 -->
<ng-template let-group let-arg="arg" let-st="st" #tmpGroups>
  <ng-container *ngIf="group.groupType !== ''">
    <ng-container *ngTemplateOutlet="group.groupType === 'A' ? templateA : templateB; context: { $implicit: group, st: st, arg: arg }">
    </ng-container>
  </ng-container>
  <ng-container *ngIf="group.groupType === ''">
    <td [ngStyle]="style(st)">&nbsp;</td>
  </ng-container>
</ng-template>
<!-- 
  <ng-template let-row let-arg="arg" let-st="st" #tmpRow>
    <div [ngStyle]="rowStyle(row,st)" style="display: flex;">
      <ng-container *ngFor="let group of row.groups">
        <ng-container *ngTemplateOutlet="tmpGroups;context: {$implicit: group, st: mergeStyle(st, group.style), arg: arg}"></ng-container>
      </ng-container>
      <ng-container *ngIf="row.groups.length==0">
        <span [ngStyle]="style(st)">&nbsp;</span>
      </ng-container>
    </div>
  </ng-template>
  
  <ng-template let-col let-arg="arg" let-st="st" #tmpCol>
    <div [ngStyle]="style(st,getData(col,arg))" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
      <span *ngIf="col.label">{{col.label}}{{col.dataSource?':':''}}</span>
      <span *ngIf="col.textBefore">{{col.textBefore}}</span>
      <span *ngIf="col.dataSource">{{getData(col,arg)}}</span>
      <span *ngIf="col.textAfter">{{col.textAfter}}</span>
    </div>
  </ng-template>
  
  <ng-template #templateA let-group let-st="st" let-arg="arg">
    <div class="category" [style.gridRow]="'span ' + group.Cols.length">
      <div class="item" *ngFor="let col of group.Cols">
        <ng-container *ngTemplateOutlet="tmpCol; context: {$implicit: col, st: st, arg: arg}"></ng-container>
      </div>
    </div>
  </ng-template>
  
  <ng-template #templateB let-group let-st="st" let-arg="arg">
    <div class="category"></div>
    <div class="item" *ngFor="let col of group.Cols">
      <ng-container *ngTemplateOutlet="tmpCol; context: {$implicit: col, st: st, arg: arg}"></ng-container>
    </div>
  </ng-template>
  
  <ng-template class="container" let-group let-arg="arg" let-st="st" #tmpGroups>
    <ng-container *ngTemplateOutlet="group.groupType === 'A' ? templateA : templateB; context: { $implicit: group, st: st, arg: arg }">
    </ng-container>
  </ng-template> -->