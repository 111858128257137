<!-- TODO:固定table高customStyle取代showStickyHeight -->
<div class="position-relative small-paginater" style="padding-bottom: 0px;visibility: visible;"
  [ngStyle]="{'max-height': showStickyHead? (showStickyHeight + 'px'):'',
               height:showStickyTableRows?(showStickyHeight + 'px'):'',
               visibility: showStickyTableRows?rowHeightCalculated?'visible':'hidden':'visible',
               maxHeight: customStyle}"
  [ngClass]="[showStickyHead ? 'overflow-auto' : 'overflow-hidden',
              scroll ? 'overflow-x-auto' : '',
              customClass? customClass:'']"
  #scrollContainer>
  <table mat-table style="table-layout: fixed;" multiTemplateDataRows
    *ngIf="this.childs?.length>0"
    [dataSource]="_viewData"
    [ngClass]="[tableFullWidth ? tableFullWidth : '', sticky ? 'd-table' : '']"
    cdkDropList (cdkDropListDropped)="drop($event)" #mtable>
    <ng-container *ngFor="let column of childs;let colInd=index">
      <ng-container [matColumnDef]="column._field">
        <th mat-header-cell [attr.id]="column.HeaderId"
          [class.hidenColumn]="column.hide"
          [ngClass]="[column._class,column._classFromGrid,
                      showMultiLineHead ? 'lh-base th-grid py-1' : '',
                      showStickyHead ? 'position-sticky' : '',
                      column.sortable?'sortable-header':'',
                      ]"
          [ngStyle]="column._style"
          (click)="headerClick(column)"
          *matHeaderCellDef
          class="text-center">
          <ng-container *ngIf="column.showSelectAll; else defaultHeader" >
            <mat-checkbox (change)="setAll($event.checked, column._field)" [disabled]="column.setDisable" [checked]="AllComplete(column)" [indeterminate]="someComplete(column)">
              {{column.title}}
            </mat-checkbox>
          </ng-container>
          <ng-template #defaultHeader>
            <ng-container *ngIf="column._hasHeaderTemplate;else textHeader">
              <ng-container *ngTemplateOutlet="column.templateRefHeader.templateRef;context: {$implicit: column}"></ng-container>
            </ng-container>
            <ng-template #textHeader>
              {{column.title}}
            </ng-template>
            <ng-container *ngIf="column.sortOrder=='desc'">
              <mat-icon style="width: 16px;height: 16px;font-size: 16px;vertical-align: middle;">keyboard_arrow_down</mat-icon>
            </ng-container>
            <ng-container *ngIf="column.sortOrder=='asc'">
              <mat-icon style="width: 16px;height: 16px;font-size: 16px;vertical-align: middle;">keyboard_arrow_up</mat-icon>
            </ng-container>
          </ng-template>
        </th>
        <td mat-cell [class.hidenColumn]="column.hide" [ngClass]="[column._class,column._classFromGrid]" *matCellDef="let element;let index=dataIndex"
          (click)="onCellClick(element.data, column)" (dblclick)="onCellDblClick(element.data, column)"
          [ngStyle]="column._style">
          <ng-container *ngIf="column._isTemplate;else elseTmp">
            <div class="extend-content position-relative" [ngStyle]="column.CellStyle">
              <ng-container *ngTemplateOutlet="column.templateRef;context: {$implicit: element.data,col:column,index:index}"></ng-container>
            </div>
          </ng-container>
          <ng-template #elseTmp>
            <div style="display: grid;" class="vis-grid-text-cell extend-content position-relative" [ngStyle]="column.CellStyle">

              <ng-container *ngIf="column.pipe;else cellContent">
                <div [class.ellipsis-content]="!column.multiLine" [title]="element.data[column._field] | valueToText:column.pipe">
                  {{element.data[column._field] | valueToText:column.pipe}}
                </div>
              </ng-container>
              <ng-template #cellContent>
                  <ng-container *ngIf="column.dateTimeFormat=='Date'">
                    <div style="text-align: center;" [class.ellipsis-content]="!column.multiLine" [title]="element.data[column._field] | formatDate:''">
                      <!--民國日期-->
                      {{element.data[column._field] | formatDate:''}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column.dateTimeFormat=='DateTime'">
                    <div style="text-align: center;" [class.ellipsis-content]="!column.multiLine" [title]="element.data[column._field] | formatROCTime:''">
                      <!--民國日期時間-->
                      {{element.data[column._field] | formatROCTime:''}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column.dateTimeFormat=='Time'">
                    <div style="text-align: center;" [class.ellipsis-content]="!column.multiLine" [title]="element.data[column._field] | formatTime:''">
                      <!--時間-->
                      {{element.data[column._field] | formatTime:''}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column.dateTimeFormat==''">
                    <div [class.ellipsis-content]="!column.multiLine" [title]="element.data[column._field]">
                      {{element.data[column._field]}}
                    </div>
                  </ng-container>

              </ng-template>


            </div>
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row [ngStyle]="{backgroundColor: uiParam.GridTitleColor, display:noHeader ? 'none' : ''}" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <!--[ngStyle]="{backgroundColor: isRowSelected(row) ? uiParam.GridRowSelected : isOdd(ind) }"
      [style.background]="isRowSelected(row) ? (uiParam.GridRowSelected) : defaultSeclected(ind) ? (uiParam.GridRowSelected) : isOdd(ind)"
      [ngClass]="[defaultSeclected(ind)?'history-row-work':'', isRowSelected(row.data)?'history-row-work':'', isRowSetChangeClass(row.data)?changeRowClass:'']"
    -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let ind=index"
      [ngStyle]="row.styles"
      [ngClass]="row.classes"
      (click)="rowClick(ind, $event)"
      (mouseenter)="mouseEnter(ind)"
      (mouseleave)="mouseLeave(ind)"
      [class.selected]="isRowSelected(row.data)"
      [ngClass]="[isRowSetChangeClass(row.data)?changeRowClass:'']"
      [cdkDragDisabled]="!dragable"
      cdkDrag></tr>


    <ng-container *ngFor="let extRow of extandRows;let i=index">

      <tr mat-row *matRowDef="let row; columns: ['expandedDetail'+i]"
        [ngStyle]="row.styles" [ngClass]="row.classes"></tr>
      <ng-container [matColumnDef]="'expandedDetail'+i">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <ng-container *ngTemplateOutlet="extRow.templateRef;context: {$implicit: element.data,col:null,index:index}"></ng-container>
        </td>
      </ng-container>
    </ng-container>


  </table>
  <ng-content select="div[no-data]"></ng-content>
  <!--分頁-->
  <div style="align-items: center;border: solid 1px #ddd;"
      class="d-flex position-sticky start-0 bottom-0 lh-1"
      [ngStyle]="{backgroundColor: uiParam.GridPaginatorColor, 'z-index': '500'}"
    *ngIf="pageable && this._data">
    <div class="d-flex">
      <!--Go First-->
      <button type="button" mat-button class="pagination-page-btn-fn" [disabled]="!_hasPrev" (click)="toFirst()">
        <mat-icon style="font-size: 20px;">skip_previous</mat-icon>
      </button>
      <!--Go Prev-->
      <button type="button" mat-button class="pagination-page-btn-fn" [disabled]="!_hasPrev" (click)="toPrev()">
        <mat-icon style="font-size: 18px;padding-top: 1px; transform: rotateY(180deg);">play_arrow</mat-icon>
      </button>
      <!-- Prev 10-->
      <ng-container *ngIf="_hasPrevTen">
        <button type="button" mat-button class="pagination-page-btn-jump" [title]="'前'+pageBtnCnt+'筆'" (click)="toPrevTen()">
          ...
        </button>
      </ng-container>
      <!--Page Index-->
      <button type="button" mat-button [ngStyle]="cssIsCurrent(pgInx)" class="pagination-page-btn" [title]="pgInx+1"
              *ngFor="let pgInx of pages" (click)="toPage(pgInx)">
        {{pgInx+1}}
      </button>
      <!-- Next 10-->
      <ng-container *ngIf="_hasNextTen">
        <button type="button" mat-button class="pagination-page-btn-jump" [title]="'後'+pageBtnCnt+'筆'" (click)="toNextTen()">
          ...
        </button>
      </ng-container>
      <!-- Next-->
      <button type="button" mat-button class="pagination-page-btn-fn" [disabled]="!_hasNext" (click)="toNext()">
        <mat-icon style="font-size: 18px;padding-top: 1px;">play_arrow</mat-icon>
      </button>
      <button type="button" mat-button class="pagination-page-btn-fn" [disabled]="!_hasNext" (click)="toLast()">
        <mat-icon style="font-size: 20px;">skip_next</mat-icon>
      </button>
    </div>
    <div style="flex-grow: 1"></div>
    <div style="overflow: hidden;">
      <mat-paginator style="margin: 0px" class="vis-paginator"
        [ngStyle]="{backgroundColor: uiParam.GridPaginatorColor}"
        [length]="_data.total" [pageSize]="pageSize"
        [pageIndex]="_pageIndex" [hidePageSize]="true" [showFirstLastButtons]="false" aria-label="Select page" #paginator>
      </mat-paginator>
    </div>
  </div>
  <div *ngIf="showMask" class="loader-wrapper" style="min-height: 100px;">
    <mat-spinner color="primary" style="zoom:0.5;position: absolute;top: calc(50% - 50px);left: calc(50% - 50px);"></mat-spinner>
  </div>
</div>
