import { WholeHist } from "../hist/whole-hist"
import { PresDateStartAndEndDto } from "../hist/whole-large-hist"


export class MutiTreatmentListDto {
    StartDate :Date
    Isqno : string
    Dept : string
    CusTP :string
    CusTot :string
    ICode :string
    HistList: WholeHist[];
    PresStartRegDate?:Date
    PresBackRegDate ?:Date
    PresDeadline ?:Date
    PresPreviewBackRegDate ?:Date
    TruePresBackRegDate ?:Date
    TruePresPreviewBackRegDate ?:Date
    PreList: PresDateStartAndEndDto[]
    LatePreList: PresDateStartAndEndDto[]
    EarlyGetChronicDrugDate:number;
    Checked:boolean;
}

export interface RehabilitateClinic {
  clinicCnt: number;
  clinicPct: number;
  clinicAccount: number;
}

export interface RehabilitateHospital {
  hospitalCnt: number;
  hospitalPct: number;
  hospitalAccount: number;
}

export interface Therapist {
  employmentCnt: number;
  days: number;
  cnt: number;
  point: number;
  uploadLimits: number;
  overCnt: number;
  overPoint: number;
}

export interface RehabilitateCase {
  caseNo: number;
  cId: string;
  caseDays: number;
  caseCnt: number;
  casePoint: number;
  limitPeople: number;
  caseLimits: number;
  minusCnt: number;
  minusPoint: number;
}

export interface RehabilitateStatistics {
  clinicId: string;
  clinicName: string;
  paymentMonth: Date;
  classCnt: number;
  classDays: number;
  limits: number;
  ink: string;
  RxCode: string;
  clinic: RehabilitateClinic[];
  hospital: RehabilitateHospital[];
  pt: Therapist[];
  ot: Therapist[];
  st: Therapist[];
  prt?: Therapist[];
  case: RehabilitateCase[];
}
