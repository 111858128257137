import { SeqNumber } from '../../../shared/hcr-lib/SeqNumber';



export class FamilyCareDto {
    SeqNo : number;
    ClinicId: number;
    ClinicCode:string;
    PatientId: number;
    PatientNo:string;
    CaseDate: Date;
    CId: string;
    CName: string;
    Phone: string;
    DoctorCId: string;
    DoctorName: string;

    MemberType: string;
    CaseType: string;
    CloseDate?: Date;
    CloseReason: string;
    P5001Date: Date;
    IsAtPeriod: boolean;
    IsEnabled :boolean;
}


export class FamilyCareMutliDto {

    FamilyCareList: FamilyCareDto[] =[];
    Total: number;
    IsOld = false;
}


export class FamilyCareFromNhiDto {
  /** 院所ID */
  NHIcode: string;
  /** 身分證號 */
  CId: string;
  /** 生日 */
  Birthday: string;
  /** 個案類別 */
  CaseType: string;
  /** 論質名單 */
  QualityList: string;
  /** 65歲以上多重慢性病 */
  Over65MultipleChronic: string;
  /** 高診次註記 */
  HighVisitCount: string;
  /** 慢性病註記 */
  Chronic: string;
  /** 非慢性病註記 */
  NonChronic: string;
  /** 同院所註記 */
  SameClinic: string;
  /** 疾病類別 */
  DiseaseType: string;
  /** 備註 */
  Note: string;
  /** 收案醫師身分證 */
  DoctorCId: string;
}
