
import { AfterContentInit, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { SYS101Params } from 'src/app/services/api-service/parameters/SYS101Params';
import { VisGridCheckboxColumnComponent } from '../vis-grid-checkbox-column/vis-grid-checkbox-column.component';
import { SortOrder, VisGridColumnComponent } from '../vis-grid-column/vis-grid-column.component';
import { CdkDragDrop, moveItemInArray, } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { FontGreatService } from 'src/app/services/font-great.service';
import { Subscription } from 'rxjs';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { VisGridRowComponent } from '../vis-grid-row/vis-grid-row.component';

declare type GridSelectSetting = {
  enabled: boolean,
  checkboxOnly: boolean,
  mode: 'multiple' | 'single',
};
export declare interface GridData {
  data: any[];
  total: number
};
class GridViewModel {
  data: any;
  styles: any;
  classes: string[];
  deSelect() {
    this.classes = this.classes.filter(x => x != 'selected');
  }
  select() {
    this.classes.push('selected');
  }
}
export declare type VisGirdSelection = {
  selectedRows: {
    dataItem: any
  }[]
}
export declare type VisGridCellClick = {
  isEdited: boolean,
  dataItem: any,
  column: VisGridColumnComponent,
  rowIndex: number
}
export declare type VisGridColumnSort = {
  sortOrder: SortOrder,
  column: string
}
@Component({
  selector: 'app-vis-grid',
  templateUrl: './vis-grid.component.html',
  styleUrls: ['./vis-grid.component.css']
})

export class VisGridComponent implements OnInit, AfterContentInit, OnDestroy {
  @ContentChildren(VisGridColumnComponent)
  childs: QueryList<VisGridColumnComponent>;
  @ContentChildren(VisGridRowComponent)
  extandRows: QueryList<VisGridRowComponent>;
  @ViewChild('scrollContainer')
  scrollContainer: ElementRef<HTMLDivElement>;

  @ViewChild('paginator')
  paginator: MatPaginator;

  _data: GridData;
  _viewData: GridViewModel[];

  @Input()
  showMask: boolean = false;

  /** 固定header開關 */
  @Input()
  showStickyHead?: boolean = false;

  /** 固定table高TODO:customStyle取代showStickyHeight */
  @Input()
  showStickyHeight?: number = 350;
  _showStickyTableRows?: number = null;
  /** showStickyHead下grid的顯示行數 */
  @Input()
  set showStickyTableRows(r: number) {
    var needReComput = !!this._showStickyTableRows;
    this._showStickyTableRows = r;
    if (needReComput) {
      this.computeRowHeight();
    }
  }
  get showStickyTableRows(): number {
    return this._showStickyTableRows;
  }
  /** 字多折行header開關 */
  @Input()
  showMultiLineHead?: boolean = false;

  /** table寬滿版 */
  @Input()
  tableFullWidth?: string = 'w-100';

  /** table固定左欄 */
  @Input()
  sticky?: boolean = false;

  /** 預設選取 flag */
  @Input()
  setDefaultSeclected: boolean = false;

  @Input()
  allowDoubleClick: boolean = false;

  @Input()
  set data(v: GridData | any[]) {
    if (v) {
      if (v instanceof Array) {
        this._data = {
          data: v,
          total: v.length
        };
      } else {
        this._data = <GridData>v;
      }
      if (this._data) {
        this._viewData = this._data.data.map((d, i) => {
          return Object.assign(new GridViewModel(), {
            data: d,
            styles: {
              //background: this.isOdd(i),
              cursor: this.dragable ? 'grab' : 'default'
            },
            classes: [i % 2 ? 'even' : 'odd']
          });
        });

        setTimeout(() => {
          if (this._showStickyTableRows) {
            this.computeRowHeight();
          }

          this.computePages();
          this.checkPageBtn();
          this.setAll(false, null, false);
          this.selectedKeys = this._selectedKeys;
          this.changeClassKeys = this._changeClassKeys;
          // 勾欄有綁field的時候，將selectedKeys內容改成有勾選的資料
          this.resetSelectStatus();
          this.onDataSetted.emit(this._data.total);
        }, 0);
      }
    }
  };


  @Input() customClass?: string;
  @Input() customStyle?: string;
  // get data():{data:any[],total:number }| any[]{
  //   return this._data;
  // }

  _pageIndex = 0;
  _pageSize: number = 10;
  @Input()
  set pageSize(v: number) {
    this._pageSize = v;
    if (this._pageSize) {
      this._pageIndex = Math.floor(this._skip / this._pageSize);
    }
  };
  get pageSize() {
    return this._pageSize;
  }

  _skip: number;
  @Input()
  set skip(v: number) {
    this._skip = v;
    if (this._pageSize) {
      this._pageIndex = Math.floor(this._skip / this._pageSize);
    }
  };
  get skip(): number {
    return this._skip
  }

  @Input()
  pageable: boolean;

  _selectable = false;
  _selectByCheckOnly = true;
  @Input()
  set selectByCheckOnly(v: boolean) {
    this._selectByCheckOnly = v || false
  };
  @Input()
  set selectable(v: boolean | GridSelectSetting) {
    if (v instanceof Boolean) {
      this._selectable = <boolean>v;
      // this.multiselect = false;
    } else if (v as GridSelectSetting) {
      this._selectable = v['enabled'] ?? true;
      this.multiselect = v['mode'] == 'multiple';
      this._selectByCheckOnly = v['checkboxOnly'] ?? false;
    }
  };
  @Input()
  cancelable: boolean = true;

  /** 黃底的選取依據 field key ，例如：Id */
  @Input()
  kendoGridSelectBy: string = '';
  _selectedKeys: string[] = [];
  @Input()
  set selectedKeys(v: string[]) {
    if (v) {
      this._selectedKeys = v;
      this.updateSelectedView()
      if (this.childs) {
        this.childs.forEach(c => {
          if (c instanceof VisGridCheckboxColumnComponent) {
            c.selectedKeys = this._selectedKeys;
            c.selectByKey = this.kendoGridSelectBy;
            this._data.data.forEach((d, i) => {
              c.rowClick(i, d)
            });
          }
        });
      }
    }
  };
  @Input()
  setChangeClassBy: string = '';
  _changeClassKeys: string[] = [];
  @Input()
  set changeClassKeys(v: string[]) {
    if (v) {
      this._changeClassKeys = v;
      this.updateSelectedView();
    }
  };
  @Input() changeRowClass?: string = '';
  @Input()
  setting: {
    checkboxOnly: true,
    mode: 'multiple'
  }
  @Input()
  navigable: boolean;

  @Input()
  dragable: boolean;

  @Input()
  noHeader: boolean;

  /** 欄位寬度超出時是否開啟卷軸 */
  @Input()
  scroll: boolean;

  @Input()
  multiselect: boolean;

  @Input()
  stickyColumnCount = 0;

  @Output()
  cdkDragEnded: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter(false);
  @Output()
  cellClick: EventEmitter<VisGridCellClick> = new EventEmitter(false);
  @Output()
  selectionChange: EventEmitter<VisGirdSelection> = new EventEmitter(false);
  @Output()
  selectionDblClickChange: EventEmitter<VisGirdSelection> = new EventEmitter(false);

  @Output()
  sort: EventEmitter<VisGridColumnSort> = new EventEmitter();


  @Input()
  pageBtnCnt = 10;
  pages: number[] = [];
  displayedColumns: string[] = [];
  _hasPrev = false;
  _hasNext = false;
  _hasPrevTen = false;
  _hasNextTen = false;
  uiParam: SYS101Params = new SYS101Params();
  usedIdRand = [];
  @Output()
  onDataSetted: EventEmitter<number> = new EventEmitter();

  fontSizeSub: Subscription;
  constructor(private paramApi: ParameterApi,
    private fontGreatService: FontGreatService,
    private clinicDataService: ClinicDataService
  ) {
    this.updateUIParam();
    this.fontSizeSub = this.fontGreatService.onFontGreatChanged.subscribe(g => {
      if (this._showStickyTableRows) {
        this.computeRowHeight();
      }
    });
  }

  updateUIParam() {
    this.clinicDataService.getParam("SYS101").then(p => {
      this.uiParam = p;
      document.documentElement.style.setProperty('--grid-odd', this.uiParam.GridRowOdd);
      document.documentElement.style.setProperty('--grid-even', this.uiParam.GridRowEven);
      document.documentElement.style.setProperty('--grid-hover', this.uiParam.GridRowHover);
      document.documentElement.style.setProperty('--grid-selected', this.uiParam.GridRowSelected);
    });
  }

  @ViewChild('mtable')
  tHeader: MatTable<any>;
  rowHeightCalculated = false;
  ngOnInit(): void {
    if (this.showStickyTableRows) {
      setTimeout(() => {
        this.computeRowHeight();
      }, 0);
    }
    this.computeColStyle()
  }
  ngOnDestroy(): void {
    this.fontSizeSub.unsubscribe();
  }
  computeColStyle() {
    if (this.stickyColumnCount > 0) {
      setTimeout(() => {
        var colLeft = 0;
        this.childs.forEach((x, i) => {
          if (i < this.stickyColumnCount) {
            (x._style).position = 'sticky !important';
            (x._style).left = colLeft + 'px';
            if (x._classFromGrid.indexOf('sticky-column') == -1) {
              x._classFromGrid += ' sticky-column'
            }
          }
          colLeft += <number>x.width;;
        })
      }, 0);

    }
  }
  computeRowHeight() {
    var headerHeight = this.tHeader._headerRowOutlet.elementRef.nativeElement.parentElement.offsetHeight;

    var bodyHeight = this.tHeader._rowOutlet.elementRef.nativeElement.parentElement.clientHeight;
    var rowHeight = this.fontGreatService.getFontGreat() ? 32 : 27; // 字體大小預設行高,應該不止這兩個數字...
    if (this._data?.data?.length) {
      rowHeight = bodyHeight / this._data.data.length;
    }

    // 每行28計 +1消除正好滿行時的scroll
    this.showStickyHeight = this.showStickyTableRows * rowHeight + headerHeight;// + 10;
    this.rowHeightCalculated = true;
  }

  isOdd(val: number): string {
    return val % 2 ? this.uiParam.GridRowEven : this.uiParam.GridRowOdd;
  }

  /** 預設選取第一筆 */
  defaultSeclected(val: number): boolean {
    if (this.setDefaultSeclected) {
      if (val == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // setDefault() {
  //   this.setDefaultSeclected = true;
  // }

  ngAfterContentInit() {
    this.displayedColumns = [];
    //this.columns = [];
    this.childs.forEach(c => {
      var field = c.field;
      if (!field) {
        do {
          field = 'id' + Math.floor(Math.random() * 100);
        } while (this.usedIdRand.includes(field));
        this.usedIdRand.push(field);
      }
      c._width = c.width ? (c.width + '') : ''
      c._field = field;
      this.displayedColumns.push(field);
      if (c instanceof VisGridCheckboxColumnComponent) {
        c.selectedKeys = this._selectedKeys;
        c.selectByKey = this.kendoGridSelectBy;
      }
    });
    this.computePages();
    this.checkPageBtn();
  }

  computePages() {
    if ((this._data?.total ?? 0) == 0) {
      this.pages = [];
    } else {
      var curPage = this._pageIndex;
      var totalPage = Math.floor((this._data.total - 1) / this.pageSize) + 1

      var startPage = curPage == 0 ? 0 : Math.floor((Math.floor((curPage) / this.pageBtnCnt)) * this.pageBtnCnt)
      var pageCount = Math.min(totalPage - startPage, this.pageBtnCnt);

      this.pages = Array(pageCount).fill(1).map((x, i) => i + startPage);
    }
  }

  toPage(ind: number) {
    this.setPage(ind);
  }

  hasNextTen() {
    if (!this.paginator) {
      return false;
    }
    var curPage = this._pageIndex;
    var totalPage = Math.floor((this._data.total - 1) / this.pageSize) + 1;
    return totalPage > (curPage - curPage % this.pageBtnCnt + this.pageBtnCnt);
  }

  toNextTen() {
    if (!this.paginator) {
      return false;
    }
    var curPage = this._pageIndex;
    this.setPage(curPage - curPage % this.pageBtnCnt + this.pageBtnCnt);
  }

  hasPrevTen() {
    if (!this.paginator) {
      return false;
    }
    var curPage = this._pageIndex;
    return curPage >= this.pageBtnCnt;
  }

  toPrevTen() {
    if (!this.paginator) {
      return false;
    }
    var curPage = this._pageIndex;
    this.setPage((curPage - curPage % this.pageBtnCnt) - 1);

  }

  toFirst() {
    this.setPage(0);
  }

  toPrev() {
    this.setPage(this._pageIndex - 1)
  }

  toLast() {
    var totalPage = Math.floor((this._data.total - 1) / this.pageSize);
    this.setPage(totalPage)
  }

  toNext() {
    this.setPage(this._pageIndex + 1)
  }

  hasPrev() {
    if (!this.paginator) {
      return false;
    }

    return this._pageIndex > 0;
  }

  hasNext() {
    if (!this.paginator) {
      return false;
    }
    var totalPage = Math.floor((this._data.total - 1) / this.pageSize) + 1;
    return this._pageIndex < totalPage - 1;
  }

  cssIsCurrent(pgInd) {
    if (pgInd == this._pageIndex) {
      return {
        'backgroundColor': '#3ab0f5',
        'color': 'white'
      }
    }
    return {};
  }

  setPage(index) {
    if (this._pageIndex != index) {
      this.clearSelect();
      //this.currentSelected = [];
      this.emitSelection();
      this.pageChange.emit(index + 1);
      setTimeout(() => {
        this._pageIndex = index;
        this.computePages();
        this.checkPageBtn();
        this.scrollContainer.nativeElement.scrollTo(0, 0);
      }, 0);
    }
  }

  rowClick(row: any, evt: any) {
    //selected
    // console.log('rowClick', row, evt)
    this.setDefaultSeclected = false;  // set flag to false after the user clicked on the cell.
  }

  clearSelect() {
    this._selectedKeys.splice(0, this._selectedKeys.length);
    this._viewData.forEach(d => d.deSelect())
  }
  /** 移除不在資料中的選取項目 */
  removeSelectNotInData() {
    var keeped = this._selectedKeys.filter(x => this._data.data.some(y => y[this.kendoGridSelectBy] == x));
    this._selectedKeys.splice(0, this._selectedKeys.length);
    keeped.forEach(x => this._selectedKeys.push(x));
    this._viewData.forEach(d => {
      d.deSelect()
      if (this._selectedKeys.some(x => x == d.data[this.kendoGridSelectBy])) {
        d.select()
      }
    });
  }
  isEdited = false;
  matMenuTimer: any;

  onCellDblClick(row: any, col: any) {
    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;
    this.handleDblClick(row, col);
  }

  handleDblClick(row: any, col: VisGridColumnComponent) {
    var index = this._data.data.indexOf(row);
    this.cellClick.emit({
      isEdited: this.isEdited,
      dataItem: row,
      column: col,
      rowIndex: index
    });
    var key = row[this.kendoGridSelectBy];
    if (this._selectable && !this._selectByCheckOnly) {
      if (this.multiselect) {
        if (this._selectedKeys.some(i => i == key)) {
          var spliceInd = this._selectedKeys.indexOf(key);
          //this.currentSelected.splice(spliceInd,1);
          this._selectedKeys.splice(spliceInd, 1);
          this.findViewData(key)?.deSelect();
        } else {
          //this.currentSelected.push(index)
          this._selectedKeys.push(key);
          this.findViewData(key)?.select();
        }
      } else {
        //this.currentSelected = [index];
        if (this._selectedKeys.length == 0) {
          this._selectedKeys.push(key);
          this.findViewData(key)?.select();
        } else if (this._selectedKeys[0] == key) {
          // 取消選取 => 診間歷史病歷double click[複製]會無反應，先拿除this.clearSelect()，未來若有影響其他功能在全面檢視 2024.1.18
          //this.clearSelect();
        } else {
          this._selectedKeys[0] = key;
          this.findViewData(key)?.select();
        }
      }
      this.emitSelectionForDbl();
    }
    this.childs.forEach(c => c.rowClick(index, row));
  }

  //currentSelected = [];
  onCellClick(row: any, col: VisGridColumnComponent) {
    this.matMenuTimer = setTimeout(() => {
      var index = this._data.data.indexOf(row);
      this.cellClick.emit({
        isEdited: this.isEdited,
        dataItem: row,
        column: col,
        rowIndex: index
      });
      var key = row[this.kendoGridSelectBy];
      if (this._selectable && !this._selectByCheckOnly) {
        if (this.multiselect) {
          //取消選取
          if (this._selectedKeys.some(i => i == key)) {
            var spliceInd = this._selectedKeys.indexOf(key);
            //this.currentSelected.splice(spliceInd,1);
            this._selectedKeys.splice(spliceInd, 1);
            this.findViewData(key)?.deSelect();
          } else {
            //this.currentSelected.push(index)
            this._selectedKeys.push(key);
            this.findViewData(key)?.select();
          }
        } else {
          //this.currentSelected = [index];
          if (this._selectedKeys.length == 0) {
            this._selectedKeys.push(key);
            this.findViewData(key)?.select();
          } else if (this._selectedKeys[0] == key) {
            if (this.cancelable) {
              // 取消選取
              this.clearSelect();
            }

          } else {
            this._selectedKeys[0] = key;
            this.findViewData(key)?.select();
          }
        }
        this.emitSelection();
      }
      this.childs.forEach(c => c.rowClick(index, row));
    }, this.selectionDblClickChange.observers.length == 0 ? 0 : 300);
  }

  emitSelection() {
    var selectedData = this._selectedKeys.map((k, ind) => {
      let item: any;
      for (let i = 0; i < this._data.data.length; i++) {
        let currentData = this._data.data[i];
        if (currentData[this.kendoGridSelectBy] === k) {
          item = currentData;
          break;
        }
      }
      return { dataItem: item };
    });
    let selected = { selectedRows: selectedData };
    this.selectionChange.emit(selected);
  }

  emitSelectionForDbl() {
    var selectedData = this._selectedKeys.map((k, ind) => {
      let item: any;
      for (let i = 0; i < this._data.data.length; i++) {
        let currentData = this._data.data[i];
        if (currentData[this.kendoGridSelectBy] === k) {
          item = currentData;
          break;
        }
      }
      return { dataItem: item };
    });
    let selected = { selectedRows: selectedData };
    this.selectionDblClickChange.emit(selected);
  }

  isRowSelected(row) {
    try {
      if (this._selectedKeys) {
        var r = this._selectedKeys.some(i => i == row[this.kendoGridSelectBy]);
        return r;
      }
    }
    catch (e) {

    }

  }

  isRowSetChangeClass(row) {
    if (this._changeClassKeys && this._changeClassKeys.length > 0) {
      return this._changeClassKeys.some(i => i == row[this.setChangeClassBy]);
    }
    return false;
  }

  setAll(checked: boolean, field: string, fireEvent: boolean = true) {
    // console.log('setAll >>>>>', this._data.data);
    this._data.data.forEach(d => {
      // 設定勾選
      if (field !== null) {
        d[field] = checked;
      }
      if (this.multiselect) {
        // 設定全選
        const spliceInd = this._selectedKeys.indexOf(d[this.kendoGridSelectBy]);
        if (spliceInd != -1) {
          this._selectedKeys.splice(spliceInd, 1);
          this.findViewData(d[this.kendoGridSelectBy])?.deSelect();
        }

        if (checked === true) {
          this._selectedKeys.push(d[this.kendoGridSelectBy])
          this.findViewData(this.kendoGridSelectBy)?.select();
        }
      }
    });
    // console.log(this._selectedKeys);
    // console.log('fireEvent >>>>>', fireEvent);
    if (fireEvent) {
      this.childs.forEach(c => {
        if (c instanceof VisGridCheckboxColumnComponent) {
          if (c.selectOnCheck) {
            this._data.data.forEach((d, i) => {
              c.rowClick(i, d)
            });
          }
          c.checkChanged.emit({ checked: checked, data: null })
        }
      });
    }
  }

  resetSelectStatus() {
    this.removeSelectNotInData();
    this.childs.forEach(c => {
      if (c instanceof VisGridCheckboxColumnComponent && this._selectByCheckOnly && !c._field.match(`id.*`)) {
        this._data.data.forEach(d => {
          if (d[c._field] == true) {
            this._selectedKeys.push(d[this.kendoGridSelectBy])
            this.findViewData(d[this.kendoGridSelectBy])?.select();
          }
        });
      }
    });
  }

  select(itemKey: any) {
    if (!this._selectedKeys.some(s => s == itemKey)) {
      this._selectedKeys.push(itemKey);
      this.findViewData(itemKey)?.select();
    }
  }

  someComplete(col: VisGridColumnComponent): boolean {
    if (this._data?.data == null) {
      return false;
    }
    if (col instanceof VisGridCheckboxColumnComponent) {
      if (col.someChecked != null) {
        return col.someChecked
      }
    }
    //console.log(this._selectedKeys.length > 0 && this._selectedKeys.length< this.data.data.length)
    return this._selectedKeys.length > 0 && this._selectedKeys.length < this._data.data.length;
  }

  AllComplete(col: VisGridColumnComponent) {
    if (this._data?.data == null || this._data.data.length == 0) {
      return false;
    }
    if (col instanceof VisGridCheckboxColumnComponent) {
      if (col.allChecked != null) {
        return col.allChecked
      }
    }
    return this._selectedKeys.length == this._data.data.length;
  }

  checkPageBtn() {
    this._hasNext = this.hasNext();
    this._hasPrev = this.hasPrev();
    this._hasNextTen = this.hasNextTen();
    this._hasPrevTen = this.hasPrevTen();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this._data.data, event.previousIndex, event.currentIndex);
    var datas = {
      preIndex: event.previousIndex,
      curIndex: event.currentIndex
    }
    this.cdkDragEnded.emit(datas);
  }

  @Input()
  mouseOnInd: number;
  @Output()
  mouseOnIndChange = new EventEmitter<number>();
  mouseEnter(ind: number) {
    this.mouseOnInd = ind;
    this.mouseOnIndChange.emit(this.mouseOnInd)
  }
  mouseLeave(ind: number) {
    this.mouseOnInd = null;
    this.mouseOnIndChange.emit(this.mouseOnInd)
  }


  headerClick(column: VisGridColumnComponent) {
    if (column.sortable) {
      column.onSort();
      if (column.sortOrder == 'none') {
        // 其他欄位切回預設
        this.childs.forEach(x => {
          if (x.sortable && x != column) {
            x.setSortDefault();//.sortOrder = 'none'
          }
        });
        this.sort.emit({
          sortOrder: 'none',
          column: ''
        });

      } else {
        //其他欄位設定成無排序
        this.childs.forEach(x => {
          if (x.sortable && x != column) {
            x.sortOrder = 'none'
          }
        });
        this.sort.emit({
          sortOrder: column.sortOrder,
          column: column.field
        });
      }

    }

  }

  findViewData(key: any) {
    return this._viewData.find(x => x.data[this.kendoGridSelectBy] == key);
  }
  updateSelectedView() {
    setTimeout(() => {
      this._viewData?.forEach(x => {
        x.deSelect();
        if (this._selectedKeys.some(y => y == x.data[this.kendoGridSelectBy])) {
          x.select();
        }
        x.classes = x.classes.filter(y => y != this.changeRowClass);

        if (this._changeClassKeys.some(y => y == x.data[this.setChangeClassBy])) {
          x.classes.push(this.changeRowClass)
        }
      })
    }, 0);

  }
}
