import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CardArrearsComponent } from 'src/app/payments/card-arrears/card-arrears.component';
import { RecardListDto } from 'src/app/services/api-service/hist/recard-list';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { MutiTreatmentListDto } from 'src/app/services/api-service/register/mutiTreatmentListDto';
import { BaseConfig } from 'src/app/services/base-config.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';

@Component({
    selector: 'app-muti-treatment-dialog',
    templateUrl: './muti-treatment.component.html',
    styleUrls: ['./muti-treatment.component.css']
})
export class MutiTreatmentDialogComponent implements OnInit {

    // isDialogDisabled = false;
    @Input() isDisabled: boolean = false;
    // @ViewChild(CardArrearsComponent, { static: false })
    // cardArrears: CardArrearsComponent
    @Input()
    regQuery: boolean = false;
    isOpenArrears = false;
    recardListdata: RecardListDto;
    initMutiTreatmentList : MutiTreatmentListDto[] = [];
    // @Input() receivedData: WholeHist;
    // any後續應該換為Patient
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onEmitResult = new EventEmitter<MutiTreatmentListDto[]>();
    @Output() onEmitQueryNotFound = new EventEmitter<boolean>();
    @Output() closeDialog = new EventEmitter(); // close dialog only
    @Output() afterReverseCardDo = new EventEmitter();
    @Output()
    cellClick: EventEmitter<any> = new EventEmitter(false);
    @Input()
    kendoGridSelectBy: string = '';
    _selectedKeys: string[] = [];
    @Input()
    set selectedKeys(v: string[]){
      if(v){
        this._selectedKeys = v;
      }
    };

    @Input()
    set InitData(data: MutiTreatmentListDto[]) {
        data = data.filter(x => x.CusTP != '');
        this.pickedData = [];  //重新抓取data 清除pickedData 
        this.initMutiTreatmentList = data;
        let ckdata =  data.find(x => x.Checked);

        let dataFirstCheck = data[0];
        // mindata 會影響接卡序 現改成接新的療程
        // if (dataFirstCheck?.CusTP == '4'){
        //     dataFirstCheck =  this.minDateData(data);
        // }
        // else 
        if (dataFirstCheck?.CusTP == '8'){
            dataFirstCheck = null;
        }
        if (ckdata) this.uploadDataCheckChanged({ checked: true, data: ckdata});
        else  this.uploadDataCheckChanged({ checked: true, data: dataFirstCheck});
        this.gridView = { data: data, total: data.length }

    }
    get InitData() {
      return this.initMutiTreatmentList;
    }


    // @Input() InitData: MutiTreatmentListDto[];
    gridView = { data: [], total: 0 };
    pageSize: number = BaseConfig.getConfig().ui.pageSize;
    pageSkipNo = 0;
    selectedItems: number[] = [];
    pickedData:MutiTreatmentListDto[] = [];

    constructor(    private notification: EasyNotificationService,
        ) { }
    // get initData() {
    //     console.log('this.InitData',this.InitData);
    //     this.gridView.data = this.InitData;
    //     // console.log('this.selectedPatient', this.InitData);
    //     // console.log('this.gridView.data ', this.gridView.data);
    //     return;
    // }

    ngOnInit() {
    }

    onDialogClose(status: string) {
        if (status === 'ok') {
            // console.log('pickedData',this.pickedData);
            // console.log('initdata',this.InitData);
                this.onEmitResult.emit(this.pickedData);
                // this.closeDialog.emit();

        } else {
            // 這次沒選任何病患
            this.closeDialog.emit();
        }
    }
    isSomeChecked(){
        return  this.InitData.some(x=>x.Checked)
    }
    // onDialogChose(event: WholeHist) {
    //     this.onEmitResult.emit(event);
    // }
    onQueryNotFound(event: boolean) {
        this.onEmitQueryNotFound.emit(event);
    }
    callAfterDo(){
        this.afterReverseCardDo.emit();
    }
    // any後續應該換為Patient
    // getInitData(data: MutiTreatmentListDto[]) {
    //     // this.receivePatientChange();
    //     this.InitData = data;
    //     this.gridView.data = data;
    //     this.gridView.total = data.length;
    //     // console.log('this.selectedPatient', this.InitData);
    //     // console.log('this.gridView.data ', this.gridView.data);
    // }

    pageChange(page: number): void {
        this.pageSkipNo = (page - 1) * this.pageSize;
        this.refreshList(); // 更新清單，因為server端資料可能異動
    }
    // 處理grid cell點擊，預設view
    public gridCellClickHandler({ isEdited, dataItem, rowIndex }): void {

    }
    refreshList(isNewQuery?: boolean) {
        if (isNewQuery) {
            this.pageSkipNo = 0;
        }

    }

    uploadDataCheckChanged(evt:{checked:boolean,data:MutiTreatmentListDto}) {
        // evt.data==null => 全選
        var selectedDatas = evt.data==null?null:[evt.data]
        // console.log('evt.checked', evt.checked);
        // console.log('selectedDatas',selectedDatas);
        if(!selectedDatas){
            return 
        }
        
        selectedDatas.forEach(d=>{
            d.Checked = evt.checked;
            var selectedIsqno = d.Isqno;
            // console.log('d.Checked', d.Checked);
            // console.log('selectedIsqno',selectedIsqno);


            // 勾選
            if(evt.checked){
                if(this.pickedData.find(x=>x.Isqno == selectedIsqno)){
                    //this.notify.showWarning('此筆資料已加入')
                    return
                }
                else if (this.pickedData.length == 1 && !this.pickedData.find(x=>x.Isqno == selectedIsqno)){
                    // this.InitData.find(x => x.Isqno  == selectedIsqno).Checked = evt.checked;
                    // console.log('this.InitData',this.InitData);
                    var oData = this.InitData.find(x=>x.Isqno == selectedIsqno)
                    this.pickedData.push(oData);
                    if(this.pickedData) {
                      this.onDialogClose('ok');
                    }
                    this.notification.showWarning('除復健與外傷外不可接受多療程！');
                    setTimeout(() => {
                        this.uploadDataCheckChanged({checked:false,data:d});
                    }, 1);
                    return
                }
                else
                var oData = this.InitData.find(x=>x.Isqno == selectedIsqno)
                this.pickedData.push(oData);
                if(this.pickedData) {
                  this.onDialogClose('ok');
                }
            }
            // 取消
            else{
                this.pickedData = this.pickedData.filter(x=>x.Isqno != selectedIsqno);
                this.onDialogClose('ok');

            }
        })

    }

    closeArrearsDialog() {
        this.isOpenArrears = false;
        // this.cardArrears.CancelDialog();
    }
    minDateData(data : any[]): any {
        return data.reduce((min, current) => (current.StartDate < min.StartDate ? current : min), data[0]);
    }
    SelectChange(event: any) {

    }
}
