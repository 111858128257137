import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { toothTreatPosDto,toothTreated } from '../models/tooth-treat-position';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';

@Component({
    selector: 'app-tooth-pos-map',
    templateUrl: './tooth-positions-map.component.html',
    styleUrls: ['./tooth-positions-map.component.css','./style.css', './main.css', './tooth.css']
})
export class ToothPositionsMapComponent implements OnInit {

    colorRed = '#FF4122';

    @Input() isHelperPadOpened?: string = '';
    @Input() toothTreatPos: toothTreatPosDto[] = [];
    @Input() test: boolean = false;
    @Output() toothPosClick = new EventEmitter<toothTreatPosDto>();

    constructor(private notification: EasyNotificationService) 
    {
    }

    ngOnInit() {
        /** 測試 */
        // if (this.test) this.setToothTreatPost();
        /** end 測試 */
        // console.log('toothTreatPos >>>>>', this.toothTreatPos);
        this.setDefaultToothPos();
    }

    // def-value for test
    setToothTreatPost() {
        this.toothTreatPos = [];
        for (var i = 1; i <= 8; i++) {
            if (i <= 4) {  // 恆齒
                for (var j = 1; j <= 8; j++) {
                    var toothPos: toothTreatPosDto = new toothTreatPosDto();
                    var treatedRcd: toothTreated = new toothTreated();
                    toothPos.posNo = '' + i + j;
                    
                    if (j == 7) { treatedRcd.canENDO = false; } else { treatedRcd.canENDO = true; }
                    if (j == 4 || j == 8) { treatedRcd.canExt = false; } else { treatedRcd.canExt = true; }
                    if (j == 6) { treatedRcd.canSea = false; } else { treatedRcd.canSea = true; }
                    if (j == 3) {
                        treatedRcd.cantOD = ['c', 'u', 'r'];
                    } else {
                        treatedRcd.cantOD = [];
                    }

                    toothPos.treatRecord = treatedRcd;
                    this.toothTreatPos.push(toothPos);
                }
            } else {    // 乳齒
                for (var j = 1; j <= 5; j++) {
                    var toothPos: toothTreatPosDto = new toothTreatPosDto();
                    var treatedRcd: toothTreated = new toothTreated();
                    toothPos.posNo = '' + i + j;
                    
                    if (j == 3) { treatedRcd.canENDO = false; } else { treatedRcd.canENDO = true; }
                    if (j == 2) { treatedRcd.canExt = false; } else { treatedRcd.canExt = true; }
                    if (j == 4) { treatedRcd.canSea = false; } else { treatedRcd.canSea = true; }
                    if (j == 5) {
                        treatedRcd.cantOD = ['c', 'r', 'l', 'b'];
                    } else {
                        treatedRcd.cantOD = [];
                    }

                    toothPos.treatRecord = treatedRcd;
                    this.toothTreatPos.push(toothPos);
                }
            }
        }
    }

    /** 初始化牙歷圖 */
    setDefaultToothPos() {
        // 設定恆齒
        this.setNormalTeethHist();
        // 設定乳齒牙面
        this.setBabyTeethHist();
    }

    /** 設定恆齒治療牙圖 */
    setNormalTeethHist() {
        for (var i = 1; i <= 4; i++) {
            for (var j = 1; j <= 8; j++) {
                var pos = (i.toString() + j.toString());
                var facePos = pos;
                this.setToothFace(facePos, false);
            }
        }
    }

    /** 設定乳齒治療牙圖 */
    setBabyTeethHist() {
        for (var i = 5; i <= 8; i++) {
            for (var j = 1; j <= 5; j++) {
                var pos = (i.toString() + j.toString());
                var facePos = pos;
                this.setToothFace(facePos, true);
            }
        }
    }

    setToothFace(facePos: string, isBabyTeeth: boolean) {
        var extPos = facePos + "";
        if (isBabyTeeth) {
            facePos = (Number.parseInt(facePos) - 40).toString();
        }
        var tooth = this.toothTreatPos.find(x => x.posNo == extPos);
        var cantOD = tooth?.treatRecord?.cantOD ?? [];
        var canSea = tooth?.treatRecord?.canSea ?? [];
        var canENDO = tooth?.treatRecord?.canENDO ?? [];
        var canExt = tooth?.treatRecord?.canExt ?? [];
        if (cantOD.some(x => x == 'c')) {
            var celm = document.getElementById(facePos + 'c');
            celm.setAttribute('fill', '#F64E5F'); //'#F64E5F' : '#FCFCFD'
        }
        if (cantOD.some(x => x == 'r')) {
            var relm = document.getElementById(facePos + 'r');
            relm.setAttribute('fill', '#F64E5F'); //'#F64E5F' : '#FCFCFD'
        }
        if (cantOD.some(x => x == 'l')) {
            var lelm = document.getElementById(facePos + 'l');
            lelm.setAttribute('fill', '#F64E5F'); //'#F64E5F' : '#FCFCFD'
        }
        if (cantOD.some(x => x == 'u')) {
            var uelm = document.getElementById(facePos + 'u');
            uelm.setAttribute('fill', '#F64E5F'); //'#F64E5F' : '#FCFCFD'
        }
        if (cantOD.some(x => x == 'b')) {
            var belm = document.getElementById(facePos + 'b');
            belm.setAttribute('fill', '#F64E5F'); //'#F64E5F' : '#FCFCFD'
        }
        if (!canSea) {
            var selm = document.getElementById(facePos + 's');
            selm.setAttribute('fill-opacity', '100');
            selm.setAttribute('stroke-opacity', '100');
        }
        if (!canExt) {
            var eelm = document.getElementById('ext-' + extPos);
            eelm.setAttribute('fill-opacity', '100');
        }
        var firstCode = extPos.substring(0, 1);
        if (firstCode == '3' || firstCode == '4' || firstCode == '7' || firstCode == '8') {
            if (!canENDO) {
                var endoElm = document.getElementById('endo' + extPos);
                endoElm.setAttribute('fill-opacity', '100');
            }
        }
    }

    /** 計算畫面縮放比例 */
    getScaleRatio(): string {
        // let screenHeight = window.screen.height;
        let screenWidth = window.screen.width;
        let devicePixelRatio = window.devicePixelRatio;
        if (screenWidth < 1550 && devicePixelRatio > 1.0) {
          return 'scale(0.60, 0.40)';
        } else {
          return 'scale(0.80, 0.55)';
        }
    }

}
