<div class="w-100 abnormal-ic">
  <div>
    <mat-button-toggle-group name="abnormalStatus">
      <mat-button-toggle [checked]="true" (change)="onUnGotSerial($event)" i18n="@@RegWaiting">
        尚未取得就醫序號
      </mat-button-toggle>
      <mat-button-toggle [checked]="false" (change)="onGotSerial($event)" i18n="@@RegUpcoming">
        已取得就醫序號
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="card card-body no-padding inside-scrollbar">
    <form  class="vis-form p-3">
      <div class="row leave-bottom" style="max-height: 310px;">
        <mat-radio-group class="vis-form-field radio-align">
          <div *ngIf="isGotSerial">
            <mat-radio-button *ngFor="let isGetSerialCode of isGetSerialSystemCodeList"
            class="k-radio col-sm-6"
            [id]="isGetSerialCode.value"
            [value]="isGetSerialCode.extension"
            [checked]="isGetSerialCode.extension==AbnormalStatus"
            i18n="@@Yes"
            (change)="radioChange($event)">
              <div class="text-wrap">{{ isGetSerialCode.extension + ' ' + isGetSerialCode.text }}</div>
            </mat-radio-button>
          </div>
          <div *ngIf="unGotSerial">
            <mat-radio-button *ngFor="let unGetSerialCode of unGetSerialSystemCodeList"
            class="k-radio col-sm-6"
            [id]="unGetSerialCode.value"
            [value]="unGetSerialCode.value"
            [checked]="unGetSerialCode.value==this.AbnormalStatus"
            i18n="@@Yes"
            (change)="radioChange($event)">
            <div class="text-wrap">{{ unGetSerialCode.value +  ' ' + unGetSerialCode.text }}</div>
            </mat-radio-button>
          </div>
          <div class="pt-2" style="border-top: 2px dashed rgba(0,0,0,.125);">
            <mat-radio-button *ngFor="let exCode of exceptionSystemCodeList"
              class="k-radio col-sm-6"
              [id]="exCode.extension + exCode.value"
              [value]="exCode.extension + exCode.value"
              [checked]="exCode.checked"
              i18n="@@Yes"
              (change)="radioChange($event)">
              <div class="text-wrap">{{ exCode.extension +  ' ' + exCode.text }}</div>
              </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </form>
  </div>
</div>
<div class="w-100 text-center mt-3 mb-2">
<button type="button" class="easy-form-btn sumbit vis-btn-primary px-4 mb-0 vis-btn-capsule mx-1" (click)="btnOkClick()">確定</button>
<button type="button" class="easy-form-btn sumbit vis-btn-primary px-4 mb-0 vis-btn-capsule mx-1" (click)="btnCancelClick()">退出</button>
<button type="button" class="easy-form-btn sumbit vis-btn-primary px-4 mb-0 vis-btn-capsule mx-1" (click)="btnClearClick()">清除</button>
</div>
