<div style="display: flex;background-color: inherit;"
  [ngStyle]="{'max-width':maxWidth ? maxWidth:'initial'}"
  [ngClass]="customClass">
  <button type="button"
    (click)="goLastDay()"
    *ngIf="showLastDateButton"
    class="m-0 text-xs btn-grid p-0 w-6 mr-2px">
    <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="上一日">keyboard_arrow_left</mat-icon>
  </button>
  <button type="button"
    (click)="goNextDay()"
    *ngIf="showNextDateButton"
    class="m-0 text-xs btn-grid p-0 w-6">
    <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="下一日">keyboard_arrow_right</mat-icon>
  </button>
  <div class="app-v-dateinput controlled-font-size-middle v-control"
      [class.v-disabled]="disabled||_disabled"  (click)="openPickerOnClick?openPicker():null"
      style="display: flex;;padding: 1px 2px 1px 2px;width: 100%;line-height: 21px;margin-top: 0px;" >
      <input matInput [matDatepicker]="picker" (dateChange)="onDateChanged($event)" [min]="min" [max]="max" [disabled]="disabled"
      style="position: absolute; visibility: hidden;padding: 0px;margin: 0px;">
      {{prefix}}
      <input type="tel"
            autocomplete="off"
            class="year"
            [disabled]="disabled||_disabled" style="border: none;text-align: center; max-width: 37px; " maxlength="4" placeholder="年"
            (keyup)="yearUp($event)"
            (keydown)="yearDown($event)"
            (blur)="onblur($event)"
            (focus)="onfocus($event)"
            #year/>
      <span style="color: #aaaaaa;">/</span>
      <input type="tel"
            autocomplete="off"
            [disabled]="disabled||_disabled"
            style="border: none;max-width: 20px;"
            maxlength="2" placeholder="月"
            (keyup)="monthUp($event)"
            (keydown)="monthDown($event)"
            (blur)="onblur($event)"
            (focus)="onfocus($event)"
            #month/>
      <span style="color: #aaaaaa;" *ngIf="format!=='MM yyyy'">/</span>
      <input type="tel"
            autocomplete="off"
            [disabled]="disabled||_disabled"
            style="border: none;max-width: 20px;"
            maxlength="2"
            placeholder="日"
            *ngIf="format!=='MM yyyy'"
            (keyup)="dayUp($event)"
            (keydown)="dayDown($event)"
            (focus)="onfocus($event)"
            (change)="onchange($event)"
            #day/>
  </div>
  <button
    type="button"
    (click)="openPicker()"
    tabindex="-1"
    [disabled]="disabled||_disabled"
    class="m-0 text-xs btn-grid w-6 mr-2px"
    style="padding: 0 4.5px;">
    <mat-icon class="vis-combine-icon d-inline-flex mr-1 text-sm lh-base" title="日曆">date_range</mat-icon>
  </button>
  <button
    type="button"
    *ngIf="showToday"
    style="min-width: 25px;"
    [disabled]="disabled||_disabled"
    class="btn-grid text-center p-0 text-base ml-0"
    (click)="onBtnTodayClick()"
    title="Today">
    今
  </button>
  <!-- <mat-datepicker-toggle style="height: 20px;width: 20px;" matSuffix [for]="picker"></mat-datepicker-toggle> -->
  <mat-datepicker
    [touchUi]="false"
    [panelClass]="panelClass"
    [startView]="startView"
    #picker>
  </mat-datepicker>
</div>
