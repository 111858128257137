import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RegisterHistAnimationStyle, RegisterHistAreaState } from 'src/app/models/RegisterHistAnimationModel';
import { RegHistListDto, RegisterApi } from 'src/app/services/api-service/register/register-api';
import { RegisterResizeService } from 'src/app/services/app-service/register/register-resize.service';
import { BaseConfig } from 'src/app/services/base-config.service';
import { GridData } from '../../grid/vis-grid/vis-grid.component';
import { HistViewComponent } from '../../hist/hist-view/hist-view.component';
import { RegisterPeriodItemDTO } from 'src/app/services/api-service-dentist/register/register-api.model';
import { PatientRemarkDialogComponent } from '../../patients/patient-remark-dialog/patient-remark-dialog.component';

@Component({
  selector: 'app-register-history',
  templateUrl: './register-history.component.html',
  styleUrls: ['./register-history.component.css'],
  animations: RegisterHistAnimationStyle
})
export class RegisterHistoryComponent implements OnInit {
  @ViewChild('histViewComp')
  private histViewComp: HistViewComponent;

  @Input() patientId: number;
  isCancelRegister: boolean = false;

  @Input() spRemark: string = "";
  @Output() spRemarkChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() periodItems: RegisterPeriodItemDTO[] = [];

  @Input() enabled: boolean = true;

  @ViewChild(PatientRemarkDialogComponent) patientRemarkDialogComp: PatientRemarkDialogComponent;

  /** 病歷區選單，A=一般看診、B=同療區域 */
  histAreaA: string = RegisterHistAreaState.idle;
  histAreaB: string = RegisterHistAreaState.ready;
  switchPatientRemarkDialog: boolean = false;

  histSourceData: RegHistListDto[] = [];
  histGrid: GridData;

  histGridPageSize: number = BaseConfig.getConfig().ui.pageSize;
  histGridPageSkipNo = 0;

  maxCCOpen: boolean = false;
  maxRmOpen: boolean = false;
  maxRegMenuOpen: boolean = false;

  registerHistAreaState = RegisterHistAreaState;

  constructor(public registerResizeService: RegisterResizeService,
    private registerApi: RegisterApi) {
  }

  ngOnInit(): void {
    this.refreshList();

  }

  handleHistAreaChange(area: string) {
    if (area === "A") {
      this.histAreaA = RegisterHistAreaState.idle;
      this.histAreaB = RegisterHistAreaState.ready;
    } else {
      this.histAreaA = RegisterHistAreaState.ready;
      this.histAreaB = RegisterHistAreaState.idle;
    }

  }

  histGridCellClickHandler({ dataItem }) {
    const data = dataItem as RegHistListDto;
    this.histViewComp.selectHist(data.RegId);
  }

  handleCCToggle() {
    this.maxCCOpen = !this.maxCCOpen;
  }

  handleRmToggle() {
    this.maxRmOpen = !this.maxRmOpen;
  }

  async refreshList() {
    this.histSourceData = await this.registerApi.GetHistoryList(this.patientId);

    this.histGrid = {
      data: this.histSourceData,
      total: this.histSourceData.length
    };
  }

  onSpRemarkChange(val: string) {
    this.spRemarkChange.emit(val);
  }

  onBtnRemarkClick() {
    if (!this.enabled) {
      return;
    }
    this.patientRemarkDialogComp.reloadData(this.spRemark);
    this.patientRemarkDialogComp.cleanData();
    this.switchPatientRemarkDialog = true;
  }

  getRemarkWindowResult(result: any) {
    this.switchPatientRemarkDialog = false;

    if (result && result.text) {
      this.spRemark = result.text;
      this.onSpRemarkChange(this.spRemark);
    }
  }
}
