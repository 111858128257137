import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GridData } from '../../grid/vis-grid/vis-grid.component';
import { RegisterPeriodItemDTO } from 'src/app/services/api-service-dentist/register/register-api.model';

@Component({
  selector: 'app-periodic-grid',
  templateUrl: './periodic-grid.component.html',
  styleUrls: ['./periodic-grid.component.css']
})
export class PeriodicGridComponent implements OnInit {
  @Input() showStickyTableRows: number = null;
  @Input() showSmallerTitle: boolean = false;
  @Input() title: string;
  @Input() pageSize: number = 10;
  @Input() pageSkipNo: number = 0;
  @Input() width: string = '100%';
  @Input() set data(value: RegisterPeriodItemDTO[]) {
    this._data = value;
    this.pageChange(1); // 每次設定時執行
  }

  get data(): RegisterPeriodItemDTO[] {
    return this._data;
  }

  private _data: RegisterPeriodItemDTO[] = [];
  gridView: GridData;
  selectedItems: number[] = [];

  constructor() { }

  ngOnInit(): void {
    this.pageChange(1);
  }

  pageChange(page: number): void {
    const startIndex = (page - 1) * this.pageSize;
    this.pageSkipNo = startIndex;
    var pageData = [];
    if (this.data) {
      pageData = this.data?.slice(startIndex, startIndex + this.pageSize);
    }

    this.gridView = {
      data: pageData,
      total: this.data?.length ?? 0
    };
  }
}
