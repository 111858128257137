<div class="top-bar">
  <div class="top-main">
    <div class="top-left-btn" (click)="onTopLeftBtnClick()">
      <img>
    </div>
    <div class="top-logo">
      <img>
    </div>
      <div *ngIf="companyName!=clinicName" class="top-company" (click)="redirectTo(this.logopath,this.logopathfg)">
        <span class="custom-logo mr-1 -top-2">
          <app-icon-vis-logo></app-icon-vis-logo>
        </span>
        {{companyName}}
      </div>
      <div class="top-clinic" (click)="redirectTo(this.logopath,this.logopathfg)">{{clinicName}}</div>
      <div class="top-clinic">
        {{'&nbsp;&nbsp;院所代號:&nbsp;' + clinicCode + '&nbsp;&nbsp;服務電話:&nbsp;' + customerServiceExt}}
      </div>
    <!--right-->
    <div class="top-right-logo">
      <img>
    </div>
    <div class="top-user-action" style="display: flex;align-items: center;column-gap: 8px;">
      <div class="top-right-btn">
        <img>
      </div>

      <div class="top-version">{{version}}</div>
      <!-- <mat-icon style="width: 18px;height: 18px;font-size: 18px;vertical-align: middle;margin-right:5px">
        {{(vpnState?'wifi':'wifi_off')}}
      </mat-icon> -->

      <mat-icon (contextmenu)="cacheReload($event)" style="width: 18px;height: 18px;font-size: 18px;vertical-align: middle;" [title]="isConn?'伺服器連線正常':'與伺服器中斷連線'">
        {{(isConn?'wifi':'wifi_off')}}
      </mat-icon>
      <div style="cursor: pointer;" (dblclick)="Ping_VPN()" [title]="'VPN:'+vpnState+', 雙擊重新偵測狀態'">
        <div class="vpn-icon" [ngClass]="[vpnState=='Fail'?'off':vpnState=='Slow'?'slow':'']">
          VPN
          <mat-icon class="refresh" *ngIf="pingWait;else els">
            refresh
          </mat-icon>
          <ng-template #els>
            <mat-icon>
              {{vpnState=='Fail'?'close':vpnState=='Slow'?'warning':''}}
            </mat-icon>
          </ng-template>
        </div>
      </div>
      <app-console-icon></app-console-icon>
      <app-debug-tool *ngIf="IsDebug"></app-debug-tool>
      <app-printer></app-printer>
      <app-chat *ngIf="IsDebug"></app-chat>
      <button mat-button [matMenuTriggerFor]="popup" (contextmenu)="openMenu2($event)" >{{userName}}</button>
    </div>

  </div>
  <div class="top-menu-area" [hidden]="!isShowMenu" *ngIf="isUseTopMenu">
    <app-menu></app-menu>
  </div>
</div>


<!-- #enddocregion mat-menu-trigger-for -->
<div>
  <mat-menu (close)="closeMenu()" #popup="matMenu" yPosition="below" xPosition="before">
    <ng-container *ngIf="showHiddenModePwd;else elsTmp">
      <button mat-menu-item (click)="openSPwdInput()" style="color:black;">
        <mat-icon>stars</mat-icon>
        <span i18n="@@ChangeClinic">{{userName}}</span>
      </button>
    </ng-container>
    <ng-template #elsTmp>
      <button mat-menu-item disabled style="color:black;">{{userName}}</button>
    </ng-template>

    <button mat-menu-item (click)="openSettingDialog()">
      <mat-icon>settings</mat-icon>
      <span i18n="@@ChangeClinic">設定</span>
    </button>
    <button mat-menu-item (click)="onPassword()">
      <mat-icon>key</mat-icon>
      <span i18n="@@Password">密碼</span>
    </button>
    <button mat-menu-item (click)="openChangeClinicDialog()">
      <mat-icon>location_on</mat-icon>
      <span i18n="@@ChangeClinic">變更診所</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onLogout()">
      <mat-icon>logout</mat-icon>
      <span i18n="@@Logout">登出</span>
    </button>
  </mat-menu>
</div>

<!-- 更換Clinic -->
<app-vis-dialog title="變更診所" [show]="isOpenChangeClinicDialog" (close)="closeChangeClinicDialog()" [width]="500"
  [height]="400" i18n-title="@@ChangeClinic">
  <div class="change-clinic-desc" i18n="@@SelectClinicToChange">選擇診所</div>
  <div [ngClass]="{'change-clinic-row': true, 'change-clinic-current': isCurrentClinicId(cln.Id) }"
    (click)="onClickClinic(cln.Id)" *ngFor="let cln of allowedClinics">
    {{ cln.Name }}
    <ng-container *ngIf="cln.Id === currentClinicId" i18n="@@Current">(目前)</ng-container>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="closeChangeClinicDialog()" primary="true" i18n="@@Close">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

