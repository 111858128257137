import { Hist } from "src/app/hist/models/hist";
import { WholeHist, WholeHistPatientsDto, WholeHistRegisterDto } from "src/app/services/api-service/hist/whole-hist";
import { DrugBagInfo, ReceiptInfoDto, RepeatedPrescriptionDto } from "src/app/services/api-service/hist/whole-large-hist";
import { NHIRegIC } from "src/app/services/api-service/register/nhiRegIc";
import { Rx } from "src/app/services/api-service/rx/rx";
import { UserCache } from "src/app/services/user-cache";
import { ValueTextPair } from "src/app/shared/models/value-text-pair";
import { PRN001Params } from "src/app/services/api-service/parameters/PRN001Params";

import { CidMaskPipe } from "src/app/shared/pipes/cid-mask.pipe";
import { DateHelper } from "src/app/shared/helpers/date-helper";
import { RHDesc, RHEnum } from "src/app/enums/RHEnum";
import { BloodDesc, BloodEnum } from "src/app/enums/BloodEnum";
import { ClinicTypeDesc } from "src/app/enums/ClinicTypeEnum";
import { NHILevelDesc } from "src/app/enums/NHILevelEnum";
import { MedicalDesc } from "src/app/enums/MedicalEnum";
import { ValueTextPairBundle } from "src/app/services/data-service/clinic-data-service";
import { HistEditOption } from "src/app/services/api-service/hist/hist-edit-option";
import { RegistEditOpt } from "src/app/services/api-service/register/register-api";
import { HistOrder } from "src/app/hist/models/hist-order";
import { AgePipe } from "src/app/shared/pipes/age.pipe";
import { FormatRocDatePipe } from "src/app/shared/pipes/format-date-roc.pipe";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { ReceiptOwnFees } from "../../components/receipt/receipt.component";
import { group } from '@angular/animations';

export interface ICmsRecipt{
    style?: RepeatPresStyle;
}

export class cmsRepeatPres implements ICmsRecipt {
    code:string;
    pwd?:string;
    width: string;
    height: string;
    padding:string;
    isHelf: boolean;
    style?: RepeatPresStyle;
    rowsBeforeRx: RepeatPresRow[];
    rowsOfRx:RepeatPresRow[];
    rowsAfterRx: RepeatPresRow[]
    /**
     *
     */
    constructor() {
        this.width = '';
        this.height = '';
        this.isHelf = false;
        this.style = null;
        this.rowsBeforeRx = [];
        this.rowsAfterRx = [];
    }
}
export class RepeatPresRow implements ICmsRecipt {
    style?: RepeatPresStyle;
    groups: RepeatPresGroup[];
    /** 自動換行 */
    wrap?:boolean;
    overflowHidden?:boolean;
    rowWidth?:number;
    spacing?:number;
    constructor() {
        this.groups = []
    }
}
export class RepeatPresGroup  implements ICmsRecipt{
    /** 欄位名稱 */
    Cols: RepeatPresCol[];
    style?: RepeatPresStyle;
    groupType:'A'| 'B' | 'C'|'';
    constructor() {
        this.Cols = []
    }
}

export class RepeatPresCol  implements ICmsRecipt{
    /** 欄位名稱 */
    label: string;
    /** 靜態文字 資料前 */
    textBefore?: string;
    /** 靜態文字 資料後 */
    textAfter?: string;
    isBarCode?:boolean;
    isQRcode?:boolean;
    /** 資料類型 */
    dataSource?: string;
    /** 資料綁定 */
    dataField?: string;
    style?: RepeatPresStyle;
    lineheight?: number;
    constructor() {
        this.label = '';
        this.textBefore = '',
        this.textAfter = '';
        this.dataSource = '';
        this.isBarCode= false;
        this.isQRcode =false;
    }
}
export class RepeatPresStyle {
    fontSize?: number;
    fontFamily?: string;
    textAlign?: string;
    padLeft?: number;
    rowHeight?: number;
    width?:number;
    textOverflow?:string;
    isBlock?:boolean;
    isNotBorder?:boolean;
    border?:number;
    borderBottom?:number;
    borderTop?:number;
    borderLeft?:number;
    borderRight?:number;
    lineheight?: number;
}
export class RepeatPresSetDto{
    BackRegDate:string;
    DeadLine:string;
    StartStr:string[]= [];
    EndStr:string[]=[];
    IsNormal:boolean = true;
    IsPres:boolean = false;
}
export class CmsLayout{
    width:string;
    height:string;
    rows:number;
    cols:number;
    contents:string[];
  }
export var RepeatDataSourceClinic :ValueTextPair[]=[
    {text:'客代',value:'Code'},
    {text:'名稱',value:'Name'},
    {text:'地址(全)',value:'Address'},
    {text:'縣市',value:'City'},
    {text:'區域',value:'Area'},
    {text:'街道',value:'Street'},
    {text:'郵遞區號',value:'ZipCode'},
    {text:'電話',value:'Phone'},
    {text:'E-Mail',value:'Email'},
    {text:'傳真',value:'Fax'},
    {text:'醫事機構代碼',value:'NHICode'},
    {text:'負責人',value:'OwnerName'},
    {text:'科別',value:'MedDeptCode'},
    {text:'院所層級',value:'NHILevel',extension:(a:RepeatData,value:any)=>NHILevelDesc.find(x=>x.value==value)?.text??''},
    {text:'特約類別',value:'NHIType',extension:(a:RepeatData,value:any)=>MedicalDesc.find(x=>x.value==value)?.text??''},
    {text:'診所類別',value:'Type',extension:(a:RepeatData,value:any)=>ClinicTypeDesc.find(x=>x.value==value)?.text??''},
]

export var RepeatDataSourcePatient :ValueTextPair[]=[
    {text:'病歷號',value:'PatientNo'},
    {text:'中文名',value:'CName'},
    {text:'身分證',value:'CIdMasked',extension:(a:RepeatData,value:string)=>new CidMaskPipe().transform(a.Patient.CId, a.PRN001.IDHidePRN)},
    {text:'身分證(無碼)',value:'CId'},
    {text:'生日',value:'BirthdayMasked',extension:(a:RepeatData,value:Date)=> new FormatRocDatePipe().transform(a.Patient.Birthday,false,a.PRN001.BirthdayHidePRN)},
    {text:'生日(無碼)',value:'Birthday',extension:(a:RepeatData,value:Date)=>DateHelper.getROCDateString(new Date(value))},
    {text:'年齡',value:'Age',extension:(a:RepeatData,value:Date)=> new AgePipe().transform(a.Patient.Birthday)},
    {text:'性別名稱',value:'SexName'},
    {text:'國籍',value:'Country'},
    {text:'食物過敏',value:'FoodAllergy'},
    {text:'藥物過敏',value:'DrugAllergy'},
    {text:'病史',value:'DiseaseHistory'},
    {text:'病史註記',value:'DiseaseHistoryRemark'},
    {text:'註記',value:'Remark'},
    {text:'電話',value:'Phone'},
    {text:'電話區碼',value:'PhoneArea'},
    {text:'手機',value:'CellPhone'},
    {text:'戶籍地址 郵遞區號',value:'ResZipCode'},
    {text:'戶籍地址 城市',value:'ResCity'},
    {text:'戶籍地址 區域',value:'ResArea'},
    {text:'戶籍地址 街道',value:'ResStreet'},
    {text:'居住地址 郵遞區號',value:' ZipCode'},
    {text:'居住地址 城市',value:' City'},
    {text:'居住地址 區域',value:' Area'},
    {text:'居住地址 街道',value:' Street'},
    {text:'保險身份',value:'ICode'},
    {text:'血型',value:'BlType',extension:(a:RepeatData,value:any)=>BloodDesc.find(x=>x.value==value)?.text??''},
    {text:'RH值',value:'BlRH',extension:(a:RepeatData,value:any)=>RHDesc.find(x=>x.value==value)?.text??''},
    {text:'職業',value:'JobCode'},
    {text:'教育程度',value:'EducationCode'},
]
export var RepeatDataSourceRegister :ValueTextPair[]=[
    {text:'候診序號',value:'WaitNo'},
    {text:'看診日期',value:'RegDate',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value,true,false)},
    {text:'報到日期時間',value:'CheckinTime',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDateTime(value)},
    {text:'開始日期時間',value:'BeginTime',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDateTime(value)},
    {text:'完診日期時間',value:'EndTime',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDateTime(value)},
    {text:'保險身分',value:'ICodeName'},
    {text:'收案別',value:'ClassName'},
    {text:'科別',value:'MedDeptCode'},
    {text:'科別名稱',value:'MedDeptName',extension:(a:RepeatData,value:string)=>a.regOptions.section.find(x => x.value ==a.Register.MedDeptCode)?.text||value},
    {text:'就診狀態名稱',value:'StatusName'},
    {text:'就醫憑證',value:'ITyp'},
    {text:'同一療程',value:'SameTreat'},
    {text:'醫師身分證',value:'DoctorCIdMasked',
        extension:(a:RepeatData,value:string)=>new CidMaskPipe().transform(a.Register.DoctorCId,a.PRN001.IDHidePRN)},
    {text:'醫師身分證(無碼)',value:'DoctorCId'},
    {text:'醫師名稱',value:'DoctorName'},
    {text:'健保就醫類別',value:'Nhictp'},
    {text:'健保卡年度',value:'Nhiyear'},
    {text:'就醫序號',value:'Icno'},
    {text:'診間名稱',value:'RoomName'},
    {text:'時段別',value:'TimeSecName'},
    {text:'付款狀態',value:'PaymentStatusName'},
    {text:'備註',value:'Remar'},
    {text:'就診來源 名',value:'VisitTypeName'},
    {text:'掛號來源名稱',value:'RegSourceName'},
    {text:'欠卡日期',value:'LackCardDate',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value)},
    {text:'還卡日期',value:'ReCardDate',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value)},
    {text:'折扣代碼',value:'DiscountCode'},
    {text:'自費額',value:'OwnFee'},
    {text:'掛號',value:'RegisterFee'},
    {text:'押金',value:'LAMT'},
]

export var RepeatDataSourceHist :ValueTextPair[]=[
    {text:'主訴',value:'CC'},
    {text:'理學',value:'PE'},
    {text:'主診斷',value:'Dx1'},
    {text:'主診斷名',value:'Dx1Name'},
    {text:'次診斷',value:'Dx2'},
    {text:'次診斷名',value:'Dx2Name'},
    {text:'診斷3',value:'Dx3'},
    {text:'次診斷名3',value:'Dx3Name'},
    {text:'診斷4',value:'Dx4'},
    {text:'次診斷名4',value:'Dx4Name'},
    {text:'診斷5',value:'Dx5'},
    {text:'次診斷名5',value:'Dx5Name'},
    {text:'診斷6',value:'Dx6'},
    {text:'次診斷名6',value:'Dx6Name'},
    {text:'主手術',value:'Sur1'},
    {text:'主手術名',value:'SurName'},
    {text:'次手術1',value:'Sur2'},
    {text:'次手術2',value:'Sur3'},
    {text:'藥師身分證',value:'PharmacistCId'},
    {text:'藥師姓名',value:'PharmacistName'},
    {text:'領藥號',value:'PickUpDrugNo'},
    {text:'醫師身分證(無碼)',value:'DoctorCId'},
    {text:'醫師身分證',value:'DoctorCIdMasked',
        extension:(a:RepeatData,value:string)=>new CidMaskPipe().transform(a.Hist.DoctorCId,a.PRN001.IDHidePRN)},
    {text:'就醫日期',value:'Date',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value,false,true)},
    {text:'同療程開始日期',value:'DateStart',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value)},
    {text:'同療程結束日期',value:'DateEnd',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value)},
    {text:'療程類別',value:'CusTP'},
    {text:'療程次別',value:'CusNo'},
    {text:'療程總次',value:'CusTot'},
    {text:'療程分類',value:'CusCl'},
    {text:'健保卡卡序',value:'Isqno'},
    {text:'原處方卡序(藥局用)',value:'IsqnoOrig'},
    {text:'勞保職業傷病',value:'LABTP'},
    {text:'總申報天數',value:'Days'},
    {text:'科別',value:'Dept'},
    {text:'初複',value:'Emer',extension:(a:RepeatData,value:any)=>value==0?'初診':'複診'},
    {text:'T轉入, Y轉出, N非轉',value:'Referral',extension:(a:RepeatData,value:any)=>value=='T'?'轉入':value=='Y'?'轉出':'非轉'},
    {text:'轉出醫院',value:'RefHosp'},
    {text:'轉入醫院',value:'InHosp'},
    {text:'開立處方單位',value:'RxHosp'},
    {text:'檢驗交付單位',value:'LabHosp'},
    {text:'給付類別',value:'PayTP'},
    {text:'特定治療1',value:'SPTX1'},
    {text:'特定治療2',value:'SPTX2'},
    {text:'特定治療3',value:'SPTX3'},
    {text:'特定治療4',value:'SPTX4'},
    {text:'案件分類',value:'Case'},
    {text:'調劑方式',value:'DispTP'},
    {text:'診察費',value:'ADx'},
    {text:'藥費',value:'ARx'},
    {text:'內服藥費',value:'ARx2'},
    {text:'外用藥費',value:'ARx3'},
    {text:'注射藥費',value:'ARx4'},
    {text:'診療費',value:'ATx'},
    {text:'注射技術費',value:'ATx5'},
    {text:'檢驗費',value:'ATx6'},
    {text:'放射費',value:'ATx7'},
    {text:'復健治療費',value:'ATx8'},
    {text:'處置費',value:'ATx9'},
    {text:'手術費',value:'ATx10'},
    {text:'麻醉費',value:'ATx11'},
    {text:'材料費',value:'AMat'},
    {text:'輸血費',value:'ATx13'},
    {text:'透析費',value:'ATx14'},
    {text:'病房費',value:'ATx15'},
    {text:'管灌費',value:'ATx16'},
    {text:'嬰兒費',value:'ATx17'},
    {text:'藥事費',value:'AMed'},
    {text:'包裹實批',value:'APack'},
    {text:'其他費1',value:'A01'},
    {text:'其他費2',value:'A02'},
    {text:'健保總額',value:'ATot'},
    {text:'部份負擔代號',value:'ApartCode'},
    {text:'部份負擔-藥品',value:'APartRx'},
    {text:'藥局部份負擔-藥品',value:'PPartRx'},
    {text:'部份負擔-門診',value:'APartOPD'},
    {text:'部份負擔-檢查',value:'APartExam'},
    {text:'自費總額',value:'AOwn'},
    {text:'申請總額(健保總額-部分負擔)',value:'ADcl'},
    {text:'論件計酬',value:'Drgs'},
    {text:'慢箋次別',value:'CntPresNo'},
    {text:'慢箋總次',value:'CntPresTot'},
    {text:'慢箋開始日',value:'CntPresDate',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value)},
    {text:'欠卡讀取的就醫識別碼',value:'LackTreatnum'},
    {text:'↑讀取時間(欠卡讀取的就醫識別碼)',value:'LackDate',extension:(a:RepeatData,value:Date)=>DateHelper.formatROCDate(value)},
    {text:'免部分負擔原因',value:'ApartCodeDescription'},
]
export var RepeatDataSourceFee :ValueTextPair[]=[
    {text:'自費藥品',value:'OwnRx'},
    {text:'自費注射',value:'OwnTx5'},
    {text:'自費檢驗檢查',value:'OwnTx67'},
    {text:'自費特殊A30費',value:'OwnTx30'},
    {text:'自費特殊A31費',value:'OwnTx31'},
    {text:'自費特殊A32費',value:'OwnTx32'},
    {text:'其他自費',value:'OwnOther'},
    {text:'總計',value:'Total',extension:(a:RepeatData,value:any)=>
            a.Register.RegisterFee + a.Register.OwnFee + a.Hist.APartExam + (a.Hist.APartRx*-1) + a.Hist.AOwn + a.Register.LAMT},
    {text:'總計(不含押金)',value:'TotalNoLamt',extension:(a:RepeatData,value:any)=>
            a.Register.RegisterFee + a.Register.OwnFee + a.Hist.APartExam + (a.Hist.APartRx*-1) + a.Hist.AOwn},
]
export var ReceiptDataSourceIC :ValueTextPair[]=[
    {text:'就醫識別碼',value:'Itreatnum'},
    {text:'刷卡時間',value:'Icdt'},
    {text:'原處方就醫時間',value:'IRegDT'},

]
/** 醫令可用資料，加工方法在extension設定 (a:RepeatData,value:any,hrx:HistOrder)=>any */
export var RepeatDataSourceHRx :ValueTextPair[]=[
    {text:'N標',value:'SPRule',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> value==0?'':value},
    {text:'ATC',value:'ATC'},
    {text:'院內碼',value:'RxCode'},
    {text:'健保碼',value:'StdCode'},
    {text:'產品名',value:'ProdName'},
    {text:'中文名',value:'ChineseName'},
    {text:'成分名',value:'IIName',
        extension:(a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.IIName??''},
    {text:'通用名',value:'GXName',
        extension:(a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.GXName??''},

    {text:'次量',value:'Dose'},
    {text:'日量',value:'DailyDose'},
    {text:'總量',value:'TotalDose'},
    {text:'小計',value:'TotalPrice',extension:(a:RepeatData,value:any,hrx:HistOrder)=>(hrx.IPrice*hrx.TotalDose).toFixed(1) },

    {text:'頻率',value:'Freq'},
    {text:'頻率名',value:'FreqName',
        // 沒有freqName這個欄位，value會是空直，要從hrx抓
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Options.dosage.find(x=>x.Code==hrx.Freq)?.Name||hrx.Freq },
    {text:'日份',value:'Days'},
    {text:'途徑',value:'Way'},
    {text:'途徑名',value:'WayName',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Options.way.find(x=>x.Code==hrx.Way)?.Name||hrx.Way },

    {text:'單位',value:'Unit'},
    {text:'單位(總量)',value:'BoxUnit'},
    {text:'備註',value:'Remark'},
    
    {text:'副作用',value:'SideEffects',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.SideEffects??'' 
    },
    {text:'適應症',value:'Indications',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.Indications??'' 
    },
    {text:'其他指示',value:'OtherInstructions',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.OtherInstructions??'' 
    },    
    {text:'臨床用途',value:'ClinicalWay',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.ClinicalWay??'' 
    },
    {text:'外觀',value:'Exterior',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.Exterior??'' 
    },
    {text:'警語',value:'Warnings',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.Warnings??'' 
    },
    {text:'重要資訊',value:'ImportantTip',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.ImportantTip??'' 
    },
    {text:'保存方式',value:'StorageMethod',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.StorageMethod??'' 
    },
    {text:'效期',value:'ValidityPeriod',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.ValidityPeriod??'' 
    },
    {text:'製造商',value:'ManufacturerName',
        extension: (a:RepeatData,value:any,hrx:HistOrder)=> a.Rx.find(x=>x.RxCode==hrx.RxCode)?.ManufacturerName??'' 
    },
    
    {text:'健保價',value:'IPrice'},
    {text:'自費價',value:'Price'},
    {text:'點數',value:'Tot'},
    {text:'差額',value:'DiffPrice'},
    {text:'加成',value:'Plus'},
    

    {text:'藥品批號',value:'LotNo'},
    {text:'支付類別',value:'PaymentType'},
    {text:'調劑',value:'DispTP'},
    {text:'歸屬',value:'RxType'},
    {text:'排序',value:'Sort'},

  
  
//   {text:'',value:'Dept'},
//   {text:'',value:'MedIDName'},
//   {text:'',value:'BeginDate'},
//   {text:'',value:'EndDate'},
//   {text:'',value:'SDate'},
//   {text:'',value:'STime'},
//   {text:'',value:'EDate'},
//   {text:'',value:'ETime'},
  
  
//   {text:'',value:'TransOri'}, // 委託或受託機構代號 (p24)
]

    export var RepeatDataSourceSP :ValueTextPair[]=[
        {text:'一般處方箋',value:'NormalCheck'},
        {text:'連續處方箋',value:'CntPresCheck'},
        {text:'第一次調劑日期(起)',value:'FirstPresDate'},
        {text:'第二次調劑日期(起)',value:'SecondPresDate'},
        {text:'第二次調劑日期(迄)',value:'SecondPresEndDate'},
        {text:'第三次調劑日期(起)',value:'ThirdPresDate'},
        {text:'第三次調劑日期(迄)',value:'ThirdPresEndDate'},
        {text:'第四次調劑日期(起)',value:'FourthPresDate'},
        {text:'第四次調劑日期(迄)',value:'FourthPresEndDate'},
        {text:'下次回診日',value:'NextRegDate'},
        {text:'截止日',value:'DeadLine'}
        ]
export var RepeatDataSourceType :ValueTextPair[]=[
    {text:'診所',value:'Clinic',extension:RepeatDataSourceClinic},
    {text:'病患',value:'Patient',extension:RepeatDataSourcePatient},
    {text:'掛號',value:'Register',extension:RepeatDataSourceRegister},
    {text:'病歷',value:'Hist',extension: RepeatDataSourceHist},
    {text:'自費',value:'Fee',extension:RepeatDataSourceFee},
    {text:'醫令',value:'HRx',extension:RepeatDataSourceHRx},
    {text:'刷卡',value:'NHIRegIC',extension:ReceiptDataSourceIC},  
    // {text:'藥袋',value:'DrugBag',extension:RepeatDataSourceDrugBag},
    {text:'特殊',value:'SP',extension:RepeatDataSourceSP}

]
export class RepeatData{
    Clinic:Clinic;
    Patient: WholeHistPatientsDto;
    Register: WholeHistRegisterDto;
    Hist: Hist;
    NHIRegIC:NHIRegIC;
    Fee:ReceiptOwnFees;
    Rx:Rx[];
    PRN001:PRN001Params;
    RepeatPresSet:RepeatPresSetDto;
    // DrugBag:{
    //     /** 袋數 */
    //     Bag:number;
    //     /** 總袋數 */
    //     TotalBag:number;
    //     /** 下次回診日 */
    //     NextRegDate:string;
    //     /** 下次領藥日 */
    //     NextDrugDate:string;
    // }
    
    /**
     *
     */
    constructor(info:RepeatedPrescriptionDto,p1:PRN001Params,
        public Options:HistEditOption,
        public regOptions:RegistEditOpt,regSet :RepeatPresSetDto) {
        this.Clinic = UserCache.getLoginUser().Clinic;
        this.Patient = info.WholeHist.Patient;
        this.Register = info.WholeHist.Register;
        this.Hist = info.WholeHist.Hist;
        this.NHIRegIC = info.NHIRegIC;
        this.Fee = new ReceiptOwnFees(info.WholeHist)
        this.PRN001 = p1;
        this.RepeatPresSet = regSet;
        // 慢箋非最後一次會印下次領藥日期，最後一次才印下次回診日
      
    }
    getHrx(){
        return this.Hist.HRxs;
    }
    getData(type:string,field:string){
        var sourceObj = this[type];
        if (field == 'NormalCheck'){
        }
        if(!sourceObj  &&  !PresSPConst.all.includes(field)){
            return '';
        }
        else if ( PresSPConst.all.includes(field)){
            if(this.Register && field == PresSPConst.NormalCheck && this.RepeatPresSet.IsNormal){
                return 'V';
            }
            else if (this.RepeatPresSet.IsPres && this.Register && this.RepeatPresSet){

                if(field == PresSPConst.NormalCheck){
                    return '';
                }
                else if(field == PresSPConst.CntPresCheck){
                    return 'V';
                }
                else if (field == PresSPConst.FirstPresDate  && this.RepeatPresSet.StartStr[0]){
                    return this.RepeatPresSet.StartStr[0]
                }
                else if (field == PresSPConst.SecondPresDate && this.RepeatPresSet.StartStr[1]){
                    return this.RepeatPresSet.StartStr[1]
                }
                else if (field == PresSPConst.SecondPresEndDate && this.RepeatPresSet.EndStr[1]){
                    return this.RepeatPresSet.EndStr[1]
                }
                else if (field == PresSPConst.ThirdPresDate  && this.RepeatPresSet.StartStr[2]){
                    return this.RepeatPresSet.StartStr[2]
                }
                else if (field == PresSPConst.ThirdPresEndDate && this.RepeatPresSet.EndStr[2]){
                    return this.RepeatPresSet.EndStr[2]
                }
                else if (field == PresSPConst.FourthPresDate && this.RepeatPresSet.StartStr[3]){
                    return this.RepeatPresSet.StartStr[3]
                }
                else if (field == PresSPConst.FourthPresEndDate && this.RepeatPresSet.EndStr[3]){
                    return this.RepeatPresSet.EndStr[3]
                }
                else if (field == PresSPConst.NextRegDate) return this.RepeatPresSet.BackRegDate;
                else if (field == PresSPConst.DeadLine) return this.RepeatPresSet.DeadLine;
            }
            return '';
        }
        var value = sourceObj[field];

        var fieldDefines:ValueTextPair[] = RepeatDataSourceType.find(x=>x.value==type)?.extension
        var modifier = fieldDefines?.find(x=>x.value==field)?.extension
        if(modifier){
            value = modifier(this,value)
        }

        if(this.Register && field == 'NormalCheck'){
            value = 'V';
        }
        return value;
    }
    getHrxData(i:number,field:string){
        var sourceObj = this.Hist.HRxs[i];
        var value = sourceObj[field];
        
        var modifier = RepeatDataSourceHRx?.find(x=>x.value==field)?.extension;
        if(modifier){
            value = modifier(this,value,sourceObj)
        }
        return value;
    }
}

export class PresSPConst {


    /** 一般處方箋 */
    static readonly NormalCheck = 'NormalCheck';
    /** 連續處方箋 */
    static readonly CntPresCheck = 'CntPresCheck';
    /** 第一次調劑日期 */
    static readonly FirstPresDate = 'FirstPresDate';
    /** 第二次調劑日期 */
    static readonly SecondPresDate = 'SecondPresDate';
    /** 第三次調劑日期 */
    static readonly ThirdPresDate = 'ThirdPresDate';
    /** 第四次調劑日期 */
    static readonly FourthPresDate = 'FourthPresDate';
    /** 下次回診日 */
    static readonly NextRegDate = 'NextRegDate';
    /** 截止日 */
    static readonly DeadLine = 'DeadLine';
       /** 第二次調劑日期(迄)*/
       static readonly SecondPresEndDate = 'SecondPresEndDate';
       /** 第三次調劑日期(迄)*/
       static readonly ThirdPresEndDate = 'ThirdPresEndDate';
       /** 第四次調劑日期(迄)*/
       static readonly FourthPresEndDate = 'FourthPresEndDate';

    /** 疫苗集合for VU,DU,AU,CU */
    static readonly presDates: string[] = [
        PresSPConst.FirstPresDate,
        PresSPConst.SecondPresDate,
        PresSPConst.ThirdPresDate,
        PresSPConst.FourthPresDate
    ];
    static readonly all: string[] = [
        PresSPConst.NormalCheck,
        PresSPConst.DeadLine,
        PresSPConst.NextRegDate,
        PresSPConst.FirstPresDate,
        PresSPConst.SecondPresDate,
        PresSPConst.ThirdPresDate,
        PresSPConst.FourthPresDate,
        PresSPConst.CntPresCheck,
        PresSPConst.FourthPresEndDate,
        PresSPConst.ThirdPresEndDate,
        PresSPConst.SecondPresEndDate,

    ];
  }