

/**
 * 取得就醫序號新版-就醫識別碼
 */ 
export class SeqNumber256N1 {
    /**
     * 就診日期時間
     */ 
    public ClinicDateTime: Date = new Date(0);
    /**
     * 就醫序號
     */ 
    public Number: string = null;
    /**
     * 醫療院所代碼
     */ 
    public ClinicCode: string = null;
    /**
     * 安全簽章
     */ 
    public SecuritySeal: string = null;
    /**
     * SAM ID
     */ 
    public SamId: string = null;
    /**
     * 同日就診
     */ 
    public SameDay: string = null;
    /**
     * 就醫識別碼
     */ 
    public TreatId: string = null;

    ///6.0欄位
    /** 重大傷病是否將屆  */
    public hvExpire?:boolean;
    /** 重大傷病是否將屆日期 */
    public hvExpireDate?: string;
    /** 住院次數提醒 */
    public inpatientNotify?:boolean;
    /** 高診次數提醒 */
    public treatmentLimit?: number;
}
