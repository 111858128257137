import { Entity } from "src/app/shared/models/entity";

export class PatientHist {
  public Id: number;
  //public MainDiagName: string;
  //public TOT: number;
  //public DoctorName: string;
  public ClinicName: string;
  public ClinicCode: string;
  public RegDate: Date;
  public PatientName: string;
  public PatientNo: string;
  public Icode: string;
  public Icno: string;
  public SameTreat: string;
  public DoctorId: number;
  public DoctorName: string;
  public RoomCode: string;
  public Case: string;
  public TreatCount: string;
  public MedCode: string;
  public DispTp: string;
  public HistoryId: number;
  public CC: string;
  public Dx1: string;
  public Dx2: string;
  public Dx1Name: string;
  public Dx2Name: string;
  public Dx1EName: string;
  public Dx2EName: string;
  public Checked?: boolean = true;
  public DxMasterId?: number;
}

export class DxCertMasterDto extends Entity {
  public Id: number;
  public ClinicId: number;
  public ClinicCode: string;
  public PatientNo: string;
  public PatientId?: number;
  public StartRegDate: Date;
  public EndRegDate: Date;
  public LastPrintDate: Date;
  public DoctorId: number;          // 醫師代碼，User.Id
  public HistCount: number;         // 列印的就診紀錄筆數
  public PrintCount: number;
  public Dept: string;
  public CC: string;
  public DxName: string;
  public PrintDxLanguage: number;   // 列印病名 1.中文 2.英文
  public PrintCode: number;         // 保險分類 選項：1.健保  2.自費 3.全部，預設為 3全部
  public CreateTime: Date;
}

export class DxCertDetail {
  public Id: number;
  public DxCertMasterId: number;
  public RegDate: Date;
  public DoctorId: number;
  public ICode: string;
  public Isqno: string;
  public CusNo: string;
  public Dept: string;
  public DispTP: string;
  public Case: string;
  public CC: string;
  public PE: string;
  public Dx1: string;
  public Dx2: string;
  public Dx3: string;
  public Dx4: string;
  public Dx5: string;
  public Dx6: string;
}

export class DxCertDto {
  public dxm: DxCertMasterDto;
  public dxds: PatientHist[];
}

export class DxPrintSetting {
  public DxCertRemark: string;
  public BirthCity: string;
  public Job: string;
  public PrintName: string;
  public EName: string;
  public EAddress: string;
  public PrintOutCount: string;
  public ReportType: string;
}