import { Entity } from "src/app/shared/models/entity";
import { ValueTextPair } from "src/app/shared/models/value-text-pair";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { SystemAnnouncement } from "src/app/system/system-announcement/models/SystemAnnouncement";

export class Announcement extends Entity{
    CompanyId:number;
    /**公告類型 */
    TypeCode:string;
    /**公告分類 */
    ClassCode:string;
    /** 標題 */
    Title:string;
    /** 內容 */
    Content:string;
    /** 起始時間 */
    StartTime:Date;
    /** 結束時間 */
    EndTime:Date;
    /** 警示時機 */
    AlertOn:string;
    Targets:AnnouncementGroup[];
}
export class AnnouncementListDto{
    Id:number;
    CompanyId:number;
    /**公告類型 */
    TypeCode:string;
    /**公告分類 */
    ClassCode:string;
    /** 標題 */
    Title:string;
    /** 內容 */
    Content:string;
    /** 起始時間 */
    StartTime:Date;
    /** 結束時間 */
    EndTime:Date;
    FileCount:string;
    /** 警示時機 */
    AlertOn:string;
    Targets:AnnouncementGroup[];
}
export class AnnouncementGroup extends Entity{
    Name:string;
    Members:Clinic[]
    Announcement:SystemAnnouncement[];
}


