
import { FormGroup } from '@angular/forms';
import { ViewChild, Output, EventEmitter, ChangeDetectorRef, OnDestroy, Directive } from '@angular/core';
import { EditControlButtonsComponent } from '../edit-control-buttons/edit-control-buttons.component';
import { EditModeEnum } from '../../enums/edit-mode-enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdHelper } from '../../helpers/guid-helper';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { NullOrEmpty, isNullOrUndefined } from '../../utilities';
import { BaseComponent } from './base.component';

export interface IBaseEditComponent {
  add();
  view(id: number);
  edit(id: number);
  cancel()
}

export interface StandardErrorMessage {
  errCode: string;
  errErrorId: number;
  errMessage: string;
  MessageShow: string;
}

@Directive()
export abstract class BaseEditComponent<T> extends BaseComponent implements IBaseEditComponent, OnDestroy {

  public validate = true;
  // 需要的services:EasyNotificationService, ChangeDetectorRef
  constructor() {
    super();
  }
  get editFormValue() {
    return this.editFormGroup.value;
  }

  //#region 變數
  private baseUnsubscribe: Subject<void> = new Subject();
  // api服務
  api: WebApiService;
  // 通知服務
  notification: EasyNotificationService;
  changeDetectorRef: ChangeDetectorRef;

  // 參數
  hasAfterSaveAction = false; // 存檔後是否有特定的行為，true的話就不執行原本的行為，改為呼叫方法afterSaveAction()

  // 表單內容
  editFormGroup: FormGroup;

  @ViewChild(EditControlButtonsComponent, { static: false })
  editControlButtons: EditControlButtonsComponent;

  // 資料更新完成後，傳給父元件
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onUpdated = new EventEmitter();
  @Output() public emitEnterEdit = new EventEmitter();
  @Output() public emitEnterView = new EventEmitter();

  private _baseEditRecordId: number = null;
  get baseEditRecordId(): number {
    return this._baseEditRecordId;
  }

  private _isDisabled: boolean = true;

  protected delPath: string = 'delete/';

  //#endregion

  //#region 基本方法
  // 建立表單格式
  abstract makeFormGroup(): FormGroup;
  // 填充表單內容
  abstract fillFormData(data: T);
  // #endregion

  // #region 表單物件-建立,銷毀
  // 移除editFormGroup
  destroyEditFormGroup() {
    if (this.editFormGroup) {
      // 如果不reset，kendo dropdownlist等元件的值不會清除
      this.editFormGroup.reset(); // 清除form group資料
    }
  }

  // 建立editFormGroup並給予初始值
  createEditFormGroup() {

    // 先清除再建立
    this.destroyEditFormGroup();
    if (!this.editFormGroup) {
      this.changeDetectorRef.detectChanges();
      this.editFormGroup = this.makeFormGroup();
      this.changeDetectorRef.detectChanges();
    }
  }
  //#endregion

  //#region View
  // 是否顯示
  isShow(): boolean {
    if (this.editFormGroup) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion

  //#region 是否為不可編輯狀態
  isDisabled(): boolean {
    return this._isDisabled;
  }
  //#endregion

  //#region 新增,編輯
  // 新增
  add() {
    this.createEditFormGroup(); // 建立空的表單物件
    this.editFormGroup.enable({ emitEvent: false });
    this._isDisabled = false;
    if (this.editControlButtons) {
      this.editControlButtons.toAddMode(); // [按鈕列]切換到[新增]模式，並啟用所有按鈕
    }
    this.emitEnterEdit.emit();
    this._baseEditRecordId = null;
    this.afterAddAction();
  }
  // 給繼承者override的，新增的預設值可以在這裡設定
  afterAddAction() { }
  //#endregion
  viewFromEdit() {
    this.editFormGroup.disable();
    this._isDisabled = true;
    this.view(this._baseEditRecordId); // 重讀一次資料是因為edit時可能有修改，但不確定是否有存檔
    this.emitEnterView.emit();
  }
  viewFromSave() {
    // console.log('viewFromSave');
    this.editFormGroup.disable(); // 存檔完成時有讀一次新資料，就不用再讀取
    this._isDisabled = true;
    if (this.editControlButtons) {
      this.editControlButtons.toViewMode();
    }
    this.emitEnterView.emit();
  }
  // 檢視
  view(id: number) {
    if (!id) {
      this.notification.showParameterError(); // 缺少必要參數，顯示錯誤訊息
      return;
    }

    this._baseEditRecordId = id;
    this.createEditFormGroup(); // 建立空的表單物件
    this.getOneRecord(id); // 由server端抓取此筆資料
    this.editFormGroup.disable();
    this._isDisabled = true;
    if (this.editControlButtons) { // 有某些繼承元件沒有用editControlButtons
      this.editControlButtons.toViewMode();
    }
    this.emitEnterView.emit();
  }
  editFromView() {
    this.editFormGroup.enable({ emitEvent: false });
    this._isDisabled = false;
    this.emitEnterEdit.emit();
  }
  // reset
  reset() {
    this.createEditFormGroup(); // 建立空的表單物件
    if (this.editControlButtons) { // 有某些繼承元件沒有用editControlButtons
      this.editControlButtons.toNoneMode();
    }
  }
  // 編輯
  edit(id: number) {
    if (!id) {
      this.notification.showParameterError(); // 缺少必要參數，顯示錯誤訊息
      return;
    }
    this._baseEditRecordId = id;
    this.createEditFormGroup(); // 建立空的表單物件
    this.getOneRecord(id); // 由server端抓取此筆資料
    this.editFormGroup.enable({ emitEvent: false });
    if (this.editControlButtons) {
      this.editControlButtons.toEditMode();
    }
    this.emitEnterEdit.emit();
  }

  // 由server取得單筆資料並填充到表格
  getOneRecord(id: number) {
    this.api.get('get/' + id).pipe(takeUntil(this.baseUnsubscribe)).subscribe(
      (data: T) => {
        this.fillFormData(data); // 填充表格資料
      },
      error => {
        this.notification.showGetDataFailed('base-edit:getOneRecord'); // 顯示查詢失敗訊息
        if (this.editControlButtons) {
          this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
        }
      }
    );
  }
  //#endregion

  //#region 儲存
  // 是否為新增，依據Id判斷
  isAdd(): boolean {
    if (this.editFormGroup) {
      if (IdHelper.isNullOrEmpty(this.editFormGroup.getRawValue().Id)) { 
        return true;
      }
    }
    return false;
  }

  // 提供繼承類別在儲存之前修改儲存資料
  prepareAdditionDataBeforeSave(data: any, isAdd: boolean): any {
    return data;
  }

  // 提供繼承類別在儲存之前進行客製的驗證
  customValidateBeforeSave() {
    return true;
  }

  showCreateError(error: any) {
    // console.log(error);
    this.notification.showError(error);// 顯示存檔失敗
  }

  showUpdateError(error: any) {
    this.notification.showError(error);// 顯示存檔失敗
  }

  // 儲存
  save() {
    if (!this.editFormGroup) {
      return;
    }
    
    // 註記所有欄位為touched以進行Validator檢查
    if (this.validate) {
      this.editFormGroup.markAllAsTouched();
    }

    if (this.editFormGroup.valid && this.customValidateBeforeSave()) {
      // 驗證成功
      if (this.isAdd()) {
        const saveData = this.prepareAdditionDataBeforeSave(this.editFormGroup.getRawValue(), true);
        // console.log(saveData)
        // 新增
        this.api.post('create', saveData).pipe(takeUntil(this.baseUnsubscribe)).subscribe(
          (data) => {
            if (this.hasAfterSaveAction) { // 若有特定的存檔後行為
              this.afterSaveAction(data);
            } else {
              this.notification.showOk(); // 顯示存檔成功
              // console.log('create done');
              if (data) {
                this.fillFormData(data); // 填充表格資料
                if (data.Id) {
                  this._baseEditRecordId = data.Id;
                  // console.log('this._baseEditRecordId:' + this._baseEditRecordId);
                }
              }
              if (!data || !data.Id || IdHelper.isNullOrEmpty(data.Id)) {
                console.warn('新增回傳的資料為null或沒有Id，很可能會影響新增後直接編輯或刪除動作');
                // this.notification.showParameterError();
              }
              this.viewFromSave();
              this.onUpdated.emit(); // 通知父元件(父元件更新清單內容)
            }
          },
          error => {
            this.notification.showError(this.handleError(error));
            this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
          }
        );
      } else {
        const saveData = this.prepareAdditionDataBeforeSave(this.editFormGroup.getRawValue(), false);
        // 更新
        this.api.put('update', saveData).toPromise().then((data: T) => {
          if (this.hasAfterSaveAction) { // 若有特定的存檔後行為
            this.afterSaveAction(data);
          } else {
            this.notification.showOk(); // 顯示存檔成功
            this.view(this._baseEditRecordId);  // 以原紀錄Id，重新填充表格
            // this.fillFormData(data); // 填充表格資料
            this.viewFromSave();
            // 不通知父元件是為了要停在這一筆
            this.onUpdated.emit(); // 通知父元件(父元件更新清單內容)
          }
        }).catch(
          error => {
            this.notification.showError(this.handleError(error));
            this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
          });
      }
    } else {
      // 驗證失敗
      this.notification.showValidateFailed();
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
    }
  }
  // 給繼承者override的
  afterSaveAction(data: T) { }
  //#endregion

  //#region 刪除,取消
  // 刪除
  delete() {
    // 檢查參數
    if (!this.editFormGroup || !this.editFormGroup.value.Id) {
      this.notification.showParameterError(); // 顯示參數錯誤
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
      return;
    }
    // 呼叫api
    this.api.delete(this.delPath + this.editFormGroup.value.Id).pipe(takeUntil(this.baseUnsubscribe)).subscribe(
      (data: string) => {
        this.notification.showOk(); // 顯示刪除成功
        this.editControlButtons.toNoneMode();
        this.cancel();
        this.onUpdated.emit(); // 通知父元件(父元件更新清單內容)
      },
      error => {
        this.notification.showError(this.handleError(error));
        this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
      }
    );
  }
  // 取消
  cancel() {
    if (this.editControlButtons.editMode === EditModeEnum.edit) {
      this.viewFromEdit();
      this.editControlButtons.toViewMode();
    } else {
      this.destroyEditFormGroup();
      this.editControlButtons.toNoneMode();
      if (this.editFormGroup) {
        this.editFormGroup.disable();
      }
      this._isDisabled = true;
      this.emitEnterView.emit();
    }
  }
  //#endregion

  ngOnDestroy() {
    this.baseUnsubscribe.next();
    this.baseUnsubscribe.complete();
  }

  //判斷訊息是否為標準化的錯誤訊息
  isStandardErrorMessage(error: any): error is StandardErrorMessage {
    return (error.errMessage !== undefined) && (typeof error.errMessage === 'string');
  }

  //處理錯誤，如果符合標準格式，就返回應顯示的訊息，如果不符合就直接回傳錯誤
  handleError(error: any): any {
    const message = error?.message;
    if (NullOrEmpty(message)) return error;

    if (this.isStandardErrorMessage(message)) {
      return message.errMessage;
    }
    else {
      return message;
    }
  }
}
