import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { Schedule } from '../../models/schedule';
import { ActivatedRoute, Router } from '@angular/router';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { CalendarMonthComponent } from 'src/app/shared/components/calendar/calendar-month/calendar-month.component';
import { DayCell } from 'src/app/shared/components/calendar/model/day-cell';
import { ScheduleSelection } from '../../models/ScheduleSelection';
import { EditControlButtonsComponent } from 'src/app/shared/components/edit-control-buttons/edit-control-buttons.component';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ScheduleApi } from 'src/app/services/api-service/schedule/schedule-api';
import { GetWeekText } from 'src/app/shared/utilities';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { UserConfirmService } from 'src/app/services/user-confirm.service';

@Component({
  selector: 'app-schedule-doctor-month',
  templateUrl: './doctor-month.component.html',
  styleUrls: ['./doctor-month.component.css']
})
export class ScheduleDoctorMonthComponent extends BaseComponent implements OnInit {

  @ViewChild(CalendarMonthComponent,  { static: false })
  calendar: CalendarMonthComponent;
  @ViewChild(EditControlButtonsComponent,  { static: false })
  editControl: EditControlButtonsComponent;
  allowMonth: string[];
  constructor(private route: ActivatedRoute,
    private webApiFactory: WebApiClient,
    private schApi: ScheduleApi,
    private userConfirm:UserConfirmService,
    private router: Router, private notification: EasyNotificationService) {
    super();
    this.week = GetWeekText();

    this.api = webApiFactory.createHisService('schedule/shiftschedule');
    this.companyCode = this.router.routerState.snapshot.root.children[0].params.com;

    if (this.route.snapshot.paramMap.has('year') && this.route.snapshot.paramMap.has('month')) {
      const y = this.route.snapshot.paramMap.get('year');
      if (y && y !== '') {
        this.year = parseInt(y, 10);
      }
      const m = this.route.snapshot.paramMap.get('month');
      if (m && m !== '') {
        this.month = parseInt(m, 10);
      }
    } else {
      const now = new Date();
      this.month = now.getMonth() + 1;
      this.year = now.getFullYear();
    }
  }
  @Output() selectionEvent = new EventEmitter<ScheduleSelection>();

  @Input()
  year: number;
  @Input()
  month: number;
  @Input()
  isComponentMode = false;

  companyCode: string;


  showDialog = false;
  loading = true;
  api: WebApiService;
  schedules: Schedule[];

  week: ValueTextPairNumberValue[];
  rooms: ValueTextPair[] = [];
  roomsPh: ValueTextPair[] = [];
  roomDropdown: ValueTextPair[] = [];
  positions:ValueTextPairNumberValue[] = [{value:0, text:'醫師'},{value:1, text:'藥師'}];
  userDropdown: ValueTextPairNumberValue[] = [];
  doctors: ValueTextPairNumberValue[] = [];
  doctorsEnabled: ValueTextPairNumberValue[] = [];
  doctorLeave: ValueTextPairNumberValue[] = [];
  pharmarcists: ValueTextPairNumberValue[] = [];
  pharmarcistsEnabled: ValueTextPairNumberValue[] = [];
  pharmarcistLeave: ValueTextPairNumberValue[] = [];
  sectorOfDay: ValueTextPair[] = [];
  currentSchedule: Schedule;

  positionSelected: number = 0;
  dialogSelectedRoom: string;
  dialogSelectedSecTime: number;
  dialogSelectedDoctor: number;
  dialogSelectedDoctorName: string;
  dialogSelectedDefaPh:number;
  dialogScheduleIsUsedBy = '';
  isAddMode = false;
  isUpdateMode = true;  // 允許切換排班醫師的flag
  _doctorFilter = 0;
  @Input()
  set doctorFilter(value: number) {
    this._doctorFilter = value;
    const doctor = this.userDropdown.find(d => d.value === value);
    if (doctor) {
      this.docterSelected = doctor;
    }
    this.useFilter = this._doctorFilter !== 0;
  }
  get doctorFilter() {
    return this._doctorFilter;
  }
  get useFilter() {
    return this._useFilter;
  }
  set useFilter(value: boolean) {
    if (this._useFilter !== value) {
      this._useFilter = value;
      if (value === false) {
        this._doctorFilter = 0;
        this.docterSelected = null;
      } else {
        if (this._doctorFilter === 0 && this.userDropdown.length > 0) {
          const doctor = this.userDropdown[0];
          if (doctor) {
            this.docterSelected = doctor;
            this._doctorFilter = doctor.value;
          }
        }
      }
      this.requestData({
        year: this.year,
        month: this.month
      });
    }
  }
  _useFilter = false;
  @Input()
  showFilter = false;
  docterSelected: ValueTextPairNumberValue;
  public requestData(event: any) {
    this.api.get('GetMonth?year=' + event.year + '&month=' + event.month)
    .subscribe((data: any) => {
        this.positionSelected = 0;
        this.doctors = data.dyDoctors;
        this.doctorLeave = data.dyDoctorLeave;//離職醫生
        this.userDropdown = data.dyDoctorsEnabled;
        this.doctorsEnabled = data.dyDoctorsEnabled;
        this.pharmarcistsEnabled = data.dyPhEnabled;
        this.pharmarcists = data.dyPh;
        this.pharmarcistLeave = data.dyPhLeave;//離職藥師

        this.rooms = data.medRoomList;
        this.roomsPh = [{value:'',text:'不分診'}].concat(this.rooms);
        this.roomDropdown = this.rooms;

        this.allowMonth = data.monthList;
        this.sectorOfDay = data.timeSection.filter(x => x.value != 0 && x.value != 9);  // 過濾掉預設(0)和無時段(9)
        this.schedules = data.schedules;
        this.calendar.bindData(data.schedules);
        this.applyFilters();
      });
  }
  onMonthChange(event: any) {
    this.year = event.year;
    this.month = event.month;
    this.requestData(event);
  }

  ngOnInit() {
    this.requestData({ year: this.year, month: this.month });
  }

  public getItems(items: any[]): any[] {
    if (this.useFilter && this.doctorFilter !== 0) {
      items = items.filter(item => item.UserId === this.doctorFilter);
    }
    var sorted = items;
    if (items && items.length > 1) {
      sorted = items.sort((a, b) => {
        if (a.SecTime === b.SecTime) {
          if (a.RoomCode === b.RoomCode) {
            if (a.PositionCode > b.PositionCode) {
              return 1;
            } else {
              return -1;
            }
          }
          if (a.RoomCode > b.RoomCode) {
            return 1;
          } else {
            return -1;
          }
        }
        if (a.SecTime > b.SecTime) {
          return 1;
        } else {
          return -1;
        }
      });
    }
    return sorted.map(item=>{
      return {
        id:item.Id,
        secName: this.showSecTime(item),
        roomName: this.showRoom(item),
        tip: this.showTip(item),
        color:this.getPosColor(item.PositionCode),
        name:item.UserName,
        pos:item.PositionCode,
        phName: item.DefaPharm ? this.getPharName(item.DefaPharm): '', //null
      }
    })
  }

  applyFilters(){
    if (this.useFilter) {
      const doctor = this.userDropdown.find(doc => doc.value === this._doctorFilter);
      if (doctor) {
        this.docterSelected = doctor;
      }
    }
  }

  getPosName(pos: string) {
    if (pos == '10') {
      return '專任';
    }
    if (pos == '11') {
      return '非專任';
    }
    if (pos == '40') {
      return '藥師';
    }
    if (pos == '41') {
      return '藥生';
    }
  }

  public showSecTime(s: Schedule): string {
    const section = this.sectorOfDay.find(sec => (Number)(sec.value) === s.SecTime);

    return section ? section.text : '';
  }
  public showRoom(s: Schedule): string {
    if (!s?.RoomCode) {
      return s.PositionCode =='40'|| s.PositionCode=='41'?'不分': '';
    }
    const data = this.roomDropdown.find(item => {
      if (item.value === s.RoomCode) {
        return true;
      }
    });
    if (data) {
      return data.text;
    } else {
      return '';
    }
  }
  public showTip(sss): string {
    return this.showSecTime(sss) + ' : ' + this.showRoom(sss) + ' - ' + sss.UserName;
  }

  // public show(s: Schedule): string {
  //   return s.UserName;
  // }

  public showDate(s: DayCell<any>): string {
    if (s === undefined) {
      return '';
    }
    if (s.Date.getDate() === 1) {
      return (s.Date.getMonth() + 1).toString() + '/' + s.Date.getDate().toString();
    }
    return s.Date.getDate().toString();
  }

  public async onMonthItemClick(id: any) {
    const data = this.schedules.find(item => {
      if (item.Id === id) {
        return true;
      }
    });
    this.dialogScheduleIsUsedBy = '';
    if (!this.isComponentMode) {
      // console.log(data);
      this.currentSchedule = Object.assign({}, data);
      this.dialogSelectedDoctor = this.currentSchedule.UserId;

      this.dialogSelectedDoctorName = this.currentSchedule.UserName;
      this.dialogSelectedRoom = this.currentSchedule.RoomCode;
      this.dialogSelectedSecTime = this.currentSchedule.SecTime;
      this.dialogSelectedDefaPh = this.currentSchedule.DefaPharm;
      this.positionSelected = this.isDoctor(this.currentSchedule.PositionCode)?0:
                              this.isPhar(this.currentSchedule.PositionCode)?1:0;
      this.positionChange(this.positionSelected);
      this.getScheduledPh();
      //await this.checkeDeleteStatus();
      this.openDialog();
    } else {
      const result = new ScheduleSelection();
      result.DoctorId = data.UserId;
      result.RoomCode = data.RoomCode;
      result.SecTime = data.SecTime;
      result.ScheduleDate = new Date(data.Year, data.Month - 1, data.Day);
      this.selectionEvent.emit(result);
    }
  }

  public onCloseButtonClick() {
    this.showDialog = false;
    this.isAddMode = false;
  }

  public async onSaveButtonClick() {
    if (this.dialogScheduleIsUsedBy !== '') {
      if (this.isAddMode) {
        this.editControl.toEditMode();
      } else {
        this.editControl.toEditMode();
      }
      return;
    }
    // console.log(this.dialogSelectedRoom.toString() + ' ' + this.dialogSelectedSecTime);
    this.currentSchedule.RoomCode = this.dialogSelectedRoom;
    this.currentSchedule.SecTime = this.dialogSelectedSecTime;
    this.currentSchedule.UserId = this.dialogSelectedDoctor;
    this.currentSchedule.UserName = this.dialogSelectedDoctorName;
    this.currentSchedule.DefaPharm = this.dialogSelectedDefaPh;
    this.currentSchedule.PositionCode = this.userDropdown.find(d=>d.value == this.dialogSelectedDoctor).extension;
    let apiActon = 'DayModify';
    if (this.isAddMode) {
      apiActon = 'Add';
    }else{
      if(!this.isAddMode) {
        var cont = await this.checkeDeleteStatus();
        if(!cont){
          return;
        }
      }
    }
    

    this.api.post(apiActon, this.currentSchedule).subscribe(result => {
      this.requestData({ year: this.year, month: this.month });
      this.onCloseButtonClick();
    }, error => {
      this.notification.showError(error.message);
      this.editControl.enableButtons();
    });
  }

  public async onDeleteButtonClick() {
    await this.checkeDeleteStatus();
    this.api.get('delete/' + this.currentSchedule.Id)
      .subscribe(result => {
        this.requestData({ year: this.year, month: this.month });
        this.onCloseButtonClick();
      },
      error => {
        this.notification.showError(error.message);
      });
  }

  isCanDelete: boolean = true;
  public async checkeDeleteStatus():Promise<boolean> {
    if(!this.isAddMode) {
      // 修改時友善提示
      var hasReg = await this.api.get('CheckScheduleIsRegist', { id: this.currentSchedule.Id }).toPromise<boolean>();
      if(hasReg){
        var conf = await this.userConfirm.showConfirm({
          title:'提醒',
          msg:'該診間醫師已有掛號資料，是否確定要進行班表變更？\n(此操作不影響已經掛號/預約所選的醫師)',
          width:450
        })
        if(!conf){
          return false;
        }
      }
    }
    return true;
    //this.openDialog();
  }

  public onDialogRoomSelectChange(value: any) {
    this.dialogSelectedRoom = value;
    this.checkSelectedScheduleAvailable();
  }
  public onDialogSecTimeSelectChange(value: number) {
    this.dialogSelectedSecTime = value;
    this.checkSelectedScheduleAvailable();
    this.getScheduledPh();
  }

  //判斷離職日期就不要出現選單
  refreshOnJobList(date: Date) {
    const scheduleDate = DateHelper.getFormatedDateString(date, false, '', false);
    if (this.positionSelected == 1) {
      this.userDropdown = this.pharmarcistsEnabled.filter(x => !this.pharmarcistLeave.find(y => y.extension < scheduleDate && y.value == x.value));
    }
    else {
      this.userDropdown = this.doctorsEnabled.filter(x => !this.doctorLeave.find(y => y.extension < scheduleDate && y.value == x.value));
    }
  }

  public async onAddNewScheduleClick(date: Date) {
    const data: Schedule = new Schedule();
    data.ScheduleDate = date;
    data.Year = date.getFullYear();
    data.Month = date.getMonth() + 1;
    data.Day = date.getDate();
    this.currentSchedule = data;

    this.refreshOnJobList(this.currentSchedule.ScheduleDate);

    // default doctor 1
    if (this.userDropdown.length > 0) {
      this.dialogSelectedDoctor = this.userDropdown[0].value;
    } else {
      this.dialogSelectedDoctor = 0;
    }
    this.dialogSelectedDoctorName = '';
    // default room 1
    if (this.roomDropdown.length > 0) {
      this.dialogSelectedRoom = this.roomDropdown[0].value;
    } else {
      this.dialogSelectedRoom = '';
    }
    // default section 0
    this.dialogSelectedSecTime = 1;
    this.dialogSelectedDefaPh = null;
    this.isAddMode = true;
    this.getScheduledPh();
    //await this.checkeDeleteStatus();
    this.openDialog();
    this.checkSelectedScheduleAvailable();
  }

  // tslint:disable-next-line: member-ordering
  isOpenDoctorList = false;
  onSelectionClick(event: Schedule) {
    this.isOpenDoctorList = false;
  }

  onAppointmentClick() {
    this.isOpenDoctorList = true;
  }

  checkSelectedScheduleAvailable() {
    // 不限制同醫師不同診間獲 同診間多個醫師了
    return;
    for (const s of this.schedules) {
      if (s.SecTime === this.dialogSelectedSecTime && s.Year === this.currentSchedule.Year &&
        s.Month ===  this.currentSchedule.Month && s.Day === this.currentSchedule.Day) {
        if (s.Id !== this.currentSchedule.Id) {
          // sUser找的到表示s是與目前的類型相同(醫師/藥師/...)
          var sUser = this.userDropdown.find(u=>u.value == s.UserId);
          // 醫師要判斷診間是否排其他醫師 或 自己已經有排到同時段
          if(this.positionSelected==0 && sUser
              && (s.RoomCode === this.dialogSelectedRoom ||  s.UserId == this.dialogSelectedDoctor)){
            this.dialogScheduleIsUsedBy = s.UserName;
            return;
            // 藥師判斷是否自己已經排在這個時段
          }else if(this.positionSelected==1 && sUser && s.UserId == this.dialogSelectedDoctor ){
            this.dialogScheduleIsUsedBy = s.UserName;
            return;
          }
        }
        this.dialogScheduleIsUsedBy = '';
      }
    }
    this.dialogScheduleIsUsedBy = '';
  }

  onDoctorSelected(value: ValueTextPairNumberValue) {
    this.doctorFilter = value.value;
  }

  openDialog(){
    this.showDialog = true;
    if (this.isAddMode) {
      this.editControl.toAddMode();
    } else {
      this.editControl.toEditMode(this.isCanDelete);
    }
  }
  UpdateName(docId) {
    this.dialogSelectedDoctorName = this.userDropdown.find(d=>d.value == docId).text;
    this.checkSelectedScheduleAvailable();
  }
  positionChange(selected:number){
    switch (selected) {
      case 1:
        this.userDropdown = this.pharmarcistsEnabled;
        this.roomDropdown = this.roomsPh;
        break;
      default:
        this.userDropdown = this.doctorsEnabled;
        this.roomDropdown = this.rooms;
        break;
    }

    this.refreshOnJobList(this.currentSchedule.ScheduleDate);
    if(!this.userDropdown.find(d=>d.value == this.dialogSelectedDoctor)){
      this.dialogSelectedDoctor = this.userDropdown[0].value;
    }
    if(!this.roomDropdown.find(d=>d.value == this.dialogSelectedRoom)){
      this.dialogSelectedRoom = this.roomDropdown[0].value;
    }
    this.checkSelectedScheduleAvailable();
  }
  isDoctor(code:string){
    return ['10','11'].includes(code);
  }
  isPhar(code:string){
    return ['40','41'].includes(code)
  }
 getPosColor(code:string){
  return {color:this.isDoctor(code)?'#06c':
                      this.isPhar(code)?'#fab415':
                      'gray'}
 }
 getPharName(id:number){
  return  this.pharmarcists.find(p=>p.value == id)?.text??'';
 }
  getScheduledPh(){
    var schPh = this.schedules.filter(s=>s.SecTime == this.dialogSelectedSecTime && s.Year === this.currentSchedule.Year &&
      s.Month ===  this.currentSchedule.Month && s.Day === this.currentSchedule.Day
      && (s.PositionCode=='40'||s.PositionCode=='41') );
    this.dialogPh = [{value:null,text:''}].concat(schPh.map(p=>{
      return {value:p.UserId,text:p.UserName};
    }));
  }
  dialogPh: ValueTextPairNumberValue[]  = [];
}
