<!-- <app-vis-dialog title="IC卡" [show]="true" (close)="close.emit()" [width]="1200" i18n-title="@@HistPricing">   -->
  <div class="card-header w-100 d-flex">
    <div class="mr-1">
      <span i18n="@@Name" class="mr-1">姓名</span>
      <input class="vis-input less-full-width-lg w-32" readonly [value]="patient?.Name" />
    </div>
    <div class="mr-1">
      <span i18n="@@Name" class="mx-1">身分證</span>
      <input class="vis-input less-full-width-xl w-32" readonly [value]="patient?.CId" maxlength="10" />
    </div>
    <div class="mr-1">
      <span i18n="@@Name" class="mx-1">生日</span>
      <input class="vis-input less-full-width-lg w-42" readonly [value]="patient?.Birth" formatDate/>
    </div>
    <div class="mr-1">
      <span i18n="@@Name" class="mx-1">性別</span>
      <input class="vis-input less-full-width-lg w-20" readonly [value]="patient?.Sex" />
    </div>
    <div class="mr-1">
      <span i18n="@@Name" class="mx-1">讀取日期</span>
      <input class="vis-input less-full-width-xlg w-32" [value]="" formatDate />
    </div>
      <div class="mr-1">
          <button type="button"
            (click)="getPatient()"
            i18n="@@Save"
            style="padding: 4px 0;"
            title="重讀基資"
            class="my-0 vis-btn-warning px-1 mr-1 lh-xs vis-btn-max-height">
            <mat-icon style="top:-4px" class="btn-scale-90 pref-icon vis-combine-icon">refresh</mat-icon>
            <span class="position-relative">重讀基資</span>
        </button>
        </div>
    <!-- <div class="ml-1">
      <label class="vis-form-field">
        <span class="w-auto float-left">醫師卡資料</span>
        <mat-radio-group>
          <mat-radio-button [value]="'hpc'" [checked]="true" #hpc>需要</mat-radio-button>
          <mat-radio-button [value]="'nohpc'" #nohpc>不需要</mat-radio-button>
        </mat-radio-group>
      </label>
    </div> -->
  </div>
  <div style="width: 100%;display: flex;" class="card-header">

    <span i18n="@@Name">器官捐贈及安寧照護</span>
    <input class="vis-input" style="flex-grow: 1;margin: 0px 10px;" readonly [value]="OrganDonate" />
    <button type="button" [appOpenDialog]="reload" i18n="@@Save" style="padding: 4px 0;" title="重讀IC"
      class="my-0 vis-btn-warning px-1 mr-1 lh-xs vis-btn-max-height">
      <mat-icon style="top:-4px" class="btn-scale-90 pref-icon vis-combine-icon">refresh</mat-icon>
      <span class="position-relative">重讀IC</span>
    </button>
    <button type="button" i18n="@@Cancel" style="padding: 4px 0;" title="列印"
      class="my-0 vis-btn-third-primary px-1 mr-1 lh-xs vis-btn-max-height">
      <mat-icon style="top:-4px" class="btn-scale-90 pref-icon vis-combine-icon">print</mat-icon>
      <span class="position-relative">列印</span>
    </button>
    <button type="button" (click)="close.emit()" i18n="@@Cancel" style="padding: 4px 0;" title="退出"
      class="my-0 vis-btn-cancel px-1 mr-1 lh-xs vis-btn-max-height">
      <mat-icon style="top:-4px" class="btn-scale-90 pref-icon vis-combine-icon">cancel</mat-icon>
      <span class="position-relative">退出</span>
    </button>

  </div>
  <div class="d-flex w-100" style="flex-grow: 1;height: 29px;">
    <div class="w-50 float-left d-inline-block">
      <!-- <label class="vis-form-field">
        <span class="w-auto float-left ml-3">醫師卡資料</span>
        <mat-radio-group>
          <mat-radio-button [value]="'hpc'" [checked]="true" #hpc>需要</mat-radio-button>
          <mat-radio-button [value]="'nohpc'" #nohpc>不需要</mat-radio-button>
        </mat-radio-group>
      </label> -->
      <!--(click)="closePricingWin(true)" primary="true"-->
      <mat-button-toggle-group #toggle>
        <mat-button-toggle class="w-32 hpc-checked" [value]="'hpc'" #hpc [checked]="true">需醫師卡資料</mat-button-toggle>
        <mat-button-toggle class="w-32 nohpc-checked" [value]="'nohpc'" #nohpc>不需醫師卡資料</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class=" pr-3 text-right float-right w-50  d-inline-block lh-lg" *ngIf="nohpc.checked">[牙醫註記:無][罕病註記:無][指定就醫:無]</div>
    <div class=" pr-3 text-right float-right w-50 d-inline-block lh-lg" *ngIf="hpc.checked">[牙醫註記:無][罕病註記:無][指定就醫:無]</div>
  </div>


  <div class=" inside-scrollbar p-0" [ngStyle]="{maxHeight: getViewScrollHeight()}">
    <div *ngIf="nohpc.checked" class="d-flex w-100 nohpc-checked">
        <div style="width: 50%;">
          <div class="ic-data-container">
            <button (click)="getTreatmentNoNeedHPC()" class="vis-btn-third-primary">重大傷病
              <mat-icon
                class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon></button>
            <app-vis-grid [data]="criIllNoHPC" [customClass]="'extend'">
              <app-vis-grid-column [title]="'病代'" [field]="'Code'" [width]="147"></app-vis-grid-column>
              <app-vis-grid-column [title]="'病名'" [field]="'Name'" [width]="148"></app-vis-grid-column>
              <app-vis-grid-column [title]="'起日'" [field]="'StartDate'" [width]="148" class="text-center custom-m-auto">
                <ng-template let-data>
                  {{data.StartDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'迄日'" [field]="'EndDate'" [width]="164" class="text-center custom-m-auto">
                <ng-template let-data>
                  {{data.EndDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
                </ng-template>
              </app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container">
            <button (click)="getTreatmentNoNeedHPC()" class="vis-btn-third-primary">就醫資料
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [scroll]="true"
              [customClass]="'extend'"
              [tableFullWidth]="'w-150'"
              [data]="treatmentNoHPC">
              <app-vis-grid-column [title]="'就醫時間'" [field]="'Date'" [width]="270"></app-vis-grid-column>
              <!-- <app-vis-grid-column [title]="''" [field]="'Name'" class="text-center"></app-vis-grid-column> -->
              <app-vis-grid-column [title]="'卡序'" [field]="'Isqno'" [width]="70"></app-vis-grid-column>
              <app-vis-grid-column [title]="'院所'" [field]="'Clinic'" [width]="164"></app-vis-grid-column>
              <app-vis-grid-column [title]="'類別'" [field]="'Itp'" [width]="70" class="text-center"></app-vis-grid-column>
              <app-vis-grid-column [title]="'依附'" [field]="'NewBorn'" [width]="70" class="text-center"></app-vis-grid-column>
              <app-vis-grid-column [title]="'補卡'" [field]="'Recard'" [width]="70" class="text-center"></app-vis-grid-column>
              <app-vis-grid-column [title]="'門診部分負擔'" [field]="'Deductible'" [width]="178" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'門診醫療費用'" [field]="'MedicalFees'" [width]="178" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'住院醫療費用'" [field]="'HospitalFees'" [width]="178" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'住院部分負擔A'" [field]="'HdeductibleA'" [width]="178" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'住院部分負擔B'" [field]="'HdeductibleB'" [width]="178" class="text-right"></app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container">
            <button (click)="getTreatmentNoNeedHPC()" class="vis-btn-third-primary">就醫費用
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [scroll]="true" [customClass]="'extend'" [data]="cumulatives" [showMultiLineHead]="true">
              <app-vis-grid-column [title]="'年度'" [field]="'Year'" [width]="80" class="text-center"></app-vis-grid-column>
              <app-vis-grid-column [title]="'門住診次數'" [field]="'CimulativeCount'" [width]="80" class="text-center"></app-vis-grid-column>
              <app-vis-grid-column [title]="'門診費用'" [field]="'MedicalFeesSum'" [width]="68" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'住診費用'" [field]="'HospitalFeesSum'" [width]="68" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'門診負擔'" [field]="'DeductibleSum'" [width]="68" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'住診負擔1'" [field]="'HdeductibleASum'" [width]="75" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'住診負擔2'" [field]="'HdeductibleBSum'" [width]="75" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'門住診負擔'" [field]="'MHdeductibleSum'" [width]="88" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'門住1診負擔'" [field]="'MHdeductibleASum'" [width]="88"class="text-right"></app-vis-grid-column>
            </app-vis-grid>
          </div>
        </div>
        <div style="width: 50%;">
          <div class="ic-data-container">
            <button (click)="getRegisterPrevent()" class="vis-btn-third-primary">預防保健
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [scroll]="true" [customClass]="'extend'" [tableFullWidth]="'w-100'"
              [data]="registerPreventSubset">
              <app-vis-grid-column [title]="'檢查日期'" [field]="'ExamDate'" [width]="107" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.ExamDate | formatROCDate">{{(data.ExamDate | formatROCDate)||'無' }}</div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'檢查項目代碼'" [width]="150" [field]="'ProjectCode'"></app-vis-grid-column>
              <app-vis-grid-column [title]="'醫療院所代碼'" [width]="150" [field]="'ClinicCode'"></app-vis-grid-column>
              <app-vis-grid-column [title]="'保健服務項目註記'" [width]="200" [field]="'HealthCareFlag'" class="text-center"></app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container">
            <button (click)="getRegisterPregnant()" class="vis-btn-third-primary">產檢資料
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [scroll]="false"
              [data]="pagedRegisterPregnant"
              [customClass]="'extend'"
              class="small-paginater"
              [pageSize]="5"
              [pageable]="true"
              [skip]="0"
              (pageChange)="registerPregnantPageChanged($event)">
              <app-vis-grid-column [title]="'檢查日期'" [field]="'ExamDate'" [width]="155" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.ExamDate | formatROCDate">{{data.ExamDate | formatROCDate }}</div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'醫療院所代碼'" [width]="220" [field]="'ClinicCode'"></app-vis-grid-column>
              <app-vis-grid-column [title]="'檢查項目代碼'" [width]="230" [field]="'ExamCode'"></app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container">
            <button (click)="getInoculateData()" class="vis-btn-third-primary">預防接種
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid class="small-paginater"
              [customClass]="'extend'"
              [scroll]="false"
              [pageSize]="5"
              [pageable]="true"
              [skip]="0"
              [data]="pagedInoculateData"
              (pageChange)="inoculateDataPageChanged($event)">
              <app-vis-grid-column [title]="'接種日期'" [field]="'InoculateDate'" [width]="135" class="text-center custom-m-auto">
                <ng-template let-data>
                  {{data.InoculateDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
                </ng-template></app-vis-grid-column>
              <app-vis-grid-column [title]="'醫療院所代碼'" [field]="'ClinicCode'" [width]="194"></app-vis-grid-column>
              <app-vis-grid-column [title]="'疫苗種類'" [field]="'Type'" [width]="135"></app-vis-grid-column>
              <app-vis-grid-column [title]="'疫苗批號'" [field]="'LotNumber'" [width]="144"></app-vis-grid-column>
            </app-vis-grid>
          </div>
        </div>

    </div>
    <!--需要醫師卡部分-->

    <div *ngIf="hpc.checked" class="d-flex w-100 hpc-checked">
        <div style="width: 50%;">
          <div class="ic-data-container">
            <button (click)="getTreatment()" class="vis-btn-third-primary">重大傷病
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [data]="criIll" [customClass]="'extend'">
              <app-vis-grid-column [title]="'病代'" [field]="'Code'" [width]="147"></app-vis-grid-column>
              <app-vis-grid-column [title]="'病名'" [field]="'Name'" [width]="148"></app-vis-grid-column>
              <app-vis-grid-column [title]="'起日'" [field]="'StartDate'" [width]="148" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.StartDate | formatROCDate">{{data.StartDate | formatROCDate }}</div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'迄日'" [field]="'EndDate'" [width]="164" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.EndDate | formatROCDate">{{data.EndDate | formatROCDate }}</div>
                </ng-template>
              </app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container">
            <button (click)="getTreatment()" class="vis-btn-third-primary">主次診斷
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>{{treatmentHPCError}}
            <app-vis-grid [data]="treatmentHPC" [scroll]="true" [customClass]="'extend'">
              <app-vis-grid-column [title]="'就醫日'" [field]="'Date'" [width]="178" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.Date | formatROCDate">{{data.Date | formatROCDate }}</div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'主診斷'" [field]="'Dx1'" [width]="77"></app-vis-grid-column>
              <app-vis-grid-column [title]="'次診斷1'" [field]="'Dx2'" [width]="90"></app-vis-grid-column>
              <app-vis-grid-column [title]="'次診斷2'" [field]="'Dx3'" [width]="90"></app-vis-grid-column>
              <app-vis-grid-column [title]="'次診斷3'" [field]="'Dx4'" [width]="90"></app-vis-grid-column>
              <app-vis-grid-column [title]="'次診斷4'" [field]="'Dx5'" [width]="90"></app-vis-grid-column>
              <app-vis-grid-column [title]="'次診斷5'" [field]="'Dx6'" [width]="90"></app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container">
            <button (click)="ReadPrescriptHVE()" class="vis-btn-third-primary">重要醫令
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [scroll]="false"
              class="small-paginater w-100"
              [customClass]="'extend'"
              [pageable]="true"
              [skip]="0"
              [pageSize]="5"
              [data]="pagedHVE"
              (pageChange)="HVEPageChanged($event)">
              <app-vis-grid-column [title]="'就醫日'" [field]="'ImplementDate'" [width]="77" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.ImplementDate | formatROCDate">{{data.ImplementDate | formatROCDate }}</div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'醫療院所代碼'" [field]="'ClinicCode'" [width]="141"></app-vis-grid-column>
              <app-vis-grid-column [title]="'重要醫令項目代碼'" [field]="'TreatmentCode'" [width]="184"></app-vis-grid-column>
              <app-vis-grid-column [title]="'實施部位代碼'" [field]="'ImplementPartCode'" [width]="141"></app-vis-grid-column>
              <app-vis-grid-column [title]="'總量'" [field]="'Total'" [width]="63" class="text-right"></app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container my-2">
            <button (click)="ReadPrescriptAllergic()" class="vis-btn-third-primary mr-1">過敏藥
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <div style="display: flex;flex-shrink: 1;flex-grow: 1;">
              <div style="flex-grow: 1;">
                <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug1">
                <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug2">
                <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug3">
              </div>
              <button class="w-24 h-24 4 rounded-5 ml-1 vis-btn-warning">過敏藥寫入IC
                <mat-icon role="img" title="IC" aria-hidden="true" data-mat-icon-type="font"
                  class="mat-icon notranslate text-base position-relative d-inline-flex material-icons mat-icon-no-color h-auto w-auto"
                  style="transform: rotate(-90deg); font-size: 3rem;"> sim_card</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div style="width: 50%;">
          <div class="ic-data-container">
            <button (click)="ReadPrescriptMain()" class="vis-btn-third-primary">門診醫令
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [scroll]="true"
              [customClass]="'extend'"
              class="small-paginater w-100"
              [pageable]="true"
              [skip]="0"
              [pageSize]="10"
              [data]="pagedOutPatient"
              [showMultiLineHead]="true"
              (pageChange)="outpatientPageChanged($event)">
              <app-vis-grid-column [title]="'就醫日'" [field]="'ClinicDateTime'" [width]="145" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.ClinicDateTime | formatROCDate">{{data.ClinicDateTime | formatROCDate }}</div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'醫令類別'" [field]="'OrderClass'" [width]="64" class="text-center order-class"></app-vis-grid-column>
              <app-vis-grid-column [title]="'診療項目代號'" [field]="'NhiDrugCode'" [width]="117"></app-vis-grid-column>
              <app-vis-grid-column [title]="'診療部位'" [field]="'TreatmentSite'" [width]="64" class="treatment-site"></app-vis-grid-column>
              <app-vis-grid-column [title]="'用法'" [field]="'Frequency'" [width]="47" class="text-center"></app-vis-grid-column>
              <app-vis-grid-column [title]="'天數'" [field]="'Days'" [width]="47" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'總量'" [field]="'TotalDose'" [width]="64" class="total-dose text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'交付處方註記'" [field]="'DispensingType'" [width]="122"></app-vis-grid-column>
            </app-vis-grid>
          </div>
          <div class="ic-data-container">
            <button (click)="ReadPrescriptLongTerm()" class="vis-btn-third-primary">長期醫令
              <mat-icon class="pref-icon text-white position-relative top-2 -left-2 btn-scale-90">sync</mat-icon>
            </button>
            <app-vis-grid [scroll]="false"
              [customClass]="'extend'"
              class="small-paginater w-100"
              [pageable]="true"
              [skip]="0"
              [pageSize]="5"
              [data]="pagedLongTerm"
              (pageChange)="longTermPageChanged($event)">
              <app-vis-grid-column [title]="'開立日期'" [field]="'CreateDate'" [width]="160" class="text-center custom-m-auto">
                <ng-template let-data>
                  <div class="ellipsis-content" [title]="data.CreateDate | formatROCDate">{{data.CreateDate | formatROCDate }}</div>
                </ng-template>
              </app-vis-grid-column>
              <app-vis-grid-column [title]="'藥品代碼'" [field]="'NhiDrugCode'" [width]="160"></app-vis-grid-column>
              <app-vis-grid-column [title]="'用法'" [field]="'Frequency'" [width]="92" class="text-center"></app-vis-grid-column>
              <app-vis-grid-column [title]="'天數'" [field]="'Days'" [width]="92" class="text-right"></app-vis-grid-column>
              <app-vis-grid-column [title]="'總量'" [field]="'TotalDose'" [width]="103" class="text-right"></app-vis-grid-column>
            </app-vis-grid>
          </div>
        </div>
    </div>
  </div>
<!-- </app-vis-dialog> -->
<app-vis-dialog title="IC卡" [width]="400" #reload>
  <!-- <app-ic-view (close)="readIcDialog.show=false"></app-ic-view>   -->
  <div class="card-body px-5 ic-view" style="max-height: 590px;">
    <h5 class="font-weight-bold text-base">重讀項目</h5>
    <div class="d-flex">
      <div class="w-50 d-flex" style="flex-direction: column;">
        <span class="mb-1">T.需醫事卡資料</span>
        <mat-checkbox [(ngModel)]="loadItems.criIll">重大傷病</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.treatment">主次診斷</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.rxHVE">重要醫令</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.rxMain">門診醫令</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.rxLong">長期醫令</mat-checkbox>
      </div>
      <div class="w-50 d-flex" style="flex-direction: column;">
        <span class="mb-1">N.不需醫事卡資料</span>
        <mat-checkbox [(ngModel)]="loadItems.criIllNoHpc">重大傷病</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.treatmentNoHpc">就醫資料</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.fee">就醫費用</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.prevent">預防保健</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.pregnent">產檢資料</mat-checkbox>
        <mat-checkbox [(ngModel)]="loadItems.inoculate">預防接種</mat-checkbox>
      </div>
    </div>
    <div class="d-flex">
      <div class="w-50 d-flex" style="flex-direction: column;">
        <mat-checkbox (change)="needHpcAll($event)">全選</mat-checkbox>
      </div>
      <div class="w-50 d-flex" style="flex-direction: column;">
        <mat-checkbox (change)="noNeedHpcAll($event)">全選</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="w-100 text-center mb-3">
    <button (click)="reloadAll();reload.show=false"
      class="easy-form-btn sumbit vis-btn-primary px-4 mb-0 vis-btn-capsule mx-1">確定
    </button>
    <button (click)="reload.show=false" class="easy-form-btn sumbit vis-btn-primary px-4 mb-0 vis-btn-capsule mx-1">取消
    </button>
  </div>
</app-vis-dialog>
