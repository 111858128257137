import { Directive, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IEntity } from '../../models/i-entity';
import { BaseConfig } from '../../../services/base-config.service';
import { BaseEditComponent, IBaseEditComponent } from './base.edit.component';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { UserListDto } from 'src/app/services/api-service/user/user-api';
import { BaseComponent } from './base.component';

@Directive()

export abstract class BaseListComponent<T extends IEntity> extends BaseComponent implements OnInit, OnDestroy {

  // 需要的services:EasyNotificationService
  constructor() {
    super();
  }

  //#region 變數
  private baseUnsubscribe: Subject<void> = new Subject();
  // api服務
  api: WebApiService;
  // 通知服務
  notification: EasyNotificationService;
  refreshListAction = 'getAll';
  // Child元件
  abstract editComponent: IBaseEditComponent;

  @Output() public emitRequestOneRecord = new EventEmitter();

  isChildInEdit = false;
  get allDisabled() {
    if (this.isChildInEdit) {
      return 'disabled';
    } else {
      return null;
    }
  }

  // 查詢條件
  queryFormGroup: FormGroup;

  // 清單物件
  listItems: T[];

  // 表格-所選項目
  selectedItems: number[] = [];

  // 表格-分頁
  gridView: {
    data: T[],
    total:number
  };
  pageSize: number = BaseConfig.getConfig().ui.pageSize;
  pageSkipNo = 0;

  // 刪除確認視窗
  // 是否開啟視窗，用來綁定到ConfirmDialog元件
  isDeleteDialogOpened = false;
  // 將刪除之項目Id
  deleteItemId = '';
  //#endregion

  //#region 基本方法
  abstract makeQueryFormGroup();

  // 為了讓繼承物件能修改查詢條件
  makeQueryConditions(): any {
    //console.log('base,makeQueryConditions() ',  this.queryFormGroup.value);
    return this.queryFormGroup.value;
  }

  ngOnInit() {
    // createQueryFormGroup必須在refreshList之前，因為refreshList會用到form group參數
    this.createQueryFormGroup();
    this.refreshList(true);
  }
  //#endregion

  //#region 查詢
  // 建立query form group
  createQueryFormGroup() {
    this.queryFormGroup = this.makeQueryFormGroup();
  }

  // query click
  onBtnQueryClick() {
    // console.log('onBtnQueryClick', this.queryFormGroup);
    this.refreshList(true);
  }
  // add click
  onBtnAddClick() {
    if (this.editComponent !== null) {
      this.editComponent.add(); // 呼叫edit元件進行新增作業
    }
    this.selectedItems[0] = 0; // 清空選擇
  }
  //#endregion

  expandQuery(query) {
      return query;
  }
  parseResult(result: any): T[] {
    return result;
  }
  //#region 清單
  // 更新清單
  refreshList(isNewQuery?: boolean) {
    if (isNewQuery) {
      this.pageSkipNo = 0;
    }
    // 取得參數資料
    const query = this.expandQuery(this.makeQueryConditions());
    this.api.get(this.refreshListAction, query).pipe(takeUntil(this.baseUnsubscribe)).subscribe(
      (result: any) => {
        const data: T[] = this.parseResult(result);
        this.listItems = this.handleListData(data);
        // console.log('refreshList', this.listItems);
        // 表格分頁
        this.setGridView();

        // 第一次進入時選到第一筆 (非主動查詢得化不幫使用者選擇))
        if (isNewQuery) {
          this.selectedAfterQuery();
        }
      },
      error => this.notification.showGetDataFailed('base-list:refreshList') // 失敗訊息
    );
  }
  selectedAfterQuery() {

    if (this.listItems.length > 0) {
      const firstRowId = this.listItems[0].Id;
      this.selectedItems[0] = firstRowId;
      if (this.editComponent !== null) {
        this.editComponent.view(firstRowId);
      }
      this.emitRequestOneRecord.emit(firstRowId);
    } else {
      if (this.editComponent !== null) {
        this.editComponent.cancel();
      }
    }
  }
  handleListData(data: T[]) {
    return data;
  }
  //#endregion

  //#region 表格
  // 表格分頁
  setGridView() {
    this.gridView = {
      data: this.listItems.slice(this.pageSkipNo, this.pageSkipNo + this.pageSize),
      total: this.listItems.length
    };
  }
  // 分頁切換事件
  pageChange(pageNum: number): void {

    this.pageSkipNo = (pageNum-1)*this.pageSize; // kendo grid page用法
    this.refreshList(); // 更新清單，因為server端資料可能異動
  }
  // 處理grid cell點擊，預設view
  public gridCellClickHandler({ isEdited, dataItem, rowIndex }): void {

    if (this.editComponent !== null) {
      this.editComponent.view(dataItem.Id);
    }

    this.emitRequestOneRecord.emit(dataItem.Id);
  }
  // highlight
  setGridHighlight(data: T) {
    this.selectedItems[0] = data.Id;
  }
  // edit click
  onBtnEditClick(evt: Event, data: T) {
    evt.stopPropagation();
    evt.preventDefault();
    this.setGridHighlight(data);
    if (this.editComponent !== null) {
      this.editComponent.edit(data.Id); // 呼叫edit元件進行編輯作業
    }
  }
  // delete click
  onBtnDeleteClick(data: T) {
    return;
    // this.setGridHighlight(data);
    // // 檢核參數
    // if (!data || !data.Id) {
    //   this.notification.showParameterError(); // 參數錯誤訊息
    //   return;
    // }
    // this.openDeleteDialog(data.Id); // 開啟刪除確認視窗
  }
  //#endregion

  //#region 子元件區
  // 接收Edit Component的存檔異動
  onEditUpdated() {
    // 停留在目前資料
    this.refreshList(); // edit存檔後，清單也更新，最新的結果才會反映出來
  }
  onChildEnterEdit() {
    this.isChildInEdit = true;

  }
  onChildEnterView() {
    this.isChildInEdit = false;
  }
  //#endregion

  ngOnDestroy() {
    this.baseUnsubscribe.next();
    this.baseUnsubscribe.complete();
  }
}
