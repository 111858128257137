import { Injectable } from '@angular/core';
import { WholeHist } from './api-service/hist/whole-hist';
import { UserCache } from './user-cache';
import { DateHelper } from '../shared/helpers/date-helper';
import { NHIRegIC } from './api-service/register/nhiRegIc';
import { PatientsDto } from './api-service/patient/patientsDto';
import { ClinicDataService, NumberValueTextPairBundle, ValueTextPairBundle } from './data-service/clinic-data-service';
import { PatientApi } from './api-service/patient/patient-api';
import { ParameterApi } from './api-service/parameters/parameter-api';
import { HST001Params } from './api-service/parameters/HST001Params';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { WebApiClient } from './web-api-client.service';
import { WholeNHIData } from './api-service/NHI/wholeNHIData';
import { HcrService } from './hcr-service.service';
import { Clinic } from '../system/clinic/models/clinic';
import { ClinicEditComponent } from '../system/clinic/edit/clinic-edit.component';
import { escapeStr, replaceAll } from '../shared/utilities';
import { EasyNotificationService } from './easy-notification.service';
import { ParamGroupEnum } from './data-service/system-param-cache-define';
import { SessionService } from './session.service';

/** 衛星藥局請求方式*/
export enum CaseMethod{
  /** 新增 */
  Add='C1',
  /** 修改重傳 */
  Resend_Modify='UF',
  /** 還卡重傳 */
  Resend_Recard='U$',
}
enum SatellitePharmacyApp{
  /** 展望 */
  VISW='VISW',
  /** 耀聖 */
  RS='RS'
}
@Injectable({
  providedIn: 'root'
})
export class SatellitePharmacyServiceService {

  enumsCodes: NumberValueTextPairBundle = null;
  hist1:HST001Params=null;
  hospId:string = '';
  private port = '7701';
  constructor(
    private clinicData:ClinicDataService,
    private patientApi:PatientApi,
    private parameterApi:ParameterApi,
    private http:HttpClient,
    private hcrService:HcrService,
    private session:SessionService,
    private easyNotification:EasyNotificationService
    ) {
  }
  private host:string = '';
  public get Host(){
      return this.host;
  }
  public async getHost(){
    const useSatellite = Number(this.session.getData('useSatellite'));
    if(!this.host){
      this.hist1 = await this.clinicData.getParam("HST001");
      if (useSatellite == 1) {
        this.host  = this.hist1.SatellitePhamacyIP;
      } else if (useSatellite == 2) {
        this.host = this.hist1.OutVisitSatellitePhamacyIP;
      }
    }
    return this.host
  }
  public async updateHost(host:string){
    //沒在用了，不修改巡診衛星藥局參數
    this.hist1 = await this.clinicData.getParam("HST001");
    this.hist1.SatellitePhamacyIP = host;
    try{
      await this.parameterApi.UpdateParamGroup( ParamGroupEnum.HST001,{'SatellitePhamacyIP':host} as any);
      this.host  = host;
      this.easyNotification.showSuccess('更新成功');
    }catch(e){
      this.easyNotification.showError(e);
    }
  }
  public async trySend(host:string=''){
    this.hist1 = await this.clinicData.getParam("HST001");
    if(!host){
      const useSatellite = Number(this.session.getData('useSatellite'));
      if (useSatellite == 1) {
        this.host = this.hist1.SatellitePhamacyIP+':'+this.port;
        if(this.hist1.SatellitePhamacyIP.indexOf(':')>=0){
          this.host = this.hist1.SatellitePhamacyIP;
        }
      } else if (useSatellite == 2) {
        this.host = this.hist1.OutVisitSatellitePhamacyIP+':'+this.port;
        if(this.hist1.OutVisitSatellitePhamacyIP.indexOf(':')>=0){
          this.host = this.hist1.OutVisitSatellitePhamacyIP;
        }
      }
      host = this.host
    }
    var url = 'http://'+ host +'/uploadAction';
    var ret = await this.hcrService.SendFromUrl(url,'test','application/xml','utf8',{
      'X-TRY':''
    });
    if(!ret.Successed){
      this.easyNotification.showWarning(`衛星藥局連線測試失敗！(${host})`)
    }else{
      this.easyNotification.showSuccess(`衛星藥局連線測試成功！(${host})`)
    }
    return ret.Successed;
  }
  public async send(hist:WholeHist, regIc:NHIRegIC, caseMethod:CaseMethod){
    if(!this.hospId){
      this.hospId = (await this.hcrService.VisionApi.GetHospID()).Data;
    }
    var patientNo = hist.Patient.PatientNo.length == 10 ? hist.Patient.PatientNo.substring(3, 10) : hist.Patient.PatientNo.padStart(7, '0');

    var todayNow = new Date();
    var timeStr = `${todayNow.getHours().toString().padStart(2,'0') + todayNow.getMinutes().toString().padStart(2,'0') + todayNow.getSeconds().toString().padStart(2,'0')}`;
    var createTime = `${todayNow.getFullYear()-1911}${(todayNow.getMonth() + 1).toString().padStart(2,'0')}${todayNow.getDate().toString().padStart(2,'0')}` + timeStr;

    this.enumsCodes = await this.clinicData.getEnum(['Sex','Blood','RH',])
    this.hist1 = await this.clinicData.getParam("HST001");

    const useSatellite = Number(this.session.getData('useSatellite'));
    let satelliteIP: string | undefined;
    if (useSatellite == 1) {
      satelliteIP = this.hist1.SatellitePhamacyIP;
    } else if (useSatellite == 2) {
      satelliteIP = this.hist1.OutVisitSatellitePhamacyIP;
    }
    if (!satelliteIP || satelliteIP.trim() == '') {
      // 不傳
      return {ret:true,msg:''};
    }
    // 送出藥的歸屬 預設2,3 可只選2或3
    let tpyeApplyRx = this.hist1.SatellitePhamacyCodes.length > 0  ? this.hist1.SatellitePhamacyCodes.split(',')
    .map(item => parseInt(item))
    .filter(item => !isNaN(item)): [2,3];
    tpyeApplyRx =  tpyeApplyRx.length <1  ? [2,3]:tpyeApplyRx;
    hist.Hist.HRxs = hist.Hist.HRxs.filter(hrx => tpyeApplyRx.includes(hrx.RxType));
    if(hist.Hist.HRxs.length==0){
      return {ret:true,msg:''};
    }
    await this.clinicData.GetDxOption();
    await this.clinicData.GetRxOption();
    var xml = await this.builXml(hist,regIc,SatellitePharmacyApp.VISW,caseMethod);

    this.host = satelliteIP+':'+this.port;

    if(satelliteIP.indexOf(':')>=0){
      this.host = satelliteIP;
    }
    var url = 'http://'+ this.host +'/uploadAction';

    try{
      var ret = await this.hcrService.SendFromUrl(url,xml,'application/xml','big5',{
        'X-Format':'vis:binary',
        'X-Filename':`${this.hospId}-${patientNo + createTime}.xml`
        // 'X-Filename':`${this.hospId}-${hist.Hist.Id}.xml`
      });
      if(!ret.Successed){
        var msg = ret.Returns?.responseBody;
        console.log(ret.Returns);
        return {ret:false,msg};
      }
      // var resp = await this.http.post(url,xml,{
      //   headers:new HttpHeaders()
      //   .append('Content-Type','application/xml;charset=utf-8')
      //   //.append('Access-Control-Allow-Origin',)
      //   .append('X-Format','vis:binary')
      //   .append('X-Filename', `${this.hospId}-${hist.Hist.Id}.xml`)
      // }).toPromise()

      return {ret:true,msg:''};
    }catch(ex){
      console.log(ex);
      return {ret:false,msg:ex.message};
    }

  }

  public async builXml(hist:WholeHist,regIc:NHIRegIC,app:SatellitePharmacyApp,caseMethod:CaseMethod){
    var patient = await this.patientApi.Get(hist.Patient.Id);

    // var caseMethod:CaseMethod;
    // var app:SatellitePharmacyApp = 'VISW';
    var xml = '<?xml version="1.0" encoding="big5"?>'

    var patientNo = hist.Patient.PatientNo;
    var dateStr = DateHelper.getROCDateTimeString(hist.Register.RegDate).padStart(13,'0');
    var histDate = dateStr.substring(0,7);
    var histTime = dateStr.substring(7);
    // 連續處方簽
    var conPresDt = DateHelper.formatROCDate(hist.Hist.CntPresDate,false,false);
    var conPresCnt = hist.Hist.CntPresNo;
    var conPresTot = hist.Hist.CntPresTot;
    var otherMo = hist.Hist.Isqno??'';
    //就醫識別碼
    var oweTreat= hist.Hist.LackTreatnum
    var preTreat = regIc.Itreatnum;

    xml+=`<case from="${this.hospId}" to="" local_id="${patientNo}" date="${histDate}" time="${histTime}" app="${app}" request_method="${caseMethod}">`
    xml+=this.profileXml(regIc,patient);
    xml+=this.insuranceXml(regIc,hist);
    xml+=this.studyXml(hist);
    xml+=`<continous_prescription start_at="${conPresDt}" count="${conPresCnt}" total="${conPresTot}" other_mo="${otherMo}" />`
    xml+=`<itreatnum owetreat="${oweTreat??''}" pretreat="${preTreat??''}" pretime="${histTime}" />`
    xml+= this.ordersXml(hist);
    xml+='</case>'
    return xml
  }

  private profileXml(regic:NHIRegIC,patient:PatientsDto){
    var patientName = patient.CName;
    var cid = patient.CId;
    var foreigner = 'no';//hist.Patient.Country
    var sex = patient.Sex;
    var birth = DateHelper.getROCDateString(new Date(patient.Birthday)).padStart(7,'0');
    var birth_no = regic.BirthsNum||' ';
    var phone= patient.Phone;
    var mobile = patient.CellPhone;
    var email = patient.EMail;
    var blood = this.enumsCodes['Blood'].find(x=>x.value==patient.BlType)?.text;
    var bloodRh = this.enumsCodes['RH'].find(x=>x.value==patient.BlRH)?.text;
    var family = patient.Family
    var addr = patient.Street;
    var remark = escapeStr(patient.Remark);
    var allergy = escapeStr(patient.DrugAllergy);

    var xml = '<profile>'
    xml+=`<person name="${patientName}" type="0" id="${cid}" foreigner="${foreigner}" sex="${sex}" birth="${birth}" birth_order="${birth_no}" phone="${phone}" family="${family}" mobile="${mobile}" email="${email}" blood="${blood}" blood_rh="${bloodRh}">`
    xml+=`<addr><![CDATA[${addr}]]></addr>`
    xml+=`<remark><![CDATA[${remark}]]></remark>`
    xml+=`<allergy><![CDATA[${allergy}]]></allergy>`
    xml+=`</person>`
    xml +='</profile>'
    return xml;
  }
  private insuranceXml(regIc:NHIRegIC, hist:WholeHist){
    var insurance_type = hist.Register.ICode.replace('00','').replace('0','');
    var icNo =  regIc.Isqno??'';
    var errNo =  regIc.Ierrcd??'';// hist.Register.Icno;
    if(hist.Register.IType=='2' || hist.Register.IType=='0'){
      icNo = '';
    }else if(hist.Register.IType=='1'){
      errNo = '';
    }
    var ApartCode = hist.Hist.ApartCode;
    var caseTp = hist.Hist.Case;
    var payTp = hist.Hist.PayTP;
    var dispTP = hist.Hist.DispTP;

    var xml =`<insurance insurance_type="${insurance_type}" serial_code="${icNo}" except_code="${errNo}" copayment_code="${ApartCode}" case_type="${caseTp}" pay_type="${payTp}" release_type="${dispTP}"/>`

    return xml;
  }
  private studyXml(hist:WholeHist){
    var drCid = hist.Register.DoctorCId;
    var subject = hist.Register.MedDeptCode;

    var cc = escapeStr(hist.Hist.CC);
    var pe = escapeStr(hist.Hist.PE);

    var xml =`<study doctor_id="${drCid}" subject="${subject}">`

    xml+=`<diseases>`;
    var dxs = [hist.Hist.Dx1,hist.Hist.Dx2,hist.Hist.Dx3]
    dxs.filter(x=>x).forEach(x=>{
      var dx = this.clinicData.dxOption.find(y=>y.Code==x)
      var dxName = escapeStr(dx.CName)
      xml+=`<item code="${dx.ApplyCode}" type="ICD10" desc="${dxName}" />`;
    })
    xml+=`</diseases>`;

    xml+=`<treatments>`;
    var sptx = [hist.Hist.SPTX1,hist.Hist.SPTX2,hist.Hist.SPTX3,hist.Hist.SPTX4]
    sptx.filter(x=>x).forEach(x=>{
      xml+=`<item code="${x}" desc="" />`;
    })
    xml+=`</treatments>`;

    xml+=`<chief_complain><![CDATA[${cc}]]></chief_complain>`;
    xml+=`<physical_examination><![CDATA[${pe}]]></physical_examination>`;
    xml+=`</study>`;
    return xml;
  }

  private ordersXml(hist:WholeHist){
    var days= hist.Hist.Days;
    var mill = hist.Hist.HRxs.find(x=>x.Remark.indexOf('磨粉')>-1)?'yes':'no';
    //2:次量, 4:日量, 5:總量
    var dosage_method = this.hist1.DosageRule=='1'?'2':
                        this.hist1.DosageRule=='2'?'4':
                        this.hist1.DosageRule=='3'?'5':'2'
    var xml =`<orders days="${days}" mill="${mill}" dosage_method="${dosage_method}">`
    hist.Hist.HRxs.forEach(x=>{

      var remark =  x.SPRule==1 ? '+' :  //申報不計價
                    x.SPRule==2 ? '*' :  //自費
                    x.SPRule==3 ? '-' :  //送啦！
                    x.SPRule==4 ? '-' :  //送啦！
                    (x.DispTP != '0') && (x.RxType == 4 || x.RxType == 12) ? '$' : //針劑或特材委外
                    '';  // 申報計價
      var localCode = escapeStr(x.RxCode);
      var id = this.clinicData.rxOption.find(y=>y.RxCode==x.RxCode).StdCode;
      var type = x.RxType;
      var dose = x.Dose;
      var dayDose = x.DailyDose;
      var totalDose = x.TotalDose;
      var freq = x.Freq;
      var day = x.Days;
      var way = x.Way;
      var price = x.SPRule==2?x.Price:x.IPrice
      var multiplier = x.Plus??"1.00"
      var memo = escapeStr(x.Remark);
      var name = escapeStr(x.ProdName);
      xml+=`<item remark="${remark}" local_code="${localCode}" id="${id}" type="${type}" `;
      xml+=`divided_dose="${dose}" daily_dose="${dayDose}" total_dose="${totalDose}" `
      xml+=`freq="${freq}" days="${day}" way="${way}" `
      xml+=`price="${price}" multiplier="${multiplier}" memo="${memo}" desc="${name}" />`
    })
    xml+=`</orders>`
    return xml;
  }
}
