import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { AddDay, deepCopy, delay, isNullOrUndefined, NullOrEmpty, Or, replaceAll } from 'src/app/shared/utilities';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { BaseConfig } from 'src/app/services/base-config.service';
import { BasicData } from 'src/app/shared/hcr-lib/BasicData';
import { CriticalIllnesNoHPC } from 'src/app/shared/hcr-lib/CriticalIllnesNoHPC';
import { CriticalIllness } from 'src/app/shared/hcr-lib/CriticalIllness';
import { Cumulative } from 'src/app/shared/hcr-lib/Cumulative';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { DemoIcHelper } from 'src/app/shared/helpers/demoic-helper';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { FontGreatService } from 'src/app/services/font-great.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { HcrService } from 'src/app/services/hcr-service.service';
import { HistActionEnum } from 'src/app/enums/HistActionEnum';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { HistViewComponent } from 'src/app/shared/components/hist/hist-view/hist-view.component';
import { IICHelper, IcHelper } from '../../shared/helpers/ic-helper';
import { IConfirmLeave, RouteInfoService } from 'src/app/services/route-info.service';
import { InitmultiTreatmentUserData, RehabituserCids } from '../muti-treatment/model/rehabitusers';
import { InoculateData } from 'src/app/shared/hcr-lib/InoculateData';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { LocalStoragesService } from 'src/app/services/local-storages.service';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MemoryCache } from 'src/app/services/memory-cache-service';
import { MutiTreatmentListDto } from 'src/app/services/api-service/register/mutiTreatmentListDto';
import { NewBornCreateDto, NewbornViewDto, PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { NewbornNote } from 'src/app/models/NewbornNote';
import { NhiRecord } from 'src/app/services/api-service/NHI/nhiRecord';
import { NHIRegIC } from '../../services/api-service/register/nhiRegIc';
import { NotifyType, SignalRService } from 'src/app/services/signalr-service';
import { OpHelper } from './../helpers/op-helper';
import { PatientApi, PatientPresDto } from 'src/app/services/api-service/patient/patient-api';
import { PaymentMaster } from 'src/app/payments/models/payment-master';
import { ICNoPHOption, RegHistCheckDto, RegHistListDto, RegistEditOpt, RegisterApi, RegisterQueryOptions } from 'src/app/services/api-service/register/register-api';
import { PrescriptionDetailHVE } from 'src/app/shared/hcr-lib/PrescriptionDetailHVE';
import { PrescriptionDetailLongTerm } from 'src/app/shared/hcr-lib/PrescriptionDetailLongTerm';
import { PrescriptionDetailOutPatient } from 'src/app/shared/hcr-lib/PrescriptionDetailOutPatient';
import { RegisterConst, RegisterVaccineConst } from '../../shared/constants/register-const';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { RegisterminiDto } from 'src/app/services/api-service/register/rgisterminiDto';
import { RegisterPregnantSubset } from 'src/app/shared/hcr-lib/RegisterPregnantSubset';
import { RegisterPreventSubset } from 'src/app/shared/hcr-lib/RegisterPreventSubset';
import { RegSignComponent } from '../reg-sign/reg-sign.component';
import { ScheduleSelection } from './../../schedule/models/ScheduleSelection';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeSectionRange } from '../../services/api-service/register/time-section-range';
import { UserCache } from 'src/app/services/user-cache';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { UserRegfee } from 'src/app/services/api-service/opd-other-service/pay-register/UserRegfee';
import { UserSimplifyInfoDTO } from 'src/app/services/api-service/user/uesrSimpleInfo';
import { ValidationTipComponent as ValidTip, ValidationTipComponent } from 'src/app/shared/components/validation-tip/validation-tip.component';
import { ValueTextPair, ValueTextPairNumberValue } from './../../shared/models/value-text-pair';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { WholeLargeHist } from 'src/app/services/api-service/hist/whole-large-hist';
import { PageData, PageData2 } from 'src/app/shared/models/page-data';
import { VitalSign } from 'src/app/services/api-service/hist/vital-sign';
import { PatientRemarkDialogComponent } from 'src/app/shared/components/patients/patient-remark-dialog/patient-remark-dialog.component';
import { PrescriptionSave } from 'src/app/hist/models/prescription-save';
import { ZipCodeHelper } from 'src/app/shared/helpers/zipcode-helper';
import { PostAreaAPI } from 'src/app/services/api-service/postarea-api';
import { Hspid } from 'src/app/services/api-service/hspid/hspid';
import { HspidApi } from 'src/app/services/api-service/hspid/hspid-api';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';
import { SexEnum } from 'src/app/enums/SexEnum';
import { REG001Params } from 'src/app/services/api-service/parameters/REG001Params';
import { RepaymentPesonalInfo } from 'src/app/payments/models/repayment-detail';
import { UserConst } from 'src/app/shared/Constants/user-const';
import { ArrearReceiptComponent } from 'src/app/system/report/components/arrear-receipt/arrear-receipt.component';
import { PresDateCheckInfo } from 'src/app/services/api-service/register/presdatecheckinfo';
import { Discount } from 'src/app/payments/discount/models/discount';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { RegisterService } from 'src/app/services/register-service';
import { DebigNotifyService } from 'src/app/services/debug-notify.service';
import { verifyAllCID } from 'src/app/shared/utilities';

// shared
// import { RouteInfoService } from 'src/app/services/route-info.service';
import { Key, utils } from 'protractor';
// module

interface DoctorRoom {
  roomCode: string;
  doctorId: number;
}
interface DoctorSection {
  sectionCode: string;
  doctorId: number;
}
class RegisterKeep {
  /** 保險身分 */
  ogICode: string = null;
  /** 健保卡卡序 */
  seqNo: string = null;
  /** (療程)卡序 */
  icno: string = null;
  /** 掛號類別,sameTreat */
  sameTreat: string = null;
  /** 就醫類別 */
  nhictp: string = null;
  /** 部份負擔, 切成慢箋會清空，所以要暫存一開始的 */
  ownFee: number = null;
  /** 編輯:原診間 */
  roomCode: string;
  /** 編輯:掛號日期 */
  regDate: Date;
  /** 編輯:時段 */
  regSec: number;
  /** 編輯:原候診號 */
  waitNo: string;
  /** 編輯:掛號類型 */
  state: string;
  /** 身分備註 */
  iNote: string = null;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  animations: [
    trigger('slide_in_out', [
      state('idle', style({
        //width: 'calc(100% - 4rem)',
        top: '0px',
        zIndex: 11
      })),
      state('ready', style({
        // width: '0px',
        display: 'none',
        top: '100%',
        zIndex: 10
      })),

      state('hidden', style({
        // width: '0px',
        //display: 'none',
        top: '0px',
        zIndex: 10
      })),
      transition('ready <=> idle', animate(300)),
      transition('idle <=> hidden', animate(0)),
      transition('hidden <=> ready', animate(0))
    ]),
    trigger('slide_in_out_00', [
      state('slide_in', style({
        width: '100%',
      })),
      state('slide_out', style({
        width: '0px',
        display: 'none'
      })),
      transition('slide_in <=> slide_out', animate(300))
    ])
  ]
})

export class RegisterComponent implements OnInit, OnDestroy, IConfirmLeave, AfterViewInit {

  @Input() isFromCompEnter: boolean = false;
  @Input() changeToSelfPay: boolean = false;
  @Input()
  set openRegId(value: any) {
    if (!isNullOrUndefined(value) && value > 0) {
      this.regId = value;
    }
  };

  @Output() ExitRegisterEvent = new EventEmitter();
  @Output() emitSelect = new EventEmitter<number>();

  @ViewChild('vsComp')
  private vsComp: RegSignComponent;
  @ViewChild('vsComp1')
  private vsComp1: RegSignComponent;

  @ViewChild('histViewComp')
  private histViewComp: HistViewComponent;

  @ViewChild(PatientRemarkDialogComponent)
  patientRemarkDialogComp: PatientRemarkDialogComponent;

  @ViewChild('zipCode')
  zipCodeEl: ElementRef<HTMLInputElement>;

  @ViewChild('newRegisterOut', { static: false })
  newRegisterOutRef: ElementRef<HTMLElement>
  @ViewChild('newRegister', { static: false })
  newRegisterRef: ElementRef<HTMLElement>

  @ViewChild('arrearReceipt') arrearReceiptComponent: ArrearReceiptComponent;

  //#region 地址-郵遞區號相關
  city: ValueTextPair[];
  area: ValueTextPair[];
  areaFilterd: ValueTextPair[];
  // 居住地
  adrHelper: ZipCodeHelper;
  //#endregion

  get isDemoMode(): boolean {
    return this.hcrService.isDemoMode;
  }
  /** UI控制是否顯示卡片區段FLAG */
  isSelfPay: boolean = false;
  gridView = { data: [], total: 0 };
  pageSize: number = BaseConfig.getConfig().ui.pageSize;
  pageSkipNo = 0;
  selectedItems: number[] = [];
  isSameAddressCheck = false;
  isShowPrintArrearReceiptDialog = false;
  printArrearReceiptRegId?: number;
  queryOptions: RegisterQueryOptions;

  //#region 變數
  private unsubscribe: Subject<void> = new Subject();
  hspid: Hspid[] = [];
  //#region -----Options-----
  /** 編輯時需要的選項或數值 */
  editOptions: RegistEditOpt = new RegistEditOpt();
  /** 是否列印掛號單 */
  isPrintReceipt = true;
  /** 卡序選項 */
  registerSeq: ICNoPHOption[];
  icodeSetting: ValueTextPair[] = [];
  registerOther: ValueTextPair[] = [];
  /** 備註選項 */
  iNoteOpt: ValueTextPair[] = [];
  /** 預防保健項目 */
  phOption: ICNoPHOption[];
  /** 預防保健項目(顯示) */
  phOptionAll: ICNoPHOption[];
  /** 保險身分可用卡序 */
  icodeIcnoOption: { [key: string]: ICNoPHOption[] };
  /** 診間名稱/代碼 */
  medRoom: ValueTextPair[] = [];
  /** 醫師名稱/Id */
  doctors: ValueTextPairNumberValue[] = [];
  /** 醫師所在診間 */
  doctorRoomMapping: DoctorRoom[];
  /** 看診時段的醫師 */
  deptMap: DoctorSection[];
  /** 胞胎數量 */
  BirthsNumList: ValueTextPairNumberValue[] = [{ 'text': '1', 'value': 1 }, { 'text': '2', 'value': 2 }, { 'text': '3', 'value': 3 },
  { 'text': '4', 'value': 4 }, { 'text': '5', 'value': 5 }, { 'text': '6', 'value': 6 },
  { 'text': '7', 'value': 7 }, { 'text': '8', 'value': 8 }, { 'text': '9', 'value': 9 }];
  /** 009其他免部分負擔身分可用選項 */
  sameTreatCodeWhenICodeOther = [
    RegisterConst.SameTreat__一般看診,
    RegisterConst.SameTreat_2_預防保健,
    RegisterConst.SameTreat_7_同日同醫,
    RegisterConst.SameTreat_8_慢箋領藥]

  sameTreatCodeWhen居康 = [
    RegisterConst.SameTreat__一般看診,
    RegisterConst.SameTreat_4_月療,
    RegisterConst.SameTreat_8_慢箋領藥]
  sameTreatOpts: ValueTextPair[] = []
  //#endregion


  multiTreatList: MutiTreatmentListDto[] = [];
  onlyonelist: MutiTreatmentListDto = new MutiTreatmentListDto();
  multiTreatmentUser: UserSimplifyInfoDTO[] = [];
  initmultiTreatmentUserData: InitmultiTreatmentUserData;
  allToGetMethod: string[] = [];

  //#region -----route變數-----
  /** 掛號Id，修改用 */
  regId: number;
  /**  病患Id，新增用 */
  patientId: number;
  /** 診間，新增用 */
  room: string;
  /** 是否初診 */
  isFirst: boolean;
  /** 是否做欠卡掛號 */
  isOweCard: boolean;
  /** 是否還卡 */
  isReCardDate: boolean;
  /** 是否有讀卡 */
  isCard: boolean;
  /** 異常代碼 */
  abCode: string;
  /** IC異常掛號最後2碼 */
  abCodeEndWith: string = '';
  /** 是否為報到進入 */
  isCheckin: boolean;
  /** 是否為例外就醫 */
  // isExceptionSeekMedical: boolean = false;
  /** 從門診候診來 */
  fromOpd: any;
  /** 是否為退掛*/
  isCancelRegister: boolean;
  /** 是否虛擬健保卡 */
  isVHC: boolean;
  //#endregion
  isBeforeToday = false;
  isFinish = false;
  //#region -----輔助用-----
  /** 初始資料暫存 */
  typ_xcg_keepReg: RegisterKeep = new RegisterKeep();
  /** 保險身分切換狀態,0:預設,1:自費轉健保條件,2:健保轉自費 */
  nhiConsultConvert: number = 0;
  /** 慢籤次數 */
  preData: PatientPresDto;
  /** 輔助判斷看診日是否變更 */
  _selectDate: Date;
  //#endregion
  /** 復健相關 */
  isRehabilitate = false;
  isRehabilitaion: boolean = false;

  //#region -----UI顯示相關-----
  /** 開啟醫師班表 */
  isOpenCalendar = false;
  /** 狀態/提醒文字 異常顏色 */
  alertColor: string;
  /** 載入完成flag */
  isLoaded = false;
  /** 掛號日期最小值 */
  minRegDate = DateHelper.todayMax;
  /** 掛號日期時間 */
  regDateTime: Date;
  regDateTimeStr: string;
  /** 是否鎖定卡序 */
  icnoLock: 'OTHER' | 'NORMAL' = 'NORMAL';

  /** 是否已經詢問過以健保洗腎 */
  isKidneyDialysisUseNHI = false;

  /** 錯誤的洗腎科別提示 */
  isWrong2B = false;
  is7A = false;
  isOpenPatSpecial = false;
  isEditSPC = false;
  //#endregion

  //掛號費
  originRegisterfee: number;
  //醫師掛號費
  doctorOwnFee: UserRegfee;
  //#region -----Data 相關-----
  /** 初始資料 */
  initReg: RegisterEditDto;
  oriReg: RegisterEditDto;
  /** 寫卡進Table資訊 */
  nhiRegICData: NHIRegIC = new NHIRegIC();

  /** 進入畫面後的 同療程預設值 */
  defaultSameTreat: number = null;
  /** 表單內容 */
  fReg: FormHelper<RegisterEditFormModel>;
  editFormGroup: FormGroup;
  editRemarkFormGroup: FormGroup;
  referralFG: FormGroup;
  checkId = 0;
  checkHistDto = new RegHistCheckDto();
  get reloadICBtnShow() {
    // 欠卡
    return (this.fReg?.Value.IType == RegisterConst.IType_3_OweCard) || false;
  }
  /** 表單值 */
  get editFormValue() {
    return this.editFormGroup.getRawValue();
  }
  get editRemarkFormValue() {
    return this.editRemarkFormGroup.getRawValue();
  }
  /** 是否為新增 */
  get isAdd(): boolean {
    return !this.regId;
  }

  /** 可編輯? 要有initReg且CanEditByDesk,checkCanCancel 為true */
  get canEdit(): boolean {
    if (this.isCancelRegister) {
      return false;
    }

    this.isFinish = this.initReg?.StatusCode == RegisterConst.Code_Status_Finish;
    if (this.initReg != null && !(this.isFinish && this.isBeforeToday)) {
      if (this.initReg.CanEditByDesk() || this.checkCanCancel()) {
        return true;
      }
    }
    return false;
  }
  get canEditStatus(): boolean {
    let finishOrExamining = this.initReg?.StatusCode == RegisterConst.Code_Status_Finish || this.initReg?.StatusCode == RegisterConst.Code_Status_Examining;
    if (finishOrExamining) {
      return false;
    }
    if (this.isCancelRegister) {
      return false;
    }
    if (this.isAdd) {
      return true;
    }
    if ((this.fReg.Value.IType == '3' || this.fReg.Value.ICode == '000') && !this.inputData?.HasHist) {
      return true;
    }
    return false;
  }
  /** 可儲存? WaitNo>0 */
  get canSave(): boolean {
    return this.editFormGroup?.value?.WaitNo;
  }
  //#endregion

  // api服務
  api: WebApiService;
  apiSchedule: WebApiService;
  apiPayRegister: WebApiService;
  apiRegister: WebApiService;
  apiRePay: WebApiService;
  //#endregion

  //#region 變數 route --------------------
  routeRegList = '/registers/reg-list';
  routeOpdList = '/registers/opd-list';
  //#endregion 變數 route --------------------

  //#region 掛號通知參數 --------------------
  // 是否使用SignalR
  isUsingSignal = BaseConfig.getConfig().register.isUsingSignal;
  // 是否由client端發送通知
  isSendNotify = BaseConfig.getConfig().register.isSendNotify;
  //#endregion --------------------

  //#region 前納相關(尚未確認流程)
  /** 流程控制,前納 */
  isBeforeCharge = false;
  //
  payWay: string[] = [];
  isOpenBeforeCharge = false;
  beforeChargeData: PaymentMaster;
  // 是否為居護院所
  isHomeCare: boolean = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  isRecoveryHome: boolean = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
  isOpenMultiTraat = false;
  isSaveClicked = false;
  isBasicCanEdit: boolean = true;

  isHasCard: boolean = false;
  hasMultipltRegister: boolean = false;
  //#endregion

  //#region 棄號用
  prevQueryRegNoState: {
    date: string,
    roomCode: string,
    section: number,
    isAppointment: boolean,
    number: number
  } = null;
  //#endregion

  private inputData: RegisterEditDto;
  pageData: PageData;

  constructor(private fb: FormBuilder,
    private fh: FormHelperService,
    private webApiFactory: WebApiClient,
    private notification: EasyNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private routeInfo: RouteInfoService,
    private signal: SignalRService,
    private printService: LocalPrintService,
    private mainLayoutService: MainLayoutService,
    private hcrService: HcrService,
    private opHelper: OpHelper,
    private icHelper: IcHelper,
    private demoIcHelper: DemoIcHelper,
    private regApi: RegisterApi,
    private patientApi: PatientApi,
    private userConfirmService: UserConfirmService,
    private histApi: HistApi,
    private notify: EasyNotificationService,
    private postAreaAPI: PostAreaAPI,
    private userConfirm: UserConfirmService,
    private localStorage: LocalStoragesService,
    private cache: MemoryCache,
    public fontGreatService: FontGreatService,
    private cd: ChangeDetectorRef,
    private confirmService: UserConfirmService,
    private hspidApi: HspidApi,
    private registerApi: RegisterApi,
    private registerService: RegisterService,
    private clinicDataService: ClinicDataService,
    private debug:DebigNotifyService
  ) {
    this.apiSchedule = this.webApiFactory.createHisService('schedule/shiftschedule');
    this.api = this.webApiFactory.createHisService('hist/histview');
    this.apiSchedule.enableLoader = false;
    this.apiPayRegister = this.webApiFactory.createHisService('opd/PayRegister');
    this.apiRegister = this.webApiFactory.createHisService('registers/register');
    this.apiRePay = this.webApiFactory.createHisService('Payments/Repayment');
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(async (val: Event) => {
      // console.log(val);
      // if (val instanceof NavigationStart) {
      //   const nav: NavigationStart = val;
      //   if (this.isAdd) {
      //     await this.giveUpCurrnt();
      //   }
      // }
    });
    this.routeInfo.comp = this;
  }
  getICHelper(): IICHelper {
    if (this.isDemoMode) {
      this.demoIcHelper.setPatient(this.patientId)
      return this.demoIcHelper;
    } else {
      return this.icHelper;
    }
  }
  confirmExit(): string {
    if (this.editFormGroup.dirty) {
      return '資料尚未儲存，是否離開？';
    }
    return '';
  }

  //#region Initialize
  private parseRoute() {
    // 來自路由的參數
    // 要編輯的regId，若無regId則為新增掛號
    this.regId = parseInt(this.route.snapshot.params['regId']);
    // 要編輯的patientId
    this.patientId = parseInt(this.route.snapshot.params['patientId']);
    // 變數是否有經過讀卡機
    this.isCard = this.route.snapshot.params['isCard']?.toLowerCase() == 'true';
    // 是否做欠卡掛號
    this.isOweCard = this.route.snapshot.params['isOweCard']?.toLowerCase() == 'true';
    // 變數是否為異常掛號
    this.abCode = this.route.snapshot.params['abCode']?.replace('-', '').substring(0, 4);
    // 是否為報到流程
    this.isCheckin = this.route.snapshot.params['isCheckin']?.toLowerCase() == 'true';

    // IC異常掛號'C001'用abCode長度大於4來確認，(對應身分備註欄位)
    if (this.route.snapshot.params['abCode']?.replace('-', '').length > 4) {
      this.abCodeEndWith = this.route.snapshot.params['abCode']?.replace('-', '').substring(4, 6);
    }

    // 變數是否初診
    this.isFirst = this.route.snapshot.params['isFirst']?.toLowerCase() == 'true'
    // 新增時來源的診間
    this.room = this.route.snapshot.params['room'];
    // 新增時來源的功能
    this.fromOpd = this.route.snapshot.params['from']?.toLowerCase() == 'opd';

    //是否進退掛
    this.isCancelRegister = this.route.snapshot.params['isCancelRegister']?.toLowerCase() == 'true';

    //是否有插卡
    this.isHasCard = this.route.snapshot.params['isHasCard']?.toLowerCase() == 'true';

    //是否有選多筆
    this.hasMultipltRegister = this.route.snapshot.params['hasMultipltRegister']?.toLowerCase() == 'true';

    //是否虛擬卡
    this.isVHC = this.route.snapshot.params['isVHC']?.toLowerCase() == 'true';
    this.icHelper.isVICInserted = this.isVHC;
  }

  // 全部
  allItems: RegHistListDto[];
  // 清單物件
  listItems: RegHistListDto[];
  isShowCompleteRegister = true;//BaseConfig.isDebug(); //若是正式台，則預設不顯示「展示模式」
  IsAdmin: boolean;
  /** V/S參數，先搬來這裡，避免componet兩個call兩次*/
  paramHST001: HST001Params;
  paramREG001: REG001Params;
  remarkList: ValueTextPair[];

  async ngOnInit() {
    //隱藏麵包屑
    this.mainLayoutService.setPageTitle(''); // 隱藏page title那一行
    //隱藏選單
    this.mainLayoutService.hideMenu();
    //顯示載入遮罩
    this.mainLayoutService.showLoader();

    this.regDateTime = new Date();
    this.regDateTimeStr = DateHelper.formatDateTime(this.regDateTime)
    //分析路由參數
    this.parseRoute();
    //新增或報到時檢查route/ 非(異卦/有卡/欠卡)時 先將UI設為自費
    this.isSelfPay = (!this.regId || this.isCheckin)
      && !(this.isOweCard || this.isCard || this.abCode);

    if (!this.regId && !this.patientId) {
      this.notification.showParameterError();
      return false;
    }

    //頁面值載入，建立editFormGroup並給予初始值
    this.createEditFormGroup();


    // 轉診來源院所清單
    //await this.getHspid();
    // 取得參數
    this.paramHST001 = await this.clinicDataService.getParam("HST001");
    this.paramREG001 = await this.clinicDataService.getParam("REG001")
    this.queryOptions = await this.registerService.GetQueryOptions();
    this.remarkList = [...this.queryOptions.remark];
    // 新增或編輯要在取得參數之後進行
    await this.getEditOptions();

    try {
      // 載入新增初始資料或編輯的資料
      if (this.isAdd) {
        await this.add(this.patientId);
      } else {
        await this.edit(this.regId);
        // 編輯時不重新由醫師科別對應掛號科別
        // 編輯預設拿掉列印勾勾
        this.isPrintReceipt = false;
      }
    } catch (ex) {
      if (ex == 'RegCancel') {
        return;
      }
    }
    // 保留異掛的特殊INote
    this.keepINote();
    // 非異常掛號時，排除異掛備註的選項
    this.updateINoteOpt();
    // 載入資料以後再重新過濾可用的同僚, 因為資料內的保險身分(其他)會影響到選項
    this.updateSameTreatOpt();
    if (this.initReg.VitalSign) {
      this.initReg.VitalSign.isFemale = this.initReg.PatientSexCode == SexEnum.SexWoman;
      this.initReg.VitalSign.birthday = this.initReg.PatientBirth;
    }

    var selfPay = this.fReg.Value.IType == RegisterConst.IType_0_SelfPay;
    this.editFormGroup.controls['INote'].disable();
    if (selfPay) {  // 自費診
      this.editFormGroup.controls['SameTreat'].setValue(null);
      this.editFormGroup.controls['SameTreat'].disable();
      this.defaultSameTreat = null;
    }
    await this.getICNoOptions();
    if (this.isHomeCare || this.isRecoveryHome) {  // 居護院所不論有無設定診間都要能取號，新增掛號直接帶月療(4) --> 健保身分或重傷病才可以
      if (!selfPay) {
        if (this.isAdd) {
          this.editFormGroup.controls['SameTreat'].enable();
          this.editFormGroup.controls['SameTreat'].setValue('4');
          this.defaultSameTreat = 4;

          this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, this.regDateTimeStr, '');
          this.allToGetMethod = ['4', '8'];
          // 先前端過濾可接受接續的項目
          this.multiTreatList = this.multiTreatList.filter(x => this.allToGetMethod.some(y => y == x.CusTP));
          if (this.multiTreatList.length == 1) {
            this.onlyonelist = this.multiTreatList[0];//.filter(x=>x.CusTP == RegisterConst.SameTreat_4_月療)
            this.isRehabilitaion = this.onlyonelist.CusTP == '1' ? true : false;
            this.isOpenMultiTraat = this.onlyonelist.CusTP == '1' ? true : false;
          } else if (this.multiTreatList.length > 1) {
            this.isOpenMultiTraat = true;
          }
          this.onSameTreatChange({
            value: RegisterConst.SameTreat_4_月療,
            prev: RegisterConst.SameTreat__一般看診
          });
          // this.editFormGroup.controls['SameTreat'].disable();
        }
        else if (!this.isAdd) {
          this.defaultSameTreat = Number(this.editFormValue.SameTreat);
        }
      }
      await this.getRegisterNo();
    } else {
      if (this.editFormValue.RoomCode && this.editFormValue.DoctorId) {
        await this.getRegisterNo();
      }
      let sameTreat = this.editFormValue.SameTreat;
      let isNeedTocall = sameTreat !== null && sameTreat !== undefined && sameTreat !== '' && sameTreat !== '7';
      if (this.isAdd && !selfPay) {
        this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, this.regDateTimeStr, '');
        this.allToGetMethod = ['4', '1', '3', '8', '0'];

        if (this.multiTreatList.length == 1) {
          let tp = '';
          if (this.multiTreatList[0]?.CusTP != '8') {
            this.onlyonelist = this.multiTreatList[0];
            tp = this.multiTreatList[0].CusTP
            this.defaultSameTreat = Number(tp);
            // console.log('tp', tp);
            this.onSameTreatChange({ value: tp, prev: RegisterConst.SameTreat__一般看診 });
          }
          if (tp == RegisterConst.SameTreat_1_復健) {
            if (this.multiTreatmentUser.length < 1) this.multiTreatmentUser = await this.regApi.GetRehabitMedUesrs();
            this.initmultiTreatmentUserData = new InitmultiTreatmentUserData();
            this.initmultiTreatmentUserData.Data = this.onlyonelist;
            this.initmultiTreatmentUserData.Users = this.multiTreatmentUser;
            this.getThreeToggle(1);
            this.initThreeToggle = 1;
            this.isOpenMultiTraat = true;
            this.isRehabilitaion = this.onlyonelist.CusTP == '1' ? true : false;
          }
          else {
            this.initThreeToggle = 0;
            this.getThreeToggle(0);
          }
        } else if (this.multiTreatList.length > 1) {
          this.isOpenMultiTraat = true;
          this.getThreeToggle(1);
          this.initThreeToggle = 1;

        }
        // this.editFormGroup.controls['SameTreat'].disable();
        this.editRemarkFormGroup.controls['PatientRemark'].enable();
      }
      else if (!this.isAdd && !selfPay && isNeedTocall) {

        // regDateTimeStr
        let regDateTime = DateHelper.formatDateTime(this.editFormValue.RegDate)

        this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, regDateTime, sameTreat);
        this.allToGetMethod = [sameTreat];
        if (this.multiTreatList.length == 1) {
          this.onlyonelist = this.multiTreatList[0];
          let tp = this.multiTreatList[0].CusTP
          this.defaultSameTreat = Number(tp);
          // console.log('tp', tp);
          this.onSameTreatChange({ value: tp, prev: RegisterConst.SameTreat__一般看診 });
          if (tp == '1') {
            if (this.multiTreatmentUser.length < 1) this.multiTreatmentUser = await this.regApi.GetRehabitMedUesrs();
            this.initmultiTreatmentUserData = new InitmultiTreatmentUserData();
            this.initmultiTreatmentUserData.Data = this.onlyonelist;
            this.initmultiTreatmentUserData.Users = this.multiTreatmentUser;
            this.getThreeToggle(1);
            this.initThreeToggle = 1;
            this.isOpenMultiTraat = true;
            this.isRehabilitaion = this.onlyonelist.CusTP == '1' ? true : false;
          }
          else {
            this.initThreeToggle = 0;
            this.getThreeToggle(0);
          }
        } else if (this.multiTreatList.length > 1) {
          let isqno = this.editFormValue.Icno;
          this.onlyonelist = this.multiTreatList.find(x => x.Isqno == isqno);
          let tp = this.onlyonelist?.CusTP
          if (tp) this.multiTreatList = [...[], this.onlyonelist]
          this.defaultSameTreat = Number(tp);
          // console.log('tp', tp);
          this.onSameTreatChange({ value: sameTreat, prev: RegisterConst.SameTreat__一般看診 });
          this.isOpenMultiTraat = true;
          this.getThreeToggle(1);
          this.initThreeToggle = 1;
          if (tp == '1') {
            if (this.multiTreatmentUser.length < 1) this.multiTreatmentUser = await this.regApi.GetRehabitMedUesrs();
            this.initmultiTreatmentUserData = new InitmultiTreatmentUserData();
            this.initmultiTreatmentUserData.Data = this.onlyonelist;
            this.initmultiTreatmentUserData.Users = this.multiTreatmentUser;
            this.getThreeToggle(1);
            this.initThreeToggle = 1;
            this.isOpenMultiTraat = true;
            this.isRehabilitaion = this.onlyonelist.CusTP == '1' ? true : false;
          }
          else {
            this.initThreeToggle = 0;
            this.getThreeToggle(0);
          }
        }
      }
      if (this.isAdd) {
        this.checkRepay();
      }
      this.rescale();
    }

    // 不走新增時，patientId會為0，此時再進行部分api如展示模式讀卡會發生Error
    if (!this.isAdd) {
      this.patientId = this.patientId > 0 ? this.patientId : this.editFormValue.PatientId;
    }

    // 初始化發送訊息機制
    this.initSignalForSend();
    this.mainLayoutService.hideLoader();
    this.isSaveClicked = false;
    // 預設資料載完 --> 進畫面
    this.isLoaded = true;
    this.editFormGroup.markAsPristine();
    await this.setIcnoAfterICodeOrSameTreatChange();
    this.setINoteAfterICodeChange();

    this.controlUIEdit();
    //await this.GetProphylacticHealth();
    // 從門診看診畫面開啟 --> 改自費流程
    if (this.changeToSelfPay) {
      this.editFormGroup.controls["ICode"].setValue('000');
      this.onICodeChange('000');
    }
    // 舊病歷區資料(退掛UI沒顯示就不撈)
    if(!this.isCancelRegister){
      // this.getPatient();
      this.nhiRecord = await this.histApi.GetNHIRecord(this.patientId);
      if (this.nhiRecord.PatientId == 0) {
        this.nhiRecord = new NhiRecord();
      } else {
        this.loadPastData();
      }
      this.refreshList();
      await this.getPreDialog();
    }

    this.fontGreater = this.fontGreatService.getFontGreat();
    this.view();

    this.adrHelper = new ZipCodeHelper(this.postAreaAPI, this.userConfirm,
      this.zipCodeEl.nativeElement,
      this.editFormGroup.controls['PatientZipCode'],
      this.editFormGroup.controls['PatientCity'],
      this.editFormGroup.controls['PatientArea'],
      this.editFormGroup.controls['Street']);

    this.adrHelper.onChanged.subscribe(v => {
    });

    this.adrHelper.onAreaChange.subscribe(v => { // 輸入郵遞區號3碼 -> 帶入縣市、鄉鎮市區
      this.city = v.City;
      this.areaFilterd = v.Area;
      // console.log('areaFilterd change')
    });
    this.adrHelper.init();
    //}, 0);
    
    this.keepData();
  }
  //#endregion

  view() {
    this.patientRemarkDialogComp.reloadData(this.patientRemarkSelectedText);
  }
  //#region RWD/字體/畫面相關
  fontGreater: boolean = false;
  async getFontGreater($event) {
    this.fontGreater = await this.fontGreatService.switchFontGreat();
  }

  bigRatio: boolean = window.innerWidth < 1251;
  isPopularScreen: boolean = window.innerWidth >= 1600;
  getRatio() {
    this.bigRatio = window.innerWidth < 1251;
  }
  getPopularScreen() {
    this.isPopularScreen = window.innerWidth >= 1600;
  }

  /** 等比縮放率 */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.getRatio();
      this.getPopularScreen();
      this.bodyHeight = document.body.clientHeight;
      this.rescale();
    }, 500);
  }
  //#endregion

  /** 取得轉診來源院所清單*/
  async getHspid() {
    var data = await this.hspidApi.GetAll();
    var oriHspid = Hspid.mapLevel(data);
    this.hspid = oriHspid.filter(x => x.Htype == 1 || x.Htype == 2);
  }

  refInlevel = '';
  async referralOpen() {
    await this.getHspid()
    this.refDiagFee = true;
  }

  onHspidChange(src: any) {
    this.oriHosp = src;
    var selItem = this.hspid.find(x => x.HID == src);
    this.refInlevel = selItem!.HLEVEL ? selItem!.HLEVEL : '';
  }

  refDiagFeeChange(evt: any) {
    this.refDiagFee = evt.checked;
  }

  onOriDateChange(evt: any) {
    this.oriDate = new Date(evt).toLocaleDateString();
  }

  /** 一般看診未完成先不秀出 */
  isNormalClinicShow = false;

  bodyHeight = document.body.clientHeight;
  registerHeight: string = '';
  sameTreatHeight: string = '';

  ccRmHeight: string = '';


  getCCMinMaxHeight(): string {
    if (this.multiTreatList) {
      if (this.multiTreatList.length > 3) {
        return '156px'
      }
      if (this.multiTreatList.length == 3) {
        return '132px'
      }
      if (this.multiTreatList.length < 3) {
        return '108px'
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getRatio();
    this.getPopularScreen()
  }

  async getPreDialog() {
    
    var lastPreDrugInfo = await this.regApi.GetLastPreDrugInfo(this.patientId, DateHelper.formatDate(this.initReg.RegDate));
    var prelist = this.multiTreatList.filter(x => x.CusTP == '8')
    if (prelist.length > 0) {
      let form = '';
      for (var i = 0; i < prelist.length; i++) {
        this.translatePreList(prelist[i]);
        let formdata = this.PresForm;

        form += ('慢籤第一次為：' +formdata.PresStartRegDate+ ' (用藥開始日：' + formdata.PresStartDateList[0] + ')　' + '卡序：' + formdata.Isqno + '　' + '日份：' + formdata.Days + '\n');
        form += ('主要病名：' + formdata.Dx + '　' + formdata.DxName + '\n');
        form += ('可調劑 ' + formdata.CntPresTot + '次' + '　開藥到期日：' + formdata.PresDeadLine + '　可掛號日期：' + formdata.PresPreviewBackRegDate + '~' + formdata.PresBackRegDate + '\n');
        if (formdata.PresStartDateList[1]) form += ('第二次應調劑日：' + formdata.PresStartDateList[1] + '~' + formdata.PresEndDateList[1] + '\n');
        if (formdata.PresStartDateList[2]) form += ('第三次應調劑日：' + formdata.PresStartDateList[2] + '~' + formdata.PresEndDateList[2] + '\n');
        if (formdata.PresStartDateList[3]) form += ('第四次應調劑日：' + formdata.PresStartDateList[3] + '~' + formdata.PresEndDateList[3] + '\n');
        if (formdata.Late3rdDates) form += ('第三次應調劑日：' + formdata.Late3rdDates + '(依實際領藥日重算)\n');
        if (formdata.Late4thDates) form += ('第四次應調劑日：' + formdata.Late4thDates + '(依實際領藥日重算)\n');
        if (formdata.TruePresBackRegDate && formdata.TruePresPreviewBackRegDate) form += '　可掛號日期：' + formdata.TruePresPreviewBackRegDate + '~' + formdata.TruePresBackRegDate + '(依實際領藥日重算)\n';
        form += '\n\n'
      }
      var check = await this.userConfirmService.showConfirm({
        title: '請確認以下訊息',
        msg: form,
        textYes: '確定',
        textNo: '取消',
        hideNo: true,
        width: 600
      });
      await this.getPreDrugInfo(lastPreDrugInfo);
    }
  }
  async getPreDrugInfo(lastPreDrugInfo: PresDateCheckInfo) {
    if (lastPreDrugInfo?.HadDrugDays > 0) {
      let form2 = '';
      form2 += ('請注意' + '　' + this.initReg.PatientNo + this.initReg.PatientFirstName);
      form2 += ('\n上次已領藥日:' + DateHelper.getFormatedDateString(lastPreDrugInfo.StartAndEnd.StartDate, true, '/') + '，已拿藥' + lastPreDrugInfo?.HadDrugDays + '日餘藥' + lastPreDrugInfo?.RemainingDay + '日');
      if (lastPreDrugInfo?.RemainingDay > 0) { form2 += ('\n下次可領藥日:' + DateHelper.getFormatedDateString(lastPreDrugInfo.StartAndEnd.EndDate, true, '/')); }
      form2 += '\n\n';
      let check2 = this.userConfirmService.showConfirm({
        title: '請確認以下訊息',
        msg: form2,
        textYes: '確定',
        textNo: '取消',
        hideNo: true,
        width: 600
      });
    }
  }
  translatePreList(data: MutiTreatmentListDto) {
    this.PresForm = {
      Isqno: '',
      Days: 0,
      PresDeadLine: '',
      PresPreviewBackRegDate: '',
      PresBackRegDate: '',
      PresStartRegDate:'',
      TruePresBackRegDate :'',
      TruePresPreviewBackRegDate :'',    
      PresStartDateList: [],
      PresEndDateList: [],
      Late3rdDates:'',
      Late4thDates:'',
      CntPresTot: '',
      Dx: '',
      DxName: '',
      ApplyStartDate: '',
      ApplyEndDate: '',
    }
    let firsthist = data?.HistList[0]?.Hist
    this.PresForm.Isqno = data.Isqno;
    this.PresForm.Days = firsthist.Days;
    this.PresForm.PresDeadLine = DateHelper.getFormatedDateString(data.PresDeadline, true);
    this.PresForm.Dx = firsthist.Dx1;
    this.PresForm.DxName = firsthist.Dx1Name;
    this.PresForm.CntPresTot = firsthist.CntPresTot;
    this.PresForm.PresBackRegDate = DateHelper.getFormatedDateString(data.PresBackRegDate, true);
    this.PresForm.PresPreviewBackRegDate = DateHelper.getFormatedDateString(data.PresPreviewBackRegDate, true);
    this.PresForm.ApplyEndDate = DateHelper.getFormatedDateString(data.PresDeadline, true);
    this.PresForm.PresStartRegDate = DateHelper.getFormatedDateString(data.PresStartRegDate, true);
    this.PresForm.TruePresBackRegDate = DateHelper.getFormatedDateString(data.TruePresBackRegDate, true);
    this.PresForm.TruePresPreviewBackRegDate = DateHelper.getFormatedDateString(data.TruePresPreviewBackRegDate, true);

    if (data.PreList?.length > 0) {
      for (var i = 0; i < data.PreList.length; i++) {
        let std = DateHelper.getFormatedDateString(data.PreList[i].StartDate, true);
        let end = DateHelper.getFormatedDateString(data.PreList[i].EndDate, true);
        this.PresForm.PresStartDateList.push(std);
        this.PresForm.PresEndDateList.push(end);
      }
    }
    if (data.LatePreList?.length > 0) {
      for (var i = 0; i < data.LatePreList.length; i++) {
        let std = DateHelper.getFormatedDateString(data.LatePreList[i].StartDate, true);
        let end = DateHelper.getFormatedDateString(data.LatePreList[i].EndDate, true);
        if (data.LatePreList[i].CntPresNo == 3) this.PresForm.Late3rdDates = std + '~' +end;
        if (data.LatePreList[i].CntPresNo == 4) this.PresForm.Late4thDates = std + '~' +end;

      }
    }
  }
  PresForm: {
    Isqno: string;
    Days: number;
    PresDeadLine: string;
    PresPreviewBackRegDate: string;
    PresBackRegDate: string;
    PresStartDateList: string[];
    PresEndDateList: string[];
    TruePresBackRegDate: string; 
    TruePresPreviewBackRegDate: string;   
    Late3rdDates: string;
    Late4thDates: string;
    PresStartRegDate : string;
    CntPresTot: string;
    Dx: string;
    DxName: string;
    ApplyStartDate: string;
    ApplyEndDate: string;
  }
  //#region 慢箋檢查
  // 調查病人是否處於連續慢籤
  async checkPres(patientId: number): Promise<PatientPresDto> {
    try {
      this.preData = await this.opHelper.GetIsPres(patientId)
      if (this.preData) {
        var addDay = (dt: Date, d: number) => {
          var ndt = new Date(dt);
          ndt.setDate(ndt.getDate() + d);
          return ndt;
        }
        var startDate = new Date(this.preData.PreDate);
        var endDate = addDay(startDate, this.preData.Days * this.preData.PreTotal);
        var regEndDate = addDay(endDate, 1);
        var regStartDate = addDay(endDate, -10);
        //var preTakeDate = [];
        var m = `慢箋第一次為${startDate.toLocaleDateString()} 卡序:${this.preData.ICNo} 日份:${this.preData.Days}\n` +
          `主要病名:${this.preData.DxCode} ${this.preData.DxName}\n` +
          `共可調劑${this.preData.PreTotal}次  開藥到期日:${endDate.toLocaleDateString()}  可掛掛號日期:${regStartDate.toLocaleDateString()}~${regEndDate.toLocaleDateString()}\n`;

        for (var i = 1; i < this.preData.PreTotal; i++) {
          var end = addDay(startDate, this.preData.Days * i);
          var st = addDay(end, -10);
          m += `第${i + 1}次應調劑日:${st.toLocaleDateString()}~${end.toLocaleDateString()}\n`;
          //preTakeDate.push({st,end,times:i+1});
        }

        var ret = await this.userConfirmService.showConfirm({
          title: '請確認以下訊息',
          msg: m,// `曾於${preData.PreDate}就醫領藥${preData.PreDesc}，是否慢箋領藥`,
          textYes: '確認',
          textNo: '否',
          //hideNo: true,
          width: 600
        });
        // 自動接續
        if (ret == true) {
          return this.preData;
        } else {
          //不做慢籤就不動作
        }
      }
    } catch (ex) {

    }
    return null;
  }
  //#endregion
  // 由子元件取回Vital Sign資料
  pullVitalSign(regDto: RegisterEditDto) {
    if (regDto.VitalSign) {
      regDto.VitalSign = Object.assign(regDto.VitalSign, this.vsComp.editFV);
    }

  }

  // #region 表單物件-建立,銷毀
  // 建立editFormGroup並給予初始值
  createEditFormGroup() {
    // 先清除再建立
    this.destroyEditFormGroup();
    if (!this.editFormGroup) {
      this.editFormGroup = this.makeFormGroup();
    }
    if (!this.editRemarkFormGroup) {
      this.editRemarkFormGroup = this.makeRemarkFormGroup();
    }
  }

  // 產生表單元件，並定義基本檢核規則
  makeFormGroup(): FormGroup {
    this.fReg = this.fh.Create<RegisterEditFormModel>().build({
      // model
      Id: [0, null],
      PatientId: [0, null],
      RegDate: [DateHelper.today, [Validators.required]],
      WaitNo: ['', null],
      DiscountCode: ['', null],
      ICode: ['', null],
      DoctorId: [0, [Validators.required]],
      //RoomCode: ['', Validators.required],
      RoomCode: ['', !this.isHomeCare ? [Validators.required] : null],
      MedDeptCode: ['', [Validators.required]],
      TimeSec: [0, [ValidTip.enumRequiredValidator()]],
      VisitSourceCode: ['', null],
      StatusCode: ['', [Validators.required]],
      RegSourceCode: ['', null],
      PaymentStatusCode: ['', null],
      StatusRemark: ['', null],
      Remark: ['', [Validators.maxLength(1000)]],
      INote: ['', null],
      IsEditPatient: [false, null],
      PatientNo: ['', null],
      PatientFirstName: ['', [Validators.required, Validators.maxLength(50)]],
      PatientBirth: [new Date(0, 0, 1), null],
      PatientCId: ['', [Validators.maxLength(20)]],
      PatientSexCode: [0, [Validators.required]],
      PatientSexName: ['', null],
      PatientPhoneHomeArea: ['', [Validators.maxLength(5)]], //Validators.pattern(ValidTip.patternNumber)]],  // 非必填欄位
      PatientPhoneHome: ['', [Validators.maxLength(20)]], //Validators.pattern(ValidTip.patternPhone)]],      // 非必填欄位
      PatientMobile: ['', [Validators.maxLength(20)]], //Validators.pattern(ValidTip.patternPhone)]],         // 非必填欄位
      PatientStreet: ['', [Validators.maxLength(200)]],
      PatientCity: ['', [Validators.maxLength(50)]],
      PatientArea: ['', [Validators.maxLength(50)]],
      PatientZipCode: ['', [Validators.maxLength(50)]],
      Nhictp: ['', null],
      Isqno: ['', null],
      Deposit: [0, null],
      OwnFee: [0, null],
      RegisterFee: [0, null],
      SelfPay: [0, null],
      IType: ['', null],
      SameTreat: ['', null],
      Icno: ['', null],
      RemarkIC: ['', null],
      AvailableCount: ['', null],
      nhiConsultConvert: [0, null],
      MonCId: ['', null],
      MonBirthdate: [null, null],
      NewbornNote: [null, null],
      IsMiddleLowIncome: [false, null],
      SpcIdentity: ['', null],
    })
    var fg = this.fReg.FormGroup;

    this.regDateTime = new Date();

    return fg;
  }
  makeRemarkFormGroup(): FormGroup {
    const fg: FormGroup = this.fb.group({
      PatientRemark: ['', Validators.maxLength(1000)]
    });
    return fg;
  }

  // 移除editFormGroup
  destroyEditFormGroup() {
    if (this.editFormGroup) {
      // 如果不reset，kendo dropdownlist等元件的值不會清除
      this.editFormGroup.reset(); // 清除form group資料
    }
  }
  // #endregion

  //#region 新增
  async add(patientId: number) {
    await this.getInitialDataForAdd(patientId);

    // 初始直搞定 先暫存各式各樣的東西
    // 取得當診醫師 診間資料
    // Task 11932: 若從門診掛號進入，且診間、時段設定'全部'，診間就依照目前登入的醫師，時段依照目前時間與時段參數關係判斷
    if (this.fromOpd && !this.room) {
      this.editFormGroup.controls['DoctorId'].setValue(UserCache.getLoginUser().UserId);
    }
    await this.refreshDoctorRoom(this.editFormValue.DoctorId);

    await this.getLargeChargePayment()
    if (this.isNewBornReg) {
      await this.getRegisterNo();  // WaitNo不可為0，一定要取號
      this.editFormGroup.controls['ICode'].disable();    // 新生兒依附掛號一定是健保身分
    }
  }

  /**
   * 處理相關費用preview to UI
     取預設付費資訊
     判斷是否有:
     1. 基資有優待直接帶入 -> 結束
     2. 基資無優待 -> 做下面的判斷
        2-1. 保險身分為A --> 'TYPE8'(慢箋掛號費) <-- 優先
                            'OVRG'(超過年齡優待掛號費) (年齡優待擇優帶入)
        2-2. 其他保險身分直接用參數的設定計算掛號費
   */
  async getLargeChargePayment() {
    var defDiscountCode = this.editFormGroup.controls['DiscountCode'].value;
    // 基資有優待(或已切換優待身分)直接帶入 -> 結束
    if (defDiscountCode == null || defDiscountCode == undefined || defDiscountCode == '') {
      var defICode = this.editFormGroup.controls['ICode'].value;
      // 保險身分為A --> 'TYPE8'(慢箋掛號費) <-- 優先
      //                'OVRG'(超過年齡優待掛號費) (年齡優待擇優帶入)
      if (defICode == 'A') {
        var discountCode = '';
        var needUseDiscForSameTreat = (this.editFormGroup.controls['SameTreat'].value == '8');
        if (needUseDiscForSameTreat && this.editOptions.discount.findIndex(x => x.value == 'TYPE8') > 0) {
          var disc = await this.regApi.GetDiscountByCode('TYPE8');
          if (disc) {
            this.editFormGroup.controls['DiscountCode'].setValue('TYPE8');
            discountCode = 'TYPE8';
          }
        } else {
          discountCode = await this.getBestDiscount();  // 年齡優待擇優帶入
        }
        if (discountCode == '') {
          this.editFormGroup.controls['DiscountCode'].setValue(defDiscountCode);
          discountCode = defDiscountCode;
        } else {
          this.editFormGroup.controls['DiscountCode'].setValue(discountCode);
        }
        await this.getChargePaymentInfo(discountCode);
      } else {  // 其他保險身分直接用參數的設定計算掛號費
        await this.getChargePaymentInfo();
      }
    } else {
      await this.getChargePaymentInfo(defDiscountCode);
    }
  }

  // 年齡優待擇優帶入
  async getBestDiscount(): Promise<string> {
    this.calculateAge(DateHelper.formatDateTime(this.editFormGroup.controls['PatientBirth'].value));
    var oriRegFee = this.editFormGroup.controls['RegisterFee'].value;
    // if (oriRegFee > 0) {  // 掛號費已是0元，無須優待 (2024/07/30: 因init已經改沒先給預設的掛號費，所以，這邊不再判斷掛號費是否已經為0，避免不更新掛號費)
    var ageDiscounts: Discount[] = await this.regApi.GetAgeDiscount();
    var discountCode = '';
    var useOVRG = false;
    var finalRegFee = 999;
    var BreakException = {};
    try {
      ageDiscounts.forEach(disc => {
        var ageSetting = disc.Ages;
        var compType = disc.AgeCompareType;
        var discType = disc.DiscType;           // 0: 減免金額，1: 折扣百分比，2: 固定收取金額
        var discRegFee = disc.RegisterFee;      // 折扣金額 (減收金額)
        var discRate = disc.DiscountRate;
        var fixRegFee = disc.FixedRegisterFee;  // 固定收取金額
        if (compType == 1) {
          if (this.howOld >= ageSetting) {
            useOVRG = true;
          } else {
            useOVRG = false;
          }
        } else if (compType == 2) {
          if (this.howOld <= ageSetting) {
            useOVRG = true;
          } else {
            useOVRG = false;
          }
        }

        if (useOVRG) {
          // 取折扣
          var calcRegFee = 0;
          if (discType == 0) {
            calcRegFee = ((oriRegFee - discRegFee) < 0 ? 0 : (oriRegFee - discRegFee));
          } else if (discType == 1) {
            calcRegFee = oriRegFee * (1 - (discRate / 100));
          } else if (discType == 2) {
            calcRegFee = fixRegFee
          }
          if (calcRegFee < finalRegFee) {
            finalRegFee = calcRegFee;
            discountCode = disc.Code;
          }
          if (finalRegFee == 0) throw BreakException;  // 掛號費為0 => 終止迴圈
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }

    return discountCode;
    // }
  }

  // 由server取得新增所需資料並填充到表格
  async getInitialDataForAdd(patientId: number) {
    try {
      var data: RegisterEditDto = await this.regApi.GetInitialDataForAdd(patientId, this.room);
      // 前次醫師不在班表時,改預設(>診間醫師>留空)
      if (!this.doctors.some(d => d.value == data.DoctorId)) {
        data.DoctorId = 0;  //this.doctorRoomMapping.find(r=>r.roomCode == this.room).doctorId
      }

      // C001 異常掛號，取得對應的身分備註預設值
      if (this.abCodeEndWith.length > 0) {
        data.INote = this.editOptions.inote.find(entry => entry.value.includes(this.abCodeEndWith)).value;
      }

      this.initReg = data;
      this.oriReg = deepCopy<RegisterEditDto>(this.initReg);
      await this.fillFormData(data); // 填充表格資料
      //新增才做些有的沒的
      if (data.Id == 0) {
        await this.patchDataByRegType(data);
      }
      this.displayNewBorn = data.NewbornNote == null;
      // 復健
      if (!this.isFirst && patientId && data.IsRehabilitate) {
        this.isRehabilitate = true;
        this.setIcno(data.NHIRegICDto.Isqno);
      }

    } catch (ex) {
      this.notification.showError(ex); // 顯示查詢失敗訊息
      this.cancel();
      throw ('RegCancel')
    }
  }

  displayNewBorn = false;
  Icdt = null;
  // 填入初始化用的值
  async fillFormData(data: RegisterEditDto) {
    if (!data) {
      return;
    }
    this.Icdt = data.Icdt;
    this.regDateTime = new Date(data.RegDate);
    this.editFormGroup.patchValue({
      // model
      Id: data.Id,
      PatientId: data.PatientId || 0,
      RegDate: new Date(data.RegDate),
      WaitNo: data.WaitNo || 0,
      DiscountCode: data.DiscountCode || null,

      RoomCode: data.RoomCode || '',
      MedDeptCode: (data.MedDeptCode && data.MedDeptCode.length == 0) ? this.editOptions.section[1].value : '',
      INote: data.INote || '',
      // 新增資料會從Server端當前時間計算
      TimeSec: data.TimeSec || 0,
      VisitSourceCode: data.VisitSourceCode || 'R', // F初診,R複診
      StatusCode: data.StatusCode || '',
      RegSourceCode: data.RegSourceCode || '',
      PaymentStatusCode: data.PaymentStatusCode || '',
      IsEditPatient: false,
      PatientNo: data.PatientNo || '',
      PatientFirstName: data.PatientFirstName || '',
      PatientSexCode: data.PatientSexCode || 0,
      PatientSexName: data.PatientSexName || '',
      PatientBirth: new Date(data.PatientBirth) || null,
      PatientCId: data.PatientCId || '',
      PatientPhoneHomeArea: data.PatientPhoneHomeArea || '',
      PatientPhoneHome: data.PatientPhoneHome || '',
      PatientMobile: data.PatientMobile || '',
      PatientStreet: data.PatientStreet || '',
      PatientCity: data.PatientCity || '',
      PatientArea: data.PatientArea || '',
      PatientZipCode: data.PatientZipCode || '',
      Deposit: 0,
      OwnFee: '',
      RegisterFee: '',
      SelfPay: '',
      SameTreat: Or(data.SameTreat, ''),
      Isqno: data.NHIRegICDto?.Isqno || '',
      MonCId: data.MonCId || '',
      MonBirthdate: data.MonBirthdate || null,
      NewbornNote: data.NewbornNote,
      SpcIdentity : data.SpcIdentity || '',
    });
    this.editRemarkFormGroup.patchValue({
      PatientRemark: data.PatientRemark || ''
    });

    if (this.isOweCard) {
      this.nhiConsultConvert = 1;
    }
    this.keepINote();
  }

  /** 跟掛號方式有關的欄位初始化 */
  async patchDataByRegType(data: RegisterEditDto) {
    this.fReg.patchValue({
      IType: this.isOweCard ? RegisterConst.IType_3_OweCard
        : (data.IType || (this.abCode ? RegisterConst.IType_2_Abnormal
          : this.isCard ? RegisterConst.IType_1_IC
            : RegisterConst.IType_0_SelfPay)),
      // 欠卡、讀卡或異掛 => 以前次完診的保險身分為預設值, 此外預設自費
      ICode: this.isOweCard ? data.ICode : (this.isCard || this.abCode?.length > 0 ? data.ICode : RegisterConst.ICode_000_SelfPay),
      DoctorId: (this.isHomeCare || this.isRecoveryHome) ? UserCache.getLoginUser().UserId : (data.DoctorId || 0),
      nhiConsultConvert: this.isOweCard ? 1 : this.nhiConsultConvert,
      Nhictp: this.isOweCard ? '' : (data.Nhictp || this.opHelper.SwitchMedicalType(UserCache.getLoginUser().Clinic.Code) || ''),
    });
    if (!this.isOweCard && !this.abCode && this.isCard) {
      if (await this.patchDataFromICCard(data.ICode)) {
        this.editFormGroup.patchValue({
          SameTreat: Or( data.SameTreat , ''),
        });
      }
    } else {
      await this.patchDataNoCard(this.fReg.Value.IType)
      this.editRemarkFormGroup.patchValue({
        PatientRemark: data.PatientRemark || ''
      });
    }
  }
  /** 取卡並更新相關欄位 */
  async patchDataFromICCard(ICode: string): Promise<boolean> {
    // 根據是否為展示模式取得IICHelper
    var icHelper = this.getICHelper();
    //非欠卡,非異常 正常取卡
    var icRegData = await icHelper.getRegisterBasicAndUpdateAvailableCountIfNeeded();
   
    if (icRegData.CId != this.fReg.Value.PatientCId) {
      var a = await this.userConfirm.showConfirm({
        title: '警告',
        msg: `身分證不一致！是否繼續？\n\n健保卡: ${icRegData.CId}\n患者: ${this.fReg.Value.PatientCId}`
      })
      if (!a) {
        return false;
      }
    }

    /** 進入畫面後的 ICode 預設值 */
    var defaultICode = 'A';
    // 根據卡片讀出來的身分進行設定 健保=3、福保=1、榮保=2、中低=4，優先順序1243，8=災民
    if (icRegData.insurerflag == '1' || icRegData.insurerflag == '2') {
      defaultICode = icRegData.insurerflag == "1" ? "003" : "004";
    } else {
      defaultICode = ICode ?? "A";
    }

    var icData = { latestSeqNum: this.fReg.Value.Isqno, lastSeqNum: '' };
    if (!this.isRehabilitate) {
      icData = await icHelper.getLastSeqNum();
    };
    var statusRemark = this.opHelper.GetRemark(this.isFirst, true, 1, 0, this.abCode, '1') ?? '';
    if (statusRemark.length > 0 && !this.isRehabilitate) {
      statusRemark = statusRemark.replace('初診', '');
    }

    this.fReg.patchValue({
      // 掛號基本資料
      ICode: defaultICode,
      IsMiddleLowIncome: icRegData.insurerflag == '4',
      RemarkIC: this.opHelper.InsurerFlagMapToHisICode(icRegData.insurerflag).text || '',
      AvailableCount: icRegData.availablecnt.toString() || '',
      StatusRemark: statusRemark || '',
      // 就醫序號 = 卡序 = 預先計算卡片的下一號
      Icno: icData.latestSeqNum || '',
      Isqno: icData.latestSeqNum || '',
      nhiConsultConvert: this.nhiConsultConvert,
      IType: '1',
      Nhictp: '01'
    });
    // 掛號基本資料保存
    this.nhiRegICData.Cid = icRegData.CId;
    this.nhiRegICData.Birthdate = icRegData.Birth;
    this.nhiRegICData.Icno = icRegData.IcNo;
    // 刷卡完紀錄刷出來的預算卡序
    this.keepIsqNo();
    this.keepIcno();
    return true;
  }
  /** 報到更新相關欄位 */
  patchDataForCheckIn() {
    if (this.fReg.Value.ICode != RegisterConst.ICode_000_SelfPay) {
      // 預約掛號的SameTreat預設空值，就醫類別應為01 一般候診
      // 統一程式寫法，使用同僚對應的就醫類別，雖然第二個參數傳true只會回傳01...唉
      var nhiType = this.opHelper.DefineItp(this.fReg.Value.SameTreat, true)
      this.fReg.patchValue({
        Nhictp: nhiType
      });
    }

    this.fReg.patchValue({
      StatusCode: 'A',
    });
  }
  /** 無卡更新相關欄位  */
  async patchDataNoCard(itype: string) {
    var statusRemark = this.opHelper.GetRemark(this.isFirst, false, 0, 0, this.abCode, itype) ?? '';
    var inote = "";
    if (itype == RegisterConst.IType_2_Abnormal && this.abCodeEndWith.length > 0) {
      inote = this.editOptions.inote.find(entry => entry.value.includes(this.abCodeEndWith)).value;
    }
    this.fReg.patchValue({
      nhiConsultConvert: this.nhiConsultConvert,
      StatusRemark: statusRemark || '',
      IType: itype,
      //異掛填異常代碼
      Isqno: itype == RegisterConst.IType_2_Abnormal ? this.abCode : '',
      // 非居護、康家，[類別]應回到[一般門診]，居護或康家[類別]應設回[月療]
      SameTreat: (this.isHomeCare || this.isRecoveryHome) ? RegisterConst.SameTreat_4_月療 : RegisterConst.SameTreat__一般看診,
      // 無卡下面清空 異掛填異常代碼
      Icno: itype == RegisterConst.IType_2_Abnormal ? this.abCode : '',
      Nhictp: itype == RegisterConst.IType_2_Abnormal ? this.fReg.Value.Nhictp : '',
      RemarkIC: '',
      AvailableCount: '',
      INote: inote
    });
    if (itype == RegisterConst.IType_2_Abnormal) {
      await this.revertINote();
      this.keepIsqNo();
      this.keepIcno();
      //this.keepINote();
    }
    if (itype == RegisterConst.IType_0_SelfPay) {
      this.editFormGroup.controls['SameTreat'].disable();
    }
  }
  //#endregion

  //#region 編輯
  async edit(id: number) {
    // this.notification.showInfo('edit');
    await this.getOneRecord(id); // 由server端抓取此筆資料
    await this.refreshDoctorRoom(this.editFormValue.DoctorId);
    //this.keepData();
    // console.log('edit');
  }

  // 由server取得單筆資料並填充到表格
  async getOneRecord(id: number) {
    try {
      var data = await this.regApi.Get(id);
      let today = new Date();
      this.isBeforeToday = DateHelper.getDate(today) > DateHelper.getDate(data.RegDate)
      this.initReg = data;
      this.oriReg = deepCopy<RegisterEditDto>(this.initReg);
      this.oriHosp = this.oriReg.OriHosp;
      this.oriDate = this.oriReg.OriDate;
      this.refDiagFee = this.oriReg.RefDiagFee;
      this.patientId = this.initReg.PatientId;
      const myStatus: ValueTextPair = this.editOptions.fullStatusCode.filter(x => x.value === this.initReg.StatusCode)[0];
      if (myStatus) {
        this.initReg.StatusName = myStatus.text;
      } else {
        this.initReg.StatusName = '';
      }

      var isqno = data.NHIRegICDto?.Isqno;
      this.mappingEditForm(data); // 填充表格資料
      this.alertColor = 'blue';
      // 預防保健ICXX會踩到這個規則，加入為異常掛號才剖析異常代碼的條件
      if (data.IType == RegisterConst.IType_2_Abnormal) {
        let regexp: RegExp = /[A-Z]/
        if (regexp.test(isqno)) {
          this.abCode = isqno
        }
      }

      this.isReCardDate = (data.ReCardDate != undefined && data.ReCardDate != null);
      if (this.isCheckin) {
        await this.patchDataByRegType(data);
        this.patchDataForCheckIn();
        await this.getLargeChargePayment();
      }
    } catch (ex) {
      console.log("getOneRecordException", ex);
      this.notification.showGetDataFailed('register : getOneRecord'); // 顯示查詢失敗訊息
    }
  }
  // 填入編輯的資料
  mappingEditForm(data: RegisterEditDto) {
    if (!data) {
      return;
    }
    this.inputData = data;
    this.pageData = {
      data: [data],
      total: 1,
      totalPages: 1
    }
    if (data.Remark) { // 如果原資料有remark資料，檢查是否該加入下拉選單
      this.updateRemarkOptions(data.Remark);
    }
    this.Icdt = data.Icdt;
    this.regDateTime = Or(new Date(data.RegDate), null);
    this.editFormGroup.patchValue({
      Id: data.Id || 0,
      PatientId: data.PatientId || 0,
      PatientNo: data.PatientNo || '',
      PatientFirstName: data.PatientFirstName || '',
      PatientCId: data.PatientCId || '',
      PatientBirth: new Date(data.PatientBirth) || null,
      PatientSexCode: data.PatientSexCode || 0,
      PatientSexName: data.PatientSexName || '',
      PatientPhoneHomeArea: data.PatientPhoneHomeArea || '',
      PatientPhoneHome: data.PatientPhoneHome || '',
      PatientMobile: data.PatientMobile || '',
      PatientStreet: data.PatientStreet || '',
      PatientCity: data.PatientCity || '',
      PatientArea: data.PatientArea || '',
      PatientZipCode: data.PatientZipCode || '',
      RemarkIC: '',
      Isqno: data.NHIRegICDto?.Isqno || '',
      AvailableCount: '',
      StatusRemark: '',
      Remark: data.Remark || '',
      RegDate: new Date(data.RegDate) || null,
      ICode: data.ICode,
      Nhictp: data.Nhictp || this.opHelper.SwitchMedicalType(UserCache.getLoginUser().Clinic.Code) || '',
      Icno: data.Icno || '',
      TimeSec: data.TimeSec || '',
      RoomCode: data.RoomCode || '',
      DoctorId: data.DoctorId || '',
      StatusCode: data.StatusCode || '',
      WaitNo: data.WaitNo || '',
      VisitSourceCode: data.VisitSourceCode || '',
      DiscountCode: data.DiscountCode || '',
      Deposit: data.Deposit || 0,
      RegisterFee: data.RegisterFee || 0,
      SelfPay: data.SelfPay || 0,
      OwnFee: data.OwnFee || 0,
      MedDeptCode: data.MedDeptCode || '',
      INote: data.INote || '',
      RegSourceCode: data.RegSourceCode || '',
      PaymentStatusCode: data.PaymentStatusCode || '',
      IsEditPatient: false,
      IType: data.IType || '',
      SameTreat: data.ICode == '000' ? '' : Or(data.SameTreat, ''),
      nhiConsultConvert: this.nhiConsultConvert,
      MonCId: data.MonCId || '',
      NewbornNote: data.NewbornNote || {},
    });
    this.realRemark = (data.Remark || '');
    this.editRemarkFormGroup.setValue({
      PatientRemark: data.PatientRemark || ''
    });
    this.isSelfPay = this.initReg.ICode == '000';
    this.originRegisterfee = data.RegisterFee;
    this.keepIcno();
    this.keepIsqNo();
  }
  //#endregion

  updateRemarkOptions(evt: string) {
    var haveValue = this.remarkList.findIndex(x => x.value == evt);
    if (haveValue < 0) {
      var kv = new ValueTextPair();
      kv.text = evt;
      kv.value = evt;
      this.remarkList.push(kv);
      this.remarkList = [...this.remarkList];  //更新選單內容
    }
  }

  //#region
  updateINoteOpt() {
    this.iNoteOpt = this.editOptions.inote;
    // 非異常下把異掛的備註拿掉
    if (this.abCodeEndWith.length == 0) {
      this.iNoteOpt = this.iNoteOpt.filter(entry => !entry.value.startsWith('='));
      //this.iNoteOpt = this.editOptions.inote.filter(entry => entry.value.startsWith('='));
    }
  }
  //#endregion

  //#region 儲存
  // 儲存
  async onSaveClick() {
    this.isSaveClicked = true;
    this.mainLayoutService.showLoader();
    var tmpRegDate = this.editFormGroup.controls['RegDate'].value;
    // 欠卡、異常掛號，檢查看診日是否被往前調，跳提醒=>可能會造成IC2.0每日上傳之就醫識別碼錯誤
    if (tmpRegDate) {
      var regDate = DateHelper.getROCDateString(new Date(tmpRegDate));
      var nowDate = DateHelper.getROCDateString(new Date());
      // 預約過去日期
      if (this.fReg.Value.StatusCode == RegisterConst.Code_Status_Appointment
        && new Date(tmpRegDate) < DateHelper.today) {
        this.mainLayoutService.hideLoader();
        var check = await this.userConfirmService.showConfirm({
          title: '提醒訊息',
          msg: '您選擇的預約時間已經過去。是否確定要以所選的時間進行掛號操作？',
          textYes: '繼續',
          textNo: '返回',
          width: 400
        });
        if (!check) {
          this.isSaveClicked = false;
          return;
        } else {
          this.mainLayoutService.showLoader();
        }
      }
      // 欠卡掛號，先檢查身分證號是否正確? 不正確會因此取不到就醫識別碼，完診時需用MISS0000 0000 0000 0000 加 備查單，所以這邊要提醒用戶
      if (this.isAdd && this.isOweCard) {
        this.mainLayoutService.hideLoader();
        if (!verifyAllCID(this.fReg.Value.PatientCId)) {
          var goAhead = await this.userConfirmService.showConfirm({
            title: '提醒訊息',
            msg: '注意：身分證號格式不正確，完診時恐無法正確取得就醫識別碼，屆時應填寫「無實際就醫識別碼補卡上傳報備單」向\n健保署分區業務組登錄報備，並說明未取得就醫識別碼原因。\n\n是否【以欠卡繼續】還是【返回】以自費或異常方式掛號',
            textYes: '以欠卡繼續',
            textNo: '返回',
            width: 400
          });
          if (!goAhead) {  // 停止掛號(儲存)
            this.isSaveClicked = false;
            return;
          } else {
            this.mainLayoutService.showLoader();
          }
        }
      } 
      if ((this.isAdd && this.isOweCard) || (this.isAdd && this.abCode && this.abCode.length > 0)) {
        // 檢查看診日是否不等於今天
        if (regDate != nowDate) {
          this.mainLayoutService.hideLoader();
          var check = await this.userConfirmService.showConfirm({
            title: '提醒訊息',
            msg: '注意：更改看診日期，可能會影響IC2.0每日上傳之就醫識別碼，造成檢核錯誤',
            textYes: '確定',
            textNo: '取消',
            width: 400
          });
          if (!check) {
            this.isSaveClicked = false;
            return;
          } else {
            this.mainLayoutService.showLoader();
          }
        }
      }
    }

    if (this.validateBeforeSave()) {
      if (this.isRehabilitaion && this.initThreeToggle == 1) {
        // this.notification.showWarning('多療程尚未實作，請先切換一般或其他流程！')
        this.multiTreatSave(this.onlyonelist);
        // this.mainLayoutService.hideLoader();
        // this.isSaveClicked = false;
      }
      else if (this.editFormGroup.controls['SameTreat'].value == '2' && this.batchPhOpt?.length > 0) {
        this.phBatchRegister(); // 預保掛號
      }
      else this.save(this.editFormGroup.getRawValue());  // 一般掛號
    }
  }

  //退掛
  async onCancelRegiestClick() {
    await this.revertRegister();
  }

  async revertRegister() {
    var icHelper = this.getICHelper();
    // 1. 是否已經完成退掛，不能再退掛
    if (this.inputData.Tpk2m == 'H' || this.inputData.Tpk2m == 'F') {
      this.openAlertCanNotRevertAgainNotify();
      return;
    }

    // 2. 檢查是否在24hrs內，掛號超過24hrs不可退掛
    // ***** IC卡掛號的要用Icdt(刷卡日期時間)來判斷
    if (this.isHasCard) {
      if (this.inputData.Icdt) {
        var dt = this.icdtTODate(this.inputData.Icdt.toString());
        if (!this.checkCanCancelDate(dt)) {
          this.openAlertCanNotDeleteNotify();   // 彈出提醒dialog
          return;
        }
      } else {
        // 無刷卡紀錄就直接判斷系統的掛號日期
        if (!this.checkCanCancelDate(this.inputData.RegDate)) {
          this.openAlertCanNotDeleteNotify();   // 彈出提醒dialog
          return;
        }
      }
    } else {
      // 無刷卡紀錄就直接判斷系統的掛號日期
      if (!this.checkCanCancelDate(this.inputData.RegDate)) {
        this.openAlertCanNotDeleteNotify();   // 彈出提醒dialog
        return;
      }
    }
    // 已經進行過病歷全刪的，不再進行刪除病歷動作||提醒
    if(this.inputData.Tpk2m !== RegisterConst.TPK2M_D刪除病歷未退掛){
      // 3. 檢查此筆掛號紀錄是否已有看診的病歷資料? -> Task 13384: 參數系統退掛是否可以連同刪除病歷
      if (this.paramREG001.RevertWithCancelHist == 2) {  // 2-同步刪除病歷
        var result = await this.histApi.DeleteHist(this.inputData.Id);
        if (!result.IsSuccess) {
          this.notification.showError(result.Message);
          return;
        }
      } else { // 1-需自行刪除病例才可做退掛
        if (this.inputData.HasHist) {
          this.userConfirmService.showConfirm({
            msg: '請先進診間將病歷刪除後方可進行退掛。',
            title: '病歷已存在',
            hideNo: true
          });
          return;
        }
      }
    }


    // 4. 確認是否已取過卡序(就醫序號) * 退掛自費身分的掛號不會讀健保卡
    var hasSeqNumber = this.inputData.ICode != "000" && this.inputData.Icno != "" && this.inputData.Icno.indexOf("IC") < 0;
    if (hasSeqNumber) {
      // 5. 檢查是否有插卡?
      if (this.isDemoMode || icHelper.getICConn()) {  // 有卡 -> 取消掛號並退掛 (NHIRegIC)
        // 取消掛號並退掛確認dialog
        this.openConnCancelDialog(this.inputData);
      }
      else {  // 無卡 -> 未插卡退掛，只能做取消掛號 (Register)
        this.openCancelDialog(this.inputData);
      }
    } else {  // 未取過卡序(就醫序號)，只能做取消掛號 (Register)
      this.openOnlyCancelDialog(this.inputData);
    }
  }

  validateBeforeSave() {
    if (!this.editFormGroup) {
      return false;
    }
    // if (this.isRehabilitaion) {
    //   return true;
    // }
    // 註記所有欄位為touched以進行Validator檢查
    this.editFormGroup.markAllAsTouched();
    var _doctorId = this.editFormGroup.controls['DoctorId'].value;
    var _medDeptCode = this.editFormGroup.controls['MedDeptCode'].value;
    var room = this.fReg.Value.RoomCode;
    if (_doctorId == 0 || _doctorId == null || _doctorId == undefined) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showError("未選擇醫師人員！")
      return false;
    }
    if (_medDeptCode == '' || _medDeptCode == null || _medDeptCode == undefined) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showError("未選擇看診科別！")
      return false;
    }
    if (!room) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showError("未選擇診間！")
      return false;
    }
    if (!this.editFormGroup.valid) {
      // 驗證失敗
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showValidateFailed();
      return false;
    }
    // 停用預約規則時，預約取到0
    var is預約 = this.fReg.Value.StatusCode == RegisterConst.Code_Status_Appointment

    var is報到取號 = this.isCheckin && this.fReg.Value.WaitNo=='0'
    // 額外檢核，候診號不可為0 (非預約時)
    if (!is預約 && (!this.fReg.Value.WaitNo || this.fReg.Value.WaitNo === '0')) {
      // 驗證失敗
      // this.notification.showValidateFailed();
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showError("診號不可為0！")
      return false;
    }

    // 額外檢核，DoctorId是否對應不到this.doctors清單
    var d = this.editFormGroup.controls['DoctorId'].value;
    var doctor = this.doctors.find(x => x.value == d);
    if (doctor == null) {
      // 驗證失敗
      // this.notification.showValidateFailed();
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showError("未選擇醫師人員！")
      return false;
    }

    var clnLevel = UserCache.getLoginUser().Clinic.NHIType;
    //var refLevel = !this.referralFG.controls.OriHosp ? 0 : Number(this.referralFG.controls.OriLevel);
    var refLevel = !this.refInlevel ? 0 : Number(this.refInlevel);
    if (clnLevel <= refLevel && this.refDiagFee) {
      // 驗證失敗 - 轉入院所之醫療層級需低於本院所
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showError("轉入之院所層級與本院所相同，請取消勾選「是否帶入接受轉診門診診察費加算！");
      return false;
    }

    // 額外檢核，DoctorId是否對應不到this.doctors清單

    if (this.fReg.Value.ICode == RegisterConst.ICode_009_Other && this.fReg.Value.INote == RegisterConst.INote_代檢
      && !(doctor.extension as string).split(',').some(x => x == UserConst.Position_檢驗師)
    ) {
      // 驗證失敗
      // this.notification.showValidateFailed();
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.userConfirm.showAlert('警告', "身分別[009] 備註[+7] 接受其他院所委託代檢之醫師欄位\n，請選擇具有檢驗師身分的人員。", { width: 450 })
      return false;
    }
    // 因欠卡可補過去紀錄，應急先拿掉
    // if (!this.validRegDate()) {
    //   return false;
    // }
    return true;
  }

  async save(registerData: RegisterEditDto, goBackAfterSave = true) {
    var icHelper = this.getICHelper();

    if (this.checkHistDto == null && this.fReg.Value.StatusCode == 'J') {
      this.closeLoader();
      var r = await this.userConfirm.showConfirm({
        title: '警告',
        msg: '您沒有選取複製的病歷',
        textYes: '是',
        hideNo: true,
      });
      return this.isSaveClicked = false;
    }
    // 先解開disable欄位，上傳後繼續disable；因為control.disable()的話，值不會傳到server端
    let getTrueCource = false;
    let formValue = this.fReg.Value;
    let regIdOri = 0;
    var isOweCardToIC = this.initReg.Id && this.initReg.IType == RegisterConst.IType_3_OweCard && formValue.IType == RegisterConst.IType_1_IC;
    let isIC = formValue.IType == RegisterConst.IType_1_IC;
    let isSelfPay = formValue.ICode == RegisterConst.ICode_000_SelfPay;

    if (isIC && this.nhiRegICData.Cid != registerData.PatientCId) {
      this.closeLoader();
      var r = await this.userConfirm.showConfirm({
        title: '警告',
        msg: '健保卡與掛號對象的身分證不一致！！！',
        textYes: '繼續',
        textNo: '取消'
      });
      if (!r) {
        this.isSaveClicked = false;
        return;
      }
      this.showLoader();
    }

    if (this.onlyonelist) {
      let sameTreatstring = (this.editFormGroup.controls['SameTreat'].value ?? '').toString();
      let isNormalSameTreatNofirst = !NullOrEmpty(this.fReg.Value.Icno) && !NullOrEmpty(this.onlyonelist.CusTP);
      getTrueCource = this.onlyonelist.Isqno === this.fReg.Value.Icno && this.onlyonelist.CusTP === sameTreatstring && isNormalSameTreatNofirst

      if (this.onlyonelist?.HistList) {
        var regIds = this.onlyonelist?.HistList.filter(x => x.Register?.Nhictp.startsWith('0'));  // 可能有多筆
        var maxDate = new Date(Math.max(...regIds.map(date => (new Date(date.Register.RegDate)).getTime()))); // 取日期最接近現在的那一筆
        regIdOri = this.onlyonelist?.HistList.find(x => x.Register?.Nhictp.startsWith('0') &&
          (new Date(x.Register.RegDate)).toDateString() == maxDate.toDateString())?.Register?.Id;
      }
    }
    if (regIdOri > 0) {
      registerData.RegIdOri = regIdOri;
    }
    var canchage = this.onlyonelist?.ICode == this.editFormGroup.controls['ICode'].value;

    // 目前切換身分相當於切換流程 所以防備的是卡序有接上，但身分別被修改，則會跳出該訊息 (就目前所知的操作情境通常不會發生)
    // 然後同日同療(for一般看診也排除這個防呆的範圍)
    if (!canchage && getTrueCource) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      var changeToIcode = this.editFormGroup.controls['ICode'].value;
      var msg = '您接續療程的[保險身分]需要修改為代碼:　' + this.onlyonelist?.ICode + '\n若需更換[身分]為 ' + changeToIcode + '，不接續原先療程，[類別]請改為"一般看診'
      var cRet = await this.userConfirm.showConfirm({
        title: '警告',
        msg: msg,
        textYes: '繼續',
        textNo: '返回',
        width: 450
      });
      if (!cRet) {
        return;
      }
      //return this.notification.showWarning(msg);
    }

    /** 補病患基資 特別註記 */
    registerData.ClinicId = UserCache.getLoginClinicId();
    this.nhiRegICData.Nhictp = this.editFormGroup.controls['Nhictp'].value
      || this.opHelper.SwitchMedicalType(UserCache.getLoginUser().Clinic.Code);
    //檢查是否有異動基資相關欄位
    if (this.editFormGroup.controls.PatientPhoneHomeArea.dirty
      || this.editFormGroup.controls.PatientPhoneHome.dirty
      || this.editFormGroup.controls.PatientMobile.dirty
      || this.editFormGroup.controls.PatientCity.dirty
      || this.editFormGroup.controls.PatientArea.dirty
      || this.editFormGroup.controls.PatientZipCode.dirty
      || this.editFormGroup.controls.PatientStreet.dirty
      || this.editRemarkFormGroup.controls.PatientRemark.dirty) {
      registerData.IsEditPatient = true;
    }
    registerData.IsEditPatient = true;  // 更新
    registerData.PatientRemark = this.editRemarkFormGroup.controls['PatientRemark'].value;
    if (this.vsComp.editFG) {
      let vitalSign = new VitalSign();
      vitalSign = {
        VSDateTime: !this.isAdd ? this.vsComp.editFG.controls['VSDateTime'].value : new Date(),
        Height: this.vsComp.editFG.controls['Height'].value,
        Weight: this.vsComp.editFG.controls['Weight'].value,
        Head: this.vsComp.editFG.controls['Head'].value,
        BT: this.vsComp.editFG.controls['BT'].value,
        SBP: this.vsComp.editFG.controls['SBP'].value,
        DBP: this.vsComp.editFG.controls['DBP'].value,
        Pulse: this.vsComp.editFG.controls['Pulse'].value,
        Id: 0,
        PatientId: this.initReg.PatientId,
        IsPregnant: this.vsComp.editFG.controls['IsPregnant'].value,
        LMPDate: this.vsComp.editFG.controls['LMPDate'].value,
        DueDate: this.vsComp.editFG.controls['DueDate'].value,
        isFemale: this.editFormGroup.controls['PatientSexName'].value === '女',
        birthday: this.initReg.PatientBirth,
        Creater: '',
        CreateTime: new Date(),
        Editer: '',
        EditTime: null,
        IsEnabled: false
      };
      registerData.VitalSign = vitalSign;
    }
    registerData.Remark = this.realRemark;  // 護理登陸，因使用combobox元件，要另外控制取得自由輸入的部份
    // 轉診轉入
    registerData.OriHosp = this.oriHosp;
    registerData.OriDate = this.oriDate;
    registerData.RefDiagFee = this.refDiagFee;
    if (this.isAdd || this.isNewBornReg) {  // 加上新生兒依附掛號
      try {
        var monCId = this.editFormValue.MonCId;
        var newbornNote = this.editFormValue.NewbornNote as NewbornNote;
        if (this.isCard) {
          if (!this.isOweCard && isIC && !isSelfPay) {  // 非自費+有卡才需讀卡
            var bbTreat = monCId ? icHelper.getBabyTreat(newbornNote.BirthsNum, this.editFormValue.PatientSexCode) : ' ';
            var icData = await icHelper.getSeqNumber(this.nhiRegICData.Nhictp, bbTreat);

            if (icData == null) {
              this.mainLayoutService.hideLoader();
              this.isSaveClicked = false;
              return;
            }
            let nhictp = this.editFormGroup.controls['Nhictp'].value;
            this.nhiRegICData.Isam = icData.SamId;
            this.nhiRegICData.Icdt = icData.ClinicDateTime;
            this.nhiRegICData.Isgn = icData.SecuritySeal;
            this.nhiRegICData.Ihcd = icData.ClinicCode;
            this.nhiRegICData.Itreatnum = icData.TreatId;

            if(this.registerSeq.some(x => x.iccode == this.fReg.Value.Isqno)){
              this.nhiRegICData.Isqno = this.fReg.Value.Isqno
            }
            else {
              this.nhiRegICData.Isqno = icData.Number;
              if(this.fReg.Value.Icno == this.fReg.Value.Isqno){
                // 卡序用就醫序號的時候卡序設定為取卡的就醫序號
                registerData.Icno = icData.Number;
              }
            }

            this.nhiRegICData.IsqnoOri = !nhictp.startsWith("0") && nhictp != 'AC' ? this.editFormGroup.controls['Icno'].value : '';
            if (monCId) {
              this.nhiRegICData.BabyDate = DateHelper.formatROCDate(this.editFormValue.PatientBirth, false, false);
              this.nhiRegICData.BabyMark = bbTreat;
              this.nhiRegICData.BirthsNum = newbornNote.BirthsNum;
              this.nhiRegICData.Cid = monCId;  // 新生兒依附父母的IC卡，NHIRegIC紀錄要用父母的身分證號
              this.nhiRegICData.Birthdate = this.editFormValue.MonBirthdate;
              // this.nhiRegICData.BabyPatientId = newbornNote.PatientId;
            }
          }
        } else if (this.abCode) {
          // 異常掛號要取就醫識別碼
          var treatNo = await icHelper.getTreatIdNoIC(monCId ? monCId : registerData.PatientCId);

          if (!treatNo) {
            // this.mainLayoutService.hideLoader();
            // this.isSaveClicked = false;
            // return;
          } else {
            this.nhiRegICData.Icdt = treatNo.DateTime;
            this.nhiRegICData.Itreatnum = treatNo.TreatNum;
            this.nhiRegICData.Ihcd = treatNo.HospCode;
          }
          this.nhiRegICData.Isqno = this.editFormGroup.controls['Isqno'].value;
          this.nhiRegICData.Ierrcd = this.abCode;

          var bbTreat = monCId ? icHelper.getBabyTreat(newbornNote.BirthsNum, this.editFormValue.PatientSexCode) : ' ';
          if (monCId) {
            this.nhiRegICData.BabyDate = DateHelper.formatROCDate(this.editFormValue.PatientBirth, false, false);
            this.nhiRegICData.BabyMark = bbTreat;
            this.nhiRegICData.BirthsNum = newbornNote.BirthsNum;
            this.nhiRegICData.Cid = monCId;  // 新生兒依附父母的IC卡，NHIRegIC紀錄要用父母的身分證號
            this.nhiRegICData.Birthdate = this.editFormValue.MonBirthdate;
            // this.nhiRegICData.BabyPatientId = newbornNote.PatientId;
          }
        }

        this.nhiRegICData.DrCid = registerData.DoctorCId;
        if (this.isSelfPay) {
          registerData.NHIRegICDto = new NHIRegIC();
          registerData.NHIRegICDto.Nhictp = registerData.Nhictp;
        } else {
          registerData.NHIRegICDto = this.nhiRegICData;
        }
        var now = new Date();
        var h = ("0" + now.getHours()).slice(-2);
        var m = ("0" + now.getMinutes()).slice(-2);
        var s = ("0" + now.getSeconds()).slice(-2);
        registerData.RegDate = new Date(registerData.RegDate.toDateString() + ' ' + h + ':' + m + ':' + s);
        registerData.BookingDate = now;
        registerData.duty = this.fromOpd ? 2 : 1;
        if (this.isOweCard && registerData.IType == '0' && registerData.ICode != '000') {
          registerData.IType = '3';
        }
        // 新增
        try {
          var copyHist = registerData.StatusCode == 'J' ? true : false;
          if (copyHist) registerData.StatusCode = 'F';
          var regId = await this.regApi.Create(registerData);
          this.cache.set("WaitingList_PatientRegIdForAdd_" + UserCache.getLoginUser().CId, regId);
          var chronicPres = this.onlyonelist?.HistList?.find(x => x.Hist.CntPresNo == '1');
          const numericValues = this.onlyonelist?.HistList?.map(item => Number(item.Hist.CntPresNo))
            .filter(value => !isNaN(value));
          if (numericValues?.length > 0) {
            let chronicPreslastNo = Math.max(...numericValues);
            if (chronicPres) chronicPres.Hist.CntPresNo = chronicPreslastNo + '';
          }
          if (copyHist) {
            registerData.Id = regId;
            var writeCardData = await this.regApi.CloneHistFromStatus(registerData, this.checkHistDto.RegId, '', chronicPres.Hist.CntPresNo);
            if (this.editFormGroup.controls['IType'].value === '1' && !this.isDemoMode) {
              try {
                var st = await this.icHelper.getAPI().GetCardStatusHC();
                if (st.StatusCode !== 0) {
                  this.notification.showError('健保卡取得錯誤：' + st.ErrorMsg);
                  return true;
                }
              } catch (ex) {
                this.notification.showError('健保卡取得錯誤：' + ex);
                return true;
              }
              try {
                this.notification.showWarning('IC卡醫令寫入中...請勿拔出病患卡片，以確保醫令簽章正常產生!');
                var retP = await this.icHelper.getAPI().WriteMultiPrescriptSign(writeCardData?.PrescriptionSignMultiWrite);
                if (retP.StatusCode != 0) {
                  this.notification.showError('健保卡寫入錯誤：' + retP.ErrorMsg);
                }
                else if (retP.StatusCode == 0 && retP.Data) {
                  if (writeCardData.PrescriptionSignMultiWrite.Prescriptions.length > 0) {
                    let pescriptionSign = writeCardData.PrescriptionSignMultiWrite.Prescriptions;
                    let prescriptionSaveDataList: PrescriptionSave[] = [];

                    for (let i = 0; i < pescriptionSign.length; i++) {
                      let prescriptionSaveData = new PrescriptionSave();
                      prescriptionSaveData.RegicId = writeCardData.RegicId;
                      prescriptionSaveData.NhiDrugCode = pescriptionSign[i].NhiDrugCode;
                      prescriptionSaveData.ClinicDateTime = pescriptionSign[i].ClinicDateTime;
                      prescriptionSaveData.Isgn = retP?.Data[i] ?? '';
                      prescriptionSaveDataList = [...prescriptionSaveDataList, prescriptionSaveData];
                      // 在这里对 currentItem 进行操作
                    }
                    this.histApi.saveIgsn(prescriptionSaveDataList);
                  }
                }
                this.notification.showWarning('IC卡診斷及費用寫入中...請勿拔出病患卡片，以避免資料寫入不完全');
                var ret2 = await this.icHelper.getAPI().WriteTreatmentData(writeCardData.TreatmentData);
                // 完診後，再次修改>完診 會寫入錯誤(是否是因為測試卡?)
                // 仿 HIS 不攔截寫卡失敗錯誤 5022 和 5023
                if (ret2.StatusCode != 0 && ret2.StatusCode != 5022 && ret2.StatusCode != 5023 && ret2.StatusCode != 5028) {
                  this.notification.showError('健保卡寫入錯誤：' + ret2.ErrorMsg);
                }else{
                  // 更新寫卡時間
                  await this.histApi.UpdateICWriteTime(writeCardData.RegId);
                }
              } catch (ex) {
                this.notification.showError('健保卡寫入錯誤：' + ex);
                return true;
              }
            }
          }
          else if (registerData.SameTreat == '8' && chronicPres) {
            registerData.Id = regId;
            var largewhole: WholeLargeHist = new WholeLargeHist();
            largewhole.normalRegister = registerData;
            largewhole.wholeHist = chronicPres;
            var writeCardData = await this.regApi.CloneChronicPresNearest(largewhole);
          }
          this.prevQueryRegNoState = null;
          // this.notification.showOk(); // 因為直接返回List畫面所以不通知
          this.print(regId); // 列印掛號單

          //如果是欠卡的狀態，且有啟用欠卡的參數，才會印欠卡單
          if (registerData.IType == '3') {
            let reg001 = await this.clinicDataService.getParam("REG001");
            if (reg001.IsEnableArrearReceipt) {
              await this.handlePrintArrearReceipt(regId);
            }
          }

          this.sendNotify(); // 由client端發送通知
          if (goBackAfterSave) {
            this.cache.set("WaitingList_PatientRegDate_" + UserCache.getLoginUser().CId, registerData.RegDate.toLocaleDateString());
            this.localStorage.setItem("WaitingList_PatientRegId_" + UserCache.getLoginUser().CId, regId);
            this.isSavedReturn(); // 清除並回到前畫面
          }
        } catch (ex) {
          this.mainLayoutService.hideLoader();
          this.isSaveClicked = false;
          if (ex.code.startsWith('SYS')) {
            this.notification.showError(ex);
          } else {
            this.notification.showFailed(); // 顯示存檔失敗
          }
        }
      } catch (e) {
        this.debug.Notify('掛號失敗！',e,registerData,this.nhiRegICData)
        this.mainLayoutService.hideLoader();
        this.isSaveClicked = false;
        if (e.code.startsWith('SYS')) {
          this.notification.showError(e);
        } else {
          this.notification.showFailed(); // 顯示存檔失敗
        }
      }
    } else {  // isEdit
      registerData.IsEditPatient = true;
      registerData.NHIRegICDto = this.nhiRegICData;
      registerData.ClinicId = this.initReg.ClinicId;
      // 更新
      try {
        registerData.RegId = registerData.Id;
        registerData.HasHist = this.oriReg?.HasHist || false;
        // 健保A 改 自費000 要退卡序
        if (this.nhiConsultConvert == 2) {
          this.closeLoader();
          // 提示: 健保改自費需插IC卡退卡序，如未插IC卡，系統將不會上傳該筆退掛資訊
          var cRet = await this.userConfirm.showConfirm({
            title: '警告',
            msg: '即將執行退掛，請確認已插入健保卡若未插卡，此筆每日上傳將調整為「不上傳」。',
            textYes: '確定',
            textNo: '取消',
            width: 450
          });
          if (!cRet) {
            return;
          } else {
            this.showLoader();
            // 有卡 -> 取消掛號並退掛 (NHIRegIC) 要退的是變更前的，所以不是抓畫面的資料來判斷
            if (this.isDemoMode || icHelper.getICConn()) {
              var hasSeqNumber = this.oriReg.IType == '1'     // registerData.IType == '1' //IC掛號 (非(自費/異掛/欠卡))
                && !NullOrEmpty(this.oriReg.Icno)             // 有卡序 registerData.Icno
                && this.oriReg.Icno.indexOf("IC") < 0;        // 非預保 registerData.Icno.indexOf("IC")
              if (hasSeqNumber) {
                let icdt = this.Icdt; //registerData.Icdt;
                if (this.isDemoMode) {
                  // 補3i -> Nhictp='ZA', Icdt=icdt
                  await this.regApi.ChangeToSelfPay(registerData.RegId, false, null, null, true);
                } else {
                  // 退健保卡卡序
                  // Step 1. 退卡序 - 判斷 Nhictp 是否為數值類型? 是->需退卡序，否->不需退卡序(因為本來就不需累計就醫序號，取號也取不到)
                  var revertIcdt: any;
                  var revertSecuritySeal: any;
                  if (!isNaN(Number(registerData.Nhictp))) {
                    var ret = await icHelper.unGetSeqNumber(icdt.toString());
                    if (ret == 5150 || ret == 0) {
                      // Step 2. 註記一筆取消掛號的紀錄到卡片(用 Nhictp='ZA' 或 Nhictp='ZB'取號就會產生)
                      var revertNhictp = this.hasMultipltRegister ? "ZB" : "ZA";
                      var icData = await icHelper.getSeqNumber(revertNhictp, " ");
                      if (icData == null) {
                        this.notification.showError('健保卡退卡序失敗');
                        // return;
                      } else {
                        revertIcdt = icData.ClinicDateTime;
                        revertSecuritySeal = icData.SecuritySeal;
                      }
                      // 補3i -> Nhictp='ZA' 或 Nhictp='ZB', Icdt=icdt
                      // await this.regApi.ChangeToSelfPay(registerData.RegId, this.hasMultipltRegister, revertIcdt, revertSecuritySeal, true);
                    } else {
                      this.notification.showError('健保卡退卡序失敗');
                      // return;
                    }
                  }

                  // 補3i -> Nhictp='ZA' 或 Nhictp='ZB', Icdt=icdt
                  await this.regApi.ChangeToSelfPay(registerData.RegId, this.hasMultipltRegister, revertIcdt, revertSecuritySeal, true);
                }
              }
            }
          }
        }
        // 需要重新取卡
        else if ((this.nhiConsultConvert == 1 && isIC && !isSelfPay) // 自費轉健保 (非欠卡/異掛)
          || (isIC && this.isCheckin)// 報到 (非欠卡/異掛)
          || isOweCardToIC  //欠卡轉健保
        ) {
          var ic = await icHelper.getSeqNumber(this.nhiRegICData.Nhictp, bbTreat);
          if (ic == null) {
            this.mainLayoutService.hideLoader();
            this.isSaveClicked = false;
            return;
          }
          this.nhiRegICData.Isam = ic.SamId
          this.nhiRegICData.Icdt = ic.ClinicDateTime
          this.nhiRegICData.Isgn = ic.SecuritySeal
          this.nhiRegICData.Ihcd = ic.ClinicCode
          this.nhiRegICData.Itreatnum = ic.TreatId

          if(this.registerSeq.some(x => x.iccode == this.fReg.Value.Isqno)){
            this.nhiRegICData.Isqno = this.fReg.Value.Isqno
          }
          else {
            this.nhiRegICData.Isqno = ic.Number;
            if(this.fReg.Value.Icno == this.fReg.Value.Isqno){
              // 卡序用就醫序號的時候卡序設定為取卡的就醫序號
              registerData.Icno = ic.Number;
            }
          }
          this.nhiRegICData.DrCid = registerData.DoctorCId;
          registerData['NHIRegICDto'] = this.nhiRegICData;
        } else if (this.abCode && this.isCheckin) { // 異掛報到
          // 異常掛號要取就醫識別碼
          // 總之先複製新增的部分 再找時間整理 不過沒有的東西再再怎麼找都找不到就是了
          var treatNo = await icHelper.getTreatIdNoIC(monCId ? monCId : registerData.PatientCId);
          if (!treatNo) {
            // 以下註解，異常掛號取卡/取就醫識別碼失敗時略過
            // this.mainLayoutService.hideLoader();
            // this.isSaveClicked = false;
            // return;
          } else {
            this.nhiRegICData.Icdt = treatNo.DateTime;
            this.nhiRegICData.Itreatnum = treatNo.TreatNum;
            this.nhiRegICData.Ihcd = treatNo.HospCode;
          }
          this.nhiRegICData.Isqno = this.editFormGroup.controls['Isqno'].value;
          this.nhiRegICData.Ierrcd = this.abCode;

          var bbTreat = monCId ? icHelper.getBabyTreat(newbornNote.BirthsNum, this.editFormValue.PatientSexCode) : ' ';
          if (monCId) {
            this.nhiRegICData.BabyDate = DateHelper.formatROCDate(this.editFormValue.PatientBirth, false, false);
            this.nhiRegICData.BabyMark = bbTreat;
            this.nhiRegICData.BirthsNum = newbornNote.BirthsNum;
            this.nhiRegICData.Cid = monCId;  // 新生兒依附父母的IC卡，NHIRegIC紀錄要用父母的身分證號
            this.nhiRegICData.Birthdate = this.editFormValue.MonBirthdate;
            // this.nhiRegICData.BabyPatientId = newbornNote.PatientId;
          }
        }

        var data = await this.regApi.Update(registerData);
        this.print(data.Id); // 列印掛號單
        // this.sendNotify(); // 由client端發送通知
        if (goBackAfterSave) {
          this.cache.set("WaitingList_PatientRegDate_" + UserCache.getLoginUser().CId, registerData.RegDate.toLocaleDateString());
          this.localStorage.setItem("WaitingList_PatientRegId_" + UserCache.getLoginUser().CId, data.Id);
          this.isSavedReturn(); // 清除並回到前畫面
        }
      } catch (ex) {
        this.mainLayoutService.hideLoader();
        this.isSaveClicked = false;
        this.notification.showError(ex); // 顯示存檔失敗
      }
    }
  }
  //#endregion


  async handlePrintArrearReceipt(regId: number) {
    this.printArrearReceiptRegId = regId;
    this.isShowPrintArrearReceiptDialog = true;

    this.mainLayoutService.hideLoader();
    await this.waitForDialogClose();
    this.mainLayoutService.showLoader();
  }

  //#region 復健區
  async multiTreatSave(registerData: MutiTreatmentListDto, goBackAfterSave = true) {
    var icHelper = this.getICHelper();

    // 先解開disable欄位，上傳後繼續disable；因為control.disable()的話，值不會傳到server端
    // registerData.ClinicId = UserCache.getLoginClinicId();
    let wholehist = this.getRehabit(registerData);
    var canchage = this.onlyonelist?.ICode == this.editFormGroup.controls['ICode'].value;

    // this.mainLayoutService.hideLoader();
    // this.isSaveClicked = false;
    // return;
    // console.log('this.rehabitMedUsers',this.rehabitMedUsers);

    if (this.rehabithrx.length < 1) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      return this.notification.showWarning('未選擇任何復健療程！');
    }

    if (this.fullRehabithrx) {
      this.fullRehabithrx = false;// 復歸
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      let warningWord = this.fullRehabitArr.join('、');
      return this.notification.showWarning(`${warningWord}療程已滿6次，請排除已滿療程再執行！`)
    }
    let ckeckword = this.checkrehabitMedUsers();
    if (ckeckword.length > 0) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      return this.notification.showWarning(ckeckword);
    }

    this.nhiRegICData.Nhictp = this.editFormGroup.controls['Nhictp'].value
      || this.opHelper.SwitchMedicalType(UserCache.getLoginUser().Clinic.Code);
    //檢查是否有異動基資相關欄位
    if (this.vsComp1.editFG) {
      let vitalSign = new VitalSign();
      vitalSign = {
        VSDateTime: new Date(),
        Height: this.vsComp1.editFG.controls['Height'].value,
        Weight: this.vsComp1.editFG.controls['Weight'].value,
        Head: this.vsComp1.editFG.controls['Head'].value,
        BT: this.vsComp1.editFG.controls['BT'].value,
        SBP: this.vsComp1.editFG.controls['SBP'].value,
        DBP: this.vsComp1.editFG.controls['DBP'].value,
        Pulse: this.vsComp1.editFG.controls['Pulse'].value,
        Id: 0,
        PatientId: this.initReg.PatientId,
        IsPregnant: this.vsComp1.editFG.controls['IsPregnant'].value,
        LMPDate: this.vsComp1.editFG.controls['LMPDate'].value,
        DueDate: this.vsComp1.editFG.controls['DueDate'].value,
        isFemale: this.editFormGroup.controls['PatientSexName'].value === '女',
        birthday: this.initReg.PatientBirth,
        Creater: '',
        CreateTime: new Date(),
        Editer: '',
        EditTime: null,
        IsEnabled: false
      };
      wholehist.VitalSign = vitalSign;
    }

    if (this.isAdd || this.isNewBornReg) { // 加上新生兒依附掛號
      if (this.isCard) {
        // console.log('isCard +++++', this.isCard);
        var monCId = this.editFormValue.MonCId;
        var newbornNote = this.editFormValue.NewbornNote as NewbornNote;
        if (!this.isOweCard && this.editFormGroup.controls['ICode'].value != '000') {  // 非自費才需讀卡
          var bbTreat = monCId ? icHelper.getBabyTreat(newbornNote.BirthsNum, this.editFormValue.PatientSexCode) : ' ';
          var icData = await icHelper.getSeqNumber(this.nhiRegICData.Nhictp, bbTreat)

          if (icData == null) {
            this.mainLayoutService.hideLoader();
            this.isSaveClicked = false;
            return;
          }
          let nhictp = this.editFormGroup.controls['Nhictp'].value;
          this.nhiRegICData.Isam = icData.SamId;
          this.nhiRegICData.Icdt = icData.ClinicDateTime;
          this.nhiRegICData.Isgn = icData.SecuritySeal;
          this.nhiRegICData.Ihcd = icData.ClinicCode;
          this.nhiRegICData.Itreatnum = icData.TreatId;
          if(this.registerSeq.some(x => x.iccode == this.fReg.Value.Isqno)){
            this.nhiRegICData.Isqno = this.fReg.Value.Isqno
          }
          else {
            this.nhiRegICData.Isqno = icData.Number;
          }
          this.nhiRegICData.IsqnoOri = !nhictp.startsWith("0") ? this.editFormGroup.controls['Icno'].value : '';
          // console.log('icData',icData);

          if (monCId) {
            this.nhiRegICData.BabyDate = DateHelper.formatROCDate(this.editFormValue.PatientBirth, false, false);
            this.nhiRegICData.BabyMark = bbTreat;
            this.nhiRegICData.BirthsNum = newbornNote.BirthsNum;
            this.nhiRegICData.Cid = monCId;  // 新生兒依附父母的IC卡，NHIRegIC紀錄要用父母的身分證號
            this.nhiRegICData.Birthdate = this.editFormValue.MonBirthdate;
            // this.nhiRegICData.BabyPatientId = newbornNote.PatientId;
          }
        }
      } else if (this.abCode) {        // 異常掛號要取就醫識別碼
        var treatNo = await icHelper.getTreatIdNoIC(monCId ? monCId : wholehist.Patient.CId);
        if (!treatNo) {
          // this.mainLayoutService.hideLoader();
          // this.isSaveClicked = false;
          // return;
        }else{
          this.nhiRegICData.Icdt = treatNo.DateTime;
          this.nhiRegICData.Itreatnum = treatNo.TreatNum;
          this.nhiRegICData.Ihcd = treatNo.HospCode;
        }
        this.nhiRegICData.Isqno = this.editFormGroup.controls['Isqno'].value;
        this.nhiRegICData.Ierrcd = this.abCode;
        var bbTreat = monCId ? icHelper.getBabyTreat(newbornNote.BirthsNum, this.editFormValue.PatientSexCode) : ' ';
        if (monCId) {
          this.nhiRegICData.BabyDate = DateHelper.formatROCDate(this.editFormValue.PatientBirth, false, false);
          this.nhiRegICData.BabyMark = bbTreat;
          this.nhiRegICData.BirthsNum = newbornNote.BirthsNum;
          this.nhiRegICData.Cid = monCId;  // 新生兒依附父母的IC卡，NHIRegIC紀錄要用父母的身分證號
          this.nhiRegICData.Birthdate = this.editFormValue.MonBirthdate;
          // this.nhiRegICData.BabyPatientId = newbornNote.PatientId;
        }
      }

      let rgisterminiDto: RegisterminiDto = new RegisterminiDto();
      let rData: RegisterEditDto = this.editFormGroup.getRawValue();
      rgisterminiDto.Deposit = rData.Deposit;
      rgisterminiDto.OriDate = rData.OriDate;
      rgisterminiDto.OriHosp = rData.OriHosp;
      rgisterminiDto.OwnFee = rData.OwnFee;
      rgisterminiDto.nhiConsultConvert = rData.nhiConsultConvert;
      rgisterminiDto.SelfPay = rData.SelfPay;
      rgisterminiDto.RegisterFee = rData.RegisterFee;
      rgisterminiDto.Duty = this.fromOpd ? 2 : 1;
      // registerData['NHIRegICDto'] = this.nhiRegICData;
      var now = new Date();
      var h = ("0" + now.getHours()).slice(-2);
      var m = ("0" + now.getMinutes()).slice(-2);
      var s = ("0" + now.getSeconds()).slice(-2);
      wholehist.Register.RegDate = new Date(wholehist.Register.RegDate.toDateString() + ' ' + h + ':' + m + ':' + s);
      rgisterminiDto.BookingDate = now;
      // 新增
      try {
        var largewhole: WholeLargeHist = new WholeLargeHist();
        largewhole.wholeHist = wholehist;
        largewhole.nhiRegIC = this.nhiRegICData;
        largewhole.miniRegister = rgisterminiDto;
        var histToICCardData = await this.histApi.saveMulti(largewhole);

        this.prevQueryRegNoState = null;

        if (this.editFormGroup.controls['IType'].value === '1' && !this.isDemoMode && wholehist.Action) {
          try {
            var st = await this.icHelper.getAPI().GetCardStatusHC();
            if (st.StatusCode !== 0) {
              this.notification.showError('健保卡取得錯誤：' + st.ErrorMsg);
              return true;
            }
          } catch (ex) {
            this.notification.showError('健保卡取得錯誤：' + ex);
            return true;
          }
          try {
            this.notification.showWarning('IC卡醫令寫入中...請勿拔出病患卡片，以確保醫令簽章正常產生!');
            var ret = await this.icHelper.getAPI().WriteMultiPrescriptSign(histToICCardData?.PrescriptionSignMultiWrite);
            // console.log('ret +++++', ret);
            if (ret.StatusCode != 0) {
              this.notification.showError('健保卡寫入錯誤：' + ret.ErrorMsg);
            }
            else if (ret.StatusCode == 0 && ret.Data) {
              if (histToICCardData.PrescriptionSignMultiWrite.Prescriptions.length > 0) {
                let pescriptionSign = histToICCardData.PrescriptionSignMultiWrite.Prescriptions;
                let prescriptionSaveDataList: PrescriptionSave[] = [];

                for (let i = 0; i < pescriptionSign.length; i++) {
                  let prescriptionSaveData = new PrescriptionSave();
                  prescriptionSaveData.RegicId = histToICCardData.RegicId;
                  prescriptionSaveData.NhiDrugCode = pescriptionSign[i].NhiDrugCode;
                  prescriptionSaveData.ClinicDateTime = pescriptionSign[i].ClinicDateTime;
                  prescriptionSaveData.Isgn = ret?.Data[i] ?? '';
                  prescriptionSaveDataList = [...prescriptionSaveDataList, prescriptionSaveData];
                  // 在这里对 currentItem 进行操作
                }
                this.histApi.saveIgsn(prescriptionSaveDataList);
              }
            }
            this.notification.showWarning('IC卡診斷及費用寫入中...請勿拔出病患卡片，以避免資料寫入不完全');
            var ret2 = await this.icHelper.getAPI().WriteTreatmentData(histToICCardData.TreatmentData);
            // 完診後，再次修改>完診 會寫入錯誤(是否是因為測試卡?)
            // 仿 HIS 不攔截寫卡失敗錯誤 5022 和 5023
            if (ret2.StatusCode != 0 && ret2.StatusCode != 5022 && ret2.StatusCode != 5023 && ret2.StatusCode != 5028) {
              this.notification.showError('健保卡寫入錯誤：' + ret2.ErrorMsg);
            }else{
              // 更新寫卡時間
              await this.histApi.UpdateICWriteTime(histToICCardData.RegId);
            }
          } catch (ex) {
            this.notification.showError('健保卡寫入錯誤：' + ex);
            return true;
          }
        }
        this.cache.set("WaitingList_PatientRegIdForAdd_" + UserCache.getLoginUser().CId, histToICCardData.RegId);
        // this.notification.showOk(); // 因為直接返回List畫面所以不通知
        this.print(histToICCardData.RegId); // 列印掛號單
        this.sendNotify(); // 由client端發送通知
        if (goBackAfterSave) {
          this.cache.set("WaitingList_PatientRegDate_" + UserCache.getLoginUser().CId, wholehist.Register.RegDate.toLocaleDateString());
          this.localStorage.setItem("WaitingList_PatientRegId_" + UserCache.getLoginUser().CId, histToICCardData.RegId);
          this.isSavedReturn(); // 清除並回到前畫面
        }
      } catch (ex) {
        this.mainLayoutService.hideLoader();
        this.isSaveClicked = false;
        if (ex.code.startsWith('SYS')) {
          this.notification.showError(ex);
        } else {
          this.notification.showFailed(); // 顯示存檔失敗
        }
      }
    }
  }
  getRehabitMedUsers(data: RehabituserCids) {
    // console.log('data', data);
    this.rehabitMedUsers = Object.assign(new RehabituserCids(), data);
  }
  getRehabithrx(data: HistOrder[]) {
    this.rehabithrx = data;
  }
  rehabitMedUsers: RehabituserCids = new RehabituserCids();
  rehabithrx: HistOrder[] = [];
  fullRehabithrx: boolean = false;
  fullRehabitArr: string[] = [];
  checkFg: Boolean[] = [false, false, false, false];
  getRehabit(treatmentList: MutiTreatmentListDto): WholeHist {
    const whole = [...treatmentList.HistList];
    this.fullRehabitArr = [];
    // 使用正则表达式匹配模式 RP{n}
    const pattern = /RP\d+/g;
    let RPcount = whole.filter(x => (x.Hist.CusCl?.match(pattern)) && !x.Hist.CusCl?.includes("RP0")).length;
    let ROcount = whole.filter(x => x.Hist.CusCl?.includes("RO") && !x.Hist.CusCl?.includes("RO0")).length;
    let RScount = whole.filter(x => x.Hist.CusCl?.includes("RS") && !x.Hist.CusCl?.includes("RS0")).length;
    let PRTcount = whole.filter(x => x.Hist.CusCl?.includes("RPR") && !x.Hist.CusCl?.includes("PRT0")).length;
    let TRcount = whole.filter(x => x.Hist.CusCl?.includes("TR")).length;
    var RPFg = this.rehabithrx.some(x => x.RxCode.startsWith("PT") || x.RxCode.startsWith('420'))
    var ROFg = this.rehabithrx.some(x => x.RxCode.startsWith("OT") || x.RxCode.startsWith('430'))
    var RSFg = this.rehabithrx.some(x => x.RxCode.startsWith("PS") || x.RxCode.startsWith('440'))
    var PRTFg = this.rehabithrx.some(x => x.RxCode.startsWith("PRT") || x.RxCode.startsWith('445'))
    var TRFg = this.rehabithrx.some(x => x.RxCode.startsWith('480'))

    let fg = [RPFg, ROFg, RSFg, PRTFg];
    this.checkFg = fg;

    var RPstr = RPcount > 0 && RPFg ? '[RP' + (RPcount + 1) + ']' : '';
    var ROstr = ROcount > 0 && ROFg ? '[RO' + (RPcount + 1) + ']' : '';
    var RSstr = RScount > 0 && RSFg ? '[RS' + (RPcount + 1) + ']' : '';
    var PRTstr = PRTcount > 0 && PRTFg ? '[RPR' + (RPcount + 1) + ']' : '';
    var TRstr = TRcount > 0 && TRFg ? '[TR' + (TRcount + 1) + ']' : '';

    if ((RPcount >= 6 && RPstr != '') || (ROcount >= 6 && ROstr != '') ||
      (RScount >= 6 && RSstr != '') || (PRTcount >= 6 && PRTstr != '')) this.fullRehabithrx = true;
    if (RPcount >= 6 && RPstr != '') this.fullRehabitArr.push('物理治療');
    if (ROcount >= 6 && ROstr != '') this.fullRehabitArr.push('職能治療');
    if (RScount >= 6 && RSstr != '') this.fullRehabitArr.push('語言治療');
    if (PRTcount >= 6 && PRTstr != '') this.fullRehabitArr.push('心理治療');

    let chosewhole = whole.sort((a, b) => a.Register.Id - b.Register.Id);
    let val = chosewhole[0];
    let cusNo = String((chosewhole?.length ?? 1) + 1);
    if (this.rehabithrx.length > 0) {
      for (let i = 0; i < this.rehabithrx.length; i++) {
        let rxCode = this.rehabithrx[i].RxCode;

        if (rxCode.startsWith("PT") || rxCode.startsWith('420')) this.rehabithrx[i].MedID = this.rehabitMedUsers.PTCid;
        else if (rxCode.startsWith("OT") || rxCode.startsWith('430')) this.rehabithrx[i].MedID = this.rehabitMedUsers.OTCid;
        else if (rxCode.startsWith("PS") || rxCode.startsWith('440')) this.rehabithrx[i].MedID = this.rehabitMedUsers.STCid;
        else if (rxCode.startsWith("PRT") || rxCode.startsWith('445')) this.rehabithrx[i].MedID = this.rehabitMedUsers.PRTCid;

      }
    }
    var registerData: RegisterEditDto = this.editFormGroup.getRawValue();
    val.Action = TRFg ? HistActionEnum.Save : HistActionEnum.Finish;
    val.Register.StatusCode = val.Action == HistActionEnum.Finish ? 'F' : 'A';
    val.Hist.Id = 0;
    // val.Register.Id = 0;
    val.Hist.CusCl = RPstr + ROstr + RSstr + PRTstr + TRstr
    val.Hist.HRxs = this.rehabithrx;
    val.Hist.CusNo = cusNo;
    val.Register.RegDate = registerData.RegDate;
    val.Register.RoomName = registerData.RoomName;
    val.Register.Nhictp = registerData.Nhictp;
    val.Register.IType = registerData.IType;
    return val;
  }

  checkrehabitMedUsers(): string {
    let res = '';
    let ptfg = this.checkFg[0];
    let otfg = this.checkFg[1];
    let stfg = this.checkFg[2];
    let prtfg = this.checkFg[3];
    // console.log('checkFg', this.checkFg)
    // console.log('PTCid', this.rehabitMedUsers.PTCid)

    if (ptfg && this.isStringEmpty(this.rehabitMedUsers.PTCid)) {
      res = '物理治療師'
    }
    if (otfg && this.isStringEmpty(this.rehabitMedUsers.OTCid)) {
      res = res.length > 0 ? res + '、職能治療師' : '職能治療師'
    }
    if (stfg && this.isStringEmpty(this.rehabitMedUsers.STCid)) {
      res = res.length > 0 ? res + '、語言治療師' : '語言治療師'
    }
    if (prtfg && this.isStringEmpty(this.rehabitMedUsers.PRTCid)) {
      res = res.length > 0 ? res + '、心理治療師' : '心理治療師'
    }
    if (res.length > 0) res += '需要設定'

    return res
  }
  isStringEmpty(data: string): boolean {
    return data == undefined || data == null || data == ''
  }
  //#endregion

  //#region UI Control
  controlUIEdit() {
    let itype = this.fReg.Value.IType;
    if (this.isAdd) {
      // 新增
      this.enableControlsForAdd();
    } else {
      if (this.canEdit) {
        // 編輯
        // 部分欄位編輯時不可修改，只有新增時能修改
        this.setControlStatusForEdit();
      } else {
        this.fReg.disableControls();
      }
    }
    // 根據參數控制畫面
    this.setControlStatusByParameter();
    // 預設不可編輯病患資料
    this.disablePatientControls();
    if (this.abCode)
      this.alertColor = 'red';
    else
      this.alertColor = '';

    // 自費、展示模式、欠卡=>開放看診日編輯
    if ((this.isDemoMode && this.isAdd)
      || (this.isAdd && this.isOweCard)
      || (this.isAdd && this.abCode && this.abCode.length > 0)
      || (this.isAdd && itype == RegisterConst.IType_0_SelfPay)) {
      this.editFormGroup.controls['RegDate'].enable();
      this.minRegDate = null;
    }
  }

  // 停用欄位 - 某些欄位只有新增時可修改
  setControlStatusForEdit() {
    let itype = this.fReg.Value.IType;

    this.fReg.Controls.RegDate.disable();

    if (!((this.fReg.Value.IType == '3' || this.fReg.Value.ICode == '000') && !this.inputData?.HasHist)) {
      this.fReg.Controls.StatusCode.disable();
      this.setEditOptStatusCode();
    }
    this.fReg.Controls.StatusRemark.disable();
    // 刷卡或異掛不可變更同療類別欄位, 預約要開放
    // 2024-6-18 補 欠卡在變更時也先關閉同療
    if (!this.isCheckin && (itype == '1' || itype == '2' || itype == '3')) {
      this.fReg.Controls.SameTreat.disable();
      this.fReg.Controls.Icno.disable();
    }
    if (this.isFinish) {
      this.fReg.Controls.ICode.disable();
      this.fReg.Controls.SameTreat.disable();
      this.fReg.Controls.Icno.disable();
      this.fReg.Controls.INote.disable();
    }
    if (this.isHomeCare || this.isRecoveryHome) {
      this.fReg.Controls.OwnFee.disable();
    }
  }
  setEditOptStatusCode() {
    this.editOptions.statusCode = this.editOptions.statusCode.filter(
      x => RegisterConst.StatusCodeList_CanSelectButCannotSelectH.indexOf(x.value) > -1);
  }
  setControlStatusByParameter() {
    let feeCanModify = this.paramREG001.FeeCanModify;
    let waitNoCanModify = this.paramREG001.WaitNoCanModify;
    if (!feeCanModify) {
      // 關押金
      this.fReg.Controls.Deposit.disable();
      // 關部分負擔
      this.fReg.Controls.OwnFee.disable();
      //　關掛號費
      this.fReg.Controls.RegisterFee.disable();
    }
    if (!waitNoCanModify) {
      // 關押金
      this.fReg.Controls.WaitNo.disable();
    }
  }
  // 開啟欄位 - 某些欄位只有新增時可修改
  enableControlsForAdd() {
    this.fReg.Controls.RegDate.enable();
    this.fReg.Controls.TimeSec.enable();
    this.setEditOptStatusCode();
  }
  // enable病患資訊欄位

  // disable病患資訊欄位
  disablePatientControls() {
    this.fReg.Controls.PatientFirstName.disable();
    this.fReg.Controls.PatientCId.disable();
    this.fReg.Controls.PatientBirth.disable();
    this.fReg.Controls.RemarkIC.disable();
    this.fReg.Controls.Isqno.disable();
    this.fReg.Controls.Nhictp.disable();
    this.fReg.Controls.AvailableCount.disable();
    this.fReg.Controls.RegDate.disable();
    this.fReg.Controls.VisitSourceCode.disable();
  }

  //#endregion

  //#region 基本方法
  // getFieldValue(name: string) {
  //   return this.editFormGroup.controls[name].value;
  // }

  getNowTimeSection() {
    return TimeSectionRange.getSectionValue(this.editOptions.timeSectionRange);
  }

  keepData() {

    var formValue = this.fReg.Value;
    this.typ_xcg_keepReg = Object.assign(this.typ_xcg_keepReg,
      {
        ogICode: formValue.ICode,
        //獨立處理
        //seqNo: formValue.Isqno ?? this.typ_xcg_keepReg.seqNo, //保持就醫序號號碼
        // icno:formValue.Icno,
        sameTreat: formValue.SameTreat,
        nhictp: formValue.Nhictp,
        ownFee: formValue.OwnFee,
        // 編輯復原用
        waitNo: formValue.WaitNo,
        roomCode: formValue.RoomCode,
        regDate: formValue.RegDate,
        regSec: formValue.TimeSec,
        state: formValue.StatusCode,
        iNote: formValue.INote,
      });
    console.log('Keep Data= ', this.typ_xcg_keepReg);
  }
  keepIsqNo() {
    var formValue = this.fReg.Value;
    this.typ_xcg_keepReg.seqNo = formValue.Isqno;
    console.log('Keep keepIsqNo= ', this.typ_xcg_keepReg);
  }
  keepIcno() {
    var formValue = this.fReg.Value;
    this.typ_xcg_keepReg.icno = formValue.Icno;
    console.log('Keep keepIcno= ', this.typ_xcg_keepReg);
  }
  keepINote() {
    var formValue = this.fReg.Value;
    this.typ_xcg_keepReg.iNote = formValue.INote;
    console.log('Keep keepINote= ', this.typ_xcg_keepReg);
  }
  keepSameTreat() {
    var formValue = this.fReg.Value;
    this.typ_xcg_keepReg.sameTreat = formValue.SameTreat;
    console.log('Keep keepSameTreat= ', this.typ_xcg_keepReg);
  }
  revertSameTreat() {
    var old = this.fReg.Value.SameTreat;
    this.fReg.patchValue({
      SameTreat: this.typ_xcg_keepReg.sameTreat
    })
    this.onSameTreatChange({ value: this.fReg.Value.SameTreat, prev: old });
  }
  keepICode() {
    this.typ_xcg_keepReg.ogICode = this.fReg.Value.ICode;
  }
  revertICode() {
    this.fReg.patchValue({ ICode: this.typ_xcg_keepReg.ogICode });
  }
  revertNHIcTp() {
    this.fReg.patchValue({ Nhictp: this.typ_xcg_keepReg.nhictp });
  }
  keepOwnFee() {
    this.typ_xcg_keepReg.ownFee = this.fReg.Value.OwnFee;
  }
  revertOwnFee() {
    this.fReg.patchValue({ OwnFee: this.typ_xcg_keepReg.ownFee || 0 });
  }
  revertWaitNo() {
    this.fReg.patchValue({ WaitNo: this.typ_xcg_keepReg.waitNo });
  }

  /** 恢復就醫序號/卡序 */
  async revertIsqAndIcno() {
    this.revertIcno();
    this.revertIsqNo();
    await this.revertINote()
  }
  revertIsqNo() {
    this.setIsqNo(this.isSelfPay ? '' : (this.typ_xcg_keepReg.seqNo ?? ''));
  }
  revertIcno() {
    //欠卡沒卡序用空直
    this.setIcno(this.isSelfPay ? '' : (this.abCode || (this.typ_xcg_keepReg.icno ?? '')));
  }
  async revertINote() {
    await this.setINote(this.typ_xcg_keepReg.iNote ?? '')
  }
  /** 設定為一般掛號 */
  async toNormalSameTreat(IsSpecialHomeCare = false) {
    //如果是居護或康家，則是月療，之後判斷是否能接續才會再改成一般掛號或接續月療
    if (this.isHomeCare || this.isRecoveryHome) {
      if (!IsSpecialHomeCare) return;
      let hcData = this.multiTreatList.find(x => x.CusTP == '4' && x.ICode == this.fReg.Value.ICode);
      if (hcData) {
        var ret = await this.userConfirmService.showConfirm({
          title: '接續月療',
          msg: '您當月已有療程，是否接續？',
          textYes: '是',
          textNo: '否',
          width: 400
        });
        if (ret == true) {
          this.onlyonelist = hcData;
          setTimeout(() => {
            this.patchDataTreatContinue(this.onlyonelist, '4', 'AH', true)
          }, 1);
          return;
        }
      }
    }
    // 恢復為一般掛號
    var icode = this.fReg.Value.ICode;
    await this.revertIsqAndIcno();
    // 重新取卡或恢復無卡預設資料
    //this.reloadCardIsqNo();
    this.fReg.patchValue({
      ICode: icode,  // 上面方法ICode會以卡片的保險身分註記為主, 這邊保留使用者目前選擇
      SameTreat: RegisterConst.SameTreat__一般看診,
      Nhictp: '01',
    });
    // 更新卡序欄位狀態
    await this.setIcnoAfterICodeOrSameTreatChange();
  }
  /** 根據 IType重新處理卡片相關欄位
   * 就醫序號=卡序=取卡或無卡預設
   * 就醫類別01 ...  */
  async reloadCardIsqNo() {
    var icode = this.fReg.Value.ICode;
    var iType = this.fReg.Value.IType;
    if (iType == RegisterConst.IType_1_IC) {
      await this.patchDataFromICCard(icode);
    }
    else {
      await this.patchDataNoCard(iType);
    }
  }
  /** 設定就醫序號 */
  setIsqNo(isqNo: string) {
    this.fReg.patchValue({ Isqno: isqNo });
  }
  /** 設定卡序 */
  setIcno(icno: string) {
    this.fReg.patchValue({ Icno: icno });
  }
  setICode(icode: string) {
    this.fReg.patchValue({ ICode: icode });
  }
  setSameTreat(sameTreat: string) {
    this.fReg.patchValue({ SameTreat: sameTreat });
  }
  setNhictp(ictp: string) {
    this.fReg.patchValue({ Nhictp: ictp });
  }
  async setINote(inote: string) {
    this.fReg.patchValue({ INote: inote });
    await this.iNote變更後重取金額()
  }
  setOwnFee(ownFee: number) {
    this.fReg.patchValue({ OwnFee: ownFee });
  }
  //#endregion

  //#region UI Event
  /** 切換優待身分選單 */
  discountChange(selectValue: string) {
    this.getChargePaymentInfo(selectValue)
  }

  realRemark: string = '';
  remarkInput(inputValue: string) {
    this.realRemark = inputValue;
    // console.log('remarkInput >>>>>', this.realRemark);
  }

  remarkChange(selectValue: string) {
    this.realRemark = selectValue;
    // console.log('remarkChange >>>>>', this.realRemark);
  }

  onPay(payData) {
    const registerData = this.editFormGroup.getRawValue();
    registerData.BeforeCharge = payData;
    this.save(registerData);
  }
  /** 取消掛號紐 */
  async openDeleteDialog() {
    if (this.checkCanCancel()) {
      var ret = await this.userConfirmService.showConfirm({
        title: '取消掛號',
        msg: '你確定要取消這筆掛號嗎?'
      });
      if (ret == true) {
        await this.cancelRegister();
      }
    }
    else {
      var ret = await this.userConfirmService.showConfirm({
        title: '取消掛號',
        msg: '已超過掛號日期時間，不可刪除.?',
        hideNo: true
      });
    }
  }

  /** 切換掛號日期 */
  async onRegDateChange(regDate: Date) {
    if (regDate != undefined && this._selectDate != undefined) {
      if (regDate == this._selectDate) {
        return;
      }
    }
    this.regDateTime = new Date(regDate);
    if (!this._selectDate) {
      this._selectDate = regDate;
    } else {
      this._selectDate = regDate;
    }
    await this.onBlurDate(regDate);
    // 檢查狀態是否需變更
    this.setStatusByReg();
    //重取可用卡序(改就診日 生日計算出的年齡會變化)
    await this.getICNoOptions();
    await this.setIcnoAfterICodeOrSameTreatChange();
  }

  /** 切換卡序 */
  async onICNoChange(icno: string) {
    // 若異掛卡序改變，或重讀卡片，則需將此身分備註清空
    await this.setINote('')
    //卡序 切回一般看診
    if (icno == this.typ_xcg_keepReg.icno) {
      // 設為一般掛號
      this.toNormalSameTreat();
      return;
    }
    // 檔： 需卡但無卡
    let isCardArrear = this.fReg.Value.IType == RegisterConst.IType_3_OweCard;
    var item = this.registerSeq.find(x => x.value == icno);
    if (item.needCard && isCardArrear) {
      await this.userConfirmService.showAlert('請確認以下訊息', '卡序[' + item.value + ']' + item.text + ',需要讀健保卡才能使用');
      // 設為一般掛號
      this.toNormalSameTreat();
      return;
    }
    await this.setIcnoByOption(item);
  }
  /** 備註切換 */
  async onINoteChange(inote: string) {
    await this.setINote(inote);
  }
  async iNote變更後重取金額() {
    if (this.fReg.Value.IType == RegisterConst.IType_3_OweCard || this.fReg.Value.IType == RegisterConst.IType_4_OweCardNoReturn) {

      await this.getChargePaymentInfo()

    }
  }
  async setIcnoByOption(item: ICNoPHOption) {
    // 警：卡序所帶的警告
    if (item.warning) {
      var ret = await this.userConfirmService.showConfirm({
        title: '請確認以下訊息',
        msg: item.warning
      });
      if (!ret) {
        // 設為一般掛號
        this.toNormalSameTreat();
        return;
      }
    }

    this.setNhictp('AC')
    // 異掛優先
    this.setIsqNo(this.abCode || item.iccode);
    this.setIcno(item.value)
    if (item.iNote) {
      await this.setINote(item.iNote)
    }
  }
  /** 身分類別為9 其他時的卡序切換 */
  async onRegisterSeqSelectByIcodeNineChge(p: string) {
    let isCardArrear = this.editFormGroup.controls['IType'].value == '3';
    if ( RegisterVaccineConst.vaccineCodes.includes(p) && isCardArrear) {
      var ret = await this.userConfirmService.showConfirm({
        title: '請確認以下訊息',
        msg: '卡序' + p + '需要讀健保卡才能使用',
        hideNo: true
      });
      if (ret) {
        this.toNormalSameTreat();
      }
    }
    else if (p != '') {
      // 選取卡序後設定 掛號類別為預保 X
      this.editFormGroup.controls["Nhictp"].setValue("AC");
      var item = this.icodeSetting.find(x => x.value == p);
      if (item) {
        //異掛優先
        this.setIsqNo(this.abCode || (item.value == 'HV' ? 'HVIT' : item.value))
      }
      else {
        this.toNormalSameTreat();
      }
    }
    else {
      this.toNormalSameTreat();
    }
  }
  /** 同療程 "1" 復健1-6次 接續 */
  async sameTreat1Continue() {
    this.patchDataTreatContinue(this.onlyonelist, '1', 'AA', true)

    if (this.multiTreatmentUser.length < 1) {
      this.multiTreatmentUser = await this.regApi.GetRehabitMedUesrs();
    }
    this.initmultiTreatmentUserData = new InitmultiTreatmentUserData();
    this.initmultiTreatmentUserData.Data = this.onlyonelist;
    this.initmultiTreatmentUserData.Users = this.multiTreatmentUser;
  }
  // 說接續療程
  async onsameTreatUseNofirst(useType: string, isNotNeedchageIcode: boolean = false): Promise<boolean> {
    var nhictp = this.opHelper.DefineItp(useType);
    if (useType == '0' && this.isAdd) {
      var medCode = this.editOptions.section.find(x => x.value == '2B').value || '';
      if (medCode != '') {
        this.editFormGroup.controls['MedDeptCode'].setValue(medCode);
      }
      else if (!this.isWrong2B) {
        this.notification.showWarning('您的院所沒有設置洗腎科(2B)')
        this.isWrong2B = true;
      }
    }
    if ((nhictp == 'AB' || nhictp == 'AJ') && this.isAdd) {
      this.setOwnFee(0);
    }
    if (isNotNeedchageIcode && this.onlyonelist?.ICode != this.fReg.Value.ICode) {
      this.onlyonelist = this.multiTreatList.find(x => x.CusTP == useType && x.ICode == this.fReg.Value.ICode);
      if (this.onlyonelist) {
        this.patchDataTreatContinue(this.onlyonelist, useType, nhictp, isNotNeedchageIcode)
        return true;
      }
      else {
        // 找不到走新療程
        this.onsameTreatUsefirst(useType);
        return false;
      }
    }
    else if (this.onlyonelist?.Isqno && this.onlyonelist?.CusTP == useType) {      // 卡序接續
      this.patchDataTreatContinue(this.onlyonelist, useType, nhictp, isNotNeedchageIcode)
      return true;
    }
    else if (this.multiTreatList.length > 0 && this.onlyonelist?.CusTP != useType && this.allToGetMethod.some(x => x == useType)) {
      this.onlyonelist = this.multiTreatList.find(x => x.CusTP == useType);
      // 卡序接續
      if (this.onlyonelist) {
        this.patchDataTreatContinue(this.onlyonelist, useType, nhictp, isNotNeedchageIcode)
        return true;
      }
      else {
        // 找不到走新療程
        this.onsameTreatUsefirst(useType);
        return false;
      }
    }
    else if (this.multiTreatList.length == 0) {
      //都沒有才查詢
      this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, this.regDateTimeStr, useType);
      this.allToGetMethod = [useType];
      if (this.multiTreatList.length == 1) {
        this.onlyonelist = this.multiTreatList[0];
        let tp = this.multiTreatList[0].CusTP
        this.onSameTreatChange({ value: tp, prev: RegisterConst.SameTreat__一般看診 });
        return true;
      }
      else if (this.multiTreatList.length > 1) {
        this.isOpenMultiTraat = true;
        return true;
      }
      else {
        this.onsameTreatUsefirst(useType);
        return false;
      }
    }
  }
  /** 更新接續同療相關欄位 */
  patchDataTreatContinue(dto: MutiTreatmentListDto, sameTreat: string, nhictp, isNotNeedchageIcode: boolean = false) {
    this.setIcno(dto.Isqno);
    if (!this.abCode) {
      this.setIsqNo('');
    }
    if (!isNotNeedchageIcode) {
      this.editFormGroup.controls['ICode'].setValue(dto.ICode);
    }
    this.editFormGroup.controls['SameTreat'].setValue(sameTreat);
    this.editFormGroup.controls['Nhictp'].setValue(nhictp)
  }
  async onsameTreatUsefirst(sameTreat: string) {
    let trueType = sameTreat;
    if (sameTreat == '7A') {
      trueType = '7';
    }
    else if (sameTreat == '7B' || sameTreat == '7C') {
      trueType = '';
    }

    var nhictp = this.opHelper.DefineItp(trueType, true);
    if (this.fReg.Value.IType == RegisterConst.IType_1_IC || sameTreat == '7C') {
      await this.revertIsqAndIcno();
    }
    if (sameTreat == '3')trueType = ''
    else if (sameTreat == '7C') trueType = '7'
    this.setSameTreat(trueType)
    this.setNhictp(nhictp);
    if (sameTreat == RegisterConst.SameTreat_0_洗腎) {
      var medCode = this.editOptions.section.find(x => x.value == '2B').value || '';
      if (medCode != '') {
        this.editFormGroup.controls['MedDeptCode'].setValue(medCode);
      }
      else if (!this.isWrong2B) {
        this.notification.showWarning('您的院所沒有設置洗腎科(2B)')
        this.isWrong2B = true;
      }
    }
  }

  /** 切換掛號類型(SameTreat) */
  async onSameTreatChange(p: { value: string, prev: string }, isNotNeedchageIcode = false) {
    // 這個方法可以調整成 在各種流程收集好要更新的直最後一次更新, 避免操作途中就出現值變更
    var formValue = this.fReg.Value
    let isCardArrear = formValue.IType === RegisterConst.IType_3_OweCard;  // 有欠卡
    // 從預保選出來先恢復
    if (p.prev == RegisterConst.SameTreat_2_預防保健) {
      await this.revertIsqAndIcno();
    }
    await this.setIcnoAfterICodeOrSameTreatChange()

    if (p.value == RegisterConst.SameTreat_1_復健) {
      if (this.onlyonelist?.Isqno && this.onlyonelist?.CusTP == RegisterConst.SameTreat_1_復健) {
        await this.sameTreat1Continue();
        return;
      }
      else if (this.multiTreatList.length > 0 && this.onlyonelist?.CusTP != RegisterConst.SameTreat_1_復健) {
        this.onlyonelist = this.multiTreatList.find(x => x.CusTP == RegisterConst.SameTreat_1_復健);
        if (this.onlyonelist) {
          await this.sameTreat1Continue();
          return;
        }
        else {
          // 找不到走新療程
          await this.reloadCardIsqNo();
          this.setSameTreat(RegisterConst.SameTreat_1_復健);
          return;
        }
      }
      else if (this.multiTreatList.length == 0) {
        this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, this.regDateTimeStr, p.value);
        this.allToGetMethod = [p.value];
        if (this.multiTreatList.length == 1) {
          this.onlyonelist = this.multiTreatList[0];
          let tp = this.multiTreatList[0].CusTP
          this.onSameTreatChange({ value: tp, prev: RegisterConst.SameTreat__一般看診 });
          this.isOpenMultiTraat = this.onlyonelist.CusTP == RegisterConst.SameTreat_1_復健 ? true : false;
          return;
        }
        else if (this.multiTreatList.length > 1) {
          this.isOpenMultiTraat = true;
        }
      }
    }
    var sametreatType = p.value;
    if (p.value == RegisterConst.SameTreat_0_洗腎 &&
      (this.isKidneyDialysisUseNHI || formValue.ICode === RegisterConst.ICode_001_重傷病)) {
      sametreatType = '0A';
    }
    // 接續同日就診
    if (p.value == RegisterConst.SameTreat_7_同日同醫 && this.multiTreatList.some(x => x.CusTP == RegisterConst.SameTreat_0_洗腎 || x.CusTP == RegisterConst.SameTreat__一般看診)) {
      let todaylist = this.filterByToday(this.multiTreatList);
      let todayMatch: WholeHist;
      let istheSame = false;
      if (todaylist.length > 0) {
        for (var i = 0; i < todaylist.length; i++) {
          if (todaylist[i].HistList[0]?.Register.DoctorId == formValue.DoctorId) {
            istheSame = true;
            todayMatch = todaylist[i].HistList[0];
            break;
          }
        }
        if (istheSame) {
          sametreatType = '7A'
          if (todayMatch?.Register?.Nhictp == 'AJ') sametreatType = '7C';
          // console.log('sametreatType',sametreatType);
          this.onsameTreatUsefirst(sametreatType);
          if (todayMatch?.Register?.Nhictp != 'AJ') this.setIcno(todayMatch.Register.Icno);
          if (formValue.ICode == RegisterConst.ICode_A_健保) {
            this.setICode(RegisterConst.ICode_009_Other)
            formValue = this.fReg.Value;
          }
          //if (this.icnoLock != 'NORMAL') this.icnoLock = 'NORMAL';
          // 切換同日同療 需回到自己醫師的科別
          if (this.isAdd) {
            this.resetMedDeptByDoctor();
          }
        }
        else {
          sametreatType = '7B'
          this.onsameTreatUsefirst(p.value);
          await this.revertIsqAndIcno();
        }
      }
    }

    var msgData = this.opHelper.SameTreatDesc(sametreatType, this.isCard);

    if (p.value == RegisterConst.SameTreat_8_慢箋領藥) { //慢籤
      // if (this.fReg.Value.IType == RegisterConst.IType_3_OweCard){
      //   await this.revertIsqAndIcno();
      //   msgData = this.opHelper.SameTreatDesc('8A', this.isCard); // no action 不出提示訊息

      // }
      // else
      // {
        var isnotfirst8 = await this.onsameTreatUseNofirst(p.value);
        if (this.onlyonelist?.CusTP == RegisterConst.SameTreat_8_慢箋領藥) {
          msgData = this.opHelper.SameTreatDesc(RegisterConst.SameTreat__一般看診, this.isCard); // no action 不出提示訊息
        }
      // }

      // if (isnotfirst8) {
      //   return;
      // }
      // this.getLargeChargePayment();  // 查找設定優免
    }
    if (p.value == RegisterConst.SameTreat_4_月療) { //居護
      var isnotfirst4 = await this.onsameTreatUseNofirst(p.value, isNotNeedchageIcode);   // 不是月療第一次 isnotfirst4
      // if (isnotfirst4) {
      //   return;
      // }
    }

    // 還是要查找費用跟設定優免 所以把上面的拉下來做這兩件事
    // **不是月療第一次: isnotfirst4
    // **不是慢箋領藥第一次: isnotfirst8
    if (isnotfirst8 || isnotfirst4) {
      this.getLargeChargePayment();  // 查找設定優免
      this.setDoctorOwnFee(this.doctorOwnFee);
      return;
    }
    // 動作處理
    if (this.isAdd || this.isCheckin) {
      if (msgData.action == 'confirm') {
        var ret = await this.userConfirmService.showConfirm({
          title: '請確認以下訊息',
          msg: msgData.msg,
          textYes: msgData.yesText,
          textNo: msgData.noText,
          width: 450
        });
        if (ret) {
          if (p.value == RegisterConst.SameTreat_3_換藥) {
            //換藥
            this.onsameTreatUsefirst(p.value);
            // 洗腎為兩層式 第一次剛進來是0 => 0A，再選一次就直接走0A
          } else if (p.value == RegisterConst.SameTreat_0_洗腎) {
            if (sametreatType == RegisterConst.SameTreat_0_洗腎) {
              this.isKidneyDialysisUseNHI = true; // 問過就不再問
              this.setICode(RegisterConst.ICode_A_健保);//健保OR重傷病
              formValue = this.fReg.Value;
              var msgData = this.opHelper.SameTreatDesc('0A', this.isCard);
              var ret0A = await this.userConfirmService.showConfirm({
                title: '請確認以下訊息',
                msg: msgData.msg,
                textYes: msgData.yesText,
                textNo: msgData.noText,
                width: 450
              });
              if (ret0A) {
                this.onsameTreatUsefirst(p.value);
              }
              else {
                this.onsameTreatUseNofirst(p.value);
              }
            }
            else {
              this.onsameTreatUsefirst(p.value);
            }
          } else {
            // 同僚第一次 不變
            if (!isCardArrear) {
              this.revertNHIcTp();
            }
          }
          if (formValue.Nhictp == 'AJ') {
            this.setOwnFee(0);
          }
          else {
            this.revertOwnFee();
          }
        } else if (ret == false) {  // 取消
          // 洗腎
          if (p.value == RegisterConst.SameTreat_0_洗腎) {
            if (sametreatType == RegisterConst.SameTreat_0_洗腎) {
              this.isKidneyDialysisUseNHI = true; // 問過就不再問
              this.setICode(RegisterConst.ICode_001_重傷病)//健保OR重傷病

              var msgData = this.opHelper.SameTreatDesc('0A', this.isCard);
              var ret0A = await this.userConfirmService.showConfirm({
                title: '請確認以下訊息',
                msg: msgData.msg,
                textYes: msgData.yesText,
                textNo: msgData.noText,
                width: 450
              });
              if (ret0A) {
                this.onsameTreatUsefirst(p.value);
              }
              else {
                this.onsameTreatUseNofirst(p.value);
              }
            }
            else {
              this.onsameTreatUseNofirst(p.value);
            }
          }
          else if (p.value == RegisterConst.SameTreat_3_換藥) {
            this.onsameTreatUseNofirst(p.value);
          }
          else//非第一次同療，接續同卡序，類別變更
          {
            //todo 查上一次卡序,若無則帶空值
            this.setIcno('')
            // 異掛優先
            this.setIsqNo(this.abCode || '')
            //對應變更類別
            // console.log('isCardArrear6', isCardArrear);
            if (!isCardArrear) {
              this.setNhictp(msgData.nhictp)
            }
            this.revertOwnFee();
          }
          if (this.editFormGroup.controls['Nhictp'].value == 'AJ') {
            this.setOwnFee(0)
          }
        } else {
          // console.log('p.prev',p.prev);
          if (!msgData.keepSameTreat) {
            this.setSameTreat(p.prev);
          }
        }
        //this.keepData()
      }
      else if (msgData.action == 'alert') {
        var ret = await this.userConfirmService.showConfirm({
          title: '請確認以下訊息',
          msg: msgData.msg,
          hideNo: true
        });
        if (!msgData.keepSameTreat) {
          this.setSameTreat(p.prev);
          await this.setIcnoAfterICodeOrSameTreatChange()
        }

      }
      else {
        var revertseqNo = !!this.typ_xcg_keepReg.icno;
        //對應變更類別
        if (!isCardArrear) {
          let nhictpP4 = p.value == RegisterConst.SameTreat_4_月療 ? '01' : msgData.nhictp;
          this.setNhictp(nhictpP4)
        };

        if (!p.value) {
          var isSp = !p.value && p.prev == '4'
          this.toNormalSameTreat(isSp)
        } else {
          //
          let icno = ((p.value == RegisterConst.SameTreat_4_月療) && revertseqNo && !isCardArrear) ? this.typ_xcg_keepReg.icno : '';
          if (p.value != RegisterConst.SameTreat_7_同日同醫) {
            this.setIcno(icno);
          }
          else
          {
            // 同日同療 01 要填就醫序號
            if (sametreatType == '7C') icno = this.typ_xcg_keepReg.icno;
          }
          //異掛優先
          this.setIsqNo(this.abCode || icno)

        }
        if (sametreatType != '7A' && sametreatType != '7C') {
          this.revertOwnFee();
        }
        //this.keepData()
      }
    }
    else {
      if (p.value == RegisterConst.SameTreat_3_換藥 ) {
        this.onsameTreatUseNofirst(p.value);
      }
    }
    this.getLargeChargePayment();  // 查找設定優免
    this.setDoctorOwnFee(this.doctorOwnFee);
  }

  /** 切換醫師，改診間 */
  async onDoctorSelectionChange(ev: any) {

    const doctorId = this.fReg.Value.DoctorId;
    var roomCode = this.doctorRoomMapping.find(x => x.doctorId == doctorId)?.roomCode || '1';
    var sectionCode = this.deptMap.find(x => x.doctorId == doctorId)?.sectionCode
      // 醫師未設科別，用診所科別
      ?? UserCache.getLoginUser().Clinic.MedDeptCode;
    await this.setRoomDoctorDept(roomCode, doctorId, sectionCode);

    if (this.editFormGroup.controls['SameTreat'].value == RegisterConst.SameTreat_7_同日同醫 && !this.is7A) {
      this.onSameTreatChange({ value: RegisterConst.SameTreat_7_同日同醫, prev: RegisterConst.SameTreat__一般看診 });
    }
  }

  /** 切換診間，改醫師 */
  async onRoomSelectionChange() {
    const roomCode = this.editFormGroup.controls['RoomCode'].value;
    const doctorId = this.doctorRoomMapping.find(item => item.roomCode === roomCode).doctorId;
    const sectionCode = this.deptMap.find(item => item.doctorId === doctorId).sectionCode;
    await this.setRoomDoctorDept(roomCode, doctorId, sectionCode);

  }
  /** 科別重置為符合目前所選醫師 */
  async resetMedDeptByDoctor() {
    const roomCode = this.fReg.Value.RoomCode;
    const doctorId = this.fReg.Value.DoctorId;
    // 醫師未設科別，用診所科別
    var sectionCode = this.deptMap.find(x => x.doctorId == doctorId)?.sectionCode ?? UserCache.getLoginUser().Clinic.MedDeptCode;
    await this.setRoomDoctorDept(roomCode, doctorId, sectionCode);
  }
  /** 診間醫師有所變更時的後續動作 */
  async setRoomDoctorDept(roomCode: string, doctorId: number, medDept: string) {
    this.fReg.patchValue({
      RoomCode: roomCode,
      DoctorId: doctorId,
      MedDeptCode: medDept
    });
    this.getRegisterNo();
    // this.doctorOwnFee.Id 防呆
    var compareId = this.doctorOwnFee != null ? this.doctorOwnFee.Id : 0;
    // 切換醫師時，取該醫師的費用
    if (doctorId != compareId) {
      await this.getChargePaymentInfo();
    }
  }
  /** 切換狀態(一般候診/預約/拿藥/急診) */
  selectedStatusCode = null;
  async onStatusSelectionChange(evt) {
    // console.log('editOptions', this.editOptions);
    this.selectedStatusCode = this.editFormGroup.controls['StatusCode'].value;
    await this.getRegisterNo();
    this.setDoctorOwnFee(this.doctorOwnFee);
    // 病歷grid的選取欄位與狀態掛勾，加入ngif判斷與設為null來重新繪製表格
    var t = this.gridView;
    this.gridView = null;
    setTimeout(() => {
      this.gridView = t;
    }, 0);
  }

  /** 切換時段 */
  async onTimeSecChange() {
    this.setStatusByReg();
    await this.refreshDoctorRoom();
    this.getRegisterNo();
  }

  /** 切換身分備註 */
  // async onINoteSelectChge(inote: string) {
  //   this.editFormGroup.controls['INote'].setValue(this.editOptions.inote)
  // }


  /** 切換保險身分變更選項時 */
  async onICodeChange(toIcode: string) {
    var formValue = this.fReg.Value;
    //#region 不給切換
    // 非新增且不為一般看診的時候 不給切，還原身分
    if (!this.isAdd && formValue.SameTreat != RegisterConst.SameTreat__一般看診) {
      await this.userConfirm.showAlert('警告',
        '改變身分別可能會造成療程接續狀態異常!\n\n' +
        '請於[加值應用]>[臨時作業]中進行療程的身分變更：\n' +
        '(若自費改建保，請重新掛一筆健保，勿使用以下操作)\n' +
        '  1.回到候診清單\n' +
        '  2.進入選單[加值應用]>[臨時作業]\n' +
        '  3.點選[病患保險身分調整]\n' +
        '  4.點選病患搜尋，輸入患者的病歷號或基資查詢\n' +
        '  5.勾選同療程的掛號紀錄\n' +
        '  6.在上方選取欲[更換的身分]後儲存\n\n' +
        '或者使用新的身分別建立新掛號：\n' +
        '  1.回到候診清單\n' +
        '  2.重新掛號。', { width: 450 }
      );
      this.revertICode();
      return;
    }
    //#endregion


    var icHelper = this.getICHelper();
    // 非異掛
    if (!this.abCode) {
      // 非異掛 且 自費000 轉 健保(非自費)
      if (this.typ_xcg_keepReg.ogICode === RegisterConst.ICode_000_SelfPay && toIcode !== RegisterConst.ICode_000_SelfPay) {
        if (this.nhiConsultConvert == 1) {
          this.getChargePaymentInfo();
          return;
        }
        // 有插卡 -> 重新取號 gatLastNum && 展示模式有問題先擋下來
        if (!this.isOweCard && await icHelper.getICConnOnlyCheck() && !this.isDemoMode) {
          if (!await this.patchDataFromICCard(this.fReg.Value.ICode)) {
            this.revertICode()
            return;
          }
          this.isOweCard = false;
          this.isCard = true;
          this.nhiConsultConvert = 1;
        } else { // 無卡 -> 以欠卡掛入
          var ret = await this.userConfirmService.showConfirm({
            title: '提醒訊息',
            msg: '無健保卡是否改欠卡掛入？\n【確定】：以欠卡掛入；【取消】：維持自費身分',
            textYes: '確定',
            textNo: '取消',
            width: 400
          });
          if (ret) {
            this.editFormGroup.controls['RegDate'].enable();
            this.editFormGroup.controls['SameTreat'].enable();
            this.nhiConsultConvert = 1;
            this.isOweCard = true;
            this.isCard = false;
            await this.patchDataNoCard(RegisterConst.IType_3_OweCard);
          } else {
            this.revertICode()
          }
        }
      }
      // 非異掛 且 健保改自費 => Save時需做退掛流程
      else if (this.typ_xcg_keepReg.ogICode !== RegisterConst.ICode_000_SelfPay && toIcode === RegisterConst.ICode_000_SelfPay) {
        if (this.nhiConsultConvert == 2) {
          return;
        }
        var ret = await this.userConfirmService.showConfirm({
          title: '提醒訊息',
          msg: '是否改為自費身分掛入？\n【確定】：改以自費掛入；【取消】：維持健保身分',
          textYes: '確定',
          textNo: '取消',
          width: 400
        });
        if (ret) {
          this.nhiConsultConvert = 2;
          this.isOweCard = false;
          this.isCard = false;
          this.keepData()
          await this.patchDataNoCard(RegisterConst.IType_0_SelfPay);
        } else {
          this.revertICode();
          this.rescale();
        }
      } else {
        // 其他選項 (切換保險身分)
        this.nhiConsultConvert = 0;
        // IType: 0:自費 1:健保正常讀卡 2:異掛 3:欠卡
        if (toIcode == RegisterConst.ICode_000_SelfPay) {
          this.fReg.patchValue({
            IType: RegisterConst.IType_0_SelfPay
          });
        } else {
          if (this.isAdd) {
            this.fReg.patchValue({
              IType: this.isCard ? RegisterConst.IType_1_IC : RegisterConst.IType_3_OweCard
            });
          }
        }
      }
    }
    // 異掛
    else {
      this.nhiConsultConvert = 0;
      this.fReg.patchValue({
        IType: RegisterConst.IType_2_Abnormal
      });
      if (toIcode !== RegisterConst.ICode_000_SelfPay) {  // 切回健保，且原本異掛
        this.nhiConsultConvert = 1;
        this.isOweCard = false;
        this.isCard = false;
        await this.patchDataNoCard(RegisterConst.IType_2_Abnormal);
      } else {
        await this.patchDataNoCard(RegisterConst.IType_0_SelfPay);
      }
    }

    // 切換保險身分後，將最終結果回存 typ_xcg_keepReg.ogICode 供之後再切換身分判斷員身分設定是什麼
    this.keepICode()
    this.isSelfPay = this.fReg.Value.ICode == RegisterConst.ICode_000_SelfPay;
    this.setDoctorOwnFee(this.doctorOwnFee);
    // 根據保險身分/院所類型刪減同僚項目
    this.updateSameTreatOpt()
    await this.setSameTreatAfterICodeChange();
    this.setINoteAfterICodeChange()
    await this.setIcnoAfterICodeOrSameTreatChange();

    this.getChargePaymentInfo();
    // console.log('11c',this.editFormGroup.controls['ICode'].value);

    setTimeout(() => {
      this.rescale();
    }, 0);
  }
  /** 就醫身分或者療程切換，重算可用卡序 */
  async setIcnoAfterICodeOrSameTreatChange() {
    var icode = this.fReg.Value.ICode;
    var sameTreat = this.fReg.Value.SameTreat;
    //取得身分別可用的卡序(一般看診)
    var icnoFromICode = sameTreat == RegisterConst.SameTreat__一般看診 ? (this.icodeIcnoOption == undefined ? [] : (this.icodeIcnoOption[icode] ?? [])) : [];
    //取得預保可用卡序 (預保至少會有一個'無')
    var icnoFromSameTreat = sameTreat == RegisterConst.SameTreat_2_預防保健 ? this.phOption : []
    // 加再一起
    this.registerSeq = [...icnoFromSameTreat, ...icnoFromICode];
    // 無可用卡序 且不是預保的時候鎖起來
    if (this.registerSeq.length == 0 && sameTreat !== RegisterConst.SameTreat_2_預防保健) {
      this.icnoLock = 'NORMAL';
      if (this.isAdd && this.fReg.Value.Nhictp != '01') {
        await this.toNormalSameTreat();
      }
    } else {
      // 沒鎖的時候加上一個可以切回一般看診的預設項
      // 異掛=>顯示異掛代碼 一般=>健保預取卡序
      var icno = this.typ_xcg_keepReg.icno;
      this.registerSeq = [{
        value: icno,
        text: icno,
        iccode: icno,
        iNote: this.typ_xcg_keepReg.iNote,
        desc: ''
      }, ...this.registerSeq];
      //
      var item = this.registerSeq.find(x => x.value == this.fReg.Value.Icno && x.value != this.typ_xcg_keepReg.icno);
      if (!item) {
        // 原本的卡序不在新的可選項目中，清空(有異常使用異常)
        this.revertIcno();
        //this.setIcno(this.abCode||this.typ_xcg_keepReg.seqNo);
        await this.setINote(this.typ_xcg_keepReg.iNote);
      } else {
        await this.setIcnoByOption(item);
      }

      this.icnoLock = 'OTHER';
    }
  }
  /** 身分切換完 自費關閉療程 居康療程定為預設月療 */
  async setSameTreatAfterICodeChange() {
    var icodeResult = this.fReg.Value.ICode;

    var isAllowSameTreatChange = true;
    // 根據保險身分調整可選取的療程
    if (icodeResult == RegisterConst.ICode_000_SelfPay) {
      isAllowSameTreatChange = false;
    } else {
      // 最後為健保時，處理居康
      if ((icodeResult !== RegisterConst.ICode_000_SelfPay) && (this.isHomeCare || this.isRecoveryHome)) {
        this.fReg.patchValue({
          SameTreat: this.defaultSameTreat ? this.defaultSameTreat.toString() : ''
        });
        this.onSameTreatChange({ value: RegisterConst.SameTreat_4_月療, prev: RegisterConst.SameTreat__一般看診 }, true);
      }
      await this.getAllTreatment();
    }
    if (isAllowSameTreatChange) {
      // 這邊會變成變更時 重複點選原本的保險身分能將被鎖住的療程打開, 可再調整
      this.fReg.Controls.SameTreat.enable();
    }
  }
  setINoteAfterICodeChange() {
    var icodeResult = this.fReg.Value.ICode;

    // 保險身分009的時候將備註 (aka HIS登陸) 啟用
    if (icodeResult == RegisterConst.ICode_009_Other) {
      this.fReg.Controls.INote.enable();
    } else {
      this.fReg.Controls.INote.disable();
    }
  }
  /** 案機構類型/保險身分別 設定可用的療程選項
   *
   */
  updateSameTreatOpt() {
    if (this.isHomeCare || this.isRecoveryHome) {
      this.sameTreatOpts = this.editOptions.registerType.filter(x => this.sameTreatCodeWhen居康.some(y => y == x.value));
    } else if (this.fReg?.Value?.ICode == RegisterConst.ICode_009_Other) {
      this.sameTreatOpts = this.editOptions.registerType.filter(x => this.sameTreatCodeWhenICodeOther.some(y => y == x.value));
    } else {
      this.sameTreatOpts = this.editOptions.registerType;
    }
    // 如果不是在可用的療程，回到一般
    if (!this.sameTreatOpts.some(x => x.value == this.fReg.Value.SameTreat)) {
      this.toNormalSameTreat();
    }
  }
  async getAllTreatment() {
    if (this.multiTreatList.length == 0 && (this.isHomeCare || this.isRecoveryHome)) {
      this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, this.regDateTimeStr, '');
      this.allToGetMethod = [RegisterConst.SameTreat_4_月療,
      RegisterConst.SameTreat_8_慢箋領藥];
      if (this.multiTreatList.length == 1) {
        this.onlyonelist = this.multiTreatList[0];
        this.isRehabilitaion = this.onlyonelist.CusTP == RegisterConst.SameTreat_1_復健 ? true : false;
        this.isOpenMultiTraat = this.onlyonelist.CusTP == RegisterConst.SameTreat_1_復健 ? true : false;

        this.onSameTreatChange({ value: RegisterConst.SameTreat_4_月療, prev: RegisterConst.SameTreat__一般看診 });
      }
      else if (this.multiTreatList.length > 1) {
        this.isOpenMultiTraat = true;
      }
    }
    else if (this.multiTreatList.length == 0 && !(this.isHomeCare || this.isRecoveryHome)) {
      this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, this.regDateTimeStr, '');
      this.allToGetMethod = [
        RegisterConst.SameTreat_4_月療,
        RegisterConst.SameTreat_1_復健,
        RegisterConst.SameTreat_8_慢箋領藥,
        RegisterConst.SameTreat_3_換藥,
        RegisterConst.SameTreat_0_洗腎];
      if (this.multiTreatList.length == 1) {
        this.onlyonelist = this.multiTreatList[0];
        this.isRehabilitaion = this.onlyonelist.CusTP == RegisterConst.SameTreat_1_復健 ? true : false;
        this.isOpenMultiTraat = this.onlyonelist.CusTP == RegisterConst.SameTreat_1_復健 ? true : false;
        let tp = this.multiTreatList[0].CusTP;
        this.onSameTreatChange({ value: tp, prev: RegisterConst.SameTreat__一般看診 });
      }
      else if (this.multiTreatList.length > 1) {
        setTimeout(() => {
          this.isOpenMultiTraat = true;
          this.notification.showInfo('同療看診畫面載入完成');
          // console.log('getMultiTraat', new Date());
        }, 50);
      }
    }
  }

  // 開啟醫師班表
  onOpenCalendarClick() {
    this.isOpenCalendar = true;
  }

  // 醫師班表回傳
  async onCalendarEmit(result: ScheduleSelection) {
    this.isOpenCalendar = false;

    this.editFormGroup.controls['RegDate'].setValue(result.ScheduleDate);
    this.regDateTime = new Date(result.ScheduleDate);
    this.editFormGroup.controls['TimeSec'].setValue(result.SecTime);
    await this.refreshDoctorRoom(result.DoctorId);
    this.setStatusByReg();
    this.getRegisterNo();
  }
  //#endregion

  //#region check function
  checkCanCancel() {
    var _searchDate = this.editFormGroup.controls["RegDate"].value;
    return this.opHelper.RegisterCanCancel(_searchDate)
  }
  //#endregion

  checkCanCancelDate(regDate: Date) {
    var _searchDate: Date = regDate;
    return this.opHelper.RegisterCanCancel(_searchDate);
  }

  //#region 取消掛號

  // 取消掛號
  async cancelRegister() {
    // 檢查參數
    if (!this.editFormGroup || !this.editFormGroup.value.Id) {
      this.notification.showParameterError(); // 顯示參數錯誤
      return;
    }
    // 呼叫api
    // 取消掛號
    try {
      await this.regApi.CancelRegister(this.editFormValue.Id, false);
      this.notification.showOk();
      this.cancel();
    } catch (ex) {
      this.notification.showFailed()
    }
  }
  //#endregion

  //#region  取消/返回
  cancel() {
    this.destroyEditFormGroup();
    this.cache.set("WaitingList_PatientRegDate_" + UserCache.getLoginUser().CId, null);
    this.localStorage.setItem("WaitingList_PatientRegId_" + UserCache.getLoginUser().CId, this.regId);
    if (this.isFromCompEnter) {
      this.ExitRegisterEvent.emit(0);
    } else {
      this.goBack(); // 回到前畫面
    }
  }

  isSavedReturn() {
    this.destroyEditFormGroup();
    if (this.isFromCompEnter) {
      this.ExitRegisterEvent.emit(this.regId);
    } else {
      this.goBack(1); // 回到前畫面
    }
  }

  goBack(isSaved: number = 0) {
    // 預設go back路徑
    if (this.fromOpd) {
      const url = this.routeInfo.makeFullUrl(this.routeOpdList);
      this.router.navigate([url]);
    } else {
      const url = this.routeInfo.makeFullUrl(this.routeRegList);
      this.router.navigate([url, { isSaved: isSaved }]);
    }
  }
  //#endregion

  //#region 掛號通知 --------------------
  initSignalForSend() {
    if (this.isUsingSignal) {
      this.signal.startConnection(() => {
      });
    }
  }

  sendNotify() {
    if (!this.isSendNotify) {
      return;
    }
    const roomCode: string = this.editFormGroup.controls['RoomCode'].value;
    // signal api本身包含auth資訊，已經會有目前登入之clinic資訊
    const signalGroupId = this.signal.GenGroupId('Register', roomCode);
    if (this.isUsingSignal) {
      this.signal.joinGroup(signalGroupId);
      this.signal.registerNotify(signalGroupId, RegisterConst.SignalGroupName);
    }
  }
  //#endregion --------------------

  //#region reg date --------------------
  setStatusByReg() {
    const regDate = new Date(this.fReg.Value.RegDate);
    // 編輯時不改狀態(編輯時不能修改掛號日期跟時段前題下)
    // 候診跟預約的情況才自動調整
    if (this.isAdd) {
      if (regDate > DateHelper.todayMax) {
        this.fReg.patchValue({ StatusCode: RegisterConst.Code_Status_Appointment });
      } else {
        const timeSec = this.editFormValue.TimeSec;
        if (timeSec > this.getNowTimeSection()) {
          this.fReg.patchValue({ StatusCode: RegisterConst.Code_Status_Appointment });
        } else {
          this.fReg.patchValue({ StatusCode: this.selectedStatusCode || RegisterConst.Code_Status_Waiting });
        }
      }
    }
  }

  validRegDate(): boolean {
    if (this.isDemoMode) {
      return true;
    }
    const regDate = new Date(this.editFormValue.RegDate);
    if (regDate < DateHelper.today) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showErrorById('MSG_RegistersRegister1');
      return false;
    }
    return true;
  }
  //#endregion --------------------

  //#region print --------------------
  print(regId: number) {
    // 因為改為前端取號，所以不需要從存檔結果列印
    const reg = this.editFormGroup.getRawValue();
    if (reg.StatusCode !== RegisterConst.Code_Status_Waiting ||
      reg.StatusCode !== RegisterConst.Code_Status_Appointment) {
      // 只需要PatientId跟RegId目前
      // 掛號單含收據(與批價收據不同)
      if (this.isPrintReceipt) {
        this.printService.printRegisterForm(regId);
      }
    }
  }
  //#endregion --------------------

  // 視窗關閉時的提醒
  @HostListener('window:beforeunload', ['$event'])
  async winBeforeUnload($event) {
    // unlock record...
    // 暫時沒棄號問題
    // if(this.isAdd){
    //   await this.giveUpCurrnt();
    // }
    return true;
  }

  async giveUpCurrnt(callback = null) {
    if (this.prevQueryRegNoState != null) {
      try {
        await this.regApi.UpdateGiveUpNo(
          this.prevQueryRegNoState.date,
          this.prevQueryRegNoState.section,
          this.prevQueryRegNoState.roomCode,
          this.prevQueryRegNoState.isAppointment,
          this.prevQueryRegNoState.number);
        this.prevQueryRegNoState = null;
        if (callback != null) {
          callback();
        }
      } catch (ex) {
        this.notification.showErrorById('MSG_RegistersRegister2');
      }
    }
  }
  showElement = false;
  vtRecords: PageData2<VitalSign>;
  async getVtRecords() {
    this.vtRecords = await this.histApi.getVitalSign(this.initReg.PatientId, 0, 0);
  }

  ngAfterViewInit(): void {
    this.getRatio();
    this.getPopularScreen();
    setTimeout(() => {
      this.rescale();
    }, 0);

    setTimeout(() => {
      this.showElement = true;
    }, 500);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    // this.onHistLoadedSub.unsubscribe();
  }

  newRegisterWidth: number;
  newRegisterOutWidth: number;
  newRegisterHeight: string;
  scaleNum: number;
  rescale(): void {
    const elem = this.newRegisterRef.nativeElement;
    const elemOut = this.newRegisterOutRef.nativeElement;
    //let realScales = realScale_1.offsetWidth + realScale_2.offsetWidth + realScale_3.offsetWidth + realScale_4.offsetWidth + realScale_5.offsetWidth;
    let realScales = elem.offsetWidth;
    let i = 0;
    const height = elem.offsetHeight;
    const width = elem.offsetWidth;
    let outWidth = elemOut.offsetWidth;

    if (realScales > 0) {
      let scalex = parseFloat(elem.getAttribute('scalex') || '1');
      let scaley = parseFloat(elem.getAttribute('scaley') || '1');
      outWidth = elemOut.offsetWidth;
      // padding 4px 10px , scroll bar 10
      this.newRegisterOutWidth = outWidth - 20 - 10;// - 95;

      i = this.newRegisterOutWidth / realScales;
      this.scaleNum = i;
      scalex = this.scaleNum;
      scaley = this.scaleNum;
      const newHeight = height * scaley;
      this.newRegisterHeight = newHeight + 5 + 'px';
      const newWidth = width * scalex;
      elem.style.transform = `scale(${scalex}, ${scaley})`;
      document.documentElement.style.setProperty('--reg-scale', scaley.toString())
      this.newRegisterWidth = newWidth;
      // 下方病歷區高度 => scroll到底要正好上方對齊IC行
      // 取得電話行的高度
      var phoneRowHeight = (elem.children.item(0) as HTMLElement).offsetHeight
      // 掛號資料扣除電話行高
      var registerRigionHeight = elem.offsetHeight - phoneRowHeight;
      // 40 = title + margin bottom
      var sameTreatHeightPx = (this.bodyHeight - 40 - registerRigionHeight * scaley);
      this.sameTreatHeight = sameTreatHeightPx + 'px';
      // 醫令/特別記載標題行高 + margin
      this.ccRmHeight = (sameTreatHeightPx - 30) + 'px';
    }
  }

  /** 舊病歷 */
  async refreshList() {
    
    if (!this.initReg.PatientId) {
      return;
    }
    var data = await this.regApi.GetHistoryList(this.initReg.PatientId);
    this.listItems = data;
    // 表格分頁
    this.setGridView();
    if (this.listItems && this.listItems[0]) {
      this.emitSelectHist(this.listItems[0].RegId, false);
      if (this.slider_stateA === 'slide_in') {
        setTimeout(() => {
          this.histViewComp.selectHist(this.listItems[0].RegId);
        }, 1);
      }
    }
    // this.apiRegister.get('GetHistoryList?pId=' + ).pipe(takeUntil(this.unsubscribe)).subscribe(
    //   (data: RegHistListDto[]) => {
    //     this.listItems = data;
    //     // 表格分頁
    //     this.setGridView();
    //     if (this.listItems && this.listItems[0]) {
    //       this.emitSelectHist(this.listItems[0].RegId, false);
    //       if (this.slider_stateA === 'slide_in') {
    //         setTimeout(() => {
    //           this.histViewComp.selectHist(this.listItems[0].RegId);
    //         }, 1);
    //       }
    //     }
    //   },
    //   error => this.notification.showGetDataFailed() // 失敗訊息
    // );
    // this.getVtRecords();
  }

  handleListData(data: RegHistListDto[]) {
    this.allItems = data;
    // 測試用
    return data.filter(d => d.RegId == this.initReg.RegId);
  }

  changeList(id: number) {
    this.listItems = this.allItems.filter(d => d.RegId == id);
    this.pageSkipNo = 0;
    this.setGridView();
  }

  // 表格分頁
  setGridView() {
    this.gridView = {
      data: this.listItems.slice(this.pageSkipNo, this.pageSkipNo + this.pageSize),
      total: this.listItems.length
    };
    this.cd.detectChanges();
  }

  emitSelectHist(histId: number, isSendToPad = true) {
    this.emitSelect.emit(histId);
    // 通知helper pad元件
  }
  async checkChange(evt: MatRadioChange, data: any) {
    if (!isNullOrUndefined(this.fReg.Value.SameTreat) && this.fReg.Value.SameTreat != '') {
      var isTheSameIcode = this.fReg.Value.Icno == data.Isqno;
      if (isTheSameIcode && data.IsPres && this.fReg.Value.SameTreat == '8' && (data.SameTreat =='' || data.SameTreat == '8'))this.checkHistDto = data;
      else if (isTheSameIcode && this.fReg.Value.SameTreat == data.SameTreat) {
        this.checkHistDto = data;
      }
      else {
        this.notification.showWarning('您的療程不符合複製該病歷!');
        setTimeout(() => {
          evt.source.checked = false;
          this.checkHistDto = null;
        }, 1);
      }
    }
  }

  //#region Fetch data from IC Card
  /**
   * 重新取卡 重新讀卡
   */
  async onBtnIsqnoClick() {
    if (!this.canEdit) {
      return;
    }
    //已有刷卡檔
    if (this.initReg?.NHIRegICDto) {
      await this.userConfirm.showAlert('警告',
        '此筆掛號紀錄刷卡檔(3i)已產生！\n\n' +
        '欲進行卡片資料變更請進行退掛後重新掛號：\n' +
        '  1.回到候診清單\n' +
        '  2.點選[退掛]按鈕\n' +
        '  3.輸入退掛患者的病歷號或其他基資蒐尋\n' +
        '  4.選取預退掛的紀錄(若只有一筆則直接進入退掛畫面)\n' +
        '  5.進行退掛作業後重新掛號\n\n' +

        '或者刪除病歷後重新掛號：\n' +
        '  1.進入門診畫面\n' +
        '  2.病歷刪除\n' +
        '  3.重新掛號。', { width: 300 }
      );
      return
    }

    if (this.isFinish) {
      await this.userConfirm.showAlert('還卡作業',
        '此筆掛號紀錄已完診！\n\n' +
        '欲進行還卡：\n' +
        '  1.回到候診清單\n' +
        '  2.點選[還卡]按鈕\n\n' +
        '改回一般掛號：\n' +
        '  1.進入門診畫面\n' +
        '  2.病歷刪除\n' +
        '  3.重新掛號並完診。', { width: 300 }
      );
      return
    }
    if (await this.patchDataFromICCard(this.fReg.Value.ICode)) {
      // 重新計算同療處理
      this.onSameTreatChange({ value: this.fReg.Value.SameTreat, prev: this.fReg.Value.SameTreat })
    }

  }


  /**
   * 健保IC卡卡片內容更新作業
   */
  async updateHCNoRest() {
    // this.notification.showInfo("卡片內容更新...");
    // let processResult = await this.hcrService.VisionApi.UpdateHCNoReset();
    // if (processResult.StatusCode != 0) {
    //   this.notification.showError(processResult.ErrorMsg);
    // }
  }
  //#endregion

  //#region Fetch data from server
  /** 取得編輯選項 */
  private async getEditOptions() {
    // 呼叫api
    try {
      this.editOptions = await this.regApi.GetEditOptions();
      this.editOptions.timeSection = this.editOptions.timeSection.filter(x => x.value != null && x.value != 0);
      this.isBeforeCharge = this.editOptions.isBeforeCharge;
      this.payWay = this.editOptions.payWay.split(',');
      this.isPrintReceipt = this.editOptions.IsPrintReceipt;
      this.updateSameTreatOpt()
    } catch (ex) {
      this.notification.showError(ex);
    }
  }

  /** 取得預保 */
  async GetProphylacticHealth() {
    // 處理預防保健
    try {
      if (this.initReg.PatientId) {
        this.phOption = await this.opHelper.GetProphylacticHealth(this.initReg.PatientId, DateHelper.formatDate(this.initReg.RegDate));
        if (this.phOption && this.phOption.length > 0) {
          this.phOptionAll = this.phOption.filter(f => f.value != null && f.value != "");
        }
        //this.phOptionAll = await this.opHelper.GetAllProphylacticHealth();
      }
    } catch (ex) {
      // 預防保健載入失敗
      this.notification.showError('預防保健資料載入失敗');
    }
  }
  /** 用掛號時間與患者Id取得可用卡序 */
  async getICNoOptions() {
    //取得可用卡序
    try {
      if (this.initReg.PatientId) {
        var patientId = this.initReg.PatientId;
        var regDate = DateHelper.formatDate(this.fReg.Value.RegDate);
        var spc = this.isEditSPC ? this.fReg.Value.SpcIdentity : '';
        var opts = await this.regApi.GetICNoOpts(patientId, regDate,spc,this.isEditSPC);
        //預防保健卡序(icno)選項
        this.phOption = opts.ph;
        //身分別可用卡序(icno)
        this.icodeIcnoOption = opts.icode;
        if (this.phOption && this.phOption.length > 0) {
          this.phOptionAll = this.phOption.filter(f => f.value != null && f.value != "");
        }
      }
    } catch (ex) {
      // 預防保健載入失敗
      this.notification.showError('預防保健資料載入失敗');
    }
    // if (this.icodeSetting?.length < 1){
    //   var birthDay = DateHelper.formatDateTime(this.fReg.Value.PatientBirth);
    //   let regDateTime = DateHelper.formatDateTime(this.editFormValue.RegDate);

    //   console.log('regDateTime',birthDay,regDateTime);
    //   this.icodeSetting = await this.regApi.GetOtherIcodeSetting(birthDay,regDateTime);
    // }
    // if (this.registerOther?.length <1){
    //   this.registerOther = [...this.icodeSetting];
    //   var icno = this.typ_xcg_keepReg.seqNo;
    //   var item: ValueTextPair = {
    //     text: icno|| '無',
    //     value: icno || '',
    //   };
    //   this.registerOther.unshift(item);
    // }
  }
  async getMultiTreatResult(data: MutiTreatmentListDto[]) {
    this.onlyonelist = data[0];
    let tp = this.onlyonelist?.CusTP ?? ''
    this.isRehabilitaion = tp == '1' ? true : false;
    if (this.multiTreatmentUser.length < 1) this.multiTreatmentUser = await this.regApi.GetRehabitMedUesrs();
    this.initmultiTreatmentUserData = new InitmultiTreatmentUserData();
    this.initmultiTreatmentUserData.Data = this.onlyonelist;
    this.initmultiTreatmentUserData.Users = this.multiTreatmentUser;
    if (data.length == 0) this.notification.showInfo('您使用一般流程');
    // console.log('tp',tp);
    this.onSameTreatChange({ value: tp, prev: RegisterConst.SameTreat__一般看診 });
    /** 選同療帶出門診列表 */
    if (this.onlyonelist && this.onlyonelist.HistList[0]) {
      if (this.onlyonelist.HistList.length > 0) {
        setTimeout(() => {
          this.histViewComp.selectHist(this.onlyonelist.HistList[0].Register.Id);
        }, 10);
      }
    }
  }

  phOptEnable(code) {
    return this.phOption.some(x => x.value == code);
  }

  /** 重新載入班表 */
  private async refreshDoctorRoom(doctorId?: number) {
    const timeSec = this.editFormGroup.controls['TimeSec'].value;
    const date = this.editFormGroup.controls['RegDate'].value;
    const org_MedDeptCode = this.editFormGroup.controls['MedDeptCode'].value;
    const now = new Date(date);
    var iseditDoc = this.initReg.CanEditByDesk() || this.checkCanCancel();
    var dr = await this.apiSchedule.get('getdoctors?year=' + now.getFullYear()
      + '&month=' + (now.getMonth() + 1)
      + '&timesec=' + timeSec
      + '&day=' + now.getDate()
      + '&isHomeCare=' + (this.isHomeCare || this.isRecoveryHome)
      + '&iseditDoc=' + iseditDoc )
      .toPromise();

    // console.log('dr +++++', dr);

    this.medRoom = dr.medRoom;
    // 查無班表 -> 選單預設給【一診】(無班表時medRoom會有一筆，但value='')
    if (this.medRoom.length == 0 || (this.medRoom.length == 1 && this.medRoom[0].value == '')) {
      if (this.editOptions?.medRoom?.length > 0 && this.editOptions?.medRoom?.filter(f => f.value != '').length > 0) {
        this.medRoom = this.editOptions.medRoom.filter(f => f.value != '');
        this.room = this.medRoom.length > 0 ? this.medRoom[0]?.value : '1';
      } else {
        this.medRoom = [{ value: '1', text: '一診' }];
        this.room = '1';
      }
    }
    this.doctors = dr.doctors;
    this.doctorRoomMapping = dr.mapping;
    this.deptMap = dr.deptMap;
    var roomCode = this.editFormValue.RoomCode || this.room;
    if (roomCode) {
      var docRoom = this.doctorRoomMapping.find(m => m.roomCode == roomCode);
      var dept = this.deptMap.find(m => m.doctorId == docRoom?.doctorId)?.sectionCode;
      this.editFormGroup.patchValue({
        RoomCode: docRoom?.roomCode,
        DoctorId: docRoom?.doctorId,
        MedDeptCode: org_MedDeptCode != null && org_MedDeptCode != "" ? org_MedDeptCode : dept
      }, { 'emitEvent': false });
    }
    if (doctorId) {
      var docRoom = this.doctorRoomMapping.find(m => m.doctorId == doctorId);
      if (docRoom) {
        var dept = this.deptMap.find(m => m.doctorId == docRoom?.doctorId)?.sectionCode;
        this.editFormGroup.patchValue({
          //無排班表時，診間對應會是空診間，此時不變更當前所選
          RoomCode: docRoom?.roomCode ?? this.fReg.Value.RoomCode,
          DoctorId: docRoom?.doctorId,
          MedDeptCode: org_MedDeptCode != null && org_MedDeptCode != "" ? org_MedDeptCode : dept
        }, { 'emitEvent': false });
      } else {
        this.editFormGroup.patchValue({
          DoctorId: doctorId,
        }, { 'emitEvent': false });
      }
    }

    // 無診間 -> 預設登入醫師之診間 -> 查無診間的話預設一診
    if (!this.editFormGroup.controls['RoomCode'].value || this.editFormGroup.controls['RoomCode'].value.length == 0 || this.editFormGroup.controls['RoomCode'].value == "undefined") {
      var loginDocId = UserCache.getLoginUser().UserId;
      var docRoom = this.doctorRoomMapping.find(m => m.doctorId == loginDocId);
      if (docRoom) {
        this.editFormGroup.patchValue({
          RoomCode: docRoom.roomCode ? docRoom.roomCode : this.room,
          DoctorId: docRoom.doctorId,
        }, { 'emitEvent': false });
      } else {
        this.editFormGroup.patchValue({
          RoomCode: '1',
        }, { 'emitEvent': false });
      }
    }

    // 無診間->預設一診
    // if (this.editFormGroup.controls['RoomCode'].value == null || this.editFormGroup.controls['RoomCode'].value.length == 0) {
    //   this.editFormGroup.patchValue({
    //     RoomCode: '1',
    //   }, { 'emitEvent': false });
    // }

    // 無科別 -> 預設診所科別
    if (this.editFormGroup.controls['MedDeptCode'].value == null || this.editFormGroup.controls['MedDeptCode'].value.length == 0) {
      this.editFormGroup.patchValue({
        MedDeptCode: UserCache.getLoginUser().Clinic.MedDeptCode,
      }, { 'emitEvent': false });
    }
  }

  // 取得醫師掛號費
  async getDoctorOwnFee(doctorId: number) {
    if (doctorId >= 1) {
      var doctorfee = this.apiPayRegister.get('Get/' + doctorId)
        .toPromise<UserRegfee>();
      return doctorfee;
    }
    return null;
  }

  // 帶入醫師掛號費至掛號費中
  setDoctorOwnFee(doctorOwnFee: UserRegfee) {
    var type = this.editFormGroup.controls['ICode'].value;
    var statusCode = this.editFormGroup.controls['StatusCode'].value;
    var sameTreat = this.editFormGroup.controls['SameTreat'].value;
    var FirstVist = this.editFormGroup.controls['VisitSourceCode'].value;

    var choosefee: number;
    if (doctorOwnFee == null) {

      this.editFormGroup.controls['RegisterFee'].setValue(this.originRegisterfee);
      return;
    }
    if (doctorOwnFee.Id < 1) {
      this.editFormGroup.controls['RegisterFee'].setValue(this.originRegisterfee);
      return;
    }
    if (FirstVist == 'F') {
      this.editFormGroup.controls['RegisterFee'].setValue(doctorOwnFee.RegFeeNew);
      return;
    }

    switch (type) {
      case '000':
        choosefee = doctorOwnFee.RegFeeO;
        break;
      case 'A':
        choosefee = doctorOwnFee.RegFeeA;
        break;
      case '001':
        choosefee = doctorOwnFee.RegFee1;
        break;
      case '004':
        choosefee = doctorOwnFee.RegFee4;
        break;
      case '006':
        choosefee = doctorOwnFee.RegFee6;
        break;
      case '003':
        choosefee = doctorOwnFee.RegFee3;
        break;
      default:
        choosefee = this.originRegisterfee;
        break;
    }
    if (statusCode == '*') {
      choosefee = doctorOwnFee.RegFeeU;
    }
    if (sameTreat == '2') {
      choosefee = doctorOwnFee.RegFee2;
    }

    this.editFormGroup.controls['RegisterFee'].setValue(choosefee);
  }

  /** 取得付費資訊 */
  async getChargePaymentInfo(discountCode: string = '', iType: string = '') {
    try {
      var formValue = this.fReg.Value;
      var doctorId = formValue.DoctorId;
      if (this.fReg.Value.INote == RegisterConst.INote_代檢
        && this.fReg.Value.ICode == RegisterConst.ICode_009_Other) {
        // 切換代檢的時候重新取得(要取消押金)
        iType = '4'
      }
      var payInfo = await this.regApi.GetRegisterPaymentInfo(
        UserCache.getLoginUser().Clinic.Id,
        formValue.VisitSourceCode,
        discountCode == '' ? formValue.DiscountCode : discountCode,
        iType == '' ? formValue.IType : iType,
        formValue.ICode,
        formValue.SameTreat,
        doctorId,
        DateHelper.formatDateTime(this.initReg.PatientBirth),
        this.regDateTimeStr,
      );
      if (payInfo != null) {
        // 切換醫師時，取該醫師的費用 (先撈資料，避免掛號費的ui更新看起來慢一拍)
        this.doctorOwnFee = payInfo.DoctorRegFee;// await this.getDoctorOwnFee(doctorId);
        this.editFormGroup.controls["Deposit"].setValue(payInfo.Deposit);
        this.editFormGroup.controls["OwnFee"].setValue(payInfo.OwnFee);
        this.editFormGroup.controls["SelfPay"].setValue(payInfo.SelfPay);
        this.keepOwnFee()
        this.originRegisterfee = payInfo.RegisterFee; // 沒有設定醫師掛號費使用此費用
        this.setDoctorOwnFee(this.doctorOwnFee);
      } else {
        this.notification.showError('找不到計價費用資料');
      }
    } catch (ex) {
      this.notification.showError(ex);
    }
  }

  /** 取得候診號 */
  async getRegisterNo() {
    var is報到取號 = this.isCheckin && (!this.fReg.Value.WaitNo|| this.fReg.Value.WaitNo=='0');
    // 新增/ 新生兒/ 無號報到/ 重新取號
    if (!this.isAdd && !this.displayNewBorn && !(is報到取號)) {
      // 編輯狀態下 如果診間+日期+時段+狀態跟原本一樣的話 使用原本的候診號
      // 頁面載入時尚未keep資料，也維持原號
      if(!this.isLoaded){
        return;
      }
      if ((this.fReg.Value.RoomCode == this.typ_xcg_keepReg.roomCode &&
        this.fReg.Value.TimeSec == this.typ_xcg_keepReg.regSec &&
        this.fReg.Value.RegDate.toString() == this.typ_xcg_keepReg.regDate.toString() &&
        this.fReg.Value.StatusCode == this.typ_xcg_keepReg.state)) {
        this.revertWaitNo();
        return;
      }
    }

    const query = {
      date: DateHelper.getIsoStringNoTimeZone(this.editFormGroup.controls['RegDate'].value),
      roomCode: this.editFormGroup.controls['RoomCode'].value as string,
      section: this.editFormGroup.controls['TimeSec'].value as number,
      // 報到若重新取號用現場規則
      isAppointment: is報到取號?false: this.editFormGroup.controls['StatusCode'].value === RegisterConst.Code_Status_Appointment,
      number: 0,
    };

    if (!this.isHomeCare) {
      if (!query.roomCode) {
        return;
      }
    }
    // 預取號而已暫時沒棄號問題
    // // 有過先前的取號的話 先棄號
    // if (this.prevQueryRegNoState != null) {
    //   await this.giveUpCurrnt();
    // }
    // 取號
    try {
      this.prevQueryRegNoState = query;
      const no = await this.regApi.GetWaitNo(query.date, query.section, query.roomCode, query.isAppointment);
      //const no = await this.api.get('GetWaitNo', query).toPromise();
      this.editFormGroup.controls['WaitNo'].setValue(no);
      this.prevQueryRegNoState.number = no;
    } catch (err) {
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      this.notification.showErrorById('MSG_RegistersRegister2');
    }
  }

  //#endregion
  batchPhOpt: ICNoPHOption[] = [];
  isPhCheck(item: ICNoPHOption) {
    return this.batchPhOpt.some(x => x.value == item.value);
  }

  phCheck(evt: MatCheckboxChange) {
    if (evt.checked) {
      if (!this.batchPhOpt.some(x => x.value == evt.source.value)) {
        this.batchPhOpt.push(this.phOption.find(x => x.value == evt.source.value));
      }
    } else {
      if (this.batchPhOpt.some(x => x.value == evt.source.value)) {
        this.batchPhOpt.splice(this.batchPhOpt.findIndex(x => x.value == evt.source.value), 1);
      }
    }
  }

  phReset(evt: MatCheckboxChange) {
    if (evt.checked) {
      this.batchPhOpt = [];
    }
  }
  async phBatchRegister() {
    this.isSaveClicked = true;
    if (this.validateBeforeSave()) {
      if (this.batchPhOpt.length > 0) {
        this.mainLayoutService.showLoader();
        //預防保健
        this.editFormGroup.controls["INote"].setValue("");
        this.editFormGroup.controls["SameTreat"].setValue("2")
        this.editFormGroup.controls["Nhictp"].setValue("AC");
        for (var x of this.batchPhOpt) {
          //切換預保項目
          this.setIcno(x.iccode.replace("IC", ""));
          this.setIsqNo(x.iccode)
          //掛入, 儲存完成後不能跳出
          await this.save(this.editFormGroup.getRawValue(), false);
          //等一秒 下一筆
          await delay(1000);
        }
      } else {
        // 一般掛號
        this.toNormalSameTreat();
        await this.save(this.editFormGroup.getRawValue(), false)
      }
      this.mainLayoutService.hideLoader();
      this.isSaveClicked = false;
      // 回前頁
      this.isSavedReturn();
    }
  }
  newBornFormHelper: FormHelper<NewBornData>;
  isNewBorn = false;
  newBornList: NewbornViewDto[];
  async newBornOpen() {
    if (this.canEdit || this.isAdd) {
      this.newBornList = await this.patientApi.GetNewBornList(this.patientId);
      this.newBornFormHelper = this.fh.Create<NewBornData>()
        .build({
          Name: [this.editFormValue.PatientFirstName + '之子', [Validators.required]],
          Birth: [new Date(), [ValidationTipComponent.MinDateWithMsg(AddDay(new Date(), -59), '新生兒出生超過60天不可進行依附')]],
          Sex: [0, [Validators.required]],
          CId: ['', [Validators.required]],
          Tel: [this.editFormValue.PatientPhoneHome, []],
          Address: [this.editFormValue.PatientStreet, []],
          BirthsNum: [1, []],
          MonParentsId: [this.editFormValue.PatientId, []]
        });
    }
  }

  async existNewBornReg(pid: number, dialog: VisDialogComponent) {
    this.isSaveClicked = true;
    this.isNewBornReg = true;
    this.add(pid);
    dialog.show = false;
    this.isSaveClicked = false;
  }

  /** 寫入依附註記 */
  async writeNewBornMark(dialog: VisDialogComponent) {
    var formFields = this.newBornFormHelper.FieldName;
    if (!this.newBornFormHelper.Controls.Birth.valid) {
      this.notification.showError(this.newBornFormHelper.Controls.Birth.errors['custom']);
      return;
    }
    try {
      var ret = await this.icHelper.getAPI().WriteNewBorn({
        NewBornBirthDate: DateHelper.getROCFullDateString(this.newBornFormHelper.getValue(formFields.Birth)),
        NewBornBirthNum: this.newBornFormHelper.getValue(formFields.BirthsNum).toString(),
        PatientBirthDate: DateHelper.getROCFullDateString(this.editFormValue.PatientBirth),
        PatientId: this.editFormValue.PatientCId,
      });

      if (ret.StatusCode == 0) {
        this.notification.showSuccess('寫入依附註記成功');
        this.isNewBornReg = false;
        dialog.show = false;
      } else {
        this.notification.showError('寫入依附註記失敗(' + ret.ErrorMsg + ')');
      }
    } catch (e) {
      this.notification.showError('寫入依附註記失敗(' + e.msg + ')');
    }
  }

  isNewBornReg: boolean = false;
  async newBornReg(dialog: VisDialogComponent) {
    var formFields = this.newBornFormHelper.FieldName;
    if (!this.newBornFormHelper.Controls.Birth.valid) {
      this.notification.showError(this.newBornFormHelper.Controls.Birth.errors['custom']);
      return;
    }
    var data: NewBornCreateDto = {
      NewbornNote: {
        Id: 0,
        MonParentsId: this.newBornFormHelper.getValue(formFields.MonParentsId),
        BirthsNum: this.newBornFormHelper.getValue(formFields.BirthsNum),
        IsGetIC: false,
        DelNoteDT: null,
        IsAlive: true,
        IsEnabled: true,
      }, Patient: {
        CName: this.newBornFormHelper.getValue(formFields.Name),
        Sex: this.newBornFormHelper.getValue(formFields.Sex),
        Birthday: this.newBornFormHelper.getValue(formFields.Birth),
        CId: this.newBornFormHelper.getValue(formFields.CId),
        ICode: 'A'  // 新生兒依附掛號一定是健保身分
      }
    };
    try {
      var patientId = await this.patientApi.SaveNewBorn(data);
      var ret = await this.icHelper.getAPI().WriteNewBorn({
        NewBornBirthDate: DateHelper.getROCFullDateString(this.newBornFormHelper.getValue(formFields.Birth)),
        NewBornBirthNum: this.newBornFormHelper.getValue(formFields.BirthsNum).toString(),
        PatientBirthDate: DateHelper.getROCFullDateString(this.editFormValue.PatientBirth),
        PatientId: this.editFormValue.PatientCId,
      });
      this.notification.showSuccess('新增成功');
      this.isNewBornReg = true;
      this.add(patientId);
      dialog.show = false;
      if (ret.StatusCode != 0) {
        this.notification.showSuccess('依附掛號新增成功，但寫入依附註記出現錯誤:' + ret.ErrorMsg);
      } else {
        this.notification.showSuccess('依附掛號新增成功');
      }
    } catch (e) {
      this.notification.showError('新增失敗(' + e.msg + ')');
    }
  }

  howOld: number = 0;

  oriHosp = '';
  oriDate = null;
  refDiagFee = false;
  referralInSure(dialog: VisDialogComponent) {
    // submit
    dialog.show = false;
    if (this.isAdd) {
      if (this.oriHosp && (this.oriDate && this.oriDate != '1970/1/1')) {
        this.editFormGroup.controls['Nhictp'].setValue('06');
      } else {
        this.revertNHIcTp();
      }
    }
  }

  referralInDiagClose(dialog: VisDialogComponent) {
    dialog.show = false;
    this.refDiagFee = false;
  }

  getRegCondiction() {
    let sex = '';
    sex = this.editFormGroup.controls['PatientSexName'].value
    return sex;
  }

  getInitRPId(): number {
    if (this.initReg.PatientId)
      return this.initReg.PatientId
  }

  calculateAge(src: any): string {
    if (src == null || src == '') {
      return '';
    }

    var result = "";
    // collect input date
    var dob = new Date(src);
    var dobYear = dob.getFullYear();
    var dobMonth = dob.getMonth();
    var dobDate = dob.getDate();

    // get the current date from the system
    var now = new Date();
    // extract the year, month, and date from current date
    var currentYear = now.getFullYear();
    var currentMonth = now.getMonth();
    var currentDate = now.getDate();

    // get years
    var yearAge = currentYear - dobYear;

    // get months
    if (currentMonth >= dobMonth) {
      //get months when current month is greater
      var monthAge = currentMonth - dobMonth;
    } else {
      yearAge--;
      var monthAge = 12 + currentMonth - dobMonth;
    }

    // get days
    if (currentDate >= dobDate) {
      // get days when the current date is greater
      var dateAge = currentDate - dobDate;
    } else {
      monthAge--;
      // get the days in the last month
      var daysInLastMonth = new Date(currentYear, currentMonth, 0).getDate();
      var dateAge = daysInLastMonth + currentDate - dobDate;
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    this.howOld = yearAge;
    // group the age in a single variable
    result = yearAge + '歲' + monthAge + '月' + dateAge + '天';
    return result;
  }

  /** 同療3選單 以ABC依序分類:一般看診 復健同療 預防保健*/
  slider_stateA: string = "idle";
  slider_stateB: string = "ready";
  initThreeToggle: number = null;
  // 一般看診 | 同療看診 切換
  getThreeToggle(index: number) {
    if (index === 0) {
      this.slider_stateA = 'idle';
      this.slider_stateB = 'hidden';
      setTimeout(() => {
        this.slider_stateB = 'ready'
      }, 300);
      if (this.onlyonelist?.HistList?.length > 0) {
        setTimeout(() => {
          this.histViewComp.selectHist(this.onlyonelist.HistList[0].Register.Id);
        }, 10);
      }
      // 原同療看診 => 一般看診 僅在一般看診才帶入
      if (this.initThreeToggle != 0 && this.isAdd) {
        this.toNormalSameTreat()
        this.initThreeToggle = 0;
      }
    } else if (index === 1) {
      this.slider_stateA = 'hidden';
      this.slider_stateB = 'idle';
      this.initThreeToggle = 1;

      setTimeout(() => {
        this.slider_stateA = 'ready'
      }, 300);
    }
  }
  /** 一般看診 regMenu */
  private nhiRecord: NhiRecord;
  onHistLoadedSub: Subscription;
  criIllNoHPC: CriticalIllnesNoHPC[] = [];
  treatmentHPC: TreatmentHPC[] = [];
  patient: BasicData = null;
  prescriptionDetailHVE: PrescriptionDetailHVE[] = [];
  prescriptionDetailOutPatient: PrescriptionDetailOutPatient[] = [];
  criIll: CriticalIllness[] = [];
  treatmentNoHPC: Treatment[] = [];
  cumulatives: Cumulative[] = [];
  registerPreventSubset: RegisterPreventSubset[] = [];
  registerPregnantSubset: RegisterPregnantSubset[] = [];
  inoculateData: InoculateData[] = [];
  loadItems = {
    criIll: false,
    treatment: false,
    rxHVE: false,
    rxMain: false,
    rxLong: false,
    allergy: false,

    criIllNoHpc: false,
    treatmentNoHpc: false,
    fee: false,
    prevent: false,
    pregnent: false,
    inoculate: false
  }
  pagedHVE = {
    data: [],
    total: 0
  };
  pagedOutPatient = {
    data: [],
    total: 0
  };
  pagedLongTerm = {
    data: [],
    total: 0
  };
  pagedRegisterPregnant = {
    data: [],
    total: 0
  };
  pagedInoculateData = {
    data: [],
    total: 0
  };

  allergicDrug1 = '';
  allergicDrug2 = '';
  allergicDrug3 = '';
  allergicDrug4 = '';
  allergicDrug5 = '';

  OrganDonate = '';
  prescriptionDetailLongTerm: PrescriptionDetailLongTerm[] = [];

  menuData = [
    { label: '重大傷病' },
    { label: '主次診斷' },
    { label: '重要醫令' },
    { label: '過敏藥品' },
    { label: '門診醫令' },
    { label: '長期醫令' },
    { label: '器官捐贈' },
    { label: '就醫資料' },
    { label: '就醫費用' },
    { label: '預防保健' },
    { label: '產前檢查' },
    { label: '預防接種' }
  ];

  slider_statesA: string[] = ['slide_in', 'slide_out', 'slide_out', 'slide_out', 'slide_out', 'slide_out', 'slide_out', 'slide_out', 'slide_out', 'slide_out', 'slide_out', 'slide_out']
  getRegMenu(index: number) {
    // 初始化為 'slide_out'
    this.showElement = false;
    this.slider_statesA = Array(12).fill('slide_out');
    this.slider_statesA[index] = 'slide_in';
    setTimeout(() => {
      this.showElement = true;
    }, 500);
  }
  /** 同療option */
  rehabilitationOpt = [
    { key: 'PT', value: 'PT' },
    { key: 'OT', value: 'OT' },
    { key: 'ST', value: 'ST' },
    { key: 'PRT', value: 'PRT' },
    { key: 'oTHER', value: '其他' }
  ];
  maxCCOpen: boolean = false;
  maxRmOpen: boolean = false;
  maxRegMenuOpen: boolean = false;
  getCCToggle() {
    this.maxCCOpen = !this.maxCCOpen;
  }
  getRmToggle() {
    this.maxRmOpen = !this.maxRmOpen;
  }
  getRegMenuToggle() {
    this.maxRegMenuOpen = !this.maxRegMenuOpen;
  }

  loadPastData() {
    // 重大傷病
    if (this.nhiRecord.MajorInj) {
      var majInj = JSON.parse(this.nhiRecord.MajorInj);
      this.criIll = majInj.filter(x => x && x.Code);
    }

    // 主次診斷
    if (this.nhiRecord.Diagno) {
      var treatmentDataHPC = JSON.parse(this.nhiRecord.Diagno);
      this.treatmentHPC = treatmentDataHPC.filter(x => x)
        .map(x => {
          return {
            Date: DateHelper.formatROCDate(x.ClinicDate, false, true),
            Dx1: x.MainDisease,
            Dx2: x.SecDisease1,
            Dx3: x.SecDisease2,
            Dx4: x.SecDisease3,
            Dx5: x.SecDisease4,
            Dx6: x.SecDisease5,
          }
        });
    }

    // 重要醫令
    if (this.nhiRecord.ImpOrder) {
      var impOrder = JSON.parse(this.nhiRecord.ImpOrder);
      this.prescriptionDetailHVE = impOrder.HVE;  //.filter(x=>x.ClinicCode);
      this.pagedHVE = {
        data: this.prescriptionDetailHVE.filter((x, i) => i < 5),
        total: this.prescriptionDetailHVE.length
      }
    }

    // 過敏藥物
    if (this.nhiRecord.AllergyMed) {
      var AllergyMed = JSON.parse(this.nhiRecord.AllergyMed);
      this.allergicDrug1 = AllergyMed.AllergicDrug[0];
      this.allergicDrug2 = AllergyMed.AllergicDrug[1];
      this.allergicDrug3 = AllergyMed.AllergicDrug[2];
      this.allergicDrug4 = AllergyMed.AllergicDrug[3];
      this.allergicDrug5 = AllergyMed.AllergicDrug[4];
    }

    // 門診醫令
    if (this.nhiRecord.OPTOrder) {
      var OPTOrder = JSON.parse(this.nhiRecord.OPTOrder);
      this.prescriptionDetailOutPatient = OPTOrder.OutPatient.filter(x => x.NhiDrugCode);
      this.pagedOutPatient = {
        data: this.prescriptionDetailOutPatient.filter((x, i) => i < 10),
        total: this.prescriptionDetailOutPatient.length
      }
    }

    // 長期醫令
    if (this.nhiRecord.LongOrder) {
      var LongOrder = JSON.parse(this.nhiRecord.LongOrder);
      this.prescriptionDetailLongTerm = LongOrder.LongTerm.filter(x => x.CreateDate);
      this.pagedLongTerm = {
        data: this.prescriptionDetailLongTerm.filter((x, i) => i < 5),
        total: this.prescriptionDetailLongTerm.length
      }
    }

    /** 無醫師卡就醫資料/費用 */
    if (this.nhiRecord.MedInfo) {
      var medInfo = JSON.parse(this.nhiRecord.MedInfo);
      this.criIllNoHPC = [medInfo.MajorInjury1, medInfo.MajorInjury2, medInfo.MajorInjury3,
      medInfo.MajorInjury4, medInfo.MajorInjury5, medInfo.MajorInjury6]
        .filter(x => x);
      this.treatmentNoHPC = [medInfo.TreatmentData1, medInfo.TreatmentData2, medInfo.TreatmentData3,
      medInfo.TreatmentData4, medInfo.TreatmentData5, medInfo.TreatmentData6]
        .filter(x => x)
        .map(x => {
          return {
            Date: DateHelper.formatROCDate(x.ClinicDate, false, true),
            Isqno: x.Isqno,
            Clinic: x.ClinicCode,
            Type: x.Itp,
            NewBorn: x.NewbornFlag,
            Recard: x.ReCardFlag,
            // 門診部分負擔
            Deductible: x.Deductible,
            // 門診醫療費用
            MedicalFees: x.MedicalFees,
            // 住院醫療費用
            HospitalFees: x.HospitalFees,
            // 住院部分負擔(短)
            HdeductibleA: x.HdeductibleA,
            // 住院部分負擔(長)
            HdeductibleB: x.HdeductibleB
          }
        });
    }

    // 就醫費用
    if (this.nhiRecord.MedFee) {
      var medFee = JSON.parse(this.nhiRecord.MedFee);
      this.cumulatives = medFee as Cumulative[];
    }

    // 預防保健資料
    if (this.nhiRecord.HealthCare) {
      var healthCare = JSON.parse(this.nhiRecord.HealthCare);
      this.registerPreventSubset = [healthCare.Set1, healthCare.Set2, healthCare.Set3, healthCare.Set4, healthCare.Set5, healthCare.Set6];
    }

    // 產檢資料
    if (this.nhiRecord.PrenatalCare) {
      var prenatalCare = JSON.parse(this.nhiRecord.PrenatalCare);
      this.registerPregnantSubset = prenatalCare.Data as RegisterPregnantSubset[];
      this.pagedRegisterPregnant = {
        data: this.registerPregnantSubset.filter((x, i) => i < 5),
        total: this.registerPregnantSubset.length
      }
    }

    // 疫苗接種資料
    if (this.nhiRecord.Vaccination) {
      var vaccination = JSON.parse(this.nhiRecord.Vaccination);
      this.inoculateData = vaccination.Data as InoculateData[];
      this.pagedInoculateData = {
        data: this.inoculateData.filter((x, i) => i < 5),
        total: this.inoculateData.length
      }
    }
  }

  async saveRecord() {
    if (this.isCard && !this.isDemoMode) {
      this.nhiRecord.PatientId = this.initReg.PatientId;
      this.nhiRecord.PatientNo = this.editFormGroup.controls.PatientNo.value;
      this.nhiRecord.ClinicId = UserCache.getLoginUser().Clinic.Id;
      this.nhiRecord.ClinicCode = UserCache.getLoginUser().Clinic.Code;
      this.nhiRecord.AgainDate = new Date();
      this.histApi.SaveNHIRecord(this.nhiRecord);
    }
  }

  async getPatient() {
    var ret = await this.icHelper.getAPI().GetBasicData();
    this.patient = ret.Data;
    var oRet = await this.icHelper.getAPI().GetOrganDonate();
    this.OrganDonate = oRet.Data == null ? '' : oRet.Data.OrganDonateNote;
    if (this.nhiRecord) {
      this.nhiRecord.OrganDon = oRet.Data == null ? '' : JSON.stringify(oRet.Data);
    }
    await this.saveRecord();
  }

  /** 重大分頁 */
  HVEPageChanged(page) {
    this.pagedHVE = {
      data: this.prescriptionDetailHVE.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.prescriptionDetailHVE.length
    }
  }
  /** 過敏藥物 */
  // async ReadPrescriptAllergic(fromBtn = true) {
  //   // if(! await this.checkHPC()){
  //   //   return;
  //   // }
  //   if (fromBtn) {
  //     this.showLoader();
  //   }
  //   var ret = await this.icHelper.getAPI().ReadPrescriptAllergic(1, 3, 3);
  //   if (ret.StatusCode == 0) {
  //     this.allergicDrug1 = ret.Data.AllergicDrug[0];
  //     this.allergicDrug2 = ret.Data.AllergicDrug[1];
  //     this.allergicDrug3 = ret.Data.AllergicDrug[2];
  //     this.allergicDrug4 = ret.Data.AllergicDrug[3];
  //     this.allergicDrug5 = ret.Data.AllergicDrug[4];
  //     this.nhiRecord.AllergyMed = JSON.stringify(ret.Data);
  //   } else {
  //     this.notify.showError('過敏藥物讀取失敗(' + ret.ErrorMsg + ')');
  //   }

  //   /** 無醫師卡就醫資料/費用 */
  //   if (this.nhiRecord.MedInfo) {
  //     var medInfo = JSON.parse(this.nhiRecord.MedInfo);
  //     this.criIllNoHPC = [medInfo.MajorInjury1, medInfo.MajorInjury2, medInfo.MajorInjury3,
  //     medInfo.MajorInjury4, medInfo.MajorInjury5, medInfo.MajorInjury6]
  //       .filter(x => x);
  //     this.treatmentNoHPC = [medInfo.TreatmentData1, medInfo.TreatmentData2, medInfo.TreatmentData3,
  //     medInfo.TreatmentData4, medInfo.TreatmentData5, medInfo.TreatmentData6]
  //       .filter(x => x)
  //       .map(x => {
  //         return {
  //           Date: DateHelper.formatROCDate(x.ClinicDate, false, true),
  //           Isqno: x.Isqno,
  //           Clinic: x.ClinicCode,
  //           Type: x.Itp,
  //           NewBorn: x.NewbornFlag,
  //           Recard: x.ReCardFlag,
  //           // 門診部分負擔
  //           Deductible: x.Deductible,
  //           // 門診醫療費用
  //           MedicalFees: x.MedicalFees,
  //           // 住院醫療費用
  //           HospitalFees: x.HospitalFees,
  //           // 住院部分負擔(短)
  //           HdeductibleA: x.HdeductibleA,
  //           // 住院部分負擔(長)
  //           HdeductibleB: x.HdeductibleB
  //         }
  //       });
  //   }

  //   // 就醫費用
  //   if (this.nhiRecord.MedFee) {
  //     var medFee = JSON.parse(this.nhiRecord.MedFee);
  //     this.cumulatives = medFee as Cumulative[];
  //   }

  //   // 預防保健資料
  //   if (this.nhiRecord.HealthCare) {
  //     var healthCare = JSON.parse(this.nhiRecord.HealthCare);
  //     this.registerPreventSubset = [healthCare.Set1, healthCare.Set2, healthCare.Set3, healthCare.Set4, healthCare.Set5, healthCare.Set6];
  //   }

  //   // 產檢資料
  //   if (this.nhiRecord.PrenatalCare) {
  //     var prenatalCare = JSON.parse(this.nhiRecord.PrenatalCare);
  //     this.registerPregnantSubset = prenatalCare.Data as RegisterPregnantSubset[];
  //     this.pagedRegisterPregnant = {
  //       data: this.registerPregnantSubset.filter((x, i) => i < 5),
  //       total: this.registerPregnantSubset.length
  //     }
  //   }

  //   // 疫苗接種資料
  //   if (this.nhiRecord.Vaccination) {
  //     var vaccination = JSON.parse(this.nhiRecord.Vaccination);
  //     this.inoculateData = vaccination.Data as InoculateData[];
  //     this.pagedInoculateData = {
  //       data: this.inoculateData.filter((x, i) => i < 5),
  //       total: this.inoculateData.length
  //     }
  //   }
  // }

  async PrintArrearReceipt() {
    const printRet = await this.arrearReceiptComponent.onPrint();

    if (printRet) {
      this.isShowPrintArrearReceiptDialog = false;
    }
  }

  /** 切換看診日Blur事件 */
  async onBlurDate(regDate) {
    this.regDateTime = new Date(regDate);
    if (this.isAdd || (!this.isAdd && this.canEdit)) {
      this.mainLayoutService.showLoader();

      // 更新診間醫師清單
      await this.refreshDoctorRoom(this.editFormValue.DoctorId);
      if (this.editFormValue.RoomCode && this.editFormValue.DoctorId) {
        await this.getRegisterNo();
      }

      let sameTreat = this.editFormValue.SameTreat;
      if (sameTreat == RegisterConst.SameTreat_0_洗腎 || sameTreat == RegisterConst.SameTreat_1_復健
        || sameTreat == RegisterConst.SameTreat_4_月療
        || sameTreat == RegisterConst.SameTreat_8_慢箋領藥) {

        this.notification.showSuccess('檢查卡序是否接續', true);
        this.regDateTimeStr = DateHelper.formatDateTime(this.regDateTime);

        this.multiTreatList = await this.regApi.GetEveryTreatmentList(this.patientId, this.regDateTimeStr, sameTreat);
        let mylist = sameTreat == RegisterConst.SameTreat_4_月療 ? this.multiTreatList.filter(x => x.CusTP == sameTreat && new Date(this.regDateTimeStr) > new Date(x.StartDate))
          : this.multiTreatList.filter(x => x.CusTP == sameTreat);
        if (mylist.length >= 1) {
          this.onlyonelist = mylist[0];
          this.onSameTreatChange({ value: sameTreat, prev: '' });
        } else {
          this.onlyonelist = new MutiTreatmentListDto();
          //無須接續，則恢復卡序、就醫類別
          // 恢復卡序
          this.revertIcno()
          // 恢復就醫類別
          this.onSameTreatChange({ value: sameTreat, prev: '' }); //重新設定就醫類別，洗腎的就醫類別=09，其他=01
        }

      }
      this.mainLayoutService.hideLoader();

    }
  }
  async onPateSpecialDialogOpen(){
    this.isOpenPatSpecial = true;
    this.isEditSPC = false; // 回到預設

  }
  async onPatSpecialSave(){
    await this.getICNoOptions();
    await this.setIcnoAfterICodeOrSameTreatChange();
    this.isOpenPatSpecial = false;
  }
  showLoader() {
    this.mainLayoutService.showLoader();
  }
  closeLoader() {
    this.mainLayoutService.hideLoader();
  }
  /** 門診醫令分頁 */
  outpatientPageChanged(page) {
    this.pagedOutPatient = {
      data: this.prescriptionDetailOutPatient.filter((x, i) => i >= (page - 1) * 10 && i < (page) * 10),
      total: this.prescriptionDetailOutPatient.length
    }
  }
  /** 長期醫令 */
  // async ReadPrescriptLongTerm(fromBtn = true) {
  //   // if(! await this.checkHPC()){
  //   //   return;
  //   // }
  //   if (fromBtn) {
  //     this.showLoader();
  //   }

  //   this.notify.showInfo('長期醫令讀取中');
  //   var ret = await this.icHelper.getAPI().ReadPrescriptLongTerm(1, 30, 30);
  //   if (ret.StatusCode == 0) {
  //     this.prescriptionDetailLongTerm = ret.Data.LongTerm.filter(x => x.CreateDate);
  //     this.pagedLongTerm = {
  //       data: this.prescriptionDetailLongTerm.filter((x, i) => i < 5),
  //       total: this.prescriptionDetailLongTerm.length
  //     }
  //     this.nhiRecord.LongOrder = JSON.stringify(ret.Data);
  //   } else {
  //     this.notify.showError('長期醫令讀取失敗(' + ret.ErrorMsg + ')');
  //   }
  //   if (fromBtn) {
  //     await this.saveRecord();
  //     this.closeLoader();
  //   }
  // }
  /** 長期分頁 */
  longTermPageChanged(page) {
    this.pagedLongTerm = {
      data: this.prescriptionDetailLongTerm.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.prescriptionDetailLongTerm.length
    }
  }
  /** 產檢分頁 */
  registerPregnantPageChanged(page) {
    this.pagedRegisterPregnant = {
      data: this.registerPregnantSubset.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.registerPregnantSubset.length
    }
  }
  /** 疫苗分頁 */
  inoculateDataPageChanged(page) {
    this.pagedInoculateData = {
      data: this.inoculateData.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.inoculateData.length
    }
  }

  public gridCellClickHandler({ isEdited, dataItem, rowIndex }): void {
    this.histViewComp.selectHist(dataItem.RegId);
    setTimeout(() => {
      this.selectedItems[0] = dataItem.RegId;
    }, 1000);
  }
  // #region hist-list --------------------
  onHistListEmitSelect(histId: number) {
    // 通知歷史資料檢視元件
    this.histViewComp.selectHist(histId);
  }
  patientRemarkSelectedText: string = '';
  // remark-dialog 視窗開關控制
  isRemarkWindowOpened = false;
  isPatientRemarkDialogOpened: boolean = false;
  switchPatientRemarkDialog: boolean = false;
  onBtnRemarkClick() {
    if (!this.editRemarkFormGroup.enabled) {
      return;
    }
    this.patientRemarkSelectedText = this.editRemarkFormGroup.controls.PatientRemark.value;
    this.patientRemarkDialogComp.reloadData(this.patientRemarkSelectedText);
    this.patientRemarkDialogComp.cleanData();
    this.switchPatientRemarkDialog = true;
  }

  openRemarkWindow() {
    this.isRemarkWindowOpened = true;
  }
  onBtnSaveClickPatientRemark(status: string) {
    // this.emitResult.emit(this.selectedItems);
    // // 清除選取
    // this.selectedItems = [];

    this.switchPatientRemarkDialog = false;
  }
  onBtnCancelClickPatientRemark(evt: Event) {
    // 這次沒選
    //this.emitResult.emit([]);
    // 清除選取
    //this.selectedItems = [];

    this.switchPatientRemarkDialog = false;
  }
  getRemarkWindowResult(data: any) {
    this.switchPatientRemarkDialog = false;
    if (data) {
      this.editRemarkFormGroup.controls.PatientRemark.setValue(data.text);
    }
  }
  // LMPDate: new Date(),
  // DueDate: new Date()
  // getVitalSign() {
  //   if (this.initReg.VitalSign) {
  //     var vt = Object.assign({},this.initReg.VitalSign);
  //     vt.isFemale = this.initReg.PatientSexCode == SexEnum.SexWoman;
  //     return vt;
  //   }
  // }



  getDisabled(): string {
    if ((!this.isAdd && this.slider_stateB === 'slide_out') || !this.canEdit) {
      return 'full-disabled';
    } else if (!this.isAdd && this.slider_stateB === 'slide_in') {
      return '';
    } else if (this.isAdd && this.slider_stateB === 'slide_out') {
      return '';
    } else if (!this.isAdd && this.slider_stateB === 'slide_in') {
      return '';
    } else return '';

  }
  async createZipHelper() {
    if ((!this.zipCodeEl)) { return; }
    this.adrHelper = new ZipCodeHelper(this.postAreaAPI, this.userConfirm, this.zipCodeEl.nativeElement,
      this.editFormGroup.controls.PatientZipCode, this.editFormGroup.controls.PatientCity,
      this.editFormGroup.controls.PatientArea, this.editFormGroup.controls.Street)

    this.adrHelper.onAreaChange.subscribe(v => {
      this.city = v.City;
      this.areaFilterd = v.Area;
    });
    await this.adrHelper.init();
  }

  getVisitCode(): string {
    if (this.editFormValue.VisitSourceCode && this.editFormValue.VisitSourceCode === 'F') {
      return '初診';
    } else if (this.editFormValue.VisitSourceCode && this.editFormValue.VisitSourceCode === 'R') {
      return '複診';
    }
  }

  getBasicCanEdit(): boolean {
    if (this.isAdd || this.canEdit) {
      this.isBasicCanEdit = !this.isBasicCanEdit;
      return this.isBasicCanEdit;
    }
  }

  icdtTODate(src: string) {
    var y = Number(src.toString().substring(0, 3)) + 1911;
    var m = Number(src.toString().substring(3, 2));
    var d = Number(src.toString().substring(5, 2));
    var h = Number(src.toString().substring(7, 2));
    var n = Number(src.toString().substring(9, 2));
    var s = Number(src.toString().substring(11, 2));
    var icdt = new Date(y, m, d, h, n, s);
    return icdt;
  }

  // #endregion hist-list --------------------

  openAlertCanNotRevertAgainNotify() {
    this.confirmService.showConfirm({
      'msg': '已完成退掛，不能再退掛',
      'title': '訊息',
      'hideNo': true
    });
  }

  openAlertCanNotDeleteNotify() {
    this.confirmService.showConfirm({
      'msg': '已超過掛號日期時間，不可刪除',
      'title': '訊息',
      'hideNo': true
    });
  }

  // 開啟 取消掛號確認視窗
  @ViewChild('cancelRegTmp')
  cancelRegTmp: TemplateRef<any>
  async openConnCancelDialog(reg: RegisterEditDto) {
    var icHelper = this.getICHelper()
    var ret = await this.confirmService.showConfirm({
      'msg': '你確定要取消這筆掛號嗎',
      'title': '訊息',
      'width': 800,
      'template': this.cancelRegTmp,
      'templateData': { data: [reg], total: 1 }
    });

    if (ret) {
      var selected = reg;
      this.mainLayoutService.showLoader();
      try {
        // 退卡序
        var hasSeqNumber = (selected.IType == '1' || (selected.IType == '3' && selected.ReCardDate))   //IC掛號 & 欠卡以還卡 (非自費/異掛/欠卡未還卡)
          && !NullOrEmpty(selected.Icno) // 有卡序
          && selected.Icno.indexOf("IC") < 0; // 非預保
        if (hasSeqNumber) {
          var icdt = selected.Icdt;
          if (this.isDemoMode) {
            // 補3i -> Nhictp='ZA', Icdt=icdt => 改至 CancelRegister 內一起做掉
            await this.registerApi.CancelRegister(selected.Id, this.hasMultipltRegister);
          } else {
            // IC卡退卡序流程:
            // Step 1. 退卡序 - 判斷 Nhictp 是否為數值類型? 是->需退卡序，否->不需退卡序(因為本來就不需累計就醫序號，取號也取不到)
            var revertIcdt: any;
            var revertSecuritySeal: any;
            if (!isNaN(Number(selected.Nhictp))) {
              var res = await icHelper.unGetSeqNumber(icdt.toString());
              if (res != 0 && res != 5150) return;
            }

            // this.mainLayoutService.showLoader();

            // Step 2. 註記一筆取消掛號的紀錄到卡片(用 Nhictp='ZA' 或 Nhictp='ZB'取號就會產生)
            var revertNhictp = this.hasMultipltRegister ? "ZB" : "ZA";
            var icData = await icHelper.getSeqNumber(revertNhictp, " ");
            if (icData == null) return;
            revertIcdt = icData.ClinicDateTime;
            revertSecuritySeal = icData.SecuritySeal;
            // 補3i -> Nhictp='ZA' 或 Nhictp='ZB', Icdt=icdt
            await this.registerApi.CancelRegister(selected.Id, this.hasMultipltRegister, revertIcdt, revertSecuritySeal);
          }
        } else {
          // 取消掛號
          await this.registerApi.CancelRegister(selected.Id, this.hasMultipltRegister);
        }
        // 狀態為[全部]會顯示退掛記錄在候診清單，所以要紀錄當前選取的資料，讓清單可以focus在那一筆
        // if (this.queryObj.statusCode === RegisterConst.StatusCodeList_All) {
        //   this.clearKeepSelectedItem();
        // }
        this.notification.showOk();
        this.cancel();
      } catch (ex) {
        this.notification.showError(ex);
      }
      this.mainLayoutService.hideLoader();
    }
  }

  // 未插卡退掛: 只有本來是異掛的才能以未插卡退掛(IType = '1')
  async openCancelDialog(reg: RegisterEditDto) {
    var msg = "未置入IC卡，是否取消這筆掛號？";
    if (reg.IType != "1" && !(reg.IType == "3" && reg.ReCardDate != null)) {
      msg = "";
    }
    if (reg.StatusCode == RegisterConst.Code_Status_Cancel
      || reg.StatusCode == RegisterConst.Code_Status_Emergency
      || reg.StatusCode == RegisterConst.Code_Status_Waiting
      || reg.StatusCode == RegisterConst.Code_Status_Appointment) {
      var ret = await this.confirmService.showConfirm({
        'msg': msg,
        'title': '訊息',
        'width': 800,
        'template': this.cancelRegTmp,
        'templateData': { data: [reg], total: 1 }
      });
      if (ret) {
        this.mainLayoutService.showLoader();

        try {
          await this.registerApi.CancelRegister(reg.Id, this.hasMultipltRegister);
          this.notification.showOk();
          this.cancel();
        } catch (ex) {
          this.notification.showError(ex);
        }

        this.mainLayoutService.hideLoader();
      }
    } else {
      this.notification.showInfo('未置入IC卡，無法執行退掛作業！');
    }
  }

  // 未取卡序，只做取消掛號 (欠卡)
  async openOnlyCancelDialog(reg: RegisterEditDto) {
    if (reg.StatusCode == RegisterConst.Code_Status_Cancel
      || reg.StatusCode == RegisterConst.Code_Status_Emergency
      || reg.StatusCode == RegisterConst.Code_Status_Waiting
      || reg.StatusCode == RegisterConst.Code_Status_Appointment) {
      var ret = await this.confirmService.showConfirm({
        'msg': '是否取消這筆掛號？',
        'title': '訊息',
        'width': 800,
        'template': this.cancelRegTmp,
        'templateData': { data: [reg], total: 1 }
      });
      if (ret) {
        this.mainLayoutService.showLoader();

        try {
          await this.registerApi.CancelRegister(reg.Id, this.hasMultipltRegister);
          this.notification.showOk();
          this.cancel();
        } catch (ex) {
          this.notification.showError(ex);
        }

        this.mainLayoutService.hideLoader();

      }
    } else {
      this.notification.showInfo('無法執行退掛作業！');
    }
  }

  private getToday(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }
  checkRepay() {
    this.apiRePay.get('GetPersonalRepayTotal/' + this.patientId).subscribe(
      (data: RepaymentPesonalInfo) => {
        if (data?.TotalDebt > 0) {
          var check = this.userConfirmService.showConfirm({
            title: '請確認以下欠款訊息',
            msg: '病患' + this.initReg.PatientFirstName + '\n有欠款金額：' + data.TotalDebt + '元' + '\n\n' + (data.Remarks.join('\n') || ''),
            textYes: '確定',
            textNo: '取消',
            hideNo: true,
            width: 450
          });
        }
      },
      error => {
        this.notification.showGetDataFailed('payments-edit'); // 顯示查詢失敗訊息
      }
    );
  }

  // 过滤 MutiTreatmentListDto 数组
  filterByToday(mutiTreatmentList: MutiTreatmentListDto[]): MutiTreatmentListDto[] {
    const today = this.getToday();

    return mutiTreatmentList.filter(mutiTreatment =>
      mutiTreatment.HistList.some(hist => {
        const histDate = new Date(hist.Register?.RegDate);
        histDate.setHours(0, 0, 0, 0); // 确保比较时忽略时间部分
        return histDate.getTime() === today.getTime() && (hist.Register?.Nhictp == '09' || hist.Register?.Nhictp == 'AJ' || hist.Register?.Nhictp == '01');
      })
    );
  }

  positionStyle(pos: string) {
    var positions = pos.split(',');
    if (this.fReg.Value.ICode == RegisterConst.ICode_009_Other &&
      this.fReg.Value.INote == RegisterConst.INote_代檢 &&
      positions.some(x => x == UserConst.Position_檢驗師)) {
      return { fontWeight: 'bold' };
    } else {
      return {}
    }
  }
  spcChange(idx: number, evt: MatCheckboxChange) {
    var spc: string = this.editFormValue.SpcIdentity;
    // 補滿(新增的時候)
    if (spc.length <= 50) {
      // console.log('<50');
      this.editFormValue.SpcIdentity.padEnd(50, '0');
    }
    // 取代其中一個字元
    spc = spc.substring(0, idx) + (evt.checked ? '1' : '0') + spc.substring(idx + 1);
    this.editFormGroup.patchValue({
      SpcIdentity: spc
    });
    this.isEditSPC = true;
  }
  spcChecked(idx: number) {
    var spc: string = this.editFormValue.SpcIdentity;
    if (!spc || spc.length < idx) {
      return false;
    }
    return spc.charAt(idx) == '1';
  }
  private dialogClosed = new Subject<void>();

  waitForDialogClose(): Promise<void> {
    return new Promise<void>((resolve) => {
      const subscription = this.dialogClosed.subscribe(() => {
        subscription.unsubscribe();
        resolve();
      });
    });
  }

  onDialogClose() {
    this.isShowPrintArrearReceiptDialog = false;
    this.cd.detectChanges();
    this.dialogClosed.next();
  }

}

interface NewBornData {
  Name: string;
  Sex: number;
  Birth: Date;
  CId: string;
  Tel: string;
  Address: string;
  MonParentsId: number;
  BirthsNum: number;
}

declare type CriIll = {
  Code: string,
  Name: string,
  StartDate: string,
  EndDate: string
}

declare type Treatment = {
  Date: string,
  Isqno: string,
  Clinic: string,
  Type: string,
  NewBorn: string,
  Recard: string,
  // 門診部分負擔
  Deductible: string,
  // 門診醫療費用
  MedicalFees: string,
  // 住院醫療費用
  HospitalFees: string,
  // 住院部分負擔(短)
  HdeductibleA: string,
  // 住院部分負擔(長)
  HdeductibleB: string
}

declare type TreatmentHPC = {
  Date: string,
  Dx1: string,
  Dx2: string,
  Dx3: string,
  Dx4: string,
  Dx5: string,
  Dx6: string
}

export interface RegisterEditFormModel {
  Id: number;
  PatientId: number;
  RegDate: Date;
  WaitNo: string;
  DiscountCode: string;
  /** 保險身分 */
  ICode: string;
  DoctorId: number;

  RoomCode: string;
  MedDeptCode: string;
  TimeSec: number;
  VisitSourceCode: string;
  /** 狀態  0:就診中 1:排1 A:候診 C:掛號未付款 D:保留牌後 E:預約 F:完診 G:拿藥 H:取消 *:急診 */
  StatusCode: string;
  RegSourceCode: string;
  PaymentStatusCode: string;
  StatusRemark: string;
  Remark: string;
  INote: string;
  IsEditPatient: boolean
  PatientNo: string;
  PatientFirstName: string;
  PatientBirth: Date;
  PatientCId: string;
  PatientSexCode: number;
  PatientSexName: string;
  PatientPhoneHomeArea: string;
  PatientPhoneHome: string;
  PatientMobile: string;
  PatientStreet: string;
  PatientCity: string;
  PatientArea: string;
  PatientZipCode: string;
  Nhictp: string;
  /** 健保 就醫序號 */
  Isqno: string;

  Deposit: number;
  OwnFee: number;
  RegisterFee: number;
  SelfPay: number;
  /** 0.自費 1.IC卡 2.異常掛號3.欠卡 */
  IType: string;
  /** 掛號類別/療程 */
  SameTreat: string;
  /** 卡序 */
  Icno: string;
  RemarkIC: string;
  AvailableCount: string;
  nhiConsultConvert: number;
  MonCId: string;
  MonBirthdate: Date;
  NewbornNote: NewbornNote;
  IsMiddleLowIncome: boolean;
  SpcIdentity:string;
}

