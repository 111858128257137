<div class="w-100 m-auto text-lg" *ngIf="(RPgridData.length > 0  || ROgridData.length > 0 || RSgridData.length > 0 || PRTgridData.length > 0)">
  <table class="grid-container rehabilitation">
    <tr class="grid-row flex-grow-one">
      <td class="w-22 text-lg border-0"></td>
    </tr>
    <tr *ngFor="let row of rows; let i = index" class="grid-row flex-grow-one">
      <th  class="grid-cell-header-primary w-20 text-lg">
        <ng-container >第 {{(i+1)}}次</ng-container>
     </th>
    </tr>
  </table>  
    <table class="grid-container rehabilitation" *ngIf="RPgridData.length > 0">
        <tr class="grid-row flex-grow-one">
          <td class="w-100 text-lg border-0 tr-height-0 "></td>
          <td class="w-22 text-lg border-0">PT</td>
          <td class="w-22 text-lg border-0">物理治療</td>
          <td class="w-22 text-lg border-0">治療師</td>
        </tr>
        <tr *ngFor="let row of RPgridData; let i = index" class="grid-row flex-grow-one">
          <td class="tr-height-0  w-20"></td>
          <td *ngFor="let cell of row; let j = index" class="w-20 text-lg text-sm ellipsis-content font-weight-normal text-center" [ngClass]="cell?'':'d-none'">
            {{ cell }}
          </td>
        </tr>
      </table>
      <table class="grid-container rehabilitation" *ngIf="ROgridData.length > 0">
        <tr class="grid-row flex-grow-one">
          <td class="w-100 text-lg border-0 tr-height-0 "></td>
          <td class="w-22 text-lg border-0">OT</td>
          <td class="w-22 text-lg border-0">職能治療</td>
          <td class="w-22 text-lg border-0">治療師</td>
        </tr>
        <tr *ngFor="let row of ROgridData; let i = index" class="grid-row flex-grow-one">
          <td class="tr-height-0  w-20"></td>
          <td *ngFor="let cell of row; let j = index" class="w-20 text-lg text-sm ellipsis-content font-weight-normal text-center" [ngClass]="cell?'':'d-none'">
            {{ cell }}
          </td>
        </tr>
      </table>
      <table class="grid-container rehabilitation" *ngIf="RSgridData.length > 0">
        <tr class="grid-row flex-grow-one">
          <td class="w-100 text-lg border-0 tr-height-0 "></td>
          <td class="w-22 text-lg border-0">ST</td>
          <td class="w-22 text-lg border-0">語言治療</td>
          <td class="w-22 text-lg border-0">治療師</td>
        </tr>
        <tr   *ngFor="let row of RSgridData; let i = index" class="grid-row flex-grow-one">
          <td class="tr-height-0  w-20"></td>
          <td *ngFor="let cell of row; let j = index" class="w-20 text-lg text-sm ellipsis-content font-weight-normal text-center" [ngClass]="cell?'':'d-none'">
            {{ cell }}
          </td>
        </tr>
      </table>
      <table class="grid-container rehabilitation" *ngIf="PRTgridData.length > 0">
        <tr class="grid-row flex-grow-one">
          <td class="w-100 text-lg border-0 tr-height-0 "></td>
          <td class="w-22 text-lg border-0">PRT</td>
          <td class="w-22 text-lg border-0">心理治療</td>
          <td class="w-22 text-lg border-0">治療師</td>
        </tr>
        <tr *ngFor="let row of PRTgridData; let i = index" class="grid-row flex-grow-one">
          <td class="tr-height-0  w-20"></td>
          <td *ngFor="let cell of row; let j = index" class="w-20 text-lg text-sm ellipsis-content font-weight-normal text-center" [ngClass]="cell?'':'d-none'">
            {{ cell }}
          </td>
        </tr>
      </table>
</div>
