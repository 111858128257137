
<div [formGroup]="referralFormGroup" class="c-form-inline card" style="margin-left: 0rem;">
    <div class="char-header">
        <mat-grid-list [rowHeight]="'40'" cols="12" [gutterSize]="'5px'">
            <mat-grid-tile colspan="5" class="patient-label" style="margin-left: 25px;">
                <button
                type="button"
                (click)="btnEditClick()"
                i18n="@@EditMode"
                class="my-1 text-xs vis-btn-third-primary px-1 w-12" style="height: 1.5rem;">
                    <mat-icon class="vis-combine-icon d-inline-flex mr-1 text-base w-auto" title="編輯">edit</mat-icon>
                    <span class="text-xs-sm float-right position-relative" style="right: 3px;">編</span>
                </button>
                <label class="vis-form-field mb-0">
                    <span class="required extend-label" i18n="@@CCHist" style="max-width: initial;">&nbsp;病情摘要(主訴或簡短病史) </span><!--formControlName="btnEdit"-->
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="c-form-field" style="margin-right: 0px;">
                    <!-- <input class="vis-input" style="width: 100%; border: none;" formControlName="LetterCounter"/> -->
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="6" class="patient-label" style="margin-left: 25px;">
                <label class="vis-form-field mb-0">
                    <span i18n="@@Diagnosis" class="required extend-label">診斷</span>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="6" rowspan="3" class="patient-detail" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <textarea class="required" rows="5" maxlength="1000" style="height: 105px; width: 98%; resize: none;" formControlName="CC">
                    </textarea>
                </label>
            </mat-grid-tile>

            <mat-grid-tile colspan="5" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <!-- <input class="vis-input" style="width: 98%;" formControlName="Dx" class="required" (change)="dxChanged($event, 'dx')" /> -->
                    <app-vis-combobox class="vis-grid-input" [ignoreCase]="false" formControlName="Dx" name="Dx" [isDxFiled]="true"
                    (valueChange)="dxChanged($event, 'dx')" [data]="dxOpt" #dx>
                    </app-vis-combobox>
                    <app-validation-tip controlName="Dx">
                    </app-validation-tip>
                </label>
            </mat-grid-tile>
            <mat-grid-tile style="margin-right: 25px;">
                <label class="vis-form-field" style="margin-left: 0px; margin-right: 25px;">
                    <!-- <input class="vis-input" style="width: 98%;" formControlName="DxName" class="required" /> -->
                    <input type="hidden" class="required" readonly class="vis-input" style="width: 1px;" formControlName="DxName" />
                </label>
            </mat-grid-tile>

            <mat-grid-tile colspan="5" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <!-- <input class="vis-input" style="width: 98%;" formControlName="Dx1" (change)="dxChanged($event, 'dx1')" /> -->
                    <app-vis-combobox class="vis-grid-input" [ignoreCase]="false" formControlName="Dx1" name="Dx1" [isDxFiled]="true"
                    (valueChange)="dxChanged($event, 'dx1')" [data]="dx1Opt" #dx1>
                    </app-vis-combobox>
                    <app-validation-tip controlName="Dx1">
                    </app-validation-tip>
                </label>
            </mat-grid-tile>
            <mat-grid-tile style="margin-right: 25px;">
                <label class="vis-form-field" style="margin-left: 0px; margin-right: 25px;">
                    <!-- <input class="vis-input" style="width: 98%;" formControlName="DxName1" /> -->
                    <input type="hidden" readonly class="vis-input" style="width: 1px;" formControlName="DxName1" />
                </label>
            </mat-grid-tile>

            <mat-grid-tile colspan="5" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <!-- <input class="vis-input" style="width: 98%;" formControlName="Dx2" (change)="dxChanged($event, 'dx2')"/> -->
                    <app-vis-combobox class="vis-grid-input" [ignoreCase]="false" formControlName="Dx2" name="Dx2" [isDxFiled]="true"
                    (valueChange)="dxChanged($event, 'dx2')" [data]="dx2Opt" #dx2>
                    </app-vis-combobox>
                    <app-validation-tip controlName="Dx2">
                    </app-validation-tip>
                </label>
            </mat-grid-tile>
            <mat-grid-tile style="margin-right: 25px;">
                <label class="vis-form-field" style="margin-left: 0px; margin-right: 25px;">
                    <!-- <input class="vis-input" style="width: 98%;" formControlName="DxName2" /> -->
                    <input type="hidden" readonly class="vis-input" style="width: 1px;" formControlName="DxName2" />
                </label>
            </mat-grid-tile>

            <mat-grid-tile colspan='12'>
                <mat-divider style="width: 97%; border: 1px dashed lightgray;"></mat-divider>
            </mat-grid-tile>
            <mat-grid-tile colspan="6" style="margin-left: 25px;">
                <label class="vis-form-field ml-0">
                    <div i18n="@@RecentMedicineDate" class="float-left extend-label" style="line-height: 1.5rem;">最近一次用藥日期　</div>
                    <app-vis-datepicker formControlName="RecOrderDate"></app-vis-datepicker>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="6" style="margin-left: 25px;">
                <label class="vis-form-field ml-0">
                    <div i18n="@@RecentCheckDate" class="float-left extend-label" style="line-height: 1.5rem;">最近一次檢查日期　</div>
                    <app-vis-datepicker formControlName="RecLISDate"></app-vis-datepicker>
                </label>
            </mat-grid-tile>
            <!-- <mat-grid-tile colspan="2">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <app-vis-datepicker formControlName="RecLISDate"></app-vis-datepicker>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <label class="vis-form-field" style="margin-left: 0px;"> -->
                    <!--<button type="button" i18n="@@SpecialRemark" style="width: 100%;">特定註記:無</button>--><!--formControlName="SpeNote"-->
                <!-- </label>
            </mat-grid-tile> -->
            <mat-grid-tile colspan="6" rowspan="3" class="fix-wording" style="margin-left: 25px; vertical-align: top;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <div style="width: calc(100% - 2rem)">
                        <span i18n="@@OperationName" class="float-left extend-label">用藥或手術名稱</span>
                        <span i18n="@@AlertMessageForOperation" class="float-right extend-label">　(超過將自動刪除)</span>
                    </div>
                    <textarea class="vist-extarea mt-2" rows="4" maxlength="1000" style="height: 100%; width: 98%; resize: none;"
                    formControlName="HRx"
                    [defaultValue]="'藥材/治療'">
                    </textarea>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="6" rowspan="3" class="fix-wording" style="margin-left: 25px; margin-right: 25px; vertical-align: top;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <div style="width: calc(100% - 2rem)">
                      <span i18n="@@AlertMessageForOperation" class="extend-label">檢查結果(最大長度1000字)</span>
                    </div>
                    <textarea class="vist-extarea mt-2" rows="4" maxlength="1000" style="height: 100%; width: 90%; resize: none;"
                    formControlName="RecLISResult">
                    </textarea>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan='12' style="height: 5px;">
                <span> </span>
            </mat-grid-tile>
            <mat-grid-tile colspan="12">
                <mat-divider style="width: 97%; border: 1px dashed lightgray;"></mat-divider>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="3" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <span class="required extend-label" i18n="@@Objects">轉診目的</span>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="10" rowspan="3">
                <label class="vis-form-field radio-align" style="margin-left: 0px;">
                    <mat-radio-group formControlName="Objects" [name]="'Objects'" [(ngModel)]="systemCode250" class="p-0 m-0 extend-label">
                        <div style="line-height: 1.25rem;">
                            <mat-radio-button *ngFor="let Objects of systemCode0250"
                            class="k-radio col-sm-4 pr-0 mr-0 mb-0"
                            [id]="'systemCode250' + Objects.Code"
                            [value]="Objects.Code + ' ' + Objects.Name"
                            i18n="@@Yes"
                            (change)="radio250Change($event)">
                            <div class="text-wrap">
                                {{ Objects.Code + ' ' + Objects.Name }}
                            </div>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="2" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <span [ngClass]="{ 'required': object6_selected }" class="extend-label" style="max-width: initial !important;" i18n="@@ObjectsNote">轉診目的(其他)<br>(最大長度60字)</span>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="10" rowspan="2" style="margin-right: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <textarea class="vist-extarea" rows="2" maxlength="60" style="height: 100%; width: 95%; resize: none;"
                    formControlName="ObjectsNote">
                    </textarea>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="3" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <span i18n="@@ReferralPur" id="ReferralPurTiltle" [ngClass]="{ 'required': objectA_selected }" class="extend-label">轉診目的<br>(轉介其他試辦計畫或方案)</span>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="10" rowspan="3">
                <label class="vis-form-field radio-align" style="margin-left: 0px;">
                    <mat-radio-group formControlName="ReferralPur" [name]="'ReferralPur'" [(ngModel)]="systemCode254" class="p-0 m-0 extend-label">
                        <div style="line-height: 1.25rem;">
                            <mat-radio-button *ngFor="let ReferralPur of systemCode0254"
                            class="k-radio col-sm-4 pr-0 mr-0 mb-0"
                            [id]="'systemCode254' + ReferralPur.Code"
                            [value]="ReferralPur.Code + ' ' + ReferralPur.Name"
                            [disabled]="!ReferralPur.IsEnabled"
                            i18n="@@Yes"
                            (change)="radio254Change($event)">
                            <div class="text-wrap">
                                {{ ReferralPur.Code + ' ' + ReferralPur.Name }}
                            </div>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="12">
                <mat-divider style="width: 97%; border: 1px dashed lightgray;"></mat-divider>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="3" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <span i18n="@@SpeNote" [ngClass]="{ 'required': objectA_selected }" class="extend-label">特定註記</span>
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="10" rowspan="3">
                <label class="vis-form-field radio-align" style="margin-left: 0px;">
                    <mat-radio-group formControlName="SpeNote" [name]="'SpeNote'" [(ngModel)]="systemCode251" class="p-0 m-0 extend-label">
                        <div style="line-height: 1.25rem;">
                            <mat-radio-button *ngFor="let specNote of systemCode0251"
                            class="k-radio col-sm-4 pr-0 mr-0 mb-0"
                            [id]="'systemCode251' + specNote.Code"
                            [value]="specNote.Code + ' ' + specNote.Name"
                            [disabled]="!specNote.IsEnabled"
                            i18n="@@Yes"
                            (change)="radio251Change($event)">
                            <div class="text-wrap">
                                {{ specNote.Code + ' ' + specNote.Name }}
                            </div>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </label>
            </mat-grid-tile>

            <ng-container *ngIf="a_selected">
              <mat-grid-tile colspan="12">
                  <mat-divider style="width: 97%; border: 1px dashed lightgray;"></mat-divider>
              </mat-grid-tile>

              <mat-grid-tile colspan="2" style="margin-left: 25px;">
                  <label class="vis-form-field" style="margin-left: 0px;">
                      <span i18n="@@UrgPatNote" id="UrgPatNoteTiltle" [ngClass]="{ 'required': a_selected }" class="extend-label">緊急傷病轉出註記</span>
                  </label>
              </mat-grid-tile>

              <mat-grid-tile colspan="10">
                  <label class="vis-form-field radio-align" style="margin-left: 0px;">
                      <mat-radio-group formControlName="UrgPatNote" [name]="'UrgPatNote'" [(ngModel)]="systemCode253" class="p-0 m-0 extend-label">
                          <div style="line-height: 1.25rem;">
                              <mat-radio-button *ngFor="let UrgPatNote of systemCode0253"
                              class="k-radio col-sm-4 pr-0 mr-0 mb-0"
                              [id]="'systemCode253' + UrgPatNote.Code"
                              [value]="UrgPatNote.Code + ' ' + UrgPatNote.Name"
                              [checked]="UrgPatNote.Code == urgPatNoteValue"
                              [disabled]="!UrgPatNote.IsEnabled"
                              i18n="@@Yes"
                              (change)="radio253Change($event)">
                              <div class="text-wrap">
                                  {{ UrgPatNote.Code + ' ' + UrgPatNote.Name }}
                              </div>
                              </mat-radio-button>
                          </div>
                      </mat-radio-group>
                  </label>
              </mat-grid-tile>

              <mat-grid-tile colspan="12">
                  <mat-divider style="width: 97%; border: 1px dashed lightgray;"></mat-divider>
              </mat-grid-tile>
              <mat-grid-tile colspan="2" rowspan="3" style="margin-left: 25px;" >
                  <label class="vis-form-field" style="margin-left: 0px;">
                      <span i18n="@@UrgPatRea" [ngClass]="{ 'required': a_selected }" class="extend-label">緊急傷病轉診原因</span>
                  </label>
              </mat-grid-tile>
              <mat-grid-tile colspan="10" rowspan="3">
                  <label class="vis-form-field radio-align" style="margin-left: 0px;">
                      <mat-radio-group formControlName="UrgPatRea" [name]="'UrgPatRea'" [(ngModel)]="systemCode255" class="p-0 m-0 extend-label">
                          <div style="line-height: 1.25rem;">
                              <mat-radio-button *ngFor="let UrgPatRea of systemCode0255"
                              class="k-radio col-sm-4 pr-0 mr-0 mb-0"
                              [id]="'systemCode255' + UrgPatRea.Code"
                              [value]="UrgPatRea.Code + ' ' + UrgPatRea.Name"
                              [disabled]="!UrgPatRea.IsEnabled"
                              i18n="@@Yes"
                              (change)="radio255Change($event)">
                              <div class="text-wrap">
                                  {{ UrgPatRea.Code + ' ' + UrgPatRea.Name }}
                              </div>
                              </mat-radio-button>
                          </div>
                      </mat-radio-group>
                  </label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2" rowspan="2" style="margin-left: 25px;">
                  <label class="vis-form-field" style="margin-left: 0px;">
                      <span i18n="@@UrgPatOth" [ngClass]="{ 'required': urgPatRea07_selected }" class="extend-label" style="max-width: initial !important;">緊急傷病轉診<br>原因(其他)<br>(最大長度60字)</span>
                  </label>
              </mat-grid-tile>
              <mat-grid-tile colspan="10" rowspan="2" style="margin-right: 25px;">
                  <label class="vis-form-field" style="margin-left: 0px;">
                      <textarea class="vist-extarea" rows="2" maxlength="60" style="height: 100%; width: 95%; resize: none;"
                      formControlName="UrgPatOth">
                      </textarea>
                  </label>
              </mat-grid-tile>
            </ng-container>

            <mat-grid-tile *ngIf="b_selected" colspan="12">
                <mat-divider style="width: 97%; border: 1px dashed lightgray;"></mat-divider>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="b_selected" colspan="2" rowspan="4" style="margin-left: 25px;">
                <label class="vis-form-field" style="margin-left: 0px;">
                    <span i18n="@@SpeCaseNote" [ngClass]="{ 'required': b_selected }" class="extend-label">特定個案轉出註記</span>
                </label>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="b_selected" colspan="10" rowspan="4">
                <label class="vis-form-field radio-align" style="margin-left: 0px;">
                    <mat-radio-group formControlName="SpeCaseNote" [name]="'SpeCaseNote'" [(ngModel)]="systemCode252" class="p-0 m-0 extend-label">
                        <div style="line-height: 1.25rem;">
                            <mat-radio-button *ngFor="let SpeCaseNote of systemCode0252"
                            class="k-radio col-sm-6 pr-0 mr-0 mb-0"
                            [id]="'systemCode252' + SpeCaseNote.Code"
                            [value]="SpeCaseNote.Code + ' ' + SpeCaseNote.Name"
                            [disabled]="!SpeCaseNote.IsEnabled"
                            i18n="@@Yes"
                            (change)="radio252Change($event)">
                            <div class="text-wrap">
                                {{ SpeCaseNote.Code + ' ' + SpeCaseNote.Name }}
                            </div>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </label>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>

<!-- <app-hist-helper-pad [isOpened]="isHelperPadOpened" (emitClose)="closeHelperPadWin()">
</app-hist-helper-pad> -->
<!-- <app-hist-helper></app-hist-helper> -->
