<div class="w-100 d-block" *ngIf="isOpenUsers">
    <label class="text-lg font-weight-bold d-inline-flex mb-0 position-relative w-34">療程/治療師設定</label>
    <div class="flex-grow-one d-inline-flex text-lg" style="width: calc(100% - 151px);">
      <div class="flex-grow-one d-flex" ><div class="float-left w-10">物理</div>
        <app-vis-dropdownlist class="text-base options-minus-label float-left"
          style="width: calc( 100% - 40px );"
          [(ngModel)]="Users.PTCid"
          [data]="RPers"
          [textField]="'text'"
          [disabled]="isEditDisabled"
          [valueField]="'value'"
          (valueChange)="rehabitMedUsersChg($event)"
          [valuePrimitive]="true">
        </app-vis-dropdownlist>
      </div>
      <div class="flex-grow-one d-flex"><div class="float-left w-10">職能</div>
        <app-vis-dropdownlist class="text-base options-minus-label float-left"
        style="width: calc( 100% - 40px );"
          [(ngModel)]="Users.OTCid"
          [data]="ROers"
          [textField]="'text'"
          [disabled]="isEditDisabled"
          [valueField]="'value'"
          (valueChange)="rehabitMedUsersChg($event)"
          [valuePrimitive]="true">
        </app-vis-dropdownlist>
      </div>
      <div class="flex-grow-one d-flex"><div class="float-left w-10">語言</div>
        <app-vis-dropdownlist class="text-base options-minus-label float-left"
          style="width: calc( 100% - 40px );"
          [(ngModel)]="Users.STCid"
          [data]="RSers"
          [disabled]="isEditDisabled"
          [textField]="'text'"
          [valueField]="'value'"
          (valueChange)="rehabitMedUsersChg($event)"
          [valuePrimitive]="true">
        </app-vis-dropdownlist>
      </div>
      <div class="flex-grow-one d-flex"><div class="float-left w-10">心理</div>
        <app-vis-dropdownlist class="text-base options-minus-label float-left"
          style="width: calc( 100% - 40px );"
          [(ngModel)]="Users.PRTCid"
          [data]="PRTers"
          [textField]="'text'"
          [disabled]="isEditDisabled"
          [valueField]="'value'"
          (valueChange)="rehabitMedUsersChg($event)"
          [valuePrimitive]="true">
        </app-vis-dropdownlist>
      </div>
    </div>
  </div>
<div style="display: flex;column-gap: 5px;" class="text-lg mt-1" searchBarField *ngIf="isOpenOrders && isOpenUsers" >
    <mat-checkbox class="w-14" *ngFor="let item of rehabilitationOpt" [disabled]="isEditDisabled" (change)="checkChange($event)" [value]="item.key" [checked]="item.checked" #rehab>{{item.value}}</mat-checkbox>
</div>
<app-vis-grid [data]="selectOrders"  *ngIf="isOpenOrders && isOpenUsers"
    [pageable]="false"
    [showStickyHead]="true"
    [customStyle]="'350px'"
    [customClass]="'float-right patients-list more-small'"
    [navigable]="true"
    [selectable]="true"
    [kendoGridSelectBy]="'RxCode'"
    style="width: calc(100% - 0.225rem);display: inline-grid;"
    class="no-border">
    <app-vis-grid-checkbox-column class="text-center" [setDisable]="true" [field]="'Checked'" [someChecked]="isSomeChecked()" [width]="20" ></app-vis-grid-checkbox-column>
    <app-vis-grid-column field="RxCode" title="項目" [width]="70" class="text-lg">
    </app-vis-grid-column>
    <app-vis-grid-column field="ProdName" title="名稱" class="text-lg"></app-vis-grid-column>
</app-vis-grid>
