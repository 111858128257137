<div class="card-body no-padding watch-list" [style.padding]="'0px'" [style.width]="width">
  <div class="grid-title">{{title}}</div>
  <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="true" tabindex="0"
    (pageChange)="pageChange($event)" [kendoGridSelectBy]="'Id'" [tableFullWidth]="'w-100'" [showStickyHead]="true"
    [showStickyHeight]="560" [class]="'no-border'" [customClass]="'overflow-x-hidden'" #reserveList>

    <app-vis-grid-column title="報到" [width]="75" class="text-center"> <!-- 功能按鈕改成展開方式 2024.11.08 -->
      <ng-template let-dataItem let-row="index">
        <ng-container>
          <mat-icon (click)="onExpendMore(row)" i18n="@@Edit"
            class="vis-combine-icon btn-scale-90 position-absolute m-auto start-0 end-0 top-0 bottom-0 cursor-pointer icon-hover"
            title="報到">{{row==expandIndex?'close':'more_vert'}}
          </mat-icon>
          <ng-container *ngIf="expandIndex==row">
            <div class="popup-icon ic-booking" (click)="onBtnReadIC(dataItem)">
              <mat-icon title="IC報到" style="rotate: -90deg;">sim_card</mat-icon>IC
            </div>
            <div class="popup-icon ab-booking" (click)="onBtnAbnormalIC(dataItem)">
              <mat-icon title="異掛報到">sim_card_alert</mat-icon>異掛
            </div>
            <div class="popup-icon own-booking" (click)="onBtnRegisterOweCard(dataItem,'Owe')">
              <mat-icon title="欠卡報到">how_to_reg</mat-icon>欠卡
            </div>
            <div class="popup-icon lack-booking" (click)="onBtnRegisterSelfPay(dataItem,'Self')">
              <mat-icon title="自費報到">how_to_reg</mat-icon>自費
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PatientNo" [CellStyle]="{direction:'rtl'}" title="病歷號" [width]="75">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PatientName" title="病患姓名" [width]="70">
      <ng-template let-data>
        <div class="ellipsis-content" [ngClass]="data.PatientSex == '女' ? 'vis-color-purple' : ''"
          title="{{data.PatientName}}">
          {{data.PatientName}}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column field="PatientSex" title="性別" [width]="45" class="text-center custom-m-auto">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PatientAge" title="年齡" [width]="40" class="text-center custom-m-auto">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="false" field="ReserveMatters" title="預約事項" [width]="80"
      i18n-title="@@ReserveMatters">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="EstimatedTime" title="估" [width]="35" class="text-center">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="ReserveDateTime" title="時間" [width]="75" class="text-center">
      <ng-template let-data>
        <div class="ellipsis-content" [title]="data.ReserveDateTime | formatHourMinTime:':'">
          {{data.ReserveDateTime | formatHourMinTime:':' }}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="DoctorName" title="醫師" [width]="70"></app-vis-grid-column>
    <app-vis-grid-column [sortable]="false" field="PatientPhone" title="電話" [width]="70"></app-vis-grid-column>
  </app-vis-grid>
</div>

<!-- IC異掛視窗 -->
<app-vis-dialog title="異常就醫" [width]="1200" [height]="0" [show]="isOpenAbnormalIC" (close)="onAbnormalCloseDialog()"
  i18n-title="">
  <div class="inside-scrollbar">
    <app-abnormal-ic (onEmitResult)="onAbnormalICRet($event)" (statusChanged)="onAbnormalStatusChanged($event)"
      (closeDialog)="onAbnormalCloseDialog()"></app-abnormal-ic>
  </div>
</app-vis-dialog>
