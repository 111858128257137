
import { CSHISResult } from './CSHISResult';
/**
 * 
 */ 
export class CSHISResultWithData<T> extends CSHISResult {
    /**
     * 資料
     */ 
    public Data: T = null;
    /**
     *
     */
    constructor(statusCode:number = 0, errMsg:string='', data:T = null) {
        super();
        this.Data = data
    }
}
