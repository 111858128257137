import { ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, combineLatest, Observable, Subscriber } from 'rxjs';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { isNullOrUndefined } from 'src/app/shared/utilities';

declare type option = { text: string, value: any };
declare type formatType = 'value' | 'text' | 'valuetext' | 'multiCommaValue'|'multiCommaValueText';
export const VIS_CB_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VissComboBoxMultipieComponent),
  multi: true
};
@Component({
  selector: 'app-vis-combobox-multiple',
  templateUrl: './vis-combobox-multiple.component.html',
  styleUrls: ['./vis-combobox-multiple.component.css'],
  providers: [VIS_CB_CONTROL_VALUE_ACCESSOR]
})
export class VissComboBoxMultipieComponent implements OnInit, ControlValueAccessor {
  @ContentChild(TemplateRef)
  displayTmp: TemplateRef<any>;
  @Input()
  set inputData(value: option[]) {
    this.data = value;
    if (value && this.autoUpdate) {
      this.updateFilteredOptions();
    }
  }
  @Input() autoUpdate: boolean = false; // 控制自動更新的開關
  @Input()
  allowCustom: Boolean;
  @Input()
  allowInput: Boolean = true;
  @Input()
  filterable: Boolean;
  @Input()
  textField: string = 'text';
  @Input()
  valueField: string = 'value';
  @Input()
  valuePrimitive: Boolean;
  @Input()
  emitPrev: boolean;
  @Input()
  panelFitContent: Boolean;
  @Input()
  value?: string;
  @Input()
  multiMaxItemCount?: number = null;
  // 用於combobox 之綁定不用ngModel
  @Input()
  set othersetvalue(val: any) {
    this.writeValue(val);
  }
  @Input() customIcon: TemplateRef<any>;
  @Input() set selectedOptions(value: ValueTextPair[]) {
    this.selectedOptionsSubject = value;
  }
  get selectedOptions(): ValueTextPair[] {
    return this.selectedOptionsSubject;
  }
  /**
   * 顯示方式 value:只顯示值 text:只顯示文字 valuetext:顯示值+文字
   * @type {formatType} 'value'|'text'|'valuetext'
   * @memberof VisMultiComboboxComponent
   */
  @Input()
  valueFormat: formatType;

  @Input()
  defaultValue: any;

  @Input()
  disabled: false;

  @Input()
  ignoreCase = true;
  @Input()
  // 處理輸入的值帶不在其 ComboBOX之data時
  set setNotInComboData(v: boolean) {
    this._setNotInComboData = v
  }
  get isMaxItemCountReached(): boolean {
    return this.multiMaxItemCount != null && this.selectedOptions.length >= this.multiMaxItemCount;
  }
  @Input() customClass?: string = '';
  @Output()
  filterChange: EventEmitter<any> = new EventEmitter(false);
  @Output()
  valueChange: EventEmitter<any> = new EventEmitter(false);
  @Output()
  selectedOptionsChange = new EventEmitter<ValueTextPair[]>();

  // 假設在某處更新了 selectedOptions，需要手動觸發 selectedOptionsChange
  updateSelectedOptions(newValue: ValueTextPair[]) {
    this.selectedOptionsSubject = newValue;
    this.selectedOptionsChange.emit(newValue);
  }
  @ViewChild('input')
  inputElement: ElementRef<HTMLInputElement>
  @ViewChild('input', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  _setNotInComboData: boolean;
  _currentValue: any;
  _prevValue: any;
  _onChange: (value) => {};
  _onTouched: () => {};
  _disabled: boolean;
  _displayText = '';
  _class: string;
  isHiddenClickButton = true;
  filteredOptions: Observable<option[]>;
  options: Subscriber<option[]>;
  data: option[];

  private selectedOptionsSubject:ValueTextPair[]=[];
  constructor(private elementRef: ElementRef, private cd: ChangeDetectorRef) {
  }

  optInit = false;
  ngOnInit(): void {
    this._disabled = this.disabled;
    if (this.defaultValue != null) this._currentValue = this.defaultValue;
    this.filteredOptions = new Observable((subscriber) => {
      this.options = subscriber;
      // 初始化過濾數據
      this.updateFilteredOptions();
    });
  }
  private updateFilteredOptions(): void {
    if (this.data) {
      if (this.valueFormat != undefined) {
        for (var x of this.data) {
          if (x[this.textField]  ) {
            // string || number

            if ((typeof (x[this.valueField]) == 'string' && !!x[this.valueField]) || (typeof (x[this.valueField]) == 'number')) {
              x[this.textField] = x[this.textField].indexOf('|') > 0 ? x[this.textField] : x[this.valueField] + " | " + x[this.textField]
            }
          }
          
          if (this._currentValue == x.value && (this.valueFormat !== 'multiCommaValue' && this.valueFormat !== 'multiCommaValueText' )) {
            this.setDisplayText(this.valueFormat, x.text);
          }
          else if (this.valueFormat === 'multiCommaValue' || this.valueFormat === 'multiCommaValueText') {
            this.setDisplayText(this.valueFormat, this._currentValue);
          }
        }
      }
      else {
        this.setDisplayText(this.valueFormat, (this.data.find(x => x[this.valueField] == this._currentValue) || {})[this.textField] || '');
      }
      this.options?.next(this.data);
      this.optInit = true;
    }
  }

  ngAfterContentChecked() {

    this._class = this.elementRef.nativeElement.className;

  }

  inputChange() {
    var text = this.inputElement.nativeElement.value;
    if (this.allowInput) {
      if ((this.valueFormat === 'multiCommaValue' || this.valueFormat === 'multiCommaValueText') && text.length > 0) {
        if (text.length > 0) {
          let numberStrings = text.split(",").map((value) => value.trim());
          let resultArray: string[] = numberStrings.map((str) => `${str}`);

          if (resultArray.length > 0) {
            let last = resultArray[(resultArray.length - 1)];
            this.options?.next(this.data?.filter(d => d[this.valueField]?.toLowerCase().includes(last?.toLowerCase())));

          }
          else this.options?.next(this.data?.filter(d => d[this.valueField]?.toLowerCase().includes(text?.toLowerCase())));
        }
      }
      else this.options?.next(this.data?.filter(d => d[this.valueField]?.toLowerCase().includes(text?.toLowerCase())));
    } else {
      this.inputElement.nativeElement.value = this._displayText;
    }
  }

  onInputClick() {
    this.openPanel();
  }

  writeValue(obj: any): void {

    if (!isNullOrUndefined(obj)) {
      this._prevValue = this._currentValue;
      this._currentValue = obj;
      if (this.valueFormat !== 'multiCommaValue' && this.valueFormat !== 'multiCommaValueText' ) {
        var item = this.data?.find(d => d[this.valueField] == this._currentValue)
        var text = item ? item[this.textField] ?? '' : '';

        // 處理輸入的值帶不在其 ComboBOX之data時
        if (this._setNotInComboData && text == '') {

          text = this._currentValue;
        }
        // 還沒Init完成就跳過，等Init完成時會在設定一次顯示文字
        if (this.optInit) {
          this.setDisplayText(this.valueFormat, text);
        }
        this.options?.next(this.data?.filter(d => d[this.textField].indexOf(this._displayText) >= 0));

      }
      else {
        var stringM = (this._currentValue?.toLowerCase()).toString() || '';
        let items: option[] = [];
        let last = '';
        if (stringM.length > 0) {
          let numberStrings = stringM.split(",").map((value) => value.trim());
          let resultArray: string[] = numberStrings.map((str) => `${str}`);
          for (var i = 0; i < resultArray.length; i++) {
            //  console.log('i',resultArray[i]);
            let x = this.data.find(d => d[this.valueField] == resultArray[i].toUpperCase());
            if (x && x[this.valueField]?.trim().length > 0 && items.length < 4 && !items.includes(x)) items.push(x);
            // console.log('items',items);
          }
          last = resultArray[resultArray.length > 0 ? resultArray.length - 1 : 0] || '';
          if (items.length > 0) {
            let str = items.map(x => x[this.valueField]);
            let spec = str.join(',');
            //  if (spec.length >0 )spec += ',';
            this._prevValue = this._currentValue;
            this._currentValue = spec;
            // this.autoComplete.closePanel();
            // this.options.next(this.data);          return;
          }
          else this._currentValue = ''
        }
        else this._currentValue = ''
        if (this.optInit) {
          this.setDisplayText(this.valueFormat, this._currentValue);
        }
        this.options?.next(this.data?.filter(d => d[this.valueField]?.toLowerCase().includes(last?.toLowerCase())));
      }
    } else {
      // clear
      this._prevValue = this._currentValue;
      this._currentValue = obj;
      this._displayText = '';
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  optSelected(evt: MatAutocompleteSelectedEvent) {
    let isCommavalue = false;
    this._prevValue = this._currentValue;

    if (this.valueFormat === 'multiCommaValue' || this.valueFormat === 'multiCommaValueText') {

      var stringM: string = (this._currentValue || '').toString();
      // var tt :option[] = [];
      if (stringM.length > 0) {
        isCommavalue = true;
      }
    }
    if (!isCommavalue) {
      this._currentValue = evt?.option?.value;
      var item = this.data.find(d => d.value == this._currentValue)
      setTimeout(() => {

        this.setDisplayText(this.valueFormat, this._currentValue);
        if (this.valueFormat == 'multiCommaValueText'){
            var getvalue = new ValueTextPair();
            getvalue.value = item.value;
            getvalue.text = item.text;
            this.selectedOptions.push(getvalue);
            this.updateSelectedOptions(this.selectedOptions);
        }
      }, 0);
    }
    else {
      let splitValue = stringM.split(",");
      if (!this.multiMaxItemCount || splitValue.length < this.multiMaxItemCount)
        this._currentValue = stringM + ',' + evt?.option?.value;

      setTimeout(() => {
        var getvalue = new ValueTextPair();
        var itemMu = this.data.find(d => d.value == evt?.option.value);
        getvalue.value = itemMu.value;
        getvalue.text = itemMu.text;
        this.selectedOptions.push(getvalue);
        this.updateSelectedOptions(this.selectedOptions);
        this.setDisplayText(this.valueFormat, this._currentValue ?? '');
      }, 1);
    }

    if (this._onChange) {
      this._onChange(this._currentValue)
    }

    if (this.valuePrimitive && !isCommavalue) {

      if (this.emitPrev) {
        this.valueChange.emit({ value: evt?.option?.value, prev: this._prevValue });
      } else {
        this.valueChange.emit(evt?.option?.value);
      }
    }
    else if (this.valuePrimitive && isCommavalue) {
      if (this.emitPrev) {
        this.valueChange.emit({ value: evt?.option?.value, prev: this._prevValue });
      } else {
        this.valueChange.emit(this._currentValue);
      }
    }
    else if (!this.valuePrimitive && isCommavalue) {
      if (this.emitPrev) {
        this.valueChange.emit({ value: evt?.option, prev: pItem });
      } else {
        setTimeout(() => {
          this.valueChange.emit(this._currentValue);
        }, 2);

      }
    }
    else {
      if (this.emitPrev) {
        var pItem = this.data.find(d => d.value == this._prevValue)
        this.valueChange.emit({ value: evt?.option, prev: pItem });
      } else {
        this.valueChange.emit(evt?.option);
      }
    }


  }

  _valueChange(evt: Event) {

    var item: option = null;
    var items: option[] = [];
    let isCommavalue = false;
    switch (this.valueFormat) {
      case 'multiCommaValue':
        item = this.data.find(d => d[this.valueField]?.toLowerCase() == evt.target['value']?.toLowerCase());
        break;
      case 'multiCommaValueText':
        item = this.data.find(d => (d[this.valueField] + '' + d[this.textField])?.toLowerCase() == evt.target['value']?.toLowerCase());
        break;
      case 'valuetext':
        item = this.data.find(d => (d[this.valueField] + '' + d[this.textField])?.toLowerCase() == evt.target['value']?.toLowerCase());
        break;
      case 'value':
        item = this.data.find(d => d[this.valueField]?.toLowerCase() == evt.target['value']?.toLowerCase());
        break;
      case 'text':
      default:
        item = this.data.find(d => d[this.valueField]?.toLowerCase() == evt.target['value']?.toLowerCase());
        break;
    }
    if (this.valueFormat === 'multiCommaValue' || this.valueFormat === 'multiCommaValueText' ) {

      var stringM = (evt.target['value']?.toLowerCase()).toString() || '';
      if (stringM.length > 0) {
        let numberStrings = stringM.split(",").map((value) => value.trim());
        let resultArray: string[] = numberStrings.map((str) => `${str}`);
        for (var i = 0; i < resultArray.length; i++) {
          let x = this.data.find(d => d[this.valueField] == resultArray[i].toUpperCase());
          if (x && x[this.valueField]?.trim().length > 0 && items.length < 4 && !items.includes(x)) items.push(x);
        }
        if (items.length > 0) {
          let str = items.map(x => x[this.valueField]);
          let spec = str.join(',');
          this._prevValue = this._currentValue;
          this._currentValue = spec;
          isCommavalue = true;
        }
      }
    }
    if (!isCommavalue) {
      this._prevValue = this._currentValue;
      this._currentValue = item && item[this.valueField] ? item[this.valueField] : '';
      // 處理輸入的值帶不在其 ComboBOX之data時
      if (!item && this._setNotInComboData) {
        this._displayText = evt.target['value'];
        this._currentValue = evt.target['value'];
      }
      if (item && !this.ignoreCase) {

        this._displayText = item[this.textField];
      }
      if (!item && (this.valueFormat === 'multiCommaValue' || this.valueFormat === 'multiCommaValueText' )) {
        this.setDisplayText(this.valueFormat, this._currentValue ?? '');
      }
    }
    else {
      this.setDisplayText(this.valueFormat, this._currentValue ?? '');
    }
    if (this.valueFormat == 'multiCommaValueText'){
      this.selectedOptions = items;
      this.updateSelectedOptions(this.selectedOptions);
    }
    if (this._onChange) {
      this._onChange(this._currentValue)
    }
    if (this.valuePrimitive && !isCommavalue) {

      if (this.emitPrev) {
        this.valueChange.emit({ value: this._currentValue, prev: this._prevValue });
      } else {
        this.valueChange.emit(this._currentValue);
      }
    }
    else if (this.valuePrimitive && isCommavalue) {

      if (this.emitPrev) {
        this.valueChange.emit({ value: this._currentValue, prev: this._prevValue });
      } else {
        this.valueChange.emit(this._currentValue);
      }
    }
    else if (!this.valuePrimitive && isCommavalue) {
      if (this.emitPrev) {
        var pItem = this.data.find(d => d[this.valueField] == this._prevValue)
        this.valueChange.emit({ value: this._currentValue, prev: pItem });
      } else {
        setTimeout(() => {
          this.valueChange.emit(this._currentValue);
        }, 1);

      }
    }
    else {
      if (this.emitPrev) {
        var pItem = this.data.find(d => d[this.valueField] == this._prevValue)
        this.valueChange.emit({ value: item, prev: pItem });
      } else {
        this.valueChange.emit(item ?? null);
      }
    }
  }

  simulateEnterKey(event: KeyboardEvent) {

    if (event.keyCode === 13) {
      // 模拟 Enter 键被按下
      this.autoComplete.closePanel();
      this.options.next(this.data);
    }
  }


  setDisplayText(_format: formatType, text: string) {
    var _content = text;
    var ret = '';
    switch (_format) {
      case 'value':
        ret = _content?.split('|')[0]
        if (ret == '' && _content != '') {
          ret = _content?.split('|')[1]
        }
        break
      case 'text':
        ret = _content?.split('|')[1]
        if (ret == '' && _content != '') {
          ret = _content?.split('|')[0]
        }
        break
      case 'multiCommaValue':
      case 'multiCommaValueText':
        
        ret = _content?.split('|')[0]
        if (ret == '' && _content != '') {
          ret = _content?.split('|')[1]
        }
        if (ret && ret.length > 0) ret = ret[ret.length - 1] !== ',' ? ret + ',' : ret;
        else ret = ''
        break
      default:
        ret = '' + _content
        break
    }
    // 因為選取同一個選項時 MAT-AUTOCOMPLETE會把VALUE設定到INPUT中，而透過[VALUE]去綁定 UI不會去更新一樣的值
    // 所以用比較暴力的方式直接設定ELEMENT的VALUE

    setTimeout(() => {
      this._displayText = ret;
      this.inputElement.nativeElement.value = this._displayText;
    }, 0);
  }

  openPanel() {
    if (this._disabled) {
      return;
    }
    this.options.next(this.data);
    setTimeout(() => {
      this.autoComplete.openPanel();
    }, 0);

  }
  getStyleObject() {
    return {
      'width': this.isHiddenClickButton ? 'calc(100%);' : 'calc(100% - 1.3rem);',
    };
  }

  generateDisplayText(option: ValueTextPair, formatType: formatType, separator: string) {
    switch (formatType) {
      case 'value':
        return option.value;
      case 'text':
        return option.text;
      case 'valuetext':
      default:
        return `${option.value}${separator}${option.text}`;
    }
  }
}
