import { AfterViewInit, ChangeDetectorRef,Component,Input, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { FormGroup } from '@angular/forms';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { CtrDrugsReport, CtrDrugsReportItem } from 'src/app/opd/opd-report/models/ctrdrugs-report';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { PRN001Params } from 'src/app/services/api-service/parameters/PRN001Params';

@Component({
    selector: 'app-ctrdrugs-container',
    templateUrl: './ctrdrugs-container.component.html',
    styleUrls: ['./ctrdrugs-container.component.css']
})
export class CtrdrugsContainerComponent implements OnInit, AfterViewInit {
  sizePage = {
    width: 9, //cm
  };
  // paddingPage = {
  //     top: 0, //cm
  //     right: 0, //cm
  //     bottom: 0.2, //cm
  //     left: 0 //cm
  // };

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;
  @ViewChild('reportContainerA6', { static: false })
  reportContainerA6: ElementRef<HTMLDivElement>;
  @ViewChild('reportContainerEmpty', { static: false })
  reportContainerEmpty: ElementRef<HTMLDivElement>;
  @Input()
  set regId(id){
    this.setRegId(id);
  }
  elContainer;
  upperboundIndex: number = 0;

  clinic: Clinic;
  dataRpt: CtrDrugsReport;
  listItems: CtrDrugsReportItem[] = [];
  // api服務
  api: WebApiService;
  // 查詢條件;
  queryFormGroup: FormGroup;
  isA6 = false;
  // data: ConsultationVolumeReportDTO[];

  constructor(private cdr: ChangeDetectorRef,
              private webApiClient: WebApiClient,
              private localPrintService: LocalPrintService,
              private notification: EasyNotificationService,
              private clinicDataService: ClinicDataService,
              private parameterApi: ParameterApi) {
                this.api = this.webApiClient.createHisService('Opd/CTRDrugs');
  }

  async ngOnInit() {
    this.param = await this.clinicDataService.getParam("PRN001");
    this.isA6 = ( this.param?.RepeatPresType != 1 ? true : false);
  }

  ngAfterViewInit() {

  }
  param = new PRN001Params();
  public async setRegId(regId:number) {
    this.param = await this.clinicDataService.getParam("PRN001");
    this.isA6 = ( this.param?.RepeatPresType != 1 ? true : false);
    this.clinic = UserCache.getLoginUser().Clinic;

    const query = {
      ClinicId: this.clinic.Id,
      RegId: regId
    };
    await this.fetchData(query);
  }

  // 取得統計期間的看診人次資料
   async fetchData(query: any) {
    this.elContainer = document.getElementById("container");
    this.dataRpt = new CtrDrugsReport();
    var data = await this.api.get('GetCTRDrugs', query).toPromise<CtrDrugsReport>();
    this.dataRpt = data;

    this.cdr.markForCheck();
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
      return Math.round((cm * 96) / 2.54);
  }
  // heightPageWithoutPadding = this.convertCmtoPx(
  //   this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  // );
  convertPxtoCm(px) {
    return Math.round((px *  2.54) / 96);
  }
  // #endregion
  predates :string[]= [];
  async getHtml() {
    var html = '';

    this.predates = [];
    
    if (!this.isA6) {
      if  (this.param?.CTRDrugPrintMethod == 1 && this.dataRpt?.PresDates.length > 0){
        for (var i = 0; i <  this.dataRpt?.PresDates.length; i++) {
          var pre = this.dataRpt.PresDates.find(x => x.CntPresNo == (i + 1));
          if (pre.CntPresNo == 1) continue;
          var start = DateHelper.getFormatedDateString(pre?.StartDate, true,'');
          var end = DateHelper.getFormatedDateString(pre?.EndDate, true,'');
          if (pre?.EndDate) {
            start = start + '~'
          this.predates.push(start+ end);
          }
          else
          {
            this.predates.push(start);
          }
        }
        this.cdr.detectChanges();
      }

      html = this.reportContainer.nativeElement.innerHTML;
      if (this.param?.CTRDrugPrintMethod == 2 && this.dataRpt?.PresDates.length > 0){
        //當第二次以上開立處方時，不顯示/7以下藥品限領一次
        var excludeTakeOnce = this.dataRpt.Drugs.filter(x => !x.TakeOnce);
        for (var i = 0; i <  this.dataRpt?.PresDates.length; i++) {
          var pre = this.dataRpt.PresDates.find(x => x.CntPresNo == (i + 1));
          if (pre.CntPresNo == 1) continue;
          var start = DateHelper.getFormatedDateString(pre?.StartDate, true,'');
          var end = DateHelper.getFormatedDateString(pre?.EndDate, true,'');
          if (pre?.EndDate) {
            start = start + '~';
            this.dataRpt.DispDate = start+ end;
          }
          else
          {
            this.dataRpt.DispDate = start;
          }
          if (i > 0) {
            this.dataRpt.Drugs = excludeTakeOnce;
          }
          this.cdr.detectChanges();
          html = html +this.reportContainer.nativeElement.innerHTML;
        }

      }

    } else {
      if  (this.param?.CTRDrugPrintMethod == 1 && this.dataRpt?.PresDates.length > 0){
        for (var i = 0; i <  this.dataRpt?.PresDates.length; i++) {
          var pre = this.dataRpt.PresDates.find(x => x.CntPresNo == (i + 1));
          if (pre.CntPresNo == 1) continue;
          var start = DateHelper.getFormatedDateString(pre?.StartDate, true,'');
          var end = DateHelper.getFormatedDateString(pre?.EndDate, true,'');
          if (pre?.EndDate) {
            start = start + '~'
          this.predates.push(start+ end);
          }
          else
          {
            this.predates.push(start);
          }
        }
        this.cdr.detectChanges();
      }
      html = this.reportContainerA6.nativeElement.innerHTML;
      if (this.param?.CTRDrugPrintMethod == 2 && this.dataRpt?.PresDates.length > 0){
        //當第二次以上開立處方時，不顯示/7以下藥品限領一次
        var excludeTakeOnce = this.dataRpt.Drugs.filter(x => !x.TakeOnce);
        for (var i = 0; i <  this.dataRpt?.PresDates.length; i++) {
          var pre = this.dataRpt.PresDates.find(x => x.CntPresNo == (i + 1));
          if (pre.CntPresNo == 1) continue;
          var start = DateHelper.getFormatedDateString(pre?.StartDate, true,'');
          var end = DateHelper.getFormatedDateString(pre?.EndDate, true,'');
          if (pre?.EndDate) {
            start = start + '~';
            this.dataRpt.DispDate = start+ end;
          }
          else
          {
            this.dataRpt.DispDate = start;
          }
          if (i > 0) {
            this.dataRpt.Drugs = excludeTakeOnce;
          }
          this.cdr.detectChanges();
          html = html +this.reportContainerA6.nativeElement.innerHTML;
        }
      }

    }

    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
    }
    // this.sizePage.height = sizpageHeight
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const reportName = '管制藥品專用處方箋';
      const printContents = await this.getHtml();
      var ret = await this.localPrintService.printHtml(809, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }
}

