import { Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RegReserveService } from '../reg-reserve-service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { WindowSizeService } from 'src/app/services/window-size-service';
import { Subscription } from 'rxjs';
import { Sum } from 'src/app/shared/utilities';
import { ReserveScheduleDto } from 'src/app/services/api-service/schedule/schedule-api';
import { TimeSecDesc, TimeSecEnum } from 'src/app/enums/TimeSecEnum';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { TutorialService } from 'src/app/services/tutorial-service';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';

declare type CalendarCellModel = {
  date:Date,
  day:string,
  textClass:any,
  style:any,
  class:string[],
  isToday:boolean,
  isRest:boolean,
  isSelected:boolean,
  secModel:{
    sec:TimeSecEnum,
    text:string,
    class:string[],
    count:number,
    id:string
  }[]
};
@Component({
  selector: 'app-reg-reserve-list',
  templateUrl: './reg-reserve-list.component.html',
  styleUrls: ['./reg-reserve-list.component.css']
})
export class RegReserveListComponent implements OnInit,OnDestroy {

  @Output() 
  dateSelected = new EventEmitter<Date>();
  @Output() 
  sectionSelected = new EventEmitter<{date:Date,sec:TimeSecEnum}>();
  
  /** 選取的日期 */
  @Input()
  selectedDate:Date;
  @Input()
  set selectSec(v:TimeSecEnum){
    if(v){
      this.secSelected = v;
      this.selectSection(this.calendarViewModel.find(x=>DateHelper.compareDate(x.date,this.selectedDate)),v)
    }
  }
  @Input()
  doctorId:number;

  get secSeletable():boolean{
    return this.reserveService.currentPatient>0;
  }

  nowWidth:number = window.innerWidth;
  bHeight:number = window.innerHeight;
  bigRatio: boolean = false;

  reloadSub:Subscription=null;
  days = ['日', '一', '二', '三', '四', '五', '六'];
  qSelect = 0;
  weekList: ValueTextPairNumberValue[] = [
    // { value:0, text: '' },
    { value:7, text: '7' },
    { value:14, text: '14' },
    { value:21, text: '21' },
    { value:28, text: '28' },
    { value:56, text: '56' },
    { value:84, text: '84'}];

  monthFirstDate = new Date();
  /** 畫面縮放事件訂閱 */
  sizeSub:Subscription;
  /** 月份顯示文字 */
  monthLabel:string;
  /** 所選的掛號日期區間 */
  dayInterval: number = 7;
  /** 月曆顯示模型 */
  calendarViewModel:CalendarCellModel[]=[];
  /** 檢視預約掛號詳細 */
  showDetailOpen = false;
  /** 預約掛號詳細資料 */
  detail:{
    DoctorId: number;
    DoctorName: string;
    RoomCode: string;
    ReserveCount: number;
  }[];

  secSelected:TimeSecEnum = null;

  constructor(private fb: FormBuilder,
    private reserveService:RegReserveService,
    private sizeService:WindowSizeService,
    private mainlayout:MainLayoutService,
    private tutorial:TutorialService) {
    this.sizeSub = sizeService.onWinResize.subscribe(()=>{
      this.bigRatio = sizeService.bigRatio;
      this.nowWidth = sizeService.nowWidth;
      this.bHeight = sizeService.contentHeight;
    })
  }

  
  async ngOnInit(){
    var today = new Date();
    this.monthFirstDate = DateHelper.addDay(today,-today.getDate()+1);
    this.bigRatio = this.sizeService.bigRatio;
    this.nowWidth = this.sizeService.nowWidth;
    this.bHeight = this.sizeService.contentHeight;
    this.reloadSub = this.reserveService.onReserveReload.subscribe(()=>{
      this.setMonth(0);
      if(this.secSelected){
        this.selectSection(this.calendarViewModel.find(x=>DateHelper.compareDate(x.date,this.selectedDate)),this.secSelected)
      }
    })
    var sec = this.reserveService.getCurrentSection();
    this.selectSec = sec
    await this.reserveService.loadReserve();
    await this.intervalChange(this.qSelect);
    
    //this.sectionSelected.emit({date:this.selectedDate,sec:sec})
  }
  
  ngOnDestroy(): void {
    this.sizeSub?.unsubscribe();
    this.reloadSub?.unsubscribe();
  }
  /** 切換月份 */
  setMonth(inc: number) {
    this.monthFirstDate = DateHelper.addMonthDay(this.monthFirstDate,inc)
    this.monthLabel = `${this.monthFirstDate.getFullYear()-1911}年${this.monthFirstDate.getMonth()+1}月`
    // 計算月曆日期
    var calendarStartDate = DateHelper.addDay(this.monthFirstDate, -this.monthFirstDate.getDay());    
    var dates = Array(42).fill(0).map((v,num) => DateHelper.addDay(calendarStartDate,num));
    
    //取得統計資料
    var reserves = this.reserveService.currentReserve();
    var secIdSetted = false;
    this.calendarViewModel = dates.map(x=>{
      var today = new Date();
      var isToday = x.toLocaleDateString() == today.toLocaleDateString();
      var isSameMonth = x.getMonth() === this.monthFirstDate.getMonth();
      //所選範圍最後日
      var avaliableEnd = DateHelper.addDay(today,this.dayInterval)

      var reserveOfDay = reserves.filter(y=>DateHelper.compareDate(y.Date,x));
      
      var isRest = reserveOfDay.length==0;
      var model = {
        date:x,
        day: x.getDate().toString().padStart(2,'0'),
        style:{borderLeft:x.getDate()?'#ddd 1px solid':''},
        textClass:[(isToday)?'today':''],
        class:[
            (x < today || x > avaliableEnd )  //範圍外
          && !(isToday)
          ||isRest  //休
          ?'day-disabled':'',DateHelper.compareDate( x,this.selectedDate)?'selected':''],
        isRest:isRest,
        isSelected:false,
        secModel:[],
        isToday:isToday
      }
      model.secModel = [1,2,3].map(y=>{
        var id = ''
        if(!secIdSetted && !model.class.some(c=>c=='day-disabled')){
          secIdSetted = true;
          id ='reserve-list-tsec'
        }
        return {
          sec:y,
          class:[],
          text:TimeSecDesc.find(z=>z.value==y).text,
          count:this.getReserveSum(reserves,x,y),
          id:id
        }
      })
      return model;
    });
  }

  /** 取得指定診的預約總和 */
  getReserveSum(reserves: ReserveScheduleDto[],date:Date,sec:TimeSecEnum){
    var reserveOfDay = reserves.filter(y=>DateHelper.compareDate(y.Date,date));
    var reserveSec = reserveOfDay.find(y=>y.Sec==sec);
    if(!reserveSec){
      return -1;
    }
    var reserveDetail = reserveSec.Reserves;
    if(this.doctorId){
      reserveDetail = reserveDetail.filter(x=>x.DoctorId ==this.doctorId);
    }
    return Sum(reserveDetail,(y=>y.ReserveCount));
  }

  /** 重撈預約併重整月曆 */
  async intervalChange(selectedValue: number) {
    selectedValue = parseInt(selectedValue.toString());
    this.qSelect = selectedValue;
    this.dayInterval = this.reserveService.getInerval();
    //await this.reserveService.loadReserve(new Date(),DateHelper.addDay(new Date(),this.dayInterval));
    var toDate = DateHelper.addDay(new Date(),selectedValue);
    // 跨年囉?
    var mDiff = (toDate.getFullYear() -this.monthFirstDate.getFullYear())*12 +(toDate.getMonth() -this.monthFirstDate.getMonth());
    this.setMonth(mDiff);
    //this.selectDate(this.calendarViewModel.find(x=>x.date.toDateString() == toDate.toDateString()));
    var d = this.calendarViewModel.find(x=>x.date.toDateString() == toDate.toDateString());
    this.sectionClick(d,this.secSelected,null);
    //this.selectSection(),this.secSelected);
    //this.sectionSelected.emit({date:toDate,sec:this.secSelected});
  }

  /** 日期點選 */
  dateClick(data:CalendarCellModel,evt:Event){
    if(data.isRest){
      return;
    }
    if(evt){
      this.qSelect = 0;
    }
    evt?.stopPropagation()
    this.selectDate(data);
    this.selectedDate = data.date;
    this.selectSection(data,null);
    this.dateSelected.emit(this.selectedDate)
  }

  selectDate(data:CalendarCellModel){
    this.calendarViewModel.forEach(x=>{
      if(x.class.some(y=>y=='selected')){
        x.class = x.class.filter(y=>y!='selected');
        x.isSelected = false;
      }
    });
    if(!data.class.some(y=>y=='selected')){
      data.class.push('selected');
    }
    this.secSelected = null;
    data.isSelected = true;
  }

  /** 時段點選 */
  sectionClick(data:CalendarCellModel,sec:number,evt:Event){
    if(evt){
      this.qSelect = 0;
    }
    evt?.stopPropagation()
    this.selectDate(data);
    this.secSelected = sec;
    this.selectedDate = data.date;
    if(this.selectSection(data,sec)){
      this.sectionSelected.emit({date:data.date,sec:sec})
    }
    
  }
  /** 更新UI選取時段的樣式 */
  selectSection(data:CalendarCellModel,sec:number){
    if(data==null){
      return false;
    }
    const activeCls = 'active';
    this.calendarViewModel.forEach(x=>{
      x.secModel.forEach(y=>{
        if(y.class.some(z=>z == activeCls)){
          y.class = y.class.filter(z=>z != activeCls)
        }
      })
    })
    var secData = data.secModel.find(x=>x.sec == sec);
    if(secData && !secData.class.some(x=>x==activeCls)){
      secData.class.push('active')
    }
    return true;
  }
  /** 時段人數點選 */
  secCountClick(data:CalendarCellModel,sec:number,evt:Event){
    evt.stopPropagation();
    
    //顯示細節
    this.detail = this.reserveService.getReserveDetail(data.date,sec);
    if(this.doctorId){
      this.detail = this.detail.filter(x=>x.DoctorId == this.doctorId);
    }
    this.showDetailOpen = true;
    
  }

  showTutorial(){
    this.tutorial.Start('reserve');
  }

  async saveSetting(evt: any, dialog: VisDialogComponent) {
    var ret = await this.reserveService.saveConfig();
    dialog.show = !ret;
    this.ngOnInit();
  }

}

export class WeekSettingDay {
  name: number;
  label: string;
  allDay: boolean;
  labelS: string;
  isSelectedS: boolean;
  labelT: string;
  isSelectedT: boolean;
  labelU: string;
  isSelectedU: boolean;
}
