import { Injectable } from '@angular/core';
import { WebApiClient } from '../../web-api-client.service';
import { WebApiService } from '../../web-api.service';
import { decreeRx, DecreeRxGroupEnum, dentalICD10CM, dentalICD10PCS, DentalValidationRule } from '../../api-service/hist/decreeRx';
import { toothPosRuleDto } from './opd-api.model';
import { toothTreatPosDto, toothTreated } from 'src/app/hist/models/tooth-treat-position';
import { RegisterWaitingItemDTO } from '../register/register-api.model';

@Injectable({
  providedIn: 'root'
})
export class OpdApiService {
    apiService: WebApiService;
    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createDentistService('opd');
    }
    
    selectedRegisterItem: RegisterWaitingItemDTO;
    decreeRxs: decreeRx[] = [];
    cannotApplyPlus: decreeRx[] = [];
    odLimits: decreeRx[] = [];
    endoLimits: decreeRx[] = [];
    extractionItems: decreeRx[] = [];
    toothRules: toothPosRuleDto[] = [];
    public async GetDecreeRxes(): Promise<toothPosRuleDto[]> {
        if (this.toothRules.length == 0) {
            var result = await this.apiService.get('GetDecreeRxes').toPromise<decreeRx[]>();
            this.cannotApplyPlus = result.filter(x => x.Group == DecreeRxGroupEnum.DentialCannotApplyPlus);
            this.odLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialODLimtit);
            this.endoLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialENDOLimit);
            this.extractionItems = result.filter(x => x.Group == DecreeRxGroupEnum.DentailExtractionItmes);
            this.parseToothRule(result.filter(x => x.Group == DecreeRxGroupEnum.DentialRule));
        }
        return this.toothRules;
    }

    public async GetCannotApplyPlus(): Promise<decreeRx[]> {
        if (this.cannotApplyPlus.length == 0) {
            var result = await this.apiService.get('GetDecreeRxes').toPromise<decreeRx[]>();
            this.cannotApplyPlus = result.filter(x => x.Group == DecreeRxGroupEnum.DentialCannotApplyPlus);
            this.odLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialODLimtit);
            this.endoLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialENDOLimit);
            this.extractionItems = result.filter(x => x.Group == DecreeRxGroupEnum.DentailExtractionItmes);
            this.parseToothRule(result.filter(x => x.Group == DecreeRxGroupEnum.DentialRule));
        }
        return this.cannotApplyPlus;
    }

    public async GetODLimits(): Promise<decreeRx[]> {
        if (this.toothRules.length == 0) {
            var result = await this.apiService.get('GetDecreeRxes').toPromise<decreeRx[]>();
            this.cannotApplyPlus = result.filter(x => x.Group == DecreeRxGroupEnum.DentialCannotApplyPlus);
            this.odLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialODLimtit);
            this.endoLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialENDOLimit);
            this.extractionItems = result.filter(x => x.Group == DecreeRxGroupEnum.DentailExtractionItmes);
            this.parseToothRule(result.filter(x => x.Group == DecreeRxGroupEnum.DentialRule));
        }
        return this.odLimits;
    }

    public async GetENDOLimits(): Promise<decreeRx[]> {
        if (this.toothRules.length == 0) {
            var result = await this.apiService.get('GetDecreeRxes').toPromise<decreeRx[]>();
            this.cannotApplyPlus = result.filter(x => x.Group == DecreeRxGroupEnum.DentialCannotApplyPlus);
            this.odLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialODLimtit);
            this.endoLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialENDOLimit);
            this.extractionItems = result.filter(x => x.Group == DecreeRxGroupEnum.DentailExtractionItmes);
            this.parseToothRule(result.filter(x => x.Group == DecreeRxGroupEnum.DentialRule));
        }
        return this.endoLimits;
    }

    public async GetExtractionItems(): Promise<decreeRx[]> {
        if (this.toothRules.length == 0) {
            var result = await this.apiService.get('GetDecreeRxes').toPromise<decreeRx[]>();
            this.cannotApplyPlus = result.filter(x => x.Group == DecreeRxGroupEnum.DentialCannotApplyPlus);
            this.odLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialODLimtit);
            this.endoLimits = result.filter(x => x.Group == DecreeRxGroupEnum.DentialENDOLimit);
            this.extractionItems = result.filter(x => x.Group == DecreeRxGroupEnum.DentailExtractionItmes);
            this.parseToothRule(result.filter(x => x.Group == DecreeRxGroupEnum.DentialRule));
        }
        return this.extractionItems;
    }

    public async GetDentalICD10CMs(): Promise<dentalICD10CM[]> {
        return await this.apiService.get('GetDentalICD10CMs').toPromise<dentalICD10CM[]>();
    }

    public async GetDentalICD10PCs(): Promise<dentalICD10PCS[]> {
        return await this.apiService.get('GetDentalICD10PCs').toPromise<dentalICD10PCS[]>();
    }

    private parseToothRule(decreeRxs: decreeRx[]) {
        decreeRxs.forEach(x => {
            var toothRule: toothPosRuleDto = new toothPosRuleDto();
            var jsonObj = JSON.parse(x.DentalValidationRule) as DentalValidationRule;
      
            toothRule.StdCode = x.StdCode;
            toothRule.ToothPosLimited = (jsonObj.ToothPosLimited.length > 0 ? jsonObj.ToothPosLimited.split(',') : []);
            toothRule.NeedToCheckToothPos = jsonObj.NeedToCheckToothPos == 'Y' ? true : false;;
            toothRule.NeedToCheckQuantity = jsonObj.NeedToCheckQuantity == 'Y' ? true : false;
            toothRule.NeedToRemark = jsonObj.NeedToRemark == 'Y' ? true : false;
            toothRule.EndDate = jsonObj.EndDate;
            if (!this.isOverTheDate(toothRule.EndDate)) this.toothRules.push(toothRule);
        });
    }

    private isOverTheDate(targetDate: Date): boolean {
        const today = new Date();
        const target = new Date(targetDate);
        today.setHours(0, 0, 0, 0);
        target.setHours(0, 0, 0, 0);
        return today > target;
    }
}