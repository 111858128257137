import { Injectable } from '@angular/core';
import { WebApiClient } from '../../web-api-client.service';
import { WebApiService } from '../../web-api.service';
import { ReserveItem } from '../../reserve.service';
import { ReserveCreateDto } from '../../api-service/register/register-api';
import { PageData } from 'src/app/shared/models/page-data';

@Injectable({
  providedIn: 'root'
})
export class ReserveApiService {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = apiClient.createDentistService('reserve');
  }
  /** 取得預約清單 */
  public async getAll(doctorId:number,sDate:Date,eDate:Date):Promise<ReserveItem[]>{
    return await this.apiService.get('getAll',{
      doctorId: doctorId,
      startDate: sDate.toLocaleDateString(),
      endDate: eDate.toLocaleDateString()
    }).toPromise<ReserveItem[]>();
  }
  
  /** 取得預約清單分頁資料 */
  public async getAllPageData(doctorId:number, sDate:Date, eDate:Date, pageNumber: number, pageSize: number): Promise<PageData> {
    return await this.apiService.get('GetAllPageData', {
      doctorId: doctorId,
      startDate: sDate.toLocaleDateString(),
      endDate: eDate.toLocaleDateString(),
      pageNumber: pageNumber,
      pageSize: pageSize
    }).toPromise<PageData>();
  }

  /** 以病患ID取得預約清單 */
  public async getByPatient(patientId:number):Promise<ReserveItem[]>{
    return await this.apiService.get('GetByPatient',{
      patientId
    }).toPromise<ReserveItem[]>();
  }
  /** 新增預約掛號 */
  public async Reserve(data: DentistReserveCreateDto): Promise<number> {
    return await this.apiService.post('Reserve', data).toPromise<number>();
    
  }
  /** 更新預約掛號 */
  public async Update(data: DentistReserveCreateDto): Promise<number> {
    return await this.apiService.post('Update', data).toPromise<number>();
  }
  
  /** 取消預約掛號 */
  public async Cancel(id:number): Promise<number> {
    return await this.apiService.post('cancel?id='+id, {}).toPromise<number>();
  }
}
export interface DentistReserveCreateDto extends ReserveCreateDto{
  ReserveMessage: string;
  EstimatedTime: number;
  ReserveMatters: string;
  ReminderText: string;
  ScheduleTime: Date;
}
