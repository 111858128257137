import { animate, state, style, transition, trigger } from "@angular/animations";

export class RegisterHistAreaState {
  static idle = 'idle';
  static ready = 'ready';
  static hidden = 'hidden';
}

export class RegisterCardAreaState {
  static slide_in = 'slide_in';
  static slide_out = 'slide_out';
}

export class AnimateTriggerType {
  static slide_in_out_hist = 'slide_in_out';
  static slide_in_out_card = 'slide_in_out_00';
}

export const RegisterHistAnimationStyle = [
  trigger(AnimateTriggerType.slide_in_out_hist, [
    state(RegisterHistAreaState.idle, style({
      top: '0px',
      zIndex: 11
    })),
    state(RegisterHistAreaState.ready, style({
      display: 'none',
      top: '100%',
      zIndex: 10
    })),

    state(RegisterHistAreaState.hidden, style({
      top: '0px',
      zIndex: 10
    })),
    transition(`${RegisterHistAreaState.ready} <=> ${RegisterHistAreaState.idle}` , animate(300)),
    transition(`${RegisterHistAreaState.idle} <=> ${RegisterHistAreaState.hidden}`, animate(0)),
    transition(`${RegisterHistAreaState.hidden} <=> ${RegisterHistAreaState.ready}`, animate(0))
  ]),
  trigger(AnimateTriggerType.slide_in_out_card, [
    state(RegisterCardAreaState.slide_in, style({
      width: '100%',
    })),
    state(RegisterCardAreaState.slide_out, style({
      width: '0px',
      display: 'none'
    })),
    transition(`${RegisterCardAreaState.slide_in} <=> ${RegisterCardAreaState.slide_out}`, animate(300))
  ])
]
