import { APP_ID, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fail } from 'assert';
import { DailyReport, DailyReportColEnum, DailyReportIcodeSum, DailyReportItem } from 'src/app/payments/models/daily-report';
import { DeclareApi, DeclareCaseReportDTO, DeclareCaseReportPageDTO } from 'src/app/services/api-service/declare/declare-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import {NullOrEmpty, NullOrEmptyTrim } from 'src/app/shared/utilities';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { PRN001Params } from 'src/app/services/api-service/parameters/PRN001Params';
import { Sum } from '../../../../../shared/utilities';

@Component({
  selector: 'app-daily-payment-report',
  templateUrl: './daily-payment-report.component.html',
  styleUrls: ['./daily-payment-report.component.css']
})
export class DailyPaymentReportComponent implements OnInit {
  sizePage = {
    width: 20.5, //cm
    height: 29 //cm
};
paddingPage = {
    top: 0.3, //cm
    right: 0, //cm
    bottom: 0.3, //cm
    left: 0 //cm
};

heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom) +0.5
);
elContainer;
anchorsBlockValue;
pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

@ViewChild('reportContainer', { static: false })
reportContainer: ElementRef<HTMLDivElement>;
@ViewChildren('[doRemark]') elementsWithReport: QueryList<ElementRef>;
@ViewChildren('[doExcel]') elementsWithExcel: QueryList<ElementRef>;

showDoctorNameColume: boolean;
monthStr: string = '';
nowStr: string = '';
condStr: string = '';
reportTime : Date;
totalRecord: number = 0;
pageNo: number = 1;
_sDate: Date;
_eDate: Date;
dailyReport: DailyReport;
// 清單物件
listItems: DailyReportItem[] = [];
listIcodeSum : DailyReportIcodeSum[] = [];
// api服務
api: WebApiService;
// 查詢條件;
queryFormGroup: FormGroup;
prn001 : PRN001Params;
enumArray = DailyReportColEnum;
hidecolArray :string[]= [];
fontsize = 10;
fontRange = 1;
forExcel = true;
excelstr = '';
constructor(private webApiClient: WebApiClient,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService:HcrService,
    private clinicData:ClinicDataService,
    private elementRef: ElementRef) {
        this.api = this.webApiClient.createHisService('Payments/Payment');
    }
@Input() isHomeCare = false;
ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
        '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
        '.pres-report2 table{ border:0px solid black; }\n' +
        '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 0.1px;padding-right: 0.1px;}\n' +
        '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
        '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
        '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
        '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
        '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
        // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
        this.reportContainer.nativeElement.appendChild(st);
    }, 0);
}


ngAfterViewInit() {
}
// 取得統計期間的看診人次資料，並組出報表的html內容
async fetchData(query: any) {
    this.elContainer = document.getElementById("container");
    this.dailyReport = new DailyReport();
    this.prn001 = await this.clinicData.getParam("PRN001");
    let hidecolnums = this.prn001?.DailyReportHideCol?.length > 0 ? this.prn001.DailyReportHideCol.split(',')
    .filter(item => !isNaN(Number(item)))  // 過濾掉非數字的子字符串
    .map(item => Number(item)):[];
    if (query.reportType == 'summary')hidecolnums = hidecolnums.concat([2,3,4,5,6,7,8,9,10])


    this.hidecolArray = DailyReportColEnum
    .filter(item => hidecolnums.includes(item.Seq))  // 過濾出序列包含的項目
    .map(item => item.Col);
    const hide = DailyReportColEnum.filter(item => hidecolnums.includes(item.Seq))
    .map(item => item.Witdh).reduce((sum, number) => sum + number, 0);
    const totalWidth = DailyReportColEnum.reduce((sum, column) => sum + column.Witdh, 0) +14;

    this.fontRange = totalWidth / (totalWidth - hide) <= 1.5 ? totalWidth / (totalWidth - hide): 1.5;
    this._sDate = query.startDate;
    this._eDate = query.endDate;
    var condWords : string[] = query.conditionWords;
    if (!NullOrEmptyTrim(query.selectWords)){
        this.condStr = '依 ' + query.selectWords + ' 條件：';
        if (condWords?.length > 0)this.condStr   += condWords.join('、');
        else this.condStr   += '全部';
    }

    this.api.get('GetDailyReport', query).subscribe(
        (data: DailyReport) => {
            this.dailyReport = data;
            this.listItems = data.Items;
            this.reportTime = data.ReportTime;
            this.listIcodeSum = data.Sum;
            this.totalRecord = this.listItems.filter(x => x.IsSummary == false).length;
            var prevName = '';
            // this.listItems.forEach(x => {
            //     var currName = '';
            //     if (this.showDoctorNameColume) {
            //         currName = x.DoctorName;
            //     } else {
            //         currName = x.ClinicName;
            //     }

            //     if (currName == prevName) {
            //         if (this.showDoctorNameColume) {
            //             x.DoctorName = '';
            //         } else {
            //             x.ClinicName = '';
            //         }
            //     } else {
            //         prevName = currName;
            //     }
            // });
            // 將查詢的資料組成報表html
            this.insertListData();
            this.doexcel();
        }
    );
}

// 組報表的html內容
insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    var isSum = false;
    var nowsearch = new Date();
    this.fontsize = 10 * this.fontRange;
    const pageRow  = 58;
    // let  actualPageHeight = (8.5 /this.fontsize ) * pageHeight;
    let actualRow=  (10 /this.fontsize ) * pageRow;
    let row = 1;
    let detailRow = 0;
    this.nowStr = this.onGetDateString(this.reportTime)+ ' ' + this.onGetTimeString(this.reportTime);
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
    var sum_header = this.createSumListHeader();
    var html_finish = this.createHTMLFinish();              // 報表結束 -- 以下空白
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {
        var prevTableContent = elTableContent.innerHTML;
        // 判斷是否超出頁面範圍? -> 新增一頁
        if (row >= actualRow) {
            row = 1;
            detailRow = 0;
            elTableContent.innerHTML = prevTableContent;
            iPage += 1;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            html_header = this.createHTMLPageHeader(iPage);
            // html_header = this.createHTMLPageNoHeader(iPage);
            elPageContent.innerHTML += html_header;
            elTableContent = document.getElementById("table-" + iPage + "-content");
            elTableContent.innerHTML = html_ListerHeader;

            this.pageContent[iPage] = [];
            this.pageContent[iPage].push(iBlock);
        } else {
            row += 1
            if (detailRow == 6 ){
                detailRow = 0
                row += 1
            }
            this.pageContent[iPage].push(iBlock);
        }
        // 加入 List 每一筆的內容
        html_Block = this.createHTMLBlock(this.listItems[i]);
        if (this.listItems[i].PatientNo == '小計') detailRow += 1;
        elTableContent.innerHTML += html_Block;
        iBlock += 1;
    }
    if(this.listIcodeSum.length > 0 && !isSum ){
        // console.log('elPageContent.offsetHeight31',elPageContent?.offsetHeight);
        if (row +2 >= actualRow) {
            // elTableContent.innerHTML = prevTableContent;
            row = 1;
            iPage += 1;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            html_header = this.createHTMLPageSumHeader(iPage);
            // html_header = this.createHTMLPageNoHeader(iPage);
            elPageContent.innerHTML += html_header;
            elTableContent = document.getElementById("table-" + iPage + "-sum");
            elTableContent.innerHTML = sum_header;

            this.pageContent[iPage] = [];
            this.pageContent[iPage].push(iBlock);
        } else {
            row += 1;
            let sum_first = this.createHTMLPageFirstSumHeader(iPage);
            elPageContent.innerHTML += sum_first;
            var elTableContent = document.getElementById("table-" + iPage + "-sum");
            elTableContent.innerHTML += sum_header;
        }
         isSum = true;
    }
    for (let i = 0; i < this.listIcodeSum.length; i++) {
        // 加入 List 每一筆的內容

        html_Block = this.createSumBlock(this.listIcodeSum[i]);
        var prevTableContent = elTableContent.innerHTML;
        // console.log('html_Block',html_Block);
        elTableContent.innerHTML += html_Block;

        // console.log('elPageContent.offsetHeight33',elPageContent?.offsetHeight,this.heightPageWithoutPadding);
        // let height = elPageContent.offsetHeight + 51;
        // 判斷是否超出頁面範圍? -> 新增一頁
        if ( row >= actualRow) {
            row = 1
            elTableContent.innerHTML = prevTableContent;
            iPage += 1;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            html_header = this.createHTMLPageSumHeader(iPage);
            // html_header = this.createHTMLPageNoHeader(iPage);
            elPageContent.innerHTML += html_header;
            elTableContent = document.getElementById("table-" + iPage + "-sum");
            elTableContent.innerHTML = sum_header;

            this.pageContent[iPage] = [];
            this.pageContent[iPage].push(iBlock);
        } else {
            row +=1
            this.pageContent[iPage].push(iBlock);
        }
        iBlock += 1;
    }
    elPageContent.innerHTML +=  html_finish;
}

convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
}

convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
}

createHTMLPage(iPage) {
    let page ='';
    if (this.forExcel) {
        page += `
          <style>
            .excel-format {
              font-size: 12pt;
            }
            .excel-format-text {
              font-size: ${this.fontsize}pt;
              mso-number-format: "@";
            }
          </style>`;
      }
      page += `<div class="page" id="page-` + iPage + `"
      style="
      margin: 0px auto;
      height: ` + this.sizePage.height + `cm;
      width: ` + this.sizePage.width + `cm;
      padding-top: ` + this.paddingPage.top + `cm;
      padding-right: ` + this.paddingPage.right + `cm;
      padding-bottom: ` + this.paddingPage.bottom + `cm;
      padding-left: ` + this.paddingPage.left + `cm;
      display: block;
      margin: 0px auto;
      box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      ">
      <div class="content" id="page-` + iPage + `-content">
      </div>
  </div>`;


    return  page;
}

createHTMLListHeader() {
    var maxwidth = this.listItems.length > 0 ? 'max-width:0.1%;':'';
    return '<tr style="width:100%">'+this.createColHeader('證號','PatientNo',46,'left')+this.createColHeader('姓名','PatientName',53,'left')
    +this.createColHeader('日期','Date',46,'left')+this.createColHeader('身','IcodeName',34,'left')
    +this.createColHeader('卡序','Isqno',28,'left')+this.createColHeader('段','TimeSecName',14,'left')
    +this.createColHeader('狀','StatusWord',34,'left')+this.createColHeader('調','DispTp',14,'left')
    +this.createColHeader('TP','SameTreat',16,'left')+this.createColHeader('櫃檯員','DUTYCreater',48,'left')
    +this.createColHeader('診','RoomCode',14,'left')+this.createColHeader('醫','DoctorName',53,'left')
    //費用區 靠右
    +this.createColHeader('掛號費','FeeReg',43,'right')+this.createColHeader('自付費','APartOPD',46,'right')
    +this.createColHeader('自費','AOwn',50,'right')+this.createColHeader('押金','FeeLAMT',48,'right')
    +this.createColHeader('藥自付','APartDrug',46,'right')+this.createColHeader('檢自付	','APartExam',46,'right')
    +this.createColHeader('欠款','Debt',48,'right')+this.createColHeader('還款','Repay',48,'right')
    +this.createColHeader('小計','Received',49,'right')+this.createColHeader('掛帳費','FeeTotal',47,'right')
    +this.createColHeader('','space',1,'left')+this.createColHeader('說明','Remark',69,'left')
    +'<tr>';
}
createColHeader(cName:string,tName:string,width:number,tAlign:string){
   const fontInitSize = 9.5;
   let  actualWitdh =  width;
   if ((tName  == 'PatientName' || tName == 'DoctorName' || tName == 'DUTYCreater' ) && this.fontsize > fontInitSize) actualWitdh = actualWitdh *1.08

   if (this.hidecolArray.includes(tName)) return ''
   else return ` <th style="text-align: ${tAlign};width:${actualWitdh}pt;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    ${cName}
    </th>`
}

createSumListHeader() {
    var additionstr = `<th style="text-align: right;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    掛號費
    </th>
    <th style="text-align: right;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    自付費
    </th>
    <th style="text-align: right;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    自費額
    </th>
    <th style="text-align: right;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    淨押金
    </th>
    <th style="text-align: right;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    欠還藥
    </th>
    <th style="text-align: right;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    檢自付
    </th>
    <th style="text-align: right;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    小計
    </th>
    </tr>`
    let addStr = this.isHomeCare ? additionstr :'';
    return `<tr>
    <th style="text-align: left;max-width: 3rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    身分
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 3rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    人數
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    初診
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    複診
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    欠卡
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    還卡
    </th>`
    +
    addStr;
}
stringFactory(input:string) :string{
    return !NullOrEmpty(input)?input.trimRight() :' ';
}
stringLengthFactory(src: string,maxlength :number,symbol:string): string {
    if (src == null || src == '' || src == undefined) return ''      // 使用正規表達式匹配中文字符
      const chineseCharacters = src.match(/[\u4e00-\u9fa5]/g);
      const fullWidthPattern = /[\uFF01-\uFF60\uFFE0-\uFFE6]/g;

      let initlength = 0;
      // 如果沒有中文字符，則返回整個字串的長度
      for (let ind = 0;ind <src.length; ind++) {
        let chineseAlpha = src[ind].match(/[\u4e00-\u9fa5]/g);
        let fullWidthAlpha = src[ind].match(/[\uFF01-\uFF60\uFFE0-\uFFE6]/g);
        if (chineseAlpha) initlength +=2;
        else if (fullWidthAlpha) initlength +=2;
        else initlength +=1;
        if (initlength >= maxlength){
          return src.substr(0, ind) + symbol;
        }
      }
    return src;

}
numberFactory(input:number):string{
    let result = '';
    if(!isNaN(input)){
        result = input > 0 ? input.toString():'0';
    }
    let paddedString = this.padToLength(result, 4, " ");
    return paddedString;
}
numberFeeFactory(input:number):string{
    let result = '';
    if(!isNaN(input)){
        result = input != 0 ? input.toString():'0';
    }
    let paddedString = this.padToLength(result, 4, " ");
    return paddedString;
}
padToLength(inputString: string, targetLength: number, paddingChar: string): string {
    const paddingLength = targetLength - inputString.length;
    if (paddingLength > 0) {
        return inputString + paddingChar.repeat(paddingLength);
    } else {
        return inputString;
    }
}


stringBFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length >= 4 ? str.slice(0, 4) + '..':str;
    return inputSlice;
}
stringCFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length > 7 ? str.slice(-7):str;
    return inputSlice;
}
stringTimeZone(input:string) :string{
    let result = !NullOrEmpty(input)?input.trimRight() :' ';
    switch(input){
        case'早上':
        result = '早';
        break;
        case'下午':
        result = '午';
        break;
        case'晚上':
        result = '晚';
        break;
        default:
        result = ' ';
        break;
    }
    return result;
}

createSumBlock(data: DailyReportIcodeSum) {
    var summaryStyle = "border-top: none;";
    var icode = this.stringBFactory(data.Icode);
    var people = this.numberFactory(data.People);
    var firstvisit = this.numberFactory(data.NumberOfFirstVisitPeople);
    var moreonevisit = this.numberFactory(data.NumberOfRevisitPeople);
    var cardarrear = this.numberFactory(data.NumberOfType3People);
    var recard = this.numberFactory(data.NumberOfType3RecardPeople);

    var feeReg = this.numberFeeFactory(data.FeeReg);
    var feeApartOpd = this.numberFeeFactory(data.APartOPD);
    var ownFee = this.numberFeeFactory(data.FeeOwn);
    var deposit =  this.numberFeeFactory(data.Deposit);
    var feeAPartDrug = this.numberFeeFactory(data.APartDrug);
    var feeAPartExam = this.numberFeeFactory(data.APartExam);
    var subSum =  this.numberFeeFactory(data.SubSum);
    let additionstr =
    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeReg + '</td>' +
    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeApartOpd + '</td>' +
    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + ownFee + '</td>' +
    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + deposit + '</td>' +
    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeAPartDrug + '</td>' +
    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeAPartExam + '</td>' +
    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + subSum + '</td>' ;
    let addStr = this.isHomeCare ? additionstr :'';
    return  '<tr>' +
                '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + icode + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + people + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + firstvisit + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + moreonevisit + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + cardarrear + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + recard + '</td>' +
                addStr +
            '</tr>' ;
}
createHTMLBlock(data: DailyReportItem) {
    var summaryStyle = "border-top: none;";
    var summaryFontStyle = data.IsSummary ? "font-weight: bold" : "";
    var patientNo = this.stringCFactory(data.PatientNo);
    var patientName = this.stringLengthFactory(data.PatientName,7,'‥');
    var patientWholeName = this.stringFactory(data.PatientName);

    var date = DateHelper.formatROCDate(data.RegDate);
    var icode = this.stringLengthFactory(data.IcodeName,5,'');
    var icno = this.stringFactory(data.Isqno);
    var timeSecName = this.stringTimeZone(data.TimeSecName);
    var status = this.stringFactory(data.StatusWord);
    var tp  = this.stringFactory(data.SameTreat);
    var dutyCreater  = this.stringLengthFactory(data.DUTYCreater,7,'‥');
    var dutyCreaterWholeName = this.stringFactory(data.DUTYCreater);
    var roomCode = this.stringFactory(data.RoomCode);
    var doctor = this.stringLengthFactory(data.DoctorName,7,'‥');
    var doctorWholeName = this.stringFactory(data.DoctorName);

    var feeReg = this.numberFeeFactory(data.FeeReg);

    var feeApartOpd = this.numberFeeFactory(data.APartOPD);
    var feeAPartDrug = this.numberFeeFactory(data.APartDrug);
    var ownFee = this.numberFeeFactory(data.AOwn);
    var deposit =  this.numberFeeFactory(data.FeeLAMT);
    var aPartExam =  this.numberFeeFactory(data.APartExam);
    var debt =  this.numberFeeFactory(data.Debt);
    var repay =  this.numberFeeFactory(data.Repay);

    var received =  this.numberFeeFactory(data.Received);
    var feeTotal =  this.numberFeeFactory(data.FeeTotal);
    var remark  = this.stringLengthFactory(data.Remark,9,'‥');
    var remarkExcel = this.stringFactory(data.Remark);


    var blankLine = data.PatientNo === '總計' ? '<tr><td colspan="24" style="border-right: none; border-left: none; border-bottom: none; border-top: none;"></td></tr>' : '';
    var subLine = data.PatientNo === '小計' ? '<tr><td colspan="2"><td colspan="20" style="border-right: none; border-left: none; border-bottom: 1.5px double black; border-top: none;"></td></tr>' : '';
    var isublinestyle = data.PatientNo === '小計'|| data.PatientNo === '總計' ? 'right':'left';
    return blankLine + '<tr>' + this.createColBlock(patientNo,'PatientNo','left')  + this.createColBlock(patientName,'PatientName',isublinestyle) + this.createColBlock(patientWholeName,'PatientName',isublinestyle,true)
    + this.createColBlock(date,'Date','left')  + this.createColBlock(icode,'IcodeName','left')
    + this.createColBlock(icno,'Isqno','left')  + this.createColBlock(timeSecName,'TimeSecName','left')
    + this.createColBlock(status,'StatusWord','left') + this.createColBlock(' ','DispTp','left')
    + this.createColBlock(tp,'SameTreat','left') + this.createColBlock(dutyCreater,'DUTYCreater','left')  + this.createColBlock(dutyCreaterWholeName,'DUTYCreater','left',true)
    + this.createColBlock(roomCode,'RoomCode','left') + this.createColBlock(doctor,'DoctorName','left') + this.createColBlock(doctorWholeName,'DoctorName','left',true)
    + this.createColBlock(feeReg,'FeeReg','right')  + this.createColBlock(feeApartOpd,'APartOPD','right')
    + this.createColBlock(ownFee,'AOwn','right') + this.createColBlock(deposit,'FeeLAMT','right')
    + this.createColBlock(feeAPartDrug,'APartDrug','right') + this.createColBlock(aPartExam,'APartExam','right')
    + this.createColBlock(debt,'Debt','right')+ this.createColBlock(repay,'Repay','right')
    + this.createColBlock(received,'Received','right')+ this.createColBlock(feeTotal,'FeeTotal','right')
    + this.createColBlock('','','right') + this.createColBlock(remark,'Remark','left')  + this.createColBlock(remarkExcel,'Remark','left',true)
            +'</tr>'
            +subLine ;
}
createColBlock(value:string,tName:string,align:string,isExcel = false){
    if (this.hidecolArray.includes(tName)) return ''
    var remark = (tName == 'Remark' || tName ==  'PatientName' || tName ==  'DUTYCreater' || tName == 'DoctorName') ? isExcel ? 'excelver' :'htmlver':'';
    return `<td  ${remark} style="text-align: ${align};border-top: none;border-bottom: none;" class="excel-format-text">${value}</td>`
}
createHTMLPageHeader(iPage) {
    return `<div id="header" style="margin: 0px 2px;">
                <div style="display: flex;column-gap: 5px;">
                    <table style="width: 100%; border: none;">
                        <tr>
                            <td colspan="6" style="height: 30px; text-align: center;font-family:標楷體; font-weight: bolder; font-size: 17.5pt;font-weight: 600; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                結帳報表
                            </td>
                        </tr>
                        <tr>
                            <td  style="font-size: 15.5px;width: 40%;font-family:標楷體;font-weight: 600; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            列印時間： ` + this.nowStr + `
                            </td>
                            <td  style="font-size: 15.5px;width: 30%;font-family:標楷體;font-weight: 600; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            區間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                            </td>
                            <td colspan="1" style="font-size: 15.5px;width: 30%;font-family:標楷體;font-weight: 600; text-align: right; border-right: none; border-left: none; border-bottom: none; border: none;">
                            頁 ` + (iPage + 1) + `
                            </td>
                        </tr>
                         <tr>
                            <td colspan="6" style="font-size: 15.5px;font-family:標楷體;font-weight: 600; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            ` + this.condStr + `
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px;">
                <div style="display: flex;column-gap: 1px;">
                <table id="table-` + iPage + `-content" style="width: 100%;font-family:標楷體; font-size: ${this.fontsize}pt; border: none;">
                </table>
                </div>
            </div>`;
}
createHTMLPageFirstSumHeader(iPage){
    return `<br />
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;zoom: 0.8;margin: 0px 2px;">
                <div style="display: flex;column-gap: 1px;">
                    <table id="table-` + iPage + `-sum" style="width: 100%;font-family:標楷體; font-size: ${this.fontsize}pt; border: none;">
                    </table>
                </div>
            </div>`;
}


createHTMLPageSumHeader(iPage) {
    return `<div id="header" style="margin: 0px 2px;">
                <div style="display: flex;column-gap: 5px;">
                    <table style="width: 100%; border: none;">
                        <tr>
                            <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder;font-family:標楷體; font-size: 17.5pt;font-weight: 600; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                結帳報表
                            </td>
                        </tr>
                        <tr>
                            <td  style="font-family:標楷體;width: 40%; font-size: 15.5px;font-weight: 600; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            列印時間 ` + this.nowStr + `
                            </td>
                            <td  style="font-family:標楷體;width: 30%; font-size: 15.5px;font-weight: 600; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            區間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                            </td>
                            <td  ;font-family:標楷體;width: 30%; font-size: 15.5px;font-weight: 600; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            頁 ` + (iPage + 1) + `
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px;">
                <div style="display: flex;column-gap: 1px;">

                    <table id="table-` + iPage + `-sum" style="width: 100%;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600; border: none;">
                    </table>
                </div>
            </div>`;
}
createHTMLPageNoHeader(iPage) {
    return `<div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px; text-align: center;">
            <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px;">
                <div style="display: flex;column-gap: 1px;">
                    <table id="table-` + iPage + `-content" style="width: 100%;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600; border: none;">
                    </table>
                </div>
            </div>`;
}
createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px; text-align: center; border-top: 5px double black;padding-top: 5px">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
}
createExcelHeader(iPage) {
    return `<div id="header" style="margin: 0px 2px;">
                <div style="display: flex;column-gap: 5px;">
                    <table style="width: 100%; border: none;">
                        <tr>
                            <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder;font-family:標楷體; font-size: 17.5pt;font-weight: 600; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                結帳報表
                            </td>
                        </tr>
                        <tr>
                            <td   style="font-family:標楷體;width: 40%; font-size: 15.5px;font-weight: 600; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            列印時間： ` + this.nowStr + `
                            </td>
                            <td  style="font-family:標楷體;width: 30%; font-size: 15.5px;font-weight: 600; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            區間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                            </td>
                            <td  ;font-family:標楷體;width: 30%; font-size: 15.5px;font-weight: 600; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            頁 ` + (iPage + 1) + `
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none;font-family:標楷體; font-size: ${this.fontsize}pt;font-weight: 600;zoom: 0.8;margin: 0px 2px;">
                <div style="display: flex;column-gap: 1px;">
                <table id="table-` + iPage + `-content" style="width: 100%;font-family:標楷體; font-size: ${this.fontsize}pt; border: none;">
                </table>
                </div>
            </div>`;
}

getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
}
doexcel(){
        let htmla = this.reportContainer.nativeElement.innerHTML;
        htmla = htmla.replace(/<td[^>]*\bhtmlver\b[^>]*>.*?<\/td>/g, '');

        this.excelstr = htmla;
        let htmlreport = this.reportContainer.nativeElement.innerHTML;
        htmlreport = htmlreport.replace(/<td[^>]*\bexcelver\b[^>]*>.*?<\/td>/g, '');

    this.reportContainer.nativeElement.innerHTML = htmlreport;
  }

async onPrint() {
    if (this.listItems.length > 0) {
        var reportName = '結帳報表';
        const printContents = this.getHtml();
        var ret = await this.localPrintService.printHtml(444, '結帳報表', printContents);
        if (ret.Successed) {
            this.notification.showSuccess(reportName + '已送出列印!', true);
        } else {
            this.notification.showError(reportName + '送出列印失敗!');
        }
    } else {
        this.notification.showInfo('查無資料可供列印!');
    }
}

async exportAsExcel() {
    if (this.listItems.length > 0) {
        var reportName = '結帳報表';

        await this.hcrService.ExportTableToExcel(reportName,this.excelstr,true,true);


    } else {
        this.notification.showInfo('查無資料可供匯出!');
    }
}

/** 轉換Date To String
 *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
 */
onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
        var d = new Date(ev);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if (isMonth) {
            return [year, month].join(symbol);
        } else {
            return [year, month, day].join(symbol);
        }
    }
}

/** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
*/
onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
        var d = new Date(ev);
        // 趕時間先簡單寫
        let hr = d.getHours().toString();
        if (d.getHours() < 10) {
            hr = '0' + hr;
        }
        let min = d.getMinutes().toString();
        if (d.getMinutes() < 10) {
            min = '0' + min;
        }
        let sec = d.getSeconds().toString();
        if (d.getSeconds() < 10) {
            sec = '0' + sec;
        }
        var timeStr = [hr, min, sec].join(timeSymbol);
        return timeStr;
    }
}

onGetDate(ev) {
    if (ev) {
        var d = new Date(ev);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day].join('');
    }
}
}
