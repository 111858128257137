import { Injectable } from "@angular/core";
import { PageData, PageData2 } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { QueryParam } from "../queryParam";
import { RxListDto } from "./rx-list-dto";
import { Rx, RxListSimpleDto, RxListWithRxDosageDto } from "./rx";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { SRx } from "src/app/opd/order/models/srx";
import { OrderModifyListDto, RxModifySaveDto } from "./rx-modify-dto";

export class RxQueryParam extends QueryParam {
  code: string;
}

export class RxEditOption {
  dosages: ValueTextPair[];
  orderTypes: ValueTextPairNumberValue[];
  queryOrderTypes: ValueTextPair[];
  payment: ValueTextPair[];
  doseForm: ValueTextPair[];
  dispTPList: ValueTextPair[];
  p79: string;
  p277: string;
};

export class PsychiatryOrderListDto {
  RxCode: string;
  StdCode: string;
  ProdName: string;
  IPrice: number;
  PriceStartDate: Date;
  PriceEndDate: Date;
  LastUsingDate: Date;
}


export class PsychSummaryDto {
  SumToday: number;
  QtyToday: number;
  DrugFeeToday: number;
  QtyA2Today: number;
  QtyA8Today: number;
  SumMonth: number;
  QtyMonth: number;
  DrugFeeMonth: number;
  QtyA2Month: number;
  QtyA8Month: number;
}

export class GetRxListWithoutLabItemQueryParam extends QueryParam {
  rxCode?: string = "";
  stdCode?: string = "";
  prodName?: string = "";
}
export class GetRxDossageCopyListQueryParam extends QueryParam {
  ordertype?:string = "";
  ordercode?: string = "";
  stdCode?: string = "";
  ordername?: string = "";
}

export class OrderListDto {
  Id: number;
  OrderCode: string;
  OrderName: string ;
  OrderType: number;
  OrderTypeName: string;
  ManufacturerName?: string;
  // RxCode: any;
  // PatientName: any;
  // CId: any;
  // ProdName: any;
  // Type: any;
}


@Injectable({ providedIn: 'root' })
export class RxApi {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = apiClient.createHisService('opd/rx');
    this.apiService.enableLoader = false;
  }

  async GetRealDrugAll(query: RxQueryParam): Promise<PageData2<RxListDto>> {
    return this.apiService.get(`GetRealDrugAll`, query).toPromise<PageData2<RxListDto>>();
  }

  async GetRealDrugATCList(query: RxQueryParam): Promise<PageData2<RxListDto>> {
    return this.apiService.get(`GetRealDrugATCList`, query).toPromise<PageData2<RxListDto>>();
  }

  async GetAllergyGroupName(query: RxQueryParam): Promise<PageData2<RxListDto>> {
    return this.apiService.get(`GetAllergyGroupName`, query).toPromise<PageData2<RxListDto>>();
  }

  async GetAllergyGroupList(query: RxQueryParam): Promise<PageData2<RxListDto>> {
    return this.apiService.get(`GetAllergyGroupList`, query).toPromise<PageData2<RxListDto>>();
  }

  async BatchUpdate(clinicId: number, connectionId: string): Promise<any> {
    return await this.apiService.get(`BatchUpdate`, { clinicId: clinicId, connectionId: connectionId }).toPromise();
  }

  async GetRxATCListByCode(codes: string[]): Promise<string[]> {
    return await this.apiService.post(`GetRxATCListByCode`, codes).toPromise();
  }

  async GetRxAllergyGroupByCode(codes: string[]): Promise<string[]> {
    return await this.apiService.post(`GetRxAllergyGroupByCode`, codes).toPromise();
  }

  async CreateChgLog(rxId: number): Promise<boolean> {
    return await this.apiService.post(`CreateChgLog`, rxId).toPromise();
  }

  async GetByStdcode(code: string, rxCode: string): Promise<Rx> {
    return await this.apiService.get(`GetByStdcode`, { stdCode: code, rxCode: rxCode }).toPromise<Rx>();
  }

  async GetIdByCode(code: string): Promise<number> {
    return await this.apiService.get(`GetIdByCode`, { rxCode: code }).toPromise();
  }

  // 取得最終替代藥(考慮到替代再替代，由Web API檢查取得最終替代藥回傳)
  async GetAlternativeRx(id: number): Promise<Rx> {
    return await this.apiService.get('GetAlternativeRx/' + id).toPromise<Rx>();
  }

  async GetRxByCode(rxCode: string): Promise<Rx> {
    return await this.apiService.get(`GetRxByCode?code=${rxCode}`).toPromise<Rx>();
  }

  async GetRxById(id: number): Promise<Rx> {
    return await this.apiService.get('get/' + id).toPromise<Rx>();
  }

  async GetEditOption(): Promise<RxEditOption> {
    return await this.apiService.get('getEditOptions').toPromise<RxEditOption>();
  }

  async GetPsychiatryRxList(patId: number, hisId: number,regDate:Date): Promise<PsychiatryOrderListDto[]> {
    return await this.apiService.put('GetPsychiatryRxList', {
      patId: patId,
      hisId: hisId,
      regDate }).toPromise<PsychiatryOrderListDto[]>();
  }

  async GetPsychSummary(regDate: string, drCId: string, hisId: number): Promise<PsychSummaryDto> {
    return await this.apiService.get('GetPsychSummary', { regDate: regDate, drCId: drCId, hisId: hisId }).toPromise<PsychSummaryDto>();
    //return data;
  }

  async GetRxDossageCopyList(query: GetRxDossageCopyListQueryParam): Promise<PageData2<RxListWithRxDosageDto>> {
    return await this.apiService.get('GetRxDossageCopyList', query).toPromise<PageData2<RxListWithRxDosageDto>>();
  }
  async CopyRxDosage(fromId:number,toId:number):Promise<boolean>{
    return await this.apiService.post('CopyRxDosage?rxId='+fromId+'&toId='+toId,{}).toPromise<boolean>();
  }
  async GetRxListWithoutLabItem(query: GetRxListWithoutLabItemQueryParam): Promise<PageData2<RxListSimpleDto>> {
    return await this.apiService.get('GetRxListWithoutLabItem', query).toPromise<PageData2<RxListSimpleDto>>();
  }

  async GetRxReport(startRxWith: string, endRxWith: string, rxType: number): Promise<OrderListDto[]> {
    
    return await this.apiService.get('GetRxReport', { startRxWith: startRxWith, endRxWith: endRxWith, rxType: rxType}).toPromise<OrderListDto[]>();
  }
  async GetRxModfiyList(orderCode: string, orderName: string, orderType: number): Promise<OrderModifyListDto[]> {
    
    return await this.apiService.get('GetRxModfiyList', { orderCode: orderCode, orderName: orderName, orderType: orderType}).toPromise<OrderModifyListDto[]>();
  }
  async SaveRxModfiyList(saveModel:RxModifySaveDto): Promise<OrderModifyListDto[]> {
    
    return await this.apiService.put('SaveRxModfiyList', saveModel).toPromise<OrderModifyListDto[]>();
  }

}
