<div class="row narrow-padding"
  [ngClass]="isDialogOpened?'':'patients-list more-small'"
  style="height: calc(100% - 25px);">
  <div [class]="listColClass">
    <div [hidden]="isShow()" i18n="@@PleaseWaitASecondForPreparingData.">
      資料準備中,請稍等.
    </div>
    <div class="card border-0" [hidden]="!isShow()" [attr.disabled]="allDisabled">
      <div class="card-header">
        <div class="c-form-inline" [formGroup]="queryFormGroup">
          <app-search-bar [Cols]="this.mode==patientListMode.search?3:2">
            <input type="tel" class="vis-input" formControlName="PatientNo" autofocus searchBarField [fieldTitle]="'病歷號'" maxlength="10" (input)="NumberInput($event, 'PatientNo')" />
            <input class="vis-input" formControlName="Name" searchBarField [fieldTitle]="'姓名'" class="text-uppercase" (input)="handleInputChange()" [(ngModel)]="inputValue"/>
            <input class="vis-input" formControlName="CId"  searchBarField [fieldTitle]="'身分證'" (input)="CIdInput($event)" maxlength="10" />
            <app-vis-datesearchdatepicker (DateSearch)="receiveDateSearch($event)" formControlName="Birthday" [maxWidth]="false" searchBarField [fieldTitle]="'生日'"></app-vis-datesearchdatepicker>
            <input type="tel" class="vis-input" formControlName="PhoneNo" searchBarField [fieldTitle]="'電話'" maxlength="10" (input)="NumberInput($event, 'PhoneNo')" />
            <input type="tel" class="vis-input" formControlName="Mobile"  searchBarField [fieldTitle]="'手機'" maxlength="10" (input)="NumberInput($event, 'Mobile')" />
            <!-- <input class="vis-input" formControlName="Address" searchBarField [fieldTitle]="'地址'"/> -->
            <button type="button"
              (click)="onBtnAddClick()"
              *ngIf="mode===patientListMode.list"
              i18n="@@Add"
              class="vis-btn-config p-0 position-relative w-18"
              title="新增">
              <mat-icon class="pref-icon add vis-combine-icon pl-2">add_circle</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">新增</span>
            </button>
            <button type="button"
              (click)="onBtnQueryClearClick()"
              i18n="@@Clear"
              class="vis-btn-config p-0 position-relative w-18"
              title="清除">
              <mat-icon class="pref-icon remove vis-combine-icon pl-2">remove_circle</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">清除</span>
            </button>
            <button type="submit"
              (click)="onQueryClick()"
              i18n="@@Query"
              class="vis-btn-config p-0 position-relative w-18"
              title="查詢">
              <mat-icon class="vis-combine-icon pl-2">search</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
            </button>
          </app-search-bar>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="inside-scrollbar p-0">
        <app-vis-grid [data]="gridView" [attr.disabled]="allDisabled" [pageSize]="pageSize" [skip]="pageSkipNo"
          [pageable]="true" (pageChange)="pageChange($event)"
          (selectionChange)="patientSelectChange($event)"
          [navigable]="true" [selectable]="true" [kendoGridSelectBy]="'Id'"
          [selectedKeys]="selectedItems" (cellClick)="gridCellClickHandler($event)" (dblclick)="rowExcuteHandler($event)" class="no-border">
          <!-- <app-vis-grid-checkbox-column field="Id" title="選取" [width]="70" ></app-vis-grid-checkbox-column> -->
          <app-vis-grid-column field="PatientNo" title="病歷號" [width]="80" [CellStyle]="{direction:'rtl'}" i18n-title="@@PatientNo" class="sun-good-spacing"></app-vis-grid-column>
          <app-vis-grid-column field="Name" title="姓名" [width]="80" i18n-title="@@Name"></app-vis-grid-column>
          <app-vis-grid-column field="Birthday" title="生日" [width]="80" i18n-title="@@Birthday" class="text-center">
            <ng-template let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.Birthday | formatDate:''">{{ dataItem.Birthday | formatDate:'' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="CId" title="身分證" [width]="80" i18n-title="@@Birthday" class="sun-good-spacing"></app-vis-grid-column>
          <app-vis-grid-column field="Mobile" title="手機(電話)" [width]="80" i18n-title="@@Birthday" class="sun-good-spacing">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.Mobile||dataItem.PhoneNo||''" class="ellipsis-content">{{ dataItem.Mobile||dataItem.PhoneNo }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="LastDate" title="最後來診日" [width]="80" *ngIf="mode===patientListMode.search" i18n-title="@@LastDate"  class="text-center">
            <ng-template let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.LastDate | formatDate:''">{{ dataItem.LastDate | formatDate:'' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="Address" title="地址" [width]="isDialogOpened?340:100" *ngIf="mode===patientListMode.search" i18n-title="@@Address">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [title]="dataItem.Address" class="ellipsis-content">{{dataItem?.City}}{{dataItem?.Area}}{{dataItem.Street}}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column title="" *ngIf="mode===patientListMode.list" i18n-title="@@Function" class="text-center custom-m-auto p-0" [width]="70">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button type="button" class="icon-btn" (click)="onEditClick($event, dataItem)" title="編輯" i18n-title="@@Edit"><mat-icon>edit</mat-icon></button>
              <button type="button" class="icon-btn" (click)="onBtnRegisterClick(dataItem)" title="掛號" i18n-title="@@Register"><mat-icon>how_to_reg</mat-icon></button>
            </ng-template>
          </app-vis-grid-column>
        </app-vis-grid>
      </div>
    </div>
  </div>
  <div [class]="editColClass" style="height: 100%;" *ngIf="mode===patientListMode.list">
    <app-patients-edit (onUpdated)="onEditUpdated()" [patientId]="selectedPatientId" (emitEnterEdit)="onChildEnterEdit()"
      (emitEnterView)="onChildEnterView()" (parentQuery)="getQueryForSave()" [parentQueryData]="toChildData">
    </app-patients-edit>
  </div>
</div>
