import { Injectable } from "@angular/core";

import { NewBornCreateDto, NewbornViewDto, PatientsDto } from "./patientsDto";
import { DateHelper } from "../../../shared/helpers/date-helper";
import { PageData, PageData2 } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { PatientEditOption } from "./patient-edit-option";
import { ContactDto } from "./contactDto";
import { RegisterEditDto } from "../register/RegisterEditDto";
import { isNullOrUndefined, onGetDateString } from "src/app/shared/utilities";
import { SexEnum } from "src/app/enums/SexEnum";

export class BasicPatientDataQuery {
  ClinicId: number;
  PatientNo: number;
  Name: string;
  Birthday: Date | string;
  PhoneNo: string;
  Mobile: string;
  CId: string;
  FullAddress: string;
  PageNumber: number;
  PageSize: number;
  SearchType: string;
  Regdate: Date | string;
  Sex: number;
};

export class GetAllQuery {
  Birthday: Date | string;
  PatientNo: string;
  Name: string;
  PhoneNo: string;
  Mobile: string;
  CId: string;
  Address: string;
  SearchType: string;
  PatientId: number;
}
export class PatientPresDto {
  PreDate: string;
  PreTimes: number;
  PreTotal: number
  Days: number
  DxCode: string;
  DxName: string;
  ICNo: string;
}
export class PatientListDto{
    Id: number;
    PatientNo: string;
    LastName: string;
    FirstName: string;
    MiddleName: string;
    PhoneNo: string;
    Mobile: string;
    Birthday: Date;
    CId: string;
    City: string;
    State: string;
    Area: string;
    Street: string;
    FullName: string;
    Address: string;
    Name: string;
    PaySysA: string;
    Sex: SexEnum;
    RegDate?: Date;
    DiscountId?:number;
    ICode?: string;
    ICno: string;
    IType?: string;
    People?: number;
    RegDates? : Date[];
    FirstDate?: Date;
    RoomCode?: string;
    DxName?: string;
    ZipCode?: string;
    times?: number;
    DiscountCode?: string;
    NeedToCheckCName?: boolean;
    Age:number;
    LastDate?: Date;
}

export class PatientResult {
  Data: PageData2<PatientListDto>;
  RowNumber: number;
}
@Injectable({ providedIn: 'root' })
export class PatientApi {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = this.apiClient.createHisService('patients/patient');
  }

  Create(model: PatientsDto) {
    model.Birthday = onGetDateString(model.Birthday);
    return this.apiService.post(`Create`, model).toPromise<PatientsDto>();
  }

  CreateContact(model: ContactDto) {
    return this.apiService.post(`CreateContact`, model).toPromise<ContactDto>();
  }

  Get(id: number) {
    return this.apiService.get(`Get/${id}`).toPromise<PatientsDto>();
  }

  CheckExist(cId: string) {
    return this.apiService.get(`CheckExist/${cId}`).toPromise<number>();
  }

  // 檢查登入之診所是否有此病歷
  CheckExistPatient(cId: string) {
    return this.apiService.get(`CheckExistPatient/${cId}`).toPromise<number>();
  }

  // 列印病歷首頁
  getPatientForPrint(cId: string) {
    return this.apiService.get(`GetPatientForPrint/${cId}`).toPromise<number>();
  }

  GetRand(): Promise<PatientsDto> {
    return this.apiService.get(`GetRand`).toPromise<PatientsDto>();
  }

  GetNotRand(cid: string): Promise<PatientsDto> {
    return this.apiService.get(`GetNotRand/${cid}`).toPromise<PatientsDto>();
  }
  GetPatientByCIdAsync(cid: string): Promise<PatientsDto> {
    return this.apiService.get(`GetPatientByCIdAsync/${cid}`).toPromise<PatientsDto>();
  }
  GetAll(query: GetAllQuery) {
    // 直接轉成ISO日期
    query.Birthday = query.Birthday ? DateHelper.getIsoStringNoTimeZone(new Date(query.Birthday)) : "";
    return this.apiService.get(`GetAll`, query).toPromise<PatientResult>();
  }

  // GetAll(pNo: string,name: string, phone: string, mobile: string, cId: string, address: string, birth: Date|string){
  //     return this.apiService.get(`GetAll?patientNo=${pNo}&name=${name}&phoneNo=${phone}&mobile=${mobile}&cId=${cId}&fullAddress=${address}&birthday=${birth}`).toPromise<PageData>();
  // }
  GetRegister(date: Date, patientId: number) {
    const query = {
      // 當天，時間都轉換為ISO格式再回傳Server
      regDate: date == null ? undefined : DateHelper.getIsoStringNoTimeZone(date),
      patientId: patientId
    };
    return this.apiService.get('GetRegList', query).toPromise<RegisterEditDto[]>();
  }

  Update(model: PatientsDto) {
    return this.apiService.put(`Update?onlyPaySysA=false`, model).toPromise<PatientsDto>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }

  UpdateNewbornNote(pid: number, cid: string, cName: string) {
    const query = {
      pid: pid,
      cid: cid,
      cName: cName
    };
    return this.apiService.get('UpdateNewbornNote', query).toPromise<boolean>();
  }

  UpdatePaySysA(model: PatientListDto) {
    return this.apiService.put(`Update?onlyPaySysA=true`, model).toPromise<PatientsDto>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }

  UpdatePatientName(id: number, newName: string) {
    var ret = this.apiService.put(`UpdatePatientName?id=` + id + `&newName=` + newName, null, true).toPromise();
    return ret;
  }

  UpdatePatientCheckNameFlag(id: number, needToCheck: boolean) {
    var ret = this.apiService.put(`UpdatePatientCheckNameFlag?id=` + id + `&needToCheck=` + needToCheck, null, true).toPromise();
    return ret;
  }

  GetEditOption() {
    return this.apiService.get('getEditOptions').toPromise<PatientEditOption>()
  }

  Delete(id: number) {
    return this.apiService.delete(`Delete/${id}`).toPromise<PatientsDto>();
  }

  GetPresInfo(patientId: number): Promise<PatientPresDto> {
    return this.apiService.get(`GetPresInfo?patientId=${patientId}`, {}).toPromise<PatientPresDto>();
  }

  CheckCIdDuplicated(patientId: number, cId: string): Promise<PatientPresDto> {
    return this.apiService.get(`CheckCIdDuplicated?patientId=${patientId}&cid=${cId}`, {}).toPromise<PatientPresDto>();
  }

  CheckCIdDuplicatedWithoutPatientNo(cId: string): Promise<Boolean> {
    return this.apiService.get(`CheckCIdDuplicatedWithoutPatientNo?cid=${cId}`, {}).toPromise<Boolean>();
  }

  async PreviewPatientNo(): Promise<string> {
    var ret = await this.apiService.get('PreviewPatientNo').toPromise<{ PatientNo: string }>();
    return ret.PatientNo;
  }

  async SaveNewBorn(data: NewBornCreateDto): Promise<number> {
    return this.apiService.post('SaveNewBorn', data).toPromise<number>();
  }

  async GetNewBornList(pid: number): Promise<NewbornViewDto[]> {
    return this.apiService.get('GetNewBornList?pid=' + pid).toPromise<NewbornViewDto[]>();
  }

  async GetLastSeqNo(pid: number): Promise<{ Isqno: string }> {
    return this.apiService.get('GetLastSeqNo?patientId=' + pid).toPromise<{ Isqno: string }>();
  }

  async GetListRowNumber(savePatientNo: string, query: GetAllQuery) {
    if (!query) query = new GetAllQuery();
    query.Birthday = query.Birthday ? DateHelper.getIsoStringNoTimeZone(new Date(query.Birthday)) : "";
    return this.apiService.get(`GetListRowNumber/${savePatientNo}`, query).toPromise<number>();
  }

  async GetNotifyUrl(patientId: number) {
    return this.apiService.get(`GetNotifyUrl?patientId=${patientId}`).toPromise<{ url: string, dataUrl: string }>();
  }

  async GetBasicData(startNo: string, endNo: string, sortBy: string): Promise<PatientListDto[]> {
    return this.apiService.get(`GetBasicData`, { startNo: startNo, endNo: endNo, sortBy: sortBy }).toPromise<PatientListDto[]>();
  }

  async GetByNo(patientNo: string) {
    return this.apiService.get(`GetByNo`, { patientNo: patientNo }).toPromise<PatientsDto>();
  }

  async GetFirstVisitedDates(startDateWith: string, endDateWith: string, sortBy: string): Promise<PatientListDto[]> {
    return this.apiService.get(`GetFirstVisitedDates`, { startDateWith: startDateWith, endDateWith: endDateWith, sortBy: sortBy }).toPromise<PatientListDto[]>();
  }

  async GetPatientDataByDate(startDate: string, endDate: string, roomid: string, doctorid: number): Promise<PatientListDto[]> {
    return this.apiService.get(`GetPatientDataByDate`, { startDate: startDate, endDate: endDate, roomid: roomid, doctorid: doctorid }).toPromise<PatientListDto[]>();
  }
  async GetPatientDataByDateGrouping(startDate: string, endDate: string, roomid: string, doctorid: number): Promise<PatientListDto[]> {
    return this.apiService.get(`GetPatientDataByDateGrouping`, { startDate: startDate, endDate: endDate, roomid: roomid, doctorid: doctorid }).toPromise<PatientListDto[]>();
  }
  async GetPatientDataByPreventiveCare(endDate: string , SeleAge:String): Promise<PatientListDto[]> {
    return this.apiService.get(`GetPatientDataByPreventiveCare`, {endDate: endDate, SeleAge: SeleAge}).toPromise<PatientListDto[]>();
  }
  async GetStatusFinishWithoutPrescriptionReport(startDateWith: string, endDateWith: string, RoomId: string, DoctorId: number,sortBy: string ): Promise<PatientListDto[]> {
    return this.apiService.get(`GetStatusFinishWithoutPrescriptionReport`, { startDateWith: startDateWith, endDateWith: endDateWith, RoomId: RoomId, doctorId: DoctorId, sortBy: sortBy }).toPromise<PatientListDto[]>();
  }
  //ggdfgdfg
}
