import { RxSetDto } from "src/app/services/api-service/rx/rx-set-dto";

export class MainSetView{

    constructor() {
    }
  
    // properties
    Id: number;
    Code: string;
    Name: string;
    ClinicId:number;
    UserId:number;
    MedDeptCode: string;
    CC: string;
    PE:string;
    Dx1: string;
    Dx2: string;
    Dx3: string;
    Dx4: string;
    Dx5: string;
    Dx6: string;
    Sur1:string;
    Sur2: string;
    Sur3: string;

    Details: RxSetDto[];
  
  
    Dx1Name: string;
    Dx2Name: string;
    Dx3Name: string;
    Dx4Name: string;
    Dx5Name: string;
    Dx6Name: string;
    Sur1Name:string;
    Sur2Name:string;
    Sur3Name: string;

  }
