import { Injectable } from '@angular/core';
import { WebApiService } from '../../web-api.service';
import { WebApiClient } from '../../web-api-client.service';
import { SpecialSameTreatDto } from './special-sameTreat-dto';


@Injectable({
  providedIn: 'root'
})
export class TemporaryWorkApi {
  api: WebApiService;

  constructor(private webApiFactory: WebApiClient) {
      this.api = this.webApiFactory.createHisService('ValueAdded/TemporaryWork');
  }

  async GetSpecialSameTreatAsync() {
    return await this.api.get('GetSpecialSameTreatAsync').toPromise<SpecialSameTreatDto[]>();
  }
  async UpdateSpecialSameTreatAsync(data:SpecialSameTreatDto[]) {
    return await this.api.put('UpdateSpecialSameTreatAsync',data).toPromise();
  }
}
