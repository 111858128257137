<app-vis-dialog title="請選擇要列印的項目" [superWidth]="'85%'" [maxWidth]="'85vw'" [height]="0" [show]="isOpenSelector" i18n-title="">
    <div class="inside-scrollbar p-0" >
        <app-vis-grid [data]="gridView.data" 
            [pageable]="false" 
            [navigable]="true"
            [selectable]="true"
            [multiselect]="true"
            [selectByCheckOnly]="true"
            [kendoGridSelectBy]="'Id'" 
            [selectedKeys]="selectedItems" 
            [setChangeClassBy]="'Id'" 
            [dragable]="false"
            [changeRowClass]="'nhi-declare-grid-bg'" 
            class="no-border">
            <app-vis-grid-checkbox-column showSelectAll="true" [width]="25" (checkChanged)="onCheckChanged($event)">
            </app-vis-grid-checkbox-column>
            <app-vis-grid-column field="RxCode" [sortable]="true" title="代碼" [width]="80" i18n-title="@@RxCode"></app-vis-grid-column>
            <app-vis-grid-column field="ProdName" title="名稱" [width]="120"i18n-title="@@ProdName"></app-vis-grid-column>
            <app-vis-grid-column field="Freq" title="頻率" [width]="60" i18n-title="@@Freq" class="text-center custom-m-auto"></app-vis-grid-column>
            <app-vis-grid-column field="Way" title="服法" [width]="60" i18n-title="@@Way" class="text-center custom-m-auto" ></app-vis-grid-column>
            <app-vis-grid-column field="Days" title="天數" [width]="60" i18n-title="@@Days" class="text-center custom-m-auto"></app-vis-grid-column>
            <app-vis-grid-column field="TotalDose" title="總量" [width]="80" i18n-title="@@TotalDose" class="text-center" ></app-vis-grid-column>
        </app-vis-grid>
    </div>
    <app-vis-dialog-actions>
        <button type="button" (click)="onSureClick()" i18n="@@DialogClose">確定</button>
        <button type="button" (click)="onCloseClick()" i18n="@@DialogClose">取消</button>
    </app-vis-dialog-actions>
</app-vis-dialog>