import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HcrService, PrintProcessState, PrintProcessStep, PrintProgressModel } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { Last, Or } from 'src/app/shared/utilities';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit, OnDestroy {

  showPrinter:boolean = false
  subs:Subscription;
  progresses:{printerName:string,progress:PrintProgressModel[]}[] = [];
  constructor(private hcrService:HcrService) { 
    
  }
  displayPrinterInfo:PrintProgressModel = null;
  displayCls='';
  ngOnInit(): void {
    // this.progresses.push({
    //   printerName: 'PDF',
    //   progress: [
    //     {DocName:'件1',Page:1,TotalPage:10,Step: PrintProcessStep.Initial, State:PrintProcessState.Processing,PrinterName:'PDF'},
    //     {DocName:'件2',Page:1,TotalPage:10,Step: PrintProcessStep.Initial, State:PrintProcessState.Processing,PrinterName:'PDF'}
    //   ]});
    //   this.progresses.push({
    //     printerName: 'PDF2',
    //     progress: [
    //       {DocName:'件A',Page:1,TotalPage:10,Step: PrintProcessStep.Initial, State:PrintProcessState.Processing,PrinterName:'PDF2'}
    //     ]});
    
    this.subs = this.hcrService.onPrinterProgress.subscribe(a=>{
      if(this.progresses.some(x=>x.printerName ==a.PrinterName)){
        var ps = this.progresses.find(x=>x.printerName == a.PrinterName);
        ps.progress.push(a);
      }else{
        this.progresses.push({
          printerName: a.PrinterName,
          progress: [a]
        });
      }
      if(!this.displayPrinterInfo || this.displayPrinterInfo.PrinterName == a.PrinterName){
        this.displayPrinterInfo = a;
      }
    });
    this.updateDisplay();
  }

  updateDisplay(){
    setTimeout(() => {
      this.displayCls = '';
      var prev = this.displayPrinterInfo;
      if(this.progresses.length > 0){
        if(!this.displayPrinterInfo){
          this.displayPrinterInfo = this.progresses[0].progress[0];
        }else{
          var ind = this.progresses.findIndex(x=>x.printerName == this.displayPrinterInfo.PrinterName);
          console.log(ind,this.progresses[ind+1])
          var p = this.progresses[0];
          if(this.progresses[ind+1]){
            p = this.progresses[ind+1];
          }
          this.displayPrinterInfo = Last(p.progress);

          if(this.displayPrinterInfo.Step == PrintProcessStep.Complete){
            var wait = new PrintProgressModel(this.displayPrinterInfo);
            wait.Step = PrintProcessStep.Waiting;
            p.progress.push(wait);
          }
        } 
        if(prev.PrinterName!=this.displayPrinterInfo.PrinterName) {
          setTimeout(() => {
            this.displayCls='rotate-out'
          }, 0);
        }
        
      }
      //console.log(this.displayPrinterInfo)
      
      this.updateDisplay();
    }, 5000);
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
