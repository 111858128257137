import { Component, OnInit, Input, TemplateRef, ContentChild, Output, EventEmitter } from '@angular/core';
import { ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { DayCell } from '../model/day-cell';

@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.css']
})
export class CalendarMonthComponent implements OnInit {
  @ContentChild(TemplateRef, { static: true }) templateRef: TemplateRef<any>;
  @ContentChild('controlBar',  { static: false })
  templateControlBar: TemplateRef<any>;
  @Input('yearStart') yearStart: number;
  @Input('yearEnd') yearEnd: number;
  // @Output('data') @Input('data') data: any[] = [];
  @Output() dateEvent = new EventEmitter<any>();

  @Input('selectedYear')
  selectedYear: number;
  selectedYearItem: number;

  @Input('selectedMonth')
  selectedMonth: number;
  selectedMonthItem = 0;

  @Input()
  week: ValueTextPairNumberValue[];

  years: number[] = [];
  months: number[] = [];
  _allowMonth: any[] = [];

  initialed = false;
  initialData: any[];
  @Input()
  set allowMonth(allowMonth: string[]) {
    if (!allowMonth || this._allowMonth.length > 0) {
      return;
    }
    this._allowMonth = [];
    this.years = [];
    allowMonth.forEach(m => {
      const ym = m.split('/');
      const allowMonthItem = {
        year: parseInt(ym[0]),
        month: parseInt(ym[1])
      };
      this._allowMonth.push(allowMonthItem);
      if (this.years.indexOf(allowMonthItem.year) < 0) {
        this.years.push(allowMonthItem.year);
      }
    });

    this.selectedYearItem = this.selectedYear;
    this.genMonthSelectItems(this.selectedYearItem);
    this.genDayCell();
    if (!this.initialed) {
      this.initialed = true;
      this.bindData(this.initialData);
    }
  }
  weekNum = [0, 1, 2, 3, 4];

  firstDayOfTheMonth: Date;
  dayCell: DayCell<ICellData>[];
  constructor() {
  }
  ngOnInit() {
    this.dayCell = new Array(7 * this.weekNum.length);
    const nowDate = new Date();
  }

  public getCellWeekSlice(week: number): DayCell<any>[] {
    const result: DayCell<any>[] = [];
    for (let i = week * 7; i < (week + 1) * 7; i++) {
      result.push(this.dayCell[i]);
    }
    if (week === 0 && result[0] && result[0].DisplayTitle.indexOf('/') < 0) {
      result[0].DisplayTitle = this.selectedMonth.toString() + '/' + result[0].Date.getDate().toString();
    }
    return result;
  }
  public bindData(data: ICellData[]) {
    if (!this.initialed) {
      this.initialData = data;
      return;
    }
    this.dayCell.forEach((cell) => {
      const year = cell.Date.getFullYear();
      const month = cell.Date.getMonth() + 1;
      const day = cell.Date.getDate();
      const s = data.filter(item => {
        if (item.Year === year && item.Month === month && item.Day === day) {
          // console.log(item.Year + ' ' + item.Month + ' ' + item.Day + '  ' + year + ' ' + month + ' ' + day);
          return true;
        }
      });
      cell.Data = s;
    });
  }

  private genDayCell() {
    this.firstDayOfTheMonth = new Date(this.selectedYearItem, this.selectedMonthItem - 1, 1);
    const ignoreDayCell = this.firstDayOfTheMonth.getDay() || 7;
    const daysOfTheMonth = new Date(this.selectedYearItem, this.selectedMonthItem, 0).getDate();
    if((daysOfTheMonth - (7 - ignoreDayCell)) > 28) {
      this.weekNum = [0, 1, 2, 3, 4, 5];
    } else {
      this.weekNum = [0, 1, 2, 3, 4];
    }

    this.dayCell = new Array(7 * this.week.length);
    for (let i = 0; i < daysOfTheMonth; i++) {
      const cell = new DayCell<ICellData>();
      const year = this.firstDayOfTheMonth.getFullYear();
      const month = this.firstDayOfTheMonth.getMonth();
      const day = this.firstDayOfTheMonth.getDate();
      cell.Date = new Date(year, month, day);
      cell.DisplayTitle = this.showDate(cell);
      this.dayCell[ignoreDayCell - 1 + i] = cell;
      this.firstDayOfTheMonth.setDate(this.firstDayOfTheMonth.getDate() + 1);
    }
  }

  public showDate(s: DayCell<any>): string {
    if (s === undefined) {
      return '';
    }
    if (s.Date.getDate() === 1) {
      return (s.Date.getMonth() + 1).toString() + '/' + s.Date.getDate().toString();
    }
    return s.Date.getDate().toString();
  }

  public onMonthSelectChange(event: any) {
    this.genDayCell();
    this.dateEvent.emit({ year: this.selectedYearItem, month: this.selectedMonthItem });
  }

  public onYearSelectChange(event: any) {
    this.genMonthSelectItems(this.selectedYearItem);
    this.genDayCell();
    this.dateEvent.emit({ year: this.selectedYearItem, month: this.selectedMonthItem });
  }
  private genMonthSelectItems(year: Number) {
    this.months = [];
    this._allowMonth.forEach(item => {
      if (item.year == year) {
        this.months.push(item.month);
      }
    });

    // 如果尚未選擇的時候 使用@input的資料
    if (this.selectedMonthItem === 0) {
      this.selectedMonthItem = this.selectedMonth;
    }
    // 產生的MONTH選單 如果目前選項不再其中則只到第一筆
    if ( this.months.indexOf(this.selectedMonthItem) < 0) {
      this.selectedMonthItem = this.months[0];
    }
  }
}

export interface ICellData{
  Year:number;
  Month:number;
  Day:number;
}