
import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })
  export class CryptoService {
      constructor(){}
    key = 'vis1234554321siv';
    iv = 'aaaaaaaaaaaaaaaa';
    encrypt(data:string){
        const k = CryptoJS.enc.Utf8.parse(this.key);
        const p = CryptoJS.AES.encrypt(data,k,{
            iv: this.iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.ZeroPadding
        });
        return CryptoJS.enc.Base64.stringify(p.ciphertext);
    }

    /** 高雄市TOCC，呼叫翰鼎API，需產生驗證碼。 */
    async generateHMAC(code: string, data: string)
    {
      //翰鼎指定的金鑰
      var SecretKey = 'D2x9Mn5Q8V4rT1Y7Lk6Gh3S0Wj4Fp9C7';
      var result = '';
      try
      {
        const secretAccessKey = SecretKey;
        const input = code + (data ?? '');
        const secretKey = new TextEncoder().encode(secretAccessKey);
        const combineData = new TextEncoder().encode(input);
        // await crypto.subtle.importKey(
        //   'raw',
        //   secretKey,
        //   { name: 'HMAC', hash: { name: 'SHA-256' } },
        //   false,
        //   ['sign']
        // ).then(async (cryptoKey) => {
        //   await crypto.subtle.sign('HMAC', cryptoKey, combineData).then((hash) => {
        //     const uint8Array = new Uint8Array(hash);
        //     const hashString = String.fromCharCode.apply(null, uint8Array);
        //     result = btoa(hashString);
        //   });
        // });
        const cryptoKey = await crypto.subtle.importKey(
          'raw',
          secretKey,
          { name: 'HMAC', hash: { name: 'SHA-256' } },
          false,
          ['sign']
        );
        const hash = await crypto.subtle.sign('HMAC', cryptoKey, combineData);
        const uint8Array = new Uint8Array(hash);
        const hashString = String.fromCharCode.apply(null, uint8Array);
        result = btoa(hashString);
      }
      catch (ex)
      {
        result = '';
      }
      return result;
    }
  }
