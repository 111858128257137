export class NHIRegIC {
    /**
     *
     */
    constructor() {
        this.Id = null;
        this.RegId = null;
        this.PatientNo = null;
        this.UpNote = null;
        this.UpNote2 = null;
        this.Cid = null;
        this.Birthdate = null;
        this.strBirthdate = null;
        this.BabyDate = null;
        this.BirthsNum = null;
        this.BabyMark = null;
        this.IRegDT = null;
        this.Icno = null;
        this.Icdt = null;
        this.IreCard = null;
        this.Iuldt = null;
        this.Nhictp = null;
        this.Isqno = null;
        this.Ierrcd = null;
        this.Ihcd = null;
        this.DrCid = null;
        this.Isam = null;
        this.Isgn = null;
        this.Itreatnum = null;
        this.ItreatnumOri = null;
        this.IhcdOri = null;
        this.IsqnoOri = null;
        this.IcdtOri = null;
        this.BabyPatientId = null;
        this.M56Type = null;
        //this.IsSameFirst = null;
        //this.IORGNO = null;
        this.IsEnabled= false;    
    }
    Id: number = 0;
    RegId: number = 0;
    PatientNo:string;
    UpNote: string = null;
    UpNote2: string = null;
    Cid: string;
    Birthdate: Date;
    strBirthdate: string;
    BabyDate: string;
    BirthsNum: number;
    BabyMark: string;
    IRegDT: string;
    Icno: string;
    Icdt: Date|string;
    IreCard: string;
    Iuldt: Date;
    Nhictp: string;
    Isqno: string;
    Ierrcd: string;
    Ihcd: string;
    Ihcd43: string;
    Ihcd52: string;
    DrCid: string;
    Isam: string;
    Isgn: string;
    Itreatnum: string;
    ItreatnumOri: string;
    IhcdOri: string;
    IsqnoOri: string;
    IcdtOri: string;
    BabyPatientId: number;
    M56Type :string;
    //IsSameFirst: boolean;
    //IORGNO: string;
    IsEnabled:boolean;
    EditTime:Date;
    IWriteCardTime?:Date;
}

export class fillNHIRegIC {
    RegId: number;
    nhiRegIc: NHIRegIC;
}
