import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { DxOpt, HistEditOption, RxOpt } from 'src/app/services/api-service/hist/hist-edit-option';
import { CloseCommand, FocusCommand, HelperPadCommand, HistHelperPadService, ItemSelectedEvent, PadInitEvent, AreaSelectedEvent, PadTab, SubTab, TabDataSource, CustomTab, CustomSubTab } from '../services/hist-helper-pad.service';
import { Subject } from 'rxjs';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { HistService } from '../services/hist.service';
import { RxClass } from '../models/rx-class';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { LocalStoragesService } from 'src/app/services/local-storages.service';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';
import { HelperId } from 'src/app/hist/models/helper-id';
import { UserApi, UserPreference } from 'src/app/services/api-service/user/user-api';
import { StartUpService } from 'src/app/services/start-up-service';
import { CdkDrag, CdkDragDrop, CdkDragEnd, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { EasyFormService } from 'src/app/services/easy-form-service';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { Rx } from 'src/app/services/api-service/rx/rx';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { HistApi, StatOpts } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { HelpPadApi, HelpPadGroups, HelpPadTab, HelpPadType } from 'src/app/services/api-service/hist/help-pad-api';
import { UserCache } from 'src/app/services/user-cache';
import { HelperPadComponent } from '../helper-pad/helper-pad.component';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { Compare, Or, replaceAll } from 'src/app/shared/utilities';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';

declare type GridViewItem = {
  code: string;
  name: string;
  isInPref: boolean;
  // 子類別，RxType
  type: number;
  displayCode: boolean;
  flagDescription?: string;
}
/** 輔助盤本體 */
@Component({
  selector: 'app-hist-helper-edit-pad',
  templateUrl: './helper-edit-pad.component.html',
  styleUrls: ['./helper-edit-pad.component.css']
})
export class HelperEditPadComponent implements OnInit, OnDestroy {

  @ViewChild('groupNameEdit')
  groupNameEdit:ElementRef<HTMLInputElement>
  tabs: PadTab[] = null
  constructor(private fb: FormBuilder,
    private notification: EasyNotificationService,
    private ezForm: EasyFormService,
    private userConfirm: UserConfirmService,
    private mainLayoutService: MainLayoutService,
    private padService: HistHelperPadService,
    private histApi: HistApi,
    private histService: HistService,
    private localStorage: LocalStoragesService,
    private userApi: UserApi,
    private startUp: StartUpService,
    private elementRef: ElementRef,
    private clinicData:ClinicDataService,
    private helpPadApi:HelpPadApi) {
    }
  _class:string

  //#region subscribe --------------------
  private unsubscribe: Subject<void> = new Subject();
  //#endregion

  //#region params --------------------

  get editOptions(): HistEditOption {
    return this.histService.EditOptions;
  }

  get histParams() {
    return this.editOptions.histParams;
  }
  //#region // window open flag
  _isOpened = true;
  @Input()
  set isOpened(t: boolean) {
    this._isOpened = t;
  }
  get isOpened(): boolean {
    return this._isOpened;
  }
  @Input() diagVersion: string;
  //#endregion

  winTop = 30;
  winLeft = document.body.clientWidth * 0.7125 - 5 - 12 - 20;
  winWidth = document.body.clientWidth - this.winLeft - 18;
  defaultHeight = 350;
  winHeight = this.defaultHeight;

  static windSizeStorageKey = 'padSizeAndPos';
  // data
  sourceOrderClass = '';
  listItems = [];
  filteredItems: GridViewItem[] = [];
  queryCount = 200;
  queryFG: FormGroup;
  get queryFV() {
    return this.queryFG.value;
  }

  tab:PadTab=null;

  // control
  isScheduledShowLoadingMessage = false;
  bodyHeight = document.body.clientHeight;
  padHeight:string = '';
  padEditHeight:string = '';
  displayStopDrugsFromPad: boolean = true;

  rxType = 0;
  //#endregion
  cTabs:CustomTab[];
  paymentTypeCodes:ValueTextPair[];
  async ngOnInit() {
    await this.loadStat()
    await this.loadGroup();
    this.orderTypeDD = (await this.clinicData.getEnum(['OrderType']))['OrderType'];
    this.paymentTypeCodes = (await this.clinicData.getSystemCodes(['F0010']))['F0010']
    let hst001 = await this.clinicData.getParam("HST001");
    this.displayStopDrugsFromPad = hst001.DisplayStopDrugsFromPad;
    await this.initTabs();

    this.makeQueryForm();
    this.subscribeUiEvent_Helper();
    this.padService.Pad_Initialed(new PadInitEvent());

    this.getPadHeight();

    if(this.padService.lastFocus){
      setTimeout(() => {
        this.changeTabByEventSource(this.padService.lastFocus);
      }, 0);
    }else{
      this.changeTab(this.tabs[0].id);
    }

  }

  async initTabs(){
    var allowRxType = replaceAll(this.histParams.OrderPanalTabsTypes,';',',').split(',').map(x=>parseInt(x)).filter(x=>!isNaN(x));
    //支付類別

    var rxPaymentType = this.paymentTypeCodes;
    this.tabs = [];
    this.pref = this.startUp.userData.prefs;

    if (!this.histService.IsChineseMode) {
      this.tabs.push({ name: '主訴', id:HelpPadTab.CC, dataSource:'CC', prefTab: [],subTab:[] });
    }
    this.tabs.push({name: '理學', dataSource:'PE',id:HelpPadTab.PE, prefTab: [],subTab:[]});
    this.tabs.push({ name: '診斷',dataSource:'DX',id:HelpPadTab.DX, prefTab: [],subTab:[]});
    this.tabs.push({ name: '套餐',dataSource:'MS',id:HelpPadTab.Set, prefTab: [],subTab:[]});
    if (this.histService.IsChineseMode) {
      // this.tabs.push({ name: '問', code: this.tabCode_ChiAsk,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '望', code: this.tabCode_ChiLook,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '脈', code: this.tabCode_ChiPulse,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '辨', code: this.tabCode_ChiBianZheng,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '治', code: this.tabCode_ChiTreatRule,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '中藥飲片', code: this.tabCode_CnSp,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '中成藥', code: this.tabCode_CnMed,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '針灸', code: this.tabCode_Acu, dataSource:'RX',subTab: [], prefTab:[] });
      // this.tabs.push({ name: '針灸圖', code: this.tabCode_AcuPic, dataSource:'RX',subTab: [], prefTab:[] });
      // this.tabs.push({ name: '推拿', code: this.tabCode_Tuina,dataSource:'RX', subTab: [], prefTab:[] });
      // this.tabs.push({ name: '處置', code: this.tabCode_CnTrt,dataSource:'RX', subTab: [], prefTab:[] });
    }
    //extension:true標記為預設項目
    this.tabs.push({
      name: '醫令',  prefTab:[], id:HelpPadTab.RX, dataSource:'RX', subTab: [
        { name: '口服藥品', value:2, isDefaultItem:true},
        { name: '外用藥品', value: 3, isDefaultItem:true},
        { name: '注射藥品', value: 4, isDefaultItem:true},
        { name: '單項檢驗', value: 6, isDefaultItem:true},
        { name: '套組檢驗', value: 206, isDefaultItem:true},
        { name: '放射檢查', value: 7, isDefaultItem:true},
        { name: '復健檢查', value: 8, isDefaultItem:true},
        { name: '處置', value: 9, isDefaultItem:true},
        { name: '特材', value: 12, isDefaultItem:true},
        { name: '注技', value: 5, isDefaultItem:true},
        { name: '麻醉', value: 11, isDefaultItem:true},
      ]
    });
    this.tabs.push({
      name: '自費特殊', id:HelpPadTab.SP, prefTab:[], dataSource:'RX',subTab:
      rxPaymentType.filter(x=>x.extension=='800')
                  .map((x,i)=>{
                    return {
                      name: x.text, value:800, isDefaultItem:true,
                      filter:(code)=>{
                        return (code as RxOpt).PaymentType ==x.value;
                      }
                    } as SubTab
                  })
    });
    var isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
    if (isHomeCare) { // 非居護遠所不要顯示居戶頁簽
      this.tabs.push({name:'居護',prefTab:[],id:HelpPadTab.HomeCare,  dataSource:'RX',subTab:[
        { name:'居護', value:9, isDefaultItem:true},
      ]});
    }

    var cTabStr = this.startUp.userData.prefs.CustomHelpPadTab
    this.cTabs =  (JSON.parse(cTabStr??'[]') as CustomTab[] ).sort((x,y)=>Compare(x.sort,y.sort));
    var addTab:PadTab[] = this.cTabs.map(c=>{
      return {
        name:c.name,id:c.id,dataSource:c.dataSource,prefTab:[],
        subTab:c.subTabs
        .sort((x,y)=>Compare(x.sort,y.sort))
        .map(s=>{
          return {
            name:s.name,
            value:s.value,
            isDefaultItem:true,
            filter:(code)=>{
              if(!s.filterCol){
                return true;
              }

              if(s.filterOp=='eq'){
                return code[s.filterCol]==s.filterValue;
              }

              if(s.filterOp=='con'){
                return (code[s.filterCol] as string).indexOf(s.filterValue as string)>=0;
              }

              if(s.filterOp=='st'){
                return (code[s.filterCol] as string).indexOf(s.filterValue as string)==0;
              }
            }
          } as SubTab
        })
      }
    });
    if(addTab.length>0){
      // 同Id組合
      this.tabs.forEach(t=>{
        var combineTab = addTab.find(x=>x.id == t.id);
        if(combineTab){
          t.subTab.push(...combineTab.subTab);
          addTab = addTab.filter(a=>a!=combineTab);
        }
      })
      this.tabs.push(...addTab);
    }


    this.resetGroupTabs();
    // 過濾掉沒開放的醫令
    this.tabs.forEach(t=>{
      if(t.dataSource=='RX'){
        t.subTab = t.subTab.filter(s=>allowRxType.some(r=>r==s.value))
      }
    })
    this.tabs = this.tabs.filter(t=> t.dataSource!='RX' || (t.subTab.length)>0)
  }
  getPadHeight() {
    const useBodyHeight = this.bodyHeight - 179;
    this.padHeight = useBodyHeight + 'px';
    this.padEditHeight = useBodyHeight - 25 + 'px';
  }

  hasResize;
  @HostListener('window:resize', ['$event'])
	onResize(event) {
    this.bodyHeight = document.body.clientHeight;
		this.getPadHeight();
	}


  /** 繼承父元件class name */
  ngAfterContentChecked() {
    this._class = this.elementRef.nativeElement.className;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bodyHeight = document.body.clientHeight;
    this.getPadHeight();
  }

  //#region subsribe and event ----------
  private subscribeUiEvent_Helper() {
    this.padService.toPad.subscribe(
      (evt: HelperPadCommand) => {
        if (evt instanceof FocusCommand) {
          // console.log(this.selectedItem)
          this.changeTabByEventSource(evt);
        } else if (evt instanceof CloseCommand) {
          this.cancelEdit();
        } else {
          return;
        }
      }
    );
  }
  //#endregion

  //#region
  makeQueryForm() {
    this.queryFG = this.fb.group({
      codeName: [''],
      isInsert: [true],
    });
  }
  resetQueryForm() {
    const origIsInsert = this.queryFV.isInsert;
    this.queryFG.reset({
      codeName: '',
      isInsert: origIsInsert,
    });
  }
  //#endregion

  //#region tab ----------

  async changeRxType(type: SubTab, name?: string) {
    await this.cancelEdit()
    this.selectedItem = type;
    this.groupStay.set(this.currentGroupTab(),this.selectedItem);
    this.focusIndex = -1;
    // console.log('changeRxType',this.rxType,type.value)
    //if (this.rxType != type.value) {
      this.rxType = type.value;
      //this.updatePrefData();
      this.fetchData();
      //this.updateRecentData();
      //console.log('['+new Date().toISOString()+'] '+'RxTab Changed'+new Date());
      this.returnTab();
    //}
  }

  labIsSet: boolean = null;
  changeLabQueryType(isSet: boolean) {
    if (isSet != this.labIsSet) {
      this.labIsSet = isSet;
      this.fetchData();
    }
  }
  clearGridView() {
    if (!this.isScheduledShowLoadingMessage) {
      return;
    }
    this.listItems = [];

    this.setGridView();
  }
  scheduleClearGridView() {
    this.isScheduledShowLoadingMessage = true;
    setTimeout(x => this.clearGridView(), 500);
  }
  /** UI切換Tab, 會保留之前選取的位置 */
  changeTab(id: HelpPadTab): boolean {
    if (this.tab?.id == id) {
      // 其他的同頁不切
      return;
    }
    // Drug因為有子項目所以得重撈
    if (this.tab?.id !== id) {
      var t = this.tabs.find(x => x.id == id);
      if (!t) { return; }
      this.tab = t;
      this.rxType = 0;
      // 更新按鈕狀態
      this.updataTabStatus();
      this.resetQueryForm();
      this.updateSelectedItemGrid();
    }
  }
  async changeTabFromUI(tab: PadTab) {
    await this.cancelEdit()
    this.changeTab(tab.id);
    this.returnTab();
    /** clear新增偏好focus */
    this.focusIndex = -1;
  }
  /** 程式性切換Tab */
  changeTabFromEvent(id: HelpPadTab) {
    // if (code === RxClass.Acu && this.tab.code === this.tabCode_AcuPic) {
    //   return false;
    // }
    this.changeTab(id);
  }
  /**
   *
   * @param isFromEvent
   * @param rxType OrderGrid的Tab包含的RxTypes
   * @returns
   */
  changeTabDrug(rxType: number[] = []) {

    // 要切換到的種類 包含目前的話 維持原狀
    // if (rxType.includes(this.rxType)) {
    //   return;
    // }
    // 如果現在的tab包含的項目 不存在沒被包含在GridTab項目中的項目 (全部都在GridTab中) => 不做切換
    if (this.tab?.subTab?.length > 0 && this.tab?.subTab?.some(x => !rxType.includes(x.value)) == false) {
      return;
    }
    var targetTab: PadTab = this.tabs.find(x => x.id == HelpPadTab.RX);
    var targetSubType = 0;
    var reLoadData = false;
    // 醫令項目回來一律Drug, 所以要按照RxType進行區分
    if (rxType.length == 1) {
      var tabMatch = this.tabs.find(t => t.subTab?.length == 1 && t.subTab[0].value == rxType[0]);
      targetTab = tabMatch;
      targetSubType = rxType[0]
    }
    if (rxType.length > 1) {
      var sum = this.tabs.filter(t => t.subTab.length > 0).map(x => {
        return { id: x.id, type: x.subTab.map(y => y.value), count: 0 };
      });
      // 類型完全與Tab吻合則直接使用
      var fullMatch = sum.find(x => x.type.sort().join(',') == rxType.sort().join(','));
      if (fullMatch) {
        targetTab = this.tabs.find(x => x.id == fullMatch.id);
      } else {
        rxType.forEach(t => {
          sum.forEach(s => {
            if (s.type.includes(t)) {
              s.count++;
            }
          });
        })
        sum.sort((s1, s2) => s1.count > s2.count ? -1 : s1.count == s2.count ? 0 : 1);
        targetTab = this.tabs.find(x => x.id == sum[0].id);
      }
    }

    // Drug因為有子項目所以得重撈

    this.tab = targetTab;
    this.rxType = targetSubType;
    // 更新按鈕狀態
    this.updataTabStatus();
    // 更新搜尋結果可見狀態

    this.resetQueryForm();
    //this.rxType = rxType;  // 藥品設定回全部
    this.updateSelectedItemGrid();
  }
  updateSelectedItemGrid(){
    // 取得tab中停駐的項目
    this.selectedItem = this.groupStay.get(this.currentGroupTab());
    if(!this.selectedItem  || this.selectedItem.isDefaultItem){
      this.rxType = this.selectedItem?.value??0;
      this.fetchData();
    } else{
      // 之前選的項目是當前Tab的話
      var selectedGroup = this.helpPadGoups.find(x=>x.Id==this.selectedItem.value)
      if(selectedGroup.Tab == this.currentGroupTab()){
        this.updateGirdGroupItem(selectedGroup);
      }else{
        this.fetchData();
      }

    }
  }
  /** 醫師偏好資料 */
  async updatePrefData() {
    if (this.isTab(HelpPadTab.CC)) {
      var codes = this.pref.HistCC;
      this.prefItem = codes.map(prefCode => {
        var e = this.editOptions.cc.find(c => prefCode == c.Code);
        // 偏好被從院內移除掉時null
        return !e ? null : {
          code: e.Code,
          name: e.Name,
          isInPref: true,
          type: -1,
          displayCode: this.pref.HistCCPECode
        };
      });
    } else if (this.isTab(HelpPadTab.PE)) {
      var codes = this.pref.HistPE;
      this.prefItem = codes.map(prefCode => {
        var e = this.editOptions.pe.find(c => prefCode == c.Code);
        // 偏好被從院內移除掉時null
        return !e ? null : {
          code: e.Code,
          name: e.Name,
          isInPref: true,
          type: -1,
          displayCode: this.pref.HistCCPECode
        }
      });
    } else if (this.isTab(HelpPadTab.DX)) {
      var codes = this.pref.HistDx;
      var dxOpt = await this.histService.getDiagList('', false, this.diagVersion);
      this.prefItem = codes.map(prefCode => {
        //var e =  this.editOptions.dx.find(c => prefCode == c.Code);
        var e = dxOpt.find(c => prefCode == c.Code);
        // 偏好被從院內移除掉時null
        return !e ? null : { code: e.Code, name: e.CName, isInPref: true, type: -1, displayCode: this.pref.HistCCPECode }
      });
    } else {
      var codes = this.pref.HistRx;
      var rxTp = this.rxType == 0 ? this.tab.subTab.map(x => x.value) : [this.rxType];
      var rxOpt = await this.histService.getOrderList('',rxTp);
      // 選出符合子Tab的
      this.prefItem = codes.map(prefCode => {
        var e = rxOpt.find(c => prefCode == c.RxCode && rxTp.includes(c.Type));
        // 偏好被從院內移除掉時null
        return !e ? null : { code: e.RxCode, name: e.ProdName, isInPref: true, type: e.Type, displayCode: this.pref.HistCCPECode, flagDescription: this.isTab(HelpPadTab.RX) ? e.FlagDescription : '' }
      });
    }
    this.prefItem = this.prefItem.filter(x => x);
  }



  get hasDx() {
    return this.padService.getDiag().length > 0;
  }

  isTab(id:HelpPadTab) {
    return this.tab && this.tab.id === id;
  }
  isTabOf(ids:HelpPadTab[]) {
    return this.tab && ids.some(x=>x==this.tab.id);
  }
  updataTabStatus() {

  }

  changeTabByEventSource(evt: FocusCommand) {

    var source = evt.to;
    var rxType = evt.data;
    var isInOrderGroups= this.isTabOf([HelpPadTab.RX,HelpPadTab.HomeCare,HelpPadTab.Set]);
    // console.log('[' + new Date().toISOString() + '] ' + 'Helper make focus ' + source, rxType);
    if (source === HelpPadTab.CC) {
      this.changeTabFromEvent(HelpPadTab.CC);
    } else if (source ===HelpPadTab.PE) {
      this.changeTabFromEvent(HelpPadTab.PE);
    } else if (source === HelpPadTab.DX) {
      this.changeTabFromEvent(HelpPadTab.DX);
    } else if (source === HelpPadTab.RX) {  //只要是醫令類的都會送RX過來
      // 這目前為醫令//居護 時不進行切換
      if(isInOrderGroups){
        return;
      }
      this.changeTabDrug(rxType);
    }else{
      if(!this.tab){
        this.changeTabFromEvent(HelpPadTab.CC);
      }
    }
  }
  //#endregion

  //#region get data ----------
  private async fetchData() {
    try {
      var data = await this.getCodes();

      //醫令不顯示停用，參數判斷是否啟用此判斷。
      if (!this.displayStopDrugsFromPad) {
        var ds = this.tabs.find(x=>x.id==this.tab.id).dataSource;
        if (ds == 'RX') {
          data = (data as RxOpt[]).filter(x => !x.IsDrugStatus);
        }
      }

      if(this.selectedItem?.filter){
        data = (data as []).filter(x=>{
          return this.selectedItem.filter(x)
        }) as SystemCode[]|DxOpt[]|RxOpt[];
      }
      this.handleFetchResult(data);
    } catch(e) {
      this.notification.showGetDataFailed('hepler-edit-pad');
      // console.log(e)
    } finally {
      this.isScheduledShowLoadingMessage = false;
    }
  }
  private handleFetchResult(data: any[]) {
    this.listItems = data;
    this.setGridView();
  }
  private async getCodes(): Promise<SystemCode[]|DxOpt[]|RxOpt[]> {
    if (!this.tab) {
      return [];
    }
    let inputValue = '';
    var ds = this.tabs.find(x=>x.id==this.tab.id).dataSource;
    if (ds=='CC') {
      //return await this.histService.getCCOptions(inputValue);
      var ccs = await this.histService.getCCOptions(inputValue);
      if(this.recentSource == HelpPadType.Clinic){
        if(this.stat?.statCC?.ClinicStat?.Month?.length>0){
          ccs = ccs.filter(x=>this.stat.statCC.ClinicStat.Month.some(y=>y==x.Code));
        }
      }else{
        if(this.stat?.statCC?.UserStat?.Month?.length>0){
          ccs = ccs.filter(x=>this.stat.statCC.UserStat.Month.some(y=>y==x.Code));
        }
      }
      return ccs;
    } else if (ds=='PE') {
      //return await this.histService.getPEOptions(inputValue);
      var pes = await this.histService.getPEOptions(inputValue);
      if(this.recentSource == HelpPadType.Clinic){
        if(this.stat?.statPE?.ClinicStat?.Month?.length>0){
          pes = pes.filter(x=>this.stat.statPE.ClinicStat.Month.some(y=>y==x.Code));
        }
      }else{
        if(this.stat?.statPE?.UserStat?.Month?.length>0){
          pes = pes.filter(x=>this.stat.statPE.UserStat.Month.some(y=>y==x.Code));
        }
      }
      return pes;
    } else if (ds=='DX') {
      var dxs = await this.histService.getDiagList(inputValue, false, this.diagVersion);
      if(this.recentSource == HelpPadType.Clinic){
        if(this.stat?.statDx?.ClinicStat?.Month?.length>0){
          dxs = dxs.filter(x=>this.stat.statDx.ClinicStat.Month.some(y=>y==x.Code));
        }
      }else{
        if(this.stat?.statDx?.UserStat?.Month?.length>0){
          dxs = dxs.filter(x=>this.stat.statDx.UserStat.Month.some(y=>y==x.Code));
        }
      }
      return dxs;
    } else if (ds=='MS'){
      return await this.getRxList(inputValue,[0])
    }
    // RX來源
    else if(ds=='RX'){
      var orderList = this.tab.subTab.map(x => x.value);
      //var orderList = [OrderTypeEnum.OralDrugFee, OrderTypeEnum.ExtDrugFee,OrderTypeEnum.InjectionDrugFee];
      if (this.rxType != 0) {
        orderList = [this.rxType];
      }
      var ret = await this.getRxList(inputValue, orderList);


      //醫令&近期
      if(this.isTab(HelpPadTab.RX) &&!this.rxType ){
        if(this.recentSource == HelpPadType.Clinic){
          if(this.stat?.statRx?.ClinicStat?.Month?.length>0){
            ret = ret.filter(x=>this.stat.statRx.ClinicStat.Month.some(y=>y==x.RxCode));
          }
        }else{
          if(this.stat?.statRx?.UserStat?.Month?.length>0){
            ret = ret.filter(x=>this.stat.statRx.UserStat.Month.some(y=>y==x.RxCode));
          }
        }
      }

      return ret;
    }
    return []

  }
  async getRxList(code, types) {
    return await this.histService.getOrderList(code, types);
  }
  async clearQuery() {
    this.queryFG.patchValue({ codeName: '' });
  }
  async onQueryKeypress(evt: KeyboardEvent){
    if(evt.key == 'Enter'){
      await this.onBtnQueryClick();
    }
  }
  async onBtnQueryClick() {
    var inputValue = '';
    if (this.queryFV && this.queryFV.codeName) {
      inputValue = this.queryFV.codeName;
    }
    this.prefItem = this.filteredItems.filter(x=>x.name.indexOf(inputValue)>=0|| x.code.toUpperCase().startsWith(inputValue.toUpperCase()))
  }
  //#endregion

  //#region grid ----------
  // 表格分頁
  setGridView() {
    this.filteredItems = this.listItems.
      map(it => {
        var code = it.RxCode || it.Code;
        return {
          code: code,
          name: it.Name || it.CName || it.ProdName,
          isInPref: false,
          type: it.RxType ?? -1,
          displayCode: this.isTabOf([HelpPadTab.CC,HelpPadTab.PE]) ? this.pref.HistCCPECode : this.pref.HistCCPECode,
          flagDescription: this.isTabOf([HelpPadTab.RX]) ? it.FlagDescription : ''
        }
      });
    this.prefItem = this.filteredItems;

  }

  public async clickGridItem(dataItem: GridViewItem) {
    if(this.isEditGroup){
      return;
    }
    var ds = this.tab.dataSource;
    if (ds=='CC') {
      let orig = this.editOptions.cc.find(it => dataItem.code == it.Code);
      this.returnSelected(orig);
    } else if (ds=='PE') {
      let orig = this.editOptions.pe.find(it => dataItem.code == it.Code);
      this.returnSelected(orig);
    } else if (ds=='DX') {
      var dxOpt = await this.histService.getDiagList('', false, this.diagVersion);
      let orig = dxOpt.find(it => dataItem.code == it.Code);
      this.returnSelected(orig);
    } else if (ds=='MS') {
      var rsOpt = await this.histService.getOrderList('',[0]);
      let orig = rsOpt.find(it => dataItem.code == it.RsCode);
      this.returnSelected(orig);
    } else if(ds=='RX'){
      var rxOpt = await this.histService.getOrderList('',[]);

      let orig = rxOpt.find(it => dataItem.code == it.RxCode);
      this.returnSelected(orig);
    }
  }
  //#endregion

  //#region query UI ----------
  onBtnInsClick() {
    this.queryFG.controls['isInsert'].setValue(!this.queryFG.value.isInsert);
  }
  //#endregion

  //#region Acu Chart
  // getAcuDrugTypes(): number[] {
  //   return this.editOptions.histParams.OrderClassAcu;
  // }
  // async acuPointClicked(drug: string) {
  //   try {
  //     var data: Rx = await this.histApi.getOrderByCode(drug, this.getAcuDrugTypes())
  //     if (data) {
  //       this.returnSelected(data);
  //     } else {
  //       this.notification.showErrorById('MSG_HistOrders2', true);
  //     }
  //   } catch (error) {
  //     this.notification.showErrorById('MSG_HistOrders1');
  //   }

  // }

  //#endregion

  //#region return selected ----------
  getSourceByTab(): HelperSourceEnum {
    if (this.isTab(HelpPadTab.CC)) {
      return HelperSourceEnum.CC;
    } else if (this.isTab(HelpPadTab.PE)) {
      return HelperSourceEnum.PE;
    } else if (this.isTab(HelpPadTab.DX)) {
      return HelperSourceEnum.Diag;
    }
    // 其他Rx類型
    else {
      return HelperSourceEnum.Order;
    }
  }

  // 回傳選到的項目
  returnSelected(item: any) {
    this.padService.Pad_ItemSelected(new ItemSelectedEvent(this.getSourceByTab(), {rxType:[item.Type],include:['CC','PE','DX','RX']}, item, this.queryFV.isInsert))
  }
  // 回傳目前Tab
  returnTab() {
    this.padService.Pad_TabSelected(new AreaSelectedEvent(this.getSourceByTab(),
    {rxType:this.rxType == 0 ? [2, 3, 4] : [this.rxType],include:['CC','PE','DX','RX']}))
  }
  //#endregion

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  print(a) {
    console.log(a);
  }
  isInsert(dataItem: GridViewItem) {

    var rId = this.isTab(HelpPadTab.CC) ? HelperId.CC :
      this.isTab(HelpPadTab.PE) ? HelperId.PE :
        '';
    if (rId) {
      var rc = this.padService.getRecords(rId);
      return rc?.some(x => x.text.trim() == dataItem.name);
    }
    if (this.isTab(HelpPadTab.DX)) {
      return this.padService.getDiag()?.some(x => x.code == dataItem.code);
    }
    return false;
  }

  recentItem: GridViewItem[] = [];
  recentPaging: GridViewItem[] = [];

  recentSource: HelpPadType = HelpPadType.Self;
  recentFreq: 'Season' | 'Month' | 'Week' | 'Dx' = 'Season';
  typeChange(tp:HelpPadType) {
    this.recentSource = tp;
    this.selectedItem = null;
    this.groupStay.clear();
    this.cancelEdit();
    this.resetGroupTabs();
    this.updateSelectedItemGrid();
    //this.updateRecentData();
  }
  recentFreqClick(tp: 'Season' | 'Month' | 'Week' | 'Dx') {
    this.recentFreq = tp;
    //this.updateRecentData();
  }
  pref: UserPreference;

  prefItem: GridViewItem[]

  async updatePrefs() {
    try {
      await this.userApi.UpdatePrefs(this.pref);
      this.notification.showSuccess('儲存完成')
    } catch (ex) {
      this.notification.showError(ex)
    }

  }
  async dropGroup(event: CdkDragDrop<string[]>) {
    if(event.previousIndex == event.currentIndex){
      return;
    }
    // UI顯示
    moveItemInArray(this.tab.prefTab, event.previousIndex, event.currentIndex);
    //資料交換
    //moveItemInArray(this.tab.prefTab, event.previousIndex, event.currentIndex);

    var item = this.tab.prefTab[event.currentIndex];
    var group = this.helpPadGoups.find(x=>x.Id == item.value);
    if(event.currentIndex>0){
      var prevItem = this.tab.prefTab[event.currentIndex-1];
      group.PrevId = prevItem.value
    }else{
      group.PrevId = 0;
    }
    await this.helpPadApi.UpdateGroup(group)
    await this.refreshHelpPadGroup();
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.prefItem, event.previousIndex, event.currentIndex);
  }
  drapSite:GridViewItem[];
  dragStart(event: CdkDrag) {

    this.drapSite = this.prefItem.map(x=>x);
  }
  dragEnd(event:CdkDragEnd<any>){

  }
  prefItemDropped(event: CdkDragDrop<GridViewItem>,item:GridViewItem){
    var prevIndex = this.prefItem.indexOf(event.item.data);
    var curIndex = this.prefItem.indexOf(item)
    //更新顯示順序
    moveItemInArray(this.prefItem,prevIndex,curIndex);
    //更新資料順序
    moveItemInArray(this.editHelpPadGroup.HelpPadItems,prevIndex,curIndex);
    this.editHelpPadGroup.HelpPadItems.forEach((x,i)=>{
      x.SN = i;
    });
  }
  menuTopLeftPosition = { x: '0', y: '0' }
  openRxMenu = false;
  editItem: Rx = null;
  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (this.openRxMenu && !document.getElementById('hist-quick-rx').contains(event.target)) {
      this.closeMenu();
    }
  }
  async openMenu(evt: MouseEvent, item: GridViewItem) {
    evt.preventDefault();
    if(this.isEditGroup){
      return;
    }
    if (item.type < 0) {
      return;
    }
    var maxX = window.screen.availWidth - 310;
    var el = evt.target as HTMLElement;
    el = el.closest('.grid-item-content')

    // 檢查RxType, 設定初始直
    var rect = el.getBoundingClientRect();
    this.menuTopLeftPosition.x = Math.min(rect.left, maxX) + 'px';
    this.menuTopLeftPosition.y = rect.bottom + 'px';//evt.clientY + 'px';
    var rx = await this.histApi.getOrderByCode(item.code, [item.type]);

    //var rx = this.editOptions.rx.find(it=>item.code == it.RxCode)
    // 用院所預設值進行修正
    this.editItem = this.histService.updateDrugByClinicDefault(rx);
    //Object.assign(this.editItem,rx);
    this.editItem.isCustomOrder = true;
    // console.log(this.editItem)
    this.openRxMenu = true;
  }
  completeEdit() {
    //return item
    this.returnSelected(this.editItem)
    this.closeMenu();
  }
  closeMenu() {
    this.editItem = null;
    this.openRxMenu = false;
  }
  commonDay = [1, 2, 3, 4, 5, 6, 7, 14, 15, 21, 28, 29, 30, 31, 8, 9, 11, 12, 20, 24];
  commonQty = [1, 2, 3, 4, 5, 6, 0.5, 1.5, 2.5, 0.75, 1.25, 1.75];
  commonFreq = ['QD', 'BID', 'TID', 'QID', 'HS', 'Q6H', 'PRN', 'AsOrder']

  prefSetting() {

    this.ezForm.show<UserPreference & {HistPadShow:boolean}>({
      title: '門診選盤設定',
      msg: '近期常用選項設定，常用選項以基準日期前三十日內的使用頻率排序。',
      tip: '',
      fields: {
        RecentFromDate: {
          label: '基準日期',
          value: this.pref.RecentFromDate,
          name: 'RecentFromDate',
          type: 'date'
        },
        // divider3: {
        //   label: '主訴理學設定',
        //   type: 'divider',
        //   name: null,
        //   value: null
        // },
        HistCCPECode: {
          label: '顯示代碼',
          value: this.pref.HistCCPECode,
          name: 'HistCCPECode',
          type: 'check'
        },
        HistPadShow: {
          label: '預設顯示',
          value: !this.pref.HistPadHide,
          name: 'HistPadShow',
          type: 'check'
        },
      },
      textOk:'儲存設定',
      btns: [{
        text: '重新分析資料',
        callback: async (data) => {
          var fromDate = data.RecentFromDate as Date;
          var strDate = DateHelper.formatDate(fromDate)
          var confirm = await this.userConfirm.showConfirm({
            'title': '確認',
            msg: '是否重新進行自' + strDate + '往前3個月內的診斷與處方的使用統計分析?\n(可能會花費數十秒至一分鐘)',
            width: 350
          });
          if (confirm) {
            try {
              this.mainLayoutService.showLoader();
              await this.histApi.ParseStatFrom(data.RecentFromDate);
              this.notification.showSuccess('分析完成');
              this.stat = await this.histApi.GetStatOptions();
              await this.updateSelectedItemGrid()
              this.notification.showSuccess('近期資料已欠變動');
              this.mainLayoutService.hideLoader();
            } catch (ex) {
              this.notification.showError(ex);
              this.mainLayoutService.hideLoader();
            }
          }
        }
      }],
      beforeSubmit: async (ret) => {
        this.pref.RecentFromDate = ret.RecentFromDate;
        this.pref.HistCCPECode = ret.HistCCPECode;
        this.pref.HistPadHide = !ret.HistPadShow;
        try {
          this.mainLayoutService.showLoader();
          await this.userApi.UpdatePrefs(this.pref);
          this.notification.showSuccess('更新成功');
          this.stat = await this.histApi.GetStatOptions();
          await this.updateSelectedItemGrid()
          this.notification.showSuccess('近期資料已欠變動');
          this.mainLayoutService.hideLoader();
          return true;
        } catch (ex) {
          this.notification.showError(ex);
          this.mainLayoutService.hideLoader();
          return false;
        }

      }
    })
  }
  /** 新增偏好focus */
  focusIndex: number = -1;
  /** 第二配盤視窗 */
  showNewWindow (index?: number) {
    this.padService.currentTab = this.tab
    this.openHelperNewPadWin(false)
    this.focusIndex = index;
  }
  addGroupClick(){
    var currentTb = this.currentGroupTab();
    if(currentTb>=0){
      this.showNewWindow(0);
      this.createGroupModel(currentTb,this.recentSource)
      this.prefItem = [];
      setTimeout(() => {
        this.groupNameEdit?.nativeElement.focus();
      }, 0);

    }
  }
  editGroupClick(){
    this.isEditGroup = true;
    this.editHelpPadGroup = this.helpPadGoups.find(x=>x.Id==this.selectedItem.value);

    this.oriHelpPadGroup = Object.assign({},this.editHelpPadGroup);
    // 避免子項目referenceCopy
    this.oriHelpPadGroup.HelpPadItems = [].concat(this.editHelpPadGroup.HelpPadItems)

    this.showNewWindow(0);
    setTimeout(() => {
      this.groupNameEdit?.nativeElement.focus();
    },0)
  }
  async recentClick(){
    this.cancelEdit();
    this.selectedItem = null;
    this.groupStay.set(this.currentGroupTab(),this.selectedItem);
    //await this.updatePrefData()
    this.rxType = 0;
    await this.fetchData();
  }

  groupStay:Map<HelpPadTab,SubTab> = new Map();
  groupClick(groupSelectionItem:SubTab){
    this.cancelEdit();
    this.selectedItem = groupSelectionItem;
    var group = this.helpPadGoups.find(x=>x.Id==groupSelectionItem.value)
    this.groupStay.set(group.Tab,this.selectedItem);
    this.updateGirdGroupItem(group)
    this.returnTab();
  }
  async updateGirdGroupItem(group:HelpPadGroups){
    var items = await this.getGroupsData(group);
    this.prefItem = items.map(x=>{
      return {
        code:x.value,
        name:x.text,
        isInPref:true,
        displayCode:this.pref.HistCCPECode,
        type:0,
        flagDescription:x.extension
      }
    })

  }
  stat: StatOpts=null;
  async loadStat(){
    this.stat = await this.clinicData.GetStatOpt();

  }
  //#region 偏好操作
  selectedItem:SubTab;
  isEditGroup = false;
  editHelpPadGroup:HelpPadGroups;
  oriHelpPadGroup:HelpPadGroups;
  helpPadGoups:HelpPadGroups[];
  displayItems:ValueTextPair[]
  async loadGroup(){
    this.helpPadGoups = await this.helpPadApi.GetHelperPadGroups();
  }
  currentGroupTab():HelpPadTab{
    return this.tab.id;
  }
  createGroupModel(tab:HelpPadTab,type:HelpPadType){
    var clinicId = UserCache.getLoginClinicId();
    var user = UserCache.getLoginUser();
    var groups = this.getGroupsIdNames(tab,type);
    this.editHelpPadGroup = {
      ClinicId:clinicId,
      UserId:type== HelpPadType.Self?user.UserId:0,
      GroupName:'',
      Tab:tab,
      Type:type,
      IsEnabled:true,
      PrevId:groups?.length>0?groups[groups.length-1].value:0,
      Creater:user.UserId.toString(),
      CreateTime:new Date(),
      Editer:user.UserId.toString(),
      EditTime:new Date(),
      HelpPadItems:[],
      Id:0
    };
    this.isEditGroup = true;
  }
  async addGroup(){
    if(!this.editHelpPadGroup.GroupName){
      this.notification.showSuccess('請輸入名稱')
      return;
    }
    try{
      var itemId = await this.helpPadApi.AddGroup(this.editHelpPadGroup);
      // 設定使其被選取
      this.selectedItem = {value:itemId,name:this.editHelpPadGroup.GroupName};
      await this.refreshHelpPadGroup();
      this.notification.showSuccess('偏好['+this.editHelpPadGroup.GroupName+']已儲存')
    }catch(e){
      this.notification.showError('偏好['+this.editHelpPadGroup.GroupName+']新增失敗')
      // console.log(e);
    }

  }
  async refreshHelpPadGroup(){
    await this.cancelEdit()
    await this.loadGroup()
    this.resetGroupTabs();
    //重新選定項目
    var reSelected = this.tab.prefTab.find(x=>x.value==this.selectedItem.value);
    if(reSelected){
      this.selectedItem = reSelected;
      //更新選定內容
      this.updateGirdGroupItem(this.helpPadGoups.find(x=>x.Id==this.selectedItem.value));
    }
  }
  /** 重新設定偏好群組 */
  resetGroupTabs(){

    this.tabs.forEach(t=>{
      var pTab = this.getGroupsIdNames(t.id)
      t.prefTab = pTab;
    })
  }
  async revertEditDate(){
    if(this.oriHelpPadGroup){
      // 還原編輯前的內容
      Object.assign(this.editHelpPadGroup,this.oriHelpPadGroup);
      await this.updateGirdGroupItem(this.editHelpPadGroup);
      this.oriHelpPadGroup = null;
    }
  }
  async cancelEdit(){
    await this.revertEditDate();
    this.isEditGroup = false;
    this.editHelpPadGroup = null;

    this.closeHelperNewPadWin();
  }
  async updateGroup(){
    if(!this.editHelpPadGroup.GroupName){
      this.notification.showSuccess('請輸入名稱')
      return;
    }
    try{
      this.editHelpPadGroup.HelpPadItems.forEach((x,i)=>{
        x.SN = i+1;
      });
      await this.helpPadApi.UpdateGroup(this.editHelpPadGroup);
      this.notification.showSuccess('偏好['+this.editHelpPadGroup.GroupName+']已更新')
      // 不進行revert
      this.oriHelpPadGroup = null;
      await this.refreshHelpPadGroup();

    }catch(e){
      this.notification.showError('偏好['+this.editHelpPadGroup.GroupName+']更新失敗')
      // console.log(e);
    }
  }
  async deleteGroup(){
    var confirmed =  await this.userConfirm.showConfirm({
      msg:'是否移除此項偏好?',
      title:'刪除'
    });
    if(!confirmed){
      return;
    }
    try{
      await this.helpPadApi.DeleteGroup(this.editHelpPadGroup);
      // 選回近期
      this.selectedItem = null;
      await this.refreshHelpPadGroup();
      this.notification.showSuccess('偏好['+this.editHelpPadGroup.GroupName+']已移除')
    }catch(e){
      this.notification.showError('偏好['+this.editHelpPadGroup.GroupName+']移除失敗')
      // console.log(e);
    }
  }
  /** 取得偏好項目的集合 */
  getGroupsIdNames(tab:HelpPadTab,type?:HelpPadType):SubTab[]{
    type = this.recentSource;
    var ret:SubTab[] = [];
    var filtered = this.helpPadGoups.filter(x=>x.Tab==tab && x.Type==type);

    //ret = filtered.map(item=>{ return {value:item.Id,text:item.GroupName}});

    var item = filtered.find(x=>x.PrevId ==0)
    while(item!=null){
      ret.push({value:item.Id, name:item.GroupName,isDefaultItem:false})
      //下一個
      item = filtered.find(x=>x.PrevId == item.Id)
    }
    return ret;
  }

  // 取得偏好項目內容
  async getGroupsData(group:HelpPadGroups):Promise<ValueTextPair[]>{
      var items = group.HelpPadItems.sort((a,b)=>a.SN>b.SN?1:a.SN==b.SN?0:-1);
      var ds = this.tabs.find(x=>x.id== group.Tab)?.dataSource
      if(ds=='DX')// group.Tab == HelpPadTab.DX)// 診斷
      {
        return items.map(x=>{
          var dx =  this.clinicData.dxOption.find(y=>y.Code==x.ItemCode);
          return dx?{value:dx.Code,text:dx.CName}:null;
        }).filter(x=>x);
      }else if( ds=='RX')// group.Tab == HelpPadTab.RX)// 醫令
      {
        return items.map(x=>{
          var rx =  this.clinicData.rxOption.find(y=>y.RxCode==x.ItemCode);
          return rx?{value:rx.RxCode,text:rx.ProdName,extension:rx.FlagDescription}:null;
        }).filter(x=>x);
      }else if( ds=='CC')// group.Tab == HelpPadTab.CC)// 主訴
      {
        var ccOpt = await this.histService.getCCOptions();
        return items.map(x=>{
          var cc = ccOpt.find(y=>x.ItemCode==y.Code);
          return cc?{value:cc.Code,text:cc.Name}:null;
        }).filter(x=>x);
      }else if(ds=='PE')// group.Tab == HelpPadTab.PE)// 理學
      {
        var peOpt = await this.histService.getPEOptions();
        return items.map(x=>{
          var pe = peOpt.find(y=>x.ItemCode==y.Code);
          return pe?{value:pe.Code,text:pe.Name}:null;
        }).filter(x=>x);
      }else if(ds=='MS')// group.Tab == HelpPadTab.Set)//
      {
        var rss = await this.histService.getOrderList('',[0]);
        return items.map(x=>{
          var rs = rss.find(y=>y.RsCode==x.ItemCode);
          return rs?{value:rs.RxCode,text:rs.ProdName}:null;
        }).filter(x=>x);
      // } else if(group.Tab == HelpPadTab.HomeCare)// 居護
      // {
      //   return items.map(x=>{
      //     var rx =  this.clinicData.rxOption.find(y=>y.RxCode==x.ItemCode);
      //     return rx?{value:rx.RxCode,text:rx.ProdName}:null;
      //   }).filter(x=>x);
      } else{
        return null;
      }
  }
  groupItemAdd(data:{type:HelpPadTab,code:string}){
    if(data.type!=this.editHelpPadGroup.Tab){
      this.notification.showWarning('欲加入的項目類型與編輯中的偏好不合');
      return;
    }
    var user = UserCache.getLoginUser();

    this.editHelpPadGroup.HelpPadItems.push({
      Id:0,
      Creater:user.UserId.toString(),
      CreateTime:new Date(),
      Editer:user.UserId.toString(),
      EditTime:new Date(),
      GroupId:this.editHelpPadGroup.Id,
      ItemCode:data.code,
      IsEnabled:true,
      SN:this.editHelpPadGroup.HelpPadItems.length+1
    });
    this.updateGirdGroupItem(this.editHelpPadGroup)
  }
  groupItemRemove(evt:Event,code:string){
    this.editHelpPadGroup.HelpPadItems = this.editHelpPadGroup.HelpPadItems.filter(x=>x.ItemCode!=code);
    this.updateGirdGroupItem(this.editHelpPadGroup)
  }
  //#endregion

  isHelperNewPadOpened = false; // default 顯示
  openHelperNewPadWin(resetPos: boolean = false) {
    if (resetPos) {
      this.localStorage.removeItem(HelperPadComponent.windSizeStorageKey);
      this.closeHelperNewPadWin();
      setTimeout(() => {
        this.openHelperNewPadWin();
      }, 10);
      return false;
    }
    this.isHelperNewPadOpened = true;
  }
  closeHelperNewPadWin() {
    this.isHelperNewPadOpened = false;
  }

  editCTab:CustomTab = null;
  onCTabEdit(cTab:CustomTab){
    this.editCTab=cTab;
  }
  onCTabAdd(){
    this.editCTab = {
      name:'',
      id:0,
      sort:this.cTabs.length,
      dataSource:'RX',
      subTabs:[]
    }
    this.cTabs.push(this.editCTab);
  }
  appendCSubTab(){
    this.editCTab.subTabs = this.editCTab.subTabs.concat([{
      name:'',
      value:0,
      filterCol:'',
      filterOp:'eq',
      filterValue:'',
      sort:this.editCTab.subTabs.length
    }])
  }
  orderTypeDD:ValueTextPairNumberValue[];
  dataSourceDD:{value:TabDataSource,text:string}[] = [
    {value:'DX',text:'診斷'},
    {value:'RX',text:'醫令'},
    {value:'MS',text:'組合'},
    {value:'CC',text:'主訴'},
    {value:'PE',text:'理學'},
  ]
  rxProp:ValueTextPair[]= [
    {value:'RxCode',text:'院內代碼',extension:'string'},
    {value:'StdCode',text:'標準代碼',extension:'string'},
    {value:'ProdName',text:'醫令名稱',extension:'string'},
    {value:'PaymentType',text:'支付類別'},
    {value:'SPRule',text:'特殊規則'},
  ]
  dxProp:ValueTextPair[]= [
    {value:'Code',text:'院內代碼',extension:'string'},
    {value:'ApplyCode',text:'標準代碼',extension:'string'},
    {value:'CName',text:'診斷中名',extension:'string'},
    {value:'EName',text:'診斷英名',extension:'string'},
  ]
  codeProp:ValueTextPair[]= [
    {value:'Code',text:'代碼',extension:'string'},
    {value:'Name',text:'名稱',extension:'string'}
  ]
  operatorDD:ValueTextPair[] =[]

  async updateCTabs(diag:VisDialogComponent){

    this.startUp.userData.prefs.CustomHelpPadTab = JSON.stringify(this.cTabs);
    this.tabs = [];
    await this.initTabs()
    diag.show=false;
  }
  getPropDD(){
    return this.editCTab.dataSource=='RX'||this.editCTab.dataSource=='MS'?this.rxProp:
    this.editCTab.dataSource=='DX'?this.dxProp:this.codeProp;

  }
  getOperatorDD(cSubTab:CustomSubTab){
    var propDD = this.getPropDD();
    var p = propDD.find(x=>x.value == cSubTab.filterCol).extension
    return p=='string'?this.operatorDD:[{ value:'eq',text:'等於' }];
  }

  colChange(cSubTab:CustomSubTab){
    console.log(cSubTab);
    var propDD = this.getPropDD();
    var p = propDD.find(x=>x.value == cSubTab.filterCol).extension
    if(p=='string'){
      this.operatorDD = [{ value:'eq',text:'等於' },
                          { value:'con',text:'包含' },
                          { value:'st',text:'開頭為' }]
    }else{
      this.operatorDD = [{ value:'eq',text:'等於' }]
    }
  }

}

