import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, AbstractControl, ValidatorFn } from '@angular/forms';
import { verifyAllCID, verifyAllCIDAndMsg } from '../../utilities';

@Component({
  selector: 'app-validation-tip',
  templateUrl: './validation-tip.component.html',
  styleUrls: ['./validation-tip.component.css']
})
export class ValidationTipComponent implements OnInit {

  constructor(private formGroup: ControlContainer) { }
  /**
   * 有符號之代碼，包含：大小寫英數字，符號.
  */
  static patternSymbolCode = '^[a-zA-Z0-9.@]+$';
  /**
   * 大小寫英數字，不含符號
   */
  static patternLetterNumber = '^[a-zA-Z0-9]+$'; //
  /**
   * 純數字，不含符號
   */
  static patternNumber = '^[0-9]+$'; //
  /**
   * 電話號碼，數字，以及符號()#+-
   */
  static patternPhone = '^[0-9()#+-]+$'; //
  /**
   * 純數字以及小數點，不含符號
   */
  static patternFloat = '[+-]?([0-9]*[.])?[0-9]+'; //
  /**
   * 純數字以及小數點，不含符號
   * 小數點前i位,小數下j位
   */
  static patternDecimal(i: number, j: number) {
    return '^([0]|([1-9]{1}[0-9]{0,' + (i - 1) + '}))([.][0-9]{' + j + '})?$';
    ///^([0]|[1-9][0-9]{0,6})(.[0-9]{1})?$/
  }  //
  /**
   * CId格式 英數字/英文大寫
   * */
  static patternCId = '^[A-Z0-9]+$'; //
  /**
   * 純數字，與含負號
   */
  static patternNumberNegative = '[-]?([0-9]*)'; //
  /**
   * 大小寫英數字，符號_
   */
  static patternRuleCode = '^[a-zA-Z0-9_]+$'; //
  /**
   * 中文兩字以上 u3400~ 中日韓擴增
   */
  static patternRuleChineseName = '^[a-zA-Z\u3400-\u9FFF]{2,}$'; //

  /**
   * 健保卡上的姓名．﹒‧·\uFF0E\u2027\u00B7
   */
  static patternRuleCName = '^[a-zA-Z _\u3400-\u9FA5\s．‧·.]{2,}$';


  @Input() controlName = '';
  thisControl: AbstractControl;

  //#region custom validation
  static enumRequiredValidator(emptyValue?: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!emptyValue) { emptyValue = 0; }
      if (control.value === emptyValue) {
        return { 'enum': { value: control.value } };
      } else {
        return null;
      }
    };
  }
  static ROCCIDValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    var valid = verifyAllCID(control.value);
    if (valid) return null;
    else return { 'custom': '無效的身分證號' }
  }
  static ROCCIDValidatorForMsg: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    var valid = verifyAllCIDAndMsg(control.value);
    if (valid.IsCid) return null;
    else return { 'custom': valid.Msg }
  }

  static ROCCIDValidatorEx(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length == 0) return null;

      var valid = verifyAllCID(control.value);
      if (valid) return null;
      else return { 'custom': '無效的身分證號' }
    }
  }

  static MaxDate(date: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var valid = control.value <= date;
      if (valid || date == null) return null;
      else return { 'custom': '日期不可大於' + date.toLocaleDateString() }
    }
  }
  static MinDate(date: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var valid = new Date(control.value) >= new Date(date.getFullYear(), date.getMonth(), date.getDate());
      if (valid || date == null) return null;
      else return { 'custom': '日期不可小於' + date.toLocaleDateString() }
    }
  }
  static MinDateWithMsg(date: Date, msg: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var valid = control.value >= date;
      if (valid || date == null) return null;
      else return { 'custom': msg }
    }
  }
  //#endregion

  // 取得上一層的form group control
  get formGroupControl() {
    return this.formGroup.control;
  }
  // 取得errors property
  get theErrors(): any {
    return this.thisControl.errors;
  }

  ngOnInit() {
    this.thisControl = this.formGroupControl.get(this.controlName);
  }

  //#region get pattern - RegExp
  get patternSymbolCode() {
    return ValidationTipComponent.patternSymbolCode;
  }
  get patternLetterNumber() {
    return ValidationTipComponent.patternLetterNumber;
  }
  get patternNumber() {
    return ValidationTipComponent.patternNumber;
  }
  get patternPhone() {
    return ValidationTipComponent.patternPhone;
  }
  get patternCId() {
    return ValidationTipComponent.patternCId;
  }
  get patternCName() {
    return ValidationTipComponent.patternRuleChineseName;
  }
  //#endregion

}
