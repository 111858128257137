import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { DiagsComponent } from '../diags/diags.component';
import { HistDiag } from '../models/hist-diag';
import { HelpPadTab } from 'src/app/services/api-service/hist/help-pad-api';
import { ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { DxOpt, RxOpt } from 'src/app/services/api-service/hist/hist-edit-option';

/** 要求Pad進行動作的指令 */
export class HelperPadCommand{

}
export class CloseCommand extends HelperPadCommand{

}
export class FocusCommand extends HelperPadCommand{
  to:HelpPadTab;
  data: any;

  constructor(to:HelpPadTab,data: any) {
    super();
    this.to = to;
    this.data = data;
  }
}
/** Pad操作回傳事件 */
export class HelperPadEvent {
  constructor() {
  }
}
export class PadInitEvent extends HelperPadEvent{

  constructor(){
    super();
  }
}
/** 門診主面板區域選取 */
export class AreaSelectedEvent extends HelperPadEvent{
  tab:HelperSourceEnum;
  data: any;
  constructor(tab:HelperSourceEnum,data:any){
    super();
    this.tab = tab;
    this.data = data;
  }
}

export class ItemSelectedEvent extends HelperPadEvent{
  type:HelperSourceEnum;
  data: any;
  item: any;
  isInsert:boolean;
  focus:boolean;

  constructor(type:HelperSourceEnum,data:any,item:any,isInsert:boolean,focus:boolean = true){
    super();
    this.type = type;
    this.data = data;
    this.item = item;
    this.isInsert = isInsert;
    this.focus = focus;
  }
}
export class SetItemEvent extends HelperPadEvent{
  type:HelperSourceEnum;
  item: any;
  isInsert:boolean;
  data:any
  constructor(type:HelperSourceEnum,item:any,isInsert:boolean,data:any){
    super();
    this.type = type;
    this.item = item;
    this.isInsert = isInsert;
    this.data = data;
  }
}
export declare type PadTab = {
  name: string;
  //code: TabCodes;
  id:number;
  prefTab:SubTab[]
  subTab:SubTab[],
  /** 資料來源，RX的話會從SubTab抓取value集合作為rxType過濾 */
  dataSource:TabDataSource,
}
export declare type TabDataSource = 'CC'|'PE'|'DX'|'RX'|'MS';
export declare type TabCodes = 'CC'|'PE'|'Diag'|'Drug'|'MainSet'|'HCare'|'SPC'
export declare class SubTab{
  name: string;
  value: number;
  isDefaultItem?:any;
  /** 過濾器，子tab的內容預設會已rxType對value過濾一次，多個subtab同value時可以以此方法設定額外過濾 */
  filter?:(code:(SystemCode|DxOpt|RxOpt))=>boolean;
}

export class CustomTab{
  name:string;
  id:number;
  dataSource:TabDataSource;
  sort:number;
  subTabs:CustomSubTab[];
}
export class CustomSubTab{
  name: string;
  value: number;
  filterCol:string;
  filterOp:string;
  filterValue:string|number;
  sort:number;
}
@Injectable()
export class HistHelperPadService /*extends HistUiEventService*/ implements OnDestroy {
  toPad = new EventEmitter<HelperPadCommand>();

  fromPad = new EventEmitter<HelperPadEvent>();
  diag:DiagsComponent;
  //資料插入紀錄
  insertRecord:Map<string, { start: number, text: string }[]> = new Map();
  currentTab: PadTab;

  setRecord(key,data:{ start: number, text: string }[]){
    this.insertRecord.set(key,data);
  }
  getRecords(key:string){
    return this.insertRecord.get(key)||[];
  }
  getDiag():HistDiag[]{
    return this.diag.diags;
  }
  constructor() {
    //super();
  }
  //#region Pad Command
  private sendToPad(evt:HelperPadCommand){
    this.toPad.emit(evt);
  }
  /** 最後焦點位置，提供給選盤重開時可以拿這個cmd來執行 */
  lastFocus:FocusCommand = null;
  public Command_FocusTo(to:HelpPadTab, data: any){
    // console.log('Helper tab focus to:', to);
    // this.currentTab = to;
    var focusCmd = new FocusCommand(to,data)
    this.lastFocus = focusCmd;
    this.sendToPad(focusCmd);
  }
  public Command_Close(){
    this.sendToPad(new CloseCommand());
  }
  //#endregion
  //#region Pad event
  public Pad_TabSelected(evt: AreaSelectedEvent){
    // console.log('Helper tab selected:', evt.tab);
    this.sendFromPad(evt);
  }

  public Pad_ItemSelected(evt: ItemSelectedEvent){
    // console.log('Helper tab item selected:', evt.type);
    this.sendFromPad(evt);
  }
  /** 設定門診編輯區資料 */
  public SetItem(evt: SetItemEvent){
    // console.log('Helper tab item selected:', evt.type);
    this.sendFromPad(evt);
  }
  public Pad_Initialed(evt: PadInitEvent){
    this.sendFromPad(evt);
  }
  private sendFromPad(evt:HelperPadEvent){
    this.fromPad.emit(evt);
  }
  //#endregion
  focusAtOrderClass(classCode: string,rxType:number[]) {
    //this.fromSource.next(HelperEvent.OrderClassEvent(classCode,rxType));
  }

  ngOnDestroy() {
    //super.ngOnDestroy();
  }
}
