<div class="card-body no-padding watch-list" [style.padding]="'0px'" [style.width]="width">
  <div class="grid-title">{{title}}</div>
  <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="true" tabindex="0" 
    (pageChange)="pageChange($event)" [kendoGridSelectBy]="'Id'" [tableFullWidth]="'w-100'" [showStickyHead]="true" [showStickyTableRows]="showStickyTableRows"
    [showStickyHeight]="560" [class]="'no-border'" [customClass]="'overflow-x-hidden'" [ngClass]="showSmallerTitle?'vis-history-bg':''" #reserveList>

    <app-vis-grid-column [sortable]="false" field="PeriodItemName" title="項目名稱" [width]="90" class="custom-m-auto">
      <ng-template let-dataItem>
        <div class="ellipsis-content space-between" title="{{dataItem.Remark}}">
          <span>{{dataItem.PeriodItemName}}</span>
          <mat-icon style="height: inherit;color: #6bd9f5;cursor: pointer;" class="controlled-font-size-middle" title="對應醫令：{{dataItem.RxCodes.join(',')}}">help</mat-icon>
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PeriodMonths" title="週期" [width]="40" class="text-center">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PreviousDate" title="上次" [width]="50" class="text-center">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="IsAllowExecute" title="可執行" [width]="40" class="text-center">
      <ng-template let-dataItem>
        <mat-icon>{{dataItem.IsAllowExecute?'check_circle':'close'}}</mat-icon>
      </ng-template>
    </app-vis-grid-column>
  </app-vis-grid>
</div>
