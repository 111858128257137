import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import * as QRCode from 'qrcode';
import { HcrService } from 'src/app/services/hcr-service.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { promise } from 'selenium-webdriver';
import { SessionService } from 'src/app/services/session.service';
import { HCASignRequestData, HcaInitData } from 'src/app/shared/hcr-lib/HcaInitData';
import { HistEditOption } from 'src/app/services/api-service/hist/hist-edit-option';
import { HistService } from 'src/app/hist/services/hist.service';
import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { RegisterApi } from 'src/app/services/api-service/register/register-api';
import { NHIRegIC } from 'src/app/services/api-service/register/nhiRegIc';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { fixSpecialChar, isSpecialChar } from 'src/app/shared/utilities';
import { OrderDispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';


/** 連續處方箋 */
@Component({
  selector: 'app-repeated-prescription',
  templateUrl: './repeated-prescription.component.html',
  styleUrls: ['./repeated-prescription.component.css']
})
export class RepeatedPrescriptionComponent implements OnInit {
  sizePage = {
    width: 21, //cm
    height: 29.7 //cm
  };
  a5hSizePage = {
    width: 21, //cm
    height: 14.8 //cm
  };
  a6SizePage = {
    // width: 28.7, //cm
    // height: 20 //cm
    width: 9.25, //cm
    height: 14 //cm
  };
  a6PaddingPage = {
      top: -0.5, //cm
      right: 0, //cm
      bottom: 0.2, //cm
      left: 0 //cm
  };
  @ViewChild('reportContainer')
  reportContainer:ElementRef<HTMLDivElement>;
  @ViewChild('reportContainerA6')
  reportContainerA6:ElementRef<HTMLDivElement>;

  hist:WholeHist;
  ic: NHIRegIC;
  clinic: Clinic;
  @ViewChild('qrcodeImg')
  qrcodeImg: ElementRef<HTMLImageElement>
  @ViewChild('qrcode2Img')
  qrcode2Img: ElementRef<HTMLImageElement>
  @ViewChild('qrcodeItreatnumImg')
  qrcodeItreatnumImg: ElementRef<HTMLImageElement>
  @ViewChild('qrcodeItreatnum2Img')
  qrcodeItreatnum2Img: ElementRef<HTMLImageElement>
  // @ViewChild('barcodeSvg', { static: false })
  // barcodeSvg: ElementRef<HTMLImageElement>;
  cidMask = '1234567890';
  bitrhMask = 'YYY年MM月DD日';
  isopenQRcode = false;
  textToEncode = 'sadasdas233dasdADD';
  normalCheck: string = 'V';
  cntPresCheck: string = '';
  repeatprehcaModule: { InitModule: number, InitSession: number }
  medcodeName: string;
  regdateString: string;
  checkSeven = false;
  isPrintItreatnum = true;
  sevenHRx: HistOrder[] = [];
  pageHRx: HistOrder[][] = [];
  fontType: string;
  isA5h = false;
  isA6 = false;
  multiPage = false;
  @Input()
  set regId(id) {
    this.setRegId(id);
  }
  constructor(private printer: LocalPrintService,
    private api: HistApi,
    private regApi: RegisterApi,
    private cdr: ChangeDetectorRef,
    private hcrService: HcrService,
    private histService: HistService,
    private notification: EasyNotificationService,
    private sessionService: SessionService,
    private parameterApi: ParameterApi,
    private clinicDataService: ClinicDataService
  ) {
  }
  get editOptions(): HistEditOption {
    return this.histService.EditOptions;
  }
  get dosages(): Dosage[] {
    return this.editOptions.dosage;
  }

  async ngOnInit() {

    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report table{ border:1px solid black; border-collapse: collapse; }\n' +
      '.pres-report table td, table th { border: 1px solid black; padding-left: 2px;padding-right: 2px;}\n' +
      '.pres-report table tr:first-child th { border-top: 0; }\n' +
      '.pres-report table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report table tr td:last-child, table tr th:last-child { border-right: 0; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
      this.reportContainerA6.nativeElement.appendChild(st);

    }, 0);
    st.outerHTML

  }
  backRegDate:string;
  deadLine:string;
  StartStr:string[] =['','','',''];
  EndStr:string[] =['','','',''];
  async setRegId(regId:number){
    // 處方籤 A4 or A6 Type
    var param = await this.clinicDataService.getParam("PRN001");
    this.isA6 = param?.RepeatPresType == 2;
    this.isA5h = param?.RepeatPresType == 3;
    this.StartStr=['','','',''];
    this.EndStr=['','','',''];
    this.clinic = UserCache.getLoginUser().Clinic;
    let userCId = UserCache.getLoginUser().CId;
    let userName = UserCache.getLoginUser().Name;
    let wholeHist = await this.api.getRepeatedPrescription(regId, this.signStr, this.clinic, userCId, userName, this.isopenQRcode);
    this.hist = wholeHist.WholeHist;
    this.hist.Hist.HRxs = this.hist.Hist.HRxs.filter(x => x.SPRule != 5);
    if (this.hist && !isNaN(this.hist?.Hist?.PPartRx)) this.hist.Hist.PPartRx = Math.abs(this.hist?.Hist.PPartRx);
    this.ic = wholeHist.NHIRegIC;
    //有發生案例是就醫識別碼中有特殊字元，所以要處理，不然會造成BarCode印製失敗。
    //案例: g  F128Y9S3BCQCWC\u0000\u0000\u0000
    if (isSpecialChar(this.ic.Itreatnum)) {
      this.ic.Itreatnum = fixSpecialChar(this.ic.Itreatnum);
    }
    let ispres = this.hist.Hist.CntPresTot != '' && this.hist.Hist.CntPresTot != null && this.hist.Hist.CntPresTot != undefined;
    if (ispres) {
      this.backRegDate = '　回診日：' + DateHelper.formatROCDate(wholeHist.BackRegDate, false);
      this.deadLine = '　截止日：' + DateHelper.formatROCDate(wholeHist.Deadline, false);
      if (wholeHist.PresDateStartAndEnds?.length > 0) {
        for (var i = 0; i < wholeHist.PresDateStartAndEnds.length; i++) {
          var pre = wholeHist.PresDateStartAndEnds.find(x => x.CntPresNo == (i + 1));
          var start = DateHelper.getFormatedDateString(pre?.StartDate, true);
          var firstReg = DateHelper.getFormatedDateString(wholeHist.FirstRegDate, true);
          if (i == 0)start = firstReg;
          var end = DateHelper.getFormatedDateString(pre?.EndDate, true);
          if (pre?.EndDate) this.StartStr[i] = start + '~'
          else this.StartStr[i] = start;
          this.EndStr[i] = end;
        }
      }
    }else{
      this.backRegDate = '';
      this.deadLine ='';
    }

    var request = new HCASignRequestData();
    request.QRCodeData = wholeHist.Data;
    request.InitModule = this.repeatprehcaModule?.InitModule;
    request.InitSession = this.repeatprehcaModule?.InitSession;
    if (this.isopenQRcode) {
      var sign = await this.hcrService.VisionApi.GetHCASign(request);
      this.signStr = sign?.Data;
      let regedit = await this.regApi.GetEditOptions();
      let medcode = this.hist.Register.MedDeptCode.toString();
      this.medcodeName = regedit?.section.find(x => x.value == medcode)?.text || medcode;
      this.regdateString = this.dateString(this.hist?.Register.RegDate);
      // console.log('this.signStr',this.signStr); //測試需要檢驗到
      if (sign.StatusCode == 0) {
        this.sessionService.setData('hcaModule', null);
        this.repeatprehcaModule.InitModule = null;
        this.repeatprehcaModule.InitSession = null;
      } else {
        this.notification.showWarning('QRCode印製失敗');
        return false;
      }
    }

    var prescriptData = await this.api.GetRepeatedPrescriptSignAndData(this.signStr, wholeHist.Data);

    if (ispres) {
      this.normalCheck = '';
      this.cntPresCheck = 'V';
      this.checkSeven =  this.hist.Hist.HRxs.some(x => x.RxCode == '/7');
    } else {
      this.normalCheck = 'V';
      this.cntPresCheck = '';
      this.checkSeven = false;
    }
    if (this.checkSeven) {
      let newhrx: HistOrder[] = [];
      for (var i = 0; i < this.hist.Hist.HRxs.length; i++) {
        if (this.hist.Hist.HRxs[i].RxCode == '/7') break;
        newhrx.push(this.hist.Hist.HRxs[i]);
      }
      let ids = newhrx.map(x => x.Id);
      this.sevenHRx = this.hist.Hist.HRxs.filter(x => !ids.includes(x.Id) && [2, 3, 4, 12].indexOf(x.RxType) >= 0 && x.RxCode != '/7');
      //this.hist.Hist.HRxs = newhrx.filter(x => [2, 3, 4, 12].indexOf(x.RxType) >= 0);
      let hrxs = newhrx.filter(x => [2, 3, 4, 12].indexOf(x.RxType) >= 0);
      this.hist.Hist.HRxs = this.hrxsToPage(hrxs);
    } else {
      //當筆數超過N筆時，印到第二頁
      // this.hist.Hist.HRxs = this.hist.Hist.HRxs.filter(x => [2, 3, 4, 12].indexOf(x.RxType) >= 0);
      let hrxs = this.hist.Hist.HRxs.filter(x => [2, 3, 4, 12].indexOf(x.RxType) >= 0);
      this.hist.Hist.HRxs = this.hrxsToPage(hrxs);
    }

    this.signData = prescriptData.SignData;
    //  var obj = {  }this.signStr

    var param = await this.clinicDataService.getParam("PRN001");
    this.cidMask = param.IDHidePRN ?? this.cidMask;
    this.bitrhMask = param.BirthdayHidePRN ?? this.bitrhMask;
    this.isPrintItreatnum = param.IsPrintDispPresItreatnum ?? true;
    this.fontType = (param.FontTypeForPrint == 0 ? '細明體' : (param.FontTypeForPrint == 1 ? '標楷體' : (param.FontTypeForPrint == 2 ? '微軟正黑體' : '細明體')));
  }
  getHtml(){
    if (this.hist && this.clinic && this.isopenQRcode) {
      this.generateQRCode(this.signData, 2);
      this.generateQRCode(this.certStr, 1);
    }

    if(this.ic && this.ic.Itreatnum){
      this.generateQRCode(this.ic?.Itreatnum,3);
    }

    let report = this.isA6 ? this.reportContainerA6 : this.reportContainer;
    var html = report.nativeElement.innerHTML;
    if (this.multiPage) {
      this.pageHRx.forEach((hrx, idx) => {
        //上面已有第一頁，所以從第二頁開始
        if (idx > 0) {
          this.hist.Hist.HRxs = hrx;
          this.cdr.detectChanges();
          html = html + report.nativeElement.innerHTML;
        }
      });
    }
    if (this.checkSeven && (this.hist.Hist.DispTP != OrderDispensingTypeEnum.T0_Clinic) ){
      this.normalCheck = 'V';
      this.cntPresCheck = '';
      this.StartStr=['','','',''];
      this.EndStr=['','','',''];
      this.backRegDate = '';
      this.deadLine = '';
      this.hist.Hist.HRxs = this.sevenHRx;
      this.hist.Hist.CntPresTot = '';
      this.cdr.detectChanges();
      html = html + report.nativeElement.innerHTML;
    }
    return html;
  }
  generateQRCode(text: string, type: number): void {
    QRCode.toDataURL(text, (err, url) => {
      if (err) {
        console.error(err);
        return;
      }
      // 将生成的二维码图片URL存储在一个变量中，或者直接在模板中使用
      // this.qrcodeImg.nativeElement.src = url;
      switch (type) {
        // 病歷
        case 1:
          this.qrcodeImg.nativeElement.src = url;
          break;
        //收據
        case 2:
          this.qrcode2Img.nativeElement.src = url;
          break;
        //就醫識別碼
        case 3:
          this.qrcodeItreatnumImg.nativeElement.src = url;
          this.qrcodeItreatnum2Img.nativeElement.src = url;
          break;
        //連處
        //連處
        default:
          break;
      }
    });
  }
  certStr: string;
  signStr: string;
  signData: string;
  async getQRCode(): Promise<boolean> {
    try {
      this.repeatprehcaModule = this.sessionService.getData('hcaModule');
      var hcaData = new HcaInitData();
      hcaData.UserPassword = '';
      hcaData.InitModule = this.repeatprehcaModule?.InitModule;
      hcaData.InitSession = this.repeatprehcaModule?.InitSession;
      var cert = await this.hcrService.VisionApi.GetHCACert(hcaData);
      this.certStr = cert.Data;

      // this.generateQRCode(sign.Data,2);
      return true;
    }
    catch (ex) {
      this.notification.showError('取得處方籤簽章失敗：' + ex);
      return false;
    }
  }

  dateString(input: Date): string {
    var d = new Date(input);
    var month = (d.getMonth() + 1);
    var day = d.getDate();
    var year = d.getFullYear();
    return year + '/' + month + '/' + day;
  }
  get dxNames() {
    return [this.hist?.Hist.Dx1Name, this.hist?.Hist.Dx2Name, this.hist?.Hist.Dx3Name,
    this.hist?.Hist.Dx4Name, this.hist?.Hist.Dx5Name, this.hist?.Hist.Dx6Name].filter(x => x).join(',');
  }
  get dxCodes() {
    return [this.hist?.Hist.Dx1, this.hist?.Hist.Dx2, this.hist?.Hist.Dx3,
    this.hist?.Hist.Dx4, this.hist?.Hist.Dx5, this.hist?.Hist.Dx6].filter(x => x).join(',');
  }

  stringFactory(src: string,maxlength :number): string {
    if (src == null || src == '' || src == undefined) return ''      // 使用正規表達式匹配中文字符
      const chineseCharacters = src.match(/[\u4e00-\u9fa5]/g);
      const fullWidthPattern = /[\uFF01-\uFF60\uFFE0-\uFFE6]/g;

      let initlength = 0;
      // 如果沒有中文字符，則返回整個字串的長度
      for (let ind = 0;ind <src.length; ind++) {
        let chineseAlpha = src[ind].match(/[\u4e00-\u9fa5]/g);
        let fullWidthAlpha = src[ind].match(/[\uFF01-\uFF60\uFFE0-\uFFE6]/g);
        if (chineseAlpha) initlength +=2;
        else if (fullWidthAlpha) initlength +=2;
        else initlength +=1;
        if (initlength >= maxlength){
          return src.substr(0, ind) + '..';
        }
      }
    return src;

  }

  getLimitCountPerPage() {
    //每頁限制筆數
    if (this.isA6) {
      //A6 (是否印就醫識別碼)
      return this.isPrintItreatnum ? 5 : 10;
    } else if (this.isA5h) {
      //A5橫式 (是否印就醫識別碼)
      return this.isPrintItreatnum ? 4 : 9;
    } else {
      //A4 (是否印就醫識別碼)
      return this.isPrintItreatnum ? 45 : 50;
    }
  }

  hrxsToPage(hrxs: HistOrder[]): HistOrder[] {
    //決定是否分頁multiPage，並將資料分頁pageHRx
    let limit = this.getLimitCountPerPage();
    let firstPage: HistOrder[] = [];
    this.multiPage = hrxs.length > 0 && hrxs.length > limit;
    if (this.multiPage) {
      this.pageHRx = hrxs.reduce((acc, cur, idx) => {
        const pageIndex = Math.floor(idx / limit);
        if (!acc[pageIndex]) {
          acc[pageIndex] = [];
        }
        acc[pageIndex].push(cur);
        return acc;
      }, []);
      firstPage = this.pageHRx[0];
    } else {
      firstPage = hrxs;
    }
    return firstPage;
  }
}
