import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CmsLayout,RepeatPresSetDto, RepeatPresCol, RepeatPresRow, cmsRepeatPres, RepeatPresStyle, RepeatData } from '../repeated-pres-models';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { RegisterApi } from 'src/app/services/api-service/register/register-api';
import { CmsReport, CmsReportApi } from 'src/app/services/api-service/cms-report/cms-report-api';
import { DrugBagInfo, RepeatedPrescriptionDto } from 'src/app/services/api-service/hist/whole-large-hist';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { style } from '@angular/animations';
import * as QRCode from 'qrcode';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { fixSpecialChar, isSpecialChar } from 'src/app/shared/utilities';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HistEditOption } from 'src/app/services/api-service/hist/hist-edit-option';

@Component({
  selector: 'app-repeated-pres-dynamic',
  templateUrl: './repeated-pres-dynamic.component.html',
  styleUrls: ['./repeated-pres-dynamic.component.css']
})
export class RepeatedPrescriptionDynamicComponent implements OnInit, AfterViewInit {

  private defaultFontSize = 14;
  private defaultFontFamily='細明體';

  private data:RepeatData;
  private report:CmsReport;
  sevenHRx: HistOrder[] = [];
  pageHRx: HistOrder[][] = [];
  checkSeven = false;
  isPrintItreatnum = true;
  tmpRepeatPresDto: RepeatedPrescriptionDto;
  printRxType: number[] = [];

  @Input()
  set cmsData(v:cmsRepeatPres){
    this._cmsData = v;
    this.updatePrintStyle();
  } 
  _cmsData = new cmsRepeatPres();
  clinic : Clinic;
  get cmsData() {
    return this._cmsData;
  }
  @ViewChild('reportContainer',{static:false})
  reportContainer: ElementRef<HTMLDivElement>;
  @ViewChild('qrcodeItreatnumImg')
  qrcodeItreatnumImg: ElementRef<HTMLImageElement>
  constructor(private histApi:HistApi,
    private regApi:RegisterApi,
    private cmsReportApi:CmsReportApi,
    private clinicData:ClinicDataService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    //this.setRegId(10267)
    
  }
  async ngAfterViewInit(){
    await this.updatePrintStyle()
  }

  async getReport(code:string){
    try{
      this.report =  await this.cmsReportApi.GetByCode(code);
      if(!this.report){
        throw '找不到報表'
      }
      if(this.report.Type == 2){
        this.cmsData = JSON.parse(this.report.Template);
      }
      await this.updatePrintStyle();
    }catch(e){
      throw e;
    }
  }
  async updatePrintStyle():Promise<void>{
    await new Promise<void>((rs,rj)=>{
      setTimeout(() => {
        if (this.reportContainer) {
          var styleNode = this.reportContainer.nativeElement.getElementsByTagName('style').item(0)
          if(styleNode){
            this.reportContainer.nativeElement.removeChild(styleNode)
          }
          
          var ss = this.cmsData.width+' '+this.cmsData.height;
          var st = document.createElement('style');
          var node = document.createTextNode(
            '@page { size: ' + ss + '; margin: 0cm; }\n' +
            '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;} div { margin:0;padding:0;}}'
          );
          
          st.appendChild(node);
          st.id = 'st';
          this.reportContainer.nativeElement.appendChild(st);
          this.cdr.markForCheck();
        }
        rs();
      }, 0);
    })
    
  }
  async setRegId(regId: number) {
    this.checkSeven = false;
    this.sevenHRx = [];
    var param = await this.clinicData.getParam("PRN001");
    var param2 = await this.clinicData.getParam("DRG002");
    this.printRxType = param2?.RxOrderType;
    let newDto = new RepeatPresSetDto();
    this.clinic = UserCache.getLoginUser().Clinic;
    let userCId = UserCache.getLoginUser().CId;
    let userName = UserCache.getLoginUser().Name;
    let histAndIc = await this.histApi.getRepeatedPrescription(regId, '', this.clinic, userCId, userName, false)
    var opt1 = await this.histApi.getEditOptions();
    var opt2 = await this.regApi.GetEditOptions();
    let whist = histAndIc.WholeHist;
    whist.Hist.HRxs = whist.Hist.HRxs.filter(x => x.SPRule != 5);
    if (whist && !isNaN(whist?.Hist?.PPartRx)) whist.Hist.PPartRx = Math.abs(whist?.Hist.PPartRx);
    //有發生案例是就醫識別碼中有特殊字元，所以要處理，不然會造成BarCode印製失敗。
    //案例: g  F128Y9S3BCQCWC\u0000\u0000\u0000
    if (isSpecialChar(histAndIc.NHIRegIC.Itreatnum)) {
      histAndIc.NHIRegIC.Itreatnum = fixSpecialChar(histAndIc.NHIRegIC.Itreatnum);
    }
    let ispres = whist.Hist.CntPresTot != '' && whist.Hist.CntPresTot != null && whist.Hist.CntPresTot != undefined;
    if (ispres) {
      newDto.BackRegDate = '　回診日：' + DateHelper.formatROCDate(histAndIc.BackRegDate, false);
      newDto.DeadLine = '　截止日：' + DateHelper.formatROCDate(histAndIc.Deadline, false);
      if (histAndIc.PresDateStartAndEnds?.length > 0) {
        for (var i = 0; i < histAndIc.PresDateStartAndEnds.length; i++) {
          var pre = histAndIc.PresDateStartAndEnds.find(x => x.CntPresNo == (i + 1));
          var start = DateHelper.getFormatedDateString(pre?.StartDate, true);
          var firstReg = DateHelper.getFormatedDateString(histAndIc.FirstRegDate, true);
          if (i == 0)start = firstReg;
          var end = DateHelper.getFormatedDateString(pre?.EndDate, true);
          if (pre?.EndDate) newDto.StartStr[i] = start + '~'
          else newDto.StartStr[i] = start;
          newDto.EndStr[i] = end;
        }
      }
    }else{
      newDto.BackRegDate = '';
      newDto.DeadLine ='';
    }

    if (ispres) {
      newDto.IsNormal = false;
      newDto.IsPres = true;
      this.checkSeven =  whist.Hist.HRxs.some(x => x.RxCode == '/7');
    } else {
      newDto.IsNormal = true;
      newDto.IsPres = false;
      this.checkSeven = false;
    }
    if (this.checkSeven) {
      let newhrx: HistOrder[] = [];
      for (var i = 0; i < whist.Hist.HRxs.length; i++) {
        if (whist.Hist.HRxs[i].RxCode == '/7') break;
        newhrx.push(whist.Hist.HRxs[i]);
      }
      let ids = newhrx.map(x => x.Id);
      // this.sevenHRx = whist.Hist.HRxs.filter(x => !ids.includes(x.Id) && [2, 3, 4, 12].indexOf(x.RxType) >= 0 && x.RxCode != '/7');
      this.sevenHRx = whist.Hist.HRxs.filter(x => !ids.includes(x.Id) && this.printRxType.indexOf(x.RxType) >= 0 && x.RxCode != '/7');
      whist.Hist.HRxs = newhrx;

    }

    this.data = new RepeatData(histAndIc,param,opt1,opt2,newDto);
    if(this.sevenHRx?.length > 0) this.tmpRepeatPresDto = histAndIc;
  }
  async getOther(){
    var param = await this.clinicData.getParam("PRN001");
    let newDto = new RepeatPresSetDto();
    newDto.IsNormal = true;
    newDto.IsPres = false;
    var opt1 = await this.histApi.getEditOptions();
    var opt2 = await this.regApi.GetEditOptions();
    this.tmpRepeatPresDto.WholeHist.Hist.HRxs = this.sevenHRx;
    this.data = new RepeatData(this.tmpRepeatPresDto,param,opt1,opt2,newDto);
    this.tmpRepeatPresDto = null;
    this.sevenHRx = [];
  }
  getHRx(){
    if(!this.data){
      return [];
    }
    return this.data.getHrx();
  }
  getData(col:RepeatPresCol,arg:{tp:'hrx',ind:number}){
    if(!this.data){
      return '';
    }
    if(arg?.tp=='hrx'){
      return this.data.getHrxData(arg.ind,col.dataField);
    }else if (col.isQRcode){
      var val = this.data.getData(col.dataSource,col.dataField);
      this.generateQRCode(val)
    }
    else{
      return this.data.getData(col.dataSource,col.dataField);
    }
    
  }
  mergeStyle(a:RepeatPresStyle,b:RepeatPresStyle,notmerge:boolean = false){
    if ( notmerge){
       a.border = null;
       a.borderBottom =  null;
       a.borderLeft =  null;
       a.borderRight =  null;
       a.borderTop =  null;
    } 
    var r =  Object.assign({},a,b);
    //console.log(i,r)
    return r;
  }
  sizeStyle(){
    return{
      width:this.cmsData.width,
      height:this.cmsData.height,
      padding:this.cmsData.padding,
      //width:this.cmsData.width.indexOf('in')>0?('calc('+this.cmsData.width+' / 0.96)'):this.cmsData.width,
      //height:this.cmsData.height.indexOf('in')>0?('calc('+this.cmsData.height+' / 0.96)'):this.cmsData.height,
    }
  }
  innerSizeStyle(){
    return{
      width:this.cmsData.isHelf?'50%':'100%',
      height:'100%',
      overflow:'hidden'
    }
  }
  rowStyle(row:RepeatPresRow,st:RepeatPresStyle){
     return{
      flexWrap:row.wrap?'wrap':'nowrap',
      columnGap:(row.spacing||0)*(st?.fontSize||this.defaultFontSize)/2 +'px',
    }
  }
  style(st:RepeatPresStyle,value:string,isDiv= false){
    var s = {
      marginLeft:(st?.padLeft||0)*(st?.fontSize||this.defaultFontSize)/2+'px',
      fontSize:(st.fontSize||this.defaultFontSize)+'px',
      fontFamily:st.fontFamily||this.defaultFontFamily,
      textAlign:st.textAlign||'left',
      textOverflow:st.textOverflow??'clip',
      border:st.border > 0 ? st.border + 'px black solid':'',
      lineHeight:st.lineheight > 0? (st.lineheight*1.25*100).toString() +'%':'125%',
    }
    if (!st.border){
      s = Object.assign(s,{
        borderTop:st.border > 0 ?'':(st.borderTop > 0 ?  st.borderTop + 'px black solid':''),
        borderBottom: st.border > 0 ?'':(st.borderBottom > 0 ?  st.borderBottom + 'px black solid':''),
        borderLeft: st.border > 0 ?'':(st.borderLeft > 0 ?  st.borderLeft + 'px black solid':''),
        borderRight: st.border > 0 ?'': (st.borderRight > 0 ?  st.borderRight + 'px black solid':''),
      });

    }
    if(st.width && !isDiv){
            s = Object.assign(s,{
        width: st.width + 'cm',
        maxWidth: st.width + 'cm',
        minWidth: st.width + 'cm',
      });
      // s = Object.assign(s,{
      //   width:(st?.width||0)*(st?.fontSize||this.defaultFontSize)/2+'px',
      //   minWidth:(st?.width||0)*(st?.fontSize||this.defaultFontSize)/2+'px',
      //   maxWidth:(st?.width||0)*(st?.fontSize||this.defaultFontSize)/2+'px'
      // });
    }
    return s;
      
  }
  styleBorder(st:RepeatPresStyle,value:string,isDiv= false){
    var s = {
      textOverflow:st.textOverflow??'clip',
      border:st.border,
      // borderTop:st.border ?'':st.borderTop,
      // borderBottom: st.border ?'':st.borderBottom,
      // borderLeft: st.border ?'':st.borderLeft,
      // borderRight: st.border ?'':st.borderRight,
    }
    if (!st.border){
      s = Object.assign(s,{
        borderTop:st.border ?'':st.borderTop,
        borderBottom: st.border ?'':st.borderBottom,
        borderLeft: st.border ?'':st.borderLeft,
        borderRight: st.border ?'':st.borderRight,
      });

    }
    return s;
      
  }
  generateQRCode(text: string): void {
    QRCode.toDataURL(text, (err, url) => {
     this.qrcodeItreatnumImg.nativeElement.src = url;
    });
  }

  // async getHtml() {
  //   // if (this.reportContainer) {
  //   //   var html = this.reportContainer.nativeElement.innerHTML;
  //   //   return html;
  //   // }
  //   let html = '';
  //   await new Promise<void>((rs,rj)=>{
  //     setTimeout(() => {
  //       // 置入容器
  //          if (this.reportContainer) {
  //           html = this.reportContainer.nativeElement.innerHTML;
  //         }
  //       rs();
  //     }, 0);
  //   })
  //   return html;
  // }
  async getHtml(){

    var html = this.reportContainer.nativeElement.innerHTML;
    if (this.sevenHRx?.length > 0 && this.tmpRepeatPresDto){
      await this.getOther();
      this.cdr.detectChanges();
      html = html + this.reportContainer.nativeElement.innerHTML;
    }
    return html;
  }
  // hrxsToPage(hrxs: HistOrder[]): HistOrder[] {
  //   //決定是否分頁multiPage，並將資料分頁pageHRx
  //   let limit = this.getLimitCountPerPage();
  //   let firstPage: HistOrder[] = [];
  //   this.multiPage = hrxs.length > 0 && hrxs.length > limit;
  //   if (this.multiPage) {
  //     this.pageHRx = hrxs.reduce((acc, cur, idx) => {
  //       const pageIndex = Math.floor(idx / limit);
  //       if (!acc[pageIndex]) {
  //         acc[pageIndex] = [];
  //       }
  //       acc[pageIndex].push(cur);
  //       return acc;
  //     }, []);
  //     firstPage = this.pageHRx[0];
  //   } else {
  //     firstPage = hrxs;
  //   }
  //   return firstPage;
  // }
  getLimitCountPerPage() {
    //每頁限制筆數
    return 45;
    // if (this.isA6) {
    //   //A6 (是否印就醫識別碼)
    //   return this.isPrintItreatnum ? 5 : 10;
    // } else if (this.isA5h) {
    //   //A5橫式 (是否印就醫識別碼)
    //   return this.isPrintItreatnum ? 4 : 9;
    // } else {
    //   //A4 (是否印就醫識別碼)
    //   return this.isPrintItreatnum ? 45 : 50;
    // }
  }
}
