<div class="d-block px-1 position-relative" [ngStyle]="{width:maxRegMenuOpen?'100%':'58.333333%'}">
  <div style="direction: rtl;display: flex;">
    <button type="button" class="title-btn2 orders-btn end-0 p-0 background-none" style="min-height: 20px;">
      <mat-icon style="width: 16px;height: 16px;line-height: 16px;font-size: 20px;"
        class="vis-combine-icon element-icon btn-scale-85" (click)="handleTabInfoToggle();">
        <ng-container *ngIf="maxRegMenuOpen">call_to_action</ng-container>
        <ng-container *ngIf="!maxRegMenuOpen">web_asset</ng-container>
      </mat-icon>
    </button>
  </div>
  <div class="d-flex flex-wrap-wrap reg-menu">
    <ng-container *ngFor="let data of menuData; let i = index">
      <button class="flex-grow-one text-center m-auto" [ngClass]="registerResizeService.bigRatio===false?'':'px-0'"
        (click)="getTabInfo(i)" [ngClass]="infoTabState[i]">
        {{ data.label }}
      </button>
    </ng-container>
  </div>
  <div class="d-flex mt-2">
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[0]">
      <!-- 重大傷病 -->
      <app-vis-grid [data]="criIllNoHPC" [pageable]="true" [skip]="0" [pageSize]="5" [customClass]="''">
        <app-vis-grid-column [title]="'病代'" [field]="'Code'" [width]="147"></app-vis-grid-column>
        <app-vis-grid-column [title]="'病名'" [field]="'Name'" [width]="148"></app-vis-grid-column>
        <app-vis-grid-column [title]="'起日'" [field]="'StartDate'" [width]="148"
          class="text-center custom-m-auto">
          <ng-template let-data>
            {{data.StartDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [title]="'迄日'" [field]="'EndDate'" [width]="164"
          class="text-center custom-m-auto">
          <ng-template let-data>
            {{data.EndDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
          </ng-template>
        </app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[1]">
      <!-- 主次診斷 -->
      <app-vis-grid [data]="treatmentHPC" [customClass]="''" [pageable]="true" [skip]="0" [pageSize]="5">
        <app-vis-grid-column [title]="'就醫日'" [field]="'Date'" [width]="176" class="text-center custom-m-auto">
          <ng-template let-data>
            <div class="ellipsis-content" [title]="data.Date | formatROCDate">{{data.Date | formatROCDate }}
            </div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [title]="'主診斷'" [field]="'Dx1'" [width]="77"></app-vis-grid-column>
        <app-vis-grid-column [title]="'次診斷1'" [field]="'Dx2'" [width]="90"></app-vis-grid-column>
        <app-vis-grid-column [title]="'次診斷2'" [field]="'Dx3'" [width]="90"></app-vis-grid-column>
        <app-vis-grid-column [title]="'次診斷3'" [field]="'Dx4'" [width]="90"></app-vis-grid-column>
        <app-vis-grid-column [title]="'次診斷4'" [field]="'Dx5'" [width]="90"></app-vis-grid-column>
        <app-vis-grid-column [title]="'次診斷5'" [field]="'Dx6'" [width]="90"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[2]">
      <!-- 重要醫令 -->
      <app-vis-grid [scroll]="false" class="small-paginater w-100"
        [showMultiLineHead]="true" [customClass]="''" [pageable]="true" [skip]="0" [pageSize]="5"
        [data]="pagedHVE" (pageChange)="HVEPageChanged($event)">
        <app-vis-grid-column [title]="'就醫日'" [field]="'ImplementDate'" [width]="77"
          class="text-center custom-m-auto">
          <ng-template let-data>
            <div class="ellipsis-content" [title]="data.ImplementDate | formatROCDate">{{data.ImplementDate |
              formatROCDate }}</div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [title]="'醫療院所代碼'" [field]="'ClinicCode'" [width]="141"></app-vis-grid-column>
        <app-vis-grid-column [title]="'重要醫令項目代碼'" [field]="'TreatmentCode'"
          [width]="184"></app-vis-grid-column>
        <app-vis-grid-column [title]="'實施部位代碼'" [field]="'ImplementPartCode'"
          [width]="141"></app-vis-grid-column>
        <app-vis-grid-column [title]="'總量'" [field]="'Total'" [width]="63"
          class="text-right"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[3]">
      <!-- 過敏藥品 -->
      <div style="display: flex;flex-shrink: 1;flex-grow: 1;">
        <div style="flex-grow: 1;">
          <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug1">
          <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug2">
          <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug3">
          <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug4">
          <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug5">
        </div>
        <button class="w-24  rounded-5 ml-1 vis-btn-warning" style="min-height: 6rem;">過敏藥寫入IC
          <mat-icon role="img" title="IC" aria-hidden="true" data-mat-icon-type="font"
            class="mat-icon notranslate text-base position-relative d-inline-flex material-icons mat-icon-no-color h-auto w-auto"
            style="transform: rotate(-90deg); font-size: 3rem;"> sim_card</mat-icon>
        </button>
      </div>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[4]">
      <!-- 門診醫令 -->
      <app-vis-grid [scroll]="false" [customClass]="''" class="small-paginater w-100"
        [pageable]="true" [skip]="0" [pageSize]="10" [data]="pagedOutPatient" [showMultiLineHead]="true"
        (pageChange)="outpatientPageChanged($event)">
        <app-vis-grid-column [title]="'就醫日'" [field]="'ClinicDateTime'" [width]="145"
          class="text-center custom-m-auto">
          <ng-template let-data>
            <div class="ellipsis-content" [title]="data.ClinicDateTime | formatROCDate">{{data.ClinicDateTime
              | formatROCDate }}</div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [title]="'醫令類別'" [field]="'OrderClass'" [width]="64"
          class="text-center order-class"></app-vis-grid-column>
        <app-vis-grid-column [title]="'診療項目代號'" [field]="'NhiDrugCode'" [width]="117"></app-vis-grid-column>
        <app-vis-grid-column [title]="'診療部位'" [field]="'TreatmentSite'" [width]="64"
          class="treatment-site"></app-vis-grid-column>
        <app-vis-grid-column [title]="'用法'" [field]="'Frequency'" [width]="47"
          class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [title]="'天數'" [field]="'Days'" [width]="47"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'總量'" [field]="'TotalDose'" [width]="64"
          class="total-dose text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'交付處方註記'" [field]="'DispensingType'"
          [width]="122"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[5]">
      <!-- 長期醫令 -->
      <app-vis-grid [scroll]="false" [customClass]="''" class="small-paginater w-100"
        [pageable]="true" [skip]="0" [pageSize]="5" [data]="pagedLongTerm"
        (pageChange)="longTermPageChanged($event)">
        <app-vis-grid-column [title]="'開立日期'" [field]="'CreateDate'" [width]="160"
          class="text-center custom-m-auto">
          <ng-template let-data>
            <div class="ellipsis-content" [title]="data.CreateDate | formatROCDate">{{data.CreateDate |
              formatROCDate }}</div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [title]="'藥品代碼'" [field]="'NhiDrugCode'" [width]="160"></app-vis-grid-column>
        <app-vis-grid-column [title]="'用法'" [field]="'Frequency'" [width]="92"
          class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [title]="'天數'" [field]="'Days'" [width]="92"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'總量'" [field]="'TotalDose'" [width]="103"
          class="text-right"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[6]">
      <!-- 器官捐贈 -->
      <input class="vis-input w-100" readonly [value]="OrganDonate" />
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[7]">
      <!-- 就醫資料 -->
      <app-vis-grid [scroll]="true" [pageable]="true" [skip]="0" [pageSize]="5"
        [customClass]="''" [showMultiLineHead]="true" [tableFullWidth]="'w-100'" [data]="treatmentNoHPC">
        <app-vis-grid-column [title]="'就醫時間'" [field]="'Date'" [width]="270"></app-vis-grid-column>
        <!-- <app-vis-grid-column [title]="''" [field]="'Name'" class="text-center"></app-vis-grid-column> -->
        <app-vis-grid-column [title]="'卡序'" [field]="'Isqno'" [width]="70"></app-vis-grid-column>
        <app-vis-grid-column [title]="'院所'" [field]="'Clinic'" [width]="164"></app-vis-grid-column>
        <app-vis-grid-column [title]="'類別'" [field]="'Itp'" [width]="70"
          class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [title]="'依附'" [field]="'NewBorn'" [width]="70"
          class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [title]="'補卡'" [field]="'Recard'" [width]="70"
          class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [title]="'門診部分負擔'" [field]="'Deductible'" [width]="178"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'門診醫療費用'" [field]="'MedicalFees'" [width]="178"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'住院醫療費用'" [field]="'HospitalFees'" [width]="178"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'住院部分負擔A'" [field]="'HdeductibleA'" [width]="178"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'住院部分負擔B'" [field]="'HdeductibleB'" [width]="178"
          class="text-right"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[8]">
      <!-- 就醫費用 -->
      <app-vis-grid [scroll]="false" [customClass]="''" [data]="cumulatives" [showMultiLineHead]="true"
        [pageable]="true" [skip]="0" [pageSize]="5">
        <app-vis-grid-column [title]="'年度'" [field]="'Year'" [width]="80"
          class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [title]="'門住診次數'" [field]="'CimulativeCount'" [width]="80"
          class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [title]="'門診費用'" [field]="'MedicalFeesSum'" [width]="68"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'住診費用'" [field]="'HospitalFeesSum'" [width]="68"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'門診負擔'" [field]="'DeductibleSum'" [width]="68"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'住診負擔1'" [field]="'HdeductibleASum'" [width]="75"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'住診負擔2'" [field]="'HdeductibleBSum'" [width]="75"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'門住診負擔'" [field]="'MHdeductibleSum'" [width]="88"
          class="text-right"></app-vis-grid-column>
        <app-vis-grid-column [title]="'門住1診負擔'" [field]="'MHdeductibleASum'" [width]="88"
          class="text-right"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[9]">
      <!-- 預防保健 -->
      <app-vis-grid [scroll]="true" [customClass]="''" [tableFullWidth]="'w-100'" [pageable]="true" [skip]="0"
        [pageSize]="5" [data]="registerPreventSubset">
        <app-vis-grid-column [title]="'檢查日期'" [field]="'ExamDate'" [width]="107"
          class="text-center custom-m-auto">
          <ng-template let-data>
            <div class="ellipsis-content" [title]="data.ExamDate | formatROCDate">{{data.ExamDate |
              formatROCDate }}</div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [title]="'檢查項目代碼'" [width]="150" [field]="'ProjectCode'"></app-vis-grid-column>
        <app-vis-grid-column [title]="'醫療院所代碼'" [width]="150" [field]="'ClinicCode'"></app-vis-grid-column>
        <app-vis-grid-column [title]="'保健服務項目註記'" [width]="200" [field]="'HealthCareFlag'"
          class="text-center"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[10]">
      <!-- 產前檢查 -->
      <app-vis-grid [scroll]="false" [showMultiLineHead]="true" [customClass]="''"
        [data]="pagedRegisterPregnant" class="small-paginater" [pageSize]="5" [pageable]="true" [skip]="0"
        (pageChange)="registerPregnantPageChanged($event)">
        <app-vis-grid-column [title]="'檢查日期'" [field]="'ExamDate'" [width]="155"
          class="text-center custom-m-auto">
          <ng-template let-data>
            <div class="ellipsis-content" [title]="data.ExamDate | formatROCDate">{{data.ExamDate |
              formatROCDate }}</div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [title]="'醫療院所代碼'" [width]="220" [field]="'ClinicCode'"></app-vis-grid-column>
        <app-vis-grid-column [title]="'檢查項目代碼'" [width]="230" [field]="'ExamCode'"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="infoTabState[11]">
      <!-- 預防接種 -->
      <app-vis-grid class="small-paginater" [customClass]="''" [showMultiLineHead]="true"
        [scroll]="false" [pageSize]="5" [pageable]="true" [skip]="0" [data]="pagedInoculateData"
        (pageChange)="inoculateDataPageChanged($event)">
        <app-vis-grid-column [title]="'接種日期'" [field]="'InoculateDate'" [width]="135"
          class="text-center custom-m-auto">
          <ng-template let-data>
            {{data.InoculateDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
          </ng-template></app-vis-grid-column>
        <app-vis-grid-column [title]="'醫療院所代碼'" [field]="'ClinicCode'" [width]="194"></app-vis-grid-column>
        <app-vis-grid-column [title]="'疫苗種類'" [field]="'Type'" [width]="135"></app-vis-grid-column>
        <app-vis-grid-column [title]="'疫苗批號'" [field]="'LotNumber'" [width]="144"></app-vis-grid-column>
      </app-vis-grid>
    </div>
  </div>
</div>
