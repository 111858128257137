

/**
 * 預防接種
 */ 
export class InoculateData {
    /**
     * 疫苗種類
     */ 
    public Type: string = null;
    /**
     * 接種日期
     */ 
    public InoculateDate: Date = new Date(0);
    /**
     * 醫療院所代碼
     */ 
    public ClinicCode: string = null;
    /**
     * 疫苗批號
     */ 
    public LotNumber: string = null;
}
