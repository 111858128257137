import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, HostListener, ViewChild, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MainLayoutService } from '../services/main-layout.service';
import { AuthenticationService } from 'src/app/security/services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { BaseConfig } from 'src/app/services/base-config.service';
import { EasyNotificationService, NotifyMessage } from 'src/app/services/easy-notification.service';
import { Router, NavigationEnd, DefaultUrlSerializer, UrlSegmentGroup, UrlSegment } from '@angular/router';
import { LoginUser } from 'src/app/permission/models/login-user';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { LoginMenu } from 'src/app/permission/models/login-menu';
import { MenuComponent } from '../menu/menu.component';
import { HcrService } from 'src/app/services/hcr-service.service';
import { ConfirmData, UserConfirmService } from 'src/app/services/user-confirm.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { StartUpService } from 'src/app/services/start-up-service';
import { ReportHelperComponent } from 'src/app/system/report/report-helper/report-helper.component';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { FontGreatService } from 'src/app/services/font-great.service';
import { HistService } from 'src/app/hist/services/hist.service';
import { PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { PatientSelectService } from 'src/app/services/patient-select.service';
import { PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { TutorialService, TutorialTip } from 'src/app/services/tutorial-service';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('printHelper')
  printHelper: ReportHelperComponent;

  @ViewChild('mainContent')
  mainContent: ElementRef<HTMLDivElement>;

  @ViewChild('qrcode')
  QRCodeReader:VisDialogComponent;
  isopened = true;
  isShowMarquee = (new Date().getTime())<(new Date('2024-09-22 07:00:00').getTime());
  private api: WebApiService;
  private menu: LoginMenu;
  constructor(
    private router: Router,
    private mainLayoutService: MainLayoutService,
    private auth: AuthenticationService,
    private notificationDI: EasyNotificationService,
    private webApiFactory: WebApiClient,
    private hcrService: HcrService,
    private userConfirmService: UserConfirmService,
    private patientSelectService: PatientSelectService,
    private tutorialService: TutorialService,
    private printService: LocalPrintService,
    private startUp: StartUpService,
    private fontGreatService: FontGreatService,

    private histService: HistService,
    private routeInfoService: RouteInfoService,
    private clinicData:ClinicDataService) {

    mainLayoutService.mainlayoutComp = this;
    this.userConfirmService.mainlayout = this;
    this.patientSelectService.mainLayoutCmp = this;
    this.tutorialService.mainLayoutCmp = this;
    mainLayoutService.pageTitle$.pipe(takeUntil(this.unsubscribe)).subscribe(
      pageTitle => {
        setTimeout(() => {
          if (pageTitle == null) {
            this.updatePageTitle()
          } else {
            this.pageTitle = pageTitle;
          }

        }, 0);
      }
    );
    mainLayoutService.isShowMenu$.pipe(takeUntil(this.unsubscribe)).subscribe(
      isShowMenu => {
        this.isShowMenu = isShowMenu;
        this.updateMenuCSS();
      }
    );
    this.switchMenuSubscription = this.mainLayoutService.switchMenu$.subscribe(
      (b) => {
        if (b) {
          this.isShowMenu = !this.isShowMenu;
          this.updateMenuCSS();
        }
      }
    );
    this.notification = this.notificationDI;
    this.notification.OnMessage.subscribe((msg) => {
      this.msgs.push(msg);
    })
    this.notification.OnCloseMessage.subscribe((id) => {
      this.closeMsgById(id);
    })
    // console.log('main layout loaded')
    this.routerHandler(router);
    this.api = this.webApiFactory.createHisService('permission/menu');

  }

  notification: EasyNotificationService;

  private unsubscribe: Subject<void> = new Subject();
  private switchMenuSubscription: Subscription;

  get isUseTopMenu(): boolean {
    if (BaseConfig.getConfig().env.menuType === 'top') {
      return true;
    } else {
      return false;
    }
  }

  get isUseLeftMenu(): boolean {
    if (BaseConfig.getConfig().env.menuType === 'left') {
      return true;
    } else {
      return false;
    }
  }
  readonly isShowPageTitle = BaseConfig.getConfig().env.isShowPageTitle;
  pageTitle = '';
  isShowMenu = true;
  error: any = null;
  isLoaded = false;
  layoutForMenuCssClass = '';
  isHist: boolean = false;

  onDoubleClick(){
    if (this.isopened) this.isopened = false;
    else this.isopened = true;
  }
  async onClickMarquee(){
    var msgcon='為了提供更完善的服務\n'+
        '將配合微軟進行機房轉移作業\n'+
        '轉移過程會暫時停止系統服務\n'+
        '暫停時間：2024/09/21(六) 23:00 ~ 2024/09/22(日) 07:00\n\n'+
        '如作業提前完成，將會提前恢復服務\n'+
        '造成您的不便，敬請見諒';
        var ret = await this.userConfirmService.showConfirm({
          title:'公告',
          msg: msgcon,
          width:450,
          textYes:'確認',
          hideNo:true
        });
  }
  updateMenuCSS() {
    // 避免因為其他Subscribe改變狀態引起ExpressionChangedAfterItHasBeenCheckedError
    // 所以統一扔到下一個生命周期進行
    setTimeout(() => {
      if (!this.isUseTopMenu) {
        this.layoutForMenuCssClass = 'without-top-menu';
      }
      if (this.isShowMenu) {
        this.layoutForMenuCssClass = '';
      } else {
        this.layoutForMenuCssClass = 'without-top-menu';
      }
    }, 0);
  }


  async ngOnInit() {
    await this.updateNotifyMode()
    await this.startUp.start();
    this.clinicData.onReload.subscribe(async ()=>{
      await this.updateNotifyMode()
    });
    this.clinicData.onParamChanged.subscribe(async n=>{
      if(n.some(x=>x=='SYS101')){
        await this.updateNotifyMode();
      }
    })
    this.hcrService.Connect().catch(e => { }).then(async () => {
      await this.startUp.getTOCCBulletin().then(async () => {
        setTimeout(async () => {
          await this.startUp.getAnnoucementAlert();
        }, 500);
      });
    });
    // console.log('GET MENU')
    this.api.get('GetMenu').subscribe(r => {
      this.menu = r;
      UserCache.getLoginUser().Menus = r.data.children;
      this.isLoaded = true;
      // console.log(this.menu)
      // 重抓
      this.mainLayoutService.setPageTitle(null)
    });
    setTimeout(() => {
      if (this.histService.isHist) {
        this.isHist = this.histService.isHist;
      }

    }, 500)
  }

  private routerHandler(router: Router) {
    router.events.filter(e => e instanceof NavigationEnd).subscribe((e: NavigationEnd) => {
      this.updatePageTitle();
    });
  }
  private updatePageTitle() {
    let url = this.routeInfoService.CurrentUrl;
    // route有可能以;做參數傳遞，移除;以後的東西
    const position = url.indexOf(';');
    if (position > 0) {
      url = url.substring(0, position);
    }
    // 分析URL
    const urlParser = new DefaultUrlSerializer();
    const urlTree = urlParser.parse(url);
    const g: UrlSegmentGroup = urlTree.root.children['primary'];
    if (!g || !g.segments) {
      // 根目錄，沒有任何route path
      return;
    }

    const s: UrlSegment[] = g.segments;
    // 取得route path不含Company Code的部分
    // 第一個segment是companyCode
    let path = s.filter((u, i) => i > 0).join('/');
    // 找不到不顯示(個功能額外route到的頁面)
    var pageTitle = ''
    // 將Menu的Text設為PageTitle
    var user = UserCache.getLoginUser();
    if (user.Menus) {

      pageTitle = this.makePageTitle(path, {
        type: 'Node',
        children: user.Menus,
        text: '',
        functionCode: '',
        order: 0,
        id: 0,
        funcSystemId: 1,
      })

      // user.Menus.forEach(barItem => {
      //   if (barItem.type === 'Node' && barItem.children) {
      //     barItem.children.forEach(subItem => {
      //       if (subItem.path && subItem.path === path) {
      //         pageTitle = subItem.text;
      //       }
      //     });
      //   } else {
      //     if(barItem.path && barItem.path === path) {
      //       pageTitle = barItem.text;
      //     }
      //   }
      // });
    }
    // console.log(path);
    this.mainLayoutService.setPageTitle(pageTitle);
    // 預設show menu，每次都設不然某些畫面關閉後，這邊又要打開
    // this.mainLayoutService.showMenu();
  }
  makePageTitle(url, item: LoginMenu): string {
    if (item.type == 'Link') {
      if (item.path == url) {
        return item.text;
      } else {
        return '';
      }
    } else if (item.type == 'Node') {
      for (let i of item.children) {
        var childText = this.makePageTitle(url, i);
        if (childText) {
          return item.text ? (item.text + ' > ' + childText) : childText;
        }
      }
      return '';
    }
  }
  onConfirm(code: number) {
    this.error = null;
    this.mainLayoutService.showError(null);
    switch (code) {
      case 401:
        this.auth.logout();
        break;
      case 403:
        // access deny donothing
        break;
      case 404:

        break;
    }
  }
  @ViewChild('confirmDialogYes', { static: false })
  confirmDialogYes: ElementRef<HTMLButtonElement>;
  @ViewChild('confirmDialogNo', { static: false })
  confirmDialogNo: ElementRef<HTMLButtonElement>;
  @ViewChildren('confirmDialogSt')
  confirmDialogSt: QueryList<ElementRef<HTMLButtonElement>>;
  @ViewChildren('confirmDialogMid')
  confirmDialogMid: QueryList<ElementRef<HTMLButtonElement>>;
  @ViewChildren('confirmDialogEnd')
  confirmDialogEnd: QueryList<ElementRef<HTMLButtonElement>>;
  confirmDialogBtns: ElementRef<HTMLButtonElement>[] = [];
  confirmDialogFocusIndex = -1;
  confirmDiagWidth = 250;
  confirmDiagHasAction = true;
  showConfirm = false;
  cusConfirmFn: (ret: boolean) => void = null;
  confirmContent: ConfirmData = new ConfirmData();
  activeElement: Element;
  confirmFocusChagne(evt: KeyboardEvent) {
    if (this.showConfirm) {
      if (this.confirmDialogFocusIndex == -1) {
        this.confirmDialogFocusIndex = 0;
      } else if (evt.key == 'ArrowRight') {
        if (this.confirmDialogBtns.length - 1 > this.confirmDialogFocusIndex) {
          this.confirmDialogFocusIndex++;
        }
      } else if (evt.key == 'ArrowLeft') {
        if (this.confirmDialogFocusIndex > 0) {
          this.confirmDialogFocusIndex--;
        }
      }

      this.confirmDialogBtns.forEach((btn, ind) => {
        if (ind == this.confirmDialogFocusIndex) {
          btn.nativeElement.className = 'confirm-dialog vis-btn-primary default-button';
          btn.nativeElement.focus();
        } else {
          btn.nativeElement.className = '';
        }
      })
      // if(evt.key=='ArrowRight'){
      //   this.confirmDialogNo.nativeElement.className= 'confirm-dialog focus';
      //   this.confirmDialogNo.nativeElement.focus();
      //   this.confirmDialogYes.nativeElement.className= '';
      // }else if(evt.key=='ArrowLeft'){
      //   this.confirmDialogYes.nativeElement.className= 'confirm-dialog focus';
      //   this.confirmDialogNo.nativeElement.className= '';
      //   this.confirmDialogYes.nativeElement.focus();
      // }
    }
  }
  confirmKeyFn:any = null;
  showCustomConfirm(data: ConfirmData) {
    this.confirmContent = data;
    // 如果帶入的資料 沒有設定focus，且 (有Yes按鈕，或沒有任何按鈕代表有預設，focus到Yes按鈕)
    if(! this.confirmContent.focus && (this.confirmContent.textYes
      || (!this.confirmContent.textYes  && !this.confirmContent.textNo  && !this.confirmContent.customBtnStart
                                        && !this.confirmContent.customBtnMid  && !this.confirmContent.customBtnEnd))
    ){
      this.confirmContent.focus = true;
    }
    this.activeElement = document.activeElement;
    this.confirmDiagWidth = data.fitContent ? null : data.width || 250;
    // 有Yes/No 額外按鈕的時候才顯示Action
    this.confirmDiagHasAction = !data.hideNo || !data.hideYes ||
      data.customBtnEnd?.length > 0 || data.customBtnMid?.length > 0 ||
      data.customBtnStart?.length > 0;
    // console.log(this.activeElement)
    this.showConfirm = true;
    var p = new Promise<boolean | string>((resolve, reject) => {
      this.cusConfirmFn = resolve;
    })
    if(!data.enableInput){
      this.confirmKeyFn = this.confirmFocusChagne.bind(this);
      window.addEventListener('keydown', this.confirmKeyFn);
    }

    

    return p;
  }

  onConfirmOpen(){
      var data = this.confirmContent;
      this.confirmDialogBtns = this.confirmDialogSt.toArray();
      if (this.confirmDialogYes) {
        this.confirmDialogBtns.push(this.confirmDialogYes);
      }
      this.confirmDialogBtns = this.confirmDialogBtns.concat(this.confirmDialogMid.toArray());
      if (this.confirmDialogNo) {
        this.confirmDialogBtns.push(this.confirmDialogNo);
      }
      this.confirmDialogBtns = this.confirmDialogBtns.concat(this.confirmDialogEnd.toArray());
      var focusItem = [{ text: this.confirmContent.textYes || '是', return: 'true' },
      { text: this.confirmContent.textNo  || '否', return: 'false' }]
        .concat(this.confirmContent.customBtnStart || [])
        .concat(this.confirmContent.customBtnMid || [])
        .concat(this.confirmContent.customBtnEnd || [])
        .find(it => it.return == '' + data.focus);
      this.confirmDialogBtns.forEach((btn, ind) => {
        if (btn.nativeElement.innerHTML.trim() === focusItem?.text?.trim()) {
          btn.nativeElement.className = 'confirm-dialog vis-btn-primary default-button';
          btn.nativeElement.focus();
          this.confirmDialogFocusIndex = ind;
        } else {
          btn.nativeElement.className = '';
        }
      });
  }
  onCustomConfirm(ret: boolean) {
    if (this.cusConfirmFn) {
      this.cusConfirmFn(ret);
      this.cusConfirmFn = null;
    }
    this.showConfirm = false;
    window.removeEventListener('keydown', this.confirmKeyFn);
    (this.activeElement as any).focus();

  }
  onCustomConfirmClose() {
    if (this.cusConfirmFn) {
      this.cusConfirmFn(false);
      this.cusConfirmFn = null;
    }
    this.showConfirm = false;

    window.removeEventListener('keydown', this.confirmKeyFn);
    (this.activeElement as any).focus();
  }
  getErrorCode() {
    if (this.error) {
      return this.error.code;
    }
    return 0;
  }
  getErrorMsg() {
    if (this.error) {
      return this.error.message;
    }
    return '';
  }
  getErrorStatus() {
    if (this.error) {
      return this.error.status;
    }
    return 0;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  hideMenu() {
    if (MenuComponent.Instance != null) {
      MenuComponent.Instance.closeMenu();
    }
  }


  msgs: NotifyMessage[] = []
  @ViewChildren('snackdiv')
  snackDiv: QueryList<ElementRef<HTMLDivElement>>;

  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if (evt.key == 'Enter' || evt.key == 'Escape') {
      // console.log(evt);

      if (this.msgs.length > 0) {
        if (evt.target instanceof HTMLButtonElement && !evt.target.classList.contains('mat-menu-item')) {
          evt.target.blur();
        }
        //&& !this.msgs[0].isAutoHide
        this.closeMsg(this.snackDiv.first.nativeElement, 0)
        evt.stopPropagation();
        evt.preventDefault();
      }
    }
  }

  closeMsg(div: HTMLDivElement, index: number) {
    // 把要關閉的訊息掛上動畫class, 交給AnimationStart/End機制處理
    div.classList.remove(this.notifyAnimationClsName + '-delay');
    div.classList.add(this.notifyAnimationClsName);
  }
  public closeMsgById(id: string) {
    var index = this.msgs.findIndex(x => x.id == id);
    if (index >= 0) {
      this.onAniEnd(null, index);
      //this.closeMsg(this.snackDiv.get(index).nativeElement,index);
    }
  }
  async updateNotifyMode(){
    var sys101 = await this.clinicData.getParam('SYS101')
    this.notifyAnimationClsName = sys101.NotifyPosition=='bottom'?'snack-slideout':'snack-fadeout'
    this.notifyContainerCls = sys101.NotifyPosition=='bottom'?'snack-container-bottom':'snack-container-top'
  }
  notifyAnimationClsName = 'snack-fadeout';//snack-fadeout-delay
  notifyContainerCls = 'snack-container'
  onAniEnd(evt, index) {
    // 移除訊息
    this.msgs.splice(index, 1);
  }
  onAniStart(evt: AnimationEvent, index: number) {
    if (evt.animationName == "fadeout") {
    }
  }
  shownoty(type: number) {
    if (type == 1) {
      this.notification.showSuccess('成功！');
    } else if (type == 2) {
      this.notification.showError('來測試看看文字太長會長成甚麼樣子究竟到底會不會跑版又或者抑或是會左右延展還是說會變成兩行產生動畫的錯誤>"<');
    } else if (type == 3) {
      this.notification.showInfo('~~~~~~~~~~~~~~~~提示~~~~~~~~~~~~~~~~');
    }
  }



  async getFontGreater($event) {
    await this.fontGreatService.switchFontGreat();
  }


  isPatientDialogOpened = false;
  patientSelectResolve: (s: PatientListDto) => void = null;
  patientSelectReject: (s: any) => void = null;
  selectPatient(): Promise<PatientListDto> {
    this.isPatientDialogOpened = true;
    var p = new Promise<PatientListDto>((rs, rj) => {
      this.patientSelectResolve = rs;
      this.patientSelectReject = rj;
    });
    return p;
  }
  onPatientDialogClose(evt: PatientListDto) {
    this.isPatientDialogOpened = false;
    this.patientSelectResolve(evt);
    this.patientSelectResolve = null;
    this.patientSelectReject = null;

  }


  tipObj: TipModel = null;
  tipPosTimeInterval: any;
  tipBorderStyle = {};
  updateTipPos() {
    if (!this.tipObj) return;
    if (!document.getElementById(this.tipObj.target.id)) {
      this.setTip(null, '', false);
      return;
    }
    var c = this.tipObj.target;
    var x = 0;
    var y = 0;
    var c = this.tipObj.target;
    while (c != null) {
      x += c.offsetLeft;
      y += c.offsetTop;
      c = c.offsetParent as HTMLElement;
    }

    var c = this.tipObj.target;
    while (c != null) {
      y -= c.scrollTop
      c = c.parentElement as HTMLElement;
    }
    var showBorder = y < window.innerHeight && x < window.innerWidth;
    //y+=o.target.clientHeight;
    //y-=60;
    //x+=o.target.clientWidth/2
    var contentFix = '';
    var msgy = y - 70;
    if (msgy < 60) {
      msgy = y + this.tipObj.target.clientHeight + 10;
    }
    if (msgy < 50) {
      msgy = 50;
      contentFix = showBorder ? '' : '(請向上捲動)'
    }
    if (msgy > window.innerHeight - 60) {
      contentFix = showBorder ? '' : '(請向下捲動)'
      msgy = window.innerHeight - 60;
    }

    var msgW = 40 + 80; // padding + button
    var msgx = x - 10;
    var fontSize = 18;
    for (let i = 0; i <= (this.tipObj.content + contentFix).length; i++) {
      var ch = (this.tipObj.content + contentFix).charAt(i)
      if (ch.match(/[^\x00-\xff]/g)) {
        msgW += fontSize;
      } else {
        msgW += (fontSize / 2 * 1.1);
      }
    }
    // 20 scrollbar
    if (msgx + msgW > window.innerWidth - 10) {
      msgx = window.innerWidth - msgW - 10;
    }
    var borderHeight = this.tipObj.target.clientHeight + 20;

    this.tipObj.contentFix = contentFix,

    this.tipObj.msgX = msgx;
    this.tipObj.msgY = msgy;
    this.tipObj.borderX = x - 10;
    this.tipObj.borderY = y - 10;
    this.tipObj.h = borderHeight;
    this.tipObj.w = this.tipObj.target.clientWidth + 20;
    this.tipObj.showBorder = showBorder;
    this.tipObj.hidden = false;
    var borderColor = '#4444'
    this.tipBorderStyle = {
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      borderLeft: this.tipObj.borderX + 'px solid ' + borderColor,
      borderTop: this.tipObj.borderY + 'px solid ' + borderColor,
      borderBottom: (window.innerHeight - this.tipObj.borderY - this.tipObj.h) + 'px solid ' + borderColor,
      borderRight: (window.innerWidth - this.tipObj.borderX - this.tipObj.w) + 'px solid ' + borderColor
    }

  }
  setTip(o: TutorialTip, name: string, isLast: boolean) {
    if (this.tipPosTimeInterval != null) {
      clearInterval(this.tipPosTimeInterval);
    }
    if (o == null) {
      this.tipObj = null;
      return;
    }
    var tar = document.getElementById(o.target);
    if (!tar) {
      return;
    }
    this.tipObj = new TipModel();
    this.tipObj.content = o.content,
    this.tipObj.cssCls = o.cssCls,
    this.tipObj.target = tar;
    this.tipObj.name = name;
    this.tipObj.isLast = isLast;
    this.tipObj.hidden = true;
    this.tipPosTimeInterval = setInterval(() => {
      this.updateTipPos();
    }, 30);
  }

  nextTip(name: string) {

    this.tutorialService.Next(name)
  }

  contentHeight() {
    return this.mainContent.nativeElement.clientHeight;
  }

  qrCodeResultCallBack:(string)=>void = null;
  qrCodeCloseEvtSubs:Subscription;
  //qrCodeOpenEvtSubs:Subscription;
  showScanner = false;
  async openQRCodeReader():Promise<string>{
    this.showScanner = true;
    // if(!this.qrCodeOpenEvtSubs){
    //   this.qrCodeOpenEvtSubs = this.QRCodeReader.open.subscribe(()=>{

    //   })
    // }
    if(!this.qrCodeCloseEvtSubs){
      this.qrCodeCloseEvtSubs = this.QRCodeReader.close.subscribe(()=>{
        if(this.qrCodeResultCallBack){
          this.qrCodeResultCallBack('');
          this.qrCodeResultCallBack = null;
        }

        this.qrCodeCloseEvtSubs.unsubscribe();
        this.qrCodeCloseEvtSubs = null;
        // this.qrCodeOpenEvtSubs?.unsubscribe();
        // this.qrCodeOpenEvtSubs = null;
        this.showScanner = false;
      })
    }
    this.QRCodeReader.show = true;


    return new Promise<string>((rs,rj)=>{
      this.qrCodeResultCallBack =  rs;
    })

  }
  closeQRCodeReader(){
    this.QRCodeReader.show = false;

  }
  async onQRCodeScanResult(code:string){
    if(this.qrCodeResultCallBack){
      this.qrCodeResultCallBack(code);
      this.showScanner = false
    }

  }
}

class TipModel {
  content: string;
  contentFix: string = '';
  target: HTMLElement;
  cssCls: string;
  msgX: number = 0;
  msgY: number = 0;
  hidden:boolean = true;
  borderX: number = 0;
  borderY: number = 0;
  showBorder: boolean = false;
  w: number = 0;
  h: number = 0;
  name: string;
  isLast: boolean;
}
