import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { ReportHistPrintCompressComponent } from 'src/app/system/report/components/hist-report/report-hist-print-compress/report-hist-print-compress.component';
import { ReportHistPrintOriginalComponent } from 'src/app/system/report/components/hist-report/report-hist-print-original/report-hist-print-original.component';
import { ReportHistPrintDiffpersonComponent } from 'src/app/system/report/components/hist-report/report-hist-print-diffperson/report-hist-print-diffperson.component';
import { HistPrintCompressDataDto, HistPrintDiffPersonDataDto, HistPrintOriginalDataDto } from '../models/hist-print-operation-data';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { UserCache } from 'src/app/services/user-cache';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { UserConfirmService } from 'src/app/services/user-confirm.service';

@Component({
  selector: 'app-hist-print-operation',
  templateUrl: './hist-print-operation.component.html',
  styleUrls: ['./hist-print-operation.component.css']
})
export class HistPrintOperationComponent extends BaseComponent implements OnInit {

  @ViewChild(ReportHistPrintCompressComponent, { static: false })
  compressComp: ReportHistPrintCompressComponent;
  @ViewChild(ReportHistPrintOriginalComponent, { static: false })
  originalComp: ReportHistPrintOriginalComponent;
  @ViewChild(ReportHistPrintDiffpersonComponent, { static: false })
  diffPersonComp: ReportHistPrintDiffpersonComponent;

  constructor(
    private fb: FormBuilder,
    private webApiClient: WebApiClient,
    private notification: EasyNotificationService,
    private userConfirm: UserConfirmService,
  ) {
    super();
    this.api = this.webApiClient.createHisService('Hist/Hist');
  }

  // api服務
  api: WebApiService;
  compressData: HistPrintCompressDataDto[];
  originalData: HistPrintOriginalDataDto[];
  diffPersonData: HistPrintDiffPersonDataDto[];
  isCompressPreviewOpened: boolean = false;
  isOriginalPreviewOpened: boolean = false;
  isDiffPersonPreviewOpened: boolean = false;
  queryFormGroup: FormGroup = this.fb.group({});
  totalRecord: number = 0;
  numberOfCopies: number = 1;

  get isPharmacy(): boolean {
    if (UserCache.getLoginUser().Clinic.TypeIsPharmacy) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.createQueryFormGroup();
    this.changeSelectReportType(this.queryFormGroup.controls['selectReportType'].value);
  }

  createQueryFormGroup() {
    this.queryFormGroup = this.fb.group({
      selectReportType: ['1'],
      clinicId: [UserCache.getLoginUser().Clinic.Id],
      startDate: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())],
      startTime: ['00:00:00'],
      endDate: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())],
      endTime: ['23:59:59'],
      patientNo: [''],
      cId: [''],
      cName: [''],
      birthday: [null],
      phones: [''],
      kcstmr: [''],
      dept: [''],
      excludeSpecialMark: [false],
      excludeLackCard: [false],
      includeSelfPayIdentity: [false],
      onlyHealthInsurance: [false],
      excludeSelfPay: [false],
      excludeNoCharge: [false],
      excludeDeclareNoCharge: [false],
      case09: [false],
      excludeSameTreat26: [false],
      includeImport: [false],
      diffPerson: [false],
      addonBodyInfo: [false],
      hideStaffCId: [false],
      orderByDate: [false],
      numberOfCopies: [1],
    });
  }

  async onBtnQuery() {
    this.numberOfCopies = this.queryFormGroup.controls['numberOfCopies'].value;
    if (this.numberOfCopies < 1 || this.numberOfCopies > 99) {
      this.notification.showError("列印份數必須輸入1~99。");
      this.numberOfCopies = 1;
      this.queryFormGroup.controls['numberOfCopies'].patchValue(this.numberOfCopies);
      return;
    }

    let valueBirth = this.queryFormGroup.controls['birthday'].value;
    let inputBirth = valueBirth ? DateHelper.getFormatedDateString(valueBirth, false) : null;

    let valueStartDate = this.queryFormGroup.controls['startDate'].value;
    let valueStartTime = this.queryFormGroup.controls['startTime'].value;
    let inputStartDate = valueStartDate ? DateHelper.getFormatedDateString(valueStartDate, false) + (valueStartTime ? ' ' + valueStartTime : '') : null;

    let valueEndDate = this.queryFormGroup.controls['endDate'].value;
    let valueEndTime = this.queryFormGroup.controls['endTime'].value;
    let inputEndDate = valueEndDate ? DateHelper.getFormatedDateString(valueEndDate, false) + (valueEndTime ? ' ' + valueEndTime : '') : null;

    let inputDays = '';
    if (inputStartDate && inputEndDate) {
      let days = Math.round(DateHelper.dayDiff(new Date(inputEndDate), new Date(inputStartDate)));
      if (days < 0) {
        var ret = await this.userConfirm.showConfirm({
          title:'錯誤',
          msg:'輸入日期，起始日期不可大於結束日期，請重新輸入。',
          width:400,
          textYes:'確認',
          hideNo:true
        });
        return;
      };
      if (days > 0) {
        inputDays = `日期條件共 ${days} 天\n\n`;
      };
    };

    const query = {
      selectReportType: this.queryFormGroup.controls['selectReportType'].value,
      clinicId: this.queryFormGroup.controls['clinicId'].value,
      startDate: inputStartDate,
      endDate: inputEndDate,
      patientNo: this.queryFormGroup.controls['patientNo'].value,
      cId: this.queryFormGroup.controls['cId'].value,
      cName: this.queryFormGroup.controls['cName'].value,
      birthday: inputBirth,
      phones: this.queryFormGroup.controls['phones'].value,
      kcstmr: this.queryFormGroup.controls['kcstmr'].value,
      dept: this.queryFormGroup.controls['dept'].value,
      excludeSpecialMark: this.queryFormGroup.get('excludeSpecialMark').status == 'VALID' ? this.queryFormGroup.controls['excludeSpecialMark'].value : false,
      excludeLackCard: this.queryFormGroup.get('excludeLackCard').status == 'VALID' ? this.queryFormGroup.controls['excludeLackCard'].value : false,
      includeSelfPayIdentity: this.queryFormGroup.get('includeSelfPayIdentity').status == 'VALID' ? this.queryFormGroup.controls['includeSelfPayIdentity'].value : false,
      onlyHealthInsurance: this.queryFormGroup.get('onlyHealthInsurance').status == 'VALID' ? this.queryFormGroup.controls['onlyHealthInsurance'].value : false,
      excludeSelfPay: this.queryFormGroup.get('excludeSelfPay').status == 'VALID' ? this.queryFormGroup.controls['excludeSelfPay'].value : false,
      excludeNoCharge: this.queryFormGroup.get('excludeNoCharge').status == 'VALID' ? this.queryFormGroup.controls['excludeNoCharge'].value : false,
      excludeDeclareNoCharge: this.queryFormGroup.get('excludeDeclareNoCharge').status == 'VALID' ? this.queryFormGroup.controls['excludeDeclareNoCharge'].value : false,
      case09: this.queryFormGroup.get('case09').status == 'VALID' ? this.queryFormGroup.controls['case09'].value : false,
      excludeSameTreat26: this.queryFormGroup.get('excludeSameTreat26').status == 'VALID' ? this.queryFormGroup.controls['excludeSameTreat26'].value : false,
      includeImport: this.queryFormGroup.get('includeImport').status == 'VALID' ? this.queryFormGroup.controls['includeImport'].value : false,
      diffPerson: this.queryFormGroup.get('diffPerson').status == 'VALID' ? this.queryFormGroup.controls['diffPerson'].value : false,
      addonBodyInfo: this.queryFormGroup.get('addonBodyInfo').status == 'VALID' ? this.queryFormGroup.controls['addonBodyInfo'].value : false,
      hideStaffCId: this.queryFormGroup.get('hideStaffCId').status == 'VALID' ? this.queryFormGroup.controls['hideStaffCId'].value : false,
      orderByDate: this.queryFormGroup.get('orderByDate').status == 'VALID' ? this.queryFormGroup.controls['orderByDate'].value : false,
      medicalCombine: false,
    };

    if (!query.patientNo && !query.cId && !query.cName && !query.phones && !query.kcstmr && !query.birthday) {
      var ret = await this.userConfirm.showConfirm({
        title:'確認',
        msg:inputDays + '當沒有輸入任何基本資料條件，若符合的資料太多時，\n\n會需要等待幾分鐘執行時間，是否確定執行？',
        width:400,
        textYes:'是',
        textNo:'否',
      });
      if (!ret) return;
    }

    if (query.selectReportType == '1' && !query.diffPerson) {
      this.totalRecord = 0;
      this.isOriginalPreviewOpened = true;
      this.api.post('GetHistPrintOriginalReport', query).subscribe(
        (data: HistPrintOriginalDataDto[]) => {
          this.totalRecord = data.length;
          if (data.length > 0) {
            this.originalData = data;
            this.originalComp.fillData(this.originalData, query.hideStaffCId);
          } else {
            this.notification.showInfo('查無可列印的資料!');
          }
        });
    }
    else if (query.selectReportType == '1' && query.diffPerson) {
      this.totalRecord = 0;
      this.isDiffPersonPreviewOpened = true;
      this.api.post('GetHistPrintDiffPersonReport', query).subscribe(
        (data: HistPrintDiffPersonDataDto[]) => {
          this.totalRecord = data.filter(d => d.MasterLeft).length + data.filter(d => d.MasterRight).length;
          if (data.length > 0) {
            this.diffPersonData = data;
            this.diffPersonComp.fillData(this.diffPersonData, query.hideStaffCId, query.addonBodyInfo);
          } else {
            this.notification.showInfo('查無可列印的資料!');
          }
        });
    }
    else {
      this.totalRecord = 0;
      this.isCompressPreviewOpened = true;
      this.api.post('GetHistPrintCompressReport', query).subscribe(
        (data: HistPrintCompressDataDto[]) => {
          this.totalRecord = data.filter(d => d.IsDetailFirst).length;
          if (data.length > 0) {
            this.compressData = data;
            this.compressComp.fillData(this.compressData, query.excludeSpecialMark);
          } else {
            this.notification.showInfo('查無可列印的資料!');
          }
        });
    }
  }

  onCompressExport() {
    this.compressComp.exportAsExcel();
    this.isCompressPreviewOpened = false;
  }

  onCompressPrint() {
    this.compressComp.onPrint();
  }

  onCompressPreviewClose() {
    this.compressComp.clearData();
    this.isCompressPreviewOpened = false;
  }

  onCompressPrinted(evt: any) {
    this.compressComp.clearData();
    this.isCompressPreviewOpened = false;
  }

  onOriginalExport() {
    this.originalComp.exportAsExcel();
    this.isOriginalPreviewOpened = false;
  }

  onOriginalPrint() {
    this.originalComp.onPrint();
  }

  onOriginalPreviewClose() {
    this.originalComp.clearData();
    this.isOriginalPreviewOpened = false;
  }

  onOriginalPrinted(evt: any) {
    this.originalComp.clearData();
    this.isOriginalPreviewOpened = false;
  }

  onDiffPersonExport() {
    this.diffPersonComp.exportAsExcel();
    this.isDiffPersonPreviewOpened = false;
  }

  onDiffPersonPrint() {
    this.diffPersonComp.onPrint();
  }

  onDiffPersonPreviewClose() {
    this.diffPersonComp.clearData();
    this.isDiffPersonPreviewOpened = false;
  }

  onDiffPersonPrinted(evt: any) {
    this.diffPersonComp.clearData();
    this.isDiffPersonPreviewOpened = false;
  }

  onOnlyHealthInsuranceCheckChanged(evt: any) {
    this.changeOnlyHealthInsurance(evt.checked);
  }

  changeOnlyHealthInsurance(checked: any) {
    if (checked) {
      this.queryFormGroup.controls['excludeSelfPay'].patchValue(false);
      this.queryFormGroup.controls['excludeNoCharge'].patchValue(false);

      this.queryFormGroup.get('excludeSelfPay').disable({ onlySelf: true });
      this.queryFormGroup.get('excludeNoCharge').disable({ onlySelf: true });
    }
    else {
      this.queryFormGroup.get('excludeSelfPay').enable({ onlySelf: true });
      this.queryFormGroup.get('excludeNoCharge').enable({ onlySelf: true });
    }
  }

  onDiffPersonCheckChanged(evt: any) {
    this.changeDiffPerson(evt.checked);
  }

  changeDiffPerson(checked: any) {
    if (checked) {
      this.queryFormGroup.get('onlyHealthInsurance').enable({ onlySelf: true });
      this.queryFormGroup.get('excludeSelfPay').enable({ onlySelf: true });
      this.queryFormGroup.get('excludeNoCharge').enable({ onlySelf: true });
      this.queryFormGroup.get('excludeDeclareNoCharge').enable({ onlySelf: true });
      this.queryFormGroup.get('addonBodyInfo').enable({ onlySelf: true });
    }
    else {
      this.queryFormGroup.controls['onlyHealthInsurance'].patchValue(false);
      this.queryFormGroup.controls['excludeSelfPay'].patchValue(false);
      this.queryFormGroup.controls['excludeNoCharge'].patchValue(false);
      this.queryFormGroup.controls['excludeDeclareNoCharge'].patchValue(false);
      this.queryFormGroup.controls['addonBodyInfo'].patchValue(false);

      this.queryFormGroup.get('onlyHealthInsurance').disable({ onlySelf: true });
      this.queryFormGroup.get('excludeSelfPay').disable({ onlySelf: true });
      this.queryFormGroup.get('excludeNoCharge').disable({ onlySelf: true });
      this.queryFormGroup.get('excludeDeclareNoCharge').disable({ onlySelf: true });
      this.queryFormGroup.get('addonBodyInfo').disable({ onlySelf: true });
    }
  }

  onGroupChange(evt: any) {
    this.changeSelectReportType(evt.value);
  }

  changeSelectReportType(value: any) {
    //清除全部勾選
    this.queryFormGroup.controls['excludeSpecialMark'].patchValue(false);
    this.queryFormGroup.controls['excludeLackCard'].patchValue(false);
    this.queryFormGroup.controls['includeSelfPayIdentity'].patchValue(false);
    this.queryFormGroup.controls['onlyHealthInsurance'].patchValue(false);
    this.queryFormGroup.controls['excludeSelfPay'].patchValue(false);
    this.queryFormGroup.controls['excludeNoCharge'].patchValue(false);
    this.queryFormGroup.controls['excludeDeclareNoCharge'].patchValue(false);
    this.queryFormGroup.controls['case09'].patchValue(false);
    this.queryFormGroup.controls['excludeSameTreat26'].patchValue(false);
    this.queryFormGroup.controls['includeImport'].patchValue(false);
    this.queryFormGroup.controls['diffPerson'].patchValue(false);
    this.queryFormGroup.controls['addonBodyInfo'].patchValue(false);
    this.queryFormGroup.controls['hideStaffCId'].patchValue(false);
    this.queryFormGroup.controls['orderByDate'].patchValue(false);

    if (value == '1') {
      // this.queryFormGroup.controls['excludeSpecialMark'].patchValue(false);
      // this.queryFormGroup.controls['onlyHealthInsurance'].patchValue(false);
      // this.queryFormGroup.controls['excludeSelfPay'].patchValue(false);
      // this.queryFormGroup.controls['excludeNoCharge'].patchValue(false);
      // this.queryFormGroup.controls['excludeDeclareNoCharge'].patchValue(false);
      // this.queryFormGroup.controls['addonBodyInfo'].patchValue(false);

      this.queryFormGroup.get('excludeSpecialMark').disable({ onlySelf: true });
      this.queryFormGroup.get('onlyHealthInsurance').disable({ onlySelf: true });
      this.queryFormGroup.get('excludeSelfPay').disable({ onlySelf: true });
      this.queryFormGroup.get('excludeNoCharge').disable({ onlySelf: true });
      this.queryFormGroup.get('excludeDeclareNoCharge').disable({ onlySelf: true });
      this.queryFormGroup.get('addonBodyInfo').disable({ onlySelf: true });

      this.queryFormGroup.get('diffPerson').enable({ onlySelf: true });
      this.queryFormGroup.get('hideStaffCId').enable({ onlySelf: true });
      this.queryFormGroup.get('orderByDate').enable({ onlySelf: true });
    }
    else {
      // this.queryFormGroup.controls['diffPerson'].patchValue(false);
      // this.queryFormGroup.controls['hideStaffCId'].patchValue(false);
      // this.queryFormGroup.controls['orderByDate'].patchValue(false);
      // this.queryFormGroup.controls['addonBodyInfo'].patchValue(false);

      this.queryFormGroup.get('diffPerson').disable({ onlySelf: true });
      this.queryFormGroup.get('hideStaffCId').disable({ onlySelf: true });
      this.queryFormGroup.get('orderByDate').disable({ onlySelf: true });
      this.queryFormGroup.get('addonBodyInfo').disable({ onlySelf: true });

      this.queryFormGroup.get('excludeSpecialMark').enable({ onlySelf: true });
      this.queryFormGroup.get('onlyHealthInsurance').enable({ onlySelf: true });
      this.queryFormGroup.get('excludeSelfPay').enable({ onlySelf: true });
      this.queryFormGroup.get('excludeNoCharge').enable({ onlySelf: true });
      this.queryFormGroup.get('excludeDeclareNoCharge').enable({ onlySelf: true });
    }
  }

}
