<div style="display: flex;flex-direction: column;min-height: inherit;" [classList]="this.formDefine.classList" [ngClass]="[fontGreater?'font-great-on':'font-great-off']">
    <div matDialogTitle>
        <div style="width: calc(100% - 30px);">
            <span>{{formDefine.title}}</span>
        </div>
        <button *ngIf="!(formDefine.hideClose==true||formDefine.showBtnCancel==false)" type="button" tabindex="-1" style="position: relative;right: 0px;width: 30px;height: 30px;margin-left: 0px; min-width: unset;padding: 0px;line-height: normal;"
        id="easy-form-close-btn" class="easy-form-close-btn" mat-button  (click)="close()">
            <mat-icon style="color: #ddd; font-size: 20px;margin-top: 5px;">close</mat-icon>
        </button>
    </div>
    <div matDialogContent style="flex-grow: 1; ">
       <div class="p-3" style="display:flex;flex-direction: column;">
        <div [ngStyle]="formDefine.height?{
            height:(formDefine.height-94)+'px',
            overflowY:'auto'
        }:{}">
            <p class="extend-msg" style="font-size: 16px;white-space:pre-wrap;">{{formDefine.msg}}</p>
            <form class="easy-form mb-3 extend-msg" *ngIf="fg" [formGroup]="fg">
                <ng-container *ngFor="let field of formDefine.fields">
                    <ng-container *ngIf="field.type=='divider'">
                        <div class="divider" style="display: flex;width: 100%;">
                            <hr style="flex-grow: 1;background-color: #b6d9f1;width: 50%;">
                            <span style="width: 120px;">{{field.label}}</span>
                            <hr style="flex-grow: 1;background-color: #b6d9f1;width: 50%;">
                        </div>
                    </ng-container>
                    <label *ngIf="field.type!='divider'" class="easy-form-field">
                        <span class="ez-form-field-title">{{field.label}}</span>
                        <span class="ez-form-field-require" [class.require]="field.required"></span>：
                        <ng-container [ngSwitch]="field.type">
                            <ng-container *ngSwitchCase="'text'">
                                <input class="rounded-2" style="width:200px" [id]="'ez-form-'+this.field.name" [formControlName]="this.field.name" autocomplete="off"/>
                            </ng-container>
                            <ng-container *ngSwitchCase="'display'">
                                <input class="rounded-2" readonly style="width:200px;border: none;cursor: default;" [id]="'ez-form-'+this.field.name" [formControlName]="this.field.name" autocomplete="off"/>
                            </ng-container>
                            <ng-container *ngSwitchCase="'radio'">
                              <div class="radio-group" style="display: flex; gap: 10px; width: 200px;">
                                <ng-container *ngFor="let option of field.data">
                                  <label [for]="'ez-form-'+field.name+'-'+option.value" style="display: flex; align-items: center; margin-right: 10px;">
                                    <input type="radio" class="rounded-2" style="width:200px" [id]="'ez-form-'+field.name+'-'+option.value"
                                      [value]="option.value" [formControlName]="field.name" style="margin-right: 5px;"/>
                                    <span style="white-space: nowrap;">{{option.text}}</span>
                                  </label>
                                </ng-container>
                              </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'dropdown'">
                              <app-vis-dropdownlist class="rounded-2" style="width:200px" [id]="'ez-form-'+this.field.name" [data]="field.data" [formControlName]="this.field.name" ></app-vis-dropdownlist>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <app-vis-datepicker class="rounded-2" style="width:200px" [id]="'ez-form-'+this.field.name" [formControlName]="this.field.name"></app-vis-datepicker>
                            </ng-container>
                            <ng-container *ngSwitchCase="'combo'">
                                <app-vis-combobox class="rounded-2" style="width:200px" [id]="'ez-form-'+this.field.name" [data]="field.data" [formControlName]="this.field.name" ></app-vis-combobox>
                            </ng-container>
                            <ng-container *ngSwitchCase="'check'">
                                <mat-checkbox class="rounded-2"  style="width:200px;text-align: center;" [id]="'ez-form-'+this.field.name" [formControlName]="this.field.name"></mat-checkbox>
                            </ng-container>
                            <ng-container *ngSwitchCase="'pwd'">
                                <input type="password" class="rounded-2" style="width:200px" [id]="'ez-form-'+this.field.name" [formControlName]="this.field.name" #pwdEZ/>
                                <button tabindex="-1" class="background-none m-0 p-0 mx-1 box-shadow-none"
                                    (click)="pwdEZ.type=pwdEZ.type=='password'?'input':'password'">
                                    <ng-container *ngIf="pwdEZ.type=='password';else elstmp">
                                    <mat-icon style="width: 16px;height: 16px;font-size: 16px;">visibility</mat-icon>
                                    </ng-container>
                                    <ng-template #elstmp>
                                    <mat-icon style="width: 16px;height: 16px;font-size: 16px;">visibility_off</mat-icon>
                                    </ng-template>
                                </button>
                            </ng-container>
                            <ng-container *ngSwitchCase="'number'">
                                <input type="tel" class="rounded-2" appNumberOnly style="width:200px" [id]="'ez-form-'+this.field.name" [formControlName]="this.field.name"/>
                            </ng-container>
                            <app-validation-tip class="rounded-2" [controlName]="this.field.name"></app-validation-tip>
                        </ng-container>
                    </label>
                    <div *ngIf="field.tip" style="color: gray;margin-left: 10px;">*{{field.tip}}</div>
                </ng-container>
            </form>
            <p *ngIf="formDefine.tip" style="font-size: 12px;white-space:pre-wrap;color: red;margin-top: 10px;">*{{formDefine.tip}}</p>
        </div>
        <div class="px-4 text-center">
          <ng-container *ngIf="formDefine.btns">
              <button type="button" class="easy-form-btn mb-1 vis-btn-primary vis-btn-capsule mx-1" style="letter-spacing: 0;" *ngFor="let btn of formDefine.btns" (click)="doBtn(btn)">{{btn.text}}</button>
          </ng-container>
          <button type="button" [attr.disabled]="isWaiting||null" class="easy-form-btn sumbit vis-btn-primary mb-1 vis-btn-capsule mx-1" (click)="submit()" #btn>{{formDefine.textOk||'確認'}}</button>
          <button *ngIf="formDefine.showBtnCancel" type="button" class="easy-form-btn sumbit vis-btn-primary mb-1 vis-btn-capsule mx-1" (click)="close()">{{formDefine.textCancel||'取消'}}</button>
        </div>
    </div>
</div>
