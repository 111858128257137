

/**
 * 就醫資料登錄
 */ 
export class TreatmentDataNoHPC {
    /**
     * 就醫類別
     */ 
    public Itp: string = null;
    /**
     * 新生兒就醫註記
     */ 
    public NewbornFlag: string = null;
    /**
     * 就診日期時間
     */ 
    public ClinicDate: Date = new Date(0);
    /**
     * 補卡註記
     */ 
    public ReCardFlag: string = null;
    /**
     * 就醫序號
     */ 
    public Isqno: string = null;
    /**
     * 醫療院所代碼
     */ 
    public ClinicCode: string = null;
    /**
     * 門診醫療費用【當次】
     */ 
    public MedicalFees: string = null;
    /**
     * 門診部分負擔費用【當次】
     */ 
    public Deductible: string = null;
    /**
     * 住院醫療費用【當次】
     */ 
    public HospitalFees: string = null;
    /**
     * 住院部分負擔費用【當次急性30天， 慢性180天以下】
     */ 
    public HdeductibleA: string = null;
    /**
     * 住院部分負擔費用【當次急性31天， 慢性181天以上】
     */ 
    public HdeductibleB: string = null;
}
