export class DailyReport {
  startDate: Date;
  endDate: Date;
  timeSec: number;
  reportType: string;
  NumberOfFirstVisit: number; // 初診人次
  NumberOfFirstVisitPeople: number; // 初診人數
  NumberOfRevisit: number; // 複診人次
  NumberOfRevisitPeople: number; // 複診人數
  NumberOfTotalVisit: number; // 總人次
  NumberOfTotalVisitPeople: number; // 總人數
  ReportTime: Date;
  Items: DailyReportItem[];
  Sum: DailyReportIcodeSum[];
}
export class DailyReportItem {
  Id: string;
  RegDate: Date;
  TimeSecName: string;
  PatientName: string;
  FeeReg: number;
  FeeExam: number;
  FeeInternal: number;
  FeeOthers: number;
  Debt: number;
  Repay: number;
  Received: number;
  IsDailyTotal: boolean;
  IsSummary: boolean;
  PatientNo: string;    
  PatientBirth: Date;      
  Icode: string;
  IcodeName:string;
  Isqno: string;   
  StatusCode: string;
  StatusWord:string;
  SameTreat: string;
  field1: string;
  RoomCode: string;
  DoctorId: number;
  APartOPD: number;
  APartDrug: number;
  APartExam: number;
  FeeLAMT:number;
  FeeTotal: number;
  DUTYCreater:string;
  DoctorAccount: string;
  DoctorName: string;
  AOwn:number;
  Remark: string;
  HRxs?: string[];
  // field3: number;
  // field4: number;
}
export class DailyReportIcodeSum {
  Icode: string; 
  FeeReg: number;
  People: number; // 人數
  NumberOfFirstVisitPeople: number; // 初診人數
  NumberOfRevisitPeople: number; // 複診人數
  NumberOfType3People: number; // 複診人數
  NumberOfType3RecardPeople: number; // 複診人數
  APartOPD: number;
  APartDrug: number;
  APartExam: number;
  FeeOwn:number;
  SubSum:number;
  Deposit:number;
}

export class DailyReportColControl {
  Col: string; 
  Name: string;
  Seq:number;
  // IsHide: number;
}


export class DailyPaymentMaster {
  Id: number;
  SerialNumber: string;
  Payable: number;
  Paid: number;
  TimeSec: number;
  Status: number;
  Detail: PaymentDetailInfo[];
  PatientName:string;
  PatientNo:string;
  constructor() {
    this.Detail = [];
  }
}

export class PaymentDetailInfo {
  FeeCode: string;
  OwnPay?: number ;
  SubTotal?: number ;

  constructor() {
    this.FeeCode = '';
    this.OwnPay = null;
    this.SubTotal = null;
  }
}
export const DailyReportColEnum:  {Seq:number,Col: string, Name: string,Witdh: number}[] =
    [
      // {Col: 'RegDate', Name: '掛號日' },
     {Seq:1, Col: 'PatientName', Name: '姓名',Witdh:8 },
     {Seq:2, Col: 'IcodeName', Name: '身分',Witdh:4 },
     {Seq:3, Col: 'Isqno', Name: '卡序',Witdh:4 },
     {Seq:4, Col: 'DoctorName', Name: '醫師',Witdh:8 },
     {Seq:5, Col: 'TimeSecName', Name: '時段',Witdh:2 },
     {Seq:6, Col: 'StatusWord', Name: '狀態',Witdh:2 },
     {Seq:7, Col: 'SameTreat', Name: '類',Witdh:2 },
     {Seq:8, Col: 'DispTp', Name: '調劑',Witdh:2 },
     {Seq:9, Col: 'DUTYCreater', Name: '櫃台',Witdh:7 },
     {Seq:10, Col: 'RoomCode', Name: '診',Witdh:2 },
     {Seq:11, Col: 'FeeReg', Name: '掛號費',Witdh:6 },
     {Seq:12, Col: 'APartOPD', Name: '部份負擔',Witdh:7 },
     {Seq:13, Col: 'AOwn', Name: '自費',Witdh:7 },
     {Seq:14, Col: 'APartDrug', Name: '藥部份負擔',Witdh:7 },
     {Seq:15, Col: 'APartExam', Name: '檢部份負擔',Witdh:7 },
     {Seq:16, Col: 'FeeLAMT', Name: '押金',Witdh:7 },
     {Seq:17, Col: 'Received', Name: '小計',Witdh:7 },
     {Seq:18, Col: 'FeeTotal', Name: '掛帳費',Witdh:7 },
     {Seq:19, Col: 'Remark', Name: '說明',Witdh:10 },
     {Seq:20, Col: 'Debt', Name: '欠款',Witdh:7 },
     {Seq:21, Col: 'Repay', Name: '還款',Witdh:7 },

      ];