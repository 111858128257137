<div class="row narrow-padding overflow-x-auto font-hidden-before register"
  [ngClass]="[isFromCompEnter!==true?'':' before-none overflow-y-hidden']">
  <div class="w-100 mt-1">
    <div class="card m-0" [formGroup]="editFormGroup" [ngStyle]="{visibility:isLoaded?'visible':'hidden'}">
      <!--基資/按鈕列-->
      <div class="card-header py-0 font-weight-bold register-header" [ngClass]="[bigRatio===false?'':'text-sm px-1']">
        <div style="display: flex;align-items: center;" class="extend-msg lh-sm">
          <ng-container *ngIf="initReg">
            <div *ngIf="isBasicCanEdit;else nameEdit" class="highlight-color mr-0 big-patient-info"
              style="display: inline-block; font-weight: 600; white-space:normal; max-width: 18rem;"
              [ngClass]="[bigRatio?fontGreater?'w-22':'w-18':'']">
              {{initReg.PatientFirstName}}
            </div>
            <ng-template #nameEdit>
              <input class="vis-input w-32" formControlName="PatientFirstName"
                [ngClass]="[bigRatio?fontGreater?'w-22':'w-18':'']" />
              <app-validation-tip controlName="PatientFirstName"></app-validation-tip>
            </ng-template>

            <div class="ml-1 big-patient-info" style="display: flex;flex-wrap:wrap;">
              <span style="min-width: 200px;">
                病歷號: {{fReg.Value.PatientNo}}&nbsp;
              </span>
              <span style="min-width: 200px;">
                身分證: {{fReg.Value.PatientCId}}&nbsp;
              </span>

              <ng-container *ngIf="isBasicCanEdit;else birthdayEdit">
                <span i18n="@@Brithday">
                  生日:{{fReg.Value.PatientBirth | formatDate:''}}
                </span>

              </ng-container>
              <ng-template #birthdayEdit>
                <span i18n="@@Brithday">
                  生日:
                </span>
                <app-vis-datepicker class="normal-birthday" formControlName="PatientBirth" [disabled]="!canEdit"
                  [maxWidth]="false" [customClass]="[(howOld < 6)?'birthday-less-input':'']"></app-vis-datepicker>
                <app-validation-tip controlName="PatientBirth"></app-validation-tip>
              </ng-template>

              <mat-icon
                class="position-relative mat-icon notranslate material-icons mat-icon-no-color cursor-pointer top-2"
                (click)="getBasicCanEdit()" *ngIf="this.isAdd || this.canEdit ">
                <span class="material-icons">{{isBasicCanEdit?'edit':'save'}}</span>
              </mat-icon>
              <span class="text-lg -top-2 position-relative" i18n="@@Brithday">
                ({{fReg.Value.PatientBirth | fullage }})&nbsp;
              </span>
              <span>
                {{fReg.Value.PatientSexName}}性&nbsp;
              </span>
            </div>
          </ng-container>
          <span class="can-not-edit-msg warning-color" [hidden]="canEdit || isCancelRegister"
            i18n="@@reg.register.canNotEdit"
            [ngClass]="[bigRatio===true&&fontGreater===false?'w-24':'',bigRatio===true&&fontGreater===true?'w-40':'']">
            此狀態下無法進行編輯
          </span>

          <div style="flex-grow: 1;"></div>
          <div style="flex-shrink: 0;" class="position-relative top-2 lh-lg text-right"
            [ngClass]="[bigRatio===true?'lh-xlg':' ', fontGreater===true?'d-inline-flex':' d-inline-block',isPopularScreen && bigRatio===false?'big-patient-btn':'']">
            <!-- 小萱說是復健同僚才要出現[列印病歷]，取目前未實作列印病歷 -->
            <!-- <div class="position-relative checkbox-label-ui d-inline-block flex-grow-one mr-1" [ngStyle]="{top:fontGreater===true?'-5px':'-8px'}"  [ngClass]="bigRatio===false?'print-block-bottom':fontGreater===true?'':'print-block-bottom-big-size'">
              <mat-checkbox  [(ngModel)]="isPrintHist">列印病歷</mat-checkbox>
            </div> -->
            <div class="position-relative checkbox-label-ui d-inline-block mr-1" [hidden]="!canEdit"
              [ngStyle]="{top:fontGreater===true?'-5px':'-8px'}"
              [ngClass]="bigRatio===false?'print-block-bottom':fontGreater===true?'':'print-block-bottom-big-size'">
              <mat-checkbox [(ngModel)]="isPrintReceipt" [ngModelOptions]="{standalone: true}">列印掛號單</mat-checkbox>
            </div>
            <!-- [disabled]="!canSave" -->
            <button type="button" *ngIf="!isCancelRegister" [disabled]="isSaveClicked" (click)="onPateSpecialDialogOpen()" [hidden]="!canEdit" i18n="@@Save"
            style="max-width: 6rem;"
            class="vis-btn-config p-0 position-relative pl-2 d-inline-block flex-grow-one" title="特殊"
            [ngStyle]="{height:bigRatio===false?'1.66rem':''}">
            <mat-icon class="pref-icon save vis-combine-icon">content_paste_search</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2 font-weight-bold">特殊</span>
          </button>
            <button type="button" [disabled]="isSaveClicked" (click)="onSaveClick()" [hidden]="!canEdit" i18n="@@Save"
              style="max-width: 5rem;"
              class="vis-btn-config p-0 position-relative pl-2 d-inline-block flex-grow-one orange" title="儲存"
              [ngStyle]="{height:bigRatio===false?'1.66rem':''}">
              <mat-icon class="pref-icon save vis-combine-icon">save</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2 font-weight-bold">儲存</span>
            </button>
            <button type="button" *ngIf="isCancelRegister" (click)="onCancelRegiestClick()" i18n="@@Calcel"
              style="max-width: 5rem;" class="vis-btn-config p-0 position-relative pl-2 d-inline-block flex-grow-one"
              title="退掛" [ngStyle]="{height:bigRatio===false?'1.66rem':''}">
              <mat-icon class="pref-icon remove vis-combine-icon">cancel</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2 font-weight-bold">退掛</span>
            </button>
            <button type="button" (click)="cancel()" i18n="@@Back" style="max-width: 5rem;"
              class="vis-btn-config p-0 position-relative pl-2 d-inline-block flex-grow-one" title="返回"
              [ngStyle]="{height:bigRatio===false?'1.66rem':'','margin-left': !canEdit&&!isCancelRegister?'15rem':''}">
              <mat-icon class="pref-icon remove vis-combine-icon">keyboard_backspace</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2 font-weight-bold">取消</span>
            </button>
            <!-- 退掛按鈕先隱藏 20230802-->
            <!-- <button type="button" (click)="openDeleteDialog()" *ngIf="!isAdd&&!isFromCompEnter" [hidden]="!canEdit"
              i18n="@@RegCancelRegister" class="vis-btn-config p-0 position-relative pl-2 w-18" title="退掛">
              <mat-icon class="pref-icon remove vis-combine-icon">cancel</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">退掛</span>
            </button> -->
            <!-- <button begin (click)="onOpenCalendarClick()" *ngIf="isAdd" [hidden]="!canEdit"
            i18n="@@RegShiftSchedule">從班表選擇</button> -->
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <!--./card-header-->

      <!-- big ratio -->
      <div class="card-body p-0 overflow-hidden gradation-bg"
        [ngClass]="[bigRatio===false?'':fontGreater===true?'':'px-1',fontGreater===true && bigRatio === true?'pb-2':'']"
        #newRegisterOut>
        <div class="py-0 pb-2 vis-form vis-patients-edit register overflow-initial vis-form-narrow"
          [ngStyle]="{height:newRegisterHeight}">
          <div class="new-register" #newRegister>
            <!--電話/住址-->
            <div class="d-flex phoneInfoRow" style="margin-bottom: 5px; font-size: 12px;position: relative;">
              <div
                style="position: absolute;left: -10px;width: calc(200%);background-color: white;height: calc(100% + 5px);z-index: -1;">
              </div>
              <div class="flex-grow-one max-w-48 mr-3">
                <label class="vis-form-field">
                  <span i18n="@@Phone">電話(家)</span>
                  <input type="tel" class="vis-input border-right-0" appNumberIntegerOnly
                    formControlName="PatientPhoneHomeArea" style="width:25%; display: inline-block;" maxlength="5" />
                  <input type="tel" class="vis-input" appNumberIntegerOnly formControlName="PatientPhoneHome"
                    style="width:75%; display: inline-block;" maxlength="20" />
                  <app-validation-tip controlName="PatientPhoneHomeArea"></app-validation-tip>
                  <app-validation-tip controlName="PatientPhoneHome"></app-validation-tip>
                </label>
              </div>
              <div class="flex-grow-one max-w-40 mr-3">
                <label class="vis-form-field">
                  <span i18n="@@Mobile">手機</span>
                  <input type="tel" class="vis-input" appNumberIntegerOnly formControlName="PatientMobile"
                    maxlength="20" />
                  <app-validation-tip controlName="PatientMobile"></app-validation-tip>
                </label>
              </div>
              <div class="flex-grow-one max-w-32">
                <label class="vis-form-field">
                  <span i18n="@@City">縣市<div class="d-inline-block w-8">　　</div></span>
                  <input #zipCode hidden />
                  <app-vis-dropdownlist class="vis-input d-inline-block max-w-32 bg-white" [data]="city"
                    [panelClass]="'reg-scale'"
                    formControlName="PatientCity"></app-vis-dropdownlist>
                  <app-validation-tip controlName="City"></app-validation-tip>
                </label>
              </div>
              <div class="flex-grow-one max-w-32">
                <label class="vis-form-field">
                  <span i18n="@@Area">鄉鎮區</span>
                  <app-vis-dropdownlist class="vis-input d-inline-block max-w-32 bg-white" [data]="areaFilterd"
                    [panelClass]="'reg-scale'"
                    [valueField]="'text'" formControlName="PatientArea"></app-vis-dropdownlist>
                  <app-validation-tip controlName="Area"></app-validation-tip>
                </label>
              </div>
              <div class="flex-grow-three max-w-92">
                <label class="vis-form-field">
                  <span i18n="@@Street">地址</span>
                  <input class="vis-input" formControlName="PatientStreet" />
                  <app-validation-tip controlName="PatientStreet"></app-validation-tip>
                </label>
              </div>
            </div>
            <!--卡片資料-->
            <div class="reg-row" *ngIf="!isSelfPay">
              <div>
                <label class="vis-form-field">
                  <span>&nbsp;</span>
                  <app-icon-sim style="display: inline-block;zoom: 1.5;margin-left: 13px;"
                    *ngIf="!isSelfPay"></app-icon-sim> <!-- (click)="getPatiCent()" -->
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="">IC註記</span>
                  <input class="vis-input" formControlName="RemarkIC" />
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <ng-container *ngIf="reloadICBtnShow;else noSync">
                    <span i18n="" class="isq-num" style="cursor: pointer;display: flex;align-items: center;"
                      (click)="onBtnIsqnoClick()">
                      <span [ngStyle]="{top:fontGreater===true?'6px':''}">就醫序號</span>
                      <mat-icon class="vis-combine-icon d-inline-flex mr-1 mat-icon-20" title="更新">sync</mat-icon>
                    </span>
                  </ng-container>
                  <ng-template #noSync>
                    <span i18n="">就醫序號</span>
                  </ng-template>

                  <input class="vis-input position-relative" formControlName="Isqno" #Isqno
                    [ngStyle]="{marginTop:reloadICBtnShow?'0.66px':''}" />
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="">就醫類別</span>
                  <input class="vis-input" formControlName="Nhictp" />
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="">可用次數</span>
                  <input class="vis-input" formControlName="AvailableCount"
                    [ngClass]="[editFormGroup.controls.AvailableCount.value < 1 ? 'available-count-red' : '']" />
                </label>
              </div>
              <div style="width: 30%;">
                <label class="vis-form-field">
                  <span i18n="">狀態/提醒</span>
                  <input [hidden]="!(alertColor=='')" class="vis-input" formControlName="StatusRemark" readonly />
                  <input [hidden]="!(alertColor=='red')" class="vis-input" formControlName="StatusRemark" readonly
                    style="color: red;" />
                  <input [hidden]="!(alertColor=='blue')" class="vis-input max-w-60" formControlName="StatusRemark"
                    readonly style="color: blue;" />
                </label>
              </div>
              <div [hidden]="true">
                <button type="button" [appOpenDialog]="newBorn" *ngIf="displayNewBorn&&!isSelfPay"
                  (open)="newBornOpen()" class="vis-btn-config p-0 position-relative pl-2 w-22 mx-1 thin-btn"
                  title="新生兒">
                  <mat-icon class="pref-icon vis-combine-icon">child_care</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2 font-weight-bold">新生兒</span>
                </button>
              </div>
              <div [hidden]="true">
                <button type="button" [appOpenDialog]="referralInDiag" [beforeOpen]="referralOpen.bind(this)" 
                  class="vis-btn-config p-0 position-relative pl-2 mr-1 w-18 thin-btn" title="轉入" [hidden]="!canEdit">
                  <mat-icon class="pref-icon vis-combine-icon">people</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2 font-weight-bold">轉入</span>
                </button>
              </div>
            </div>
            <!--掛號資料 1-->
            <div class="reg-row">
              <div style="width: 20%;">
                <label class="vis-form-field">
                  <span class="required" i18n="@@RegDate">看診日</span>
                  <app-vis-datepicker formControlName="RegDate" class="required" [disabled]="!canEdit" [maxWidth]="true"
                    [customClass]="'w-100'" [max]="editOptions.MaxAppointmentDate"
                    (blurChange)="onRegDateChange($event)"></app-vis-datepicker>
                  <app-validation-tip controlName="RegDate"></app-validation-tip>
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@Insurance">身分</span>
                  <app-vis-combobox formControlName="ICode" [data]="editOptions.insurance" [textField]="'text'"
                    [panelClass]="'reg-scale'"
                    [valueField]="'value'" [valueFormat]="'value'" [valuePrimitive]="true" [allowInput]="false"
                    (valueChange)="onICodeChange($event)" [customClass]="'bg-white d-inline-block w-100'">
                  </app-vis-combobox>
                  <app-validation-tip controlName="ICode"></app-validation-tip>
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="">類別</span>
                  <app-vis-combobox formControlName="SameTreat" [data]="sameTreatOpts" [textField]="'text'"
                    [valueField]="'value'" [valueFormat]="'value'" [emitPrev]="true" [valuePrimitive]="true"
                    [allowInput]="false" (valueChange)="onSameTreatChange($event)"
                    [panelClass]="'reg-scale'"
                    [customClass]="'bg-white d-inline-block w-100'">
                  </app-vis-combobox>
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="">卡序
                    <!-- <mat-icon class="vis-combine-icon d-inline-flex mr-1 btn-scale-80 float-right -top-2" title="更新" (click)="ReadCardToGetLastSeqNumAndGetRegisterBasic()">sync</mat-icon> -->
                  </span>
                  <app-vis-combobox *ngIf="icnoLock!=='NORMAL'" formControlName="Icno" [data]="registerSeq"
                    [allowInput]="false" [textField]="'text'" [valueField]="'value'" [valueFormat]="'value'"
                    [valuePrimitive]="true" (valueChange)="onICNoChange($event)"
                    [panelClass]="'reg-scale'"
                    [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <!-- <app-vis-combobox *ngIf="icnoLock=='OTHER'" formControlName="Icno" [data]="registerOther"
                  [allowInput]="false" [textField]="'text'" [valueField]="'value'" [valueFormat]="'value'"
                  [valuePrimitive]="true" (valueChange)="onRegisterSeqSelectByIcodeNineChge($event)"
                  [customClass]="'bg-white d-inline-block max-w-18'">
                </app-vis-combobox> -->
                  <input *ngIf="icnoLock=='NORMAL'" class="vis-input" disabled formControlName="Icno"
                    [title]="'就醫序號'+editFormGroup.controls.Isqno?.value" />
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@INote">備註
                  </span>
                  <app-vis-combobox formControlName="INote" [data]="iNoteOpt" [textField]="'text'"
                    [allowInput]="false" [setNotInComboData]="true" [valueField]="'value'" [valuePrimitive]="true"
                    [valueFormat]="'value'" [customClass]="'bg-white d-inline-block'"
                    [panelClass]="'reg-scale'"
                    (valueChange)="onINoteChange($event)">
                  </app-vis-combobox>
                  <app-validation-tip controlName="IdRemarkCode"></app-validation-tip>
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@MedDeptCode">科別</span>
                  <app-vis-combobox formControlName="MedDeptCode" [data]="editOptions.section"
                    [allowInput]="false" [textField]="'text'" [valueField]="'value'" [valueFormat]="'value'"
                    [panelClass]="'reg-scale'" 
                    [valuePrimitive]="true" [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <app-validation-tip controlName=""></app-validation-tip>
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@Discount">優待
                  </span>
                  <app-vis-combobox formControlName="DiscountCode" [data]="editOptions.discount" [textField]="'text'"
                    [valueField]="'value'" [valueFormat]="'value'" [valuePrimitive]="true" [allowInput]="false"
                    [panelClass]="'reg-scale'"
                    (valueChange)="discountChange($event)" [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <app-validation-tip controlName="DiscountCode"></app-validation-tip>
                </label>
              </div>
              <div  style="width: 20%;">
                <label class="vis-form-field">
                  <span i18n="@@Remark" class="top-0">護理登錄</span>
                  <app-vis-combobox formControlName="Remark" [data]="remarkList" [textField]="'text'"
                    [valueField]="'value'" [valuePrimitive]="true" [allowInput]="true" (valueInput)="remarkInput($event)"
                    (valueChange)="remarkChange($event)" [panelClass]="'reg-scale'" [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <!-- <textarea rows="1" class="summary-textarea w-100" maxlength="1000" formControlName="Remark"
                    style="top:0; height: 25px; line-height: 12.5px; resize: none;">
                  </textarea> -->
                </label>
              </div>
            </div>
            <!--掛號資料 2-->
            <div class="reg-row">
              <div>
                <label class="vis-form-field">
                  <span i18n="@@RegVisitType">初複診</span>
                  <app-vis-combobox formControlName="VisitSourceCode" [data]="editOptions.visitType"
                    [textField]="'text'" [allowInput]="false" [valueField]="'value'" [valuePrimitive]="true"
                    [panelClass]="'reg-scale'"
                    [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <!-- <app-icon-sim *ngIf="!isSelfPay" (click)="getPatient()"></app-icon-sim> {{getVisitCode()}}-->
                  <!--隱藏欄位上傳用-->
                  <!-- <input formControlName="VisitSourceCode" [hidden]="true" /> -->
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span class="required" i18n="@@RegPeriod">時段</span>
                  <app-vis-combobox formControlName="TimeSec" [data]="editOptions.timeSection" [textField]="'text'"
                    [allowInput]="false" [valueField]="'value'" [valuePrimitive]="true" class="required"
                    [panelClass]="'reg-scale'"
                    (valueChange)="onTimeSecChange()" [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <app-validation-tip controlName="TimeSec"></app-validation-tip>
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span class="required" i18n="@@Room">診間
                  </span>
                  <app-vis-combobox formControlName="RoomCode" [data]="medRoom" [textField]="'text'" class="required"
                    [allowInput]="false" [valueField]="'value'" [valuePrimitive]="true"
                    [panelClass]="'reg-scale'"
                    (valueChange)="onRoomSelectionChange()" [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <app-validation-tip controlName="RoomCode"></app-validation-tip>
                </label>

              </div>
              <div >
                <label class="vis-form-field">
                  <span i18n="@@Doctor">
                    {{isHomeCare ? '醫師' : '醫師'}}
                  </span> <!-- 先統一都顯示[醫師]-->
                  <!-- <div class="k-textbox read-only">{{editFormValue.DoctorName}}</div> -->
                  <app-vis-dropdownlist formControlName="DoctorId" [data]="doctors" [textField]="'text'"
                    [valueField]="'value'" [valuePrimitive]="true" class="required d-inline-block max-w-28"
                    [panelClass]="'reg-scale'"
                    (valueChange)="onDoctorSelectionChange($event)">
                    <ng-template let-data>
                      <div [ngStyle]="positionStyle(data.extension)">{{data.text}}</div>
                    </ng-template>
                  </app-vis-dropdownlist>
                </label>
              </div>
              <div >
                <label class="vis-form-field">
                  <span class="required" i18n="@@Status">狀態</span>
                  <app-vis-combobox formControlName="StatusCode" [data]="editOptions.statusCode" [textField]="'text'"
                    [valueField]="'value'" [valueFormat]="'value'" [valuePrimitive]="true" class="required"
                    [allowInput]="false" [hidden]="isFromCompEnter==true?true:(!canEditStatus)"
                    [panelClass]="'reg-scale'"
                    (valueChange)="onStatusSelectionChange($event)" [customClass]="'bg-white d-inline-block'">
                  </app-vis-combobox>
                  <span *ngIf="initReg" class="register-status"
                    [hidden]="isFromCompEnter==true?false:(canEditStatus)">{{initReg.StatusName}}</span>
                  <app-validation-tip controlName="StatusCode"></app-validation-tip>
                </label>
              </div>
              <div [hidden]="true">
                <label class="vis-form-field">
                  <span i18n="@@Deposit">看診號</span>
                  <input class="vis-input" value="{{editFormValue.WaitNo}}" formControlName="WaitNo" />
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@Deposit">押金</span>
                  <input class="vis-input" formControlName="Deposit" />
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@OwnFee" class="nhi-support">部分負擔</span>
                  <input class="vis-input" formControlName="OwnFee" />
                </label>
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@RegisterFee">掛號費</span>
                  <input class="vis-input" formControlName="RegisterFee" />
                </label> <!--v-disabled-->
              </div>
              <div>
                <label class="vis-form-field">
                  <span i18n="@@SelfPay">自費</span>
                  <input class="vis-input" type="tel" formControlName="SelfPay" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--./card-body-->
    </div>
    <!--病歷區  -->
    <div class="card border-top-0" style="overflow-y: hidden;"
      [ngClass]="[!isShowCompleteRegister?'d-none':(!isFromCompEnter||!changeToSelfPay?'':' d-none')]"
      [ngStyle]="{height:sameTreatHeight}" *ngIf="isLoaded && !isCancelRegister">
      <div class="card-body p-0 overflow-x-hidden d-flex">
        <div>
          <button
            [ngStyle]="slider_stateA=='idle'?{background:'var(--third-primary)',fontWeight:'bolder'}:{background:'#e6ffff'}"
            class="d-flex three-part-label position-sticky top-0" (click)="getThreeToggle(0)">一般看診</button>
          <!-- <button [ngStyle]="slider_stateB=='idle'?{background:'#c2d6ff',fontWeight:'bolder'}:{background:'#e6e6ff'}"
            class="d-flex three-part-label position-sticky top-0" (click)="getThreeToggle(1)"
            style="z-index: 9;">同療看診</button> -->
        </div>
        <div class="three-part-content" style="background: linear-gradient(90deg, #e6ffff 0%, white 150%);"
          [@slide_in_out]="slider_stateA">
          <div style="padding: 0 15px;">
            <div class="row" *ngIf="isLoaded">
              <div class="col-sm-7 pl-1">
                <div class="inside-scrollbar w-100 p-0 mt-1">
                  <app-vis-grid *ngIf="histGrid" [data]="histGrid" [pageSize]="pageSize" [skip]="pageSkipNo"
                    [pageable]="false" [setDefaultSeclected]="true" [showStickyHead]="true" [customStyle]="'198px'"
                    [customClass]="'float-right '" [navigable]="true" [selectable]="{mode: 'single'}"
                    [showMultiLineHead]="true" [kendoGridSelectBy]="'RegId'" (cellClick)="histGridCellClickHandler($event)"
                    [selectedKeys]="selectedItems" style="width: calc(100% - 0.25rem);display: inline-grid;"
                    class="no-border">

                    <app-vis-grid-column field="RegDate" title="就診日" [width]="fontGreater===true?90:80"
                      i18n-title="@@RegDate" class="text-center custom-m-auto">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="ellipsis-content" [title]="dataItem.RegDate | formatDate:''">{{ dataItem.RegDate |
                          formatDate:'' }}</div>
                      </ng-template>
                    </app-vis-grid-column>

                    <app-vis-grid-column field="ICode" title="身分" [width]="40" i18n-title="@@ICode"
                      class="text-center custom-m-auto"></app-vis-grid-column>
                    <app-vis-grid-column field="SameTreat" title="類" [width]="20" i18n-title="@@SameTreat"
                      class="text-center custom-m-auto"></app-vis-grid-column>
                    <app-vis-grid-column field="Isqno" title="卡序" [width]="50" i18n-title="@@Isqno"
                      class="text-center custom-m-auto"></app-vis-grid-column>
                    <app-vis-grid-column field="Case" title="案類" [width]="50" i18n-title="@@Case"
                      class="text-center custom-m-auto"></app-vis-grid-column>
                    <app-vis-grid-column field="Dx1Name" title="症候" [width]="170"
                      i18n-title="@@DxName"></app-vis-grid-column>
                    <app-vis-grid-column field="Days" title="日" [width]="40" i18n-title="@@DrugDays"
                      class="text-center custom-m-auto"></app-vis-grid-column>
                    <app-vis-grid-column field="Deposit" title="押金" [width]="50" i18n-title="@@Deposit"
                      class="text-right"></app-vis-grid-column>
                    <app-vis-grid-column field="Dept" title="科別" [width]="45" i18n-title="@@Dept"
                      class="text-center custom-m-auto"></app-vis-grid-column>
                    <app-vis-grid-column field="DoctorName" title="醫師" [width]="70" i18n-title="@@DoctorName"
                      class="text-center custom-m-auto"></app-vis-grid-column>
                  </app-vis-grid>
                  <div class="d-block text-center bg-light-gray px-5" style="width: calc(100% - 4px);padding: 2.7rem 0;"
                    *ngIf="!histGrid?.data || histGrid?.data.length<1" #nodata>
                    <p>沒有本院病歷</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <!-- && isNormalClinicShow -->
                <label class="mb-0 font-weight-bold d-block">醫令
                  <button type="button"
                    class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none"
                    style="min-height: 20px;">
                    <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="getCCToggle()">
                      <ng-container *ngIf="maxCCOpen">call_to_action</ng-container>
                      <ng-container *ngIf="!maxCCOpen">web_asset</ng-container>
                    </mat-icon>
                  </button>
                </label>
                <div style="height: calc(100% - 22px);min-height:181px;max-height: 181px;overflow: hidden;"
                  class="d-block w-100">
                  <div class="rcd-textarea w-100 overflow-y-auto" style="resize: none;z-index: 1000;"
                    [ngClass]="maxCCOpen?'resize-open cc-rm':''"
                    [ngStyle]="{height:maxCCOpen?ccRmHeight:'',overflowY:maxCCOpen?'auto':''}">
                    <app-shared-hist-hist-view #histViewComp [IsShowToolBar]="false"></app-shared-hist-hist-view>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" [formGroup]="editRemarkFormGroup">
                <label class="mb-0 font-weight-bold d-block"><span class="ml-1">特別記載</span>
                  <mat-icon class="w-4 h-auto mr-1 top-2 pref-icon vis-combine-icon text-xlg" type="button"
                    [hidden]="!canEdit" *ngIf="canEditPatRemark" style="font-size: 133%;" (click)="onBtnRemarkClick()"
                    title="特別記載選項">settings</mat-icon>
                  <button type="button"
                    class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none"
                    style="min-height: 20px;">
                    <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="getRmToggle()">
                      <ng-container *ngIf="maxRmOpen">call_to_action</ng-container>
                      <ng-container *ngIf="!maxRmOpen">web_asset</ng-container>
                    </mat-icon>
                  </button>
                </label>
                <div style="height: calc(100% - 22px);min-height:181px;max-height: 181px;" class="d-block w-100">
                  <textarea class="rcd-textarea w-100" style="resize: none;z-index: 1000;"
                    formControlName="PatientRemark" [ngClass]="maxRmOpen?'resize-open cc-rm':''"
                    [ngStyle]="{height:maxRmOpen?ccRmHeight:''}" [readonly]="!canEditPatRemark">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 d-block mt-1 position-relative" style="height: 1px;">
            <div class="d-inline-block position-absolute end-0" style="width:41.333%;">
              <app-periodic-grid [data]="PeriodItems" [pageSize]="6"></app-periodic-grid>
            </div>
          </div>

          <div class="d-block px-1 position-relative" [ngStyle]="{width:maxRegMenuOpen?'100%':'58.333333%'}">
            <div style="direction: rtl;display: flex;">
              <button type="button" class="title-btn2 orders-btn end-0 p-0 background-none" style="min-height: 20px;">
                <mat-icon style="width: 16px;height: 16px;line-height: 16px;font-size: 20px;"
                  class="vis-combine-icon element-icon btn-scale-85" (click)="getRegMenuToggle();">
                  <ng-container *ngIf="maxRegMenuOpen">call_to_action</ng-container>
                  <ng-container *ngIf="!maxRegMenuOpen">web_asset</ng-container>
                </mat-icon>
              </button>
            </div>

            <div class="d-flex flex-wrap-wrap reg-menu">
              <ng-container *ngFor="let data of menuData; let i = index">
                <button class="flex-grow-one text-center m-auto" [ngClass]="bigRatio===false?'':'px-0'"
                  (click)="getRegMenu(i)" [ngClass]="slider_statesA[i]">
                  {{ data.label }}
                </button>
              </ng-container>
            </div>
            <div class="d-flex mt-2">
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[0]">
                <!-- 重大傷病 -->
                <app-vis-grid [data]="criIllNoHPC" [pageable]="true" [skip]="0" [pageSize]="5" [customClass]="''"
                  *ngIf="showElement">
                  <app-vis-grid-column [title]="'病代'" [field]="'Code'" [width]="147" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'病名'" [field]="'Name'" [width]="148" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'起日'" [field]="'StartDate'" [width]="148" class="text-center custom-m-auto">
                    <ng-template let-data>{{data.StartDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
                    </ng-template>
                  </app-vis-grid-column>
                  <app-vis-grid-column [title]="'迄日'" [field]="'EndDate'" [width]="164" class="text-center custom-m-auto">
                    <ng-template let-data>
                      {{data.EndDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
                    </ng-template>
                  </app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[1]">
                <!-- 主次診斷 -->
                <app-vis-grid [data]="treatmentHPC" [customClass]="''" [pageable]="true" [skip]="0" [pageSize]="5"
                  *ngIf="showElement">
                  <app-vis-grid-column [title]="'就醫日'" [field]="'Date'" [width]="176" class="text-left custom-m-auto">
                    <ng-template let-data>
                      <div class="ellipsis-content" [title]="data.Date | formatROCDate">{{data.Date | formatROCDate }}
                      </div>
                    </ng-template>
                  </app-vis-grid-column>
                  <app-vis-grid-column [title]="'主診斷'" [field]="'Dx1'" [width]="77" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'次診斷1'" [field]="'Dx2'" [width]="90" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'次診斷2'" [field]="'Dx3'" [width]="90" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'次診斷3'" [field]="'Dx4'" [width]="90" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'次診斷4'" [field]="'Dx5'" [width]="90" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'次診斷5'" [field]="'Dx6'" [width]="90" class="text-left"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[2]">
                <!-- 重要醫令 -->
                <app-vis-grid [scroll]="false" *ngIf="showElement" class="small-paginater w-100"
                  [showMultiLineHead]="true" [customClass]="''" [pageable]="true" [skip]="0" [pageSize]="5"
                  [data]="pagedHVE" (pageChange)="HVEPageChanged($event)">
                  <app-vis-grid-column [title]="'就醫日'" [field]="'ImplementDate'" [width]="77"
                    class="text-center custom-m-auto">
                    <ng-template let-data>
                      <div class="ellipsis-content" [title]="data.ImplementDate | formatROCDate">{{data.ImplementDate | formatROCDate }}</div>
                    </ng-template>
                  </app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫療院所代碼'" [field]="'ClinicCode'" [width]="85" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫療院所名稱'" [field]="'ClinicName'" [width]="130" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫令項目代碼'" [field]="'TreatmentCode'" [width]="80" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫令項目名稱'" [field]="'TreatmentName'" [width]="120" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'實施部位'" [field]="'ImplementPartCode'" [width]="60" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'總量'" [field]="'Total'" [width]="40" class="text-right"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[3]">
                <!-- 過敏藥品 -->
                <div style="display: flex;flex-shrink: 1;flex-grow: 1;" *ngIf="showElement">
                  <div style="flex-grow: 1;">
                    <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug1">
                    <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug2">
                    <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug3">
                    <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug4">
                    <input style="display: block;width: 100%;" [(ngModel)]="allergicDrug5">
                  </div>
                  <button class="w-24  rounded-5 ml-1 vis-btn-warning" style="min-height: 6rem;">過敏藥寫入IC
                    <mat-icon role="img" title="IC" aria-hidden="true" data-mat-icon-type="font"
                      class="mat-icon notranslate text-base position-relative d-inline-flex material-icons mat-icon-no-color h-auto w-auto"
                      style="transform: rotate(-90deg); font-size: 3rem;"> sim_card</mat-icon>
                  </button>
                </div>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[4]">
                <!-- 門診醫令 -->
                <app-vis-grid [scroll]="false" *ngIf="showElement" [customClass]="''" class="small-paginater w-100"
                  [pageable]="true" [skip]="0" [pageSize]="10" [data]="pagedOutPatient" [showMultiLineHead]="true"
                  (pageChange)="outpatientPageChanged($event)">
                  <app-vis-grid-column [title]="'就醫日'" [field]="'ClinicDateTime'" [width]="140" class="text-center custom-m-auto">
                    <ng-template let-data>
                      <div class="ellipsis-content" [title]="data.ClinicDateTime | formatROCDate">{{data.ClinicDateTime
                        | formatROCDate }}</div>
                    </ng-template>
                  </app-vis-grid-column>
                  <app-vis-grid-column [title]="'類別'" [field]="'OrderClass'" [width]="64" class="text-center order-class"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'診療項目代號'" [field]="'NhiDrugCode'" [width]="100" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'診療項目名稱'" [field]="'NhiDrugName'" [width]="100" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'診療部位'" [field]="'TreatmentSite'" [width]="64" class="text-left treatment-site"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'用法'" [field]="'Frequency'" [width]="47" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'天數'" [field]="'Days'" [width]="40" class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'總量'" [field]="'TotalDose'" [width]="47" class="total-dose text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'交付處方註記'" [field]="'DispensingType'" [width]="110" class="text-left"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[5]">
                <!-- 長期醫令 -->
                <app-vis-grid [scroll]="false" *ngIf="showElement" [customClass]="''" class="small-paginater w-100"
                  [pageable]="true" [skip]="0" [pageSize]="5" [data]="pagedLongTerm"
                  (pageChange)="longTermPageChanged($event)">
                  <app-vis-grid-column [title]="'開立日期'" [field]="'CreateDate'" [width]="80" class="text-center custom-m-auto">
                    <ng-template let-data>
                      <div class="ellipsis-content" [title]="data.CreateDate | formatROCDate">{{data.CreateDate | formatROCDate }}</div>
                    </ng-template>
                  </app-vis-grid-column>
                  <app-vis-grid-column [title]="'藥品代碼'" [field]="'NhiDrugCode'" [width]="100" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'藥品名稱'" [field]="'NhiDrugName'" [width]="220" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'用法'" [field]="'Frequency'" [width]="50" class="text-center"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'天數'" [field]="'Days'" [width]="50" class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'總量'" [field]="'TotalDose'" [width]="70" class="text-right"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[6]">
                <!-- 器官捐贈 -->
                <input class="vis-input w-100" readonly [value]="OrganDonate" *ngIf="showElement" />
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[7]">
                <!-- 就醫資料 -->
                <app-vis-grid [scroll]="true" *ngIf="showElement" [pageable]="true" [skip]="0" [pageSize]="5"
                  [customClass]="''" [showMultiLineHead]="true" [tableFullWidth]="'w-100'" [data]="treatmentNoHPC">
                  <app-vis-grid-column [title]="'就醫時間'" [field]="'Date'" [width]="160" class="text-left"></app-vis-grid-column>
                  <!-- <app-vis-grid-column [title]="''" [field]="'Name'" class="text-center"></app-vis-grid-column> -->
                  <app-vis-grid-column [title]="'卡序'" [field]="'Isqno'" [width]="70"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'院所'" [field]="'Clinic'" [width]="100"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'院所名稱'" [field]="'ClinicName'" [width]="210"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'類別'" [field]="'Itp'" [width]="70" class="text-center"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'依附'" [field]="'NewBorn'" [width]="70" class="text-center"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'補卡'" [field]="'Recard'" [width]="70" class="text-center"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'門診部分負擔'" [field]="'Deductible'" [width]="120" class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'門診醫療費用'" [field]="'MedicalFees'" [width]="120" class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'住院醫療費用'" [field]="'HospitalFees'" [width]="120" class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'住院部分負擔A'" [field]="'HdeductibleA'" [width]="120" class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'住院部分負擔B'" [field]="'HdeductibleB'" [width]="120" class="text-right"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[8]">
                <!-- 就醫費用 -->
                <app-vis-grid [scroll]="false" [customClass]="''" [data]="cumulatives" [showMultiLineHead]="true"
                  [pageable]="true" [skip]="0" [pageSize]="5" *ngIf="showElement">
                  <app-vis-grid-column [title]="'年度'" [field]="'Year'" [width]="80"
                    class="text-center"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'門住診次數'" [field]="'CimulativeCount'" [width]="80"
                    class="text-center"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'門診費用'" [field]="'MedicalFeesSum'" [width]="68"
                    class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'住診費用'" [field]="'HospitalFeesSum'" [width]="68"
                    class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'門診負擔'" [field]="'DeductibleSum'" [width]="68"
                    class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'住診負擔1'" [field]="'HdeductibleASum'" [width]="75"
                    class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'住診負擔2'" [field]="'HdeductibleBSum'" [width]="75"
                    class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'門住診負擔'" [field]="'MHdeductibleSum'" [width]="88"
                    class="text-right"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'門住1診負擔'" [field]="'MHdeductibleASum'" [width]="88"
                    class="text-right"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[9]">
                <!-- 預防保健 -->
                <app-vis-grid [scroll]="true" [customClass]="''" [tableFullWidth]="'w-100'" [pageable]="true" [skip]="0"
                  [pageSize]="5" *ngIf="showElement" [data]="registerPreventSubset">
                  <app-vis-grid-column [title]="'檢查日期'" [field]="'ExamDate'" [width]="87"
                    class="text-center custom-m-auto">
                    <ng-template let-data>
                      <div class="ellipsis-content" [title]="data.ExamDate | formatROCDate">{{data.ExamDate |
                        formatROCDate }}</div>
                    </ng-template>
                  </app-vis-grid-column>
                  <app-vis-grid-column [title]="'檢查項目代碼'" [width]="100" [field]="'ProjectCode'" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫療院所代碼'" [width]="100" [field]="'ClinicCode'" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫療院所名稱'" [width]="200" [field]="'ClinicName'" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'保健服務項目註記'" [width]="100" [field]="'HealthCareFlag'" class="text-left"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[10]">
                <!-- 產前檢查 -->
                <app-vis-grid [scroll]="false" *ngIf="showElement" [showMultiLineHead]="true" [customClass]="''"
                  [data]="pagedRegisterPregnant" class="small-paginater" [pageSize]="5" [pageable]="true" [skip]="0"
                  (pageChange)="registerPregnantPageChanged($event)">
                  <app-vis-grid-column [title]="'檢查日期'" [field]="'ExamDate'" [width]="120" class="text-center custom-m-auto">
                    <ng-template let-data>
                      <div class="ellipsis-content" [title]="data.ExamDate | formatROCDate">{{data.ExamDate | formatROCDate }}</div>
                    </ng-template>
                  </app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫療院所代碼'" [width]="200" [field]="'ClinicCode'" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'醫療院所名稱'" [width]="150" [field]="'ClinicName'" class="text-left"></app-vis-grid-column>
                  <app-vis-grid-column [title]="'檢查項目代碼'" [width]="180" [field]="'ExamCode'" class="text-left"></app-vis-grid-column>
                </app-vis-grid>
              </div>
              <div class="flex-grow-one text-center m-auto" [@slide_in_out_00]="slider_statesA[11]">
                <!-- 預防接種 -->
                <app-vis-grid class="small-paginater" *ngIf="showElement" [customClass]="''" [showMultiLineHead]="true"
                  [scroll]="false" [pageSize]="5" [pageable]="true" [skip]="0" [data]="pagedInoculateData"
                  (pageChange)="inoculateDataPageChanged($event)">
                  <app-vis-grid-column [title]="'接種日期'" [field]="'InoculateDate'" [width]="100" class="text-center custom-m-auto">
                    <ng-template let-data>{{data.InoculateDate | formatROCDate }}<div class="ellipsis-content" [title]=""></div>
                    </ng-template></app-vis-grid-column>
                    <app-vis-grid-column [title]="'醫療院所代碼'" [field]="'ClinicCode'" [width]="120" class="text-left"></app-vis-grid-column>
                    <app-vis-grid-column [title]="'醫療院所名稱'" [field]="'ClinicName'" [width]="150" class="text-left"></app-vis-grid-column>
                    <app-vis-grid-column [title]="'疫苗種類'" [field]="'Type'" [width]="135" class="text-left"></app-vis-grid-column>
                    <app-vis-grid-column [title]="'疫苗批號'" [field]="'LotNumber'" [width]="144" class="text-left"></app-vis-grid-column>
                </app-vis-grid>
              </div>
            </div>
          </div>
        </div>
        <!--[disabled]="!isOpenMultiTraat"-->
        <div class="three-part-content" style="background: linear-gradient(90deg, #e6e6ff 0%, white 150%);"
          [@slide_in_out]="slider_stateB">
          <div style="padding: 0 15px;">
            <div class="row" *ngIf="isLoaded">
              <div class="col-sm-7 pl-1">
                <!-- isOpenMultiTraat && -->
                <app-muti-treatment-dialog [isDisabled]="!isAdd" (onEmitResult)="getMultiTreatResult($event)"
                  [InitData]="multiTreatList">
                </app-muti-treatment-dialog>
              </div>
              <div class="col-sm-3">
                <!-- isOpenMultiTraat && -->
                <label class="mb-0 font-weight-bold d-block">門診登錄
                  <button type="button"
                    class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none"
                    style="min-height: 20px;">
                    <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="getCCToggle()">
                      <ng-container *ngIf="maxCCOpen">call_to_action</ng-container>
                      <ng-container *ngIf="!maxCCOpen">web_asset</ng-container>
                    </mat-icon>
                  </button>
                </label>
                <div style="height: calc(100% - 25px);" [ngStyle]="{maxHeight:getCCMinMaxHeight()}"
                  class="d-block w-100">
                  <div class="rcd-textarea w-100 overflow-y-auto" style="resize: none;z-index: 1000;"
                    [ngClass]="maxCCOpen?'resize-open cc-rm':''"
                    [ngStyle]="{height:maxCCOpen?ccRmHeight:'',overflowY:maxCCOpen?'auto':''}">
                    <app-shared-hist-hist-view #histViewComp [IsShowToolBar]="false"></app-shared-hist-hist-view>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" [formGroup]="editRemarkFormGroup">
                <label class="mb-0 font-weight-bold d-block"><span class="ml-1">特別記載</span>
                  <mat-icon class="w-4 h-auto mr-1 top-2 pref-icon vis-combine-icon text-xlg" type="button"
                    [hidden]="!canEdit" style="font-size: 133%;" (click)="onBtnRemarkClick()"
                    title="特別記載選項">settings</mat-icon>
                  <button type="button"
                    class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none"
                    style="min-height: 20px;">
                    <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="getRmToggle()">
                      <ng-container *ngIf="maxRmOpen">call_to_action</ng-container>
                      <ng-container *ngIf="!maxRmOpen">web_asset</ng-container>
                    </mat-icon>
                  </button>
                </label>
                <div style="height: calc(100% - 25px);" [ngStyle]="{maxHeight:getCCMinMaxHeight()}"
                  class="d-block w-100">
                  <textarea class="rcd-textarea w-100" style="resize: none;" formControlName="PatientRemark"
                    [ngClass]="maxRmOpen?'resize-open cc-rm':''" [ngStyle]="{height:maxRmOpen?ccRmHeight:''}">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- 同療看診內容 -->
          <div class="d-flex w-100">
            <div class="flex-grow-one py-1 same-treat-slide">
              <app-rehabilitation *ngIf="onlyonelist" [InitRehabilitationData]="initmultiTreatmentUserData"
                #rehabilitation></app-rehabilitation>
            </div>
            <div class="flex-grow-one py-1 pl-2 ml-2 rehabilitation-order-select">
              <div class="w-100 d-flex" *ngIf="onlyonelist">
                <div class="flex-grow-two">
                  <app-rehabilitation-order [isEditDisabled]="!isAdd" (onEmitHistOrderResult)="getRehabithrx($event)"
                    (onEmitUsers)="getRehabitMedUsers($event)" [InitmultiTreatmentUser]="initmultiTreatmentUserData"
                    [InitRehabOrderData]="onlyonelist"></app-rehabilitation-order>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-register-history *ngIf="editFormGroup && patientId"
      [patientId]="patientId" [spRemark]="editRemarkFormGroup.controls.PatientRemark" (spRemarkChange)="getRemarkWindowResult($event)"
      [periodItems]="PeriodItems">
    </app-register-history> -->
  </div>
</div>
<!--身分變更流程訊息-->

<app-vis-dialog title="醫師班表" [width]="1180" [height]="'95%'" [show]="isOpenCalendar" (close)="isOpenCalendar=false"
  i18n-title="@@DoctorSchedule">
  <div style="float:right;">
    <button type="button" (click)="isOpenCalendar=false" i18n="@@Cancel">取消</button>
  </div>
  <app-schedule-doctor-month *ngIf="isOpenCalendar" [isComponentMode]="true" (selectionEvent)="onCalendarEmit($event)">
  </app-schedule-doctor-month>
</app-vis-dialog>

<app-registers-payment [isOpen]="isOpenBeforeCharge" [payment]="beforeChargeData" [availablePayWay]="payWay"
  (Save)="onPay($event)" (Close)="isOpenBeforeCharge=false"></app-registers-payment>

<app-msg-text textId="MSG_RegistersRegister1" i18n="@@MSG_RegistersRegister1">
  無法以過去時間掛號
</app-msg-text>
<app-msg-text textId="MSG_RegistersRegister2" i18n="@@MSG_RegistersRegister2">
  取得候診號失敗
</app-msg-text>
<app-msg-text textId="MSG_RegistersRegister3" i18n="@@MSG_RegistersRegister3">
  掛號日期不能早於今天
</app-msg-text>


<app-vis-dialog [title]="'新生兒依附就醫掛號'" [superWidth]="'50%'" [maxWidth]="'50vw'" #newBorn>
  <div *ngIf="newBornFormHelper" [formGroup]="newBornFormHelper?.FormGroup" class="inside-scrollbar d-flex"
    style="flex-wrap: wrap;column-gap: 10%;row-gap: 10px;max-height: 455px;">
    <div style="width: 45%;display: flex;">
      <span style="width: 100px;" class="lh-base">新生兒姓名</span>
      <input [formControlName]="newBornFormHelper.FieldName.Name"
        style="flex-grow: 1;min-width: unset;width: inherit;" />
    </div>

    <div style="width: 45%;display: flex;">
      <span style="width: 40px;" class="lh-base">性別</span>
      <app-vis-dropdownlist class="text-base" [formControlName]="newBornFormHelper.FieldName.Sex"
        [data]="editOptions.sex" style="display: inline-block;flex-grow: 1;"></app-vis-dropdownlist>
    </div>
    <div style="width: 45%;display: flex;">
      <span style="width: 130px;" class="lh-base">新生兒生日</span>
      <app-vis-datepicker [formControlName]="newBornFormHelper.FieldName.Birth" [maxWidth]="false" class="w-100"
        style="display: inline-block;flex-grow: 1;"></app-vis-datepicker>
    </div>
    <div style="width: 45%;display: flex;">
      <span class="lh-base">本次生產第</span>
      <app-vis-dropdownlist class="text-base" [formControlName]="newBornFormHelper.FieldName.BirthsNum"
        [data]="BirthsNumList" style="display: inline-block;flex-grow: 1;"></app-vis-dropdownlist>
      <span>胎</span>
    </div>
    <div style="width: 45%;display: flex;">
      <span style="width: 100px;" class="lh-base">新生兒身分證</span>
      <input [formControlName]="newBornFormHelper.FieldName.CId" maxlength="10"
        style="flex-grow: 1;min-width: unset;width: inherit;" />
    </div>
    <div style="width: 45%;display: flex;">
      <span style="width: 40px;" class="lh-base">電話</span>
      <input [formControlName]="newBornFormHelper.FieldName.Tel"
        style="flex-grow: 1;min-width: unset;width: inherit;" />
    </div>
    <div style="display: flex;width: 100%;">
      <span style="width: 100px;" class="lh-base">依附紀錄</span>
      <app-vis-grid style="flex-grow: 1;" [data]="newBornList">
        <app-vis-grid-column [field]="'PatientNo'" [title]="'病歷號'" class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [field]="'Name'" [title]="'姓名'" class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [field]="'Sex'" [title]="'性別'" class="text-center">
          <ng-template let-data>
            {{data.Sex | valueToText:editOptions.sex}}
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [field]="'Birth'" [title]="'生日'" class="text-center">
          <ng-template let-data>
            {{data.Birth | formatDate:''}}
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [field]="'BirthsNum'" [title]="'胞胎'" class="text-center"></app-vis-grid-column>
        <app-vis-grid-column [field]="'PatientMastId'" [title]="'掛入'" class="text-center">
          <ng-template let-data>
            <button type="button" (click)="existNewBornReg(data.PatientMastId, newBorn)" title="掛入"
              [disabled]="isSaveClicked" class="my-1 text-xs btn-grid px-1 background-none">
              <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="掛入">check_circle</mat-icon>
              <span class="text-xs-sm float-right">掛入</span>
            </button>
          </ng-template>
        </app-vis-grid-column>
      </app-vis-grid>
    </div>
  </div>
  <div class="my-3 text-center dialog-action-btn">
    <button (click)="newBornReg(newBorn);">依附掛號</button>
    <button (click)="newBorn.show=false">取消</button>
    <button (click)="writeNewBornMark(newBorn);">寫入依附註記</button>
  </div>
</app-vis-dialog>

<app-vis-dialog [title]="'門診轉診就醫'" [superWidth]="'30%'" [maxWidth]="'30vw'" #referralInDiag>
  <div class="card-body pb-0" style="font-size: 16px;"> <!-- [formGroup]="referralFG"> -->
    <div class="row">
      <div class="col-sm-12">
        <label style="display: flex;">
          <span i18n="@@OriHosp">轉入之院所代號：</span>
          <app-vis-dropdownlist class="text-base" [data]="hspid" style="display: inline-block; flex-grow: 1;"
            [value]="oriHosp" [valueField]="'HID'" [textField]="'HNAME'" (valueChange)="onHspidChange($event)"
            [valuePrimitive]="true"></app-vis-dropdownlist>
        </label>
      </div>
      <div class="col-sm-12">
        <label style="display: flex;">
          <span i18n="@@RefDiagFee">是否帶入接受轉診門診診察費加算？</span>
          <mat-checkbox (change)="refDiagFeeChange($event)" [checked]="refDiagFee">
          </mat-checkbox>
        </label>
      </div>
      <div class="col-sm-9">
        <label style="display: flex;">
          <span i18n="@@OriDate">轉診單開立日期：</span>
          <app-vis-datepicker [maxWidth]="false" style="width: 50px;" (blurChange)="onOriDateChange($event)"
            [value]="oriDate" style="display: inline-block; flex-grow: 1;"></app-vis-datepicker>
        </label>
      </div>
    </div>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="referralInSure(referralInDiag)" i18n="@@Sure">確定</button>
    <button type="button" (click)="referralInDiagClose(referralInDiag)" i18n="@@RegCancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<app-vis-dialog title="基資特殊身分" [show]="isOpenPatSpecial" (close)="isOpenPatSpecial = false" [width]="600" i18n-title="@@HpcCodePin">
  <div style="padding: 10px 15px; white-space: pre-wrap; overflow-x: hidden; overflow-y: auto;" class="extend-msg">
    請選擇特殊的身分：</div>
  <div class="print-toolbar inside-scrollbar pt-0 mt-0">
    <div style="display: flex;" class="checkbox-label-ui">
      <ng-container *ngFor="let item of editOptions.spcIdentity">
        <mat-checkbox [disabled]="!this.editFormGroup.enabled" [checked]="spcChecked(item.value)"
          style="width:7.33rem;" (change)="spcChange(item.value,$event)">{{item.text}}</mat-checkbox>
      </ng-container>
    </div>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onPatSpecialSave()" i18n="@@Add">儲存</button>
    <button type="button" (click)="isOpenPatSpecial = false" i18n="@@RegCancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<app-shared-patient-remark-dialog *ngIf="editRemarkFormGroup" [isDialogOpened]="switchPatientRemarkDialog"
  [selectedText]="patientRemarkSelectedText" (emitResult)="getRemarkWindowResult($event)">
</app-shared-patient-remark-dialog>

<!--取消掛號 確認畫面-->
<ng-template let-data #cancelRegTmp>
  <app-vis-grid [data]="pageData" class="no-border">
    <app-vis-grid-column field="RegDate" title="看診日期" [width]="100" i18n-title="@@RegDate"
      class="text-center custom-m-auto">
      <ng-template let-dataItem>
        <div class="ellipsis-content" title="{{ dataItem.RegDate | formatDate:'' }}">{{ dataItem.RegDate | formatDate:''
          }}</div>
      </ng-template>
    </app-vis-grid-column>
    <app-vis-grid-column field="RoomCode" title="診間" [width]="60" i18n-title="@@RegRoom" class="text-center">
    </app-vis-grid-column>
    <app-vis-grid-column field="TimeSec" title="時段" [width]="60" i18n-title="@@RegPeriod" class="text-center">
      <ng-template let-dataItem>
        <div class="ellipsis-content" title="{{ dataItem.TimeSec==1 ? '上午' : (dataItem.TimeSec==2 ? '下午' : '晚上') }}">
          {{ dataItem.TimeSec==1 ? '上午' : (dataItem.TimeSec==2 ? '下午' : '晚上') }}
        </div>
      </ng-template>
    </app-vis-grid-column>
    <app-vis-grid-column field="WaitNo" title="看診號" [width]="60" i18n-title="@@RegWaitNo"
      class="text-center"></app-vis-grid-column>
    <app-vis-grid-column field="PatientCId" title="身分證" [width]="110" i18n-title="@@RegPeriod"></app-vis-grid-column>
    <app-vis-grid-column field="PatientFirstName" title="姓名" i18n-title="@@RegPeriod" [width]="110"
      class="text-center"></app-vis-grid-column>
    <app-vis-grid-column field="StatusName" title="狀態" [width]="100" i18n-title="@@Status"
      class="text-center custom-m-auto">
      <ng-template let-dataItem>
        <div class="ellipsis-content" title="{{ dataItem.StatusName }}"
          [ngClass]="[dataItem.StatusCode == 'F' ? 'font-weight-bold vis-color-third-primary' : '', dataItem.StatusCode == 'H' ? 'font-weight-bold vis-color-cancel' : '']">
          {{ dataItem.StatusName }}
        </div>
      </ng-template>
    </app-vis-grid-column>
  </app-vis-grid>
  <div class="card-body pb-0">
    <p class="mb-1">可退掛條件:</p>
    <ol class="pl-2 mb-0">
      <li class="w-50 d-inline-block">1.卡序必須與退掛日期同年度。</li>
      <li class="w-50 d-inline-block">2.必須在24小時以內。</li>
      <li class="w-50 d-inline-block">3.需VPN連線中。</li>
      <li class="w-50 d-inline-block">4.需為同一家院所的掛號資料。</li>
      <li class="w-50 d-inline-block">5.同一天若執行部份退掛，不可再執行全退。</li>
    </ol>
  </div>
</ng-template>

<app-arrear-receipt [hidden]="true" [regId]="printArrearReceiptRegId" #arrearReceipt></app-arrear-receipt>

<app-vis-dialog title="列印押金單" [show]="isShowPrintArrearReceiptDialog" (close)="onDialogClose()">
  <div class="card-body pb-0" style="font-size: 16px;"> <!-- [formGroup]="referralFG"> -->
    <div class="row">
      <div class="col-sm-12">
        <label style="display: flex;">
          <span i18n="@@RefDiagFee">是否列印押金單(欠卡單)?</span>
        </label>
      </div>
    </div>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="PrintArrearReceipt()" i18n="@@Sure">確定</button>
    <button type="button" (click)="onDialogClose()" i18n="@@RegCancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
