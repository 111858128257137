import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { MultiPrescriptWrite } from 'src/app/shared/hcr-lib/MultiPrescriptWrite';
import { TreatmentData } from 'src/app/shared/hcr-lib/TreatmentData';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';

import { PrescriptionSignMultiWrite } from 'src/app/shared/hcr-lib/PrescriptionSignMultiWrite';

import { UserCache } from 'src/app/services/user-cache';

import { CashierPreview, PricingApi } from 'src/app/services/api-service/pricing/pricing-api';


export class CashierViewer {
    CashierPreview: CashierPreview;
    CaseTps:string[]= [];
    SortedCase:any[]= [];
    IsCaseTps: boolean;
  }
@Injectable({
  providedIn: 'root'
})
export class RatingPriceService {

  constructor(private histApi: HistApi,
    private api: PricingApi,
) {
  }
  isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  isRecoveryHome = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
  async getCashierViewer(histRcd :WholeHist,caspcheckOpt,defaultCase :string,hasdrug : boolean = false):Promise<CashierViewer> {
   if (UserCache.getLoginUser().Clinic.Type == 3 || UserCache.getLoginUser().Clinic.Type ==4) var feedback = await this.getFromDentist(histRcd,defaultCase);
   else  feedback =  await this.getFromNormal(histRcd, defaultCase,caspcheckOpt,hasdrug);   
   feedback.IsCaseTps = feedback.CaseTps != undefined && feedback.CaseTps != null
    if (feedback.IsCaseTps) feedback.IsCaseTps = feedback.CaseTps.length > 0;
    return feedback;
  }

  async getFromNormal(histRcd :WholeHist,defaultCase :string,caspcheckOpt: string[],hasdrug : boolean = false):Promise<CashierViewer>{
    let is01incase = caspcheckOpt.some(x => x == '01') && !this.isRecoveryHome && hasdrug;
    let is09incase = caspcheckOpt.some(x => x == '09') && !this.isRecoveryHome
    var feedBack = new CashierViewer();
    feedBack.CashierPreview = await this.api.Preview(histRcd, defaultCase, is01incase, is09incase);


    for (var caseTp in feedBack.CashierPreview) {
        feedBack.CaseTps.push(caseTp);
    }

    // 用藥費金額比較取最大的項目  小計不符合現今的規範(小計會因為交付而短少 可能會出現算起來比簡表小的問題)
      feedBack.SortedCase = feedBack.CaseTps.sort((a: string, b: string) => {
      var isSelfpay = histRcd.Register.ICode == '000'
      var subsumA = (feedBack.CashierPreview[a].Injection ?? 0) + (feedBack.CashierPreview[a].Oral ?? 0) + (feedBack.CashierPreview[a].External ?? 0);
      var subsumB = (feedBack.CashierPreview[b].Injection ?? 0) + (feedBack.CashierPreview[b].Oral ?? 0) + (feedBack.CashierPreview[b].External ?? 0);
      if (a == '01' && b == '09' && isSelfpay) {
        return 1;
      }
      else if (a == '09' && b == '01' && isSelfpay) {
        return -1;
      }
      return subsumA < subsumB ? 1 : subsumA == subsumB ? 0 : -1;
    });
    return feedBack;
  }

  async getFromDentist(histRcd :WholeHist,defaultCase :string):Promise<CashierViewer>{
    var feedBack = new CashierViewer();

    feedBack.CashierPreview = await this.api.PreviewDentist(histRcd, defaultCase);

    for (var caseTp in feedBack.CashierPreview) {
        feedBack.CaseTps.push(caseTp);
    }
    // 用藥費金額比較取最大的項目  小計不符合現今的規範(小計會因為交付而短少 可能會出現算起來比簡表小的問題)
    feedBack.SortedCase = feedBack.CaseTps.sort((a: string, b: string) => {
      var subsumA = (feedBack.CashierPreview[a].Total ?? 0);
      if (a == '11' && b == '19' && subsumA >= 300) {
        return 1;
      }
      else if (a == '19' && b == '11' && subsumA >= 300) {
        return -1;
      }
      return 0;
    });
    return feedBack;

  }
}
