<div class="inside-scrollbar p-0" #myDiv>
    <app-vis-grid [data]="gridView"
        [pageSize]="pageSize"
        [skip]="pageSkipNo"
        [pageable]="false"
        [showStickyHead]="true"
        [customStyle]="'129px'"
        [customClass]="'float-right'"
        (pageChange)="pageChange($event)"
        (selectionChange)="SelectChange($event)"
        [navigable]="true"
        [showMultiLineHead]="true"
        [selectable]="true"
        [kendoGridSelectBy]="'Isqno'"
        [selectedKeys]="selectedItems"
        (cellClick)="gridCellClickHandler($event)"
        style="width: calc(100% - 1rem);display: inline-grid;"
        class="no-border">
        <app-vis-grid-checkbox-column class="text-center" [setDisable]="isDisabled" [field]="'Checked'" [width]="30" [someChecked]="isSomeChecked()"  (checkChanged)="uploadDataCheckChanged($event);"></app-vis-grid-checkbox-column>
        <!-- [selectOnCheck]="true" [allChecked]="isAllChecked()" [someChecked]="isSomeChecked()" [showSelectAll]="true"
       >
        </app-vis-grid-checkbox-column> -->
      <app-vis-grid-column field="StartDate" title="治療首日" [width]="100" i18n-title="@@StartDate" class="text-center">
        <ng-template let-data>
          <div class="ellipsis-content" [title]="data.StartDate | formatDate">{{data.StartDate | formatDate}}</div>
        </ng-template>
      </app-vis-grid-column>
        <app-vis-grid-column field="ICode" title="身分" [width]="60" i18n-title="@@ICode" class="text-center custom-m-auto"></app-vis-grid-column>
        <app-vis-grid-column field="Isqno" title="卡序" [width]="40" i18n-title="@@Isqno" class="text-center custom-m-auto" ></app-vis-grid-column>
        <app-vis-grid-column field="Dept" title="科別" [width]="45" i18n-title="@@Dept" class="text-center custom-m-auto"></app-vis-grid-column>
        <app-vis-grid-column field="CusNo" title="已做療程" [width]="80"i18n-title="@@CusNo"  class="text-right custom-m-auto">
        </app-vis-grid-column>
        <app-vis-grid-column field="CusTot" title="療程總數" [width]="80"i18n-title="@@CusTot" class="text-right custom-m-auto">
        </app-vis-grid-column>
        <app-vis-grid-column field="field2" title="療程預計日" [width]="100" i18n-title="@@Total" class="text-right"></app-vis-grid-column>
    </app-vis-grid>
    <!-- <button type="button" (click)="onDialogClose('ok')" i18n="@@Select" style="width: 1.66rem;min-height: 5.45rem;" class="p-0 ml-1 float-left">選擇</button> -->
</div>
