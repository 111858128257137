import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { DrugBagInfo, DrugBagInfoDetail } from 'src/app/services/api-service/hist/whole-large-hist';
import { Data } from '@angular/router';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';


@Component({
    selector: 'app-drug-bag',
    templateUrl: './drug-bag.component.html',
    styleUrls: ['./drug-bag.component.css']
})
export class DrugBagComponent implements OnInit {

    @ViewChild('reportContainer')
    reportContainer: ElementRef<HTMLDivElement>;

    //hist: WholeHist;
    hist: DrugBagInfo;
    clinic: Clinic;
    //editOptions: RegistEditOpt;
    drugCount: number = 0;
    byOral: boolean;
    byExternalUse: boolean;
    indications: string;
    reConsulateDate: string;
    nextDrugDateStart: string;
    nextDrugDateEnd: string;
    /** 起始點微調(公分) Style */
    startBlankHeightStyle: string = 'height: 0cm';
    formType: number = 1;   // 藥袋種類: 1:展望標準藥袋 2:一藥一袋型式
    printNextTakeDrugDate: boolean = false;
    printNextRegDate: boolean = false;
    printPatientBirthday: boolean = true;
    printNextPickupDrugDate: boolean = true;
    fontType: string;
    // 字體大小
    titleFont: number = 32;
    addressFont: number = 20; // promptFont
    patientFont: number = 19; // doctorFont
    drugFont: number = 16;
    // 列印寬度
    patPaddingTop: number = 0;  //13
    patPaddingTop1: number = 13;  //13
    patPaddingBottom: number = 5;
    rxIds: number[] = [];
    // 本地報表測試用
    @Input()
    set regId(id){
        this.setRegId(id);
    }

    clinicAddress = '';
    hrxes:DrugBagInfoDetail[];

    constructor(private histApi: HistApi,
                private clinicDataService: ClinicDataService,
                private cdr: ChangeDetectorRef) {

    }

    async ngOnInit() {
      this.clinic = UserCache.getLoginUser().Clinic;
      this.clinicAddress = this.clinic.City + this.clinic.Area + this.clinic.Street;
    }

    async setRegId(regId:number){
      // var param = await this.clinicDataService.getParam("PRN001");
      this.hist = await this.histApi.getDrugBag(regId, this.rxIds);
      this.formType = this.hist.FormType;
      this.drugCount = this.hist.Hrx[0].data.length;
      this.hrxes = this.hist.Hrx[0].data;
      this.hist.TotalBag = this.hist.Hrx[0].totalPages;   //this.hist.Hrx.length;
      this.hist.BagNo = 1;
    }

    async setData(data:DrugBagInfo, rxIds: number[]){
      this.rxIds = rxIds;
      var param = await this.clinicDataService.getParam("PRN001");
      this.fontType = (param.FontTypeForPrint == 0 ? '細明體' : (param.FontTypeForPrint == 1 ? '標楷體' : (param.FontTypeForPrint == 2 ? '微軟正黑體' : '細明體')));
      this.hist = data;
      this.printPatientBirthday = this.hist.PrintPatientBirthday;
      this.printNextPickupDrugDate = this.hist.PrintNextPickupDrugDate;
      this.formType = this.hist.FormType;
      this.hrxes = this.hist.Hrx[0].data;
      this.hist.TotalBag = this.hist.Hrx[0].totalPages;   //this.hist.Hrx.length;
      this.drugCount = this.hist.Hrx[0].data.length;
      this.startBlankHeightStyle = 'height:' + (this.hist.StartBlankHeight ?? 0) + 'cm';
      //負數的話用margin-top
      if(this.hist.StartBlankHeight!=null&&this.hist.StartBlankHeight!=undefined&&this.hist.StartBlankHeight!=''&&this.hist.StartBlankHeight.startsWith('-')){
        this.startBlankHeightStyle = 'margin-top:' + (this.hist.StartBlankHeight ?? 0) + 'cm';
      }
      this.drugFont = this.hist.HrxFontSize;
      var pageWidth = this.hist.DrugBagWidth ?? 8;
      var pageHeight = this.hist.DrugBagHeight ?? 7;
      if (pageWidth < 6.7) {
        this.titleFont = 26;
        this.addressFont = 16;    // promptFont
        this.patientFont = 18;    // doctorFont
        this.drugFont = 13;
        this.patPaddingTop = 1;
        this.patPaddingTop1 = 1;
        this.patPaddingBottom = 0;
      }
      var st = document.createElement('style');
      // 標準藥袋 8in x 7in
      st.appendChild(document.createTextNode(
        '@page { size: ' + pageWidth + 'in ' + pageHeight + 'in; margin: 0cm; }\n'+
        '@media print {body {margin: 0;color: #000;background-color: #fff;}}'
      ));

      setTimeout(() => {
        if(this.reportContainer){
        this.reportContainer.nativeElement.appendChild(st);
        this.cdr.markForCheck();
        }
      }, 0);

      // 慢箋非最後一次會印下次領藥日期，最後一次才印下次回診日
      var printReConsultateLimit = this.hist.PrintReConsultateLimit ?? 0;
      this.printNextRegDate = false;
      this.printNextTakeDrugDate = false;
      if (this.hist.IsSameTreat8) {
        if (this.hist.CntPresNo.length > 0 && (this.hist.CntPresNo == this.hist.CntPresTot || this.hist.IsH8)) {
          if (printReConsultateLimit > 0) { 
            this.printNextRegDate = true; 
            this.reConsulateDate = DateHelper.formatROCDate(this.hist.BackDate, true, false);
          } else {
            this.printNextRegDate = false; 
          }
        }
        if (this.hist.CntPresNo.length > 0 && (Number)(this.hist.CntPresNo) < (Number)(this.hist.CntPresTot)) { // || (this.hist.CntPresNo == '2' && !this.hist.IsH8))) {
          this.printNextRegDate = false;        // 非最後一次慢箋領藥，預設不印下次回診日，要印下次領藥日，
          this.printNextTakeDrugDate = true;
          // 遇到第二次且一次領完 -> 印下次回診日，不印下次領藥日
          if (this.hist.CntPresNo == '2' && this.hist.IsH8) {  
            this.printNextRegDate = true;
            this.printNextTakeDrugDate = false;
          }

          if (this.hist.PresDateStartAndEnds?.length > 0) { 
            for (var i = 0; i < this.hist.PresDateStartAndEnds.length; i++) {
              if ((this.hist.PresDateStartAndEnds[i].CntPresNo - 1).toString() == this.hist.CntPresNo) {
                this.nextDrugDateEnd = DateHelper.formatROCDate(this.hist.PresDateStartAndEnds[i].EndDate, true, false);
                this.nextDrugDateStart = DateHelper.formatROCDate(this.hist.PresDateStartAndEnds[i].StartDate, true, false);
              }
            }
          }
        }
      } else { // 一般診
        if (this.hist.CntPresNo == null || this.hist.CntPresNo == '' || this.hist.CntPresNo == undefined || this.hist.CntPresNo.length == 0) {
          var reConsDate = new Date(this.hist.RegDate);
          // 取最大天數
          var days = Math.max(...this.hrxes.map(x => x.Days));
          // 判斷是否超過建議回診日的參數設定值
          if (printReConsultateLimit > 0 && days > printReConsultateLimit) {  // 有設定則檢查給藥日數是否超過設定值，未設定就不印
            this.printNextRegDate = true;
            reConsDate.setDate(new Date(this.hist.RegDate).getDate() + days);  // 建議回診日
            this.reConsulateDate = DateHelper.formatROCDate(reConsDate, true, false);
          } else {
            this.printNextRegDate = false;
          }
        }
      }
    }

    getHtml() {
        var html = this.reportContainer.nativeElement.innerHTML;
        return html;
    }

    formatRocDate(src: Date, withSepetator: boolean = false): string {
        if (withSepetator) {
            let regDate = new Date(src);
            let ret = regDate.getFullYear()-1911 + '.' + (regDate.getMonth()+1).toString().padStart(2,'0') + '.' + regDate.getDate().toString().padStart(2,'0')
            return ret;
        } else {
            return DateHelper.formatROCDate(src, false, false);
        }
    }

    calculateAge(src: Date|string): string {
        if (src == null || src == '') {
          return '';
        }

        var result = "";
        // collect input date
        var dob = new Date(src);
        var dobYear = dob.getFullYear();
        var dobMonth = dob.getMonth();
        var dobDate = dob.getDate();

        // get the current date from the system
        var now = new Date();
        // extract the year, month, and date from current date
        var currentYear = now.getFullYear();
        var currentMonth = now.getMonth();
        var currentDate = now.getDate();

        // get years
        var yearAge = currentYear - dobYear;

        // get months
        if (currentMonth >= dobMonth) {
          //get months when current month is greater
          var monthAge = currentMonth - dobMonth;
        } else {
          yearAge--;
          var monthAge = 12 + currentMonth - dobMonth;
        }

        // get days
        if (currentDate >= dobDate) {
          // get days when the current date is greater
          var dateAge = currentDate - dobDate;
        } else {
          monthAge--;
          // get the days in the last month
          var daysInLastMonth = new Date(currentYear, currentMonth, 0).getDate();
          var dateAge = daysInLastMonth + currentDate - dobDate;
          if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
          }
        }
        // group the age in a single variable
        result = yearAge + '歲';
        return result;
    }
}
