export class toothTreatPosDto {
    /** 牙位代碼 ex. 11, 12...84, 85 */
    posNo: string;
    /** 牙齒治療紀錄 */
    treatRecord: toothTreated;
}

export class toothTreated {
    /** 不可補牙的牙面位置 OD. ex. c, r, l, u, b */
    cantOD: string[];
    /** 可做 ENDO (根管治療)  */
    canENDO: boolean;
    /** 可拔牙 Extraction */
    canExt: boolean;
    /** 可溝縫封填 Sealant */
    canSea: boolean;
}