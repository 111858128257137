<div>
    <mat-icon  style="width: 18px;height: 18px;font-size: 18px;vertical-align: middle;cursor: pointer;" title="列印中" 
        (click)="showPrinter=!showPrinter" >
        print
    </mat-icon>
    <div *ngIf="showPrinter" class="main_border">
        <div *ngIf="progresses.length==0">
            無列印工作
        </div>
        <div *ngIf="displayPrinterInfo" [ngClass]="displayCls" style="display: flex;">
            <div class="printer-name" [title]="displayPrinterInfo.PrinterName">
                {{displayPrinterInfo.PrinterName}}
            </div>
            <div class="document-name" [title]="displayPrinterInfo.DocName">
                {{displayPrinterInfo.DocName}}
            </div>
            <div class="step" [title]="displayPrinterInfo.StepDesc">
                {{displayPrinterInfo.StepDesc}}
            </div>
            <div class="page">
                {{displayPrinterInfo.PageText}}
            </div>
        </div>
        
    </div>
</div>

