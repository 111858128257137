import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { HistView } from 'src/app/hist/models/hist-view';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { settings } from 'cluster';

@Component({
  selector: 'app-shared-hist-hist-view',
  templateUrl: './hist-view.component.html',
  styleUrls: ['./hist-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistViewComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
    private notification: EasyNotificationService,
    private webApiFactory: WebApiClient,
    private histApi: HistApi,
    private cd: ChangeDetectorRef) {
    this.api = this.webApiFactory.createHisService('hist/hist');
  }

  //#region 參數 --------------------
  private unsubscribe: Subject<void> = new Subject();
  // api服務
  api: WebApiService;
  histView: HistView = new HistView();
  copyFG: FormGroup;
  /**是否顯示無資料*/
  isNoData=false;
  get copyFV() {
    return this.copyFG.value;
  }
  //#endregion 參數 --------------------

  //#region opd-record --------------------
  // @Input() patientId = '';
  @Output() emitInsert = new EventEmitter<HistView>();
  //#endregion opd-record --------------------
  _isShowToolBar = true;

  // @Input()
  // disabled: Boolean = false;
  private _disabled = false;
  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabled();
  }

  @Input()
  set IsShowToolBar(input: boolean) {
    this._isShowToolBar = input;
  }


  @Input() minHist = false;

  @Input() showNhiPay = false;
  /** 醫令執行人員 */
  executor:ValueTextPair[];

  async ngOnInit() {
    this.copyFG = this.makeCopyFG();
    this.executor = await this.histApi.GetExecutorList();
    this.cd.detectChanges();
    // if (this.disabled) {
    //   this.copyFG.disable();
    // }
    this.setDisabled();
  }

  setDisabled() {
    if (this.copyFG) {
      if (this._disabled) {
        this.copyFG.disable();
      } else {
        this.copyFG.enable();
      }
    }
  }

  @Input() ClinicName: any;

  // 建立query form group
  makeCopyFG() {
    return this.fb.group({
      isInsert: [true],
      isIncludeCC: [true],
      isIncludePE: [true],
      isIncludeDiag: [true],
      isIncludeOrder: [true],
    });
  }

  // #region get data --------------------
  getOpdRecord(id: number, autoCopy: boolean = false) {
    if (!id) {
        this.isNoData = true;
        this.cd.detectChanges();
      return;
    }
    this.isNoData=false;
    this.api.get('GetHistory/' + id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (data: HistView) => {
        if(data && data?.HRxs?.length>0){
          data.HRxs.forEach(ele => {
            if(ele.MedID!=null&&ele.MedID!=undefined&&ele.MedID!=""){
              var user=  this.executor.find(f=>f.value==ele.MedID);
              if(user){
                ele.MedIDName=user.text;
              }
            }
          });
        }
        this.histView = data;
        this.showText(autoCopy);
      },
      error => this.notification.showGetDataFailed('hist-view:getOpdRecord') // 失敗訊息
    );
  }

  showText(autoCopy: boolean) {
    this.cd.detectChanges();

    // autoCopy
    if (autoCopy) {
      this.onCopyClick();
    }
  }
  // #endregion get data --------------------

  // #region action --------------------
  onCopyClick() {
    if (this.copyFV.isInsert === true) {
      this.histView.IsInsert = true;
    } else {
      this.histView.IsInsert = false;
    }
    this.histView.IsIncludeCC = this.copyFV.isIncludeCC;
    this.histView.IsIncludePE = this.copyFV.isIncludePE;
    this.histView.IsIncludeDiag = this.copyFV.isIncludeDiag;
    this.histView.IsIncludeOrder = this.copyFV.isIncludeOrder;
    if(this.histView && this.histView?.HRxs?.length>0){
      this.histView.HRxs.forEach(ele => {
        if(ele.MedID!=null&&ele.MedID!=undefined&&ele.MedID!=""){
          var user=  this.executor.find(f=>f.value==ele.MedID && f.extension.Item2);
          if(!user){
            ele.MedID='';
          }
        }
      });
    }
    this.emitInsertHist(this.histView);
  }
  // #endregion action --------------------

  //#region opd-record --------------------
  // receive command - selectHist
  selectHist(histId: number) {
    this.getOpdRecord(histId);
  }

  selectHistAndCopy(histId: number) {
    this.getOpdRecord(histId, true);
  }

  emitInsertHist(histView: HistView) {
    this.emitInsert.emit(histView);
  }
  //#endregion opd-record --------------------

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
