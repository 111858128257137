<app-vis-dialog title="收費" [width]="370" [hidden]="!isOpen" (close)="onCloseClick()" i18n-title="@@Charge">
  <div class="card no-border" *ngIf="payment">
    <div class="card-body no-padding">
      <table class="c-table">
        <thead>
          <tr>
            <th i18n="@@FeeName" class="title-col">費用項目</th>
            <th i18n="@@Subtotal">小計</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let detail of payment.Details">
            <tr>
              <td>
                {{detail.FeeName}}
              </td>
              <td>
                {{detail.Subtotal}}
              </td>
            </tr>
          </ng-container>
          <tr class="total-price-row">
            <td>
              <span class="total-price" i18n="@@HistTotalPrice">總金額</span>
            </td>
            <td>
              {{payment.Payable}}
            </td>
          </tr>
        </tbody>
      </table>
      <div style="height:10px;">&nbsp;</div>
      <form [formGroup]="formGroup" *ngIf="formGroup">
        <table class="c-table">
          <thead>
            <tr>
              <th i18n="@@PayWay" class="title-col">付款方式</th>
              <th i18n="@@PayAmount">金額</th>
            </tr>
          </thead>
          <tbody>
            <tr class="highlight">
              <!-- 應收 -->
              <td>
                <span i18n="@@Receivable">應收</span>
              </td>
              <td>
                {{formGroup.value.Payable}}
                <input class="vis-input" formControlName="Payable" readonly="readonly" [hidden]="true" />
              </td>
            </tr>
            <!-- 信用卡 -->
            <tr [hidden]="!IsPayWayActive('CreditCard')">
              <td>
                <span i18n="@@PaidCreditCard">信用卡</span>
              </td>
              <td>
                <input formControlName="PaidCreditCard" appNumberOnly/>
                <app-validation-tip controlName="PaidCreditCard"></app-validation-tip>
              </td>
            </tr>
            <!-- 現金卡 -->
            <tr [hidden]="!IsPayWayActive('CashCard')">
              <td>
                <span i18n="@@PaidCashCard">現金卡</span>
              </td>
              <td>
              <input formControlName="PaidCashCard" appNumberOnly/>
                <app-validation-tip controlName="PaidCashCard"></app-validation-tip>
              </td>
            </tr>
            <!-- 醫保卡 -->
            <tr [hidden]="!IsPayWayActive('MedCard')">
              <td>
                <span i18n="@@PaidMedCard">醫保卡</span>
              </td>
              <td>
                <app-vis-numerictextbox formControlName="PaidMedCard" [min]="0" [format]="'n0'" [spinners]="false">
                </app-vis-numerictextbox>
                <app-validation-tip controlName="PaidMedCard"></app-validation-tip>
              </td>
            </tr>
            <!-- 其他 -->
            <tr [hidden]="!IsPayWayActive('Other')">
              <td>
                <span i18n="@@PaidOther">其他</span>
              </td>
              <td>
                <input formControlName="PaidOther" appNumberOnly/>
                <app-validation-tip controlName="PaidOther"></app-validation-tip>
              </td>
            </tr>
            <!-- 其他備註 -->
            <tr [hidden]="!IsPayWayActive('Other')">
              <td>
                <span i18n="@@PaidOther">其他備註</span>
              </td>
              <td>
                <input formControlName="PaidOtherNote" />
                <app-validation-tip controlName="PaidOtherNote"></app-validation-tip>
              </td>
            </tr>
            <!-- 現金應收 -->
            <tr>
              <td>
                <span i18n="@@PayReceivableCash">現金應收</span>
              </td>
              <td>
                {{formGroup.value.PaidCash}}
                <input formControlName="PaidCash" readonly="readonly" [hidden]="true" />
              </td>
            </tr>
            <tr class="highlight">
              <!-- 現金實收 -->
              <td>
                <span i18n="@@PayCashReceipt">現金實收</span>
              </td>
              <td>
                <input id="register_payment_cashTake" formControlName="CashTake" appNumberOnly/>
                <app-validation-tip controlName="CashTake"></app-validation-tip>
              </td>
            </tr>
            <tr>
              <!-- 現金找零 -->
              <td>
                <span>
                  <ng-container i18n="@@PayCashChange">現金找零</ng-container>
                </span>
              </td>
              <td>
                {{formGroup.value.CashChange}}
                <input formControlName="CashChange" readonly="readonly" [hidden]="true" />
              </td>
            </tr>
            <tr class="highlight">
              <!-- 實收 -->
              <td>
                <span i18n="@@PayReceived">實收</span>
              </td>
              <td>
                {{formGroup.value.Paid}}
                <input formControlName="Paid" readonly="readonly" [hidden]="true" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <div class="error-color" *ngIf="validMsgId">
        <ng-container *ngIf="validMsgId==='1'" i18n="@@PayShortage">欠收金額</ng-container>
        <ng-container *ngIf="validMsgId==='2'" i18n="@@PayMakeChange">需找零</ng-container>
        <ng-container *ngIf="validMsgId==='3'" i18n="@@CardOverPaid">已超收卡片費用
        </ng-container>
      </div>
    </div>
  </div>

  <app-vis-dialog-actions>
    <button type="button" (click)="onBtnChargeClick()" primary="true" i18n="@@Save">儲存</button>
    <button type="button" (click)="onCloseClick()" i18n="@@Cancel">取消</button><button type="button" (click)="onCloseClick()" i18n="@@Cancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
