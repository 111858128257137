import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HistoryListDto } from 'src/app/services/api-service/patient/patientHis-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { onGetDateString } from 'src/app/shared/utilities';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.css']
})
export class HistoryListComponent implements OnInit, AfterViewInit {

  reportName = '病歷清單';
  forExcel = false;

  sizePage = {
    // width: 20, //cm
    // height: 28.7 //cm
    width: 28.7, //cm
    height: 20.7 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  upperboundIndex: number = 0;
  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  _sDate: Date;
  _eDate: Date;
  // visitsReport: VisitsReport;  //報表表頭資料
  listItems: HistoryListDto[] = [];
  totalFirstVisitPatients: number = 0;
  totalRevisitPatients: number = 0;
  totalPatientsInMorning: number = 0;
  totalPatientsInAfternoon: number = 0;
  totalPatientsInEvening: number = 0;
  totalPaidByHealthCare: number = 0;
  totalPaidBySelf: number = 0;

  // api服務
  api: WebApiService;
  // 查詢條件;
  // queryFormGroup: FormGroup;

  constructor(
    // private webApiClient: WebApiClient,
    private localPrintService: LocalPrintService,
    private hcrService: HcrService,
    private notification: EasyNotificationService) {
      // (沒用到)
      // this.api = this.webApiClient.createHisService('Opd/OpdReport');
    }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  async fetchData(data: HistoryListDto[], forExcel = false) {
    this.forExcel = forExcel;

    this.elContainer = document.getElementById("container");
    //此報表沒表頭
    // this.visitsReport = new VisitsReport();

    //以下是報表表頭欄位, 目前沒用到, 先給預設值。
    // this.nowStr = this.onGetDateString(new Date(), true);
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    // this.timeStr = this.onGetTimeString(new Date());
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    // this._sDate = query.startDate;
    this._sDate = DateHelper.today;
    // this._eDate = query.endDate;
    this._eDate = DateHelper.today;

    //資料改由外部傳入
    // this.api.get('GetVisitsReport', query).subscribe(
    //   (data: VisitsReport) => {
    //     this.visitsReport = data;
    //     this.listItems = this.showDoctorNameColume ? data.Items.filter(x => x.IsSummary == false) : data.Items;
    //     this.totalRecord = this.showDoctorNameColume ? this.listItems.filter(x => x.IsSummary == false && x.IsDoctorTotal == false).length : this.listItems.filter(x => x.IsSummary == false).length;
    //     this.totalFirstVisitPatients = 0;
    //     this.totalRevisitPatients = 0;
    //     this.totalPatientsInMorning = 0;
    //     this.totalPatientsInAfternoon = 0;
    //     this.totalPatientsInEvening = 0;
    //     this.totalPaidByHealthCare = 0;
    //     this.totalPaidBySelf = 0;
    //     var prevName = '';
    //     this.listItems.forEach(x => {
    //       if (x.IsSummary||x.IsDoctorTotal) {
    //         this.totalFirstVisitPatients += x.FirstVisitPatients;
    //         this.totalRevisitPatients += x.RevisitPatients;
    //         this.totalPatientsInMorning += x.PatientsInMorning;
    //         this.totalPatientsInAfternoon += x.PatientsInAfternoon;
    //         this.totalPatientsInEvening += x.PatientsInEvening;
    //         this.totalPaidByHealthCare += x.PaidByHealthCare;
    //         this.totalPaidBySelf += x.PaidBySelf;
    //       }
    //       var currName = '';
    //       if (this.showDoctorNameColume) {
    //         currName = x.DoctorName;
    //       } else {
    //         currName = x.ClinicName;
    //       }

    //       if (currName == prevName) {
    //         if (this.showDoctorNameColume) {
    //           x.DoctorName = '';
    //         } else {
    //           x.ClinicName = '';
    //         }
    //       } else {
    //         prevName = currName;
    //       }
    //     });
    //     // 將查詢的資料組成報表html
    //     this.insertListData();
    //   }
    // );
    this.listItems = data;
    this.totalRecord = this.listItems.length;
    this.insertListData();
  }

  insertListData() {
    //因為此報表只做匯出Excel, 沒表頭, 沒跳頁, 沒加總, 改成只有明細。以forExcel判斷。

    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭 (excel只用到table)
    var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
    var html_finish = this.forExcel ? '' : this.createHTMLFinish();              // 報表結束 -- 以下空白 (excel沒用到)
    var html_total = this.forExcel ? '' : this.createHTMLTotal();             //  (excel沒用到)
    var html_blank = this.forExcel ? '' : this.createHTMLBlankLine();         //  (excel沒用到)
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {
      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listItems[i]);
      elTableContent.innerHTML += html_Block;

      // (excel沒用到)
      // 判斷是否超出頁面範圍? -> 新增一頁
      if (!this.forExcel && elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // (excel沒用到)
      // 最後一筆要加上總計
      if (!this.forExcel && i == this.listItems.length-1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // (excel沒用到)
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if ((this.listItems[i].IsSummary || this.listItems[i].IsDoctorTotal) && !(i == this.listItems.length-1)) {
      if (!this.forExcel && !(i == this.listItems.length-1)) {
        afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var tPage = document.getElementById("totalPage-" + i);
      tPage.innerHTML = "" + (this.upperboundIndex + 1);
    }
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    var page = '';
    //強制儲存格轉文字mso-number-format
    if (this.forExcel) {
      page += `
        <style>
          .excel-format {
            font-size: 20px;
          }
          .excel-format-text {
            font-size: 20px;
            mso-number-format: "@";
          }
        </style>`;
    }
    page += `
      <div class="page" id="page-` + iPage + `"
                style="
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
    return page;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="11" style="height: 46px; border-right: none; border-left: none; border-bottom: none; border-top: none;">

              </th>
            </tr>`;
  }

  createHTMLListHeader() {
    var commonStyle = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;';
    var commonClass = this.forExcel ? 'class="excel-format"' : '';
    return `<tr>
              <th style="text-align: left;  width: 7%;  ${commonStyle}" ${commonClass}>病歷號</th>
              <th style="text-align: left;  width: 5%;  ${commonStyle}" ${commonClass}>姓名</th>
              <th style="text-align: left;  width: 5%;  ${commonStyle}" ${commonClass}>生日</th>
              <th style="text-align: left;  width: 7%;  ${commonStyle}" ${commonClass}>身分證號</th>
              <th style="text-align: left;  width: 2%;  ${commonStyle}" ${commonClass}>卡號</th>
              <th style="text-align: right; width: 2%;  ${commonStyle}" ${commonClass}>日份</th>
              <th style="text-align: left;  width: 5%;  ${commonStyle}" ${commonClass}>就診日</th>
              <th style="text-align: left;  width: 2%;  ${commonStyle}" ${commonClass}>身</th>
              <th style="text-align: left;  width: 2%;  ${commonStyle}" ${commonClass}>調劑</th>
              <th style="text-align: left;  width: 2%;  ${commonStyle}" ${commonClass}>案類</th>
              <th style="text-align: left;  width: 2%;  ${commonStyle}" ${commonClass}>tp</th>
              <th style="text-align: right; width: 5%;  ${commonStyle}" ${commonClass}>藥費</th>
              <th style="text-align: right; width: 5%;  ${commonStyle}" ${commonClass}>其他</th>
              <th style="text-align: right; width: 5%;  ${commonStyle}" ${commonClass}>藥事</th>
              <th style="text-align: right; width: 5%;  ${commonStyle}" ${commonClass}>自付</th>
              <th style="text-align: right; width: 5%;  ${commonStyle}" ${commonClass}>總額</th>
              <th style="text-align: left;  width: 20%; ${commonStyle}" ${commonClass}>狀況</th>
              <th style="text-align: left;  width: 7%;  ${commonStyle}" ${commonClass}>電話</th>
              <th style="text-align: left;  width: 7%;  ${commonStyle}" ${commonClass}>手機</th>
            </tr>`;
  }

  createHTMLBlock(data: HistoryListDto) {
    // var summaryStyle = (data.IsSummary || data.IsDoctorTotal) ? "border-top: 1px dotted #777;" : "border-top: none;";
    var summaryStyle = "border-top: none;";
    // var showSummaryName = (data.IsSummary || data.IsDoctorTotal) ? "合計" : (this.showDoctorNameColume ? data.DoctorName : data.ClinicName);
    var showSummaryName = '';
    // var roomCode = data.RoomCode ?? "";
    var roomCode = '';
    // var showDate = data.Date ? this.onGetDateString(data.Date, true, '', false) : "";
    var showDate = '';
    // var visitorSummary = data.FirstVisitPatients + data.RevisitPatients;
    var visitorSummary = '';
    // return '<tr>' +
              // '<td style="text-align: left; font-weight: bold; ' + summaryStyle + ' border-bottom: none;">' + showSummaryName + '</td>' +
              // '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + roomCode + '</td>' +
              // '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + showDate + '</td>' +
              // '<td style="text-align: left; border-top: none; border-bottom: none;">' + data.PatientNo + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.PatientName + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.Birthday + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.CId + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.Isqno + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.Days + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.RegDate + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.Icode + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.DispTP + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.Case + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.SameTreat + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.ARx + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.Aother + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.AMed + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.AOwn + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.ADcl + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.Condition + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.HousePhone + '</td>' +
              // '<td style="text-align: right; border-top: none; border-bottom: none;">' + data.CellPhone + '</td>' +
              // '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + visitorSummary + '</td>' +
            // '</tr>\n';

    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClass = this.forExcel ? 'class="excel-format"' : '';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var dataBirthday = data.Birthday ? DateHelper.getROCDateString(new Date(data.Birthday)) : '';
    var dataRegDate = data.RegDate ? DateHelper.getROCDateString(new Date(data.RegDate)) : '';
    return `
      <tr>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientName}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${dataBirthday}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CId}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Isqno ?? ''}</td>
        <td style="text-align: right; ${commonStyle}" ${commonClass}    >${data.Days ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${dataRegDate}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Icode ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.DispTP ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Case ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.SameTreat ?? ''}</td>
        <td style="text-align: right; ${commonStyle}" ${commonClass}    >${data.ARx ?? ''}</td>
        <td style="text-align: right; ${commonStyle}" ${commonClass}    >${data.Aother ?? ''}</td>
        <td style="text-align: right; ${commonStyle}" ${commonClass}    >${data.AMed ?? ''}</td>
        <td style="text-align: right; ${commonStyle}" ${commonClass}    >${data.AOwn ?? ''}</td>
        <td style="text-align: right; ${commonStyle}" ${commonClass}    >${data.ADcl ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Condition ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.HousePhone ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CellPhone ?? ''} </td>
      </tr>`;
  }

  createHTMLTotal() {
    return `<tr border="0">
              <td style="text-align: left; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                總計
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">

              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">

              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalFirstVisitPatients + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalRevisitPatients + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPatientsInMorning + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPatientsInAfternoon + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPatientsInEvening + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPaidByHealthCare + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPaidBySelf + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + (this.totalFirstVisitPatients + this.totalRevisitPatients) + `
              </td>
            </tr>`;
  }

  //for excel 前面div沒用到, 只留table。
  createHTMLPageHeader(iPage) {
    var pageHeader = '';
    if (!this.forExcel) {
      pageHeader += `
        <div id="header" style="margin: 0px 10px;">
          <div style="display: flex;column-gap: 5px;">
            <table style="width: 100%; text-align: center; border: none;">
              <tr>
                <td colspan="11" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                ` + this.reportName + `
                </td>
              </tr>
              <tr>
                  <td colspan="3" style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  印表日期 ` + this.nowStr + `
                  </td>
                  <td colspan="5" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  期間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                  </td>
                  <td colspan="3" style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  總筆數 ` + this.totalRecord + `
                  </td>
              </tr>
              <tr>
                  <td colspan="3" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  印表時間 ` + this.timeStr + `
                  </td>
                  <td colspan="5" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                  </td>
                  <td colspan="3" style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                  </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
          <label style="font-weight: bold; height: 23px;">　</label>
        </div>`;
    }
    pageHeader += `
      <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
        <div style="display: flex;column-gap: 5px;">
          <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
          </table>
        </div>
      </div>`;
    return pageHeader;
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, this.reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(this.reportName + '已送出列印!', true);
      } else {
        this.notification.showError(this.reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {
      const table = this.reportContainer.nativeElement;
      // console.log(table.innerHTML);

      //html已有<table>經由ExportTableToExcel()後最外層會加上<table>，導致Excel第一行會空白，所以轉出時把table轉div。
      const html = this.forExcel ? table.innerHTML.replace('table', 'div') : table.innerHTML;

      //透過主控台寫入檔案
      await this.hcrService.ExportTableToExcel(this.reportName, html);
      //(沒用到)轉base64下載到download目錄，以主控台寫入為主。
      // await this.ExportTableToExcelDownload(reportName, table.innerHTML);
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }

    this.forExcel = false;
  }

  async ExportTableToExcelDownload(sheetName:string ,html:string){
    const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
                            xmlns:x="urn:schemas-microsoft-com:office:excel"
                            xmlns="http://www.w3.org/TR/REC-html40">
                        <head>
                            <!--[if gte mso 9]>
                            <xml>
                                <x:ExcelWorkbook>
                                    <x:ExcelWorksheets>
                                        <x:ExcelWorksheet>
                                            <x:Name>{worksheet}</x:Name>
                                            <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                                        </x:ExcelWorksheet>
                                    </x:ExcelWorksheets>
                                </x:ExcelWorkbook>
                            </xml>
                            <![endif]-->
                        </head>
                        <body>
                            <table>{table}</table>
                        </body>
                      </html>`;

    const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
    const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
    const table = this.reportContainer.nativeElement;
    const ctx = { worksheet: this.reportName, table: table.innerHTML };

    var fileName = sheetName +'_' + onGetDateString(new Date(), false, '', false) + DateHelper.getTimeString(new Date(), '',true) + `.xls`;
    // var content = format(template, ctx);
    // var ret =await this.hcrService.SaveTextAsZipIfPwd(this.reportName+'\\'+fileName,content);
    // await this.hcrService.ConfirmOpenDir('下載完成','檔案已下載至'+ret.Returns,ret.Returns);

    // window.location.href = uri + base64(format(template, ctx));
    const a = document.createElement('a');
    a.download = fileName;
    a.href = uri + base64(format(template, ctx));
    a.click();
    this.notification.showSuccess(fileName + '\n檔案已下載!', true);
  }

}
