
import { CustomResult } from 'src/app/shared/models/custom-result';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';
import { VitalSign } from './vital-sign';
import { Hist } from 'src/app/hist/models/hist';
import { HistActionEnum } from 'src/app/enums/HistActionEnum';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { HCRxCodes } from '../pricing/hcRxCodes';
import { ContactDto } from '../patient/contactDto';
import { RegisterConst } from 'src/app/shared/constants/register-const';

export class WholeHistPatientsDto {
  /** PatientMastId */
  Id: number = 0;
  /** 病歷號 */
  PatientNo: string = '';
  /** 自訂病歷號? */
  Kcstmr: string = '';
  /** 中文名 */
  CName: string = '';
  /** 名 */
  MiddleName: string = '';
  /** 姓 */
  LastName: string = '';
  /** 暱稱 */
  Nickname: string = '';
  /** 身分證 */
  CId: string = '';
  /** 是否檢查身分證 */
  IsCheckCId: boolean = false;
  /** 生日 */
  Birthday: Date = null;
  /** 性別 */
  Sex: number = 0;
  /** 性別名稱 */
  SexName: string = '';
  /** 國籍 */
  Country: string = '';
  /** 食物過敏 */
  FoodAllergy: string = '';
  /** 藥物過敏 */
  DrugAllergy: string = '';
  /** 病史 */
  DiseaseHistory: string = '';
  /** 病史註記 */
  DiseaseHistoryRemark: string = '';
  /** 註記 */
  Remark: string = '';
  /** 電話 */
  Phone: string = '';
  /** 電話區碼 */
  PhoneArea: string = '';
  /** 手機 */
  CellPhone: string = '';
  /** 戶籍地址 郵遞區號 */
  ResZipCode: string = '';
  /** 戶籍地址城市 */
  ResCity: string = '';
  /** 戶籍地址 區域 */
  ResArea: string = '';
  /** 戶籍地址 街道 */
  ResStreet: string = '';
  ZipCode: string = '';
  /// <summary>
  /// 居住地址城市
  /// </summary>
  City: string = '';
  /// <summary>
  /// 居住地址 區域
  /// </summary>
  Area: string = '';
  /// <summary>
  /// 居住地址 街道
  /// </summary>
  Street: string = '';
  /** 醫療給付方案 收案別 */
  PaySysA: string = '';
  // 被依附的父母的身分證號
  MomCId: string = '';
  // 胎數
  BirthsNum?: number;
  MajorInjuryDate: Date = null;
  MajorInjuryTimes?: number;
  SpcIdentity : string ='';
  /** 保險身份 */
  ICode: string = '';
  /** 血型 */
  BlType: number;
  /** RH值 */
  BlRH: number;
  /** 職業 */
  JobCode: string;
  /** 教育程度 */
  EducationCode: string;
  /** 本診所的初診日 */
  FirstDate: Date | string;
  /** 初次來診洗腎日期 */
  FirstDialysisDate?: Date;
  /** 是否顯示姓名更換提示 */
  NeedToCheckCName?: boolean;
}

export class WholeHistRegisterDto {
  /** 掛號檔Id */
  Id: number;
  /** 看診日期 */
  RegDate: Date;
  /** 優待身分 */
  DiscountId?: number;
  /** 候診序號 */
  WaitNo: number;
  /**報到日期時間 */
  CheckinTime?: Date;
  /** 開始日期時間 */
  BeginTime?: Date;
  /** 完診日期時間 */
  EndTime?: Date;
  /** 保險身分 */
  ICode: string;
  /** 保險身分名稱 */
  ICodeName: string;
  /** 收案別 */
  Class: string;
  /** 收案別 */
  ClassName: string;
  /** 科別 */
  MedDeptCode: string;
  /** 就診狀態
   *  A一般、0就診中、1..9保留排號、D保留排後、E預約、F完診、G拿藥、H取消、*急診、C已掛號未付款不可顯示候診*/
  StatusCode: string;
  /**就診狀態名稱 */
  StatusName: string;
  /** 就醫憑證 0.自費 1.IC卡 2.異常掛號 3.欠卡*/
  IType: string
  /**同一療程 0.洗腎 1.復健 2.預保 3.換藥 4.月療 5.全療 6.內科同療 7.同日同醫師看診 8.慢籤領藥 */
  SameTreat: string;
  /** 醫師身分證 */
  DoctorCId: string;
  /** 醫師名稱 */
  DoctorName: string;
  /**健保就醫類別 */
  Nhictp: string
  /**健保卡年度 */
  Nhiyear: string;
  /**就醫序號 */
  Icno: string;
  /**診間名稱, SystemCode:C0120 */
  RoomName: string;
  /**時段 */
  TimeSec: number;
  /** 時段別 名 */
  TimeSecName: string;
  /** 繳款狀態  0已掛號未批價,1已批價未付款,2結帳中,3已付款,4賒欠，5退費 */
  PaymentStatusCode: string;
  /** 付款狀態 名 */
  PaymentStatusName: string;
  /**備註 */
  Remark: string
  /** 就診來源 初診、複診、急診 SystemCode.Type=C0140 */
  VisitSourceCode: string;
  /** 就診來源 名 */
  VisitTypeName: string;
  /** 掛號來源 */
  RegSourceCode: string;
  /** 掛號來源名稱 */
  RegSourceName: string;
  /**欠卡日期 */
  LackCardDate?: Date;
  /**還卡日期 */
  ReCardDate?: Date;

  RoomCode: string;
  INote: string;
  DoctorId: number;
  DiscountCode: string
  OwnFee: number;
  RegisterFee: number;
  OriHosp: string;
  OriDate: Date;
  RefDiagFee: boolean;
  LAMT: number;
  /** 檢驗師 */
  ExaminerCId:string;
  
}
/*
這個class用來對應server端的OpdRecordDTO與PackageDTO(部分時候 for template)，
所以包含了這兩個DTO的資料，
會這樣是因為Template是把OpdRecord.Hist的資料存入PackageMaster & Detail，
為了節省前端copy資料，直接把Hist與相關參數傳到後端，
讓後端轉換為Package
*/
export class WholeHist {

  constructor() {
    this.Action = null;
    this.Patient = new WholeHistPatientsDto();
    //this.PatientId = '';
    this.Register = new WholeHistRegisterDto();
    //this.RegisterId = 0;
    this.Hist = new Hist();
    this.VitalSign = new VitalSign();
    this.Contacts = new Array<ContactDto>();
    this.TemplateCode = '';
    this.TemplateName = '';
    this.IsTemplateForcedSave = false;
    this.IsTemplateShared = false;
    this.HasUploaded = false;
  }
  // #region properties --------------------

  // action
  Action: HistActionEnum;

  // patient
  //PatientId: string;
  Patient: WholeHistPatientsDto;

  // reg
  //RegisterId: number;
  Register: WholeHistRegisterDto;

  Hist: Hist;

  // vital sign
  VitalSign: VitalSign;

  Contacts: ContactDto[];

  HistParams: HST001Params;
  //IsShowPricingWindow: boolean;
  //IsAlertPaid: boolean;

  // template - for PackageDTO
  MedDeptCode: string; // Package需要此欄位，但Hist沒有，須從Register取得
  TemplateCode: string;
  TemplateName: string;
  IsTemplateForcedSave: boolean;
  IsTemplateShared: boolean;
  HasUploaded: boolean;
  HomecareRxCodeList: HCRxCodes[];
  IsChangeOwnBySelf :boolean = false;
  IsInitUrgentTrue : boolean = false;
  /** 是否有管制用藥 */
  IsUseCTRDrugs: boolean;
  // #endregion properties --------------------
  VSDisplay: string;
  RatingPriceOriginPresNo : string = '';
  // #region create --------------------
  static create(data: WholeHist): WholeHist {
    let rcd = new WholeHist();
    // OpdRecord
    rcd = Object.assign(new WholeHist(), data);
    // Patient
    if (data.Patient) {
      rcd.Patient = Object.assign(new WholeHistPatientsDto(), data.Patient);
    }
    // Register
    if (data.Register) {
      rcd.Register = Object.assign(new RegisterEditDto(), data.Register);
    }
    // Hist & details
    if (data.Hist) {
      // Hist
      rcd.Hist = Object.assign(new Hist(), data.Hist);
      // Orders
      if (data.Hist.HRxs) {
        rcd.Hist.HRxs = [];
        data.Hist.HRxs.forEach(elem => {
          let odr = new HistOrder();
          odr = Object.assign(odr, elem);
          rcd.Hist.HRxs.push(odr);
        });
      }
      // diags
      rcd.Hist.createDiags();
    }
    // // vital sign
    if (data.VitalSign) {
      rcd.VitalSign = Object.assign(new VitalSign(), data.VitalSign);
      //rcd.VitalSign.VitalSignDisplay = rcd.HistParams.VitalSignDisplay;
      if (rcd.Patient.Sex === 0) {
        rcd.VitalSign.isFemale = true;
      } else {
        rcd.VitalSign.isFemale = false;
      }
      rcd.VitalSign.PatientId = data.Patient.Id;
    }
    if (data.Contacts) {
      rcd.Contacts = Object.assign(new Array<ContactDto>(), data.Contacts);
    }
    return rcd;
  }
  // #endregion create from data --------------------

  // #region validate --------------------
  validate(): CustomResult[] {
    return this.Hist.validate();
  }
  // #endregion validate --------------------

  test() {
    console.log('test');
  }
  為代檢(){
    return this.Register.ICode== RegisterConst.ICode_009_Other && this.Register.INote == RegisterConst.INote_代檢
  }
}
