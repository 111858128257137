import { Injectable } from '@angular/core';
import { WebApiClient } from '../../web-api-client.service';
import { WebApiService } from '../../web-api.service';
import { RegisterChangeDoctorDTO, RegisterClinicHistoryDTO, RegisterEditOptionDTO, RegisterEditPageDTO, RegisterEditPageOptionsDTO, RegisterPeriodItemDTO, RegisterWaitingDTO, RegisterWaitingQueryDTO } from './register-api.model';
import { ICNoPHOption } from '../../api-service/register/register-api';

@Injectable({
  providedIn: 'root'
})
export class RegisterApiService {
  apiService: WebApiService;

  constructor(apiClient: WebApiClient) {
    this.apiService = apiClient.createDentistService('Register');
  }

  public async GetRegister(regId: number): Promise<RegisterEditPageDTO> {
    return await this.apiService.get('GetRegister', { regId: regId }).toPromise<RegisterEditPageDTO>();
  }

  public async GetRegisterWaitingList(param: RegisterWaitingQueryDTO): Promise<RegisterWaitingDTO> {
    return await this.apiService.get('GetRegisterWaitingList', param).toPromise<RegisterWaitingDTO>();
  }

  public async GetRegisterPeriodItems(patientId: number): Promise<RegisterPeriodItemDTO[]> {
    return await this.apiService.get('GetRegisterPeriodItems', { patientId: patientId }).toPromise<RegisterPeriodItemDTO[]>();
  }

  public async GetRegisterClinicHistories(patientId: number): Promise<RegisterClinicHistoryDTO[]> {
    return await this.apiService.get('GetRegisterClinicHistories', {patientId: patientId }).toPromise<RegisterClinicHistoryDTO[]>();
  }

  public async GetInitialDataForAdd(patientId: number): Promise<RegisterEditPageDTO> {
    return await this.apiService.get('GetInitialDataForAdd', {
      patientId: patientId
    }).toPromise<RegisterEditPageDTO>();

  }

  public async GetInitialDataForReserveInput(regId: number, iType: string): Promise<RegisterEditPageDTO> {
    return await this.apiService.get('GetInitialDataForReserveInput', {
      regId: regId,
      iType: iType
    }).toPromise<RegisterEditPageDTO>();

  }

  public async ChangeDoctor(param: RegisterChangeDoctorDTO): Promise<number> {
    return await this.apiService.post("ChangeDoctor", param).toPromise<number>();
  }

  public async SendNotificationToPatient(regId: number): Promise<number> {
    return await this.apiService.post(`SendNotificationToPatient/${regId}`, null).toPromise<number>();
  }

  public async GetRegisterEditOption(): Promise<RegisterEditOptionDTO> {
    return await this.apiService.get('GetRegisterEditOption').toPromise<RegisterEditOptionDTO>();
  }

  public async Create(param: RegisterEditPageDTO): Promise<number> {
    return await this.apiService.post('Create', param).toPromise<number>();
  }

  public async InputFromReserve(param: RegisterEditPageDTO): Promise<number> {
    return await this.apiService.post('InputFromReserve', param).toPromise<number>();
  }

  public async GetProphylacticHealth(patientId: number, regDate: string): Promise<ICNoPHOption[]> {
    return await this.apiService.get('GetProphylacticHealth', { patientId: patientId, regDate: regDate }).toPromise<ICNoPHOption[]>();
  }

  public async CancelPatientNotify(regId: number): Promise<number> {
    return await this.apiService.post(`CancelPatientNotify/${regId}`, null).toPromise<number>();
  }

}
