import { Inject, Injectable, Optional } from '@angular/core';
import {MatMomentDateAdapterOptions, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MomentFactory } from './moment-factory';
import { BaseConfig } from 'src/app/services/base-config.service';

@Injectable()
export class VisMomentDateAdapter extends MomentDateAdapter {
  isRocDate = BaseConfig.getConfig().ui.rocDate;
  moment = MomentFactory(_moment);

  constructor( @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS) private options?: MatMomentDateAdapterOptions
  ) {
    super(dateLocale, options);
    this.setLocale(dateLocale || this.moment.locale());
    //this.setLocale(dateLocale || _moment.locale());

  }

  getYearName(date: Moment): string {
    if(this.isRocDate){
      var twYear = date.year()-1911;

      return twYear>0?'民 '+ twYear: '民前 '+ (twYear*-1);// this.moment(this.clone(date)).format('tYY');
    }else{
      return date.year().toString();
    }
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return ['日','一','二','三','四','五','六']
  }
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[]{
    return ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月']
  }
  getDateNames(): string[]{
    return Array(31).fill(0).map((x,i)=>(i+1).toString());
  }
  parse(value: any, parseFormat: string | string[]): Moment | null {
    if(this.isRocDate){
      if (value && typeof value === 'string') {
        return this.createMoment(value, parseFormat, this.locale);
      }
      return value ? this.createMoment(value).locale(this.locale) : null;
    }
    return super.parse(value,parseFormat);
  }
  createDate(year: number, month: number, date: number): Moment{
    return super.createDate(year,month,date);// this.createMoment(new Date(year,month,date));
  }
  format(date: Moment, displayFormat: string): string {
    if(this.isRocDate){
      var twYear = 0;
      var twMonth = 0;
      if(date instanceof Date){
        twYear = date.getFullYear()-1911
        twMonth = date.getMonth()+1;
      }else{
        twYear = date.year()-1911;
        twMonth = date.month()+1;
      }
      return (twYear>0?'民國 '+ twYear: '民國前'+(twYear*-1)) + ' 年 ' + twMonth+' 月 ';
    }
    return super.format(date,displayFormat);
  }
  numberToTwUnit(num:number){
    var unit = ['','拾','佰','仟','萬','拾','佰','仟','億','拾','佰','仟','兆']
    var text = '零壹貳叁肆伍陸柒捌玖';
    var result = '';
    var c = num;
    var pos = 0;
    while(c>0){
      var v = c%10;
      result = text.charAt(v)+unit[pos] + result;
      c = (c-v)/10;
      pos++;
    }
    return result;

  }
  clone(date: Moment): Moment{
    if(date instanceof Date){
      return this.createMoment(date)
    }
    return super.clone(date);
  }

  isValid(date: Moment): boolean{
    if(date instanceof Date){
      return true
    }
    return super.isValid(date);
  }
  private createMoment(
    date: _moment.MomentInput,
    format?: _moment.MomentFormatSpecification,
    locale?: string
  ): Moment {
    const { strict, useUtc }: MatMomentDateAdapterOptions = this.options || {};
    return useUtc
      ? this.moment.utc(date, format, locale, strict)
      : this.moment(date, format, locale, strict);
  }
}
