import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserCache } from 'src/app/services/user-cache';
import { WholeHist } from "src/app/services/api-service/hist/whole-hist";
// import { ClinicApi } from 'src/app/services/api-service/clinic/clinic-api';
import { LoginUser } from "src/app/permission/models/login-user";
import { ContactDto } from 'src/app/services/api-service/patient/contactDto';
import { PatientApi } from 'src/app/services/api-service/patient/patient-api';
import { PatientsDto } from "src/app/services/api-service/patient/patientsDto";
import { Referral } from "src/app/services/api-service/referral/referral";

@Component({
    selector: 'app-referrals-referral-form-dialog-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

    @Input() queryPatientNo = '';
    @Input() histRcd: WholeHist;
    @Input() referralFormGroup: FormGroup;
    @Input() oriRef: Referral;

    constructor(private patientApi: PatientApi) { }

    private loginUser: LoginUser;

    async ngOnInit() {
        this.getMainContact(this.histRcd.Patient.Id);
    }

    async getMainContact(Id: number) {
        if (this.oriRef) {
            this.referralFormGroup.controls.ContactName.setValue(this.oriRef.ContactName);
        } else {
            var data: PatientsDto = await this.patientApi.Get(Id);
            var mainContact: ContactDto = data.Contacts.filter(x => x.MainPerson)[0] ?? new ContactDto(data.Id);
            this.referralFormGroup.controls.ContactName.setValue(mainContact.Name);
        }
        this.fillForm();
    }

    fillForm() {
        this.loginUser = UserCache.getLoginUser();
        this.referralFormGroup.controls.DoctorName.setValue(this.histRcd.Register.DoctorName);
        this.referralFormGroup.controls.DoctorCid.setValue(this.histRcd.Register.DoctorCId);
        this.referralFormGroup.controls.DoctorPhone.setValue(this.loginUser.Clinic.Phone);
        this.referralFormGroup.controls.ClinicEmail.setValue(this.loginUser.Clinic.Email);
        this.referralFormGroup.controls.PatientNo.setValue(this.histRcd.Patient.PatientNo);
        this.referralFormGroup.controls.PatientName.setValue(this.histRcd.Patient.CName);
        this.referralFormGroup.controls.DoctorName.disable();
        this.referralFormGroup.controls.DoctorCid.disable();
        // this.referralFormGroup.controls.DoctorPhone.disable();
        this.referralFormGroup.controls.ClinicEmail.disable();
        this.referralFormGroup.controls.PatientNo.disable();
        this.referralFormGroup.controls.PatientName.disable();
        this.referralFormGroup.controls.PatientSex.setValue(this.histRcd.Patient.Sex);
        // 去除時間
        // this.referralFormGroup.controls.ReferralDate.setValue(this.histRcd.Register.RegDate);
        let regDate = new Date(this.histRcd.Register.RegDate);
        regDate = new Date(regDate.getFullYear(), regDate.getMonth(), regDate.getDate());
        this.referralFormGroup.controls.ReferralDate.setValue(regDate);
        this.referralFormGroup.controls.PatientId.setValue(this.histRcd.Patient.CId);
        this.referralFormGroup.controls.Birthday.setValue(this.histRcd.Patient.Birthday);
        this.referralFormGroup.controls.PhoneNo.setValue(!this.histRcd.Patient.CellPhone ? this.histRcd.Patient.Phone : this.histRcd.Patient.CellPhone);
        // this.referralFormGroup.controls.ExpiryDate.setValue(this.addDays(new Date(this.histRcd.Register.RegDate), 90));
        this.referralFormGroup.controls.ExpiryDate.setValue(this.addDays(new Date(regDate), 89)); // 經實測 +90會被擋
        const address = this.histRcd.Patient.ResZipCode + this.histRcd.Patient.ResCity + this.histRcd.Patient.ResStreet;
        this.referralFormGroup.controls.Address.setValue(address);
        const alleryHist = this.histRcd.Patient.DrugAllergy + " " + this.histRcd.Patient.FoodAllergy;
        this.referralFormGroup.controls.AllergyHistory.setValue(alleryHist);
        if (this.oriRef) {
            // this.referralFormGroup.controls.ReferralDate.setValue(this.oriRef.RegDate);
            let regDate = new Date(this.oriRef.RegDate);
            regDate = new Date(regDate.getFullYear(), regDate.getMonth(), regDate.getDate());
            this.referralFormGroup.controls.ReferralDate.setValue(regDate);
            // this.referralFormGroup.controls.ExpiryDate.setValue(this.addDays(new Date(this.histRcd.Register.RegDate), 90));
            this.referralFormGroup.controls.ExpiryDate.setValue(this.addDays(new Date(regDate), 89));// 經實測 +90會被擋
            this.referralFormGroup.controls.AllergyHistory.setValue(this.oriRef.AllergyHistory);
            this.referralFormGroup.controls.PhoneNo.setValue(this.oriRef.keepPhoneNo ?? '');
        }
    }

    onReferralDateChange(event: Date) {
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + Math.ceil(days));
        return date;
    }
}
