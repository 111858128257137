import { PaymentDetail } from './payment-detail';
import { PaymentStatusEnum } from './payment-status-enum';

export class PaymentMaster {

  Id: string;
  RoomCode: string;
  RoomName: string;
  SerialNumber: string;
  CreateDate: Date;
  NhiPay: number;
  OwnPay: number;
  PreDiscountTotal: number;
  DiscountId: string;
  DiscountRate: number;
  DiscountAmount: string;
  DiscountTotal: number;
  PreTaxTotal: number;
  TaxRate: number;
  TaxTotal: number;
  MantissaRebate: number;

  Payable: number;
  Paid: number;
  PaidCreditCard: number;
  PaidCashCard: number;
  PaidMedCard: number;
  PaidOther: number;
  PaidOtherNote: number;
  PaidCash: number;
  CashTake: number;
  CashChange: number;
  Cashier: string;
  CashierName: string;
  PayDate: Date;
  Remark: string;
  Status: number;

  Details: PaymentDetail[];

  // 非Domain Model資料
  PatientId: number;
  PatientName: string;

  static createFromData(data: PaymentMaster): PaymentMaster {
    let pay = new PaymentMaster();
    pay = Object.assign(pay, data);
    pay.Details = data.Details;
    return pay;
  }
  get IsNotPaid(): boolean {
    if (this.Status === PaymentStatusEnum.NotPaid) {
      return true;
    } else {
      return false;
    }
  }
  get IsPaid(): boolean {
    if (this.Status === PaymentStatusEnum.Paid) {
      return true;
    } else {
      return false || this.IsRefund;
    }
  }
  get IsRefund(): boolean {
    return this.Status === PaymentStatusEnum.Refund;
  }
}
