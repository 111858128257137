

/**
 * 重大傷病註記
 */ 
export class CriticalIllnesNoHPC {
    /**
     * 有效期限起始日
     */ 
    public StartDate: Date = new Date(0);
    /**
     * 有效期限終止日
     */ 
    public EndDate: Date = new Date(0);
}
