import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HistApi, clinicRx } from 'src/app/services/api-service/hist/hist-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { PageData } from 'src/app/shared/models/page-data';
import { HistDiag } from '../models/hist-diag';
import { DxApi, HistViewDxQueryDto } from 'src/app/services/api-service/dx/dx-api';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { HistService } from '../services/hist.service';
import { async } from '@angular/core/testing';
import { isNullOrUndefined } from 'src/app/shared/utilities';

@Component({
  selector: 'app-hist-search',
  templateUrl: './hist-search.component.html',
  styleUrls: ['./hist-search.component.css']
})
export class HistSearchComponent implements OnInit {
  @Input() isDisabled?: boolean = false;
  @Output() searchedEmit = new EventEmitter<HistDiag[]>();

  constructor(private fb: FormBuilder,
    private notification: EasyNotificationService,
    private mainLayoutService: MainLayoutService,
    private histapi: HistApi,
    private histService:HistService,
    private dxApi : DxApi,
    private cdr: ChangeDetectorRef

    ) {
    }
  @ViewChild('firstTable', { static: true }) firstTableElementRef: ElementRef;
  editFormGroup: FormGroup;
  /** 表單值 */
  get editFormValue() {
    return this.editFormGroup.getRawValue();
  }
  // @Input()
  // get sdxlist(){
  //   return this.pageData;
  // }
  // set sdxlist(val:PageData){
  //   this.pageData = val
  //   if (!val)  return;
  //   this.setSdxGridView(0,300,null)
  //   this.currentPage = 1;

  // }

  // @Input()
  // get dxList(){
  //   return this.dxPageData;
  // }
  // set dxList(val:PageData){
  //   console.log(val);
  //   this.dxPageData = val
  //   if (!val)  return;
  //   this.setDxGridView(0,300,null)
  //   this.currentPage = 1;


  // }
  @Input()
  get histdiags(){
    return this.saveDxArray;
  }
  set histdiags(val:HistDiag[]){
    if (val.length < 1)  return;
    val.length >= 4 ? 4 :val.length;
    for (var i = 0; i < val.length; i++)
    {
      this.dxArray[i] = val[i].code || '';
      this.dxIsSt[i] = false;
      this.saveDxArray[i] = val[i]
      this.diagWord += val[i].code?.length > 0 ?(val[i].code + '｜' + val[i].name) +'，':''
    }

  }

  @Input() diagVersion: string;

  scrollData(val :number){
        let firstcount = (val -1)*300;
        let lastcount = val*300;
        this.publicClinicData = {
          data: this.pageData.data.slice(firstcount, lastcount),
          total: 300
        };

  }
  // 滾動時 會切換data 實驗用
  //(wheel)="onWheel($event)"
  // @HostListener('wheel', ['$event'])
  // onWheel(event: WheelEvent): void {
  //   // 阻止默认的滚轮事件，以避免影响整个页面的滚动
  //   event.preventDefault();

  //   // 判断滚轮方向，可以根据实际需要调整
  //   const delta = Math.sign(event.deltaY);

  //   if (delta > 0) {
  //     // 向下滚动，执行跳转逻辑
  //     this.currentPage++;
  //     this.scrollData(this.currentPage);
  //   } else if (delta < 0) {
  //     // 向上滚动，执行跳转逻辑
  //     if (this.currentPage > 1) {
  //       this.currentPage--;
  //       this.scrollData(this.currentPage);
  //     }
  //   }
  // }
  //#region  checkBox UI 值
  // type = 1 And , type = 2 Or
  disePartName1:any[] = [
    {type:1,name:'急性'},
    {type:1,name:'慢性'},
    {type:1,name:'初期'},
    {type:1,name:'後續'},
    {type:1,name:'後遺'},
    {type:1,name:'侵襲'},
    {type:1,name:'復發'},
    {type:1,name:'未明'},
    {type:1,name:'其他'}
  ];

  disePartName2:any[] = [
    {type:1,name:'男'},
    {type:1,name:'女'},
    {type:1,name:'新生'},
    {type:1,name:'老年'},
    {type:1,name:'妊娠'}
  ];

  disePartName3:any[] = [
    {type:2,name:'左'},
    {type:2,name:'右'},
    {type:2,name:'雙側'},
    {type:2,name:'上'},
    {type:2,name:'下'},
    {type:2,name:'前'},
    {type:2,name:'後'}
  ];

  disePartName4:any[] = [
    {type:1,name:'擦傷'},
    {type:1,name:'挫傷'},
    {type:1,name:'拉傷'},
    {type:1,name:'撕裂'},
    {type:1,name:'扭傷'},
    {type:1,name:'損傷'},
    {type:1,name:'脫臼'},
    {type:1,name:'骨折'},
    {type:1,name:'燒傷'}
  ];

  disePartName5:any[] = [
    {type:1,name:'頭'},
    {type:1,name:'腦'},
    {type:1,name:'臉'},
    {type:1,name:'眼'},
    {type:1,name:'耳'},
    {type:1,name:'鼻'},
    {type:1,name:'舌'},
    {type:1,name:'口'},
    {type:1,name:'牙'},
    {type:1,name:'唇'},
    {type:1,name:'顎'},
    {type:1,name:'肩'},
    {type:1,name:'臂'},
    {type:1,name:'腕'},
    {type:1,name:'指'},
    {type:1,name:'腿'},
    {type:1,name:'膝'},
    {type:1,name:'踝'},
    {type:1,name:'足'},
    {type:1,name:'腳'},
    {type:1,name:'趾'},
    {type:1,name:'心'},
    {type:1,name:'肝'},
    {type:1,name:'脾'},
    {type:1,name:'肺'},
    {type:1,name:'腎'},
    {type:1,name:'膽'},
    {type:1,name:'胰'},
    {type:1,name:'胃'},
    {type:1,name:'腸'},
    {type:1,name:'腎'},
    {type:1,name:'皮膚'},
    {type:1,name:'炎'},
    {type:1,name:'膿'}
  ];
  //#endregion
  //#region 變數區
  selfClinicData:any
  currentPage = 0;
  dx1:string = "";
  dx2:string = "";
  dx3:string = "";
  dx4:string = "";
  pageData: PageData = {'data':[],'total':0,'totalPages':0};
  dxPageData : PageData = {'data':[],'total':0,'totalPages':0};
  publicClinicData: any;
  inputsData = [];
  dxArray = ['','','',''];
  dxIsSt :boolean[] = [null,null,null,null];
  selfClinicName: string ='';
  selfClinicEName: string ='';
  publicClinicName: string ='';
  publicClinicEName: string ='';
  condititons:string[] = [];
  orCondititons:string[] = [];
  wordCondititons:string[] = [];
  bodyHeight = document.body.clientHeight;
  maxSelfClinicOpen: boolean = false;
  maxPublicClinicOpen: boolean = false;
  saveDxArray:HistDiag[]= [new HistDiag(),new HistDiag(),new HistDiag(),new HistDiag()];
  saveSelfDxArray:string[] = [];
  diagWord:string ='';
  sdxPageSkipNo:number = 0;
  sdxPageSize: number = 10;
  dxPageSkipNo:number = 0;
  dxPageSize: number = 10;
  selectedPatientId: number;
  selectedItems: number[] = [];
  sdxselectedItems: number[] = [];
  sdxQueryFormGroup: FormGroup;
  dxQueryFormGroup: FormGroup;
  sdxLoading:boolean = false;
  dxLoading:boolean = false;
  isEngLish:boolean = false;
  isLong:boolean = false;
  dxMinCount = this.histService.TotalDiagnosis || 2;
  //#endregion
  ngOnChanges(changes: SimpleChanges): void {
    this.bodyHeight = document.body.clientHeight;
    this.getSelfClinicHeight();
    this.getPublicClinicHeight();
    this.uploadPublicClinicDataCheckChanged();
    this.refreshSDX();
    this.refreshDX();

  }

  ngOnInit(): void {


    this.bodyHeight = document.body.clientHeight;
    this.getSelfClinicHeight();
    this.getPublicClinicHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.bodyHeight = document.body.clientHeight;
    this.getSelfClinicHeight();
    this.getPublicClinicHeight();
  }

  getSelfClinicToggle(open: boolean) {
    open = this.maxSelfClinicOpen;
    this.maxSelfClinicOpen = !this.maxSelfClinicOpen;
  }
  getPublicClinicToggle(open: boolean) {
    open = this.maxPublicClinicOpen;
    this.maxPublicClinicOpen = !this.maxPublicClinicOpen;
  }

  getSelfClinicHeight(): string {
    let useBodyHeight: number;
    useBodyHeight = this.bodyHeight - 388;
    return useBodyHeight + 'px';
  }

  getPublicClinicHeight(): string {
    let useBodyHeight: number;
    useBodyHeight = this.bodyHeight - 388;
    return useBodyHeight + 'px';
  }

  getSliceHelfHeight(): string {
    let useBodyHeight: number;
    useBodyHeight = this.bodyHeight - 420;
    return useBodyHeight/2 + 'px';
  }

  pickedSelfClinic(data:any) {
    this.selfClinicName = data?.CName || '';
    this.selfClinicEName = data?.EName|| '';
    this.picked(data);
  }
  pickedPublicClinic(data:any){
    this.publicClinicName = data?.CName || '';
    this.publicClinicEName = data?.EName || '';
    this.picked(data,true);
    this.pushAdd(data);
  }
  picked(data:any,isfromSdx= false) {
    let code = data.Code;
    let name = data.CName;
    if (this.dxArray.some(x => x == code))
    {
      this.notification.showWarning(data.CName+'已經輸入!');
    }
    else if(this.dxArray.every(x => x !== 'undefined' && x !== '' && x !== null))
    {
      this.notification.showWarning('診斷全滿');
    }
    else
    {
      for (var i = 0; i < 4; i++) {
        if (this.dxArray[i] == ''){
          this.dxArray[i] = code;
          if (isfromSdx) this.dxIsSt[i] = true;
          else this.dxIsSt[i] = false;
          // console.log('this.saveDxArray', this.saveDxArray);
          this.saveDxArray[i].no = i+1;
          this.saveDxArray[i].code = code;
          this.saveDxArray[i].name = name;
          this.saveDxArray[i].acuteNote = data.AcuteNote;
          this.diagWord += code.length > 0 ?(code + '｜' + name) +'，':''
          return
        }

      }
    }
  }
  /** 本院醫令push */
  pushAdd(data:any) {
    if (!this.selfClinicData.data.some(x => x.Code == data.Code))
    {
      let saveDx = data.Code
      this.saveSelfDxArray.push(saveDx);

    }
  }
  checkChanged(evt:{checked:boolean,data:clinicRx}) {
    if (evt.checked == true) {
      this.inputsData.push(evt.data.Code);
    } else if (evt.checked == false){
      this.inputsData = this.inputsData.filter(code => code !== evt.data.Code);
    }
  }
  uploadSelfClinicDataCheckChanged(evt?:{checked:boolean,data:clinicRx}) {
    if (evt.checked == true) {
      this.selfClinicData = this.selfClinicData.filter(item => item.Code !== evt.data.Code);
    }
  }
  uploadPublicClinicDataCheckChanged(evt?:{checked:boolean,data:clinicRx}) {

  }

  showLoader() {
    this.mainLayoutService.showLoader();
  }
  closeLoader() {
    this.mainLayoutService.hideLoader();
  }

  onlongillName(event:MatCheckboxChange){
    this.isLong = event.checked;
  }
  normarlsearch(){
    this.sdxPageChange(1);
    this.dxPageChange(1);
  }
  engChange(event:MatCheckboxChange){
    this.isEngLish = event.checked;
    this.sdxPageChange(1);
    this.dxPageChange(1);
  }
  searchcheck(evt: MatCheckboxChange,word:string,type :number){
    // if (this.dxLoading || this.sdxLoading){
    //   this.searchcheck(evt,word,type);
    //   return;
    // }
    if (evt.checked) {
      if (!this.condititons.some(x => x == word) && type == 1) {
        this.condititons.push(word);
      }
      if (!this.orCondititons.some(x => x == word) && type == 2) {
        this.orCondititons.push(word);
      }
    } else {
      if (this.condititons.some(x => x == word) && type == 1) {
        this.condititons = this.condititons.filter(x => x !== word)
      }
      if (this.orCondititons.some(x => x == word) && type == 2) {
        this.orCondititons = this.orCondititons.filter(x => x !== word)
      }
    }
    this.sdxPageChange(1);
    this.dxPageChange(1);

    }
  keyin(evt:Event){
    let keyinvalie = (evt.target as HTMLInputElement).value;
    this.wordCondititons = keyinvalie.split(/[, ]+/).map(item => item.trim())
    // this.refreshSDX();
    // this.refreshDX();
  }

  async inputChange(evt:Event,position:number){
    let keyinvalie = (evt.target as HTMLInputElement).value;
    keyinvalie = keyinvalie.replace(/[^a-zA-Z0-9]/g, '');
    keyinvalie = keyinvalie.toUpperCase();
    if (keyinvalie.length > 0){
      this.diagWord = '';
      let keyData = await this.dxApi.GetHistViewDxFromCode(keyinvalie, this.diagVersion);
      if (!keyData){
        // 這個處理是for 畫面未更新;
        if (isNullOrUndefined(this.dxArray[position]) && keyinvalie?.length > 0){
          this.dxArray[position] = keyinvalie;
          this.cdr.detectChanges();
        }
        this.notification.showError('無此診斷!');
        this.cleanCodebyPosition(position);
      }
      else
      {
        var keyinCode =  keyData.Code;
        var isRepeat = this.dxArray.some(x => x == keyinCode);

        if (isRepeat){
          this.notification.showWarning(keyData.CName+'已經輸入!');
           // 這個處理是for 畫面未更新:
          if (isNullOrUndefined(this.dxArray[position]) && keyinvalie?.length > 0){
            this.dxArray[position] = keyinvalie;
            this.cdr.detectChanges();
          }
            this.cleanCodebyPosition(position);
        }
        else
        {
            if (keyData.IsSdx) this.dxIsSt[position]= true;
            else this.dxIsSt[position]= false;
            this.dxArray[position] = keyData.Code;
            this.saveDxArray[position].no = position+1;
            this.saveDxArray[position].code = keyData.Code;
            this.saveDxArray[position].name = keyData.CName;
            this.saveDxArray[position].acuteNote = keyData.AcuteNote;
        }
      }

    }
    else
    {
      this.diagWord = '';
       this.cleanCodebyPosition(position);

      // keyinvalie = '';
    }
    for (var i = 0; i < 4; i++) {
      if (this.saveDxArray[i]?.code != ''){
        var dx = this.saveDxArray[i];
        this.diagWord += dx.code?.length > 0 ?(dx.code + '｜' + dx.name) +'，':''
      }
    };
  }
  cleanCodebyPosition(position:number){
    this.dxArray[position]= null;
    this.saveDxArray[position].no = position+1;
    this.saveDxArray[position].code = '';
    this.saveDxArray[position].name = '';
    this.saveDxArray[position].acuteNote = null;
    this.dxIsSt[position]=null;
  }
  sdxPageChange(page: number): void {
    this.sdxPageSkipNo = (page - 1) * this.sdxPageSize;
    this.refreshSDX(); // 更新清單，因為server端資料可能異動
  }
  dxPageChange(page: number): void {
    this.dxPageSkipNo = (page - 1) * this.dxPageSize;
    this.refreshDX(); // 更新清單，因為server端資料可能異動
  }
  public gridCellClickHandler({ isEdited, dataItem, rowIndex },isSelf:boolean = false): void {
    // for 新增後的row 取消 highlight(黃底);
    if (isSelf){
      this.selfClinicName = dataItem.CName;
      this.selfClinicEName = dataItem.EName;
      return;
    }
    this.publicClinicName = dataItem.CName;
    this.publicClinicEName = dataItem.EName;

  }
  createsdxQueryFormGroup(): FormGroup {
    return this.fb.group({
      // codeCategory: ['', Validators.required],
      code: [''],
      name: [''],
      type: ['sdxHistView'],
      pageNumber:[1],
      orCondititons:[this.orCondititons],
      condititons : [this.condititons],
      wordCondititons:[this.wordCondititons],
      isEnglish :this.isEngLish,
    });
  }
  async refreshSDX(){
    this.sdxLoading = true;
    this.sdxQueryFormGroup = this.createsdxQueryFormGroup();
    var sdxQuery = Object.assign(new HistViewDxQueryDto(),this.sdxQueryFormGroup.value);
    sdxQuery.type = 'sdxHistView'
    sdxQuery.pageSize = this.sdxPageSize;
    sdxQuery.pageNumber = this.sdxPageSkipNo > 0 ?(this.sdxPageSkipNo/this.sdxPageSize)+1 :1;
    sdxQuery.diagVersion = this.diagVersion;


    this.pageData = await this.dxApi.GetHistViewDxCodes(sdxQuery);
    // this.leftSelections = [];
    this.setSdxGridView();
    this.sdxLoading = false;
  }
  async refreshDX(){
    this.dxLoading = true;
    this.dxQueryFormGroup = this.createsdxQueryFormGroup();
    var dxQuery = Object.assign(new HistViewDxQueryDto(),this.dxQueryFormGroup.value);
    dxQuery.type = 'dxHistView'
    dxQuery.pageSize = this.dxPageSize;
    dxQuery.pageNumber = this.dxPageSkipNo > 0 ?(this.dxPageSkipNo/this.dxPageSize)+1 :1;
    dxQuery.diagVersion = this.diagVersion;


    this.dxPageData = await this.dxApi.GetHistViewDxCodes(dxQuery);
    // this.leftSelections = [];
    this.setDxGridView();
    this.dxLoading = false;
  }

    setSdxGridView() {
    this.publicClinicData = {
      data: this.pageData.data,
      total: this.pageData.total
    };
        if(this.publicClinicData) {
      this.publicClinicName = this.publicClinicData.data[0]?.CName ?? '';
      this.publicClinicEName = this.publicClinicData.data[0]?.EName?? '';
    }
  }
  setDxGridView() {
    this.selfClinicData = {
      data: this.dxPageData.data,
      total: this.dxPageData.total
    };
        if(this.selfClinicData) {
      this.selfClinicName = this.selfClinicData.data[0]?.CName ?? '';
      this.selfClinicEName = this.selfClinicData.data[0]?.EName?? '';
    }
  }

  // 儲存
  onSaveClick() {
    if (this.saveSelfDxArray.length > 0)this.histapi.savedxFromSdx(this.saveSelfDxArray);
    this.searchedEmit.emit(this.saveDxArray);
  }
  onCancelClick() {
    this.searchedEmit.emit(null);
  }
  //#region 舊方法  先保留
  // setSdxGridView(start:number, end:number,inputdata :any[]) {
  //   let data = this.pageData.data;
  //   if (inputdata) data = inputdata;
  //   this.publicClinicData = {
  //     data: data.slice(start, end),
  //     total: end - start
  //   };
  //       if(this.publicClinicData) {
  //     this.publicClinicName = this.publicClinicData.data[0].CName;
  //     this.publicClinicEName = this.publicClinicData.data[0].EName;
  //   }
  // }
  // setDxGridView(start:number, end:number,inputdata :any[]) {
  //   let data = this.dxPageData.data;
  //   if (inputdata) data = inputdata;
  //   this.selfClinicData = {
  //     data: data.slice(start, end),
  //     total: end - start
  //   };
  //       if(this.selfClinicData.length > 0 ) {
  //     this.selfClinicName = this.selfClinicData.data[0].Name;
  //     this.selfClinicEName = this.selfClinicData.data[0].EName;
  //   }
  // }
  //
  // refreshSdxList(){
  //   if(this.condititons.length == 0 && this.orCondititons.length == 0 && this.wordCondititons.length == 0){
  //     // this.setSdxGridView(0,300,null)
  //   }
  //   else if(this.condititons.length != 0 && this.orCondititons.length == 0 && this.wordCondititons.length == 0)
  //   {
  //     let data =  this.pageData.data.filter(x => this.condititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     // this.setSdxGridView(0,total,data)

  //   }
  //   else if(this.condititons.length == 0 && this.orCondititons.length != 0 && this.wordCondititons.length == 0)
  //   {
  //     let data =  this.pageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     // this.setSdxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length == 0 && this.orCondititons.length == 0 && this.wordCondititons.length != 0)
  //   {
  //     let data =  this.pageData.data.filter(x => this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     // this.setSdxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length != 0 && this.orCondititons.length != 0 && this.wordCondititons.length == 0)
  //   {
  //     let data =  this.pageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     data =  data.filter(x => this.condititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     // this.setSdxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length != 0 && this.orCondititons.length == 0 && this.wordCondititons.length != 0)
  //   {
  //     let data = this.pageData.data.filter(x => this.condititons.every(substring => x.CName.includes(substring)) || this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     // this.setSdxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length == 0 && this.orCondititons.length != 0 && this.wordCondititons.length != 0)
  //   {
  //     let data =  this.pageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     data =  data.filter(x => this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     // this.setSdxGridView(0,total,data)
  //   }
  //   else
  //   {
  //     let data =  this.pageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     data =  data.filter(x => this.condititons.every(substring => x.CName.includes(substring)) || this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     // this.setSdxGridView(0,total,data)
  //   }
  // }
  // refreshDxList(){
  //   if(this.condititons.length == 0 && this.orCondititons.length == 0 && this.wordCondititons.length == 0){
  //     this.setDxGridView(0,300,null)
  //   }
  //   else if(this.condititons.length != 0 && this.orCondititons.length == 0 && this.wordCondititons.length == 0)
  //   {
  //     let data =  this.dxPageData.data.filter(x => this.condititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     this.setDxGridView(0,total,data)

  //   }
  //   else if(this.condititons.length == 0 && this.orCondititons.length != 0 && this.wordCondititons.length == 0)
  //   {
  //     let data =  this.dxPageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     this.setDxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length == 0 && this.orCondititons.length == 0 && this.wordCondititons.length != 0)
  //   {
  //     let data =  this.dxPageData.data.filter(x => this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     this.setDxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length != 0 && this.orCondititons.length != 0 && this.wordCondititons.length == 0)
  //   {
  //     let data =  this.dxPageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     data =  data.filter(x => this.condititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     this.setDxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length != 0 && this.orCondititons.length == 0 && this.wordCondititons.length != 0)
  //   {
  //     let data = this.dxPageData.data.filter(x => this.condititons.every(substring => x.CName.includes(substring)) || this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     this.setDxGridView(0,total,data)
  //   }
  //   else if(this.condititons.length == 0 && this.orCondititons.length != 0 && this.wordCondititons.length != 0)
  //   {
  //     let data =  this.dxPageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     data =  data.filter(x => this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     this.setDxGridView(0,total,data)
  //   }
  //   else
  //   {
  //     let data =  this.dxPageData.data.filter(x => this.orCondititons.some(substring => x.CName.includes(substring)))
  //     data =  data.filter(x => this.condititons.every(substring => x.CName.includes(substring)) || this.wordCondititons.every(substring => x.CName.includes(substring)))
  //     let total = data.length >=300 ? 300: data.length;
  //     this.setDxGridView(0,total,data)
  //   }
  // }
  //#endregion
  //#region 暫無作用
  // makeFormGroup(): FormGroup {
  //   const fg: FormGroup = this.fb.group({
  //     Code:[''],
  //     Name:[''],
  //     EName:[''],
  //     AllName:[''],
  //     disePartName1:[''],
  //     disePartName2:[''],
  //     disePartName3:[''],
  //     disePartName4:[''],
  //     disePartName5:[''],
  //     searchEName:[false],
  //     longName:[false],
  //   });
  //   return fg;
  // }
  // removeWork: boolean = false;
  // remove():boolean {
  //   this.removeWork = !this.removeWork ;
  //   return this.removeWork;
  // }
  // addWork: boolean = false;
  // addFlag():boolean {
  //   this.addWork = !this.addWork ;
  //   return this.addWork;
  // }
  mappingEditForm(data: clinicRx) {
    if (!data) {
      return;
    }
    this.editFormGroup.patchValue({
      Code: data.Code,
      Name: data.Name,
      EName: data.EName
    });
  }

    // 填入初始化用的值
    async fillFormData(data: clinicRx) {
      if (!data) {
        return;
      }
      this.editFormGroup.patchValue({
        Code: data.Code,
        Name: data.Name,
        EName: data.EName
      });
    }

    async edit(data: clinicRx) {

    }
  //#endregion
}
