<form [formGroup]="editFG" *ngIf="editFG" class="vis-form	vis-patients-edit overflow-x-auto  position-relative" style="height:inherit;overflow-y: scroll;">
  <!-- ./ chi-top -->
  <div formArrayName="orders" *ngIf="orderArray" [class]="layoutCssClass" [id]="areaId" class="position-relative" [ngStyle]="{width:tableWidth}">
    <!-- default grid -->
    <table [class]="'odr-tb'" class="position-relative w-100" [hidden]="isChineseGrid">
      <thead style="z-index: 990;" class="top-0 position-sticky">
        <tr>
          <ng-container *ngIf="!fillExecuteOnly;else elseFillExe">
            <th class="s-mark text-center" (contextmenu)="openBatchEdit($event,'SPRule')" i18n="@@OrderMark" style="cursor: pointer; width: 4cqb;" title="點擊滑鼠右鍵批次修改">標記</th>
            <th class="code text-center" (click)="codeSort()" i18n="@@OrderCode">代碼</th>
            <th class="name text-center" style="width: 80px;" i18n="@@OrderName" [ngStyle]="{minWidth:namePlusWidth}" title="僅顯示最新藥價或已存檔的醫令藥價">名稱 {{ isDental == true ? '' : showPrice }}</th>
            <th *ngIf="!fillExecuteOnly" class="qty text-center" (contextmenu)="openBatchEdit($event,'QTY')" i18n="@@OrderQty" style="cursor: pointer;" title="點擊滑鼠右鍵批次修改">
              {{ getFieldName('QTY') }}
            </th>
            <!-- <th class="unit text-center" i18n="@@OrderUnit">單位</th> -->
            <th class="freq text-center" (contextmenu)="openBatchEdit($event,'Freq')" i18n="@@OrderFreq" style="cursor: pointer; width: 6cqb;" title="點擊滑鼠右鍵批次修改">頻率</th>
            <th class="days text-center" (contextmenu)="openBatchEdit($event,'Days')" i18n="@@OrderDays" style="cursor: pointer; width: 4cqb;" title="點擊滑鼠右鍵批次修改">日</th>
            <th class="total text-center" i18n="@@OrderTotal">{{ getFieldName('TotalDose') }}</th>
            <th *ngIf="!hiddenWhenOpen && !isSum" (click)="onClickHiddenWhenOpen(true)" class="way text-center" (contextmenu)="openBatchEdit($event,'Way')" i18n="@@OrderWay" style="cursor: pointer; width: 5cqb;" title="點擊滑鼠右鍵批次修改">途徑</th>
            <th *ngIf="(hiddenWhenOpen || isSum)" (click)="onClickHiddenWhenOpen(false)" class="way text-center"  i18n="@@OrderTotal"  style="cursor: pointer; width: 5cqb;">小計</th>
            <th *ngIf='!isLab' class="dispensing text-center" (contextmenu)="openBatchEdit($event,'DispTP')"  i18n="@@OrderDispensing" style="cursor: pointer; width: 4cqb;" title="點擊滑鼠右鍵批次修改">調劑</th>
            <th class="remark text-center" i18n="@@Remark" (contextmenu)="onContextMenu($event, 'Remark')">註
              <!-- <button type="button" class="title-btn2 position-absolute m-auto top-0 bottom-0 w-4" style="right:3px; height: 20px;"
              [matMenuTriggerFor]="menuRemark" aria-label="popup menu" [disabled]="checkDisabled()">
              <app-icon-etc></app-icon-etc>
              </button> -->
              <div [ngStyle]="{position:'fixed', top: menuRemarkPosition.y, left: menuRemarkPosition.x}" [matMenuTriggerFor]="menuRemark"></div>
              <mat-menu #menuRemark="matMenu">
                <ng-template matMenuContent>
                  <button [attr.disabled]="checkDisabled()"
                    *ngFor="let item of _remarkOpts"
                    (click)="setAllRemark(item.value)"
                    mat-menu-item>
                    <span class="managedBy-value">{{item.text}}</span>
                  </button>
                </ng-template>
              </mat-menu>
            </th>
          </ng-container>
          <ng-template #elseFillExe>
            <th class="code text-center" (click)="codeSort()" i18n="@@OrderCode">代碼</th>
            <th class="name text-center" style="width: 80px;" i18n="@@OrderName" [ngStyle]="{minWidth:namePlusWidth}">名稱 {{ showPrice }}</th>
          </ng-template>
        <!-- <ng-container *ngIf="fillExecuteOnly"> -->
          <ng-container>
            <th class="user text-center" i18n="@@OrderWay" style="min-width: 100px;max-width: 100px;">執行人員</th>
            <th class="date text-center" i18n="@@OrderWay">開始日</th>
            <th class="time text-center" i18n="@@OrderWay">開始時</th>
            <th class="date text-center" i18n="@@OrderWay">截止日</th>
            <th class="time text-center" i18n="@@OrderWay">截止時</th>
          </ng-container>
          <th class="dispensing text-center lh-lg" (contextmenu)="openBatchEdit($event,'DispTP')" *ngIf='isLab&&!fillExecuteOnly' i18n="@@OrderLabExam" title="在診所檢驗">檢驗</th>
        </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let odr of orderArray.controls; let idx = index" [formGroupName]="idx">
          <tr [ngStyle]="{'cursor' : dragable ? 'grab' : 'default'}" [cdkDragDisabled]="dragDisabled" cdkDrag>
            <td class="s-mark position-relative" *ngIf="!fillExecuteOnly">
              <span class="extend">
              <!-- <input formControlName="SPRule" class="odr-cell" [id]="markIdPrefix + idx" autocomplete="off" (click)="SPRuleClick($event)" -->
                <!-- [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus /> -->
                <ng-container *ngIf="idx < findLastEmptyCodePosition()">
                  <div class="title" style="display: flex">
                    <input type="tel" formControlName="SPRule" class="odr-cell" tabindex="{{idx * 1000 + 0}}"
                        [id]="spRuleIdPrefix + idx" [attr.disabled]="inputDisabled()"
                        (keydown)="onSPRuleKeyDown($event, odr, spRuleIdPrefix + idx, idx)"
                        (blur)="onSPRuleChenge(idx,odr)"
                        autocomplete="off" [enterLastColIdx]="enterLastColIdx"
                        appOrderGridInput appHistFocus [data]="_tab.rxType" />
                    <button type="button" *ngIf="editCol==''" class="title-btn2" [matMenuTriggerFor]="menu" [attr.disabled]="checkDisabled()"
                        [matMenuTriggerData]="spRuleIdPrefix + idx" aria-label="popup menu">
                        <app-icon-etc></app-icon-etc> <!-- <mat-icon class="popup-menu">more_vert</mat-icon> -->
                     </button>
                    <mat-checkbox *ngIf="editCol=='SPRule'&&odr.value.RxCode" (click)="$event.preventDefault()"
                     (mousedown)="checkMouseDown($event, check, odr)" (mouseenter)="checkMouseOver($event, check, odr)"
                      (change)="batchCheckedChange($event, odr)" [checked]="isBatchEditCheck(odr)"  #check></mat-checkbox>
                  </div>
                  <mat-menu #menu="matMenu">
                    <ng-template matMenuContent >
                      <button [attr.disabled]="checkDisabled()"
                        *ngFor="let item of spRulemenu"
                        (click)="onSPRuleSelect(odr, item, spRuleIdPrefix + idx, idx)"
                        mat-menu-item>
                        <span class="managedBy-value">{{item.text}}</span>
                      </button>
                    </ng-template>
                  </mat-menu>
                </ng-container>
              </span>
            </td>
            <td class="code position-relative">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="RxCode" class="odr-cell" [id]="codeIdPrefix + idx" [attr.disabled]="inputDisabled()||(fillExecuteOnly?true:null)"
                  (focus)="onCodeFocus($event, idx)" (blur)="onCodeBlur($event, idx)" (keyup)="onCodeKeyUp($event, idx)" tabindex="{{idx * 1000 + 1}}"
                  (keydown)="onCodeKeyDown($event, idx)"
                  autocomplete="off" [enterLastColIdx]="enterLastColIdx"
                  appOrderGridInput appHistFocus [data]="_tab.rxType" #codeInput/>
                  <button type="button" *ngIf="isActive(codeInput)&&codeInput.value" (mousedown)="deleteRowAt(idx)" class="title-btn2" [attr.disabled]="checkDisabled()">刪</button>
              </div>
            </td>
            <td class="name position-relative">
              <div style="display: flex;" class="extend">
                <input formControlName="ProdName" class="odr-cell" [id]="nameIdPrefix + idx" [attr.disabled]="inputDisabled()||(fillExecuteOnly?true:null)"
                title="{{ getOrderName(idx) }}" readonly="readonly" [enterLastColIdx]="enterLastColIdx" (click)="onClickAtName(idx)"
                appOrderGridInput appHistFocus [data]="_tab.rxType"/>
                <button type="button" *ngIf="odr.value.RxCode" style="height: 25px; width: 25px; padding: 0px;" (click)="viewDetail(odr)" 
                [attr.disabled]="checkDisabled()">
                  <app-icon-etc></app-icon-etc>
                </button>
              </div>
            </td>
            <!--這個叫做輸入量，拿來進行量法計算用的-->
            <td class="qty position-relative"  [ngClass]="validateCls(idx,'QTY')" [title]="errTip(idx,'QTY')" *ngIf="!fillExecuteOnly">
              <div style="display: flex;align-items: center;padding-right: 5px;" class="extend">
                <input type="tel" formControlName="QTY" class="odr-cell" [id]="qtyIdPrefix + idx" autocomplete="off" 
                (keyup)="onQtyChenge($event,idx)"
                (input)="onQtyInput($event, idx)"
                  [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus appNumberOnly [data]="_tab.rxType" tabindex="{{idx * 1000 + 2}}"
                  [readonly]="editCol!=''" (keydown)="onQtyKeyDown($event, idx)" />
                  <mat-checkbox *ngIf="editCol=='QTY'&&odr.value.RxCode" (click)="$event.preventDefault()"
                    (mousedown)="checkMouseDown($event,check,odr)" (mouseenter)="checkMouseOver($event,check,odr)"
                              (change)="batchCheckedChange($event,odr)" [checked]="isBatchEditCheck(odr)" #check></mat-checkbox>
                <span>{{qtyRule==3?odr.value.BoxUnit:odr.value.Unit}}</span>
              </div>
            </td>
            <!-- <td class="unit position-relative" *ngIf="!fillExecuteOnly">
              <div class="extend">
                <input type="tel" formControlName="Unit" class="odr-cell" readonly="readonly" [attr.disabled]="checkDisabled()"
                [id]="unitIdPrefix + idx" [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus [data]="_tab.rxType" />
              </div>
            </td> -->
            <td class="freq position-relative"  [ngClass]="validateCls(idx,'FREQ')" [title]="errTip(idx,'FREQ')" *ngIf="!fillExecuteOnly">
              <div style="display: flex;" class="extend">
                <!--formControlName="Freq"-->
                <input type="tel" formControlName="Freq" class="odr-cell" [attr.disabled]="inputDisabled()" 
                (blur)="onFreqBlur(idx)"
                (keydown)="onFreqKeyDown($event, odr, idx)" [id]="freqIdPrefix + idx" autocomplete="off" tabindex="{{idx * 1000 + 3}}"
                [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus [data]="_tab.rxType" />
                <button type="button" *ngIf="editCol==''" [matMenuTriggerData]="freqIdPrefix + idx" [hidden]="idx >= findLastEmptyCodePosition()" class="title-btn2" style="width: 20px;"
                  [matMenuTriggerFor]="menuFreq" aria-label="popup menu" [attr.disabled]="checkDisabled()"
                  #freqSelectBtn>
                    <app-icon-etc></app-icon-etc>
                  </button>
                <mat-checkbox *ngIf="editCol=='Freq'&&odr.value.RxCode" (click)="$event.preventDefault()"
                 (mousedown)="checkMouseDown($event, check, odr)" (mouseenter)="checkMouseOver($event, check, odr)"
                  (change)="batchCheckedChange($event, odr)" [checked]="isBatchEditCheck(odr)"  #check></mat-checkbox>
              </div>
              <mat-menu #menuFreq="matMenu" (closed)="freqMenuClosed(idx)" class="extend">
                <ng-template matMenuContent >
                  <div class="input-menu">
                    <button type="button" *ngFor="let item of dosages" [attr.disabled]="checkDisabled()"
                      (click)="setFreq(odr, item.Code, freqIdPrefix + idx,idx)"
                      mat-menu-item>
                    <span class="managedBy-value">{{item.Code}}</span>
                  </button>
                  </div>
                </ng-template>
              </mat-menu>
            </td>
            <td class="days position-relative"  [ngClass]="validateCls(idx,'DAY')" [title]="errTip(idx,'DAY')" *ngIf="!fillExecuteOnly">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="Days" class="odr-cell" [id]="daysIdPrefix + idx" autocomplete="off" [attr.disabled]="inputDisabled()" tabindex="{{idx * 1000 + 4}}"
                  [enterLastColIdx]="enterLastColIdx" 
                  appOrderGridInput appHistFocus appNumberOnly [data]="_tab.rxType" 
                  (keydown)="onDaysKeyDown($event, idx)" (keyup)="onDaysChenge($event,idx)" />
                <mat-checkbox *ngIf="editCol=='Days'&&odr.value.RxCode" (click)="$event.preventDefault()"
                 (mousedown)="checkMouseDown($event,check,odr)" (mouseenter)="checkMouseOver($event,check,odr)"
                              (change)="batchCheckedChange($event,odr)" [checked]="isBatchEditCheck(odr)"  #check></mat-checkbox>
              </div>
            </td>
            <!--非總量法放總量-->
            <td class="total position-relative"  [ngClass]="validateCls(idx,'TOTAL')" [title]="errTip(idx,'TOTAL')"
               *ngIf="!fillExecuteOnly&&qtyRule!=3">
              <div class="extend" style="display: flex;align-items: center;padding-right: 5px;">
                <input type="tel" formControlName="TotalDose" class="odr-cell" [id]="totalDoseIdPrefix + idx" autocomplete="off" [attr.disabled]="inputDisabled()" tabindex="{{idx * 1000 + 5}}"
                [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus [data]="_tab.rxType" 
                (keydown)="onTotalDoseKeyDown($event, idx)" (blur)="onTotalDoseBlur($event, idx)" appNumberOnly />
                <span>{{odr.value.BoxUnit}}</span>
              </div>
              
            </td>
            <!--總量法方次量(Dose)-->
            <td class="qty position-relative" [ngClass]="validateCls(idx,'DOSE')" [title]="errTip(idx,'DOSE')"
                *ngIf="!fillExecuteOnly&&qtyRule==3">
              <div class="extend" style="display: flex;align-items: center;padding-right: 5px;">
                <input type="tel" formControlName="Dose" class="odr-cell" [id]="DoseIdPrefix + idx" autocomplete="off" [attr.disabled]="inputDisabled()" tabindex="{{idx * 1000 + 5}}"
                [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus [data]="_tab.rxType" 
                (keydown)="onDoseKeyDown($event, idx)" (keyup)="onDoseKeyUp($event, idx)" appNumberOnly />
                <span>{{odr.value.Unit}}</span>
              </div>
              
            </td>

            <td class="way position-relative" *ngIf="!fillExecuteOnly && !hiddenWhenOpen && !isSum">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="Way" class="odr-cell" [attr.disabled]="inputDisabled()" tabindex="{{idx * 1000 + 6}}"
                  (keydown)="onWayKeyDown($event, odr, idx)"
                  [id]="wayIdPrefix + idx"
                  autocomplete="off"
                  [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus [data]="_tab.rxType"/>
                  <button type="button" *ngIf="editCol==''" [matMenuTriggerData]="wayIdPrefix + idx" [attr.disabled]="checkDisabled()"
                    [hidden]="idx >= findLastEmptyCodePosition()" class="title-btn2" style="width: 20px;"
                    [matMenuTriggerFor]="menuWay" aria-label="popup menu"
                    (menuOpened)="wayMenuOpen(odr)"
                    #waySelectBtn>
                    <app-icon-etc></app-icon-etc>
                    </button>
                <mat-checkbox *ngIf="editCol=='Way'&&odr.value.RxCode" (click)="$event.preventDefault()"
                 (mousedown)="checkMouseDown($event,check,odr)" (mouseenter)="checkMouseOver($event,check,odr)"
                              (change)="batchCheckedChange($event,odr)" [checked]="isBatchEditCheck(odr)"  #check></mat-checkbox>
              </div>
              <mat-menu #menuWay="matMenu" (closed)="wayMenuClosed(idx)" class="extend">
                <ng-template matMenuContent >
                  <div class="input-menu">
                    <ng-container *ngIf="[2,3,4].includes(odr.value.RxType)">
                      <button type="button" *ngFor="let code of wayMenu"
                              (click)="setWay(odr,code,wayIdPrefix + idx)"
                              mat-menu-item>
                        <span class="managedBy-value">{{code}}</span>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="[6,7,8,9,10].includes(odr.value.RxType)">
                      <button type="button" *ngFor="let item of editOptions.part"
                              (click)="setWay(odr,item.Code,wayIdPrefix + idx)"
                              mat-menu-item>
                        <span class="managedBy-value">{{item.Code}}|{{item.Name}}</span>
                      </button>
                    </ng-container>
                  </div>
                </ng-template>
              </mat-menu>
            </td>
            <td class="total position-relative"  *ngIf="!fillExecuteOnly && (hiddenWhenOpen || isSum)">
              <div class="extend">
                <input type="tel" style="color: darkgreen;" formControlName="CalPrice" class="odr-cell" [id]="calPricePrefix + idx" autocomplete="off" [attr.disabled]="inputDisabled()" tabindex="{{idx * 1000 + 5}}"
                [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus   appNumberOnly />
              </div>
            </td>
            <!-- <td class="box">
              <div>{{ odr.value.TotalBox }} {{ odr.value.BoxUnit }}</div>
            </td> -->
            <!-- <td class="box-total">
              <input formControlName="TotalBox" class="odr-cell" readonly="readonly" tabindex="-1"
                [id]="totalBoxIdPrefix + idx" [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus />
            </td> -->
            <!-- <td class="box-unit">
              <input formControlName="BoxUnit" class="odr-cell" readonly="readonly" tabindex="-1"
                [id]="boxUnitIdPrefix + idx" [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus />
            </td> -->
            <td class="dispensing position-relative" *ngIf="!fillExecuteOnly">
              <div type="tel" class="title extend" style="display: flex;">
                <input class="odr-cell" [id]="dispensingIdPrefix + idx" formControlName="DispTP" tabindex="{{idx * 1000 + 7}}"
                  autocomplete="off" [value]="odr.value.DispTP" [attr.disabled]="inputDisabled()||dispDisable(odr.value)"
                  (keydown)="onDispensingKeyDown($event, odr, dispensingIdPrefix + idx, idx)" [enterLastColIdx]="enterLastColIdx" appOrderGridInput
                  appHistFocus [data]="_tab.rxType"/>
                  <button type="button" *ngIf="editCol==''" [matMenuTriggerData]="dispensingIdPrefix + idx" 
                          [hidden]="idx >= findLastEmptyCodePosition()" class="title-btn2" style="width: 20px;"
                          [matMenuTriggerFor]="menu1" aria-label="popup menu" [attr.disabled]="checkDisabled()||dispDisable(odr.value)"
                          (menuOpened)="onDispMenuOpen(odr.value)"
                  #dispSelectBtn>
                  <app-icon-etc></app-icon-etc>
                  </button>
                  <mat-checkbox *ngIf="editCol=='DispTP'&&odr.value.RxCode" (click)="$event.preventDefault()"
                   (mousedown)="checkMouseDown($event,check,odr)" (mouseenter)="checkMouseOver($event,check,odr)"
                  (change)="batchCheckedChange($event,odr)" [checked]="isBatchEditCheck(odr)" #check></mat-checkbox>
              </div>
              <mat-menu class="dispMenu" #menu1="matMenu" (closed)="dispMenuClosed(idx)">
                <ng-template matMenuContent >
                  <div class="input-menu">
                    <button type="button" *ngFor="let item of _dispOpts" [title]="item.text"
                      (click)="setDisp(odr, item.value, dispensingIdPrefix + idx, idx)"
                      mat-menu-item>
                      <span class="managedBy-value" >{{item.value}}|{{item.text}}</span>
                    </button>
                  </div>
                </ng-template>
              </mat-menu>
            </td>
            <td class="remark position-relative" *ngIf="!fillExecuteOnly">
              <div class="title extend" style="display: flex;">
                <span style="align-content: center;margin-left: 5px;">{{odr.value.ChiFormCode}}</span>
                <input class="odr-cell" [id]="remarkIdPrefix + idx" formControlName="Remark" tabindex="{{idx * 1000 + 8}}"
                  autocomplete="off" [value]="odr.value.Remark" [attr.disabled]="inputDisabled()"
                  (keydown)="onRemarkKeyDown($event,odr,remarkIdPrefix + idx, idx)" [enterLastColIdx]="enterLastColIdx" appOrderGridInput
                  appHistFocus [data]="_tab.rxType"/>
                  <button type="button" *ngIf="odr.value.RxType==2" [matMenuTriggerData]="remarkIdPrefix + idx" [hidden]="idx >= findLastEmptyCodePosition()" class="title-btn2" style="width: 20px;"
                  [matMenuTriggerFor]="menu2" aria-label="popup menu" [attr.disabled]="checkDisabled()">
                  <app-icon-etc></app-icon-etc>
                  </button>
              </div>
              <mat-menu #menu2="matMenu" (closed)="remarkMenuClosed(idx)">
                <ng-template matMenuContent >
                  <div class="input-menu">
                    <button
                      *ngFor="let item of _remarkOpts"
                      (click)="setRemark(odr, item.value, remarkIdPrefix + idx)"
                      mat-menu-item>
                      <span class="managedBy-value">{{item.text}}</span>
                    </button>
                  </div>
                </ng-template>
              </mat-menu>
              <!-- <input formControlName="Remark" class="odr-cell" [id]="remarkIdPrefix + idx"
                autocomplete="off" [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus /> -->
            </td>
            <ng-container> <!-- *ngIf="fillExecuteOnly" -->
              <ng-container *ngIf="odr.value.RxCode; else noRxCode">
                <td class="freq position-relative">
                  <div style="display: flex;" class="extend">
                    <input type="tel" formControlName="MedIDName" class="odr-cell" 
                    [attr.disabled]="inputDisabled()" tabindex="{{idx * 1000 + 9}}"
                    (keydown)="onMedIDNameKeyDown($event, idx)" [id]="medIdNamePrefix + idx" autocomplete="off" [value]="odr.MedIDName" (blur)="medIDNameInputBlur($event, idx)"
                    [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus [data]="editOptions.executor" (input)="medIDNameInputChange($event, idx)" />
                    <button type="button" *ngIf="editCol==''" [matMenuTriggerData]="medIdNamePrefix + idx" [hidden]="idx >= findLastEmptyCodePosition()" class="title-btn2" style="width: 20px;"
                      [matMenuTriggerFor]="menuMedIdName" aria-label="popup menu" 
                      [attr.disabled]="checkDisabled()||checkNeedExecutor(idx)"
                      #freqSelectBtn>
                        <app-icon-etc></app-icon-etc>
                    </button>
                    <mat-checkbox *ngIf="editCol=='MedIDName'&&odr.value.RxCode" (click)="$event.preventDefault()"
                    (mousedown)="checkMouseDown($event, check, odr)" (mouseenter)="checkMouseOver($event, check, odr)"
                                  (change)="batchCheckedChange($event, odr)" [checked]="isBatchEditCheck(odr)" #check></mat-checkbox>
                  </div>
                  <mat-menu #menuMedIdName="matMenu" (closed)="medIdNameMenuClosed(idx)">
                    <ng-template matMenuContent >
                      <div class="input-menu">
                        <button type="button" *ngFor="let item of getFilterExecutor(idx)"
                          (click)="setMedID(odr, item.value, item.text, medIdNamePrefix + idx)"
                          mat-menu-item>
                        <span class="managedBy-value">{{item.text}}</span>
                      </button>
                      </div>
                    </ng-template>
                  </mat-menu>
                </td>
                <!--要調整，根據RxCode的屬性決定Filter editOptions.medUse -->
                <td class="st-date">
                  <div style="display: flex;">
                    <app-vis-datepicker formControlName="SDate" class="odr-cell" tabindex="{{idx * 1000 + 10}}"
                    [id]="sdatePrefix + idx" autocomplete="off" [attr.disabled]="checkDisabled()" [hidden]="idx >= findLastEmptyCodePosition()"
                    appOrderGridInput appHistFocus appNumberOnly></app-vis-datepicker>
                  </div>
                </td>
                <td class="st-time" i18n="@@STime">
                  <app-vis-timepicker formControlName="STime" class="odr-cell" tabindex="{{idx * 1000 + 11}}"
                    [id]="stimePrefix + idx" autocomplete="off" [attr.disabled]="checkDisabled()" [hidden]="idx >= findLastEmptyCodePosition()"
                    appOrderGridInput appHistFocus appNumberOnly></app-vis-timepicker>
                </td>
                <td class="st-date" i18n="@@EDate">
                  <div style="display: flex;">
                    <app-vis-datepicker formControlName="EDate" class="odr-cell" tabindex="{{idx * 1000 + 12}}"
                    [id]="edatePrefix + idx" autocomplete="off" [attr.disabled]="checkDisabled()" [hidden]="idx >= findLastEmptyCodePosition()"
                    appOrderGridInput appHistFocus appNumberOnly></app-vis-datepicker>
                  </div>
                </td>
                <td class="st-time" i18n="@@ETime">
                  <app-vis-timepicker formControlName="ETime" class="odr-cell" tabindex="{{idx * 1000 + 13}}"
                    [id]="etimePrefix + idx" autocomplete="off" [attr.disabled]="checkDisabled()" [hidden]="idx >= findLastEmptyCodePosition()"
                    appOrderGridInput appHistFocus appNumberOnly></app-vis-timepicker>
                </td>
              </ng-container>

              <ng-template #noRxCode>
                <td class="freq position-relative"></td>
                <td class="st-date"> </td>
                <td class="st-time" i18n="@@STime"> </td>
                <td class="st-date" i18n="@@EDate"> </td>
                <td class="st-time" i18n="@@ETime">  </td>
              </ng-template>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <!--formArrayName-->
</form>

<div class="batch-edit-mask" [ngStyle]="styleLeftMask" (click)="closeBatch()"></div>
<div class="batch-edit-mask" [ngStyle]="styleRightMask" (click)="closeBatch()">
  <div style="z-index: 1002;position: absolute;top:5px; left: 5px; min-width: 120px;max-width: 200px; background-color: rgba(255,255,255,0.8);border-radius: 5px;padding: 5px;"
  (click)="$event.stopPropagation()">
    <div class="position-relative text-left">
      <button
        type="button"
        (click)="closeBatch()"
        i18n="@@DialogClose"
        style="top: -0.8rem;right: -0.8rem;z-index: 995;"
        class="position-absolute vis-btn-config p-0 m-0 w-auto h-auto background-white border-0">
        <mat-icon class="mat-icon notranslate position-relative float-left d-inline-flex material-icons mat-icon-no-color" title="離開[Esc]">cancel</mat-icon>
      </button>
    </div>
    <div>批次修改：</div>
    <div style="display: flex;flex-wrap: wrap;column-gap:10px">
      <ng-container *ngFor="let item of batchRxtype" >
        <mat-checkbox  style="width: calc(50% - 5px);" (change)="batchRxTypeCheckChange($event,item.value)">{{item.text}}</mat-checkbox>
      </ng-container>
    </div>
    <div style="display: flex;">
      <input style="min-width: 0;flex-grow: 1;height: 25px;" (keyup)="batchInput($event)" #data>
      <button class="px-0" type="button" style="height: 25px;padding: 0px;min-width: 30px;" (click)="saveBatch(data.value)">
        <mat-icon style="font-size: 15px;line-height: 15px;height: 15px;vertical-align: middle;">keyboard_backspace</mat-icon>
      </button>
    </div>
    <div style="display: flex;flex-flow: row wrap;max-height: 200px;overflow-y: scroll;">
      <ng-container *ngFor="let item of batchOpt">
        <button class="px-0" type="button" style="min-width: 50%; max-height: unset;" (click)="saveBatch(item.value)">{{item.text}}</button>
      </ng-container>
    </div>
  </div>
</div>
<!-- <div style="display: block; height: 100%;background-color: red;" >

</div> -->
<ng-template let-data #rxDetail>
    <table class="rx-detail w-100 mat-table">
      <tbody>
        <tr>
          <th class="mat-header-cell w-40">標準處方代碼</th>
          <td class="mat-cell"><div class="extend-content">{{data.StdCode}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">歸屬</th>
          <td class="mat-cell"><div class="extend-content">{{data.Type}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">原瓶藥水註記</th>
          <td class="mat-cell"><div class="extend-content">{{data.SyrupNote}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">ATC代碼</th>
          <td class="mat-cell"><div class="extend-content">{{data.ATC}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">成分名</th>
          <td class="mat-cell"><div class="extend-content">{{data.IIName}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">價格變更日</th>
          <td class="mat-cell"><div class="extend-content">{{data.PriceChgDate|formatDate:''}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">健保價</th>
          <td class="mat-cell"><div class="extend-content">{{data.IPrice}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">上次健保價</th>
          <td class="mat-cell"><div class="extend-content">{{data.LastIPrice}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">自費價</th>
          <td class="mat-cell"><div class="extend-content">{{data.Price}}</div></td>
        </tr>
        <!-- <tr>
          <th class="mat-header-cell">特殊使用條件</th>
          <td class="mat-cell"><div class="extend-content">{{data.Price}}</div></td>
        </tr> -->
      </tbody>
    </table>
</ng-template>
