import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { HistPrintOperationDataDto, HistPrintOperationDataItemDto, HistPrintOriginalDataDto } from 'src/app/hist/models/hist-print-operation-data';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { CidMaskPipe } from 'src/app/shared/pipes/cid-mask.pipe';

@Component({
  selector: 'app-report-hist-print-original',
  templateUrl: './report-hist-print-original.component.html',
  styleUrls: ['./report-hist-print-original.component.css'],
  providers: [CidMaskPipe]
})
export class ReportHistPrintOriginalComponent implements OnInit {

  @Input()
  numberOfCopies: number = 1;

  @Input()
  set singleData(value: HistPrintOriginalDataDto) {
    if (value) {
      this.fillData([value], false);
    }
  }

  @Input()
  set multipleData(value: HistPrintOriginalDataDto[]) {
      if (value && value.length > 0) {
        this.fillData(value, false);
      }
    }

  @Input()
  set setICodes(value: ValueTextPair[]) {
    if (value) {
      this.icodes = value;
    }
  }

  @Input()
  set setCidMask(value: string) {
    if (value) {
      this.cidMask = value;
    }
  }

  @Input()
  printSecondName: boolean = true;

  @Output()
  printed = new EventEmitter<boolean>();

  sizePage = {
    width: 20, //cm
    height: 28.7 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.3, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: any;
  pageContent = [[]];

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  commonStyle = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 0px solid black; border-top: 0px solid black; vertical-align: middle;';
  commonStyle1 = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black; border-top: 0px solid black; vertical-align: middle;';
  commonStyle2 = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 0px solid black; border-top: 1px solid black; vertical-align: middle;';
  commonClass = '';
  commonClassText = '';
  upperboundIndex: number = 0;
  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  pageNo: number = 1;
  reportData: HistPrintOriginalDataDto[];
  reportName: string = '';
  shortReportName: string = '';
  forExcel: boolean = false;
  icodes: ValueTextPair[] = [];
  cidMask: string = '1234567890';
  hideStaffCId: boolean = false;
  oneSide: boolean = false;
  fullPage: boolean = false;
  medicalCombine: boolean = false;
  historyReport = 102;

  constructor(
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService: HcrService,
    private clinicDataService: ClinicDataService,
    private cidMaskPipe: CidMaskPipe
  ) {}

  async ngOnInit() {
    var codes = await this.clinicDataService.getSystemCodes(['C0010']);
    this.icodes = [{ value: '', text: '' }].concat(codes['C0010']);
    var param = await this.clinicDataService.getParam("PRN001");
    this.cidMask = param.IDHidePRN ?? this.cidMask;

    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 0px solid black; padding-left: 0px;padding-right: 0px;}\n' +//調整左右padding 5px->0px
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '.pres-report2 table tr { line-height: 20px; }\n' +//調整每行高度
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);

  }

  clearData() {
    this.totalRecord = 0;
    if (this.elContainer)
      this.elContainer.innerHTML = '';
  }

  fillData(data: HistPrintOriginalDataDto[], hideStaffCId: boolean, oneSide: boolean = false, fullPage: boolean = false, medicalCombine: boolean = false) {
    this.elContainer = document.getElementById("container");
    this.reportData = data;
    this.hideStaffCId = hideStaffCId;
    this.oneSide = oneSide;
    this.fullPage = fullPage;
    if (fullPage) {
      this.oneSide = true;
    }
    this.medicalCombine = medicalCombine;
    this.reportName = '病歷表(原紙張大小)';//報表上沒印出
    this.shortReportName = '原紙張病歷表';
    this.forExcel = true;
    this.commonClass = this.forExcel ? 'class="excel-format"' : '';
    this.commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    this.totalRecord = this.reportData.length;
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);

    // 組報表的html內容
    this.insertReportData();
  }

  insertReportData() {
    var listItems = this.reportData;
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);                                 // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);                                      // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader(listItems[0]);                         // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();                                               // 報表結束 -- 以下空白
    var elPageContent = document.getElementById("page-" + iPage + "-content");               // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    var j: number = 0;
    for (let i = 0; i < listItems.length; i++) {
        // 加入 List 每一筆的內容
        prevTableContent = elTableContent.innerHTML;
        if (afterSummary) {
            elTableContent.innerHTML += this.createHTMLListHeader(listItems[i]);
            afterSummary = false;
        }
        if (listItems[i].IsSummary) {
            html_Block = this.createHTMLSummary(listItems[i].SummaryAmount, j);
            afterSummary = true;
            j = 0;
        } else {
            html_Block = this.createHTMLBlock(listItems[i]);
            j++;
        }
        elTableContent.innerHTML += html_Block;
        // 判斷是否超出頁面範圍? -> 新增一頁
        //IsMasterFirst=不同Patient跳頁用
        if ((i > 0 && listItems[i].IsMasterFirst) || elPageContent.offsetHeight > this.heightPageWithoutPadding) {
            elTableContent.innerHTML = prevTableContent;
            iPage += 1;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            html_header = this.createHTMLPageHeader(iPage);
            elPageContent.innerHTML += html_header;
            elTableContent = document.getElementById("table-" + iPage + "-content");
            if (listItems[i].IsSummary) {
                elTableContent.innerHTML = this.createHTMLListHeader(listItems[i-1]);
            } else {
                elTableContent.innerHTML = this.createHTMLListHeader(listItems[i]);
            }
            elTableContent.innerHTML += html_Block;

            this.pageContent[iPage] = [];
            this.pageContent[iPage].push(iBlock);
        } else {
            this.pageContent[iPage].push(iBlock);
        }

        iBlock += 1;
    }
    elPageContent.innerHTML +=  html_finish;
    this.upperboundIndex = iPage;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    var page = '';
    //強制儲存格轉文字mso-number-format
    if (this.forExcel) {
      page += `
        <style>
          .excel-format {
            font-size: 12pt;
          }
          .excel-format-text {
            font-size: 12pt;
            mso-number-format: "@";
          }
        </style>`;
    }
    //margin: 0px auto; 若設5px，只印一頁時，輸出pdf會多一頁空白。
    page += `
      <div class="page" id="page-` + iPage + `"
        style="
        height: ` + this.sizePage.height + `cm;
        width: ` + this.sizePage.width + `cm;
        padding-top: ` + this.paddingPage.top + `cm;
        padding-right: ` + this.paddingPage.right + `cm;
        padding-bottom: ` + this.paddingPage.bottom + `cm;
        padding-left: ` + this.paddingPage.left + `cm;
        display: block;
        margin: 0px auto;
        box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        background-color: white;
        ">
        <div class="content" id="page-` + iPage + `-content">
        </div>
      </div>`;
    return page;
  }

  createHTMLPageHeader(iPage) {
    return `
      <div id="header" style="margin: 0px 10px;">
        <div style="display: flex;column-gap: 5px;">
          <table style="width: 100%; text-align: center; border: none;">
            <tr>
              <td colspan="9" style="height: 1px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none; font-family: 細明體;">

              </td>
            </tr>
          </table>
        </div>
      </div>

      <div style="-webkit-text-size-adjust: none; font-size: 8pt; zoom: 0.8; margin: 0px 2px; text-align: center; height: 1px;">
        <label style="font-weight: bold; height: 1px;"></label>
      </div>
      <div style="-webkit-text-size-adjust: none; font-size: 12pt; zoom: 0.8; margin: 0px 10px; font-family: 細明體;">
        <div style="display: flex;column-gap: 5px;">
          <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;"></table>
        </div>
      </div>`;
  }

  createHTMLListHeader(dto?: HistPrintOriginalDataDto) {
    // 改到createHTMLBlock()去執行。
    return '';
  }

  createHTMLListHeaderExt(data?: HistPrintOperationDataDto, side?: 'Left' | 'Right') {
    let iCodeName = this.icodes.find(i => i.value === data.ICode)?.text ?? data.ICode;
    let showICodeName = (iCodeName.length > 4 ? '身:' : '身份:') + iCodeName;
    let cid = this.cidMaskPipe.transform(data.CId, this.cidMask);
    let showCid = (iCodeName.length > 4 ? '證:' : '身份證:') + cid;
    let birthday = data.Birthday ? DateHelper.getROCFullDateString(new Date(data.Birthday)) : '';
    let regDate = data.Date ? DateHelper.getROCFullDateString(new Date(data.Date), '') : '';
    let showTreat = '';
    if (data.CusNo && data.CntPresNo) {
      showTreat = `(療${data.CusNo}/${data.CusTot},慢${data.CntPresNo}/${data.CntPresTot})`;
    }
    else if (data.CusNo) {
      showTreat = `(${data.CusNo}/${data.CusTot})`;
    }
    else if (data.CntPresNo) {
      showTreat = `(${data.CntPresNo}/${data.CntPresTot})`;
    }
    showTreat = showTreat ? '　' + showTreat : '';
    let showBP = (data.SBP && data.DBP) ? `${data.SBP}/${data.DBP}` : '';
    let showBMI = side === 'Right' ? `
            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>體重:${data.Weight !== 0 ? data.Weight + 'kg' : ''}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>體溫:${data.BT !== 0 ? data.BT + '℃' : ''}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>血壓:${showBP}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>BMI:${data.BMI !== 0 ? data.BMI : ''}</td>
              </tr>
            </table></td></tr>
      ` : `
            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${data.Weight !== 0 ? '體重:' + data.Weight + 'kg' : '　'}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${data.BT !== 0 ? '體溫:' + data.BT + '℃' : ''}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${showBP ? '血壓:' + showBP : ''}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${data.BMI !== 0 ? 'BMI:' + data.BMI : ''}</td>
              </tr>
            </table></td></tr>
      `;
    let showRegisterRemark = side === 'Right' ? data.RegisterRemark : '';
    let showPE = data.PE ? `
              <tr>
                <td style="text-align: left; white-space: pre-wrap; ${this.commonStyle}" ${this.commonClass}>PE:${data.PE}</td>
              </tr>
    ` : '';

    return `
          <table style="width:100%; font-size: 12pt;">

            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>姓名:${data.PatientNoAndName}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${showICodeName}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${showCid}</td>
              </tr>
            </table></td></tr>

            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>日期:${regDate}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>卡:${data.Isqno}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>院所:${data.NHICode}${data.ClinicName}</td>
              </tr>
            </table></td></tr>

            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>門診登錄: ${showRegisterRemark}</td>
              </tr>
            </table></td></tr>

            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; white-space: pre-wrap; ${this.commonStyle}" ${this.commonClass}>${data.CC}</td>
              </tr>
              ${showPE}
            </table></td></tr>

            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td>　</td>
              </tr>
            </table></td></tr>

            ${showBMI}

            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>國病別:${data.AllDxName}${showTreat}</td>
              </tr>
            </table></td></tr>

            <tr><td><table style="width:100%; font-size: 12pt;">
              <tr>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>科　別:${data.Dept}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>案類:${data.Case}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>日份:${data.DeclareDays}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>調劑:${data.DispTP}</td>
                <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>生日:${birthday}</td>
              </tr>
            </table></td></tr>

          </table>
      `;
  }

  createHTMLBlock(dto: HistPrintOriginalDataDto) {
    let data = dto.Master;
    let items = dto.Items;
    //當醫令合併時，且非第一筆醫令，不顯示醫令標題。
    let combine = this.medicalCombine;

    let leftHeader = this.createHTMLListHeaderExt(data, 'Left');
    let rightHeader = this.oneSide ? '' : this.createHTMLListHeaderExt(data, 'Right');

    let leftBlock = this.createHTMLBlockHead(items, 'Left');
    let rightBlock = this.oneSide ? '' : this.createHTMLBlockHead(items, 'Right');

    leftBlock += this.createHTMLBlockItem(items, 'Left');
    rightBlock += this.oneSide ? '' : this.createHTMLBlockItem(items, 'Right');

    let leftLast = this.createHTMLBlockLast(data, 'Left');
    let rightLast = this.oneSide ? '' : this.createHTMLBlockLast(data, 'Right');

    let leftCombine = !combine ? '' : this.createHTMLBlockCombine(dto.CombineItems, 'Left');
    let rightCombine = !combine ? '' : this.oneSide ? '' : this.createHTMLBlockCombine(dto.CombineItems, 'Right');

    leftBlock += `
          </table>
          ${leftLast}`;

    rightBlock += `
          </table>
          ${rightLast}`;

    //左邊td一個病歷, 中間td空白, 右邊td一個病歷(同一人同一病歷,左右格式有些差異)
    let text = !this.fullPage ? `
      <tr>
        <td style="width:49.5%; vertical-align:top;">
          ${leftHeader}
          ${leftBlock}
          ${leftCombine}
        </td>

        <td style="width:1%; vertical-align:top;">
          <table style="width:100%; font-size: 12pt;">
            <tr><td>&nbsp;</td></tr>
          </table>
        </td>

        <td style="width:49.5%; vertical-align:top;">
          ${rightHeader}
          ${rightBlock}
          ${rightCombine}
        </td>
      </tr>
    `
    : `
      <tr>
        <td style="width:100%; vertical-align:top;">
          ${leftHeader}
          ${leftBlock}
          ${leftCombine}
        </td>
      </tr>
    `;

    return text;
  }

  createHTMLBlockHead(items: HistPrintOperationDataItemDto[], side?: 'Left' | 'Right') {
    let qtyLabel = (items.length > 0 && items[0].QtyRule === HistOrder.QtyRule_DailyDose) ? '日量' : '劑量';

    return side === 'Left' ? `
          <table style="width:100%; font-size: 12pt;">
            <tr>
              <td style="text-align: left;   width:  23%; ${this.commonStyle1}" ${this.commonClass}>健保碼</td>
              <td style="text-align: center; width:   3%; ${this.commonStyle1}" ${this.commonClass}>N</td>
              <td style="text-align: left;                ${this.commonStyle1}" ${this.commonClass}>藥材/治療</td>
              <td style="text-align: right;  width:   8%; ${this.commonStyle1}" ${this.commonClass}>單價</td>
              <td style="text-align: right;  width: 8.2%; ${this.commonStyle1}" ${this.commonClass}>${qtyLabel}</td>
              <td style="text-align: center; width:   6%; ${this.commonStyle1}" ${this.commonClass}>UN</td>
              <td style="text-align: center; width:  10%; ${this.commonStyle1}" ${this.commonClass}>頻率</td>
              <td style="text-align: right;  width:   6%; ${this.commonStyle1}" ${this.commonClass}>日</td>
              <td style="text-align: right;  width: 8.2%; ${this.commonStyle1}" ${this.commonClass}>總量</td>
              <td style="text-align: center; width: 8.2%; ${this.commonStyle1}" ${this.commonClass}>途徑</td>
            </tr>
        ` : `
          <table style="width:100%; font-size: 12pt;">
            <tr>
              <td style="text-align: center; width:   4%; ${this.commonStyle1}" ${this.commonClass}>N</td>
              <td style="text-align: left;                ${this.commonStyle1}" ${this.commonClass}>藥材/治療</td>
              <td style="text-align: right;  width:   8%; ${this.commonStyle1}" ${this.commonClass}>${qtyLabel}</td>
              <td style="text-align: center; width:   6%; ${this.commonStyle1}" ${this.commonClass}>UN</td>
              <td style="text-align: center; width:  10%; ${this.commonStyle1}" ${this.commonClass}>頻率</td>
              <td style="text-align: right;  width:   6%; ${this.commonStyle1}" ${this.commonClass}>日</td>
              <td style="text-align: center; width:   5%; ${this.commonStyle1}" ${this.commonClass}>註</td>
              <td style="text-align: right;  width:   8%; ${this.commonStyle1}" ${this.commonClass}>總量</td>
            </tr>
        `;
  }

  createHTMLBlockItem(items: HistPrintOperationDataItemDto[], side?: 'Left' | 'Right') {
    let text = '';
    for (let i = 0; i < items.length; i++) {
      if (side === 'Left') {
        text += `
            <tr>
              <td style="text-align: left;   width:  23%; ${this.commonStyle}" ${this.commonClassText}>${items[i].StdCode}</td>
              <td style="text-align: center; width:   3%; ${this.commonStyle}" ${this.commonClass}>${items[i].SPRule !== 0 ? items[i].SPRule : ''}</td>
              <td style="text-align: left;                ${this.commonStyle}" ${this.commonClassText}>${this.stringFactory(items[i].ProdName, this.fullPage ? 22 : 12)}</td>
              <td style="text-align: right;  width:   8%; ${this.commonStyle}" ${this.commonClass}>${items[i].IPrice}</td>
              <td style="text-align: right;  width: 8.2%; ${this.commonStyle}" ${this.commonClass}>${items[i].QTY}</td>
              <td style="text-align: center; width:   6%; ${this.commonStyle}" ${this.commonClass}>${items[i].Unit}</td>
              <td style="text-align: center; width:  10%; ${this.commonStyle}" ${this.commonClass}>${items[i].FreqName}</td>
              <td style="text-align: right;  width:   6%; ${this.commonStyle}" ${this.commonClass}>${items[i].Days}</td>
              <td style="text-align: right;  width: 8.2%; ${this.commonStyle}" ${this.commonClass}>${items[i].TotalDose}</td>
              <td style="text-align: center; width: 8.2%; ${this.commonStyle}" ${this.commonClass}>${items[i].Way}</td>
            </tr>
          `;
      }
      else {
        let rightFirstName = items[i].IIName ? items[i].IIName : items[i].ProdName;
        rightFirstName = this.stringFactory(rightFirstName, 26);
        let rightSecondName = items[i].GXName;
        let showSecondName = this.printSecondName && rightSecondName ? `
            <tr>
              <td style="text-align: center; ${this.commonStyle}" ${this.commonClass}></td>
              <td colspan="7" style="text-align: left;   ${this.commonStyle}" ${this.commonClass}>${rightSecondName}</td>
            </tr>
        ` : '';

        text += `
            <tr>
              <td style="text-align: center; width:   4%; ${this.commonStyle}" ${this.commonClass}>${items[i].SPRule !== 0 ? items[i].SPRule : ''}</td>
              <td style="text-align: left;                ${this.commonStyle}" ${this.commonClassText}>${rightFirstName}</td>
              <td style="text-align: right;  width:   8%; ${this.commonStyle}" ${this.commonClass}>${items[i].QTY}</td>
              <td style="text-align: center; width:   6%; ${this.commonStyle}" ${this.commonClass}>${items[i].Unit}</td>
              <td style="text-align: center; width:  10%; ${this.commonStyle}" ${this.commonClass}>${items[i].Freq}</td>
              <td style="text-align: right;  width:   6%; ${this.commonStyle}" ${this.commonClass}>${items[i].Days}</td>
              <td style="text-align: center; width:   5%; ${this.commonStyle}" ${this.commonClassText}>${items[i].Remark.slice(0, 1)}</td>
              <td style="text-align: right;  width:   8%; ${this.commonStyle}" ${this.commonClass}>${items[i].TotalDose}</td>
            </tr>
            ${showSecondName}
          `;
      }
    };
    return text;
  }

  createHTMLBlockLast(data: HistPrintOperationDataDto, side?: 'Left' | 'Right') {
    let drName = '';
    if (data.DrID) {
      let drID = this.hideStaffCId ? this.cidMaskPipe.transform(data.DrID, this.cidMask) : data.DrID;
      drName = '醫師:' + drID + data.DoctorName;
    }
    let phName = '';
    if (data.PharID) {
      let phID = this.hideStaffCId ? this.cidMaskPipe.transform(data.PharID, this.cidMask) : data.PharID;
      phName = '藥師:' + phID + data.PharmacistName;
    }
    let showDoctor = `
          <tr><td><table style="width:100%; font-size: 12pt;">
            <tr>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${drName}</td>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${phName}</td>
            </tr>
          </table></td></tr>
    `;
    let showAPartRx = data.APartRx ? `★藥自付:${data.APartRx}★` : '';
    let showAPartExam = data.APartExam ? `★檢驗自付:${data.APartExam}★` : '';
    return side === 'Left' ? `
        <table style="width:100%; font-size: 12pt;">

          ${showDoctor}

          <tr><td><table style="width:100%; font-size: 12pt;">
            <tr>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>診察${data.ADx !== 0 ? data.ADx : ''}</td>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>藥費${data.ARx !== 0 ? data.ARx : ''}</td>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>治療${data.ATx !== 0 ? data.ATx : ''}</td>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>藥事${data.AMed !== 0 ? data.AMed : ''}</td>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>申請${data.ADcl !== 0 ? data.ADcl : ''}</td>
            </tr>
            <tr>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>掛費${data.FeeReg !== 0 ? data.FeeReg : ''}</td>
              <td colspan="4" style="text-align: left; ${this.commonStyle}" ${this.commonClass}>自付${data.APartOPD !== 0 ? data.APartOPD : ''} ${showAPartRx} ${showAPartExam}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${data.AOwn !== 0 ? '[自費:' + data.AOwn + ']' : ''}</td>
            </tr>
          </table></td></tr>

        </table>
        ` : `
        <table style="width:100%; font-size: 12pt;">

          ${showDoctor}

          <tr><td><table style="width:100%; font-size: 12pt;">
            <tr>
              <td style="text-align: left; width:25%; ${this.commonStyle}" ${this.commonClass}>自付:${data.APartOPD !== 0 ? data.APartOPD : ''}</td>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>掛費:${data.FeeReg !== 0 ? data.FeeReg : ''} ${showAPartRx} ${showAPartExam}</td>
            </tr>
            <tr>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>${data.AOwn !== 0 ? '[自費:' + data.AOwn + ']' : (this.printSecondName ? '' : '　')}</td>
            </tr>
          </table></td></tr>

        </table>
    `;
  }

  createHTMLBlockCombine(items: HistPrintOperationDataItemDto[], side?: 'Left' | 'Right') {
    if (!items) return '';

    //從items取得所有distinct historyid
    let historyIds = items.map(i => i.HistoryId).filter((value, index, self) => self.indexOf(value) === index);
    let text = '';
    historyIds.forEach(h => {
      let itemsByHistory = items.filter(i => i.HistoryId === h);
      let regDate = itemsByHistory[0].Date ? DateHelper.getROCFullDateString(new Date(itemsByHistory[0].Date), '/') : '';
      text += `
        <table style="width:100%; font-size: 12pt;">
          <tr><td><table style="width:100%; font-size: 12pt;">
            <tr>
              <td style="text-align: left; ${this.commonStyle}" ${this.commonClass}>日期:${regDate}</td>
            </tr>
          </table></td></tr>
        </table>
        <table style="width:100%; font-size: 12pt;">
        ${this.createHTMLBlockItem(itemsByHistory, side)}
        </table>
        `;
    });

    return text;
  }

  createHTMLSummary(summary: number, records: number) {
    return '';

    var html = `<tr>
                    <td colspan='3' style="text-align: right; width: 33%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        小計:
                    </td>
                    <td style="text-align: right; width: 17%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        ` + (Math.round(summary*100)/100).toFixed(2) + `
                    </td>
                    <td style="text-align: left; width: 12%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">

                    </td>
                    <td colspan='2' style="text-align: right; width: 18%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        筆數:
                    </td>
                    <td style="text-align: right; width: 18%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        ` + records + `筆
                    </td>
                    <td style="text-align: left; width: 2%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">

                    </td>
                </tr>`;
    return html;
  }

  createHTMLFinish() {
      // return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: none;">
      //             <label style="font-weight: bold;">以下空白</label>
      //         </div>`;
      return '';
  }

  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      //去除邊框, 實際印出到pdf
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.15cm;','');
      html = html.replace('box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0.5cm;','');
    }
    return html;
  }

  async onPrint() {
      const printContents = this.getHtml();
      for (let i = 1; i <= this.numberOfCopies; i++) {
        var ret = await this.localPrintService.printHtml(this.historyReport, this.shortReportName, printContents);
        if (!ret.Successed) break;
      }
      if (ret.Successed) {
          this.notification.showSuccess(this.shortReportName + '已送出列印!', true);
      } else {
          this.notification.showError(this.shortReportName + '送出列印失敗!');
      }
      this.printed.emit(true);
  }

  async exportAsExcel() {
    if (this.reportData.length > 0) {
      const table = this.reportContainer.nativeElement;

      //html已有<table>經由ExportTableToExcel()後最外層會加上<table>，導致Excel第一行會空白，所以轉出時把table轉div。
      const html = this.forExcel ? table.innerHTML.replace('table', 'div') : table.innerHTML;

      await this.hcrService.ExportTableToExcel(this.shortReportName, html);
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }

    this.forExcel = false;

    this.printed.emit(true);
  }

  stringFactory(src: string,maxlength :number): string {
    if (src == null || src == '' || src == undefined) return ''      // 使用正規表達式匹配中文字符
      const chineseCharacters = src.match(/[\u4e00-\u9fa5]/g);
      const fullWidthPattern = /[\uFF01-\uFF60\uFFE0-\uFFE6]/g;

      let initlength = 0;
      // 如果沒有中文字符，則返回整個字串的長度
      for (let ind = 0;ind <src.length; ind++) {
        let chineseAlpha = src[ind].match(/[\u4e00-\u9fa5]/g);
        let fullWidthAlpha = src[ind].match(/[\uFF01-\uFF60\uFFE0-\uFFE6]/g);
        if (chineseAlpha) initlength +=2;
        else if (fullWidthAlpha) initlength +=2;
        else initlength +=1;
        if (initlength > maxlength){
          return src.substr(0, ind);
        }
      }
    return src;
  }

}
