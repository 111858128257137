import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GridData } from '../../grid/vis-grid/vis-grid.component';
import { ReserveService } from 'src/app/services/reserve.service';
import { RegisterReserveDTO } from 'src/app/services/api-service-dentist/register/register-api.model';
import { RegisterService, RegistInfo } from 'src/app/services/register-service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { PageData } from 'src/app/shared/models/page-data';

@Component({
  selector: 'app-reserve-grid',
  templateUrl: './reserve-grid.component.html',
  styleUrls: ['./reserve-grid.component.css']
})
export class ReserveGridComponent implements OnInit {

  @Input() title: string;
  @Input() pageSize: number = 10;
  @Input() pageSkipNo: number = 0;
  @Input() width: string = '100%';
  @Input() data: PageData;  // RegisterReserveDTO[];
  @Input() isAllowInput:boolean;

  gridView: GridData;
  selectedItems: number[] = [];

  isOpenAbnormalIC: boolean = false;
  handleData: RegisterReserveDTO;

  constructor(private reserveService: ReserveService,
    private registerServive: RegisterService
  ) { }

  ngOnInit(): void {
    this.refreshList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue) {
      this.refreshList();
    }
  }

  pageChange(page: any): void {
    this.pageSkipNo = (page - 1) * this.pageSize;  // 跳過的資料筆數
    this.refreshList();
  }

  async refreshList() {
    //如果沒有帶資料進來，會取得所有預約資料
    var totalCount = 0;
    if (!this.data) {
      // const defaultData = await this.reserveService.getReserveList(0, new Date(), new Date());
      const defaultData = await this.reserveService.getReservePageData(0, new Date(), new Date())
      this.data.data = RegisterReserveDTO.fromReserveItem(defaultData.data);
      totalCount = defaultData.total;
    } else {
      totalCount = this.data.total;
    }

    this.gridView = {
      data: this.data.data,
      total: totalCount //this.data.length
    };
  }

  onBtnAbnormalIC(data: any) { // RegisterReserveDTO) {
    this.handleData = data as RegisterReserveDTO;
    this.isOpenAbnormalIC = true;
  }

  onBtnReadIC(data: any) { //RegisterReserveDTO) {
    this.handleData = data  as RegisterReserveDTO;
    const regInfo = new RegistInfo(data.PatientId, 'RESV').reg(data.RegisterId).checkIn().ic();
    this.registerServive.goToRegPage(regInfo);
  }

  onBtnRegisterOweCard(data: any) { //RegisterReserveDTO) {
    this.handleData = data as RegisterReserveDTO;
    const regInfo = new RegistInfo(data.PatientId, 'RESV').reg(data.RegisterId).checkIn().oweCard();
    this.registerServive.goToRegPage(regInfo);
  }

  onBtnRegisterSelfPay(data: any) { //RegisterReserveDTO) {
    this.handleData = data as RegisterReserveDTO;;
    const regInfo = new RegistInfo(data.PatientId, 'RESV').reg(data.RegisterId).checkIn().selfPay();
    this.registerServive.goToRegPage(regInfo);
  }

  /** 以異常代碼進入掛號,無異常則同IC掛
  * @param abCode
  */
  onAbnormalIC(abCode: string) {
    const regInfo = new RegistInfo(this.handleData.PatientId, 'RESV').reg(this.handleData.RegisterId).checkIn().abnormal(abCode);
    this.registerServive.goToRegPage(regInfo);
  }

  /** 接收異常代碼視窗回傳並關閉 */
  onAbnormalICRet(arg: string) {
    this.isOpenAbnormalIC = false;
    this.onAbnormalIC(arg);
  }

  /** 處理異常掛號對話窗狀態變更事件
* @param evt
*/
  onAbnormalStatusChanged(arg: string) {
    console.log("onAbnormalStatusChanged", arg);
  }

  /** 處理異常掛號對話窗關閉事件 */
  onAbnormalCloseDialog() {
    console.log("onAbnormalCloseDialog");
    this.isOpenAbnormalIC = false;
  }

  /** 報到功能按鈕事件 */
  expandIndex = -1;
  onExpendMore(i) {
    if (this.expandIndex == i) {
      this.expandIndex = -1
    } else {
      this.expandIndex = i;
    }
  }
}
