import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ControlDrugsReport, ControlDrugsReportItem } from 'src/app/opd/opd-report/models/control-drugs-report';
import { HcrService } from 'src/app/services/hcr-service.service';
import { deepCopy } from 'src/app/shared/utilities';

@Component({
    selector: 'app-controlled-drugs',
    templateUrl: './controlled-drugs.component.html',
    styleUrls: ['./controlled-drugs.component.css']
})
export class ControlledDrugsComponent implements OnInit, AfterViewInit {

    @Output()
    printed = new EventEmitter<boolean>();

    sizePage = {
        width: 20, //cm
        height: 28.5 //cm
      };
      paddingPage = {
          top: 0, //cm
          right: 0, //cm
          bottom: 0.5, //cm
          left: 0 //cm
      };

    heightPageWithoutPadding = this.convertCmtoPx(
        this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
    );
    elContainer: any;
    anchorsBlockValue: any;
    pageContent = [[]];

    @ViewChild('reportContainer', { static: false })
    reportContainer: ElementRef<HTMLDivElement>;

    upperboundIndex: number = 0;
    showDoctorNameColume: boolean;
    monthStr: string = '';
    nowStr: string = '';
    timeStr: string = '';
    totalRecord: number = 0;
    pageNo: number = 1;
    _sDate: string;
    _eDate: string;
    // 報表清單物件
    controlDrugsReport: ControlDrugsReport[];
    reportName: string = '';
    drugTitle: string = '';

    constructor(private localPrintService: LocalPrintService,
                private notification: EasyNotificationService,
                private hcrService:HcrService) {
                }

    ngOnInit() {
        var st = document.createElement('style');
        st.appendChild(document.createTextNode(
            '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
            '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
            '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
            '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
            '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
            '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
            '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
            '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
        ));
        setTimeout(() => {
            this.reportContainer.nativeElement.appendChild(st);
        }, 0);
    }

    ngOnDestroy() {
        this.clearData();
    }

    ngAfterViewInit() {

    }

    clearData() {
        if (this.elContainer) this.elContainer.innerHTML = '';
        const parentElement = document.getElementById("pres-report2");
        if (parentElement) parentElement.firstElementChild.innerHTML = '';
    }

    fillData(data: ControlDrugsReport[]) {
        this.elContainer = document.getElementById("container");
        this.controlDrugsReport = data;
        this.reportName = '管制用藥醫令統計';
        // 報表名稱
        if (this.controlDrugsReport[0].reportType == '1') {    // 1: 總表, 2: 日量統計表, 3: 明細表
            this.reportName += '總表';
            this.totalRecord = this.controlDrugsReport[0].Items.length;
        } else if (this.controlDrugsReport[0].reportType == '2') {
            this.reportName += '總表 -- 屬日';
            this.totalRecord = this.controlDrugsReport[0].Items.filter(x => x.IsSummary == false).length;
        } else if (this.controlDrugsReport[0].reportType == '3') {
            this.totalRecord = this.controlDrugsReport[0].Items.filter(x => x.IsSummary == false).length;
            this.reportName += '明細表 -- ';
            if (this.controlDrugsReport[0].groupBy == '1') {
                this.reportName += '依日期';
            } else if (this.controlDrugsReport[0].groupBy == '2') {
                this.reportName += '依病患';
            } else if (this.controlDrugsReport[0].groupBy == '3') {
                this.reportName += '依藥品';
            }
        }
        // 藥品名稱欄位Title
        this.drugTitle = this.controlDrugsReport[0].searchBy ? `藥代` : `健保碼`;
        // 列印日期
        this.nowStr = this.onGetDateString(new Date(this.controlDrugsReport[0].ReportTime), true);
        this.timeStr = this.onGetTimeString(new Date(this.controlDrugsReport[0].ReportTime));
        // 查詢日期時間區間
        this._sDate = this.onGetDateString(new Date(this.controlDrugsReport[0].startDate), true, '.') + '-' + this.onGetTimeString(new Date(this.controlDrugsReport[0].startDate), ':', false);
        this._eDate = this.onGetDateString(new Date(this.controlDrugsReport[0].endDate), true, '.') + '-' + this.onGetTimeString(new Date(this.controlDrugsReport[0].endDate), ':', false);

        // 組報表的html內容
        this.insertReportData();

        // 組完報表將結果指定給父元素的第一個子元素
        const parentElement = document.getElementById("pres-report2");
        parentElement.firstElementChild.innerHTML = this.elContainer.innerHTML;

        //console.log('The final report container after reassign element >>>>>', this.reportContainer.nativeElement.innerHTML);
    }

    insertReportData() {
        var listItems = this.controlDrugsReport[0].Items;
        var html_Block = "";
        var iPage = 0;
        var iBlock = 0;
        this.elContainer.innerHTML = this.createHTMLPage(iPage);
        var html_header = this.createHTMLPageHeader(iPage);                                      // 頁表頭
        var html_ListerHeader = this.createHTMLListHeader(listItems[0]);                         // List欄位顯示名稱
        var html_finish = this.createHTMLFinish();                                               // 報表結束 -- 以下空白
        var elPageContent = document.getElementById("page-" + iPage + "-content");               // 取得某頁的content，每次查詢後會取第0頁
        var prevTableContent: any;
        var afterSummary = false;
        // 組報表內容
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        var j: number = 0;
        for (let i = 0; i < listItems.length; i++) {
            // 加入 List 每一筆的內容
            prevTableContent = elTableContent.innerHTML;
            if (afterSummary) {
                elTableContent.innerHTML += this.createHTMLListHeader(listItems[i]);
                afterSummary = false;
            }
            if (this.controlDrugsReport[0].reportType != '1' && listItems[i].IsSummary) {
                html_Block = this.createHTMLSummary(listItems[i].SummaryAmount, j);
                afterSummary = true;
                j = 0;
            } else {
                html_Block = this.createHTMLBlock(listItems[i]);
                j++;
            }
            elTableContent.innerHTML += html_Block;
            // 判斷是否超出頁面範圍? -> 新增一頁
            if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                elTableContent.innerHTML = prevTableContent;
                iPage += 1;
                this.elContainer.innerHTML += this.createHTMLPage(iPage);
                
                elPageContent = document.getElementById("page-" + iPage + "-content");
                // [頁表頭][List欄位顯示名稱]
                html_header = this.createHTMLPageHeader(iPage);
                elPageContent.innerHTML += html_header;
                elTableContent = document.getElementById("table-" + iPage + "-content");
                if (this.controlDrugsReport[0].reportType != '1' && listItems[i].IsSummary) {
                    elTableContent.innerHTML = this.createHTMLListHeader(listItems[i-1]);
                } else {
                    elTableContent.innerHTML = this.createHTMLListHeader(listItems[i]);
                }
                elTableContent.innerHTML += html_Block;

                this.pageContent[iPage] = [];
                this.pageContent[iPage].push(iBlock);
            } else {
                this.pageContent[iPage].push(iBlock);
            }

            iBlock += 1;
        }
        elPageContent.innerHTML += html_finish;
        this.upperboundIndex = iPage;
        for (var i = 0; i <= iPage; i++) {
            var tPage = document.getElementById("totalPage-" + i);
            tPage.innerHTML = "" + (this.upperboundIndex + 1);
        }
        //console.log('The final report container before reassign element >>>>>', this.reportContainer.nativeElement.innerHTML); 
    }

    convertPxToCm(px) {
        return Math.round(((px * 2.54) / 96) * 100) / 100;
    }

    convertCmtoPx(cm) {
        return Math.round((cm * 96) / 2.54);
    }

    createHTMLPage(iPage) {
        return `<div class="page" id="page-` + iPage + `"
                    style="
                    height: ` + this.sizePage.height + `cm;
                    width: ` + this.sizePage.width + `cm;
                    padding-top: ` + this.paddingPage.top + `cm;
                    padding-right: ` + this.paddingPage.right + `cm;
                    padding-bottom: ` + this.paddingPage.bottom + `cm;
                    padding-left: ` + this.paddingPage.left + `cm;
                    display: block;
                    margin: 0px auto;
                    box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                    box-sizing: border-box;
                    background-color: white;
                    ">
                    <div class="content" id="page-` + iPage + `-content">
                    </div>
                </div>`;
    }

    createHTMLPageHeader(iPage) {
        return `<div id="header" style="margin: 0px 10px;">
                    <div style="display: flex;column-gap: 5px;">
                        <table style="width: 100%; text-align: center; border: none;">
                            <tr>
                                <td colspan="9" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                    ` + this.reportName + `
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width: 20%; font-size: 12px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                印表日期 ` + this.nowStr + `
                                </td>
                                <td colspan="5" style="width: 60%; font-size: 14px; text-align: center; font-weight: bolder; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                期間：` + this._sDate + ` 至 ` + this._eDate + `
                                </td>
                                <td colspan="2" style="width: 20%; font-size: 12px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                總筆數 ` + this.totalRecord + `
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="font-size: 12px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                印表時間 ` + this.timeStr + `
                                </td>
                                <td colspan="5" style="font-size: 12px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                                </td>
                                <td colspan="2" style="font-size: 12px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="-webkit-text-size-adjust: none; font-size: 12pt; zoom: 0.8; margin: 0px 2px; text-align: center;">
                    <label style="font-weight: bold; height: 10px;">　</label>
                </div>
                <div style="-webkit-text-size-adjust: none; font-size: 12px; zoom: 0.8; margin: 0px 10px;">
                    <div style="display: flex;column-gap: 5px;">
                        <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                        </table>
                    </div>
                </div>`;
    }

    createHTMLListHeader(data?: any) {
        var html = ``;
        if (this.controlDrugsReport[0].reportType == '1') {     // 1: 總表
            html = `<tr>
                        <td colspan='2' style="text-align: left; width: 16%; height: 40px; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                            ` + this.drugTitle + `
                        </td>
                        <td colspan='4' style="text-align: left; width: 60%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                            藥品全名
                        </td>
                        <td colspan='2' style="text-align: center; width: 12%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                            使用總量
                        </td>
                        <td style="text-align: center; width: 12%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                            級數
                        </td>
                    </tr>`;
        } else if (this.controlDrugsReport[0].reportType == '2') {      // 2: 日量統計表
            var drugCode = this.controlDrugsReport[0].searchBy == '1' ? data.RxCode : data.StdCode;
            var drugName = this.controlDrugsReport[0].useCommonName ? data.GXName : data.ProdName;
            var spacer = '　 　 　 　'
            html = `<tr>
                        <td style="text-align: left; width: 2%; height: 40px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">

                        </td>
                        <td colspan='2' style="text-align: left; width: 31%; font-size: 16px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: none;">
                            ` + this.drugTitle + `:　` + drugCode + `
                        </td>
                        <td colspan='6' style="text-align: left; width: 67%; font-size: 16px; font-weight: bolder;border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: none;">
                            藥名:　` + drugName + `
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: left; width: 2%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">

                        </td>
                        <td colspan='2' style="text-align: left; width: 31%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                            日期
                        </td>
                        <td colspan='4' style="text-align: left; width: 52%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                            ` + spacer + `使用總量
                        </td>
                        <td style="text-align: right; width: 13%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                            級數
                        </td>
                        <td style="text-align: left; width: 2%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">

                        </td>
                    </tr>`;
        } else if (this.controlDrugsReport[0].reportType == '3') {  // 3: 明細表
            if (this.controlDrugsReport[0].groupBy == '1') {        // 1: 依日期分類
                var regDate = this.onGetDateString(new Date(data.RegDate), true, '');
                html = `<tr>
                            <td colspan='10' style="text-align: left; width: 67%; font-size: 16px; font-weight: bolder;border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                日期:　` + regDate + `
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left; width: 8%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                ` + this.drugTitle + `
                            </td>
                            <td style="text-align: left; width: 20%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                藥&nbsp;品&nbsp;全&nbsp;名
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                使用處方人
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                病歷號
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                身分證號
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                電話
                            </td>
                            <td style="text-align: center; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                使用總量
                            </td>
                            <td style="text-align: left; width: 7%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                服法
                            </td>
                            <td style="text-align: center; width: 8%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                消耗量
                            </td>
                            <td style="text-align: left; width: 7%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                級數
                            </td>
                        </tr>`;
            } else if (this.controlDrugsReport[0].groupBy == '2') { // 2: 依病患姓名
                var patName = data.CName;
                var patBirthday = this.onGetDateString(new Date(data.Birthday), true, '');
                var patPhone = (data.PhoneArea.length > 0 ? (data.PhoneArea + '-') : '') + data.Phone;
                var patAddress = data.City + data.Area + data.Street;
                html = `<tr>
                            <td colspan="5" style="text-align: left; width: 100%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                病患姓名:&nbsp;` + patName + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                生日:&nbsp;` + patBirthday + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                電話:&nbsp;` + patPhone + `<br>
                                地址:&nbsp;` + patAddress + `
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left; width: 20%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                ` + this.drugTitle + `
                            </td>
                            <td style="text-align: left; width: 50%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                藥&nbsp;品&nbsp;全&nbsp;名
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                使用日期
                            </td>
                            <td style="text-align: center; width: 10%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                使用總量
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                級數
                            </td>
                        </tr>`;
            } else if (this.controlDrugsReport[0].groupBy == '3') { // 3: 依藥品分類
                var drugName = this.controlDrugsReport[0].useCommonName ? data.GXName : data.ProdName;
                html = `<tr>
                            <td colspan='10' style="text-align: left; width: 67%; font-size: 18px; font-weight: bolder;border-right: 0px solid black; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                藥品明細:　` + drugName + `
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left; width: 8%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                ` + this.drugTitle + `
                            </td>
                            <td style="text-align: left; width: 20%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                藥&nbsp;品&nbsp;全&nbsp;名
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                日期
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                病患姓名
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                身分證號
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                電話
                            </td>
                            <td style="text-align: center; width: 10%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                使用總量
                            </td>
                            <td style="text-align: left; width: 7%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                服法
                            </td>
                            <td style="text-align: center; width: 8%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                消耗量
                            </td>
                            <td style="text-align: left; width: 7%; border-right: 0px solid black; font-size: 14px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: 3px solid black;">
                                級數
                            </td>
                        </tr>`;
            }
        }
        return html;
    }

    createHTMLBlock(data: ControlDrugsReportItem) {
        var html = ``;
        var drugCode = this.controlDrugsReport[0].searchBy == '1' ? data.RxCode : data.StdCode;
        var drugName = this.controlDrugsReport[0].useCommonName ? data.GXName : data.ProdName;
        var totalDose = (Math.round(data.TotalDose*100)/100).toFixed(2);
        var CTRDrugs = data.CTRDrugs;
        var regDate = this.onGetDateString(new Date(data.RegDate), true, '');
        if (this.controlDrugsReport[0].reportType == '1') {     // 1: 總表
            drugCode = this.controlDrugsReport[0].searchBy == '1' ? data.SummaryRxCode : data.SummaryStdCode;
            drugName = data.SummaryDrugName;
            totalDose = (Math.round(data.SummaryAmount*100)/100).toFixed(2);
            CTRDrugs = data.SummaryCTRDrugs;
            html = `<tr>
                        <td colspan='2' style="text-align: left; width: 16%; height: 40px; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 1px solid black;">
                            ` + drugCode + `
                        </td>
                        <td colspan='4' style="text-align: left; width: 60%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 1px solid black;">
                            ` + drugName + `
                        </td>
                        <td colspan='2' style="text-align: right; width: 12%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 1px solid black;">
                            ` + totalDose + `
                        </td>
                        <td style="text-align: center; width: 12%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 1px solid black;">
                            ` + CTRDrugs + `
                        </td>
                    </tr>`;
        } else if (this.controlDrugsReport[0].reportType == '2') {      // 2: 日量統計表
            html = `<tr>
                        <td style="text-align: left; width: 2%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: none;">

                        </td>
                        <td colspan='2' style="text-align: left; width: 31%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: none;">
                            ` + regDate + `
                        </td>
                        <td style="text-align: right; width: 12%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: none;">
                            ` + totalDose + `
                        </td>
                        <td colspan='2' style="text-align: left; width: 12%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: none;">

                        </td>
                        <td colspan='2' style="text-align: right; width: 40%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: none;">
                            ` + CTRDrugs + `
                        </td>
                        <td style="text-align: left; width: 2%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: none;">

                        </td>
                    </tr>`;
        } else if (this.controlDrugsReport[0].reportType == '3') {      // 3: 明細表
            var patName = data.CName;
            var patNo = data.PatientNo;
            var patCId = data.CId;
            var patPhone = (data.PhoneArea.length > 0 ? (data.PhoneArea + '-') : '') + data.Phone;
            var freq = data.Freq;
            if (this.controlDrugsReport[0].groupBy == '1') {        // 1: 依日期分類
                html =  `<tr>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + drugCode + `
                            </td>
                            <td style="text-align: left; width: 20%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + drugName.substring(0, 20) + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + patName + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + patNo + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + patCId + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + patPhone + `
                            </td>
                            <td style="text-align: right; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + totalDose + `
                            </td>
                            <td style="text-align: left; width: 7%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + freq + `
                            </td>
                            <td style="text-align: right; width: 8%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + totalDose + `
                            </td>
                            <td style="text-align: center; width: 7%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + CTRDrugs + `
                            </td>
                        </tr>`;
            } else if (this.controlDrugsReport[0].groupBy == '2') { // 2: 依病患姓名
                html =  `<tr>
                            <td style="text-align: left; width: 20%; border-right: 0px solid black; font-size: 14px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + drugCode + `
                            </td>
                            <td style="text-align: left; width: 50%; border-right: 0px solid black; font-size: 14px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + drugName + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 14px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + regDate + `
                            </td>
                            <td style="text-align: right; width: 10%; border-right: 0px solid black; font-size: 14px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + totalDose + `
                            </td>
                            <td style="text-align: center; width: 10%; border-right: 0px solid black; font-size: 14px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + CTRDrugs + `
                            </td>
                        </tr>`;
            } else if (this.controlDrugsReport[0].groupBy == '3') { // 3: 依藥品分類
                html =  `<tr>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + drugCode + `
                            </td>
                            <td style="text-align: left; width: 20%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + drugName.substring(0, 20) + `
                            </td>
                            <td style="text-align: center; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + regDate + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + patName + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + patCId + `
                            </td>
                            <td style="text-align: left; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + patPhone + `
                            </td>
                            <td style="text-align: right; width: 10%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + totalDose + `
                            </td>
                            <td style="text-align: left; width: 7%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + freq + `
                            </td>
                            <td style="text-align: right; width: 8%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + totalDose + `
                            </td>
                            <td style="text-align: center; width: 7%; border-right: 0px solid black; font-size: 12px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                                ` + CTRDrugs + `
                            </td>
                        </tr>`;
            }


        }
        return html;
    }

    createHTMLSummary(summary: number, records: number) {
        var html = ``;
        if (this.controlDrugsReport[0].reportType == '2') {
            html = `<tr>
                        <td colspan='3' style="text-align: right; width: 33%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                            小計:
                        </td>
                        <td style="text-align: right; width: 17%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                            ` + (Math.round(summary*100)/100).toFixed(2) + `
                        </td>
                        <td style="text-align: left; width: 12%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">

                        </td>
                        <td colspan='2' style="text-align: right; width: 18%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                            筆數:
                        </td>
                        <td style="text-align: right; width: 18%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                            ` + records + `筆
                        </td>
                        <td style="text-align: left; width: 2%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">

                        </td>
                    </tr>`;
        } else if (this.controlDrugsReport[0].reportType == '3') {
            var cols = this.controlDrugsReport[0].groupBy == '2' ? '5' : '10';
            var totString = '筆數:　 　' + records + '筆　 　　 　小計:　 　' + (Math.round(summary*100)/100).toFixed(2) + '　 　';
            html = `<tr>
                        <td colspan="` + cols + `" style="width: 100%; text-align: right; font-size: 16px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                            ` + totString + `
                        </td>
                        <td colspan="` + cols + `" style="width: 100%;">

                        </td>
                    </tr>`;
        }
        return html;
    }

    createHTMLFinish() {
        return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: none;">
                    <label style="font-weight: bold;">以下空白</label>
                </div>`;
    }

    getHtml() {
        var elPageContent = document.getElementById("container");

        var html = this.reportContainer.nativeElement.innerHTML;
        for (var i = 0; i <= this.upperboundIndex; i++) {
            html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
        }
        return html;
    }

    async onPrint() {
        const printContents = this.getHtml();
        console.log('printContents',printContents);
        var ret = await this.localPrintService.printHtml(444, this.reportName, printContents);
        if (ret.Successed) {
            this.notification.showSuccess(this.reportName + '已送出列印!', true);
        } else {
            this.notification.showError(this.reportName + '送出列印失敗!');
        }
        this.printed.emit(true);
    }

    async exportAsExcel() {

        const table = this.reportContainer.nativeElement;
        await this.hcrService.ExportTableToExcel(this.reportName,table.innerHTML)

        // const uri = 'data:application/vnd.ms-excel;base64,';
        // const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
        //                     <head>
        //                         <!--[if gte mso 9]>
        //                         <xml>
        //                             <x:ExcelWorkbook>
        //                                 <x:ExcelWorksheets>
        //                                     <x:ExcelWorksheet>
        //                                         <x:Name>{worksheet}</x:Name>
        //                                         <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
        //                                     </x:ExcelWorksheet>
        //                                 </x:ExcelWorksheets>
        //                             </x:ExcelWorkbook>
        //                         </xml>
        //                         <![endif]-->
        //                     </head>
        //                     <body>
        //                         <table>{table}</table>
        //                     </body>
        //                     </html>`;
        // const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
        // const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
        // const table = this.reportContainer.nativeElement;
        // const ctx = { worksheet: this.reportName, table: table.innerHTML };

        // var fileName = this.reportName + `_` + this.onGetTimeString(new Date(), '') + `.xls`
        // var content = format(template, ctx);
        // var ret =await this.hcrService.SaveTextAsZipIfPwd(this.reportName+'\\'+fileName,content)
        // await this.hcrService.ConfirmOpenDir('下載完成','檔案已下載至'+ret.Returns,ret.Returns);


        // const link = document.createElement('a');
        // link.download = this.reportName + `_` + this.onGetTimeString(new Date(), '') + `.xls`;
        // link.href = uri + base64(format(template, ctx));
        // link.click();
        this.printed.emit(true);
    }

    /** 轉換Date To String
     *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
     */
    onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
        if (ev) {
            var d = new Date(ev);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = isRoc ? ('' + (d.getFullYear() - 1911)) : ('' + d.getFullYear());
            if (isRoc) {
                if (year.length < 3) year = '0' + year;
            }

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            if (isMonth) {
                return [year, month].join(symbol);
            } else {
                return [year, month, day].join(symbol);
            }
        }
    }

    /** 轉換Time To String
        *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
    */
    onGetTimeString(ev, timeSymbol = ':', useSec = true) {
        if (ev) {
            var d = new Date(ev);
            // 趕時間先簡單寫
            let hr = d.getHours().toString();
            if (d.getHours() < 10) {
                hr = '0' + hr;
            }
            let min = d.getMinutes().toString();
            if (d.getMinutes() < 10) {
                min = '0' + min;
            }
            let sec = d.getSeconds().toString();
            if (d.getSeconds() < 10) {
                sec = '0' + sec;
            }
            var timeStr = [hr, min, sec].join(timeSymbol);
            if (!useSec) timeStr = [hr, min].join(timeSymbol);
            return timeStr;
        }
    }

    onGetDate(ev) {
        if (ev) {
            var d = new Date(ev);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day].join('');
        }
    }
}
