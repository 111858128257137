import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helpers/date-helper';
import { mas } from 'process';

@Pipe({
  name: 'formatROCDate'
})
export class FormatRocDatePipe implements PipeTransform {

  transform(value: Date|string,showUnit:boolean, mask:string=null): string {
    var dateStr = DateHelper.formatROCDate(value, showUnit);
    if(!mask){
      return dateStr;
    }
    var ret = '';
    var fixMask = showUnit?mask:mask.replace('年','').replace('月','').replace('日','');
    dateStr = dateStr.padStart(fixMask.length,'0');
    for(var i=0;i<fixMask.length;i++){
      if(i>=dateStr.length){
        return ret;
      }
      var char = fixMask.charAt(i)
      // 0-9使用原本字元
      if(/[YyMmDd]/.test(char)){
        ret+=dateStr.charAt(i);
      }else{
        //其他使用替代字元
        ret+=char
      }
    }
    return ret;
  }

}
@Pipe({
  name: 'formatROCDateUnit'
})
export class FormatRocDateUnitPipe implements PipeTransform {

  transform(value: string): string {
    var ret = ''
    if(value.length>=3){
      var yyy = value.substring(0,3);
      ret+=`${yyy}年`;
    }
    if(value.length>=5){
      var mm = value.substring(3,5);
      ret+=`${mm}月`;
    }
    if(value.length>=7){
      var dd = value.substring(5,7);
      ret+=`${dd}日`;
    }
    return ret;
  }

}

@Pipe({
  name: 'formatROCFullDate'
})
export class FormatRocFullDatePipe implements PipeTransform {

  transform(value: Date, dateSepetator: string = ''): string {
    if (!value) { return ''; }
    const newDate = new Date(value);
    return DateHelper.getROCFullDateString(newDate, dateSepetator);
  }

}

@Pipe({
  name: 'formatROCFullDateTime'
})
export class FormatRocFullDateTimePipe implements PipeTransform {

  transform(value: Date, dateSepetator: string = '', timeSepetator: string = ''): string {
    if (!value) { return ''; }
    const newDate = new Date(value);
    const formatDate = DateHelper.getROCFullDateString(newDate, dateSepetator);
    const formatTime = DateHelper.getTimeString(newDate, timeSepetator, true);
    return `${formatDate} ${formatTime}`;
  }

}
