import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { MutiTreatmentListDto } from 'src/app/services/api-service/register/mutiTreatmentListDto';
import { UserSimplifyInfoDTO } from 'src/app/services/api-service/user/uesrSimpleInfo';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { InitmultiTreatmentUserData, RehabituserCids } from '../model/rehabitusers';


@Component({
    selector: 'app-rehabilitation',
    templateUrl: './rehabilitation.component.html',
    styleUrls: ['./rehabilitation.component.css']
})


export class RehabilitationComponent implements OnInit {


  // @Output() onEmitUsers = new EventEmitter<RehabituserCids>();

multiTreat: boolean = false;


@Input()
set InitRehabilitationData(biguserData:InitmultiTreatmentUserData) {
  if(biguserData) {
    let listdata = biguserData.Data;
    let whole  = listdata?.HistList;
    this.initWhole = whole;
    const regexy = /\[RP\d+\]/;

  
    let RPwhole = whole.filter(x =>regexy.test(x.Hist.CusCl));
    let RPArray = this.getRehabilitation(RPwhole,"RP",biguserData.Users);
    let RSwhole = whole.filter(x =>x.Hist.CusCl?.includes("RS"));
    let RSArray = this.getRehabilitation(RSwhole,"RS",biguserData.Users);
    let ROwhole = whole.filter(x =>x.Hist.CusCl?.includes("RO"));
    let ROArray = this.getRehabilitation(ROwhole,"RO",biguserData.Users);
    let PRTwhole = whole.filter(x =>x.Hist.CusCl?.includes("RPR"));
    let PRTArray = this.getRehabilitation(PRTwhole,"RPR",biguserData.Users);
    if(RSArray) this.RSgridData = RSArray;
    else this.RSgridData = [];
    if(ROArray) this.ROgridData = ROArray;
    else this.ROgridData = [];
    if(RPArray) this.RPgridData = RPArray;
    else this.RPgridData = [];
    if(PRTArray) this.PRTgridData = PRTArray;
    else this.PRTgridData = [];
    if ((this.RPgridData.length > 0)  ||(this.ROgridData.length > 0) || (this.RSgridData.length > 0)){
      this.multiTreat = true;
    } else this.multiTreat = false;
  }
  else
  {
     this.RSgridData = [];
     this.ROgridData = [];
     this.RPgridData = [];
     this.PRTgridData = [];
  }
}
// rehabitMedUsersChg(value:ValueTextPair){
//   console.log('thisu',this.Users, value);
//   this.onEmitUsers.emit(this.Users);
// }
rehabitFormGroup: FormGroup;

    rows: number[] = [1, 2, 3, 4, 5, 6]; // 6行
    cols: number[] = [1, 2, 3]; // 3列

    initWhole : WholeHist[] = [];
    RSgridData: string[][] = [];
    ROgridData: string[][] = [];
    RPgridData: string[][] = [];
    PRTgridData: string[][] = [];
    RSers :  ValueTextPair[] = [];
    ROers :  ValueTextPair[] = [];
    RPers :  ValueTextPair[] = [];
    PRTers :  ValueTextPair[] = [];
    Users : RehabituserCids;
    // initUsers : RehabituserCids;

    constructor(){

    }

    ngOnInit(): void {
      // 初始化3x6网格数据


    }

    getinitselectUser(data: WholeHist[]){
      // this.initUsers = Object.assign(new RehabituserCids());

      var hist = data.map(x => x.Hist);
      var hRxs = hist.find(x => x.CusNo == '1').HRxs;
      // console.log('hRxs',hRxs);
      for (var order of hRxs) {
        if (order.RxCode.startsWith('420')) {
          this.Users.PTCid = order.MedID ?? '';
          // console.log('OTCid',order.MedID, this.Users.PTCid);

          }
          if (order.RxCode.startsWith('430')) {
            this.Users.OTCid = order.MedID ?? '';
          // console.log('STCid',order.MedID, this.Users.OTCid);

          }
          if (order.RxCode.startsWith('440')) {
            this.Users.STCid = order.MedID ?? '';
          //   console.log('PTCid',order.MedID, this.Users.STCid);

          }
          if (order.RxCode.startsWith('445')) {
            this.Users.PRTCid = order.MedID ?? '';
          //   console.log('PRTCid',order.MedID, this.Users.PRTCid);

          }
        }
    }

    getRehabilitation(data: WholeHist[],type ="",users :UserSimplifyInfoDTO[]) : string[][]{
      let array : string[][] = [];
      let empty : string[][] = [];
      var hist = data.map(x => x.Hist);
    //   var res = data.map(x => x.Register);
      var histOrders = hist.map(x => x.HRxs);
      let str : string;
      let str2: string;
      let emptycount = 6;
      if(histOrders.length <1) return array;
      for (var order of histOrders[0]) {
        if (order?.RxCode.startsWith('420') && type === "RP") {
            str = order.RxCode;
            str2 = order.MedID;
          }
          if (order?.RxCode.startsWith('430') && type === "RO") {
            str = order?.RxCode;
            str2 = order?.MedID;
          }
          if (order.RxCode.startsWith('440')&& type === "RS") {
            str = order?.RxCode;
            str2 = order?.MedID;
          }
          if (order.RxCode.startsWith('445')&& type === "RPR") {
            str = order?.RxCode;
            str2 = order?.MedID;
          }
        }
        for (let i = 1 ; i <= 24 ; i++){
           let hs = hist.find(x => x.CusNo == i.toString())
           if(hs)
           {
             var r = data.filter(x => x.Hist.Id == hs.Id).map(x => x.Register);
             let strtwo = '';
             if(type === "RP") strtwo = hs.HRxs.find(x => x.RxCode.startsWith('420'))?.MedID || '';
             else if(type === "RO") strtwo = hs.HRxs.find(x => x.RxCode.startsWith('430'))?.MedID|| '';
             else if(type === "RS") strtwo = hs.HRxs.find(x => x.RxCode.startsWith('440'))?.MedID|| '';
             else if(type === "RPR") strtwo = hs.HRxs.find(x => x.RxCode.startsWith('445'))?.MedID|| '';
             // console.log('users',users);
             let user =  users.find(x => x.CId == strtwo);
             let userName = user?.Name || strtwo;
             let x = [DateHelper.formatROCDate((r[0]?.RegDate)),str,userName.toString()]
             array.push(x)
             emptycount = emptycount - 1;
           }
           else
           {
            // let x = ['','','']
            // empty.push(x)
           }
        }
        let x = ['','','']
        if (emptycount >= 1)
        {
          for(var i = 1 ; i <= emptycount ;i++)
          {
            empty.push(x)

          }
        }
        var combinedArray: string[][] = [...array, ...empty];

        // array.concat(empty);
        // console.log('array',array,empty);
        return combinedArray;
    }
}
