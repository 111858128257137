import { Component, OnInit, AfterViewChecked, Input, Output, EventEmitter, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, } from "@angular/core";
// shared
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { HistService } from "../services/hist.service";
import { OrderTypeEnum } from "src/app/enums/OrderTypeEnum";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { DispensingTypeEnum, OrderDispensingTypeEnum } from "src/app/enums/DispensingTypeEnum";
import { CashierPreview, Hrxfee, PricingApi, PricingEditOpt } from "src/app/services/api-service/pricing/pricing-api";
import { UserConfirmService } from "src/app/services/user-confirm.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Distinct, GetChNumberText, isNullOrUndefined, isSpecialChar, Or } from "src/app/shared/utilities";
import { FnKey, HotKeyService, Priority } from "src/app/services/hotkey-service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { ParameterApi } from "src/app/services/api-service/parameters/parameter-api";
import { DateHelper } from "src/app/shared/helpers/date-helper";
import { MainLayoutService } from "src/app/layout/services/main-layout.service";
import { MemoryCache } from "src/app/services/memory-cache-service";
import { EasyNotificationService } from "src/app/services/easy-notification.service";
import { MatOption } from "@angular/material/core";
import { SessionService } from "src/app/services/session.service";
import { HistApi } from "src/app/services/api-service/hist/hist-api";
import { ReportHelperComponent } from "src/app/system/report/report-helper/report-helper.component";
import { UserCache } from "src/app/services/user-cache";
import { MedLackAreaApi } from "src/app/services/api-service/medlackarea/medlackarea-api";
import { MedLackArea } from "src/app/services/api-service/medlackarea/medlackarea";
import { NHIDeclareApi } from "src/app/services/api-service/NHI/nhideclare-api";
import { HistOrder } from "../models/hist-order";
import { HCRxCodes } from "src/app/services/api-service/pricing/hcRxCodes";
import { PriceOtherControl } from "./models/priceothercontrol";
import { FontGreatService } from "src/app/services/font-great.service";
import { GetHspidQuery, HspidApi } from "src/app/services/api-service/hspid/hspid-api";
import { PageData } from "src/app/shared/models/page-data";
import { BaseConfig } from "src/app/services/base-config.service";
import { LabforRatingInfo } from "./models/labforratinginfo";
import { Subscription, throwError } from 'rxjs';
import { KidneyDialysisDto, JailInfo } from './models/kidneydialysisdto';
import { Rx } from "src/app/models/rx";
import { ClinicDataService } from "src/app/services/data-service/clinic-data-service";
import { OrderSpecialRuleEnum } from "src/app/enums/OrderSpecialRuleEnum";
import { HST001Params } from "src/app/services/api-service/parameters/HST001Params";
import { PCSxApiService } from "src/app/services/api-service/opd/pcsx/pcsx-api";
import { VisComboboxRealTimeOptionComponent } from "src/app/shared/components/dropdownlist/vis-combobox-real-time-option/vis-combobox-real-time-option.component";
import { Console } from "console";
import { M } from "@angular/cdk/keycodes";
import { RegisterVaccineConst } from "src/app/shared/constants/register-const";
import { RatingPriceService, CashierViewer } from '../services/rating-price.service';

export class Result {
  CompleteMode: 'Next' | 'List' | 'Cancel' | 'DontPrint' | 'DontPrintAndNext';
  IsPrintPrescription: boolean; // 列印處方箋1
  IsPrintDetail: boolean;// 明細收據2
  IsPrintWay: boolean;// 服法收據
  IsPrintExam: boolean; //院內檢驗單
  IsPrintDispPrescription: boolean; // 列印交付處方箋5
  IsPrintDispExam: boolean; //交付檢驗單
  IsPrintDrugBag: boolean; // 列印藥袋
  IsPrintMedRecord: boolean; // 列印病歷單
  IsPrintCtrDrugs: boolean;//列印管制藥品處方箋
  //IsPrintCheck:boolean;
}
export class SortAndCode {
  Sort: number;
  Code: string;
}
@Component({
  selector: 'app-rating-price',
  templateUrl: './rating-price.component.html',
  styleUrls: ['./rating-price.component.css'],
})
export class RatingPriceComponent implements OnInit, OnDestroy {
  @ViewChild('pcsxComboBox')
  pcsxComboBox: VisComboboxRealTimeOptionComponent;
  @ViewChild('presButton')
  presButton: ElementRef;

  route: any;
  private histRcd: WholeHist;
  @Input()
  set isPricingOpened(value: any) {
    this.showOpen = value;
  }
  /** 從哪裡開啟此RecordComponent，預設OPD，為了區別從申報進來做得一些特殊流程*/
  @Input() entranceType = "OPD"; //OPD、Declare
  @Input() isSaving = false;
  @Output() closed = new EventEmitter<Result>();
  result: Result = {
    CompleteMode: 'List',
    IsPrintDrugBag: false,
    IsPrintMedRecord: false,
    IsPrintPrescription: false,
    IsPrintExam: false,
    IsPrintDetail: false,
    IsPrintDispExam: false,
    IsPrintDispPrescription: false,
    IsPrintWay: false,
    IsPrintCtrDrugs: false
  }
  @Output() onEmitDisp = new EventEmitter<HistOrder[]>();
  @Output() controlEvent = new EventEmitter<PriceOtherControl>();  // 避免重複計算同療的flag
  @Output() ischangeOwn = new EventEmitter<boolean>();  // 避免重複計算同療的flag
  @Output() onReferralOpen = new EventEmitter();
  @Output() ischangePillDisp = new EventEmitter<boolean>();  // 避免重複計算同療的flag

  ErrorAndWarning = {
    UrgentHC: '',
  }
  showOpen = true;
  caseOpt: ValueTextPair[];
  spcOpt: ValueTextPair[];
  dispOpt: ValueTextPair[];
  labOpt: ValueTextPair[];
  labAddrCodeOpt: ValueTextPair[];
  correctionalInstitution: ValueTextPair[] = [{ value: '9A32011987', text: '桃園監獄' }, { value: '9A32011503', text: '敦品中學' }];
  correctionalInstitutionValue: string;
  labAddrCode: string;
  referralOpt: ValueTextPair[];
  consultationOpt: ValueTextPair[];
  payOpt: ValueTextPair[];
  emerOpt: ValueTextPair[] = [{ value: '1', text: '初複診' }, { value: '2', text: '急診' }];
  /** 居護收案別e */
  paySysAOpt: ValueTextPair[] = [{ value: 'NG', text: '一般居家' }, { value: 'NI', text: '整合照護' }, { value: 'N4', text: '呼吸器照護' }];
  phOpt: ValueTextPairNumberValue[];
  presNumOpt: ValueTextPair[];
  dept: ValueTextPair[];
  medLack02Areas: ValueTextPair[] = [];
  medLack03Areas: ValueTextPair[] = [];
  medLack04Areas: ValueTextPair[] = [];
  medLackAreaData: MedLackArea[] = [];
  defaultDept: string;
  chronicDay: number = 999;
  isMedDiv: boolean = false;
  alertChronicPrescipt = false;
  rxDailyQuota = [];
  isLoading = true;
  supportAreaIsChecked = false;
  specItemword = '';
  checkCodeWord = '';
  isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  isRecoveryHome = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
  isDebugOrAdmin = BaseConfig.isDebug();
  homecareCaseFg = false;
  homeCareList: HCRxCodes[];
  isUrgent = false;
  isUrgentNight = false;
  isUrgentHC = false;
  drugHistOrders: HistOrder[] = [];
  defaultsettingCase: string;
  casechecklist: string[] = [];
  isNotUseCheckCard = false;
  dxsp: string[] = [];
  Special9List = ['P1015', 'P1016'];
  hasDrug = false;
  /** 針劑自調條件 目前洗腎之針劑有申報且無委外藥=>要走自調 */
  hasInjectionSelfDisp = false;
  acuteCaseList = ['04', '08', '24', '28'];
  hasPharmacist = true;
  isUseCTRDrugs = false;
  canprintDisp = false;
  hasExam = false;
  fontGreater: boolean = false;
  isShowRegDialog = false;
  rightListData: PageData;
  isChangingToGether: boolean = true;
  isDisabledLab: boolean = true;
  isNeedRemote: boolean = false;
  pricecontrol = new PriceOtherControl();
  kidneyDialysisDto = new KidneyDialysisDto();
  labsinfo: LabforRatingInfo[] = [];
  JailInfo = new JailInfo();
  /** keep原本傳入的列印參數 */
  hst001Params: HST001Params;
  pcsxOption: ValueTextPair[];
  private editOptionsSubscription: Subscription;
  isShowPCSxAllocationDialog = false;
  presDateMax: Date;
  OutVisitSPTX: string; // 巡診主治

  /** 是否為預保 */
  isPH = false;
  @Input()
  set histRecord(histRcd: WholeHist) {
    this.histRcd = histRcd;
    this.forceFreeDrugPartFee = this.histRcd.Hist.CntPresCl === this.freeDrugPartFeeCode;
    let regDate = new Date(this.histRcd.Register.RegDate);
    this.presDateMax = new Date(regDate.getFullYear(), regDate.getMonth(), regDate.getDate() + 10, 23, 59, 59);
  }
  get histRecord() {
    return this.histRcd;
  }
  @Input()
  set initPrintPara(res: Result) {
    if (res) {
      this.result = res;
    }
  }

  @Input()
  set hst001Param(param: HST001Params) {
    if (param) {
      this.hst001Params = param;
    }
  }
  @Input()
  set isControlValue(val: PriceOtherControl) {
    this.pricecontrol = val;
  }
  get isControlValue() {
    return this.pricecontrol;
  }
  @Input() ischangeOwnByUrSerf: boolean = false;
  @Input() diagVersion: string;
  private fnKeyRegid: number;
  constructor(
    private histApi: HistApi,
    private api: PricingApi,
    private userConfirm: UserConfirmService,
    private dialog: MatDialog,
    private hotKeyService: HotKeyService,
    private paramsService: ParameterApi,
    private hspidApi: HspidApi,
    // private cdr:ChangeDetectorRef,
    private mainLayoutService: MainLayoutService,
    private memoryCache: MemoryCache,
    private sessionService: SessionService,
    private notification: EasyNotificationService,
    private MedLackAreaApi: MedLackAreaApi,
    private fontGreatService: FontGreatService,
    private apiNHIdeclare: NHIDeclareApi,
    private clinicDataService: ClinicDataService,
    private pCSxApiService: PCSxApiService,
    private ratingPriceService: RatingPriceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.fnKeyRegid = this.hotKeyService.regist(Priority.Cashier, 'Fn', (key: FnKey) => {
      switch (key) {
        // 確認
        case 'F2':
          if (!this.isSaving) {
            this.saving(true);
          }
          break;
        // 返回
        case 'F4':
          if (!this.isSaving) {
            this.saving(false);
          }
          break;
        case 'F8':
          this.cancel();
          break;
        case 'F9':
          this.optionRefresh();
          break;
      }
    })
  }

  //#region 主診斷
  async dx1Changed(evt) {
    var input = (evt.target as HTMLInputElement);
    var code = input.value.toUpperCase();

    var dx = await this.histApi.getDiagByCode(code, this.diagVersion);
    this.histRcd.Hist.Dx1Name = dx?.CName || '';
    //setTimeout(() => {
    this.histRcd.Hist.Dx1 = dx?.Code || '';
    this.histRcd.Hist.Dx1Acute = dx?.AcuteNote || 0;
    //}, 0);
    await this.resetSpecItem();
  }
  //#endregion
  async setDxNameAndGetSpecitem() {
    var hist = this.histRcd.Hist;
    // 前四診斷
    var t = [hist.Dx1, hist.Dx2, hist.Dx3, hist.Dx4].filter(v => v);
    this.hasChronicDx = false;
    for (var code of t) {
      //取得主治
      var dx = await this.histApi.getDiagByCode(code, this.diagVersion);
      // 有主治/慢病註記=>有開立慢病診斷
      if (dx?.AcuteNote == 2) {
        this.hasChronicDx = true;
      }

      // 主治中文描述
      var dxName = dx?.CName ?? '';
      dxName = dxName.length > 16 ? dxName.slice(0, 16) + '...' : dxName; // 超過16個字省略
      if (this.illWord != '') {

        this.illWord = this.illWord + '，（' + code + '）' + dxName;
      }
      else {
        this.illWord = '（' + code + '）' + dxName;
      }
      var sp = dx?.SpecItem;
      sp = sp?.trim();
      if (sp && dx?.AcuteNote == 2) {

        this.dxsp.push(sp);

      }
    }
    var t = [hist.Dx5, hist.Dx6].filter(v => v);
    for (var code of t) {
      var dx = await this.histApi.getDiagByCode(code, this.diagVersion);
      var sp = dx?.SpecItem;
      if (sp && dx?.AcuteNote == 2) {
        this.userConfirm.showConfirm({
          'msg': '【診斷5】或【診斷6】有慢性病病名，\n請調整診斷順序，將該診斷往前移動。',
          'title': '提示',
          'hideNo': true,
          'textYes': 'Ok'
        });
      }
      var dxName = dx?.CName ?? '';
      dxName = dxName.length > 16 ? dxName.slice(0, 16) + '...' : dxName; // 超過16個字省略
      if (this.illWord != '') {

        this.illWord = this.illWord + '，（' + code + '）' + dxName;
      }
      else {
        this.illWord = '（' + code + '）' + dxName;
      }
    }
    //}
    this.dxsp = Distinct(this.dxsp);
  }
  //#region 次診斷
  subDx: string;
  subDxAcute: boolean = false;
  setSubDx() {
    var hist = this.histRcd.Hist;
    if (this.isPH) {
      hist.PHCResult = '01';
      hist.Dx2 = null;
      hist.Dx3 = null;
      hist.Dx4 = null;
      hist.Dx5 = null;
      hist.Dx6 = null;
      this.checkCodeWord = '01　正常'
    }
    this.subDxAcute = hist.Dx2Acute == 2 || hist.Dx3Acute == 2 || hist.Dx4Acute == 2 ||
      hist.Dx5Acute == 2 || hist.Dx6Acute == 2;
    // Null轉空值
    var t = [hist.Dx2, hist.Dx3, hist.Dx4, hist.Dx5, hist.Dx6].filter(v => v);
    this.subDx = t.join(',');

    if (this.subDx.length > 0) this.subDx = this.subDx + ',';
  }
  async updateSubDx() {
    // 長度不足項目用空值
    var t = this.subDx.split(',').concat(['', '', '', '', '', '']).slice(0, 6);
    var hist = this.histRcd.Hist;
    for (var i = 2; i <= 6; i++) {
      hist['Dx' + i] = '';
      hist['Dx' + i + 'Name'] = '';
      hist['Dx' + dxIndex + 'Acute'] = null;
    }
    var dxIndex = 2;
    // 更新診斷名稱
    for (var i = 0; i < 6; i++) {
      if (t[i] != '') {
        var diag = await this.histApi.getDiagByCode(t[i].toUpperCase(), this.diagVersion);
        if (diag != null) {
          hist['Dx' + dxIndex] = diag.Code;
          hist['Dx' + dxIndex + 'Name'] = diag.CName;
          hist['Dx' + dxIndex + 'Acute'] = diag.AcuteNote || null;
          dxIndex++;
        }
      }
    }
    this.setSubDx();
    await this.resetSpecItem();
  }
  subDxChanged(evt: Event) {
    this.subDx = (evt.target as HTMLInputElement).value
    this.updateSubDx();
  }
  //#endregion
  pHCResultChanged(evt: Event) {
    let key = (evt.target as HTMLInputElement).value
    switch (key) {
      case '01':
        this.checkCodeWord = '01 正常';
        break;
      case '02':
        this.checkCodeWord = '02 需進一步檢查';
        break;
      case '03':
        this.checkCodeWord = '03 需接受治療';
        break;
      default:
        this.checkCodeWord = '';
        break;
    }
  }
  //#region SpecItem 主治
  illWord = '';
  specItem: string = null;
  hasChronicDx = false;
  setSpecItem() {
    var declare = this.histRcd.Hist;
    // 完診就不改主治
    if (this.histRcd.Register.StatusCode == 'F') {
      var spt = [declare.SPTX1, declare.SPTX2, declare.SPTX3, declare.SPTX4].filter(v => v);
      this.specItemStringFactory(spt);
      return;
    }
    //由主治依序找第一個空位填入
    for (var i = 1; i <= 4; i++) {
      if (!this.isNullOrEmpty(declare['SPTX' + i])) {
        this.dxsp.push(declare['SPTX' + i]);
      }
      declare['SPTX' + i] = '';
    }
    // 加入巡診主治
    if (this.histRcd.Hist.useOutVisit && this.OutVisitSPTX != '') {
      this.dxsp.unshift(this.OutVisitSPTX);
    }
    if (this.histRcd.Register.SameTreat === '0') {
      this.dxsp.unshift('D8');
    }
    // EC For 居家整合計畫 所以它必須滿足  收案別為整合類且案類為E1 A1 A5
    if ((declare.Case == 'E1' || declare.Case == 'A1' || declare.Case == 'A5') && this.histRcd.Patient.PaySysA == 'NI') {
      this.dxsp.unshift('EC');
    }

    if (this.pricecontrol.ishouseCare) {
      this.dxsp.unshift('G8');
    }
    this.dxsp = Distinct(this.dxsp);
    if (this.dxsp.length > 0) {
      for (var i = 0; i < this.dxsp.length; i++) {
        declare['SPTX' + (i + 1)] = this.dxsp[i];
      }
    }
    // Null轉空值
    var spt = [declare.SPTX1, declare.SPTX2, declare.SPTX3, declare.SPTX4].filter(v => v);

    setTimeout(() => {
      this.specItemStringFactory(spt);
    }, 1);
  }

  async resetSpecItem() {
    this.illWord = '';
    var declare = this.histRcd.Hist;
    //由主治依序找第一個空位填入
    for (var i = 1; i <= 4; i++) {
      declare['SPTX' + i] = '';
    }

    // if (this.specItem == null) {
    var hist = this.histRcd.Hist;
    // 前四診斷
    var t = [hist.Dx1, hist.Dx2, hist.Dx3, hist.Dx4].filter(v => v);
    this.hasChronicDx = false;
    this.dxsp = [];
    for (var code of t) {
      //取得主治
      var dx = await this.histApi.getDiagByCode(code, this.diagVersion);
      // 有主治/慢病註記=>有開立慢病診斷

      if (dx?.AcuteNote == 2) {
        this.hasChronicDx = true;
      }
      // if (this.hasChronicDx && this.chronicDay) {
      //   this.allowChronicPres = true;
      // }
      // 主治中文描述
      var dxName = dx?.CName ?? '';
      dxName = dxName.length > 16 ? dxName.slice(0, 16) + '...' : dxName; // 超過16個字省略
      if (this.illWord != '') {

        this.illWord = this.illWord + '，（' + code + '）' + dxName;
      }
      else {
        this.illWord = '（' + code + '）' + dxName;
      }
      var sp = dx?.SpecItem;
      sp = sp?.trim();
      if (sp && dx?.AcuteNote == 2) {
        this.dxsp.push(sp);
      }
    }
    var t = [hist.Dx5, hist.Dx6].filter(v => v);
    for (var code of t) {
      var dx = await this.histApi.getDiagByCode(code, this.diagVersion);
      var sp = dx?.SpecItem;
      if (sp && dx?.AcuteNote == 2) {
        this.userConfirm.showConfirm({
          'msg': '【診斷5】或【診斷6】有慢性病病名，\n請調整診斷順序，將該診斷往前移動。',
          'title': '提示',
          'hideNo': true,
          'textYes': 'Ok'
        });
      }
      var dxName = dx?.CName ?? '';
      dxName = dxName.length > 16 ? dxName.slice(0, 16) + '...' : dxName; // 超過16個字省略
      if (this.illWord != '') {

        this.illWord = this.illWord + '，（' + code + '）' + dxName;
      } else {
        this.illWord = '（' + code + '）' + dxName;
      }
    }
    // }
    this.dxsp = Distinct(this.dxsp);

    // EC For 居家整合計畫 所以它必須滿足  收案別為整合類且案類為E1 A1 A5
    if ((declare.Case == 'E1' || declare.Case == 'A1' || declare.Case == 'A5') && this.histRcd.Patient.PaySysA == 'NI') {
      this.dxsp.unshift('EC');
    }
    if (this.dxsp.length > 0) {
      for (var i = 0; i < this.dxsp.length; i++) {
        if (declare['SPTX' + (i + 1)] == '') {
          declare['SPTX' + (i + 1)] = this.dxsp[i];
        }
      }
    }
    // Null轉空值
    var spt = [declare.SPTX1, declare.SPTX2, declare.SPTX3, declare.SPTX4].filter(v => v);
    this.specItemStringFactory(spt);
  }
  getTitle(histRecord: WholeHist) {
    var feedback = this.histRecord.Patient.CName;
    let IcodeName = ' ';
    switch (this.histRcd.Register.ICode) {
      case '000':
        IcodeName = ' 自費診 ';
        break;
      case 'A':
        IcodeName = ' 健保診 ';
        break;
      case '001':
        IcodeName = ' 重傷病 ';
        break;
      case '003':
        IcodeName = ' 福保 ';
        break;
      case '006':
        IcodeName = ' 職業病 ';
        break;
      case '004':
        IcodeName = ' 榮民 ';
        break;
      default:
        break;
    }
    var cardserialNum = '(' + (this.histRecord.Register.Icno || '') + ')';
    if (this.histRecord.Register.ReCardDate == null && this.histRcd.Register.IType === '3') cardserialNum = ' 欠卡 ';
    let fee = '';
    if (this.currentCashier?.External > 0 || this.currentCashier?.Oral > 0 || this.currentCashier?.Injection > 0) {
      let feeNum = this.currentCashier?.External + this.currentCashier?.Oral + this.currentCashier?.Injection;
      fee = '(藥費+診療費：' + feeNum.toString() + ')';
      if (this.histRecord.Hist.DispTP == DispensingTypeEnum.OutOfClinic) fee = fee + '藥局藥費：' + feeNum.toString()
        + (this.currentCashier?.PDrugPart > 0 ? ' 藥品部分負擔' + this.currentCashier?.PDrugPart.toString() : '');
    }
    feedback = feedback + IcodeName + cardserialNum + '➔批價確認 ' + fee + this.getCntPresTitle();
    return feedback;
  }
  async updateSpecItem() {
    // 長度不足項目用空值
    var t = ['', '', '', ''];
    if (this.specItem) t = this.specItem.split(',').concat(['', '', '', '']);
    let spec = Distinct(t);
    var declare = this.histRcd.Hist;
    for (var i = 0; i < spec.length; i++) {
      var word = spec[i].trim();
      spec[i] = word;
      if (spec[i] == '') {
        spec.splice(i, 1);
      }
    }

    declare.SPTX1 = spec[0];
    declare.SPTX2 = spec[1];
    declare.SPTX3 = spec[2];
    declare.SPTX4 = spec[3];
    setTimeout(() => {
      this.specItem = spec.join(',');
      if (this.specItem.length > 0 && !this.specItem.endsWith(',')) this.specItem += ',';

    }, 0);
    var specItemword = [declare.SPTX1, declare.SPTX2, declare.SPTX3, declare.SPTX4].filter(v => v);
    this.specItemStringFactory(specItemword);
  }

  async SpecItemChanged(evt: MatOption) {
    let val = evt?.value || evt;
    this.specItem = val;
    this.updateSpecItem();
  }

  isInJail = false;
  /** 主治描述資料處理*/
  specItemStringFactory(val: string[]) {
    let textArr: string[] = [];
    let valueArr: string[] = [];
    let isinjail = false;
    for (var i = 0; i <= val.length; i++) {
      let x = this.spcOpt.find(d => d.value?.toLowerCase() == val[i]?.toLowerCase());
      if (x && valueArr.length < 4 && !valueArr.includes(x?.value)) {
        console.log('x',x);
        textArr.push(x?.text);
        valueArr.push(x?.value);
        if (x?.value.toLowerCase() == 'ja' || x.value.toLowerCase() == ('jb')) isinjail = true;
      }
    }
    textArr.sort((value1, value2) => {
      const sort1 = this.spcOpt.find(opt => opt.text === value1)?.extension ?? 99;
      const sort2 = this.spcOpt.find(opt => opt.text === value2)?.extension ?? 99;
      return sort1 - sort2;
    });
    valueArr.sort((value1, value2) => {
      const sort1 = this.spcOpt.find(opt => opt.value === value1)?.extension ?? 99;
      const sort2 = this.spcOpt.find(opt => opt.value === value2)?.extension ?? 99;
      return sort1 - sort2;
    });
    this.isInJail = isinjail;
    this.specItemword = textArr.join('，');
    let spec = valueArr.join(',');
    if (spec.length > 0) spec += ',';
    setTimeout(() => {
      this.specItem = spec;
    }, 1);
  }
  //#endregion

  //#region Sur手術
  pcsxSelectedOptions: ValueTextPair[] = [];
  sur: string;
  /** set預保時程代碼*/
  async setSurOrHealthCare() {
    var hist = this.histRcd.Hist;
    if (this.isPH) {
      var code = await this.api.GetPriceingPHcsWord(hist.Dx1);
      this.histRecord.Hist.PHCScCode = code;
    }

    // Null轉空值
    var t = [hist.Sur1, hist.Sur2, hist.Sur3].filter(v => v);

    const selectOpt = this.pcsxOption.filter(x => t.includes(x.value));
    this.surChanged(selectOpt);
  }

  updateSur() {
    // 長度不足項目用空值
    var surs = this.splitSur();
    var hist = this.histRcd.Hist;
    hist.Sur1 = surs[0];
    hist.Sur2 = surs[1];
    hist.Sur3 = surs[2];

    if (surs.length > 3) {
      this.sur = `${hist.Sur1},${hist.Sur2},${hist.Sur3}`
    }
  }

  splitSur(): string[] {
    return this.sur.split(',').concat(['', '']);
  }

  surChanged(options: ValueTextPair[]) {
    this.pcsxSelectedOptions = options;
    this.sur = options.map(x => x.value).join(",");
    this.updateSur();
  }

  get surDescription(): string {
    return this.generateSurDescription();
  }

  get surDescriptionCut(): string {
    let desc = this.generateSurDescription();
    return desc.substring(0, 40) + (desc.length > 40 ? "..." : "");

  }

  private generateSurDescription() {
    let surs = this.splitSur();
    let resultArray: string[] = [];
    // 遍歷 surs 並找出 pcsxOption 中對應的項目
    surs.forEach(sur => {
      let matchedItem = this.pcsxOption.find(option => option.value === sur);
      if (matchedItem) {
        resultArray.push(`(${matchedItem.value}) ${matchedItem.text}`);
      }
    });

    // 用逗號和空格將結果陣列連接成字串
    return resultArray.join(', ');
  }
  //#endregion

  //#region 案類
  /** 符合慢性病條件 */
  allowChronicPres = false
  setCase() {
    var declare = this.histRcd.Hist;

    if (this.isHomeCare || this.isRecoveryHome) {
      if (this.isRecoveryHome) {    // 康家的 PaySysA 預設 L，居護直接取基資的PaySysA設定
        this.defaultCase = 'A2';
        this.histRcd.Hist.Case = 'A2';
        this.histRcd.Patient.PaySysA = 'L';
      }
    } else {    // 一般診所的 PaySysA 預設 D
      this.histRcd.Patient.PaySysA = 'D';
    }

    //居護收案別
    if (this.histRcd.Hist.PaySysA == undefined || this.histRcd.Hist.PaySysA == null || this.histRcd.Hist.PaySysA == '') {
      this.histRcd.Hist.PaySysA = this.histRcd.Patient.PaySysA;
    }
    //預防保健
    if (this.isPH) {
      this.defaultCase = 'A3';
      this.histRcd.Hist.Case = 'A3'
      return;
    }
    if(RegisterVaccineConst.vaccineCodes.indexOf(this.histRcd.Register.Icno)  >= 0){
      this.defaultCase = 'D2';
      this.histRcd.Hist.Case = 'D2'
      return;
    }
    if (this.histRcd.Register.Icno == 'IC07') {
      this.defaultCase = 'B7';
      this.histRcd.Hist.Case = 'B7'
      return;
    }

    var orders = this.histRcd.Hist.HRxs;
    var checkList = [OrderTypeEnum.T2_OralDrugFee, OrderTypeEnum.T3_ExtDrugFee, OrderTypeEnum.T4_InjectionDrugFee]
    // this.drugHistOrders =  this.histRcd.Hist.HRxs.filter(x => checkList.some(y =>y ==x.RxType))
    // Check 01 (預設), 開藥<=3

    var moreThen3 = false;
    var isChronic = false;
    var hasSur = false;
    var hasOther = false;
    this.hasDrug = false;
    this.defaultCase = this.defaultsettingCase;

    var NormalDoctorFg = false;  // 一般照護醫師之fg
    var PeacefulDoctorFg = false; // 安寧照護醫師之fg
    var NuringFg = false; // 一般照護護理師之fg
    var PeacefulNuringFg = false;  // 安寧照護護理師之fg
    var otherHCFg = false; // 其他相關之fg
    if (this.homeCareList.some(item => item.PositionGroup === 1 && item.ClinicType == null)) NormalDoctorFg = true;
    if (this.homeCareList.some(item => item.PositionGroup === 1 && item.ClinicType != null)) PeacefulDoctorFg = true;
    if (this.homeCareList.some(item => item.PositionGroup === 2 && item.ClinicType == null)) NuringFg = true;
    if (this.homeCareList.some(item => item.PositionGroup === 2 && item.ClinicType != null)) PeacefulNuringFg = true;
    if (this.homeCareList.length > 0 && !NormalDoctorFg && !PeacefulDoctorFg && !NuringFg && !PeacefulNuringFg) otherHCFg = true;

    for (var o of orders) {

      // 不再上面那幾種裡面
      if (checkList.some(t => t == o.RxType)) {
        this.hasDrug = true;
        if (o.Days > 3 && ![2, 3, 4].some(x => x == o.SPRule)) {
          moreThen3 = true;
        }
        // 有慢病診斷時才判斷開藥
        if (this.hasChronicDx && o.Days >= this.chronicDay) {
          isChronic = true;  //慢籤條件 ok
        }
      } else {
        hasOther = true;
      }
      if (o.RxType == OrderTypeEnum.T10_SurgeryFee) {
        hasSur = true;
      }
    }

    let A1Fg = NuringFg || NormalDoctorFg || PeacefulDoctorFg || PeacefulDoctorFg || otherHCFg
    let SpFg = A1Fg || this.isRecoveryHome;

    let E1A5FG = false;
    if ((PeacefulDoctorFg || PeacefulNuringFg) && this.homecareCaseFg && this.histRcd.Hist.IsEdit != '1') {
      this.defaultCase = 'A5';
      E1A5FG = true;
      this.histRcd.Hist.Case = this.defaultCase;

    }
    // E1 為居護整合計畫內的案類，NI且沒有護理師費用歸屬此類
    if ((NormalDoctorFg || PeacefulDoctorFg) && !PeacefulNuringFg && !NuringFg && this.histRcd.Patient.PaySysA == 'NI' && this.histRcd.Hist.IsEdit != '1') {
      this.defaultCase = 'E1';
      E1A5FG = true;
      this.histRcd.Hist.Case = this.defaultCase;
    }
    // A1作為最基礎的居護
    if (A1Fg && this.homecareCaseFg && !E1A5FG && this.histRcd.Hist.IsEdit != '1') {
      this.defaultCase = 'A1';
      this.histRcd.Hist.Case = this.defaultCase;
    }
    // 開藥超過三日 排除01
    if (moreThen3 || !this.hasDrug) {
      this.caseOpt = this.caseOpt.filter(c => c.value != '01');
    }

    // if(declare.Case){
    //   return;
    // }
    // 職災 006
    if (this.histRcd.Register.SameTreat == '0') {
      this.defaultCase = '05';
    } else if (this.histRcd.Register.ICode == '006') {
      this.defaultCase = 'B6';
    } else {
      // Check 02, 急診
      if (declare.Emer == '2' && !SpFg) {
        this.defaultCase = '02';
      } else {
        // Check 03, 手術 SurgeryFee
        if (hasSur && !SpFg) {
          this.defaultCase = '03';
        } else {
          // Check 04, 開藥>=ChronicDays日
          if (this.hasChronicDx && !SpFg) {
            this.defaultCase = '04';
            if (this.histRcd.Register.SameTreat == '8') this.defaultCase = '08';
          } else {
            // Check defaultCase, 含非內服,外用,注射 西醫預設09 居護預設A1 康復預設A2
            if ((!this.hasDrug || hasOther) && !SpFg) {
              this.defaultCase = this.defaultsettingCase;
            } else {
              if (!moreThen3 && !SpFg) {
                this.defaultCase = '01';
              } else {
                // Check 09, 有藥類
                if (moreThen3 && this.hasDrug && !SpFg) {
                  this.defaultCase = '09';
                }
              }
            }
          }
        }
      }
    }
    this.casechecklist = this.caseOpt.map(x => x.value);

    // 為鎖定改為預設Case
    if (this.histRcd.Hist.IsEdit == '1') {
      // 鎖定不動
    } else {
      if (!this.casechecklist.some(x => x == this.defaultCase)) {
        this.defaultCase = this.defaultsettingCase;
      }
      this.histRcd.Hist.Case = this.defaultCase;
    }

    if ((this.defaultCase == '04' || this.defaultCase == '08') && isChronic && (this.histRecord.Hist.DispTP == DispensingTypeEnum.OutOfClinic || this.histRecord.Hist.DispTP == DispensingTypeEnum.Clinic)) {
      this.allowChronicPres = true;
    }
  }

  async caseChagne() {
    this.resetSpecItem();
    await this.getPreview(true);
    await this.setPres();
  }
  //#endregion
  async supportAreaChange(evt: any) {
    this.supportAreaIsChecked = evt.checked;
    this.histRcd.Hist.SpecAreaSV = '04';
    if (this.supportAreaIsChecked) {

    } else {
      if (!this.isNullOrEmpty(this.histRcd.Hist.SupArea)) this.notification.showWarning('您填的山地離區域代碼已被清除')
      this.histRcd.Hist.SpecAreaSV = '';
      this.histRcd.Hist.SupArea = '';
      await this.getPreview(true);
    }
  }

  async initsupportArea() {
    this.medLackAreaData = await this.MedLackAreaApi.GetAll('ALL');
    this.medLackAreaData.forEach(x => {
      var item: ValueTextPair = {
        text: x.Zipcode + " | " + x.Area,
        value: x.Zipcode,
      };
      if (x.Data_type == 'EC') this.medLack04Areas.push(item);
      else if (x.Data_type == 'BC') this.medLack03Areas.push(item);
      else if (x.Data_type == '') this.medLack02Areas.push(item);

    });
  }
  /** 巡迴看診*/
  async itinerantConsultationChange(matOp: MatOption) {
    let searchWord = 'None';
    let value = matOp?.value ?? '';
    let isNeedPushArea = false;
    this.histRcd.Hist.SupArea = '';
    switch (value) {
      case '01':  //巡診
        searchWord = 'None';
        break;
      case '02':
        searchWord = '';
        // isNeedPushArea = this.medLack02Areas.length < 1  ? true  :false;
        break;
      case '03':
        searchWord = 'BC';
        // isNeedPushArea = this.medLack03Areas.length < 1  ? true  :false;
        break;
      case '04':
        searchWord = 'EC';
        // isNeedPushArea = this.medLack04Areas.length < 1  ? true  :false;
        break;
      default:
        searchWord = 'None';
        break;
    }
    if (searchWord != 'None') {
      this.supportAreaIsChecked = true;
      // if( isNeedPushArea){
      //   this.medLackAreaData = await this.MedLackAreaApi.GetAll(searchWord);
      // this.medLackAreaData.forEach(x => {
      //   var item: ValueTextPair = {
      //     text: x.Zipcode + " | " + x.Area,
      //     value: x.Zipcode,
      //   };
      //   if(value == '02') this.medLack02Areas.push(item);
      //   else if(value == '03') this.medLack03Areas.push(item);
      //   else if(value == '04') this.medLack04Areas.push(item);
      // });
      // }
    }
    else {
      this.supportAreaIsChecked = false
    }
    this.pricecontrol.isEditSupArea = true;
    await this.getPreview(true);
  }
  async supAreaChange(value: string) {
    this.pricecontrol.isEditSupArea = true;
    await this.getPreview(true);
  }
  labCheckBoxChange(event: MatCheckboxChange) {
    this.isChangingToGether = event.checked;
  }

  async labAddrCodeChange(matOp: MatOption, dataItem: any) {
    if (!this.isChangingToGether) {
      dataItem.TransOri = matOp.value
      this.histRcd.Hist.HRxs[dataItem.SortNo].TransOri = matOp.value

      return;
    }

    for (var i = 0; i < this.histRcd.Hist.HRxs.length; i++) {
      this.histRcd.Hist.HRxs[i].TransOri = matOp.value;
    }

    this.refreshLabList();
  }
  //#region 日份
  /** set 日份 有用藥才影響*/
  setDays() {
    var checkList = [Number(OrderTypeEnum.T2_OralDrugFee), Number(OrderTypeEnum.T3_ExtDrugFee), Number(OrderTypeEnum.T4_InjectionDrugFee)];
    var checkSPRuleList = [2, 3, 4];

    var getDays = this.histRcd.Hist.HRxs.filter(x => checkList.some(y => y == x.RxType) && !checkSPRuleList.some(y => y == x.SPRule));
    var maxDayOral = 0;
    var maxDayOther = 0;
    if (getDays.length > 0) {
      getDays.forEach(o => {
        let days = !isNaN(Number(o.Days)) ? Number(o.Days) : 0;

        if (o.RxType == OrderTypeEnum.T2_OralDrugFee) {
          if (maxDayOral < days) {
            maxDayOral = days;
          }
        } else if (o.RxType == OrderTypeEnum.T3_ExtDrugFee || o.RxType == OrderTypeEnum.T4_InjectionDrugFee) {
          if (maxDayOther < days) {
            maxDayOther = days;
          }
        }
      })

    }
    this.histRcd.Hist.Days = maxDayOral > 0 ? maxDayOral : maxDayOther;

  }
  dayChange(evt: Event) {
    var days = (evt.target as HTMLInputElement).value;
    this.histRcd.Hist.Days = parseInt(days);
    this.histRcd.Hist.Days = parseInt(days);
  }
  //#endregion

  //#region 調劑 DispTP
  isDispDisabled = false;
  setDisp() {
    // 完診則調劑方式不變
    // if (this.histRecord.Register.StatusCode == RegisterConst.Code_Status_Finish) {
    //   // 鎖定不動
    //   if(this.histRcd.Hist.HRxs.some(x => [2, 3, 4].indexOf(x.RxType) >= 0))   this.isDispDisabled = true;
    //   return;
    // }
    // 有無藥師
    this.hasPharmacist = this.phOpt.length > 0;
    // 有無藥品醫令
    this.hasDrug = false;
    // 有無交付醫令
    var hasOutClinic = false;
    var sencondAdPh = false;
    const hrx = Object.assign([], this.histRcd.Hist.HRxs);


    for (var o of hrx) {
      if ([2, 3, 6, 7].indexOf(o.RxType) >= 0) {
        // if (o.RxType !== 6) this.hasDrug = true;
        let spRule = this.histRcd.Register.ICode == '000' ? true : (o.SPRule < 2 || o.SPRule == 5);
        if (o.RxType == 6 || o.RxType == 7) {
          if (['23', '24', '26', '28'].indexOf(o.Rx.StdCode) >= 0) sencondAdPh = true;
          if (!sencondAdPh) this.hasExam = true;
        }
        if ((o.RxType !== 6 && o.RxType !== 7) && o.DispTP == OrderDispensingTypeEnum.T1_OutOfClinic && spRule) {
          this.hasDrug = true;
          hasOutClinic = true;
          this.isDispDisabled = true;
        } else if ((o.RxType !== 6 && o.RxType !== 7) && o.DispTP == OrderDispensingTypeEnum.T0_Clinic && spRule) {
          this.hasDrug = true;
          // hasSelfDisp = true;
        }
      }
      else if (o.RxType == '4') {
        if (o.DispTP == DispensingTypeEnum.OutOfClinic) hasOutClinic = true;
      }
    }

    // 有開藥
    if (this.hasDrug) {
      // 有任一交付 則調劑方式為交付
      if (this.histRcd.Hist.PharmacistId == 0 && this.hasDrug) hasOutClinic = true;
      if (hasOutClinic) {
        this.histRcd.Hist.DispTP = DispensingTypeEnum.OutOfClinic;
        // setTimeout(() => {
        this.ischangePillDisp.emit(true);
        // }, 2500);
        // 調劑方式為交付下則不能送藥師
        this.histRcd.Hist.PharmacistId = 0;
      }
      // 無交付下才為自調
      else {
        this.histRcd.Hist.DispTP = DispensingTypeEnum.Clinic;
      }
    }
    // 未開藥
    else if (!this.hasDrug) {
      if (this.hasInjectionSelfDisp) this.histRcd.Hist.DispTP = DispensingTypeEnum.Clinic;
      else if (hasOutClinic) this.histRcd.Hist.DispTP = DispensingTypeEnum.OutOfClinic;
      else this.histRcd.Hist.DispTP = DispensingTypeEnum.NoNeed;
      this.histRcd.Hist.PharmacistId = 0;
    }
  }
  async dispChange(value: MatOption) {
    if (this.histRcd.Hist.DispTP == DispensingTypeEnum.OutOfClinic || this.histRcd.Hist.DispTP == DispensingTypeEnum.NoNeed) {
      // 調劑方式為交付下則不能送藥師
      this.histRcd.Hist.PharmacistId = 0;
    } else {
      if (this.histRcd.Hist.PharmacistId == 0) {
        this.revertPharm();
      }
    }
    if (this.histRcd.Hist.DispTP != DispensingTypeEnum.OutOfClinic && this.histRcd.Hist.DispTP != DispensingTypeEnum.Clinic) {
      this.allowChronicPres = false;
    }
    else if (this.acuteCaseList.some(x => x == this.histRecord.Hist.Case) && (this.histRecord.Hist.DispTP == DispensingTypeEnum.OutOfClinic || this.histRecord.Hist.DispTP == DispensingTypeEnum.Clinic) && this.hasChronicDx && this.histRcd.Hist.Days >= this.chronicDay) {
      this.allowChronicPres = true;
    }
    this.cntChange();

    // await this.changeRxDisp(this.histRcd.Hist.DispTP);  // 次方法為即時更改醫令區調劑，暫不使用
    await this.getPreview(true);
  }
  // async changeRxDisp(value: string) {
  //   let v: DispensingTypeEnum;
  //   switch (value) {
  //     case '0':
  //       v = DispensingTypeEnum.Clinic;
  //       break;
  //     case '1':
  //       v = DispensingTypeEnum.OutOfClinic;
  //       break;
  //     case '2':
  //       v = DispensingTypeEnum.NoNeed;
  //       break;

  //   }
  //   for (var o of this.histRcd.Hist.HRxs) {
  //     if ([2, 3, 4].indexOf(o.RxType) >= 0) {

  //       o.DispTP = v;
  //     }
  //   }
  //   this.onEmitDisp.emit(this.histRcd.Hist.HRxs);
  //   this.histRcd.Hist.DispTP
  // }
  //#endregion

  //#region 科別 Dept
  setDept() {
    if (!this.histRecord.Hist.Dept) {
      this.defaultDept = this.histRcd.Register.MedDeptCode;
      this.histRecord.Hist.Dept = this.defaultDept;
    }
    else if (this.histRecord.Hist.Dept && this.histRecord.Register.StatusCode == 'F') {
      this.defaultDept = this.histRecord.Hist.Dept;
    }
    if (this.homeCareList.some(x => (x.PositionGroup === 6 && x.Rxcode.startsWith('P')) || this.Special9List.some(y => y === x.Rxcode))) {
      this.histRecord.Hist.Dept = 'AC';
      return;
    }
    else if (this.homeCareList.some(x => x.PaySysA == 'NG' || x.PaySysA == 'NI' || x.PaySysA == 'N4')) { //(this.homeCareList.length > 0) {
      this.histRecord.Hist.Dept = 'EA';
      return;
    }
    else {
      if (!this.histRecord.Hist.Dept) this.histRecord.Hist.Dept = this.defaultDept;
    }

  }
  /** set 設置同療次數 目前有用於 1.復健療程 2.一般月療 3.慢籤*/
  async setCus() {
    if (this.pricecontrol.isCusValue) return;
    let outputArray: string[] = [];

    let sameTreat = this.histRecord.Register.SameTreat;
    let oldCusfg = false;

    var odr = this.histRcd.Hist.HRxs;
    let cusNoFinish = '';
    let cusNo = this.histRcd.Hist.CusNo || '';
    let presNoFinish = '';
    let presNo = this.histRcd.Hist.CntPresNo || '';

    // 復健(第二次) && 月療
    if (sameTreat == '1' || sameTreat == '4' || sameTreat == '0' || sameTreat == '8' || sameTreat == '3') {
      var isSpecial = sameTreat == '0' ? !this.histRcd.Patient.FirstDialysisDate : (this.isRecoveryHome || this.isHomeCare);
      var rxt = await this.api.GetCourseTreatmentData(this.histRecord.Patient.Id,
        this.histRcd.Register.Icno, this.histRcd.Register.RegDate, sameTreat, this.histRcd.Hist?.Id, isSpecial);
      if (rxt.HasCourse) oldCusfg = true;

      if (this.isRecoveryHome && rxt?.Id == 0 && rxt.HasCourse) this.notification.showWarning('已重新調整同療次別')
      if (sameTreat != '8') {
        cusNo = rxt?.CusNo || '';
        cusNoFinish = String(Number(cusNo));
        cusNo = String(Number(cusNo) + 1);

        this.histRecord.Hist.CusNo = this.histRecord.Register.StatusCode == 'F' ? cusNoFinish : cusNo;
        this.histRecord.Hist.CusCl = rxt?.CusCl || '';
        if (!rxt?.CusNo && this.histRcd.Register.Icno == '') this.histRecord.Hist.CusNo = '1';
        if (isSpecial && sameTreat == '0') this.histRcd.Patient.FirstDialysisDate = rxt.FirstDialysisDate;
      }
      if (rxt.HasCourse && sameTreat == '8') {
        presNoFinish = String(Number(rxt.CntPresNo));
        presNo = String(Number(rxt.CntPresNo) + 1);
        this.histRecord.Hist.CntPresNo = this.histRecord.Register.StatusCode == 'F' ? presNoFinish : presNo;
        this.histRecord.Hist.CntPresTot = rxt.CntPresTot;
        this.histRecord.RatingPriceOriginPresNo = this.histRecord.Hist.CntPresNo;
        this.histRecord.Hist.DateStart = rxt.DateStart;
      }
    }
    let reg = this.histRecord.Register;
    let regCtp = reg.Nhictp === '01' || reg.Nhictp === ''
    if (reg.SameTreat == '' && regCtp && (this.isHomeCare || this.isRecoveryHome)) {
      this.histRecord.Hist.CusNo = '1';
      cusNo = this.histRecord.Hist.CusNo;
      this.histRecord.Register.SameTreat = '4';
      sameTreat = this.histRecord.Register.SameTreat;
    }
    // if (reg.Nhictp.startsWith("0") && sameTreat == '8')this.histRecord.Hist.DateStart = reg.RegDate;
    if (sameTreat == '1') this.histRecord.Hist.CusTot = '6';
    if (sameTreat == '4' || sameTreat == '0') this.histRecord.Hist.CusTot = 'Z';
    if (sameTreat == '3') this.histRecord.Hist.CusTot = '2';
    // if (sameTreat == '8')this.histRecord.Hist = '2';

    if (this.histRecord.Hist.CusCl) {
      const regex = /\[(.*?)\]/g;
      const matches = this.histRecord.Hist.CusCl.match(regex);
      if (matches) outputArray = matches.map(match => match.slice(1, -1));
    }
    let newCusClArr: string[] = [];

    if (((this.isHomeCare && this.homecareCaseFg) || this.isRecoveryHome) && sameTreat == '4') {
      let hcStr = this.getCusClCourseString(outputArray, 'HC', this.histRecord.Register.StatusCode, this.histRecord.Hist.CusNo)
      newCusClArr.push(hcStr);
    }
    else if (sameTreat == '0') {
      let kdStr = this.getCusClCourseString(outputArray, 'KD', this.histRecord.Register.StatusCode, this.histRecord.Hist.CusNo)
      newCusClArr.push(kdStr);
    }
    let isPTCus = false;
    let isOTCus = false;
    let isSTCus = false;
    let isPRTCus = false;
    let InitializeCus = 0;

    for (var o of odr) {
      //第一次即使不輸入同療 也會透過醫令判斷是否為復健療程，若無抓到復健(第二次)，則通常視為第一次療程
      if (!oldCusfg && (o.RxCode.startsWith('420') || o.RxCode.startsWith('430') || o.RxCode.startsWith('440') || o.RxCode.startsWith('445'))) {
        this.histRecord.Hist.CusTP = '1';
        this.histRecord.Register.SameTreat = '1';
        this.histRecord.Hist.CusNo = '1';
      }
      else if (oldCusfg && (o.RxCode.startsWith('420') || o.RxCode.startsWith('430') || o.RxCode.startsWith('440') || o.RxCode.startsWith('445'))) {
        this.histRecord.Hist.CusTP = '1';
        this.histRecord.Register.SameTreat = '1';
        this.histRecord.Hist.CusTot = rxt?.CusTot || '6';
        this.histRecord.Hist.CusNo = this.histRecord.Register.StatusCode == 'F' ? cusNoFinish : cusNo;
      }

      if (o.RxCode.startsWith('420') && !isPTCus && !oldCusfg) {
        let rpStr = this.getCusClCourseString(outputArray, 'RP', this.histRecord.Register.StatusCode, this.histRecord.Hist.CusNo)
        newCusClArr.push(rpStr);
        isPTCus = true;
        InitializeCus += 6;
      }
      if (o.RxCode.startsWith('430') && !isOTCus && !oldCusfg) {
        let roStr = this.getCusClCourseString(outputArray, 'RO', this.histRecord.Register.StatusCode, this.histRecord.Hist.CusNo)
        newCusClArr.push(roStr);
        isOTCus = true;
        InitializeCus += 6;
      }
      if (o.RxCode.startsWith('440') && !isSTCus && !oldCusfg) {
        let rsStr = this.getCusClCourseString(outputArray, 'RS', this.histRecord.Register.StatusCode, this.histRecord.Hist.CusNo)
        newCusClArr.push(rsStr);
        isSTCus = true;
        InitializeCus += 6;
      }
      if (o.RxCode.startsWith('445') && !isPRTCus && !oldCusfg) {
        let rsStr = this.getCusClCourseString(outputArray, 'RPR', this.histRecord.Register.StatusCode, this.histRecord.Hist.CusNo)
        newCusClArr.push(rsStr);
        isPRTCus = true;
        InitializeCus += 6;
      }
      if (o.RxCode.startsWith('480')) {
        let rsStr = this.getCusClCourseString(outputArray, 'TR', this.histRecord.Register.StatusCode, this.histRecord.Hist.CusNo)
        newCusClArr.push(rsStr);
      }
    }
    if ((isPTCus || isOTCus || isSTCus || isPRTCus) && InitializeCus > 0) this.histRecord.Hist.CusTot = InitializeCus.toString();
    let formattedItems = newCusClArr.map(item => `[${item}]`);
    if (formattedItems.length > 0) {
      this.histRecord.Hist.CusCl = formattedItems.join('');
      if (formattedItems.some(x => x.startsWith('[TR') && formattedItems.length < 2)) {
        this.histRecord.Hist.CusTP = '3';
        if (this.histRecord.Register.SameTreat != '3'){
          this.histRecord.Register.SameTreat = '3';
          this.histRecord.Hist.CusNo = '1';
          this.histRecord.Hist.CusTot = '2';
        }

      }
    }
    this.pricecontrol.isCusValue = true;
  }
  /** set 設置慢籤 初始載入跟其他慢籤方法都會用到*/
  async setPres() {
    let hasChronicDx = this.acuteCaseList.some(x => x == this.histRecord.Hist.Case) && this.histRcd.Hist.IsEdit == '1' ? true : this.hasChronicDx;
    if (this.acuteCaseList.some(x => x == this.histRecord.Hist.Case) && (this.histRecord.Hist.DispTP == DispensingTypeEnum.OutOfClinic || this.histRecord.Hist.DispTP == DispensingTypeEnum.Clinic) && hasChronicDx && this.histRcd.Hist.Days >= this.chronicDay) this.allowChronicPres = true;
    else this.allowChronicPres = false;

    let outputArray: string[] = [];
    if (this.histRecord.Hist.CusCl) {
      const regex = /\[(.*?)\]/g;
      const matches = this.histRecord.Hist.CusCl.match(regex);
      if (matches) outputArray = matches.map(match => match.slice(1, -1));
    }
    if (!outputArray.some(x => x.startsWith('08')) && (this.histRecord.Hist.Case == '04' || this.histRecord.Hist.Case == '08')) {
      let rsStr = this.getCusClCourseString(outputArray, '08', this.histRecord.Register.StatusCode, this.histRecord.Hist.CntPresNo || '1')
      outputArray.push(rsStr);
    }
    else if (outputArray.some(x => x.startsWith('08')) && this.histRecord.Hist.CntPresTot && this.allowChronicPres == true) {
      let rsStr = this.getCusClCourseString(outputArray, '08', this.histRecord.Register.StatusCode, this.histRecord.Hist.CntPresNo)
      outputArray = outputArray.filter(x => !x.startsWith('08')); //有的話先拿掉 組裝完再回塞
      outputArray.push(rsStr);
    }
    let formattedItems = outputArray.map(item => `[${item}]`);
    if (formattedItems.length > 0) {
      this.histRecord.Hist.CusCl = formattedItems.join('');
    }
  }
  async setPresAndCount() {
    await this.setPres();
    await this.getPreview(true);
  }
  async deptChange(value: string) {
    await this.getPreview(true);
  }
  //#endregion

  //#region
  /** set 來源 Emer (是否急診)*/
  setEmer() {
    if (this.isPH) {
      this.histRecord.Hist.Emer = '1';
      return;
    }
    if (!this.histRecord.Hist.Emer) {
      this.histRecord.Hist.Emer = '1';
    }
  }
  async emerChange(value: string) {
    await this.getPreview(true);
  }
  //#endregion


  //#region 給類 PayTP
  setPayTP() {
    if (this.isPH) {
      this.histRecord.Hist.PayTP = '4';
      return;
    }
    var isEdit = (this.histRecord.Hist.PayTP || '') != '';
    var advised = '4';
    if (this.histRcd.Register.ICode == '006') {
      // 職災只能選1/2
      this.payOpt = this.payOpt.filter(p => p.value == '1' || p.value == '2')
      advised = '2';
    } else {
      //其他不能選1/2
      this.payOpt = this.payOpt.filter(p => p.value !== '1' && p.value !== '2')
    }
    // 新增病歷 或者 值不再選項中
    if (!isEdit || this.payOpt.some(kv => kv.value == this.histRecord.Hist.PayTP)) {
      this.histRecord.Hist.PayTP = advised;
    }
    if (this.homeCareList.some(x => (x.PositionGroup === 6 && x.Rxcode.startsWith('P')) || this.Special9List.some(y => y === x.Rxcode))) {
      this.histRecord.Hist.PayTP = '9'
      return;
    }
    else if (this.homeCareList.length > 0) {
      this.histRecord.Hist.PayTP = '4'
      return;
    }

  }
  async payTPChange(value: string) {
    await this.getPreview(true);
  }
  //#endregion


  //#region 勞職 LABTP
  setLabTP() {
    if (this.isPH) {
      this.histRecord.Hist.LABTP = '1';
      return;
    }
    var isEdit = (this.histRecord.Hist.LABTP || '') != '';
    var advised = '1';
    if (this.histRcd.Register.ICode == '006' && this.histRcd.Hist.Case == 'B6') {
      if (this.histRecord.Hist.PayTP == '1') {
        advised = 'A';
        this.labOpt = this.labOpt.filter(l => l.value == 'A');
      } else if (this.histRecord.Hist.PayTP == '2') {
        advised = 'A';
        this.labOpt = this.labOpt.filter(l => ['A', 'B', 'C', 'D'].includes(l.value));
      }
    }
    // 沒編輯過(新病例) 或者已成為不可選的選項時
    if (!isEdit || this.labOpt.some(kv => kv.value == this.histRecord.Hist.LABTP)) {
      this.histRecord.Hist.LABTP = advised;
    }

  }
  async labTyChange(value: string) {
    await this.getPreview(true);
  }
  //#endregion

  //#region 藥師
  defaPharmOverQuota = false;
  setPharm() {
    // 藥師執業=否(HasPharmacist=false) --> 自動改為無藥師，不要跳相關訊息
    if (UserCache.getLoginUser().Clinic.HasPharmacist) {
      var defaPharId = this.sessionService.getPhar();
      var pOpt = this.phOpt.find(p => p.value == defaPharId);
      if (this.phOpt.length == 0) {
        this.notification.showWarning('無藥師排班');
      } else {
        if (!pOpt) {
          // this.notification.showWarning('未設定對應藥師');
          pOpt = this.phOpt[0];
        }
        for (var i = this.rxDailyQuota.length; i > 0; i--) {
          var quota = this.rxDailyQuota[i - 1];
          if (pOpt.extension > quota) {
            this.userConfirm.showConfirm({
              'title': '提示',
              'msg': `藥師 ${pOpt.text}當日已調配${pOpt.extension}張處方，已超出第${GetChNumberText(i)}段合理量(>${quota})`,
              hideNo: true
            });
            this.defaPharmOverQuota = true
            break;
          }
        }
      }
      this.histRcd.Hist.PharmacistId = pOpt?.value ?? 0;
      this.selectedPharm = this.histRcd.Hist.PharmacistId;
    } else {
      this.histRcd.Hist.PharmacistId = 0;
      this.selectedPharm = this.histRcd.Hist.PharmacistId;
    }

  }

  selectedPharm = 0;
  revertPharm() {
    if (this.selectedPharm != 0) {
      this.histRcd.Hist.PharmacistId = this.selectedPharm;
    } else {

    }
  }
  selfcashChange(evt: Event) {
    this.histRecord.Hist.AOwn = Number((evt.target as HTMLInputElement).value);
    this.histRecord.IsChangeOwnBySelf = true;
    this.ischangeOwn.emit(true);
  }

  isPharmacistDisabled() {
    return !this.hasDrug || (this.histRcd.Hist.DispTP == DispensingTypeEnum.OutOfClinic || this.histRcd.Hist.DispTP == DispensingTypeEnum.NoNeed);
  }
  async pharmChange(opt: ValueTextPairNumberValue) {

    this.selectedPharm = opt.value > 0 ? opt.value : this.selectedPharm;
    var alert = this.memoryCache.get('AlertDefaultPhar', true);
    var defaPharId = this.sessionService.getPhar();
    if (this.defaPharmOverQuota && alert && opt.value != defaPharId && opt.value) {
      var updateDefaPharm = await this.userConfirm.showConfirm({
        'title': '提示',
        'msg': `是否將${opt.text}設定為新的預設藥師?`,
        textYes: '是',
        textNo: '否',
        'customBtnEnd': [{
          'text': '否，不再提醒',
          'return': 'never'
        }],
        width: 370
      });
      if (updateDefaPharm == true) {
        this.sessionService.setPhar(opt.value);
        //this.memoryCache.set('DefaultPhar',opt.value);
        this.memoryCache.set('AlertDefaultPhar', false);
        this.defaPharmOverQuota = false;
      } else if (updateDefaPharm == false) {

      } else if (updateDefaPharm == 'never') {
        this.memoryCache.set('AlertDefaultPhar', false);
      }
    }
    if (opt.value === 0 && this.hasDrug) {
      this.histRcd.Hist.DispTP = DispensingTypeEnum.OutOfClinic;
    }
    else if (opt.value === 0 && !this.hasDrug) {
      this.histRcd.Hist.DispTP = DispensingTypeEnum.NoNeed;
    }
    await this.getPreview(true);
  }
  //#endregion

  //#region 診察費

  //#endregion

  //#region 藥費
  details: { [key: number]: string } = {};
  rxTypeColumnMap = {
    2: 'ARx2',
    3: 'ARx3',
    4: 'ARx4',
    5: 'ATx5',
    6: 'ATx6',
    7: 'ATx7',
    8: 'ATx8',
    9: 'ATx9',
    10: 'ATx10',
    11: 'ATx11',
    12: 'AMat',
    13: 'ATx13',
    14: 'ATx14',
    15: 'ATx15',
    16: 'ATx16',
    17: 'ATx17',
  }
  //#endregion

  //#region 門診部分負擔
  opdPartDetail = '';
  opdPartDisplay: number = null

  nowWidth: number;

  //#endregion

  //#region 藥費部份負擔計算

  //#endregion

  //#region 藥事服務費


  //#endregion
  //#region 總額

  async getPreview(isUserModify: boolean = false) {
    if (isUserModify) {
      this.histRcd.Hist.IsEdit = this.defaultCase == this.histRcd.Hist.Case ? "0" : "1";
    }

    // 應急防呆用 => SPRule若為空字串，後端接收是int，會對應不到。造成批價框轉圈圈
    if (this.histRcd.Hist?.HRxs?.length > 0) {
      this.histRcd.Hist.HRxs.forEach(f => {
        if (f.SPRule != null && f.SPRule.toString() == '') {
          f.SPRule = null;
        }
      });
    }
    console.log('UserCache',UserCache.getLoginUser().Clinic.Type)
    var cashierViewer = await this.ratingPriceService.getCashierViewer(this.histRcd,this.casechecklist,this.defaultCase,this.hasDrug);
    this.cashierPreview = cashierViewer.CashierPreview;
    var caseTps = cashierViewer.CaseTps;
    // if (sortedCase.length > 1) {
    //   this.caseTab = sortedCase;
    // }

    // 非使用者指定的案類下
    if (this.histRcd.Hist.IsEdit != '1') {
      if (this.isPH) {
        // 預保維持A3不動
      } else {
        // 把預設案類改成價格最優
        this.defaultCase = cashierViewer.SortedCase[0];
      }

      this.histRcd.Hist.Case = this.defaultCase;
      this.currentCashier = this.cashierPreview[this.defaultCase];
    } else {
      // 計算出來沒有使用者指定的案類
      if (!caseTps.some(c => c == this.histRcd.Hist.Case) && cashierViewer.IsCaseTps) {
        // 清除鎖定
        this.histRcd.Hist.IsEdit = '0';
        // 設定為預設案類
        this.histRcd.Hist.Case = caseTps[0];
      }
      else if (!cashierViewer.IsCaseTps) {

      }
      this.currentCashier = this.cashierPreview[this.histRcd.Hist.Case];
    }

    this.histRcd.Hist.IsCardWrite = this.currentCashier?.IsRxDxChanged && this.histRcd.Hist.IsCardWrite

    // 判斷是否有管制用藥，且包含在設定值 this.ctrDrugLevs 內
    var needPrintCtrDrug: boolean = false;
    this.histRcd.Hist.HRxs.forEach((elem) => {

      if (needPrintCtrDrug) { return }
      needPrintCtrDrug = this.ctrDrugLevs.includes(elem.Rx.CTRDrugs);
    });

    this.isUseCTRDrugs = needPrintCtrDrug; // this.currentCashier.IsUseCTRDrugs;
    // 控制列印參數區
    this.setPrintParameter();
    if (this.currentCashier && !this.ischangeOwnByUrSerf) this.histRecord.Hist.AOwn = this.currentCashier.OwnPay;
    if (this.ischangeOwnByUrSerf) this.histRcd.IsChangeOwnBySelf = true;
    this.ErrorAndWarning.UrgentHC = this.currentCashier?.UrgentHCWarning === 'Not Add True' ? 'W' : '';
    if (this.currentCashier?.UrgentHCWarning === 'Add True') this.histRecord.IsInitUrgentTrue = true;
    else if (this.currentCashier?.UrgentHCWarning !== 'Add True' && this.isfirstPrice) this.histRecord.IsInitUrgentTrue = false;
    if (this.isfirstPrice) this.isfirstPrice = false;
    if (this.currentCashier?.UrgentHCWarning === 'Not Add True' && this.ErrorAndWarning.UrgentHC === 'W') {
      this.notification.showWarning('您的緊急訪視條件不符，請確認訪視時間是否在加成區間內')
    }

    if (this.histRecord.IsInitUrgentTrue && !this.isUrgentHC) {
      this.ErrorAndWarning.UrgentHC = 'W' ?? this.ErrorAndWarning.UrgentHC;
      this.notification.showWarning('您的居護加成因不勾選不計算緊急訪視加成')
    }

    // for 居護康家提示訊息
    if (this.histRcd.Hist.HRxs.some(x => x.RxType == 9) && this.currentCashier?.TreatmentFee === 0 && this.histRcd.Register.ICode !== '000'
      && (this.isHomeCare || this.isRecoveryHome) && !this.pricecontrol.isWarningTreatment) {
      this.notification.showWarning('請注意　本筆病歷缺診療費用 !')
      this.pricecontrol.isWarningTreatment = true; // 控制不重複提示訊息
    }
  }

  //#endregion
  @ViewChildren('countA')
  countA: QueryList<ElementRef<any>>
  ngOnDestroy(): void {
    //this.dialog.closeAll();  --不要把全部dialog關掉，申報那邊連結病歷也是dialog
    this.closeCnt()
    this.hotKeyService.unRegist(this.fnKeyRegid);

    if (this.editOptionsSubscription) {
      this.editOptionsSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.mainLayoutService.showLoader();
    this.fontGreater = this.fontGreatService.getFontGreat();
    this.isLoading = true;
    await this.optionRefresh();
    var hspidQuery = Object.assign({}, { htype: '3', pageSkip: '', pageSize: '' });
    this.isDisabledLab = !this.histRcd.Hist.HRxs.some(x => (x.RxType == 6 || x.RxType == 7) && ((x.SPRule > 5 && x.SPRule < 8) || x.DispTP == OrderDispensingTypeEnum.T4_LabHosp || x.DispTP == OrderDispensingTypeEnum.T2_RefLabHosp || x.DispTP == OrderDispensingTypeEnum.T3_RefLabClinic || x.DispTP == OrderDispensingTypeEnum.T5_LabHospClinic));
    this.rightListData = await this.hspidApi.GetHspidAllFromHist(hspidQuery);
    this.setlabdroplist();
    this.homecareCaseFg = this.histRcd.Patient.PaySysA == 'NI' || this.histRcd.Patient.PaySysA == 'NG' || this.histRcd.Patient.PaySysA == 'N4';
    var histParams = await this.clinicDataService.getParam("HST001");
    this.getCtrDrugLevel();
    // console.log ('para',histParams);
    // this.result.IsPrintMedRecord = histParams.IsPrintMedRecord;
    // this.result.IsPrintPrescription = histParams.IsPrintPrescription;
    // this.result.IsPrintDrugBag = histParams.IsPrintDrugBag;
    var hrxs = this.histRcd.Hist.HRxs.map(ac => ac.RxCode);
    this.homeCareList = await this.api.GetHomeCareRxList(hrxs);
    if (this.homeCareList?.length > 0) {
      this.isNeedRemote = this.homeCareList.some(x => x.Remote == true);
      this.histRecord.HomecareRxCodeList = this.homeCareList;
    }
    if (this.entranceType == "OPD") {

      this.histRcd.Hist.IsCardWrite = this.sessionService.getData('writeCardData');  // 選藥師時設定的 "寫IC卡" checkbox

      var isRecard = (this.histRcd.Register.IType == '3' && (this.histRcd.Register.ReCardDate != null && this.histRcd.Register.ReCardDate != undefined));
      this.isNotUseCheckCard = this.histRcd.Register.IType != '1' && !isRecard;

      this.histRcd.Hist.IsCardWrite = this.isNotUseCheckCard ? false : this.histRcd.Hist.IsCardWrite;
      this.histRcd.Hist.useSatellitePharmacy = Boolean(Number(this.sessionService.getData('useSatellite')));  // 選藥師時設定的 "衛星藥局" checkbox
      this.histRcd.Hist.useOutVisit = this.sessionService.getData('useOutVisit');
      this.histRcd.Hist.useEmr = this.sessionService.getData('useEmr');   // 選藥師時設定的 "電子病歷" checkbox
    }

    this.setUrgent();

    this.nowWidth = window.innerWidth;

    //this.ready.emit();
    // var ret = await this.api.GetPricing(new Date(this.histRcd.Register.RegDate || new Date()));
    // this.diagFee = ret.diagFee;
    // this.partOpd = ret.partOpd;
    // this.medPay = ret.medPay;
    // this.drugPay = ret.drugPay;
    // this.breaf = ret.breaf;
    await this.initsupportArea();

    await this.getPCSxOption();

    this.OutVisitSPTX = histParams.OutVisitSPTX; // 巡診主治

    setTimeout(async () => {
      await this.updatePrice();
      // 開啟畫面時提醒一次就好
      if (this.histRcd.Hist.Case == '04') {
        if (this.alertChronicPrescipt == true) {
          this.userConfirm.showConfirm({
            'msg': '慢性病案類可依慢箋規則開立慢箋',
            'hideNo': true,
            'textYes': '確認'
          });
        }
      }

      this.isLoading = false;
      this.mainLayoutService.hideLoader();
    }, 0);

    this.editOptionsSubscription = this.api.editoptRatingPrice$.subscribe(editOptions => {
      if (editOptions) {
        this.resetOption();
      }
    });

    // console.log("histRcd-end", this.histRcd);

  }

  async resetOption() {
    this.mainLayoutService.showLoader();
    var opts = await this.api.GetEditOptions();
    this.caseOpt = opts.CaseTP;
    this.dispOpt = opts.DispTP;
    this.labOpt = opts.LABTP;
    this.referralOpt = opts.referralopt.filter(x => x.value != 'T');  // T: 轉入先拿掉 2024/02/05
    this.consultationOpt = opts.consultationOpt;
    this.consultationOpt = this.consultationOpt.filter(x => x.value != '01');  //先排除01位於醫缺地區
    if (!this.isNeedRemote) this.consultationOpt = this.consultationOpt.filter(x => x.value != '04')
    this.payOpt = opts.PayTP;
    this.spcOpt = opts.spcTreatList;
    this.phOpt = [{ value: 0, text: '無', extension: '交付' }, ...opts.Pharmarcist];
    this.hasPharmacist = opts.Pharmarcist.length > 0;
    this.dept = opts.deptList;
    this.defaultDept = opts.userDept;
    this.chronicDay = opts.chronicDay;
    this.isMedDiv = opts.medDiv;
    this.defaultsettingCase = opts.defaultCase;
    this.cnt = opts.chronicFreq.toString();
    this.alertChronicPrescipt = opts.p13 == ' ' || opts.p13 == '2';
    this.rxDailyQuota = opts.rxDailyQuota;
    this.presNumOpt = opts.presNumOpt;
    this.mainLayoutService.hideLoader();
  }

  async getPCSxOption() {
    var ret = await this.pCSxApiService.GetPCSxPageList({
      pageSize: 20,
      pageSkip: 0,
      IsPage: false,
      DiagVersion: this.diagVersion
    })

    this.pcsxOption = ret.data.map(x => {
      let pair = new ValueTextPair();
      pair.text = `${x.CName}`
      pair.value = x.ApplyCode

      return pair;
    })
  }

  ctrDrugLevs: string[] = [];
  async getCtrDrugLevel() {
    var param = await this.clinicDataService.getParam("PRN001");
    if (param.CTRDrugPrintType?.length > 0) {
      var types = param.CTRDrugPrintType;
      if (types.includes('1')) {
        this.ctrDrugLevs.push('A');
      }
      if (types.includes('2')) {
        this.ctrDrugLevs.push('B');
      }
      if (types.includes('3')) {
        this.ctrDrugLevs.push('C');
      }
      if (types.includes('4')) {
        this.ctrDrugLevs.push('D');
      }
    }
  }

  cashierPreview: CashierPreview;
  currentCashier: Hrxfee;
  caseTab: string[] = [];
  defaultCase: string;
  isfirstPrice = true;
  async updatePrice() {
    this.isPH = this.histRcd.Register.Nhictp == 'AC' && this.histRcd.Register.SameTreat == '2';
    // 這邊可能需要邏輯來判斷是否要重批
    //var isFinish= this.histRcd.Register.StatusCode == RegisterConst.Code_Status_Finish;
    var isFinish = false;
    if (isFinish) {
      this.defaultCase = this.histRcd.Hist.Case;
    } else {
      // 重批
      // Parse次診斷
      this.setSubDx();
      await this.setDxNameAndGetSpecitem(); // 取得慢病診斷
      this.setCase(); // 案類在setSpecItem前面先跑
      // Parse主治, 居護案類必須先取得 所以方法拆分為  setDxNameAndGetSpecitem / setSpecItem
      this.setSpecItem();
      this.setKDJASPExtension();

      // Parse手術
      await this.setSurOrHealthCare();
      this.setDays();
      this.setEmer();
      //設定藥師
      this.setPharm();
      //設定調劑方式，視情況改掉藥師
      this.setDept();
      this.setDisp();
      this.setPayTP();
      this.setLabTP();

      // 設定復健相關
      await this.setCus();
      await this.setPres();
      this.checklab();

    }
    // 要在處理完調劑後處理

    //醫缺地區處理
    var ZipCode = await this.MedLackAreaApi.GetZipCodeByCityArea(this.histRcd.Patient.City, this.histRcd.Patient.Area, this.isNeedRemote, 'EC');
    var hasorigSupArea = !this.isNullOrEmpty(this.histRcd.Hist.SupArea)
    if (!this.pricecontrol.isEditSupArea && this.histRcd.Register.SameTreat != 'F') {
      if (hasorigSupArea && this.isNeedRemote) {
        this.supportAreaIsChecked = true;
      }
      else if (hasorigSupArea && !this.isNeedRemote && this.histRcd.Hist.SpecAreaSV == '04') {
        this.notification.showWarning('您的醫令沒有符合山地離島的條件，支援地區代碼已被清除')
        this.supportAreaIsChecked = false;
        this.histRcd.Hist.SupArea = '';
        this.histRcd.Hist.SpecAreaSV = '';
      }
      else if (!this.isNullOrEmpty(ZipCode) && !hasorigSupArea && this.isHomeCare) {
        this.histRcd.Hist.SpecAreaSV = '04';
        this.supportAreaIsChecked = true;
        this.histRcd.Hist.SupArea = ZipCode;
      }
      else if (this.isNullOrEmpty(ZipCode) && !hasorigSupArea && this.isHomeCare) {
        this.histRcd.Hist.SupArea = '';
        this.histRcd.Hist.SpecAreaSV = '';
        this.supportAreaIsChecked = false;
      }
    }
    //一般的巡診，沒有加成，畫面顯示00，存入00
    if ( this.histRcd.Hist.useOutVisit && isNullOrUndefined(this.histRcd.Hist.SpecAreaSV) && this.histRcd.Register.StatusCode !== 'F') {
      this.histRcd.Hist.SpecAreaSV = '00';
    }

    await this.getPreview();

    var el = document.getElementById('specItem');
    if (el) el.focus();
  }

  diagFee: any;
  partOpd: any;
  medPay: any;
  drugPay: any;
  breaf: any;
  cntDialogRef: MatDialogRef<any> = null;
  cnt = '3';
  cntDate = DateHelper.today;
  cntDisabled = [false, false, false, false];
  disablecntDate = false;
  forceFreeDrugPartFee = false;
  //continuousPrescriptionCode = '08';//慢性病連續處方箋
  freeDrugPartFeeCode = '01';//強制慢箋免藥自付(停經症候群開藥21日)
  freeDrugPartFeeDays = 21;//強制慢箋免藥自付(停經症候群開藥21日)
  confirm21Days = false;//如已經詢問過，於完診就不再次詢問。
  // #region 慢籤
  // 開啟慢籤
  openCntPres(tmp, evt: MouseEvent) {
    var ele = (evt.target as HTMLElement);
    var rect = ele.getBoundingClientRect();
    for (var i = 0; i < 3; i++) {
      this.cntDisabled[i] = (i + 2) * this.histRcd.Hist.Days > 92;
    }
    //this.histRcd.Hist.date
    // if (!this.histRcd.Hist.CntPresDate) {
    //   this.histRcd.Hist.CntPresDate = DateHelper.today;
    // }
    this.cnt = Or(this.histRcd.Hist.CntPresTot, this.cnt);
    // let cusCntVal = !isNaN(Number(this.histRcd.Hist.CntPresTot)) ? Number(this.histRcd.Hist.CntPresTot) > 3 ? Number(this.histRcd.Hist.CntPresTot) : null : null;
    // if (cusCntVal) { this.cnt = '4'; this.customInputEnabled = true; }
    // else { this.cnt = Or(this.histRcd.Hist.CntPresTot, this.cnt); this.customInputEnabled = false; }
    // if (cusCntVal) this.customInputValue = !isNaN(Number(this.histRcd.Hist.CntPresTot)) ? this.histRcd.Hist.CntPresTot : null;
    this.cntDate = Or(this.histRcd.Hist.DateStart, this.histRcd.Register.RegDate);
    let cntCount = !isNaN(Number(this.histRcd.Hist.CntPresNo)) ? Number(this.histRcd.Hist.CntPresNo) : 0;

    this.disablecntDate = cntCount > 1;
    this.forceFreeDrugPartFee = Or(this.histRcd.Hist.CntPresCl === this.freeDrugPartFeeCode, this.forceFreeDrugPartFee);
    if (this.cntDialogRef == null) {
      this.cntDialogRef = this.dialog.open(tmp, {
        closeOnNavigation: false,
        disableClose: true,
        hasBackdrop: false,
        autoFocus: false,
        position: { 'left': (rect.x - 100) + 'px', 'top': (rect.y + rect.height) + 'px' }
      });
    } else {
      this.closeCnt()
    }
  }
  closeCnt() {
    this.forceFreeDrugPartFee = this.histRcd.Hist.CntPresCl === this.freeDrugPartFeeCode;

    this.cntDialogRef?.close();
    this.cntDialogRef = null;
  }
  setCnt() {
    this.cntChange();
    this.cntDialogRef?.close();
    this.setPresAndCount();
    this.cntDialogRef = null;
  }

  matchForceFree(sex: number, specItem: string) {
    //33青光眼 67停經症候群
    return (specItem.includes('67') || specItem.includes('33'));
  }

  onGroupChange(evt: any) {
    if (evt?.value &&
      this.histRcd.Hist.Days == this.freeDrugPartFeeDays &&
      this.matchForceFree(this.histRcd.Patient.Sex, this.specItem)) {
      //***關閉強制慢箋免藥自付，checkbox不顯示(display: none)，改成自動判斷符合條件就免要自付，判斷寫在後端GetDrugPartFee()。
      // this.forceFreeDrugPartFee = true;
      this.forceFreeDrugPartFee = false;
    } else {
      this.forceFreeDrugPartFee = false;
    }
  }

  async onForceFreeChange(evt: MatCheckboxChange) {
    if (evt?.checked) {
      if (!await this.checkInputForceFree()) { return; }
    }
  }

  async checkInputForceFree() {
    if (!this.forceFreeDrugPartFee) {
      return true;
    }

    if (!this.cnt) {
      await this.userConfirm.showAlert('提示', '必須選擇調劑次數，才能勾選強制慢箋免藥自付。');
      this.forceFreeDrugPartFee = false;
      return false;
    } else {
      if (!this.cntDate) {
        await this.userConfirm.showAlert('提示', '必須輸入用藥開始日，才能勾選強制慢箋免藥自付。');
        this.forceFreeDrugPartFee = false;
        return false;
      }
    }

    let msg = '';
    if (this.histRcd.Hist.Days == this.freeDrugPartFeeDays && !this.matchForceFree(this.histRcd.Patient.Sex, this.specItem)) {
      msg = `已勾選強制慢箋免藥自付，\n主治應為67停經症候群，或33青光眼，\n\n請確認診斷。`;
    } else if (this.histRcd.Hist.Days != this.freeDrugPartFeeDays && this.matchForceFree(this.histRcd.Patient.Sex, this.specItem)) {
      msg = `已勾選強制慢箋免藥自付，\n服藥天數應為${this.freeDrugPartFeeDays}天，目前輸入${this.histRcd.Hist.Days}天，\n\n請確認日份。`;
    } else if (this.histRcd.Hist.Days != this.freeDrugPartFeeDays && !this.matchForceFree(this.histRcd.Patient.Sex, this.specItem)) {
      msg = `已勾選強制慢箋免藥自付，\n主治應為67停經症候群，或33青光眼，\n服藥天數應為${this.freeDrugPartFeeDays}天，目前輸入${this.histRcd.Hist.Days}天，\n\n請確認。`;
    }
    if (msg) {
      await this.userConfirm.showConfirm({ 'title': '提示', 'msg': msg, 'hideNo': true, 'textYes': '確認', fitContent: true });
      //原本是提示，改為不能勾選，目前必定符合上述條件，才能勾選。
      //若要改成提示後可勾選，需將下面這兩行註解掉。
      this.forceFreeDrugPartFee = false;
      return false;

      //原本提示仍可勾選時，此行是控制存檔時不用再提示一次。
      this.confirm21Days = true;
    }

    return true;
  }

  async checkSaveForceFree() {
    if (this.cntDialogRef) {
      await this.userConfirm.showAlert('提示', '必須先確認慢箋次別，才能存檔。');
      return false;
    }

    if (this.allowChronicPres && this.forceFreeDrugPartFee && !this.confirm21Days) {
      let msg = '';
      if (this.histRcd.Hist.Days == this.freeDrugPartFeeDays && !this.matchForceFree(this.histRcd.Patient.Sex, this.specItem)) {
        msg = `已勾選強制慢箋免藥自付，\n主治應為67停經症候群，或33青光眼，\n\n請確認是否勾選強制?`;
      } else if (this.histRcd.Hist.Days != this.freeDrugPartFeeDays && this.matchForceFree(this.histRcd.Patient.Sex, this.specItem)) {
        msg = `已勾選強制慢箋免藥自付，\n服藥天數應為${this.freeDrugPartFeeDays}天，目前輸入${this.histRcd.Hist.Days}天，\n\n請確認是否勾選強制?`;
      } else if (this.histRcd.Hist.Days != this.freeDrugPartFeeDays && !this.matchForceFree(this.histRcd.Patient.Sex, this.specItem)) {
        msg = `已勾選強制慢箋免藥自付，\n主治應為67停經症候群，或33青光眼，\n服藥天數應為${this.freeDrugPartFeeDays}天，目前輸入${this.histRcd.Hist.Days}天，\n\n請確認是否勾選強制?`;
      }
      if (msg) {
        if (this.cntDialogRef == null) {
          this.presButton.nativeElement.click();
        }
        //原本是提示詢問，改為擋住，不符合條件就不能勾選強制。
        //若要改成提示後可勾選，需還原下面註解。
        // let ret= await this.userConfirm.showConfirm({ 'title': '提示', 'msg': msg, textYes: '是', textNo: '否', width: 350 });
        let ret = await this.userConfirm.showConfirm({ 'title': '提示', 'msg': msg, 'hideYes': true, 'textNo': '確認', width: 350 });
        if (!ret) {
          this.forceFreeDrugPartFee = false;
          return false;
        } else {
          this.confirm21Days = true;
          this.cntDialogRef?.close();
          this.cntDialogRef = null;
        }
      }
    }
    return true;
  }

  cntChange() {
    if (this.cnt && (this.histRecord.Register.ReCardDate == null && this.histRcd.Register.IType === '3')) {
      //12345
      this.userConfirm.showConfirm({
        'msg': '注意！全民健康保險醫療辦法107年04月27日公告修正，欠卡不得開立慢性病連續處方箋',
        'title': '請確認以下資訊',
        'hideNo': true,
        'width':400,
        'textYes': '確定'
      });
      this.histRcd.Hist.CntPresCl = null;
      this.histRcd.Hist.CntPresTot = null;
      this.histRcd.Hist.CntPresNo = null;
      this.histRcd.Hist.DateStart = null;
      this.result.IsPrintDispPrescription = false;
    }
    else if (this.cnt && this.allowChronicPres) {
      //不存08
      // this.histRcd.Hist.CntPresCl = this.forceFreeDrugPartFee ? this.freeDrugPartFeeCode : this.continuousPrescriptionCode;
      this.histRcd.Hist.CntPresCl = this.forceFreeDrugPartFee ? this.freeDrugPartFeeCode : null;
      // if (this.customInputEnabled) this.histRcd.Hist.CntPresTot = this.customInputValue;
      // else this.histRcd.Hist.CntPresTot = this.cnt;
      this.histRcd.Hist.CntPresTot = this.cnt;
      this.histRcd.Hist.CntPresNo = this.histRecord.RatingPriceOriginPresNo || '1';
      this.histRcd.Hist.DateStart = this.cntDate;
      if (this.entranceType != 'Declare') {
        this.result.IsPrintDispPrescription = true;
      }
    } else {
      this.histRcd.Hist.CntPresCl = null;
      this.histRcd.Hist.CntPresTot = null;
      this.histRcd.Hist.CntPresNo = null;
      this.histRcd.Hist.DateStart = null;
      this.result.IsPrintDispPrescription = false;
    }
  }

  getCntPresTitle() {
    var cntPresTitle = '';
    //免藥自付顯示hint
    if (!this.currentCashier?.DrugPart) {
      if (this.allowChronicPres && this.cnt && this.histRcd.Hist.Days == this.freeDrugPartFeeDays) {
        if (this.specItem.includes('67')) {
          cntPresTitle = '(慢箋免藥自付: 停經症候群開藥21日)';
        } else if (this.specItem.includes('33')) {
          cntPresTitle = '(慢箋免藥自付: 青光眼開藥21日)';
        }
      }
    }
    return cntPresTitle;
  }

  //#endregion
  private isSelfMedService(dispType: string) {
    return dispType == DispensingTypeEnum.Clinic || dispType == DispensingTypeEnum.EmerSelf;
  }

  previewCaseChanged(caseTp: string) {
    this.currentCashier = this.cashierPreview[caseTp];
    if (this.histRecord.Hist.Case != caseTp) {
      this.histRecord.Hist.Case = caseTp
    }
  }

  setPrintParameter() {
    if (this.entranceType != 'Declare') {
      this.canprintDisp = this.histRcd.Hist.HRxs.some(x => x.RxType == 2 || x.RxType == 3 || x.RxType == 4 || x.RxType == 12);
      this.result.IsPrintCtrDrugs = this.isUseCTRDrugs && this.result.IsPrintCtrDrugs;
      this.result.IsPrintDispExam = this.hasExam && this.result.IsPrintDispExam && this.histRcd.Hist.HRxs.some(x => (x.RxType == 6 || x.RxType == 7) && x.SPRule != 5) && !this.histRcd.Hist.HRxs.filter(x => (x.RxType == 6 || x.RxType == 7)).every(x => x.SPRule == 5);
      this.result.IsPrintDispPrescription = (this.histRcd.Hist.CntPresNo == '1'
        || (this.canprintDisp
          && !(this.histRcd.Hist.HRxs.filter(x => x.RxType == 2 || x.RxType == 3 || x.RxType == 4 || x.RxType == 12).every(x => x.SPRule == OrderSpecialRuleEnum.A5_ApplyNotPrint))
          && this.result.IsPrintDispPrescription));
      this.result.IsPrintExam = false;
      this.result.IsPrintDrugBag = this.hst001Params?.IsPrintDrugBag && this.histRecord.Hist.DispTP != DispensingTypeEnum.NoNeed;
    }
  }

  stopp(evt: Event) {
    // console.log(evt)
    //evt.stopPropagation();
    //evt.preventDefault();
    return false;
  }


  //#region CloseDialog
  cancel() {
    this.controlEvent.emit(this.pricecontrol);
    // if (!this.ischangeOwnByUrSerf && this.currentCashier.OwnPay > 0) {
    //   this.histRecord.Hist.AOwn = this.histRecord.Hist.AOwn - this.currentCashier.OwnPay > 0 ? this.histRecord.Hist.AOwn - this.currentCashier.OwnPay : 0;
    // }
    if (this.isLoading) {
      return;
    }
    this.isSaving = false;
    this.showOpen = false;
    this.result.CompleteMode = 'Cancel';
    this.closed.emit(this.result);
  }

  async saving(print: boolean = true) {
    if (!await this.checkSaveForceFree()) { return; }

    if (!this.isSaving) {
      this.finish(print);
      this.isSaving = true;
    }
  }

  finish(print: boolean = true) {
    if (this.isLoading) {
      return;
    }
    this.showOpen = false;

    if (!print) {
      this.result.IsPrintWay = false;
      if (this.result.CompleteMode == 'Next') {
        this.result.CompleteMode = 'DontPrintAndNext';
      } else {
        this.result.CompleteMode = 'DontPrint';
      }
    } else {
      if (this.result.CompleteMode != 'Next') {
        this.result.CompleteMode = 'List';
      }
    }
    // }
    this.closed.emit(this.result);
  }
  /** 處理 setKDJASPExtension 之方法*/
  setKDJASPExtension() {
    let inJail = false;
    if (!this.isNullOrEmpty(this.specItem)) inJail = this.specItem.includes('JA') || this.specItem.includes('JB')
    if (!(this.histRcd.Register.SameTreat === '0' || inJail)) return;
    let spE = this.histRcd.Hist.SPExtension;
    let match = spE.match(/\[KD=(.*?)\]/);
    let match2 = spE.match(/\[JA=(.*?)\]/);
    if (this.isNullOrEmpty(this.histRcd.Hist.SPExtension) && this.kidneyDialysisDto.Time === '00:00:00' && this.histRcd.Register.StatusCode !== 'F') {
      this.translateSPEString('TIME', '0400')
    }
    this.kidneyDialysisDto.AK = this.isNullOrEmpty(this.kidneyDialysisDto.AK) ? '' : this.kidneyDialysisDto.AK;
    this.kidneyDialysisDto.Time = this.kidneyDialysisDto.Time === '00:00:00' && this.histRcd.Register.StatusCode !== 'F' ? '04:00:00' : this.kidneyDialysisDto.Time;
    this.kidneyDialysisDto.HCT = this.isNullOrEmpty(this.kidneyDialysisDto.HCT) ? '' : this.kidneyDialysisDto.HCT;
    this.kidneyDialysisDto.Hb = this.isNullOrEmpty(this.kidneyDialysisDto.Hb) ? '' : this.kidneyDialysisDto.Hb;
    this.kidneyDialysisDto.BUN = this.isNullOrEmpty(this.kidneyDialysisDto.BUN) ? '' : this.kidneyDialysisDto.BUN;
    this.kidneyDialysisDto.Cr = this.isNullOrEmpty(this.kidneyDialysisDto.Cr) ? '' : this.kidneyDialysisDto.Cr;
    this.kidneyDialysisDto.EPO = this.isNullOrEmpty(this.kidneyDialysisDto.EPO) ? '' : this.kidneyDialysisDto.EPO;

    this.JailInfo.JailAddressCode = this.isNullOrEmpty(this.JailInfo.JailAddressCode) ? null : this.JailInfo.JailAddressCode;
    this.JailInfo.JailHouse = this.isNullOrEmpty(this.JailInfo.JailHouse) ? '' : this.JailInfo.JailHouse;
    this.JailInfo.PensonalCode = this.isNullOrEmpty(this.JailInfo.PensonalCode) ? '' : this.JailInfo.PensonalCode;
    this.handleKDInitInfo();
    if (match) {
      let kdPart: string = match[1];
      const obj: { [key: string]: string } = {};
      kdPart.split(';').forEach(pair => {
        let [key, value] = pair.trim().split(':');
        // console.log('pair',key ,value);
        let keyVal = key.toUpperCase();
        switch (keyVal) {
          case 'AK':
            this.kidneyDialysisDto.AK = value;
            break;
          case 'TIME':
            value = value.padEnd(4, '0');
            let presentime = value[0] + value[1] + ':' + value[2] + value[3] + ':00'
            this.kidneyDialysisDto.Time = presentime;
            break;
          case 'HCT':
            this.kidneyDialysisDto.HCT = value;
            break;
          case 'HB':
            this.kidneyDialysisDto.Hb = value;
            break;
          case 'BUN':
            this.kidneyDialysisDto.BUN = value;
            break;
          case 'CR':
            this.kidneyDialysisDto.Cr = value;
            break;
          case 'EPO':

            this.kidneyDialysisDto.EPO = !this.isNullOrEmpty(value) ? value : this.kidneyDialysisDto.EPO;
            break;
        }
      });
    }
    if (match2) {
      let kdPart: string = match2[1];
      const obj: { [key: string]: string } = {};
      kdPart.split(';').forEach(pair => {
        let [key, value] = pair.trim().split(':');
        let keyVal = key.toUpperCase();
        switch (keyVal) {
          case 'JAILHOUSE':
            this.JailInfo.JailHouse = value;
            break;
          case 'JAILADDRESSCODE':
            this.JailInfo.JailAddressCode = value;
            break;
          case 'PERSONALCODE':
            this.JailInfo.PensonalCode = value;
            break;

        }
      });
    }

  }
  handleKDInitInfo() {
    if (this.kidneyDialysisDto.EPO.length > 0 && this.histRcd.Register.StatusCode == 'F') {
      return this.hasInjectionSelfDisp = true;
    }
    var diOrders = this.histRcd.Hist.HRxs.filter(x => x.RxType == 4);
    let isTodecl = false;
    let unit = 0; // 先這樣
    for (var diOrder of diOrders) {
      if (diOrder.SPRule < 2 || diOrder.SPRule > 4) isTodecl = true;
      if (!this.isNullOrEmpty(diOrder.Rx?.BoxUnit)) {
        let bu = diOrder.Rx?.BoxUnit.replace(/[^\d]+/g, '')
        unit = !isNaN(Number(bu)) ? Number(bu) : 0;
      }

      if (diOrder.StdCode === 'K000782209' && unit === 0) {
        unit = 40     //A40
      }
      else if (diOrder.StdCode === 'K000781209' && unit === 0) {
        unit = 25;    // A25
      }
      else if (diOrder.StdCode === 'K000882209' && unit === 0) {
        unit = 4000; // A20
      }
      else if (diOrder.StdCode === 'K000648209' && unit === 0) {
        unit = 1; // R1
      }
      else if (diOrder.StdCode === 'KC00646212' && unit === 0) {
        unit = 2; // R2
      }
      else if (diOrder.StdCode === 'K000620212' && unit === 0) {
        unit = 4; // DE
      }
      else if (diOrder.StdCode === 'KC00645221' && unit === 0) {
        unit = 5; // R5
      }
      if (unit > 0) break;
    }
    if (unit > 0) {
      this.kidneyDialysisDto.EPO = diOrder.TotalDose + '*' + unit;
      this.translateSPEString('EPO', this.kidneyDialysisDto.EPO)
      this.hasInjectionSelfDisp = isTodecl;
    }

  }
  setToNext(checked: MatCheckboxChange) {

    this.result.CompleteMode = checked.checked ? 'Next' : 'List';
  }

  setUrgent() {
    this.isUrgent = this.histRecord.Hist.Urgent == 'V' ? true : false;
    this.isUrgentNight = this.histRecord.Hist.UrgentNight == 'V' ? true : false;
    this.isUrgentHC = this.histRecord.Hist.UrgentHC == 'V' ? true : false;
  }
  //#endregion
  //#region flag 控制區
  ICCardChange(event: MatCheckboxChange) {
    this.histRecord.Hist.IsCardWrite = event.checked;
  }

  EMRChange(event: MatCheckboxChange) {
    this.histRecord.Hist.useEmr = event.checked;
  }

  UseSatellitePharmacy(event: MatCheckboxChange) {
    this.histRecord.Hist.useSatellitePharmacy = event.checked;
  }

  async UrgentChange(event: MatCheckboxChange) {
    this.histRecord.Hist.Urgent = event.checked ? 'V' : '';
    await this.getPreview();
  }

  async UrgentNightChange(event: MatCheckboxChange) {
    this.histRecord.Hist.UrgentNight = event.checked ? 'V' : '';
    await this.getPreview();
  }

  async UrgentHCChange(event: MatCheckboxChange) {
    this.histRecord.Hist.UrgentHC = event.checked ? 'V' : '';
    await this.getPreview();
  }
  //#endregion


  isOverQuota(amount: number) {
    return this.rxDailyQuota.length > 0 && amount > this.rxDailyQuota[0]
  }
  onDtpEndDateChange(event: string) {
    let endDate = DateHelper.getFormatedDateString(event, false, "");
    // if (Number(endDate) < Number(startDate)) {
    //   this.queryFormGroup.controls['endDate'].setValue(sDate);
    //   this.notification.showInfo('結束日期不可小於開始日期');
    // }
  }
  onKDInputChange(kdAttribute: string, evt: Event) {
    let input = (evt.target as HTMLInputElement).value;
    this.translateSPEString(kdAttribute, input);

  }

  /** 處理 SPEString 之方法*/
  translateSPEString(kdAttribute: string, inputVal: string) {
    let spE = this.histRcd.Hist.SPExtension;
    let match = spE.match(/\[KD=(.*?)\]/);
    const obj: { [key: string]: string } = {};
    if (match) {
      let kdPart: string = match[1];
      kdPart.split(';').forEach(pair => {
        const [key, value] = pair.trim().split(':');
        if (!isNullOrUndefined(key) && !isNullOrUndefined(value)) obj[key.trim().toUpperCase()] = value.trim();
      });
    }
    let istypeValue = !this.isNullOrEmpty(inputVal)
    if (kdAttribute in obj && !istypeValue) {
      // 如果是，删除 'EPO' 属性
      delete obj[kdAttribute];
    }
    else if (istypeValue) {
      if (kdAttribute == 'TIME') {
        inputVal = inputVal.replace(/:/g, '');
      }
      obj[kdAttribute] = inputVal;
    }
    if (Reflect.has(this.kidneyDialysisDto, kdAttribute)) {
      // 修改属性的值
      Reflect.set(this.kidneyDialysisDto, kdAttribute, inputVal);
    }

    let result = Object.entries(obj).map(([key, value]) => `${key}:${value}`).join(';');
    this.histRcd.Hist.SPExtension = this.histRcd.Hist.SPExtension.replace(/\[KD=(.*?)\]/, '')
    if (result.length > 0) this.histRcd.Hist.SPExtension += ('[KD=' + result + ';]');
  }

  onJaInputChange(jaAttribute: string, evt: Event) {
    let input = (evt.target as HTMLInputElement).value;
    this.translateSPEJaString(jaAttribute, input);
  }

  onJaComboBoxChange(jaAttribute: string, evt: MatOption) {
    let input = evt?.value || ''
    this.translateSPEJaString(jaAttribute, input);
  }

  /** 處理 SPEString JA之方法*/
  translateSPEJaString(jaAttribute: string, inputVal: string) {
    let spE = this.histRcd.Hist.SPExtension;
    let match = spE.match(/\[JA=(.*?)\]/);
    const obj: { [key: string]: string } = {};
    if (match) {
      let kdPart: string = match[1];
      kdPart.split(';').forEach(pair => {
        const [key, value] = pair.trim().split(':');
        if (!isNullOrUndefined(key) && !isNullOrUndefined(value)) obj[key.trim().toUpperCase()] = value.trim();
      });
    }
    let istypeValue = !this.isNullOrEmpty(inputVal)
    if (jaAttribute in obj && !istypeValue) {
      // 如果是，删除 'EPO' 属性
      delete obj[jaAttribute];
    }
    else if (istypeValue) {
      obj[jaAttribute] = inputVal;
    }
    if (Reflect.has(this.JailInfo, jaAttribute)) {
      // 修改属性的值
      Reflect.set(this.JailInfo, jaAttribute, inputVal);
    }

    let result = Object.entries(obj).map(([key, value]) => `${key}:${value}`).join(';');
    this.histRcd.Hist.SPExtension = this.histRcd.Hist.SPExtension.replace(/\[JA=(.*?)\]/, '')
    if (result.length > 0) this.histRcd.Hist.SPExtension += ('[JA=' + result + ']');
  }
  /** 處理 CusCl 的字串組成 目前能使用複合療程 ex:復健加外傷 [OS2][TR1] */
  getCusClCourseString(arr: string[], type: string, statusCode: string, cusNo: string): string {
    let no = cusNo;
    let add = statusCode === 'F' ? 0 : 1;
    if (type != 'HC' && type != '08' && type != 'KD') no = '1';
    else return type + no; //月療|慢籤 | 洗腎
    var res = '';
    let filterStartsWith = arr.filter(item => item.startsWith(type));
    if (type == 'RP') filterStartsWith = filterStartsWith.filter(item => !item.startsWith('RPR'))
    if (filterStartsWith.length > 0) {
      let numbers = filterStartsWith.map(item => parseInt(item.slice(type.length)));
      let maxNumber = Math.max(...numbers) + add;  // 提取數字部分並轉換為數字
      if (Number.isNaN(maxNumber)) maxNumber = statusCode === 'F' ? 0 : 1;
      res = type + maxNumber;
    }
    else res = type + no;
    return res;
  }

  openReferral() {
    this.onReferralOpen.emit();
  }

  //#region 檢驗區相關方法
  labgridview: any;
  openLablist() {
    this.isShowRegDialog = true;
    this.refreshLabList();


  }
  closeLablist() {
    this.checklab();
    this.isShowRegDialog = false;
  }
  setlabdroplist() {
    this.labAddrCodeOpt = [];
    this.rightListData.data.forEach(x => {
      var item: ValueTextPair = {
        text: x.HNAME,
        value: x.HID,
      };
      this.labAddrCodeOpt.push(item);
      // console.log('item',item);
    });
  }
  refreshLabList() {
    let gridData = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 6 || x.RxType == 7) && ((x.SPRule > 5 && x.SPRule < 8) || x.DispTP == OrderDispensingTypeEnum.T4_LabHosp || x.DispTP == OrderDispensingTypeEnum.T2_RefLabHosp || x.DispTP == OrderDispensingTypeEnum.T3_RefLabClinic || x.DispTP == OrderDispensingTypeEnum.T5_LabHospClinic));
    this.labsinfo = gridData.map((hrx, index) => {
      let labinfo: LabforRatingInfo = {
        SortNo: index,
        RxCode: hrx.RxCode,
        ProdName: hrx.ProdName,
        TransOri: hrx.TransOri,
      };
      return labinfo;
    });

    this.labgridview = {
      data: this.labsinfo,
      total: this.labsinfo.length
    }

    // console.log("hrxs", this.histRcd.Hist.HRxs);
    // console.log("gridData", gridData);
    // console.log("labgridview", this.labgridview);
  }
  /** 處理 醫令的TransOri, NhiDeclare的LabHosp, 代檢輸入框下拉式選單 */
  async checklab() {
    if (this.isDisabledLab) return this.histRcd.Hist.LabHosp = null;
    let strArr: string[] = [];
    var msg = this.getLabWarning();
    if (msg.length > 0) {
      await this.userConfirm.showAlert('提示', msg.join('\n'))
    }

    if (this.labAddrCodeOpt.length == 0) {
    }
    else if (this.labAddrCodeOpt.length > 0) {
      for (var i = 0; i < this.histRcd.Hist.HRxs.length; i++) {
        var rx = this.histRcd.Hist.HRxs[i];
        if ((rx.RxType == 6 || rx.RxType == 7) && ((rx.SPRule > 5 && rx.SPRule < 8) || rx.DispTP == OrderDispensingTypeEnum.T4_LabHosp || rx.DispTP == OrderDispensingTypeEnum.T2_RefLabHosp || rx.DispTP == OrderDispensingTypeEnum.T3_RefLabClinic || rx.DispTP == OrderDispensingTypeEnum.T5_LabHospClinic)) {
          let transOri = '';
          // rx.Id > 0 代表已經儲存過 而hrx在代檢框有代值則以該值為主
          if (rx.Id > 0 || !this.isNullOrEmpty(rx.TransOri)) {
            transOri = this.histRcd.Hist.HRxs[i].TransOri;
          }
          else if (!this.isNullOrEmpty(rx.Rx?.LabHosp)) {
            transOri = this.labAddrCodeOpt.find(x => x.value == rx.Rx?.LabHosp).value;
            if (this.isNullOrEmpty(transOri)) transOri = this.labAddrCodeOpt[0].value;
          }
          else {
            transOri = this.labAddrCodeOpt[0].value;

          }
          this.histRcd.Hist.HRxs[i].TransOri = transOri;
          if (!this.isNullOrEmpty(transOri)) strArr = strArr.concat(transOri);

        }

      }
    }
    if (strArr.length > 0) {
      for (var i = 0; i < this.labAddrCodeOpt.length; i++) {
        let hascode = strArr.some(x => x == this.labAddrCodeOpt[i].value)

        if (hascode) {
          this.histRcd.Hist.LabHosp = this.labAddrCodeOpt[i].value;
          break;
        }
      }
    }
  }
  getLabWarning() {
    var msg: string[] = []
    let hrxsTrans = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 6 || x.RxType == 7) && ((x.SPRule > 5 && x.SPRule < 8) || x.DispTP == OrderDispensingTypeEnum.T4_LabHosp || x.DispTP == OrderDispensingTypeEnum.T2_RefLabHosp || x.DispTP == OrderDispensingTypeEnum.T3_RefLabClinic || x.DispTP == OrderDispensingTypeEnum.T5_LabHospClinic) && !this.isNullOrEmpty(x.TransOri)).map(x => x.TransOri)
    let hrxsDispTps = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 6 || x.RxType == 7) && (x.SPRule > 5 && x.SPRule < 8) && (x.DispTP == OrderDispensingTypeEnum.T4_LabHosp || x.DispTP == OrderDispensingTypeEnum.T2_RefLabHosp || x.DispTP == OrderDispensingTypeEnum.T3_RefLabClinic || x.DispTP == OrderDispensingTypeEnum.T5_LabHospClinic)).map(x => Number(x.DispTP))
    if (this.labAddrCodeOpt.length == 0) {
      this.histRcd.Hist.LabHosp = null;
      if (hrxsTrans.length > 0 && !this.pricecontrol.isMSGcheckLab1st) {
        this.pricecontrol.isMSGcheckLab1st = true
        msg.push('來源機構未設置，完診或儲存將會影響原有代檢機構之資料');
      }
      else if (hrxsTrans.length == 0 && !this.pricecontrol.isMSGcheckLab2nd) {
        this.pricecontrol.isMSGcheckLab2nd = true
        msg.push('來源機構未設置，完診將影響ic 每日上傳2.0 之正確結果')
        if (hrxsDispTps.length > 0 && !this.pricecontrol.isMSGcheckLab3rd) {
          if (hrxsDispTps.some(x => ![0, 1, 2, 4].includes(x))) {
            console.log('3');
            this.pricecontrol.isMSGcheckLab3rd = true;
            msg.push('且醫令標記設為 (6,7) 之檢驗醫令的"醫令調劑"應為 (0, 1, 2, 4) ，建議為4');
          }
        }
      }
      else if (hrxsDispTps.length > 0 && !this.pricecontrol.isMSGcheckLab3rd) {
        if (hrxsDispTps.every(x => ![0, 1, 2, 4].includes(x))) {
          this.pricecontrol.isMSGcheckLab3rd = true;
          console.log('4');
          msg.push('來源機構未設置，醫令標記設為 (6,7) 之檢驗醫令的"醫令調劑"應為 (0, 1, 2, 4) ，建議為4');
        }
      }
    }
    else if (this.labAddrCodeOpt.length > 0  && !this.pricecontrol.isMSGcheckLab3rd) {
      if (hrxsDispTps.some(x => ![0, 1, 2, 4].includes(x))) {
        this.pricecontrol.isMSGcheckLab3rd = true;
        msg.push('醫令標記設為 (6,7) 之檢驗醫令的"醫令調劑"應為 (0,1,2,4) ，建議為4');
      }
    }
    return msg;
  }
  //#endregion
  //#region 其他共用
  isNullOrEmpty(val: string): boolean {
    return val === undefined || val === '' || val === null
  }
  cntToday() {
    this.cntDate = DateHelper.today;
  }

  //#endregion

  async optionRefresh() {
    await this.resetOption();

  }

  onPCSxSettingClick() {
    this.isShowPCSxAllocationDialog = true;
  }

  async handlePCSxSelect(evt: string[]) {
    // 檢查選項數量是否超過 3 個
    if (evt.length > 3) {
      // 如果超過 3 個，減少到前 3 個
      evt = evt.slice(0, 3);
    }

    await this.getPCSxOption();
    this.changeDetectorRef.detectChanges();

    this.pcsxSelectedOptions = this.pcsxOption.filter(opt => evt.includes(opt.value));
    this.changeDetectorRef.detectChanges();

    this.sur = evt.join(',');
    this.updateSur();
  }
  // customInputValue: string;
  // customInputEnabled: boolean;
}
