<app-vis-dialog title="選擇項目" [show]="isDialogOpened" (close)="onDialogClose(false)"
  [width]="winWidth" [height]="winHeight" i18n-title="@@SelectOptions">
  <div style="display: flex;flex-direction: column; height: 400px;">
    <div class="allergy-search py-2" style="width: 100%;display: flex;">
      <mat-button-toggle-group
        style="width: 255px;"
        class="ml-1"
        [value]="currentTp"
        (change)="changeCodeType($event)">
        <ng-container *ngFor="let code of codeTypeList;">
          <mat-button-toggle class="patient-remark" [value]="code.value">{{code.text}}</mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
      <label style="flex-grow: 1;display: flex;height: 30px;align-items: center;">
        <span style="width: 40px;text-align-last: justify;margin-left: 5px;">代碼</span>
        <input [(ngModel)]="code" (change)="query()" class="ml-1 w-28"/>
        <div class="position-relative end-0 ml-2">
          <button type="submit"
            (click)="query()"
            i18n="@@BringIn"
            class="vis-btn-config p-0 position-relative pl-2 w-18"
            title="查詢">
            <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
          </button>
          <button type="button"
            (click)="onDialogClose(true)"
            i18n="@@BringIn"
            class="vis-btn-config p-0 position-relative pl-2 w-18"
            title="帶入">
            <mat-icon class="pref-icon vis-combine-icon">arrow_circle_down</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">帶入</span>
          </button>
          <button type="button"
            (click)="onDialogClose(false)"
            i18n="@@BringIn"
            class="vis-btn-config p-0 position-relative pl-2 w-18"
            title="取消">
            <mat-icon class="pref-icon vis-combine-icon">cancel</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">取消</span>
          </button>
        </div>
      </label>
    </div>

    <div class="inside-scrollbar p-0" style="max-height: 300px;">
      <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkip" [pageable]="true"
        [selectable]="true"
        [multiselect]="true"
        [selectByCheckOnly]="true"
         [kendoGridSelectBy]="'value'"
         (pageChange)="pageChange($event)" #grid >
        <app-vis-grid-checkbox-column (checkChanged)="checkChanged($event)" [field]="'checked'" showSelectAll="true" [width]="30"></app-vis-grid-checkbox-column>
        <app-vis-grid-column field="Code" title="代碼" i18n-title="@@Code" [width]="100" min-width="100"></app-vis-grid-column>
        <app-vis-grid-column field="ProdName" title="名稱" i18n-title="@@Name">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span style="white-space: pre-line; word-break: break-all;">{{ dataItem.ProdName }}</span>
            <button *ngIf="currentTp == 'AllergyGroup'" type="button" class="icon-btn" (click)="onDetailClick($event, dataItem)" title="明細" i18n-title="@@Detail" style="float: right; padding-top: 5px;"><mat-icon>settings</mat-icon></button>
          </ng-template>
        </app-vis-grid-column>
      </app-vis-grid>
    </div>

    <div class="p-2" style="white-space: pre-wrap;overflow-y: auto;flex-grow: 1;">
      <ng-container *ngFor="let code of codeTypeList;">
        <div style="display: flex;">
          <div style="width: 110px" class="d-inlin-flex extend-msg">[{{code.text}}]:</div>
          <ng-container *ngFor="let selected of userInput.history[code.value]">
            <mat-checkbox style="margin-right: 10px;" class="extend-label" [labelPosition]="'after'" [checked]="true" (change)="unCheck(code.value,selected)">{{selected}}</mat-checkbox>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</app-vis-dialog>

<app-vis-dialog title="過敏藥群組明細" [show]="isAllergyGroupOpened" (close)="onDetailClose()"
  [width]="winWidth" [height]="winHeight" i18n-title="@@SelectOptions">
  <div style="display: flex;flex-direction: column; height: 400px;">
    <app-vis-grid [data]="allergyGroupGridView" [pageSize]="detailPageSize" [skip]="detailPageSkip" [pageable]="true"
        (pageChange)="detailPageChange($event)">
      <app-vis-grid-column field="Code" title="群組代碼" i18n-title="@@Code" [width]="100" min-width="100"></app-vis-grid-column>
      <app-vis-grid-column field="RxCode" title="藥品代碼" i18n-title="@@RxCode" [width]="100" min-width="100"></app-vis-grid-column>
      <app-vis-grid-column field="ProdName" title="藥品名稱" i18n-title="@@ProdName"></app-vis-grid-column>
    </app-vis-grid>
  </div>
</app-vis-dialog>

