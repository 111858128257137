import { DengueFormDto, DengueUploadDto } from './../../services/api-service/hist/tocc-api';
import { PACSEmitterRecordDTO } from './../../services/api-service/pacs-emitter-record/pacsemitter-record-api.model';
import { Component, OnInit, HostListener, ViewChild, OnDestroy, ElementRef, TemplateRef, SimpleChanges, Input, Output, EventEmitter, NgZone, AfterViewInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
// shared module
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { BaseConfig, FunctionEnable } from 'src/app/services/base-config.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
// this module
import { WholeHist, WholeHistPatientsDto, WholeHistRegisterDto } from 'src/app/services/api-service/hist/whole-hist';
import { HistRecordPanel } from '../models/hist-record-panel';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { OrdersComponent } from '../orders/orders.component';
import { HistService } from '../services/hist.service';
import { HistView } from '../models/hist-view';
import { HistViewComponent } from 'src/app/shared/components/hist/hist-view/hist-view.component';
import { DiagsComponent } from '../diags/diags.component';
import { TmplView } from '../models/tmpl-view';
import { HistOrder } from '../models/hist-order';
import { RegisterConst,RegisterVaccineConst  } from 'src/app/shared/constants/register-const';
import { HistActionEnum } from '../enums/hist-action-enum';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { HistHelperService } from '../services/hist-helper.service';
import { HistHelperPadService, ItemSelectedEvent, AreaSelectedEvent, SetItemEvent } from '../services/hist-helper-pad.service';
import { Subject, Subscription } from 'rxjs';
import { HelperTextareaCCComponent } from '../helper-textarea/helper-textarea-cc.component';
import { HelperTextareaPEComponent } from '../helper-textarea/helper-textarea-pe.component';
import { Result } from '../rating-price/rating-price.component';
import { LocalStoragesService } from 'src/app/services/local-storages.service';
import { HelperPadComponent } from '../helper-pad/helper-pad.component';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { UserCache } from 'src/app/services/user-cache';
import { RemoveAt, RemoveFirst, deepCopy, escapeStr, isNullOrUndefined, keysToLowerCase, xmlFixSpecialChar } from 'src/app/shared/utilities';
import { FnKey, HotKeyService, Priority } from 'src/app/services/hotkey-service';
import { HelperEventTypeEnum } from '../enums/helper-event-type-enum';
import { HelperId } from '../models/helper-id';
import { HcrService } from 'src/app/services/hcr-service.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { Dx } from 'src/app/services/api-service/dx/dx';
import { HistErrorCode } from '../models/hist-error-code';
import { HistApi, nhiReq, nhiReqSubOne, nhiReqSubTwo, ddiData, ddiSubOne, ddiSubTwo, ddiSubThree, medPrtData, medPrtSubOne, medPrtSubTwo, medPrtSubThree, CheckResultAction, CheckResult, nhiReqV2, allergySubOne, specialSubOne } from 'src/app/services/api-service/hist/hist-api';
import { DeclareApi } from 'src/app/services/api-service/declare/declare-api';
import { EasyFormService } from 'src/app/services/easy-form-service';
import { FormFieldCollection } from 'src/app/shared/components/easy-form/form-define';
import { PrintService } from 'src/app/services/print.service';

import { CreateICXml_2 } from '../models/ic-xml';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { ClinicApi, NhiimiListDto } from 'src/app/services/api-service/clinic/clinic-api';
// import { LoginUser } from 'src/app/permission/models/login-user';
import { CreateXml } from 'src/app/shared/utilities';
import { NHIApi } from 'src/app/services/api-service/NHI/nhi';
import { HistDiag } from '../models/hist-diag';
import { IcHelper } from 'src/app/shared/helpers/ic-helper';
import { NHIRegIC } from 'src/app/services/api-service/register/nhiRegIc';
import { LackNegic } from 'src/app/services/api-service/hist/lackcardnegic';
import { NhiimiSetting } from 'src/app/system/clinic/models/nhiimiSetting';
import { FontGreatService } from 'src/app/services/font-great.service';
import { DemoIcHelper } from 'src/app/shared/helpers/demoic-helper';
import { HistListComponent } from '../hist-list/hist-list.component';
import { AccessCheckLevel, SignalRService } from 'src/app/services/signalr-service';
import { OpHelper } from 'src/app/registers/helpers/op-helper';
import { RegisterApi, emrDto } from 'src/app/services/api-service/register/register-api';
import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { VitalSignComponent } from '../vital-sign/vital-sign.component';
import { PrescriptionSave } from '../models/prescription-save';
import { IcViewComponent, MajorIll } from '../ic-view/ic-view.component';
import { StartUpService } from 'src/app/services/start-up-service';
import { HistSearchComponent } from '../hist-search/hist-search.component';
import { PageData } from 'src/app/shared/models/page-data';
import { CaseMethod, SatellitePharmacyServiceService } from 'src/app/services/satellite-pharmacy-service.service';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { DrugAllergyComponent } from 'src/app/shared/components/patients/drug-allergy/drug-allergy.component';
import { PatientRemarkDialogComponent } from 'src/app/shared/components/patients/patient-remark-dialog/patient-remark-dialog.component';
import { PsychSummaryDto, PsychiatryOrderListDto, RxApi } from 'src/app/services/api-service/rx/rx-api';
import { PriceOtherControl } from '../rating-price/models/priceothercontrol';
import { MemoryCache } from 'src/app/services/memory-cache-service';
import { PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { Referral } from 'src/app/services/api-service/referral/referral';
import { ReferralApi } from 'src/app/services/api-service/referral/referral-api';
import { HelpPadTab } from 'src/app/services/api-service/hist/help-pad-api';
import { DispensingTypeEnum, OrderDispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';
import { WebApiService } from 'src/app/services/web-api.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { UserApi } from 'src/app/services/api-service/user/user-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { FamilycaryApi } from 'src/app/services/api-service/nhiplan/familycary-api';
import { SendEmitData } from '../pacscreate-order/model/SendEmitData';
import { PACSReceiver } from 'src/app/shared/models/pACS-receiver';
import { RegistrationCodeView } from 'src/app/services/api-service/registration-code/registration-code-api.model';
import { PACSEmitterRecordApiService } from 'src/app/services/api-service/pacs-emitter-record/pacsemitter-record-api.service';
import { PACSStatusEnum } from 'src/app/services/api-service/pacs-emitter-record/pacsemitter-record-api.model';
import { TimeRaceHelper } from 'src/app/shared/helpers/timeout-race-helper';
import { CriticalIllness } from 'src/app/shared/hcr-lib/CriticalIllness';
import { CriticalIllnesNoHPC } from 'src/app/shared/hcr-lib/CriticalIllnesNoHPC';
import { FamilyCareDto, FamilyCareMutliDto } from 'src/app/services/api-service/nhiplan/familycaredto';
import { MatOption } from '@angular/material/core';
import { PricingApi } from 'src/app/services/api-service/pricing/pricing-api';
import { Hist } from '../models/hist';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';
import { ValidationTipComponent } from 'src/app/shared/components/validation-tip/validation-tip.component';
import { MedicationWayAndReceiptReportComponent } from 'src/app/shared/components/report/medication-way-and-receipt-report/medication-way-and-receipt-report.component';
import { FullAgePipe } from 'src/app/shared/pipes/age.pipe';
import { AnnounceAlertService } from 'src/app/services/announceAlert.service';
import { SessionService } from 'src/app/services/session.service';
import { RegisterWaitingListQueryTypeEnum } from 'src/app/enums/RegisterWaitingListQueryTypeEnum';
import { CSHISResultWithData } from 'src/app/shared/hcr-lib/CSHISResultWithData';
import { VPNGetRandomXandSignX } from 'src/app/shared/hcr-lib/VPNGetRandomXandSignX';
import { ToccKeyword, ToccNotFilled, ToccService } from 'src/app/services/tocc-service.service';
import { ToccApi } from 'src/app/services/api-service/hist/tocc-api';
import { ReceiptNewQueryDto } from 'src/app/payments/period-receipt/model/receiptquerylistdto';
import { HPCHelper } from 'src/app/shared/helpers/hpc-helper';
import { TreatNumNoICCard } from 'src/app/shared/hcr-lib/TreatNumNoICCard';

/** 從哪裡開啟此RecordComponent的類別 ：自行擴充，目前用來記錄從哪裡進來的，預設OPD，為了區別從申報進來做得一些特殊流程*/
declare type entranceType = 'OPD' | 'Declare';

class RegisterViewItem extends RegisterEditDto {
  constructor(data: RegisterEditDto) {
    super();
    Object.assign(this, data);
    this.PatientName = `${this.PatientFirstName || ''} ${this.PatientMiddleName || ''} ${this.PatientLastName || ''}`.trim().replace('  ', ' ');
  }
  PatientAge: number;
  PatientName: string;
}
interface DoctorRoom {
  roomCode: string;
  doctorId: number;
}
interface DoctorSection {
  sectionCode: string;
  doctorId: number;
}
/*
說明：
讀入OpdRecord，作為資料的初始值，
此元件(opd-record)為主畫面，各部分拆為子元件，各元件自己負責自己的FormGroup，
元件包含opd-diag、opd-helper-textarea、opd-hist-list、opd-hist-view、opd-orders、opd-vital-sign，
本元件不採用onPush策略，不然引用其他模組的元件會有問題，
但子元件都採用onPush策略，不然會有效能問題，
此元件把OpdRecord資料發送給各元件作為初始值
各元件資料變動時，不emit回OpdRecord，而是主元件存檔時，再從各元件取得最新資料，以增進效能。
*/
@Component({
  selector: 'app-hist-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css'],
  providers: [HistHelperService, HistHelperPadService]
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  //@ViewChild('medicationWayAndReceipt') medicationWayAndReceiptComponent: MedicationWayAndReceiptReportComponent;


  /** 為載入Component進入後，設定regId用(不是從Route進入) */
  @Input()
  set regId(value: any) {
    if (!isNullOrUndefined(value) && value > 0) {
      this.initRegId = value;
      this.getInfoWithComp();
    }
  }
  /** 是否為載入Component進入(不是從Route進入) */
  @Input()
  set isFromCompEnter(value: any) {
    if (!isNullOrUndefined(value)) {
      this.isCompEnter = value;
    } else {
      this.isCompEnter = false;
    }
  }
  /** 從哪裡開啟此RecordComponent，預設OPD，為了區別從申報進來做得一些特殊流程*/
  @Input() entranceType: entranceType = "OPD";
  /** 離開RecordComponent的Output事件。目前為載入Component進入，要離開時通知父層使用*/
  @Output() ExitHisRecordEvent = new EventEmitter();

  isEnabledPACS: boolean = false;
  isEnabledTOCC: boolean = false;
  private tempPACSSender?: PACSReceiver;
  diagnosisICD102023StartDate: Date;
  diagnosisICD102023ForceUse: boolean;
  drugsSelectorOpen: boolean = false;

  constructor(public printServiceForReferral: PrintService,
    private fb: FormBuilder,
    private fb2: FormBuilder,
    private notification: EasyNotificationService,
    private route: ActivatedRoute,
    private mainLayoutService: MainLayoutService,
    private histApi: HistApi,
    private pricingApi: PricingApi,
    private nhiApi: NHIApi,
    private rxApi: RxApi,
    private familycaryAPI: FamilycaryApi,
    private histService: HistService,
    private helperPadService: HistHelperPadService,
    private regApi: RegisterApi,
    private printService: LocalPrintService,
    private localStorage: LocalStoragesService,
    private userConfirmService: UserConfirmService,
    private hkService: HotKeyService,
    private hcrService: HcrService,
    private clinicApi: ClinicApi,
    private histHelperPadService: HistHelperPadService,
    private histHelperService: HistHelperService,
    private icHelper: IcHelper,
    private hpcHelper:HPCHelper,
    private demoIcHelper: DemoIcHelper,
    private declareApi: DeclareApi,
    private opHelper: OpHelper,
    private fontGreatService: FontGreatService,
    private ezForm: EasyFormService,
    private signalR: SignalRService,
    private webApiFactory: WebApiClient,
    private startUp: StartUpService,
    private refApi: ReferralApi,
    private cache: MemoryCache,
    private spService: SatellitePharmacyServiceService,
    private userApi: UserApi,
    private clinicDataService: ClinicDataService,
    private pACSEmitterRecordApiService: PACSEmitterRecordApiService,
    private cdr: ChangeDetectorRef,
    private annoService:AnnounceAlertService,
    private session:SessionService,
    private toccApi: ToccApi,
    private toccService: ToccService,
    private userConfirm: UserConfirmService
  ) {
    this.histApi.getEditOptions();
    this.pricingApi.GetEditOptions(true);
    this.histHelperService.fromSource.subscribe(evt => {
      if (evt.type == HelperEventTypeEnum.DiagChanged) {
        console.log('record recieve event value:', evt);
      }
    });
    this.apiSchedule = this.webApiFactory.createHisService('schedule/shiftschedule');
    this.apiSchedule.enableLoader = false;

    this.setUp();

  }

  private fnKeyEnable = true;
  private fnKeyRegistId: number;
  private f5RestoreId: string;

  //#region 參數 --------------------
  private unsubscribe: Subject<void> = new Subject();

  config = BaseConfig.getConfig();
  apiSchedule: WebApiService;

  // 網頁列印要產生的div頁面
  sizePage = {
    width: 21, //cm
    height: 30.5 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0, //cm
    left: 0 //cm
  };

  //editOptions: HistEditOption = new HistEditOption();
  get isDemoMode(): boolean {
    return this.hcrService.isDemoMode;
  }
  initRegId = 0; // 初始化時需要有 regId
  hasUploaded = false;
  fromHist = false;
  histRcd: WholeHist; // 初始化時取得的資料
  originHistRcd: WholeHist; // 初始化時取得的資料(Keeping)
  keepingHistRcd: WholeHist;
  priceControl = new PriceOtherControl(); // 只要按過一次完診即為 true
  referralOut: Referral;    // 轉診轉出資料
  //referralIn: Referral;
  isChangeOwn = false;
  refHistRcd: WholeHist;
  vsDisplayOrder = [];
  hrxsExecutor: HistOrder[];
  ErrorAndWarning = {
    UrgentHC: '',
  }
  isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  isRecoveryHome = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;

  /** 生理量測開關 */
  vitalsignToggle: boolean = false;
  /** 是否為載入Component進入(不是從Route進入) */
  isCompEnter: boolean = false;
  //get histParams() {
  //    return this.editOptions.histParams;
  //  }

  // helper
  get uiEventSourceEnum() {
    return HelperSourceEnum;
  }

  // UI
  get btnSaveDisabledAttr(): string {
    if (!this.histRcd || !this.histRcd.Register) {
      return null;
    }
    // if (this.histRcd.Register.StatusCode === RegisterConst.Code_Status_Finish) {
    //   return 'disabled';
    // }
    return null;
  }

  get registerDate(): Date {
    var registerDate: Date;
    if (this.firstSameTreatDate) {
      registerDate = this.firstSameTreatDate;
    } else {
      registerDate = this.histRcd ? (new Date(this.histRcd.Register.RegDate) ?? null) : null;
    }
    return registerDate;
  }

  get icd10Version(): string {
    return this.histService.ICD10Version(this.registerDate);
  }
  //#endregion 參數

  //#region 參數 child --------------------
  @ViewChild('remarkComp') private remarkComp: ElementRef<HTMLTextAreaElement>;
  @ViewChild('allergyComp') private allergyComp: ElementRef<HTMLTextAreaElement>;
  @ViewChild('ccComp') private ccComp: HelperTextareaCCComponent;
  @ViewChild('peComp') private peComp: HelperTextareaPEComponent;
  @ViewChild('chiAskComp') private chiAskComp: HelperTextareaPEComponent;
  @ViewChild('chiLookComp') private chiLookComp: HelperTextareaPEComponent;
  @ViewChild('chiPulseComp') private chiPulseComp: HelperTextareaPEComponent;
  @ViewChild('chiBianZhengComp') private chiBianZhengComp: HelperTextareaPEComponent;
  @ViewChild('chiTreatRuleComp') private chiTreatRuleComp: HelperTextareaPEComponent;
  @ViewChild('diagsComp') private diagsComp: DiagsComponent;
  @ViewChild('ordersComp') private ordersComp: OrdersComponent;
  @ViewChild('vsComp') private vsComp: VitalSignComponent;
  @ViewChild('histViewComp') private histViewComp: HistViewComponent;
  @ViewChild('reg') private regComp: TemplateRef<any>;
  @ViewChild('histListComp') private histListComp: HistListComponent;
  @ViewChild('ordersExecComp') private ordersExecComp: OrdersComponent;
  @ViewChild('icViewComp') private icViewComp: IcViewComponent;
  @ViewChild('histSearch') private histSearch: HistSearchComponent;
  @ViewChild('DrugAllergyComp') private DrugAllergyComp: DrugAllergyComponent;
  @ViewChild('PatientRemarkComp') private PatientRemarkComp: PatientRemarkDialogComponent;
  @ViewChild('nextRegTemp') private nextRegTemp: TemplateRef<any>;
  @ViewChild('toccUploadMessage') private toccUploadMessage: TemplateRef<any>;

  // PatientRemarkComp
  // @ViewChild('patEditComp') private patEditComp: PatientEditComponent;
  //#endregion 參數 child

  //#region 參數 layout --------------------
  isInnerClickOfTitle = false; // 此點擊是否點title
  mainLayoutHeightStyle = ''; // record元件的主要layout高度
  // isBottomFirst: 多的高度優先給Bottom，false時多的高度優先給middle
  isBottomFirst = true;
  // 只定義幾個會縮放的panel
  readonly pnl_cc = 'cc';
  readonly pnl_pe = 'pe';
  readonly pnl_chiAsk = 'chiAsk';
  readonly pnl_chiLook = 'chiLook';
  readonly pnl_chiPulse = 'chiPulse';
  readonly pnl_chiBianZheng = 'chiBianZheng';
  readonly pnl_chiTreatRule = 'chiTreatRule';
  readonly pnl_measure = 'measure';
  readonly pnl_rm = 'rm';
  readonly pnl_diag = 'diag';
  readonly pnl_alg = 'alg';
  readonly pnl_order = 'order';
  readonly pnl_hist = 'hist';

  pnlMiddle: HistRecordPanel = new HistRecordPanel();
  pnlLeft: HistRecordPanel = new HistRecordPanel();
  pnlInnerMiddle: HistRecordPanel = new HistRecordPanel();
  pnlRight: HistRecordPanel = new HistRecordPanel();
  pnlBottom: HistRecordPanel = new HistRecordPanel();
  pnlCC: HistRecordPanel = new HistRecordPanel();
  pnlMeasure: HistRecordPanel = new HistRecordPanel();
  pnlPE: HistRecordPanel = new HistRecordPanel();
  pnlChiAsk: HistRecordPanel = new HistRecordPanel();
  pnlChiLook: HistRecordPanel = new HistRecordPanel();
  pnlChiPulse: HistRecordPanel = new HistRecordPanel();
  pnlChiBianZheng: HistRecordPanel = new HistRecordPanel();
  pnlChiTreatRule: HistRecordPanel = new HistRecordPanel();
  pnlRm: HistRecordPanel = new HistRecordPanel();
  pnlDiag: HistRecordPanel = new HistRecordPanel();
  pnlAlg: HistRecordPanel = new HistRecordPanel();
  pnlOrder: HistRecordPanel = new HistRecordPanel('69%');
  pnlHistList: HistRecordPanel = new HistRecordPanel();

  // 欠卡專用 Regic
  nhiRegICData: NHIRegIC = new NHIRegIC();
  lackCardRegic: LackNegic = new LackNegic();
  //#endregion layout

  //#region window --------------------
  screenHeight = window.screen.availHeight;
  // 轉診單
  referralFormGroup: FormGroup = this.fb.group({});
  RegDateFG: FormGroup = this.fb.group({});

  isLoadTmplWinOpened = false;
  isPricingWinOpened = false;
  isPricingReady = false;
  isLabReportWinOpened = false;
  isDMWinOpened = false;
  isReferralWinOpened = false;
  isHelperPadOpened = false; // default 隱藏
  isDrugAlgWindowOpened = false;
  isRemarkWindowOpened = false;
  isTmplWinOpened = false;
  isChangeRegDateDialogOpened = false;
  today: Date = new Date();
  isCopy: boolean = false;
  //TotalDiagnosis: number = 4;

  // allowPrintReferral = true;
  // allowUploadNHI = true;

  isVitalSignchange = false;
  // for originhistRcd 取資料用的
  isOriginData = false;
  // for originhistRcd 取資料用的
  isCheckDX5DX6Data = false;
  patientId: number = 0;
  roomCode: string = '';
  get isChineseMode() {
    return this.histService.IsChineseMode;
  }
  get editOptions() {
    return this.histService.EditOptions;
  }
  isRecordDone :boolean;
  allergies: string[] = [];
  cln: Clinic;
  clnOthInfo: Clinic;
  ratingPriceResult = new Result();
  nhiimiSetting: NhiimiSetting;
  //psyOrderList: PsychiatryOrderListDto[] = [];  // 精神科診療醫令藥價清單
  signInToken: CSHISResultWithData<VPNGetRandomXandSignX>;
  samId: CSHISResultWithData<string>;
  hcId: CSHISResultWithData<string>;
  hpcId: CSHISResultWithData<string>;
  useNhiimiVersion: number = 1;//1: 雲端查詢系統1.0, 2: 雲端查詢系統2.0
  useNhiimi = false;
  useNhiimiSpe = false;
  /** 雲端藥歷(綠十字)開關 */
  openCloudHistLink: boolean = false;

  histLoadSubsc: Subscription;
  histFetchSubsc: Subscription;
  histTmplInsert: Subscription;
  // opdHistList: HistListItem[] = [];
  isShowRegDialog: boolean = false;
  isShowPatDialog: boolean = false;
  isShowPatDialogEdit: boolean = false;
  isShowDiagCertDiaglog: boolean = false;
  isFamilyCareDialog: boolean = false;
  isFamilySearchDialog: boolean = false;
  isFamilyCareNewDialog: boolean = false;
  isFamilyCareModifyDialog: boolean = false;
  changeToSelfPay: boolean = false;
  isCompReturn: boolean = false;

  isShowRxDialog: boolean = false;
  isShowSearch: boolean = false;
  isShowSearchDisabled: boolean = false;
  isShowAppointmentDialog: boolean = false;
  isShowPACSConnectDialog: boolean = false;
  isShowPACSCreateDialog: boolean = false;
  pACSCreateTriggerDate: Date = null;
  pACSCreateTriggerNumber: string = "";
  patData: PatientListDto;
  nextRegisters: any;
  goNextPat: boolean = false;

  useReferral: boolean = true;
  cacheKey_Seq = "Referral_Seq";
  keepPatientPhone: string = '';
  /** 診間名稱/代碼 */
  medRoom: ValueTextPair[] = [];
  /** 醫師名稱/Id */
  doctors: ValueTextPairNumberValue[] = [];
  /** 醫師所在診間 */
  doctorRoomMapping: DoctorRoom[];
  /** 看診時段的醫師 */
  deptMap: DoctorSection[];
  /** 洗腎跟重大傷病比對 */
  isKidneyDialyis = 0;
  majorInjuryCards = 0;
  majorInjuryFirstDate = new Date();
  /** 讀取遮罩 */
  showBlock = false;
  /** 家護醫師 */
  familyDoctors: ValueTextPair[] = [];
  familyDoctor: string;
  familyModifyDoctor: string;
  familyModifyCase: string;
  familyStartSeqNo: number;
  familyEndSeqNo: number;
  familyTotal = 0;
  /** 家護會員type */
  familyMemberTypeOpt: ValueTextPairNumberValue[] = [];
  /** 家護個案type */
  familyCaseTypeOpt: ValueTextPairNumberValue[] = [];
  /** 家護病患資料 */
  familyCareDto = new FamilyCareDto();
  familyCareList = new FamilyCareMutliDto();
  familyCareNewMemberList = new FamilyCareMutliDto();

  familyCareGridview: any;
  familyCareNewGridview: any;
  hst001: HST001Params;

  readonly toccNotFilled = ToccNotFilled;
  readonly toccKeyword = ToccKeyword;
  dengueFormDto = new DengueFormDto();
  dengueResult = '';
  dengueFlag: ('Y' | 'N' | '') = '';
  dengueRecordTime: Date = null;
  keepToccMessage = '';
  isShowTOCCUpload = false;
  firstSameTreatDate: Date;

  //#endregion

  // #region init --------------------
  setUp() {
    this.initOptions();
    this.histLoadSubsc = this.histService.onHistLoaded.subscribe(async data => {
      var restored = await this.histService.restore(data)
      if (restored) {
        data = restored;
      }
      await this.updateWholeHistwithUpstatus(data);
      this.histService.currentHist = data
      this.patientId = data.Patient.Id;
      this.roomCode = data.Register.RoomCode;
      this.firstSameTreatDate = await this.getFirstSameTreatDate();
      //移至批價完診 判斷完科別後
      //this.psyOrderList = await this.rxApi.GetPsychiatryRxList(this.histRcd.Patient.Id, this.histRcd.Hist.Id);
      await this.checkPH();  // 預防保健流程
      // 取得候診病患清單
      if (this.entranceType != "Declare" && !this.hasUploaded && !this.fromHist) { //不是從申報進入且未申報過
        this.getNextPats();
      }
      // 轉入院所寫入InHosp
      this.histRcd.Hist.InHosp = this.histRcd.Register.OriHosp ?? '';
      // 轉診轉入加算診察費
      if (this.histRcd.Register.OriHosp && this.histRcd.Register.RefDiagFee) {
        // 預防完診後修改，先檢查是否已有轉入醫令01038C，沒有的話補一筆
        if (!this.histRcd.Hist.Diags.find(x => x.code == '01038C')) {
          await this.addOrder('01038C');
        }
      }
      // 預取轉診轉出資料(如果有先前門診時轉出/轉入的資料)
      await this.getReferrals();
      await this.checkHouseCare();
      await this.checkSpecialMedWay();
      this.histService.beginStoreHist(() => {
        if (this.hasUploaded) {
          return null;
        }
        return this.createHistCopy();
      });
      await this.annoService.alertOnOpd(this.histRcd);
      this.dengueFormDto = new DengueFormDto();
      this.dengueResult = '';
      this.dengueFlag = '';
      if (this.isEnabledTOCC) {
        await this.queryDengueToCC(false);
        await this.getLastRecordTime();
      }
      // 過渡功能 #高雄 #登革熱 #TOCC
      else if(this.hst001.TOCCAddr){
        var ads = this.hst001.TOCCAddr.split(',').filter(x=>x);
        var patAddr = (this.histRcd.Patient.City??'') +(this.histRcd.Patient.Area??'') + (this.histRcd.Patient.Street??'');
        var clinic =  UserCache.getLoginUser().Clinic;
        var clinicAddr = (clinic.City??'') + (clinic.Area??'') + (clinic.Street??'');
        var toccAlert = this.session.getData('ToccAlert')??[];
        var user = UserCache.getLoginUser().UserId;
        if(ads.some(x=> clinicAddr.indexOf(x)>=0)
            && !toccAlert.some(x=>x.patient==0 && x.user == user && x.sec == this.histRcd.Register.TimeSec)){
          await this.userConfirmService.showAlert('高雄市政府公告','目前是登革熱流行期，且流感覺COVID-19疫情持續上升，針對有上呼吸道症狀者，如有任一疑似登革熱症狀或近兩周有東南亞地區活動史，請務必要執行登革熱快篩。')
          if(!this.histRcd.Hist.CC||this.histRcd.Hist.CC?.indexOf('[TOCC]:')<0){
            this.histRcd.Hist.CC = '[TOCC]:\n' + (this.histRcd.Hist.CC??'')
            this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.CC, { Name: this.histRcd.Hist.CC },false, null));
          }
          toccAlert.push({
            patient:0,
            user:user,
            sec:this.histRcd.Register.TimeSec
          })
        }else if(ads.some(x=>patAddr.indexOf(x)>=0 )
          && !toccAlert.some(x=>x.patient== this.histRcd.Patient.Id && x.user == user && x.sec == this.histRcd.Register.TimeSec)){
          await this.userConfirmService.showAlert('高雄市政府公告','經本局系統指定區域判斷，貴院所/該個案為登革熱TOCC名單，出現登革熱疑似症狀，請醫護配合「T.O.C.C」(旅遊史、職業別、接觸史及群聚情形)落實問診，並填寫「高雄市就醫民眾登革熱TOCC檢核表」。')
          if(!this.histRcd.Hist.CC||this.histRcd.Hist.CC?.indexOf('[TOCC]:')<0){
            this.histRcd.Hist.CC = '[TOCC]:\n' + (this.histRcd.Hist.CC??'')
            this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.CC, { Name: this.histRcd.Hist.CC },false, null));
          }
          toccAlert.push({
            patient:this.histRcd.Patient.Id,
            user:user,
            sec:this.histRcd.Register.TimeSec
            })
        }
        this.session.setData('ToccAlert',toccAlert);
      }
    });
    this.histFetchSubsc = this.histService.onHistFetch.subscribe(async rs => {
      await this.pullAllData();
      rs(this.histRcd)
    });

    this.histTmplInsert = this.histService.onTmplViewEmitInsert.subscribe(async t => {
      await this.onTmplViewEmitInsert(t);
    });
  }

  windowMax: number;

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.nowWidth = window.innerWidth;
      this.getRatio();
      this.bodyHeight = document.body.clientHeight;
      this.getDisabledBlockHeight();
    }, 500);
  }

  async ngOnInit() {
    this.histService.isHist = true;
    this.showLoader();
    this.RegDateFG = this.makeRegDateFormGroup();
    this.cln = UserCache.getLoginUser().Clinic;
    this.cln.Name
    this.nhiimiSetting = await this.clinicApi.GetNHIIMISettingAsync(this.cln.Id);

    let paramHst001 = await this.clinicDataService.getParam("HST001");
    if (paramHst001 && paramHst001.EnablePACS)
      this.isEnabledPACS = paramHst001.EnablePACS;

    this.isEnabledTOCC = await this.toccService.isEnabledTOCC(this.startUp.vpnState);

    if (this.nhiimiSetting != null) {
      this.useNhiimi = this.nhiimiSetting.UseNHIIMI.toString() == '0' ? false : true;
      this.useNhiimiSpe = this.nhiimiSetting.UseNHIIMISPE.toString() == '0' ? false : true;
      this.useNhiimiVersion = this.nhiimiSetting.UseNHIIMIVERSION;
    }
    this.getClinicInfo();
    try {
      if (this.isCompEnter && !isNullOrUndefined(this.initRegId) && this.initRegId > 0) {
        this.setLayoutHeight();     // 設定畫面layout的size
      } else {
        // layout init
        this.mainLayoutService.setPageTitle(''); // 隱藏page title那一行
        this.mainLayoutService.hideMenu();  // 隱藏功能選單
        this.setLayoutHeight();     // 設定畫面layout的size

        if (this.goNextPat) {
          await this.histService.loadHist(this.initRegId);  // 以 regId 取得門診病歷資料
        } else {
          if (this.initByRoute()) {  // 取得 routing 過來的 regId
            await this.histService.loadHist(this.initRegId);  // 以 regId 取得門診病歷資料
          }
        }

        this.closeLoader();   // 關閉 Loader 動畫
        this.registFnKey();   // 註冊快速功能鍵

        var used = await this.signalR.CheckAccessAvailable('record_' + this.initRegId, AccessCheckLevel.Clinic);
        if (used) {
          await this.userConfirmService.showAlert('警告', `此筆病歷正被${used}進行編輯中！`);
        }
        setTimeout(() => {
          this.focusToFirstArea();  // 設定 fouse 到第一區塊
        }, 1000);
      }
    } catch (ex) {
      this.notification.showError(ex);
      this.closeLoader();
    }
    this.windowMax = window.innerWidth;

    this.getRatio();
    // 開檢索功能
    this.isShowSearch = true;
    // ***********************************
    // 載入病歷完成以後才要做的行為 請移駕至
    // setUp() 這個方法的 this.histService.onHistLoaded 事件中
    // ***********************************
  }

  async getReferrals() {
    // 預取轉診轉出資料(如果有先前門診時轉出的資料)
    var d = await this.refApi.Get(this.initRegId);
    if (d) {  // 已有轉診資料
      // this.referralOut = d?.filter(x => x.Type == 1)[0];
      // this.referralIn = d?.filter(x => x.Type == 2)[0];
      this.referralOut = d[0];
      // this.referralOut.keepPhoneNo = this.keepPatientPhone;
    }
  }

  async onExitRegister(evt: any) {
    this.isCompReturn = true;
    this.isShowRegDialog = false;
    if (evt != 0) {
      this.initRegId = evt;
      await this.histService.loadHistForRegChange(this.initRegId);
      this.replaceRegister();
    }
    this.mainLayoutService.hideLoader();
  }

  replaceRegister() {
    if (this.histService.histForRegChange.Register) {
      var regData: WholeHistRegisterDto = deepCopy<WholeHistRegisterDto>(this.histService.histForRegChange.Register);
      this.histRcd.Register = regData;
    }
    this.notification.showInfo("請記得重新按【完診】確認資料更新。");
  }

  replacePatientData() {
    if (this.histService.histForRegChange.Patient) {
      var patData: WholeHistPatientsDto = deepCopy<WholeHistPatientsDto>(this.histService.histForRegChange.Patient)
      this.histRcd.Patient = patData;
    }
  }

  getNextPats() {
    var isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
    var orderRegDate = new Date(this.histRcd.Register.RegDate).toLocaleDateString();
    var qPageSize = 12;
    if (qPageSize > 0) {
      let query = {
        regDate: orderRegDate,
        startDate: isHomeCare ? orderRegDate : null,
        endDate: isHomeCare ? orderRegDate : null,
        room: this.histRcd.Register.RoomCode,
        timeSec: this.histRcd.Register.TimeSec,
        statusValues: RegisterConst.StatusCodeList_ForExamining,
        qType: RegisterWaitingListQueryTypeEnum.PatientNo,
        qCondition: '',
        pageSkip: 0,
        pageSize: qPageSize
      };
      this.regApi.GetAll(query).then((d: PageData) => {
        var listItems: RegisterViewItem[] = [];
        d.data.forEach((item) => {
          var viewItem = new RegisterViewItem(item);
          if (viewItem.PatientId != this.patientId) {
            listItems.push(viewItem);
          }
        });
        this.nextRegisters = listItems;
      });
    }
  }

  getPatient(waitNo: string, patName: string) {
    var ret = waitNo + '　' + patName;
    return ret;
  }

  getPatientInfo(evt: RegisterViewItem) {
    var icno = evt.IType == '3' && evt.SameTreat !== '2' ? (evt.ReCardDate != null ? '還卡' : '欠卡') : evt.Icno;
    var h = ("0" + new Date(evt.RegDate).getHours()).slice(-2);
    var m = ("0" + new Date(evt.RegDate).getMinutes()).slice(-2);
    var ret = '卡序:' + icno + (evt.Remark ? ('　護理登錄:' + evt.Remark) : '') + '　掛號時間:' + h + ':' + m + (evt.PatientBirth ? ('　生日:' + DateHelper.getROCDateStringWithSepetator(new Date(evt.PatientBirth), '-')) : '');
    return ret;
  }

  async onNextRegClick(reg: RegisterViewItem) {
    // check anything you want
    var used = await this.signalR.CheckAccessAvailable('record_' + reg.Id, AccessCheckLevel.Clinic);
    if (used) {
      await this.userConfirmService.showAlert('警告', `此病患正由${used}看診中！`);
      return;
    }

    // show dialog to decide a way 1. 存檔-選入下一位 2.不存檔-選入下一位 3.取消
    var act = await this.userConfirmService.showConfirm({
      title: '',
      msg: '將本患者看診資料存檔並選入 [' + reg.WaitNo + ' ' + reg.PatientName + ']？',
      textYes: '中斷儲存-選入下一位',
      textNo: '取消',
      width: 450,
      template: this.nextRegTemp,
      customBtnMid: [
        { text: '不存檔-選入下一位', return: 'e' }
      ]
    });

    if (act == true) {
      this.saveAndExit(false);
      // 選入下一位
      if (reg.ICode == '001' && reg.BeginTime != null) {
        await this.userConfirmService.showAlert('請確認以下訊息', '本病患是以【重大傷病】身分掛入\n請注意: 病名務必以此病患之【重大傷病卡記載】的病名相符才可申報，以免被核扣！');
      }
      this.goNextPat = true;
      this.initRegId = reg.Id;
      this.histService.changePatientHist(reg.Id);
    } else if (act == 'e') {
      await this.histApi.updateRegStatusByAction(this.histRcd.Register.Id, HistActionEnum.Exit, '');
      // 套完移除
      this.localStorage.removeItem('RegStatus_' + this.histRcd.Register.Id);
      // 選入下一位
      if (reg.ICode == '001' && reg.BeginTime != null) {
        await this.userConfirmService.showAlert('請確認以下訊息', '本病患是以【重大傷病】身分掛入\n請注意: 病名務必以此病患之【重大傷病卡記載】的病名相符才可申報，以免被核扣！');
      }
      this.goNextPat = true;
      this.initRegId = reg.Id;
      this.histService.changePatientHist(reg.Id);
    } else {
      // do nothing，staying this page
    }
  }

  /** 為載入Component進入用(不是從Route進入)，取得相關資料 */
  async getInfoWithComp() {
    this.showLoader();
    await this.histService.loadHist(this.initRegId);  // 以 regId 取得門診病歷資料

    // 檢查是否已經申報過，如果是 -> 門診畫面要鎖住，只有[主訴]可以編輯存檔
    var checkDecale = await this.declareApi.CheckDeclareXmlLog(
      UserCache.getLoginClinicId(),
      DateHelper.getFormatedDateString(this.histService.currentHist.Register.RegDate, false),
      false
    );
    this.hasUploaded = checkDecale;
    if (!this.helpPadDefaultHide()) {  // 非預設隱藏才開
      this.openHelperPadWin();
      // this.closeHelperPadWin();   // 0828 因功能還在調整->先預設隱藏
    } else {
      this.closeHelperPadWin();
    }
    this.registFnKey();   // 註冊快速功能鍵
    setTimeout(() => {
      this.focusToFirstArea();  // 設定 fouse 到第一區塊
    }, 500);
  }
  fontGreater: boolean = false;
  async ngAfterViewInit() {
    this.fontGreater = this.fontGreatService.getFontGreat();
  }
  /**
   * Get the clinci other info
   */
  async getClinicInfo() {
    this.clnOthInfo = await this.clinicApi.Get(this.cln.Id);
  }

  /**
   * 預防保健流程
  */
  async checkPH() {
    if (this.histRcd.Register.SameTreat != '2' || this.histRcd.Register.StatusCode == RegisterConst.Code_Status_Finish
      // || !(this.histRcd.Register.StatusCode == RegisterConst.Code_Status_Waiting
      //     || this.histRcd.Register.StatusCode == RegisterConst.Code_Status_Appointment
      //     || this.histRcd.Register.StatusCode == RegisterConst.Code_Status_Examining)
    ) {
      return;
    }

    var addL1001C = false;
    var icNo = this.histRcd.Register.Icno.replace('IC', '');
    var msg = '';
    if (['21', '22', '25'].indexOf(icNo) >= 0) {
      msg = `成人預防保健(${this.histRcd.Register.Icno})於1090928起，就醫年-出生年>=45歲且<=79歲者，可接受B、C肝炎篩檢，是否實施此醫令?`;

    } else if ('27' == this.histRcd.Register.Icno) {
      msg = `成人預防保健(${this.histRcd.Register.Icno})於1080601起，就醫年-出生年>=40歲且<=79歲者，可接受B、C肝炎篩檢，是否實施此醫令?`;

    }
    if (msg != '') {
      var opt = await this.histApi.GetProphylacticHealth(this.histRcd.Patient.Id, this.histRcd.Register.RegDate)
      if (opt?.some(o => o.value == '29')) {
        addL1001C = <boolean>await this.userConfirmService.showConfirm({
          title: '預防保健',
          msg: msg,
          width: 350
        });
      }
    }

    await this.addDx(icNo);
    await this.addOrder(icNo);
    if (addL1001C) {
      await this.addOrder('L1001C');
    }
    setTimeout(() => {
      this.focusToFirstArea();  // 設定 fouse 到第一區塊
    }, 300)
  }
  async checkSpecialMedWay() {
    var pDate = new Date(this.histRcd.Patient.Birthday)
    var regDate = new Date(this.histRcd.Register.RegDate);
    var dobYear = pDate.getFullYear();
    var regYear = regDate.getFullYear();
    var yearAge = regYear - dobYear;
    var isOriginResidents = this.histRcd.Patient.SpcIdentity.length > 0 ? this.histRcd.Patient.SpcIdentity[0] == '1' : false;

    if (this.histRcd.Register.SameTreat == '0' && this.histRcd.Register.StatusCode != 'F') {
      await this.addDx('N186')
      await this.callBackMajorinj();
    }
    else if (RegisterVaccineConst.vaccineCodes.indexOf(this.histRcd.Register.Icno)  >= 0) {
      await this.addDx('Z23')
      if (this.histRcd.Register.Icno == RegisterVaccineConst.Isqno_Vaccine_Pneumococcal && (yearAge >= 55 || yearAge <= 64) && isOriginResidents) {
        await this.addOrder('A3001C');
        await this.addOrder('K000492206');
      }
      else if (this.histRcd.Register.Icno == RegisterVaccineConst.Isqno_Vaccine_Pneumococcal && yearAge >= 65) {
        await this.addOrder('A3001C');
        await this.addOrder('K000492206');
      }
      else if (this.histRcd.Register.Icno == RegisterVaccineConst.Isqno_Vaccine_Child ) {
        await this.addOrder('A2051C');
      }
    }
  }
  async addDx(code: string) {
    try {
      var dx = await this.histApi.getDiagByApplyCode(code);
      if (this.histRcd.Hist.Dx1 == dx.Code) {
        return;
      }
      this.diagsComp.setCodeAtFirstEmpty(dx);
    } catch (ex) {
      await this.userConfirmService.showConfirm({
        title: '提示',
        msg: `尚未將診斷代碼[${code}]設定為院內用`,
        hideNo: true
      });
    }
  }
  /** 自動帶入醫令流程 (預防保健), 重複的不會新增 */
  async addOrder(code: string) {
    try {
      var order = await this.histApi.GetOrderByStdCodeAsync(code);
      if (this.histRcd.Hist.HRxs.some(hrx => hrx.RxCode == order.RxCode)) {
        return;
      }
      await this.ordersComp.addOrderByHelperEvent(new ItemSelectedEvent(HelperSourceEnum.Order, { rxType: [order.Type], include: ['RX'] }, order, true));
    } catch (ex) {
      console.log('ex', ex);
      await this.userConfirmService.showConfirm({
        title: '提示',
        msg: `尚未將醫令代碼[${code}]設定為院內用`,
        hideNo: true
      });
    }
  }

  /** 自動帶入醫令流程 (預防保健) */
  async addOrderByRxCode(rxCode: string) {
    try {
      var rx = await this.rxApi.GetRxByCode(rxCode);
      if (this.histRcd.Hist.HRxs.some(hrx => hrx.RxCode == rx.RxCode)) {
        return;
      }
      await this.ordersComp.addOrderByHelperEvent(new ItemSelectedEvent(HelperSourceEnum.Order, { rxType: [rx.Type], include: ['RX'] }, rx, true));
    } catch (ex) {
      await this.userConfirmService.showConfirm({
        title: '提示',
        msg: `尚未將醫令代碼[${rxCode}]設定為院內用`,
        hideNo: true
      });
    }
  }

  // detect change
  detectChanges() {
    // this.cd.detectChanges();
  }

  async unlockAll(evt: PointerEvent) {
    // evt.preventDefault();
    // evt.stopPropagation();
    if (this.hasUploaded) {
      var redir = await this.userConfirmService.showConfirm({
        title: '解鎖全部',
        msg: '【繼續】：解鎖已申報資料，離開本畫面並重新載入。\r\n 【取消】：返回補登病歷畫面。',
        textYes: '繼續',
        textNo: '取消',
        focus: false,
        width: 300
      });
      if (redir) {
        var d = await this.declareApi.DeleteDeclareXmlLog(
          UserCache.getLoginClinicId(),
          DateHelper.getFormatedDateString(this.histRcd.Register.RegDate, false)
        );

        if (d) {
          this.hasUploaded = false;
          this.notification.showInfo('您已解鎖病歷資料，請記得需重新申報');
          // var url = window.location.href.replace('h=1', 'h=0');
          // location.replace(url);
        } else {
          this.notification.showInfo('解鎖失敗');
        }
      } else {
        return;
      }
    }
  }


  // 視窗關閉時的提醒
  @HostListener('window:beforeunload', ['$event'])
  winBeforeUnload(event) {
    event.preventDefault();
    //event.returnValue = '是否要直接跳出';
    // 開發方便重新整理用，只讀環境的isDebug
    if (this.config.env.isDebug) {
      return true;
    }
    return false;
  }

  initByRoute(): boolean {
    // 來自路由的參數
    this.initRegId = this.route.snapshot.params['regId'];
    if (this.route.snapshot.params['h'] == '1') {
      this.hasUploaded = true;
    }
    if (this.route.snapshot.params['f'] && this.route.snapshot.params['f'] == 'hist') this.fromHist = true;
    // this.hasUploaded = true;  // for test
    if (!this.initRegId) {
      this.notification.showParameterError();
      return false;
    }
    return true;
  }

  // 取得編輯時需要的選項或數值
  private initOptions() {
    var data = this.histApi.EditOpt; // this.histService.EditOptions;
    if (data != null && data !== undefined) {
      //顯示生理測量
      if (data.histParams.VitalSignDisplay) {
        this.vsDisplayOrder = data.histParams.VitalSignDisplay.split(';');
      }
    }
  }


  updateWholeHist(data: WholeHist, b = false) {
    //this.makeFormGroup(); // 建立空的表單物件
    if (b) {
      this.fillFormData2(data); // 填充表格資料
    } else {
      this.fillFormData(data); // 填充表格資料
    }

    this.checkPaid(); // 檢查是否已付費
    // 更新掛號狀態為看診中
    // await this.histApi.updateRegStatusByAction(this.initRegId, HistActionEnum.EnterRoom, this.histRcd.Register.StatusCode);
    if (!this.helpPadDefaultHide()) {  // 非預設隱藏才開
      this.openHelperPadWin();
      // this.closeHelperPadWin();
    }
  }

  // 進入record html時
  async updateWholeHistwithUpstatus(data: WholeHist) {
    this.fillFormData(data); // 填充表格資料
    //  進入時 data.Register.Id 更新掛號狀態為看診中
    await this.histApi.updateRegStatusByAction(data.Register.Id, HistActionEnum.EnterRoom, this.histRcd.Register.StatusCode);
    if (!this.helpPadDefaultHide()) {  // 非預設隱藏才開
      this.openHelperPadWin();
    }

    if (this.entranceType != 'Declare') {
      // 從Cache取得衛星藥局IP設定
      this.histRcd.Hist.useSatellitePharmacy = false;  // default
      var hst001 = await this.clinicDataService.getParam("HST001");
      this.hst001 = hst001;

      const useSatellite = Number(this.session.getData('useSatellite'));
      let satelliteIP: string | undefined;

      if (useSatellite == 1) {
        satelliteIP = hst001.SatellitePhamacyIP;
      } else if (useSatellite == 2) {
        satelliteIP = hst001.OutVisitSatellitePhamacyIP;
      }

      if (!satelliteIP || satelliteIP.trim() == '') {
        this.histRcd.Hist.hasSatellitePharmacyIP = false;
      } else {
        this.histRcd.Hist.hasSatellitePharmacyIP = true;
      }
      // 從cache取得EMR Path設定

      this.histRcd.Hist.useEmr = false;
      if (!hst001.EMRXmlPath || hst001.EMRXmlPath == ' ') {
        this.histRcd.Hist.useEmr = false;
      } else {
        this.histRcd.Hist.useEmr = true;
      }
      this.histRcd.Hist.emrPath = hst001.EMRXmlPath;
      this.isRecordDone = this.histRcd.Register.StatusCode === RegisterConst.Code_Status_Finish;
      if (hst001) {
        this.ratingPriceResult.IsPrintCtrDrugs = hst001.IsPrintCtrDrugs;
        this.ratingPriceResult.IsPrintDetail = hst001.IsPrintDetail;
        // this.ratingPriceResult.IsPrintDispExam =  hst001.IsPrintDispExam; //目前預設為false
        // console.log('hst001.IsPrintDispExam',hst001.IsPrintDispExam);
        this.ratingPriceResult.IsPrintDispExam = hst001.IsPrintDispExam;
        this.ratingPriceResult.IsPrintDispPrescription = hst001.IsPrintDispPrescription;
        this.ratingPriceResult.IsPrintDrugBag = hst001.IsPrintDrugBag;
        // this.ratingPriceResult.IsPrintExam =hst001.IsPrintExam; //目前先預設為false
        this.ratingPriceResult.IsPrintExam = false;
        this.ratingPriceResult.IsPrintWay = hst001.IsPrintWay;;
        this.ratingPriceResult.IsPrintPrescription = hst001.IsPrintPrescription;
      }
    }
    // 第一次載入才塞值 this.isOriginData 為 true 時則不做
    if (!this.originHistRcd) {
      if (this.entranceType == "Declare") {
        this.histRcd.Hist.IsCardWrite = false;
      } else {
        this.histRcd.Hist.IsCardWrite = true;
      }
      this.originHistRcd = WholeHist.create(data);
      // console.log('this.originHistRcd', this.originHistRcd);
      this.mappingcolumn();

      this.isOriginData = true;
    }
  }
  // #endregion init

  // #region form --------------------
  // 設定選項或參數

  // 產生表單元件，並定義基本檢核規則
  // makeFormGroup(): FormGroup {
  //   const fg: FormGroup = this.fb.group({
  //     // patient
  //     Remark: [''],
  //     DrugAllergy: [''],
  //   });
  //   return fg;
  // }
  // 填入表單資料
  orderHrx: HistOrder[] = [];
  fillFormData(data: WholeHist) {
    if (!data) {
      return;
    }
    this.histRcd = WholeHist.create(data);
    this.keepingHistRcd = WholeHist.create(data);
    this.orderHrx = this.histRcd.Hist.HRxs;
    this.histRcd.VitalSign.birthday = this.histRcd.Patient.Birthday;
    var tmp = this.histRcd.Patient.DrugAllergy?.split(';') || [];
    this.allergies = tmp.length > 0 ? tmp[0].split(',') : [];   // 過敏藥
    // 取得ACT和過敏藥群組之RxCode
    var acts = (tmp.length > 1) ? (tmp[1].length > 0 ? tmp[1].split(',') : []) : [];       // ACT
    if (acts.length > 0) this.getRxCodeOfACT(acts);
    var algs = tmp.length > 2 ? (tmp[2].length > 0 ? tmp[2].split(',') : []) : [];         // 過敏藥群組
    if (algs.length > 0) this.getRxCodeOfAllergyGroup(algs);
    this.detectChanges();
    //this.lockReadonly();
  }

  fillFormData2(data: WholeHist) {
    if (!data) {
      return;
    }
    this.histRcd = WholeHist.create(data);
    this.keepingHistRcd = WholeHist.create(data);
    this.histRcd.VitalSign.birthday = this.histRcd.Patient.Birthday;

    var tmp = this.histRcd.Patient.DrugAllergy?.split(';') || [];
    this.allergies = tmp.length > 0 ? tmp[0].split(',') : [];
    // 取得ACT和過敏藥群組之RxCode
    var acts = (tmp.length > 1) ? tmp[1].split(',') : [];
    if (acts.length > 0) this.getRxCodeOfACT(acts);
    var algs = tmp.length > 2 ? tmp[2].split(',') : [];
    if (algs.length > 0) this.getRxCodeOfAllergyGroup(algs);
    this.detectChanges();
    //this.lockReadonly();
  }

  async getRxCodeOfACT(acts: string[]) {
    // 去開頭的空白
    acts = acts.map(x => {
      return x.trimLeft().trimRight();
    });
    // 去陣列內容為Empty的
    acts = acts.filter(x => x.length > 0);
    var data = await this.rxApi.GetRxATCListByCode(acts);
    if (data.length > 0) {
      data.forEach(item => {
        // console.log('getRxCodeOfACT >>>', item);
        this.allergies.push(item);
      });
    }
  }

  async getRxCodeOfAllergyGroup(algs: string[]) {
    // 去開頭的空白
    algs = algs.map(x => {
      return x.trimLeft().trimRight();
    });
    // 去陣列內容為Empty的
    algs = algs.filter(x => x.length > 0);
    var data = await this.rxApi.GetRxAllergyGroupByCode(algs);
    if (data.length > 0) {
      data.forEach(item => {
        // console.log('getRxCodeOfAllergyGroup >>>', item);
        this.allergies.push(item);
      });
    }
  }

  // 檢查是否已付費，必須在FillForm之後，因為FillForm內有真正的create Register物件
  checkPaid() {
    if (!this.histService.IsShowPricingWindow &&
      this.histRcd.Register.PaymentStatusCode == RegisterConst.Code_Payment_Paid) {
      this.notification.showWarningById('MSG_HistRecord2');
    }
  }
  // #endregion form
  bodyHeight = document.body.clientHeight;
  nowWidth: number = window.innerWidth;
  bigRatio: boolean = false;
  getRatio() {
    if (this.nowWidth < 1251) {
      this.bigRatio = true;
    } else this.bigRatio = false;
  }

  getSearchPadWidth(): string {
    let padWidth = window.innerWidth * .3 + 397 + 'px';
    return padWidth;
  }

  getDisabledBlockHeight(): string {
    const useBodyHeight = this.bodyHeight - 38;
    let disabledHeight: string = '';
    disabledHeight = useBodyHeight + 'px';
    return disabledHeight;
  }

  //#region window size --------------------
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    setTimeout(() => {
      this.setLayoutHeight();
      this.nowWidth = window.innerWidth;
      this.getRatio();
      this.bodyHeight = document.body.clientHeight;
      this.getDisabledBlockHeight();
    }, 500);
  }
  //#endregion

  //#region layout size --------------------
  middleCls = '';
  expandableHeight = '';
  // 設定主Layout高度
  async setLayoutHeight() {
    let p = await this.clinicDataService.getParam("OPD002");
    this.diagnosisICD102023StartDate = p.DiagnosisICD102023StartDate;
    this.diagnosisICD102023ForceUse = p.DiagnosisICD102023ForceUse;
    var dxRow = Math.ceil(p.TotalDiagnosis/2);
    this.middleCls = 'dx'+ dxRow*2;
    this.expandableHeight = `calc(50% - ${(30*dxRow+40)/2}px)`;
    const appTopBar = 35; // app main 保留給 top-bar的 固定高度
    const mainMarginBottom = 10; // record main margin bottom保留10px
    // 整個文件 扣掉 (1)top-bar, (2) record main margin bottom，剩下的就是record main可用高度
    const mainCanUseful = document.body.clientHeight - appTopBar - mainMarginBottom;
    const topArea = 62; // top-area 固定高度
    const middlePanel = 320; // middle panel 固定/最小高
    const bottomPanel = 215; // bottom panel 固定/最小高度
    const panelBorder = 6; // panel間的border吃掉的高度
    // main所需最小高度
    const mainMin = topArea + middlePanel + bottomPanel + panelBorder;
    // main高度
    let mainHeight = mainMin;
    if (mainCanUseful > mainMin) {
      mainHeight = mainCanUseful;
    }
    // 必須設定record main高度，不然內部的splitter pane會有不正常的狀況
    this.mainLayoutHeightStyle = mainHeight + 'px';

    if (this.isBottomFirst) {
      // middle panel設為固定，其他(多的)部份給bottom panel
      const bottomHeight = mainHeight - topArea - middlePanel - panelBorder;
      // this.pnlMiddle = new OpdRecordPanel(middlePanel + 'px');
      this.pnlBottom = new HistRecordPanel(bottomHeight + 'px');
    } else {
      // bottom panel設為固定，其他(多的)部份給middle panel
      const middleHeight = mainHeight - topArea - bottomPanel - panelBorder;
      this.pnlMiddle = new HistRecordPanel(middleHeight + 'px');
    }

  }
  //#endregion

  maxOrderOpen: boolean = false;
  maxRmOpen: boolean = false;
  maxCCOpen: boolean = false;
  minHist: boolean = false;

  //#region panel control --------------------
  maxPanel(name: string, open?: boolean) {
    if (name === this.pnl_order) {
      open = this.maxOrderOpen;
      this.maxOrderOpen = !this.maxOrderOpen;
    }
    if (name === this.pnl_rm) {
      open = this.maxRmOpen;
      this.maxRmOpen = !this.maxRmOpen;
      this.maxCCOpen = false;
    }
    if (name === this.pnl_cc) {
      open = this.maxCCOpen;
      this.maxCCOpen = !this.maxCCOpen;
      this.maxRmOpen = false;
    }
    if (name === this.pnl_hist) {
      open = this.minHist;
      this.minHist = !this.minHist;
    }
    // 檢查是否內部的按鈕事件
    if (this.isInnerClickOfTitle) {
      this.isInnerClickOfTitle = false;
      return;
    }
    // left內的最大化行為以left內部為主。不會放大left或bottom panels
    // bottom內只有Order，但bottom會放大，left要縮小。
    if (this.isThisMaxInLeftPanel(name)) {
      // 若已有最大化，就都回復預設
      if (this.isAlreadyMaxInLeftPanel()) {
        this.restoreForLeftPanel();
      } else {
        // 若無最大化，就最大化指定項目
        this.minLeftAllSubPanel();
        if (name === this.pnl_cc) {
          this.pnlCC.setMax();
        } else if (name === this.pnl_pe) {
          this.pnlPE.setMax();
        } else if (name === this.pnl_chiAsk) {
          this.pnlChiAsk.setMax();
        } else if (name === this.pnl_chiLook) {
          this.pnlChiLook.setMax();
        } else if (name === this.pnl_chiPulse) {
          this.pnlChiPulse.setMax();
        } else if (name === this.pnl_chiBianZheng) {
          this.pnlChiBianZheng.setMax();
        } else if (name === this.pnl_chiTreatRule) {
          this.pnlChiTreatRule.setMax();
        } else if (name === this.pnl_rm) {
          this.pnlRm.setMax();
        } else if (name === this.pnl_diag) {
          this.pnlDiag.setMax();
        } else if (name === this.pnl_alg) {
          this.pnlAlg.setMax();
        } else if (name === this.pnl_measure) {
          this.pnlMeasure.setMax();
        }
      }
    } else if (this.isThisMaxInBottomPanel(name)) {
      if (this.isAlreadyMaxInBottomPanel()) {
        this.restoreForBottomPanel();
      } else {
        if (name === this.pnl_order) {
          //this.maxBottomPanel();
          this.pnlOrder.setMax();
          this.pnlHistList.setMin();
        }
      }
    }
    return false;
  }
  // 此次max是否在LeftPanel
  isThisMaxInLeftPanel(name: string) {
    if (name === this.pnl_cc || name === this.pnl_pe ||
      name === this.pnl_chiAsk || name === this.pnl_chiLook ||
      name === this.pnl_chiPulse || name === this.pnl_chiBianZheng ||
      name === this.pnl_chiTreatRule ||
      name === this.pnl_rm || name === this.pnl_diag ||
      name === this.pnl_alg) {
      return true;
    } else {
      return false;
    }
  }
  // 此次max是否在BottomPanel
  isThisMaxInBottomPanel(name: string) {
    if (name === this.pnl_order) {
      return true;
    } else {
      return false;
    }
  }
  // LeftPanel是否已有任何視窗最大化
  isAlreadyMaxInLeftPanel() {
    if (this.pnlCC.isMax || this.pnlPE.isMax ||
      this.pnlChiAsk.isMax || this.pnlChiLook.isMax ||
      this.pnlChiPulse.isMax || this.pnlChiBianZheng.isMax ||
      this.pnlChiTreatRule.isMax ||
      this.pnlRm.isMax || this.pnlAlg.isMax || this.pnlDiag.isMax) {
      return true;
    } else {
      return false;
    }
  }
  // BottomPanel是否已有任何視窗最大化
  isAlreadyMaxInBottomPanel() {
    if (this.pnlOrder.isMax) {
      return true;
    } else {
      return false;
    }
  }
  // 所有左邊子視窗最小化
  minLeftAllSubPanel() {
    this.pnlCC.setMin();
    this.pnlPE.setMin();
    this.pnlChiAsk.setMin();
    this.pnlChiLook.setMin();
    this.pnlChiPulse.setMin();
    this.pnlChiBianZheng.setMin();
    this.pnlChiTreatRule.setMin();
    this.pnlRm.setMin();
    this.pnlDiag.setMin();
    this.pnlAlg.setMin();
  }
  // 最大化下面視窗
  maxBottomPanel() {
    this.pnlMiddle.setMin();
    this.pnlBottom.setMax();
  }
  restoreForLeftPanel() {
    // left panel內部的縮放不影響其他panel
    this.pnlCC.setDefault();
    this.pnlPE.setDefault();
    this.pnlChiAsk.setDefault();
    this.pnlChiLook.setDefault();
    this.pnlChiPulse.setDefault();
    this.pnlChiBianZheng.setDefault();
    this.pnlChiTreatRule.setDefault();
    this.pnlRm.setDefault();
    this.pnlDiag.setDefault();
    this.pnlAlg.setDefault();
  }
  restoreForBottomPanel() {
    // bottom panel內部的縮放，會影響left與bottom panel
    this.pnlMiddle.setDefault();
    this.pnlBottom.setDefault();

    this.pnlOrder.setDefault();
    this.pnlHistList.setDefault();
  }
  //#endregion

  //#region sidebar --------------------
  btnSidebarSwitchClick() {
    if (this.pnlRight.isMin) {
      this.pnlRight.setMax();
    } else {
      this.pnlRight.setMin();
    }
  }
  //#endregion

  // #region action --------------------
  validate() {
    if (!this.histService.IsEnableFrontValidation) {
      return true;
    }
    var errors = this.histRcd.validate();
    let tmpMsg = '';
    if (errors && errors.length > 0) {
      var warning = errors.filter(e => e.Code == HistErrorCode.OrderFilled);

      if (warning.length > 0) {
        errors = errors.filter(e => e.Code !== HistErrorCode.OrderFilled);
        //this.notification.showWarning('自動填補缺漏的劑量/頻率/日數 - ' + warning.map(w => w.Args.join(',')).join(','));
      }
      if (errors.length > 0) {
        errors.forEach(errorResult => {
          const errorMsg = this.notification.getTextById(errorResult.Code);
          if (errorMsg) {
            tmpMsg += errorResult.CreateFormatMessage(errorMsg) + ' ';
          }
        });
        // 驗證失敗訊息
        this.notification.showError(tmpMsg);
        return false;
      }
    }
    return true;
  }

  // removeFirstElement(originalArray: HistOrder[], rxcode: string): any {
  //   const elementIndex = originalArray.findIndex(item => item.RxCode.includes(rxcode));
  //   let modifiedArray: any
  //   if (elementIndex !== -1) {
  //     modifiedArray = [
  //       ...originalArray.slice(0, elementIndex),
  //       ...originalArray.slice(elementIndex + 1)
  //     ];
  //   } else {
  //     modifiedArray = [...originalArray];
  //   }
  //   return modifiedArray
  // }


  // 為剛進入時 histRcd 去 mapping 送出時對照的欄位，欄位順序會影響最終新舊資料的判斷
  mappingcolumn() {
    var Diags = this.originHistRcd.Hist.Diags;
    // console.log('Diags', Diags);
    var newdiag = [];
    for (var i = 1; i <= 4; i++) {
      var diag = Diags[(i - 1)];
      // console.log('Diags-item', diag);
      var item = {
        "no": i,
        "code": diag?.code ?? "",
        "name": diag?.name ?? ""
      };
      newdiag.push(item);
    }
    this.originHistRcd.Hist.Diags = newdiag;

    this.originHistRcd.VitalSign.LMPDate = this.originHistRcd.VitalSign.LMPDate ?? null;
    this.originHistRcd.VitalSign.DueDate = this.originHistRcd.VitalSign.DueDate ?? null;

    this.originHistRcd.Hist.IsCardWrite = true;
    this.originHistRcd.Hist.CC = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.CC);
    this.originHistRcd.Hist.PE = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.PE);
    this.originHistRcd.Hist.ChiAsk = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.ChiAsk);
    this.originHistRcd.Hist.ChiLook = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.ChiLook);
    this.originHistRcd.Hist.ChiPulse = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.ChiPulse);
    this.originHistRcd.Hist.ChiBianZheng = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.ChiBianZheng);
    this.originHistRcd.Hist.ChiTreatRule = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.ChiTreatRule);
    this.originHistRcd.Hist.Dx1 = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx1);
    this.originHistRcd.Hist.Dx1Name = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx1Name);
    this.originHistRcd.Hist.Dx2 = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx2);
    this.originHistRcd.Hist.Dx2Name = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx2Name);
    this.originHistRcd.Hist.Dx3 = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx3);
    this.originHistRcd.Hist.Dx3Name = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx3Name);
    this.originHistRcd.Hist.Dx4 = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx4);
    this.originHistRcd.Hist.Dx4Name = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx4Name);
    if (this.isCheckDX5DX6Data) {
      this.originHistRcd.Hist.Dx5 = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx5);
      this.originHistRcd.Hist.Dx5Name = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx5Name);
      this.originHistRcd.Hist.Dx6 = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx6);
      this.originHistRcd.Hist.Dx6Name = this.appendSpaceIfNotEmpty(this.originHistRcd.Hist.Dx6Name);
    }

    // this.originHistRcd.Hist.HRxs = this.ordersComp.orders;
    // this.originHistRcd.Hist.HRxs = this.ordersComp.orders;
    this.originHistRcd.VitalSign.birthday = this.originHistRcd.Patient.Birthday;
  }

  safeGetContent(obj: any) {
    if (obj && obj.content) {
      return obj.content;
    } else {
      return '';
    }
  }

  async pullAllData() {
    if (!this.histService.histUIState.All) {
      this.notification.showWarning('資料載入中，請稍後…');
      throw 'UI Not Ready';
      return
    }
    //this.histRcd.Patient.Remark = this.editFV.Remark;
    //this.histRcd.Patient.DrugAllergy = this.editFV.DrugAllergy;
    // 子元件資料不emit，parent在save時主動抓取資料
    this.histRcd.Hist.CC = this.getCCFromComponents();
    this.histRcd.Hist.PE = this.getPEFromComponents();
    this.histRcd.Hist.ChiAsk = this.safeGetContent(this.chiAskComp);
    this.histRcd.Hist.ChiLook = this.safeGetContent(this.chiLookComp);
    this.histRcd.Hist.ChiPulse = this.safeGetContent(this.chiPulseComp);
    this.histRcd.Hist.ChiBianZheng = this.safeGetContent(this.chiBianZhengComp);
    this.histRcd.Hist.ChiTreatRule = this.safeGetContent(this.chiTreatRuleComp);
    await this.pullDiags();
    this.pullOrders();
    this.pullVitalSign();
    this.detectChanges();
  }

  getCCFromComponents() {
    return this.safeGetContent(this.ccComp)
  }
  getPEFromComponents() {
    return this.safeGetContent(this.peComp);
  }

  // 由子元件取回Diag資料
  async pullDiags() {
    this.histRcd.Hist.Diags = await this.getDiagsFromComponents();
    this.histRcd.Hist.restoreByDiags();
  }
  async getDiagsFromComponents(): Promise<HistDiag[]> {
    let tempDiags: HistDiag[] = Object.assign([], await this.diagsComp.pullDiags());
    tempDiags = tempDiags.filter(x => x.code !== null && x.code !== '');
    var diags: HistDiag[] = [];
    tempDiags.forEach((diag) => {
      if (!diag) {
        return;
      }
      diag.code = diag.code.trimRight();
      diags.push(diag);
    });
    return diags;
  }
  getDiagsFromComponentsNoCheck() {
    let tempDiags = Object.assign([], this.diagsComp.diags);
    tempDiags = tempDiags.filter(x => x.code !== null && x.code !== '');
    var diags = [];
    tempDiags.forEach((diag) => {
      if (!diag) {
        return;
      }
      diag.code = diag.code.trimRight();
      diags.push(diag);
    });
    return diags;
  }
  // 由子元件取回Orders資料
  pullOrders() {
    let tempHRxs = this.getRxFromComponents();
    tempHRxs = tempHRxs.filter(x => x.RxCode !== null && x.RxCode !== '');
    let hRxsLab = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 6 || x.RxType == 7)  && x.SPRule > 5 && !!x.TransOri)
    for (var i = 0; i < tempHRxs.length; i++) {
      // 調整 SPRule null => 0
      if (tempHRxs[i].SPRule == null || tempHRxs[i].SPRule == "") {
        tempHRxs[i].SPRule = 0;
      }
      // 調整 DispTP null => ""
      if (tempHRxs[i].DispTP == null) {
        tempHRxs[i].DispTP = "";
      }
      // 調整排序
      tempHRxs[i].Sort = tempHRxs.length - i;
      tempHRxs[i].RxClass = '醫令';
      let hrxLab = hRxsLab.find(x => x.RxCode == tempHRxs[i]?.RxCode);

      if (hrxLab) {
        tempHRxs[i].TransOri = hrxLab.TransOri;
        hRxsLab = RemoveFirst(hRxsLab, tempHRxs[i]?.RxCode, (item, code) => item.RxCode.includes(code))
        //hRxsLab = this.removeFirstElement(hRxsLab, tempHRxs[i]?.RxCode);
      }
    }
    this.histRcd.Hist.HRxs = tempHRxs;
  }
  getRxFromComponents() {
    return Object.assign([], this.ordersComp.orders);
  }

  // 由子元件取回Vital Sign資料
  pullVitalSign() {
    this.histRcd.VitalSign = this.getVitalSignFromComponent();
  }
  getVitalSignFromComponent() {
    return Object.assign(this.histRcd.VitalSign, this.vsComp.editFV);
  }
  /** freq free下 查無視為ASORD */
  findDosage(dosages: Dosage[], freq: string): Dosage {
    if (!dosages || !freq) {
      return null;
    }
    let ds1 = dosages.find(ds => ds.Code.toUpperCase() === freq.toUpperCase());

    if (HistService.EnableFreqFree && ds1 == null) {
      ds1 = dosages.find(ds => ds.Code.toUpperCase() === 'ASORD');
    }
    return ds1;
  }

  // prescriptions: PrescriptionDetailOutPatient[] = [];
  async save(action: HistActionEnum): Promise<boolean> {
    this.showLoader();
    //this.unlockReadonly();
    this.histRcd.Action = action;
    this.originHistRcd.Action = action;
    this.validateToSave();
    const submitData = this.histRcd;
    if (!submitData) {
      return false;
    }
    if (!this.validate()) {
      // 驗證失敗
      return false;
    }
    let sp = this.saveBeforeCheckSpecial();
    if (!sp) return false;

    if (JSON.stringify(this.originHistRcd) === JSON.stringify(submitData) && action == HistActionEnum.Save) {
      this.notification.showWarning('欄位未更動，請用不存離開');
      return false;
    }
    // if (JSON.stringify(this.originHistRcd) !== JSON.stringify(submitData) && action == HistActionEnum.Save) {
    //   this.notification.showInfo('欄位有更動，不需儲存');
    //   return;
    // }
    try {
      var isType3Recard = (this.histRcd.Register.IType === '3' && (this.histRcd.Register.ReCardDate != null && this.histRcd.Register.ReCardDate != undefined))
      // 欠卡(3)且'完診'  如果該筆欠卡日期跟識別碼存了就不處理
      if (this.histRcd.Register.IType === '3' && !this.histRcd.Hist.LackDate && action === HistActionEnum.Finish) {
        try {
          // 欠卡要取無卡就醫識別碼
          this.icHelper.recall = true;
          if (!this.isDemoMode) {
            var treatNo = await this.icHelper.getTreatIdNoIC((this.histRcd.Patient.MomCId != null && this.histRcd.Patient.MomCId != '') ? this.histRcd.Patient.MomCId : this.histRcd.Patient.CId);
          } else {
            treatNo = await this.demoIcHelper.getTreatIdNoIC((this.histRcd.Patient.MomCId != null && this.histRcd.Patient.MomCId != '') ? this.histRcd.Patient.MomCId : this.histRcd.Patient.CId);
          }
          if (!treatNo && this.icHelper.recall) {
            treatNo = await this.icHelper.getTreatIdNoIC((this.histRcd.Patient.MomCId != null && this.histRcd.Patient.MomCId != '') ? this.histRcd.Patient.MomCId : this.histRcd.Patient.CId);
            if (!treatNo && this.icHelper.recall)
              treatNo = await this.icHelper.getTreatIdNoIC((this.histRcd.Patient.MomCId != null && this.histRcd.Patient.MomCId != '') ? this.histRcd.Patient.MomCId : this.histRcd.Patient.CId);
            if (!treatNo && this.icHelper.recall) {
              treatNo = await this.icHelper.getTreatIdNoIC((this.histRcd.Patient.MomCId != null && this.histRcd.Patient.MomCId != '') ? this.histRcd.Patient.MomCId : this.histRcd.Patient.CId);
              this.icHelper.recall = false;
            }
            if (!treatNo) {  // 重取3次失敗，不是身分證號錯誤 -> 不繼續往下走
              this.notification.showError('欠卡就醫識別碼重取3次失敗，請確認展望主控台是否正常運作');
              this.closeLoader();
              return false;
            }
          }
          else if (!treatNo) {
            // treatNo = new TreatNumNoICCard();
            // treatNo.TreatNum = 'MISS0000000000000000';
            // treatNo.DateTime = new Date();
            this.closeLoader();
            // return false;
          }
          // 欠卡只存取 LackTreatnum, LackDate(還卡取就醫識別碼用) NHIDeclare用到
          this.histRcd.Hist.LackTreatnum = (treatNo?.TreatNum ?? 'MISS0000000000000000');
          this.histRcd.Hist.LackDate = (treatNo?.DateTime ?? new Date());
        } catch (ex) {
          this.notification.showError('離線就醫識別碼發生錯誤：' + ex);
          return false;
        }
      }
      // console.log('treatNo',submitData,this.histRcd);
      var data = await this.histApi.save(this.histRcd);

      if(data.HistoryId > 0) this.histRcd.Hist.Id = data.HistoryId;
      // 如果是轉診轉出，必須讀卡補一筆3i(NHIRegIC)
      var hasReferral3I = await this.nhiApi.HasReferral(this.histRcd.Register.Id); // 檢查是否已有轉診補刷卡檔(3I)資料
      if (!hasReferral3I) { // 沒有轉診(出)補刷卡檔(3I)資料才須補資料
        if (this.histRcd.Hist.Referral == 'Y') {
          var nhiRegICData: NHIRegIC = new NHIRegIC();
          var monCId = this.histRcd.Patient.MomCId;
          var BirthsNum = this.histRcd.Patient.BirthsNum;
          var bbTreat = monCId ? this.icHelper.getBabyTreat(BirthsNum, this.histRcd.Patient.Sex) : ' ';

          if (this.histRcd.Register.ICode != '000' && this.histRcd.Register.IType !== '1') {
            // 非ICode=000(自費)且不能讀卡(主要是異掛的情況) => 不需查【IC卡掛號刷卡資料檔】(呼叫API取得掛號時的資料來補DA的3I資料)
            var nhiData = await this.nhiApi.GetNHIData(this.histRcd.Register.Id);
            if (!this.isDemoMode) {
              var treatNo = await this.icHelper.getTreatIdNoIC((this.histRcd.Patient.MomCId != null && this.histRcd.Patient.MomCId != '') ? this.histRcd.Patient.MomCId : this.histRcd.Patient.CId);
            } else {
              treatNo = await this.demoIcHelper.getTreatIdNoIC((this.histRcd.Patient.MomCId != null && this.histRcd.Patient.MomCId != '') ? this.histRcd.Patient.MomCId : this.histRcd.Patient.CId);
            }

            if (nhiData.RegIc) {  // 複製刷卡紀錄(異掛會有資料，欠卡不會有刷卡紀錄) => 補一筆DA轉診的3I資料
              if (monCId == '') {
                nhiRegICData.Cid = this.histRcd.Patient.CId;
              } else {
                nhiRegICData.Cid = monCId;
                nhiRegICData.BabyDate = DateHelper.formatROCDate(this.histRcd.Patient.Birthday, false, false);
                nhiRegICData.BirthsNum = BirthsNum;
                nhiRegICData.BabyMark = bbTreat;
              }
              nhiRegICData.RegId = this.histRcd.Register.Id;
              nhiRegICData.Icdt = DateHelper.getROCDateTimeString(treatNo.DateTime);
              nhiRegICData.IreCard = '1';
              nhiRegICData.Nhictp = 'DA';
              nhiRegICData.Isqno = '';
              nhiRegICData.Isam = nhiData.RegIc.Isam;
              nhiRegICData.Isgn = nhiData.RegIc.Isgn;
              nhiRegICData.Ihcd = nhiData.RegIc.Ihcd;
              nhiRegICData.Itreatnum = treatNo.TreatNum;

              // Call Api
              var r = await this.histApi.SaveReferralIC(nhiRegICData);
              if (!r.IsSuccess) {
                this.notification.showError('轉診轉出：' + r.Message);
                this.closeLoader();
                return false;
              }
            }
          } else {  // 可讀卡 => 以實際讀卡資料補轉診3I資料
            if (!this.isDemoMode) {
              var icData = await this.icHelper.getSeqNumber('DA', bbTreat)
            } else {
              var icData = await this.demoIcHelper.getSeqNumber('DA', bbTreat);
            }
            if (icData) {  // 有取得就醫序號 => 補一筆DA轉診的3I資料
              if (monCId == '') {
                nhiRegICData.Cid = this.histRcd.Patient.CId;
              } else {
                nhiRegICData.Cid = monCId;
                nhiRegICData.BabyDate = DateHelper.formatROCDate(this.histRcd.Patient.Birthday, false, false);
                nhiRegICData.BirthsNum = BirthsNum;
                nhiRegICData.BabyMark = bbTreat;
              }
              nhiRegICData.RegId = this.histRcd.Register.Id;
              nhiRegICData.Icdt = DateHelper.getROCDateTimeString(icData.ClinicDateTime);
              nhiRegICData.IreCard = '1';
              nhiRegICData.Nhictp = 'DA';
              nhiRegICData.Isqno = icData.Number;
              nhiRegICData.Isam = icData.SamId;
              nhiRegICData.Isgn = icData.SecuritySeal;
              nhiRegICData.Ihcd = icData.ClinicCode;
              nhiRegICData.Itreatnum = icData.TreatId;

              // Call Api
              var r = await this.histApi.SaveReferralIC(nhiRegICData);
              if (!r.IsSuccess) {
                this.notification.showError('轉診轉出：' + r.Message);
                this.closeLoader();
                return false;
              }
            }
          }
        }
      }

      // this.prescriptions = data.PrescriptionSignMultiWrite.Prescriptions;
      if (this.isCompEnter) {
        this.ExitHisRecordEvent.emit(this.initRegId);
      }
      // this.notification.showOk(); // 顯示存檔成功
      if (action === HistActionEnum.Finish) {
        // 補上Server端計算完的部分負擔代碼
        this.histRcd.Hist.ApartCode = data.APartCode;
        //  0.自費 1.IC卡 2.異常掛號 3.欠卡未還卡, 除了IC,欠卡已還卡以外其他都不用寫卡 ; 批價時是否寫卡不勾選也不做後續寫卡動作
        // 展示模式下不去呼叫卡機相關api --- this.isDemoMode

        if ((this.histRcd.Register.IType !== '1' && !isType3Recard) || !this.histRcd.Hist.IsCardWrite
        //|| this.isDemoMode
          ) {
          // console.log('gothisway');
          return true;
        }

        // 處理寫卡
        // console.log('寫卡 +++++', data.MultiPrescriptWrite)
        try {
          var st = await this.icHelper.getAPI().GetCardStatusHC();

          if (st.StatusCode !== 0) {
            this.notification.showError('健保卡取得錯誤：' + st.ErrorMsg);
            return true;
          }
          else if (st.StatusCode === 0 && st.Data === '卡片未置入') {
            this.notification.showWarning('健保卡未置入，請確認患者健保卡是否讀取成功');
            return true;
          }
        } catch (ex) {
          this.notification.showError('健保卡取得錯誤：' + ex);
          return true;
        }

        try {
          let prescriptionSaveDataList: PrescriptionSave[] = [];
          // 有需要更新異動才打api
          if (data.PrescriptionSignMultiWrite?.Prescriptions?.length > 0) {
            this.notification.showWarning('IC卡醫令寫入中...請勿拔出病患卡片，以確保醫令簽章正常產生!');

            var ret = await this.icHelper.getAPI().WriteMultiPrescriptSign(data.PrescriptionSignMultiWrite);

            if (ret.StatusCode != 0) {
              this.notification.showError('健保卡寫入錯誤：' + ret.ErrorMsg);
            }
            else if (ret.StatusCode == 0 && ret.Data) {
              // if (data.PrescriptionSignMultiWrite.Prescriptions.length > 0) {
              let pescriptionSign = data.PrescriptionSignMultiWrite.Prescriptions;
              // let prescriptionSaveDataList :PrescriptionSave[] = [];

              for (let i = 0; i < pescriptionSign.length; i++) {
                let prescriptionSaveData = new PrescriptionSave();
                prescriptionSaveData.RegicId = data.RegicId;
                prescriptionSaveData.NhiDrugCode = pescriptionSign[i].NhiDrugCode;
                prescriptionSaveData.ClinicDateTime = pescriptionSign[i].ClinicDateTime;
                prescriptionSaveData.Isgn = ret?.Data[i] ?? '';
                prescriptionSaveDataList = [...prescriptionSaveDataList, prescriptionSaveData];
                // 在这里对 currentItem 进行操作
              }
              await this.histApi.saveIgsn(prescriptionSaveDataList);
              // }
            }
          }

          // console.log('ret +++++', ret);
          this.notification.showWarning('IC卡診斷及費用寫入中...請勿拔出病患卡片，以避免資料寫入不完全');
          var ret2 = await this.icHelper.getAPI().WriteTreatmentData(data.TreatmentData);
          // 完診後，再次修改>完診 會寫入錯誤(是否是因為測試卡?)
          // 仿 HIS 不攔截寫卡失敗錯誤 5022 和 5023
          if (ret2.StatusCode != 0 && ret2.StatusCode != 5022 && ret2.StatusCode != 5023 && ret2.StatusCode != 5028) {
            this.notification.showError('健保卡寫入錯誤：' + ret2.ErrorMsg);
          }else{
            // 更新寫卡時間
            await this.histApi.UpdateICWriteTime(data.RegId);
          }

          // 有需要異動才檢查
          if (data.PrescriptionSignMultiWrite?.Prescriptions?.length > 0) {
            var result = await this.histApi.checkIgsn(prescriptionSaveDataList);
            if (!result.IsOK) {
              this.notification.showWarning(result.ErrorMessege);
              this.mainLayoutService.hideLoader();
              var act = await this.userConfirmService.showConfirm({
                title: '簽章異常',
                msg: '是否要重新取得簽章?(簽章異常將影響IC每日上傳的結果)',
                textYes: '確認',
                textNo: '取消',
                width: 500
              });
              if (act == true) { // 設定此筆資料不申報
                this.notification.showWarning('IC卡醫令再次寫入中...請勿拔出病患健保卡，以確保醫令簽章正常產生!');
                var ret = await this.icHelper.getAPI().WriteMultiPrescriptSign(data.PrescriptionSignMultiWrite);
                if (ret.StatusCode != 0) {
                  this.notification.showError('健保卡醫令寫入失敗!');
                }
                return true;
              }
              else {
                return true;
              }
            }
          }

        } catch (ex) {
          this.notification.showError('健保卡寫入錯誤：' + ex);
          return true;
        }
      } else {
        //Debug
        //this.presService.printPrescription(this.histRcd);
      }
      return true;

    } catch (error) {
      this.closeLoader();
      if (error.message) {
        this.notification.showError(error.message); // 顯示存檔失敗
      } else {
        this.notification.showFailed(); // 顯示存檔失敗
      }
      return false;
    }

    //this.lockReadonly();
  }
  // #endregion action

  validateToSave() {
    // 不檢核 兩者時間 只檢核其他值異動
    this.originHistRcd.VitalSign.CreateTime = this.histRcd.VitalSign.CreateTime;
    this.originHistRcd.VitalSign.EditTime = this.histRcd.VitalSign.EditTime;
    this.originHistRcd.VitalSign.VSDateTime = this.histRcd.VitalSign.VSDateTime;
    this.originHistRcd.Hist.CreateTime = this.histRcd.Hist.CreateTime;
    this.originHistRcd.Hist.EditTime = this.histRcd.Hist.EditTime;
    // VitalSign string_number 型別問題會影響是否需儲存的判斷
    if (this.originHistRcd.VitalSign.Weight == this.histRcd.VitalSign.Weight) {
      this.originHistRcd.VitalSign.Weight = this.histRcd.VitalSign.Weight;
    }
    if (this.originHistRcd.VitalSign.Height == this.histRcd.VitalSign.Height) {
      this.originHistRcd.VitalSign.Height = this.histRcd.VitalSign.Height;
    }
    if (this.originHistRcd.VitalSign.BT == this.histRcd.VitalSign.BT) {
      this.originHistRcd.VitalSign.BT = this.histRcd.VitalSign.BT;
    }
    if (this.originHistRcd.VitalSign.SBP == this.histRcd.VitalSign.SBP) {
      this.originHistRcd.VitalSign.SBP = this.histRcd.VitalSign.SBP;
    }
    if (this.originHistRcd.VitalSign.DBP == this.histRcd.VitalSign.DBP) {
      this.originHistRcd.VitalSign.DBP = this.histRcd.VitalSign.DBP;
    }
    if (this.originHistRcd.VitalSign.Pulse == this.histRcd.VitalSign.Pulse) {
      this.originHistRcd.VitalSign.Pulse = this.histRcd.VitalSign.Pulse;
    }
  }
  //#region toolbar buttons --------------------
  closeLoader() {
    this.mainLayoutService.hideLoader();
    this.detectChanges();
  }

  showLoader() {
    this.mainLayoutService.showLoader();
    this.detectChanges();
  }
  // 用來檢測特殊規則
  saveBeforeCheckSpecial(): boolean {
    var result = true;
    if (this.histRcd.Hist?.Days >= 42 && this.histRcd.Hist?.CntPresNo == '1') {
      var declare = this.histRcd.Hist;
      var specItemword = [declare?.SPTX1, declare?.SPTX2, declare?.SPTX3, declare?.SPTX4].filter(v => v);
      var specismatch = this.compareStringsAndArray(specItemword, ['H8', 'HA', 'HB', 'HC', 'HD', 'HI'])
      if (specismatch) {
        this.notification.showWarning('新制部分負擔開始後，慢性連續處方箋一次領取需分開申報，' +
          '第一次和第二次(含)後調劑請分開兩筆掛號。')
        return false;
      }
    }

    return result;
  }

  compareStringsAndArray(a: string[], b: string[]): boolean {
    // 将字符串 a 拆分成数组，使用逗号作为分隔符
    // 判断数组 b 中的每个元素是否都在数组 aArray 中
    const result = b.some(item => a.includes(item));

    return result;
  }

  sleep(ms) {
    return new Promise(res => setTimeout(res, ms));
  }
  async ValidateOrders(): Promise<boolean> {
    //改成async
    // var validateErr = this.ordersComp.getErrors();
    var validateErr = await this.ordersComp.getErrorsExt();

    if (validateErr.length > 0) {
      var hasError = validateErr.filter(x => x.Level == 'ERROR').length > 0;
      var hasExeWarning = validateErr.filter(x => x.colType == 'EXEC' || x.colType == 'EXECDT' || x.colType == 'EXECTIME'  || x.colType == 'EXECDTChk' ).length > 0;
      var msgWay = validateErr.filter(x => x.colType == 'WAY');
      var errMsg = validateErr.filter(x => x.colType !== 'WAY');
      var msgAry = errMsg.filter(x => x.Level == 'ERROR').map(x => '  ' + x.Message);
      var warnMsg = errMsg.filter(x => x.Level == 'WARNING').map(x => '  ' + x.Message)
      if (warnMsg.length > 0) {
        msgAry.push('請確認以下內容正確：')
        msgAry = msgAry.concat(warnMsg)
      }

      if (msgWay.length > 0) {
        if (msgAry.length > 0) {
          msgAry.push('');
        }
        msgAry = msgAry.concat(['注意!!以下藥品途徑代碼非健保署公告標準給藥途徑，可能造成IC卡上傳檢核錯誤，建議修改',
          '----------------------------------------------------------------------------------------------------'])
          .concat(msgWay.map(x => ' ' + x.Message));
      }
      var msg = msgAry.join('\n');
      // 中斷
      if (hasError) {
        await this.userConfirmService.showAlert('警告！', '請修正以下問題：\n' + msg, { width: 800 });
        return false;
      } else {
        var conf = await this.userConfirmService.showConfirm({
          title: '提示！',
          msg: msg,
          textYes: '繼續',
          textNo: '返回',
          width: 800
        });
        if (conf == false) {
          return false;
        }
      }
      if (hasExeWarning) {
        this.openExecutorAndDateTime();
        return false;
      }
    }
    return true;
  }
  /** 點[完診] */
  async onBtnFinishClick() {
    if (!await this.ValidateOrders()) {
      return;
    }
    //this.histService.
    await this.pullAllData()

    await this.vacineCheck();

    // if(!await this.戒菸Check()){
    //   return;
    // }

    if (!await this.checkHist()) {
      return;
    }
    await this.emptyRxCheck();

    if (!await this.emptyDxCheck()) {
      return;
    }
    // 非自費
    if (this.histRcd.Register.ICode !== '000') {
      // 精神科(不含康家)且非自費 => 需顯示平均藥價狀況
      if (this.histRcd.Register.MedDeptCode == '13' && !this.cln.TypeIsRecoveryHome) {
        var hst001 = await this.clinicDataService.getParam("HST001");
        var displayAveDrugsFee = hst001.DisplayAverageDrugsFee;
        if (displayAveDrugsFee) {
          this.psychSummary = await this.rxApi.GetPsychSummary(DateHelper.formatDateTime(this.histRcd.Register.RegDate),
            this.histRcd.Register.DoctorCId,
            this.histRcd.Hist.Id);
          await this.promptForRx();
        }
      }
    }
    if (!await this.待檢來源資料輸入()) {
      return;
    }


    if (this.useNhiimi || this.useNhiimiSpe) {  // 跨院雲端藥歷查詢
      var ret = this.isDemoMode ? await this.toCrossInquiryForDemo('A') : await this.toCrossInquiry('A');
      // console.log('useNhiimi_ret',ret);
      if (ret) {
        if (this.histService.IsShowPricingWindow) {
          this.openPricingWin();
        } else {
          await this.saveAndExit(true);
        }
      } else {
        return;
      }
    } else {
      if (this.histService.IsShowPricingWindow) {
        this.openPricingWin();
      } else {
        await this.saveAndExit(true);
      }
    }
  }
  //#region 批價前病歷檢查
  psychSummary: PsychSummaryDto;
  /**精神科醫令 用藥間隔警示 平均藥費提示，每次存檔都要顯示 */
  async promptForRx() {
    var rxInterVal: ValueTextPairNumberValue[] = [{ value: 7, text: '45010C' }, { value: 180, text: '45046C' },
    { value: 14, text: '45087C' }, { value: 14, text: '45088C' },
    { value: 14, text: '45089C' }, { value: 180, text: '45098C' },
    { value: 180, text: '45099C' }, { value: 14, text: '45100C' },
    { value: 14, text: '45102C' }];
    var stdCodes: string[] = ['45010C', '45046C', '45087C', '45088C', '45089C', '45098C', '45099C', '45100C', '45102C'];
    var avgSum = 200;
    var regDate = this.histRcd.Register.RegDate;
    var sumThis = 0;
    var sumToday = 0;
    var sumMonth = 0;
    var a2This = 0;
    var a2Today = 0;
    var a2Month = 0;
    var qtyA2This = 0;
    var qtyA2Today = 0;
    var qtyA2Month = 0;
    var qtyThis = 0;    // 開藥天數
    var qtyToday = 0;
    var qtyMonth = 0;
    var qtyA8This = 0;  // 精神科醫令治療件數
    var qtyA8Today = 0;
    var qtyA8Month = 0;
    var msg = "";

    // 精神科醫令診察費
    var hrxes = this.histRcd.Hist.HRxs.filter(x => stdCodes.includes(x.StdCode));  // 目前編輯含精神科醫令
    if (hrxes?.length > 0) {
      qtyThis = 1;    // 因為有查到精神科醫令，所以開藥天數/治療件數要加1，後續顯示平均藥價提醒藥判斷用
      qtyA8This = 1;
    }
    var psyOrderList = await this.rxApi.GetPsychiatryRxList(this.histRcd.Patient.Id, this.histRcd.Hist.Id,regDate);
    for (var i = 0; i < hrxes.length; i++) {
      var hrx = hrxes[i];
      var rxData = psyOrderList.find(x => x.StdCode == hrx.StdCode
        && x.PriceStartDate <= regDate
        && x.PriceEndDate >= regDate);

      var intervalDays = rxInterVal.find(x => x.text == hrx.StdCode)?.value;
      var dp2 = hrx.IPrice;
      sumThis += (dp2 ? dp2 * hrx.TotalDose : 0);  // 藥價 * 總量
      var needCheckDays = (intervalDays ? intervalDays : 0);  // 間隔天數
      if (needCheckDays == 0 && dp2 >= 300) needCheckDays = 180;  // 沒設定間隔天數，就檢查藥價是否>=300，是的話間隔天數以180日計
      if (needCheckDays > 0) {
        var chkStartDate = DateHelper.addDay(new Date(regDate), needCheckDays * -1);  // 往前回算天數後的日期(=起始日期)
        var privUsingDate = rxData.LastUsingDate;   // 最近一次用藥日期
        // 最近一次用藥日期 大於 往前回算天數後的日期(=起始日期) => 表示還沒超過間隔日期就又用藥 => 組顯示的提醒文字
        if (privUsingDate >= chkStartDate) {
          var diffInTime = regDate.getTime() - privUsingDate.getTime();
          var diffDays = Math.round(diffInTime / (1000 * 3600 * 24));
          msg += DateHelper.getROCDateStringWithSepetator(privUsingDate) + ' 已使用' + hrx.StdCode + '-' + hrx.ProdName + '應間隔>' + needCheckDays + ' 日, 目前間隔:' + diffDays + '日\n';
        }
      }
    }

    // 藥費 // RxType為2或3才抓
    var drugHrxs = this.histRcd.Hist.HRxs.filter(x => x.RxType == 2 || x.RxType == 3);
    var a2This = 0;
    for (var i = 0; i < drugHrxs.length; i++) {
      var hrx = drugHrxs[i];
      var dp2 = hrx.IPrice;
      qtyA2This = (qtyA2This > hrx.InfoDays ? qtyA2This : hrx.InfoDays);
      a2This += (dp2 ? dp2 * hrx.TotalDose : 0);
    }

    // 看診人數
    qtyToday = ((this.psychSummary?.QtyToday ?? 0) + 1);              // 當日總看診人數(加當前這一筆)
    qtyMonth = (qtyToday + (this.psychSummary?.QtyMonth ?? 0));       // 當月總看診人數(加當日 加當前這一筆)
    sumToday = (sumThis + (this.psychSummary?.SumToday ?? 0));        // 當日總診察費(加當前這一筆)
    sumMonth = (sumToday + (this.psychSummary?.SumMonth ?? 0));       // 當月總診察費(加當日 加當前這一筆)
    qtyA8Today = (qtyA8This + (this.psychSummary?.QtyA8Today ?? 0));  // 當日精神科醫令治療件數(加當前這一筆)
    qtyA8Month = (qtyA8Today + (this.psychSummary?.QtyA8Month ?? 0)); // 當月精神科醫令治療件數(加當日 加當前這一筆)
    a2Today = (a2This + (this.psychSummary?.DrugFeeToday ?? 0));      // 當日總藥費(加當前這一筆)
    a2Month = (a2Today + (this.psychSummary?.DrugFeeMonth ?? 0));     // 當月總藥費(加當日 加當前這一筆)
    qtyA2Today = (qtyA2This + (this.psychSummary?.QtyA2Today ?? 0));  // 當日開藥總天數(加當前這一筆)
    qtyA2Month = (qtyA2Today + (this.psychSummary?.QtyA2Month ?? 0)); // 當月開藥總天數(加當日 加當前這一筆)

    if (msg.length > 0) {
      msg = '◎◎用藥間隔警示：\n' + msg + '\n\n';
    }

    // 平均藥費提示，每次存檔都要顯示
    // 注意: 下面的字串排列不要移動，否則會跑版
    msg += `
      【當日】\n
          ◎診療費: ` + sumToday + ` / 看診人數: ` + qtyToday + ` (治療件數: ` + qtyA8Today + `)\n
          平均治療費=` + this.formatNumber(sumToday / qtyToday) + `\n
          差額:` + this.formatNumber(avgSum - (sumToday / qtyToday) * qtyToday) + `\n
          －－－－－－－－－－－－－－－－－－－－－\n
          ◎藥費: ` + this.formatNumber(a2Today) + ` / 開藥天數: ` + qtyA2Today + `\n
          平均藥費=` + this.formatNumber(a2Today / qtyA2Today) + `\n
          ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝\n
      【當月】\n
          ◎診療費: ` + sumMonth + ` / 看診人數: ` + qtyMonth + ` (治療件數: ` + qtyA8Month + `)\n
          平均治療費=` + this.formatNumber(sumMonth / qtyMonth) + `\n
          差額: ` + this.formatNumber(avgSum - (sumMonth / qtyMonth) * qtyMonth) + `\n
          －－－－－－－－－－－－－－－－－－－－－\n
          ◎藥費: ` + this.formatNumber(a2Month) + ` / 開藥天數: ` + qtyA2Month + `\n
          平均藥費=` + this.formatNumber(a2Month / qtyA2Month) + `\n`;

    await this.userConfirmService.showAlert('請確認以下訊息', msg, { width: 400 });
  }
  /** 卡序VU (新冠疫苗)  AU(流感) 病歷輸入檢查*/
  async vacineCheck() {
    // Covid-19
    if (this.histRcd.Register.Icno == RegisterVaccineConst.Isqno_Vaccine_Covid) {
      var covids = this.histRcd?.Hist?.HRxs.filter(x => x.Rx?.StdCode?.startsWith('COVID19'));
      if (covids.length < 1) {
        await this.userConfirmService.showAlert('未輸入COVID-19疫苗醫令', '施打covid-19疫苗 未輸入COVID-19疫苗醫令!!');
      }
    }
    if (this.histRcd.Register.Icno == RegisterVaccineConst.Isqno_Vaccine_Flu) {
      var au113 = ['J000151206', 'J000138206', 'K001036206', 'K001126206','K000939206']
      var auRxs = this.histRcd?.Hist?.HRxs.filter(x => au113.includes(x.Rx?.StdCode));
      if (auRxs.length < 1) {
        let auWords = au113.join(',');
        await this.userConfirmService.showAlert(
          '未輸入流感疫苗疫苗醫令',
          `施打流感疫苗 未輸入疫苗醫令! 今年度流感疫苗：\n ${auWords}，歸屬04，N欄位為申報不計價，請確認!!`);
      }
      else {
        await this.addOrder('A2001C');
      }
    }
  }
  /** server 端病歷輸入檢查 */
  async checkHist() {
    var result = await this.histApi.HistCheck(this.histRcd);
    var allowContinue = true;
    var msg: string[] = [];
    for (let r of result) {
      // 有層級 error的 阻擋
      if (r.Result == CheckResult.Error) {
        allowContinue = false;
      }
      // 警告的 訊息堆疊
      if (r.Action == CheckResultAction.Alert && r.Data) {
        msg.push(r.Data);
      }
      // 動作 插入醫令
      if (r.Action == CheckResultAction.InsertRx && r.Data) {
        var rxCode = r.Data;
        await this.addOrderByRxCode(rxCode);
      }
      // 動作 插入診斷
      if (r.Action == CheckResultAction.InsertDx && r.Data) {
        var dxCode = r.Data;
        await this.addDx(dxCode);
      }
    }
    if (msg.length > 0) {
      if (allowContinue) {
        return await this.userConfirmService.showConfirm({
          title: '請確認以下訊息',
          msg: msg.join('\n\n'),
          textYes: '繼續',
          textNo: '返回',
          width: 400
        });
      } else {
        await this.userConfirmService.showAlert('請確認以下訊息', msg.join('\n\n'));
      }

    }
    return allowContinue;
  }
  /** 卡序 IC07 戒菸 檢查 */
  async 戒菸Check() {
    if (this.histRcd.Register.Icno == 'IC07') {

      var 已開立醫令 = this.histRcd?.Hist?.HRxs;
      var 戒菸治療服務費清單 = ['E1006C', 'E1007C'];
      var 戒菸衛教醫令 = ['E1022C'];
      var 戒菸追蹤費醫令 = ['E1023C', 'E1024C', 'E1025C', 'E1026C', 'E1027C', 'E1028C', 'E1029C'];
      var 戒菸輔助用藥清單 = ['B0220903EF', 'B022089396', 'B024457100', 'B024499100', 'B022538129', 'B024740100', 'B024741100', 'B027835161', 'B024649100', 'B024648100',
        'A057335100', 'A057338100', 'A0609233EF', 'A058301396', 'A0583863EG', 'A0386463EH', 'A0386463EJ', 'A0386463EK', 'A058046100', 'A058047100',
        'A0567821T0', 'A0493221T0', 'A0490111T0', 'A0550051T0', 'A0552841T0', 'A0549771T0', 'A0555341T0', 'A0608311T0']

      var 有開立到輔助用藥醫令 = 已開立醫令.some(x => 戒菸輔助用藥清單.some(y => y == x.Rx?.StdCode));
      var 有開立到戒菸治療服務費醫令 = 已開立醫令.some(x => 戒菸治療服務費清單.some(y => y == x.Rx?.StdCode));
      //漏開治療費
      if (有開立到輔助用藥醫令 && !有開立到戒菸治療服務費醫令) {
        await this.userConfirmService.showAlert('提示',
          `有開立輔助用藥但未輸入戒菸治療服務費 E1006C(自調) 或 E1007C(委外)`);
        return false;
      }
      var 戒菸相關醫令清單 = [...戒菸治療服務費清單, ...戒菸衛教醫令, ...戒菸追蹤費醫令, ...戒菸輔助用藥清單]
      var 有開立戒菸相關以外的醫令 = 已開立醫令.some(x => !戒菸相關醫令清單.some(y => y == x.Rx?.StdCode));
      if (有開立戒菸相關以外的醫令) {
        await this.userConfirmService.showAlert('提示',
          `需加開戒菸藥之外的其它藥品，應分屬不同病歷開立！`);
        return false;
      }
      //Server端檢查
      /**3.輔助用藥與同療程最後次的開藥日重複>7天 (預期剩餘藥量低於7日才可開)
       提醒「輔助用藥與前次開藥日重複大於7日，下次可開藥日為…」
        4.同一療程內輔助用藥天數總和大於8周
       提醒「輔助用藥日數總和需小於等於8周，本次療程先前已開藥共N日」 */
    }
    return true;
  }
  /** 檢查是否沒填醫令 */
  async emptyRxCheck() {
    if (this.histRcd?.Hist?.HRxs.length < 1 && this.isRecoveryHome) {
      await this.userConfirmService.showAlert('醫令尚未輸入', '您的醫令沒有登打');
    }
  }
  /** 檢查是否需填診斷 */
  async emptyDxCheck() {
    // 自費外身分 且 非洗腎
    let diagshasCode = this.histRcd.Hist.Diags.some(x => x.code != undefined && x.code != '' && x.code != null);
    if (this.histRcd.Register.ICode !== '000'
      && this.histRcd.Register.SameTreat != RegisterConst.SameTreat_0_洗腎
      && !diagshasCode) {
      await this.userConfirmService.showAlert('診斷碼尚未輸入', '請至少輸入1個診斷', { width: 400 })
      return false;
    }
    return true;
  }

  async 待檢來源資料輸入(): Promise<boolean> {
    if (this.histRcd.Register.ICode == RegisterConst.ICode_009_Other && this.histRcd.Register.INote == RegisterConst.INote_代檢) {
      var ret = await this.ezForm.show({
        title: '代(轉)檢原處方日及原醫事機構代號輸入',
        msg: '',
        closeValue: () => null,
        width: 500,
        fields: {
          OriICNo: {
            label: '原就醫卡序',
            // 預保禁止編輯
            type: this.histRcd.Register.SameTreat == RegisterConst.SameTreat_2_預防保健 ? 'display' : 'text',
            name: 'OriICNo',
            value: this.histRcd.Register.Icno ?? '',
            tip: this.histRcd.Register.SameTreat == RegisterConst.SameTreat_2_預防保健 ? null : '原機構就醫時的卡序，4碼',
          },
          OriLabRegDate: {
            label: '原就診日期',
            type: 'date',
            name: 'OriLabRegDate',
            value: this.histRcd.Hist.OriLabRegDate ?? DateHelper.today,
          },
          OriLabHosp: {
            label: '原醫事機構代號',
            type: 'text',
            name: 'OriLabHosp',
            value: this.histRcd.Hist.OriLabHosp
          },
          OriLabDcotor: {
            label: '原醫師身分證',
            type: 'text',
            name: 'OriLabDcotor',
            value: this.histRcd.Hist.OriLabDcotor
          },
          DoctorCId: {
            label: '檢驗師身分證',
            type: 'display',
            name: 'DoctorCId',
            value: this.histRcd.Register.ExaminerCId
          },
        },
        beforeSubmit: async (d) => {
          if (d.OriLabRegDate > DateHelper.getDate(this.histRcd.Register.RegDate)) {
            await this.userConfirmService.showAlert('警告', '原就診日期不可晚於此病歷就診日期')
            return false;
          }
          if (this.histRcd.Register.SameTreat != RegisterConst.SameTreat_2_預防保健 && d.OriICNo?.length != 4) {
            await this.userConfirmService.showAlert('警告', '原就醫卡序長度需為4碼')
            return false;
          }
          if (d.OriLabDcotor?.length > 10) {
            await this.userConfirmService.showAlert('警告', '原醫師身分證長度不可超過10馬')
            return false;
          }
          if (d.OriLabHosp?.length > 10) {
            await this.userConfirmService.showAlert('警告', 'V長度不可超過10馬')
            return false;
          }
          return true;
        }
      });
      if (ret) {
        this.histRcd.Hist.OriLabDcotor = ret.OriLabDcotor;
        this.histRcd.Hist.OriLabHosp = ret.OriLabHosp;
        this.histRcd.Hist.OriLabRegDate = ret.OriLabRegDate;
        this.histRcd.Register.Icno = ret.OriICNo;
        //this.histRcd.Register.DoctorCId = ret.DoctorCId;
        //Object.assign(this.histRcd.Hist,ret);
        return true;
      }
      return false;
    }
    return true;
  }
  //#endregion
  formatNumber(src: any): string {
    if (isNaN(src)) return '0';
    var testDigital: string = (src.toFixed(2)).toString();
    if (testDigital.indexOf('.') >= 0) {
      var startIndex = testDigital.indexOf('.') + 1;
      var endIndex = startIndex + 2;
      if (testDigital.substring(startIndex, endIndex) == '00') {
        return testDigital.substring(0, testDigital.indexOf('.'));
      } else {
        return testDigital;
      }
    } else {
      return testDigital;
    }
  }


  onBtnLoadTemplateClick() {
    this.openLoadTmplWin();
  }
  onBtnClearClick() {
    this.openClearWindow();
  }
  onBtnSaveTemplateClick() {
    this.openTmplWin();
  }
  async onBtnSaveClick() {
    await this.saveAndExit(true);
  }

  onBtnExitClick() {
    this.openExitDialog();
  }
  //#endregion toolbar buttons

  // #region patient remark --------------------
  openRemarkWindow(evt: Event) {
    evt.stopPropagation();
    this.isInnerClickOfTitle = true;
    this.PatientRemarkComp.cleanData();
    this.isRemarkWindowOpened = true;
    // 避免同時開了兩個Dialog window
    this.closeDrugAlgWindow();
  }

  closeDiagnosisWindow() {
    this.isShowSearchDisabled = false;
  }
  closeRemarkWindow() {
    this.isRemarkWindowOpened = false;
  }
  getRemarkWindowResult(data: any) {
    this.closeRemarkWindow();
    if (data) {
      this.histRcd.Patient.Remark = data.text;
    }
  }
  // #endregion patient remark
  firstloading = true;
  async openDiagnosisWindow(evt: boolean) {
    // evt.stopPropagation();
    this.pullDiags();
    this.histdiags = this.histRcd.Hist.Diags;

    this.isShowSearchDisabled = evt;
    this.isHelperPadOpened = evt;

  }
  sdxListData: PageData;
  dxListData: PageData;
  histdiags: HistDiag[];
  createsdxQueryFormGroup(): FormGroup {
    return this.fb.group({
      // codeCategory: ['', Validators.required],
      code: [''],
      name: [''],
      type: ['sdx'],
      applyCode: [''],

    });
  }
  // #region patient drug allergy --------------------
  openDrugAlgWindow(evt: Event) {
    evt.stopPropagation();
    //this.isInnerClickOfTitle = true;
    this.DrugAllergyComp.cleanData();
    // 用Input會造成Comp close以後內部清空的資料 因為selectedText綁定的內容沒有變更而造成第二次開啟的時候變成空白
    this.DrugAllergyComp.reloadData(this.histRcd.Patient.DrugAllergy);
    this.isDrugAlgWindowOpened = true;
    // 避免同時開了兩個Dialog window
    this.closeRemarkWindow();
  }
  closeDrugAlgWindow() {
    this.isDrugAlgWindowOpened = false;
  }
  getDrugAlgWindowResult(data: any) {
    this.closeRemarkWindow();
    if (data) {
      this.histRcd.Patient.DrugAllergy = data.text + ';'+this.otherAllergies;
    }
    var tmp = this.histRcd.Patient.DrugAllergy.split(';');
    this.allergies = tmp.length > 0 ? tmp[0].split(',') : [];

    // 取得ACT和過敏藥群組之RxCode (clinic's acts & allergies)
    var acts = (tmp.length > 1) ? tmp[1].split(',') : [];
    if (acts.length > 0) this.getRxCodeOfACT(acts);
    var algs = tmp.length > 2 ? tmp[2].split(',') : [];
    if (algs.length > 0) this.getRxCodeOfAllergyGroup(algs);

    this.isDrugAlgWindowOpened = false;
    this.closeDrugAlgWindow();
  }
  otherAllergies:string = '';
  otherAllergyInput(evt:KeyboardEvent){
    this.otherAllergies = (evt.target as HTMLInputElement).value;
    var alergies = this.histRcd.Patient.DrugAllergy.split(';');
    var t = [alergies[0]??'',alergies[1]??'',alergies[2]??'',this.otherAllergies]
    this.histRcd.Patient.DrugAllergy = t.join(';');
  }
  displayAllergies() {
    var tmp = this.histRcd.Patient.DrugAllergy.split(';');
    var result = '';
    result += tmp.length > 0 ? (tmp[0].length > 0 ? ('[藥品代碼]:' + tmp[0] + ' ') : '') : '';
    result += tmp.length > 1 ? (tmp[1].length > 0 ? ('[ATC碼]:' + tmp[1] + ' ') : '') : '';
    result += tmp.length > 2 ? (tmp[2].length > 0 ? ('[過敏藥群組]:' + tmp[2] + ' ') : '') : '';
    // 自己打字可能會包含到;號，將其組回去
    this.otherAllergies = tmp.filter((x,i)=>i>=3).join(';')
    return result;
  }
  // #endregion patient drug allergy --------------------

  // #region hist-list --------------------
  onHistListEmitSelect(histId: number) {
    // 通知歷史資料檢視元件
    this.histViewComp.selectHist(histId);
  }

  onHistListEmitSelectToInsert(histId: number) {
    if (!this.hasUploaded) {

      this.isCopy = true;
      this.histViewComp.selectHistAndCopy(histId);
    }
  }

  // onHistListEmitGet(histList: HistListItem[]) {
  //   this.opdHistList = histList;
  // }

  onHistListAreaClick() {
    this.closeHelperPadWin();
  }
  // #endregion hist-list --------------------

  // #region hist-view --------------------
  onHistViewAreaClick() {
    this.closeHelperPadWin();
  }

  // 複製病歷、診斷和醫令
  async onHistViewEmitInsert(histView: HistView) {

    if (!histView) {
      return;
    }
    if (histView.Id == undefined || histView.Id == null || histView.Id == 0) {
      return;
    }
    this.histService.UIReset();
    var hist = await this.histApi.getWholeHist({ regId: histView.Id })
    if (!hist) {
      this.histService.UIReady('CC');
      this.histService.UIReady('PE');
      this.histService.UIReady('Dx');
      this.histService.UIReady('Rx');
      return;
    }
    this.isCopy = true;
    this.histService.UIReset();
    //await this.pullAllData()
    const isInsert = histView.IsInsert;

    // 複製主訴
    if (histView.IsIncludeCC) {
      // 如來源主訴有TOCC，則先清除TOCC
      // this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.CC, { Name: hist.Hist.CC }, isInsert, null));
      var copyCC = hist.Hist.CC;
      if (this.isEnabledTOCC) {
        var historyId = hist.Hist.Id;
        var originalTOCC = '';
        if (historyId) {
          try {
            var lastQueryReturnMessage = await this.toccApi.GetLastQueryReturnMessage(historyId);
            if (lastQueryReturnMessage) {
              originalTOCC = lastQueryReturnMessage.QueryReturnMessage.trim();
            }
          }
          catch (ex) {
            // this.notification.showError('讀取TOCC問卷紀錄發生錯誤: ' + ex);
          }
          if (copyCC && copyCC.indexOf(this.toccKeyword) >= 0) {
            //原本如有TOCC先清除-來自TOCC問卷紀錄
            if (originalTOCC) copyCC = copyCC.replace(originalTOCC, '');
            //如有未填寫一律清除
            copyCC = copyCC.replace(this.toccNotFilled, '');
          }
        }
      }
      this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.CC, { Name: copyCC }, isInsert, null));
    } else {
      this.histService.UIReady('CC')
    }

    // 複製理學
    if (histView.IsIncludePE) {
      this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.PE, { Name: hist.Hist.PE }, isInsert, null));
    } else {
      this.histService.UIReady('PE')
    }

    // 複製診斷
    if (histView.IsIncludeDiag) {
      var dxs = [hist.Hist.Dx1, hist.Hist.Dx2, hist.Hist.Dx3, hist.Hist.Dx4, hist.Hist.Dx5, hist.Hist.Dx6].filter(x => x);
      var addDx = [];
      for (let dx of dxs) {
        var dxopt = (await this.histService.getDiagList(dx, false, this.icd10Version)).find(x => x.Code == dx)
        if (dxopt) {
          addDx.push(dxopt);
        }
        this.isCheckDX5DX6Data = true;  //???
      }
      this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.Diag, addDx, isInsert, null));
    } else {
      this.histService.UIReady('Dx')
    }

    // 複製醫令
    // console.log(histView.IsIncludeOrder)
    if (histView.IsIncludeOrder) {
      this.helperPadService.SetItem(
        new SetItemEvent(HelperSourceEnum.Order, hist.Hist.HRxs, isInsert,
          { rxType: [2, 3, 4], include: ['RX'] }));
    } else {
      this.histService.UIReady('Rx')
    }
    // this.fillFormData(this.histRcd);
  }
  // #endregion hist-view --------------------

  //#region clear window --------------------
  async openClearWindow() {
    var formField: FormFieldCollection<{ CC: boolean, PE: boolean, ChiAsk: boolean, DX: boolean, RX: boolean }> = {};
    formField.CC = { label: '主訴', type: 'check', value: true, name: 'CC', order: 1 };
    formField.PE = { label: '理學', type: 'check', value: true, name: 'PE', order: 2 };
    if (this.isChineseMode) {
      formField.ChiAsk = { label: '四診辨證', type: 'check', value: true, name: 'ChiAsk', order: 3 };
    }
    formField.DX = { label: '診斷', type: 'check', value: true, name: 'DX', order: 4 };
    formField.RX = { label: '處方', type: 'check', value: true, name: 'RX', order: 5 };
    this.fnKeyEnable = false;
    var ret = await this.ezForm.show({
      title: '清除選項',
      msg: '請選擇要清除的項目',
      fields: formField,
      classList: ['clear-form', 'table-width-400'],
      // 取消回傳null
      closeValue: (d) => null,
      btns: [{
        text: '全勾選',
        callback: (data) => {
          for (var p in data) {
            data[p] = true;
          }
          return { action: 'Patch', data };
        }
      }, {
        text: '全取消',
        callback: (data) => {
          for (var p in data) {
            data[p] = false;
          }
          return { action: 'Patch', data };
        }
      }]
    });
    this.fnKeyEnable = true;
    if (ret) {
      if (ret.CC && !this.histService.IsChineseMode) {
        this.ccComp.clear();
      }
      if (ret.PE && !this.histService.IsChineseMode) {
        this.peComp.clear();
      }
      if (ret.ChiAsk && this.histService.IsChineseMode) {
        this.chiAskComp.clear();
        this.chiLookComp.clear();
        this.chiPulseComp.clear();
        this.chiBianZhengComp.clear();
        this.chiTreatRuleComp.clear();
      }
      if (ret.DX) {
        this.diagsComp.clear();
      }
      if (ret.RX) {
        this.ordersComp.clear();
      }
      this.isCopy = false;
      await this.pullAllData()
    }
  }

  //#endregion clear window --------------------

  //#region make referral form
  makeReferralFormGroup(): FormGroup {
    const fg: FormGroup = this.fb.group({
      Type: [this.referralOut?.Type ?? '1'],            // 轉診類型 1.轉出 2.轉入
      RegId: [''],            // 掛號Register.Id
      RegDate: [''],          // 掛號Register. RegDate
      ClinicId: [''],         // 對應Clinic.Id
      ClinicCode: [''],       // 診所代碼
      DoctorName: [''],       // 畫面: 醫師名
      DoctorId: [''],         // 畫面: 醫師身分證
      DoctorCid: [''],        // 畫面: 醫師識別
      DoctorPwd: [''],        // 畫面: 醫師密碼
      PatientId: [''],        // 對應PatientMast .Id
      PatientNo: [''],        // 病歷號，以診所為主
      PatientName: [''],      // 畫面: 病患姓名
      PatientSex: [''],       // 畫面: 病患性別
      ReferralDate: [''],     // 畫面: 轉診日
      Birthday: [''],         // 畫面: 病患生日
      PhoneNo: [''],          // 畫面: 病患電話
      ExpiryDate: [''],       // 有效期限(h34)為病歷日+30天
      ContactName: [''],      // 聯絡人 (h5)
      Address: [''],          // 畫面: 地址
      AllergyHistory: [''],   // 過敏史(h17)
      CC: [''],               // 病情摘要(主訴) (h8)
      Dx: [''],               // 主診斷(icd-10不含小數) (h9)
      DxName: [''],           // 畫面: 診斷1 (從病歷資料來)
      Dx1: [''],              // 次診斷1(icd-10不含小數)(h10)
      Dx2: [''],              // 次診斷2(icd-10不含小數)(h11)
      RecOrder: [''],         // 最近一次用藥或手術名稱(h15)
      RecOrderDate: [''],     // 最近一次用藥日期(h16)
      HRx: [''],              // 醫令資料
      RecLISDate: [''],       // 最近一次檢查日期(h12)
      RecLISResult: [''],     // 最近一次檢查結果(h13)
      RecLISReport: [''],     // 最近一次檢查報告(h14)
      Objects: ['4'],         // 轉診目的 (h18) SystemCodeType=C0250
      ObjectsNote: [''],      // 轉診目的說明 (h19)
      IssueDate: [''],        // 開單日期許(h23)
      SchMedDate: [''],       // 安排就醫日期(h24)
      SchMedDep: [''],        // 安排就醫科別代碼(h25)
      SchMedDiag: [''],       // 安排就醫診別(h26)
      SchMedNum: [''],        // 安排就醫號碼(h27)
      InHspid: [''],          // 轉入院所之醫事機構代碼(h28)
      InDep: [''],            // 轉入科別代碼(h29)
      InDrName: [''],         // 轉入醫師姓名(h30)
      InHsAddrw: [''],        // 轉入院所地址(h31)
      InHsPhone: [''],        // 轉入院所電話(h32)
      InPatientNo: [''],      // 畫面: 轉入院所病歷號
      DrTrans: [''],          // 醫師交班注意事項(h33)
      NewBornNote: [''],      // 新生兒註記(含胎次)(h35)，若為新生兒填”Y”，若為多胎填”Yn”例：Y2
      SerialNum: [''],        // 院所自編序號(h36): 10碼醫事機構代號+8碼開單日+5碼流水號
      PilotProject: [''],     // 得轉回原醫療院所診之試辦計畫代碼(h37)，若h28與轉出醫事機構代碼相同，則本欄必填
      ReferralNum: [''],      // 轉診單序號(h38) 數值9(16)
      SpeNote: [''],          // 特定註記(h39) A：緊急傷病患轉診個案 B：特定個案轉出註記 C：轉診 Pre-ESRD 收案
      SpeCaseNote: [''],      // 特定個案轉出註記(h40) 若h39填入 B，本欄為必填
      //            C6 中醫門診總額醫療資源不足地區改善方案
      //            E2 照護機構院民轉出
      //            EC 居家醫療照護整合計畫
      //            F3 牙醫門診總額醫療資源不足地區改善方案
      //            FT 牙醫門診總額醫療資源不足地區改善方案
      //            G5 西醫醫療資源不足地區改善方案
      //            G9 全民健康保險山地離島地區醫療給付效益提昇計畫
      //            JA 提供保險對象收容於矯正機關者醫療服務計畫
      UrgPatNote: [''],       // 緊急傷病患轉出註記(h41)
      //            若h39填入A，本欄為必填。
      //            E：急診
      //            I：加護病房
      ReferralPur: [''],      // 轉診目的(轉介其他試辦計畫或方案) (h42)
      //            1.若 h18 欄位填入 A，本欄為必填。
      //            2.若本欄填入試辦計畫代碼 01，h39欄位必須為 C。
      //              01.轉診 Pre-ESRD 收案
      UrgPatRea: [''],        // 緊急傷病轉診原因(h43)
      //            1. 若 h39 欄位填入 A，本欄為必填。
      //            2. 緊急傷病轉診原因代碼:
      //              01 無該專科醫師
      //              02 有該科醫師，因故無法處理
      //              03 儀器或設備不足
      //              04 床位不足
      //              05 處置與特殊治療量能滿載
      //              06 病人或家屬要求
      //              07 其他
      UrgPatOth: [''],        // 緊急傷病轉診因(其他) (h44)
      // 緊急傷病轉診原因為「07：其他」者，此欄位為必填；
      // 若緊急傷病轉診原因非填入「07：其他」則不可填寫此欄位。
      UrgPatTime: [''],       // 緊急傷病單時間(h45)
      //     1.若 h39 欄位填入 A，本欄為必填。
      //     2.格式 YYYYMMDDHHMM
      //     3.請填寫醫師完成開立轉診單日期及時間
      InHspidName: [''],      // 轉入院所名稱(回傳用)
      InDepName: [''],        // 轉入科別名稱(回傳用)
      InHspidAddress: [''],   // 轉入院所地址(回傳用)
      InHspidPhone: [''],     // 轉入院所電話(回傳用)
      InHspLevel: [''],       // 轉入院所層級(回傳用)
      ExDepName: [''],        // 轉出科別名稱(回傳用)
      btnEdit: [''],          // 畫面UI元素
      DxName1: [''],          // 畫面UI元素
      DxName2: [''],          // 畫面UI元素
      SPRule: [''],           // 畫面UI元素
      printReferral: [true],  // 畫面UI元素
      uploadNHI: [true],      // 畫面UI元素
      DoctorPhone: [''],      // 畫面UI元素
      ClinicEmail: ['']       // 畫面UI元素
    });

    return fg;
  }

  //#endregioin referral form

  makeRegDateFormGroup(): FormGroup {
    const fg: FormGroup = this.fb2.group({
      RDate: [''],
      RTime: [''],
      RoomCode: [''],
      DoctorId: [''],
    });
    return fg;
  }

  //#region template window --------------------

  openTmplWin() {
    this.isTmplWinOpened = true;
  }
  closeTmplWin() {
    this.isTmplWinOpened = false;
    this.detectChanges();
  }


  //#endregion template window

  //#region load template window --------------------
  openLoadTmplWin() {
    this.packageId = null;
    this.isLoadTmplWinOpened = true;
  }
  closeLoadTmplWin() {
    this.isLoadTmplWinOpened = false;
    this.detectChanges();
  }
  /**
   * 範本載入
   * @param tmplView
   * @keyword 範本載入,載入範本
   * @returns
   */
  async onTmplViewEmitInsert(tmplView: TmplView) {
    if (!tmplView || !tmplView.RsCode) {
      return;
    }
    const isInsert = tmplView.IsInsert;
    var includes = [];

    if (tmplView.IsIncludeCC) {
      includes.push('CC');
      this.histService.UIUnReady('CC');
    }
    if (tmplView.IsIncludePE) {
      includes.push('PE');
      this.histService.UIUnReady('PE');
    }
    if (tmplView.IsIncludeDiag) {
      includes.push('DX');
      this.histService.UIUnReady('Dx');
    }
    if (tmplView.IsIncludeOrder) {
      includes.push('RX');
      this.histService.UIUnReady('Rx');
      // var rss = await this.histService.getOrderList(tmplView.RsCode, [0], false)
      // var rs = rss.find(x => x.RsCode == tmplView.RsCode);
      // if(rs){
      //   this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.Order, rs, isInsert, {rxType:[2,3,4], include:includes}));
      // }
    }
    var rss = await this.histService.getOrderList(tmplView.RsCode, [0], false);
    var rs = rss.find(x => x.RsCode == tmplView.RsCode);
    if (rs?.Surs?.length > 0){
      var surStr = rs.Surs.split(',').concat(['', '']);
      this.histRcd.Hist.Sur1 = surStr[0];
      this.histRcd.Hist.Sur2 = surStr[1];
    }

    this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.Order, rs, isInsert, { rxType: [2, 3, 4], includes: includes }));
    this.closeLoadTmplWin();
  }

  async getCName(code) {
    if (code == '') {
      return '';
    }
    return (await this.histApi.getDiagByCode(code, this.icd10Version))?.CName || '';// this.getString(histView.Hist.Dx1Name);
  }
  //#endregion load template window

  //#region function for text view --------------------
  appendSpaceIfNotEmpty(str: any) {
    if (str && str.trim() !== '') {
      return str + ' ';
    } else {
      return '';
    }
  }
  getString(str: any) {
    if (str) {
      return str;
    } else {
      return '';
    }
  }
  //#endregion function for text view

  //#region charge window --------------------
  openPricingWin() {
    this.isCopy = false;
    //this.pullAllData();
    // console.log("WholeHistDto ++++", this.histRcd);
    // 併入驗證訊息
    //if (this.checkOrderFreqQTY()) {
    var currentCId = UserCache.getLoginUser().CId;

    if (this.histRcd.為代檢()) {
      // 代檢的藥師設定為檢驗師(掛號所選)
      this.histRcd.Hist.PharmacistCId = this.histRcd.Register.DoctorCId;
      // 保留掛號檔的醫師(檢驗師)
    } else {
      // 選入的醫師和掛號的醫師是否不同? 不同的話換成現在選入的醫師
      if (this.histRcd.Register.DoctorCId != currentCId) {
        this.histRcd.Register.DoctorCId = currentCId;
        this.histRcd.Register.DoctorName = UserCache.getLoginUser().Name;
      }
    }

    // 病歷醫師設為使用者
    if (this.histRcd.Hist.DoctorCId == undefined || this.histRcd.Hist.DoctorCId == null) {
      //this.histRcd.Hist.DoctorCId = this.histRcd.Register.DoctorCId;
      this.histRcd.Hist.DoctorCId = currentCId;
    }
    if (this.histRcd.Hist.PharmacistCId == undefined || this.histRcd.Hist.PharmacistCId == null) {
      this.histRcd.Hist.PharmacistCId = "";
    }
    if (this.histRcd.Hist.RxNo == undefined || this.histRcd.Hist.RxNo == null) {
      this.histRcd.Hist.RxNo = "";
    }
    // 先檢查再批價，不要批價後才發現輸入有錯誤
    if (!this.validate()) {
      return;
    }
    this.isPricingReady = false;
    this.isPricingWinOpened = true;
    //}
  }

  async onDrugbagPrintEx(evt: HistOrder[]) {
    // console.log('補印藥帶 +++++', evt);
    this.drugsSelectorOpen = false;
    // console.log('histRcd.Hist.HRxs >>>>>', this.histRcd.Hist.HRxs)
    this.mainLayoutService.showLoader();
    await this.printService.printDrugbag(this.histRcd.Register.Id, evt);
    this.exit();
  }

  async closePricingWin(result: Result) {
    this.updateWholeHist(this.histRcd, true);
    this.cdr.detectChanges();

    if (result.CompleteMode == 'Cancel') {
      // DoNothing
      setTimeout(() => {
        this.isPricingWinOpened = false;
      }, 10);
      this.ratingPriceResult = result;
    } else {
      var histState = this.histRcd.Register.StatusCode;
      // this.pullAllData(); // openPricingWin時已經取得UI資料，是否需要再取一次?
      // 檢查 主訴/理學 以外是否修改?
      var isRxDxChanged = this.histService.RxDxChanged(this.originHistRcd, this.histRcd);
      // 完診 且 RxDx沒有變更才問
      if (!isRxDxChanged && histState == 'F') {
        var res = await this.userConfirmService.showConfirm({
          title: '批價確認',
          msg: '此病歷並無主訴/理學以外的修改，如重新批價將會重新產生上傳資料，請確認要[重新批價]或是[儲存離開]？',
          textYes: '重新批價',
          textNo: '儲存離開',
          width: 350
        });
        if (res == true) {
          if (await this.save(HistActionEnum.Finish)) {
            // if (!this.isDemoMode) {
            //   await this.print(result);
            // } else {
            //   await this.printForDemo(result);
            // }
            if (this.histRcd.Register.ICode !== '000' && this.histRcd.Register.IType !== '3') {  // 欠卡('3')和異掛類為('000')不需查【卡掛號刷卡資料檔】
              var nhiData = await this.nhiApi.GetNHIData(this.histRcd.Register.Id);
              if (nhiData.RegIc != null) {
                this.nhiRegICData = nhiData.RegIc;
                var xml = CreateICXml_2(this.histRcd.Register.RegDate, nhiData);
                var fileName = `Upload_IC2.0\\${DateHelper.formatROCDate(new Date())}\\IC_${DateHelper.getTimeString(new Date())}.xml`;

                await this.hcrService.SaveTextAs(fileName, xml, false, 'big5');
              }
              // 先只產生檔案
              //var uploadRet = await this.hcrService.VisionApi.UploadData(fullFileName,nhiDatas.length.toString());
              // console.log(xml);
            }

            // 衛星藥局
            if (this.histRcd.Hist.useSatellitePharmacy) {
              try {
                //未完診->完診? 新增 : 修改
                var caseMethod = histState !== 'F' ? CaseMethod.Add : CaseMethod.Resend_Modify;

                var ret = await this.spService.send(this.histRcd, this.nhiRegICData, caseMethod);
                if (!ret.ret) {
                  this.notification.showError('衛星藥局轉送失敗: ' + ret.msg);
                }
              } catch (ex) {
                // console.log(ex);
                this.notification.showError('衛星藥局轉送失敗');
              }
            }

            // 電子病歷
            if (this.histRcd.Hist.useEmr) {
              // try {
              //this.histRcd.Hist.emrPath =  `\\\\192.168.0.17\\ftp\\`;  // 測試用
              if (this.histRcd.Hist.emrPath && this.histRcd.Hist.emrPath != ' ') {
                this.generalEmrXML(this.histRcd);  // 產生電子病歷xml 並存放到 emrPath 路徑
              }
              // } catch (ex) {
              //   this.notification.showError('電子病歷產生失敗');
              // }
            }

            //如果診所有開啟PACS功能，且就醫類別為IC卡或異常掛號，則補WorkList2
            if (this.isEnabledPACS) {
              let iType = this.histRcd.Register.IType;
              if (iType && ["1", "2"].includes(iType)) {
                this.sendPACS("workList2");
              }
            }

            if (this.isEnabledTOCC) {
              await this.toccService.uploadDengue(
                this.histService.DxChanged(this.originHistRcd, this.histRcd),
                false,
                this.newDengueUploadDto(),
                this.dengueFormDto,
                this.dengueFlag,
                this.dengueResult
              );
            }

            // if (result.CompleteMode == 'List' || result.CompleteMode == 'DontPrint' || this.fromHist || this.entranceType == 'Declare') {
            if (result.CompleteMode == 'Next' || result.CompleteMode == 'DontPrintAndNext') {
              await this.next();
            } else {
              if (result.CompleteMode == 'DontPrint') {
                this.exit();
              } else {
                //if(!result.IsPrintWay){
                  // this.exit();
                //}
                if (!this.isDemoMode) {
                  await this.print(result);
                  debugger
                  if (result.IsPrintDrugBag) await this.printService.printDrugbag(this.histRcd.Register.Id, this.histRcd.Hist.HRxs);
                } else {
                  await this.printForDemo(result);
                }
                this.exit();
              }
            }
          } else {    // 完診 -> 重新批價 -> 失敗
            this.closeLoader();
            setTimeout(() => {
              this.isPricingWinOpened = false;
            }, 10);
            this.notification.showError('病歷儲存失敗。');
          }
        } else {
          if (await this.save(HistActionEnum.Save)) {
            this.exit();
          } else {  // 完診 -> 儲存離開 -> 失敗
            this.closeLoader();
            setTimeout(() => {
              this.isPricingWinOpened = false;
            }, 10);
            this.notification.showError('病歷儲存失敗。');
          }
          this.histService.isHist = false;
        }
      } else {
        if (await this.save(HistActionEnum.Finish)) {
          // if (!this.isDemoMode) {
          //   await this.print(result, isRxDxChanged);
          // } else {
          //   await this.printForDemo(result);
          // }
          if (this.histRcd.Register.ICode !== '000' && this.histRcd.Register.IType !== '3') {  // 欠卡('3')和異掛類為('000')不需查【卡掛號刷卡資料檔】
            var nhiData = await this.nhiApi.GetNHIData(this.histRcd.Register.Id);
            if (nhiData.RegIc != null) {
              this.nhiRegICData = nhiData.RegIc;
              var xml = CreateICXml_2(this.histRcd.Register.RegDate, nhiData);
              var fileName = `Upload_IC2.0\\${DateHelper.formatROCDate(new Date())}\\IC_${DateHelper.getTimeString(new Date())}.xml`;

              await this.hcrService.SaveTextAs(fileName, xml, false, 'big5');
            }
            // 先只產生檔案
            //var uploadRet = await this.hcrService.VisionApi.UploadData(fullFileName,nhiDatas.length.toString());
            // console.log(xml);
          }

          // 衛星藥局
          if (this.histRcd.Hist.useSatellitePharmacy) {
            try {
              //未完診->完診? 新增 : 修改
              var caseMethod = histState !== 'F' ? CaseMethod.Add : CaseMethod.Resend_Modify;

              var ret = await this.spService.send(this.histRcd, this.nhiRegICData, caseMethod);
              if (!ret.ret) {
                this.notification.showError('衛星藥局轉送失敗: ' + ret.msg);
              }
            } catch (ex) {
              // console.log(ex);
              this.notification.showError('衛星藥局轉送失敗');
            }
          }

          // 電子病歷
          if (this.histRcd.Hist.useEmr) {
            // try {
            //this.histRcd.Hist.emrPath =  `\\\\192.168.0.17\\ftp\\`;  // 測試用
            if (this.histRcd.Hist.emrPath && this.histRcd.Hist.emrPath != ' ') {
              this.generalEmrXML(this.histRcd);  // 產生電子病歷xml 並存放到 emrPath 路徑
            }
            // } catch (ex) {
            //   this.notification.showError('電子病歷產生失敗');
            // }
          }

          //如果診所有開啟PACS功能，且就醫類別為IC卡或異常掛號，則補WorkList2
          if (this.isEnabledPACS) {
            let iType = this.histRcd.Register.IType;
            if (iType && ["1", "2"].includes(iType)) {
              this.sendPACS("workList2");
            }
          }

          if (this.isEnabledTOCC) {
            await this.toccService.uploadDengue(
              this.histService.DxChanged(this.originHistRcd, this.histRcd),
              false,
              this.newDengueUploadDto(),
              this.dengueFormDto,
              this.dengueFlag,
              this.dengueResult
            );
          }

          if (result.CompleteMode == 'Next' || result.CompleteMode == 'DontPrintAndNext') {
            await this.next();
          } else {
            if (result.CompleteMode == 'DontPrint') {
              this.exit();
            } else {
              //if(!result.IsPrintWay) {
                // this.exit();
              //}
              // 列印搬到這邊
              if (!this.isDemoMode) {
                await this.print(result);
                debugger
                if (histState == 'F' && result.IsPrintDrugBag) { // 列印藥袋
                  this.drugsSelectorOpen = true;
                  this.mainLayoutService.hideLoader();
                } else {
                  if (result.IsPrintDrugBag) {
                    await this.printService.printDrugbag(this.histRcd.Register.Id, this.histRcd.Hist.HRxs);
                  }
                }
              } else {
                await this.printForDemo(result);
                this.exit();
              }
            }
          }
        } else {    // 完診 -> 儲存 -> 失敗
          this.closeLoader();
          setTimeout(() => {
            this.isPricingWinOpened = false;
          }, 10);
          this.notification.showError('病歷儲存失敗。');
        }
      }
    }
  }
  //#endregion

  //#region EMR batch -- just for EC
  // for EMR batch export
  forEC = false;  // true for batch export EMR
  batchClicinId = 10054;  //120, 10054(伊生診所)
  async onBtnBatchEMR() {
    this.mainLayoutService.showLoader();
    var regList = await this.regApi.GetAllRegisterOfClinic(new Date('2024-03-24'), new Date('2024-04-28'), '', this.batchClicinId);
    if (regList) {
      regList.forEach(async (regId: number) => {
        let emrHist = await this.histApi.getWholeHist({ regId: regId });
        if (emrHist) {
          emrHist.Hist.emrPath = "D:\\emrs";  //D:\\emrs
          this.generalEmrXML(emrHist, true);
        }
      });

      this.histRcd = this.originHistRcd;
      this.mainLayoutService.hideLoader();
    } else {
      this.mainLayoutService.hideLoader();
    }
  }
  //#endregion EMR batch -- just for EC

  async generalEmrXML(hist: WholeHist, needUpdateDB: boolean = false) {

    try {
      var todayNow = new Date();
      var timeStr = `${todayNow.getHours().toString().padStart(2, '0') + todayNow.getMinutes().toString().padStart(2, '0') + todayNow.getSeconds().toString().padStart(2, '0')}`;
      var createTime = `${todayNow.getFullYear()}${(todayNow.getMonth() + 1).toString().padStart(2, '0')}${todayNow.getDate().toString().padStart(2, '0')}` + timeStr;
      var patBirthday = new Date(hist.Patient.Birthday);
      var patientBirthday = `${patBirthday.getFullYear()}${(patBirthday.getMonth() + 1).toString().padStart(2, '0')}${patBirthday.getDate().toString().padStart(2, '0')}`;
      var identity = hist.Register.ICode == '000' ? '自費' : '健保';
      var gender = hist.Patient.CId.substring(1, 1) == '1' ? 'M' : 'F';
      var tel = hist.Patient.Phone ? `<TEL><Home>` + escapeStr(hist.Patient.Phone) + `</Home></TEL>` : ``;
      var address = hist.Patient.City + hist.Patient.Area + hist.Patient.Street;
      var zipCode = hist.Patient.ZipCode ? `<ZipCode>` + escapeStr(hist.Patient.ZipCode) + `</ZipCode>` : ``;
      if (address?.length > 0) address = `<Address><Original>` + zipCode + `<Location>` + escapeStr(address) + `</Location></Original></Address>`
      var regDate = new Date(hist.Register.RegDate);
      var visitTimeStr = `${regDate.getHours().toString().padStart(2, '0') + regDate.getMinutes().toString().padStart(2, '0') + regDate.getSeconds().toString().padStart(2, '0')}`;
      var visitDate = `${regDate.getFullYear()}${(regDate.getMonth() + 1).toString().padStart(2, '0')}${regDate.getDate().toString().padStart(2, '0')}` + visitTimeStr;
      var visitSeq = visitDate.substring(10, 4);
      var hisDocPK = (hist.Patient.PatientNo.length == 10 ? hist.Patient.PatientNo.substring(3, hist.Patient.PatientNo.length) : hist.Patient.PatientNo.padStart(7, '0')) + DateHelper.getROCDateTimeString(regDate);
      var sheetName = `西醫門診單`;
      var medType = `西醫`;
      var chartNo = hist.Patient.PatientNo.length == 10 ? hist.Patient.PatientNo.substring(3, hist.Patient.PatientNo.length) : hist.Patient.PatientNo.padStart(7, '0');
      var emrPath = hist.Hist.emrPath;
      var cc = hist.Hist.CC.replace(/\r\n/g, ' ').replace(/[\r\n]+/g, ' ');
      var fileName = hisDocPK + `-` + hist.Register.DoctorCId + `-` + hist.Register.DoctorName + `-TW.Foxconn.Clinic.WesternMedicine.1-` + medType + `-`;
      fileName += chartNo + `-` + visitDate + `-` + visitSeq + `-` + hist.Patient.CName.replace('?', '？') + `.xml`;
      var filePath = emrPath.substring(emrPath.length - 1, 1) == '\\' ? (emrPath + fileName) : (emrPath + '\\' + fileName);
      // console.log('filePath >>>' + filePath);
      var xmlContent = `<?xml version="1.0" encoding="Big5"?>
      <EMR xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="TW.Foxconn.Clinic.WesternMedicine.1.1.xsd">
      <DocumentInfo>
      <HISDocPK>` + escapeStr(hisDocPK) + `</HISDocPK>
        <HospitalID>` + escapeStr(this.cln.NHICode) + `</HospitalID>
        <HospitalName>` + escapeStr(this.cln.Name) + `</HospitalName>
        <Sheet>
          <ID>TW.Foxconn.Clinic.WesternMedicine.1</ID>
          <Name>` + escapeStr(sheetName) + `</Name>
          <Version>1</Version>
        </Sheet>
        <Doc>
          <DocConfidentialityCode>N</DocConfidentialityCode>
          <CreateTime>` + escapeStr(createTime) + `</CreateTime>
        </Doc>
      </DocumentInfo>
      <PatientInfo>
        <ChartNo>` + escapeStr(chartNo) + `</ChartNo>
        <PatientName>` + escapeStr(hist.Patient.CName) + `</PatientName>
        <Birthday>` + escapeStr(patientBirthday) + `</Birthday>
        <Identity>` + escapeStr(identity) + `</Identity>
        <Gender>` + escapeStr(gender) + `</Gender>
        <PatientID>` + escapeStr(hist.Patient.CId) + `</PatientID>`;
      if (tel.length > 0) xmlContent += tel;
      if (address.length > 0) xmlContent += address;
      xmlContent += `</PatientInfo>
      <Encounter>
        <VisitDate>` + escapeStr(visitDate) + `</VisitDate>
        <VisitSeq>` + escapeStr(visitSeq) + `</VisitSeq>
        <Department>` + escapeStr(hist.Register.MedDeptCode) + `</Department>
        <DoctorID>` + escapeStr(hist.Register.DoctorCId) + `</DoctorID>
        <DoctorName>` + escapeStr(hist.Register.DoctorName ?? '') + `</DoctorName>
        <HeightUnit>cm</HeightUnit>
        <WeightUnit>Kg</WeightUnit>
        <ChiefComplain>` + escapeStr(cc) + `</ChiefComplain>
        <MajorInjuries>
          <MajorInjuryFlag>未知</MajorInjuryFlag>
        </MajorInjuries>`
        + this.getDiagnosis(hist) + this.getTreatments(hist) +
        `</Encounter>`
        + this.getDrugs(hist) +
        `</EMR>`;
      this.hcrService.SaveTextAs(filePath, xmlContent, false, 'Big5');


      if (needUpdateDB) {
        var data: emrDto = new emrDto();
        data.regId = hist.Register.Id;
        data.clinicId = this.batchClicinId;
        data.emrContent = xmlContent;
        setTimeout(() => {
          this.regApi.UpdateEMR(data);
        }, 1000);
      }
    } catch (ex) {
      this.notification.showError('RegId:[' + hist.Register.Id + ']電子病歷產生失敗 [' + ex.message + ']');
    }
  }

  getDiagnosis(hist: WholeHist): string {
    var xml = ``;
    if (hist.Hist.Dx1) {
      xml += `<Diagnosis>
        <Code>` + escapeStr(hist.Hist.Dx1) + `</Code>
        <Name>` + escapeStr(hist.Hist.Dx1Name) + `</Name>
      </Diagnosis>`;
    }
    if (hist.Hist.Dx2) {
      xml += `<Diagnosis>
        <Code>` + escapeStr(hist.Hist.Dx2) + `</Code>
        <Name>` + escapeStr(hist.Hist.Dx2Name) + `</Name>
      </Diagnosis>`;
    }
    if (hist.Hist.Dx3) {
      xml += `<Diagnosis>
        <Code>` + escapeStr(hist.Hist.Dx3) + `</Code>
        <Name>` + escapeStr(hist.Hist.Dx3Name) + `</Name>
      </Diagnosis>`;
    }
    if (hist.Hist.Dx4) {
      xml += `<Diagnosis>
        <Code>` + escapeStr(hist.Hist.Dx4) + `</Code>
        <Name>` + escapeStr(hist.Hist.Dx4Name) + `</Name>
      </Diagnosis>`;
    }
    if (hist.Hist.Dx5) {
      xml += `<Diagnosis>
        <Code>` + escapeStr(hist.Hist.Dx5) + `</Code>
        <Name>` + escapeStr(hist.Hist.Dx5Name) + `</Name>
      </Diagnosis>`;
    }
    if (hist.Hist.Dx6) {
      xml += `<Diagnosis>
        <Code>` + escapeStr(hist.Hist.Dx6) + `</Code>
        <Name>` + escapeStr(hist.Hist.Dx6Name) + `</Name>
      </Diagnosis>`;
    }

    return xml;
  }

  // 檢驗??治療?? 目前沒有 2024-02-26
  getTreatments(hist: WholeHist): string {
    var xml = ``;
    var isOrdersOk = true;
    if (hist.Hist.HRxs.length <= 0) {
      isOrdersOk = false;
    }
    // 只要檢驗
    var orders = hist.Hist.HRxs.filter(x => x.RxType != 2 && x.RxType != 3 && x.RxType != 4);
    if (orders.length <= 0) {
      isOrdersOk = false;
    }
    if (isOrdersOk) {
      for (var i = 0; i < orders.length; i++) {
        var order = orders[i];
        xml += `<Treatment>
          <TreatmentRegionNHICode>＿</TreatmentRegionNHICode>
          <TreatmentNHICode>` + escapeStr(order.StdCode) + `</TreatmentNHICode>
          <TreatmentHospCode>` + escapeStr(order.RxCode) + `</TreatmentHospCode>
          <TreatmentDescription>` + escapeStr(order.ProdName) + `</TreatmentDescription>
          <TreatmentDose>` + order.Dose + `</TreatmentDose>
        </Treatment>`;
      }
    }
    return xml;   // 回傳
  }

  getDrugs(hist: WholeHist): string {
    var xml = ``;
    var regDate = new Date(hist.Register.RegDate);
    var startDate = `${regDate.getFullYear()}${(regDate.getMonth() + 1).toString().padStart(2, '0')}${regDate.getDate().toString().padStart(2, '0')}`;
    var orders = hist.Hist.HRxs.filter(x => x.RxType == 2 || x.RxType == 3 || x.RxType == 4);   // 檢驗過濾掉
    orders.forEach(odr => {
      var drugEndDate = new Date();
      drugEndDate.setDate(regDate.getDate() + odr.Days);
      var endDate = `${drugEndDate.getFullYear()}${(drugEndDate.getMonth() + 1).toString().padStart(2, '0')}${drugEndDate.getDate().toString().padStart(2, '0')}`;
      xml += `<Drug>
        <DrugNHICode>` + escapeStr(odr.StdCode) + `</DrugNHICode>
        <DrugCode>` + escapeStr(odr.RxCode) + `</DrugCode>
        <DrugName>` + escapeStr(odr.ProdName) + `</DrugName>
        <Dose>` + odr.Dose + `</Dose>
        <DoseUnit>` + escapeStr(odr.Unit) + `</DoseUnit>
        <Route>` + escapeStr(odr.Way) + `</Route>
        <Frequency>` + escapeStr(odr.Freq) + `</Frequency>
        <DrugStartDate>` + escapeStr(startDate) + `</DrugStartDate>
        <DrugEndDate>` + escapeStr(endDate) + `</DrugEndDate>
        <Days>` + odr.Days + `</Days>
        <TotalAmount>` + Number(odr.TotalDose).toFixed(0) + `</TotalAmount>
        </Drug>`;
    });

    return xml;
  }

  //#region print --------------------
  async print(result: Result) {
    if (result.CompleteMode == 'DontPrint' || result.CompleteMode == 'DontPrintAndNext') return;   // 只存不印
    if (result.IsPrintPrescription) { // 列印 一般處方
      await this.printService.printPrescription(this.histRcd.Register.Id);
    }
    if (result.IsPrintDetail) { // 列印收據 明細
      await this.printService.printReceipt(this.histRcd.Register.Id);
    }
    if (result.IsPrintDispExam) {   // 列印 交付檢驗單
      this.printService.printLabOutClinic(this.histRcd.Register.Id);
    }
    if (this.histRcd.Hist.CntPresNo == '1' || result.IsPrintDispPrescription) {  // 列印交付連處
      var countOpt = this.histService.EditOptions.p37;
      // 慢箋時讀p37決定張數
      if (this.histRcd.Hist.CntPresTot) {
        // "加"印1-9張
        if (/[1-9]/.test(countOpt)) {
          var count = parseInt(countOpt);
          for (var i = 0; i <= count; i++) {
            await this.printService.printRepeatPres(this.histRcd.Register.Id);
          }
        } else if (countOpt.toLowerCase() == 'z') {
          //總樟樹根據慢箋總次
          if (/[0-9]/.test(this.histRcd.Hist.CntPresTot)) {
            var count = parseInt(this.histRcd.Hist.CntPresTot);
            for (var i = 0; i < count; i++) {
              await this.printService.printRepeatPres(this.histRcd.Register.Id);
            }
          } else {
            // 無法解析 一張
            await this.printService.printRepeatPres(this.histRcd.Register.Id);
          }
        } else {
          //預設印一張
          await this.printService.printRepeatPres(this.histRcd.Register.Id);
        }
      } else {
        //非慢箋一張
        await this.printService.printRepeatPres(this.histRcd.Register.Id);
      }
    }
    // if (result.IsPrintDrugBag) {  // 列印藥袋
    //   if (this.histRcd.Register.StatusCode == 'F' && isRxChanged) { // 已經完診過 -> 應是補印，詢問藥列印那些醫令
    //     // 不再這邊輸出列印，另外處理補印藥袋
    //   } else {
    //     await this.printService.printDrugbag(this.histRcd.Register.Id);
    //   }
    // }
    if (result.IsPrintCtrDrugs) {  // 管制藥品處方箋
      var ctrDrugsContent = await this.printService.getCtrdrugs(this.histRcd.Register.Id);
      if (ctrDrugsContent.indexOf('[#NODATA#]') >= 0) {
        //this.notification.showWarning('尚未設定管制藥品資料,無法列印');
      }
      else {
        await this.printService.printCtrdrugs(this.histRcd.Register.Id);
      }
    }

    if (result.IsPrintWay) {
      await this.printService.printWay(this.histRcd.Register.Id);
      //this.medicationWayAndReceiptComponent.onPrint(this.histRcd.Register.Id);
    }
  }
  //#endregion

  //#region print for demo
  async printForDemo(result: Result) {
    if (result.CompleteMode == 'DontPrint' || result.CompleteMode == 'DontPrintAndNext') return;   // 只存不印
    var html = '';
    if (result.IsPrintDetail) {
      let ReceiptContent = await this.printService.getReceipt(this.histRcd.Register.Id);
      html += this.createHTMLPage(ReceiptContent);
    }
    if (result.IsPrintPrescription) {
      let prescriptionContent = await this.printService.getPrescription(this.histRcd.Register.Id);
      html += this.createHTMLPage(prescriptionContent);
    }
    if (this.histRcd.Hist.CntPresNo == '1' || result.IsPrintDispPrescription) {
      let countOpt = this.histService.EditOptions.p37;
      let RepeatPres = '';
      // 慢箋時讀p37決定張數
      if (this.histRcd.Hist.CntPresTot) {
        // "加"印1-9張
        if (/[1-9]/.test(countOpt)) {
          var count = parseInt(countOpt);
          for (var i = 0; i <= count; i++) {
            RepeatPres += await this.printService.getRepeatPres(this.histRcd.Register.Id);
          }
        } else if (countOpt.toLowerCase() == 'z') {
          //總樟樹根據慢箋總次
          if (/[0-9]/.test(this.histRcd.Hist.CntPresTot)) {
            var count = parseInt(this.histRcd.Hist.CntPresTot);
            for (var i = 0; i < count; i++) {
              RepeatPres += await this.printService.getRepeatPres(this.histRcd.Register.Id);
            }
          } else {
            // 無法解析 一張
            RepeatPres += await this.printService.getRepeatPres(this.histRcd.Register.Id);
          }
        } else {
          //預設印一張
          RepeatPres += await this.printService.getRepeatPres(this.histRcd.Register.Id);
        }
      } else {
        //非慢箋一張
        RepeatPres += await this.printService.getRepeatPres(this.histRcd.Register.Id);
      }
      html += RepeatPres;
    }
    if (result.IsPrintDrugBag) {  // 列印藥袋
      let drugBagContent = await this.printService.getDrugbag(this.histRcd.Register.Id);
      html += this.createHTMLPage(drugBagContent);
    }
    if (result.IsPrintCtrDrugs) {  // 管制藥品處方箋
      let ctrDrugsContent = await this.printService.getCtrdrugs(this.histRcd.Register.Id);
      if (ctrDrugsContent.indexOf('[#NODATA#]') >= 0) {
        //this.notification.showWarning('尚未設定管制藥品資料,無法列印');
      }
      else {
        html += this.createHTMLPage(ctrDrugsContent);
      }
    }
    // console.log(html);
    // 有內容才列印
    if (html.length > 0) {
      let htmlContent = "<HTML><head></head><BODY style='word-wrap:break-word' onload='window.print();window.close();'>" + html + "</BODY></HTML>";
      var printPage = window.open("", "printing", "");
      printPage.document.open();
      printPage.document.write(htmlContent);
      printPage.document.close();
    }
  }

  createHTMLPage(content: string): string {
    return `<div
            style="
            margin: 0px auto;
            height: ` + this.sizePage.height + `cm;
            width: ` + this.sizePage.width + `cm;
            padding-top: ` + this.paddingPage.top + `cm;
            padding-right: ` + this.paddingPage.right + `cm;
            padding-bottom: ` + this.paddingPage.bottom + `cm;
            padding-left: ` + this.paddingPage.left + `cm;
            display: block;
            margin: 0px auto;
            box-sizing: border-box;
            ">
            <div class="content">
            ` + content + `
            </div>
        </div>\n`;
  }

  //#endregion

  //#region Lab Report
  onBtnLabReportClick() {
    this.openLabReportWin();
  }

  onBtnLoadPACSClick = async () => {
    if (this.hcrService.ConnectStatus) {
      this.isShowPACSConnectDialog = true;
    }
    else {
      this.notification.showError("主控台程式尚未開啟，無法使用PACS開單");
    }
  }

  openLabReportWin() {
    this.isLabReportWinOpened = true;
  }
  closeLabReportWin() {
    this.isLabReportWinOpened = false;
    this.detectChanges();
  }
  //#endregion

  //#region DM
  onBtnDMClick() {
    this.openDMWin();
  }
  openDMWin() {
    this.isDMWinOpened = true;
  }
  closeDMWin() {
    this.isDMWinOpened = false;
    this.detectChanges();
  }
  //#endregion

  //#region open register
  onBtnRegEditClick() {
    this.isShowRegDialog = true;
    this.changeToSelfPay = false;
    this.detectChanges();
  }
  //#endregion

  //#region Referral
  onBtnReferralClick() {
    this.referralFormGroup = this.makeReferralFormGroup();
    if (this.referralOut) {
      this.referralOut.keepPhoneNo = this.keepPatientPhone;
    }
    this.openReferralWin();
  }

  async openReferralWin() {
    this.isCopy = false;
    await this.pullAllData()   // Pull all data to this.histRcd
    // if(this.checkOrderFreqQTY()) {
    var newHistRcd: WholeHist = deepCopy<WholeHist>(this.histRcd);  // Deep copy a new object
    this.refHistRcd = newHistRcd;
    this.isReferralWinOpened = true;
    // }
  }

  async closeReferralWin() {
    this.isReferralWinOpened = false;
    this.detectChanges();
  }

  async ReferralSaveAs() {
    if (this.checkMustField()) {
      this.keepPatientPhone = this.referralFormGroup.controls.PhoneNo.value;
      var printed = this.referralFormGroup.controls.printReferral.value;  // 列印
      var uploaded = this.referralFormGroup.controls.uploadNHI.value;     // 產生並上傳xml
      var hasUploades = false;
      // 更新主訴
      this.histRcd.Hist.CC = this.referralFormGroup.controls.CC.value;
      // 更新診斷
      if (this.referralFormGroup.controls.Dx.value) {
        if (!this.histRcd.Hist.Diags[0]) {
          this.histRcd.Hist.Diags.push(new HistDiag());
        } //histRcd.Hist.Diags
        this.histRcd.Hist.Diags[0].code = this.referralFormGroup.controls.Dx.value;
        this.histRcd.Hist.Diags[0].name = this.referralFormGroup.controls.DxName.value;
        this.histRcd.Hist.Dx1 = this.referralFormGroup.controls.Dx.value;
        this.histRcd.Hist.Dx1Name = this.referralFormGroup.controls.DxName.value;
      }
      if (this.referralFormGroup.controls.Dx1.value) {
        if (!this.histRcd.Hist.Diags[1]) {
          this.histRcd.Hist.Diags.push(new HistDiag());
        }
        this.histRcd.Hist.Diags[1].code = this.referralFormGroup.controls.Dx1.value;
        this.histRcd.Hist.Diags[1].name = this.referralFormGroup.controls.DxName1.value;
        this.histRcd.Hist.Dx2 = this.referralFormGroup.controls.Dx1.value;
        this.histRcd.Hist.Dx2Name = this.referralFormGroup.controls.DxName1.value;
      }
      if (this.referralFormGroup.controls.Dx2.value) {
        if (!this.histRcd.Hist.Diags[2]) {
          this.histRcd.Hist.Diags.push(new HistDiag());
        }
        this.histRcd.Hist.Diags[2].code = this.referralFormGroup.controls.Dx2.value;
        this.histRcd.Hist.Diags[2].name = this.referralFormGroup.controls.DxName2.value;
        this.histRcd.Hist.Dx3 = this.referralFormGroup.controls.Dx2.value;
        this.histRcd.Hist.Dx3Name = this.referralFormGroup.controls.DxName2.value;
      }

      //產生h45緊急傷病開單時間，雖然h39特定註記=A才必填
      const speNoteChooseItem = this.referralFormGroup.controls.SpeNote.value === undefined ? '' : this.referralFormGroup.controls.SpeNote.value.split(" ")[0];
      let urgPatTime = '';
      if (speNoteChooseItem == 'A') {
        let todayDate = new Date();
        urgPatTime = this.formatDate(todayDate.toLocaleDateString()) + todayDate.getHours().toString().padStart(2, '0') + todayDate.getMinutes().toString().padStart(2, '0');
      }
      this.referralFormGroup.controls.UrgPatTime.patchValue(urgPatTime);

      this.diagsComp.diags = this.histRcd.Hist.Diags
      //this.diagsComp.fillFormData(this.histRcd.Hist.Diags);
      var ccIn1000 = '';
      var ccOutOf1000 = '';
      var cc = this.referralFormGroup.controls.CC.value;
      if (cc.length > 0) {
        ccIn1000 = this.truncateString(cc, 1000);   // 擷取前1000個bytes的字元串(因資料庫欄位為varchar(1000)，所以只能截取前1000個bytes)
        ccOutOf1000 = cc.replace(ccIn1000, '');     // 超出1000bytes的字元串
        var drTrans = this.referralFormGroup.controls.DrTrans.value;
        if (ccOutOf1000.length > 0) {
          drTrans += '\nCC:' + ccOutOf1000;         // 把主訴超過1000個bytes的部份放到交班注意事項之後，以CC:為記號。
          this.referralFormGroup.controls.DrTrans.setValue(drTrans);
        }
      } else {
        ccIn1000 = '';
        ccOutOf1000 = '';
      }
      this.referralFormGroup.controls.CC.setValue(ccIn1000);

      // 列印
      if (printed) await this.printReferral();
      // 產生並上傳xml
      if (uploaded) {
        hasUploades = await this.generateReferralXML();
      }
      this.closeReferralWin();

      // 加一筆醫令--有上傳: 01036C，未上傳但有列印診單: 01037C
      var rxCode = "";
      if (uploaded && hasUploades) { // 有上傳且上傳成功: 01036C  (醫師已輸入就不要再加)
        if (!this.RefOrderExist("01036C")) rxCode = "01036C";
      } else {
        // 未上傳或上傳失敗: 01037C (醫師已輸入就不要再加)
        if (!this.RefOrderExist("01037C")) rxCode = "01037C";
      }

      // NHILevel:　A西醫(醫學中心)
      //            B西醫(區域醫院)
      //            C西醫(地區醫院) G牙醫(地區醫院) R中醫(地區醫院之中醫或中醫醫院)
      //            D西醫(基層院所) H牙醫(基層院所) S中醫(基層院所) L西醫基層-山地離島地區
      //            U藥局
      var lev1: string[] = ['A'];
      var lev2: string[] = ['B'];
      var lev3: string[] = ['C', 'G', 'R'];
      var lev4: string[] = ['D', 'H', 'S', 'L'];
      // var lev0: string[] = [ 'U' ];

      // 診所 Level
      var clnLevel = !this.cln.NHIType ? 0 : (lev1.includes(this.cln.NHILevel) ? 1 : (lev2.includes(this.cln.NHILevel) ? 2 : (lev3.includes(this.cln.NHILevel) ? 3 : (lev4.includes(this.cln.NHILevel) ? 4 : 0)))); // Number(this.cln.NHILevel);
      // 接收轉診院所 Level
      var refLevel = !this.referralFormGroup.controls.InHspLevel.value ? 0 : Number(this.referralFormGroup.controls.InHspLevel.value);
      if (rxCode != "") {  // 未輸入轉診醫令->加入一筆rxCode的醫令 (HLevel 醫療院所層級必須是高於轉出院所才加轉診醫令(數字越小層級越高))
        if (refLevel < clnLevel) {
          await this.addOrder(rxCode);
        } else {
          this.userConfirm.showConfirm({
            'msg': '轉診醫令01036C或01037C必須用在上轉高於轉出院所層級的醫療院所。',
            'title': '提示',
            'hideNo': true,
            'textYes': 'OK'
          });
        }
      }

      this.histRcd.Hist.Referral = "Y";
      this.histRcd.Hist.RefHosp = this.referralFormGroup.controls.InHspid.value;  // 轉出院所(對方院所:建議轉診院所)

      //this.histRcd.Hist.InHosp = this.histRcd.Register.OriHosp ?? '';   // 掛號時紀錄的轉入院所
      this.histRcd.Hist.RxHosp = this.cln.NHICode;

      if (this.histRcd.Register.OriHosp && this.histRcd.Register.RefDiagFee) {  // 有轉入+轉出
        await this.addReferral(2);
      } else { // 轉診轉出
        await this.addReferral(1);
      }
      // 取回轉診資料
      this.getReferrals();
    }
  }

  async addReferral(refType: number) {
    // 產生轉診資料物件模型
    var data = this.createReferralData(refType);

    try {
      await this.refApi.CreateOrUpdate(data);
    } catch (ex) {
      this.notification.showError(ex);
    }
  }

  truncateString(input: string, maxLength: number): string {
    if (input.length == 0) {
      return '';
    }

    let byteCount = 0;    // byte計數器
    let truncated = '';   // 擷取的字串
    // 迴圈逐字元計算bytes並擷取
    for (const char of input) {
      const charByteSize = new TextEncoder().encode(char).length; // 每個字元的bytes
      if (byteCount + charByteSize > maxLength) {   // 判斷是否超出要截取的最大長度，超出的話就結束迴圈
        break;
      }

      truncated += char;
      byteCount += charByteSize;
    }
    // 回傳截取的字串
    return truncated;
  }

  createReferralData(refType: number): Referral {
    var refData: Referral = new Referral();
    refData.Type = refType;
    // if (refType == 1) {   // 轉出
    if (this.referralOut) {  // 有舊資料
      refData = deepCopy<Referral>(this.referralOut);
    } else {
      refData.Id = 0;
      refData.RegId = this.histRcd.Register.Id;
      refData.RegDate = this.histRcd.Register.RegDate;
      refData.ClinicId = UserCache.getLoginUser().Clinic.Id;
      refData.ClinicCode = UserCache.getLoginUser().Clinic.Code;
      refData.PatientId = this.histRcd.Patient.Id;
      refData.PatientNo = this.histRcd.Patient.PatientNo;
    }
    // 取畫面資料更新Referral物件
    refData.ExpiryDate = this.referralFormGroup.controls.ExpiryDate.value;
    refData.ContactName = this.referralFormGroup.controls.ContactName.value;
    refData.AllergyHistory = this.referralFormGroup.controls.AllergyHistory.value;
    refData.CC = this.referralFormGroup.controls.CC.value;
    refData.Dx = this.referralFormGroup.controls.Dx.value;
    refData.Dx1 = this.referralFormGroup.controls.Dx1.value;
    refData.Dx2 = this.referralFormGroup.controls.Dx2.value;
    refData.RecOrder = this.referralFormGroup.controls.HRx.value;
    refData.RecOrderDate = this.referralFormGroup.controls.RecOrderDate.value;
    refData.RecLISDate = this.referralFormGroup.controls.RecLISDate.value;
    refData.RecLISResult = this.referralFormGroup.controls.RecLISResult.value;
    refData.RecLISReport = this.referralFormGroup.controls.RecLISReport.value;
    refData.Objects = this.referralFormGroup.controls.Objects.value?.split(" ")[0];
    refData.ObjectsNote = this.referralFormGroup.controls.ObjectsNote.value;
    // refData.IssueDate = this.referralFormGroup.controls.IssueDate.value;
    refData.IssueDate = this.referralFormGroup.controls.ReferralDate.value;
    refData.SchMedDate = this.referralFormGroup.controls.SchMedDate.value;
    refData.SchMedDep = this.referralFormGroup.controls.SchMedDep.value;
    refData.SchMedDiag = this.referralFormGroup.controls.SchMedDiag.value;
    refData.SchMedNum = this.referralFormGroup.controls.SchMedNum.value;
    refData.InHspid = this.referralFormGroup.controls.InHspid.value;
    refData.InDep = this.referralFormGroup.controls.InDep.value;
    refData.InDrName = this.referralFormGroup.controls.InDrName.value;
    refData.InHsAddrw = this.referralFormGroup.controls.InHsAddrw.value;
    refData.InHsPhone = this.referralFormGroup.controls.InHsPhone.value;
    refData.DrTrans = this.referralFormGroup.controls.DrTrans.value;
    refData.NewBornNote = this.referralFormGroup.controls.NewBornNote.value;
    refData.SerialNum = this.referralFormGroup.controls.SerialNum.value;
    refData.PilotProject = this.referralFormGroup.controls.PilotProject.value;
    refData.ReferralNum = this.referralFormGroup.controls.ReferralNum.value;
    refData.SpeNote = this.referralFormGroup.controls.SpeNote.value?.split(" ")[0];
    refData.SpeCaseNote = this.referralFormGroup.controls.SpeCaseNote.value?.split(" ")[0];
    refData.UrgPatNote = this.referralFormGroup.controls.UrgPatNote.value?.split(" ")[0];
    refData.ReferralPur = this.referralFormGroup.controls.ReferralPur.value?.split(" ")[0];
    refData.UrgPatRea = this.referralFormGroup.controls.UrgPatRea.value?.split(" ")[0];
    refData.UrgPatOth = this.referralFormGroup.controls.UrgPatOth.value;
    // refData.UrgPatTime = this.referralFormGroup.controls.UrgPatTime.value;
    //文字內容格式為yyyyMMddHHmm，此處加上00秒
    refData.UrgPatTime = new Date(undefined);//new Date('');
    let urgPatTime = this.referralFormGroup.controls.UrgPatTime.value;
    if (urgPatTime) {
      let toFullDate = urgPatTime.substring(0, 4) + '-' + urgPatTime.substring(4, 6) + '-' + urgPatTime.substring(6, 8) + ' ' + urgPatTime.substring(8, 10) + ':' + urgPatTime.substring(10) + ':00';
      refData.UrgPatTime = new Date(toFullDate);
    }

    return refData;
  }

  RefOrderExist(rxCode: string): boolean {
    let tempHRxs = Object.assign([], this.ordersComp.orders);
    tempHRxs = tempHRxs.filter(x => x.RxCode == rxCode);
    if (tempHRxs.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async printReferral() {
    // 依據勾選項，關閉畫面回看診畫面，看診畫面按下[儲存並離開]時依據轉診單設定(列印轉診單、要上傳健保署平台)
    // 離開前要做的上列勾選的作業，原本 HIS的 [存檔]、[寫IC卡]是確定後一定要做的。
    if (this.referralFormGroup.controls.printReferral.value) {
      // this.allowPrintReferral = true;  // 回復預設值
      // 統一送展望主控台列印
      const printContents = this.generateReferralContent();
      var ret = await this.printService.printHtml(444, '轉診轉檢單', printContents);
      if (ret.Successed) {
        this.notification.showSuccess('轉診轉檢單已送出列印!', true);
      } else {
        this.notification.showError('轉診轉檢單送出列印失敗!');
      }
    }
  }

  checkMustField(): boolean {
    // 需檢查的必要欄位
    const objectChoseItem = this.referralFormGroup.controls.Objects.value === undefined ? '' : this.referralFormGroup.controls.Objects.value.split(" ")[0];
    const speNoteChooseItem = this.referralFormGroup.controls.SpeNote.value === undefined ? '' : this.referralFormGroup.controls.SpeNote.value.split(" ")[0];
    const UrgPatNoteChooseItem = this.referralFormGroup.controls.UrgPatNote.value === undefined ? '' : this.referralFormGroup.controls.UrgPatNote.value.split(" ")[0];
    const UrgPatReaChooseItem = this.referralFormGroup.controls.UrgPatRea.value === undefined ? '' : this.referralFormGroup.controls.UrgPatRea.value.split(" ")[0];
    const SpeCaseNoteChooseItem = this.referralFormGroup.controls.SpeCaseNote.value === undefined ? '' : this.referralFormGroup.controls.SpeCaseNote.value.split(" ")[0];
    const referralPurChooseItem = this.referralFormGroup.controls.ReferralPur.value === undefined ? '' : this.referralFormGroup.controls.ReferralPur.value.split(" ")[0];

    if (this.referralFormGroup.controls.PatientId.value == "") {
      this.notification.showError('患者基本資料不存在');
      return false;
    } else if (this.referralFormGroup.controls.PatientName.value == "") {
      this.notification.showError('患者姓名未填');
      return false;
    } else if (this.referralFormGroup.controls.ReferralDate.value == "") {
      this.notification.showError('轉診日未填');
      return false;
    } else if (this.referralFormGroup.controls.ExpiryDate.value == "") {
      this.notification.showError('有效日未填');
      return false;
    } else if (this.referralFormGroup.controls.PhoneNo.value == "") {
      this.notification.showError('患者電話未填');
      return false;
    } else if (this.referralFormGroup.controls.DoctorCid.value == "") {
      this.notification.showError('醫師身分證未填');
      return false;
    } else if (this.referralFormGroup.controls.DoctorPhone.value == "") {
      this.notification.showError('醫師連絡電話未填');
      return false;
    } else if (this.referralFormGroup.controls.InHspid.value == "") {
      this.notification.showError('建議轉診→醫院未填');
      return false;
    } else if (this.referralFormGroup.controls.InDep.value == "") {
      this.notification.showError('轉診科別未填');
      return false;
    } else if (this.referralFormGroup.controls.CC.value == "") {
      this.notification.showError('病情摘要未填');
      return false;
    } else if (this.referralFormGroup.controls.Dx.value == "") {
      this.notification.showError('診斷未填');
      return false;
    } else if (objectChoseItem == "") {
      this.notification.showError('轉診目的未填');
      return false;
    } else {
      if (objectChoseItem == "A") {
        // 若h18欄位填入A，本欄[轉診目的(轉介其他試辦計畫或方案)]為必填。
        if (referralPurChooseItem == "") {
          this.notification.showError('轉診目的為 A，轉診目的(轉介其他試辦計畫或方案)必填');
          return false;
        }

        // 轉診目的 (Objects) 為 A，特定註記 (SpeNote) 必填且須為 CDEFG
        if (speNoteChooseItem != "C" &&
          speNoteChooseItem != "D" &&
          speNoteChooseItem != "E" &&
          speNoteChooseItem != "F" &&
          speNoteChooseItem != "G") {
          this.notification.showError('轉診目的為 A，特定註記必須為 CDEFG');
          return false;
        }
      } else if (objectChoseItem == "6") {
        // 轉診目的 (Objects) 為 6，目的說明 (ObjectsNote) 必填
        if (this.referralFormGroup.controls.ObjectsNote.value == "") {
          this.notification.showError('轉診目的為 6，目的說明必填');
          return false;
        }
        // 轉診目的7 和 8 限定 緊急傷病患使用(h39=A)
      } else if (objectChoseItem == "7" || objectChoseItem == "8") {
        if (speNoteChooseItem != "A") {
          this.notification.showError('轉診目的為 7和8，特定註記必須為 A');
          return false;
        }
      }

      if (objectChoseItem != "6") {
        // 若轉診目的非選擇「6 ：其他」則不可填寫此欄位
        if (this.referralFormGroup.controls.ObjectsNote.value != "") {
          this.notification.showError('轉診目的不為 6，目的說明必須空白');
          return false;
        }
      }

      if (speNoteChooseItem == "A") {
        // 若h39 欄位填入 A ，本欄h41 緊急傷病患轉出註記為必填。
        if (this.referralFormGroup.controls.UrgPatNote.value == "") {
          this.notification.showError('特定註記為 A，緊急傷病轉出註記必填');
          return false;
        }

        // 若h39欄位填入A，本欄h43 緊急傷病轉診原因為必填。
        if (UrgPatReaChooseItem == "") {
          this.notification.showError('特定註記為 A，緊急傷病轉診原因必填');
          return false;
          // 緊急傷病轉診原因 (UrgPatRea) 為 07，轉診原因(其他) (UrgPatOth) 必填
        } else if (UrgPatReaChooseItem == "07") {
          if (this.referralFormGroup.controls.UrgPatOth.value == "") {
            this.notification.showError('緊急傷病轉診原因為 07，轉診原因(其他)必填');
            return false;
          }
        }

        //若 緊急傷病轉診原因非 填入 「 07 ：其他」則不可填寫此欄位。
        if (UrgPatReaChooseItem != "07") {
          if (this.referralFormGroup.controls.UrgPatOth.value != "") {
            this.notification.showError('緊急傷病轉診原因不為 07，轉診原因(其他)必須空白');
            return false;
          }
        }

      } else if (speNoteChooseItem == "B") {  // 特定註記 (SpeNote) 為 B，特定個案轉出註記 (SpeCaseNote) 必填
        if (SpeCaseNoteChooseItem == "") {
          this.notification.showError('特定註記為 B，特定個案轉出註記必填');
          return false;
        }
      }

      // h42 轉診目的(轉介其他試辦計畫或方案)
      // 若本欄填入試辦計畫代碼01，h39欄位必須為C。
      if (referralPurChooseItem == "01") {
        if (speNoteChooseItem != "C") {
          this.notification.showError('轉診目的(轉介其他試辦計畫或方案)為 01，特定註記必須為 C');
          return false;
        }
      }
      // 若本欄填入試辦計畫代碼02~04、06、07，h39欄位必須為D。
      else if (referralPurChooseItem == "02" ||
        referralPurChooseItem == "03" ||
        referralPurChooseItem == "04" ||
        referralPurChooseItem == "06" ||
        referralPurChooseItem == "07") {
        if (speNoteChooseItem != "D") {
          this.notification.showError('轉診目的(轉介其他試辦計畫或方案)為 02~04、06、07，特定註記必須為 D');
          return false;
        }
      }
      // 若本欄填入試辦計畫代碼08，h39欄位必須為E。
      else if (referralPurChooseItem == "08") {
        if (speNoteChooseItem != "E") {
          this.notification.showError('轉診目的(轉介其他試辦計畫或方案)為 08，特定註記必須為 E');
          return false;
        }
      }
      // 若本欄填入試辦計畫代碼09，h39欄位必須為F。
      else if (referralPurChooseItem == "09") {
        if (speNoteChooseItem != "F") {
          this.notification.showError('轉診目的(轉介其他試辦計畫或方案)為 09，特定註記必須為 F');
          return false;
        }
      }
      // 若本欄填入試辦計畫代碼10，h39欄位必須為G。
      else if (referralPurChooseItem == "10") {
        if (speNoteChooseItem != "G") {
          this.notification.showError('轉診目的(轉介其他試辦計畫或方案)為 10，特定註記必須為 G');
          return false;
        }
      }

      if (SpeCaseNoteChooseItem != "") {  // 特定個案轉出註記 (SpeCaseNote) 有填，轉出和轉入院所機構代碼不可相同
        if (this.referralFormGroup.controls.InHspid.value == this.cln.NHICode) {
          this.notification.showError('特定個案轉出註記有填，轉出和轉入院所機構代碼不可相同');
          return false;
        }
      }

      return true;
    }
  }

  formatDate(date: string): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('');
  }

  async generateReferralXML() {
    if (this.referralFormGroup.controls.uploadNHI.value) {
      const objectChoseItem = this.referralFormGroup.controls.Objects.value === undefined ? '' : this.referralFormGroup.controls.Objects.value.split(" ")[0];
      const speNoteChooseItem = this.referralFormGroup.controls.SpeNote.value === undefined ? '' : this.referralFormGroup.controls.SpeNote.value.split(" ")[0];
      const UrgPatNoteChooseItem = this.referralFormGroup.controls.UrgPatNote.value === undefined ? '' : this.referralFormGroup.controls.UrgPatNote.value.split(" ")[0];
      const UrgPatReaChooseItem = this.referralFormGroup.controls.UrgPatRea.value === undefined ? '' : this.referralFormGroup.controls.UrgPatRea.value.split(" ")[0];
      const SpeCaseNoteChooseItem = this.referralFormGroup.controls.SpeCaseNote.value === undefined ? '' : this.referralFormGroup.controls.SpeCaseNote.value.split(" ")[0];
      var h = new Array(46);
      h[1] = this.fixSpeCharacter(this.refHistRcd.Patient.CId);
      h[2] = this.fixSpeCharacter(this.refHistRcd.Patient.CName)
      if (this.referralFormGroup.controls.PatientSex.value == "1") {
        h[3] = "1";
      } else if (this.referralFormGroup.controls.PatientSex.value == "0") {
        h[3] = "2";
      }

      if (this.referralFormGroup.controls.Birthday.value != "") {
        h[4] = this.formatDate(new Date(this.referralFormGroup.controls.Birthday.value).toLocaleDateString());
      }

      if (this.referralFormGroup.controls.ContactName.value != "") {
        h[5] = this.fixSpeCharacter(this.referralFormGroup.controls.ContactName.value);
      }

      h[6] = this.fixSpeCharacter(this.referralFormGroup.controls.PhoneNo.value);

      if (this.referralFormGroup.controls.Address.value != "") {
        h[7] = this.fixSpeCharacter(this.referralFormGroup.controls.Address.value);
      }

      h[8] = this.fixSpeCharacter(this.referralFormGroup.controls.CC.value);
      h[9] = this.fixSpeCharacter(this.referralFormGroup.controls.Dx.value);

      if (this.referralFormGroup.controls.Dx1.value != "") {
        h[10] = this.referralFormGroup.controls.Dx1.value;
      }

      if (this.referralFormGroup.controls.Dx2.value != "") {
        h[11] = this.referralFormGroup.controls.Dx2.value;
      }

      if (this.referralFormGroup.controls.RecLISDate.value != "") {
        h[12] = this.formatDate(new Date(this.referralFormGroup.controls.RecLISDate.value).toLocaleDateString());
      }

      if (this.referralFormGroup.controls.RecLISResult.value != "") {
        h[13] = this.fixSpeCharacter(this.referralFormGroup.controls.RecLISResult.value);
      }

      // if (this.referralFormGroup.controls.RecLISDate.value != "") {
      //   h[14] = this.formatDate(new Date(this.referralFormGroup.controls.RecLISDate.value).toLocaleDateString());
      // }

      if (this.referralFormGroup.controls.HRx.value != "") {
        h[15] = this.fixSpeCharacter(this.referralFormGroup.controls.HRx.value);
      }

      if (this.referralFormGroup.controls.RecOrderDate.value != "") {
        h[16] = this.formatDate(new Date(this.referralFormGroup.controls.RecOrderDate.value).toLocaleDateString());
      }

      if (this.histRcd.Patient.DrugAllergy != "") {
        h[17] = this.fixSpeCharacter(this.histRcd.Patient.DrugAllergy);
      }

      h[18] = objectChoseItem;

      if (this.referralFormGroup.controls.ObjectsNote.value != "") {
        h[19] = this.fixSpeCharacter(this.referralFormGroup.controls.ObjectsNote.value);
      }

      h[20] = this.referralFormGroup.controls.DoctorCid.value;

      if (this.histRcd.Register.MedDeptCode != "") {
        h[21] = this.histRcd.Register.MedDeptCode;
      }

      h[22] = this.fixSpeCharacter(this.referralFormGroup.controls.DoctorPhone.value);
      h[23] = this.formatDate(new Date(this.referralFormGroup.controls.ReferralDate.value).toLocaleDateString());
      if (this.referralFormGroup.controls.SchMedDate.value) {
        h[24] = this.formatDate(new Date(this.referralFormGroup.controls.SchMedDate.value).toLocaleDateString());
      }

      h[25] = this.referralFormGroup.controls.InDep.value;
      h[26] = "";
      h[27] = "";
      h[28] = this.referralFormGroup.controls.InHspid.value;
      h[29] = this.referralFormGroup.controls.InDep.value;
      if (this.referralFormGroup.controls.InDrName.value != "") {
        h[30] = this.fixSpeCharacter(this.referralFormGroup.controls.InDrName.value);
      }

      if (this.referralFormGroup.controls.InHspidAddress.value != "") {
        h[31] = this.fixSpeCharacter(this.referralFormGroup.controls.InHspidAddress.value);
      }

      if (this.referralFormGroup.controls.InHsPhone.value != "") {
        h[32] = this.fixSpeCharacter(this.referralFormGroup.controls.InHsPhone.value);
      }

      h[33] = this.referralFormGroup.controls.DrTrans.value;       // 醫師交班注意事項

      h[34] = this.formatDate(new Date(this.referralFormGroup.controls.ExpiryDate.value).toLocaleDateString());
      h[35] = "";       // 新生兒註記(含胎次)
      h[36] = "";       // 院所自編序號
      h[37] = "";       // 得轉回原醫療院所診療之試辦計畫代碼
      h[38] = "";       // 轉診單序號
      if (speNoteChooseItem != "") {
        h[39] = speNoteChooseItem;
      }

      if (SpeCaseNoteChooseItem != "") {
        h[40] = SpeCaseNoteChooseItem;
      }

      if (UrgPatNoteChooseItem != "") {
        h[41] = UrgPatNoteChooseItem;
      }

      if (speNoteChooseItem == "C") {
        h[42] = "01";
      }

      if (UrgPatReaChooseItem != "") {
        h[43] = UrgPatReaChooseItem;
      }

      if (UrgPatReaChooseItem == "07") {
        h[44] = this.fixSpeCharacter(this.referralFormGroup.controls.UrgPatOth.value);
      }

      //h39特定註記=A則必填，且讀controls.UrgPatTime，產生的時分秒都要補0。
      let todayDate = new Date();
      // h[45] = this.formatDate(todayDate.toLocaleDateString()) + todayDate.getHours() + todayDate.getMinutes();
      if (speNoteChooseItem == "A") {
        h[45] = this.fixSpeCharacter(this.referralFormGroup.controls.UrgPatTime.value);
      }

      let xmlContent = CreateXml({
        etahosp: {
          hospid: this.fixSpeCharacter(this.cln.NHICode),
          hospaddr: this.fixSpeCharacter(this.clnOthInfo.City + this.clnOthInfo.Area + this.clnOthInfo.Street),
          hospfax: this.fixSpeCharacter(this.clnOthInfo.Fax),
          hospemail: this.fixSpeCharacter(this.clnOthInfo.Email),
          etadata: {
            h1: h[1], h2: h[2], h3: h[3], h4: h[4], h5: h[5], h6: h[6], h7: h[7], h8: h[8], h9: h[9], h10: h[10],
            h11: h[11], h12: h[12], h13: h[13], h14: h[14], h15: h[15], h16: h[16], h17: h[17], h18: h[18], h19: h[19], h20: h[20],
            h21: h[21], h22: h[22], h23: h[23], h24: h[24], h25: h[25], h26: h[26], h27: h[27], h28: h[28], h29: h[29], h30: h[30],
            h31: h[31], h32: h[32], h33: h[33], h34: h[34], h35: h[35], h36: h[36], h37: h[37], h38: h[38], h39: h[39], h40: h[40],
            h41: h[41], h42: h[42], h43: h[43], h44: h[44], h45: h[45]
          }
        }
      });

      // 依據勾選項，關閉畫面回看診畫面，上傳轉診單XML至健保署
      var accessDateTime = DateHelper.formatROCDate(todayDate)// this.formatDate(todayDate.toLocaleDateString());
      var oriSeq = this.cache.get(this.cacheKey_Seq + "_" + this.cln.NHICode);
      var seq = "001";
      if (oriSeq) {
        var tmp = oriSeq.split(';');
        if (tmp[0] == accessDateTime) {
          seq = (Number(tmp[1]) + 1).toString().padStart(3, '0');
        }
      }
      // 檔名
      var queryFileName = "ETA" + this.cln.NHICode + "-" + accessDateTime + seq + ".xml";
      var saveRet = await this.hcrService.SaveTextAs(`referral_tmp\\${accessDateTime}\\${queryFileName}`, xmlContent, false, 'Big5');
      var path: string = saveRet.Returns;
      var port: number = 0;
      var typeCode: string = "10";  // 開立電子轉診單
      // submit download condition
      var ret = await this.icHelper.getAPI().CNHIEiiDownload(port, typeCode, path);
      if (ret.StatusCode != 0) {
        this.notification.showError(ret.ErrorMsg);
        this.cache.set(this.cacheKey_Seq + "_" + this.cln.NHICode, (accessDateTime + ";" + seq));
        return false;
      } else {
        this.notification.showInfo("電子轉診單: " + queryFileName + "已送出");
        this.cache.set(this.cacheKey_Seq + "_" + this.cln.NHICode, (accessDateTime + ";" + seq));
        return true;
      }
    }
  }

  fixSpeCharacter(src: string) {
    var res = src;
    if (res != undefined && !Number.isNaN(res)) {
      // res = res.replace("<", "＜");
      // res = res.replace(">", "＞");
      // res = res.replace("&", "＆;");
      // res = res.replace("\'", "’");
      // res = res.replace("\"", "＂");
      //開立電子轉診單
      //XML格式設計有5種特殊字元，需作為資料格式判斷，如【表4】，故欄位資料內容中出現此特殊字元時，請以全形方式申報。
      res = xmlFixSpecialChar(res, 'FULL');
    } else {
      res = "";
    }
    return res;
  }


  generateReferralContent() {
    var schMedDate = this.referralFormGroup.controls.SchMedDate.value ? DateHelper.formatROCDateTime(new Date(this.referralFormGroup.controls.SchMedDate.value), true, false) : '';
    const speNoteChooseItem = this.referralFormGroup.controls.SpeNote.value === undefined ? '' : this.referralFormGroup.controls.SpeNote.value.split(" ")[0];
    var speNoteDesc = "";
    if (speNoteChooseItem == "A") {
      speNoteDesc = this.referralFormGroup.controls.SpeNote.value.split(' ').join('') +
        (!this.referralFormGroup.controls.UrgPatNote.value ? '' : " " + this.referralFormGroup.controls.UrgPatNote.value.split(' ').join('')) +
        (!this.referralFormGroup.controls.UrgPatRea.value ? '' : " " + this.referralFormGroup.controls.UrgPatRea.value.split(' ').join('')) +
        (!this.referralFormGroup.controls.UrgPatOth.value ? '' : " " + this.referralFormGroup.controls.UrgPatOth.value);
    } else if (speNoteChooseItem == "B") {
      speNoteDesc = this.referralFormGroup.controls.SpeNote.value.split(' ').join('') +
        (!this.referralFormGroup.controls.SpeCaseNote.value ? '' : " " + this.referralFormGroup.controls.SpeCaseNote.value.split(" ")[1]);
    } else {
      speNoteDesc = this.referralFormGroup.controls.SpeNote.value.split(' ').join('');
    }
    if (!speNoteDesc) speNoteDesc = "";

    let objectsDesc = this.referralFormGroup.controls.Objects.value.split(' ').join('') +
      (!this.referralFormGroup.controls.ObjectsNote.value ? '' : '(' + this.referralFormGroup.controls.ObjectsNote.value + ')') +
      (!this.referralFormGroup.controls.ReferralPur.value ? '' : " " + this.referralFormGroup.controls.ReferralPur.value.split(' ').join(''));

    var dxInfo = "";
    if (this.referralFormGroup.controls.Dx.value) {
      dxInfo = this.referralFormGroup.controls.Dx.value + " " + this.referralFormGroup.controls.DxName.value;
    }
    if (this.referralFormGroup.controls.Dx1.value) dxInfo += ", " + this.referralFormGroup.controls.Dx1.value + " " + this.referralFormGroup.controls.DxName1.value;
    if (this.referralFormGroup.controls.Dx2.value) dxInfo += ", " + this.referralFormGroup.controls.Dx2.value + " " + this.referralFormGroup.controls.DxName2.value;
    // if (this.referralFormGroup.controls.Dx3.value) dxInfo += ", " + this.referralFormGroup.controls.Dx3.value + " " + this.referralFormGroup.controls.DxName3.value;
    var hrx = (this.referralFormGroup.controls.HRx.value.replace("藥材/治療                數量  UN  頻率  日  總量  途徑\n", "")).split('\n');

    var hrxList = `<span style="font-size: 9pt; letter-spacing: -0.2pt">&nbsp; &nbsp;&nbsp;`;
    for (var i = 0; i < hrx.length; i++) {
      if (i > 0) {
        hrxList += "&nbsp;&nbsp;&nbsp;&nbsp;";
      }
      hrxList += hrx[i].replace("\t\t", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;").replaceAll("\t", "&nbsp;&nbsp;&nbsp;") + `<br>`;
    }
    hrxList += `</span>`;

    // generate referral
    let styles = `<style>
      @font-face
        {font-family:細明體;
        panose-1:2 2 5 0 0 0 0 0 0 0;}
      @font-face
	      {font-family:細明體;
	      panose-1:2 2 5 9 0 0 0 0 0 0;}
      @font-face
	      {font-family:"Cambria Math";
	      panose-1:2 4 5 3 5 4 6 3 2 4;}
      @font-face
	      {font-family:"\@細明體";
	      panose-1:2 1 6 9 0 1 1 1 1 1;}
      @font-face
	      {font-family:"\@細明體";
	      panose-1:2 1 6 1 0 1 1 1 1 1;}
      p.MsoNormal, li.MsoNormal, div.MsoNormal
	      {margin:0cm;
	      text-autospace:none;
	      font-size:11.0pt;
	      font-family:細明體;}
      p.MsoTitle, li.MsoTitle, div.MsoTitle
        {margin-top:.4pt;
        margin-bottom:0cm;
        text-align:center;
        text-autospace:none;
        font-size:18.0pt;
        font-family:細明體;}
      p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
        {margin-top:7.55pt;
        margin-right:0cm;
        margin-bottom:2.35pt;
        margin-left:6.0pt;
        text-autospace:none;
        font-size:9.5pt;
        font-family:細明體;}
      p.TableParagraph, li.TableParagraph, div.TableParagraph
        {mso-style-name:"Table Paragraph";
        margin:0cm;
        text-autospace:none;
        font-size:11.0pt;
        font-family:細明體;}
      .MsoChpDefault
        {font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
      .MsoPapDefault
        {text-autospace:none;}
       @page WordSection1
        {size:A4;
        margin:40.0pt 30.0pt 10.0pt 20.0pt;}
      div.WordSection1
        {page:WordSection1;}
    </style>`;

    let html = `
    <div class="WordSection1">
    <p class="MsoTitle"><span style="letter-spacing:-.05pt">全民健康保險` + this.cln.Name + `轉診單</span></p>
    <p class="MsoBodyText">
      <span style="letter-spacing:.2pt">保險醫事服務機構代號： </span>
      <span style="letter-spacing:.1pt">` + this.cln.NHICode + `</span>
    </p>
    <table class="TableNormal" border="1" cellspacing="0" cellpadding="0" style="margin-left:7.3pt;border-collapse:collapse;border:none">
      <tr style="height:20.6pt">
        <td width="31" rowspan="10" valign="middle" style="width:23.3pt;border-top:1.0pt; border-left:1.0pt;border-bottom:1.0pt;border-right:1.0pt;border-color:black;border-style:solid;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:12.0pt">&nbsp;</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size:12.0pt">&nbsp;</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size:12.0pt">&nbsp;</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size:12.0pt">&nbsp;</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size:12.0pt">&nbsp;</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size:12.0pt">&nbsp;</span>
          </p>
          <p class="TableParagraph" style="margin-top:9.25pt;margin-right:4.7pt;margin-bottom:0cm;margin-left:4.2pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;line-height:168%">
            <b><span style="font-size:12.0pt;line-height:168%;serif;letter-spacing:-.5pt">原診治醫院診所</span></b>
          </p>
        </td>
        <td width="29" rowspan="2" valign="middle" align="center" style="width:21.85pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">保基險本對資象料</span>
          </p>
        </td>
        <td width="48" colspan="3" valign="middle" align="center" style="width:36.0pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.5pt">姓名</span>
          </p>
        </td>
        <td width="93" colspan="2" valign="middle" align="left" style="width:69.7pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">&nbsp;` + this.refHistRcd.Patient.CName + `</span>
          </p>
        </td>
        <td width="62" colspan="4" valign="middle" align="center" style="width:46.75pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.5pt">身分證號</span>
          </p>
        </td>
        <td width="89" colspan="4" valign="middle" align="left" style="width:60.05pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">&nbsp;` + this.refHistRcd.Patient.CId + `</span>
          </p>
        </td>
        <td width="58" colspan="3" valign="middle" align="center" style="width:45.35pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">出生日期</span>
          </p>
        </td>
        <td width="76" colspan="4" valign="middle" align="left" style="width:56.8pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">&nbsp;` + DateHelper.getROCDateStringWithSepetator(new Date(this.refHistRcd.Patient.Birthday)) + `</span>
          </p>
        </td>
        <td width="34" valign="middle" align="center" style="width:27.5pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph" >
            <span style="font-size:9.5pt">性別</span>
          </p>
        </td>
        <td width="26" valign="middle" align="center" style="width:19.65pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">&nbsp;` + (this.referralFormGroup.controls.PatientSex.value == "1" ? "男" : (this.referralFormGroup.controls.PatientSex.value == "0" ? "女" : "")) + `</span>
          </p>
        </td>
        <td width="65" colspan="2" valign="middle" align="center" style="width:48.4pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt;letter-spacing:-.25pt">病歷號碼</span>
          </p>
        </td>
        <td width="74" valign="middle" align="left" style="width:55.3pt;border-top:solid black 1.0pt;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:20.6pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt;letter-spacing:-.1pt">&nbsp;` + this.referralFormGroup.controls.PatientNo.value + `</span>
          </p>
        </td>
      </tr>
      <tr style="height:21.0pt">
        <td width="48" colspan="3" valign="middle" align="center" style="width:36.0pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:21.0pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt; letter-spacing:-.25pt">聯絡人</span>
          </p>
        </td>
        <td width="93" colspan="2" valign="middle" align="left" style="width:69.7pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:21.0pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">&nbsp;` + (this.referralFormGroup.controls.ContactName.value ?? "　") + `</span>
          </p>
        </td>
        <td width="62" colspan="4" valign="middle" align="center" style="width:46.75pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:21.0pt">
          <p class="TableParagraph">
            <span style="font-size:8.5pt">聯絡電話</span>
          </p>
        </td>
        <td width="89" colspan="4" valign="middle" align="left" style="width:67.05pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:21.0pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">&nbsp;` + this.referralFormGroup.controls.PhoneNo.value + `</span>
          </p>
        </td>
        <td width="58" colspan="3" valign="middle" align="center" style="width:43.35pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:21.0pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">聯絡地址</span>
          </p>
        </td>
        <td width="274" colspan="9" valign="middle" align="left" style="width:205.65pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:21.0pt">
          <p class="TableParagraph">
            <span style="font-size:9.0pt">&nbsp;` + this.referralFormGroup.controls.Address.value + `</span>
          </p>
        </td>
      </tr>
      <tr style="height:279.5pt;">
        <td width="29" valign="middle" align="center" style="width:21.85pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:279.5pt">
          <p class="TableParagraph" style="margin-top:0cm;margin-right:6pt;margin-bottom:0cm;margin-left:6pt;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;line-height:90%">
            <span style="font-size:9.5pt;line-height:90%;letter-spacing:-.5pt">病歷摘要</span>
          </p>
        </td>
        <td width="230" colspan="25" valign="top" style="width:172.6pt;border:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;height:279.5pt">
          <p class="TableParagraph" style="margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;margin-left:4.8pt;margin-bottom:.0001pt">
            <span style="font-size:9.5pt;letter-spacing:.6pt">病情摘要: </span>
            <span style="font-size:9.0pt;letter-spacing:-.25pt">` + this.referralFormGroup.controls.CC.value + `</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
          <p class="TableParagraph" style="margin-top: 8pt; margin-right: 0cm; margin-bottom: 0cm; margin-left: 4.8pt; margin-bottom: 0.0001pt;">
            <span style="font-size: 9.5pt; letter-spacing: 1.5pt">診&nbsp;&nbsp;&nbsp;斷:</span>
            <span  style="font-size: 9pt">` + dxInfo + `</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
          <p class="TableParagraph" style="margin-top: 0cm; margin-right: 5pt; margin-bottom: 0cm; margin-left: 54.35pt; margin-bottom: 0.0001pt; text-indent: -49.6pt; line-height: 81%;">
            <span style="font-size: 9.5pt; line-height: 81%; letter-spacing: 0.65pt">治療摘要: </span>
            <span style="font-size: 9pt; line-height: 81%">1. 最近一次檢查結果日期: ` + (this.referralFormGroup.controls.RecLISDate.value ? DateHelper.getROCDateStringWithSepetator(new Date(this.referralFormGroup.controls.RecLISDate.value)) : '') + `</span>
          </p>
          <p class="TableParagraph" style="margin-left: 54.35pt; line-height: 11.05pt">
            <span style="font-size: 9pt; letter-spacing: -0.2pt">&nbsp; &nbsp;&nbsp;報告: ` + this.referralFormGroup.controls.RecLISResult.value + `</span>
          </p>
          <p class="TableParagraph" style="margin-left: 60.35pt; line-height: 11.05pt">
            <span style="font-size: 9pt; letter-spacing: -0.2pt">2. 最近一次用藥或手術名稱日期: ` + (this.referralFormGroup.controls.RecOrderDate.value ? DateHelper.getROCDateStringWithSepetator(new Date(this.referralFormGroup.controls.RecOrderDate.value)) : '') + `</span>
          </p>
          <p class="TableParagraph" style="margin-left: 54.35pt; line-height: 11.05pt">
            <span style="font-size: 9pt; letter-spacing: -0.2pt">&nbsp; &nbsp;&nbsp;藥材/治療&nbsp;&nbsp;&nbsp;&nbsp;數量&nbsp;&nbsp;UN&nbsp;&nbsp;&nbsp;頻率&nbsp;&nbsp;&nbsp;日&nbsp;&nbsp;總量&nbsp;&nbsp;途徑&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </p>
          <p class="TableParagraph" style="margin-left: 54.35pt; line-height: 11.5pt; ">` + hrxList + `</p>
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
          <p class="TableParagraph" style="margin-top: 6.9pt;margin-right: 0cm;margin-bottom: 0cm;margin-left: 4.8pt;margin-bottom: 0.0001pt;line-height: 11.45pt;">
            <span style="font-size: 9.5pt">過敏藥物: </span>
            <span style="font-size: 9pt; line-height: 81%">` + this.referralFormGroup.controls.AllergyHistory.value + `</span>
          </p>
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr style="height: 12pt">
        <td width="55" colspan="2" valign="middle" align="center" style="width: 41.3pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt;  padding: 0cm 0cm 0cm 0cm;height: 12pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">特定註記</span>
          </p>
        </td>
        <td width="599" colspan="24" valign="middle" align="left" style="width: 449.05pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 12pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + speNoteDesc + `</span>
          </p>
        </td>
      </tr>
      <tr style="height: 11.3pt">
        <td width="55" colspan="2" valign="middle" align="center" style="width: 41.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 11.3pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">轉診目的</span>
          </p>
        </td>
        <td width="599" colspan="24" valign="middle" align="left" style="width: 449.05pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 11.3pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + objectsDesc + `</span>
          </p>
        </td>
      </tr>
      <tr style="height: 32.9pt">
        <td width="55" colspan="2" valign="middle" align="center" style="width: 41.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 32.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 2.5pt">醫師交班注意事項</span>
          </p>
        </td>
        <td width="599" colspan="24" valign="middle" align="left" style="width: 449.05pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 32.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + this.referralFormGroup.controls.DrTrans.value + `</span>
          </p>
        </td>
      </tr>
      <tr style="height: 21pt">
        <td width="55" colspan="2" valign="middle" align="center" style="width: 41.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 21pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">院所住址</span>
          </p>
        </td>
        <td width="460" colspan="21" valign="middle" align="left" style="width: 345.35pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 21pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + this.clnOthInfo.City + this.clnOthInfo.Area + this.clnOthInfo.Street + `</span>
          </p>
        </td>
        <td width="29" valign="middle" align="center" style="width: 21.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 21pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing:1.5pt">傳真號碼</span>
          </p>
        </td>
        <td width="109"colspan="2" valign="middle" align="left" style="width: 81.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 21pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + this.clnOthInfo.Fax + `</span>
          </p>
        </td>
      </tr>
      <tr style="height: 19.45pt">
        <td width="55" colspan="2" valign="middle" align="center" style="width: 41.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 19.45pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt;">診治醫師</span>
          </p>
        </td>
        <td width="131" colspan="5" valign="middle" align="left" style="width: 98.6pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 19.45pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + this.referralFormGroup.controls.DoctorName.value + ` 醫師</span>
          </p>
        </td>
        <td width="21" valign="middle" align="center" style="width: 16.05pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 19.45pt;">
          <p class="TableParagraph" style=" margin-top: 0cm; margin-right: 1.85pt; margin-bottom: 0cm; margin-left: 5.3pt; margin-bottom: 0.0001pt; line-height: 9.7pt;">
            <span style="font-size: 8pt; letter-spacing: -0.5pt">科別</span>
          </p>
        </td>
        <td width="137" colspan="8" valign="middle" align="left" style="width: 102.6pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 19.45pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + this.referralFormGroup.controls.ExDepName.value + `</span>
          </p>
        </td>
        <td width="35" valign="middle" align="center" style="width: 26.15pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 19.45pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 1.5pt">聯絡電話</span>
          </p>
        </td>
        <td width="136" colspan="6" valign="middle" align="left" style="width: 101.95pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 19.45pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + this.referralFormGroup.controls.DoctorPhone.value + `</span>
          </p>
        </td>
        <td width="29" valign="middle" align="center" style="width: 21.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 19.45pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 1.5pt">醫師簽章</span>
          </p>
        </td>
        <td width="109" colspan="2" valign="middle" align="center" style="width: 81.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 19.45pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr style="height: 18.75pt">
        <td width="55" colspan="2" valign="middle" align="center" style="width: 41.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">轉診日期</span>
          </p>
        </td>
        <td width="71" colspan="4" valign="middle" align="left" style="width: 53.55pt;border: none;border-bottom: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">&nbsp; ` + DateHelper.getROCDateStringWithSepetator(new Date(this.referralFormGroup.controls.ReferralDate.value)) + `</span>
          </p>
        </td>
        <td width="16" valign="middle" style="width: 12.35pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
        <td width="48" colspan="3" valign="middle" align="center" style="width: 34.4pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 7.5pt">安排就醫日期</span>
          </p>
        </td>
        <td width="27" colspan="13" valign="middle" align="left" style="width: 20.15pt;border: none;border-bottom: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` +
      (schMedDate != null && schMedDate != "" ? schMedDate : "&nbsp;&nbsp;年&nbsp;&nbsp;月&nbsp;&nbsp;日") + "&nbsp;&nbsp;&nbsp;" +
      this.referralFormGroup.controls.InDepName.value + "&nbsp;&nbsp;" +
      // "&nbsp;科&nbsp;&nbsp;" +
      this.referralFormGroup.controls.SchMedDiag.value + "&nbsp;診&nbsp;&nbsp;" +
      this.referralFormGroup.controls.SchMedNum.value + "&nbsp;號&nbsp;" + `</span>
          </p>
        </td>
        <td width="29" valign="middle" align="center" style="width: 21.85pt;border-top: none;border-left: solid black 1.0pt;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 1.5pt">有效期限</span>
          </p>
        </td>
        <td width="109" colspan="2" valign="middle" align="left" style="width: 81.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp; ` + DateHelper.getROCDateStringWithSepetator(new Date(this.referralFormGroup.controls.ExpiryDate.value)) + `</span>
          </p>
        </td>
      </tr>
      <tr style="height: 36.75pt">
        <td width="55" colspan="2" valign="middel" align="center" style="width: 41.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 36.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 3.5pt">建議轉診院所</span>
          </p>
        </td>
        <td width="131" colspan="5" valign="middel" align="left" style="width: 98.6pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 36.75pt;">
          <p class="TableParagraph" >
            <span style="font-size: 9pt; letter-spacing: -0.1pt">` + this.referralFormGroup.controls.InHspidName.value + `</span>
          </p>
        </td>
        <td width="21" valign="middel" align="center" style="width: 16.05pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm; height: 36.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: -0.5pt">醫師</span>
          </p>
        </td>
        <td width="70" colspan="4" valign="middel" style="width: 52.45pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 36.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;` + this.referralFormGroup.controls.InDrName.value + `</span>
          </p>
        </td>
        <td width="21" valign="middel" align="center" style="width: 15.7pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 36.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: -0.5pt">科別</span>
          </p>
        </td>
        <td width="81" colspan="4" valign="middel" style="width: 60.6pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 36.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;` + this.referralFormGroup.controls.InDepName.value + `</span>
          </p>
        </td>
        <td width="54" colspan="3" valign="middle" align="center" style="width: 40.4pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 36.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 5.5pt">轉診院所地址電話</span>
          </p>
        </td>
        <td width="220" colspan="6" valign="middel" align="left" style="width: 165.25pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 36.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;` + this.referralFormGroup.controls.InHspidAddress.value + " " + this.referralFormGroup.controls.InHspidPhone.value + `</span>
          </p>
        </td>
      </tr>
      <tr style="height: 41.3pt">
        <td width="31" rowspan="4" valign="middle" align="center" style="width: 23.3pt;border-top: none;border-left: solid black 1.0pt;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 41.3pt;">
          <p class="TableParagraph">
            <b><span style="font-size: 12pt;">接受轉診醫院診所</span></b>
          </p>
        </td>
        <td width="29" valign="middle" align="center" style="width: 21.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 41.3pt;">
          <p class="TableParagraph" style="margin-right: 6pt;margin-left: 6pt;text-align: justify;text-justify: inter-ideograph;">
            <span style="font-size: 8pt; letter-spacing:-0.5pt">處理情形</span>
          </p>
        </td>
        <td width="625" colspan="25" valign="middle" style="width: 468.5pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 41.3pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr style="height: 42.75pt">
        <td width="29" valign="middle" align="center" style="width: 21.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 42.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 1.55pt">診療摘要注意事項</span>
          </p>
        </td>
        <td
          width="625"
          colspan="25"
          valign="middle" style="width: 468.5pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 42.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr style="height: 18.75pt">
        <td width="71" colspan="3" valign="middle" align="center" style="width: 53.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">院所名稱</span>
          </p>
        </td>
        <td width="274" colspan="13" valign="middle" style="width: 205.25pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
        <td width="70" colspan="2" valign="middle" align="center" style="width: 52.35pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">聯絡電話</span>
          </p>
        </td>
        <td width="239" colspan="8" valign="middle" style="width: 179.45pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 18.75pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr style="height: 23.9pt">
        <td width="71" colspan="3" valign="middle" align="center" style="width: 53.3pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm; height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">負責醫師</span>
          </p>
        </td>
        <td width="115" colspan="4" valign="middle" style="width: 86.6pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
        <td width="30" colspan="2" valign="middle" align="center" style="width: 22.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: -0.5pt">科別</span>
          </p>
        </td>
        <td width="128" colspan="7" valign="middle" style="width: 95.8pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
        <td width="70" colspan="2" valign="middle" align="center" style="width: 52.35pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt">醫師簽章</span>
          </p>
        </td>
        <td width="101" colspan="5" valign="middle" style="width: 75.75pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
        <td width="29" valign="middle" align="center" style="width: 21.85pt;border-top: none;border-left: none;border-bottom: solid black 1.0pt;border-right: solid black 1.0pt;padding: 0cm 0cm 0cm 0cm;height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 8pt; letter-spacing: 1.5pt">回覆日期</span>
          </p>
        </td>
        <td width="109" colspan="2" valign="middle"style="width: 81.85pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 23.9pt;">
          <p class="TableParagraph">
            <span style="font-size: 9pt">&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr height="0">
        <td colspan="27" style="border: none"></td>
      </tr>
    </table>
    <p class="MsoNormal"><span >&nbsp;</span></p>
    </div>`;

    return "<HTML><head>" + styles + "</head><BODY onload='window.print();window.close();'>" + html + "</BODY></HTML>";
  }
  //#endregion

  //#region helper pad
  onBtnHelperPadClick(resetPos: boolean) {
    // 未申報過或全解鎖才可以開啟輔助盤
    if (this.hasUploaded) {
      return;
    }
    if (resetPos) {
      this.localStorage.removeItem(HelperPadComponent.windSizeStorageKey);
      this.closeHelperPadWin();
      setTimeout(() => {
        this.openHelperPadWin();
      }, 10);
      return false;
    }
    if (this.isHelperPadOpened) {
      this.closeHelperPadWin();
    } else {
      this.openHelperPadWin();
    }
  }
  openHelperPadWin() {
    // 未申報過或全解鎖才可以開啟輔助盤
    if (this.hasUploaded) {
      return;
    }
    this.isHelperPadOpened = true;
  }
  closeHelperPadWin() {
    this.isHelperPadOpened = false;
  }
  //#endregion

  //#region helper pad

  //#endregion

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.histLoadSubsc.unsubscribe();
    this.histFetchSubsc.unsubscribe();
    this.histTmplInsert.unsubscribe();
    this.unregistFnKey();
    this.signalR.CompleteAccess();
  }

  //#region Private
  private exit() {
    this.localStorage.removeItem('HistRcd_' + this.histRcd.Register.Id);
    if (this.isCompEnter || this.entranceType == 'Declare') {
      this.ExitHisRecordEvent.emit();
      this.closeLoader();
    } else {
      // 預設go back路徑
      if (this.fromHist) {
        this.histService.routeToHistList();
      } else {
        this.histService.routeToOpdList();

      }
      // 先route再close，不然畫面還沒切過去，loader就關了
      this.closeLoader();
    }
  }
  private async next() {
    // var nextId = await this.histApi.GetNextRegId(this.histRcd.Register.Id);
    // 直接從nextRegisters取第一個
    var nextId = this.nextRegisters[0]?.Id;
    if (nextId) {
      this.localStorage.removeItem('RegStatus_' + this.histRcd.Register.Id);
      // 選入下一位
      if (this.nextRegisters[0].ICode == '001' && this.nextRegisters[0].BeginTime != null) {
        await this.userConfirmService.showAlert('請確認以下訊息', '本病患是以【重大傷病】身分掛入\n請注意: 病名務必以此病患之【重大傷病卡記載】的病名相符才可申報，以免被核扣！');
      }
      this.goNextPat = true;
      this.initRegId = nextId;

      this.histService.changePatientHist(nextId);
    } else {
      // 預設go back路徑
      this.histService.routeToOpdList();
    }
    // 先route再close，不然畫面還沒切過去，loader就關了
    this.closeLoader();
  }

  private async saveAndExit(exit: boolean = true): Promise<boolean> {
    this.showLoader();
    this.isCopy = false;
    await this.pullAllData()
    //已申報，未解鎖，只能修改主訴
    if (this.hasUploaded) {
      //this.unlockReadonly();
      const submitData = this.histRcd;
      try {
        var s = await this.histApi.saveCC(submitData.Hist.Id, submitData.Hist.CC, submitData);
        this.closeLoader();
        if (exit) {
          this.exit();
        } else {
          return true;
        }
      } catch (error) {
        this.closeLoader();
        if (error.message) {
          this.notification.showError(error.message); // 顯示存檔失敗
        } else {
          this.notification.showFailed(); // 顯示存檔失敗
        }
        return false;
      }
    } else {
      // 儲存離開不檢核
      // if (await this.ValidateOrders()) {
      if (await this.save(HistActionEnum.Save)) {
        //離開應該不要印東西吧
        //this.print();
        if (exit) {
          this.exit();
        } else {
          this.closeLoader();
          return true;
        }
      } else {
        this.closeLoader();
        return false;
      }
      // }
      // if (this.checkOrderFreqQTY()) {
      //   if (await this.save(HistActionEnum.Save)) {
      //     //離開應該不要印東西吧
      //     //this.print();
      //     if (exit) {
      //       this.exit();
      //     }
      //   } else {
      //     this.closeLoader();
      //   }
      // }
    }
    this.histService.isHist = false;
  }
  //#endregion


  //#region FunctionKeyEvent
  private registFnKey() {
    this.fnKeyRegistId = this.hkService.regist(Priority.Hist, 'Fn', this.subscribeFnKey.bind(this));
  }
  private unregistFnKey() {
    this.hkService.unRegist(this.fnKeyRegistId);
  }
  private async subscribeFnKey(k: FnKey) {
    if (this.fnKeyEnable == false) {
      return;
    }
    switch (k) {
      // 呼叫舊病歷的複製按鈕
      case 'F1':
        if (!this.hasUploaded) {
          this.histViewComp.onCopyClick();
        }
        break;
      // 完診
      case 'F2':
        if (!this.hasUploaded) {
          await this.onBtnFinishClick();
        }
        break;
      // 儲存範本
      case 'F3':
        if (!this.hasUploaded && this.entranceType != 'Declare') {
          this.openTmplWin();
        }
        break;
      // 清除
      case 'F4':
        if (!this.hasUploaded && this.entranceType != 'Declare') {
          this.openClearWindow();
        }
        break;
      // 特別記載
      case 'F5':
        if (!this.hasUploaded) {
          this.focusToRemark();
        }
        break;
      // 開啟範本
      case 'F6':
        if (!this.hasUploaded && this.entranceType != 'Declare') {
          this.openLoadTmplWin();
        }
        break;
      // 開啟復診掛號資訊
      case 'F7':
        if (!this.hasUploaded && this.entranceType != 'Declare') {
          // this.openRegDialog();
          this.onBtnRegEditClick();
        }
        break;
      // 切換Focus
      case 'F8':
        this.focusToNextArea();
        break;
      // 離開操作
      case 'F9':
        await this.openExitDialog();
        break;

      // 轉診
      // case 'F10': this.openReferralWin(); break;
    }
  }
  //#endregion FunctionKeyEvent

  private focusToRemark() {
    this.remarkComp.nativeElement.focus();
    this.remarkComp.nativeElement.parentElement.classList.add('area-focus');
    var ids = HelperId.getAreaIds();
    ids.forEach(id => {
      var el = document.getElementById(id);
      if (el?.classList.contains('area-focus')) {
        this.f5RestoreId = id;
        el.classList.remove('area-focus');
      };
    });
  }

  private blurFromRemark() {
    this.remarkComp.nativeElement.parentElement.classList.remove('area-focus');
  }

  private focusToNextArea() {
    var nextFocus = '';
    var remarkArea = this.remarkComp.nativeElement.parentElement;
    if (remarkArea.classList.contains('area-focus')) {
      remarkArea.classList.remove('area-focus');
      nextFocus = this.f5RestoreId;;
    }

    if (document.getElementById(HelperId.VSArea)?.classList.contains('area-focus')) {
      document.getElementById(HelperId.VSArea)?.classList.remove('area-focus');
      nextFocus = HelperId.StoredPrevFocusAreaId;
    }
    var ids = [HelperId.CCArea, HelperId.PEArea, HelperId.DiagArea,/*HelperId.VSArea,*/HelperId.OrderArea];
    if (nextFocus == '') {
      for (var i = 0; i < ids.length; i++) {
        var el = document.getElementById(ids[i]);
        if (el.classList.contains('area-focus')) {
          nextFocus = ids[(i == ids.length - 1) ? 0 : (i + 1)];
          break;
        }
      }
    }
    var enums = [HelperSourceEnum.CC, HelperSourceEnum.PE, HelperSourceEnum.Diag, HelperSourceEnum.Order];
    this.histHelperPadService.Pad_TabSelected(new AreaSelectedEvent(enums[ids.indexOf(nextFocus)], [2, 3, 4]));
    var tabs = [HelpPadTab.CC, HelpPadTab.PE, HelpPadTab.DX, HelpPadTab.RX];
    this.histHelperPadService.Command_FocusTo(tabs[ids.indexOf(nextFocus)], [2, 3, 4]);
  }

  private focusToFirstArea() {
    var focusId = this.histService.FirstFocus;
    this.histHelperPadService.Pad_TabSelected(new AreaSelectedEvent(focusId, [2, 3, 4]));
    this.histHelperPadService.Command_FocusTo(HelperId.getHelpTab(focusId), [2, 3, 4]);
  }

  private async openRegDialog() {
    var act = await this.userConfirmService.showConfirm({
      title: '離開',
      hideNo: true,
      hideYes: true,
      width: 1170,
      msg: '',
      template: this.regComp,
    });
  }

  private async openExitDialog() {
    if (this.entranceType == "Declare") { //從申報進入
      var actComp = await this.userConfirmService.showConfirm({
        title: '離開',
        msg: '請選擇動作',
        textYes: '不存離開',
        textNo: '取消',
        width: 350,
        customBtnMid: [
          { text: '儲存並離開', return: 'scc' }
        ]
      });
      if (actComp == true) {
        this.exit();
      } else if (actComp == 'scc') {
        this.onBtnSaveClick();
      }
    } else {
      var act: any;
      if (this.hasUploaded) {
        act = await this.userConfirmService.showConfirm({
          title: '離開',
          msg: '請選擇動作',
          textYes: '儲存並離開',
          textNo: '取消',
          width: 450,
          customBtnMid: [
            { text: '不存離開', return: 'e' }
          ]
        });
      } else {
        act = await this.userConfirmService.showConfirm({
          title: '離開',
          msg: '請選擇動作',
          textYes: '儲存並離開',
          textNo: '取消',
          width: 550,
          customBtnMid: [
            { text: '不存離開', return: 'e' },
            { text: '病歷全刪', return: 'd' },
            { text: '改自費', return: 's' }
          ]
        });
      }

      if (act == true) {  // 儲存並離開
        this.onBtnSaveClick();
      } else if (act == 'e') {
        // var st = this.localStorage.getItem('RegStatus_'+this.histRcd.Register.Id);
        await this.histApi.updateRegStatusByAction(this.histRcd.Register.Id, HistActionEnum.Exit, '');
        // 套完移除
        this.localStorage.removeItem('RegStatus_' + this.histRcd.Register.Id);
        //await this.histApi.updateRegStatusByAction(this.histRcd.Register.Id, HistActionEnum.Exit, this.histRcd.Register.StatusCode);
        this.exit();
      } else if (act == 'd') {
        var act2 = await this.userConfirmService.showConfirm({
          title: '病歷全刪',
          msg: '此動作將會刪除此病歷(包含醫令、批價並取消此次掛號)，確定要刪除嗎?\n【請記得執行退掛作業】',
          textYes: '確定',
          textNo: '取消',
          width: 350
        });
        if (act2 == true) {
          // await this.histApi.updateRegStatusByAction(this.histRcd.Register.Id, HistActionEnum.Finish, '');
          this.localStorage.removeItem('RegStatus_' + this.histRcd.Register.Id);
          var result = await this.histApi.DeleteHist(this.histRcd.Register.Id);
          if (result.IsSuccess) {
            this.notification.showOk();
            this.exit();
          } else {
            this.notification.showError(result.Message);
          }
        } else {
          // do nothing，staying this page
        }
      } else if (act == 's') {
        // 詢問是要保險身分改自費?還是只是不申報?
        var canCancel = this.checkCanCancel(this.histRcd.Register.RegDate);
        var act3 = await this.userConfirmService.showConfirm({
          title: '改自費',
          msg: '請選擇執行的操作',
          textYes: '設定此筆資料不申報',
          textNo: '取消',
          width: 500,
          customBtnMid: [
            { text: '保險身分改為自費', return: 'ctn', disabled: !canCancel },
          ]
        });
        if (act3 == true) { // 設定此筆資料不申報
          var ret = await this.regApi.ChangeToSelfPay(this.histRcd.Register.Id, false, null, null, false);
          if (ret == '') {
            this.notification.showOk();
            this.exit();
          } else {
            this.notification.showError(ret.toString());
          }
        } else if (act3 == 'ctn') { // 保險身分改為自費 change to NHI (需有卡片做退掛，否則不能退卡序+刪除3i)
          if (this.icHelper.getICConn()) {
            this.isShowRegDialog = true;
            this.changeToSelfPay = true;
            this.detectChanges();
          } else {
            this.notification.showWarning('未置入健保IC卡，無法變更保險身分為自費！');
          }
        } else {
          // do nothing，staying this page
        }
      }
    }
  }

  checkCanCancel(regDate: Date) {
    var _searchDate: Date = regDate;
    //const _nowTsec = TimeSectionRange.getSectionValue(this.queryOptions.timeSectionRange);
    return this.opHelper.RegisterCanCancel(_searchDate);
  }

  // 開啟雲端藥歷，網頁會開啟websocket讀取醫師卡和病患的健保卡取得資料
  // 這邊需要卡機程式串好，前端畫面只能做到這樣
  async openCloudHist(type: string, hasCTRDrugs: boolean) {
    if (this.useNhiimiVersion == 1) {
      if (this.useNhiimi) {
        window.open("https://medcloud.nhi.gov.tw/imme0008/imme0008s01.aspx", "CloudHistTab");
      }

      //特定醫療資訊查詢, for 身心科, 後續用參數控制是否開啟。
      // if (UserCache.getLoginUser().Clinic.TypeIsClinic && UserCache.getLoginUser().Clinic.MedDeptCode == '13') {
      //判斷自動且有管制用藥才開啟，手動則不判斷。
      if (this.useNhiimiSpe && ((type == 'A' && hasCTRDrugs) || type == 'M')) {
        window.open("https://medcloud.nhi.gov.tw/iese0000/IESE0200S00.aspx", "CloudSpecialTab");
      }
    } else if (this.useNhiimiVersion == 2) {
      //卡別：4 SAM卡 + 醫事人員卡 + 健保卡
      //服務代號：91「健保醫療資訊雲端查詢系統主動提示功能 Web API 」
      if (!this.isDemoMode) {
        this.signInToken = await this.icHelper.getAPI().GetVPNGetRandomXAndSignX('4', '91');
        this.samId = await this.icHelper.getAPI().GetCardNoSAM();
        this.hcId = await this.icHelper.getAPI().GetCardNoHC();
        this.hpcId = await this.icHelper.getAPI().GetCardNoHPC();
      } else {
        let demoToken = new CSHISResultWithData<VPNGetRandomXandSignX>();
        demoToken.StatusCode = 0;
        let demoData = new VPNGetRandomXandSignX();
        demoData.RandomX = '09AD8428D6B57FE05000';
        demoData.SignX = 'BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA612BD6A61021BB6768FD362FDEA61';
        demoToken.Data = demoData;
        this.signInToken = demoToken;
        let demoSamId = new CSHISResultWithData<string>();
        demoSamId.Data = '001000000103';
        this.samId = demoSamId;
        let demoHcId = new CSHISResultWithData<string>();
        demoHcId.Data = '000073983649';
        this.hcId = demoHcId;
        let demoHpcId = new CSHISResultWithData<string>();
        demoHpcId.Data = '000000243387';
        this.hpcId = demoHpcId;
      }
    } else {

    }

    document.body.style.cursor = 'wait';
    //var ret = await this.hcrService.OpenUrl("https://medcloud.nhi.gov.tw/imme0008/imme0008s01.aspx");
    //this.notification.showInfo(ret.Returns);
    this.openCloudHistLink = true;
    document.body.style.cursor = 'default';
  }

  onBtnCrossInqiryClick() {
    this.pullOrders();
    this.isDemoMode ? this.toCrossInquiryForDemo('M') : this.toCrossInquiry('M');
  }

  //#region 跨院雲端查詢
  crossResult = "";
  alleryResult = "";
  interactResult = "";
  specialResult = "";
  cloudQueryError = '雲端查詢系統回應錯誤: ';
  async toCrossInquiry(type: string) {
    var crossInq = false;   //跨院醫囑
    var allergyInq = false; //跨院過敏藥
    var interact = "0";     //跨院交互
    if (type == "A") {
      crossInq = this.nhiimiSetting.CrossInquiryA;
      allergyInq = this.nhiimiSetting.AllergyInquiryA;
      interact = this.nhiimiSetting.InteractA;
    } else {
      crossInq = this.nhiimiSetting.CrossInquiryM;
      allergyInq = this.nhiimiSetting.AllergyInquiryM;
      interact = this.nhiimiSetting.InteractM;
    }
    // if (!this.useNhiimi || (!crossInq && !allergyInq && interact == '0')) { // 雖然開啟雲端跨院查詢，但是設定值都不檢查的話，就直接return
    if ((!this.useNhiimi || (!crossInq && !allergyInq && interact == '0')) && !this.useNhiimiSpe) { // 雖然開啟雲端跨院查詢，但是設定值都不檢查的話，就直接return
      return true;    // 要回一個值 不然完診不會過
    }

    let hasCTRDrugs = this.histRcd.Hist.HRxs.some(h => h.Rx.CTRDrugs);

    // 卡機主控台會開啟網頁自動驗卡(10.253.253.247)，健保署Server會Keep住驗卡的資訊，
    // 後續查詢雲端服務的API就會去健保署Server驗證是否已登入? 否則會傳回 RtnCode="02"
    // var validApiUrl = "https://medcloud.nhi.gov.tw:443/imme0008/IMME0008S01.aspx";
    //console.log('開啟認證視窗(卡機主控台)');
    await this.openCloudHist(type, hasCTRDrugs);

    if (this.useNhiimiVersion == 2) {
      if (!this.signInToken || this.signInToken.StatusCode != 0) {
        this.notification.showError("認證失敗，無法執行雲端查詢主動提示功能。");
        return true;
      }
      if (!this.hpcId || this.hpcId.StatusCode != 0) {
        this.notification.showError(this.hpcId.ErrorMsg + "\n無法執行雲端查詢主動提示功能。");
        return true;
      }
      if (!this.hcId || this.hcId.StatusCode != 0) {
        this.notification.showError(this.hcId.ErrorMsg + "\n無法執行雲端查詢主動提示功能。");
        return true;
      }
      if (!this.samId || this.samId.StatusCode != 0) {
        this.notification.showError(this.samId.ErrorMsg + "\n無法執行雲端查詢主動提示功能。");
        return true;
      }
      if (!this.cln.NHICode) {
        this.notification.showError("醫事機構代碼不正確" + "\n無法執行雲端查詢主動提示功能。");
        return true;
      }
      if (!UserCache.getLoginUser().CId) {
        this.notification.showError("醫事人員身分證號不正確" + "\n無法執行雲端查詢主動提示功能。");
        return true;
      }
      if (!this.histRcd.Patient.CId) {
        this.notification.showError("病人身分證號不正確" + "\n無法執行雲端查詢主動提示功能。");
        return true;
      }
    }

    // 先檢查處方醫令區有無輸入藥品 RxType = 2, 3, 4 才是藥品和注射
    let drugs = this.histRcd.Hist.HRxs.filter(x => x.RxType == 2 || x.RxType == 3 || x.RxType == 4);
    if (drugs.length > 0) {
      if (crossInq) {
        if (this.useNhiimiVersion == 1) {
          var testData: nhiReq = new nhiReq();
          testData.sHospId = this.cln.NHICode;
          testData.sHcaId = UserCache.getLoginUser().CId;
          testData.sPatId = this.histRcd.Patient.CId;   // 使用真實病患身分證號
          testData.sub = [];
          // 居護暫時不會有跨院查詢，之後再確認資料對應邏輯如何取得?
          drugs.forEach(itm => {
            var s: nhiReqSubTwo = new nhiReqSubTwo();
            s.sOrder = itm.StdCode;
            var sub: nhiReqSubOne = new nhiReqSubOne();
            sub.sType = itm.TotalDose > 10 ? "1" : "4";
            sub.sub = [];
            sub.sub.push(s);
            testData.sub.push(sub);
          });

          try {
            this.crossResult = "";
            // console.log('testData >>>>>', testData);
            var d1 = await this.hcrService.GetMedPrtData(testData);
            if (d1 != null) {
              if (d1.sub != null) {
                this.crossResult = JSON.stringify(d1.sub);
              }
            }
            if (this.crossResult == 'null') this.crossResult = "";
          } catch (ex) {
            this.notification.showError(ex);
          }
        } else if (this.useNhiimiVersion == 2) {
          var testData2: nhiReqV2 = new nhiReqV2();
          testData2.sHospId = this.cln.NHICode;
          testData2.sHcaId = UserCache.getLoginUser().CId;
          testData2.sPatId = this.histRcd.Patient.CId;
          testData2.sPatCardType = '2';
          testData2.sHcaCardId = this.hpcId.Data;
          testData2.sPatCardId = this.hcId.Data;
          testData2.sClientRandom = this.signInToken.Data.RandomX;
          testData2.sSignature = this.signInToken.Data.SignX;
          testData2.vhcCloudToken = '';
          testData2.sSamId = this.samId.Data;
          testData2.sub = [];
          let drugs = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 2 || x.RxType == 3 || x.RxType == 4 || x.RxType == 6) && x.RxCode);
          if (drugs.length > 0) {
            var rxCodes = drugs.map(x => { return { RxCode: x.RxCode, Sort: x.Sort }; });
            var nhiimiList = await this.clinicApi.GetNHIIMIList(rxCodes);
            this.makeQuerySub(testData2, nhiimiList, 1);//1之資料其B欄表示用藥資料之ATC7碼，對應資料類別01。
            this.makeQuerySub(testData2, nhiimiList, 3);//3之資料其B欄表示檢查檢驗之醫令代碼，對應資料類別03、04。
            this.makeQuerySub(testData2, nhiimiList, 5);//5之資料其B欄表示非類固醇抗發炎劑藥品之ATC7碼，對應資料類別05。
            this.makeQuerySub(testData2, nhiimiList, 6);//6之資料其B欄表示高風險用藥資料之ATC7碼，對應資料類別06。
            this.makeQuerySub(testData2, nhiimiList, 7);//7之資料其B欄表示非類固醇抗發炎口服藥(NSAIDs)用藥(含比對腎功能)之ATC7碼，對應資料類別07。
            this.makeQuerySub(testData2, nhiimiList, 11);//資料類別11
            if (testData2.sub.length > 0) {
              try {
                this.crossResult = "";
                var d1 = await this.hcrService.GetMedPrtDataV2(testData2);
                if (d1 != null) {
                  let returnCode = d1.RtnCode ?? d1.rtnCode;
                  if (returnCode != '00') {
                    const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
                    this.crossResult = msgBody;
                    this.notification.showError("跨院查詢: \n" + msgHead);
                  } else if (d1.sub != null) {
                    this.crossResult = JSON.stringify(d1.sub);
                  }
                }
                if (this.crossResult == 'null') this.crossResult = "";
              } catch (ex) {
                this.notification.showError("跨院查詢發生錯誤: " + ex);
              }
            }
          }
        }
      }

      // 過敏藥
      if (allergyInq) {
        if (this.useNhiimiVersion == 1) {
          var testData: nhiReq = new nhiReq();
          testData.sHospId = this.cln.NHICode;
          testData.sHcaId = UserCache.getLoginUser().CId;
          testData.sPatId = this.histRcd.Patient.CId;   // 使用真實病患身分證號須先經由
          testData.sub = [];
          drugs.forEach(itm => {
            var s: nhiReqSubTwo = new nhiReqSubTwo();
            s.sOrder = itm.StdCode;
            var sub: nhiReqSubOne = new nhiReqSubOne();
            sub.sType = "B";    // 過敏藥物資料
            sub.sub = [];
            sub.sub.push(s);
            testData.sub.push(sub);
          });

          try {
            this.alleryResult = "";
            var d2 = await this.hcrService.GetAllergyData(testData);
            // console.log('雲端藥歷過敏藥查詢d2.sub >>>>>', d2?.sub);
            if (d2 != null) {
              if (d2.sub != null) {
                this.alleryResult = JSON.stringify(d2.sub);
              }
            }
            if (this.alleryResult == 'null') this.alleryResult = "";
          } catch (ex) {
            this.notification.showError(ex);
          }
        } else if (this.useNhiimiVersion == 2) {
          var testData2: nhiReqV2 = new nhiReqV2();
          testData2.sHospId = this.cln.NHICode;
          testData2.sHcaId = UserCache.getLoginUser().CId;
          testData2.sPatId = this.histRcd.Patient.CId;
          testData2.sPatCardType = '2';
          testData2.sHcaCardId = this.hpcId.Data;
          testData2.sPatCardId = this.hcId.Data;
          testData2.sClientRandom = this.signInToken.Data.RandomX;
          testData2.sSignature = this.signInToken.Data.SignX;
          testData2.vhcCloudToken = '';
          testData2.sSamId = this.samId.Data;
          testData2.sub = [];
          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '02';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'X';
          sub1.sub.push(s1);
          testData2.sub.push(sub1);

          try {
            this.alleryResult = "";
            var d2V2 = await this.hcrService.GetAllergyDataV2(testData2);
            if (d2V2 != null) {
              let returnCode = d2V2.RtnCode ?? d2V2.rtnCode;
              if (returnCode != '00') {
                const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
                this.alleryResult = msgBody;
                this.notification.showError("過敏藥物查詢: \n" + msgHead);
              } else if (d2V2.sub != null) {
                this.alleryResult = JSON.stringify(d2V2.sub);
              }
            }
            if (this.alleryResult == 'null') this.alleryResult = "";
          } catch (ex) {
            this.notification.showError("過敏藥物查詢發生錯誤: " + ex);
          }
        }
      }

      // 交互查詢
      if (interact != "0") {
        if (this.useNhiimiVersion == 1) {
          var testData: nhiReq = new nhiReq()
          testData.sHospId = this.cln.NHICode;
          testData.sHcaId = UserCache.getLoginUser().CId;
          testData.sPatId = this.histRcd.Patient.CId;   // 使用真實病患身分證號須先經由
          testData.sub = [];
          drugs.forEach(itm => {
            var s: nhiReqSubTwo = new nhiReqSubTwo();
            s.sOrder = itm.StdCode;
            var sub: nhiReqSubOne = new nhiReqSubOne();
            sub.sType = "A";   // 藥品交互作用資料
            sub.sub = [];
            sub.sub.push(s);
            testData.sub.push(sub);
          });

          try {
            this.interactResult = "";
            var d3 = await this.hcrService.GetDDIData(testData);
            // console.log('雲端藥歷交互查詢d3.sub >>>>>', d3?.sub);
            if (d3 != null) {
              if (d3.sub != null) {
                this.interactResult = JSON.stringify(d3.sub);
              }
            }
            if (this.interactResult == 'null') this.interactResult = "";
          } catch (ex) {
            this.notification.showError(ex);
          }
        } else if (this.useNhiimiVersion == 2) {
          let sType = interact == 'D' ? 9 : 8;
          var testData2: nhiReqV2 = new nhiReqV2();
          testData2.sHospId = this.cln.NHICode;
          testData2.sHcaId = UserCache.getLoginUser().CId;
          testData2.sPatId = this.histRcd.Patient.CId;
          testData2.sPatCardType = '2';
          testData2.sHcaCardId = this.hpcId.Data;
          testData2.sPatCardId = this.hcId.Data;
          testData2.sClientRandom = this.signInToken.Data.RandomX;
          testData2.sSignature = this.signInToken.Data.SignX;
          testData2.vhcCloudToken = '';
          testData2.sSamId = this.samId.Data;
          testData2.sub = [];
          let drugs = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 2 || x.RxType == 3 || x.RxType == 4) && x.StdCode);
          if (drugs.length > 0) {
            var nhiimiList = drugs.map(x => {
              return {
                Id: 0,
                Stype: sType,
                ATC: '',
                StdCode: x.StdCode,
                RxCode: x.RxCode,
                Sort: x.Sort
              } as NhiimiListDto;
            });
            this.makeQuerySub(testData2, nhiimiList, sType);
            if (testData2.sub.length > 0) {
              try {
                this.interactResult = "";
                var d3 = await this.hcrService.GetDDIDataV2(testData2);
                if (d3 != null) {
                  let returnCode = d3.RtnCode ?? d3.rtnCode;
                  if (returnCode != '00') {
                    const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
                    this.interactResult = msgBody;
                    this.notification.showError("交互查詢: \n" + msgHead);
                  } else if (d3.sub != null) {
                    this.interactResult = JSON.stringify(d3.sub);
                  }
                }
                if (this.interactResult == 'null') this.interactResult = "";
              } catch (ex) {
                this.notification.showError("交互查詢發生錯誤: " + ex);
              }
            }
          }
        }
      }

      if (this.useNhiimiSpe) {
        if (this.useNhiimiVersion == 2) {
          var testData2: nhiReqV2 = new nhiReqV2();
          testData2.sHospId = this.cln.NHICode;
          testData2.sHcaId = UserCache.getLoginUser().CId;
          testData2.sPatId = this.histRcd.Patient.CId;
          testData2.sPatCardType = '2';
          testData2.sHcaCardId = this.hpcId.Data;
          testData2.sPatCardId = this.hcId.Data;
          testData2.sClientRandom = this.signInToken.Data.RandomX;
          testData2.sSignature = this.signInToken.Data.SignX;
          testData2.vhcCloudToken = '';
          testData2.sSamId = this.samId.Data;
          testData2.sub = [];
          let drugs = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 2 || x.RxType == 3 || x.RxType == 4) && x.RxCode);
          if (drugs.length > 0) {
            var rxCodes = drugs.map(x => { return { RxCode: x.RxCode, Sort: x.Sort }; });
            var nhiimiList = await this.clinicApi.GetNHIIMIList(rxCodes);
            this.makeQuerySub(testData2, nhiimiList, 10);//10之資料其B欄表示保險對象特定醫療資訊查詢之ATC7碼，對應資料類別10。
            if (testData2.sub.length > 0) {
              try {
                this.specialResult = "";
                var d4 = await this.hcrService.GetSpecialDataV2(testData2);
                let returnCode = d4.RtnCode ?? d4.rtnCode;
                if (returnCode != '00') {
                  const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
                  this.specialResult = msgBody;
                  this.notification.showError("保險對象特定醫療資訊查詢: \n" + msgHead);
                } else if (d4 != null) {
                  if (d4.sub != null) {
                    this.specialResult = JSON.stringify(d4.sub);
                  }
                }
                if (this.specialResult == 'null') this.specialResult = "";
              } catch (ex) {
                this.notification.showError("保險對象特定醫療資訊查詢發生錯誤: " + ex);
              }
            }
          }
        }
      }

      var totelResult = this.assemblyNhiimiContent();

      var prompt: any;
      if (type == 'A') {
        prompt = await this.userConfirmService.showConfirm({
          title: '跨院用藥醫囑檢查提示',
          msg: totelResult == '' ? '查無相關提示' : totelResult,
          textYes: '確定',
          textNo: '返回',
          focus: true,
          width: totelResult == '' ? 600 : 1800
        });
      } else {
        prompt = await this.userConfirmService.showAlert('跨院用藥醫囑檢查提示', (totelResult == '' ? '查無相關提示' : totelResult), {
          width: totelResult == '' ? 600 : 1800
        });
      }
      return prompt;
    } else {
      return await this.userConfirmService.showAlert('跨院用藥醫囑檢查提示', '無藥品處方需要查詢', { width: 600 });
    }
  }

  async toCrossInquiryForDemo(type: string) {
    var crossInq = false;   //跨院醫囑
    var allergyInq = false; //跨院過敏藥
    var interact = "0";     //跨院交互
    if (type == "A") {
      crossInq = this.nhiimiSetting.CrossInquiryA;
      allergyInq = this.nhiimiSetting.AllergyInquiryA;
      interact = this.nhiimiSetting.InteractA;
    } else {
      crossInq = this.nhiimiSetting.CrossInquiryM;
      allergyInq = this.nhiimiSetting.AllergyInquiryM;
      interact = this.nhiimiSetting.InteractM;
    }
    // if (!this.useNhiimi && (!crossInq && !allergyInq && interact == '0')) { // 雖然開啟雲端跨院查詢，但是設定值都不檢查的話，就直接return
    if (!this.useNhiimi && !this.useNhiimiSpe && (!crossInq && !allergyInq && interact == '0')) { // 雖然開啟雲端跨院查詢，但是設定值都不檢查的話，就直接return
      return;
    }

    let hasCTRDrugs = true;

    /* 以下程式碼為測試健保署雲端查詢使用 */
    // 卡機主控台會開啟網頁自動驗卡(10.253.253.247)，健保署Server會Keep住驗卡的資訊，
    // 後續查詢雲端服務的API就會去健保署Server驗證是否已登入? 否則會傳回 RtnCode="02"
    // var validApiUrl = "https://medcloud.nhi.gov.tw:443/imme0008/IMME0008S01.aspx";
    await this.openCloudHist(type, hasCTRDrugs);

    if (crossInq) {
      if (this.useNhiimiVersion == 1) {
        var testData: nhiReq = new nhiReq()
        testData.sHospId = "7407020149";
        testData.sHcaId = "G221489822";
        testData.sPatId = "Z299999992";
        testData.sub = [];
        var s1: nhiReqSubTwo = new nhiReqSubTwo();
        s1.sOrder = "AC43493345";
        var sub1: nhiReqSubOne = new nhiReqSubOne();
        sub1.sType = "5";
        sub1.sub = [];
        sub1.sub.push(s1);
        testData.sub.push(sub1);
        try {
          this.crossResult = "";
          var d1 = await this.hcrService.GetMedPrtData(testData);
          if (d1 != null) {
            if (d1.sub != null) {
              this.crossResult = JSON.stringify(d1.sub);
            }
          }
          if (this.crossResult == 'null') this.crossResult = "";
        } catch (ex) {
          this.notification.showError("跨院查詢: " + ex);
        }
      } else if (this.useNhiimiVersion == 2 && this.signInToken) {
        var testData2: nhiReqV2 = new nhiReqV2();
        testData2.sHospId = '3501200000';
        testData2.sHcaId = 'BA00243387';
        testData2.sPatId = 'Z299999992';
        testData2.sPatCardType = '2';
        testData2.sHcaCardId = this.hpcId.Data;
        testData2.sPatCardId = this.hcId.Data;
        testData2.sClientRandom = this.signInToken.Data.RandomX;
        testData2.sSignature = this.signInToken.Data.SignX;
        testData2.vhcCloudToken = '';
        testData2.sSamId = this.samId.Data;
        testData2.sub = [];
        var drugs = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 2 || x.RxType == 3 || x.RxType == 4 || x.RxType == 6) && x.RxCode);
        if (drugs.length > 0) {
          var rxCodes = drugs.map(x => {
            return {
              RxCode: x.RxCode,
              Sort: x.Sort
            };
          });
          var nhiimiList = await this.clinicApi.GetNHIIMIList(rxCodes);
          this.makeQuerySub(testData2, nhiimiList, 1);//1之資料其B欄表示用藥資料之ATC7碼，對應資料類別01。
          this.makeQuerySub(testData2, nhiimiList, 3);//3之資料其B欄表示檢查檢驗之醫令代碼，對應資料類別03、04。
          this.makeQuerySub(testData2, nhiimiList, 5);//5之資料其B欄表示非類固醇抗發炎劑藥品之ATC7碼，對應資料類別05。
          this.makeQuerySub(testData2, nhiimiList, 6);//6之資料其B欄表示高風險用藥資料之ATC7碼，對應資料類別06。
          this.makeQuerySub(testData2, nhiimiList, 7);//7之資料其B欄表示非類固醇抗發炎口服藥(NSAIDs)用藥(含比對腎功能)之ATC7碼，對應資料類別07。
          this.makeQuerySub(testData2, nhiimiList, 11);//資料類別11
        } else {
          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '01';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'NC104681G0';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = 'BC030771G0';
          sub1.sub.push(s2);
          var s3: nhiReqSubTwo = new nhiReqSubTwo();
          s3.sOrder = 'VC00007100';
          sub1.sub.push(s3);
          testData2.sub.push(sub1);

          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '03';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = '09001C';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = '32001C';
          sub1.sub.push(s2);
          testData2.sub.push(sub1);

          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '04';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = '09001C';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = '32001C';
          sub1.sub.push(s2);
          testData2.sub.push(sub1);

          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '05';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'AC35651151';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = 'A040011100';
          sub1.sub.push(s2);
          testData2.sub.push(sub1);

          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '06';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'AC58639100';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = 'BC23711100';
          sub1.sub.push(s2);
          testData2.sub.push(sub1);

          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '07';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'AB45725100';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = 'BC23177100';
          sub1.sub.push(s2);
          testData2.sub.push(sub1);

          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '11';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'X';
          sub1.sub.push(s1);
          testData2.sub.push(sub1);
        }

        try {
          this.crossResult = "";
          var d1 = await this.hcrService.GetMedPrtDataV2(testData2);
          if (d1 != null) {
            let returnCode = d1.RtnCode ?? d1.rtnCode;
            if (returnCode != '00') {
              const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
              this.crossResult = msgBody;
              this.notification.showError("跨院查詢: \n" + msgHead);
            } else if (d1.sub != null) {
              this.crossResult = JSON.stringify(d1.sub);
            }
          }
          if (this.crossResult == 'null') this.crossResult = "";
        } catch (ex) {
          this.notification.showError("跨院查詢: " + ex);
        }
      }
    }

    if (allergyInq) {
      if (this.useNhiimiVersion == 1) {
        var testData: nhiReq = new nhiReq()
        testData.sHospId = "7407020149";
        testData.sHcaId = "G221489822";
        testData.sPatId = "Z299999992";
        testData.sub = [];
        var s1: nhiReqSubTwo = new nhiReqSubTwo();
        var s2: nhiReqSubTwo = new nhiReqSubTwo();
        var s3: nhiReqSubTwo = new nhiReqSubTwo();
        // var s4: nhiReqSubTwo = new nhiReqSubTwo();
        // var s5: nhiReqSubTwo = new nhiReqSubTwo();
        s1.sOrder = "AC28341500";
        s2.sOrder = "AC43991100";
        s3.sOrder = "AA49629100";
        // s4.sOrder = "AB446851G0";
        // s5.sOrder = "A020707100";
        var sub1: nhiReqSubOne = new nhiReqSubOne();
        sub1.sType = "B";
        sub1.sub = [];
        sub1.sub.push(s1);
        sub1.sub.push(s2);
        sub1.sub.push(s3);
        // sub1.sub.push(s4);
        // sub1.sub.push(s5);
        testData.sub.push(sub1);

        try {
          this.alleryResult = "";
          var d2 = await this.hcrService.GetAllergyData(testData);
          if (d2 != null) {
            if (d2.sub != null) {
              this.alleryResult = JSON.stringify(d2.sub);
            }
          }
          if (this.alleryResult == 'null') this.alleryResult = "";
        } catch (ex) {
          this.notification.showError("過敏藥物查詢: " + ex);
        }
      } else if (this.useNhiimiVersion == 2 && this.signInToken) {
        var testData2: nhiReqV2 = new nhiReqV2();
        testData2.sHospId = '3501200000';
        testData2.sHcaId = 'BA00243387';
        testData2.sPatId = 'Z299999992';
        testData2.sPatCardType = '2';
        testData2.sHcaCardId = this.hpcId.Data;
        testData2.sPatCardId = this.hcId.Data;
        testData2.sClientRandom = this.signInToken.Data.RandomX;
        testData2.sSignature = this.signInToken.Data.SignX;
        testData2.vhcCloudToken = '';
        testData2.sSamId = this.samId.Data;
        testData2.sub = [];
        var sub1: nhiReqSubOne = new nhiReqSubOne();
        sub1.sType = '02';
        sub1.sub = [];
        var s1: nhiReqSubTwo = new nhiReqSubTwo();
        s1.sOrder = 'X';
        sub1.sub.push(s1);
        testData2.sub.push(sub1);

        try {
          this.alleryResult = "";
          var d2V2 = await this.hcrService.GetAllergyDataV2(testData2);
          if (d2V2 != null) {
            let returnCode = d2V2.RtnCode ?? d2V2.rtnCode;
            if (returnCode != '00') {
              const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
              this.alleryResult = msgBody;
              this.notification.showError("過敏藥物查詢: \n" + msgHead);
            } else if (d2V2.sub != null) {
              this.alleryResult = JSON.stringify(d2V2.sub);
            }
          }
          if (this.alleryResult == 'null') this.alleryResult = "";
        } catch (ex) {
          this.notification.showError("過敏藥物查詢: " + ex);
        }
      }
    }

    if (interact != "0") {
      if (this.useNhiimiVersion == 1) {
        var testData: nhiReq = new nhiReq()
        testData.sHospId = "7407020149";
        testData.sHcaId = "G221489822";
        testData.sPatId = "Z299999992";
        testData.sub = [];
        var s1: nhiReqSubTwo = new nhiReqSubTwo();
        var s2: nhiReqSubTwo = new nhiReqSubTwo();
        // var s3: nhiReqSubTwo = new nhiReqSubTwo();
        s1.sOrder = "AC58147100";
        s2.sOrder = "BB26412100";
        // s3.sOrder = "XCOVID0001";
        var sub1: nhiReqSubOne = new nhiReqSubOne();
        sub1.sType = "A";
        sub1.sub = [];
        sub1.sub.push(s1);
        sub1.sub.push(s2);
        // sub1.sub.push(s3);
        testData.sub.push(sub1);
        try {
          this.interactResult = "";
          var d3 = await this.hcrService.GetDDIData(testData);
          if (d3 != null) {
            if (d3.sub != null) {
              this.interactResult = JSON.stringify(d3.sub);
            }
          }
          if (this.interactResult == 'null') this.interactResult = "";
        } catch (ex) {
          this.notification.showError("交互查詢: " + ex);
        }
      } else if (this.useNhiimiVersion == 2 && this.signInToken) {
        let sType = interact == 'D' ? 9 : 8;
        var testData2: nhiReqV2 = new nhiReqV2();
        testData2.sHospId = '3501200000';
        testData2.sHcaId = 'BA00243387';
        testData2.sPatId = 'Z299999992';
        testData2.sPatCardType = '2';
        testData2.sHcaCardId = this.hpcId.Data;
        testData2.sPatCardId = this.hcId.Data;
        testData2.sClientRandom = this.signInToken.Data.RandomX;
        testData2.sSignature = this.signInToken.Data.SignX;
        testData2.vhcCloudToken = '';
        testData2.sSamId = this.samId.Data;
        testData2.sub = [];
        var drugs = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 2 || x.RxType == 3 || x.RxType == 4) && x.StdCode);
        if (drugs.length > 0) {
          var nhiimiList = drugs.map(x => {
            return {
              Id: 0,
              Stype: sType,
              ATC: '',
              StdCode: x.StdCode,
              RxCode: x.RxCode,
              Sort: x.Sort
            } as NhiimiListDto;
          });
          this.makeQuerySub(testData2, nhiimiList, sType);
        } else {
          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '08';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'BC26000245';
          sub1.sub.push(s1);
          testData2.sub.push(sub1);

          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '09';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'A054738';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = 'A032289';
          sub1.sub.push(s2);
          testData2.sub.push(sub1);
        }

        try {
          this.interactResult = "";
          var d3 = await this.hcrService.GetDDIDataV2(testData2);
          if (d3 != null) {
            let returnCode = d3.RtnCode ?? d3.rtnCode;
            if (returnCode != '00') {
              const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
              this.interactResult = msgBody;
              this.notification.showError("交互查詢: \n" + msgHead);
            } else if (d3.sub != null) {
              this.interactResult = JSON.stringify(d3.sub);
            }
          }
          if (this.interactResult == 'null') this.interactResult = "";
        } catch (ex) {
          this.notification.showError("交互查詢: " + ex);
        }
      }
    }

    if (this.useNhiimiSpe) {
      if (this.useNhiimiVersion == 2 && this.signInToken) {
        var testData2: nhiReqV2 = new nhiReqV2();
        testData2.sHospId = '3501200000';
        testData2.sHcaId = 'BA00243387';
        testData2.sPatId = 'Z299999992';
        testData2.sPatCardType = '2';
        testData2.sHcaCardId = this.hpcId.Data;
        testData2.sPatCardId = this.hcId.Data;
        testData2.sClientRandom = this.signInToken.Data.RandomX;
        testData2.sSignature = this.signInToken.Data.SignX;
        testData2.vhcCloudToken = '';
        testData2.sSamId = this.samId.Data;
        testData2.sub = [];
        var drugs = this.histRcd.Hist.HRxs.filter(x => (x.RxType == 2 || x.RxType == 3 || x.RxType == 4) && x.RxCode);
        if (drugs.length > 0) {
          var rxCodes = drugs.map(x => {
            return {
              RxCode: x.RxCode,
              Sort: x.Sort
            };
          });
          var nhiimiList = await this.clinicApi.GetNHIIMIList(rxCodes);
          this.makeQuerySub(testData2, nhiimiList, 10);//10之資料其B欄表示保險對象特定醫療資訊查詢之ATC7碼，對應資料類別10。
        } else {
          var sub1: nhiReqSubOne = new nhiReqSubOne();
          sub1.sType = '10';
          sub1.sub = [];
          var s1: nhiReqSubTwo = new nhiReqSubTwo();
          s1.sOrder = 'B021408100';
          sub1.sub.push(s1);
          var s2: nhiReqSubTwo = new nhiReqSubTwo();
          s2.sOrder = 'AC45892100';
          sub1.sub.push(s2);
          testData2.sub.push(sub1);
        }

        try {
          this.specialResult = "";
          var d4 = await this.hcrService.GetSpecialDataV2(testData2);
          let returnCode = d4.RtnCode ?? d4.rtnCode;
          if (returnCode != '00') {
            const [msgHead, msgBody] = this.cloudQueryReturn(returnCode, testData2);
            this.specialResult = msgBody;
            this.notification.showError("保險對象特定醫療資訊查詢: \n" + msgHead);
          } else if (d4 != null) {
            if (d4.sub != null) {
              this.specialResult = JSON.stringify(d4.sub);
            }
          }
          if (this.specialResult == 'null') this.specialResult = "";
        } catch (ex) {
          this.notification.showError("保險對象特定醫療資訊查詢: " + ex);
        }
      }
    }

    var totelResult = this.assemblyNhiimiContent();
    var prompt: any;
    if (type == 'A') {
      prompt = await this.userConfirmService.showConfirm({
        title: '跨院用藥醫囑檢查提示',
        msg: totelResult == '' ? '查無相關提示' : totelResult,
        textYes: '確定',
        textNo: '返回',
        focus: true,
        width: totelResult == '' ? 600 : 1800
      });
    } else {
      prompt = await this.userConfirmService.showAlert('跨院用藥醫囑檢查提示', (totelResult == '' ? '查無相關提示' : totelResult), {
        width: totelResult == '' ? 600 : 1800
      });
    }
    return prompt;
  }

  assemblyNhiimiContent(): string {
    var crossHint = '●跨院重複開立醫囑提示:';
    var allergyHint = '●過敏藥物提示:';
    var interactHint = '●藥品交互作用提示:';
    var specialHint = '●保險對象特定醫療資訊查詢:';
    var lineBetween = '-'.repeat(100);
    var contentString = '';
    if (this.crossResult.startsWith(this.cloudQueryError)) {
      contentString += crossHint + "\n";
      contentString += lineBetween + "\n";
      contentString += this.crossResult + "\n";
    } else if (this.crossResult != '') {
      // var crossObj: medPrtSubOne[] = JSON.parse(this.crossResult) as medPrtSubOne[];
      let crossObj: medPrtSubOne[] = [];
      let resultObj: medPrtSubOne[] = JSON.parse(this.crossResult) as medPrtSubOne[];
      //由於雲端api新舊版本傳回的json格式，欄位名稱一樣，但大小寫不同，所以要轉換成統一的格式，這樣下方各欄位判斷式才能正確取到值。
      //如:
      //1.0版本回傳的欄位名稱為: OwnQty1
      //2.0版本回傳的欄位名稱為: ownQty1
      resultObj.forEach(item => crossObj.push(new medPrtSubOne(item)));

      if (crossObj.some(x => x.sub.length > 0)) {
        contentString += crossHint + "\n";
        contentString += lineBetween + "\n";
      }
      if (crossObj.length > 0) {
        crossObj.forEach(item => {
          if (item.sub != null && item.sub.length > 0) {
            // var i = 0;
            item.sub.forEach(c => {
              if (c.oOrder != null) {
                contentString += "醫令代碼/ATC5 代碼: " + c.oOrder + "\n";
              }
              if (c.HRiskOrder != null) {
                contentString += "醫令代碼/ATC5 代碼: " + c.HRiskOrder + "\n";
              }
              if (c.oMsg != null) {
                contentString += c.oMsg + "\n";
              }
              if (c.ATC5EName != null) {
                contentString += "ATC5 名稱: " + c.ATC5EName + "\n";
              }
              if (c.HRiskATCEName != null) {
                contentString += "ATC5 名稱: " + c.HRiskATCEName + "\n";
              }
              if (c.DrugGroupCName != null) {
                contentString += "成分名稱: " + c.DrugGroupCName + "\n";
              }
              if (c.PresMedDay != null) {
                contentString += "遵醫囑應餘用藥日數: " + c.PresMedDay + "\n";
              }
              if (c.EDate != null) {
                contentString += "同成分用藥結束日期: " + c.EDate + "\n";
              }
              if (c.OwnQty1 != null) {
                contentString += "貼布累計量: " + c.OwnQty1 + "\n";
              }
              if (c.OwnQty2 != null) {
                contentString += "軟膏累計量: " + c.OwnQty2 + "\n";
              }
              if (c.ReasonableInterval != null) {
                contentString += "合理區間: " + c.ReasonableInterval + "\n";
              }
              if (c.Interval != null) {
                contentString += "距前次檢查(驗)日數: " + c.Interval + "\n";
              }
              contentString += lineBetween + "\n";
              if (c.sub != null && c.sub.length > 0) {
                c.sub.forEach(cs => {
                  if (cs.NSAIDsType != null) {
                    contentString += "　非類固醇抗發炎劑藥品類別: " + cs.NSAIDsType + "\n";
                  }
                  if (cs.HospName != null) {
                    contentString += "　開立藥品醫事機構簡稱: " + cs.HospName + "\n";
                  }
                  if (cs.FuncDT != null) {
                    contentString += "　開藥日期: " + cs.FuncDT + "\n";
                  }
                  if (cs.Day != null) {
                    contentString += "　給藥日數: " + cs.Day + "\n";
                  }
                  if (cs.DrugGroupCName != null) {
                    contentString += "　藥品分類分組中文名稱: " + cs.DrugGroupCName + "\n";
                  }
                  if (cs.OrderQty != null) {
                    contentString += "　醫令總量: " + cs.OrderQty + "\n";
                  }
                  if (cs.StdQty != null) {
                    contentString += "　藥品規格量: " + cs.StdQty + "\n";
                  }
                  if (cs.Std != null) {
                    contentString += "　藥品規格單位: " + cs.Std + "\n";
                  }
                  if (cs.OrderCName != null) {
                    contentString += "　醫令項目中文名稱: " + cs.OrderCName + "\n";
                  }
                  if (cs.RealInspectDate != null) {
                    contentString += "　採驗/實際檢查日期: " + cs.RealInspectDate + "\n";
                  }
                  if (cs.CurePath != null) {
                    contentString += "　診療部位: " + cs.CurePath + "\n";
                  }
                  contentString += lineBetween + "\n";
                });
              }
            });
          }
        });
      }
    }

    if (this.alleryResult.startsWith(this.cloudQueryError)) {
      contentString += allergyHint + "\n";
      contentString += lineBetween + "\n";
      contentString += this.alleryResult + "\n";
    } else if (this.alleryResult != '') {
      if (this.useNhiimiVersion == 1) {
        let alleryObj: ddiSubOne[] = JSON.parse(this.alleryResult) as ddiSubOne[];
        if (contentString.length > 0) contentString += "\n";
        contentString += allergyHint + "\n";
        alleryObj[0].sub.forEach(a => {
          contentString += "ATC7 碼/醫令代碼: " + a.oOrder + ": \n" + a.oMsg + "\n";
        });
      } else if (this.useNhiimiVersion == 2) {
        if (this.alleryResult != '') {
          let allergyObj: allergySubOne[] = [];
          let resultObj: allergySubOne[] = JSON.parse(this.alleryResult) as allergySubOne[];
          resultObj.forEach(item => allergyObj.push(new allergySubOne(item)));

          if (allergyObj.some(x => x.sub.length > 0)) {
            if (contentString.length > 0) contentString += "\n";
            contentString += allergyHint + "\n";
            contentString += lineBetween + "\n";
          }
          if (allergyObj.length > 0) {
            allergyObj.forEach(item => {
              if (item.sub != null && item.sub.length > 0) {
                item.sub.forEach(c => {
                  if (c.allergyOrder_text != null) {
                    contentString += "過敏紀錄: " + c.allergyOrder_text + "\n";
                  }
                  if (c.upload_Flag != null) {
                    contentString += "上傳過敏藥物註記: " + c.upload_Flag + "\n";
                  }
                  if (c.hospName != null) {
                    contentString += "上傳醫事機構簡稱: " + c.hospName + "\n";
                  }
                  if (c.upload_date != null) {
                    contentString += "上傳日期: " + c.upload_date + "\n";
                  }
                  contentString += lineBetween + "\n";
                });
              }
            });
          }
        }
      }
    }

    if (this.interactResult.startsWith(this.cloudQueryError)) {
      contentString += interactHint + "\n";
      contentString += lineBetween + "\n";
      contentString += this.interactResult + "\n";
    } else if (this.interactResult != '') {
      // let interactObj: ddiSubOne[] = JSON.parse(this.interactResult) as ddiSubOne[];
      let interactObj: ddiSubOne[] = [];
      let resultObj: ddiSubOne[] = JSON.parse(this.interactResult) as ddiSubOne[];
      resultObj.forEach(item => interactObj.push(new ddiSubOne(item)));

      if (interactObj.some(x => x.sub.length > 0)) {
        if (contentString.length > 0) contentString += "\n";
        contentString += interactHint + "\n";
        contentString += lineBetween + "\n";
      }
      if (interactObj.length > 0) {
        interactObj.forEach(item => {
          if (item.sub != null && item.sub.length > 0) {
            item.sub.forEach(i => {
              contentString += "ATC7 碼/醫令代碼: " + i.oOrder + "\n";
              if (i.oMsg != null) {
                contentString += i.oMsg + "\n";
              }
              if (i.CDrugName != null) {
                contentString += "藥材名稱: " + i.CDrugName + "\n";
              }
              if (i.ATC7Name != null) {
                contentString += "ATC7 名稱: " + i.ATC7Name + "\n";
              }
              if (i.Effect != null) {
                contentString += "交互作用結果: " + i.Effect + "\n";
              }
              if (i.Mechanism != null) {
                contentString += "機轉: " + i.Mechanism + "\n";
              }
              if (i.Management != null) {
                contentString += "處置方式: " + i.Management + "\n";
              }
              contentString += lineBetween + "\n";
              if (i.sub != null && i.sub != undefined && i.sub.length > 0) {
                i.sub.forEach(is => {
                  if (is.HospName != null) {
                    contentString += "　開立處方醫事機構簡稱: " + is.HospName + "\n";
                  }
                  if (is.FuncDT != null) {
                    contentString += "　開立處方日期: " + is.FuncDT + "\n";
                  }
                  if (is.DDIATC7Name != null) {
                    contentString += "　交互作用 ATC7 名稱: " + is.DDIATC7Name + "\n";
                  }
                  if (is.DDICDrugName != null) {
                    contentString += "　交互作用藥材名稱: " + is.DDICDrugName + "\n";
                  }
                  if (is.DDIOrder != null) {
                    contentString += "　交互作用醫令: " + is.DDIOrder + "\n";
                  }
                  contentString += lineBetween + "\n";
                  if (is.HospSub != null && is.HospSub != undefined && is.HospSub.length > 0) {
                    is.HospSub.forEach(iss => {
                      if (iss.HospName != null) {
                        contentString += "　　開立處方醫事機構簡稱: " + iss.HospName + "\n";
                      }
                      if (iss.FuncDT != null) {
                        contentString += "　　開立處方日期: " + iss.FuncDT + "\n";
                      }
                      contentString += lineBetween + "\n";
                    });
                  }
                  if (is.DDISub != null && is.DDISub != undefined && is.DDISub.length > 0) {
                    is.DDISub.forEach(iss => {
                      if (iss.IngName != null) {
                        contentString += "　　ATC 成分名稱: " + iss.IngName + "\n";
                      }
                      if (iss.DDIIngName != null) {
                        contentString += "　　交互作用 ATC 成分名稱: " + iss.DDIIngName + "\n";
                      }
                      if (iss.Effect != null) {
                        contentString += "　　交互作用結果: " + iss.Effect + "\n";
                      }
                      if (iss.Mechanism != null) {
                        contentString += "　　機轉: " + iss.Mechanism + "\n";
                      }
                      if (iss.Management != null) {
                        contentString += "　　處置方式: " + iss.Management + "\n";
                      }
                      if (iss.Alternatives != null) {
                        contentString += "　　可替代品項(建議): " + iss.Alternatives + "\n";
                      }
                      contentString += lineBetween + "\n";
                    });
                  }
                });
              }
            });
          }
        });
      }
    }

    if (this.specialResult.startsWith(this.cloudQueryError)) {
      contentString += specialHint + "\n";
      contentString += lineBetween + "\n";
      contentString += this.specialResult + "\n";
    } else if (this.specialResult != '') {
      let specialObj: specialSubOne[] = [];
      let resultObj: specialSubOne[] = JSON.parse(this.specialResult) as specialSubOne[];
      resultObj.forEach(item => specialObj.push(new specialSubOne(item)));

      if (specialObj.some(x => x.sub.length > 0)) {
        if (contentString.length > 0) contentString += "\n";
        contentString += specialHint + "\n";
        contentString += lineBetween + "\n";
      }
      if (specialObj.length > 0) {
        specialObj.forEach(item => {
          if (item.sub != null && item.sub.length > 0) {
            item.sub.forEach(i => {
              if (i.DrugGroupCName != null) {
                contentString += "成分名稱: " + i.DrugGroupCName + "\n";
              }
              if (i.DrugGroupCode != null) {
                contentString += "成分代碼: " + i.DrugGroupCode + "\n";
              }
              if (i.Dose != null) {
                contentString += "總劑量: " + i.Dose + "\n";
              }
              if (i.SugDose != null) {
                contentString += "建議每半年劑量: " + i.SugDose + "\n";
              }
              contentString += lineBetween + "\n";
            });
          }
        });
      }
    }

    return contentString;
  }
  //#endregion

  onBtnPatientClick() {
    this.mainLayoutService.showLoader();
    this.isShowPatDialogEdit = true;
    setTimeout(() => {
      this.isShowPatDialog = true;
      this.mainLayoutService.hideLoader();
    }, 500);
    this.detectChanges();
  }

  async onBtnDiagCertClick() {
    // 先請用戶將看診畫面資料儲存
    var act = await this.userConfirmService.showConfirm({
      title: '開立診斷書',
      msg: '資料尚未完診儲存，是否先儲存病歷？',
      textYes: '儲存',
      textNo: '取消',
      width: 300
    });
    if (act == true) {
      // 儲存
      var isSave = await this.saveAndExit(false);
      if (isSave) {
        // 確定儲存後再打開診斷書畫面
        var pat = this.histService.currentHist.Patient;
        var p = new PatientListDto();
        p.Id = pat.Id;
        p.PatientNo = pat.PatientNo;
        p.LastName = pat.LastName;
        p.FirstName = '';
        p.MiddleName = pat.MiddleName;
        p.PhoneNo = pat.CellPhone ? pat.CellPhone : (pat.PhoneArea + '-' + pat.Phone);
        p.Mobile = pat.CellPhone;
        p.Birthday = pat.Birthday;
        p.CId = pat.CId;
        p.City = pat.City;
        p.State = '';
        p.Area = pat.Area;
        p.Street = pat.Street;
        p.FullName = pat.CName;
        p.Address = pat.City + pat.Area + pat.Street;
        p.Name = pat.CName;
        p.PaySysA = pat.PaySysA;
        p.Sex = pat.Sex;
        this.patData = p;
        this.isShowDiagCertDiaglog = true;
      }
    }
  }

  async closeDiagCertClick() {
    this.isShowDiagCertDiaglog = false;
    await this.userConfirmService.showAlert('訊息', '看診資料尚未完診，請記得執行完診(F2)', { width: 400 })
  }

  doChangeRegDate() {
    if (!((this.histRcd.Register.IType === '0' || this.histRcd.Register.IType === '2' || this.histRcd.Register.IType === '3') || this.histRcd.Register.StatusCode == 'F')) return;
    //自費、欠卡、異常掛號 => 開放可修改時間
    this.isChangeRegDateDialogOpened = true;
    var regdate = new Date(this.histRcd.Register.RegDate);
    var regTime = this.timeFormat(regdate.getHours()) + ":" + this.timeFormat(regdate.getMinutes()) + ":" + this.timeFormat(regdate.getSeconds());
    this.RegDateFG.patchValue({
      RDate: regdate,
      RTime: regTime,

    });
    if (this.histRcd.Register.IType === '0' || this.histRcd.Register.IType === '2' || this.histRcd.Register.IType === '3') {
      this.RegDateFG.controls.RDate.enable();
      this.RegDateFG.controls.RTime.enable();
    }
    else {
      this.RegDateFG.controls.RDate.disable();
      this.RegDateFG.controls.RTime.disable();

    }
    if (this.histRcd.Register.StatusCode == 'F') {
      let doctorId = this.histRcd.Register.DoctorId;
      this.refreshDoctorRoom(doctorId);
      this.RegDateFG.controls.DoctorId.enable();
      this.RegDateFG.controls.RoomCode.enable();
    }
    else {
      this.RegDateFG.controls.DoctorId.disable();
      this.RegDateFG.controls.RoomCode.disable();
    }
  }

  getRegTime(): string {
    var regdate = new Date(this.histRcd.Register.RegDate);
    return this.timeFormat(regdate.getHours()) + ":" + this.timeFormat(regdate.getMinutes()) + ":" + this.timeFormat(regdate.getSeconds());
  }

  timeFormat(src: number): string {
    let d = src.toString();
    if (d.length >= 2) {
      return d;
    } else {
      return '0' + d;
    }
  }

  dateToString(src: Date): string {
    let ret: string = src.getFullYear() + "-" + (src.getMonth() + 1) + "-" + src.getDate();
    return ret;
  }

  onChangeRegDateDialogClose(s: string) {
    if (s == 'sure') {
      var regdate = new Date(this.RegDateFG.controls['RDate'].value);
      var regtime = this.RegDateFG.controls['RTime'].value;
      this.histRcd.Register.RegDate = new Date(this.dateToString(regdate) + " " + regtime);
      //其他程式判斷ICD10版本時會用currentHist的RegDate，因此這裡改變日期時要寫入。
      this.histService.currentHist.Register.RegDate = this.histRcd.Register.RegDate;
      if (this.histRcd.Register.StatusCode == 'F') {
        this.histRcd.Hist.DoctorId = this.RegDateFG.controls['DoctorId'].value;
        this.histRcd.Register.DoctorId = this.RegDateFG.controls['DoctorId'].value;
        this.histRcd.Register.RoomCode = this.RegDateFG.controls['RoomCode'].value;
      }

    }
    this.isChangeRegDateDialogOpened = false;
  }

  async onExitPatEvent(evt: any) {
    if (evt != 0) {
      await this.histService.loadHistForRegChange(this.initRegId);
      this.replacePatientData();
    }
    this.isShowPatDialog = false;
    this.isShowPatDialogEdit = false;
    this.mainLayoutService.hideLoader();
  }

  //拿來記錄前一次開啟的樣板是哪個Dx來的
  lastDxForTmpl = '';
  packageId: number = null
  async dxChagned(dx: Dx) {
    if (dx) {
      var dxFull = await this.histApi.getDiagByCode(dx.Code, this.icd10Version);
      if (dxFull.PackageId && dxFull.Code != this.lastDxForTmpl) {
        this.packageId = dxFull.PackageId;
        this.isLoadTmplWinOpened = true;
        this.lastDxForTmpl = dxFull.Code;
      }
    }
    else {
      this.lastDxForTmpl = '';
    }
  }

  showVitalSignPanel($event) {
    this.vitalsignToggle = !this.vitalsignToggle;
    if (this.vitalsignToggle) {
      this.pnlMeasure.setMax();
      this.pnlCC.setMin();
      this.pnlPE.setMin();
      this.pnlChiAsk.setMin();
      this.pnlChiLook.setMin();
      this.pnlChiPulse.setMin();
      this.pnlChiBianZheng.setMin();
      this.pnlChiTreatRule.setMin();
      this.pnlRm.setMin();
      this.pnlAlg.setMin();
    }
    if (!this.vitalsignToggle) {
      this.pnlMeasure.setMin();
    }
  }

  isControlEvent(val: PriceOtherControl) {
    this.priceControl = val;
  }

  ischangePillDisp(val: boolean) {
    if (val) {
      let tempHRxs = Object.assign([], this.ordersComp.orders);
      tempHRxs = tempHRxs.filter(x => x.RxCode !== null && x.RxCode !== '');
      for (var i = 0; i < tempHRxs.length; i++) {
        if (tempHRxs[i]) {
          if (this.histRcd.Hist.DispTP == DispensingTypeEnum.OutOfClinic && [2, 3].indexOf(tempHRxs[i].RxType) >= 0) {
            tempHRxs[i].DispTP = OrderDispensingTypeEnum.T1_OutOfClinic
          }

          let hrx = this.histRcd.Hist.HRxs.find(x => x.Id == tempHRxs[i].Id);
          if (hrx) {
            tempHRxs[i].TransOri = hrx.TransOri;
          }
        }
      }
      this.histRcd.Hist.HRxs = tempHRxs;
      this.ordersComp.patchOrderDisp(tempHRxs)
      //this.orderHrx = tempHRxs; // 要倒回去order 這樣下補上顯示才不會有與實際值有差

    }
  }
  isChangeOwnEvent(val: boolean) {
    this.isChangeOwn = val;
  }
  exeCompSure() {
    this.isCopy = false;
    var newOrders: HistOrder[] = [];
    var execOrder = this.ordersExecComp.orders;
    // 更新醫令輸入區的執行人員相關資訊
    this.ordersComp.patchOrderExec(execOrder);
    // 完整會重新拉取HRx 故掠過
    // this.histRcd.Hist.HRxs.forEach(item => {
    //   execOrder.forEach(exeItem => {
    //     if (item.RxType == exeItem.RxType && item.RxCode == exeItem.RxCode) {
    //       item = Object.assign(item,exeItem);
    //     }
    //   });
    //   newOrders.push(item);
    // });
    // //this.histRcd.Hist.HRxs = [];
    // this.histRcd.Hist.HRxs = deepCopy<HistOrder[]>(newOrders);
    // this.orderHrx = this.histRcd.Hist.HRxs;
    this.isShowRxDialog = false;
  }

  closeRxExecutorWin() {
    this.isShowRxDialog = false;
  }
  getstatusdone(){
    var ret = this.histRcd.Register.StatusCode === RegisterConst.Code_Status_Finish
    console.log('ret',ret);
    return ret
  }
  openExecutorAndDateTime() {
    // 取得畫面輸入的醫令
    this.pullOrders();
    this.hrxsExecutor = this.histRcd.Hist.HRxs.filter(x => x.NeedExecuteTime == true || x.NeedExecuteDate == true || x.NeedExecutor == true);
    if (this.hrxsExecutor.length == 0) {
      this.notification.showInfo('不需輸入醫令執行人員及起訖日期時間！');
      return;
    }
    this.isShowRxDialog = true;
  }

  urgentChange(evt: any) {
    this.histRcd.Hist.Urgent = evt.checked ? 'V' : 'X';
  }

  urgentNightChange(evt: any) {
    this.histRcd.Hist.UrgentNight = evt.checked ? 'V' : 'X';
  }

  async urgentHCChange(evt: any) {
    this.histRcd.Hist.UrgentHC = evt.checked ? 'V' : 'X';
    const submitData = this.hrxsExecutor;
    var word = await this.histApi.CheckHomeCareUrgent(submitData, this.histRcd.Hist.UrgentHC);
    this.ErrorAndWarning.UrgentHC = (word.includes('Not Add True') || word.includes('Not Have MedID')) && this.histRcd.Hist.UrgentHC === 'V' ? 'W' : '';
    if (word.includes('Not Have MedID') && word.includes('Not Add True') && this.ErrorAndWarning.UrgentHC === 'W') {
      this.notification.showWarning('您的訪視人員、填入時間不在加成範圍內，建議更正')
    }
    else if (word.includes('Not Have MedID') && this.ErrorAndWarning.UrgentHC === 'W') {
      this.notification.showWarning('您的訪視人員未輸入，請選擇對應訪視人員')
    }
    else if (word.includes('Not Add True') && this.ErrorAndWarning.UrgentHC === 'W') {
      this.notification.showWarning('您的緊急訪視條件不符，請確認訪視時間是否在加成區間內')
    }
  }

  getOrderDialogHeight() {
    let newHeight = document.body.clientHeight - 176 + 'px';
    return newHeight;
  }

  getReferralDialogHeight() {
    let newHeight = document.body.clientHeight - 130 + 'px';
    return newHeight;
  }

  enableTeleMed = FunctionEnable.EnableTeleMed
  async videoCall() {
    if (!this.enableTeleMed) {
      return;
    }

    var url = await this.histApi.CallTeleMed(this.initRegId);
    window.open(url.DoctorUrl);
  }

  async videoReplay() {
    if (!this.enableTeleMed) {
      return;
    }
    try {
      var url = await this.histApi.ReviewTeleMed(this.initRegId);
      window.open(url.DoctorUrl);
    } catch (e) {
      this.notification.showError(e);
    }
  }

  getSearchedEmit(dxArray: HistDiag[]) {
    this.isShowSearch = true;
    this.isShowSearchDisabled = false;
    if (dxArray == null) return;
    dxArray = dxArray.filter(x => x.code != '' && x.code != undefined && x.code != null)
    this.histRcd.Hist.Diags = dxArray;
  }

  helpPadDefaultHide() {
    return this.startUp.userData.prefs.HistPadHide;
  }

  closeShowSearch() {
    this.isShowSearchDisabled = false;
  }
  /** 重新載入班表 */
  async refreshDoctorRoom(doctorId?: number) {
    // const timeSec = this.editFormGroup.controls['TimeSec'].value;
    var isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
    var isRecovery = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
    var showNuser = (isHomeCare || isRecovery) ? true : false;
    const date = this.histRcd.Register.RegDate;
    const now = new Date(date);

    var allDr = await this.userApi.GetDoctorAndNurse(showNuser, false, DateHelper.getFormatedDateString(date),false);
    this.doctors = allDr;

    if (doctorId) {
      this.RegDateFG.patchValue({
        DoctorId: doctorId,
      }, { 'emitEvent': false });
    }

    var medRoom = await this.clinicDataService.getSystemCodes(['C0120']);
    this.medRoom = medRoom['C0120'];
    var roomCode = this.histRcd.Register.RoomCode;
    if (roomCode) {
      this.RegDateFG.patchValue({
        RoomCode: roomCode,
      }, { 'emitEvent': false });
    }
  }
  /** 切換看診日Blur事件 */
  async onBlurDate(regDate) {
    var isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
    var isRecovery = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
    var showNuser = (isHomeCare || isRecovery) ? true : false;
    const date = new Date(regDate);
    //切換看診日=>醫師下拉選單重新取得 (因要判斷醫師是否離職)
    var allDr = await this.userApi.GetDoctorAndNurse(showNuser, false, DateHelper.getFormatedDateString(date),false);
    this.doctors = allDr;
  }

  async checkHouseCare() {
    var patientHasHouseCare = await this.familycaryAPI.CheckFamilyCareByHistPatientId(this.histRcd.Patient.Id, this.histRcd.Register.RegDate);
    this.priceControl.ishouseCare = patientHasHouseCare;
    if (this.priceControl.ishouseCare) {
      this.notification.showInfo('該病患今年有家護資料');
    }
  }
  async getHouseCare() {
    this.familyCareList = await this.familycaryAPI.GetFamilyCareByHistPatientId(this.histRcd.Patient.Id, this.histRcd.Register.RegDate, false);
    this.familyTotal = this.familyCareList?.Total || 0;
    this.familyCareDto = this.familyCareList?.FamilyCareList[0];
    this.priceControl.ishouseCare = this.familyCareDto?.IsEnabled || false;
    // if (this.priceControl.ishouseCare) {
    //   this.notification.showInfo('該病患今年有家護資料');
    // }
  }

  /** 開啟家護按鈕事件*/
  async onHouseCareClick() {
    await this.getHouseCare();
    if (this.familyDoctors.length < 1 || this.familyMemberTypeOpt.length < 1 || this.familyCaseTypeOpt.length < 1) {
      let options = await this.familycaryAPI.GetFamilyCareSettingEditOptions();
      this.familyDoctors = options?.familyDoctors;
      this.familyMemberTypeOpt = options?.familyMemberTypeOpt;
      this.familyCaseTypeOpt = options?.familyCaseTypeOpt;
      if (this.familyDoctors.length > 0 && this.familyCareList?.FamilyCareList?.length > 0) {
        this.familyDoctor = this.familyDoctors.find(x => x.value == UserCache.getLoginUser()?.CId)?.value || '';
        this.familyCareDto.DoctorName = this.familyDoctors.find(x => x.value == this.familyCareDto?.DoctorCId)?.text || '';
        let dtos = this.familyCareList.FamilyCareList;
        dtos.forEach(element => {
          element.DoctorName = this.familyDoctors.find(x => x.value == element.DoctorCId)?.text || '';
        });
        this.familyCareGridview = {
          data: this.familyCareList.FamilyCareList,
          total: this.familyCareList.FamilyCareList.length
        }
      }

    }
    this.isFamilyCareDialog = true;
  }
  async onFamilySaveClick() {
    try {
      let feedback = await this.familycaryAPI.CreateFamilyCareByDtoAsync(this.familyCareList?.FamilyCareList);
      if (feedback.IsSuccess) {
        this.notification.showInfo('儲存完成');
        this.isFamilyCareDialog = false;
        await this.getHouseCare();
      }
    } catch (error) {
      if (error.message) {
        this.notification.showError(error.message); // 顯示存檔失敗]
        return;
      } else {
        this.notification.showFailed(); // 顯示存檔失敗
        return;
      }
    }

  }
  async onFamilyNewSaveClick() {
    try {
      let saveop = this.familyCareNewMemberList?.FamilyCareList?.filter(x => x.IsEnabled)
      if (saveop?.length < 1) {
        this.notification.showWarning('未勾選任何病患，資料不儲存!');
        return this.isFamilyCareNewDialog = false;
      }
      let options = await this.familycaryAPI.CreateFamilyCareByDtoAsync(saveop);

    } catch (error) {
      if (error.message) {
        this.notification.showError(error.message); // 顯示存檔失敗]
        return;
      } else {
        this.notification.showFailed(); // 顯示存檔失敗
        return;
      }
    }
    this.notification.showInfo('儲存完成');
    this.isFamilyCareNewDialog = false;
  }
  onFamilyModifySaveClick() {
    let length = this.familyCareNewGridview?.data;
    if (length < 1) { this.isFamilyCareModifyDialog = false; return; }

    let start = this.familyStartSeqNo;
    let end = this.familyEndSeqNo < length ? this.familyEndSeqNo : length;
    if (start > length) { this.isFamilyCareModifyDialog = false; return; }
    for (var i = (start - 1); i < end; i++) {
      let family = this.familyCareNewMemberList.FamilyCareList[i];

      if (family) {
        family.CaseType = this.familyModifyCase;
        family.DoctorCId = this.familyModifyDoctor;
      }
    }
    this.familyCareNewGridview = {
      data: this.familyCareNewMemberList.FamilyCareList,
      total: this.familyCareNewMemberList.FamilyCareList.length
    }
    this.isFamilyCareModifyDialog = false;
  }
  async onFamilyCareCheckClick(evt: { checked: boolean, data: any }) {
    // console.log('selected evt :', evt);
    if (evt.checked == true) {
      // select all
      var dto = evt.data as FamilyCareDto;
      if (dto) {
        var personInfo = this.familyCareList?.FamilyCareList.find(x => x.PatientNo == dto.PatientNo && x.ClinicId == dto.ClinicId);
        if (personInfo) {
          personInfo.IsEnabled = true;
          personInfo.CaseDate = this.histRcd.Register.RegDate;
          personInfo.DoctorCId = this.familyDoctor;
          personInfo.DoctorName = this.familyDoctors.find(x => x.value == this.familyDoctor)?.text;
        }

      }
    } else {
      var dto = evt.data as FamilyCareDto;
      if (dto) {
        var personInfo = this.familyCareList?.FamilyCareList.find(x => x.PatientNo == dto.PatientNo && x.ClinicId == dto.ClinicId);
        if (personInfo) personInfo.IsEnabled = false;

      }
    }
  }
  onFamilyDoctorChange(dataItem: any, evt: MatOption) {
    var dto = dataItem as FamilyCareDto;
    if (dto) {
      var personInfo = this.familyCareList?.FamilyCareList.find(x => x.PatientNo == dto.PatientNo && x.ClinicId == dto.ClinicId);
      if (personInfo) personInfo.DoctorCId = evt.value;
    }
  }
  onFamilyDoctorModifyChange(dataItem: any, evt: MatOption) {
    var dto = dataItem as FamilyCareDto;
    if (dto) {
      var personInfo = this.familyCareNewMemberList?.FamilyCareList.find(x => x.PatientNo == dto.PatientNo && x.ClinicId == dto.ClinicId);
      if (personInfo) personInfo.DoctorCId = evt.value;
    }
  }
  async onFamilySearchPatientClick() {
    this.isFamilySearchDialog = true;
  }
  async onPatientDialogClose(data: PatientListDto) {
    this.isFamilySearchDialog = false;
    // The variable named "patient" will be null when user clicks button named "x" on the dialog box to close or triggers the closing action.
    if (data != null) {
      this.familyCareNewMemberList = await this.familycaryAPI.GetFamilyCareByHistPatientId(data?.Id, new Date(), true);
      if (this.familyCareNewMemberList?.IsOld) {
        this.notification.showWarning(data.Name + '已經為家護成員!');
        return;
      }
      this.familyCareNewGridview = {
        data: this.familyCareNewMemberList.FamilyCareList,
        total: this.familyCareNewMemberList.FamilyCareList.length
      }
      this.isFamilyCareNewDialog = true;
      // this.patient = data;
      // this.refreshList();
      // this.patientName = data.Name == undefined || data.Name == null ? '' : data.Name;
      // this.patientNo = data.PatientNo == undefined || data.PatientNo == null ? '' : data.PatientNo;
      // this.patientPhone = data.PhoneNo == undefined || data.PhoneNo == null ? '' : data.PhoneNo;
      // this.patientBirthday = data.Birthday == undefined || data.Birthday == null ? '' : new Date(data.Birthday).toDateString();
      // this.patientAddress = data.City + data.Area + data.Street;
    }
  }
  getRow(i: number): number {
    console.log('i >>', i);
    return i
  }
  onMemberTypeChange(dataItem: any, evt: MatOption) {
    var dto = dataItem as FamilyCareDto;
    if (dto) {
      var personInfo = this.familyCareList.FamilyCareList?.find(x => x.PatientNo == dto.PatientNo && x.ClinicId == dto.ClinicId);
      if (personInfo) personInfo.MemberType = evt.value;
    }
  }
  onCaseTypeChange(dataItem: any, evt: MatOption) {
    var dto = dataItem as FamilyCareDto;
    if (dto) {
      var personInfo = this.familyCareList.FamilyCareList?.find(x => x.PatientNo == dto.PatientNo && x.ClinicId == dto.ClinicId);
      if (personInfo) personInfo.CaseType = evt.value;
    }
  }
  /** 是否做洗腎重傷病判斷 */
  callMajorinj(val: boolean) {
    if (!val || this.histRcd.Register.SameTreat != '0') {
      this.isKidneyDialyis = 1;
      return;
    }
    this.isKidneyDialyis = 2;
  }
  /** 洗腎重傷病判斷 */
  async callBackMajorinj() {
    let majinj = new MajorIll();
    let hpcMaj: CriticalIllness[] = [];
    let noHpcMaj: CriticalIllnesNoHPC[] = [];

    try {
      this.showBlock = true;
      this.notification.showInfo('讀取患者重大傷病資料中...');

      if (await this.autoCheckHPC()) {
        hpcMaj = await this.getTreatment();
      }
      else {
        noHpcMaj = await this.getTreatmentNoNeedHPC();

      }
    }
    catch (ex) {
      // this.notification.showError(ex);
      console.log('ex', ex);
      this.showBlock = false;
    }


    var nowd = new Date();
    if (noHpcMaj.length > 0) {
      majinj.MajDate = noHpcMaj[0]?.StartDate;
      majinj.CardCount = noHpcMaj.filter(x => new Date(x.StartDate) <= nowd && new Date(x.EndDate) >= nowd)?.length || 0;
    }
    else {
      majinj.MajDate = hpcMaj[0]?.StartDate;
      majinj.CardCount = hpcMaj.filter(x => new Date(x.StartDate) <= nowd && new Date(x.EndDate) >= nowd)?.length || 0;
    }
    let majorInjuryDate = this.histRcd.Patient.MajorInjuryDate;
    let origDate = new Date(majorInjuryDate);
    if (origDate) origDate.setHours(0, 0, 0, 0);
    let majOnlyDate = new Date(majinj.MajDate);
    if (majOnlyDate) majOnlyDate.setHours(0, 0, 0, 0);
    this.showBlock = false;
    if (!majorInjuryDate && majinj.CardCount > 0 && (!this.histRcd.Patient.MajorInjuryTimes || this.histRcd.Patient.MajorInjuryTimes < 1)) {
      this.histRcd.Patient.MajorInjuryDate = majinj.MajDate;
      this.histRcd.Patient.MajorInjuryTimes = majinj.CardCount;
    }
    else if (majorInjuryDate && majOnlyDate != origDate && majinj.CardCount > 0 && majinj.CardCount != this.histRcd.Patient.MajorInjuryTimes) {
      var act = await this.userConfirmService.showConfirm({
        title: '不同的重大傷病資料',
        msg: '您的重大傷病卡數或初次領重大傷病日期與卡片不同，是否修改',
        textYes: '是',
        textNo: '否',
        width: 500
      });
      if (act == true) {
        this.histRcd.Patient.MajorInjuryDate = majinj.MajDate;
        this.histRcd.Patient.MajorInjuryTimes = majinj.CardCount;
      }
    }
    var ageArr = DateHelper.transformBirthDay(this.histRcd.Patient.Birthday,this.histRcd.Register.RegDate);
    // 12歲以下或急診案件 金馬離島地區
    if (UserCache.getLoginUser().Clinic.IsKM || (ageArr[1] != 14 && (ageArr[0] < 12 || (ageArr[0] == 12 && ageArr[1] == 0 &&  ageArr[2] == 0))) || this.histRcd.Register.StatusCode == '*'){
      await this.addOrder('58027C');
    }
    else if (!this.histRcd.Patient.MajorInjuryDate) {
      await this.addOrder('58029C');
    }
    else {
      let majD = new Date(this.histRcd.Patient.MajorInjuryDate);
      let majDate = new Date(majD.getFullYear(), majD.getMonth(), majD.getDate());
      let nowD = new Date();
      let nowDate = new Date(nowD.getFullYear(), nowD.getMonth(), nowD.getDate());
      // console.log('majD',majD,this.histRcd?.Patient?.MajorInjuryDate)
      majDate.setDate(majDate.getDate() + 30);
      // console.log('majD',majDate)
      if (( this.histRcd.Patient.MajorInjuryTimes == 1 && (majDate >= nowDate)) || this.histRcd.Patient.MajorInjuryTimes >= 2) await this.addOrder('58027C');
      else await this.addOrder('58029C');
    }
  }
  async autoCheckHPC() {
    return await this.hpcHelper.VarifyHPC();
    // var ret = await this.icHelper.getAPI().GetHPCStatus();
    // if (ret.StatusCode == 0) {
    //   var st = await this.icHelper.getAPI().GetCardStatusHPC();
    //   if (st.Data.includes('PIN 尚未認證')) {
    //     var retPin = await this.icHelper.getAPI().VerifyHPCPIN()
    //     var pinValid = retPin.StatusCode == 0;
    //     if (!pinValid) {
    //       return false;
    //     }
    //     return pinValid;
    //   } else if (!st.Data.includes('認證成功')) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // } else {
    //   return false;
    // }
  }
  async getTreatment(): Promise<CriticalIllness[]> {

    this.notification.showInfo('重大傷病讀取中');
    var ret = await this.icHelper.getAPI().GetTreatmentNeedHPC();
    var majInj: CriticalIllness[] = [];
    if (ret.StatusCode == 0 && ret.Data) {
      majInj = [ret.Data.MajorInjury1, ret.Data.MajorInjury2, ret.Data.MajorInjury3,
      ret.Data.MajorInjury4, ret.Data.MajorInjury5, ret.Data.MajorInjury6]
    }
    return majInj;
  }
  async getTreatmentNoNeedHPC(): Promise<CriticalIllnesNoHPC[]> {

    var data = await this.icHelper.getAPI().GetTreatmentNoNeedHPC();
    var majInj: CriticalIllnesNoHPC[] = [];
    if (data.StatusCode == 0) {
      majInj = [data.Data.MajorInjury1, data.Data.MajorInjury2, data.Data.MajorInjury3,
      data.Data.MajorInjury4, data.Data.MajorInjury5, data.Data.MajorInjury6]
        .filter(x => x);
    }
    return majInj;
  }


  onBtnAppoinmentClick() {
    this.isShowAppointmentDialog = true;
  }

  onPACSConnectDialogClose = async () => {
    this.isShowPACSConnectDialog = false;
  }

  onPACSConnectCreateDialog = async () => {
    this.pullOrders();
    this.isShowPACSConnectDialog = false;
    this.pACSCreateTriggerDate = new Date();
    this.pACSCreateTriggerNumber = this.formatPACSNumber(new Date());
    this.isShowPACSCreateDialog = true;
  }

  onPACSConnectOpenDialog = async () => {
    await this.callPACSViewer();
    this.isShowPACSConnectDialog = false;
  }

  onPACSCreateDialogClose = async () => {
    this.isShowPACSCreateDialog = false;
  }

  /**
   *產生PACS開單號
   *
   * @private
   * @param {Date} date
   * @memberof RecordComponent
   */
  private formatPACSNumber = (date: Date): string => {
    const minguoYear = date.getFullYear() - 1911; // 民國年計算
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `A${minguoYear}${month}${day}${hours}${minutes}${seconds}`;
  }

  /** 處理PACS開單的事件發射器
   *
   *
   * @param {SendEmitData} emitData
   * @memberof RecordComponent
   */
  handlePACSCreateEmitCode = async (emitData: SendEmitData) => {
    await this.pullOrdersWhenEmitPACS(emitData);
    await this.genPACSSender(emitData);
    await this.sendPACS();
  }

  /** PACS請求送出時，回寫醫令
   *
   *
   * @private
   * @param {SendEmitData} emitData
   * @memberof RecordComponent
   */
  private pullOrdersWhenEmitPACS = async (emitData: SendEmitData) => {
    //寫入醫令有勾選時的流程
    if (emitData.isInputRx) {
      let uniqueRegCodesByDNo = Array.from(
        new Map(emitData.selectedRegCodes.map(item => {
          return [this.getCodeValueFull(item), item];
        })).values()
      );

      for (const selectedRegCode of uniqueRegCodesByDNo) {
        await this.addOrderByRxCode(this.getCodeValueFull(selectedRegCode));
      }
    }

    //同步子元件的項目
    this.pullOrders();
  }

  /** 產生PACS傳輸資料
   *
   *
   * @private
   * @param {SendEmitData} emitData
   * @memberof RecordComponent
   */
  private genPACSSender = async (emitData: SendEmitData) => {
    let codeValues = emitData.selectedRegCodes.map(element => this.getCodeValueFull(element));
    let codeMeans = emitData.selectedRegCodes.map(element => element.CodeMean);

    //取得健保資料
    var nhiData = await this.nhiApi.GetNHIData(this.histRcd.Register.Id);

    //產生PACS傳送資料
    let now = new Date();

    let sexCode: string = "O";
    if (emitData.patientView.SexName == "男") {
      sexCode = "M";
    }
    else if (emitData.patientView.SexName == "女") {
      sexCode = "F";
    }

    let birthDay: Date = new Date(emitData.patientView.Birthday);
    let regDate: Date = new Date(this.histRcd.Register.RegDate);

    this.tempPACSSender = {
      AccessionNumber: emitData.accessionNumber,
      MType: emitData.selectedRegCodes[0].MType,
      ExamDate: this.formatDateByObj(now),
      PatientId: emitData.patientView.PatientNo,
      PatientName: emitData.patientView.PatientName,
      PatientOtherId: emitData.patientView.CId,
      BirthDate: this.formatDateByObj(birthDay),
      Sex: sexCode,
      CodeMean: codeMeans.join(";"),
      CodeValue: codeValues.join(";"),
      PatientDate: this.formatDateTimeByObj(regDate),
      RoomCode: this.roomCode,
      ReferringPhysician: this.histRcd.Register.DoctorName,
      RequestedLocation: this.cln.NHICode,
      MedicalCategory: this.cln.NHILevel == "D" ? "11" : "12",
      RegDate: this.formatTwYearMonth(regDate),
      RegDateTime: this.formatTwDateYearMinute(regDate),
      HealthScanTime: "",
      MedicalAttentionCategory: "",
      MedicalAttentionNumber: "",
      HealthBackupCardRemarks: "",
      BodyPart: "",
      NewbornBirthday: null,
      NewbornRemarks: null
    }

    if (nhiData.RegIc) {

      if (typeof nhiData.RegIc.Icdt === 'string') {
        this.tempPACSSender.HealthScanTime = nhiData.RegIc.Icdt;
      }
      else if (nhiData.RegIc.Icdt instanceof Date) {
        this.tempPACSSender.HealthScanTime = this.formatTwDateTimeByObj(nhiData.RegIc.Icdt);
      }
      this.tempPACSSender.MedicalAttentionCategory = nhiData.RegIc.Nhictp;
      this.tempPACSSender.MedicalAttentionNumber = nhiData.RegIc.Isqno;
      this.tempPACSSender.HealthBackupCardRemarks = nhiData.RegIc.IreCard;
      this.tempPACSSender.BodyPart = "";
      this.tempPACSSender.NewbornBirthday = nhiData.RegIc.BabyDate ? nhiData.RegIc.BabyDate : null;
      this.tempPACSSender.NewbornRemarks = nhiData.RegIc.BabyMark ? nhiData.RegIc.BabyMark : null;
    }
  }

  /** 傳送PACS開單
   *
   *
   * @private
   * @param {("workList1" | "workList2")} [target="workList1"]
   * @memberof RecordComponent
   */
  private sendPACS = async (target: "workList1" | "workList2" = "workList1") => {
    try {
      let apiResult = await this.savePACSEmitterRecord(this.tempPACSSender);
      if (!apiResult || !apiResult.isOk) {
        return false;
      }

      let recordId: number = apiResult.recordId;

      try {
        let wsResult: { Successed: boolean; };

        //30秒超時
        let sendFunc = target == "workList1"
          ? this.hcrService.SendPACSRequestToWorkList.bind(this.hcrService)
          : this.hcrService.SendPACSRequestToWorkList2.bind(this.hcrService);
        wsResult = await TimeRaceHelper(sendFunc(this.tempPACSSender), 30000);
        if (!wsResult.Successed) {
          await this.pACSEmitterRecordApiService.UpdateStatus(recordId, PACSStatusEnum.UNKNOW_ERROR);
          this.notification.showWarning(`醫令已寫入，但寫入PACS開單失敗，可能是主控台版本問題。`)
          return false;
        }
      } catch (error) {
        if (error.message === 'Request timed out') {
          await this.pACSEmitterRecordApiService.UpdateStatus(recordId, PACSStatusEnum.TIMEOUT);
          this.notification.showWarning('醫令已寫入，但寫入PACS開單超時，可能是主控台版本問題。');
          return false;
        } else {
          await this.pACSEmitterRecordApiService.UpdateStatus(recordId, PACSStatusEnum.UNKNOW_ERROR);
          throw error;
        }
      }



      await this.pACSEmitterRecordApiService.UpdateStatus(recordId, PACSStatusEnum.SUCCESS);
      return true;
    } catch (ex) {
      this.notification.showWarning(`醫令已寫入，但寫入PACS開單失敗，可能是主控台版本問題。`)
      return false;
    }
  }

  /** 儲存PACS傳輸紀錄
   *
   *
   * @private
   * @param {PACSReceiver} sender
   * @memberof RecordComponent
   */
  private savePACSEmitterRecord = async (sender: PACSReceiver): Promise<{ isOk: boolean, recordId: number | null }> => {
    const RegisterId = this.histRcd.Register.Id;
    const Status = PACSStatusEnum.PENDING;
    let param: PACSEmitterRecordDTO = { ...sender, RegisterId, Status }

    try {
      let result = await this.pACSEmitterRecordApiService.Create(param);
      if (!result.IsSuccess) {
        this.notification.showError(`PACS傳輸LOG紀錄失敗，請檢查API狀態。`)
        return {
          isOk: false,
          recordId: null
        };
      }

      return {
        isOk: true,
        recordId: result.Object.Id
      };

    } catch (error) {
      this.notification.showError(`PACS傳輸LOG紀錄失敗，請檢查API狀態。`)
      return {
        isOk: false,
        recordId: null
      };
    }

  }

  private callPACSViewer = async (): Promise<boolean> => {
    try {
      let wsResult: { Successed: boolean; };
      wsResult = await this.hcrService.CallPACSViewer(this.histRcd.Patient.PatientNo);
      if (!wsResult.Successed) {
        this.notification.showWarning(`開啟PACS Viewer的過程中發生錯誤`)
        return false;
      }

      return true;
    } catch (error) {
      this.notification.showWarning(`開啟PACS Viewer的過程中發生錯誤`);
      return false;
    }

  }

  formatDateByObj = (date: Date): string => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
  }

  formatDateTimeByObj = (date: Date): string => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let sec = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}${hour}${minute}${sec}`;
  }


  formatTwDateTimeByObj = (date: Date): string => {
    let year = date.getFullYear() - 1911;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let sec = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}${hour}${minute}${sec}`;
  }

  private formatTwYearMonth = (date: Date): string => {
    const twYear = date.getFullYear() - 1911; // 民國年計算
    const month = String(date.getMonth() + 1).padStart(2, '0');

    return `${twYear}${month}`;
  }

  private formatTwDateYearMinute = (date: Date): string => {
    const twYear = date.getFullYear() - 1911; // 民國年計算
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${twYear}${month}${day}${hours}${minutes}`;
  }

  private getCodeValueFull(regCode: RegistrationCodeView): string {
    return regCode.CodeValue + (regCode.CodeValueSuffix ?? '');
  }
  editParam() {
    this.clinicDataService.editParam('HST001', ['DefaultDays', 'DefaultDose', 'DefaultFrequency']);
  }

  createHistCopy() {
    var h = Object.assign(new WholeHist(), this.histRcd);
    h.Hist = Object.assign(new Hist(), this.histRcd.Hist);
    h.Hist.CC = this.getCCFromComponents();
    h.Hist.PE = this.getPEFromComponents();
    h.Hist.Diags = this.getDiagsFromComponentsNoCheck();
    h.Hist.restoreByDiags();
    h.Hist.HRxs = this.ordersComp.getOrderNoCheck();
    h.VitalSign = this.getVitalSignFromComponent();
    return h;
  }

  makeQuerySub(query: nhiReqV2, nhiimiList: NhiimiListDto[], type: number) {
    if (type == 2 || type == 11) {
      var subs: nhiReqSubTwo[] = [];
      var s: nhiReqSubTwo = new nhiReqSubTwo();
      s.sOrder = 'X';
      subs.push(s);
      var sub: nhiReqSubOne = new nhiReqSubOne();
      sub.sub = subs;
      sub.sType = type.toString().padStart(2, '0');
      query.sub.push(sub);
    } else {
      var nhiimi = nhiimiList.filter(x => {
        if (type == 8 || type == 9) {
          return true;
        } else {
          return x.Stype == type;
        }
      });
      if (nhiimi.length > 0) {
        var subs: nhiReqSubTwo[] = [];
        nhiimi.forEach(n => {
          var s: nhiReqSubTwo = new nhiReqSubTwo();
          s.sOrder = n.StdCode;
          subs.push(s);
        });
        var sub: nhiReqSubOne = new nhiReqSubOne();
        sub.sub = subs;
        sub.sType = type.toString().padStart(2, '0');
        query.sub.push(sub);
        //3之資料其B欄表示檢查檢驗之醫令代碼，對應資料類別03、04。
        //03：檢查檢驗紀錄
        //04：檢查檢驗結果
        if (type == 3) {
          var sub: nhiReqSubOne = new nhiReqSubOne();
          sub.sub = subs;
          sub.sType = '04';
          query.sub.push(sub);
        }
      }
    }
  }

  cloudQueryReturn(rtnCode: string, params: nhiReqV2) {
    var error = '';
    switch (rtnCode) {
      case '00':
        break;
      case '01':
        error = '參數解析失敗\n(院所端傳入參數拆解失敗。)';
        break;
      case '02':
        error = '個案驗章失敗\n(實體健保卡驗證失敗。)';
        break;
      case '03':
        error = '連線數過多，請稍候再試\n(系統忙碌，已逹設定之最大連線數。)';
        break;
      case '04':
        error = '系統發生異常';
        break;
      case '05':
        error = '非適用特定醫囑代碼之醫令範圍\n(內含1項(含)以上非適用特定醫囑代碼之醫令範圍則整筆不處理。)';
        break;
      case '06':
        error = '資料類別錯誤\n(填入之資料類別錯誤。)';
        break;
      case '07':
        error = '個案驗章失敗\n(虛擬健保卡驗證失敗。)';
        break;
      case '08':
        error = '資料筆數過多\n(回傳資料過多，請調整查詢筆數。)';
        break;
      case '09':
        error = '非院所的專兼任醫師(藥師)';
        break;
      default:
        error = '未知的錯誤';
        break;
    }

    var medicals = params.sub.map(x => x.sub.map(y => { return '資料類別: ' + x.sType + '  醫令代碼: ' + y.sOrder }).join('\n')).join('\n');
    var hospId = '醫事機構代碼: ' + params.sHospId;
    var hcaId = '醫事人員身分證號: ' + params.sHcaId;
    var patId = '病人身分證號: ' + params.sPatId;
    var msgHead = error ? this.cloudQueryError + '\n' + rtnCode + error : '';
    var msgBody = msgHead + '\n' + medicals + '\n' + hospId + '\n' + hcaId + '\n' + patId + '\n';

    return [ msgHead, msgBody ];
  }

  async getLastRecordTime() {
    if (this.histRcd.Register.StatusCode == RegisterConst.Code_Status_Finish && this.histRcd.Hist.Id) {
      try {
        var lastRecordTime = await this.toccApi.GetLastRecordTime(this.histRcd.Hist.Id);
        if (lastRecordTime && lastRecordTime.RecordTime) {
          this.dengueRecordTime = lastRecordTime.RecordTime;
        }
      }
      catch (ex) {
        // this.notification.showError('讀取TOCC最新上傳時間發生錯誤: ' + ex);
      }
    }
    this.isShowTOCCUpload = true;
  }

  async onTOCCQueryClick() {
    if (this.isEnabledTOCC) {
      var ret = await this.userConfirmService.showConfirm({
        title: 'TOCC重新查詢',
        msg: '是否確定重新查詢問卷填寫內容?\n\n查詢結果將顯示於主訴中，並於完診時，自動上傳患者就醫紀錄。',
        textYes: '確定',
        textNo: '取消',
        fitContent: true
      });

      if (ret) {
        await this.queryDengueToCC(true);
      }
    }
  }

  async queryDengueToCC(manual: boolean) {
    var toccMessage = '';
    var originalTOCC = '';
    [toccMessage, originalTOCC, this.dengueFormDto, this.dengueFlag, this.dengueResult] = await this.toccService.queryDengue(
      this.isEnabledTOCC,
      this.histRcd,
      (this.histRcd.Register.StatusCode != RegisterConst.Code_Status_Finish) || manual
    );

    if (toccMessage) {
      if (manual) this.histRcd.Hist.CC = this.getCCFromComponents();

      if (this.histRcd.Hist.CC && this.histRcd.Hist.CC?.indexOf(this.toccKeyword) >= 0) {
        //原本如有TOCC先清除-來自TOCC問卷紀錄
        if (originalTOCC) this.histRcd.Hist.CC = this.histRcd.Hist.CC.replace(originalTOCC, '');
        //原本如有TOCC先清除-來自還沒寫入TOCC問卷紀錄前的TOCC
        if (this.keepToccMessage) this.histRcd.Hist.CC = this.histRcd.Hist.CC.replace(this.keepToccMessage, '');
        //如有未填寫一律清除
        this.histRcd.Hist.CC = this.histRcd.Hist.CC.replace(this.toccNotFilled, '');
      }
      this.histRcd.Hist.CC = toccMessage + '\n' + (this.histRcd.Hist.CC??'');
      this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.CC, { Name: this.histRcd.Hist.CC },false, null));
      this.keepToccMessage = toccMessage;
    }
  }

  async onTOCCUploadClick() {
    if (this.isEnabledTOCC && this.histRcd.Register.StatusCode == RegisterConst.Code_Status_Finish) {
      if (!this.dengueRecordTime) await this.getLastRecordTime();
      var showRecordTime = '上次上傳時間: ' +
        (this.dengueRecordTime ?
          DateHelper.getROCFullDateString(new Date(this.dengueRecordTime), '/') + ' ' + DateHelper.getTimeString(this.dengueRecordTime, ':', true) :
          '無');
      var showHint = this.dengueRecordTime ? '' : '(必須執行上傳)';
      var showColor = this.dengueRecordTime ? '' : 'red';
      var ret = await this.userConfirmService.showConfirm({
        title: 'TOCC重新上傳',
        msg: '是否確定重新上傳患者就醫紀錄?',
        textYes: '確定',
        textNo: '取消',
        fitContent: true,
        template: this.toccUploadMessage,
        templateData: { Color: showColor, Message: showRecordTime, Hint: showHint }
      });

      if (ret) {
        var result: boolean;
        [result, this.dengueRecordTime, this.dengueFormDto, this.dengueFlag] = await this.toccService.uploadDengue(
          this.histService.DxChanged(this.originHistRcd, this.histRcd),
          true,
          this.newDengueUploadDto(),
          this.dengueFormDto,
          this.dengueFlag,
          this.dengueResult);
      }
    }
  }

  newDengueUploadDto() {
    var dengueUploadDto = new DengueUploadDto();
    dengueUploadDto.HistoryId = this.histRcd.Hist.Id;
    dengueUploadDto.PatientNo = this.histRcd.Patient.PatientNo;
    dengueUploadDto.PatientId = this.histRcd.Patient.Id;
    dengueUploadDto.Name = this.histRcd.Patient.CName;
    dengueUploadDto.PersonalIdNumber = this.histRcd.Patient.CId;
    dengueUploadDto.RegDate = this.histRcd.Register.RegDate;
    dengueUploadDto.EditTime = this.histRcd.Hist.EditTime;
    dengueUploadDto.Dx1 = this.histRcd.Hist.Dx1;
    dengueUploadDto.Dx2 = this.histRcd.Hist.Dx2;
    dengueUploadDto.Dx3 = this.histRcd.Hist.Dx3;
    dengueUploadDto.Dx4 = this.histRcd.Hist.Dx4;
    dengueUploadDto.Dx5 = this.histRcd.Hist.Dx5;
    dengueUploadDto.Dx6 = this.histRcd.Hist.Dx6;
    dengueUploadDto.Dx1Name = this.histRcd.Hist.Dx1Name;
    dengueUploadDto.Dx2Name = this.histRcd.Hist.Dx2Name;
    dengueUploadDto.Dx3Name = this.histRcd.Hist.Dx3Name;
    dengueUploadDto.Dx4Name = this.histRcd.Hist.Dx4Name;
    dengueUploadDto.Dx5Name = this.histRcd.Hist.Dx5Name;
    dengueUploadDto.Dx6Name = this.histRcd.Hist.Dx6Name;
    dengueUploadDto.Birthday = this.histRcd.Patient.Birthday;
    dengueUploadDto.MomCId = this.histRcd.Patient.MomCId;
    dengueUploadDto.City = this.histRcd.Patient.City;
    dengueUploadDto.Area = this.histRcd.Patient.Area;
    dengueUploadDto.Street = this.histRcd.Patient.Street;
    return dengueUploadDto;
  }

  async onICD10SettingClick() {
    var msg = 'ICD-10 2023 啟用日期: ' + DateHelper.getROCDateStringWithSepetator(DateHelper.createDate(this.diagnosisICD102023StartDate)) + '\n' +
      'ICD-10 2023 強制使用: ' + (this.diagnosisICD102023ForceUse ? '是' : '否') + '\n\n' +
      (this.firstSameTreatDate ? '第一次看診日: ' : '看診日期: ') +
      DateHelper.getROCDateStringWithSepetator(DateHelper.createDate(this.registerDate)) + '\n' +
      '判斷使用: ICD-10 ' + this.icd10Version;
    await this.userConfirmService.showAlert('ICD-10 2023 設定', msg);
  }

  /** 當ICD10 2023啟用時，如慢箋領藥，需要取得第一次開慢箋的日期，來判斷可以輸入哪個版本的ICD10。 */
  async getFirstSameTreatDate() {
    //如果是強制使用也就不用跑這段
    //用當天日期先判斷ICD10 2023有沒啟用, 沒啟用就不用跑這段
    if (this.diagnosisICD102023ForceUse || this.histService.ICD10Version() === '2014') return null;

    //此段來自批價一樣的呼叫
    var sameTreat = this.histRcd.Register.SameTreat;
    if (sameTreat == '1' || sameTreat == '4' || sameTreat == '0' || sameTreat == '8' || sameTreat == '3') {
      var isSpecial = sameTreat == '0' ? !this.histRcd.Patient.FirstDialysisDate : (this.isRecoveryHome || this.isHomeCare);
      var rxt = await this.pricingApi.GetCourseTreatmentData(this.histRcd.Patient.Id,
        this.histRcd.Register.Icno, this.histRcd.Register.RegDate, sameTreat, this.histRcd.Hist?.Id, isSpecial);

      return rxt.DateStart;
    }

    return null;
  }

}
