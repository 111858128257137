import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DengueFormDto, DengueUploadDto, ToccApi } from 'src/app/services/api-service/hist/tocc-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { StartUpService } from 'src/app/services/start-up-service';
import { ToccService } from 'src/app/services/tocc-service.service';
import { UserCache } from 'src/app/services/user-cache';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { Clinic } from 'src/app/system/clinic/models/clinic';

@Component({
  selector: 'app-tocc-batch-upload',
  templateUrl: './tocc-batch-upload.component.html',
  styleUrls: ['./tocc-batch-upload.component.css']
})
export class ToccBatchUploadComponent implements OnInit {

  @ViewChild('toccUploadMessage') private toccUploadMessage: TemplateRef<any>;

  queryFormGroup: FormGroup;
  gridView = { data: [], total: 0 };
  pageSize: number = 0; //BaseConfig.getConfig().ui.pageSize; //不要分頁
  pageSkipNo = 0;
  orderBy = '';
  orderDesc = false;
  listItems: DengueUploadDto[];
  selectedItems: number[] = [];
  itemCount: number = 0;
  selectCount: number = 0;
  isEnabledTOCC: boolean = false;
  cln: Clinic;
  batchUploadColor: string = '';

  constructor(
    private fb: FormBuilder,
    private notification: EasyNotificationService,
    private userConfirmService: UserConfirmService,
    private startUp: StartUpService,
    private toccApi: ToccApi,
    private toccService: ToccService
  ) { }

  async ngOnInit(): Promise<void> {
    this.cln = UserCache.getLoginUser().Clinic;
    this.isEnabledTOCC = await this.toccService.isEnabledTOCC(this.startUp.vpnState);
    this.createQueryFormGroup();
    if (!this.isEnabledTOCC) this.notification.showInfo('沒有啟用TOCC功能，只能查詢，不能執行上傳。');
  }

  createQueryFormGroup() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.queryFormGroup = this.fb.group({
      startDate: [today],
      endDate: [today],
      toccUploadStatus: ['N'],
    });
  }

  async onBtnQueryClick() {
    this.refreshList(true);
  }

  pageChange(page: number): void {
    this.pageSkipNo = (page - 1) * this.pageSize;
    this.refreshList();
  }

  async refreshList(isNewQuery?: boolean) {
    if (isNewQuery) {
      this.pageSkipNo = 0;
      this.selectedItems = [];
      this.selectCount = 0;
      this.itemCount = 0;
    }

    const query = {
      startDate: DateHelper.getFormatedDateString(this.queryFormGroup.controls['startDate'].value, false),
      endDate: DateHelper.getFormatedDateString(this.queryFormGroup.controls['endDate'].value, false),
      toccUploadStatus: this.queryFormGroup.controls['toccUploadStatus'].value,
      pageSize: this.pageSize,
      pageNumber: this.pageSize > 0 ? (this.pageSkipNo / this.pageSize) + 1 : 1,
      orderBy: this.orderBy,
      orderDesc: this.orderDesc
    };

    try {
      var result = await this.toccApi.GetUploadList(query);
      this.listItems = result.data;
      this.itemCount = result.data.length;
      this.batchUploadColor = this.listItems.some(x => x.RecordReturnCode !== 200) ? 'red' : '';
      this.gridView = {
        data: result.data,
        total: result.total,
      };
    } catch (e) {
      this.notification.showGetDataFailed('tocc-batch-upload:refreshList');
    }
  }

  isAllChecked() {
    return !this.listItems?.some(x=>!x.SelectItem);
  }

  isSomeChecked() {
    return !this.isAllChecked() && this.listItems?.some(x=>x.SelectItem);
  }

  selectItemCheckChanged(evt: any) {
    this.selectCount = this.listItems.filter(x => x.SelectItem === true).length;
  }

  async onBtnUpload(dengueUploadDto: DengueUploadDto) {
    if (!this.isEnabledTOCC) return;

    //此段程式與record.component.ts的onBtnUpload相同，如有調整請同步修改
    var dengueRecordTime = dengueUploadDto.RecordTime;
    var showRecordTime = '上次上傳時間: ' +
      (dengueRecordTime ?
        DateHelper.getROCFullDateString(new Date(dengueRecordTime), '/') + ' ' + DateHelper.getTimeString(dengueRecordTime, ':', true) :
        '無');
    var showHint = dengueRecordTime ? '' : '(必須執行上傳)';
    var showColor = dengueRecordTime ? '' : 'red';
    var ret = await this.userConfirmService.showConfirm({
      title: 'TOCC重新上傳',
      msg: '是否確定重新上傳患者就醫紀錄?',
      textYes: '確定',
      textNo: '取消',
      fitContent: true,
      template: this.toccUploadMessage,
      templateData: { Color: showColor, Message: showRecordTime, Hint: showHint }
    });

    if (!ret) return;

    await this.toccService.uploadDengue(false, true, dengueUploadDto, new DengueFormDto(), '', '');

    this.refreshList(true);

    this.selectedItems = [dengueUploadDto.Id];
  }

  onBtnSelectNeed() {
    this.listItems.forEach(x => x.SelectItem = x.RecordReturnCode !== 200);
    this.selectCount = this.listItems.filter(x => x.SelectItem === true).length;
    if (!this.selectCount) this.notification.showInfo('沒有未上傳的資料');
  }

  async onBtnBatchUpload() {
    if (!this.isEnabledTOCC) return;

    let runItems = this.listItems.filter(x => x.SelectItem);

    if (runItems.length > 0) {
      var ret = await this.userConfirmService.showConfirm({
        title: 'TOCC批次上傳',
        msg: '是否確定批次上傳患者就醫紀錄? \n共' + runItems.length + '筆',
        textYes: '確定',
        textNo: '取消',
        fitContent: true
      });

      if (!ret) return;

      //照順序逐個上傳，若遇到一個失敗，就立即停止，但前面的上傳會成功。
      let allSuccess = true;
      var cf = await this.userConfirmService.showWaiter('執行上傳', '', {width:750});
      cf.msg+=`\n執行中`;
      for (let i in runItems) {
        let dengueUploadDto = runItems[i];
        this.selectedItems = [dengueUploadDto.Id];
        if (dengueUploadDto.SelectItem) {
          cf.msg+=`\n執行 ${dengueUploadDto.Name}`;
        }
        let [result] = await this.toccService.uploadDengue(false, true, dengueUploadDto, new DengueFormDto(), '', '');
        if (!result) {
          this.refreshList(true);
          this.selectedItems = [dengueUploadDto.Id];
          allSuccess = false;
          cf.msg+=`\n上傳失敗 ${dengueUploadDto.Name}`;
          this.notification.showError('上傳失敗\n' + dengueUploadDto.Name);
          break;
        } else {
          cf.msg+=`->完成`;
        }
      }
      cf.disableYes = false;
      cf.wait='done';
      if (allSuccess) {
        this.refreshList(true);
        this.selectedItems = [runItems[0].Id];
        this.notification.showSuccess('上傳成功');
      }
    } else {
      this.notification.showInfo('請先勾選資料');
    }
  }

}
