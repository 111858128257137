
import { InoculateData } from './InoculateData'; 
/**
 * 預防接種
 */ 
export class Inoculate {
    /**
     * 預防接種資料(40筆)
     */ 
    public Data: InoculateData[] = [];
}
