import { ClinicDataService } from '../../../../services/data-service/clinic-data-service';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReportComponent } from '../../../../system/report/components/report-component';
import { ReportComponentService } from '../../../../system/report/components/report-component.service';
import { HistApi, MedicationWayReportViewModel } from 'src/app/services/api-service/hist/hist-api';

@Component({
  selector: 'app-medication-way-report',
  templateUrl: './medication-way-report.component.html',
  styleUrls: ['./medication-way-report.component.css']
})
export class MedicationWayReportComponent implements ReportComponent, OnInit {
  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  _regId: number;
  @Input()
  get regId() {
    return this._regId
  };
  set regId(val: number) {
    this._regId = val;
    this.getData(val);
  }

  @Input()
  fontFamily: string = "細明體";

  @Output()
  getDataFinished: EventEmitter<void> = new EventEmitter();

  medicationWayReportViewModel: MedicationWayReportViewModel;

  constructor(private reportComponentService: ReportComponentService,
    private histApiService: HistApi,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getData(this.regId);
  }
  async setRegId(regId: number) {
    this.medicationWayReportViewModel = await this.histApiService.GenerateMedicationWayReport(regId);
    this.cdr.markForCheck();
  }
  async getData(regId: number) {
    this.medicationWayReportViewModel = await this.histApiService.GenerateMedicationWayReport(regId);
    this.getDataFinished.emit();
  }

  getHtml(): string {
    let cssText = this.reportComponentService.getAllCss(this.reportContainer.nativeElement);

    let style = `
      <style>
        ${cssText}

        @page {
          size: '20.6cm 9cm;';
          margin: 0cm;
          padding: 0cm;
        }
      </style>
    `;

    var html = this.reportContainer.nativeElement.innerHTML;

    let fullHtml = `
    <html>
      <head>${style}</head>
      <body style="margin: 0cm;padding:0cm">${html}</body>
    </html>`;

    return fullHtml;
  }

}


