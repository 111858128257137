import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/security/services/authentication.service';
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { SessionService } from 'src/app/services/session.service';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';

@Component({
  selector: 'app-abnormal-ic',
  templateUrl: './abnormal-ic.component.html',
  styleUrls: ['./abnormal-ic.component.css']
})
export class AbnormalICComponent implements OnInit {

  unGotSerial : boolean = true;
  isGotSerial : boolean = false;
  selectedValue : string;

  @Input() AbnormalStatus : string;
  @Output() statusChanged : EventEmitter<string> = new EventEmitter();
  @Output() closeDialog  = new EventEmitter(); // close dialog only
  @Output() onEmitResult : EventEmitter<string> = new EventEmitter();

  constructor(private session: SessionService,
    private auth: AuthenticationService,
    private api: SystemcodeApi,
    private clinicDataService:ClinicDataService
    ) { }

  isGetSerialSystemCodeList: ValueTextPair[] = [];
  isGetSerialSystemCodeList1: ValueTextPair[] = [];
  isGetSerialSystemCodeList2: ValueTextPair[] = [];
  unGetSerialSystemCodeList: ValueTextPair[] = [];
  unGetSerialSystemCodeList1: ValueTextPair[] = [];
  unGetSerialSystemCodeList2: ValueTextPair[] = [];
  exceptionSystemCodeList: CheckModel[] = [];

  async ngOnInit(){

    this.AbnormalStatus = this.session.getAbnormalIC() || '';
    await this.initList();


  }

  reload(v: string) {
    this.isGetSerialSystemCodeList = [];
    this.isGetSerialSystemCodeList1 = [];
    this.isGetSerialSystemCodeList2 = [];
    this.unGetSerialSystemCodeList = [];
    this.unGetSerialSystemCodeList = [];
    this.unGetSerialSystemCodeList2 = [];
    this.exceptionSystemCodeList = [];
    this.AbnormalStatus = v;
    this.session.setAbnormalIC(this.AbnormalStatus);
    this.ngOnInit();
  }

  async initList() {
    var data = await this.clinicDataService.getSystemCodes(['N0020','C0240'])
    var excetionData = data['C0240'].map(x=>{
      return {
      text:x.text,
      value:x.value,
      extension:x.extension,
      checked:false
    }});
    var cN0020 = data['N0020'];
    cN0020.forEach(item=>{
      if (item.extension != null) {
        this.isGetSerialSystemCodeList.push(item);
      }
      if (item.value != 'C001') {
        this.unGetSerialSystemCodeList.push(item);
      }
      excetionData.forEach(exItem => {
        if (exItem.extension == item.value) {
          exItem.checked = false;
          this.exceptionSystemCodeList.push(exItem);
        }
      });
    })


    // 排成直的
    let halfLength1 = Math.ceil(this.isGetSerialSystemCodeList.length / 2);
    var i = 0;
    this.isGetSerialSystemCodeList.forEach(d => {
      i++;
      if (i <= halfLength1) {
        this.isGetSerialSystemCodeList1.push(d);
      } else {
        this.isGetSerialSystemCodeList2.push(d);
      }
    });
    this.isGetSerialSystemCodeList = [];
    for (var k = 0; k < halfLength1; k++) {
      this.isGetSerialSystemCodeList.push(this.isGetSerialSystemCodeList1[k]);
      if (this.isGetSerialSystemCodeList2[k] !== undefined) {
        this.isGetSerialSystemCodeList.push(this.isGetSerialSystemCodeList2[k]);
      }
    }

    let halfLength2 = Math.ceil(this.unGetSerialSystemCodeList.length / 2);
    var j = 0;
    this.unGetSerialSystemCodeList.forEach(d => {
      j++;
      if (j <= halfLength2) {
        this.unGetSerialSystemCodeList1.push(d);
      } else {
        this.unGetSerialSystemCodeList2.push(d);
      }
    });
    this.unGetSerialSystemCodeList = [];

    for (var l = 0; l < halfLength2; l++) {
      this.unGetSerialSystemCodeList.push(this.unGetSerialSystemCodeList1[l]);
      if (this.unGetSerialSystemCodeList2[l] !== undefined) {
        this.unGetSerialSystemCodeList.push(this.unGetSerialSystemCodeList2[l]);
      }
    }
  }

  onGotSerial(evt: any): void {
    this.isGotSerial = true ;
    this.unGotSerial = false ;
  }

  onUnGotSerial(evt: any): void {
    this.isGotSerial = false ;
    this.unGotSerial = true ;
  }

  btnOkClick(): void {
    //  this.statusChanged.emit(this.AbnormalStatus);
     var data: string[] = ['A000', 'A001', 'A010', 'A011', 'A020', 'A021', 'A030', 'A031', 'C000', 'D000', 'D010', 'D001', 'D011', 'E000', 'G000'];
     if (data.findIndex(x => x == this.AbnormalStatus) >= 0) {
      this.session.setAbnormalIC(this.AbnormalStatus);
     } else {
      this.session.setAbnormalIC('');
     }
     this.onEmitResult.emit(this.AbnormalStatus)
  }

  btnCancelClick(): void {
    var data: string[] = ['A000', 'A001', 'A010', 'A011', 'A020', 'A021', 'A030', 'A031', 'C000', 'D000', 'D010', 'D001', 'D011', 'E000', 'G000'];
    if (data.findIndex(x => x == this.AbnormalStatus) < 0) {
      this.AbnormalStatus = "";
      this.selectedValue = null;
    }
    this.closeDialog.emit() ;
  }

  btnClearClick() : void {
    this.AbnormalStatus = "";
    this.selectedValue = null;
    this.session.setAbnormalIC(this.AbnormalStatus);
    this.closeDialog.emit() ;
  }

  radioChange(evt: any): void {
    this.AbnormalStatus = evt.value;
    console.log('radioChange',evt.value)
    this.statusChanged.emit(this.AbnormalStatus);
  }
}


interface CheckModel
{
  text:string,
  value:string,
  extension:string,
  checked:boolean
}
