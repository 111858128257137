import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PaymentMaster } from 'src/app/payments/models/payment-master';
import NP from 'number-precision';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-registers-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder) {
    if (this.formGroup === undefined) {
      this.makeFormGroup();
    }
  }
  private unsubscribe: Subject<void> = new Subject();

  formGroup: FormGroup;
  _payment: PaymentMaster;
  @Input()
  set payment(payment: PaymentMaster) {
    if (payment !== undefined) {
      for (const p in payment) {
        if (payment[p] === 0) {
          payment[p] = '';
        }
      }
      this._payment = payment;
      if (this.formGroup === undefined) {
        this.makeFormGroup();
      }
      this.formGroup.patchValue(this._payment);
    }
  }
  get payment() {
    return this._payment;
  }

  _isOpen = false;
  @Input()
  set isOpen(val: boolean) {
    this._isOpen = val;
    if (this._isOpen) {
      setTimeout(this.focusCashTake, 300);
    }
  }
  get isOpen(): boolean {
    return this._isOpen;
  }
  @Input()
  availablePayWay: string[];
  @Output()
  Save = new EventEmitter<any>();
  @Output()
  Close = new EventEmitter();
  validMsgId = '';

  ngOnInit() {
  }

  focusCashTake() {
    const elem = document.getElementById('register_payment_cashTake');
    if (elem) {
      elem.focus();
    }
  }

  makeFormGroup() {
    this.formGroup = this.fb.group({
      Payable: [''],
      Paid: [''],
      PaidCreditCard: [''],
      PaidCashCard: [''],
      PaidMedCard: [''],
      PaidOther: [''],
      PaidOtherNote: [''],
      PaidCash: [''],
      CashTake: [''],
      CashChange: [''],
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(
      data => {
        this.calculate(data);
      }
    );
  }

  calculate(editFV: any) {
    // 應收
    const payable = parseFloat(editFV.Payable) || 0;
    // 信用卡收 (以下幾個文字欄位 轉為數字後回存)
    const payCreditCard = parseFloat(editFV.PaidCreditCard) || 0;
    // 現金卡收
    const payCashCard = parseFloat(editFV.PaidCashCard) || 0;
    // 醫保卡收
    const payMedCard = parseFloat(editFV.PaidMedCard) || 0;
    // 其他收
    const payOther = parseFloat(editFV.PaidOther) || 0;
    // 刷卡總收
    const fromCard = payCreditCard + payCashCard + payMedCard + payOther;
    // 剩餘需付現
    let payCash = payable - fromCard - payOther;
    payCash = payCash < 0 ? 0 : payCash;
    // 現金收
    const cashTake = parseFloat(editFV.CashTake) || 0;
    // 已付金額
    let paid = fromCard + payOther + cashTake;
    let cashChange = 0;
    // 刷卡超出
    if (fromCard > payable) {
      payCash = payable;
      paid = 0;
      cashChange = 0;
    } else {
      // 如果應收 < 已付, 計算找零
      if (payable <= paid) {
        const needChange = NP.minus(paid, payable);
        paid = payable;
        cashChange = needChange;
      }
    }
    const updateValue = {
      PaidCash: payCash,
      Paid: paid,
      CashChange: cashChange
    };
    this.formGroup.patchValue(updateValue, { emitEvent: false });
    this.validMsgId = '';
  }

  IsCardOverPay() {
    const editFV = this.formGroup.value;
    // 應收
    const payable = parseFloat(editFV.Payable) || 0;
    // 信用卡收
    const payCreditCard = parseFloat(editFV.PaidCreditCard) || 0;
    // 現金卡收
    const payCashCard = parseFloat(editFV.PaidCashCard) || 0;
    // 醫保卡收
    const payMedCard = parseFloat(editFV.PaidMedCard) || 0;
    // 其他收
    const payOther = parseFloat(editFV.PaidOther) || 0;
    // 剩餘需付現
    const payCash = payable - payCreditCard - payCashCard - payMedCard - payOther;
    return payCash < 0;
  }

  validate(): boolean {
    const formValue = this.formGroup.value;
    this.calculate(formValue);
    this.validMsgId = '';
    const payable = parseFloat(formValue.Payable);
    const paid = parseFloat(formValue.Paid);
    if (this.IsCardOverPay()) {
      this.validMsgId = '3';
      return false;
    } else if (paid < payable) {
      this.validMsgId = '1';
      return false;
    } else if (paid > payable) {
      this.validMsgId = '2';
      return false;
    }

    return true;
  }
  IsPayWayActive(way: string): boolean {
    return this.availablePayWay.indexOf(way) >= 0;
  }
  onBtnChargeClick() {
    if (this.formGroup.valid && this.validate()) {
      this.Save.emit(this.formGroup.getRawValue());
    }
  }
  onCloseClick() {
    this.Close.emit();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
