import { Component, OnInit, Input, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VitalSign } from '../../services/api-service/hist/vital-sign';
import { HelperId } from '../models/helper-id';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HistService } from '../services/hist.service';
import { AgeYearMonthDiff } from 'src/app/shared/utilities';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { ChildGrowDataChartService, ChildGrowDataType, PIndex } from 'src/app/services/child-grow-chart-service';
import { PageData2 } from 'src/app/shared/models/page-data';
import { HotKeyService } from 'src/app/services/hotkey-service';
import { appKeydownCode } from 'src/app/shared/directives/keydown-to.directive';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hist-vital-sign',
  templateUrl: './vital-sign.component.html',
  styleUrls: ['./vital-sign.component.css', '../styles/hist.css']
})
export class VitalSignComponent implements OnInit, OnDestroy {

  @ViewChild('childGrowTmp')
  childGrowTmp: TemplateRef<any>;
  isShowGrowChartIcon = true;
  constructor(private fb: FormBuilder,
    private userConfirmService: UserConfirmService,
    private childGrowService: ChildGrowDataChartService,
    private paraApi: ParameterApi,
    private histApi: HistApi,
    private histService: HistService,
    private hotkeyService: HotKeyService,
    private clinicDataService: ClinicDataService
  ) {

  }

  areaId = HelperId.VSArea;
  heightId = HelperId.VS + '_ht';
  weightId = HelperId.VS + '_wt';
  btId = HelperId.VS + '_bt';
  headId = HelperId.VS + '_hc';
  sbpId = HelperId.VS + '_sbp';
  dbpId = HelperId.VS + '_dbp';
  pulseId = HelperId.VS + '_pls';
  lmpId = HelperId.VS + '_lmp';
  eddId = HelperId.VS + '_edd';

  isShowHTWT: boolean = false;
  isShowBT: boolean = false;
  isShowHC: boolean = false;
  isShowBP: boolean = false;
  isShowLMP: boolean = false;
  isShowEDD: boolean = false;
  isTodayVT = false;
  //#region 參數 main --------------------
  editFG: FormGroup;

  @Input() isRegister?: boolean = false;

  get editFV() {
    // 請注意:原本用this.editFG.value=>但是量測日期是disabled會抓不到，先改用getRawValue()
    // cyy表示有FormHelper可以用，後續維護者可以改用
    var data = this.editFG.getRawValue();
    data.VSDateTime = DateHelper.formatDateTime(data.VSDateTime); //注意時間傳回後端可能有時區問題，因此先toString()
    return data;
  }
  //#endregion

  //#region 參數 input ui --------------------
  initVS: VitalSign = new VitalSign();

  @Input()
  hasUploaded: boolean = false;

  @Input() isHelperPadOpened?: string = '';

  @Input()
  set opdRecord(rcd: VitalSign) {
    this.initVS = rcd;
    if (!this.initVS.LMPDate) {
      this.initVS.LMPDate = null;
    }
    if (!this.initVS.DueDate) {
      this.initVS.DueDate = null;
    }
    this.fillFormData(this.initVS);
  }

  /** 顯示開關 */
  @Input() showVitalSignPanel: boolean = true;
  //@Input()
  displayOrder: { code: string, width: number }[][] = [];
  nowAge: number;
  //#endregion
  subs:Subscription;
  async ngOnInit() {
    var histParams = await this.clinicDataService.getParam("HST001");
    this.displayOrder = histParams.VitalSignDisplay.split(';')
      .map(x => x.split(',')
        .map((s, i, a) => {
          return {
            code: s,
            width: 1 / a.length
          }
        }));
    this.editFG = this.makeFormGroup();
    this.fillFormData(this.initVS);
    this.isShowHTWT = this.isShowVSOption('HTWT');
    this.isShowBT = this.isShowVSOption('BT');
    this.isShowHC = this.isShowVSOption('HC') && this.isShowGrowChartIcon;
    this.isShowBP = this.isShowVSOption('BP');
    this.isShowLMP = this.initVS.isFemale ? this.isShowVSOption('LMP') : false;
    this.isShowEDD = this.initVS.isFemale ? this.isShowVSOption('EDD') : false;

    // this.isShowHTWT = true;
    // this.isShowBT = true;
    // this.isShowHC = true;
    // this.isShowBP = true;
    // this.isShowLMP = true;
    // this.isShowEDD = true;

    if (new Date(this.editFG.value.VSDateTime).toLocaleDateString() !==
      new Date(this.histService.currentHist.Register.RegDate).toLocaleDateString()
      || this.hasUploaded) {  // 如果已申報，全部欄位設為disable
      this.editFG.disable();
    } else {
      if (new Date(this.editFG.value.VSDateTime).toLocaleDateString() === new Date(this.editFG.value.EditTime).toLocaleDateString()) {
        this.isTodayVT = true;
      }
      this.editFG.controls.VSDateTime.disable();
    }

    if (this.initVS.birthday) {
      const today = new Date();
      const birthday = new Date(this.initVS.birthday);
      this.nowAge = today.getFullYear() - birthday.getFullYear();
    }
    this.subs = this.histService.onGetVT.subscribe(fn=>fn(this.editFV));
    this.add();
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  // #region form --------------------
  // 產生表單元件，並定義基本檢核規則
  makeFormGroup(): FormGroup {
    const fg: FormGroup = this.fb.group({
      Id: [''],
      PatientId: [''],
      VSDateTime: [null],
      Height: [''],
      Weight: [''],
      BT: [''],
      Head: [''],
      Pulse: [''],
      SBP: [''],
      DBP: [''],
      LMPDate: [null],
      DueDate: [null],
      IsPregnant: [false],
    });
    return fg;
  }
  // 填入表單資料
  fillFormData(rcd: VitalSign) {
    if (!this.editFG) { return; }
    this.editFG.patchValue(rcd);
    this.editFG.patchValue({
      VSDateTime: new Date(rcd.VSDateTime) || null,
    });
    this.editFG.patchValue({
      Height: rcd.Height == 0 ? null : rcd.Height,
      Weight: rcd.Weight == 0 ? null : rcd.Weight,
      BT: rcd.BT == 0 ? null : rcd.BT,
      Head: rcd.Head == 0 ? null : rcd.Head,
      Pulse: rcd.Pulse == 0 ? null : rcd.Pulse,
      SBP: rcd.SBP == 0 ? null : rcd.SBP,
      DBP: rcd.DBP == 0 ? null : rcd.DBP,
      LMPDate: rcd.LMPDate == null ? null : DateHelper.createDate(rcd.LMPDate),
      DueDate: rcd.DueDate == null ? null : DateHelper.createDate(rcd.DueDate)
    });
    var age = AgeYearMonthDiff(rcd.birthday, new Date());
    this.isShowGrowChartIcon = age.year * 12 + age.month <= 60;
  }
  // #endregion

  isShowVSOption(code: string): boolean {
    if (this.displayOrder && this.displayOrder.some(x => x.some(a => a.code == code))) {
      return true;
    } else {
      return false;
    }
  }
  pregnantChange(evt: MatCheckboxChange) {
    if (evt.checked) {
      if (this.editFG.controls.LMPDate.value && !this.editFG.controls.DueDate.value) {
        var d = this.editFG.controls.LMPDate.value as Date;
        this.editFG.controls.DueDate.setValue(new Date(d.getFullYear(), d.getMonth(), d.getDate() + 280));
      }
    } else {
      this.editFG.controls.DueDate.setValue(null);
    }
  }
  getFullMonthAge() {
    var age = AgeYearMonthDiff(this.initVS.birthday, new Date());
    return age.year * 12 + age.month;
  }
  async openChildGrow(type: ChildGrowDataType) {
    var ret = await this.userConfirmService.showConfirm({
      msg: '',
      hideYes: true,
      hideNo: true,
      fitContent: true,
      template: this.childGrowTmp,
      templateData: {
        sex: this.initVS.isFemale ? 'Girl' : 'Boy',
        tp: type,
        month: this.getFullMonthAge(),
        value: parseFloat(type == 'Height' ? this.editFV.Height :
          type == 'Weight' ? this.editFV.Weight :
            type == 'BMI' ? this.calcBMI() :
              type == 'Head' ? this.editFV.Head : '')
      }
    });

  }
  BMIVal: string;
  @Input() outRmOpen?: boolean;
  calcBMI() {
    if (!this.editFV.Weight || !this.editFV.Height) {
      return '---'
    }
    this.BMIVal = (Math.round(this.editFV.Weight / Math.pow(this.editFV.Height / 100, 2) * 10) / 10).toFixed(2);
    return (Math.round(this.editFV.Weight / Math.pow(this.editFV.Height / 100, 2) * 10) / 10).toFixed(2);
  }
  notifyClass(type) {
    var value = parseFloat(type == 'Height' ? this.editFV.Height :
      type == 'Weight' ? this.editFV.Weight :
        type == 'BMI' ? this.calcBMI() :
          type == 'Head' ? this.editFV.Head : ''
    );
    var month = this.getFullMonthAge();
    if (value && month <= 60) {
      var ret = this.childGrowService.validate(this.initVS.isFemale ? 'Girl' : 'Boy', type, month, value)
      if (ret > PIndex.P97 || (ret as number) == -1) {
        return 'too-high Child'
      }
      if (ret < PIndex.P3) {
        return 'too-low Child'
      }
      return '';
    } else {
      if (type == 'BMI') {
        var bmi = parseFloat(this.calcBMI());
        if (!bmi) {
          return;
        }
        if (bmi >= 35) {
          return 'too-high BMI';
        } else if (bmi >= 24) {
          return 'high BMI';
        } else if (bmi < 18.5) {
          return 'too-low BMI';
        }
        else {
          return 'normal BMI';
        }
      }
      if (type == 'BT') {
        var bt = parseFloat(this.editFV.BT);
        if (!bt) {
          return;
        }
        if (bt > 37.5 && bt < 38) {
          return 'high BT';
        } else if (bt >= 38) {
          return 'too-high BT';
        } else {
          return 'normal BT';
        }
      }
      if (type == 'SBP') {

        var BP1 = parseFloat(this.editFV.SBP);
        if (!BP1) {
          return;
        }
        if (BP1 < 90) {
          return 'too-low BP';
        } else if (BP1 > 120 && BP1 < 140) {
          return 'high BP';
        } else if (BP1 >= 140) {
          return 'too-high BP';
        }
      }

      if (type == 'DBP') {
        var BP2 = parseFloat(this.editFV.DBP);
        if (!BP2) {
          return;
        }
        if (BP2 < 60) {
          return 'too-low BP';
        } else if (BP2 > 80 && BP2 < 90) {
          return 'high BP';
        } else if (BP2 >= 90) {
          return 'too-high BP';
        }
      }
      if (type == 'Pulse') {
        var ps = parseFloat(this.editFV.Pulse);
        if (!ps) {
          return;
        }
        if (ps > 100) {
          return 'too-high ps';
        } else if (ps > 85) {
          return 'high ps';
        } else if (ps < 60) {
          return 'too-low ps';
        }
      }
    }
  }
  @ViewChild('vtHist')
  vtHist: TemplateRef<any>
  vtPage = 1;
  vtPageSkip = 0;
  vtPageSize = 5;
  vtRecords: PageData2<VitalSign>;
  async viewVTDetail() {
    this.vtRecords = await this.histApi.getVitalSign(this.initVS.PatientId, this.vtPageSize, this.vtPageSkip);
    this.userConfirmService.showAlert('過往紀錄', '', {
      template: this.vtHist,
      templateData: this.vtRecords,
      width: 600
    })
  }
  async vtDetailPageChange(page: number) {
    this.vtPage = page;
    this.vtPageSkip = (page - 1) * this.vtPageSize;
    this.vtRecords = await this.histApi.getVitalSign(this.initVS.PatientId, this.vtPageSize, this.vtPageSkip);
  }
  public add() {
    this.editFG.patchValue({
      VSDateTime: this.histService.currentHist.Register.RegDate
    });
    this.editFG.enable();
    this.editFG.controls.VSDateTime.disable();
  }
  public revert() {
    this.editFG.patchValue(this.initVS);
    this.editFG.disable();
  }
  nextCodes(row: number, col: number): string {
    var nextAll: string[] = []
    for (var i = row; i < this.displayOrder.length; i++) {
      var cRow = this.displayOrder[i];
      var colIdx = i == row ? col + 1 : 0;
      for (var j = colIdx; j < cRow.length; j++) {
        if (cRow[j].code == 'BP') {
          nextAll.push('SBP');
        } else {
          nextAll.push(cRow[j].code)
        }
      }
    }
    return nextAll.join(',');
  }
  /** 取得位置之前的所有ColCode appKeyDown會自動跳過找不到的 */
  preCodes(row: number, col: number): string {
    var prevAll: string[] = []
    for (var i = row; i >= 0; i--) {
      var cRow = this.displayOrder[i];
      var lastColIdx = i == row ? col - 1 : cRow.length - 1;
      for (var j = lastColIdx; j >= 0; j--) {
        if (cRow[j].code == 'BP') {
          prevAll.push('Pulse');
        } else {
          prevAll.push(cRow[j].code)
        }
      }
    }
    return prevAll.join(',');
  }
  /** Enter的時候如果是最後一個欄位，會Focus回去 */
  focusBack(key: appKeydownCode, row: number, col: number) {
    if (key == 'Enter' && row == this.displayOrder.length - 1 && col == this.displayOrder[row].length - 1) {
      setTimeout(() => {
        this.hotkeyService.triggerFnKey('F8');
      }, 0);

      return;
    }
    // if((key == 'Left'|| key=='Up') && row==0 && col == 0){
    //   this.hotkeyService.triggerFnKey('F8');
    //   return;
    // }
  }
}
