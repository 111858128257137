import { TreatmentCode } from './TreatmentCode';
import { TreatmentFee } from './TreatmentFee';

import { PatientBaseInput } from './PatientBaseInput';
import { GetSeqNumber256 } from './GetSeqNumber256';
import { TreatmentData } from './TreatmentData';
import { PrescriptionSignWrite } from './PrescriptionSignWrite';
import { PrescriptionSignMultiWrite } from './PrescriptionSignMultiWrite';
import { CSHISResultWithData } from './CSHISResultWithData';
import { BasicData } from './BasicData';
import { RegisterBasic } from './RegisterBasic';
import { TreatmentNoNeedHPC } from './TreatmentNoNeedHPC';
import { TreatmentNeedHPC } from './TreatmentNeedHPC';
import { Prescription } from './Prescription';
import { OrganDonate } from './OrganDonate';
import { LastSeqNum } from './LastSeqNum';
import { DocIdData } from './DocIdData';
import { CSHISResult } from './CSHISResult';
import { CriticalIllnessData } from './CriticalIllnessData';
import { SeqNumber256 } from './SeqNumber256';
import { PrescriptionMain } from './PrescriptionMain';
import { SeqNumber256N1 } from './SeqNumber256N1';

import { IVisionHIS } from './IVisionHis';
import { HcrService, VisionApiResult } from 'src/app/services/hcr-service.service';
import { DateHelper } from '../helpers/date-helper';
import { VisionHIS } from './VisionHIS';
import { HttpClient } from '@angular/common/http';
import { PrescriptionDetailOutPatient } from './PrescriptionDetailOutPatient';
import { PrescriptionDetailLongTerm } from './PrescriptionDetailLongTerm';
import { PrescriptionDetailHVE } from './PrescriptionDetailHVE';
import { RegisterBasic2 } from './RegisterBasic2';
import { RegisterPrevent } from './RegisterPrevent';
import { RegisterPreventSubset } from './RegisterPreventSubset';
import { TreatmentDataNoHPC } from './TreatmentDataNoHPC';
import { CriticalIllnesNoHPC } from './CriticalIllnesNoHPC';
import { TreatmentDataHPC } from './TreatmentDataHPC';
import { CriticalIllness } from './CriticalIllness';
import { RegisterPregnant } from './RegisterPregnant';
import { RegisterPregnantSubset } from './RegisterPregnantSubset';
import { Inoculate } from './Inoculate';
import { InoculateData } from './InoculateData';
import { InoculateDataWrite } from './InoculateDataWrite';
import { TreatNumNoICCard } from './TreatNumNoICCard';
import { MultiPrescriptWrite } from './MultiPrescriptWrite';
import { AllergicMedicines } from './AllergicMedicines';
import { AllergicNum } from './AllergicNum';
import { EmergentTelWrite } from './EmergentTelWrite';
import { HealthInsurance } from './HealthInsurance';
import { NewBorn } from './NewBorn';
import { OrganDonateWrite } from './OrganDonateWrite';
import { PredeliveryCheckup } from './PredeliveryCheckup';
import { OutPatientPrescriptionWrite } from './OutPatientPrescriptionWrite';
import { DownloadDataArg } from './DownloadDataArg';
import { UploadDataResult } from './UploadDataResult';
import { UploadDataPrecResult } from './UploadDataPrecResult';
import { CnhiEiiApiID } from './Download';
import { PrescriptionAllergic } from './PrescriptionAllergic';
import { PrescriptionHVE } from './PrescriptionHVE';
import { PrescriptionLongTerm } from './PrescriptionLongTerm';
import { HcaInitData, HCASignRequestData } from './HcaInitData';
import { EmergentTel } from './EmergentTel';
import { CumulativeFee } from './CumulativeFee';
import { CumulativeData } from './CumulativeData';
import { SeqNumber } from './SeqNumber';
import { VPNGetRandomXandSignX } from './VPNGetRandomXandSignX';
import { Cumulative } from './Cumulative';
/**
    *
    */
export class VisionHIS6 
//extends VisionHIS 
implements IVisionHIS {
    

    //#region 基本資料/掛號資料/就醫資料取得 (不需醫師卡)

    /** 讀取不需個人 PIN 碼之基本資料 */
    public async GetBasicData(): Promise<CSHISResultWithData<BasicData>> {
        var path = this.getWebApiPath('BasicData/Query')
        var ret = await this.CallWebApi<BasicData>(CsHis6BasicData, path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    /**掛號或報到時讀取基本資料 */
    public async GetRegisterBasic(): Promise<CSHISResultWithData<RegisterBasic>> {
        var path = this.getWebApiPath('BasicData/Register')
        var ret = await this.CallWebApi<RegisterBasic>(CsHis6RegisterBasic, path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    /**掛號或報到時讀取基本資料2 */
    public async GetRegisterBasic2(): Promise<CSHISResultWithData<RegisterBasic2>> {
        var path = this.getWebApiPath('BasicData/Register2')
        var ret = await this.CallWebApi<RegisterBasic2>(CsHis6RegisterBasic2 ,path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }

    public async GetEmergentTel(): Promise<CSHISResultWithData<EmergentTel>> {
        var path = this.getWebApiPath('EmergentTel/Query')
        var ret = await this.CallWebApi<EmergentTel>(CsHis6EmergentTel, path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    
    /** 取得預防接種資料 */
    public async GetInoculateData(): Promise<CSHISResultWithData<Inoculate>> {
        var path = this.getWebApiPath('Inoculate/Query')
        var ret = await this.CallWebApi<Inoculate>(CsHis6RegisterInoculate ,path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    /** API name VHChisGetQuickInsurence 用途： 取得民眾在保狀態查核時使用 */
    public async QuickInsurence(cTreatItem: string): Promise<CSHISResult> {
        var path = this.getWebApiPath('Insurance/Quick')
        var ret = await this.CallWebApi<void>(null, path, {
            treatmentItem:cTreatItem
        },'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }

    /** 讀取同意器官捐贈及安寧緩和醫療註記 */
    public async GetOrganDonate(): Promise<CSHISResultWithData<OrganDonate>> {
        var path = this.getWebApiPath('OrganDonate/Query')
        var ret = await this.CallWebApi<OrganDonate>(CsHis6OrganDonate, path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }

    /** 取得產檢資料 */
    public async GetRegisterPregnant(): Promise<CSHISResultWithData<RegisterPregnant>> {
        var path = this.getWebApiPath('PregnantData/Query')
        var ret = await this.CallWebApi<RegisterPregnant>(CsHis6RegisterPregnant ,path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    
    /** 取得預防保健資料 */
    public async GetRegisterPrevent(): Promise<CSHISResultWithData<RegisterPrevent>> {
        var path = this.getWebApiPath('PreventData/Query')
        var ret = await this.CallWebApi<RegisterPrevent>(CsHis6RegisterPrevent ,path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }

    /** 讀取就醫資料不需HPC卡的部分*/
    public async GetTreatmentNoNeedHPC(): Promise<CSHISResultWithData<TreatmentNoNeedHPC>> {
        var path = this.getWebApiPath('Treatment/NoNeedHPC')
        var ret = await this.CallWebApi<TreatmentNoNeedHPC>(CsHis6TreatmentNoNeedHPC ,path, {},'HC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }

    //#region 就醫累計資料/費用
    public async GetCumulativeData(): Promise<CSHISResultWithData<CumulativeData>> {
        var path = this.getWebApiPath('CumulativeData/Query')
        var ret = await this.CallWebApi<CumulativeData>(CsHis6CumulativeData, path,{},'HC', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    public async GetCumulativeFee(): Promise<CSHISResultWithData<CumulativeFee>> {
        var path = this.getWebApiPath('CumulativeFee/Query')
        var ret = await this.CallWebApi<CumulativeFee>(CsHis6CumulativeFee, path,{},'HC', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    //#endregion
    
    //#region 重大傷病
    
    public async GetCriticalIllness(): Promise<CSHISResultWithData<CriticalIllnessData>> {
        var path = this.getWebApiPath('TreatmentNumber/NoCard')
        var ret = await this.CallWebApi<CriticalIllnessData>(CsHis6CriticalIllnessData, path, {
            format:'0'
        },'HC', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    
    
    public async GetCriticalIllnessID(data: PatientBaseInput): Promise<CSHISResultWithData<CriticalIllnessData>> {
        return this.GetCriticalIllness()
    }
    //#endregion



    /**
    * 讀取最近一次就醫序號
    */
    public async GetLastSeqNum(): Promise<CSHISResultWithData<LastSeqNum>> {        
        var path = this.getWebApiPath('SequelNumber/Last')
        var ret = await this.CallWebApi<LastSeqNum>(CsHis6LastSeqNum,path, {},'HC', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    //#endregion

    
    //#region 需醫師卡就醫資料取得
    /** 讀取就醫資料需要HPC卡的部份 */
    public async GetTreatmentNeedHPC(): Promise<CSHISResultWithData<TreatmentNeedHPC>> {
        var path = this.getWebApiPath('Treatment/NeedHPC')
        var ret = await this.CallWebApi<TreatmentNeedHPC>(CsHis6TreatmentNeedHPC ,path, {
            format:'2'
        },'HCHPC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    /** 讀取處方箋作業 */
    public async ReadPrescription(): Promise<CSHISResultWithData<Prescription>> {
        var path = this.getWebApiPath('Prescription/Query')
        var ret = await this.CallWebApi<Prescription>(CsHis6Prescription ,path, {
            format:'2'
        },'HCHPC',SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }

    // /** (已過時)診間就醫讀取資料時使用 !!!(過敏/重大傷病/門診醫令/長期醫令四個API請改使用ReadPrescription取得)*/
    // public async ReadPrescriptMain(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionMain>> {
    //     var ret = await this.ReadPrescription();
    //     return {
    //         StatusCode:ret.StatusCode,
    //         ErrorMsg:ret.ErrorMsg,
    //         Data:{
    //             OutPatient: ret.Data.OutPatient,
    //             RecCount:ret.Data.OutPatient.length
    //         }
    //      } 
    // }
    // /** (已過時)讀取過敏藥 !!!(過敏/重大傷病/門診醫令/長期醫令四個API請改使用ReadPrescription取得)*/
    // async ReadPrescriptAllergic(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionAllergic>> {
    //     var ret = await this.ReadPrescription();
    //     return {
    //         StatusCode:ret.StatusCode,
    //         ErrorMsg:ret.ErrorMsg,
    //         Data:{
    //             AllergicDrug: ret.Data.IrritationDrug,
    //             RecCount:ret.Data.IrritationDrug.length
    //         }
    //      } 
    // }
    // /** (已過時)讀取重大傷病 !!!(過敏/重大傷病/門診醫令/長期醫令四個API請改使用ReadPrescription取得)*/
    // async ReadPrescriptHVE(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionHVE>> {
    //     var ret = await this.ReadPrescription();
    //     return {
    //         StatusCode:ret.StatusCode,
    //         ErrorMsg:ret.ErrorMsg,
    //         Data:{
    //             HVE: ret.Data.HVE,
    //             RecCount:ret.Data.HVE.length
    //         }
    //      } 
    // }
    // /** (已過時)讀取長期醫令 !!!(過敏/重大傷病/門診醫令/長期醫令四個API請改使用ReadPrescription取得) */
    // async ReadPrescriptLongTerm(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionLongTerm>> {
    //     var ret = await this.ReadPrescription();
    //     return {
    //         StatusCode:ret.StatusCode,
    //         ErrorMsg:ret.ErrorMsg,
    //         Data:{
    //             LongTerm: ret.Data.LongTerm,
    //             RecCount:ret.Data.LongTerm.length
    //         }
    //      } 
    // }
    VHPCLogout(): Promise<CSHISResult> {
        return this.LogOutHPC()
    }
    //#endregion


    //#region 卡片操作

    //#region 健保卡相關 API (HC)
    /** 讀取保險對象基本資料 */
    public async GetCardHC(): Promise<CSHISResultWithData<HCStatus>> {
        var path = '/api/hc/v1/Basic';
        var ret = await this.GetCsHis6Api<HCStatus& CSHIS6Output>(path)
        var ret2 = this.toCSHISResultWithData<HCStatus>(ret);
        ret2.Data = ret;
        return ret2;
    }
    /** 讀取卡片狀態文字(健保卡) */
    public async GetCardStatusHC(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCsHis6Api<CsHis6Status>('/api/common/v1/Status');
        return new CSHISResultWithData(ret.statusCode,this.ErrorMsg(ret.statusCode), ret.status.hc.status==0?'未置入健保卡':
                ret.status.hc.status==1?'健保卡已置入':
                ret.status.hc.status==2?'健保卡已與 SAM 驗證':
                ret.status.hc.status==3?'健保卡已與 HCA 驗證':'');
    }
    /** 健保卡是否有密碼。 statusCode=0:無,1:有 */
    async ISSetPIN(): Promise<CSHISResult> {
        var ret = await this.GetCsHis6Api<CSHIS6Output&{hasPin:boolean}>('/api/hc/v1/Pin');
        var ret2 = new CSHISResultWithData<boolean>();
        ret2.StatusCode = ret.statusCode||ret.hasPin?1:0;
        ret2.Data = ret.hasPin;
        ret2.ErrorMsg = this.ErrorMsg(ret.statusCode);
        return ret2;
    }
    /** 驗證健保卡PIN */
    async VerifyHCPIN(pin:string): Promise<CSHISResult> {
        var path = '/api/hc/v1/Pin'
        var ret = await this.PostCsHis6Api<CSHIS6Output>(path,{pin:pin});
        return this.toCSHISResult(ret);
        
    }
    
    /** 設定健保卡PIN */
    async InputHCPIN(newPin:string): Promise<CSHISResult> {
        var path = '/api/hc/v1/Pin'
        var ret = await this.PutCsHis6Api<CSHIS6Output>(path,{newPin:newPin});
        return this.toCSHISResult(ret);
        
    }
    /** 移除健保卡PIN */
    async DisableHCPIN(): Promise<CSHISResult> {
        var path = '/api/hc/v1/Pin';
        var ret = await this.DeleteCsHis6Api<CSHIS6Output>(path)
        var ret2 = this.toCSHISResultWithData<void>(ret);
        return ret2;
    }
    /** 登出健保卡 */
    async LogOutHC(): Promise<CSHISResult> {
        
        var path = '/api/hc/v1/Logout';
        var ret = await this.DeleteCsHis6Api<CSHIS6Output>(path)
        var ret2 = this.toCSHISResultWithData<void>(ret);
        return ret2;
    }
    
    /** 更新『實體』卡片內容 */
    public async UpdateHCContents(): Promise<CSHISResult> {
        // 更新相關註記
        var ret = await this.PostCsHis6Api<CSHIS6Output>("/api/hc/v1/RealHc/Update", {})
        var r = this.toCSHISResult(ret)
        // 業務端更新卡片 雖然不知道差在哪裡總之兩邊都call一次
        var path = this.getWebApiPath('HcContent/Update');
        var ret2 = await this.CallWebApi<void>(null,path,{},'HC',SignatureServiceTypeEnum._04_HC_UPDATE)
        return ret2;
    }
    /** 同 UpdateHCContents*/
    public UpdateHCNoReset(): Promise<CSHISResult> {
        return this.UpdateHCContents()
    }
    /** (已取代，請使用 GetCardHC 取得完診資訊) */
    public async GetCardNoHC(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardHC()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.cardId)
    }
    //#endregion


    //#region 醫事人員卡相關 API (HCA)

    /** 讀取卡片狀態文字(醫事人員卡) */
    public async GetCardStatusHPC(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCsHis6Api<CsHis6Status>('/api/common/v1/Status');
        return new CSHISResultWithData(ret.statusCode,this.ErrorMsg(ret.statusCode), ret.status.hc.status==0?'未置入醫事人員卡':
                ret.status.hc.status==1?'醫事人員卡已置入':
                ret.status.hc.status==2?'醫事人員卡已驗證':
                ret.status.hc.status==3?'醫事人員卡已驗 PIN':'');
    };
    /** 讀取卡片插入狀態(醫事人員卡) */
    public async GetHPCStatus(): Promise<CSHISResultWithData<boolean>> {
        var st = await this.GetCardStatusAll();
        return new CSHISResultWithData<boolean>(st.statusCode,this.ErrorMsg(st.statusCode), st.status.hpc.status>0);
    }
    /** 取得醫事人員卡資訊 */
    public async GetCardHPC(): Promise<CSHISResultWithData<HPCStatus>> {
        var path = '/api/hpc/v1/Basic';
        var ret = await this.GetCsHis6Api<HPCStatus& CSHIS6Output>(path)
        var ret2 = this.toCSHISResultWithData<HPCStatus>(ret);
        ret2.Data = ret;
        return ret2;
    }
    /** (已取代，請使用 GetCardHPC 取得完診資訊) */
    public async GetCardNoHPC(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardHPC()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.cardId)
    }
    /** (已取代，請使用 GetCardHPC 取得完診資訊) */
    public async GetHPCCNAME(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardHPC()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.cName)
    }
    /** (已取代，請使用 GetCardHPC 取得完診資訊) */
    public async GetHPCENAME(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardHPC()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.eName)
    }
    /** (已取代，請使用 GetCardHPC 取得完診資訊) */
    public async GetHPCSN(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardHPC()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.cardId)
    }
    /** (已取代，請使用 GetCardHPC 取得完診資訊) */
    public async GetHPCSSN(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardHPC()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.idNo)
    }
    
    /** 登出醫事人員卡 */
    async LogOutHPC(): Promise<CSHISResult> {
        
        var path = '/api/hpc/v1/Logout';
        var ret = await this.DeleteCsHis6Api<CSHIS6Output>(path)
        var ret2 = this.toCSHISResultWithData<void>(ret);
        return ret2;
    }
    /** 輸入醫事人員PIN */
    // public async KeyInHPCPIN(pin:string): Promise<CSHISResult> {
    //     var path = '/api/hpc/v1/Verification/Hpc'
    //     var ret = await this.PostCsHis6Api<CSHIS6Output>(path,{pin:pin});
    //     return this.toCSHISResult(ret);
    // }
    /** 重新設定醫事人員卡Pin */
    async InputHPCPIN(newPin:string): Promise<CSHISResult> {
        var path = 'api/hpc/v1/Pin'
        var ret = await this.PostCsHis6Api<CSHIS6Output>(path,{newPin:newPin});
        return this.toCSHISResult(ret);
    }
    /** 解鎖醫事人員卡 (使用 PUK 重新設定 PIN 碼) */
    async UnlockHPC(puk:string,newPin:string): Promise<CSHISResult> {
        var path = 'api/hpc/v1/Pin'
        var ret = await this.PostCsHis6Api<CSHIS6Output>(path,{
            puk:puk,
            newPin:newPin
        });
        return this.toCSHISResult(ret);
    }
    /** 驗證醫事人員卡Pin */
    async VerifyHPCPIN(pin:string): Promise<CSHISResult> {
        var path = '/api/hpc/v1/Verification/Hpc'
        var ret = await this.PostCsHis6Api<CSHIS6Output>(path,{pin:pin});
        return this.toCSHISResult(ret);
    }

    /** 驗證醫事人員卡行動憑證 */
    async VerifyMobileHPC(signatureCertificate:string): Promise<CSHISResult> {
        throw '目前不支援醫事人員行動憑證'
        var path = '/api/hpc/v1/Verification/MobiliHca'
        var signature = ''// this.CreateSignature...
        var ret = await this.PostCsHis6Api<CSHIS6Output>(path,{
            "idNo": "R88855084A",
            "birthday": "1970-05-28",
            "cName": "測試資料",
            "eName": "",
            "hospitalId": "7777777777",
            "medId": "F",
            "loginDateTime": "2024-05-28T08:44:41Z",
            "signatureCertificate": signatureCertificate,// b64
            "signature": signature
           });
        return this.toCSHISResult(ret);
    }
    //#endregion

    //#region  安全模組相關 API (SAM)

    public async GetCardSAM(): Promise<CSHISResultWithData<SAMStatus>> {
        var path = '/api/sam/v1/Basic';
        var ret = await this.GetCsHis6Api<SAMStatus& CSHIS6Output>(path)
        var ret2 = this.toCSHISResultWithData<SAMStatus>(ret);
        ret2.Data = ret;
        return ret2;
    }
    /** (已取代，請使用 GetCardSAM 取得完診資訊) */
    public async GetHospAbbName(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardSAM()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.hospitalShortName)
    }
    /** (已取代，請使用 GetCardSAM 取得完診資訊) */
    public async GetHospID(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardSAM()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.hospitalId)
    }
    /** (已取代，請使用 GetCardSAM 取得完診資訊) */
    public async GetHospName(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardSAM()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.hospitalName)
    }
    /** (已取代，請使用 GetCardSAM 取得完診資訊) */
    public async GetCardNoSAM(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCardSAM()
        return new CSHISResultWithData(ret.StatusCode,ret.ErrorMsg, ret.Data.samId)
    }
    /** (已取代，請使用 GetCardStatusAll) */
    public async GetCardStatusSAM(): Promise<CSHISResultWithData<string>> {
        var st = await this.GetCardStatusAll();
        return {
            StatusCode:st.statusCode,
            ErrorMsg:this.ErrorMsg(st.statusCode),
            Data: CsHis6SAMStatusEnumName[st.status?.sam?.status??0]
        }
    }
    /** 驗證SAM */
    async VerifySAMDC(): Promise<CSHISResult> {
        var path = '/api/sam/v1/Verification';
        var ret = await this.PostCsHis6Api<CSHIS6Output>(path,{})
        var ret2 = this.toCSHISResultWithData<void>(ret);
        return ret2;
    }
    /** 登出SAM */
    async LogOutSAM(): Promise<CSHISResult> {
        var path = '/api/sam/v1/Logout';
        var ret = await this.DeleteCsHis6Api<CSHIS6Output>(path)
        var ret2 = this.toCSHISResultWithData<void>(ret);
        return ret2;
    }
    
    /** 6.0重設=SAM重登 */
    SoftwareResetCR(): Promise<CSHISResult> {
        return this.SoftwareResetSAM();
    }
    /** 6.0重設=SAM重登 */
    SoftwareResetHC(): Promise<CSHISResult> {
        return this.SoftwareResetSAM();
    }
    /** 6.0重設=SAM重登 */
    SoftwareResetHPC(): Promise<CSHISResult> {
        return this.SoftwareResetSAM();
    }
    /** 6.0重設=SAM重登 */
    async SoftwareResetSAM(): Promise<CSHISResult> {
        await this.LogOutSAM();
        return await this.VerifySAMDC();
    }
    //#endregion

    //#endregion
    
    //#region 寫卡作業


    /** 就醫診療資料寫入作業 */
    public async WriteTreatmentCode(data: TreatmentCode): Promise<CSHISResultWithData<DocIdData>> {
        var path = this.getWebApiPath('Treatment/WriteCode')
        var ret = await this.CallWebApi<DocIdData>(null,path, {
            treatmentDateTime:data.DateTime,
            afterCheck:data.ReCardFlag,
            format:'2',
            mainCode:data.MainDisease,
            subCode1:data.SecDisease1,
            subCode2:data.SecDisease2,
            subCode3:data.SecDisease3,
            subCode4:data.SecDisease4,
            subCode5:data.SecDisease5,
            
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }

    /** 就醫費用資料寫入作業 */
    public async WriteTreatmentFee(data: TreatmentFee): Promise<CSHISResult> {
        var path = this.getWebApiPath('Treatment/WriteFee')
        var ret = await this.CallWebApi<void>(null,path, {
            treatmentDateTime:data.DateTime,
            outpatientFee:data.MedicalFees,
            costFee:data.Deductible,
            inpatientFee:data.HospitalFees,
            inpatient30Fee:data.HdeductibleA,
            inpatient180Fee:data.HdeductibleB
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }

    /** 就醫診療料寫入與就醫費用寫入 */
    public async WriteTreatmentData(data: TreatmentData): Promise<CSHISResultWithData<DocIdData>> {
        var path = this.getWebApiPath('Treatment/WriteCodeFee')
        var ret = await this.CallWebApi<DocIdData>(null,path, {
            treatmentDateTime:data.DateTime,
            afterCheck:data.ReCardFlag,
            format:'2',
            mainCode:data.MainDisease,
            subCode1:data.SecDisease1,
            subCode2:data.SecDisease2,
            subCode3:data.SecDisease3,
            subCode4:data.SecDisease4,
            subCode5:data.SecDisease5,

            outpatientFee:parseInt(data.MedicalFees)||0,
            costFee:parseInt(data.Deductible)||0,
            inpatientFee:parseInt(data.HospitalFees)||0,
            inpatient30Fee:parseInt(data.HdeductibleA)||0,
            inpatient180Fee:parseInt(data.HdeductibleB)||0,
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }

    /** 寫入處方箋資料 */
    public async WriteMultiPrescriptSign(data: PrescriptionSignMultiWrite): Promise<CSHISResultWithData<string[]>> {
        var path = this.getWebApiPath('Prescription/Write')
        var ret = await this.CallWebApi<string[]>(CsHis6WriteMultiPrescriptSign,path, {
            treatmentDateTime:data.DateTime,
            prescriptions:data.Prescriptions.map(x=>{
                return {
                    /** 天數 */
                    days:x.Days,
                    /** 交付註記 */
                    deliveryNotes:x.DispensingType,
                    /** 總量 */
                    totalQuantity: x.TotalDose,
                    /** 就診日期時間 */
                    treatmentDateTime:DateHelper.getROCDateTimeString(x.ClinicDateTime),
                    /** 醫令類別 */
                    treatmentItem:x.OrderClass,
                    /** 藥品代碼 */
                    treatmentItemCode:x.NhiDrugCode,
                    /** 診療部位 */
                    treatmentPosition:x.TreatmentSite,
                    /** 用法 */
                    usage:x.Frequency
                }
            })
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }

    //#region 過敏藥    
    public async WriteAllergicMedicines(data: AllergicMedicines): Promise<CSHISResultWithData<DocIdData>> {
        var path = this.getWebApiPath('AllergicMedicines/Write')
        var ret = await this.CallWebApi<DocIdData>(null,path, {
            allergicMedicines:[
                data.AllergicMedicine1,
                data.AllergicMedicine2,
                data.AllergicMedicine3]
        },'HCHPC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }
    public async WriteAllergicNum(data: AllergicNum): Promise<CSHISResultWithData<DocIdData>> {
        var path = this.getWebApiPath('AllergicMedicines/WriteByIndex')
        var ret = await this.CallWebApi<DocIdData>(null,path, {
            allergicMedicine:data.AllergicData,
            index:data.Num
        },'HCHPC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }
    //#endregion

    public async WriteEmergentTel(data: EmergentTelWrite): Promise<CSHISResult> {
        var path = this.getWebApiPath('EmergentTel/Write')
        var ret = await this.CallWebApi<void>(null,path, {
            tel:data.RelationPhone,
        },'HCHPC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }
    
    /** 寫入預防接種資料 */
    public async WriteInoculateData(data: InoculateDataWrite): Promise<CSHISResult> {
        var path = this.getWebApiPath('Inoculate/Write')
        var ret = await this.CallWebApi<void>(null,path, {
            item:data.Item,
            packageNumber:data.PackageNumber,
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }
    
    public async WriteNewBorn(data: NewBorn): Promise<CSHISResult> {
        var path = this.getWebApiPath('NewBorn/Write')
        var ret = await this.CallWebApi<void>(null,path, {
            newBornBady:data.NewBornBirthNum,
            newBornDate:data.NewBornBirthDate,
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }

    public WriteOrganDonate(data: OrganDonateWrite): Promise<CSHISResult> {
        throw 'notSupport'
    }
    
    public async WritePredeliveryCheckup(data: PredeliveryCheckup): Promise<CSHISResult> {
        var path = this.getWebApiPath('PregnantData/Write')
        var ret = await this.CallWebApi<void>(null,path, {
            checkupCode:data.CheckUpCode,
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }
    public async DeletePredeliveryData(data: PatientBaseInput): Promise<CSHISResult> {
        var path = this.getWebApiPath('PregnantData/Delete')
        var ret = await this.CallWebApi<void>(null,path, {
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
        
    }

    public async WriteHealthInsurance(data: HealthInsurance): Promise<CSHISResult> {
        var path = this.getWebApiPath('PreventData/Write')
        var ret = await this.CallWebApi<void>(null,path, {
            serviceItem:data.ServiceItemRemark,
            serviceCode:data.ServiceItemCode
        },'HC', SignatureServiceTypeEnum._02_HC_WRITE);
        return ret;
    }


    //#endregion

    //#region 掛號卡序作業

    /** 取得就醫序號時使用-新增加取就醫序號同時取就醫識別碼 */
    public async GetSeqNumber256N1(args: GetSeqNumber256): Promise<CSHISResultWithData<SeqNumber256N1>> {
        var path = this.getWebApiPath('SequelNumber/Next')
        var ret = await this.CallWebApi<SeqNumber256N1>(CsHis6SeqNumber256N1, path, {
            treatmentItem:args.TreatItem.trim(),
            babyTreatment:args.BabyTreat.trim(),
            afterCheck:args.TreatAfterCheck.trim()
        },'HC', SignatureServiceTypeEnum._03_HC_Number);
        return ret;
    }

    /** 無卡取得就醫識別碼(異常/欠卡) */
    public async GetTreatNumNoICCard(patientId: string, hospCode: string): Promise<CSHISResultWithData<TreatNumNoICCard>> {
        var path = this.getWebApiPath('TreatmentNumber/NoCard')
        var ret = await this.CallWebApi<TreatNumNoICCard>(CsHis6TreatNumNoICCard, path, {
            patientId
        },'SAM', SignatureServiceTypeEnum._03_HC_Number);
        return ret;
    }

    /** 還卡單獨取得就醫識別碼  */
    public async hisGetTreatNumICCard(pDateTime: string): Promise<CSHISResultWithData<string>> {
        var path = this.getWebApiPath('TreatmentNumber/NoCard')
        var ret = await this.CallWebApi<string>(CsHis6TreatNumICCard, path, {
            treatmentDateTime:pDateTime
        },'HC', SignatureServiceTypeEnum._03_HC_Number);
        return ret;
    }


    /** 回復就醫資料累計值(退掛)  */
    public async UnGetSeqNumber(pUnTreatDate: string): Promise<CSHISResult> {
        var path = this.getWebApiPath('SequelNumber/Rollback')
        var ret = await this.CallWebApi<void>(null,path, {
            treatmentDateTime:pUnTreatDate
        },'HC', SignatureServiceTypeEnum._03_HC_Number);
        return ret;
    }
    //#endregion

    
    //#region 虛擬健保卡
    /** 載入虛擬健保卡 */
    public async VirtualHc(code: string): Promise<CSHISResult> {
        var ret = await this.PostCsHis6Api<CSHIS6Output>("/api/hc/v1/Verification/VirtualHc", {
            token: code
        })
        return this.toCSHISResult(ret)
    }
    /** 讀取虛擬健保卡基本資料 */
    //#region 元件端
    public async VirtualHcStatus(code: string): Promise<CSHISResultWithData<HCStatus>> {
        var ret = await this.PostCsHis6Api<CSHIS6Output& HCStatus>("/api/hc/v1/VirtualHc/ReadBasic", {
            token: code
        })
        var r = this.toCSHISResultWithData<HCStatus>(ret)
        r.Data = ret;
        return r;
    }
    
    /** 匯出虛擬健保卡 */
    public async VirtualHcExport(): Promise<CSHISResultWithData<string>> {
        var ret = await this.PostCsHis6Api<CSHIS6Output& {transferToken:string}>("/api/hc/v1/VirtualHc/Export", {})
        var r = this.toCSHISResultWithData<string>(ret)
        r.Data = ret.transferToken;
        return r;
    }
    /** 匯入虛擬健保卡 */
    public async VirtualHcImport(transferToken: string): Promise<CSHISResult> {
        var ret = await this.PostCsHis6Api<CSHIS6Output>("/api/hc/v1/Verification/VirtualHc", {
            transferToken: transferToken
        })
        return this.toCSHISResult(ret)
    }
    /** /QRCodeReader/VHCcleanQRCode API 重置並清除已掃描 QRCode 資料。 */
    public async VirtualHcLogout(): Promise<CSHISResult> {
        // 文件說匯出 狀態會變回0
        return await this.VirtualHcExport()
    }
    //#endregion
    //#region 業務端
    /** 請求視訊醫療虛擬健保卡 Token 資料 
     * 回傳呼叫 ResponseToken API 時所需的AccessToken
    */
    public async VirtualHcRequestToken(patientId: string): Promise<CSHISResultWithData<string>> {
        var path = this.getWebApiPath('TeleMedicine/RequestToken')
        var ret = await this.CallWebApi<string>(CsHis6VirtualHcRequestToken, path, {
            patientId:patientId
        },'SAM', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    /** 取得視訊醫療虛擬健保卡 Token 資料授權結果 
     * 回傳 虛擬健保卡 QR Code 資料
    */
    public async VirtualHcResponseToken(accessToken: string): Promise<CSHISResultWithData<string>> {
        var path = this.getWebApiPath('TeleMedicine/ResponseToken')
        var ret = await this.CallWebApi<string>(CsHis6VirtualHcResponseToken, path, {
            accessToken:accessToken
        },'SAM', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    //#endregion
    //#endregion
    
    //#region 上傳檔案
    async UploadData(pUploadFileXmlContent: string, pNumber: string): Promise<CSHISResultWithData<UploadDataResult>> {
        var path = this.getWebApiPath('UploadData/Upload')
        var ret = await this.CallWebApi<UploadDataResult>(CsHis6UploadDataResult, path, {
            fileContent:btoa(pUploadFileXmlContent)
        },'SAM', SignatureServiceTypeEnum._05_UPLOAD);
        return ret;
    }
    //#endregion

    //#region 已過時
    /** 開啟讀卡機連結埠 沒這玩意(Initialize) */
    public OpenCom(comport: number): Promise<CSHISResult> {
        return Promise.resolve({
            StatusCode:0,
            ErrorMsg:''
        })
    }
    /** 關閉讀卡機連結埠 沒這玩意(Finalize) */
    public CloseCom(): Promise<CSHISResult> {
        return Promise.resolve({
            StatusCode:0,
            ErrorMsg:''
        })
    }

    
    public async GetICD10DeC(encoded: string): Promise<CSHISResultWithData<string>> {
        var path = this.getWebApiPath('Icd/Decode')
        var ret = await this.CallWebApi<string>(CsHis6Decode,path, {
            encCode:encoded,
        },'SAM', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    public async GetICD10EnC(code: string): Promise<CSHISResultWithData<string>> {
        var path = this.getWebApiPath('Icd/Encode')
        var ret = await this.CallWebApi<string>(CsHis6Encode,path, {
            code:code,
        },'SAM', SignatureServiceTypeEnum._01_HC_READ);
        return ret;
    }
    // DownloadData(args: DownloadDataArg): Promise<CSHISResultWithData<string>> {
    //     throw 'notImplement'
    // }
    // UploadDataPrec(pUploadFileName: string, pNumber: string, pPrecNumber: string): Promise<CSHISResultWithData<UploadDataPrecResult>> {
    //     throw 'notSupport'
    // }
    //#endregion


    
    //#region 其他WEBAPI作業
    public GetHCACert(data: HcaInitData): Promise<CSHISResultWithData<string>> {
        throw 'notImplement'
    }
    public GetHCASign(data: HCASignRequestData): Promise<CSHISResultWithData<string>> {
        throw 'notImplement'
    }
    InitializationAndCheckPasswd(passWord: string): Promise<CSHISResultWithData<HcaInitData>> {
        throw 'notImplement'
    }
    public GetVPNGetRandomXAndSignX(cardType: string, serviceCode: string): Promise<CSHISResultWithData<VPNGetRandomXandSignX>> {
        throw 'notImplement'
    }
    
    public async webApiGetTreatNum(patientId: string, hospCode: string, treatDateTime: string): Promise<CSHISResultWithData<TreatNumNoICCard>> {
        var r = await this.hisGetTreatNumICCard(treatDateTime);
        var r2 = new CSHISResultWithData<TreatNumNoICCard>(r.StatusCode,r.ErrorMsg,{
            DateTime:DateHelper.parseROCDateTime(treatDateTime),
            HospCode:hospCode,
            TreatNum:r.Data
        });
        return r2;
    }
    public async webApiGetTreatNumNoIC(patientId: string, hospCode: string): Promise<CSHISResultWithData<TreatNumNoICCard>> {
        var r = await this.GetTreatNumNoICCard(patientId,hospCode);
        return r;
    }
    async webApiUpload(xmlString: string, type: 'A1' | 'A2' | 'ZZ'):Promise<{RtnCode:string,Opcode:string}>{
        var sign = await this.getSAMSignature(SignatureServiceTypeEnum._30_SAM_醫療資訊傳輸服務);
        
        //  Base64 Encode btoa ; DeCode: atob
        let base64EncodedString = btoa(xmlString);
        let now = new Date();
        let timestamp = DateHelper.formatFullDateTime(now);
        var input = {
            sSamid:sign.samId,
            sHospid:sign.hospitalId,
            sClientrandom:sign.clientRandom,
            sSignature:sign.signature,
            sPatData:base64EncodedString,
            sType:type,
            sUploadDT:timestamp,
        }
        let url = 'https://medvpndti.nhi.gov.tw/V2000/VNHI_Upload';
        try{
            var ret = await this.httpClient.post<{
                RtnCode:string,
                Opcode:string
            }>(url, input, {
                headers: { "Content-Type": "application/json" }
            }).toPromise()
            return ret;
        }catch(ex){
            console.log(ex)
            return  {ERRORCODE:-1,message:'醫療資訊傳輸服務WEBAPI呼叫失敗'} as any
        }
    }
    async webApiDownload(opCode: string, type: 'A1' | 'A2' | 'ZZ') {
        var sign = await this.getSAMSignature(SignatureServiceTypeEnum._30_SAM_醫療資訊傳輸服務);
        var request = {
            sSamid: sign.samId,
            sHospid:sign.hospitalId,
            sClientrandom:sign.clientRandom,
            sSignature:sign.signature,
            sType: type,
            sOpcode:opCode,
        }
        let url = 'https://medvpndti.nhi.gov.tw/V2000/VNHI_Download';
        try{
            var ret = await this.httpClient.post<{
                RtnCode:string,
                Tx_result_txt:string,
                Tx_result_json:string
            }>(url, request, {
                headers: { "Content-Type": "application/json" }
            }).toPromise()
            if(ret.RtnCode=='0000'){
                ret.Tx_result_json = atob(ret.Tx_result_json)
                ret.Tx_result_txt = atob(ret.Tx_result_txt)
            }
            return ret;
        }catch(ex){
            console.log(ex)
            return  {ERRORCODE:-1,message:'醫療資訊傳輸服務WEBAPI呼叫失敗'} as any
        }
    }

    CNHIEiiSend(port: number, typeCode: string, filePath: string): Promise<CSHISResultWithData<CnhiEiiApiID>> {
        return new VisionHIS(this.hcrService).CNHIEiiSend(port,typeCode,filePath);
    }
    CNHIEiiDownload(port: number, typeCode: string, filePath: string): Promise<CSHISResultWithData<CnhiEiiApiID>> {
        return new VisionHIS(this.hcrService).CNHIEiiDownload(port,typeCode,filePath);
    }    
    CNHIEiiGet(port: number, downloadPath: string, localId: string, nhiId: string): Promise<CSHISResult> {
        return new VisionHIS(this.hcrService).CNHIEiiGet(port,downloadPath,localId,nhiId);
    }
    //#endregion

    /**
     *
     */
    constructor(protected hcrService: HcrService, private httpClient: HttpClient) {
        //super(hcrService);
    }



    //#region 元件端API串接內部方法
    private baseUrl = 'https://localhost:5066'
    /** 元件端Post */
    private async PostCsHis6Api<T>(path: string, body: any): Promise<T> {
        try{
            var ret = await this.httpClient.post<T>(this.baseUrl + path, body, {
                headers: { "Content-Type": "application/json" }
            }).toPromise()
            console.log('CSHIS6.0 呼叫>>> ', path)
            console.log('CSHIS6.0 回傳<<< ', ret)
            return ret;
        }catch(ex){
            console.log(ex)
            return  {ERRORCODE:-1,message:'虛擬健保卡API呼叫失敗'} as any
        }
    }
    /** 元件端Put */
    private async PutCsHis6Api<T>(path: string, body: any): Promise<T> {
        try{
            var ret = await this.httpClient.put<T>(this.baseUrl + path, body, {
                headers: { "Content-Type": "application/json" }
            }).toPromise()
            console.log('CSHIS6.0 呼叫>>> ', path)
            console.log('CSHIS6.0 回傳<<< ', ret)
            return ret;
        }catch(ex){
            console.log(ex)
            return  {ERRORCODE:-1,message:'虛擬健保卡API呼叫失敗'} as any
        }
    }
    /** 元件端Get */
    private async GetCsHis6Api<T>(path: string): Promise<T> {
        try{
            var ret = await this.httpClient.get<T>(this.baseUrl + path, {
                headers: { "Content-Type": "application/json" }
            }).toPromise<T>()
            console.log('CSHIS6.0 呼叫>>> ', path)
            console.log('CSHIS6.0 回傳<<< ', ret)
            return ret;
        }catch(ex){
            console.log(ex)
            return  {ERRORCODE:-1,message:'虛擬健保卡API呼叫失敗'} as any
        }
    }
    
    /** 元件端Delete */
    private async DeleteCsHis6Api<T>(path: string): Promise<T> {
        try{
            var ret = await this.httpClient.delete<T>(this.baseUrl + path, {
                headers: { "Content-Type": "application/json" }
            }).toPromise<T>()
            console.log('CSHIS6.0 呼叫>>> ', path)
            console.log('CSHIS6.0 回傳<<< ', ret)
            return ret;
        }catch(ex){
            console.log(ex)
            return  {ERRORCODE:-1,message:'虛擬健保卡API呼叫失敗'} as any
        }
    }
    /** 取得健保卡簽章 */
    private async getHCSignature(serviceType:SignatureServiceTypeEnum):Promise<HCSignature& CSHIS6Output>{
        return await this.PostCsHis6Api<HCSignature& CSHIS6Output>('/api/hc/v1/Signature/Hc',{
            serviceType:serviceType
        })
    }
    /** 取得醫事人員簽章 */
    private async getHPCSignature(serviceType:SignatureServiceTypeEnum):Promise<HPCSignature& CSHIS6Output>{
        return await this.PostCsHis6Api<HPCSignature& CSHIS6Output>('/api/hpc/v1/Signature',{
            serviceType:serviceType
        })
    }
    /** 取得SAM簽章 */
    private async getSAMSignature(serviceType:SignatureServiceTypeEnum):Promise<SAMSignature& CSHIS6Output>{
        return await this.PostCsHis6Api<SAMSignature& CSHIS6Output>('/api/sam/v1/Signature',{
            serviceType:serviceType
        })
    }
    /** 取得三卡簽章 */
    private async getHPCHCSignature(serviceType:SignatureServiceTypeEnum){
        return await this.PostCsHis6Api<HCSignature&HPCSignature& CSHIS6Output>('/api/hc/v1/Signature/HpcHc',{
            serviceType:serviceType
        })
    }
    //#endregion



    //#region 業務端API串接內部方法
    //private webApiUrl = 'https://medvpndc.nhi.gov.tw'//正式
    private webApiUrl = 'https://medvpndct.nhi.gov.tw';
    private getWebApiPath(path:string,version:string = 'V1'){
        return `/api/${version}/${path}`
    }
    /**
     * 呼叫業務端API (整合)
     * 
     * T: 回傳資料格式(5157)
     * 
     * 若業務端回應只有statusCode則 T設定為<void>, ctor設定為null 即可
     * @param ctor 符合6.0業務端回傳資料結構並且實作IConvertToCsHis<T>的型別，
     * @param path 6.0業務端路徑
     * @param body Post Api時要上傳的額外參數，不必包含元件端所提供的項目
     * @param signType 從元件端取得簽章的類型，取出後會與body合併提交
     * @param serviceType 元件端取得簽章的服務類別
     * @returns CSHISResultWithData<T> 內已將業務端的果轉換為此資料結構
     */
    private async CallWebApi<T>(ctor:Constructor<T>, path: string, body: any,signType:'HC'|'HPC'|'HCHPC'|'SAM',serviceType:SignatureServiceTypeEnum): Promise<CSHISResultWithData<T>> {
        try{
            var sign:CSHIS6Output = signType=='HC'? await this.getHCSignature(serviceType):
                        signType == 'HPC'? await this.getHPCSignature(serviceType):
                        signType == 'SAM'?await this.getSAMSignature(serviceType):
                        signType == 'HCHPC'?await this.getHPCHCSignature(serviceType):
                        {statusCode:-9001} as CSHIS6Output;
            if(sign.statusCode!=0){
                console.log('6.0 元件端簽章失敗 ', sign.statusCode);
                return Promise.resolve(this.toCSHISResultWithData(sign));
            }
            var signedBody = Object.assign(sign,body);

            var ret = await this.httpClient.post<CSHIS6Output&T>(this.webApiUrl + path, signedBody, {
                headers: { "Content-Type": "application/json" }
            }).toPromise()
            
            console.log('6.0 WEBAPI 呼叫>>> ', path)
            console.log('6.0 WEBAPI 回傳<<< ', ret)
            var rs = this.toCSHISResultWithData<T>(ret);
            if (rs.StatusCode != 0) {
                return rs;
            }
            if(ctor){
                var a =  new ctor();
                Object.assign(a,ret);
                rs.Data = a.toCsHis()
            }
            return rs;
        }catch(ex){
            console.log(ex)
            return  {ERRORCODE:-1,message:'虛擬健保卡API呼叫失敗'} as any
        }
    }
    //#endregion



    //#region CSHIS6指令
    
    /** 讀取狀態(全部) */
    public async GetCardStatusAll(): Promise<CsHis6Status> {
        var ret = await this.GetCsHis6Api<CsHis6Status>('/api/common/v1/Status');
        return ret;
    }

    /** 讀取讀卡機日期時間(民國年) */
    public async GetDateTime(): Promise<CSHISResultWithData<Date>> {
        var ret = await this.GetCsHis6Api<CSHIS6Output&{serverDateTime:string}>('/api/common/v1/ServerDateTime');
        var r = this.toCSHISResultWithData<Date>(ret)
        r.Data = DateHelper.parseROCDateTime(ret.serverDateTime);
        return r;
    }
    
    ///** API name getSDKVersion  用途： 健保卡 SDK 版本資訊 */
    public async GetAPIVersion(): Promise<CSHISResultWithData<string>> {
        var ret = await this.GetCsHis6Api<CSHIS6Output&VersionInfo>('/api/common/v1/Version');
        var r = this.toCSHISResultWithData<string>(ret)
        r.Data = ret.longVersion;
        return r;
    }
    /** 健保卡 SDK 版本資訊 */
    public async GetVersionEx(): Promise<CSHISResultWithData<number>> {
        var ret = await this.GetCsHis6Api<CSHIS6Output&VersionInfo>('/api/common/v1/Version');
        var r = this.toCSHISResultWithData<number>(ret)
        r.Data = parseInt(ret.longVersion);
        return r;
    }

    /** 初始化 */
    public async Initial(){
        return await this.PostCsHis6Api<CSHIS6Output>('/api/common/v1/Initial',{})
    }
    /** 結束 */
    public async Finalize(){
        return await this.PostCsHis6Api<CSHIS6Output>('/api/common/v1/Finalize',{})
    }
    //#endregion




    private toCSHISResult(ret: CSHIS6Output): CSHISResult {
        return {
            StatusCode: ret.statusCode,
            ErrorMsg: this.ErrorMsg(ret.statusCode),
        }
    }
    private toCSHISResultWithData<T>(ret: CSHIS6Output): CSHISResultWithData<T> {
        
        return {
            StatusCode: ret.statusCode,
            ErrorMsg: this.ErrorMsg(ret.statusCode),
            Data:null
        }
    }
    private ErrorMsg(errorCode: number): string {
        switch (errorCode) {
            case 0: return '成功';
            case -9001: return '參數錯誤：未知的signType';
            //1000 - 1099 狀態異常
            case 1000: return '控制軟體尚未初始化';
            case 1001: return '控制軟體已初始化';
            case 1002: return '未查詢到指定的或是任何有效的裝置名稱';
            case 1003: return '正式卡與測試卡不能混用';
            //1100 - 1199 PCSC 讀卡機相關異常代碼';
            case 1100: return '沒有發現預設 PCSC 讀卡機';
            case 1101: return '目前沒有使用任何 PCSC 讀卡機';
            case 1102: return '未置入卡片';
            case 1103: return '切換卡片應用程式失敗';
            case 1119: return 'PCSC 發生其它錯誤';
            //1200 - 1299 實體讀卡機相關異常代碼';
            case 1200: return '無法載入 Reader.dll 檔案';
            case 1201: return '開啟 Comport 失敗';
            case 1202: return '操作實體讀卡機失敗';
            case 1203: return '實體讀卡機鍵盤按下取消';
            //1300 - 1399 SAM 相關異常代碼';
            case 1300: return 'SAM 進入模式一失敗';
            case 1301: return 'SAM 進入模式二失敗';
            case 1302: return '醫事機構已超過合約起迄';
            case 1303: return '醫事機構已停權';
            case 1304: return 'SAM 已註銷';
            //1400 - 1499 HCA 相關異常代碼';
            case 1400: return '醫事人員卡進入模式一失敗';
            case 1401: return '醫事人員卡進入模式二失敗';
            case 1402: return '醫事人員卡進入模式三失敗';
            case 1403: return '醫事人員卡不支援此功能';
            case 1410: return '醫事人員卡驗證 PIN 碼失敗';
            case 1420: return 'HCA 驗簽失敗';
            case 1421: return 'HCA 簽章逾時';
            case 1422: return '憑證不在有效期內';
            case 1423: return '未查詢到信任上層憑證';
            case 1424: return '憑證已註消';
            case 1430: return 'Mobile HCA 驗簽失敗';
            case 1431: return '行動 HCA 驗證，醫事機構代碼與 SAM 不符';
            //1500 - 1599 HC 相關異常代碼';
            case 1500: return '健保卡進入模式一失敗';
            case 1501: return '健保卡進入模式二失敗';
            case 1502: return '健保卡進入模式三失敗';
            case 1510: return '實體健保卡並無設定 PIN 碼';
            case 1511: return '尚未驗證 PIN 碼';
            case 1512: return '停用 HC PIN 碼失敗';
            case 1513: return 'PIN 碼已驗證';
            case 1514: return '驗證 HC PIN 碼失敗';
            case 1515: return '呼叫的功能僅支援實體健保卡';
            case 1520: return '虛擬健保卡 Token 格式錯誤';
            case 1521: return '虛擬健保卡 Token 已逾時';
            case 1522: return '虛擬卡 Token 格式不符';
            case 1523: return '虛擬卡 Token 驗簽失敗';
            case 1524: return '虛擬卡 Token 已逾期';
            case 1525: return '虛擬卡 Token 已超過驗證次數';
            case 1526: return '虛擬卡無法呼叫 PIN 相關函式';
            case 1527: return '呼叫的功能僅支援虛擬健保卡';
            case 1528: return '找不到虛擬健保卡轉移暫存資料';
            case 1529: return '此虛擬健保卡轉移已被其它狀置使用';
            case 1530: return '虛擬健保卡轉移只能使用在同一家醫事機構';
            case 1531: return '虛擬健保卡已轉移或已失效';
            //1600 - 1699 設定相關異常代碼';
            case 1600: return '使用讀卡機名稱無法查詢到 PCSC 讀卡機';
            case 1601: return '自動偵測預設讀卡機失敗';
            case 1610: return '雲端安全模組檔案格式錯誤';
            case 1611: return '沒有查詢到雲端安全模組編號';
            //1700 - 1799 DC 有關錯誤碼';
            case 1700: return '與 DC 通訊連線異常';
            case 1701: return '與 DC 通訊命令代碼不存在';
            case 1702: return '與 DC 通訊回覆 HTTP 相關異常代碼';
            case 1703: return 'Session 不存在或已逾期';
            case 1704: return '參數格式異常';
            case 1705: return '參數格式異常';
            case 1706: return '參數格式異常';
            case 1707: return 'HCA 卡片已登出或在其它主機登入';
            case 1799: return 'DC 端未定義錯誤';


        }
    }
    take(str: string, start: number, end: number) {
        var sIndex = start - 1;
        var eIndex = end;
        // //var takeLen = end - start+1;
        // var sb = str.substring(sIndex,eIndex);
        // for(let c of sb){
        //     if(/[\u4E00-\u9FFF]/g.test(c)){
        //         eIndex--;
        //     }
        // }
        return str.substring(sIndex, eIndex);
    }
}

declare type CSHIS6Output = {
    statusCode:number;
}

interface IConvertToCsHis<T>{
    /** 轉換為cshis5157資料結構的方法 */
    toCsHis():T;
}
//#region CSHIS6.0 Response Mapping Classes
class CsHis6BasicData implements IConvertToCsHis<BasicData> {
    /** 卡片編號 */
    cardSn:string;
    /** 姓名 */
    name: string;
    /** 身分證號或身分證明文件編號 */
    idCardNum: string;
    /** 出生日期 yyyMMdd */
    birthday: string;
    /** 性別 M: 男性,F: 女性 */
    sex: string;
    /** 發卡日期 */
    dateIssue: string;
    /** 卡片註銷註記  1: 有效卡,2: 註銷卡*/
    cancelTermination: string;
    /** 緊急聯絡電話  */
    emergentTel: string;
    
    toCsHis(): BasicData {
        var ret = this;
        return {
            IcNo: ret.cardSn.trim(),
            Name: ret.name.trim(),
            CId: ret.idCardNum.trim(),
            Birth: DateHelper.parseROCDate(ret.birthday.trim()),
            Sex: ret.sex,
            Issuedate: DateHelper.parseROCDate(ret.dateIssue),
            CancellationFlag: ret.cancelTermination.trim(),
            RelationPhone: ret.emergentTel.trim()
        };
    }

}

class CsHis6RegisterBasic implements IConvertToCsHis<RegisterBasic> {
    /** 卡片編號 */
    cardSn:string;
    /** 姓名 */
    name: string;
    /** 身分證號或身分證明文件編號 */
    idCardNum: string;
    /** 出生日期 yyyMMdd */
    birthday: string;
    /** 性別 M: 男性,F: 女性 */
    sex: string;
    /** 發卡日期 */
    dateIssue: string;
    /** 卡片註銷註記  1: 有效卡,2: 註銷卡*/
    cancelTermination: string;
    /** 保險對象種類代碼  RFU  */
    insurerCode: string;
    /**保險對象身分註記 */
    insurerId:string;
    /**  卡片效期  */
    cardValidity:string;
    /** 就醫可用次數  */
    treatmentCounter:number;
    /** 新生兒出生日期 yyyMMdd */
    newbornBirthday: string;
    /** 胞胎註記 */
    newbornBaby: string;

    toCsHis(): RegisterBasic {
        var ret = this;
        return {
            IcNo: ret.cardSn.trim(),
            Name: ret.name.trim(),
            CId: ret.idCardNum.trim(),
            Birth: DateHelper.parseROCDate(ret.birthday.trim()),
            Sex: ret.sex.trim(),
            Issuedate: DateHelper.parseROCDate(ret.dateIssue.trim()),
            CancellationFlag: ret.cancelTermination.trim(),
            ICode: ret.insurerCode.trim(),
            InsurerFlag: ret.insurerId.trim(),
            CardExpirationDate: DateHelper.parseROCDate(ret.cardValidity.trim()),
            AvailableCount: ret.treatmentCounter.toString(),
            NewbornBirthDate: DateHelper.parseROCDate(ret.newbornBirthday.trim()),
            NewbornBirthNum: ret.newbornBaby?.trim()??''
        }
    }
}

class CsHis6RegisterBasic2 implements IConvertToCsHis<RegisterBasic2> {
    /**  卡片效期  */
    cardValidity:string;
    /** 就醫可用次數  */
    treatmentCounter:number;
    toCsHis(): RegisterBasic2 {
        var ret = this;
        return {
            CardExpirationDate: DateHelper.parseROCDate(ret.cardValidity.trim()),
            AvailableCount: ret.treatmentCounter.toString(),
        }
    }
}

class CsHis6LastSeqNum implements IConvertToCsHis<LastSeqNum> {
    /** 最近一次就醫序號  */
    sequenceNumber:string;
    toCsHis(): LastSeqNum {
        var ret = this;
        return {
            // yyy1234
            LastSeqNumData:this.sequenceNumber.padStart(7,'0'),
        }
    }
}

class CsHis6RegisterPrevent implements IConvertToCsHis<RegisterPrevent> {
    models:{
        /** 保健服務項目註記 */
        serviceItem:string,// "01",
        /** 檢查日期 */
        serviceDate:string,// "1130101",
        /** 醫療院所代碼 */
        hospitalId:string,// "7777777777",
        /** 檢查項目代碼 */
        serviceCode:string,// "11"

    }[];
    toCsHis(): RegisterPrevent {
        var ret = this;
        var toSet:(i)=>RegisterPreventSubset = (i)=>{
            var m = this.models[i];
            if(m){
                return {
                    ClinicCode:m.hospitalId,
                    ExamDate:DateHelper.parseROCDate(m.serviceDate),
                    HealthCareFlag:m.serviceItem,
                    ProjectCode:m.serviceCode
                }
            }else{
                return {
                    ClinicCode:'',
                    ExamDate:null,
                    HealthCareFlag:'',
                    ProjectCode:''
                };
            }
        };
        return {
            Set1:toSet(0),
            Set2:toSet(1),
            Set3:toSet(2),
            Set4:toSet(3),
            Set5:toSet(4),
            Set6:toSet(5),
        }
    }
}

class CsHis6RegisterInoculate implements IConvertToCsHis<Inoculate> {
    inoculates:{
        /** 預防接種種類 */
        inoType:string;
        /** 預防接種日期 */
        inoDate:string,// "1130101",
        /** 醫療院所代碼 */
        hospitalId:string,// "7777777777",
        /** 批號 */
        packageNumber:string,// "42"

    }[];
    toCsHis(): Inoculate {
        var ret = this;
        var a = new Inoculate();
        a.Data = ret.inoculates.map(x=> {
            var r = new InoculateData()
            r.ClinicCode = x.hospitalId;
            r.InoculateDate = DateHelper.parseROCDate( x.inoDate);
            r.Type = x.inoType;
            r.LotNumber = x.packageNumber;
            return r;
        }) 
        return a;
    }
}

class CsHis6RegisterPregnant implements IConvertToCsHis<RegisterPregnant> {
    models:{
        /** 檢查日期 */
        checkDate:string,// "1130101",
        /** 醫療院所代碼 */
        hospitalId:string,// "7777777777",
        /** 檢查項目代碼 */
        itemCode:string,// "42"

    }[];
    toCsHis(): RegisterPregnant {
        var ret = this;
        var a = new RegisterPregnant();
        a.Data = ret.models.map(x=> {
            var r = new RegisterPregnantSubset()
            r.ClinicCode = x.hospitalId;
            r.ClinicDate = DateHelper.parseROCDate( x.checkDate);
            r.ExamCode = x.itemCode;
            return r;
        }) 
        return a;
    }
}

class CsHis6SeqNumber256N1 implements IConvertToCsHis<SeqNumber256N1> {
    /** 最近一次就醫序號  */
    sequenceNumber:string;
    treatmentDateTime:string;
    signature:string;
    samId:string;
    hospitalId: string;
    treatmentNumber: string;
    isSameDay: boolean;
    /** 重大傷病是否將屆  */
    hvExpire:boolean;
    /** 重大傷病是否將屆日期 */
    hvExpireDate: string;
    /** 住院次數提醒 */
    inpatientNotify:boolean;
    /** 高診次數提醒 */
    treatmentLimit: number;
    toCsHis(): SeqNumber256N1 {
        var ret = this;
        return {
            ClinicCode:ret.hospitalId,
            ClinicDateTime:DateHelper.parseROCDateTime(ret.treatmentDateTime),
            Number:ret.sequenceNumber,
            SamId:ret.samId,
            SameDay:ret.isSameDay?'Y':'N',
            SecuritySeal:ret.signature,
            TreatId:ret.treatmentNumber,
            /** 重大傷病是否將屆 */
            hvExpire: ret.hvExpire,
            /** 重大傷病是否將屆日期 */
            hvExpireDate: ret.hvExpireDate,
            /** 住院次數提醒 */
            inpatientNotify: ret.inpatientNotify,
            /** 高診次數提醒 */
            treatmentLimit: ret.treatmentLimit,
        }
    }
}

class CsHis6TreatNumNoICCard implements IConvertToCsHis<TreatNumNoICCard> {
    treatmentNumber: string;
    treatmentDateTime:string;
    hospitalId: string;
    toCsHis(): TreatNumNoICCard {
        var ret = this;
        return {
            TreatNum:ret.treatmentNumber,
            DateTime:DateHelper.parseROCDateTime(ret.treatmentDateTime),
            HospCode:ret.hospitalId
        }
    }
}
class CsHis6TreatNumICCard implements IConvertToCsHis<string> {
    treatmentNumber: string;
    toCsHis(): string {
        var ret = this;
        return this.treatmentNumber
    }
}

class CsHis6OrganDonate implements IConvertToCsHis<OrganDonate> {
    organDonate: string;
    toCsHis(): OrganDonate {
        var ret = this;
        return {
            OrganDonateNote:this.organDonate
        }
    }
}
class CsHis6EmergentTel implements IConvertToCsHis<EmergentTel> {
    tel: string;
    toCsHis(): EmergentTel {
        var ret = this;
        return {
            RelationPhone:this.tel
        }
    }
}
class CsHis6CumulativeData implements IConvertToCsHis<CumulativeData> {
    costPayments: {
        /** 門診就醫年度 */
        outpatientYear: string;
        /** 門住診就醫累計次數 */
        hospitalVstCumulativeCounter: number;
        /** 門診部分負擔費用累計 */
        outpatientFee: number;
        /** 住診急性 30 天、慢性 180 天以下部分負擔費用累計 */
        inpatient30Fee: number;
        /** 住診急性 31 天、慢性 181 天以上部分負擔費用累計 */
        inpatient180Fee: number;
        /** 門診+住診部分負擔費用累計 */
        cumulativeFee: number;
        /** [門診+住診(急性 30天、慢性 180 天以下)]部分負擔費用累計 */
        cumulative180Fee: number;
        /** 門診醫療費用累計 */
        totalPaymentOutpatientFee: number;
        /** 住診醫療費用累計 */
        totalPaymentInpatientFee: number;
    }[];
    toCsHis(): CumulativeData {
        var ret = this;
        var convert = (i:number):Cumulative=>{
            return {
                CimulativeCount:ret.costPayments[0]?.hospitalVstCumulativeCounter.toString(),
                DeductibleSum:ret.costPayments[0]?.outpatientFee.toString(),
                HdeductibleASum:ret.costPayments[0]?.inpatient30Fee.toString(),
                HdeductibleBSum:ret.costPayments[0]?.inpatient180Fee.toString(),
                HospitalFeesSum:ret.costPayments[0]?.totalPaymentInpatientFee.toString(),
                MedicalFeesSum:ret.costPayments[0]?.totalPaymentOutpatientFee.toString(),
                MHdeductibleASum:ret.costPayments[0]?.cumulative180Fee.toString(),
                MHdeductibleSum:ret.costPayments[0]?.cumulativeFee.toString(),
                Year:ret.costPayments[0]?.outpatientYear
            }
        }
        return {
            Set1:convert(0),
            Set2:convert(1)
        }
    }
}
class CsHis6CumulativeFee implements IConvertToCsHis<CumulativeFee> {
    /** 門診醫療費用總累計 */
    everPayOutpatient:number;
    /** 住診醫療費用總累計 */
    everPayInpatient:number;

    toCsHis(): CumulativeFee {
        var ret = this;
        return {
            HospitalFeesSum:this.everPayInpatient.toString(),
            MedicalFeesSum:this.everPayOutpatient.toString()
        }
    }
}
class CsHis6WriteMultiPrescriptSign implements IConvertToCsHis<string[]> {
    signatureItems:string[]
    toCsHis(): string[]{
        var ret = this;
        return ret.signatureItems;
    }
}

class CsHis6CriticalIllnessData implements IConvertToCsHis<CriticalIllnessData> {
    
    criticalIllnesses:{
        ciCode:string;
        validityStart:string,
        validityEnd:string
    }[];
     /** 牙醫特殊服務項目醫療服務試辦計畫加成註記 */
     dentistMark:string;
     /** 罕見疾病  (本欄位之註記 Y 者，為全民健康保險重大傷病罕病個案)) */
     rareDisease:string;
     /** 油症註記 (本欄位之註記 Y 或 A者，為衛生福利部國民健康署提供之個案) */
     yuchengDisease:string;
     /** 保留欄位 */
     rfu:string;
     /** 指定就醫 (指定至特定保險醫事服務機構就醫註記) */
     restrictionTreatment:string;
    toCsHis(): CriticalIllnessData{
        var ret = this;
        
        var getMajorInj = (x:number):CriticalIllness=>{
            if(!ret.criticalIllnesses[x]) return null;
            var t = ret.criticalIllnesses[x];
            return {
                Code:t.ciCode,
                StartDate:DateHelper.parseROCDateTime(t.validityStart),
                EndDate:DateHelper.parseROCDateTime(t.validityEnd),
            }
        }
        return {
            dentistMark:ret.dentistMark,
            rareDisease:ret.rareDisease,
            yuchengDisease:ret.yuchengDisease,
            rfu:ret.rfu,
            restrictionTreatment:ret.restrictionTreatment,
            CriticalIllness1:getMajorInj(0),
            CriticalIllness2:getMajorInj(1),
            CriticalIllness3:getMajorInj(2),
            CriticalIllness4:getMajorInj(3),
            CriticalIllness5:getMajorInj(4),
            CriticalIllness6:getMajorInj(5),
        };
    }
}
class CsHis6TreatmentNoNeedHPC implements IConvertToCsHis<TreatmentNoNeedHPC> {
    treatments:{
        /** 就醫類別 */
        treatmentItem: string;
        /** 新生兒就醫註記 */
        babyTreatment: string;
        /** 就診時間 */
        treatDateTime: string;
        /** 醫事機構 */
        hospitalId: string;
        /** 補卡註記 */
        afterCheck: string;
        /** 就醫序號 */
        hospitalVisit: string;
        /** 門診醫療費用 */
        outpatientFee: number;
        /** 住院醫療費用 */
        inpatientFee: number;
        /** 住院部份負擔30 <180 */
        inpatient30Fee: number;
        /** 住院部份負擔31 >180 */
        inpatient180Fee: number;
        /** 門診部分負擔費用 */
        costFee: number;
    }[];
    criticalIllnesses:{
        validityStart:string,
        validityEnd:string
    }[];
    toCsHis(): TreatmentNoNeedHPC{
        var ret = this;
        var getTreatment = (x:number):TreatmentDataNoHPC=>{
            if(!ret.treatments[x]) return null;
            var t = ret.treatments[x];
            return {
                ClinicCode:t.hospitalId,
                ClinicDate:DateHelper.parseROCDateTime(t.treatDateTime),
                Deductible:t.costFee.toString(),
                MedicalFees:t.outpatientFee.toString(),
                HospitalFees:t.inpatientFee.toString(),
                HdeductibleA:t.inpatient30Fee.toString(),
                HdeductibleB:t.inpatient180Fee.toString(),
                Isqno:t.hospitalVisit,
                Itp:t.treatmentItem,
                NewbornFlag:t.babyTreatment,
                ReCardFlag:t.afterCheck
            }
        }
        var getMajorInj = (x:number):CriticalIllnesNoHPC=>{
            if(!ret.criticalIllnesses[x]) return null;
            var t = ret.criticalIllnesses[x];
            return {
                StartDate:DateHelper.parseROCDateTime(t.validityStart),
                EndDate:DateHelper.parseROCDateTime(t.validityEnd),
            }
        }
        return {
            TreatmentData1:getTreatment(0),
            TreatmentData2:getTreatment(1),
            TreatmentData3:getTreatment(2),
            TreatmentData4:getTreatment(3),
            TreatmentData5:getTreatment(4),
            TreatmentData6:getTreatment(5),
            MajorInjury1:getMajorInj(0),
            MajorInjury2:getMajorInj(1),
            MajorInjury3:getMajorInj(2),
            MajorInjury4:getMajorInj(3),
            MajorInjury5:getMajorInj(4),
            MajorInjury6:getMajorInj(5),
        };
    }
}

class CsHis6TreatmentNeedHPC implements IConvertToCsHis<TreatmentNeedHPC> {
    treatments:{
        /** 就診時間 */
        treatDateTime: string;
        mainCode:string;
        subCode1:string;
        subCode2:string;
        subCode3:string;
        subCode4:string;
        subCode5:string;
    }[];
    criticalIllnesses:{
        /** 重大傷病代碼 */
        ciCode:string,
        validityStart:string,
        validityEnd:string
    }[];
    /** 特別註記 */
    special: {
        /** 牙醫特殊服務項目醫療服務試辦計畫加成註記 */
        dentistMark:string;
        /** 罕見疾病  (本欄位之註記 Y 者，為全民健康保險重大傷病罕病個案)) */
        rareDisease:string;
        /** 油症註記 (本欄位之註記 Y 或 A者，為衛生福利部國民健康署提供之個案) */
        yuchengDisease:string;
        /** 保留欄位 */
        rfu:string;
        /** 指定就醫 (指定至特定保險醫事服務機構就醫註記) */
        restrictionTreatment:string;
    };
    toCsHis(): TreatmentNeedHPC{
        var ret = this;
        var getTreatment = (x:number):TreatmentDataHPC=>{
            if(!ret.treatments[x]) return null;
            var t = ret.treatments[x];
            return {
                ClinicDate:DateHelper.parseROCDateTime(t.treatDateTime),
                MainDisease:t.mainCode,
                SecDisease1:t.subCode1,
                SecDisease2:t.subCode2,
                SecDisease3:t.subCode3,
                SecDisease4:t.subCode4,
                SecDisease5:t.subCode5,
            }
        }
        var getMajorInj = (x:number):CriticalIllness=>{
            if(!ret.criticalIllnesses[x]) return null;
            var t = ret.criticalIllnesses[x];
            return {
                Code:t.ciCode,
                StartDate:DateHelper.parseROCDateTime(t.validityStart),
                EndDate:DateHelper.parseROCDateTime(t.validityEnd),
            }
        }
        return {
            TreatmentData1:getTreatment(0),
            TreatmentData2:getTreatment(1),
            TreatmentData3:getTreatment(2),
            TreatmentData4:getTreatment(3),
            TreatmentData5:getTreatment(4),
            TreatmentData6:getTreatment(5),
            MajorInjury1:getMajorInj(0),
            MajorInjury2:getMajorInj(1),
            MajorInjury3:getMajorInj(2),
            MajorInjury4:getMajorInj(3),
            MajorInjury5:getMajorInj(4),
            MajorInjury6:getMajorInj(5),
        };
    }
}

class CsHis6Prescription implements IConvertToCsHis<Prescription> {
    /** 過敏藥 */
    allergicMedicines:string[]
    /** 門診醫令 */
    outpatientPrescriptions:{
        /** 天數 */
        days:string,
        /** 交付註記 */
        deliveryNotes:string,
        /** 總量 */
        totalQuantity:string,
        /** 就診日期時間 */
        treatmentDateTime:string,
        /** 醫令類別 */
        treatmentItem:string,
        /** 藥品代碼 */
        treatmentItemCode:string,
        /** 診療部位 */
        treatmentPosition:string,
        /** 用法 */
        usage:string,
    }[];
    /** 長期醫令 */
    longTernPrescriptions:{
        /** 長期處方日期 yyyMMdd */
        ltpDate: string;
        /** 總量 */
        totalQuantity:string;
        /** 藥品代碼  */
        medCode:string;
        /** 用法 */
        usage:string;
        /** 天數 */
        days:string;
    }[];
    /** 重要醫令 */
    importTreatments:{
        /** 實施日期 */
        hvnDate:string;
        /** 實施部位代碼 */
        bodyCode:string;
        /** 總量 */
        totalQuantity:string;
        /** 重要醫令項目代碼 */
        examinCode:string;
        /** 醫療院所代碼  */
        hospitalId:string;
    }[];
    
    toCsHis(): Prescription{
        var ret = this;
        return {
            IrritationDrug: ret.allergicMedicines,
            OutPatient: ret.outpatientPrescriptions.map(x=>{
                return {
                    ClinicDateTime:DateHelper.parseROCDateTime(x.treatmentDateTime),
                    Days:x.days,
                    DispensingType:x.deliveryNotes,
                    Frequency:x.usage,
                    NhiDrugCode:x.treatmentItemCode,
                    OrderClass:x.treatmentItem,
                    TotalDose:x.totalQuantity,
                    TreatmentSite:x.treatmentPosition
                }
            }),
            LongTerm:ret.longTernPrescriptions.map(x=>{
                return {
                    CreateDate:DateHelper.parseROCDateTime(x.ltpDate),
                    Days:x.days,
                    Frequency:x.usage,
                    NhiDrugCode:x.medCode,
                    TotalDose:x.totalQuantity,
                }
            }),
            HVE:ret.importTreatments.map(x=>{
                return {
                    ImplementDate:DateHelper.parseROCDateTime(x.hvnDate),
                    ImplementPartCode:x.bodyCode,
                    ClinicCode:x.hospitalId,
                    Total:x.totalQuantity,
                    TreatmentCode:x.examinCode
                }
            }),
        };
    }
}
class CsHis6VirtualHcRequestToken implements IConvertToCsHis<string> {
    accessToken: string;
    toCsHis(): string {
        var ret = this;
        return this.accessToken
    }
}
class CsHis6VirtualHcResponseToken implements IConvertToCsHis<string> {
    virtualCardToken: string;
    toCsHis(): string {
        var ret = this;
        return this.virtualCardToken
    }
}

class CsHis6UploadDataResult implements IConvertToCsHis<UploadDataResult> {
    samId:string; // "901000000000",
    hospitalId:string; //": "7777777777",
    uploadDateTime:string; //"1130101123456",
    receiveDateTime:string; //"1130101123456",

    toCsHis(): UploadDataResult {
        
        return {
            HospId:this.hospitalId,
            RecieveDateTime:DateHelper.parseROCDateTime(this.receiveDateTime),
            RecieveNumber:0,
            RecieveSize:'',
            SAMId:this.samId,
            UploadDateTime:DateHelper.parseROCDateTime(this.uploadDateTime)
        }
    }
}
class CsHis6Encode implements IConvertToCsHis<string> {
    encCode:string
    toCsHis(): string{
        var ret = this;
        return ret.encCode;
    }
}
class CsHis6Decode implements IConvertToCsHis<string> {
    deCode:string
    toCsHis(): string{
        var ret = this;
        return ret.deCode;
    }
}
//#endregion
//#region CsHis6 Client data structure
export enum CsHis6HCStatusEnum{
    '未置入健保卡' = 0,
    '健保卡已置入' = 1,
    '健保卡已與SAM驗證' = 2,
    '健保卡已與HCA驗證' = 3
}
export var CsHis6HCStatusEnumName = [
    '未置入健保卡' ,
    '健保卡已置入' ,
    '健保卡已與SAM驗證' ,
    '健保卡已與HCA驗證',
]
export enum CsHis6HPCStatusEnum{
    '未置入醫事人員卡' = 0,
    '醫事人員卡已置入' = 1,
    '醫事人員卡已驗證' = 2,
    '醫事人員卡已驗PIN' = 3
}
export var CsHis6HPCStatusEnumName=[
    '未置入醫事人員卡' ,
    '醫事人員卡已置入' ,
    '醫事人員卡已驗證' ,
    '醫事人員卡已驗PIN',
]
export enum CsHis6HCTypeEnum{
    '無健保卡' = 0,
    '實體卡' = 1,
    '虛擬卡' = 2,
}
export enum CsHis6SAMStatusEnum{
    '未置入SAM卡' = 0,
    'SAM已置入' = 1,
    'SAM已驗證' = 2,
}
export var CsHis6SAMStatusEnumName=[
    '未置入SAM卡',
    'SAM已置入',
    'SAM已驗證',
]
export enum CsHis6SAMTypeEnum{
    '無SAM' = 0,
    '雲端安全模組' = 1,
    '實體安全模組' = 2,
}
export var CsHis6SAMTypeEnumName = [
    '無SAM',
    '雲端安全模組',
    '實體安全模組'
]
export enum CsHis6HPCTypeEnum{
    '無醫事人員卡' = 0,
    '醫師卡' = 1,
    '醫事人員卡' = 2,
    '醫事人員行動憑證' = 3,
}

interface CsHis6Status{
 statusCode: number;
 status: {
    initialized: true,
    name: string,
    readerName: string,
    hc: {
        status: CsHis6HCStatusEnum;
        type: CsHis6HCTypeEnum; 
        expireTime: string;
        hasPin: boolean;
        verifiedPin:boolean;
    },
    hpc: {
        status: CsHis6HPCStatusEnum; 
        type: CsHis6HPCTypeEnum; 
        expireTime: string;
    },
    sam: {
        status: CsHis6SAMTypeEnum; 
        type: CsHis6SAMTypeEnum; 
        expireTime: string
    },
 }
}

interface HCSignature{
    clientRandom:string;
    hospitalId:string;
    samId:string;
    signature:string;
    hcId:string;
    hcIdNo:string;
}

interface HPCSignature{
    clientRandom:string;
    signature:string;
    samId:string;
    hospitalId:string;
    serviceType:string;
    hpcId:string;
    hpcIdNo:string;
}
interface SAMSignature{
    clientRandom:string;
    hospitalId:string;
    samId:string;
    signature:string;
    serviceType:string;
}
/** 需實作包含轉換為5157資料結構的方法的型別 */
type Constructor<T2> = { 
    new (): IConvertToCsHis<T2>
    //toCsHis():T2;
};

enum SignatureServiceTypeEnum{
    _01_HC_READ='01',
    _02_HC_WRITE='02',
    _03_HC_Number='03',
    _04_HC_UPDATE='04',
    _05_UPLOAD = '05',
    /**(Web API)(類似 API 1.56hisGetTreatNumICCard 功能) */
    _11_SAM_取得就醫識別碼='11',
    /**(Web API)之健保測試卡使用 */
    _12_SAM_取得就醫識別碼='12',
    /** (同 API 1.12hisGetOrganDonate 功能) */
    _13_HC_讀取同意器官捐贈及安寧緩和醫療註記='13',
    /** (同 API 1.33 hisGetCriticalIllness 功能)，使用健保卡簽章只能查詢重大傷病有效期，使用三卡簽章可查詢完成重大傷病。 */
    _14_HC_HPC_讀取重大傷病註記資料='14',
    /** (類似 API 1.56 hisGetTreatNumICCard 功能) */
    _15_SAM_取得就醫識別碼='15',
    /** (同 API 1.55 hisQuickInsurence 功能) */
    _16_HC_在保狀態查核='16',
    /** (類似 API 1.54 hisGetTreatNumNoICCard 功能) */
    _17_SAM_取得就醫識別碼='17',

    _20_HC_電子轉診平台單一轉診個案即時查詢='20',
    _21_HPC_空轉審核中心緊急醫療單一個案即時下載='21',
    _22_SAM_COVID19疫苗接種名冊比對作業='22',
    _30_SAM_醫療資訊傳輸服務='30',
    _90_HC_健保醫療資訊雲端查詢系統單一個案即時下載作業 = '90',
}


declare type SAMStatus = {
    /** SAM卡號 */
    samId:string,
    /** 醫事機構帶碼 */
    hospitalId:string,
    /** 醫事機構名稱 */
    hospitalName:string,
    /** 醫事機構簡稱 */
    hospitalShortName:string,
}
declare type HPCStatus = {
    /** 卡號 */
    cardId:string,
    /** 身分證 */
    idNo:string,
    /** 中文姓名 */
    cName:string,
    /** 英文姓名 */
    eName:string,
    /** 生日 YYYY-MM-DD */
    birthday:string,
}

enum HCIdentityStatusEnum{
    _1_福民 = '1',
    _2_榮民 = '2',
    _3_一般 = '3',
    _4_中低收入 = '4'
}
declare type HCStatus = {
    /** 卡號 */
    cardId:string,
    /** 中文姓名 */
    name:string,
    /** 身分證 */
    idNo:string,
    /** 生日 YYYMMDD */
    birthday:string,
    /** 性別 */
    sex:'F'|'M';
    /** 發卡日期 */
    dateIssue: string;
    /** 身分註記 */
    identityStatus:HCIdentityStatusEnum;
    
}
declare type VersionInfo={
    /** 檔案版本? */
    fileVersion:string;// "1.2.0.1",
    /** 短版本編號 */
    shortVersion:string;// "60",
    /**長版本編號 */
    longVersion:string;// "6000",
    /**目前元件存放路徑 */
    location:string;// "C:\\Program Files (x86)\\nhi\\cshis6\\ciet6001s01.exe",
}

//#endregion