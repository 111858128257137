import { Component, OnInit, Input, ViewChildren, QueryList, ViewChild, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { truncate } from 'fs';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { MutiTreatmentListDto } from 'src/app/services/api-service/register/mutiTreatmentListDto';
import { RegisterApi } from 'src/app/services/api-service/register/register-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { InitmultiTreatmentUserData, RehabituserCids } from '../model/rehabitusers';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';

class SelectOrders {
    Order:HistOrder;
    RxCode: string = '';
    ProdName: string = '';
    Checked: boolean = false;
}

@Component({
    selector: 'app-rehabilitation-order',
    templateUrl: './rehab-order.component.html',
    styleUrls: ['./rehab-order.component.css']
})

export class RehabilitationOrderComponent implements OnInit {
    @ViewChildren('rehab')
    rehabChk:QueryList<MatCheckbox>;
    rehabilitationOpt = [
        {key:'PT',value:'PT', checked: false},
        {key:'OT',value:'OT', checked: false},
        {key:'ST',value:'ST', checked: false},
        {key:'PRT',value:'PRT', checked: false},
        {key:'oTHER',value:'其他', checked: false}
      ];

    initMutiTreatmentList : MutiTreatmentListDto[] = [];
    initHistOrders : HistOrder[] = [];
    selectOrders : SelectOrders[] = [];
    isOpenOrders = false;
    isOpenUsers = false;
    constructor(    private notification: EasyNotificationService,
        private regApi: RegisterApi,
        private renderer: Renderer2, private el: ElementRef
        ) { }

    @Input()
    set InitRehabOrderData(data: MutiTreatmentListDto) {
        this.isOpenOrders = false;
        this.selectOrders  = [];
        if(!data) return;
        let whole  = data.HistList;
        if(data.HistList) {
          if (data.HistList.length > 0) {
            let firstWholehist = whole.find(x => x.Hist.CusNo == '1');
            
            if(!firstWholehist || data?.CusTP != '1')return;
            this.isOpenOrders = true;
            let firstDate = firstWholehist.Register.RegDate;
            let now = new Date();
            let isTomorrow = this.dayDiff(firstDate,now) <= 1;
            // console.log('isTomorrow',isTomorrow,this.dayDiff(firstDate,now));
              this.regApi.GetInitHRx(firstWholehist.Hist.HRxs).then(
                (result: HistOrder[]) => {
                    if (result.length > 0){
                        for (let i = 0; i < result.length; i++) {
                            if(result[i].RxCode.startsWith('480') && !isTomorrow) break;
                            let selectOrder  = new SelectOrders();
                            selectOrder.Order = result[i];
                            selectOrder.RxCode = result[i].RxCode;
                            selectOrder.ProdName = result[i].ProdName;
                            selectOrder.Checked = true;
                            this.selectOrders = [...this.selectOrders, selectOrder];
                            // 在这里对 currentItem 进行操作
                        }
                        this.rehabilitationOpt.forEach(item => {
                            const checkbox = this.el.nativeElement.querySelector(`[value="${item.key}"]`);
                            item.checked = true;
                            this.renderer.setProperty(checkbox, 'checked', item.checked);
                        });
                        var historders = this.selectOrders.filter(x => x.Checked == true).map(x => x.Order)
                        this.onEmitHistOrderResult.emit(historders);
                    }
                // console.log('result',result);
                // console.log('initHistOrders',this.initHistOrders);
            });
          }
        }

    }
    @Input()
set InitmultiTreatmentUser(biguserData:InitmultiTreatmentUserData){
  this.Users = new RehabituserCids();

  if(!biguserData?.Users) return;
  var empty = {text:'無',value: ''}
  this.RPers = biguserData.Users.filter(x => x.PositionIds.includes(5)).map(user => { return {text: user.Name, value: user.CId}});
  this.ROers = biguserData.Users.filter(x => x.PositionIds.includes(17)).map(user => { return {text: user.Name, value: user.CId}});
  this.RSers = biguserData.Users.filter(x => x.PositionIds.includes(18)).map(user => { return {text: user.Name, value: user.CId}});
  this.PRTers = biguserData.Users.filter(x => x.PositionIds.includes(13)).map(user => { return {text: user.Name, value: user.CId}});
  this.RPers.push(empty);
  this.ROers.push(empty);
  this.RSers.push(empty);
  this.PRTers.push(empty);
  // this.rehabitFormGroup = this.makeFormGroup();
  this.Users.OTCid =  '';
  this.Users.STCid =  '';
  this.Users.PTCid ='';
  this.Users.PRTCid =  '';
  let whole  = biguserData.Data.HistList;

  this.getinitselectUser(whole);
}
@Input() isEditDisabled = false;
RSers :  ValueTextPair[] = [];
ROers :  ValueTextPair[] = [];
RPers :  ValueTextPair[] = [];
PRTers :  ValueTextPair[] = [];
Users : RehabituserCids;

    @Output() onEmitHistOrderResult = new EventEmitter<HistOrder[]>();
    @Output() onEmitUsers = new EventEmitter<RehabituserCids>();

    ngOnInit() {

    }
    isSomeChecked(){
        return  this.selectOrders.some(x=>x.Checked)

    }
    rehabitMedUsersChg(value:ValueTextPair){
        // this.Users.PTCid = this.rehabitFormGroup.controls.PTers.value != '' ? this.rehabitFormGroup.controls.PTers.value :'';
        this.onEmitUsers.emit(this.Users);
      }
    dayDiff(inputDate1st: Date, inputDate2nd:Date):number{
        let date1st = (new Date(inputDate1st));
        let date2nd = (new Date(inputDate2nd));
        let dateFrist = new Date(date1st.getFullYear(), date1st.getMonth(), date1st.getDate(), 0, 0, 0);
        let dateSecond = new Date(date2nd.getFullYear(), date2nd.getMonth(), date2nd.getDate(), 0, 0, 0);
        let timeDifference = dateFrist.getTime() - dateSecond.getTime();
        return  Math.abs(timeDifference / (1000 * 60 * 60 * 24));
    }

    getinitselectUser(data: WholeHist[]){
        this.isOpenUsers = false;

        var hist = data.map(x => x.Hist);
        if (hist.length < 1) return;
         
        var hRxs = hist.find(x => x.CusNo == '1')?.HRxs || [];
        if (hRxs.length < 1) return;
        this.isOpenUsers = true;
        for (var order of hRxs) {
          if (order?.RxCode.startsWith('420')) {
            this.Users.PTCid = order?.MedID ?? '';

            }
            if (order?.RxCode.startsWith('430')) {
              this.Users.OTCid = order?.MedID ?? '';

            }
            if (order?.RxCode.startsWith('440')) {
              this.Users.STCid = order?.MedID ?? '';

            }
            if (order?.RxCode.startsWith('445')) {
              this.Users.PRTCid = order?.MedID ?? '';

            }
          }
          this.onEmitUsers.emit(this.Users);
      }
    checkChange(evt:MatCheckboxChange){
        // console.log('evt',evt);
        var ckeckval = evt.source.value;
        switch (ckeckval){
            case 'PT':
                this.getTypeOrder('PT','420',evt.checked);
                break;
            case 'OT':
                this.getTypeOrder('OT','430',evt.checked);
                break;
            case 'ST':
                this.getTypeOrder('ST','440',evt.checked);
                break;
            case 'PRT':
                this.getTypeOrder('PRT','445',evt.checked);
                break;
                case 'oTHER':
                this.getTypeOrder('OTHER','',evt.checked);
                break;
              default:
                break;
            }
        var historders = this.selectOrders.filter(x => x.Checked == true).map(x => x.Order)
        this.onEmitHistOrderResult.emit(historders);
        this.isSomeChecked();
    }
    getTypeOrder(type:string,type2:string,isChecked: boolean )
    {

        var item  = this.selectOrders.filter(x => x.RxCode.startsWith(type) || x.RxCode.startsWith(type2))
        if (type == 'OTHER'){
            var ty = ['PRT','ST','OT','PT','420','430','440','445']
            item  = this.selectOrders.filter(x => x.RxCode.startsWith(type))
            item = this.selectOrders.filter(it => {
                const prefixesToExclude = ['PRT', 'ST', 'OT', 'PT', '420', '430', '440', '445'];
                return !prefixesToExclude.some(prefix => it.RxCode.startsWith(prefix));
            });
        }

        if(item)
        {
            for (let i = 0; i < item.length; i++) {
                // let selectOrder  = new SelectOrders();

                // selectOrder.Order = item[i].Order;
                // selectOrder.RxCode = item[i].RxCode;
                item[i].Checked = isChecked;
                // if(isChecked){
                //     if(this.selectOrders.find(x=>x.RxCode == selectOrder.RxCode)){
                //         //this.notify.showWarning('此筆資料已加入')
                //         return
                //     }
                //     // else this.selectOrders.push(selectOrder);
                // }
                // // 取消
                // else{
                //     this.selectOrders = this.selectOrders.filter(x=>x.RxCode != selectOrder.RxCode);
                // }
                // 在这里对 currentItem 进行操作
            }
        }

    }
}
function OutPut(target: RehabilitationOrderComponent, propertyKey: 'ngOnInit', descriptor: TypedPropertyDescriptor<() => void>): void | TypedPropertyDescriptor<() => void> {
    throw new Error('Function not implemented.');
}

