import { query } from '@angular/animations';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { UserConfirmService } from 'src/app/services/user-confirm.service';

@Component({
    selector: 'app-drugs-selector',
    templateUrl: './drugs-selector.component.html',
    styleUrls: ['./drugs-selector.component.css']
})
export class DrugsSelectorComponent implements OnInit, OnDestroy {

    // @Input() InitData: HistOrder[] = [];
    @Input() set InitData(val: HistOrder[]) {
        if (val) {
            this.gridView.data = val;
            this.gridView.total = val.length;
        } else {
            this.gridView.data = [];
            this.gridView.total = 0;
        }
      }

    @Input() isOpenSelector: boolean = false;
    @Output() onEmitResult = new EventEmitter<HistOrder[]>();
    @Output() closeDialog = new EventEmitter();    // close dialog only

    gridView = { data: [], total: 0 };
    selectedItems: number[] = [];

    constructor(private notification: EasyNotificationService,
        private userConfirmService: UserConfirmService) 
        { }

    ngOnInit() { }

    onSureClick() {
        var retData: HistOrder[] = [];
        if (this.selectedItems.length > 0) {
            this.selectedItems.forEach(id => {
                var odr = (this.gridView.data.find(x => x.Id == id)) as HistOrder;
                retData.push(odr);
            });
        }
        this.onEmitResult.emit(retData);
        this.isOpenSelector = false;
    }

    onCloseClick() {
        this.isOpenSelector = false;
    }

    /** 項目勾選變化事件 */
    onCheckChanged(evt: { checked: boolean, data: HistOrder }) {
        if (evt.data) {
            let hid = evt.data.Id;
            // console.log("selected Id ?>>>>>>", hid);
            let hasId = this.selectedItems.some(id => id == hid);
            if (evt.checked) {
                if (!hasId) this.selectedItems.push(hid);
            } else {
                this.selectedItems = this.selectedItems.filter(id => id != hid);
            }
        }
    }

    ngOnDestroy() {
        
    }
}