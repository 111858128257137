import { Injectable } from '@angular/core';
import { StandardErrorMessage } from '../shared/components/base/base.edit.component';
import { NullOrEmpty } from '../shared/utilities';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {
  //判斷訊息是否為標準化的錯誤訊息
  public isStandardErrorMessage(error: any): error is StandardErrorMessage {
    return (error.errMessage !== undefined) && (typeof error.errMessage === 'string');
  }

  //處理錯誤，如果符合標準格式，就返回應顯示的訊息，如果不符合就直接回傳錯誤
  public handleError(error: any): any {
    const message = error?.message;
    if (NullOrEmpty(message)) return error;

    if (this.isStandardErrorMessage(message)) {
      return message.errMessage;
    }
    else {
      if (message.MessageShow) return message.MessageShow;

      return message;
    }
  }
}
