

/**
 * 孕婦產前檢查資料  ★兒童預防保健服務挪用
 */ 
export class RegisterPregnantSubset {
    /**
     * 檢查日期
     */ 
    public ClinicDate: Date = new Date(0);
    /**
     * 醫療院所代碼
     */ 
    public ClinicCode: string = null;
    /**
     * 醫療院所名稱
     */ 
    public ClinicName?: string = null;
    /**
     * 檢查項目代碼
     */ 
    public ExamCode: string = null;
    /**
     * 檢查項目名稱
     */ 
    public ExamName?: string = null;
}
