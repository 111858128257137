import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { HelperEvent } from '../models/helper-event';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { HelperEventTypeEnum } from '../enums/helper-event-type-enum';

@Injectable()
export class HistUiEventService implements OnDestroy {
  constructor() { }

  //#region 參數 subject, api, window --------------------
  unsubscribe: Subject<void> = new Subject();

  private _isWindowOpened = false; // 目標視窗是否開啟

  // 接收到的input keyword
  fromSource = new BehaviorSubject<HelperEvent>(new HelperEvent());
  fromSource$ = this.fromSource.asObservable();

  // 視窗選擇的項目或動作=>要回傳給來源的選擇項目內容
  // 來源包括 Helper 與 HelperPad
  helperAction = new Subject<HelperEvent>();
  helperAction$ = this.helperAction.asObservable();

  //#endregion --------------------

  //#region send --------------------
  sendEvent(event: HelperEvent) {
    // console.log('ui-event - sendEvent');
    // console.log(event);
    this.fromSource.next(event);
    if(event.type !== HelperEventTypeEnum.Keyboard){
      this._lastEvent = event;
    }
  }

  sendKeyboard(event: KeyboardEvent) {
    const evt = HelperEvent.KeyboardEvent(event.key);
    this.sendEvent(evt);
  }
  _lastEvent:HelperEvent;
  get lastEvent() {
    return this._lastEvent;
    //return this.fromSource.getValue();
  }
  
  // 是否跟最後字元來自相同的source
  isSameSource(source: HelperSourceEnum): boolean {
    // console.log('isSameSource');
    const lastEvent = this.fromSource.getValue();
    if (!lastEvent || !lastEvent.source || lastEvent.source === HelperSourceEnum.NA) {
      // 若目前無任何keyword，回傳true，代表相同來源->因為不同來源會洗掉原本的東西，並停止後續流程
      return true;
    }
    const lastSource = lastEvent.source;
    if (source === lastSource) {
      return true;
    } else {
      return false;
    }
  }
  // 重設，有以下幾種情境
  // input: (1)按下Escape重設，input那邊會清空，(2) focus時重置
  // textarea: 重新click，就算是同一source也要reset
  reset() {
    this.sendEvent(HelperEvent.ResetEvent());
  }
  focus(sourceId: string,data:any = null) {
    // console.log('ui-event - focus - sourceId:' + sourceId);
    this.sendEvent(HelperEvent.FocusEvent(sourceId, data));
  }
  // blur跟reset差在blur會延遲一下
  blur() {
    // console.log('ui-event - blur: ' + this._isWindowOpened);
    // 需要延遲是因為，如果用滑鼠點擊helper時，原本元件失去focus會立刻reset inputValue，造成滑鼠點不到東西，所以延遲一下再關閉
    setTimeout(() => {
      this.reset();
    }, 300);

  }
  //#endregion --------------------

  //#region helper selection --------------------
  helperReturn(evt: HelperEvent) {
    this.helperAction.next(evt);
  }
  //#endregion --------------------

  //#region window control --------------------
  isWindowOpened() {
    return this._isWindowOpened;
  }
  setWindowOpened(opened: boolean) {
    this._isWindowOpened = opened;
  }
  //#endregion --------------------

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
