import { HistOrder } from './hist-order';
import { HistDiag } from './hist-diag';
import { IdHelper } from 'src/app/shared/helpers/guid-helper';
import { HistErrorCode } from './hist-error-code';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { RxClass } from './rx-class';
import { Entity } from 'src/app/shared/models/entity';
import { NullOrEmpty } from 'src/app/shared/utilities';
import { OrderTypeEnum } from 'src/app/enums/OrderTypeEnum';

export class Hist extends Entity {

  constructor() {
    super();
    this.PharmacistId = IdHelper.IdEmpty;
    this.Days = 0;
    this.TOT = 0;
    this.PrintCount = 0;
    //this.NHIDeclare = new NHIDeclare();
  }

  // properties
  Id: number;
  /**
   * 本張處方的總天數，以內服為主
   */
  //Days: number;
  /**
   * 主訴
   */
  CC: string;
  /**
   * 理學
   */
  PE: string;
  /**
   * 主診斷
   */
  Dx1: string;
  /**
   * 主診斷名
   */
  Dx1Name: string = "";
  Dx1Acute:number;
  /**
   * 次診斷
   */
  Dx2: string;
  /**
   * 次診斷名
   */
  Dx2Name: string;
  Dx2Acute:number;
  /**
   * 診斷3
   */
  Dx3: string;
  Dx3Name: string;
  Dx3Acute:number;
  /**
   * 診斷4
   */
  Dx4: string;
  Dx4Name: string;
  Dx4Acute:number;

  /**
   * 診斷5
   */
  Dx5: string = "";
  Dx5Name: string = "";
  Dx5Acute:number;

  /**
   * 診斷6
   */
  Dx6: string = "";
  Dx6Name: string = "";
  Dx6Acute:number;
  /**
   * 主手術
   */
  Sur1: string;
  /**
   * 主手術名
   */
  SurName: string;
  /**
   * 次手術1
   */
  Sur2: string;
  /**
  * 次手術2
  */
  Sur3: string;

  RxNo: string;
  ChiAsk: string;
  ChiLook: string;
  ChiPulse: string;
  ChiBianZheng: string;
  ChiTreatRule: string;
  IsRxFilled: boolean;
  PharmacistId: number;
  PharmacistCId: string;
  PharmacistName: string;
  PickUpDrugNo: string;
  IsGotMedicine: boolean;
  PrintCount: number;

  HRxs: HistOrder[];
  Diags: HistDiag[];
  DoctorId: number;
  DoctorCId: string;
  //#region Declare
  /**
   * 就醫日期
   */
  Date: Date;
    /**
   * 同療程開始日期
   */
  DateStart: Date;
  /**
   * 同療程結束日期
   */
  DateEnd: Date;
  /**
   * 健保身分
   */
  Iid: string;
  /**
   * 0憑證, 1押金, 2其他
   */
  IType: string;
  /**
   * 療程類別
   */
  CusTP: string;
  /**
   * 療程次別
   */
  CusNo: string;
  /**
   * 療程總次
   */
  CusTot: string;
  /**
   * 療程分類
   * */
  CusCl: string;
  /**
   * 健保卡年度
   */
  IYear: string;
  /**
   * 健保卡卡序
   */
  Isqno: string;
  /**
   * 原處方卡序(藥局用)
   */
  IsqnoOrig: string;
  /**
   * 醫療給付方案 居護：四階=N4、整合=NI、普通=NG (不同月可以更換)
   *             康復之家=L
   */
  PaySysA: string;
  /**
   * 勞保職業傷病
   */
  LABTP: string;
  /**
   * 總申報天數
   */
  Days: number;
  /**
   * 科別
   */
  Dept: string;
  /**
   * 醫師代號
   */
  DrID: string;
  /**
   * 藥師代號
   */
  PharID: string;
  /**
   * 0初1複2急
   */
  Emer: string;
  /**
   * T轉入, Y轉出, N非轉
   */
  Referral: string;
  /**
   * 轉出醫院(轉診轉出)
   */
  RefHosp: string;
  /**
   * 轉入醫院
   */
  InHosp: string;
  /**
   * 開立處方單位
   */
  RxHosp: string;

  /**
   * 檢驗交付單位
   */
  LabHosp: string ;
  /**
   * 交付對象機構代碼 指定收單藥局的機構代碼
   */
  //OutHost: string;

  /**
   * 資源共享註記
   */
  ShareNT: string;
  /**
   * 資源共享醫院
   */
  ShHosp: string;
  /**
   * 給付類別
   */
  PayTP: string;
  /**
   * 特定治療1
   */
  SPTX1: string;
  /**
   * 特定治療2 litem/d5
   */
  SPTX2: string;
  /**
   * 特定治療3 litem/d6
   */
  SPTX3: string;
  /**
   * 特定治療4 litem/d7
   */
  SPTX4: string;
  /**
   * 案件分類
   */
  Case: string;
  /**
   * 調劑方式
   */
  DispTP: string;
  /**
   * 診察費
   */
  ADx?: number = 0;
  /**
   * 藥費
   */
  ARx?: number = 0;
  /**
   * 內服藥費
   */
  ARx2?: number = 0;
  /**
   * 外用藥費
   */
  ARx3?: number = 0;
  /**
   * 注射藥費
   */
  ARx4?: number = 0;
  /**
   * 診療費
   */
  ATx?: number = 0;
  /**
   * 注射技術費
   */
  ATx5?: number = 0;
  /**
   * 檢驗費
   */
  ATx6?: number = 0;
  /**
   * 放射費
   */
  ATx7?: number = 0;
  /**
   * 復健治療費
   */
  ATx8?: number = 0;
  /**
   * 處置費
   */
  ATx9?: number = 0;
  /**
   * 手術費
   */
  ATx10?: number = 0;
  /**
   * 麻醉費
   */
  ATx11?: number = 0;
  /**
   * 材料費
   */
  AMat?: number = 0;
  /**
   * 輸血費
   */
  ATx13?: number = 0;
  /**
   * 透析費
   */
  ATx14?: number = 0;
  /**
   * 病房費
   */
  ATx15?: number = 0;
  /**
   * 管灌費
   */
  ATx16?: number = 0;
  /**
   * 嬰兒費
   */
  ATx17?: number = 0;
  /**
   * 藥事費
   */
  AMed?: number = 0;
  /**
   * 包裹實批
   */
  APack?: string = null;
  /**
   * 其他費1
   */
  A01?: number = 0;
  /**
   * 其他費2
   */
  A02?: number = 0;
  /**
   * 健保總額
   */
  ATot?: number = 0;
  /**
   * 部份負擔代號
   */
  ApartCode: string = "";
  /**
   * 部份負擔-藥品
   */
  APartRx: number = 0;
  /**
   * 藥局部份負擔-藥品
   */
  PPartRx: number = 0;
  /**
   * 部份負擔-門診
   */
  APartOPD: number = 0;
  /**
   * 部份負擔-檢查
   */
  APartExam: number = 0;
  /**
   * 自費總額
   */
  AOwn: number = 0;
  /**
   * 申請總額(健保總額-部分負擔)
  */
  ADcl: number = 0;
  /**
   * 論件計酬
   */
  Drgs: string;
  //#endregion

  IsEdit: string;

  // No used
  TOT: number = 0;

  SpecAreaSV: string;

  SupArea: string;
  /** 是否寫IC */
  IsCardWrite: boolean;
  /** 是否啟用衛星藥局 */
  useSatellitePharmacy: boolean;
  /** 是否啟用衛星藥局IP */
  hasSatellitePharmacyIP: boolean;
  /** 是否啟用EMR */
  useEmr: boolean;
  /** EMR路徑 */
  emrPath: string;
  /** 是否啟用巡診 */
  useOutVisit: boolean;

  /**
   * 慢箋分類
   * 強制慢箋免藥自付(停經症候群開藥21日)=01
  */
  CntPresCl: string = "";
  /** 慢箋次別 */
  CntPresNo: string = "";
  /** 慢箋總次 */
  CntPresTot: string = "";
  /** 慢箋開始日 */
  CntPresDate: Date;
  /** 欠卡讀取的就醫識別碼 */
  LackTreatnum: string;
  /** ↑讀取時間(欠卡讀取的就醫識別碼) */
  LackDate:Date;
  PHCScCode:string ='';
  PHCResult:string;
  /** 醫療院所 夜間加成 */
  UrgentNight: string = '';
  /** 醫療院所 假日加成 */
  Urgent: string = '';
  /** 居護加成 'V' or 'X' or '' */
  UrgentHC: string = '';
  /** 部份負擔描述 */
  ApartCodeDescription:string = '';
  /** 擴充欄位 */
  SPExtension:string = '';
  /** 代檢原醫師 */
  OriLabDcotor:string;
  /** 代檢原機構 */
  OriLabHosp:string;
  /** 代檢原就診時間 */
  OriLabRegDate?:Date;
  // #region static functions --------------------
  // 尋找重複的字串，並回傳重複的項目，以逗號分隔
  static findDuplicated(items: string[]): string {
    let dulplicated = [];
    if (items) {
      const _items = items.filter(x => !NullOrEmpty(x));
      const itemObj = {};
      _items.forEach(item => {
        itemObj[item] = itemObj[item] > 0 ? itemObj[item] + 1 : 1;
      });
      for (const prop in itemObj) {
        if (itemObj[prop] > 1) {
          dulplicated.push(prop);
        }
      }
    }
    return dulplicated.join(',');
  }
  // #region static functions --------------------

  // #region Diags --------------------
  // 把Diagnosis 1-6放入diag array
  createDiags() {
    this.Diags = [];
    if (this.Dx1 && this.Dx1 !== '') {
      this.Diags.push(HistDiag.create(1, this.Dx1, this.Dx1Name,this.Dx1Acute));
    }
    if (this.Dx2 && this.Dx2 !== '') {
      this.Diags.push(HistDiag.create(2, this.Dx2, this.Dx2Name,this.Dx2Acute));
    }
    if (this.Dx3 && this.Dx3 !== '') {
      this.Diags.push(HistDiag.create(3, this.Dx3, this.Dx3Name,this.Dx3Acute));
    }
    if (this.Dx4 && this.Dx4 !== '') {
      this.Diags.push(HistDiag.create(4, this.Dx4, this.Dx4Name,this.Dx4Acute));
    }
    if (this.Dx5 && this.Dx5 !== '') {
      this.Diags.push(HistDiag.create(5, this.Dx5, this.Dx5Name,this.Dx5Acute));
    }
    if (this.Dx6 && this.Dx6 !== '') {
      this.Diags.push(HistDiag.create(6, this.Dx6, this.Dx6Name,this.Dx6Acute));
    }
  }
  // 把diag array放回Diagnosis 1-6
  restoreByDiags() {
    if (!this.Diags) {
      return;
    }
    // 塞滿到6 作為清空使用
    while(this.Diags.length<6){
      var emptyDx = new HistDiag();
      emptyDx.no = this.Diags.length+1;
      emptyDx.code = '';
      emptyDx.name = '';
      emptyDx.acuteNote = 0;
      this.Diags.push(emptyDx)
    }
    this.Diags.forEach((diag) => {
      if (!diag) {
        return;
      }
      if (diag.no === 1) {
        this.Dx1 = diag.code.trimRight();
        this.Dx1Name = diag.name.trimRight();
        this.Dx1Acute = diag.acuteNote;
      } else if (diag.no === 2) {
        this.Dx2 = diag.code.trimRight();
        this.Dx2Name = diag.name.trimRight();
        this.Dx2Acute = diag.acuteNote;
      } else if (diag.no === 3) {
        this.Dx3 = diag.code.trimRight();
        this.Dx3Name = diag.name.trimRight();
        this.Dx3Acute = diag.acuteNote;
      } else if (diag.no === 4) {
        this.Dx4 = diag.code.trimRight();
        this.Dx4Name = diag.name.trimRight();
        this.Dx4Acute = diag.acuteNote;
      } else if (diag.no === 5) {
        this.Dx5 = diag.code.trimRight();
        this.Dx5Name = diag.name.trimRight();
        this.Dx5Acute = diag.acuteNote;
      } else if (diag.no === 6) {
        this.Dx6 = diag.code.trimRight();
        this.Dx6Name = diag.name.trimRight();
        this.Dx6Acute = diag.acuteNote;
      }
    });
  }

  // 檢核診斷
  validateDiags(): CustomResult {
    if (this.Diags) {
      const codes = this.Diags.map(x => x.code);
      const result = Hist.findDuplicated(codes);
      if (result) {
        return CustomResult.Error(HistErrorCode.DiagRepeat);
      } else {
        return null;
      }
    }
    return null;
  }
  // 檢核醫令
  validateOrders(): CustomResult[] {
    if (!this.HRxs) {
      return null;
    }

    // 全部錯誤
    const errors = [];

    // (A)檢核缺少必要的欄位 -----
    let requiredErrorCodes = [];
    var filledCode = [];
    this.HRxs.forEach(odr => {
      if (odr.fillEmpty()) {
        filledCode.push(odr.RxCode);
      }
      if (!odr.validateRequired()) {
        requiredErrorCodes.push(odr.RxCode);
      }
    });
    if (filledCode.length > 0) {
      errors.push(CustomResult.Error(HistErrorCode.OrderFilled, filledCode))
    }
    // 醫令地次劑量可以不填，由程式自動由下往上補FreqQTY和InfoDays
    // if (requiredErrorCodes.length > 0) {
    //   errors.push(CustomResult.Error(HistErrorCode.OrderRequiredField, [requiredErrorCodes.join(',')]));
    // }

    // (B)重複醫令檢核 -----
    // 內服、外用、注射要檢核不可重複
    // 中藥不同付可重複，單一付內不可重複
    // 其他特殊狀況，手術可能不同刀口第二刀以後打折，所以會有兩筆，要看各院狀況。

    // (B2)檢核同一付中藥內不可重複
    /*
    const odrCnSoap1 = this.HRxs.filter(x => x.RxClass === RxClass.CnSp1).map(x => x.RxCode);
    if (odrCnSoap1) {
      const repeatCnSoap1 = Hist.findDuplicated(odrCnSoap1);
      if (repeatCnSoap1) {
        errors.push(CustomResult.Error(HistErrorCode.OrderRepeat, [repeatCnSoap1]));
      }
    }

    const odrCnSoap2 = this.HRxs.filter(x => x.RxClass === RxClass.CnSp2).map(x => x.RxCode);
    if (odrCnSoap2) {
      const repeatCnSoap2 = Hist.findDuplicated(odrCnSoap2);
      if (repeatCnSoap2) {
        errors.push(CustomResult.Error(HistErrorCode.OrderRepeat, [repeatCnSoap2]));
      }
    }

    const odrCnSoap3 = this.HRxs.filter(x => x.RxClass === RxClass.CnSp3).map(x => x.RxCode);
    if (odrCnSoap3) {
      const repeatCnSoap3 = Hist.findDuplicated(odrCnSoap3);
      if (repeatCnSoap3) {
        errors.push(CustomResult.Error(HistErrorCode.OrderRepeat, [repeatCnSoap3]));
      }
    }

    const cnMed = this.HRxs.filter(x => x.RxClass === RxClass.CnMed).map(x => x.RxCode);
    if (cnMed) {
      const repeatCodesCnMed = Hist.findDuplicated(cnMed);
      if (repeatCodesCnMed) {
        errors.push(CustomResult.Error(HistErrorCode.OrderRepeat, [repeatCodesCnMed]));
      }
    }
    */
    return errors;
  }
  // 檢核，含診斷與醫令
  validate(): CustomResult[] {
    let errors = [];
    const r1 = this.validateDiags();
    if (r1) {
      errors.push(r1);
    }
    const r2 = this.validateOrders();
    if (r2 && r2.length > 0) {
      errors = errors.concat(r2);
    }
    return errors;
  }
  // #endregion Diags --------------------
}
