import { Data } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { PCSxApiService } from 'src/app/services/api-service/opd/pcsx/pcsx-api';
import { PCSxAllocationQuery, PCSxListDto } from 'src/app/services/api-service/opd/pcsx/pcsx-api.model';
import { SPCSxApiService } from 'src/app/services/api-service/opd/spcsx/spcsx.api';
import { SPCSxListDto } from 'src/app/services/api-service/opd/spcsx/spcsx.api.model';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { VisGirdSelection, VisGridComponent } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';
import { Hist } from '../models/hist';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';

@Component({
  selector: 'app-pcsx-allocation',
  templateUrl: './pcsx-allocation.component.html',
  styleUrls: ['./pcsx-allocation.component.css']
})
export class PCSxAllocationComponent implements OnInit {
  @ViewChild('topGrid')
  topGrid: VisGridComponent;
  @ViewChild('bottomGrid')
  bottomGrid: VisGridComponent;

  @Input()
  selectedOptions: ValueTextPair[];
  @Input() diagVersion: string;

  @Output()
  selectedOptionsChange = new EventEmitter<ValueTextPair[]>();

  queryFormHelper: FormHelper<PCSxAllocationQuery>
  pageSize: number = 10;
  topPageSkip: number = 0;
  topPage: number = 1;
  bottomPageSkip: number = 0;
  bottomPage: number = 1;
  topGridData: {
    data: PCSxListDto[],
    total: number;
  };
  bottomGridData: {
    data: SPCSxListDto[],
    total: number;
  };

  @Output()
  selectEmit: EventEmitter<string[]> = new EventEmitter<string[]>(false);

  @Output()
  closeEmit: EventEmitter<void> = new EventEmitter<void>(false);


  constructor(private formHelperService: FormHelperService,
    private sPCSxApi: SPCSxApiService,
    private pCSxApiService: PCSxApiService,
    private mainLayoutService: MainLayoutService) {
  }

  ngOnInit(): void {
    this.makeQueryFormGroup();
    this.topGridData = {
      data: [],
      total: 0
    };
    this.bottomGridData = {
      data: [],
      total: 0
    };

    this.handleQuery();
  }

  // 當某個選項被選中時，更新 selectedOptions 並發射事件
  onOptionSelected(newOption: ValueTextPair) {
    this.selectedOptions = [...this.selectedOptions, newOption];
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  // 當某個選項被取消選中時，更新 selectedOptions 並發射事件
  onOptionDeselected(optionToRemove: ValueTextPair) {
    this.selectedOptions = this.selectedOptions.filter(option => option !== optionToRemove);
    this.selectedOptionsChange.emit(this.selectedOptions);
  }


  makeQueryFormGroup() {
    this.queryFormHelper = this.formHelperService.Create<PCSxAllocationQuery>().build({
      Code: ['', null],
      CName: ['', null],
      ApplyCode: ['', null],
      FilterContainsPCSx: [true, null],
      pageSize: [10, null],
      pageSkip: [0, null]
    })

  }

  async handleQuery() {
    await this.handleTopRefresh();
    await this.handleBottomRefresh();
  }

  async topPageChange(pageNum: any): Promise<void> {
    this.topPage = pageNum;
    this.topPageSkip = (pageNum - 1) * this.pageSize;
    await this.handleTopRefresh()
  }

  async bottomPageChange(pageNum: any): Promise<void> {
    this.bottomPage = pageNum;
    this.bottomPageSkip = (pageNum - 1) * this.pageSize;
    await this.handleBottomRefresh()
  }

  async handleTopRefresh() {
    this.mainLayoutService.showLoader();

    let ret = await this.pCSxApiService.GetPCSxPageList({
      pageSize: this.pageSize,
      pageSkip: this.topPageSkip,
      Code: this.queryFormHelper.Value.Code,
      CName: this.queryFormHelper.Value.CName,
      ApplyCode: this.queryFormHelper.Value.ApplyCode,
      DiagVersion: this.diagVersion
    });

    // 設置每個項目的 IsChecked 屬性並輸出一些參數到控制台
    const updatedData = ret.data.map(item => {
      const isChecked = this.selectedOptions.some(select => select.value == item.Code);
      return {
        ...item,
        IsChecked: isChecked
      };
    });


    this.topGridData = {
      data: updatedData,
      total: ret.total
    };

    this.mainLayoutService.hideLoader();
  }

  async handleBottomRefresh() {
    this.mainLayoutService.showLoader();

    let ret = await this.sPCSxApi.GetSPCSxPageList({
      pageSize: this.pageSize,
      pageSkip: this.bottomPageSkip,
      Code: this.queryFormHelper.Value.ApplyCode,
      CName: this.queryFormHelper.Value.CName,
      FilterContainsPCSx: this.queryFormHelper.Value.FilterContainsPCSx,
      DiagVersion: this.diagVersion
    });

    this.bottomGridData = {
      data: ret.data,
      total: ret.total
    };


    this.mainLayoutService.hideLoader();
  }

  async handleSelect() {
    let topSelectKeys = this.topGrid._selectedKeys;
    let bottomSelectKeys = this.bottomGrid._selectedKeys;

    let needAddStandardIds = this.getMatchingIds(bottomSelectKeys);

    await this.pCSxApiService.CreatePCSxByStandard(needAddStandardIds);

    let combinedKeys = [...topSelectKeys, ...bottomSelectKeys];
    let deduplicateKeys = combinedKeys.reduce((uniqueArray, item) => {
      if (!uniqueArray.includes(item)) {
        uniqueArray.push(item);
      }
      return uniqueArray;
    }, [] as string[]);

    this.selectEmit.emit(deduplicateKeys);
    this.closeEmit.emit();
  }


  private getMatchingIds(codes: string[]): number[] {
    return this.bottomGridData.data
      .filter(item => codes.includes(item.Code))
      .map(item => item.Id);
  }
}
