import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DxCertDto, DxCertMasterDto, DxPrintSetting, PatientHist } from 'src/app/patients/patient-hist/models/patient-hist';
import { DxApi, GetDxQuery } from 'src/app/services/api-service/dx/dx-api';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { HistEditOption } from 'src/app/services/api-service/hist/hist-edit-option';
import { PatientApi, PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { RegistEditOpt, RegisterApi } from 'src/app/services/api-service/register/register-api';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { PageData } from 'src/app/shared/models/page-data';
import { ValueTextPair, ValueTextPairNumberValue, ValueTextPairAnyValue } from 'src/app/shared/models/value-text-pair';
import { DiagnosisCertComponent } from 'src/app/system/report/components/diagnosis-cert/diagnosis-cert.component';
import { VisGridComponent } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { UserPermissions } from 'src/app/enums/UserPermssionEnum';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { UserApi, FullUser } from 'src/app/services/api-service/user/user-api';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'src/app/shared/utilities';

@Component({
  selector: 'app-diagnosiscert',
  templateUrl: './diagnosis-cert.component.html',
  styleUrls: ['./diagnosis-cert.component.css']
})
export class DiagnosisCertReportComponent extends BaseComponent implements OnInit {

  @ViewChild(DiagnosisCertComponent, { static: true })
  diagnosisCertComp: DiagnosisCertComponent;

  @ViewChild('masterGridCom') masterGridCom: VisGridComponent;

  @Input() patientData: PatientListDto;

  constructor(private fb: FormBuilder,
              private regApi: RegisterApi,
              private patientApi: PatientApi,
              private histApi: HistApi,
              private webApiClient: WebApiClient,
              private systemcodeApi: SystemcodeApi,
              private dxApi: DxApi,
              private route: ActivatedRoute,
              private userApi: UserApi,
              private notification: EasyNotificationService) {
                super();
                this.api = this.webApiClient.createHisService('patients/PatientHist');
              }

  @ViewChild('grid')
  grid: VisGridComponent;

  api: WebApiService;
  isSearchHistDialogOpened: boolean = false;
  isPatientDialogOpened: boolean = false;
  isDiagCertPreviewOpened: boolean = false;
  isPrintSettingDialogOpened: boolean = false;
  isEnglishSettingDialogOpened: boolean = false;
  isCopySettingDialogOpened: boolean = false;
  patient: PatientListDto;
  patientName: string = '';
  patientNo: string = '';
  patientId?: number;
  patientBirthday: string = '';
  patientPhone: string = '';
  patientAddress: string = '';
  patientCId: string = '';
  editOptions: RegistEditOpt;
  histEditOptions: HistEditOption;
  isAdd: boolean = false;
  hasRecord: boolean = false;
  canEdit: boolean = false;
  editCC: boolean = false;
  editDx: boolean = false;

  systemCode: SystemCode[];
  queryFormGroup: FormGroup = this.fb.group({});
  SettingFormGroup: FormGroup = this.fb.group({});
  printSettingFormGroup: FormGroup = this.fb.group({});
  // englishSettingFormGroup: FormGroup = this.fb.group({});
  ccQueryFormGroup: FormGroup = this.fb.group({});
  dxQueryFormGroup: FormGroup = this.fb.group({});
  oriMasterList: DxCertMasterDto[] = [];
  masterGridView: DxCertMasterDto[] = [];
  copyGridView: DxCertMasterDto[] = [];
  wholeDetail: PatientHist[] = [];
  detailGridView: PatientHist[] = [];
  printDxCert: DxCertDto = new DxCertDto;
  today = new Date();
  langsSet = [ {'text': '1 中文', 'value': '1'}, {'text': '2 英文', 'value': '2'} ];
  icodeSet = [ {'text': '1 自費', 'value': '1'}, {'text': '2 健保', 'value': '2'}, {'text': '3 全部', 'value': '3'} ];
  defDoctorSet = [ {'text': '1 第一筆醫師', 'value': '1'}, {'text': '2 最末筆醫師', 'value': '2'} ];

  selectedMasterId?: number;
  selectedDeptCode: string = '';
  selectedUseLang: string = '1';
  selectedIcode: string = '3';
  selectedDefDoctorUseBy: string = '1';
  isLoadCC: boolean = true;
  // isLoadPE: boolean = true;
  printDoctor: string = '';
  printDept: string = '';
  printCCDisabled: boolean = true;
  printSickNameDisabled: boolean = true;
  selectedItems: PatientHist[];
  doctorList: ValueTextPairNumberValue[] = [];
  sectionList: ValueTextPair[] = [];
  printSetting: DxPrintSetting;

  systemCodeList: any;
  ccPageSize: number = 21;
  ccPageSkipNo = 0;
  dxList: any;
  dxPageSize: number = 21;
  dxPageSkipNo = 0;
  nowWidth: number = window.innerWidth;

  // 表格-分頁
  // gridView: any;
  gridView: SystemCode[] = [];
  pageSize: number =15;
  pageSkipNo = 0;

  async ngOnInit() {
    this.getMedDepSettings();
    this.createQueryFormGroup();
    this.createSettingFormGroup();
    this.createPrintSettingFormGroup();
    // this.createEnglishSettingFormGroup();
    this.createCCQueryFormGroup();
    this.createDxQueryFormGroup();
    await this.parseRoute();
    this.editOptions = await this.regApi.GetEditOptions();
    this.sectionList = this.editOptions.section;

    this.histEditOptions = await this.histApi.getEditOptions();
    this.doctorList = this.histEditOptions.doctor;

    this.SettingFormGroup.disable();

    if (this.patientData) {
      this.onPatientDialogClose(this.patientData);
    } else {
      this.openPatientDialog();
    }
  }

  async getSystemCode() {
    const query = {
      clinicId: UserCache.getLoginUser().Clinic.Id,
      type: 50040,
      pageSize: this.ccPageSize,
      pageSkip: this.ccPageSkipNo
    };

    var result: PageData = await this.systemcodeApi.GetAll(query);
    this.systemCodeList = {
      data: result.data,
      total: result.total,
      totalPages: result.totalPages
    };
  }

  async getDxCode() {
    const query: GetDxQuery = {
      code: '',
      name: '',
      type: 'sphc',
      pageSize: this.dxPageSize,
      pageSkip: this.dxPageSkipNo,
      diagVersion: ''//dx都讀出全部, 雖然api也沒用到此參數
    };

    var result: PageData = await this.dxApi.GetInCompanyCodes(query);
    this.dxList = {
      data: result.data,
      total: result.total,
      totalPages: result.totalPages
    };
  }

  createCCQueryFormGroup() {
    this.ccQueryFormGroup = this.fb.group({
      ccCode: [''],
      ccName: [''],
    });
  }

  createDxQueryFormGroup() {
    this.dxQueryFormGroup = this.fb.group({
      dxCode: [''],
      dxName: [''],
    });
  }

  createQueryFormGroup() {
    this.queryFormGroup = this.fb.group({
      startDate: [new Date()],
      endDate: [new Date()],
      deptCode: [this.selectedDeptCode],
      useLang: [this.selectedUseLang],
      icode: [this.selectedIcode],
      defaultDoctorUseBy: [this.selectedDefDoctorUseBy],
      isIncludeCC: [this.isLoadCC],
      // isIncludePE: [this.isLoadPE]
    });
  }

  createSettingFormGroup() {
    this.SettingFormGroup = this.fb.group({
      printCC: [''],
      printSickName: [''],
      printDoctor: [''],
      printDept: ['']
    });
  }

  createPrintSettingFormGroup() {
    this.printSettingFormGroup = this.fb.group({
      DxCertRemark: [''],
      BirthCity: [''],  // 預設以身分證號第一碼判斷
      Job: [''],
      PrintName : [''],
      EName: [''],
      EAddress: [''],
      PrintOutCount: ['1'],
      ReportType: ['1'],
    });
  }

  // createEnglishSettingFormGroup() {
  //   this.englishSettingFormGroup = this.fb.group({
  //     medDept: ['']
  //   });
  // }

  onBtnAddClick() {
    this.isAdd = true;
    this.canEdit = false;
    this.isSearchHistDialogOpened = true;
    this.queryFormGroup.controls['startDate'].setValue(new Date());
    this.queryFormGroup.controls['endDate'].setValue(new Date());
    this.queryFormGroup.controls['deptCode'].setValue('');
    this.queryFormGroup.controls['useLang'].setValue('1');
    this.queryFormGroup.controls['icode'].setValue('3');
    this.queryFormGroup.controls['defaultDoctorUseBy'].setValue('1');
    this.queryFormGroup.controls['isIncludeCC'].setValue(true);
    // this.queryFormGroup.controls['isIncludePE'].setValue(true);
  }

  onBtnEditClick() {
    this.printCCDisabled = false;
    this.printSickNameDisabled = false;
    this.SettingFormGroup.enable();
    this.canEdit = true;
    this.editCC = true;
    this.getSystemCode();
    document.getElementById('ccInput').focus();
    this.editDx = false;
  }

  onBtnCCQueryClick() {
    var ccCode = this.ccQueryFormGroup.controls['ccCode'].value;
    var ccName = this.ccQueryFormGroup.controls['ccName'].value;
    this.ccPageSkipNo = 0;
    this.refreshCCList(ccCode, ccName);
  }

  onCCPageChange(pageNum: any) {
    var ccCode = this.ccQueryFormGroup.controls['ccCode'].value;
    var ccName = this.ccQueryFormGroup.controls['ccName'].value;
    this.ccPageSkipNo = (pageNum - 1) * this.ccPageSize;
    this.refreshCCList(ccCode, ccName);
  }

  ccGridSelectionChange(evt: any) {
    var cc = evt.dataItem;
    var ccs: string = this.SettingFormGroup.controls['printCC'].value;
    var ccName = cc.Name;
    if (!(ccs.includes(ccName))) {
      ccs += (ccs.length > 0 ? (',' + ccName) : ccName);
      this.SettingFormGroup.controls['printCC'].setValue(ccs);
    }
  }

  async refreshCCList(code: string, name: string) {
    const query = {
      clinicId: UserCache.getLoginUser().Clinic.Id,
      type: 50020,
      code: code,
      name: name,
      pageSize: this.ccPageSize,
      pageSkip: this.ccPageSkipNo
    };

    var result: PageData = await this.systemcodeApi.GetAll(query);
    this.systemCodeList = {
      data: result.data,
      total: result.total
    };
  }

  onBtnDxQueryClick() {
    var dxCode = this.dxQueryFormGroup.controls['dxCode'].value;
    var dxName = this.dxQueryFormGroup.controls['dxName'].value;
    this.dxPageSkipNo = 0;
    this.refreshDxList(dxCode, dxName);
  }

  onDxPageChange(pageNum: any) {
    var dxCode = this.dxQueryFormGroup.controls['dxCode'].value;
    var dxName = this.dxQueryFormGroup.controls['dxName'].value;
    this.dxPageSkipNo = (pageNum - 1) * this.dxPageSize;
    this.refreshDxList(dxCode, dxName);
  }

  dxGridSelectionChange(evt: any) {
    var dx = evt.dataItem;
    var dxs: string = this.SettingFormGroup.controls['printSickName'].value;
    var dxName = '';
    if (this.selectedUseLang == '1') {  // 中文
      dxName = dx.CName;
    } else {
      dxName = dx.EName;
    }
    if (!(dxs.includes(dxName))) {
      dxs += (dxs.length > 0 ? (',' + dxName) : dxName);
      this.SettingFormGroup.controls['printSickName'].setValue(dxs);
    }
  }

  async refreshDxList(code: string, name: string) {
    const query: GetDxQuery = {
      code: code,
      name: name,
      type: 'sphc',
      pageSize: this.dxPageSize,
      pageSkip: this.dxPageSkipNo,
      diagVersion: ''//dx都讀出全部, 雖然api也沒用到此參數
    };

    var result: PageData = await this.dxApi.GetInCompanyCodes(query);
    this.dxList = {
      data: result.data,
      total: result.total,
      totalPages: result.totalPages
    };
  }

  async onBtnSaveClick() {
    var filtered = this.detailGridView.filter(x => x.Checked == true);
    this.printCCDisabled = false;
    this.printSickNameDisabled = false;
    this.SettingFormGroup.enable();

    // 儲存紀錄
    var dxCerts: DxCertDto = new DxCertDto();;
    // step.1 - DxCertDto - new Data
    var oriDxm = new DxCertMasterDto();
    var dxm = new DxCertMasterDto();
    if (this.selectedMasterId) {
      oriDxm = this.oriMasterList.find(x => x.Id == this.selectedMasterId);
      dxm.Id = this.selectedMasterId;
      dxm.ClinicId = oriDxm.ClinicId;
      dxm.ClinicCode = oriDxm.ClinicCode;
      dxm.PatientNo = oriDxm.PatientNo;;
      dxm.PatientId = oriDxm.PatientId;
      dxm.StartRegDate = oriDxm.StartRegDate;
      dxm.EndRegDate = oriDxm.EndRegDate;
      dxm.LastPrintDate = oriDxm.LastPrintDate;
      dxm.DoctorId = oriDxm.DoctorId;
      dxm.HistCount = filtered.length;
      dxm.PrintCount = oriDxm.PrintCount;
      dxm.Dept = oriDxm.Dept;
      dxm.CC = this.SettingFormGroup.controls['printCC'].value;
      dxm.DxName = this.SettingFormGroup.controls['printSickName'].value;
      dxm.PrintDxLanguage = oriDxm.PrintDxLanguage;
      dxm.PrintCode = oriDxm.PrintCode;
    } else {
      dxm.ClinicId = UserCache.getLoginUser().Clinic.Id;
      dxm.ClinicCode = UserCache.getLoginUser().Clinic.Code;
      dxm.PatientNo = this.patientNo;
      dxm.PatientId = this.patientId;
      dxm.StartRegDate = this.queryFormGroup.controls['startDate'].value;
      dxm.EndRegDate = this.queryFormGroup.controls['endDate'].value;
      dxm.LastPrintDate = null;
      dxm.DoctorId = this.SettingFormGroup.controls['printDoctor'].value;
      dxm.HistCount = filtered.length;
      dxm.PrintCount = 0;
      dxm.Dept = this.SettingFormGroup.controls['printDept'].value;
      dxm.CC = this.SettingFormGroup.controls['printCC'].value;
      dxm.DxName = this.SettingFormGroup.controls['printSickName'].value;
      dxm.PrintDxLanguage = this.queryFormGroup.controls['useLang'].value;
      dxm.PrintCode = this.queryFormGroup.controls['icode'].value;
    }
    var dxds: PatientHist[] = [];
    filtered.forEach((x) => {
      dxds.push(x);
    });
    var dxcs: DxCertDto = new DxCertDto();
    dxcs.dxm = dxm;
    dxcs.dxds = dxds;
    dxCerts = dxcs;
    this.printDxCert = dxcs;

    // step.2 - invoke API to save into database
    var ret = await this.api.post('CreateDxCert', dxCerts).toPromise();
    if (ret == true) {
      this.notification.showInfo('診斷證明書儲存成功!');
      this.getPatDxCert();
    } else {
      this.notification.showError('診斷證明書儲存失敗!');
    }
  }

  async onBtnDeleteClick() {
    var dxCertId = this.detailGridView[0].DxMasterId;
    let query = { id: dxCertId };
    var ret: boolean = await this.api.get('DeleteDxCertById', query).toPromise();
    if (ret) {
      this.notification.showSuccess('診斷證明書列印紀錄刪除成功!');
      this.queryFormGroup.controls['startDate'].setValue(new Date());
      this.queryFormGroup.controls['endDate'].setValue(new Date());
      this.queryFormGroup.controls['deptCode'].setValue('');
      this.queryFormGroup.controls['useLang'].setValue('1');
      this.queryFormGroup.controls['icode'].setValue('3');
      this.queryFormGroup.controls['defaultDoctorUseBy'].setValue('1');
      this.SettingFormGroup.controls['printCC'].setValue('');
      this.SettingFormGroup.controls['printSickName'].setValue('');
      this.SettingFormGroup.controls['printDoctor'].setValue('');
      this.SettingFormGroup.controls['printDept'].setValue('');
      this.printCCDisabled = true;
      this.printSickNameDisabled = true;
      this.SettingFormGroup.disable();
      this.getPatDxCert();
    } else {
      this.notification.showWarning('診斷證明書列印紀錄刪除失敗!');
    }
  }

  onBtnCancelClick() {
    this.isAdd = false;
    this.canEdit = false;

    if (this.masterGridView.length > 0) {
      this.detailGridView = this.wholeDetail.filter(x => x.DxMasterId == this.selectedMasterId);
    } else {
      this.detailGridView = [];
    }
    this.queryFormGroup.controls['startDate'].setValue(new Date());
    this.queryFormGroup.controls['endDate'].setValue(new Date());
    this.queryFormGroup.controls['deptCode'].setValue('');
    this.queryFormGroup.controls['useLang'].setValue('1');
    this.queryFormGroup.controls['icode'].setValue('3');
    this.queryFormGroup.controls['defaultDoctorUseBy'].setValue('1');
    this.queryFormGroup.controls['isIncludeCC'].setValue(true);
    // this.queryFormGroup.controls['isIncludePE'].setValue(true);
    this.SettingFormGroup.controls['printCC'].setValue('');
    this.SettingFormGroup.controls['printSickName'].setValue('');
    this.SettingFormGroup.controls['printDoctor'].setValue('');
    this.SettingFormGroup.controls['printDept'].setValue('');
    this.printCCDisabled = true;
    this.printSickNameDisabled = true;
    this.SettingFormGroup.disable();
    this.getPatDxCert();
  }

  onBtnTodayForStartDateClick() {
    this.queryFormGroup.controls['startDate'].setValue(new Date());
  }

  onDtpStartDateChange(event: Date) {
    let eDate: Date = this.queryFormGroup.controls['endDate'].value;
    var endDate = DateHelper.getFormatedDateString(eDate, false, "");
    var startDate = DateHelper.getFormatedDateString(event, false, "");
    if (Number(startDate) > Number(endDate)) {
      this.queryFormGroup.controls['startDate'].setValue(eDate);
      this.notification.showInfo('開始日期不可大於結束日期');
    }
  }

  onBtnTodayForEndDateClick() {
    this.queryFormGroup.controls['endDate'].setValue(new Date());
  }

  onDtpEndDateChange(event: Date) {
    let sDate: Date = this.queryFormGroup.controls['startDate'].value;
    var startDate = DateHelper.getFormatedDateString(sDate, false, "");
    var endDate = DateHelper.getFormatedDateString(event, false, "");
    if (Number(endDate) < Number(startDate)) {
      this.queryFormGroup.controls['endDate'].setValue(sDate);
      this.notification.showInfo('結束日期不可小於開始日期');
    }
  }

  onDdlDeptChange(event: any) {
    this.selectedDeptCode = this.queryFormGroup.controls['deptCode'].value;
  }

  onDdlLangChange(event: any) {
    this.selectedUseLang = this.queryFormGroup.controls['useLang'].value;
  }

  onDdlICodeChange(event: any) {
    this.selectedIcode = this.queryFormGroup.controls['icode'].value;
  }

  onDdlDefDrChange(event: any) {
    this.selectedDefDoctorUseBy = this.queryFormGroup.controls['defaultDoctorUseBy'].value;
  }

  async onHistQuery() {
    this.selectedMasterId = null;
    this.SettingFormGroup.controls['printCC'].setValue('');
    this.SettingFormGroup.controls['printSickName'].setValue('');
    this.SettingFormGroup.controls['printDoctor'].setValue('');
    this.SettingFormGroup.controls['printDept'].setValue('');
    this.printCCDisabled = false;
    this.printSickNameDisabled = false;
    this.SettingFormGroup.enable();
    this.detailGridView = [];
    this.isLoadCC = this.queryFormGroup.controls['isIncludeCC'].value;
    // this.isLoadPE = this.queryFormGroup.controls['isIncludePE'].value;
    var selectedIcode = this.queryFormGroup.controls['icode'].value;        // 保險分類
    var selectedDeptCode = this.queryFormGroup.controls['deptCode'].value;  // 科別
    var sDate = this.queryFormGroup.controls['startDate'].value;
    var eDate = this.queryFormGroup.controls['endDate'].value;
    let query = { startDate: sDate.getFullYear() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getDate(),
                  endDate: eDate.getFullYear() + "/" + (eDate.getMonth() + 1) + "/" + eDate.getDate(),
                  patientNo: this.patientNo };
    // 呼叫 API 取得該病歷資料
    var result: PatientHist[] = await this.api.get('GetHistByNo', query).toPromise();
    if (!result.length) {
      this.notification.showInfo('查無指定日期區間的病歷資料。');
    }

    if (selectedDeptCode) {
      result = result.filter(x => x.MedCode == selectedDeptCode);
    }

    if (selectedIcode == '1') {   // 自費
      this.detailGridView = result.filter(x => x.Icode == '000');
    } else if (selectedIcode == '2') {   // 健保
      this.detailGridView = result.filter(x => x.Icode != '000');
    } else {  // 全部
      this.detailGridView = result;
    }

    this.SettingFormGroup.controls['printDept'].setValue(this.selectedDeptCode);
    // 組出CC和DX
    var CCs = '';
    var Dxs = '';
    for (var i = 0; i < this.detailGridView.length; i++) {
      //預設第一筆的醫師
      if (this.selectedDefDoctorUseBy == '1' && i == 0) {
        this.SettingFormGroup.controls['printDoctor'].setValue(this.detailGridView[i].DoctorId);
      }
      // 預設最末筆的醫師
      if (this.selectedDefDoctorUseBy == '2' && i == this.detailGridView.length - 1) {
        this.SettingFormGroup.controls['printDoctor'].setValue(this.detailGridView[i].DoctorId);
      }

      // 是否載入CC, PE
      if (this.isLoadCC) {
        if (this.detailGridView[i].CC && this.detailGridView[i].CC.length > 0) {
          if (!(CCs.includes(this.detailGridView[i].CC))) {
            if (i == this.detailGridView.length - 1) {  // 最後一筆
              CCs += this.detailGridView[i].CC.trimRight();
            } else {
              CCs += (this.detailGridView[i].CC.trimRight() + ',');
            }
          }
        }
      }

      if (this.selectedUseLang == '1') {
        if (this.detailGridView[i].Dx1Name && this.detailGridView[i].Dx1Name.length > 0) {
          if (!(Dxs.includes(this.detailGridView[i].Dx1Name))) {
            if (i == this.detailGridView.length - 1) {  // 最後一筆
              Dxs += this.detailGridView[i].Dx1Name.trimRight();
            } else {
              Dxs += (this.detailGridView[i].Dx1Name.trimRight() + ',');
            }
          }
        }

        if (this.detailGridView[i].Dx2Name && this.detailGridView[i].Dx2Name.length > 0) {
          if (!(Dxs.includes(this.detailGridView[i].Dx2Name))) {
            if (i == this.detailGridView.length - 1) {  // 最後一筆
              Dxs += this.detailGridView[i].Dx2Name.trimRight();
            } else {
              Dxs += (this.detailGridView[i].Dx2Name.trimRight() + ',');
            }
          }
        }
      } else {
        if (this.detailGridView[i].Dx1EName && this.detailGridView[i].Dx1EName.length > 0) {
          if (!(Dxs.includes(this.detailGridView[i].Dx1EName))) {
            if (i == this.detailGridView.length - 1) {  // 最後一筆
              Dxs += this.detailGridView[i].Dx1EName.trimRight();
            } else {
              Dxs += (this.detailGridView[i].Dx1EName.trimRight() + ',');
            }
          }
        }

        if (this.detailGridView[i].Dx2EName && this.detailGridView[i].Dx2EName.length > 0) {
          if (!(Dxs.includes(this.detailGridView[i].Dx2EName))) {
            if (i == this.detailGridView.length - 1) {  // 最後一筆
              Dxs += this.detailGridView[i].Dx2EName.trimRight();
            } else {
              Dxs += (this.detailGridView[i].Dx2EName.trimRight() + ',');
            }
          }
        }
      }
    }
    this.SettingFormGroup.controls['printCC'].setValue(CCs);
    this.SettingFormGroup.controls['printSickName'].setValue(Dxs);

    // 顯示醫囑選單 (ccList)
    this.canEdit = true;
    this.editCC = true;
    this.getSystemCode();
    document.getElementById('ccInput').focus();
    this.editDx = false;
  }

  setIcodeName(src: string) {
    if (src == '000') {
      return '自費';
    } else {
      return '健保';
    }
  }

  onDdlMenualDrChange(event: any) {
    this.printDoctor = this.SettingFormGroup.controls['printDoctor'].value;
  }

  onDdlMenualDeptChange(event: any) {
    this.printDept = this.SettingFormGroup.controls['printDept'].value;
  }

  onDiagnosisCertPrint() {
    var cnt = (Number)(this.printSettingFormGroup.controls['PrintOutCount'].value ?? '1');
    this.diagnosisCertComp.onPrint(cnt);
  }

  onPrinted(evt: any) {
    if (evt) {
      this.getPatDxCert();
    }
    this.isDiagCertPreviewOpened = false;
  }

  openPatientDialog() {
    this.isPatientDialogOpened = true;
  }

  checkChanged(evt:{ checked:boolean, data:any }) {
    // console.log('evt +++++', evt);
    // console.log('detailGridView', this.detailGridView);
  }

  gridSelectionChange(evt: any) {
    this.detailGridView = [];
    this.selectedMasterId = evt.dataItem.Id;
    this.detailGridView = this.wholeDetail.filter(x => x.DxMasterId == evt.dataItem.Id);
    this.printDxCert.dxds = this.wholeDetail.filter(x => x.DxMasterId == evt.dataItem.Id);
    this.printDxCert.dxm = this.masterGridView.find(x => x.Id == evt.dataItem.Id);
    this.printCCDisabled = false;
    this.printSickNameDisabled = false;
    this.SettingFormGroup.enable();
    this.SettingFormGroup.controls['printCC'].setValue(evt.dataItem.CC);
    this.SettingFormGroup.controls['printSickName'].setValue(evt.dataItem.DxName);
    this.SettingFormGroup.controls['printDept'].setValue(evt.dataItem.Dept);
    this.SettingFormGroup.controls['printDoctor'].setValue(evt.dataItem.DoctorId);
  }

  async onPatientDialogClose(data: PatientListDto) {
    this.isPatientDialogOpened = false;
    // The variable named "patient" will be null when user clicks button named "x" on the dialog box to close or triggers the closing action.
    // 清除原本資料
    if (data != null) {
      this.patient = data;
      this.patientName = data.Name == undefined || data.Name == null ? '' : data.Name;
      this.patientNo = data.PatientNo == undefined || data.PatientNo == null ? '' : data.PatientNo;
      this.patientId = data.Id == undefined || data.Id == null ? null : data.Id;
      this.patientPhone = data.PhoneNo == undefined || data.PhoneNo == null ? '' : data.PhoneNo;
      this.patientBirthday = data.Birthday == undefined || data.Birthday == null ? '' : new Date(data.Birthday).toDateString();
      this.patientAddress = data.City + data.Area + data.Street;
      this.patientCId = data.CId == undefined || data.CId == null ? '' : data.CId;

      this.SettingFormGroup.controls['printCC'].setValue('');
      this.SettingFormGroup.controls['printSickName'].setValue('');
      this.SettingFormGroup.controls['printDept'].setValue('');
      this.SettingFormGroup.controls['printDoctor'].setValue('');
      this.getPatDxCert();
    }
  }

  async getPatDxCert() {
    this.wholeDetail = [];
    this.oriMasterList = [];
    this.masterGridView = [];
    this.detailGridView = [];
    this.canEdit = false;
    this.editCC = false;
    this.editDx = false;
    this.printCCDisabled = true;
    this.printSickNameDisabled = true;
    this.SettingFormGroup.disable();
    // 取得此病患之前的診斷證明列印紀錄
    let query = { patientNo: this.patientNo };
    var result: DxCertDto[] = await this.api.get('GetDxCertByNo', query).toPromise();
    if (result.length > 0) {
      this.hasRecord = true;
      var masterTemp: DxCertMasterDto[] = [];
      var detailTemp: PatientHist[] = [];
      var i = 0;
      result.forEach(x => {
        var m: DxCertMasterDto = new DxCertMasterDto();
        m.Id = x.dxm.Id;
        m.ClinicId = x.dxm.ClinicId;
        m.ClinicCode = x.dxm.ClinicCode;
        m.PatientNo = x.dxm.PatientNo;
        m.PatientId = x.dxm.PatientId;
        m.StartRegDate = x.dxm.StartRegDate;
        m.EndRegDate = x.dxm.EndRegDate;
        m.LastPrintDate = x.dxm.LastPrintDate;
        m.DoctorId = x.dxm.DoctorId;
        m.HistCount = x.dxm.HistCount;
        m.PrintCount = x.dxm.PrintCount;
        m.Dept = x.dxm.Dept;
        m.CC = this.isLoadCC ? x.dxm.CC : '';
        m.DxName = x.dxm.DxName;
        m.PrintDxLanguage = x.dxm.PrintDxLanguage;
        m.PrintCode = x.dxm.PrintCode;
        m.CreateTime = x.dxm.CreateTime;
        masterTemp.push(m);
        // 畫面的預設值
        if (i == 0) {
          this.selectedMasterId = m.Id;
          this.printDxCert.dxm = m;   // default
          this.printCCDisabled = false;
          this.printSickNameDisabled = false;
          this.SettingFormGroup.enable();
          this.SettingFormGroup.controls['printCC'].setValue(x.dxm.CC);
          this.SettingFormGroup.controls['printSickName'].setValue(x.dxm.DxName);
          this.SettingFormGroup.controls['printDept'].setValue(x.dxm.Dept);
          this.SettingFormGroup.controls['printDoctor'].setValue(x.dxm.DoctorId);
        }
        x.dxds.forEach(d => {
          d.DxMasterId = x.dxm.Id;
          this.wholeDetail.push(d);
          if (i == 0) {
            detailTemp.push(d);
          }
        });
        i++;
      });
      this.printDxCert.dxds = detailTemp;  // default
      this.oriMasterList = masterTemp;
      this.masterGridView = masterTemp;
      this.copyGridView = masterTemp;
      this.detailGridView = detailTemp;
    }
    this.masterGridCom.setDefaultSeclected = true;
  }

  onPreviewClose() {
    this.isDiagCertPreviewOpened = false;
  }

  async onPreviewOpen() {
    this.isDiagCertPreviewOpened = true;
    var langType = this.printSetting.ReportType;   // 1 - 中文, 2 - 英文
    var depts = this.gridView;
    var seleltedDetp = this.printDept ? this.printDept : this.printDxCert.dxm.Dept;
    var deptData = depts.find(x => x.Code == seleltedDetp);  // this.sectionList.find(x => x.value == seleltedDetp)?.text;
    var dept = deptData?.Name;
    if (langType == '2') dept = deptData?.Name1;

    var selectedDocId = this.printDoctor ? this.printDoctor : this.printDxCert.dxm.DoctorId;
    var docData: FullUser = await this.userApi.getFullUser(selectedDocId);
    var docName = docData.name;
    if (langType == '2') docName = docData.ename;
    //var docName = this.doctorList.find(x => x.value == this.printDxCert.dxm.DoctorId)?.text;
    var docLicence = this.doctorList.find(x => x.value == this.printDxCert.dxm.DoctorId)?.extension;
    this.printDxCert.dxm.CC = this.SettingFormGroup.controls['printCC'].value;  // 帶入畫面輸入的醫囑
    this.printDxCert.dxm.DxName = this.SettingFormGroup.controls['printSickName'].value;  // 帶入畫面輸入的診斷病名
    this.diagnosisCertComp.fillData(this.printDxCert, this.patientId, this.printSetting, (dept === undefined ? '' : dept), (docName === undefined ? '' : docName), (docLicence === 'undefined' ? '' : docLicence));
  }

  onSearchHistDialogClose(evt: string) {
    this.isSearchHistDialogOpened = false;
    this.isAdd = false;
    this.canEdit = false;
    if (evt == 'sure') {
      this.onHistQuery();
    }
  }

  async onPrintSettingDialogOpen() {
    try {
      var settingData: PatientsDto = await this.patientApi.Get(this.patientId);

      this.printSettingFormGroup.controls['DxCertRemark'].setValue(settingData?.DxCertRemark ?? '');
      this.printSettingFormGroup.controls['BirthCity'].setValue(settingData?.BirthCity ?? '');
      this.printSettingFormGroup.controls['Job'].setValue(settingData?.Job ?? '');
      this.printSettingFormGroup.controls['PrintName'].setValue(settingData?.PrintName ?? '');
      this.printSettingFormGroup.controls['EName'].setValue(settingData?.EName ?? '');
      this.printSettingFormGroup.controls['EAddress'].setValue(settingData?.EAddress ?? '');
      this.printSettingFormGroup.controls['PrintOutCount'].setValue('1');
    } catch (ex) {
      // nothing to do....
    }
    this.isPrintSettingDialogOpened = true;
  }

  onPrintSettingDialogClose(evt: string) {
    this.isPrintSettingDialogOpened = false;
    if (evt == 'sure') { // 開啟預覽列印
      this.printSetting = new DxPrintSetting();
      this.printSetting.DxCertRemark = this.printSettingFormGroup.controls['DxCertRemark'].value;
      this.printSetting.BirthCity = this.printSettingFormGroup.controls['BirthCity'].value;
      this.printSetting.Job = this.printSettingFormGroup.controls['Job'].value;
      this.printSetting.PrintName = this.printSettingFormGroup.controls['PrintName'].value;
      this.printSetting.EName = this.printSettingFormGroup.controls['EName'].value;
      this.printSetting.EAddress = this.printSettingFormGroup.controls['EAddress'].value;
      this.printSetting.PrintOutCount = this.printSettingFormGroup.controls['PrintOutCount'].value;
      this.printSetting.ReportType = this.printSettingFormGroup.controls['ReportType'].value;
      this.onPreviewOpen();
    }
  }

  onCcInputClick() {
    if (this.canEdit) {
      this.editDx = false;
      this.editCC = true;
      this.getSystemCode();
    }
  }

  onDxInputClick() {
    if (this.canEdit) {
      this.editCC = false;
      this.editDx = true;
      this.getDxCode();
    }
  }

  async getMedDepSettings() {
    const query = {
      clinicId: UserCache.getLoginUser().Clinic.Id,
      type: 30020,
      withCopy: true,
      pageSize: 999,
      pageSkip: 0
    };

    var result: PageData = await this.systemcodeApi.GetAll(query);
    this.gridView = result.data as SystemCode[];
  }

  onEnglishSetting() {
    this.isEnglishSettingDialogOpened = true;
    // this.getMedDepSettings();
  }

  onCopySetting() {
    this.isCopySettingDialogOpened = true;
  }

  onEnglishSettingDialogClose() {
    this.editId = null;
    this.refreshList();
    this.isEnglishSettingDialogOpened = false;
  }

  onCopySettingDialogClose() {
    this.refreshList();
    this.isCopySettingDialogOpened = false;
  }

  // 選入病歷
  onBtnOrderClick(data: DxCertMasterDto) {
    if (data && data.CC) {
      var cc = data.CC;
      var ccs: string = this.SettingFormGroup.controls['printCC'].value;
      ccs = (ccs.length > 0) ? (cc + ',' + ccs) : cc ;
      this.SettingFormGroup.controls['printCC'].setValue(ccs);
      this.onCopySettingDialogClose()
    }
}

  pageChange(page: number) {
    this.pageSkipNo = page;
    this.refreshList();
  }

  // 更新清單
  refreshList() {
    this.getMedDepSettings();
  }

  selectedMedItemsId: number[] = [];
  setGridHighlight(data: SystemCode) {
    this.selectedMedItemsId[0] = data.Id;
  }

  editHandler(dataItem) {
    //this.closeEditor(sender);
    this.setGridHighlight(dataItem);
    this.editId = dataItem.Id;
    let valueFormControl: FormControl;
    valueFormControl = new FormControl(dataItem.Name1);
  }

  cancelHandler({ sender, rowIndex }) {
    // this.closeEditor(sender, rowIndex);
    this.editId = null;
    this.refreshList();
  }

  async saveHandler(dataItem) {
    var result = await this.systemcodeApi.UpdateEngDetpName(dataItem as SystemCode);
    if (result.IsSuccess) {
      this.notification.showOk();
      this.editId = null;
    } else {
      this.notification.showError(result.Message);
    }
  }

  /** 刷卡ic跳過來，先這樣，需要加強再調 */
   async parseRoute() {
    var patientCId  = this.route.snapshot.params['ICPatientCId'];
    if (!isNullOrUndefined(patientCId)){
      var pat: PatientsDto = await this.patientApi.GetPatientByCIdAsync(patientCId);
      if (pat){
      this.patientData = new PatientListDto();
      this.patientData.Id = pat.Id;
      this.patientData.PatientNo = pat.PatientNo;
      this.patientData.LastName  =pat.LastName;
      this.patientData.MiddleName  =pat.MiddleName;
      this.patientData.PhoneNo  =!isNullOrUndefined(pat.CellPhone)?pat.CellPhone:  pat.PhoneArea + pat.Phone;
      this.patientData.Birthday  =pat.Birthday;
      this.patientData.CId  =pat.CId;
      this.patientData.City  =pat.City;
      this.patientData.State  =pat.State;
      this.patientData.Area  =pat.Area;
      this.patientData.Street  =pat.Street;
      this.patientData.FullName  =pat.CName;
      this.patientData.Address  =pat.City + pat.Area+ pat.Street;
      this.patientData.Name  =pat.Name;
      this.patientData.PaySysA  =pat.PaySysA;
      this.patientData.Sex  =pat.Sex;
      }

    }
  }
  editId = null;
  isEdit(data: SystemCode) {
    return data.Id == this.editId;
  }
}
