import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { HistService } from "../hist/services/hist.service";
import { MainLayoutService } from "../layout/services/main-layout.service";
import { AuthenticationService } from "../security/services/authentication.service";
import { EasyFormComponent } from "../shared/components/easy-form/easy-form.component";
import { ValidationTipComponent } from "../shared/components/validation-tip/validation-tip.component";
import { HistApi } from "./api-service/hist/hist-api";
import { ParameterApi } from "./api-service/parameters/parameter-api";
import { PostAreaAPI } from "./api-service/postarea-api";
import { TimeSectionRange } from "./api-service/register/time-section-range";
import { ScheduleApi } from "./api-service/schedule/schedule-api";
import { FullUser, UserApi } from "./api-service/user/user-api";
import { ChildGrowDataChartService } from "./child-grow-chart-service";
import { EasyFormService } from "./easy-form-service";
import { EasyNotificationService } from "./easy-notification.service";
import { MemoryCache } from "./memory-cache-service";
import { SessionService } from "./session.service";
import { UserCache } from "./user-cache";
import { UserConfirmService } from "./user-confirm.service";
import { WebApiService } from "./web-api.service";
import { WebApiClient } from "./web-api-client.service";
import { Schedule } from "../schedule/models/schedule";
import { FontGreatService } from "./font-great.service";
import { SignalRService } from "./signalr-service";
import { NullOrEmpty } from "../shared/utilities";
import { RegReserveService } from "../registers/reserve/reg-reserve-service";
import { HcrService } from "./hcr-service.service";
import { FormFieldCollection } from "../shared/components/easy-form/form-define";
import { SatellitePharmacyServiceService } from "./satellite-pharmacy-service.service";
import { ClinicDataService } from "./data-service/clinic-data-service";
import { LabApi } from "./api-service/labs/lab-api";
import { AlertOn, AnnounceAlertService } from "./announceAlert.service";
import { ReserveService } from "./reserve.service";
import { bulletinParameter, bulletinResponse, bulletinResult, ToccService } from "./tocc-service.service";
import { AnnouncementApi } from "./api-service/announcement/announcement-api";
import { Announcement } from "./api-service/announcement/announcement-model";
import { DateHelper } from "../shared/helpers/date-helper";
import { ClinicApi } from "./api-service/clinic/clinic-api";
import { DengueFormDto, ToccApi } from "./api-service/hist/tocc-api";

export declare type VPNState = 'Ok' | 'Slow' | 'Fail';

@Injectable({
  providedIn: 'root'
})
export class StartUpService {
  userData: FullUser;
  api: WebApiService;
  isHomeCare: boolean = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  isRecoveryHome: boolean = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
  /**
   *
   */
  private isStart = false;
  constructor(
    private postAreaApi: PostAreaAPI,
    private webApiFactory: WebApiClient,
    private userApi: UserApi,
    private scheduleApi: ScheduleApi,
    private announcement: AnnounceAlertService,
    private paramApi: ParameterApi,
    private mainLayout: MainLayoutService,
    private userConfirm: UserConfirmService,
    private easyForm: EasyFormService,
    private notify: EasyNotificationService,
    private session: SessionService,
    private auth: AuthenticationService,
    private hist: HistApi,
    private cgcService: ChildGrowDataChartService,
    private fontGreatService: FontGreatService,
    private signalRService: SignalRService,
    private reserveService: RegReserveService,
    private hcrService: HcrService,
    private satelliteService: SatellitePharmacyServiceService,
    private clinicDataService: ClinicDataService,
    private labApi: LabApi,
    private dReserveService:ReserveService,
    private toccApi: ToccApi,
    private toccService: ToccService,
    private announcementApi: AnnouncementApi,
    private clinicApi: ClinicApi
  ) {
    this.api = this.webApiFactory.createHisService('schedule/shiftschedule');
    this.auth.logoutEvent.subscribe(() => {
      // 清除參數快取
      //this.paramApi.clearCache();
      // 清除們診選項快取
      this.hist.EditOpt = null;
      this.userData = null;
    });
  }
  async start() {
    console.log('系統啟動中')
    // 預先載入
    await this.Ping_VPN();

    // 這個項目裡面有Grid的設定，先預載
    await this.clinicDataService.getParam("SYS101");

    // 載入使用者資料
    await this.getUser();
    await this.checkPwdChange();
    // Task 12024.4 登錄時預設排班表展開功能去除 2023/06/09
    // if (!this.isHomeCare) {
    //     await this.checkMonthSchedule();
    // }
    await this.initByPosition();

    // 載入不分院所資料
    // 郵遞區號
    await this.postAreaApi.GetCityArea();
    // 載入兒童成長取線資料
    await this.cgcService.load();
    // 預約掛號設定
    await this.reserveService.init();
    await this.dReserveService.init();
    // 設定需要使用StartUpService的Service，未避免循環參考故不再對方進行注入
    this.fontGreatService.Init(this);
    this.signalRService.startConnection((s) => {

      this.signalRService.userLogin()
    });
    // await this.getAnnoucementAlert();
    // 改到main-layout的ngOnInit()，必須在hcrService.Connect()之後執行，因為呼叫api是透過主控台。
    // await this.getTOCCBulletin().then(async () => {
    //   setTimeout(async () => {
    //     await this.getAnnoucementAlert();
    //   }, 1000);
    // });
  }

  private async getUser() {
    var user = UserCache.getLoginUser();
    // Get User Data
    this.userData = await this.userApi.getFullUser(user.UserId);

    //服務專線後面加上客服分機
    var phone = await this.clinicApi.GetServicePhone(user.Clinic.Code);
    user.Clinic.CustomerServiceExt += phone ? ' ' + phone : '';
  }

  async initByPosition() {
    // 醫師 -- 都顯示
    // if (this.isDoctor()) {
    // 醫師預載入院內資料
    await this.hist.getEditOptions();
    await this.updateLabImport();
    // var phSelected = this.session.getPhar();
    // 登入後為空, null是無藥師
    // if (phSelected == undefined) {
    // if (UserCache.getLoginUser().Clinic.HasPharmacist) {
    await this.showSelectPhar();   // 不論有無藥師都要顯示，要讓院所勾選是否寫卡和衛星藥局
    // }
    // }
    // }
  }

  private isDoctor() {
    return this.userData.positions.find(p => p.value == '10' || p.value == '11');
  }

  private isPharm() {
    this.userData.positions.find(p => p.value == '40' || p.value == '41')
  }

  public async showSelectPhar() {
    var phSelected = this.session.getPhar();
    var p = await this.clinicDataService.getParam("REG001");
    var hst001 = await this.clinicDataService.getParam("HST001");

    var secRange = new TimeSectionRange(p);
    var defCardWite = this.session.getData('writeCardData') ?? hst001.IsWriteICDefault;
    var defUseSatellite;
    var defUseEmr = this.session.getData('useEmr');
    var defUseOutVisit = this.session.getData('useOutVisit');

    var defSat = this.session.getData('useSatellite');

    var sec = TimeSectionRange.getSectionValue(secRange);
    var ph = await this.scheduleApi.GetPharmarcist(new Date(), sec);

    // 如果ph為空，新增一個無藥師的選項=>改成有藥師也可以委外ex巡診要委外
    //if (ph.length == 0) {
        ph.push({ text: '無藥師', value: 0 })
    //}
    const existph = ph.some(item => item.value === phSelected);  //可能記錄到別家的，避免一些空白發生
    //由參數來的
    var hasSatelliteIP = (!hst001.SatellitePhamacyIP || hst001.SatellitePhamacyIP == ' ') ? false : true;
    var hasEmrPath = (!hst001.EMRXmlPath || hst001.EMRXmlPath == ' ') ? false : true;
    var enableOutVisit = hst001.EnableOutVisit != null && hst001.EnableOutVisit;
    var hasOutSatelliteIP = (!hst001.OutVisitSatellitePhamacyIP || hst001.OutVisitSatellitePhamacyIP == ' ') ? false : true;

    if (typeof defSat == 'number') {
        defUseSatellite = defSat;
    } else if ( (defSat == undefined || defSat == null) && hasOutSatelliteIP) {
      //登出後會沒有session，defSat == undefined，有衛星藥局的先預設為1
        defUseSatellite = 1;
    } else if (defSat == true) {
      //原本參數是boolean，改成number
        defUseSatellite = 1;
    } else {
        defUseSatellite = 0;
    }

    var sat = [];
    // 兩個都有：push '一般' 和 '巡診' 和 '關閉'，預設 '一般'
    sat.push({ text: '一般', value: 1 }); //預設
    sat.push({ text: '關閉', value: 0 });
    sat.push({ text: '巡診', value: 2 });

    if (hasSatelliteIP && hasOutSatelliteIP) {
      // 兩個都有：'一般' 和 '巡診' 和 '關閉'，預設 '一般'
    } else if (hasSatelliteIP && !hasOutSatelliteIP) {
       // 只有一般： '一般' 和 '關閉'，預設 '一般'
        sat = sat.filter(x => x.value != '2');
    } else if (!hasSatelliteIP && hasOutSatelliteIP) {
       // 只有巡診：'關閉' 和 '巡診'，預設 '關閉'
        sat = sat.filter(x => x.value != '1');
    } else {
      // 兩個都沒有：'關閉'，預設 '關閉' =>基本上不會進來
        sat = sat.filter(x => x.value == 0);
    }

    if (sat.findIndex(s => s.value == defUseSatellite) == -1) {
      defUseSatellite = sat[0].value;
    }

    // 預設值
    var phValue = (phSelected != undefined && phSelected != null && existph ) ? phSelected : ph[0].value;
    var isWriteCardValue = defCardWite == undefined ? true : defCardWite;
    var useEmrValue = hasEmrPath ? (defUseEmr == undefined ? true : defUseEmr) : hasEmrPath;
    var useOutVisitValue = defUseOutVisit == undefined ? false : defUseOutVisit; //預設不巡診
    var satValue = (hasSatelliteIP || hasOutSatelliteIP) ?(defUseSatellite) : sat[0].value;

    var formField: FormFieldCollection<{ ph: number, isWriteCard: boolean, useSatellite: number, useEmr: boolean, useOutVisit: boolean }> = {};
    formField.ph           = { label: '值班藥師', name: 'ph'          , value: phValue, data: ph  , type: 'dropdown', order: 1 };
    formField.isWriteCard  = { label: '寫IC卡'  , name: 'isWriteCard' , value: isWriteCardValue   , type: 'check'   , order: 2 };
    formField.useEmr       = { label: '電子病歷', name: 'useEmr'      , value: useEmrValue        , type: 'check'   , order: 3 };
    formField.useOutVisit  = { label: '巡診'    , name: 'useOutVisit' , value: useOutVisitValue   , type: 'check'   , order: 4 };
    formField.useSatellite = { label: '衛星藥局', name: 'useSatellite', value: satValue, data: sat, type: 'radio' , order: 5 };

    let baseFields: (keyof typeof formField)[] = ['ph', 'isWriteCard'];
    let conditionalFields: {[K in keyof typeof formField]?: boolean} = {
      useSatellite: hasSatelliteIP || hasOutSatelliteIP,
      useEmr: hasEmrPath,
      useOutVisit: enableOutVisit
    };

    var finalFormField = this.buildFormField(formField, baseFields, conditionalFields);

    var formValue;
    formValue = await this.easyForm.show({
      title: '設定',
      msg: '請選擇值班藥師',
      fields: finalFormField,
      hideClose : true
    });

    let inconsistentMessage = '';
    const isOutVisit = formValue.useOutVisit;
    const satelliteType = formValue.useSatellite;
    if (isOutVisit && satelliteType == 1) {
      inconsistentMessage = '"巡診"建議選擇"巡診衛星藥局"';
    } else if (!isOutVisit && satelliteType == 2) {
      inconsistentMessage = '"非巡診"建議選擇"一般衛星藥局"';
    }

    if (inconsistentMessage) {
      await this.userConfirm.showConfirm(
        {
          title: '設定不一致警告！！！',
          msg: `${inconsistentMessage}\n\n請重新整理瀏覽器確認您的設定`,
          hideNo: true,
          textYes: '確認',
          width: 350
        })
    }

    if (satelliteType > 0 && formValue.ph > 0) {
      await this.userConfirm.showConfirm(
        {
          title: '設定不一致警告',
          msg: `有開啟衛星藥局，已將值班藥師調整為"無藥師"\n\n若要修改請重新整理瀏覽器確認您的設定`,
          hideNo: true,
          textYes: '確認',
          width: 350
        })
        formValue.ph = 0;
    }

    this.session.setPhar(formValue.ph);
    this.session.setData('writeCardData', formValue.isWriteCard);
    this.session.setData('useSatellite', (formValue.useSatellite == null || formValue.useSatellite == undefined) ? 0 : formValue.useSatellite);
    this.session.setData('useEmr'      , (formValue.useEmr == null || formValue.useEmr == undefined) ? false : formValue.useEmr);
    this.session.setData('useOutVisit' , (formValue.useOutVisit == null || formValue.useOutVisit == undefined) ? false : formValue.useOutVisit);
  }

  private buildFormField<T>(
    formField: T,
    baseFields: (keyof T)[],
    conditionalFields: {[K in keyof T]?: boolean}
  ): Partial<T> {
    let ff: Partial<T> = {};

    // 基礎
    baseFields.forEach(field => {
      if (formField[field] != null) {
        ff[field] = formField[field];
      }
    });

    // 條件
    (Object.entries(conditionalFields) as [keyof T, boolean][]).forEach(([field, condition]) => {
      if (condition && formField[field] != null) {
        ff[field] = formField[field];
      }
    });
    return ff;
  }


  public async checkPwdChange() {
    if (this.userData.needChangePwd) {
      await this.showChangePwd('尚未設定密碼，請更新您的密碼。')
    }
  }

  public async showChangePwd(msg: string = '') {
    var ret = await this.easyForm.show<{ oldPwd: string, newPwd: string, confirmPwd: string }>({
      title: '更改密碼',
      msg: msg,
      tip: '密碼為至少六碼以上，且包含英文與數字',
      beforeSubmit: (async (data) => {
        try {
          await this.userApi.changePwd(data.oldPwd, data.newPwd)
          return true;
        } catch (ex) {
          var msg = ex?.message;
          this.notify.showError(NullOrEmpty(msg) ? ex : msg);
          return false;
        }
      }).bind(this),
      fields: {
        oldPwd: {
          label: '目前密碼',
          name: 'oldPwd',
          type: 'pwd',
          value: '',
          required: true,
          order: 1
        },
        newPwd: {
          label: '新密碼',
          name: 'newPwd',
          type: 'pwd',
          value: '',
          required: true,
          order: 2,
          validator: [Validators.pattern(/^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/)]
        },
        confirmPwd: {
          label: '確認密碼',
          name: 'confirmPwd',
          type: 'pwd',
          value: '',
          required: true,
          order: 3,
          validator: [(c) => {
            if (!c.value) {
              //只顯示必填
              return null
            }
            var er = Validators.pattern(/^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/)(c);
            if (er) {
              // 只顯示格式不符
              return er;
            } else {
              var newV = c.parent.get('newPwd');
              if (c.value == newV.value) {
                return null;
              } else {
                return { custom: '與新密碼不一致' }
              }
            }
          }]
        }
      }
    })
  }

  async checkMonthSchedule() {
    var hasMonth = await this.scheduleApi.HasMonthSchedule(new Date());
    // 取得周排班資料
    var weeklyData: Schedule[] = await this.api.get('GetWeekly').toPromise();
    if (hasMonth == false) {
      var msgText = "查無包含今日之後的月排班資料。\n請選擇欲從周排班進行轉換的時間範圍";  // 無月排班但有周排班
      if (weeklyData.length <= 0) {
        msgText = "查無包含今日之後的月排班資料。\n請先建立排班資料";  // 無月排班也無周排班
      }
      var opts = await this.scheduleApi.GetData();
      await this.easyForm.show<{ startDate: Date, endDate: Date }>({
        title: '是否建立月排班',
        msg: msgText,
        fields: {
          startDate: {
            label: '開始日期',
            type: 'date',
            value: new Date(),
            name: 'startDate',
            required: true,
            order: 1,
            validator: [
              ValidationTipComponent.MaxDate(new Date(opts.lastGenMonthDate)),
              ValidationTipComponent.MinDate(new Date())
            ],
          }, endDate: {
            label: '結束日期',
            type: 'date',
            value: new Date(opts.lastGenMonthDate),
            name: 'endDate',
            required: true,
            order: 2,
            validator: [
              ValidationTipComponent.MaxDate(new Date(opts.lastGenMonthDate)),
              ValidationTipComponent.MinDate(new Date())
            ],
          }
        },
        showBtnCancel: true,
        beforeSubmit: async (data) => {
          try {
            await this.scheduleApi.GenMonthFromWeek(data.startDate, data.endDate);
            this.notify.showSuccess('月排班建立完成');
          } catch (ex) {
            this.notify.showError(ex)
          }
          return true;
        }
      })
    }
  }

  vpnState: VPNState = 'Fail';
  pingRs: (boolean) => void;
  pingTimeout = null;
  lastTryDt: Date = null;
  reloadAngle = 0;
  Ping_VPN(): Promise<boolean> {

    if (this.pingTimeout != null) {
      throw '正在等候結果';
    }
    if (this.lastTryDt != null && (new Date().getTime() <= (this.lastTryDt.getTime() + 5000))) {
      throw '操作時間過近';
    }
    this.lastTryDt = new Date();
    var p = new Promise<boolean>((rs, rj) => {
      this.pingRs = rs;
    })
    var ip = 'medvpn.nhi.gov.tw/'
    var img = new Image();
    img.onload = async () => {
      await vpnSuccess();
    };
    img.onerror = async () => {
      await vpnSuccess();
    };

    var st = new Date();
    var vpnSuccess = async () => {
      this.vpnState = 'Ok';
      if (this.pingTimeout != null) {
        clearTimeout(this.pingTimeout);
        this.pingTimeout = null;
      }
      var sec = (new Date().getTime() - st.getTime());
      if (sec > 3000) {
        this.vpnState = 'Slow';
      }
      this.pingRs(true);
      await this.hcrService.VisionApi.VerifySAMDC();
    }

    this.pingTimeout = setTimeout(async () => {
      console.log(new Date().toLocaleTimeString(), 'VPN Time Out')
      console.log('Ping_VPN timeout')
      this.vpnState = 'Fail';
      this.pingRs(false);
      var ret = await this.userConfirm.showConfirm(
        {
          title: '警告！！！',
          msg: '無法連線至健保VPN，請重試一次或者檢查您的網路環境。',
          textYes: '重試',
          textNo: '確認'
        })
      img.onload = null;
      img.onerror = null;
      img.src = '';
      this.pingTimeout = null;
      if (ret) {
        this.Ping_VPN();
      }
    }, 5000);
    setTimeout(() => {
      img.src = "https://" + ip;
    }, 0);

    return p;
  }

  async updateLabImport() {
    try {
      var ret = await this.labApi.ImportLab('');
      if (ret.ImportCount) {
        await this.userConfirm.showAlert('匯入結果', `新取得 ${ret.ImportCount} 筆匯入資料。\n更新 ${ret.LabForms.length} 筆檢驗報告。`)
      }
    } catch (e) {
      this.notify.showErrorWithPrefix('檢驗匯入資料取得失敗：', e,false, false);
    }
  }
  async getAnnoucementAlert() {
    await this.announcement.init();
    await this.announcement.alertOnLogin();
  }

  async getTOCCBulletin() {
    var isEnabledTOCC = await this.toccService.isEnabledTOCC(this.vpnState);
    if (isEnabledTOCC) {
      var cln = UserCache.getLoginUser().Clinic;
      var parameter: bulletinParameter = {
        regInstitutionCode: cln.NHICode
      };
      var dengueFormDto = new DengueFormDto();
      dengueFormDto.ClinicId = cln.Id;
      dengueFormDto.ClinicCode = cln.Code;
      dengueFormDto.PatientNo = '';
      dengueFormDto.PatientId = 0;
      dengueFormDto.QueryTime = new Date();
      dengueFormDto.RegInstitutionCode = cln.NHICode;
      dengueFormDto.RegInstitutionName = cln.Name;

      try {
        var [retResult, retParameter, retApiUrlType] = await this.toccService.getBulletin(parameter);

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.QueryParameter = retParameter;
        dengueFormDto.QueryResponse = retResult;

        var bulletin = (retResult ? JSON.parse(retResult) as bulletinResponse : null);
        if (bulletin != null) {
          var toccMessage = bulletin.message ? bulletin.message : '';

          dengueFormDto.QueryReturnCode = bulletin?.code;
          dengueFormDto.QueryReturnMessage = toccMessage;

          if (bulletin.code != 200) {
            this.notify.showError('TOCC讀取公告失敗: ' + '(' + bulletin.code.toString() + ')' + toccMessage);
          } else {
            var anyPublishInstitution: boolean[] = [];
            var anyPublishPatient: boolean[] = [];
            await Promise.all(bulletin.result.map(async (b) => {
              var [isPublishInstitution, isPublishPatient] = await this.createTOCCAnnouncement(b);
              anyPublishInstitution.push(isPublishInstitution);
              anyPublishPatient.push(isPublishPatient);
            })).then(async () => {
              if ((anyPublishInstitution.length == 0 && anyPublishPatient.length == 0) ||
                (anyPublishInstitution.every(x => x === false) && anyPublishPatient.every(x => x === false))) {
                await this.expireTOCCAnnouncement('05,06');
              } else {
                if (anyPublishInstitution.every(x => x === false)) await this.expireTOCCAnnouncement('05');
                if (anyPublishPatient.every(x => x === false)) await this.expireTOCCAnnouncement('06');
              }
            });
          }
        } else {
          dengueFormDto.QueryReturnCode = -1;
          dengueFormDto.QueryReturnMessage = 'TOCC讀取公告失敗';

          this.notify.showError(dengueFormDto.QueryReturnMessage);
        }
      } catch (ex) {
        var [retResult, retParameter, retApiUrlType] = Array.isArray(ex) ? ex : [ex, null, null];

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.QueryParameter = retParameter;
        dengueFormDto.QueryResponse = retResult;

        dengueFormDto.QueryReturnCode = -99;
        dengueFormDto.QueryReturnMessage = 'TOCC讀取公告發生錯誤: ' + retResult;

        this.notify.showError(dengueFormDto.QueryReturnMessage);
      } finally {
        try {
          await this.toccApi.CreateDengueForm(dengueFormDto);
        } catch (ex) {
          // this.notify.showError('TOCC建立紀錄發生錯誤: ' + ex);
        }
      }
    }
  }

  async createTOCCAnnouncement(result: bulletinResult) {
    if (!result) return [null, null];

    var cln = UserCache.getLoginUser().Clinic;
    var clinicCode = cln.NHICode;
    var clinicAddr = (cln.City??'') + (cln.Area??'') + (cln.Street??'');
    var isPublishInstitution = false;
    var isPublishPatient = false;
    // 0:全部發佈, 註：原TOCC政令，依照上述條件顯示於登入頁或診間首頁
    // 1:指定醫事機構行政區, 註：同上
    // 2:指定醫事機構, 註：同上
    // 3:指定病患通訊地址行政區域, 註：原TOCC警示，顯示於病歷製作頁；病人地址或機構地址符合條件就要顯示
    if (result.type == '0') {
      isPublishInstitution = true;
    } else if (result.type == '1' && result.institutionAreaList && result.institutionAreaList.length > 0) {
      var toccAreas = result.institutionAreaList.filter(x => x);
      if (toccAreas.some(x => clinicAddr.indexOf(x) >= 0)) {
        isPublishInstitution = true;
      }
    } else if (result.type == '2' && result.institutionList && result.institutionList.length > 0 && clinicCode) {
      if (result.institutionList.some(x => x == clinicCode)) {
        isPublishInstitution = true;
      }
    } else if (result.type == '3' && result.patientAreaList && result.patientAreaList.length > 0) {
      //先存起來，等看診時才判斷病患地址
      isPublishPatient = true;
    }

    if (result.institutionExcludeList && result.institutionExcludeList.length > 0 &&
      result.institutionExcludeList.some(x => x == clinicCode)) {
        isPublishInstitution = false;
        isPublishPatient = false;
    }

    if (isPublishInstitution || isPublishPatient) {
      var announcement = new Announcement();
      announcement.CompanyId = Number(cln.CompanyId);
      announcement.TypeCode = 'IMP';
      announcement.ClassCode = isPublishInstitution ? '05' : '06';//TOCC政令/TOCC警示
      announcement.Title = '高雄市衛生局TOCC' + (isPublishInstitution ? '政令' : '警示');
      announcement.Content = result?.content ?? '';
      if (announcement.Content) {
        var addition = '';
        if (isPublishPatient) {
          addition += '病患通訊地址行政區域: ' + result.patientAreaList.join(',') + '\n';
        }
        if (result.updateTime) {
          addition += '更新時間: ';
          if (!isNaN(Date.parse(result.updateTime))) {
            addition += DateHelper.formatROCDateTime(result.updateTime, false, false) + ' ' + DateHelper.getTimeString(new Date(result.updateTime), ':', true) + '\n';
          } else {
            addition += result.updateTime + '\n';
          }
        }
        addition += '高雄市政府衛生局\n';

        announcement.Content += '\n\n' + addition;
      }
      announcement.StartTime = DateHelper.today;
      announcement.EndTime = DateHelper.addMonthDay(DateHelper.today, 6);
      var alertOn: AlertOn[] = [];
      if (isPublishInstitution) {
        alertOn.push({ AlertOn: 'LOGIN', AlertCondition: '', AlertConditionCol: '', AlertConditionOp: '', AlertConditionValue: '' });
      } else {
        alertOn.push({ AlertOn: 'OPD', AlertCondition: 'patient', AlertConditionCol: 'street', AlertConditionOp: 'some', AlertConditionValue: result.patientAreaList.join(',') });
      }
      announcement.AlertOn = JSON.stringify(alertOn);
      try {
        //dataScope: 0全部, 1院所公告, 2系統公告(全院所)
        await this.announcementApi.CreateSpecific(announcement, [], 1);
      }
      catch (ex) {
        this.notify.showError('建立TOCC公告發生錯誤: ' + ex);
        return [null, null];
      }
    }

    return [isPublishInstitution, isPublishPatient];
  }

  async expireTOCCAnnouncement(classCodes: string) {
    var cln = UserCache.getLoginUser().Clinic;
    var announcement = new Announcement();
    announcement.CompanyId = Number(cln.CompanyId);
    announcement.ClassCode = classCodes;
    try {
      await this.announcementApi.ExpireSpecific(announcement, [], 1);
    }
    catch (ex) {
      this.notify.showError('過期TOCC公告發生錯誤: ' + ex);
    }
  }

}

