<div >
    <select class="rounded-2 px-2 mt-2 mb-1 easy-form-msg"
      (change)="onYearSelectChange($event)"
      [(ngModel)]="selectedYearItem">
      <option *ngFor="let y of years" (value)="y">{{y}}</option>
    </select>
    <select
      class="rounded-2 px-2 ml-1 mt-2 mb-1 easy-form-msg"
      (change)="onMonthSelectChange($event)"
      [(ngModel)]="selectedMonthItem">
      <option *ngFor="let m of months" (value)="m-1">{{m}}</option>
    </select>
  </div>
<div class="css-table">
  <div class="thead">
    <div class="tr">
      <!-- <div class="td-title"> </div> -->
      <div class="td" [ngClass]="{'firstColumn': wi==0 , 'endColumn': wi==6 }" *ngFor="let w of week;let wi = index;">
        {{w.text}}
      </div>
    </div>
  </div>
  <div class="tbody">
    <div class="tr" [ngClass]="{'oddRow': si%2==1, 'evenRow': si%2==0}" *ngFor="let s of weekNum;let si = index;">
      <div class="td day-setting"
        style="  min-height:7rem;
        max-height: 7rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;"
        [ngClass]="{'firstColumn': di==0 , 'endColumn': di==6 }"
        *ngFor="let d of getCellWeekSlice(si);let di = index;">
        <ng-container *ngIf="d" >
          <div style="display:flex">
            <div style="flex-grow:1;" [ngClass]="{'day': di%7 != 6, 'day2': di%7 == 6}">{{d?.DisplayTitle}}</div>
            <div>
              <ng-template [ngTemplateOutlet]="templateControlBar" [ngTemplateOutletContext]="{$implicit: (d)}">
              </ng-template>
            </div>
          </div>
          <div class="clearfix"></div>
          <div style="width:100%;float:left;overflow-y: auto;">
            <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: (d)}">
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
