import { ClinicDataService } from '../../../../services/data-service/clinic-data-service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReportComponent } from '../../../../system/report/components/report-component';
import { ReportComponentService } from '../../../../system/report/components/report-component.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { ReceiptComponent } from '../../../../system/report/components/receipt/receipt.component';
import { combineLatest, Subject } from 'rxjs';
import { MedicationWayReportComponent } from '../medication-way-report/medication-way-report.component';
import { ReceiptUnitComponent } from '../receipt-unit/receipt-unit.component';

@Component({
  selector: 'app-medication-way-and-receipt-report',
  templateUrl: './medication-way-and-receipt-report.component.html',
  styleUrls: ['./medication-way-and-receipt-report.component.css'],
})
export class MedicationWayAndReceiptReportComponent implements ReportComponent, OnInit {
  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;
  @ViewChild('wayReport', { static: false })
  wayReport: MedicationWayReportComponent;
  @ViewChild('recpReport', { static: false })
  recpReport: ReceiptUnitComponent;

  @Input() regId: number;
  @Output() printed: EventEmitter<void> = new EventEmitter();

  doubleSidedPrintMedicationWayAndReceipt: boolean = false;
  showHandledMessage: boolean = true;
  fontType: string;

  private medicationWayReportReady$ = new Subject<boolean>();
  private receiptReportReady$ = new Subject<boolean>();
  private isOnPrint$ = new Subject<boolean>();

  constructor(private reportComponentService: ReportComponentService,
    private notification: EasyNotificationService,
    private localPrintService: LocalPrintService,
    private clinicDataService: ClinicDataService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.init();

  }

  ngOnDestroy() {
    this.medicationWayReportReady$.complete();
    this.receiptReportReady$.complete();
    this.isOnPrint$.complete();
  }

  async init(): Promise<void> {
    await this.getDoubleSidedPrintMedicationWayAndReceipt();

    if (this.doubleSidedPrintMedicationWayAndReceipt) {
      combineLatest([
        this.isOnPrint$,
        this.medicationWayReportReady$,
        this.receiptReportReady$
      ]).subscribe(([isOnPrint, medicationReady, receiptReady]) => {
        if (isOnPrint && medicationReady && receiptReady) {
          this.handlePrint();
        }
      });
    }
    else {
      combineLatest([
        this.isOnPrint$,
        this.medicationWayReportReady$,
      ]).subscribe(([isOnPrint, medicationReady]) => {
        if (isOnPrint && medicationReady) {
          this.cdr.detectChanges();

          this.handlePrint();
        }
      });
    }

  }

  async handlePrint(): Promise<void> {
    const reportName = '服法與收據';
    const printContents = this.getHtml();

    var ret = await this.localPrintService.printHtml(3, reportName, printContents);  // 'Consultation Volume Statistic'
    if (this.showHandledMessage) {
      if (ret.Successed) {
        //this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    }

    this.isOnPrint$.next(false);

    this.printed.emit();
  }

  async setRegId(regId){
    this.regId = regId;
    await this.wayReport.setRegId(regId);
    await this.recpReport?.setRegId(regId);
  }

  onPrint(regId: number = null, showHandledMessage: boolean = true) {
    if (regId) {
      this.regId = regId;
    }

    this.showHandledMessage = showHandledMessage;
    this.isOnPrint$.next(true);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  getHtml(): string {
    let cssText = this.reportComponentService.getAllCss(this.reportContainer.nativeElement);

    let style = `
      <style>
        ${cssText}

        @page {
          size: '20.6cm 9cm;';
          margin: 0cm;
          padding: 0cm;
        }
      </style>
    `;

    var html = this.reportContainer.nativeElement.innerHTML;

    let fullHtml = `
    <html>
      <head>${style}</head>
      <body style="margin: 0cm;padding:0cm">${html}</body>
    </html>`;

    return fullHtml;
  }

  async getDoubleSidedPrintMedicationWayAndReceipt() {
    const prn001 = await this.clinicDataService.getParam("PRN001");
    this.doubleSidedPrintMedicationWayAndReceipt = prn001.DoubleSidedPrintMedicationWayAndReceipt ?? false;
    this.fontType = (prn001.FontTypeForPrint == 0 ? '細明體' : (prn001.FontTypeForPrint == 1 ? '標楷體' : (prn001.FontTypeForPrint == 2 ? '微軟正黑體' : '細明體')));
  }

  onMedicationWayReportReady() {
    this.medicationWayReportReady$.next(true);
  }

  onReceiptReportReady() {
    this.receiptReportReady$.next(true);
  }

}

