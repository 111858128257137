import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { ScheduleApi } from 'src/app/services/api-service/schedule/schedule-api';
import { UserApi } from 'src/app/services/api-service/user/user-api';
import { PatientApi,PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { CumulativeMedicalVisitsComponent } from 'src/app/system/report/components/cumulative-medical-visits/cumulative-medical-visits.component';

@Component({
  selector: 'app-hist-doctor-patient-details',
  templateUrl: './doctor-patient-details.component.html',
  styleUrls: ['./doctor-patient-details.component.css']
})
export class DoctorPatientDetailsComponent  implements OnInit {

  @ViewChild(CumulativeMedicalVisitsComponent)
  cumulativemedicalvisitsComp:CumulativeMedicalVisitsComponent;

  rooms: ValueTextPair[];
  // Room code
  roomSelected: any;
  roomsDropdown: ValueTextPair[] = [];
  // Doctor code
  doctorsDropdown : ValueTextPairNumberValue[];
  DoctorList: ValueTextPairNumberValue[] = [];
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ScheduleApi : ScheduleApi,
    private UserApi : UserApi,
    private PatientApi : PatientApi,
    private webApiClient: WebApiClient,
    private notification: EasyNotificationService) {
    this.api = webApiClient.createHisService('schedule/shiftschedule');
    ValueTextPairNumberValue
  }

  // api服務
  api: WebApiService;

  // 查詢條件
  queryFormGroup: FormGroup;

  ReportType: ValueTextPair[] = [
    { value: '1', text: '1.醫師別報表' },
    { value: '2', text: '2.病患就醫名冊' },
    { value: '3', text: '3.累積就醫次數' },
    { value: '4', text: '4.健保就醫病患名冊' }];

  ngOnInit() {
    this.createQueryFormGroup();
    this.getQueryOptions();
  }

  // 建立query form group物件與預設值
  createQueryFormGroup() {
    this.queryFormGroup = this.fb.group({
      RoomId:[], //先預設空值
      DoctorId:[], //先預設空值
      ReportType:[this.ReportType[0].value], //報表類別初始值
      startDate: [new Date(new Date().getFullYear(), new Date().getMonth())], //當月1號
      endDate: [new Date(new Date())], //當天日期
});
    // this.queryFormGroup.controls['RoomId'].setValue(this.roomsDropdown[0].value);
    // this.queryFormGroup.controls['DoctroId'].setValue(this.doctorsDropdown[0].value);
    // this.queryFormGroup.controls['ReportType'].setValue(this.ReportType[0].value);
  }

  // 由serve 取得查詢選項
  async getQueryOptions() {
    var RoomCodedata = await this.ScheduleApi.GetData(); //診間代號
    this.doctorsDropdown = await this.UserApi.GetDoctorList(); //醫師別代號
    this.rooms = RoomCodedata.medRoomList;
    this.roomsDropdown = this.rooms;
    this.queryFormGroup.controls.RoomId.setValue(this.roomsDropdown[0].value); //塞診別代號預設值
    this.queryFormGroup.controls.DoctorId.setValue(this.doctorsDropdown[0].extension); //塞醫師別代號預設值
  }

  // btnQuery
  async onBtnQueryClick() {
    var reportType = this.queryFormGroup.controls['ReportType'].value;
    var roomid = this.queryFormGroup.controls['RoomId'].value;
    var doctorid = this.queryFormGroup.controls['DoctorId'].value;
    var startDate = (new Date(this.queryFormGroup.controls['startDate'].value)).toLocaleDateString();
    var endDate = (new Date(this.queryFormGroup.controls['endDate'].value)).toLocaleDateString();
    if(reportType==1){
      var data : PatientListDto [] = await this.PatientApi.GetPatientDataByDate(startDate,endDate,roomid,doctorid);
    }
    if(reportType==2){
      var data : PatientListDto [] = await this.PatientApi.GetPatientDataByDateGrouping(startDate,endDate,roomid,doctorid);
    }
    if(reportType==3){
      var data : PatientListDto [] = await this.PatientApi.GetPatientDataByDateGrouping(startDate,endDate,roomid,doctorid);
    }
    if(reportType==4){
      var data : PatientListDto [] = await this.PatientApi.GetPatientDataByDateGrouping(startDate,endDate,roomid,doctorid);
    }
    var Seledoctor = this.doctorsDropdown.find(doc => doc.extension === doctorid);
    var doctorName = Seledoctor ? Seledoctor.text : "未找到醫師"
    this.cumulativemedicalvisitsComp.fetchData(data,roomid,doctorid,startDate,endDate,reportType,doctorName);


    // var reportType = this.queryFormGroup.controls['ReportType'].value;
    // if(reportType == "1"){
    //   console.log("選擇的報表類型是：1.醫師別報表");  //畫下面的頁面
    // }else if(reportType == "2"){
    //   console.log("選擇的報表類型是：2.病患就醫名冊");  //畫下面的頁面
    // }else if(reportType == "ˇ"){
    //   console.log("選擇的報表類型是：3.累積就醫次數");  //畫下面的頁面
    // }else if(reportType == "ˋ"){
    //   console.log("選擇的報表類型是：4.健保就醫病患名冊");  //畫下面的頁面
    // }

  }

  onBtnPrintClick() {
    var reportType = this.queryFormGroup.controls['ReportType'].value;
    this.cumulativemedicalvisitsComp.onPrint(reportType);
  }

  onBtnExportAsExcel() {
    var reportType = this.queryFormGroup.controls['ReportType'].value;
    this.cumulativemedicalvisitsComp.exportAsExcel(reportType);
  }


  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }
}
