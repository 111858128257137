<div *ngIf="this.showFilter" style="display: inline-block;">
  <span style="float: left;"> Doctors :</span>
  <div style="float: left;margin-left: 10px;">
    <input type="radio" [(ngModel)]="useFilter" [value]="false" id="all" class="k-radio" checked />
    <label class="k-radio-label" for="all">All</label>
  </div>
  <div style="float: left;margin-left: 10px;">
    <input type="radio" [(ngModel)]="useFilter" [value]="true" id="current" class="k-radio" />
    <label class="k-radio-label" for="current">Current</label>
  </div>
  <div style="float: left;margin-left: 10px;">
    <app-vis-dropdownlist [data]="this.doctors" [valueField]="'value'" [textField]="'text'" [(ngModel)]="docterSelected"
      (valueChange)="onDoctorSelected($event)"></app-vis-dropdownlist>
  </div>
</div>
<app-calendar-month [yearStart]="year" [yearEnd]="year+1" [selectedMonth]="this.month" [selectedYear]="year"
  [week]="week" (dateEvent)="onMonthChange($event)" [allowMonth]="this.allowMonth">
  <ng-template let-item>
    <div style="display: table; width: 100%;">
      <ng-container *ngFor="let sss of getItems(item.Data); let si = index;">
        <div class="regItem" (click)="onMonthItemClick(sss.id)" title="{{sss.tip}}">
          <div class="regTimeSec">{{sss.secName}}</div>
          <div class="regRoom">{{sss.roomName}}</div>
          <div [ngStyle]="sss.color" class="regDoctor font-weight-bold">
            <span class="d-inline-flex" *ngIf="sss.name.length <= 3">{{sss.name}}</span>
            <span class="d-inline-flex word-name-much" *ngIf="sss.name.length > 3">{{sss.name}}</span>
            ({{ getPosName(sss.pos) }})
            <ng-container *ngIf="sss.phName!=null && sss.phName!=''">
              <mat-icon class="link-icon">link</mat-icon>
              <span style="color:#fab415">{{sss.phName}}</span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <ng-template let-item #controlBar>
    <button *appAllowPermissionIf="[[this.UserPermissions.Add],null,isComponentMode]"
      type="button"
      class="addBtn"
      (click)="onAddNewScheduleClick(item.Date)"
      i18n="@@Add"
      class="text-xs btn-grid btn-grid-add pr-1 pt-0 background-none">
       <mat-icon
        class="mat-icon notranslate position-relative float-left d-inline-flex btn-scale-90 material-icons mat-icon-no-color"
        style="top:1px"
        title="新增">
        add_circle
      </mat-icon>
       <span class="text-xs-sm float-right mt-1">新增</span>
     </button>
  </ng-template>
</app-calendar-month>

<!-- 修改、刪除排班人員Dialog -->
<app-vis-dialog [show]="showDialog"  (close)="onCloseButtonClick()">
  <div class="sch-edit-panel pt-3 px-3 extend-msg">
    <div class="sch-edit-field">
      <span  i18n="@@DoctorName">職位</span>:
      <div >
        <ng-container *ngIf="(isAddMode||isUpdateMode);else postmp">
          <app-vis-dropdownlist [data]="positions"
          [valuePrimitive]="true" [(ngModel)]="positionSelected"
          (valueChange)="positionChange($event)"></app-vis-dropdownlist>
        </ng-container>
        <ng-template #postmp>
          <input [value]="positions[positionSelected].text" readonly />
        </ng-template>
      </div>
    </div>
      <div class="sch-edit-field">
        <!--醫師或藥師-->
        <span  i18n="@@DoctorName">{{positions[positionSelected].text}}</span>:
        <div >
          <ng-container *ngIf="(isAddMode||isUpdateMode);else tmp">
            <app-vis-dropdownlist [data]="this.userDropdown"
            [valuePrimitive]="true" [(ngModel)]="dialogSelectedDoctor" (valueChange)="UpdateName(dialogSelectedDoctor)"></app-vis-dropdownlist>
          </ng-container>
          <ng-template #tmp>
            <input [value]="dialogSelectedDoctorName" readonly />
          </ng-template>
        </div>
      </div>
      <div class="sch-edit-field">
        <span i18n="@@Section">時段</span>:
        <div style="width:200px;">
          <app-vis-dropdownlist  [data]="sectorOfDay" [valuePrimitive]="true" [(ngModel)]="dialogSelectedSecTime"
            (valueChange)="onDialogSecTimeSelectChange($event)"></app-vis-dropdownlist>
        </div>
      </div>
      <div  class="sch-edit-field">
          <span i18n="@@Room">診間</span>:
          <div>
            <app-vis-dropdownlist [data]="roomDropdown" [valuePrimitive]="true"
              (valueChange)="onDialogRoomSelectChange($event)" [(ngModel)]="dialogSelectedRoom">
            </app-vis-dropdownlist>
          </div>
      </div>
      <!--醫師用-->
      <div  class="sch-edit-field" *ngIf="positionSelected==0">
          <span i18n="@@Room">藥師</span>:
          <div>
            <app-vis-dropdownlist [data]="dialogPh" [valuePrimitive]="true"
              [(ngModel)]="dialogSelectedDefaPh">
            </app-vis-dropdownlist>
          </div>
      </div>
      <div class="mt-1" style="color: #f44336" *ngIf="dialogScheduleIsUsedBy!=''">
        *{{dialogScheduleIsUsedBy}}
        <ng-container i18n="@@ScheduleDuplicate">於此診間時段已有排班.</ng-container>
      </div>
    </div>
  <app-shared-edit-control-buttons
    [hasDeleteButton]="!isAddMode"
    [hasEditButton]="false"
    (onEmitSave)="onSaveButtonClick()"
    (onEmitCancel)="onCloseButtonClick()"
    (onEmitDelete)="onDeleteButtonClick()"
    [isShowOnInit]="true"
    class="mb-1 mr-1 d-block"
    #dialogControlBtn>
  </app-shared-edit-control-buttons>
</app-vis-dialog>

