<style>
    @media print {
        /* @page {
            size: A5 landscape;
        }  */
        body {
            margin: 0;
            color: #000;
            background-color: #fff;
        }
    }
</style>
<div class="{{!isA5h ? 'a4-print-style p-2' : 'a5-rotate-print-style p-2'}}" #reportContainer [hidden]="isA6"> <!-- a4-print-style a5-rotate-print-style -->
    <div *ngIf="clinic&&hist" style="background-color: white;height: 100%;width: 100%;">
        <div  style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;" [hidden]="!isopenQRcode">
            <div style="display: flex;column-gap: 5px; border:1px solid black; border-collapse: collapse;height:250px" >
                <table style="width: 43%;" >
                    <tr>
                        <td>
                        <div>處方籤QR-Code</div>
                        <div>就醫日期：{{regdateString}}</div>
                        <div>就醫科別：{{medcodeName}}</div>
                        <div>姓名：{{hist?.Patient.CName}}</div>
                            <div>院所：{{clinic?.Name}}</div>
                        </td>
                    </tr>
                </table>
                <table style="width: 57%;" >
                    <tr>
                        <div class="con" >
                            <img style="width:240px;height: auto;padding-top: 2.5px;" #qrcodeImg>
                        </div>
                    </tr>
                    <tr>
                        <div class="con">
                            <img style="width:240px;height: auto;padding-top: 2.5px;" #qrcode2Img>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
        <div *ngIf="!isopenQRcode" style="text-align: center;font-weight: bolder;">全 民 健 康 保 險 門 診 交付調劑/慢性病連續 處 方 箋</div>
        <div *ngIf="!isopenQRcode && ic?.Itreatnum && this.isPrintItreatnum" style="display: flex;">
            <table style="width: 40%; margin-top: 10px; margin-left: 20px; border: none; font-size: 12px;">
              <tr>
                <td>
                  <div>原處方就醫識別碼：{{ic?.Itreatnum}}</div>
                  <div>原處方就醫時間：{{ic?.IRegDT}}</div>
                </td>
              </tr>
            </table>
            <ngx-barcode6 style="padding-top: 20px;" [bc-format]="'CODE39'" [bc-width]="0.9" [bc-height]="30" [bc-value]="ic?.Itreatnum"></ngx-barcode6>
            <div class="con">
              <img style="width:80px;height: auto;padding-top: 0px;" #qrcodeItreatnumImg>
            </div>
          </div>
        <div *ngIf="!isopenQRcode" style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;" class="pres-report">
            <div style="display: flex;column-gap: 5px;">
                <table style="width: 67%">
                    <tr>
                        <td style="width: 25%;">
                            特約醫事服務機構 代號及名稱
                        </td>
                        <td style="width: 75%;">
                            <div>{{clinic?.NHICode}}</div>
                            <div>{{clinic?.Name}}</div>
                        </td>
                    </tr>
                </table>
                <table style="width: 33%;">
                    <tr>
                        <td style="width: 40%;">一 般 處 方 箋</td>
                        <td>
                            <div style="width:10%;display: inline-block;">{{normalCheck}}</div>
                            <div style="width:90%;display: inline-block;">{{backRegDate}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>連 續 處 方 箋</td>
                        <td>
                            <div style="width:10%;display: inline-block;">{{cntPresCheck}}</div>
                            <div style="width:90%;display: inline-block;">{{deadLine}}</div>
                        </td>
                    </tr>
                </table>
            </div>
            <table style="margin-top: 5px;width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:25%">姓名：{{hist?.Patient.CName}}</td>
                    <td style="width:12.5%">性別： {{hist?.Patient.SexName}}</td>
                    <td style="width:37.5%">身分證字號：{{hist?.Patient.CId}}</td>
                    <td style="width:25%">出生日期：{{hist?.Patient.Birthday| formatROCDate:true:bitrhMask}} </td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:12.5%">就醫科別：{{hist?.Register.MedDeptCode}}</td>
                    <td style="width:25%">就醫日期： {{hist?.Register.RegDate | formatROCDate:true}}</td>
                    <td style="width:25%">健保卡就醫序號：{{hist?.Register.ICode=='000' ? '自費' : hist?.Register.Icno}}</td>
                    <td style="width:12.5%">案類： {{hist?.Hist.Case}}</td>
                    <td style="width:25%">給藥日份：{{hist?.Hist.Days}}</td>
                </tr>
            </table>
            <table style="border-top: 0;width: 100%;">
                <tr>
                    <td colspan="7">
                        傷病名稱及主要症候：{{dxNames}}
                    </td>
                    <td rowspan="2" colspan="2">
                        國際疾病分類代碼：<br>
                        {{dxCodes}}
                    </td>
                </tr>
                <tr>
                    <td colspan="7">免部分負擔代碼及原因：{{hist?.Register.ICode=='000' ? '' : (hist?.Hist.ApartCode + ' ' + hist?.Hist.ApartCodeDescription)}}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        藥品名稱及規格 (劑型、劑量)
                    </td>
                    <td colspan="5">
                        用法及用量
                    </td>
                    <td style="width:16%;">
                        <div style="width:50%;display: inline-block;">總數量</div>
                        <div style="width:50%;display: inline-block">金額小計</div>
                    </td>
                    <td style="width:9%">
                        備註
                    </td>
                </tr>
                <tr *ngFor="let hrx of hist.Hist.HRxs" style="line-height: 15px;">
                    <td style="width:10%;border: 0;">
                        {{hrx.StdCode}}
                    </td>
                    <td style="width:30%; border: 0;border-right: 1px solid black;">
                        <span style="width: 270px; display: inline-block; overflow: hidden; text-overflow: clip; white-space: nowrap;">
                            {{hrx.ProdName}}
                        </span>
                    </td>
                    <td style="width:10%;border: 0;">
                        {{hrx.Dose| NumberFixed:2 }}/{{hrx.Unit}}
                    </td>
                    <td style="width:5%;border: 0;">
                        <!-- <div style="width:55px;display: inline-block;padding-top: 2.5px;padding-bottom: 2.5px;">{{hrx.Way?.length > 0 ? hrx.Way.toUpperCase():hrx.Way}}</div> -->
                        {{hrx.Way?.length > 0 ? hrx.Way.toUpperCase():hrx.Way}}
                    </td>
                    <td style="width:6%;border: 0;">
                        {{hrx.Freq}}
                        <!-- <div style="width:45px;display: inline-block;padding-top: 2.5px;padding-bottom: 2.5px;">{{hrx.Freq}}</div> -->
                    </td>
                    <td style="width:5%;border: 0;"></td>
                    <td style="width:7%;border: 0">
                        {{hrx.Days}}日
                    </td>
                    <td style="width:16%;border: 0;border-left: 1px solid black;margin:0;">
                        <div style="width:50%; display: inline-block;">{{hrx.TotalDose}}</div>
                        <div style="width:50%; display: inline-block;">{{(hrx.IPrice*hrx.TotalDose) | NumberFixed:1}}</div>
                    </td>
                    <td style="width:9%;border: 0;border-left: 1px solid black;">
                        {{hrx.Remark}}
                    </td>
                </tr>
            </table>
            <table style="width: 100%;margin-top: 5px;">
                <tr>
                    <td style="width: 32%;border-bottom: 0;" rowspan="1">
                        診治醫生代號：{{clinic?.NHICode}}<br>
                    </td>
                    <td style="width: 10.5%;">
                        處方醫院診所
                    </td>
                    <td style="width: 22.5%;padding-left: 20px;">
                        一般處方箋專用
                    </td>
                    <td colspan="4" style="width: 25%;">
                        慢性病連續處方箋專用 可調劑{{hist?.Hist.CntPresTot}}次
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" style="border-top: 0;">
                        <br>
                        簽章：{{hist?.Register.DoctorName}}
                    </td>
                    <td style="width: 10%;">
                        核章
                    </td>
                    <td style="width: 22.5%;">
                        調劑藥師(生)代號：
                    </td>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        1
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[0]}}<br>  {{EndStr[0]}}
                    </td>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        3
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[2]}}<br>  {{EndStr[2]}}
                    </td>
                    <!-- <td rowspan="2" style="width: 12.5%;">
                        <div style="width:10%;display: inline-block;justify-content: center;align-items: center;">1</div>
                        <div style="width:90%;display: inline-block">{{StartStr[0]}}<br>  {{EndStr[0]}}</div>
                    </td>
                    <td rowspan="2" style="width: 12.5%;">
                        <div style="width:10%;display: inline-block;"><p style="justify-content: center;align-items: center">3</p></div>
                        <div style="width:90%;display: inline-block"><p> {{StartStr[2]}} <br> {{EndStr[2]}}</p></div>
                    </td> -->
                </tr>
                <tr>
                    <td rowspan="3">
                    </td>
                    <td rowspan="3">
                        簽章：<br>
                        調劑日期：<br>
                    </td>
                </tr>
                <tr>
                    <td>
                        聯絡電話：{{clinic?.Phone}}
                    </td>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        2
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[1]}}<br>  {{EndStr[1]}}
                    </td>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        4
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[3]}}<br>  {{EndStr[3]}}
                    </td>
                    <!-- <td rowspan="2">
                        <div style="width:10%;display: inline-block;"><p style="justify-content: center;align-items: center">2</p></div>
                        <div style="width:90%;display: inline-block"><p> {{StartStr[1]}} <br> {{EndStr[1]}}</p></div>
                    </td>
                    <td rowspan="2">
                        <div style="width:10%;display: inline-block;justify-content: center;align-items: center;">4</div>
                        <div style="width:90%;display: inline-block">{{StartStr[3]}} <br> {{EndStr[3]}}</div>
                    </td> -->
                </tr>
                <tr>
                    <td style="border-right: 1px solid;">傳真：{{clinic?.Fax}}</td>
                </tr>
            </table>
        </div>
        <div *ngIf="!isopenQRcode">
        <div style="display: flex;">
            <div style="-webkit-writing-mode: vertical-lr;writing-mode: vertical-lr;width: 3%;margin-top: 10px;font-size: 14px;" >
                <div>注意事項</div>
            </div>
            <table style="width: 95%;margin-top: 1px; border: none;font-size: 12px;line-height: 13px;">
                <tr>
                    <td>
                        <div>(一)本處方如有塗改，需由原處方醫師之蓋章確認，否則無效。 ◎◎領藥時請務必攜帶健保IC卡◎◎
                            <div style="float: right;">(&nbsp;&nbsp;&nbsp;&nbsp;{{ hist.Hist.PPartRx}})</div></div>
                        <div>(二)若同時須開給病患一般(七日內)用藥及連續處方用藥時，需分開填寫在不同的處方箋，一般處方箋自就醫日起三日內有效。</div>
                        <div>(三)本處方得選擇在健保特約藥局或在就醫之醫療機構調劑。</div>
                        <div>(四)請注意！！本釋出處方箋內如有含戒菸藥品，限由戒菸服務特約藥局或戒菸輔助用藥契約調劑藥局調劑，不具該二種資格
                            之藥局，調劑本處方有關之任何費用，國民健康署均不予補助。</div>
                    </td>
                </tr>
            </table>
        </div>
        <!-- <div *ngIf="ic?.Itreatnum && this.isPrintItreatnum" style="display: flex;">
          <table style="width: 40%; margin-top: 10px; margin-left: 20px; border: none; font-size: 12px;">
            <tr>
              <td>
                <div>原處方就醫識別碼：{{ic?.Itreatnum}}</div>
                <div>原處方就醫時間：{{ic?.IRegDT}}</div>
              </td>
            </tr>
          </table>
          <ngx-barcode6 style="padding-top: 20px;" [bc-format]="'CODE39'" [bc-width]="0.9" [bc-height]="30" [bc-value]="ic?.Itreatnum"></ngx-barcode6>
          <div class="con">
            <img style="width:80px;height: auto;padding-top: 0px;" #qrcodeItreatnumImg>
          </div>
        </div> -->
    </div>
    <!-- <div *ngIf="clinic&&hist" class="pres-report" style="background-color: white;height: 100%;width: 100%;">

    </div> -->
</div>
<div class="a6-print-style " #reportContainerA6  [hidden]="!isA6"> <!-- a4-print-style a5-rotate-print-style -->
    <div *ngIf="clinic&&hist" style="background-color: white;height: 100%;width: 100%;"       [ngStyle]="{height: a6SizePage.height + 'cm',
    width: a6SizePage.width + 'cm',
    paddingTop: a6PaddingPage.top + 'cm',
    paddingRight: a6PaddingPage.right + 'cm',
    paddingBottom: a6PaddingPage.bottom + 'cm',
    paddingLeft: a6PaddingPage.left + 'cm'}">
        <div  style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;" [hidden]="!isopenQRcode">
            <div style="display: flex;column-gap: 5px; border:1px solid black; border-collapse: collapse;height:250px" >
                <table style="width: 43%;" >
                    <tr>
                        <td>
                        <div>處方籤QR-Code</div>
                        <div>就醫日期：{{regdateString}}</div>
                        <div>就醫科別：{{medcodeName}}</div>
                        <div>姓名：{{hist?.Patient.CName}}</div>
                            <div>院所：{{clinic?.Name}}</div>
                        </td>
                    </tr>
                </table>
                <table style="width: 57%;" >
                    <tr>
                        <div class="con" >
                            <img style="width:240px;height: auto;padding-top: 2.5px;" #qrcodeImg>
                        </div>
                    </tr>
                    <tr>
                        <div class="con">
                            <img style="width:240px;height: auto;padding-top: 2.5px;" #qrcode2Img>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
        <div *ngIf="!isopenQRcode" style="text-align: center;font-weight: bolder;font-size: 11pt">全 民 健 康 保 險 門 診 交付調劑/慢性病連續 處 方 箋</div>
        <div *ngIf="!isopenQRcode && ic?.Itreatnum && this.isPrintItreatnum" style="display: block;">
            <table style="width: 100%; margin-top: 0px; border: none;">
              <tr>
                <td>
                  <table style="width: 100%; margin-top: 0px; border: none; font-size: 8pt;">
                    <tr>
                      <td>
                        <div>原處方就醫識別碼：{{ic?.Itreatnum}}</div>
                        <div>原處方就醫時間：{{ic?.IRegDT}}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ngx-barcode6 [bc-format]="'CODE39'" [bc-width]="0.7" [bc-height]="25" [bc-value]="ic?.Itreatnum"></ngx-barcode6>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <div class="con">
                    <img style="width:80px;height: auto;padding-top: 0px;" #qrcodeItreatnum2Img>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        <div *ngIf="!isopenQRcode" style="-webkit-text-size-adjust: none;zoom: 0.8;margin: 0px 0px;" class="pres-report">
            <div style="display: flex;column-gap: 5px;">
                <table style="width: 60%">
                    <tr>
                        <td style="width: 52%;font-size: 9pt;">
                            <div>特約醫事服務機構</div>
                            <div>代號及名稱</div>

                        </td>
                        <td style="width: 48%;font-size: 9pt;">
                            <div>{{clinic?.NHICode}}</div>
                            <div>{{clinic?.Name}}</div>
                        </td>
                    </tr>
                </table>
                <table style="width: 40%;font-size: 9pt;">
                    <tr>
                        <td style="width: 70%;">一 般 處 方 箋</td>
                        <td>
                            <div style="display: inline-block;">{{normalCheck}}</div>
                            <!-- <div style="width:90%;display: inline-block;">{{backRegDate}}</div> -->
                        </td>
                    </tr>
                    <tr>
                        <td>連 續 處 方 箋</td>
                        <td>
                            <div style="display: inline-block;">{{cntPresCheck}}</div>
                        </td>
                    </tr>
                </table>
            </div>
            <table style="margin-top: 5px;width: 100%;border-bottom: 0;font-size: 9pt;">
                <tr>
                    <td style="width:30%">姓名：{{hist?.Patient.CName}}</td>
                    <td style="width: 35%">身分證字號：{{hist?.Patient.CId}}</td>
                    <td style="width:35%">出生日期：{{hist?.Patient.Birthday| formatROCDate:true:bitrhMask}} </td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;font-size: 9pt;">
                <tr>
                    <td style="width:21%">就醫科別：{{hist?.Register.MedDeptCode}}</td>
                    <td style="width:35%">就醫日： {{hist?.Register.RegDate | formatROCDate:true}}</td>
                    <td style="width:24%">卡號：{{hist?.Register.ICode=='000' ? '自費' : hist?.Register.Icno}}</td>
                    <td style="width: 20%">日份：{{hist?.Hist.Days}}</td>
                </tr>
            </table>
            <table style="border-top: 0;width: 100%;font-size: 9.5pt;">
                <tr>
                    <td colspan="2" style="border-right: 0;">
                        傷病名稱及主要症候：{{dxNames}}
                    </td>
                    <td  colspan="2" style="border-left: 0;">
                        疾病碼：{{dxCodes}}
                    </td>
                </tr>
                <tr>
                    <td colspan="6">免部分負擔代碼及原因：{{hist?.Register.ICode=='000' ? '' : (hist?.Hist.ApartCode + ' ' + hist?.Hist.ApartCodeDescription)}}
                    </td>
                </tr>
                <tr>
                    <td style="width:46%;">
                        藥品名稱及規格
                    </td>
                    <td style="width:30%;">
                        用法及用量
                    </td>
                    <td style="width:14%;">
                        總數量
                    </td>
                    <td style="width:10%">
                        備註
                    </td>
                </tr>
                <tr *ngFor="let hrx of hist.Hist.HRxs" style="line-height: 15px;">
                    <td style="width:46%;border: 0;border-right: 1px solid black;">
                        {{hrx.StdCode}}  {{stringFactory(hrx.ProdName,14)}}
                    </td>
                    <td style="width:30%; border: 0;border-right: 1px solid black;">

                    <!-- </td>
                    <td style="width:10%;border: 0;"> -->
                        {{hrx.Dose| NumberFixed:2 }}/{{hrx.Unit}}
                    <!-- </td>
                    <td style="width:5%;border: 0;"> -->
                        <!-- <div style="width:55px;display: inline-block;padding-top: 2.5px;padding-bottom: 2.5px;">{{hrx.Way?.length > 0 ? hrx.Way.toUpperCase():hrx.Way}}</div> -->
                        {{hrx.Way?.length > 0 ? hrx.Way.toUpperCase():hrx.Way}}
                    <!-- </td>
                    <td style="width:6%;border: 0;"> -->
                        {{hrx.Freq}}
                        <!-- <div style="width:45px;display: inline-block;padding-top: 2.5px;padding-bottom: 2.5px;">{{hrx.Freq}}</div> -->
                    </td>
                    <td style="width:14%;border: 0;border-left: 1px solid black;margin:0;">
                        {{hrx.TotalDose}}
                    </td>
                    <td style="width:10%;border: 0;border-left: 1px solid black;">
                        {{hrx.Remark}}
                    </td>
                </tr>
            </table>
            <table style="width: 100%;margin-top: 0px;font-size: 9.5pt;">
                <tr>
                    <td style="width: 30%;border-bottom: 0;" >
                        診治醫生代號：<br>{{clinic?.NHICode}}
                    </td>
                    <td style="width: 22%;">
                        處方醫院診所<br> 核章
                    </td>
                    <td style="width: 23%;">
                        一般處方箋專用
                    </td>
                    <td colspan="4" style="width: 25%;">
                        慢性病連續處方箋 <br>專用　可調劑{{hist?.Hist.CntPresTot}}次
                    </td>
                </tr>
                <tr>
                    <td rowspan="1" style="border-top: 0;">
                        <br>
                        簽章：{{hist?.Register.DoctorName}}
                    </td>
                    <td style="width: 22%;border-bottom: 0;">

                    </td>
                    <td style="width: 23%;">
                        調劑藥師(生)代號：
                    </td>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        1
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[0]}}<br>  {{EndStr[0]}}
                    </td>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        3
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[2]}}<br>  {{EndStr[2]}}
                    </td>
                    <!-- <td rowspan="2" style="width: 12.5%;">
                        <div style="width:10%;display: inline-block;justify-content: center;align-items: center;">1</div>
                        <div style="width:90%;display: inline-block">{{StartStr[0]}}<br>  {{EndStr[0]}}</div>
                    </td>
                    <td rowspan="2" style="width: 12.5%;">
                        <div style="width:10%;display: inline-block;"><p style="justify-content: center;align-items: center">3</p></div>
                        <div style="width:90%;display: inline-block"><p> {{StartStr[2]}} <br> {{EndStr[2]}}</p></div>
                    </td> -->
                </tr>
                <tr>
                    <td rowspan="2" >
                        聯絡電話：{{clinic?.Phone}}

                    </td>
                    <td rowspan="3" style="border-top: 0;">

                    </td>
                    <td rowspan="3" style="border-right:  1px solid black;">
                        簽章：<br>
                        調劑日期：<br>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        2
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[1]}}<br>  {{EndStr[1]}}
                    </td>
                    <td rowspan="2" style="width: 2%;border-right: 0;">
                        4
                    </td>
                    <td rowspan="2" style="width: 10.5%;border-left: 0;">
                        {{StartStr[3]}}<br>  {{EndStr[3]}}
                    </td>
                    <!-- <td rowspan="2">
                        <div style="width:10%;display: inline-block;"><p style="justify-content: center;align-items: center">2</p></div>
                        <div style="width:90%;display: inline-block"><p> {{StartStr[1]}} <br> {{EndStr[1]}}</p></div>
                    </td>
                    <td rowspan="2">
                        <div style="width:10%;display: inline-block;justify-content: center;align-items: center;">4</div>
                        <div style="width:90%;display: inline-block">{{StartStr[3]}} <br> {{EndStr[3]}}</div>
                    </td> -->
                </tr>
                <tr>
                    <td style="border-right: 1px solid;">傳真：{{clinic?.Fax}}</td>
                </tr>
            </table>
        </div>
        <div *ngIf="!isopenQRcode">
        <div style="display: flex;">

            <table style="width: 100%;margin-top: 1px; border: none;font-size: 9px;line-height: 10px;">
                <tr>
                    <td>
                        <div>注意事項：(一)本處方如有塗改，需由原處方醫師之蓋章確認，否則無效． ◎◎領藥時請務必攜帶健保IC卡◎◎
                            <div style="float: right;">(&nbsp;&nbsp;&nbsp;&nbsp;{{ hist.Hist.PPartRx}})</div></div>
                        <div>(二)若同時須開給病患一般(七日內)用藥及連續處方用藥時，需分開填寫在不同的處方箋，一般處方箋自就醫日起三日內有效．</div>
                        <div>(三)本處方得選擇在健保特約藥局或在就醫之醫療機構調劑．</div>
                        <div>(四)請注意！！本釋出處方箋內如有含戒菸藥品，限由戒菸服務特約藥局或戒菸輔助用藥契約調劑藥局調劑，不具該二種資格
                            之藥局，調劑本處方有關之任何費用，國民健康署均不予補助。</div>
                    </td>
                </tr>
            </table>
        </div>

    </div>
    <!-- <div *ngIf="clinic&&hist" class="pres-report" style="background-color: white;height: 100%;width: 100%;">

    </div> -->
</div>
