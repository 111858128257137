import { empty } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RegisterCardAreaState, RegisterHistAnimationStyle } from 'src/app/models/RegisterHistAnimationModel';
import { RegisterResizeService } from 'src/app/services/app-service/register/register-resize.service';
import { FormHelper } from 'src/app/services/formhelper';
import { CriticalIllnesNoHPC } from 'src/app/shared/hcr-lib/CriticalIllnesNoHPC';
import { Cumulative } from 'src/app/shared/hcr-lib/Cumulative';
import { InoculateData } from 'src/app/shared/hcr-lib/InoculateData';
import { PrescriptionDetailHVE } from 'src/app/shared/hcr-lib/PrescriptionDetailHVE';
import { PrescriptionDetailLongTerm } from 'src/app/shared/hcr-lib/PrescriptionDetailLongTerm';
import { PrescriptionDetailOutPatient } from 'src/app/shared/hcr-lib/PrescriptionDetailOutPatient';
import { RegisterPregnantSubset } from 'src/app/shared/hcr-lib/RegisterPregnantSubset';
import { RegisterPreventSubset } from 'src/app/shared/hcr-lib/RegisterPreventSubset';

@Component({
  selector: 'app-register-hist-info',
  templateUrl: './register-hist-info.component.html',
  styleUrls: ['./register-hist-info.component.css'],
  animations: RegisterHistAnimationStyle
})
export class RegisterHistInfoComponent implements OnInit {

  @Input() patientId: number;

  @Input() maxRegMenuOpen: boolean = false;
  @Output() maxRegMenuOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  infoTabState: string[] = [];

  criIllNoHPC: CriticalIllnesNoHPC[] = [];
  treatmentHPC: TreatmentHPC[] = [];
  prescriptionDetailHVE: PrescriptionDetailHVE[] = [];
  prescriptionDetailOutPatient: PrescriptionDetailOutPatient[] = [];
  prescriptionDetailLongTerm: PrescriptionDetailLongTerm[] = [];
  treatmentNoHPC: Treatment[] = [];
  cumulatives: Cumulative[] = [];
  registerPreventSubset: RegisterPreventSubset[] = [];
  registerPregnantSubset: RegisterPregnantSubset[] = [];
  inoculateData: InoculateData[] = [];

  pagedHVE = {
    data: [],
    total: 0
  };

  pagedOutPatient = {
    data: [],
    total: 0
  };

  pagedLongTerm = {
    data: [],
    total: 0
  };
  pagedRegisterPregnant = {
    data: [],
    total: 0
  };
  pagedInoculateData = {
    data: [],
    total: 0
  };

  allergicDrug1 = '';
  allergicDrug2 = '';
  allergicDrug3 = '';
  allergicDrug4 = '';
  allergicDrug5 = '';

  OrganDonate = '';


  menuData = [
    { label: '重大傷病' },
    { label: '主次診斷' },
    { label: '重要醫令' },
    { label: '過敏藥品' },
    { label: '門診醫令' },
    { label: '長期醫令' },
    { label: '器官捐贈' },
    { label: '就醫資料' },
    { label: '就醫費用' },
    { label: '預防保健' },
    { label: '產前檢查' },
    { label: '預防接種' }
  ];

  constructor(public registerResizeService: RegisterResizeService) { }

  ngOnInit(): void {
    this.getTabInfo(0);
  }

  handleTabInfoToggle() {
    this.maxRegMenuOpen = !this.maxRegMenuOpen;
    this.maxRegMenuOpenChange.emit(this.maxRegMenuOpen);
  }

  getTabInfo(index: number) {
    this.infoTabState = Array(12).fill(RegisterCardAreaState.slide_out);
    this.infoTabState[index] = RegisterCardAreaState.slide_in;
  }

  /** 重大分頁 */
  HVEPageChanged(page) {
    this.pagedHVE = {
      data: this.prescriptionDetailHVE.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.prescriptionDetailHVE.length
    }
  }

  /** 門診醫令分頁 */
  outpatientPageChanged(page) {
    this.pagedOutPatient = {
      data: this.prescriptionDetailOutPatient.filter((x, i) => i >= (page - 1) * 10 && i < (page) * 10),
      total: this.prescriptionDetailOutPatient.length
    }
  }

  /** 長期分頁 */
  longTermPageChanged(page) {
    this.pagedLongTerm = {
      data: this.prescriptionDetailLongTerm.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.prescriptionDetailLongTerm.length
    }
  }

  /** 產檢分頁 */
  registerPregnantPageChanged(page) {
    this.pagedRegisterPregnant = {
      data: this.registerPregnantSubset.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.registerPregnantSubset.length
    }
  }

  /** 疫苗分頁 */
  inoculateDataPageChanged(page) {
    this.pagedInoculateData = {
      data: this.inoculateData.filter((x, i) => i >= (page - 1) * 5 && i < (page) * 5),
      total: this.inoculateData.length
    }
  }
}


declare type TreatmentHPC = {
  Date: string,
  Dx1: string,
  Dx2: string,
  Dx3: string,
  Dx4: string,
  Dx5: string,
  Dx6: string
}

declare type Treatment = {
  Date: string,
  Isqno: string,
  Clinic: string,
  Type: string,
  NewBorn: string,
  Recard: string,
  // 門診部分負擔
  Deductible: string,
  // 門診醫療費用
  MedicalFees: string,
  // 住院醫療費用
  HospitalFees: string,
  // 住院部分負擔(短)
  HdeductibleA: string,
  // 住院部分負擔(長)
  HdeductibleB: string
}
