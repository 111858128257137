import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { HistViewComponent } from 'src/app/shared/components/hist/hist-view/hist-view.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { IdHelper } from 'src/app/shared/helpers/guid-helper';
import { PageData, PageData2 } from 'src/app/shared/models/page-data';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { PatientHist } from '../models/patient-hist';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { LocalStoragesService } from 'src/app/services/local-storages.service';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { SessionService } from 'src/app/services/session.service';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { HistoryCertificateComponent } from 'src/app/system/report/components/hist-report/history-certificate/history-certificate.component';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { SignalRService } from 'src/app/services/signalr-service';
import { UserCache } from 'src/app/services/user-cache';
import { DeclareApi } from 'src/app/services/api-service/declare/declare-api';
import { MemoryCache } from 'src/app/services/memory-cache-service';
import { VisGirdSelection, VisGridColumnSort } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';
import { PatientHistDto, PatientHisApi, DeclareStatisticsDto } from 'src/app/services/api-service/patient/patientHis-api';
import { AddMonth, isNullOrUndefined } from 'src/app/shared/utilities';
import { RegisterService } from 'src/app/services/register-service';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { HistService } from 'src/app/hist/services/hist.service';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { DxOpt } from 'src/app/services/api-service/hist/hist-edit-option';
import { UserApi } from 'src/app/services/api-service/user/user-api';
import { RegisterApi } from 'src/app/services/api-service/register/register-api';
import { RegisterConst } from 'src/app/shared/constants/register-const';
import { HcrService } from 'src/app/services/hcr-service.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { BaseConfig } from 'src/app/services/base-config.service';
import { DispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';
import { HistoryListComponent } from 'src/app/system/report/components/hist-report/history-list/history-list.component';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { MedicationWayAndReceiptReportComponent } from 'src/app/shared/components/report/medication-way-and-receipt-report/medication-way-and-receipt-report.component';
import { RegisterViewItem } from 'src/app/shared/view-model/register/RegisterViewItem';
import { ClinicTypeEnum } from 'src/app/enums/ClinicTypeEnum';

// 模式:
// list: List只有 病歷號、姓名、編輯/修改按鈕
// search: List有 病歷號、姓名、生日...，但沒有 編輯/修改按鈕
enum PatientListMode {
  list = 'list',
  search = 'search'
}
export enum AccessCheckLevel {
  User = 0,
  Clinic = 1,
  Company = 2
}

/** 從哪個路由Route進入 */
enum FromRouteMode {
  /** 從掛號進入 */
  reg = 'reg',
  /** 從門診進入 */
  hist = 'hist'
}

declare type QueryObj = {
  StartDate: Date | string;
  EndDate: Date | string;
  DispTp: string;
  ICodeTp: string;
  Dept: string;
  // 其他條件/輸出類別/排序方式 暫略
  CaseTp: string;
  CaseExlude: boolean;
  Doctor: string;
  Pharmcist: string;
  RegDateStart: Date;
  RegDateEnd: Date;
  RegTimeStart: string;
  RegTimeEnd: string;

  AmountMin: number;
  AmountMax: number;
  DrugFeeMin: number;
  DrugFeeMax: number;
  DaysMin: number;
  DaysMax: number;
  SelfPay: number;
  DrugPay: number;
  Rxs: string;
  StartRxs: string;
  EndRxs: string;
  SRx: string;
  StartSRx: string;
  EndSRx: string;
  ExcludeRx: string;
  CC: string;

  PatientNo: string,
  Name: string,
  Birthday: Date | string,
  CId: string,
  Phone: string,
  Dx: string;
  DxExclude: boolean;
  Spec: string;
  Icno: string,
  Remark: string;

  IsSameTreat: boolean;
  Is慢箋: boolean;
  Is預保: boolean;
  IsClearDate: boolean;
  OrderBy: string;
  OrderDesc: boolean;
}
@Component({
  selector: 'app-system-patient-hist-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class PatientHistListComponent implements OnInit {
  @ViewChild('medicationWayAndReceipt') medicationWayAndReceiptComponent: MedicationWayAndReceiptReportComponent;

  constructor(private fb: FormBuilder,
    private webApiClient: WebApiClient,
    private cdr: ChangeDetectorRef,
    private patientHisApi: PatientHisApi,
    private hcrService: HcrService,
    private notificationDI: EasyNotificationService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private localStorage: LocalStoragesService,
    private localPrintService: LocalPrintService,
    private routeInfo: RouteInfoService,
    private userConfirmService: UserConfirmService,
    private formHelper: FormHelperService,
    private signalR: SignalRService,
    private declareApi: DeclareApi,
    private cache: MemoryCache,
    private registerService: RegisterService,
    private histService: HistService,
    private sessionService: SessionService,
    private regApi: RegisterApi,
    private histApi: HistApi,
    private userApi: UserApi,
    private parameterApi: ParameterApi,
    private mianLayoutService: MainLayoutService,
    private clinicDataService: ClinicDataService) {
    this.subscribeEvt.push(this.hcrService.onDemoMode.subscribe(b => {
      this.isDemoMode = b;
    }));
    //this.api = this.webApiClient.createHisService('patients/PatientHist');
  }
  subscribeEvt: Subscription[] = [];
  pageSkipNo = 0;
  pageSize: number = 15;//BaseConfig.getConfig().ui.pageSize;
  statisticsPageSkipNo = 0;
  statisticsPageSize: number = 15;//BaseConfig.getConfig().ui.pageSize;
  //api: WebApiService;
  @ViewChild('histViewComp') private histViewComp: HistViewComponent;
  @ViewChild('histCertRptComp') private histCertRptComp: HistoryCertificateComponent;
  @ViewChild(HistoryListComponent)
  historyList: HistoryListComponent;
  isOpenPrintDialog = false;
  isDemoMode = false;
  isShowQRcodeTest = BaseConfig.isDebug(); //若是正式台，則預設不顯示QRcode列印
  regItem: RegisterViewItem;
  // keepQueryData?: string = null;
  cacheKey_queryConditions = 'patientHist_queryCond';
  initRegId = ''; // 初始化時需要有 regId
  selectedRegId: number = null;
  PersonCId = '';
  PersonName = '';
  // 補登相關參數
  PatientNo = '';
  routeOpdRecord = '/hist/record';
  routeDentalOpdRecord = '/hist/record-dental';

  dateSearch = ''; //生日日期查詢方式 年、月
  dateSearchReg = ''; //看診日期查詢方式 年、月
  /** 開啟就醫證明Dialog視窗 Flag*/
  isOpenHistCert = false;
  histCertData = null;
  /** 從哪個路由Route進入 */
  fromMode: FromRouteMode;
  /** 列印Dialog視窗Max高度*/
  printMaxHeight = 600;
  /** 列印Dialog視窗Max高度*/
  printHeight = 600;

  gridView: any;
  statisticsGridView: any;
  listItems: PatientHistDto[];
  selectedItems: number[] = [];
  keepSelectedItems: number[] = [];
  private unsubscribe: Subject<void> = new Subject();
  pageData: PageData2<PatientHistDto>;
  statisticsPageData: PageData2<DeclareStatisticsDto>;
  queryFormGroup: FormGroup;
  queryFormHelper: FormHelper<QueryObj>;

  disp: ValueTextPair[] = [];
  icodes: ValueTextPair[] = [];
  dept: ValueTextPair[] = [];
  case: ValueTextPair[] = [];
  doctor: ValueTextPairNumberValue[] = [];
  pharm: ValueTextPair[] = [];
  dx: DxOpt[] = [];
  spcT: ValueTextPair[] = [];
  get patientListMode() {
    return PatientListMode;
  }

  // 網頁列印要產生的div頁面
  sizePage = {
    width: 21, //cm
    height: 30.5 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0, //cm
    left: 0 //cm
  };

  medicationWayRegId?: number;

  async ngOnInit() {
    this.isDemoMode = this.hcrService.isDemoMode;
    this.fromMode = this.aRoute.snapshot.data.from;
    this.createQueryFormGroup();
    // 用路徑來判斷是哪一種模式
    if (this.fromMode == FromRouteMode.hist) { // 來自門診登錄的病歷查詢
      // 取得查詢條件
      var keepQuery = this.cache.get(this.cacheKey_queryConditions + '_' + UserCache.getLoginUser().CId);
      var d = keepQuery ? keepQuery.split(';') : null;
      if (d) {
        this.pageSkipNo = Number(d[9]);
        this.keepSelectedItems = [d[10]];
        this.selectedItems.push(this.keepSelectedItems[0]);
        this.queryFormGroup.patchValue({
          PatientNo: d[0],
          Name: d[1],
          CId: d[2],
          Birthday: d[3] ? new Date(d[3]) : '',
          StartDate: d[4] ? new Date(d[4]) : '',
          EndDate: d[5] ? new Date(d[5]) : '',
          ICodeTp: d[6],
          DispTp: d[7],
          Dept: d[8],
          Rxs: d[11],
          StartRxs: d[12],
          EndRxs: d[13],
          SRx: d[14],
          StartSRx: d[15],
          EndSRx: d[16],
          IsClearDate: d[17] == 'true',

          SearchType: '',
          SearchTypeReg: '',
        });
        this.refreshList(false);
      }
    }
    this.cache.set(this.cacheKey_queryConditions + '_' + UserCache.getLoginUser().CId, null);
    //await this.refreshList(false);
    var codes = await this.clinicDataService.getSystemCodes(['C0010', 'C0020', 'C0050', 'C0070'])

    // await this.registerService.Init()
    // var q = await this.registerService.GetQueryOptions();
    // var e = await this.registerService.getEditOptions();
    var disp = this.histService.EditOptions.dispensing.filter(x => x.text != '無');
    this.disp =  [{ value: '', text: '' }].concat(disp);
    this.icodes = [{ value: '', text: '' }].concat(codes['C0010'])//.map(x=>{ return {value:x.value,text:`${x.value} | ${x.text}`}}));
    this.dept = [{ value: '', text: '' }].concat(codes['C0020']);
    this.case = [{ value: '', text: '' }].concat(codes['C0050']);
    this.dx = await this.clinicDataService.GetDxOption()
    this.spcT = [{ value: '', text: '' }].concat(await this.clinicDataService.GetSPCTOption());
    this.doctor = [{ value: null, text: '' }].concat((await this.userApi.GetDoctors()));
    this.pharm = [{ value: '', text: '' }].concat((await this.userApi.GetPharmarcist()));
  }

  createQueryFormGroup() {
    this.queryFormGroup = this.makeQueryFormGroup();
  }

  async onBtnQueryClearClick() {
    this.pageSkipNo = 0;
    var v = this.queryFormHelper.Value;
    this.queryFormHelper.clear()
    this.queryFormHelper.patchValue({
      //清除後改成預設日期並加上IsClearDate
      // StartDate: v.StartDate,
      // EndDate: v.EndDate,
      StartDate: AddMonth(new Date(), -1),
      EndDate: new Date(),
      IsClearDate: true,
    });
    if (this.isOpenAdvSearch == false) {
      this.refreshList(true);
    }
  }

  isOpenAdvSearch = false;
  onBtnAdvQueryClick() {
    // 這行拿來更新form control的值，不然會兩邊查詢內容不同步
    this.queryFormGroup.patchValue(this.queryFormHelper.Value);
    this.isOpenAdvSearch = true;
  }

  //匯出清單
  isExportHistoryList = true;
  onBtnExportHistoryListAsExcel() {
    // 這行拿來更新form control的值，不然會兩邊查詢內容不同步
    this.queryFormGroup.patchValue(this.queryFormHelper.Value);
    // this.isExportHistoryList = true;
    this.historyListRefreshList(true);
    // this.isExportHistoryList = false;
  }

  //申報資料核對統計
  isOpenDeclareStatistics = false;
  isOpenStatisticsRemark = false;
  onBtnOpenDeclareStatisticsClick() {
    // 這行拿來更新form control的值，不然會兩邊查詢內容不同步
    this.queryFormGroup.patchValue(this.queryFormHelper.Value);
    this.statisticsRefreshList(true);
    this.isOpenDeclareStatistics = true;
  }

  onBtnQueryClick() {
    this.isOpenAdvSearch = false
    this.pageSkipNo = 0;
    // 這行拿來更新form control的值，不然會兩邊查詢內容不同步
    this.queryFormGroup.patchValue(this.queryFormHelper.Value);
    this.refreshList(true);
  }

  makeQueryFormGroup() {
    this.queryFormHelper = this.formHelper.Create<QueryObj>().build({
      StartDate: [AddMonth(new Date(), -1), []],
      EndDate: [new Date(), []],
      RegDateStart: [null, []],
      RegDateEnd: [null, []],
      DispTp: ['', []],
      ICodeTp: ['', []],
      Dept: ['', []],
      CaseTp: ['', []],
      CaseExlude: [false, []],
      Doctor: ['', []],
      Pharmcist: ['', []],
      RegTimeStart: ['', []],
      RegTimeEnd: ['', []],
      AmountMin: [null, []],
      AmountMax: [null, []],
      DrugFeeMin: [null, []],
      DrugFeeMax: [null, []],
      DaysMin: [null, []],
      DaysMax: [null, []],
      SelfPay: [null, []],
      DrugPay: [null, []],
      Rxs: ['', []],
      StartRxs: ['', []],
      EndRxs: ['', []],
      SRx: ['', []],
      StartSRx: ['', []],
      EndSRx: ['', []],
      ExcludeRx: ['', []],
      CC: ['', []],
      PatientNo: ['', []],
      Name: ['', []],
      CId: ['', []],
      Phone: ['', []],
      Dx: ['', []],
      DxExclude: [false, []],
      Spec: ['', []],
      Icno: ['', []],
      Remark: ['', []],
      Birthday: [null, []],

      IsSameTreat: [false, []],
      Is慢箋: [false, []],
      Is預保: [false, []],
      IsClearDate: [true, []],
      OrderBy: ['', []],
      OrderDesc: [false, []]
    })
    return this.queryFormHelper.FormGroup;
  }

  public selectChange(selection: VisGirdSelection): void {
    if (selection.selectedRows.length > 0) {
      var dataItem = selection.selectedRows[0].dataItem
      this.selectedRegId = dataItem.Id;
      this.histViewComp.selectHist(dataItem.Id);  // it's a register Id
      this.histViewComp.ClinicName = dataItem.ClinicName;
    }
  }

  pageChange(page: any): void {
    this.pageSkipNo = (page - 1) * this.pageSize;
    this.refreshList(); // 更新清單，因為server端資料可能異動
  }

  statisticsPageChange(page: any): void {
    this.statisticsPageSkipNo = (page - 1) * this.statisticsPageSize;
    this.statisticsRefreshList(); // 更新清單，因為server端資料可能異動
  }

  handleListData(data: PatientHist[]) {
    return data;
  }

  receiveDateSearch(value: string) {
    this.dateSearch = value;
  }

  receiveDateSearchReg(value: string) {
    this.dateSearchReg = value;
  }

  // 更新清單
  // isFirstEnter: 是否為第一次進入
  async refreshList(isNewQuery?: boolean) {
    this.cdr.detectChanges();
    if (isNewQuery) {
      this.pageSkipNo = 0;
      this.selectedItems = [];
    }

    //輸入病歷號/身分證號/姓名/生日時，查詢會清除日期
    if (this.queryFormHelper.Value.IsClearDate &&
      (this.queryFormHelper.Value.PatientNo || this.queryFormHelper.Value.CId || this.queryFormHelper.Value.Name || this.queryFormHelper.Value.Birthday)) {
        this.queryFormHelper.patchValue({
          StartDate: null,
          EndDate: null
        });
    }

    const query = this.queryFormHelper.Value;
    query['pageNumber'] = 1 + Math.floor(this.pageSkipNo / this.pageSize);
    query['pageSize'] = this.pageSize;
    query.PatientNo = query.PatientNo ? query.PatientNo.trim() : '';
    query.CId = query.CId ? query.CId.trim() : '';
    query.Name = query.Name ? query.Name.trim() : '';
    query.Phone = query.Phone ? query.Phone.trim() : '';
    query.Remark = query.Remark ? query.Remark.trim() : '';
    query.Icno = query.Icno ? query.Icno.trim() : '';
    query.CC= query.CC ? query.CC.trim() : '';
    query.Rxs= query.Rxs ? query.Rxs.trim() : '';
    query.StartRxs= query.StartRxs ? query.StartRxs.trim() : '';
    query.EndRxs= query.EndRxs ? query.EndRxs.trim() : '';
    query.SRx= query.SRx ? query.SRx.trim() : '';
    query.StartSRx= query.StartSRx ? query.StartSRx.trim() : '';
    query.EndSRx= query.EndSRx ? query.EndSRx.trim() : '';
    query.StartDate = query.StartDate ? DateHelper.getIsoStringNoTimeZone(new Date(query.StartDate)) : '';
    query.EndDate = query.EndDate ? DateHelper.getIsoStringNoTimeZone(new Date(query.EndDate)) : '';
    query.Birthday = query.Birthday ? DateHelper.getIsoStringNoTimeZone(new Date(query.Birthday)) : '';

    // else {
    // 取得參數資料
    try {
      var result = await this.patientHisApi.GetAll2(query);
      this.pageData = result;
      //var result: PageData = await this.api.get('GetAll', query).toPromise();
      const data: PatientHistDto[] = this.pageData.data;
      this.listItems = data;
      // 表格分頁
      this.setGridView();
      if (this.listItems.length > 0) {
        if (!this.selectedItems[0]) {
          //setTimeout(() => {
          const firstRowId = this.listItems[0].Id;
          this.selectedRegId = this.listItems[0].Id;
          this.selectedItems = [firstRowId];
          this.histViewComp.selectHist(firstRowId);
          this.histViewComp.ClinicName = this.listItems[0].ClinicName;
          //}, 1);
        } else {
          this.selectedRegId = this.selectedItems[0];
          this.histViewComp.selectHist(this.selectedItems[0]);
          this.histViewComp.ClinicName = this.listItems[0].ClinicName;
        }
      } else {
        //setTimeout(() => {
        this.selectedRegId = null;
        this.selectedItems = [];
        this.histViewComp.selectHist(0);
        //}, 1);
      }
    } catch (e) {
      this.notificationDI.showGetDataFailed('patient-hist-list:refreshList') // 失敗訊息
    }
    //}
  }

  setGridView() {
    this.gridView = {
      data: this.pageData.data,
      total: this.pageData.total
    };
  }

  setStatisticsGridView() {
    this.statisticsGridView = {
      data: this.statisticsPageData.data,
      total: this.statisticsPageData.total
    };
  }

  // 選入病歷
  async onBtnOrderClick(data: PatientHistDto) {
    // console.log('data', data);
    if (data && data.Id) {
      var used = await this.signalR.CheckAccessAvailable('record_' + data.Id, AccessCheckLevel.Clinic);
      if (used) {
        await this.userConfirmService.showAlert('警告', `此病患正由${used}看診中！`);
        return;
      }

      if (data.Icode == '001' && data.BeginTime != null) {
        await this.userConfirmService.showAlert('請確認以下訊息', '本病患是以【重大傷病】身分掛入\n請注意: 病名務必以此病患之【重大傷病卡記載】的病名相符才可申報，以免被核扣！');
      }

      // 記下查詢條件
      if (this.fromMode == FromRouteMode.hist) {
        var patientNo = this.queryFormHelper.Value.PatientNo ?? '';
        var name = this.queryFormHelper.Value.Name ?? '';
        var cId = this.queryFormHelper.Value.CId ?? '';
        var birthday = this.queryFormHelper.Value.Birthday ?? '';
        var startDate = this.queryFormHelper.Value.StartDate ?? '';
        var endDate = this.queryFormHelper.Value.EndDate ?? '';
        var iCodeTp = this.queryFormHelper.Value.ICodeTp ?? '';
        var dispTp = this.queryFormHelper.Value.DispTp ?? '';
        var dept = this.queryFormHelper.Value.Dept ?? '';
        var rxs=this.queryFormHelper.Value.Rxs ?? '';
        var startRxs=this.queryFormHelper.Value.StartRxs ?? '';
        var endRxs=this.queryFormHelper.Value.EndRxs ?? '';
        var sRx=this.queryFormHelper.Value.SRx ?? '';
        var startSRx=this.queryFormHelper.Value.StartSRx ?? '';
        var endSRx=this.queryFormHelper.Value.EndSRx ?? '';
        var isClearDate = this.queryFormHelper.Value.IsClearDate;

        var queryCond = patientNo + ';' + name + ';' + cId + ';' + birthday + ';' + startDate + ';' + endDate + ';' +
        iCodeTp + ';' + dispTp + ';' + dept + ';' + this.pageSkipNo + ';' + data.Id + ';' +
        rxs + ';' + startRxs + ';' + endRxs + ';' +
        sRx + ';' + startSRx + ';' + endSRx + ';' +
        isClearDate;
        this.cache.set(this.cacheKey_queryConditions + '_' + UserCache.getLoginUser().CId, queryCond);
      }

      this.goToOpdPage(data);
    }
  }

  // 選入病歷
  async goToOpdPage(data: PatientHistDto) {
    // 檢查是否已經申報過，如果是 -> 門診畫面要鎖住，只有[主訴]可以編輯存檔
    var d = await this.declareApi.CheckDeclareXmlLog(
      UserCache.getLoginClinicId(),
      DateHelper.getFormatedDateString(data.RegDate, false),
      false
    );
    var path = this.routeInfo.makeFullUrl(this.routeOpdRecord); 
    if (UserCache.getLoginUser().Clinic.Type == ClinicTypeEnum.DentistClinic) {
      path = this.routeInfo.makeFullUrl(this.routeDentalOpdRecord);
    }
    // console.log('path', path);
    var from = (this.fromMode == FromRouteMode.hist) ? 'hist' : '';
    if(!data.IsRead){
      try{
        await this.updateReadState(data.HistoryId,true)
        data.IsRead = true;
        this.notificationDI.showSuccess('變更檢閱狀態成功');
      }catch(e){
        this.notificationDI.showError(e);
      }
    }

    setTimeout(() => {
      this.router.navigate([path, { regId: data.Id, h: d ? '1' : '0', f: from }]);
    }, 0);
  }

  /**打開 就醫證明視窗Dialog */
  async onOpenHistCertDialog() {
    this.printHeight = window.innerHeight - 50;
    this.printMaxHeight = window.innerHeight - 130;
    //this.histCertData = this.histViewComp.histView;
    this.histCertData = await this.histApi.getWholeHist({ regId: this.histViewComp.histView.Id })
    this.isOpenHistCert = true;
  }

  /**列印 就醫證明報表 */
  async onBtnPrintHistCert() {
    const printContents = this.histCertRptComp.getHtml();
    var ret = await this.localPrintService.printHtml(444, '就醫證明_' + this.histCertData?.Patient?.CName, printContents);
    if (ret.Successed) {
      this.notificationDI.showSuccess('就醫證明已送出列印!', true);
    } else {
      this.notificationDI.showError('就醫證明送出列印失敗!');
    }
  }

  /** #region 補印 */
  async onBtnPrintClick() {
    if (!this.selectedRegId) {
      this.notificationDI.showInfo('請先選取要補印的病歷資料');
      return;
    }
    this.mianLayoutService.showLoader();
    var data = await this.regApi.Get(this.selectedRegId);
    this.mianLayoutService.hideLoader();

    this.regItem = new RegisterViewItem(data);

    if(this.regItem.StatusCode !==  RegisterConst.Code_Status_Finish){
      this.notificationDI.showWarningById('MSG_RegistersWaitingList3');
      this.regItem = null;
      return;
    }

    await this.openPrintDialog();
  }

  async openPrintDialog() {
    this.isOpenPrintDialog = true;

  }
  /** #endregion */

  /** 排序 */
  sort(col: VisGridColumnSort) {
    this.queryFormHelper.patchValue({
      OrderBy: col.column,
      OrderDesc: col.sortOrder == 'desc'
    });
    this.refreshList();
  }

  //讀取病歷統計
  async statisticsRefreshList(isNewQuery?: boolean) {
    this.cdr.detectChanges();
    if (isNewQuery) {
      this.statisticsPageSkipNo = 0;
      // this.selectedItems = [];
    }
    const query = this.queryFormHelper.Value;
    query['PageNumber'] = 1 + Math.floor(this.statisticsPageSkipNo / this.statisticsPageSize);
    query['PageSize'] = this.statisticsPageSize;

    // else {
    // 取得參數資料
    try {
      var result = await this.patientHisApi.GetDeclareStatistics(query);
      this.statisticsPageData = result;
      this.setStatisticsGridView();
    } catch (e) {
      this.notificationDI.showGetDataFailed('patient-hist-list:refreshDeclareStatistics') // 失敗訊息
    }
  }

  //讀取病歷匯出excel
  async historyListRefreshList(isNewQuery?: boolean) {
    this.cdr.detectChanges();
    if (isNewQuery) {
      //this.statisticsPageSkipNo = 0;
    }
    const query = this.queryFormHelper.Value;
    // query['PageNumber'] = 1 + Math.floor(this.statisticsPageSkipNo / this.statisticsPageSize);
    // query['PageSize'] = this.statisticsPageSize;

    try {
      var result = await this.patientHisApi.GetHistoryList(query);
      this.historyList.fetchData(result, true);
      this.historyList.exportAsExcel();
    } catch (e) {
      this.notificationDI.showGetDataFailed('patient-hist-list:historyListRefreshList') // 失敗訊息
    }
  }
  async readChanged(evt:MatCheckboxChange,data:PatientHistDto){
    try{
      await this.updateReadState(data.HistoryId,evt.checked)
      this.notificationDI.showSuccess('變更檢閱狀態成功')
    }catch(e){
      setTimeout(() => {
        data.IsRead = !evt.checked;
      }, 0);
      this.notificationDI.showError(e);
    }
  }
  async updateReadState(histId:number,readState:boolean){
    if(BaseConfig.isDebug()){
      throw '狀態變更失敗：您為超使用者或位於測試環境！';
    }
    await this.patientHisApi.UpdateIsRead(histId, readState)
  }
  rxsChange(event) {
    if (event.target.value) {
      this.queryFormHelper.Controls.StartRxs.setValue('');
      this.queryFormHelper.Controls.EndRxs.setValue('');
    }
  }
  betweenRxsChange(event) {
    if (event.target.value) {
      this.queryFormHelper.Controls.Rxs.setValue('');
    }
  }
  srxChange(event) {
    if (event.target.value) {
      this.queryFormHelper.Controls.StartSRx.setValue('');
      this.queryFormHelper.Controls.EndSRx.setValue('');
    }
  }
  betweenSRxChange(event) {
    if (event.target.value) {
      this.queryFormHelper.Controls.SRx.setValue('');
    }
  }

}
