
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
export const VIS_DatePicker_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VisTimepickerComponent),
  multi: true
};
@Component({
  selector: 'app-vis-timepicker',
  templateUrl: './vis-timepicker.component.html',
  styleUrls: ['./vis-timepicker.component.css'],
  providers: [VIS_DatePicker_CONTROL_VALUE_ACCESSOR]
})
export class VisTimepickerComponent implements OnInit, ControlValueAccessor  {
  //#region UI Interface
  @Input()
  showSecond: boolean = false;

  /** 時間 */
  @Input()
  value: Date;

  /** 是否停用 */
  @Input()
  set disabled(v:boolean){
    this._disabled = v;
  }
  get disabled():boolean{
    return this._disabled;
  }
  //#endregion

  @ViewChild('hour')
  hourInput: ElementRef<HTMLInputElement>;
  @ViewChild('min')
  minInput: ElementRef<HTMLInputElement>;
  @ViewChild('sec')
  secInput: ElementRef<HTMLInputElement>;
  @ViewChild('hourPicker')
  hourPicker: ElementRef<HTMLDivElement>;
  @ViewChild('minPicker')
  minPicker: ElementRef<HTMLDivElement>;
  @ViewChild('secPicker')
  secPicker: ElementRef<HTMLDivElement>;

  /** 值改變(使用者輸入或者選取) */
  @Output()
  valueChange = new EventEmitter<string>();

  /** T按鈕按下去 */
  // @Output()
  // today = new EventEmitter();

  optHour: number[] | string[] = [];
  optMin: number[] | string[] = [];
  optSec: number[] | string[] = [];

  _currentValue: string;

  _onChange: (value) => {};
  _onTouched: () => {};
  _disabled: boolean;

  writeValue(obj: any): void {
    if (obj) {
      this._currentValue = obj;
      setTimeout(() => {
        this.valueToInput();
      }, 0);
    } else {
      this._currentValue = null;
      setTimeout(() => {
        this.hourInput.nativeElement.value = '00';
        this.minInput.nativeElement.value = '00';
        this.secInput.nativeElement.value = '00';
      }, 0);
    }
    // 初次不產生change, 值一樣不產生change Chrome有夏令時區問題 待解
    //if(prevValue && this.fixTimeZone(prevValue)!=this.fixTimeZone(this._currentValue)){
      //this.emitChange();
    //}
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  control: FormControl;
  elWidth = 120;
  constructor(private el: ElementRef,
    private cd:ChangeDetectorRef) {
    el.nativeElement.focus = (opt) => {
      this.hourInput.nativeElement.focus(opt);
    }
  }

  ngOnInit(): void {
    this.optHour = Array(24).fill(0).map((x, i)=>{ return (i < 10 ? '0' : '') + i; });
    this.optMin = Array(60).fill(0).map((x, i)=>{ return (i < 10 ? '0' : '') + i; });
    this.optSec = Array(60).fill(0).map((x, i)=>{ return (i < 10 ? '0' : '') + i; });

    if(this._currentValue){
      this.valueToInput();
    }
  }

  valueToInput(){
    var t = this._currentValue.split(':');
    this.hourInput.nativeElement.value = (t[0] == '0' ? '00' : t[0]) || '00';
    this.minInput.nativeElement.value = (t[1] == '0' ? '00' : t[1]) || '00';
    this.secInput.nativeElement.value = (t[2] == '0' ? '00' : t[2]) || '00';
  }

  numberDown(evt: KeyboardEvent){
    var allow = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Backspace', 'Tab' ,'Delete', 'Enter'];
    if (allow.some(k => k == evt.key))
    {
      return true;
    }
    if (evt.key < "0" || evt.key > "9")
    {
      return false;
    }
    return true;
  }

  focusTo(inputEl: HTMLInputElement){
    inputEl.focus();
    inputEl.setSelectionRange(0, inputEl.value.length, 'none');
  }

  // inputDown(evt: KeyboardEvent, prevTo: HTMLInputElement, nextTo: HTMLInputElement){
  inputDown(evt: KeyboardEvent, prevTo: string, nextTo: string) {
    evt.stopPropagation();
    if(!this.numberDown(evt)){
      return false;
    }
    // var input = evt.target as HTMLInputElement; // this.minInput.nativeElement;
    var input: HTMLInputElement;
    var nextInput: HTMLInputElement;
    var prevInput: HTMLInputElement;
    if (nextTo) {
      if (nextTo == 'min') {
        input = this.hourInput.nativeElement;
        prevInput = null;
        nextInput = this.minInput.nativeElement;
      } else if (nextTo == 'sec') {
        input = this.minInput.nativeElement;
        prevInput = this.hourInput.nativeElement;
        nextInput = this.secInput.nativeElement;
      }
    } else {
      input = this.secInput.nativeElement;
      prevInput = this.minInput.nativeElement;
      nextInput = null;
    }

    // 在最後一位數按右鍵 -> 跳下一個欄位，沒下一個欄位就停住
    if(evt.key == 'ArrowRight'){
      // 右鍵
      if(input.selectionEnd == input.value.length && nextTo) {
        this.focusTo(nextInput);
        return false;
      }
    } else if(evt.key == 'ArrowLeft' && prevTo){
      //左鍵 在最左回前一個欄位，無前一個欄位則停住
      if(input.selectionEnd == 0) {
        this.focusTo(prevInput);
        return false;
      }
    } else if(evt.key == 'Backspace' && prevTo){
      //左鍵 在最左回前一個欄位，無前一個欄位則停住
      if(input.selectionEnd == 0) {
        this.focusTo(prevInput);
        return false;
      }
    } else if (evt.key == 'ArrowUp') {
      // 上 加 1，已是最大限制就指定最大
      if (!input.value) {
        input.value = '1';
      } else {
        var next = (parseInt(input.value) + 1);
        var max = parseInt(input.max);
        if(!this._currentValue || next > max) {
          // next = next > max ? max : next;
          next = max;
        }
        input.value = next.toString()
      }
    } else if(evt.key == 'ArrowDown'){
      // 下 減 1，已是最小值就指定最小
      if (!input.value) {
        input.value = input.min;
      } else {
        var next = parseInt(input.value) - 1;
        var min = parseInt(input.min);
        if(!this._currentValue || next < min){
          // next = next < min ? min : next;
          next = min;
        }
        input.value = next.toString();
      }
    }
  }

  // inputUp(evt: KeyboardEvent, nextTo: HTMLInputElement){
  inputUp(evt: KeyboardEvent, nextTo: string) {
    var skip = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Backspace', 'Tab' , 'Delete'];
    if(skip.some(k => evt.key == k)){
      return;
    }

    // var input = evt.target as HTMLInputElement;
    var input: HTMLInputElement;
    var nextInput: HTMLInputElement;
    var prevInput: HTMLInputElement;
    if (nextTo) {
      if (nextTo == 'min') {
        input = this.hourInput.nativeElement;
        prevInput = null;
        nextInput = this.minInput.nativeElement;
      } else if (nextTo == 'sec') {
        input = this.minInput.nativeElement;
        prevInput = this.hourInput.nativeElement;
        nextInput = this.secInput.nativeElement;
      }
    } else {
      input = this.secInput.nativeElement;
      prevInput = this.minInput.nativeElement;
      nextInput = null;
    }
    if(input.value.length >= input.maxLength && nextTo){
      this.focusTo(nextInput);
    }
    
    input.value = parseInt(input.value) > parseInt(input.max) ? input.max : input.value;
    this.updateValue();
  }

  updateValue(){
    var hour = this.hourInput.nativeElement.value;
    var min = this.minInput.nativeElement.value;
    var sec = this.secInput.nativeElement.value;
    this._currentValue = hour+':'+min+':'+sec;
    if(this._onChange) {
      this._onChange(this._currentValue);
      this.valueChange.emit(this._currentValue);
    }
    if(this._onTouched){
      this._onTouched();
    }
  }

  focused = [0,0,0]
  onfocus(evt){
    if(evt.target==this.hourInput.nativeElement) this.focused[0] = 1;
    if(evt.target==this.minInput.nativeElement) this.focused[1] = 1;
    if(evt.target==this.secInput.nativeElement) this.focused[2] = 1;
    var inputEl = (evt.target as HTMLInputElement);
    inputEl.setSelectionRange(0,inputEl.value.length,'none');
  }

  onblur(evt) {
  }
 
  setHour(h) {
    this.hourInput.nativeElement.value = h;
    this.updateValue();
  }

  setMin(h) {
    this.minInput.nativeElement.value = h;
    this.updateValue();
  }
  
  setSec(s) {
    this.secInput.nativeElement.value = s;
    this.updateValue();
  }
  menuContainerStyle = {width:(this.elWidth||100)+'px'};
  
  updateWidth() {
    this.elWidth = (this.el.nativeElement as HTMLElement).firstElementChild.clientWidth; // -25;
    
    this.menuContainerStyle = {width:(this.elWidth||100)+'px'};
    
        var optIndex = this.optHour.findIndex(x=>x.toString() == this.hourInput.nativeElement.value);
        optIndex = Math.max(optIndex-2,0);
        this.hourPicker.nativeElement.scrollTop= optIndex * 32;
  
        optIndex = this.optMin.findIndex(x=>x.toString() == this.minInput.nativeElement.value);
        optIndex = Math.max(optIndex-2,0);
        this.minPicker.nativeElement.scrollTop= optIndex * 32;
  
        optIndex = this.optSec.findIndex(x=>x.toString() == this.secInput.nativeElement.value);
        optIndex = Math.max(optIndex-2,0);
        this.secPicker.nativeElement.scrollTop= optIndex * 32;
    
  }

  @ViewChild('menuTrigger')
  menu:MatMenuTrigger;
}
