<app-vis-dialog title="補印" [show]="isOpenDialog" (close)="handleDialogClose()" [width]="600" i18n-title="@@HpcCodePin">
  <div style="padding: 10px 15px; white-space: pre-wrap; overflow-x: hidden; overflow-y: auto;" class="extend-msg">
    請選擇要補印的項目：</div>
  <div class="print-toolbar inside-scrollbar pt-0 mt-0">
    <div style="display: flex;flex-wrap: wrap;column-gap: 10px;" [formGroup]="formHelper.FormGroup"
      *ngIf="formHelper && formHelper.FormGroup">
      <mat-checkbox *ngIf="mode && mode == registerConst.Mode_Reg" [checked]="false" formControlName="IsPrintRegister"
        class="mt-2">掛號單</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintPrescription" class="mt-2">門診處方</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintDetail" class="mt-2">門診明細及收據</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintWay" class="mt-2">服法及收據</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintExam" class="mt-2">院內檢驗單</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintDispPrescription" class="mt-2">交付調劑處方箋</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintDispExam" class="mt-2">交付檢驗單</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintDrugBag" class="mt-2">藥袋</mat-checkbox>
      <mat-checkbox *ngIf="isShowQRcodeTest" [checked]="false" (change)="qrcodeChange($event)"
        formControlName="IsPrintDispPrescriptionQRcode" class="mt-2">QR Code 交付調劑處方箋(測試)</mat-checkbox>
      <mat-checkbox [checked]="false" formControlName="IsPrintCtrDrugs" class="mt-2">管制藥品處方箋</mat-checkbox>
    </div>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onPrint()" i18n="@@Add">列印</button>
    <button type="button" (click)="onCancel()" i18n="@@RegCancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>



<app-vis-dialog title="請輸入醫事人員Pin碼" [show]="isopenCodePin" (close)="isopenCodePin = false"
[width]="300" i18n-title="@@HpcCodePin">
<label class="vis-form-field">
    <span class="required" i18n="@@HpcCodePin">Pin碼</span>
  <input class="vis-input" type="tel"  [(ngModel)]="hpcCodePin"  />
</label>
  <app-vis-dialog-actions>
    <button type="button" (click)="onHpcCodeDialogClose('ok')" i18n="@@Add">確定</button>
    <button type="button" (click)="onHpcCodeDialogClose('cancel')" i18n="@@RegCancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
