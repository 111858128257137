import { AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterQueryOptions, ReserveDto, ReserveHistDetails, ReserveListDto, ReserveRegDetails, ReserveReceiptPrint } from 'src/app/services/api-service/register/register-api';
import { BaseConfig } from 'src/app/services/base-config.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { RegReserveListComponent, WeekSettingDay } from '../reg-reserve-list/reg-reserve-list.component';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { MemoryCache } from 'src/app/services/memory-cache-service';
import { UserCache } from 'src/app/services/user-cache';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { weekDay } from 'src/app/services/api-service/register/RegisterEditDto';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { FontGreatService } from 'src/app/services/font-great.service';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { SCH001Params } from 'src/app/services/api-service/parameters/SCH001Params';
import { RegReserveService } from '../reg-reserve-service';
import { WindowSizeService } from 'src/app/services/window-size-service';
import { Subscription } from 'rxjs';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';
import { TimeSecEnum } from 'src/app/enums/TimeSecEnum';
import { PatientSelectService } from 'src/app/services/patient-select.service';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { VisGirdSelection, VisGridCellClick, VisGridColumnSort, VisGridComponent } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';
import { Compare, Distinct, Or } from 'src/app/shared/utilities';
import { TutorialService } from 'src/app/services/tutorial-service';
import { ReserveReceiptComponent } from 'src/app/system/report/components/reg-reserve/reserve-receipt/reserve-receipt.component';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';

@Component({
  selector: 'app-reg-reserve',
  templateUrl: './reg-reserve.component.html',
  styleUrls: ['./reg-reserve.component.css']
})


export class RegReserveComponent implements OnInit, OnDestroy, AfterViewInit {
  /** 所選患者Id, 門診過來要帶預設值 */
  @Input()
  patientId: number;
  /** 當前操作醫師，門診帶過來 */
  @Input()
  doctorId: number = 0;
  @ViewChild('histGrid')
  histGridComp: VisGridComponent;
  //列印掛號預約單
  @ViewChild('report')
  reports: ReserveReceiptComponent;

  reserveReceiptName = '掛號預約單';

  /** 畫面是否初始化 */
  isInitialized = false;
  enablePlaceHolder = false;
  /** 顯示相關設定... */
  bodyHeight = document.body.clientHeight;
  nowWidth: number = window.innerWidth;
  bigRatio: boolean = false;


  roomFilterOpt: ValueTextPair[] = [];
  @Input()
  roomFilter:string = '';
  /** 基資form */
  formHelper: FormHelper<PatientFormModel>;
  patientFormGroup: FormGroup;
  /** 掛號form */
  regFormHelper: FormHelper<RegFormModel>;
  regFormGroup: FormGroup;

  // 查詢選項
  timeSecOpt: ValueTextPairNumberValue[] = [];
  roomOpt: ValueTextPair[] = [];
  doctorOpt: ValueTextPairNumberValue[] = [];
  icodeOpt: ValueTextPair[] = [];

  // 表格-分頁
  /** 該日預約清單 */
  gridView: ReserveListDto[] = []
  /** 患者預約清單 */
  reserveGridView: ReserveRegDetails[] = [];
  /** 患者就醫紀錄 */
  histGridView: ReserveHistDetails[] = [];

  /** 月曆選取時間 */
  selectedDate: Date;
  secFilter: TimeSecEnum = null;
  /** 月曆選取時段 */
  selectedSec:TimeSecEnum;

  /** 患者預約清單選取項目 */
  regSelectKeys:number[] = [];


  /** 是否顯示爽約 */
  showMiss: boolean = false;
  /** 是否只顯示同醫師(有醫師Id時為true) */
  showSelf: boolean = false;
  /** 所選患者預約資料 */
  reserveData: ReserveDto;

  /** 事件訂閱 */
  private resizeSub: Subscription;
  private loadSub: Subscription;
  addRows = 0;
  constructor(private fb: FormBuilder,
    private cache: MemoryCache,
    public fontGreatService: FontGreatService,
    private fhs: FormHelperService,
    private notification: EasyNotificationService,
    private paramAPI: ParameterApi,
    private reserveService: RegReserveService,
    private sizeService: WindowSizeService,
    private patientSelectService: PatientSelectService,
    private tutorial:TutorialService,
    private localPrint: LocalPrintService,
    private mainLayoutService: MainLayoutService,
    private zone: NgZone,
    ) {

    this.resizeSub = this.sizeService.onWinResize.subscribe(() => {
      this.nowWidth = this.sizeService.nowWidth;
      this.bodyHeight = this.sizeService.bodyHeight;
      this.bigRatio = this.sizeService.bigRatio;
      var dHeight = this.sizeService.contentHeight - 650;
      this.addRows = dHeight>0? Math.floor(dHeight / 60):0;
    })
    this.loadSub = this.reserveService.onReserveLoad.subscribe(()=>{
      var allDoctor= this.reserveService.getAllDoctor();
      this.roomFilterOpt = Distinct(allDoctor
                            .map(x=>{ return {value:x.extension,text:this.roomOpt.find(y=>y.value==x.extension).text,extension:''}}),x=>x.value)
                            .sort((a,b)=>Compare(a.value,b.value))
                            .concat([{value:'',text:'全部',extension:''}]);
      this.enablePlaceHolder = true;
    })
    this.tutorial.RegisterTips('reserve',[{
      content:'欲檢視預約清單先點選日期',
      target:'reserve-list-today',
      cssCls:'',
    },{
      content:'此處顯示所選日期的預約清單',
      target:'reserve-patient-list',
      cssCls:'',
    },{
      content:'此處可檢視該時段各醫師的預約量',
      target:'reserve-list-tsec-cnt',
      cssCls:'',
    },{
      content:'掛號：請先點選「查詢」選取患者',
      target:'reserve-query-Btn',
      cssCls:'',
    },{
      content:'此處會列出該患者未看診的預約清單',
      target:'reserve-unfinished-list',
      cssCls:'',
    },{
      content:'此處會列出該患者看診的紀錄',
      target:'reserve-hist-list',
      cssCls:'',
    },{
      content:'掛號：請選擇日期的時段',
      target:'reserve-list-tsec',
      cssCls:'',
    },{
      content:'掛號：此處提供常用的預約天數可直接選取',
      target:'reserve-list-quick-select',
      cssCls:'',
    },{
      content:'請修改與確認資訊是否正確',
      target:'reserve-form',
      cssCls:'',
    },{
      content:'點選「預約」完成預約掛號',
      target:'reserve-Btn',
      cssCls:'',
    }]);
  }


  async ngOnInit() {
    this.enablePlaceHolder = false;
    this.reserveService.currentPatient = this.patientId;
    this.initFormGroup();
    if(this.doctorId){
      this.showSelf = true;
    }
    this.timeSecOpt = this.reserveService.timeSecOption
    this.roomOpt = this.reserveService.roomOption;
    this.icodeOpt = this.reserveService.iCodeSecOption;
    //this.bodyHeight = document.body.clientHeight;

    this.nowWidth = this.sizeService.nowWidth;
    this.bodyHeight = this.sizeService.bodyHeight;
    this.bigRatio = this.sizeService.bigRatio;
    if(!this.roomFilter){
      this.roomFilter = this.reserveService.filter.roomCode;
    }
    this.selectedDate = new Date();
    //await this.refreshReserveList(this.selectedDate,null);
    if (this.patientId) {
      await this.getPatientReserves();
    }

    var dHeight = this.sizeService.contentHeight - 650;
    this.addRows = dHeight>0? Math.floor(dHeight / 60):0;
    this.isInitialized = true;

    //掛號預約單
    this.setClinicInfo();
  }
  ngAfterViewInit(): void {
    this.filterRoom(this.roomFilter);
  }
  //msgId = '';
  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
    this.loadSub.unsubscribe();
  }
  /** form初始化 */
  initFormGroup() {
    this.formHelper = this.fhs.Create<PatientFormModel>().build({
      Id: [0, null],
      PatientNo: ['', null],
      CName: ['', null],
      CId: ['', null],
      Birthday: [null, null],
      CellPhone: ['', null],
      Phone: ['', null],
      MissTimes: [0, null],
      Kcstmr: ['', null]
    });
    this.patientFormGroup = this.formHelper.FormGroup;
    this.regFormHelper = this.fhs.Create<RegFormModel>().build({
      RegId: [0, null],
      RegDate: [null, null],
      RegDateStr: ['', null],
      TimeSec: [null, null],
      TimeSecStr: ['', null],
      DoctorId: [0, null],
      ICode: ['', null],
      WaitNo: [null, null],
      Remark: ['', null]
    });
    this.regFormGroup = this.regFormHelper.FormGroup;
    this.regFormGroup.disable();
  }

//#region UI Events
  /** [月曆選取]日期，取得當天預約清單 */
  async onDateSelected(date: Date) {
    this.selectedDate = date;
    this.secFilter = null
    this.regFormHelper.patchValue({
      RegDate: date,
      RegDateStr: DateHelper.formatROCDate(date, true, false),
      TimeSec: null,
      TimeSecStr: '',
      DoctorId:null
    });
    // query reserv list
    await this.refreshReserveList(date,null);
  }
  /** [月曆選取]時段別，帶入掛號輸入 */
  async onSectionSelected(evt: { date: Date, sec: TimeSecEnum }) {
    this.secFilter = evt.sec;
    this.selectedDate = evt.date;
    this.refreshReserveList(evt.date,this.secFilter);
    //this.doctorOpt = this.reserveService.getScheduleDoctorByDateSec(evt.date, evt.sec);
    // var doctor = this.regFormHelper.getValue('DoctorId');
    // // 有預設Doctor下
    // if (this.doctorId && this.doctorOpt.some(x => x.value == this.doctorId)) {
    //   doctor = this.doctorId;
    // }
    // if(this.reserveService.isNoschedule() && this.doctorOpt.length>0 ){
    //   doctor = this.doctorOpt[0].value;
    // }
    // // 只有一診
    // else if (this.doctorOpt.length == 1) {
    //   doctor = this.doctorOpt[0].value;
    // }
    var doctor = this.getDefaultDoctor(evt.date,evt.sec)
    this.regFormHelper.patchValue({
      RegDate: evt.date,
      RegDateStr: DateHelper.formatROCDate(evt.date, true, false),
      TimeSec: evt.sec,
      TimeSecStr: this.timeSecOpt.find(x => x.value == evt.sec)?.text,
      DoctorId: doctor
    });
    await this.getWaitNo()
  }
  getDefaultDoctor(date:Date,sec:TimeSecEnum){
    this.doctorOpt = this.reserveService.getScheduleDoctorByDateSec(date, sec);
    var doctor = this.regFormHelper.getValue('DoctorId');
    // 有預設Doctor下
    if (this.doctorId && this.doctorOpt.some(x => x.value == this.doctorId)) {
      doctor = this.doctorId;
    }else  if(this.reserveService.isNoschedule() && this.doctorOpt.length>0 ){
      doctor = this.doctorOpt[0].value;
    }
    // 只有一診
    else if (this.doctorOpt.length == 1) {
      doctor = this.doctorOpt[0].value;
    }
    // 無可選醫師
    else if(this.doctorOpt.length == 0){
      return null;
    }
    return doctor;
  }
 /** [查詢] =>選擇患者 */
  async selectPatient() {
    var patient = await this.patientSelectService.SelectPatient();
    this.patientId = patient.Id;
    this.cancel();
    await this.getPatientReserves();


  }
  /** 切換醫師 */
  async doctorChanged() {
    await this.getWaitNo()
  }
  /** 切換顯示爽約 */
  async ShowMissChecked(evt: MatCheckboxChange) {
    this.filterRegGrid();
  }

  /** 點選重給號 */
  async onGetWaiNoClick(){
    await this.getWaitNo(true);
  }

  /** 患者預約清單選取 */
  async regSelected(selections:VisGirdSelection){
    if(selections.selectedRows.length>0){
      var data: ReserveRegDetails = selections.selectedRows[0].dataItem;
      var date = new Date(data.RegDate);
      var sec = data.Section;
      this.selectedDate = date;
      this.selectedSec = sec;

      this.doctorOpt = this.reserveService.getScheduleDoctorByDateSec(date,sec);
      this.regFormHelper.patchValue({
        RegDate:date,
        RegDateStr: DateHelper.formatROCDate(date, true, false),
        TimeSec:sec,
        TimeSecStr: this.timeSecOpt.find(x => x.value == data.Section)?.text,
        DoctorId:data.DoctorId,
        ICode:data.ICode,
        RegId:data.RegId,
        Remark:data.Remark,
        WaitNo:data.WaitNo
      })
    }else{
      this.cancel();
    }
  }

  /** 取消編輯 */
  cancel() {
    var doctor = this.getDefaultDoctor(this.selectedDate,this.secFilter);
    if(this.doctorOpt.length>0){
      this.regFormHelper.patchValue({
        RegId:null,
        DoctorId: doctor,
        RegDate: this.selectedDate,// null,
        RegDateStr: DateHelper.formatROCDate(this.selectedDate, true, false),
        TimeSec: this.secFilter,// null,
        TimeSecStr: this.timeSecOpt.find(x => x.value == this.secFilter)?.text,
        Remark: '',
        WaitNo: null
      });
    }else{
      this.regFormHelper.patchValue({
        RegId:null,
        DoctorId: null,
        RegDate: null,
        RegDateStr:'',
        TimeSec: null,
        TimeSecStr: '',
        Remark: '',
        WaitNo: null
      });
    }

    this.selectedSec = this.secFilter;
    this.regSelectKeys=[];
  }

  /** 該日預約清單選取患者 */
  async reserveListClick(evt:VisGridCellClick){
    var data = evt.dataItem as ReserveListDto;
    if(this.patientId!=data.PatientId){
      this.patientId = data.PatientId;
      if(this.patientId){
        this.cancel();
        await this.getPatientReserves();
      }
    }
  }


  /** 排序-當日預約 */
  gridSort(evt:VisGridColumnSort){
    this.gridView =[].concat(this.gridView.sort((a,b)=>Compare(a[evt.column],b[evt.column])*(evt.sortOrder=='desc'?-1:1)));
  }
  /** 排序-患者預約 */
  gridRegSort(evt:VisGridColumnSort){
    this.reserveGridView =[].concat(this.reserveGridView.sort((a,b)=>Compare(a[evt.column],b[evt.column])*(evt.sortOrder=='desc'?-1:1)));
  }
  /** 排序-患者就醫紀錄 */
  gridHistSort(evt:VisGridColumnSort){
    this.histGridView =[].concat(this.histGridView.sort((a,b)=>Compare(a[evt.column],b[evt.column])*(evt.sortOrder=='desc'?-1:1)));
  }
//#endregion





//#region CRUD
  currentReserveList:ReserveListDto[];
  /** 取得預約清單 */
  async refreshReserveList(date:Date,sec:TimeSecEnum) {
    this.currentReserveList = await this.reserveService.getReservesByDate(date);
    if(sec!=null){
      this.currentReserveList = this.currentReserveList.filter(x=>x.TimeSec == sec);
    }
    var ret = this.filterReserveList();
    this.gridView = ret;

    //原本要做到點選日期後，出現預約清單，自動連動基本資料。
    //但發現預約清單與基本資料本就不自動連動，否則會影響新增預約時的控制。
    //(後來取消)如果預約掛號已沒資料，連同未看診，歷史也清空。
    // if (this.gridView.length == 0) {
    //   this.patientId = 0;
    //   this.reserveGridView = [];
    //   this.histGridView = [];
    // }
    // else {
    //   // this.reserveListClick(this.gridView[0]);
    //   // if(this.patientId!=this.gridView[0].PatientId){
    //     this.patientId = this.gridView[0].PatientId;
    //     if(this.patientId){
    //       this.cancel();
    //       await this.getPatientReserves();
    //     }
    //   // }
    // }

  }

  filterReserveList(){
    var ret = this.currentReserveList;
    if(this.roomFilter){
      ret = ret.filter(x=>x.RoomCode == this.roomFilter);
    }
    if(this.doctorId){
      ret = ret.filter(x=>x.DoctorId==this.doctorId);
    }
    return ret;
  }

  /** 取得病患預約資料 */
  async getPatientReserves() {
    this.reserveData = await this.reserveService.getReserveData(this.patientId)
    // 基本資料區
    this.formHelper.patchValue({
      Id: this.reserveData.Id,
      CName: this.reserveData.Name,
      PatientNo: this.reserveData.PatientNo,
      CId: this.reserveData.CId,
      Birthday: this.reserveData.Birthday,
      Kcstmr: this.reserveData.Kcstmr,//patient.Kcstmr,
      MissTimes: this.reserveData.MissTimes,//patient.MissTimes,
      Phone: this.reserveData.PhoneNo,
      CellPhone: this.reserveData.Mobile
    });
    // 預約資料區
    this.regFormHelper.patchValue({
      ICode: this.reserveData.ICode,
      //DoctorId: this.getDefaultDoctor(this.selectedDate,this.selectedSec)
    })
    this.filterRegGrid();
    this.filterHistGrid();

    this.regFormGroup.controls[this.regFormHelper.FieldName.ICode].enable();
    this.regFormGroup.controls[this.regFormHelper.FieldName.Remark].enable();
    this.regFormGroup.controls[this.regFormHelper.FieldName.DoctorId].enable();
    this.reserveService.currentPatient = this.patientId;
    await this.getWaitNo();
    // this.notification.closeMessage(this.msgId);
    // this.msgId = this.notification.showTip('請選擇右側時段');

  }
  /** 取候診號(預覽) */
  async getWaitNo(showNotify: boolean = false) {
    var pId = this.formHelper.getValue('Id');
    if (!pId) {
      if (showNotify) {
        this.notification.showWarning('請先選擇患者')
      }
      return;
    }
    var doctor = this.regFormHelper.getValue('DoctorId');
    if (!doctor) {
      if (showNotify) {
        this.notification.showWarning('請先選擇醫師')
      }
      return;
    }
    var date = this.regFormHelper.getValue('RegDate');
    var sec = this.regFormHelper.getValue('TimeSec');
    if (!date || !sec) {
      if (showNotify) {
        this.notification.showWarning('請先選擇預約日期/時段')
      }
      return;
    }
    var doctors = this.reserveService.getScheduleDoctorByDateSec(date, sec);
    var doctorRoom = doctors.find(x => x.value == doctor)?.extension ?? '';
    if (!doctorRoom) {
      if (showNotify) {
        this.notification.showWarning('找不到該時段別所選醫師的診間')
      }
      return
    }
    if (doctorRoom && date && sec) {
      try {
        var no = await this.reserveService.getWaitNo(date, sec, doctorRoom)
        this.regFormHelper.setValue('WaitNo', no);
      } catch (e) {
        if (showNotify) {
          this.notification.showWarning('取得預約號碼失敗(' + e.message + ')')
        }
        console.log(e);
        return
      }
    }
  }
  /** 儲存(新增預約掛號) */
  async save() {
    var date = this.regFormHelper.getValue('RegDate');
    var sec = this.regFormHelper.getValue('TimeSec');
    if(!date || !sec){
      this.notification.showWarning('請先選擇日期/時段');
      return;
    }
    var doctor = this.regFormHelper.getValue('DoctorId');
    if(!doctor){
      this.notification.showWarning('請先選擇醫師');
      return;
    }
    var doctors = this.reserveService.getScheduleDoctorByDateSec(date, sec);
    var doctorRoom = doctors.find(x => x.value == doctor)?.extension ?? '';

    var ret = await this.reserveService.reserve({
      Id:0,
      ClinicId: UserCache.getLoginClinicId(),
      PatientId: this.formHelper.getValue('Id'),
      DoctorId: doctor,
      RegDate: date,
      TimeSec: sec,
      RoomCode: doctorRoom,
      ICode: this.regFormHelper.getValue('ICode'),
      Remark: this.regFormHelper.getValue('Remark'),
      DUTY: 1,
    });
    if (ret) {
      await this.updateViewAfterCommit()
    }

  }
  /** 更新預約掛號 */
  async update() {
    var date = this.regFormHelper.getValue('RegDate');
    var sec = this.regFormHelper.getValue('TimeSec');
    var doctor = this.regFormHelper.getValue('DoctorId');
    var doctors = this.reserveService.getScheduleDoctorByDateSec(date, sec);
    var doctorRoom = doctors.find(x => x.value == doctor)?.extension ?? '';

    var ret = await this.reserveService.updateReserve({
      Id:this.regFormHelper.getValue('RegId'),
      ClinicId: UserCache.getLoginClinicId(),
      PatientId: this.formHelper.getValue('Id'),
      DoctorId: doctor,
      RegDate: date,
      TimeSec: sec,
      RoomCode: doctorRoom,
      ICode: this.regFormHelper.getValue('ICode'),
      Remark: this.regFormHelper.getValue('Remark'),
      DUTY: 1,
    });
    if (ret) {
      await this.updateViewAfterCommit()
    }
  }
  /** 刪除預約掛號 */
  async remove() {
    var ret = await this.reserveService.deleteReserve(this.regFormHelper.getValue('RegId'));
    if (ret) {
      await this.updateViewAfterCommit()
    }
  }
  /** 新增修改刪除後更新畫面資料 */
  async updateViewAfterCommit(){
    
    //if(DateHelper.compareDate(this.selectedDate,this.regFormHelper.getValue('RegDate'))){
      await this.refreshReserveList(this.selectedDate,this.secFilter);
    //}
    this.regFormHelper.patchValue({
      RegId:null,
      DoctorId: this.getDefaultDoctor(this.selectedDate,this.secFilter),
      // RegDate: null,
      // TimeSec: null,
      ICode:null,
      Remark: '',
      WaitNo: null
    });
    await this.getPatientReserves();
    await this.reserveService.reloadReserve()
  }



  /** 按條件過濾患者預約清單 */
  filterRegGrid() {
    var ret = this.reserveData.Regs;
    if (!this.showMiss) {
      ret = ret.filter(x => new Date(x.RegDate) >= DateHelper.today);
    }


    if (this.showSelf) {
      ret = ret.filter(x => x.DoctorId == this.doctorId);
    }
    this.reserveGridView = ret;
  }
  /** 按條件過濾就醫紀錄 */
  filterHistGrid(){
    var ret = this.reserveData.Hists;
    if (this.showSelf) {
      ret = ret.filter(x => x.DoctorId == this.doctorId);
    }
    this.histGridView = ret;

  }
//#endregion

  /** 歷史看診資料Grid完成Setted */
  HistDataSetted(ele){
    if (this.histGridComp) {
      // 將scroll 捲到底
      var bottom= this.histGridComp.scrollContainer.nativeElement.scrollHeight;
      this.histGridComp.scrollContainer.nativeElement.scrollTo(0,bottom);
    }
  }


// 以下其他
  isOpenReserveDialog: boolean = false;
  unGotSerial: boolean = true;
  isGotSerial: boolean = false;

  reserveDialogOpen(event: boolean) {
    this.isOpenReserveDialog = event;
  }

  onGotSerial(evt: any): void {
    // console.log(evt.source);
    this.isGotSerial = true;
    this.unGotSerial = false;
  }

  onUnGotSerial(evt: any): void {
    this.isGotSerial = false;
    this.unGotSerial = true;
  }

  filterRoom(roomCode:string){
    this.roomFilter = roomCode;
    this.reserveService.setFilter({roomCode,doctorId:0})
    // 過濾預約掛號清單
    var ret = this.filterReserveList();
    this.gridView = ret;
    // 更新預約資料的醫師欄位(與其他欄位，因為可能點來點去被清除)
    var doctor = this.getDefaultDoctor(this.selectedDate,this.secFilter)
    if(this.doctorOpt.length>0){
      this.regFormHelper.patchValue({
        RegDate: this.selectedDate,
        RegDateStr: DateHelper.formatROCDate(this.selectedDate, true, false),
        TimeSec: this.secFilter,
        TimeSecStr: this.timeSecOpt.find(x => x.value == this.secFilter)?.text,
        DoctorId: doctor
      });
      // 更新完醫師重拿預覽預約號
      this.getWaitNo();
    }
    // 無可選醫師(休診) 日期時段清除
    else{
      this.regFormHelper.patchValue({
        RegDate:null,
        RegDateStr:'',
        TimeSec:null,
        TimeSecStr:''
      });
    }
  }

  //掛號預約單
  previewData: ReserveReceiptPrint[] = [];
  printData: ReserveReceiptPrint[] = [];
  currentNotSeen = false;
  currentFindId: 0;

  clinicName = '';
  clinicAddress = '';
  clinicPhone = '';
  setClinicInfo(){
    const user = UserCache.getLoginUser();
    this.clinicName = user.Clinic.Name;
    this.clinicAddress = user.Clinic.City + user.Clinic.Area + user.Clinic.Street;
    this.clinicPhone = user.Clinic.Phone;
  }

  previewList(findId: any, notSeen: any): ReserveReceiptPrint{
    var toPreview: ReserveReceiptPrint;
    var findData: any;
    if (!notSeen){
      findData = this.currentReserveList.find(x => x.Id == findId);
      if (!findData)
        return null;

      toPreview =  {
        Name: findData.Name,
        PatientNo: findData.PatientNo,
        RoomName: this.roomOpt.find(y=>y.value==findData.RoomCode).text,
        Remark: findData.Remark,
        DoctorName: findData.DoctorName,
        WaitNo: findData.WaitNo,
        RegDate: findData.RegDate,
        TimeName: this.timeSecOpt.find(y=>y.value==findData.TimeSec).text,
        ClinicName: this.clinicName,
        ClinicAddress: this.clinicAddress,
        ClinicPhone: this.clinicPhone,
      }
    }
    else {
      findData = this.reserveData.Regs.find(x => x.RegId == findId);
      if (!findData)
        return null;

      toPreview =  {
        Name: this.reserveData.Name,
        PatientNo: this.reserveData.PatientNo,
        RoomName: this.roomOpt.find(y=>y.value==findData.RoomCode).text,
        Remark: findData.Remark,
        DoctorName: findData.DoctorName,
        WaitNo: findData.WaitNo,
        RegDate: findData.RegDate,
        TimeName: this.timeSecOpt.find(y=>y.value==findData.Section).text,
        ClinicName: this.clinicName,
        ClinicAddress: this.clinicAddress,
        ClinicPhone: this.clinicPhone,
      }
    }

    return toPreview;
  }

  async preview(d: VisDialogComponent, findId: any, notSeen: any) {
    this.mainLayoutService.showLoader();
    // this.notification.showSuccess('資料整理中')
    this.previewData = [];
    this.currentNotSeen = notSeen;
    this.currentFindId = findId;
    let data = this.previewList(findId, notSeen);
    // this.notification.showSuccess('資料已備妥')
    this.zone.run(() => {
      this.previewData = [data];
      d.show = true;
      this.mainLayoutService.hideLoader()
    }, this);
  }

  //列印掛號預約單
  async print(d: VisDialogComponent, findId: any, notSeen: any) {
    this.currentNotSeen = notSeen;
    this.currentFindId = findId;
    let data = this.previewList(findId, notSeen);
    let h = '';
    for (let d of [data]) {
      let rs;
      let p = new Promise((a) => { rs = a; });
      this.printData = [d];
      // 父data指給子列印用
      [this.reports.data] = this.printData;
      this.mainLayoutService.hideLoader()
      // let j = new Promise((resolve) => {
      // });

      let m = new Promise((resolve) => {
        if (this.printData[0].Remark && resolve) {
          this.reports.hasRemark = true
        } else {
          this.reports.hasRemark = false;
        }
      });
      setTimeout(() => {
        h += this.reports.getHtml();
        // console.log('h TIMEOUT',h);
        rs();
      }, 500);
      await p;
    };
    // console.log('[data]',[data]);
    this.localPrint.printHtml(444, this.reserveReceiptName, h);
    this.printData = [data];
    // console.log('printData',this.printData);
    this.notification.showSuccess('開始列印-' + this.reserveReceiptName);
    if (d) {
      d.show = false
    }
  }

}


interface PatientFormModel {
  Id: number;
  PatientNo: string;
  CName: string;
  CId: string;
  Birthday: Date;
  CellPhone: string;
  Phone: string;
  MissTimes: number;
  Kcstmr: string;
}
interface RegFormModel {
  RegId: number;
  RegDate: Date;
  RegDateStr: string;
  TimeSec: TimeSecEnum;
  TimeSecStr: string;
  DoctorId: number;
  ICode: string;
  WaitNo: number;
  Remark: string;
}
