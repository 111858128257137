<div class="row narrow-padding">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header mt-1">
        <form [formGroup]="queryFormGroup">
          <div class="c-form-inline float-left pt-1 pb-1" style="width: calc(100% - 20rem);">
            <span class="float-left ml-2 mr-1">開始日期</span>
            <app-vis-datepicker class="float-left mr-1" formControlName="startDate"></app-vis-datepicker>
            <span class="float-left ml-2 mr-1">結束日期</span>
            <app-vis-datepicker class="float-left mr-1" formControlName="endDate"></app-vis-datepicker>
            <span class="float-left ml-2" i18n="">上傳狀態</span>
            <div class="vis-form-field">
              <mat-radio-group class="float-left mr-1" formControlName="toccUploadStatus">
                <mat-radio-button value="" i18n="@@All">全部</mat-radio-button>
                <mat-radio-button value="N" i18n="@@NotUpload">未上傳</mat-radio-button>
                <mat-radio-button value="Y" i18n="@@Upload">已上傳</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </form>
        <div class="float-right">
          <button type="button" (click)="onBtnQueryClick()" i18n="@@Query"
            class="vis-btn-config p-0 position-relative pl-2 w-18" title="查詢">
            <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
          </button>
          <button type="button" (click)="onBtnSelectNeed()" i18n="@@SelectNeed"
            class="vis-btn-config p-0 position-relative pl-2 w-28" title="勾選未上傳">
            <mat-icon class="pref-icon vis-combine-icon">select_all</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">勾選未上傳</span>
          </button>
          <button type="button" (click)="onBtnBatchUpload()" i18n="@@BatchUpload"
            class="vis-btn-config p-0 position-relative pl-2 w-24" title="批次上傳" [disabled]="!isEnabledTOCC">
            <mat-icon class="pref-icon vis-combine-icon">cloud_upload</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2" [ngStyle]="{'color': batchUploadColor}">批次上傳</span>
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="inside-scrollbar p-0">
          <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="false"
            (pageChange)="pageChange($event)" [navigable]="true" [selectable]="true" [kendoGridSelectBy]="'Id'"
            [selectedKeys]="selectedItems" [setChangeClassBy]="'Id'" [changeRowClass]="'nhi-declare-grid-bg'" class="no-border">
            <app-vis-grid-checkbox-column [title]="'勾選(' + selectCount + '/' + itemCount + ')'" [field]="'SelectItem'"
              [showSelectAll]="true" [allChecked]="isAllChecked()" [someChecked]="isSomeChecked()"
              (checkChanged)="selectItemCheckChanged($event)" [width]="80"
              class="text-center position-sticky super-column-fix-left fix-IsEnabled">
            </app-vis-grid-checkbox-column>
            <app-vis-grid-column field="PatientNo" title="病歷號" [width]="100"
              i18n-title="@@PatientNo"></app-vis-grid-column>
            <app-vis-grid-column field="Name" title="姓名" [width]="100" i18n-title="@@Name"></app-vis-grid-column>
            <app-vis-grid-column field="RegDate" title="看診日期" [width]="110" i18n-title="@@RegDate"
              class="text-center custom-m-auto">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="ellipsis-content" [title]="dataItem.RegDate | formatROCTime:'ispadstart,timetohourmin'">{{
                  dataItem.RegDate | formatROCTime:'ispadstart,timetohourmin' }}</div>
              </ng-template>
            </app-vis-grid-column>
            <app-vis-grid-column field="RecordTime" title="TOCC上傳時間" [width]="110" i18n-title="@@RecordTime"
              class="text-center custom-m-auto">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="ellipsis-content" [title]="dataItem.RecordTime | formatROCTime:'ispadstart,timetohourmin'">
                  {{ dataItem.RecordTime | formatROCTime:'ispadstart,timetohourmin' }}</div>
              </ng-template>
            </app-vis-grid-column>
            <app-vis-grid-column field="RecordReturnCode" title="TOCC上傳狀態" [width]="100" i18n-title="@@RecordReturnCode"
              class="text-center custom-m-auto">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="ellipsis-content" [ngStyle]="{'color': (dataItem.RecordReturnCode !== 200 ? 'red' : '')}">{{
                  dataItem.RecordReturnCode === 200 ? '已上傳' : '未上傳' }}</div>
              </ng-template>
            </app-vis-grid-column>
            <app-vis-grid-column title="" [width]="100" class="text-center custom-m-auto">
              <ng-template kendoGridCellTemplate let-dataItem>
                <button type="button" (click)="onBtnUpload(dataItem)" i18n="@@TOCCUpload"
                  class="my-1 text-xs btn-grid px-1 background-none" [disabled]="!isEnabledTOCC">
                  <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="上傳">cloud_upload</mat-icon>
                  <span class="text-xs-sm float-right" [ngStyle]="{'color': (dataItem.RecordReturnCode !== 200 ? 'red' : '')}">
                    {{dataItem.RecordReturnCode !== 200 ? '必須上傳' : '上傳'}}</span>
                </button>
              </ng-template>
            </app-vis-grid-column>
            <app-vis-grid-column field="TOCCFlag" title="TOCC填寫問卷" [width]="100" i18n-title="@@TOCCFlag"
              class="text-center custom-m-auto">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="ellipsis-content">{{ dataItem.TOCCFlag ? '病患有填' : '病患未填寫' }}</div>
              </ng-template>
            </app-vis-grid-column>
          </app-vis-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template let-data #toccUploadMessage>
  <div>
    <div style="padding: 10px 15px;">
      <label [ngStyle]="{color: data.Color}">{{data.Message}}<br>{{data.Hint}}</label>
    </div>
  </div>
</ng-template>
