import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { VisGridComponent } from '../components/grid/vis-grid/vis-grid.component';
import { UserConfirmService } from 'src/app/services/user-confirm.service';

@Directive({
  selector: 'app-vis-grid[appGridKeybord]'
})
export class GridKeybordDirective implements OnInit {

  @Input()
  appGridKeybord:boolean = true;
  @Output()
  enterData = new EventEmitter<any>()
  @Input()
  preventDefaultScroll = false;

  constructor(
    private grid: VisGridComponent,
    private confirmService:UserConfirmService) {

  }

  ngOnInit(): void {

  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if(!this.appGridKeybord){
      return;
    }
    var allow = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageUp', 'PageDown', 'Enter'];
    if (allow.some(k => k == event.key)) {
      if (event.key === 'Enter') {
        if (!this.isSelectOneRecord()) {
          this.openAlertNotSelect();
          return;
        }
        var selectedItem = this.getSelectedData();
        this.enterData.emit(selectedItem);
      } else if (event.key === 'ArrowUp') {           // 上一筆
        // console.log('ArrowUp');
        var selectedItem = this.getPrevSelectedData();
        if (selectedItem) {
          this.grid._selectedKeys.splice(0,this.grid._selectedKeys.length);
          this.grid._selectedKeys.push(selectedItem[this.grid.kendoGridSelectBy]);
          this.grid.emitSelection();
        }
      } else if (event.key === 'ArrowDown') {           // 下一筆
        // console.log('ArrowDown');
        if (this.preventDefaultScroll) {
          event.preventDefault(); // 阻止默認行為
        }
        var selectedItem = this.getNextSelectedData();
        if (selectedItem) {
          this.grid._selectedKeys.splice(0,this.grid._selectedKeys.length);
          this.grid._selectedKeys.push(selectedItem[this.grid.kendoGridSelectBy]);
          this.grid.emitSelection();
        }
      } else if (event.key === 'PageUp' || event.key === 'ArrowLeft') {   // 上一頁

        if (this.grid._pageIndex > 0) {
          this.grid.setPage(this.grid._pageIndex-1)
        } else {
          return;
        }
      } else if (event.key === 'PageDown' || event.key === 'ArrowRight') {  // 下一頁
        if (this.preventDefaultScroll) {
          event.preventDefault(); // 阻止默認行為
        }
        var totalRecord = this.grid._data.total;
        var pageSize =  this.grid._pageSize;
        var currentPage = this.grid._pageIndex;

        var totalPage = Math.ceil(totalRecord/pageSize);

        //if ((totalRecord % pageSize) > 0) totalPage += 1;
        if (currentPage < totalPage-1 ) {
          this.grid.setPage(currentPage+1);
        } else {
          return;
        }
      }
    }
  }
  isSelectOneRecord() {
    var selectedItems = this.grid._selectedKeys;
    if (!selectedItems || !(selectedItems[0]) || (selectedItems[0] as any)=== 0) {
      return false;
    } else {
      return true;
    }
  }
  openAlertNotSelect() {
    this.confirmService.showConfirm({
      'msg': '請先選擇一筆紀錄。',
      'title': '訊息',
      'hideNo': true
    });
  }

  getSelectedData(){
    var selectedItems = this.grid._selectedKeys;
    var key = this.grid.kendoGridSelectBy;
    for(var elem of this.grid._data.data){
      if (elem[key] === selectedItems[0]) {
        return elem;
      }
    }
  }
  getPrevSelectedData(): any {
    var listItems = this.grid._data.data;
    var selectedItems = this.grid._selectedKeys;
    var key = this.grid.kendoGridSelectBy;

    if(selectedItems.length == 0 && listItems.length>0){
      return listItems[0]
    }

    for(var i = 0;i<listItems.length;i++){
      if (listItems[i][key] === selectedItems[0]) {
        return listItems[Math.max(i-1,0)];
      }
    }

  }
  getNextSelectedData(): any {
    var listItems = this.grid._data.data;
    var selectedItems = this.grid._selectedKeys;
    var key = this.grid.kendoGridSelectBy;

    if(selectedItems.length == 0 && listItems.length>0){
      return listItems[0]
    }

    for(var i=0;i<listItems.length;i++){
      if (listItems[i][key] === selectedItems[0]) {
        return listItems[Math.min(listItems.length-1,i+1)];
      }
    }
  }
}

