import { Injectable } from "@angular/core";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";

export class CalendarCountDto {
  Year: number;
  YearCount: number;
  LastEditTime?: Date;
}

@Injectable({
  providedIn: 'root'
})

export class CalendarApi {
  constructor(
    private webApiFactory: WebApiClient) {
    this.api = this.webApiFactory.createHisService('system/calendar');
    // this.api.enableLoader = false;
  }

  api: WebApiService;

  async ImportCalendar(year: number): Promise<any> {
    return await this.api.post('ImportCalendar?year=' + year, {}).toPromise();
  }

  async CountCalendar(year: number): Promise<CalendarCountDto[]>{
    return await this.api.get('CountCalendar/' + year).toPromise<CalendarCountDto[]>();
  }
}
