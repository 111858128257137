<app-receipt #receipt></app-receipt>
<app-dynamic-recipt #cmsReport></app-dynamic-recipt>
<app-repeated-pres-dynamic #cmsPresReport></app-repeated-pres-dynamic>
<app-history-report #history></app-history-report>
<!-- <app-medication-way-report #way></app-medication-way-report> -->
<app-medication-way-and-receipt-report [hidden]="true" #way></app-medication-way-and-receipt-report>
<app-register-report #register></app-register-report>
<app-repeated-prescription #repPres></app-repeated-prescription>
<app-labs-outclinic-report #labOutclinic></app-labs-outclinic-report>
<app-drug-bag #drugbag></app-drug-bag>
<app-ctrdrugs-container #ctrdrugs ></app-ctrdrugs-container>


<!-- <div style="display: flex;flex-direction: column;" class="UDTmp" #UDTmp> -->
<div style="display: flex; flex-wrap: wrap; width:50%; height:100%" class="UDTmp" #UDTmp>
</div>
<div style="display: flex;" class="LRTmp" #LRTmp>
</div>
<!-- <div style="display: flex; flex-wrap: wrap; height:100%" class="UDLRTmp" #UDLRTmp> -->
<div style="display: flex; flex-wrap: wrap; width:100%; height:100%" class="UDLRTmp" #UDLRTmp>
</div>
