import { TreatmentCode } from './TreatmentCode';
import { TreatmentFee } from './TreatmentFee';
import { OutPatientPrescriptionWrite } from './OutPatientPrescriptionWrite';
import { NewBorn } from './NewBorn';
import { AllergicMedicines } from './AllergicMedicines';
import { OrganDonateWrite } from './OrganDonateWrite';
import { HealthInsurance } from './HealthInsurance';
import { EmergentTelWrite } from './EmergentTelWrite';
import { PredeliveryCheckup } from './PredeliveryCheckup';
import { PatientBaseInput } from './PatientBaseInput';
import { InoculateDataWrite } from './InoculateDataWrite';
import { GetSeqNumber256 } from './GetSeqNumber256';
import { MultiPrescriptWrite } from './MultiPrescriptWrite';
import { AllergicNum } from './AllergicNum';
import { TreatmentData } from './TreatmentData';
import { PrescriptionSignWrite } from './PrescriptionSignWrite';
import { PrescriptionSignMultiWrite } from './PrescriptionSignMultiWrite';
import { DownloadDataArg } from './DownloadDataArg';
import { CSHISResultWithData } from './CSHISResultWithData';
import { BasicData } from './BasicData';
import { RegisterBasic } from './RegisterBasic';
import { RegisterPrevent } from './RegisterPrevent';
import { RegisterPregnant } from './RegisterPregnant';
import { TreatmentNoNeedHPC } from './TreatmentNoNeedHPC';
import { CumulativeData } from './CumulativeData';
import { CumulativeFee } from './CumulativeFee';
import { TreatmentNeedHPC } from './TreatmentNeedHPC';
import { SeqNumber } from './SeqNumber';
import { Prescription } from './Prescription';
import { Inoculate } from './Inoculate';
import { OrganDonate } from './OrganDonate';
import { EmergentTel } from './EmergentTel';
import { LastSeqNum } from './LastSeqNum';
import { DocIdData } from './DocIdData';
import { CSHISResult } from './CSHISResult';
import { CriticalIllnessData } from './CriticalIllnessData';
import { SeqNumber256 } from './SeqNumber256';
import { RegisterBasic2 } from './RegisterBasic2';
import { PrescriptionMain } from './PrescriptionMain';
import { PrescriptionLongTerm } from './PrescriptionLongTerm';
import { PrescriptionHVE } from './PrescriptionHVE';
import { PrescriptionAllergic } from './PrescriptionAllergic';
import { SeqNumber256N1 } from './SeqNumber256N1';
import { TreatNumNoICCard } from './TreatNumNoICCard';
import { UploadDataResult } from './UploadDataResult';
import { UploadDataPrecResult } from './UploadDataPrecResult';
import { IVisionHIS } from './IVisionHis';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { PatientApi } from '../../services/api-service/patient/patient-api';
import { IPatient, PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { CnhiEiiApiID } from './Download';
import { VPNGetRandomXandSignX } from './VPNGetRandomXandSignX';
import { HcaInitData,HCASignRequestData } from './HcaInitData';
import { DateHelper } from '../helpers/date-helper';
import { User } from 'src/app/system/user/models/user';
import { CriticalIllnesNoHPC } from './CriticalIllnesNoHPC';
import { TreatmentDataNoHPC } from './TreatmentDataNoHPC';
/**
    *
    */
export class VisionHisDemo implements IVisionHIS {

    pat: IPatient;
    clinic: Clinic;
    doctor: User;

    constructor(private patientApi: PatientApi) {

    }
    setCurrentPatient(pat: IPatient) {
        this.pat = pat;
    }
    setDoctor(doctor: User) {
        this.doctor = doctor;
    }
    setClinic(clinic: Clinic) {
        this.clinic = clinic;
    }
    GetBasicData(): Promise<CSHISResultWithData<BasicData>> {
        var ret = new CSHISResultWithData<BasicData>();
        ret.StatusCode = 0;
        ret.Data = new BasicData();
        // ret.Data.CId = this.pat.CId;
        // ret.Data.Birth = this.pat.Birthday;
        // ret.Data.Name = this.pat.CName;
        // ret.Data.Sex = this.pat.Sex == 0 ? 'F' : 'M';

        ret.Data.CId = this.pat?.CId??'A123456789';
        ret.Data.Birth = this.pat?.Birthday??new Date(2020,1,1);
        ret.Data.Name = this.pat?.CName??'吳此人';
        ret.Data.Sex = (this.pat?.Sex??1) == 0 ? 'F' : 'M';

        return Promise.resolve(ret);
    }

    /**
    * 掛號或報到時讀取基本資料
    */
    GetRegisterBasic(): Promise<CSHISResultWithData<RegisterBasic>> {
        var ret = new CSHISResultWithData<RegisterBasic>();
        ret.StatusCode = 0;
        ret.Data = new RegisterBasic();
        ret.Data.CId = this.pat?.CId??'A123456789';
        ret.Data.Birth = this.pat?.Birthday??new Date(2020,1,1);
        ret.Data.IcNo = ('90' + (this.pat?.PatientNo || '').padStart(10, '0'))
        ret.Data.Name = this.pat?.CName??'吳此人';
        ret.Data.Sex = (this.pat?.Sex??1) == 0 ? 'F' : 'M';
        ret.Data.AvailableCount = Math.ceil(Math.random() * 6).toString();
        //健保
        ret.Data.InsurerFlag = '3';
        return Promise.resolve(ret);
    }

    /**
    * 預防保健掛號作業
    */
    GetRegisterPrevent(): Promise<CSHISResultWithData<RegisterPrevent>> {
        throw 'notImplement';
    };

    /**
    * 孕婦產前檢查掛號作業
    */
    GetRegisterPregnant(): Promise<CSHISResultWithData<RegisterPregnant>> {
        throw 'notImplement';
    };

    /**
    * 讀取就醫資料不需HPC卡的部分
    */
    GetTreatmentNoNeedHPC(): Promise<CSHISResultWithData<TreatmentNoNeedHPC>> {
        var ret = new CSHISResultWithData<TreatmentNoNeedHPC>();
        ret.StatusCode = 0;
        ret.Data = {
            MajorInjury1:{
                StartDate:DateHelper.addDay(new Date(),-365),
                EndDate:DateHelper.addDay(new Date(),-30)
            },
            MajorInjury2:new CriticalIllnesNoHPC(),
            MajorInjury3:new CriticalIllnesNoHPC(),
            MajorInjury4:new CriticalIllnesNoHPC(),
            MajorInjury5:new CriticalIllnesNoHPC(),
            MajorInjury6:new CriticalIllnesNoHPC(),
            TreatmentData1: new TreatmentDataNoHPC(),
            TreatmentData2: new TreatmentDataNoHPC(),
            TreatmentData3: new TreatmentDataNoHPC(),
            TreatmentData4: new TreatmentDataNoHPC(),
            TreatmentData5: new TreatmentDataNoHPC(),
            TreatmentData6: new TreatmentDataNoHPC(),
        }
        return Promise.resolve( ret);
    };

    /**
    * 讀取就醫累計資料
    */
    GetCumulativeData(): Promise<CSHISResultWithData<CumulativeData>> {
        throw 'notImplement';
    };

    /**
    * 讀取醫療費用總累計
    */
    GetCumulativeFee(): Promise<CSHISResultWithData<CumulativeFee>> {
        throw 'notImplement';
    };
    /**
    * 讀取就醫資料需要HPC卡的部份
    */
    GetTreatmentNeedHPC(): Promise<CSHISResultWithData<TreatmentNeedHPC>> {
        throw 'notImplement';
    };

    /**
    * 取得就醫序號
    */
    GetSeqNumber(cTreatItem: string, cBabyTreat: string): Promise<CSHISResultWithData<SeqNumber>> {
        throw 'notImplement';
    };

    /**
    * 讀取處方箋作業
    */
    ReadPrescription(): Promise<CSHISResultWithData<Prescription>> {
        throw 'notImplement';
    };

    /**
    * 讀取預防接種資料
    */
    GetInoculateData(): Promise<CSHISResultWithData<Inoculate>> {
        throw 'notImplement';
    };

    /**
    * 讀取同意器官捐贈及安寧緩和醫療註記
    */
    GetOrganDonate(): Promise<CSHISResultWithData<OrganDonate>> {
        var ret = new CSHISResultWithData<OrganDonate>();
        ret.StatusCode = 0;
        ret.Data = { OrganDonateNote:'N'}
        return Promise.resolve(ret)
        //throw 'notImplement';
    };

    /**
    * 讀取緊急聯絡電話資料
    */
    GetEmergentTel(): Promise<CSHISResultWithData<EmergentTel>> {
        var ret = new CSHISResultWithData<EmergentTel>();
        ret.StatusCode = 0;
        ret.Data =  { RelationPhone:'0987654321'}
        return Promise.resolve(ret)
    };

    /**
    * 讀取最近一次就醫序號
    */
    async GetLastSeqNum(): Promise<CSHISResultWithData<LastSeqNum>> {
        var ret = new CSHISResultWithData<LastSeqNum>();
        ret.StatusCode = 0;
        ret.Data = new LastSeqNum()
        if(this.pat){
            // 取得所有病掛號
            var resp = await this.patientApi.GetLastSeqNo(this.pat.Id);
            ret.Data.LastSeqNumData = resp.Isqno || '0000';
        }else{
            ret.Data.LastSeqNumData = '0000'
        }
        return ret;
    };

    /**
    * 讀取卡片狀態(安全模組檔)
    */
    GetCardStatusSAM(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = "展示模式中";
        return Promise.resolve(ret);
    };

    /**
    * 讀取卡片狀態(健保IC卡)
    */
    GetCardStatusHC(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = "展示模式中";
        return Promise.resolve(ret);
    };

    /**
    * 讀取卡片狀態(醫事人員卡)
    */
    GetCardStatusHPC(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = "展示模式中";
        return Promise.resolve(ret);
    };

    /**
    * 就醫診療資料寫入作業
    */
    WriteTreatmentCode(data: TreatmentCode): Promise<CSHISResultWithData<DocIdData>> {
        throw 'notImplement';
    };

    /**
    * 就醫費用資料寫入作業
    */
    WriteTreatmentFee(data: TreatmentFee): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 處方箋寫入作業<br />  此函式一次寫入一組門診處方箋，若有 n 組處方箋需要寫入，則需呼叫此函式 n 次
    */
    WritePrescription(data: OutPatientPrescriptionWrite): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 新生兒註記寫入作業
    */
    WriteNewBorn(data: NewBorn): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 過敏藥物寫入作業
    */
    WriteAllergicMedicines(data: AllergicMedicines): Promise<CSHISResultWithData<DocIdData>> {
        throw 'notImplement';
    };

    /**
    * 同意器官捐贈及安寧緩和醫療註記寫入作業
    */
    WriteOrganDonate(data: OrganDonateWrite): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 預防保健資料寫入作業
    */
    WriteHealthInsurance(data: HealthInsurance): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 緊急聯絡電話資料寫入作業
    */
    WriteEmergentTel(data: EmergentTelWrite): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 寫入產前檢查資料
    */
    WritePredeliveryCheckup(data: PredeliveryCheckup): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 清除產前檢查資料
    */
    DeletePredeliveryData(data: PatientBaseInput): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 預防接種資料寫入作業
    */
    WriteInoculateData(data: InoculateDataWrite): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 驗證健保IC卡之PIN值
    */
    VerifyHCPIN(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 呼叫此函式後，DLL 端自行 pop-up 視窗，使用者可於讀卡機 Keypad輸入兩次 pin 碼<br />  如原先之健保卡 Pin 碼輸入功能設為停用，經呼叫此函式成功後，即啟動健保卡之 Pin 碼輸入功能。<br />  如欲變更 Pin 碼，需先呼叫 csVerifyHCPIN 輸入舊 Pin 碼成功後，再呼叫csInputHCPIN 輸入兩次新 Pin 碼。
    */
    InputHCPIN(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };
    /**
    * 停用健保IC卡之Pin碼輸入功能
    */
    DisableHCPIN(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * (健保IC卡卡片內容更新作業)  呼叫本函數後， CS 會自動更新下列欄位內容：  「卡片註銷註記」(HC 基本 8)<br />  「保險人代碼」(HC 健 1)<br />  「保險對象身分註記」(HC 健 2)<br />  「卡片有效期限」(HC 健 3)<br />  「重大傷病註記」第一組(HC 健 4)<br />  「重大傷病註記」第二組(HC 健 4)<br />  「重大傷病註記」第三組(HC 健 4)<br />  「重大傷病註記」第四組(HC 健 4)<br />  「重大傷病註記」第五組(HC 健 4)<br />  「重大傷病註記」第六組(HC 健 4)<br />  「就醫可用次數」(HC 健 5)<br />  「最近一次就醫序號」(HC 健 6)<br />  「就醫累計資料」第一組(HC 健 9)<br />  「就醫累計資料」第二組(HC 健 9)<br />  「Pin 碼的狀態」(enable 或 disable
    */
    UpdateHCContents(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 開啟讀卡機連結埠<br />  呼叫 cshis.dll 內之任何一函數前，必須先呼叫此一函數確認讀卡機連接成功後，才可再呼叫其他函數
    */
    OpenCom(comport: number): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 關閉讀卡機連結埠<br />  結束所有讀卡機作業時需呼叫此一函數， 將先前開啟之讀卡機連結埠關閉
    */
    CloseCom(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 讀取重大傷病註記資料
    */
    GetCriticalIllness(): Promise<CSHISResultWithData<CriticalIllnessData>> {
        var ret = new CSHISResultWithData<CriticalIllnessData>();
        ret.StatusCode = 0;
        ret.Data = {
            dentistMark:'',
            rareDisease:'',
            restrictionTreatment:'',
            rfu:'',
            yuchengDisease:'',
            CriticalIllness1:{Code:'',StartDate:null,EndDate:null},
            CriticalIllness2:{Code:'',StartDate:null,EndDate:null},
            CriticalIllness3:{Code:'',StartDate:null,EndDate:null},
            CriticalIllness4:{Code:'',StartDate:null,EndDate:null},
            CriticalIllness5:{Code:'',StartDate:null,EndDate:null},
            CriticalIllness6:{Code:'',StartDate:null,EndDate:null},
        }
        return Promise.resolve(ret);
    };

    /**
    * 讀取讀卡機日期時間(民國年)
    */
    GetDateTime(): Promise<CSHISResultWithData<Date>> {
        throw 'notImplement';
    };
    /**
    * 讀取卡片號碼(SAM)
    */
    GetCardNoSAM(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = "展示模式中";
        return Promise.resolve(ret);
    };
    /**
    * 讀取卡片號碼(健保IC卡)
    */
    GetCardNoHC(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = "展示模式中";
        return Promise.resolve(ret);
    };

    /**
    * 讀取卡片號碼(醫事人員卡)
    */
    GetCardNoHPC(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = "展示模式中";
        return Promise.resolve(ret);
    };

    /**
    * 檢查健保IC卡是否設定密碼  ///
    */
    ISSetPIN(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 取得就醫序號新版
    */
    async GetSeqNumber256(args: GetSeqNumber256): Promise<CSHISResultWithData<SeqNumber256>> {
        var ret = new CSHISResultWithData<SeqNumber256>();
        ret.StatusCode = 0;
        ret.Data = new SeqNumber256()
        var year = new Date().getFullYear() - 1911;
        if(this.pat){
            var rs = await this.patientApi.GetRegister(null, this.pat.Id);
            var nhiICNos = rs.filter(r => r.Nhiyear == year.toString()).map(r => parseInt(r.Icno));
            if (nhiICNos.length == 0) {
                ret.Data.Number = year.toString().padStart(0, '0') + '0000';
                return ret;
            } else {
                var maxNo: number = Math.max.call(this, nhiICNos);
                ret.Data.Number = year.toString().padStart(0, '0') + (maxNo + 1).toString().padStart(4, '0');
                return ret;
            }
        }else{
            ret.Data.Number = year.toString().padStart(0, '0') + '0000';
                return ret;
        }
        
    };

    /**
    * 掛號或報到時讀取基本資料，且不檢查該 HC 卡是否有設定 PIN 保護資料隱私
    */
    GetRegisterBasic2(): Promise<CSHISResultWithData<RegisterBasic2>> {
        throw 'notImplement';
    };

    /**
    * 回復就醫資料累計值---退掛
    */
    UnGetSeqNumber(pUnTreatDate: string): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 健保IC卡卡片內容更新作業<br />  此功能和UpdateHCContents完全相同，唯一的差異處在於本函式在完成更新健保卡後，  不對健保卡做Reset動作，故持卡人需將卡片移出再重新插入讀卡機，才能繼續進行其他  作業。<br />  UpdateHCContents 則於完成更新後，自動呼叫 Reset 指令，因此健保 IC 卡完成更新後  ，不需拔出卡片即可以直接查詢卡片內資料或進行掛號作業。
    */
    UpdateHCNoReset(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 讀取就醫資料-門診處方箋
    */
    ReadPrescriptMain(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionMain>> {
        throw 'notImplement';
    };

    /**
    * 讀取就醫資料-長期處方箋
    */
    ReadPrescriptLongTerm(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionLongTerm>> {
        throw 'notImplement';
    };

    /**
    * 讀取就醫資料-重要醫令
    */
    ReadPrescriptHVE(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionHVE>> {
        throw 'notImplement';
    };
    /**
    * 讀取就醫資料-過敏藥物
    */
    ReadPrescriptAllergic(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionAllergic>> {
        throw 'notImplement';
    };


    /**
    * 多筆處方箋寫入作業
    */
    WriteMultiPrescript(data: MultiPrescriptWrite): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };
    /**
    * 過敏藥物寫入指定欄位作業
    */
    WriteAllergicNum(data: AllergicNum): Promise<CSHISResultWithData<DocIdData>> {
        throw 'notImplement';
    };
    /**
    * 就醫診療資料及費用寫入作業
    */
    WriteTreatmentData(data: TreatmentData): Promise<CSHISResultWithData<DocIdData>> {
        var ret = new CSHISResultWithData<DocIdData>();
        ret.StatusCode = 0;
        ret.Data = {DocId:'DEMO456789'};
        return Promise.resolve(ret);
    };

    /**
    * 處方箋寫入作業-回傳簽章
    */
    WritePrescriptionSign(data: PrescriptionSignWrite): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        var a = new Array(40);
        ret.Data = a.map(x => '0').join() + "(展示模式中)";
        return Promise.resolve(ret);
    };

    /**
    * 多筆處方箋寫入作業-回傳簽章
    */
    WriteMultiPrescriptSign(data: PrescriptionSignMultiWrite): Promise<CSHISResultWithData<string[]>> {
        var ret = new CSHISResultWithData<string[]>();
        ret.StatusCode = 0;
        ret.Data = data.Prescriptions.map(x=>'demoIsign');
        return Promise.resolve(ret);
    };

    /**
    * 讀取重大傷病註記資料身分比對  本函式與 hisGetCriticalIllness 功能相同，但呼叫時要傳入 ID 和  birthday，  確認和 HC 卡為同一人時才可讀取重大傷病資料，HIS可和 hisGetCriticalIllness 擇一使用
    */
    GetCriticalIllnessID(data: PatientBaseInput): Promise<CSHISResultWithData<CriticalIllnessData>> {
        throw 'notImplement';
    };

    /**
    * 取得控制軟體版本
    */
    GetVersionEx(): Promise<CSHISResultWithData<number>> {
        var ret = new CSHISResultWithData<number>();
        ret.StatusCode = 0;

        ret.Data = -1;
        return Promise.resolve(ret);
    };

    /**
    * 提供His重置讀卡機或卡片的API (讀卡機)
    */
    SoftwareResetCR(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };
    /**
    * 提供His重置讀卡機或卡片的API (安全模組)
    */
    SoftwareResetSAM(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 提供His重置讀卡機或卡片的API (醫事人員卡)
    */
    SoftwareResetHPC(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 提供His重置讀卡機或卡片的API (健保卡)
    */
    SoftwareResetHC(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 取得就醫序號新版-就醫識別碼
    */
    GetSeqNumber256N1(args: GetSeqNumber256): Promise<CSHISResultWithData<SeqNumber256N1>> {

        return Promise.resolve({
            ErrorMsg: '',
            StatusCode: 0,
            Data: {

                'ClinicCode': this.clinic.NHICode,
                'ClinicDateTime': new Date(),
                'Number': '',
                'SamId': '123456789ABC',
                'SameDay': 'N',
                'SecuritySeal': '',//'2CF269A1CC0735AB49F75DA1EBE7367C0CC78BC9576A31EEF94124C4C1D0F9E19FF14904FCE0F8EB57F727DF4C5F8B03888DFC6800BA9D132EC2E9D11614A8C6D802509B47293C559FDCB166C0CC70282B1FF1C60196E2772B278281EF15507E81EF72422DD2CA5F4D6B025153E7674D04B9527544AB63F29EB274E389EC5557'
                'TreatId': this.getTreatId()
            }
        })

    };

    private getITreatString(n: number) {
        var char = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
        var calcTemp = Math.floor(n);
        var treatIdBuild = ''
        do {
            var a = calcTemp % char.length;
            treatIdBuild = char[a] + treatIdBuild;
            calcTemp = Math.floor(calcTemp / char.length)
        }
        while (calcTemp > 0)
        return treatIdBuild;
    }
    private getTreatId() {
        var c1 = this.getITreatString(parseInt(this.clinic.NHICode || '7777777777'));
        var cid = this.pat?.CId??'A123456789'
        var code = (cid.charCodeAt(0) - 'A'.charCodeAt(0) + 10) + cid.substring(1, 8)
        var c2 = this.getITreatString(parseInt(code));
        // 取得最後時間5碼
        var now = new Date();
        var secDiff = (now.getTime() - new Date(2023, 0, 1, 0, 0, 0).getTime()) / 1000
        var c3 = this.getITreatString(secDiff);
        // '4S8TMPY126T5Q'
        return c1 + c2 + 'J0' + c3;
    }
    /**
    * 取得就醫識別碼
    */
    GetTreatNumNoICCard(patientId: string, hospCode: string): Promise<CSHISResultWithData<TreatNumNoICCard>> {
        return Promise.resolve({
            ErrorMsg: '',
            StatusCode: 0,
            Data: {
                'HospCode': this.clinic.NHICode,
                'DateTime': new Date(),
                'TreatNum': this.getTreatId()
            }
        })
    };

    /**
    * 在保狀態查核
    */
    QuickInsurence(cTreatItem: string): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 單獨取得就醫識別碼  要插入健保卡，可配合既有 HIS 運作
    */
    public hisGetTreatNumICCard(pDateTime: string): Promise<CSHISResultWithData<string>> {
        return Promise.resolve({
            Data: '',
            ErrorMsg: '',
            StatusCode: 0
        });
    }
    /**
    * SAM與DC認證
    */
    VerifySAMDC(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 讀取SAM院所代碼
    */
    GetHospID(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = this.clinic.NHICode;
        return Promise.resolve(ret);
    };

    /**
    * 讀取SAM院所名稱
    */
    GetHospName(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = this.clinic.Name;
        return Promise.resolve(ret);
    };
    /**
    * 讀取SAM院所簡稱
    */
    GetHospAbbName(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = this.clinic.Name;
        return Promise.resolve(ret);
    };

    /**
    * 資料上傳
    */
    UploadData(pUploadFileName: string, pNumber: string): Promise<CSHISResultWithData<UploadDataResult>> {
        throw 'notImplement';
    };

    /**
    * (資料上傳) 增加「處方筆數」參數
    */
    UploadDataPrec(pUploadFileName: string, pNumber: string, pPrecNumber: string): Promise<CSHISResultWithData<UploadDataPrecResult>> {
        throw 'notImplement';
    };


    /**
    * 檢核錯誤報表資料自動下載
    */
    DownloadData(args: DownloadDataArg): Promise<CSHISResultWithData<string>> {
        throw 'notImplement';
    };

    /**
    * 取得醫事人員卡狀態
    */
    GetHPCStatus(): Promise<CSHISResultWithData<boolean>> {
        var ret = new CSHISResultWithData<boolean>();
        ret.StatusCode = 0;
        ret.Data = true;
        return Promise.resolve(ret);
    };

    /**
    * 檢查醫事人員卡之PIN值
    */
    VerifyHPCPIN(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };
    /**
    * 輸入新的醫事人員卡之PIN值
    */
    InputHPCPIN(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };
    /**
    * 解開鎖住的醫事人員卡
    */
    UnlockHPC(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 取得醫事人員卡序號
    */
    GetHPCSN(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = new Array(10).map(x => '0').join();
        return Promise.resolve(ret);
    };
    /**
    * 取得醫事人員卡身分證字號
    */
    GetHPCSSN(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = this.doctor.CId;
        return Promise.resolve(ret);
    };

    /**
    * 取得醫事人員卡中文姓名
    */
    GetHPCCNAME(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = this.doctor.Name;
        return Promise.resolve(ret);
    };

    /**
    * 取得醫事人員卡英文姓名
    */
    GetHPCENAME(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = this.doctor.Name;
        return Promise.resolve(ret);
    };

    /**
    * 虛擬醫師卡登出
    */
    VHPCLogout(): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    };

    /**
    * 進行疾病診斷碼押碼
    */
    GetICD10EnC(code: string): Promise<CSHISResultWithData<string>> {
        throw 'notImplement';
    };

    /**
    * 進行疾病診斷碼解押碼
    */
    GetICD10DeC(encoded: string): Promise<CSHISResultWithData<string>> {
        throw 'notImplement';
    };

    /**
    * 取得API版本
    */
    GetAPIVersion(): Promise<CSHISResultWithData<string>> {
        var ret = new CSHISResultWithData<string>();
        ret.StatusCode = 0;
        ret.Data = "展示模式中";
        return Promise.resolve(ret);
    };
    /** CNHIEii 資料上傳
        * @param port 整數值，健保讀卡機連接之通訊連接埠編號，起始值為0
        * @param typeCode 資料類型
        * - "03"：醫費申報資料 XML 格式
        * - "05"：預檢醫費申報資料 XML 格式
        * - "07"：醫療費用電子申復資料
        * - "09"：預檢醫療費用電子申復資料
        * - "26"：檢驗(查)每日上傳資料 XML 格式
        * @param filePath 上傳作業檔案，為完整路徑及檔名，例：C:\AP\3501200000110990913B.zip
        * @example  CNHIEiiSend(0,"03","C:\\AP\\3501200000110990913B.zip")
        */
    CNHIEiiSend(port: number, typeCode: string, filePath: string): Promise<CSHISResultWithData<CnhiEiiApiID>> {
        var ret = new CSHISResultWithData<CnhiEiiApiID>();
        ret.StatusCode = 0;
        ret.Data = {'LocalID':'DemoLocalId','NHIID':'DemoNHIID'};
        return Promise.resolve(ret);
    }
    /** CNHIEii 提出醫療資料下載申請 
     * @param port 整數值，健保讀卡機連接之通訊連接埠編號，起始值為0
     * @param typeCode 資料類型
     * - "03"：醫費申報資料檢核結果回饋資料
     * - "04"：電子轉診資料下載_回復電子轉診單
     * - "05"：預檢醫費申報資料檢核結果回饋資料
     * - "06"：抽樣回饋資料
     * - "07"：醫療費用電子申復資料檢核結果回饋資料
     * - "08"：健保醫療資訊雲端查詢批次下載作業
     * - "09"：預檢醫療費用電子申復資料檢核結果回饋資料
     * - "10"：電子轉診資料下載_開立電子轉診單
     * - "26"：檢驗(查)每日上傳資料 XML 格式檢核結果回饋資料
     * - "27"：電子轉診資料下載申請檔_管理作業 csv 下載
     * - "31"：健保醫療資訊雲端查詢批次下載作業(新格式)
     * - "33"：居家輕量藍牙就醫資料下載
     * @param filePath 下載作業需求檔案，檔案內容格式請依照「下載檔案種類」要求，為完整路徑及檔名，
     * @returns CnhiEiiApiID 作業辨識碼
     *
     * @example CNHIEiiDownload(0,"03","C:\\AP\\3501200000-1010704-001.txt")
     * 
    */
    CNHIEiiDownload(port: number, typeCode: string, filePath: string): Promise<CSHISResultWithData<CnhiEiiApiID>> {
        var ret = new CSHISResultWithData<CnhiEiiApiID>();
        ret.StatusCode = 0;
        //湊12碼
        ret.Data = {'LocalID':'Demo_LocalId','NHIID':'Demo_NHI_ID_'};
        return Promise.resolve(ret);
    }
    /** CNHIEii 下載醫療資料
     * @param port 整數值，健保讀卡機連接之通訊連接埠編號，起始值為0
     * @param downloadPath 檔案下載存放的路徑，例：C:\Data。注意:請勿使用根目錄 C:\。
     * @param localId 本機作業辨識碼
     * @param nhiId IDC作業辨識碼
     */
    CNHIEiiGet(port: number, downloadPath: string, localId: string, nhiId: string): Promise<CSHISResult> {
        var ret = new CSHISResult();
        ret.StatusCode = 0;
        return Promise.resolve(ret);
    }
    public GetVPNGetRandomXAndSignX(cardType:string,serviceCode:string): Promise<CSHISResultWithData<VPNGetRandomXandSignX>>{
        throw 'notImplement';
    }
    public  GetHCACert(data:HcaInitData): Promise<CSHISResultWithData<string>>{
        throw 'notImplement';
    }
    public  GetHCASign(data:HCASignRequestData): Promise<CSHISResultWithData<string>>{
        throw 'notImplement';
    }
    public  InitializationAndCheckPasswd(passWord:string): Promise<CSHISResultWithData<HcaInitData>>{
        console.log('InitializationAndCheckPasswd, pwd='+passWord)
        return Promise.resolve({
            Data:{
                InitModule:0,
                InitSession:0,
                UserPassword:passWord //'UserPassword'
            },
            ErrorMsg:'',
            StatusCode:0
        });
    }
    webApiGetTreatNum(patientId:string, hospCode:string,treatDateTime:string): Promise<CSHISResultWithData<TreatNumNoICCard>>{
        var data:TreatNumNoICCard = new TreatNumNoICCard();
        
        data.DateTime = DateHelper.parseROCDateTime(treatDateTime);
        data.HospCode = hospCode,
        data.TreatNum = this.getTreatId()
        return Promise.resolve({
            ErrorMsg: '',
            StatusCode: 0,
            Data: data
        });
    }
    webApiGetTreatNumNoIC(patientId:string, hospCode:string): Promise<CSHISResultWithData<TreatNumNoICCard>>{
        var data:TreatNumNoICCard = new TreatNumNoICCard();
        
        data.DateTime = new Date();
        data.HospCode = hospCode,
        data.TreatNum = this.getTreatId()
        return Promise.resolve({
            ErrorMsg: '',
            StatusCode: 0,
            Data: data
        });
    }
    webApiUpload(xmlString: string, type: 'A1' | 'A2' | 'ZZ'):Promise<{RtnCode:string,Opcode:string}> {
        return Promise.resolve( {
        RtnCode:'',
        Opcode:''
        })
    }
    webApiDownload(opCode: string, type: 'A1' | 'A2' | 'ZZ'):Promise<{
        RtnCode:string,
        Tx_result_txt:string,
        Tx_result_json:string
    }> {
        return Promise.resolve( {
            RtnCode:'',
        Tx_result_txt:'',
        Tx_result_json:'',
            })
    }
}
