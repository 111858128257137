import { TreatmentCode } from './TreatmentCode';
import { TreatmentFee } from './TreatmentFee';
import { OutPatientPrescriptionWrite } from './OutPatientPrescriptionWrite';
import { NewBorn } from './NewBorn';
import { AllergicMedicines } from './AllergicMedicines';
import { OrganDonateWrite } from './OrganDonateWrite';
import { HealthInsurance } from './HealthInsurance';
import { EmergentTelWrite } from './EmergentTelWrite';
import { PredeliveryCheckup } from './PredeliveryCheckup';
import { PatientBaseInput } from './PatientBaseInput';
import { InoculateDataWrite } from './InoculateDataWrite';
import { GetSeqNumber256 } from './GetSeqNumber256';
import { MultiPrescriptWrite } from './MultiPrescriptWrite';
import { AllergicNum } from './AllergicNum';
import { TreatmentData } from './TreatmentData';
import { PrescriptionSignWrite } from './PrescriptionSignWrite';
import { PrescriptionSignMultiWrite } from './PrescriptionSignMultiWrite';
import { DownloadDataArg } from './DownloadDataArg';
import { CSHISResultWithData } from './CSHISResultWithData';
import { BasicData } from './BasicData';
import { RegisterBasic } from './RegisterBasic';
import { RegisterPrevent } from './RegisterPrevent';
import { RegisterPregnant } from './RegisterPregnant';
import { TreatmentNoNeedHPC } from './TreatmentNoNeedHPC';
import { CumulativeData } from './CumulativeData';
import { CumulativeFee } from './CumulativeFee';
import { TreatmentNeedHPC } from './TreatmentNeedHPC';
import { SeqNumber } from './SeqNumber';
import { Prescription } from './Prescription';
import { Inoculate } from './Inoculate';
import { OrganDonate } from './OrganDonate';
import { EmergentTel } from './EmergentTel';
import { LastSeqNum } from './LastSeqNum';
import { DocIdData } from './DocIdData';
import { CSHISResult } from './CSHISResult';
import { CriticalIllnessData } from './CriticalIllnessData';
import { SeqNumber256 } from './SeqNumber256';
import { RegisterBasic2 } from './RegisterBasic2';
import { PrescriptionMain } from './PrescriptionMain';
import { PrescriptionLongTerm } from './PrescriptionLongTerm';
import { PrescriptionHVE } from './PrescriptionHVE';
import { PrescriptionAllergic } from './PrescriptionAllergic';
import { SeqNumber256N1 } from './SeqNumber256N1';
import { TreatNumNoICCard } from './TreatNumNoICCard';
import { UploadDataResult } from './UploadDataResult';
import { UploadDataPrecResult } from './UploadDataPrecResult';

import { IVisionHIS } from './IVisionHis';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { HcrService, VisionApiResult } from 'src/app/services/hcr-service.service';
import { CnhiEiiApiID } from './Download';
import { VPNGetRandomXandSignX } from './VPNGetRandomXandSignX';
import { HCASignRequestData, HcaInitData } from './HcaInitData';
import { DateHelper } from '../helpers/date-helper';
/**
    *
    */
export class VisionHIS implements IVisionHIS {
    public GetBasicData(): Promise<CSHISResultWithData<BasicData>> {
        return this.CallCsHis("GetBasicData", {

        });
    }

    /**
    * 掛號或報到時讀取基本資料
    */
    public GetRegisterBasic(): Promise<CSHISResultWithData<RegisterBasic>> {
        return this.CallCsHis("GetRegisterBasic", {
        });
    }

    /**
    * 預防保健掛號作業
    */
    public GetRegisterPrevent(): Promise<CSHISResultWithData<RegisterPrevent>> {
        return this.CallCsHis("GetRegisterPrevent", {

        });
    }

    /**
    * 孕婦產前檢查掛號作業
    */
    public GetRegisterPregnant(): Promise<CSHISResultWithData<RegisterPregnant>> {
        return this.CallCsHis("GetRegisterPregnant", {

        });
    }

    /**
    * 讀取就醫資料不需HPC卡的部分
    */
    public GetTreatmentNoNeedHPC(): Promise<CSHISResultWithData<TreatmentNoNeedHPC>> {
        return this.CallCsHis("GetTreatmentNoNeedHPC", {

        });
    }

    /**
    * 讀取就醫累計資料
    */
    public GetCumulativeData(): Promise<CSHISResultWithData<CumulativeData>> {
        return this.CallCsHis("GetCumulativeData", {

        });
    }

    /**
    * 讀取醫療費用總累計
    */
    public GetCumulativeFee(): Promise<CSHISResultWithData<CumulativeFee>> {
        return this.CallCsHis("GetCumulativeFee", {

        });
    }

    /**
    * 讀取就醫資料需要HPC卡的部份
    */
    public GetTreatmentNeedHPC(): Promise<CSHISResultWithData<TreatmentNeedHPC>> {
        return this.CallCsHis("GetTreatmentNeedHPC", {

        });
    }

    /**
    * 取得就醫序號
    */
    public GetSeqNumber(cTreatItem: string, cBabyTreat: string): Promise<CSHISResultWithData<SeqNumber>> {
        return this.CallCsHis("GetSeqNumber", {
            cTreatItem: cTreatItem,
            cBabyTreat: cBabyTreat
        });
    }

    /**
    * 讀取處方箋作業
    */
    public ReadPrescription(): Promise<CSHISResultWithData<Prescription>> {
        return this.CallCsHis("ReadPrescription", {

        });
    }

    /**
    * 讀取預防接種資料
    */
    public GetInoculateData(): Promise<CSHISResultWithData<Inoculate>> {
        return this.CallCsHis("GetInoculateData", {

        });
    }

    /**
    * 讀取同意器官捐贈及安寧緩和醫療註記
    */
    public GetOrganDonate(): Promise<CSHISResultWithData<OrganDonate>> {
        return this.CallCsHis("GetOrganDonate", {

        });
    }

    /**
    * 讀取緊急聯絡電話資料
    */
    public GetEmergentTel(): Promise<CSHISResultWithData<EmergentTel>> {
        return this.CallCsHis("GetEmergentTel", {

        });
    }

    /**
    * 讀取最近一次就醫序號
    */
    public GetLastSeqNum(): Promise<CSHISResultWithData<LastSeqNum>> {
      return this.CallCsHis("GetLastSeqNum", {

        });
    }

    /**
    * 讀取卡片狀態(安全模組檔)
    */
    public GetCardStatusSAM(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetCardStatusSAM", {

        });
    }

    /**
    * 讀取卡片狀態(健保IC卡)
    */
    public GetCardStatusHC(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetCardStatusHC", {

        });
    }

    /**
    * 讀取卡片狀態(醫事人員卡)
    */
    public GetCardStatusHPC(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetCardStatusHPC", {

        });
    }

    /**
    * 就醫診療資料寫入作業
    */
    public WriteTreatmentCode(data: TreatmentCode): Promise<CSHISResultWithData<DocIdData>> {
        return this.CallCsHis("WriteTreatmentCode", {
            data: data
        });
    }

    /**
    * 就醫費用資料寫入作業
    */
    public WriteTreatmentFee(data: TreatmentFee): Promise<CSHISResult> {
        return this.CallCsHis("WriteTreatmentFee", {
            data: data
        });
    }

    /**
    * 處方箋寫入作業<br />  此函式一次寫入一組門診處方箋，若有 n 組處方箋需要寫入，則需呼叫此函式 n 次
    */
    public WritePrescription(data: OutPatientPrescriptionWrite): Promise<CSHISResult> {
        return this.CallCsHis("WritePrescription", {
            data: data
        });
    }

    /**
    * 新生兒註記寫入作業
    */
    public WriteNewBorn(data: NewBorn): Promise<CSHISResult> {
        return this.CallCsHis("WriteNewBorn", {
            data: data
        });
    }

    /**
    * 過敏藥物寫入作業
    */
    public WriteAllergicMedicines(data: AllergicMedicines): Promise<CSHISResultWithData<DocIdData>> {
        return this.CallCsHis("WriteAllergicMedicines", {
            data: data
        });
    }

    /**
    * 同意器官捐贈及安寧緩和醫療註記寫入作業
    */
    public WriteOrganDonate(data: OrganDonateWrite): Promise<CSHISResult> {
        return this.CallCsHis("WriteOrganDonate", {
            data: data
        });
    }

    /**
    * 預防保健資料寫入作業
    */
    public WriteHealthInsurance(data: HealthInsurance): Promise<CSHISResult> {
        return this.CallCsHis("WriteHealthInsurance", {
            data: data
        });
    }

    /**
    * 緊急聯絡電話資料寫入作業
    */
    public WriteEmergentTel(data: EmergentTelWrite): Promise<CSHISResult> {
        return this.CallCsHis("WriteEmergentTel", {
            data: data
        });
    }

    /**
    * 寫入產前檢查資料
    */
    public WritePredeliveryCheckup(data: PredeliveryCheckup): Promise<CSHISResult> {
        return this.CallCsHis("WritePredeliveryCheckup", {
            data: data
        });
    }

    /**
    * 清除產前檢查資料
    */
    public DeletePredeliveryData(data: PatientBaseInput): Promise<CSHISResult> {
        return this.CallCsHis("DeletePredeliveryData", {
            data: data
        });
    }

    /**
    * 預防接種資料寫入作業
    */
    public WriteInoculateData(data: InoculateDataWrite): Promise<CSHISResult> {
        return this.CallCsHis("WriteInoculateData", {
            data: data
        });
    }

    /**
    * 驗證健保IC卡之PIN值
    */
    public VerifyHCPIN(): Promise<CSHISResult> {
        return this.CallCsHis("VerifyHCPIN", {

        });
    }

    /**
    * 呼叫此函式後，DLL 端自行 pop-up 視窗，使用者可於讀卡機 Keypad輸入兩次 pin 碼<br />  如原先之健保卡 Pin 碼輸入功能設為停用，經呼叫此函式成功後，即啟動健保卡之 Pin 碼輸入功能。<br />  如欲變更 Pin 碼，需先呼叫 csVerifyHCPIN 輸入舊 Pin 碼成功後，再呼叫csInputHCPIN 輸入兩次新 Pin 碼。
    */
    public InputHCPIN(): Promise<CSHISResult> {
        return this.CallCsHis("InputHCPIN", {

        });
    }

    /**
    * 停用健保IC卡之Pin碼輸入功能
    */
    public DisableHCPIN(): Promise<CSHISResult> {
        return this.CallCsHis("DisableHCPIN", {

        });
    }

    /**
    * (健保IC卡卡片內容更新作業)  呼叫本函數後， CS 會自動更新下列欄位內容：  「卡片註銷註記」(HC 基本 8)<br />  「保險人代碼」(HC 健 1)<br />  「保險對象身分註記」(HC 健 2)<br />  「卡片有效期限」(HC 健 3)<br />  「重大傷病註記」第一組(HC 健 4)<br />  「重大傷病註記」第二組(HC 健 4)<br />  「重大傷病註記」第三組(HC 健 4)<br />  「重大傷病註記」第四組(HC 健 4)<br />  「重大傷病註記」第五組(HC 健 4)<br />  「重大傷病註記」第六組(HC 健 4)<br />  「就醫可用次數」(HC 健 5)<br />  「最近一次就醫序號」(HC 健 6)<br />  「就醫累計資料」第一組(HC 健 9)<br />  「就醫累計資料」第二組(HC 健 9)<br />  「Pin 碼的狀態」(enable 或 disable
    */
    public UpdateHCContents(): Promise<CSHISResult> {
        return this.CallCsHis("UpdateHCContents", {

        });
    }

    /**
    * 開啟讀卡機連結埠<br />  呼叫 cshis.dll 內之任何一函數前，必須先呼叫此一函數確認讀卡機連接成功後，才可再呼叫其他函數
    */
    public OpenCom(comport: number): Promise<CSHISResult> {
        return this.CallCsHis("OpenCom", {
            Comport: comport
        });
    }

    /**
    * 關閉讀卡機連結埠<br />  結束所有讀卡機作業時需呼叫此一函數， 將先前開啟之讀卡機連結埠關閉
    */
    public CloseCom(): Promise<CSHISResult> {
        return this.CallCsHis("CloseCom", {

        });
    }

    /**
    * 讀取重大傷病註記資料
    */
    public GetCriticalIllness(): Promise<CSHISResultWithData<CriticalIllnessData>> {
        return this.CallCsHis("GetCriticalIllness", {

        });
    }

    /**
    * 讀取讀卡機日期時間(民國年)
    */
    public GetDateTime(): Promise<CSHISResultWithData<Date>> {
        return this.CallCsHis("GetDateTime", {

        });
    }

    /**
    * 讀取卡片號碼(SAM)
    */
    public GetCardNoSAM(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetCardNoSAM", {

        });
    }

    /**
    * 讀取卡片號碼(健保IC卡)
    */
    public GetCardNoHC(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetCardNoHC", {

        });
    }

    /**
    * 讀取卡片號碼(醫事人員卡)
    */
    public GetCardNoHPC(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetCardNoHPC", {

        });
    }

    /**
    * 檢查健保IC卡是否設定密碼  ///
    */
    public ISSetPIN(): Promise<CSHISResult> {
        return this.CallCsHis("ISSetPIN", {

        });
    }

    /**
    * 取得就醫序號新版
    */
    public GetSeqNumber256(args: GetSeqNumber256): Promise<CSHISResultWithData<SeqNumber256>> {
        return this.CallCsHis("GetSeqNumber256", {
            args: args
        });
    }

    /**
    * 掛號或報到時讀取基本資料，且不檢查該 HC 卡是否有設定 PIN 保護資料隱私
    */
    public GetRegisterBasic2(): Promise<CSHISResultWithData<RegisterBasic2>> {
        return this.CallCsHis("GetRegisterBasic2", {

        });
    }

    /**
    * 回復就醫資料累計值---退掛
    */
    public UnGetSeqNumber(pUnTreatDate: string): Promise<CSHISResult> {
        return this.CallCsHis("UnGetSeqNumber", {
            pUnTreatDate: pUnTreatDate
        });
    }

    /**
    * 健保IC卡卡片內容更新作業<br />  此功能和UpdateHCContents完全相同，唯一的差異處在於本函式在完成更新健保卡後，  不對健保卡做Reset動作，故持卡人需將卡片移出再重新插入讀卡機，才能繼續進行其他  作業。<br />  UpdateHCContents 則於完成更新後，自動呼叫 Reset 指令，因此健保 IC 卡完成更新後  ，不需拔出卡片即可以直接查詢卡片內資料或進行掛號作業。
    */
    public UpdateHCNoReset(): Promise<CSHISResult> {
        return this.CallCsHis("UpdateHCNoReset", {

        });
    }

    /**
    * 讀取就醫資料-門診處方箋
    */
    public ReadPrescriptMain(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionMain>> {
        return this.CallCsHis("ReadPrescriptMain", {
            iStartPos: iStartPos,
            iEndPos: iEndPos,
            iRecCount: iRecCount
        });
    }

    /**
    * 讀取就醫資料-長期處方箋
    */
    public ReadPrescriptLongTerm(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionLongTerm>> {
        return this.CallCsHis("ReadPrescriptLongTerm", {
            iStartPos: iStartPos,
            iEndPos: iEndPos,
            iRecCount: iRecCount
        });
    }

    /**
    * 讀取就醫資料-重要醫令
    */
    public ReadPrescriptHVE(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionHVE>> {
        return this.CallCsHis("ReadPrescriptHVE", {
            iStartPos: iStartPos,
            iEndPos: iEndPos,
            iRecCount: iRecCount
        });
    }

    /**
    * 讀取就醫資料-過敏藥物
    */
    public ReadPrescriptAllergic(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionAllergic>> {
        return this.CallCsHis("ReadPrescriptAllergic", {
            iStartPos: iStartPos,
            iEndPos: iEndPos,
            iRecCount: iRecCount
        });
    }

    /**
    * 多筆處方箋寫入作業
    */
    public WriteMultiPrescript(data: MultiPrescriptWrite): Promise<CSHISResult> {
        return this.CallCsHis("WriteMultiPrescript", {
            data: data
        });
    }

    /**
    * 過敏藥物寫入指定欄位作業
    */
    public WriteAllergicNum(data: AllergicNum): Promise<CSHISResultWithData<DocIdData>> {
        return this.CallCsHis("WriteAllergicNum", {
            data: data
        });
    }

    /**
    * 就醫診療資料及費用寫入作業
    */
    public WriteTreatmentData(data: TreatmentData): Promise<CSHISResultWithData<DocIdData>> {
        return this.CallCsHis("WriteTreatmentData", {
            data: data
        });
    }

    /**
    * 處方箋寫入作業-回傳簽章
    */
    public WritePrescriptionSign(data: PrescriptionSignWrite): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("WritePrescriptionSign", {
            data: data
        });
    }

    /**
    * 多筆處方箋寫入作業-回傳簽章
    */
    public WriteMultiPrescriptSign(data: PrescriptionSignMultiWrite): Promise<CSHISResultWithData<string[]>> {
        return this.CallCsHis("WriteMultiPrescriptSign", {
            data: data
        });
    }

    /**
    * 讀取重大傷病註記資料身分比對  本函式與 hisGetCriticalIllness 功能相同，但呼叫時要傳入 ID 和  birthday，  確認和 HC 卡為同一人時才可讀取重大傷病資料，HIS可和 hisGetCriticalIllness 擇一使用
    */
    public GetCriticalIllnessID(data: PatientBaseInput): Promise<CSHISResultWithData<CriticalIllnessData>> {
        return this.CallCsHis("GetCriticalIllnessID", {
            data: data
        });
    }

    /**
    * 取得控制軟體版本
    */
    public GetVersionEx(): Promise<CSHISResultWithData<number>> {
        return this.CallCsHis("GetVersionEx", {
            path:null
        });
    }

    /**
    * 提供His重置讀卡機或卡片的API (讀卡機)
    */
    public SoftwareResetCR(): Promise<CSHISResult> {
        return this.CallCsHis("SoftwareResetCR", {
        });
    }

    /**
    * 提供His重置讀卡機或卡片的API (安全模組)
    */
    public SoftwareResetSAM(): Promise<CSHISResult> {
        return this.CallCsHis("SoftwareResetSAM", {
        });
    }

    /**
    * 提供His重置讀卡機或卡片的API (醫事人員卡)
    */
    public SoftwareResetHPC(): Promise<CSHISResult> {
        return this.CallCsHis("SoftwareResetHPC", {
        });
    }

    /**
    * 提供His重置讀卡機或卡片的API (健保卡)
    */
    public SoftwareResetHC(): Promise<CSHISResult> {
        return this.CallCsHis("SoftwareResetHC", {
        });
    }

    /**
    * 取得就醫序號新版-就醫識別碼
    */
    public GetSeqNumber256N1(args: GetSeqNumber256): Promise<CSHISResultWithData<SeqNumber256N1>> {
        return this.CallCsHis("GetSeqNumber256N1", {
            args: args
        });
    }

    /**
    * 取得就醫識別碼
    */
    public async GetTreatNumNoICCard(patientId: string, hospCode: string): Promise<CSHISResultWithData<TreatNumNoICCard>> {
        
        return this.CallCsHis("GetTreatNumNoICCard", {
            patientId: patientId,
            hospCode: hospCode
        });
    }

    /**
    * 在保狀態查核
    */
    public QuickInsurence(cTreatItem: string): Promise<CSHISResult> {
        return this.CallCsHis("QuickInsurence", {
            cTreatItem: cTreatItem
        });
    }

    /**
    * 單獨取得就醫識別碼  要插入健保卡，可配合既有 HIS 運作
    */ 
    public hisGetTreatNumICCard(pDateTime: string ) :Promise<CSHISResultWithData<string>>{
        
        return this.CallCsHis("hisGetTreatNumICCard", {
            pDateTime: pDateTime 
        });
    }
    /** 還卡取得就醫識別碼 */
    public async webApiGetTreatNum(patientId:string, hospCode:string,treatDateTime:string): Promise<CSHISResultWithData<TreatNumNoICCard>>{
        var samId = await this.hcrService.VisionApi.GetCardNoSAM();
        //11:Encode,12:Encode測試卡, 15 IC, 17 NoIC... 只有11有成功
        var ra =  await this.hcrService.VisionApi.GetVPNGetRandomXAndSignX('3','15');
        //let url = 'https://medvpndti.nhi.gov.tw/cshis/V1000/hisGetTreatNumNoICCard2';   //17
        let url = 'https://medvpndti.nhi.gov.tw/cshis/V1000/hisGetTreatNumNoICCard';  //15
        //let url = 'https://medvpndti.nhi.gov.tw/TreatNum/V1000/Encode';               //11
        var ret = await this.hcrService.sendMsg({ Call: 'SendFromUrl', Args: { Url: url, Content: JSON.stringify({
            sSamid:samId.Data,
            sHospId:hospCode,
            sClientrandom:ra.Data.RandomX,
            sSignature:ra.Data.SignX,
            sPatientId:patientId,
            sTreatDT:treatDateTime
            })
        }});
        var data = null;
        var erMsg = '';
        var rtnCode = 0
        if(!ret.Successed){
            try{
                var body = JSON.parse(ret.Returns.responseBody);
                rtnCode = ret.Returns.StatusCode;
                console.log(body);
                try{
                    for(let k in body.errors){
                        erMsg += body.errors[k]+";";
                    }
                }catch(ex){
                    erMsg = 'API呼叫失敗';
                }
            }catch(e){
                erMsg = ret.Returns;
                rtnCode = -9999;
            }
        }else{
            var body = JSON.parse(ret.Returns);
            if(body.RtnCode!="0000"){
                erMsg = rtnCode.toString();
                rtnCode =parseInt(body.RtnCode);
            }else{
                data = new TreatNumNoICCard();
                data.DateTime = DateHelper.parseROCDateTime(body.Model.TreatDT);
                data.HospCode = body.Model.HospId;
                data.TreatNum = body.Model.TreatNum;
            }
        }
        return {
            'Data':data,
            'ErrorMsg':erMsg,
            'StatusCode': rtnCode
        }
    }
    /** 欠卡/異常掛號取得就醫識別碼 */
    public async webApiGetTreatNumNoIC(patientId:string, hospCode:string): Promise<CSHISResultWithData<TreatNumNoICCard>>{
        var samId = await this.hcrService.VisionApi.GetCardNoSAM();
        //11:Encode,12:Encode測試卡, 15 IC, 17 NoIC... 只有11有成功
        var ra =  await this.hcrService.VisionApi.GetVPNGetRandomXAndSignX('3','17');
        let url = 'https://medvpndti.nhi.gov.tw/cshis/V1000/hisGetTreatNumNoICCard2';   //17
        var ret = await this.hcrService.sendMsg({ Call: 'SendFromUrl', Args: { Url: url, Content: JSON.stringify({
            sSamid:samId.Data,
            sHospId:hospCode,
            sClientrandom:ra.Data.RandomX,
            sSignature:ra.Data.SignX,
            sPatientId:patientId
            })
        }});
        var data:TreatNumNoICCard = null;
        var erMsg = '';
        var rtnCode = 0;
        if(!ret.Successed){
            try{
                var body = JSON.parse(ret.Returns.responseBody);
                rtnCode = ret.Returns.StatusCode;
                console.log(body);
                try{
                    for(let k in body.errors){
                        erMsg += body.errors[k]+";";
                    }
                }catch(ex){
                    erMsg = 'API呼叫失敗';
                }
            }catch(e){
                erMsg = ret.Returns;
                rtnCode = -9999;
            }
        }else{
            var body = JSON.parse(ret.Returns);
            if(body.RtnCode!="0000"){
                erMsg = rtnCode.toString();
                rtnCode =parseInt(body.RtnCode);
            }else{
                data = new TreatNumNoICCard();
                data.DateTime = DateHelper.parseROCDateTime(body.Model.TreatDT);
                data.HospCode = body.Model.HospId;
                data.TreatNum = body.Model.TreatNum;
            }
        }
        return {
            'Data':data,
            'ErrorMsg':erMsg,
            'StatusCode': rtnCode
        }
    }
    async webApiUpload(xmlString: string, type: 'A1' | 'A2' | 'ZZ'):Promise<{RtnCode:string,Opcode:string}> {
        var ra =   await this.GetVPNGetRandomXAndSignX('3','30');
        var getHospID = await this.GetHospID();
        var hospId = getHospID.Data;
        var getSamid = await this.GetCardNoSAM();
        var samid = getSamid.Data;
        //  Base64 Encode btoa ; DeCode: atob
        let base64EncodedString = btoa(xmlString);
        let now = new Date();
        let timestamp =DateHelper.formatFullDateTime(now);
        var input = {
            sSamid:samid,
            sHospid:hospId,
            sClientrandom:ra.Data.RandomX,
            sSignature:ra.Data.SignX,
            sPatData:base64EncodedString,
            sType:type,
            sUploadDT:timestamp,
        }
        let jsonContent = JSON.stringify(input)
        let url = 'https://medvpndti.nhi.gov.tw/V1000/VNHI_Upload';
        var ret = await this.CallCsHis('SendFromUrl', { Url: url, Content: jsonContent } );
        return JSON.parse(ret);
    }
    async webApiDownload(opCode: string, type: 'A1' | 'A2' | 'ZZ'):Promise<{
        RtnCode:string,
        Tx_result_txt:string,
        Tx_result_json:string
    }> {
        var ra =   await this.GetVPNGetRandomXAndSignX('3','30');
        var getHospID = await this.GetHospID();
        var hospId = getHospID.Data;
        var getSamid = await this.GetCardNoSAM();
        var samid = getSamid.Data;
    
        var request = {
            sSamid: samid,
            sHospid:hospId,
            sClientrandom:ra.Data.RandomX,
            sSignature: ra.Data.SignX,
            sType: type,
            sOpcode:opCode,
        }

        let jsonContent = JSON.stringify(request)

        let url = 'https://medvpndti.nhi.gov.tw/V1000/VNHI_Download';
        var j = await this.CallCsHis('SendFromUrl', { Url: url, Content: jsonContent } );
        var ret:{
            RtnCode:string,
            Tx_result_txt:string,
            Tx_result_json:string
        } = JSON.parse(j)
        if(ret.RtnCode=='0000'){
            ret.Tx_result_json = atob(ret.Tx_result_json)
            ret.Tx_result_txt = atob(ret.Tx_result_txt)
        }
        return ret;
    }
    /**
    * SAM與DC認證
    */
    public VerifySAMDC(): Promise<CSHISResult> {
        return this.CallCsHis("VerifySAMDC", {

        });
    }

    /**
    * 讀取SAM院所代碼
    */
    public GetHospID(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetHospID", {

        });
    }

    /**
    * 讀取SAM院所名稱
    */
    public GetHospName(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetHospName", {

        });
    }

    /**
    * 讀取SAM院所簡稱
    */
    public GetHospAbbName(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetHospAbbName", {

        });
    }

    /**
    * 資料上傳
    */
    public UploadData(pUploadFileName: string, pNumber: string): Promise<CSHISResultWithData<UploadDataResult>> {
        return this.CallCsHis("UploadData", {
            pUploadFileName: pUploadFileName,
            pNumber: pNumber
        });
    }

    /**
    * (資料上傳) 增加「處方筆數」參數
    */
    public UploadDataPrec(pUploadFileName: string, pNumber: string, pPrecNumber: string): Promise<CSHISResultWithData<UploadDataPrecResult>> {
        return this.CallCsHis("UploadDataPrec", {
            pUploadFileName: pUploadFileName,
            pNumber: pNumber,
            pPrecNumber: pPrecNumber
        });
    }

    /**
    * 檢核錯誤報表資料自動下載
    */
    public DownloadData(args: DownloadDataArg): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("DownloadData", {
            args: args
        });
    }

    /**
    * 取得醫事人員卡狀態
    */
    public GetHPCStatus(): Promise<CSHISResultWithData<boolean>> {
        return this.CallCsHis("GetHPCStatus", {

        });
    }

    /**
    * 檢查醫事人員卡之PIN值
    */
    public VerifyHPCPIN(): Promise<CSHISResult> {
        return this.CallCsHis("VerifyHPCPIN", {

        });
    }

    /**
    * 輸入新的醫事人員卡之PIN值
    */
    public InputHPCPIN(): Promise<CSHISResult> {
        return this.CallCsHis("InputHPCPIN", {

        });
    }

    /**
    * 解開鎖住的醫事人員卡
    */
    public UnlockHPC(): Promise<CSHISResult> {
        return this.CallCsHis("UnlockHPC", {

        });
    }

    /**
    * 取得醫事人員卡序號
    */
    public GetHPCSN(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetHPCSN", {

        });
    }

    /**
    * 取得醫事人員卡身分證字號
    */
    public GetHPCSSN(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetHPCSSN", {

        });
    }

    /**
    * 取得醫事人員卡中文姓名
    */
    public GetHPCCNAME(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetHPCCNAME", {

        });
    }

    /**
    * 取得醫事人員卡英文姓名
    */
    public GetHPCENAME(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetHPCENAME", {

        });
    }

    /**
    * 虛擬醫師卡登出
    */
    public VHPCLogout(): Promise<CSHISResult> {
        return this.CallCsHis("VHPCLogout", {

        });
    }

    /**
    * 進行疾病診斷碼押碼
    */
    public GetICD10EnC(code: string): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetICD10EnC", {
            code: code
        });
    }

    /**
    * 進行疾病診斷碼解押碼
    */
    public GetICD10DeC(encoded: string): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetICD10DeC", {
            encoded: encoded
        });
    }

    /**
    * 取得API版本
    */
    public GetAPIVersion(): Promise<CSHISResultWithData<string>> {
        return this.CallCsHis("GetAPIVersion", {

        });
    }


     /** CNHIEii 資料上傳
     * @param port 整數值，健保讀卡機連接之通訊連接埠編號，起始值為0
     * @param typeCode 資料類型
     * - "03"：醫費申報資料 XML 格式
     * - "05"：預檢醫費申報資料 XML 格式
     * - "07"：醫療費用電子申復資料
     * - "09"：預檢醫療費用電子申復資料
     * - "26"：檢驗(查)每日上傳資料 XML 格式
     * @param filePath 上傳作業檔案，為完整路徑及檔名，例：C:\AP\3501200000110990913B.zip
     * @example  CNHIEiiSend(0,"03","C:\\AP\\3501200000110990913B.zip")
     */
     CNHIEiiSend(port:number,typeCode:string,filePath:string): Promise<CSHISResultWithData<CnhiEiiApiID>>{
        return this.CallCsHis("Send", {
            port:port,
            typeCode:typeCode,
            filePath:filePath
        });
     }
     /** CNHIEii 提出醫療資料下載申請 
      * @param port 整數值，健保讀卡機連接之通訊連接埠編號，起始值為0
      * @param typeCode 資料類型
      * - "03"：醫費申報資料檢核結果回饋資料
      * - "04"：電子轉診資料下載_回復電子轉診單
      * - "05"：預檢醫費申報資料檢核結果回饋資料
      * - "06"：抽樣回饋資料
      * - "07"：醫療費用電子申復資料檢核結果回饋資料
      * - "08"：健保醫療資訊雲端查詢批次下載作業
      * - "09"：預檢醫療費用電子申復資料檢核結果回饋資料
      * - "10"：電子轉診資料下載_開立電子轉診單
      * - "26"：檢驗(查)每日上傳資料 XML 格式檢核結果回饋資料
      * - "27"：電子轉診資料下載申請檔_管理作業 csv 下載
      * - "31"：健保醫療資訊雲端查詢批次下載作業(新格式)
      * - "33"：居家輕量藍牙就醫資料下載
      * @param filePath 下載作業需求檔案，檔案內容格式請依照「下載檔案種類」要求，為完整路徑及檔名，
      * @returns CnhiEiiApiID 作業辨識碼
      *
      * @example CNHIEiiDownload(0,"03","C:\\AP\\3501200000-1010704-001.txt")
      * 
     */
     CNHIEiiDownload(port:number,typeCode:string,filePath:string): Promise<CSHISResultWithData<CnhiEiiApiID>>{
        return this.CallCsHis("Download", {
            port:port,
            typeCode:typeCode,
            filePath:filePath
        });
     }
     /** CNHIEii 下載醫療資料
      * @param port 整數值，健保讀卡機連接之通訊連接埠編號，起始值為0
      * @param downloadPath 檔案下載存放的路徑，例：C:\Data。注意:請勿使用根目錄 C:\。
      * @param localId 本機作業辨識碼
      * @param nhiId IDC作業辨識碼
      */
     CNHIEiiGet(port:number,downloadPath:string,localId:string,nhiId:string): Promise<CSHISResult>{
        return this.CallCsHis("Get", {
            port:port,
            downloadPath:downloadPath,
            localId:localId,
            nhiId:nhiId
        });
     }

    public GetVPNGetRandomXAndSignX(cardType:string,serviceCode:string): Promise<CSHISResultWithData<VPNGetRandomXandSignX>>{
        return this.CallCsHis("GetVPNGetRandomXAndSignX", {
            cardType: cardType,
            serviceCode: serviceCode,
        });
    }
    public GetHCACert(data:HcaInitData): Promise<CSHISResultWithData<string>>{
        return this.CallCsHis("GetHCACert", {
            data: data,
        });
    }
    public GetHCASign(data:HCASignRequestData): Promise<CSHISResultWithData<string>>{
        return this.CallCsHis("GetHCASign", {
            data: data,
        });
    }
    public InitializationAndCheckPasswd(passWord:string): Promise<CSHISResultWithData<HcaInitData>>{
        return this.CallCsHis("InitializationAndCheckPasswd", {
            passWord: passWord,
        });
    }
    /**
     *
     */
    constructor(protected hcrService: HcrService) {


    }
    private async CallCsHis(name: string, args: any) {
        //console.log('Call IC Card API: '+name,args);
        var msg = {
            call: name,
            args: args
        };
        var resp: VisionApiResult = await this.hcrService.sendMsg(msg);
        //var obj: {Call:string, Successed:boolean, Returns: any, Id: string} = JSON.parse(resp);
        if (!resp.Successed) {
            throw resp.Returns
        } else {
            return resp.Returns;
        }
    }
}
