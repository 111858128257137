
import { RegisterPregnantSubset } from './RegisterPregnantSubset'; 
/**
 * 孕婦產前檢查掛號作業  兒童預防保健服務挪用
 */ 
export class RegisterPregnant {
    /**
     * 資料 12組
     */ 
    public Data: RegisterPregnantSubset[] = [];
}
