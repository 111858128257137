import { SexEnum } from "src/app/enums/SexEnum";
import { NHIRegIC } from "./nhiRegIc"
import { PaymentInfoDto } from "./PaymentInfoDto";
import { HistOrder } from "src/app/hist/models/hist-order";
import { NewbornNote } from "src/app/models/NewbornNote";
import { VitalSign } from "../hist/vital-sign";
import { RegisterConst } from "src/app/shared/constants/register-const";

export class RegisterEditDto {
    Id: number
    RegId: number;
    IsEnabled: boolean;
    ClinicId: number;
    PatientId: number
    BookingDate: Date;
    RegDate: Date;
    WaitNo: string;
    CheckinTime?: Date;
    BeginTime?: Date;
    EndTime?: Date;
    DiscountCode: string
    ICode: string;
    ICodeName: string;
    SameTreat: string;
    DoctorCId: string;
    DoctorId: number;
    IType: string;
    Nhictp: string;
    Icdt: string | Date;
    Nhiyear: string;
    Icno: string;
    RoomCode: string;
    RoomName: string;
    MedDeptCode: string;
    MedDeptName: string;
    INote: string;
    INoteName: string;
    TimeSec: number;
    VisitSourceCode: string;
    StatusCode: string;
    RegSourceCode: string;
    Tpk2m: string;
    PaymentStatusCode: string;
    Remark: string
    TimeSecName: string;
    SelfPay: number;
    IsMiddleLowIncome: boolean;
    Deposit: number;
    OwnFee: number;
    RegisterFee: number;
    nhiConsultConvert: number;
    ReturnDate?: Date;
    IsFirstVisit: boolean;
    IsEditPatient: boolean = false;
    PatientNo: string;
    PatientFirstName: string;
    PatientMiddleName: string;
    PatientLastName: string;
    PatientBirth?: Date;
    PatientCId: string;
    PatientSexCode: SexEnum;
    PatientSexName: string;
    PatientPhoneHomeArea: string
    PatientPhoneHome: string
    PatientMobile: string
    PatientZipCode: string;
    PatientCity: string;
    PatientArea: string;
    PatientStreet: string;
    PatientNeedToCheckCName?: boolean;
    PatientRemarkOne?: string;
    DiscountName: string;
    StatusName: string;
    DoctorName: string;
    DoctorAccount: string;
    BeforeCharge: PaymentInfoDto;
    NHIRegICDto: NHIRegIC;
    SupArea: string;
    PaySysA: string;
    IcdtOri: string;
    Paid: number;
    CusNo: string;
    Dx: string;
    Case: string;
    Hrx: HistOrder[];
    OriHosp: string;
    OriDate: Date;
    RefDiagFee: boolean;
    ReCardDate: Date;
    Kcstmr: string;
    HasHist: boolean;
    MonCId: string;
    MonBirthdate?: Date;
    NewbornNote: NewbornNote;
    duty: number;   //  1:櫃檯掛號掛入(報表顯示N)、2門診候診掛入(報表顯示T)
    PatientRemark?: string;
    VitalSign?: VitalSign;
    RegIdOri: number;
    // 欠卡還卡退款區
    MedOwnFee:number;

    LackCardDate: Date;
    LackTreatnum: string;
    IsRehabilitate: boolean;
    IsSupTransfer: boolean = false;  // 前端補傳紀錄用
    IsSuper : boolean = false;
    SpcIdentity : string = '';
    CanBooking(){
        // 是否可報到
        var today = new Date();
        var toDate = this.getDateString(today, false, "");
        var regDate = this.getDateString(this.RegDate, false, "");
        var nextDate = this.getDateString(new Date(today.getFullYear(),today.getMonth(),today.getDate()+1), false, "");
        if (Number(regDate) >= Number(toDate) && Number(regDate) < Number(nextDate) &&
            this.StatusCode === RegisterConst.Code_Status_Appointment) {
            return true;
        } else {
            return false;
       }
    }
    CanEditByDesk() {
        // 這些狀態下，掛號櫃台作業可修改該掛號資料
        var today = new Date();
        var toDate = this.getDateString(today, false, "");
        var regDate = this.getDateString(this.RegDate, false, "");
        if (Number(regDate) >= Number(toDate) &&
            (this.StatusCode === RegisterConst.Code_Status_Examining ||
            this.StatusCode === RegisterConst.Code_Status_Waiting ||
            this.StatusCode === RegisterConst.Code_Status_Appointment ||
            this.StatusCode === RegisterConst.Code_Status_Emergency ||
            this.StatusCode === RegisterConst.Code_Status_ReservedLater ||
            this.StatusCode === RegisterConst.Code_Status_RegNotPay ||
            this.StatusCode === RegisterConst.Code_Status_Queue1 ||
            this.StatusCode === RegisterConst.Code_Status_Finish)) {
            return true;
        } else {
            return false;
        }
    }
    CanChargeByDesk() {
        // 這些狀態下，掛號櫃台作業可修改該掛號資料
        if (this.StatusCode === RegisterConst.Code_Status_Finish ||
            this.StatusCode === RegisterConst.Code_Status_PickUpDrug) {
            return true;
        } else {
            return false;
        }
    }
    // 可否進行看診
    CanConsult() {
      if (this.StatusCode === RegisterConst.Code_Status_Cancel) {
          return false;
      } else {
          const today = new Date();
          const regDate = new Date(this.RegDate);
          const newRegDate = new Date(regDate.getFullYear(), regDate.getMonth(), regDate.getDate());
          const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // 當日的日期，去除時間部分
          if (newRegDate.getTime() > currentDate.getTime()) {
        //   if (regDate > today) {
              return false;
          } else {
              return true;
          }
      }
    }

    IsPaid(): boolean {
        if (this.PaymentStatusCode === RegisterConst.Code_Payment_Paid) {
            return true;
        } else {
            return false;
        }
    }

    IsAppointment(): boolean {
        if (this.StatusCode === RegisterConst.Code_Status_Appointment) {
            return true;
        } else {
            return false;
        }
    }
    getDeposit(total:number,otherFee:number[]):number{
        var res = 0;
        if (total > 0){
            let allOtherFee: number = otherFee.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            res = total - res;
        }
        return res
    }
    getDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
        if (ev) {
          var d = new Date(ev);
          var month = '' + (d.getMonth() + 1);
          var day = '' + d.getDate();
          var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

          if (month.length < 2)
            month = '0' + month;
          if (day.length < 2)
            day = '0' + day;

          if (isMonth) {
            return [year, month].join(symbol);
          } else {
            return [year, month, day].join(symbol);
          }
        }
      }
}

export interface weekTime {
  label: string;
  isSelected: boolean;
}

export interface weekDay {
  name: number;
  label: string;
  allDay: boolean;
  labelS: string;
  isSelectedS: boolean;
  labelT: string;
  isSelectedT: boolean;
  labelU: string;
  isSelectedU: boolean;
}

export class RegisterCountDto {
    waitingCount: number;
    finishedCount: number;
    reserveCount: number;
}

export class SupTransferDto {
    Id: number;
    RegDate: Date;
    PatientNo: string;
    ICode: string;
    Icno: string;
    PatientCId: string;
    IType: string;
    StatusCode: string;
    SameTreat: string;
    ReCardDate: Date;
}
