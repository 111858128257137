import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { WebApiService } from 'src/app/services/web-api.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { Discount } from 'src/app/payments/discount/models/discount';
import { LocalPrintService } from 'src/app/services/local-print.service';

@Component({
  selector: 'app-reg-free-fee',
  templateUrl: './reg-free-fee.component.html',
  styleUrls: ['./reg-free-fee.component.css']
})
export class RegFreeFeeComponent implements OnInit, AfterViewInit {

  reportName = '優免身分看診統計';
  forExcel = true;

  sizePage = {
    width: 28.7, //cm
    height: 20 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );

  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;
  upperboundIndex: number = 0;
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  pageNo: number = 1;
  _sDate: Date;
  _eDate: Date;
  startDate: String;
  endDate: String;

  // 清單
  queryFormGroup: FormGroup;
  listMonth: Discount[] = []; //月統計
  listDay: Discount[] = []; //日統計
  listContent: Discount[] = []; //明細
  data: Discount[];
  totalRecord: number;
  api: WebApiService;
  // localPrintService: any;

  constructor(
    private webApiClient: WebApiClient,
    private hcrService: HcrService,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService) {
    this.api = this.webApiClient.createHisService('payments/discount');
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {
  }

  async fetchData(data: Discount[], startDate: string, endDate: string, reportType: string, forExcel = true) {
    this.forExcel = forExcel;
    this.elContainer = document.getElementById("container");
    // //顯示印表期間
    // this.startDate = startDate;
    // this.endDate = endDate;
    // this.startDate = this.startDate ? this.onGetDateString(this.startDate, true, '', false) : "";
    // this.endDate = this.endDate ? this.onGetDateString(this.endDate, true, '', false) : "";

    //顯示印表期間(已將格式改成y/m/d)
    this.startDate = startDate;
    this.endDate = endDate;
    this.startDate = startDate ? DateHelper.getROCDateStringWithSepetator(new Date(startDate)) : "";
    this.endDate = endDate ? DateHelper.getROCDateStringWithSepetator(new Date(endDate)) : "";

    //以下是報表表頭欄位, 目前沒用到, 先給預設值。
    // this.nowStr = this.onGetDateString(new Date(), true);
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    // this.timeStr = this.onGetTimeString(new Date());
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    // this._sDate = query.startDate;
    this._sDate = DateHelper.today;
    // this._eDate = query.endDate;
    this._eDate = DateHelper.today;

    if (reportType == '1') {
      this.listMonth = data; // 月統計筆數
      this.totalRecord = this.listMonth.length;
    } else if (reportType == '2') {
      this.listDay = data.filter(item => item.TotalDiscountPatientCount > 0); // 日統計筆數
      this.totalRecord = this.listDay.length;
    } else {
      this.listContent = data;// 明細報表統計筆數
      this.totalRecord = this.listContent.length;
    }

    switch (reportType) {
      case "1":
        this.insertListData();
        break;
      case "2":
        this.insertListData2();
        break;
      case "3":
        this.insertListData3();
        break;
      default:
        this.insertListData();
    }
  }


  // #region組報表
  // 組報表的html內容 - 每月掛號費減(免)人次表

  insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
    var html_total = this.createHTMLTotal();  //組統計行的 HTML 內容

    // var html_blank = this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listMonth.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listMonth[i]);
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;
        elTableContent.innerHTML += html_total;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.listMonth.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }

      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if ((this.listIcodeSum[i].IsSummary || this.listIcodeSum[i].IsDoctorTotal) && !(i == this.listIcodeSum.length - 1)) 
      {
        // afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        // elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          // elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                  style="
                  margin: 0px auto;
                  height: ` + this.sizePage.height + `cm;
                  width: ` + this.sizePage.width + `cm;
                  padding-top: ` + this.paddingPage.top + `cm;
                  pa dding-right: ` + this.paddingPage.right + `cm;
                  padding-bottom: ` + this.paddingPage.bottom + `cm;
                  padding-left: ` + this.paddingPage.left + `cm;
                  display: block;
                  margin: 0px auto;
                  box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                  box-sizing: border-box;
                  ">
                  <div class="content" id="page-` + iPage + `-content">
                  </div>
              </div>`;
  }

  // createHTMLBlankLine() {
  //   return `<tr>
  //               <th colspan="4" style="height: 12px; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
  //               </th>
  //             </tr>`;
  // }

  createHTMLListHeader() {
    return `<tr>   
                <th
                  style="text-align: center; width:10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  年月
                </th>   
                <th
                  style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  各月份總掛號人次
                </th>
                <th
                  style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  免收掛號費人次
                </th>
                  <th
                  style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  免收掛號費總金額
                </th>
                 <th
                  style="text-align: center; width: 15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  減收掛號費人次
                </th>
                <th
                  style="text-align: center; width: 15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  減收掛號費總金額
                </th>
                 <th
                  style="text-align: center; width: 15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  減免掛號費合計總金額
                </th>
              </tr>`;
  }

  createHTMLBlock(data: Discount) {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var month = data.Month ? this.onGetDateString(data.Month, true, '', true) : "";


    return `
        <tr>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${month}</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.TotalPatientCount} 人 </td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.FreeChargePatientCount} 人 </td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.FreeChargeAmount} 元</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.DiscountPatientCount} 人</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.DiscountAmount} 元 </td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.TotalDiscoundPerMonth} 元</td>         
        </tr>`;
  }

  createHTMLTotal() {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; bgcolor: #E3E3E3;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var TotalPatientCount = 0;
    var FreeChargePatientCount = 0;
    var FreeChargeAmount = 0;
    var DiscountPatientCount = 0;
    var DiscountAmount = 0;
    var TotalDiscoundPerMonth = 0;

    for (let i = 0; i < this.listMonth.length; i++) {
      TotalPatientCount += this.listMonth[i].TotalPatientCount; // 各月份總掛號人次(合計)
      FreeChargePatientCount += this.listMonth[i].FreeChargePatientCount; // 免收掛號費人次(合計)
      FreeChargeAmount += this.listMonth[i].FreeChargeAmount; // 免收掛號費總金額(合計)
      DiscountPatientCount += this.listMonth[i].DiscountPatientCount; // 減收掛號費人次(合計)
      DiscountAmount += this.listMonth[i].DiscountAmount; // 減收掛號費總金額(合計)
      TotalDiscoundPerMonth += this.listMonth[i].TotalDiscoundPerMonth; // 減免掛號費合計總金額(合計)
    }

    return `
       <tr style="background-color: #E3E3E3;">
            <td style="text-align: center; width: 5%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
              <b>期間內總計共</b> </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${TotalPatientCount} 人 </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${FreeChargePatientCount} 人 </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${FreeChargeAmount} 元</td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${DiscountPatientCount} 人</td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${DiscountAmount} 元 </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${TotalDiscoundPerMonth} 元</td>     
        </tr> 
    `;
  }

  createHTMLPageHeader(iPage) {
    var reportName = '每月減(免)掛號費人次表';
    return `<div id="header" style="margin: 0px 20px;">
                <div style="display: flex;column-gap: 5px;">
                  <table style="width: 100%; text-align: center; border: none;">
                    <tr>
                      <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      ` + reportName + `
                      </td>
                    </tr>
                    <tr>
                        <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表日期 ` + this.nowStr + `
                        </td>
                        <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                        </td>
                        <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        總筆數 ` + this.totalRecord + `
                        </td>
                    </tr>
                    <tr>
                        <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表時間 ` + this.timeStr + `
                        </td>
                        <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
  
                        </td>
                        <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                        </td>
                        
                    </tr>
                  </table>
                </div>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                  <label style="font-weight: bold; height: 23px;">　</label>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 5px;">
                      <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                      </table>
                  </div>
              </div>`;
  }

  createHTMLFinish() {

    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;  margin-top: 5px;">
                  <label style="font-weight: bold;">以下空白</label>
              </div>`;
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint() {
    if (this.listMonth.length > 0) {
      const reportName = '每月減(免)掛號費人次表';
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listMonth.length > 0) {
      const reportName = '每月減(免)掛號費人次表';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)


    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  // #region 組報表
  // 組報表的html內容 - 每日掛號費減(免)人次表
  insertListData2() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage2(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader2(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader2();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish2();                // 報表結束 -- 以下空白
    var html_total = this.createHTMLTotal2()                    // 組統計行的 HTML 內容

    // var html_blank = this.createHTMLBlankLine2();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listDay.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock2(this.listDay[i]);
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage2(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader2(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.listDay.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage2(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader2(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if ((this.listItems[i].IsSummary || this.listItems[i].IsDoctorTotal) && !(i == this.listItems.length - 1)) 
      {
        // afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        // elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage2(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader2(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          // elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }


  convertPxToCm2(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx2(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage2(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                  style="
                  margin: 0px auto;
                  height: ` + this.sizePage.height + `cm;
                  width: ` + this.sizePage.width + `cm;
                  padding-top: ` + this.paddingPage.top + `cm;
                  padding-right: ` + this.paddingPage.right + `cm;
                  padding-bottom: ` + this.paddingPage.bottom + `cm;
                  padding-left: ` + this.paddingPage.left + `cm;
                  display: block;
                  margin: 0px auto;
                  box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                  box-sizing: border-box;
                  ">
                  <div class="content" id="page-` + iPage + `-content">
                  </div>
              </div>`;
  }

  // createHTMLBlankLine2() {
  //   return `<tr>
  //               <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
  //               </th>
  //             </tr>`;
  // }

  createHTMLListHeader2() {
    return `<tr>   
                <th
                  style="text-align: center; width:16%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  日期
                </th>      
                <th
                  style="text-align: center; width:17%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  免收掛號費人次
                </th>
                <th
                  style="text-align: center; width: 17%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  減收掛號費人次
                </th>
                 <th
                  style="text-align: center; width: 17%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  總減免掛號費人次
                </th>
                <th
                  style="text-align: center; width: 17%;border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  總掛號人次
                </th>
                <th
                  style="text-align: center; width: 16%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                  每日減免人數占比
                </th>
              </tr>`;
  }

  createHTMLBlock2(data: Discount) {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var date = data.Date ? this.onGetDateString(data.Date, true, '', false) : "";
    // Check if TotalDiscountPatientCount is greater than 0

    if (data.TotalDiscountPatientCount <= 0) {
      // If TotalDiscountPatientCount is 0 or less, handle it as needed
      this.totalRecord = 0; // Optionally set totalRecord to 0
      this.listDay = []; // Ensure listDay is an empty array
      return ''; // Return an empty string or handle as needed
    }

    // data.XXX== undefined ? '' : data.XXX 拿掉ubdefined顯示空值
    return `
        <tr>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${date}</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.FreeChargePatientCount}</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.DiscountPatientCount} </td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.TotalDiscountPatientCount}</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.TotalPatientCount}</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.DiscountPercentage.toFixed(2)}%</td>
        </tr>`;
  }

  createHTMLTotal2() {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black; bgcolor: #E3E3E3;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var FreeChargePatientCount = 0;
    var DiscountPatientCount = 0;
    var TotalDiscountPatientCount = 0;
    var TotalPatientCount = 0;
    var DiscountPercentage = 0; //TotalDiscountPatientCount / TotalPatientCount * 100;

    for (let i = 0; i < this.listDay.length; i++) {
      FreeChargePatientCount += this.listDay[i].FreeChargePatientCount; // 免收掛號費人次(合計)
      DiscountPatientCount += this.listDay[i].DiscountPatientCount; // 減收掛號費人次(合計)
      TotalDiscountPatientCount += this.listDay[i].TotalDiscountPatientCount; // 總減免掛號費人次(合計)
      TotalPatientCount += this.listDay[i].TotalPatientCount; // 總掛號人次(合計)
    }

    DiscountPercentage = TotalDiscountPatientCount / TotalPatientCount * 100;

    return `
       <tr style="background-color: #E3E3E3;">
             <td style="text-align: center; width: 5%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
              <b>期間內總計共</b> </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${FreeChargePatientCount} 人 </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${DiscountPatientCount} 人 </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${TotalDiscountPatientCount} 人 </td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${TotalPatientCount} 人</td>
            <td style="text-align: center;  ${commonStyle}" ${commonClassText}> ${DiscountPercentage.toFixed(2)} % </td> 
        </tr> 
    `;
  }

  createHTMLPageHeader2(iPage) {
    var reportName = '每日掛號費減(免)人次表';
    return `<div id="header" style="margin: 0px 20px;">
                <div style="display: flex;column-gap: 5px;">
                  <table style="width: 100%; text-align: center; border: none;">
                    <tr>
                      <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      ` + reportName + `
                      </td>
                    </tr>
                    <tr>
                        <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表日期 ` + this.nowStr + `
                        </td>
                        <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                        </td>
                        <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        總筆數 ` + this.totalRecord + `
                        </td>
                    </tr>
                    <tr>
                        <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表時間 ` + this.timeStr + `
                        </td>
                        <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
  
                        </td>
                        <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                        </td>
                        
                    </tr>
                  </table>
                </div>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                  <label style="font-weight: bold; height: 23px;">　</label>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 5px;">
                      <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                      </table>
                  </div>
              </div>`;
  }
  createHTMLFinish2() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                  <label style="font-weight: bold;">以下空白</label>
              </div>`;
  }

  // #endregion
  getHtml2() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint2() {
    if (this.listDay.length > 0) {
      const reportName = '每日掛號費減(免)人次表';
      const printContents = this.getHtml2();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel2() {
    if (this.listDay.length > 0) {
      const reportName = '每日掛號費減(免)人次表';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)


    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  // #region 組報表
  // 組報表的html內容 - 掛號費減(免)病患明細表
  insertListData3() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage3(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader3(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader3();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish3();                // 報表結束 -- 以下空白

    // var html_total = this.createHTMLTotal();
    var html_blank = this.createHTMLBlankLine3();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listContent.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock3(this.listContent[i]);


      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage3(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader3(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.listContent.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        // elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage3(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader3(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          //   elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if ((this.listItems[i].IsSummary || this.listItems[i].IsDoctorTotal) && !(i == this.listItems.length - 1)) 
      {
        // afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage3(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader3(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToC3(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx3(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage3(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                  style="
                  margin: 0px auto;
                  height: ` + this.sizePage.height + `cm;
                  width: ` + this.sizePage.width + `cm;
                  padding-top: ` + this.paddingPage.top + `cm;
                  padding-right: ` + this.paddingPage.right + `cm;
                  padding-bottom: ` + this.paddingPage.bottom + `cm;
                  padding-left: ` + this.paddingPage.left + `cm;
                  display: block;
                  margin: 0px auto;
                  box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                  box-sizing: border-box;
                  ">
                  <div class="content" id="page-` + iPage + `-content">
                  </div>
              </div>`;
  }

  createHTMLBlankLine3() {
    return `<tr>
                <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                </th>
              </tr>`;
  }

  createHTMLListHeader3() {
    return `<tr>        
                <th
                  style="text-align: center; width:5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  病歷號
                </th>
                <th
                  style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  姓名
                </th>
                <th
                  style="text-align: center; width: 9%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  電話
                </th>
                <th
                  style="text-align: center; width: 22%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  地址
                </th>
                <th
                  style="text-align: center; width: 6%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  就診日
                </th>
                 <th
                  style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  身份
                </th>
                <th
                  style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  藥自負
                </th>
                <th
                  style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  診自負
                </th>
                <th
                  style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  檢自負
                </th>
                <th
                  style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  減免收
                </th>
                <th
                  style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                 掛號費
                </th>
                <th
                  style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                  減免金額
                </th>
                <th
                  style="text-align: center; width: 15%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                 優惠原因
                </th>
              </tr>`;
  }
  createHTMLBlock3(data: Discount) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var regdate = data.RegDate ? this.onGetDateString(data.RegDate, true, '', false) : "";
    var DiscountType;
    if (data.Subtotal > 0)
      DiscountType = "減收"
    if (data.Subtotal <= 0)
      DiscountType = "免收"

    // data.XXX== undefined ? '' : data.XXX 拿掉ubdefined顯示空值
    return `
        <tr>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.PatientNo}</td>
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientName}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.Mobile == undefined ? '' : data.Mobile}</td>
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Address == undefined ? '' : data.Address}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${regdate}</td>
          <td style="text-align: center;  ${commonStyle}" ${commonClassText}>${data.ICode}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.DrugOwnFee}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ExamOwnFee}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ConsultFee}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${DiscountType == undefined ? '' : DiscountType}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.Subtotal}</td>
          <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.DiscountRegisterFee == undefined ? '' : data.DiscountRegisterFee}</td>
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Name == undefined ? '' : data.Name}</td>
        </tr>`;
  }

  createHTMLPageHeader3(iPage) {
    var reportName = '掛號費減(免)病患明細表';
    return `<div id="header" style="margin: 0px 20px;">
                <div style="display: flex;column-gap: 5px;">
                  <table style="width: 100%; text-align: center; border: none;">
                    <tr>
                      <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      ` + reportName + `
                      </td>
                    </tr>
                    <tr>
                        <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表日期 ` + this.nowStr + `
                        </td>
                        <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                        </td>
                        <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        總筆數 ` + this.totalRecord + `
                        </td>
                    </tr>
                    <tr>
                        <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        印表時間 ` + this.timeStr + `
                        </td>
                        <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
  
                        </td>
                        <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                        </td>
                        
                    </tr>
                  </table>
                </div>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                  <label style="font-weight: bold; height: 23px;">　</label>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 5px;">
                      <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                      </table>
                  </div>
              </div>`;
  }

  createHTMLFinish3() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; border-top: 5px double black;text-align: center;">
                  <label style="font-weight: bold;">以下空白</label>
              </div>`;
  }

  // #endregion
  getHtml3() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint3() {
    if (this.listContent.length > 0) {
      const reportName = '掛號費減(免)病患明細表';
      const printContents = this.getHtml3();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel3() {
    if (this.listContent.length > 0) {
      const reportName = '掛號費減(免)病患明細表';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)


    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }


  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */

  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();
      var years = year.toString();
      if (years.length < 2)
        years = '00' + year; //民國6年7月5日出生 (0060705)
      if (years.length < 3)
        years = '0' + year;
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [years, month].join(symbol);
      } else {
        return [years, month, day].join(symbol);
      }
    }
  }

   //就診日切割
 formatRegDates(RegDate: string): string {
  if (RegDate.length === 7) {
      // 切割年份、月份與日期
      const year = RegDate.substring(0, 3);
      const month = RegDate.substring(3, 5);
      const day = RegDate.substring(5, 7);

      // 返回格式化後的字串
      return `${year}/${month}/${day}`;
  } else {
      // 如果 birthday 不是7碼長，返回原始字串或空字串
      return RegDate;
  }
}

  /** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */

  onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }

  onGetDate(ev) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [month, day].join('');
    }
  }
}