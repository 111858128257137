import { Component, OnInit, ElementRef, SimpleChanges, HostListener } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ConsoleInfo, HcrService } from 'src/app/services/hcr-service.service';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { IPatient } from 'src/app/services/api-service/patient/patientsDto';
import { BaseConfig } from 'src/app/services/base-config.service';
import { TrimEnd, verifyTaiwanIdIntermediateStringAndMsg } from 'src/app/shared/utilities';
import { CnhiEiiService } from 'src/app/services/cnhi-eii.service';
import { MatDialog } from '@angular/material/dialog';
import { CnhiEiiTestComponent } from '../cnhi-eii-test/cnhi-eii-test.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SatellitePharmacyServiceService } from 'src/app/services/satellite-pharmacy-service.service';
import { SessionService } from 'src/app/services/session.service';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';

import { LabApi } from 'src/app/services/api-service/labs/lab-api';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { IcHelper } from 'src/app/shared/helpers/ic-helper';
import { HPCHelper } from 'src/app/shared/helpers/hpc-helper';

@Component({
  selector: 'app-hcr-console',
  templateUrl: './hcr-console.component.html',
  styleUrls: ['./hcr-console.component.css']
})
export class HcrConsoleComponent implements OnInit {
  isHCREnable = false;
  isHcrDemo = false;
  hcrVersion = '';
  dllVer =0;
  hospName = '';
  hospAbbName = '';
  statusSAM = '';
  statusHC = '';
  statusHPC = '';
  cardReaderHC = '';
  cardReaderHPC = '';
  versionOption = [
    {text:'1.0.0',value:'1.0.0'},
    {text:'2.0.0',value:'2.0.0'}]
  demoPatient: IPatient;

  useWebApi:boolean;

  get prevDemoPatientWhenRand():boolean{
    return this.hcrService.prevDemoPatientWhenRand;
  }
  prevPatChanged(evt:MatCheckboxChange){
    this.hcrService.prevDemoPatientWhenRand = evt.checked;
  }
  isDebugMode(){
    return BaseConfig.isDebug();
  }

  constructor(
    private notification: EasyNotificationService,
    private hcrService: HcrService,
    private satelliteService:SatellitePharmacyServiceService,
    private session:SessionService,
    private cnhi_eii:CnhiEiiService,
    private elementRef:ElementRef,
    private confirmService: UserConfirmService,
    private labApi:LabApi,
    private matDialog:MatDialog,
    private hpcHelper:HPCHelper
  ) {
      this.hcrService.SetHcrConsoleComponent(this);

     }
  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.nowWidth = window.innerWidth;
      this.getRatio();
    }, 500);
  }

  nowWidth:number = window.innerWidth;
  bigRatio: boolean = false;
  getRatio() {
		if (this.nowWidth < 1251 ) {
      this.bigRatio = true;
    } else this.bigRatio = false;
  }
  async ngOnInit() {
    // this.isHCREnable = this.hcrService.ConnectStatus;
    // if(this.isHCREnable){
    //   await this.getHcrVersion();
    //   await this.getHospName();
    //   await this.getCardStatus();
    //   await this.getCardReader();
    // }
    this.nowWidth = window.innerWidth;
    this.getRatio();
    this.useWebApi = HcrService.useWebApi;
    await this.satelliteService.getHost();
  }
  bodyHeight = document.body.clientHeight;
  getRealHeight():number {
    let resultHeight = this.bodyHeight - 90
    return resultHeight;
  }
  consoleInfo:ConsoleInfo;
  satelliteStatus:boolean=false;
  get satelliteEnable(){
    return (Number(this.session.getData('useSatellite')));
  }
  get satelliteHost(){
    return this.satelliteService.Host;
  }
  async updateHost(host:string){
    //沒有在用
    await this.satelliteService.updateHost(host)
    this.satelliteStatus = await  this.satelliteService.trySend();

  }
  async tryHost(host:string){
    await  this.satelliteService.trySend(host);
  }
  public UpdateConsoleInfo(isConnected){
    //沒有在用
    setTimeout(async () => {
      this.isHCREnable = isConnected;
      if(isConnected){
        await this.getHcrVersion();
        await this.getHospName();
        await this.getCardStatus();
        await this.getCardReader();
        if(Boolean(Number(this.session.getData('useSatellite')))){
          this.satelliteStatus = await  this.satelliteService.trySend();
        }
        this.consoleInfo = await this.hcrService.logConsoleInfo()
      }
    }, 0);
  }
  async HpCardChanged(isInsert:boolean){
    var wait = 0;
    if(isInsert){
      this.statusHC = '讀取中...'
      wait = 1000;
    }
    setTimeout(async () => {
      await this.getHCStatus();
    },wait);

  }
  async HpcCardChanged(isInsert:boolean){
    var wait = 0;
    if(isInsert){
      this.statusHPC = '讀取中...'
      wait = 1000;
    }
    setTimeout(async () => {
      await this.getHPCStatus();
    },wait);
  }

  async getCardReader(){
     var ret = await this.hcrService.GetCardReaderInfo()
    if(ret.Successed){
      setTimeout(() => {
        this.cardReaderHC = ret.Returns.HC;
        this.cardReaderHPC = ret.Returns.HPC;
      }, 0);
    }
  }
  async getCardStatus(){
    await this.getHCStatus();
    await this.getSAMStatus();
    await this.getHPCStatus();

  }
  async getSAMStatus(){
    var ret = await this.hcrService.VisionApi.GetCardStatusSAM()
    if(ret.StatusCode == 0){
      setTimeout(() => {
        this.statusSAM = ret.Data;
      }, 0);
    }
  }
  async getHPCStatus(){
    var ret = await this.hcrService.VisionApi.GetCardStatusHPC()
    if(ret.StatusCode == 0){
      setTimeout(() => {
        this.statusHPC = ret.Data;
      }, 0);
    }
  }
  async getHCStatus(){
    var ret = await this.hcrService.VisionApi.GetCardStatusHC()
    if(ret.StatusCode == 0){
      setTimeout(() => {
        this.statusHC = ret.Data;
      }, 0);
    }
  }
  async getHospName(){
    var ret = await this.hcrService.VisionApi.GetHospName()
    if(ret.StatusCode == 0){
      var name =TrimEnd(ret.Data,'\0');
      this.hospName = name;
    }
    var ret = await this.hcrService.VisionApi.GetHospAbbName()
    if(ret.StatusCode == 0){
      var name =TrimEnd(ret.Data,'\0');
      this.hospAbbName = name;
    }
  }
  async getHcrVersion(){
    var dllVerRet = await this.hcrService.VisionApi.GetVersionEx()
    if(dllVerRet.StatusCode==0){
      this.dllVer = dllVerRet.Data;
    }
  }
  async SamValidate(){
    var ret = await this.hcrService.VisionApi.VerifySAMDC()
    if(ret.StatusCode!=0){
      this.notification.showWarning(ret.ErrorMsg);
    }else{
      this.notification.showSuccess('認證成功')
      await this.getCardStatus();
    }
  }
  async UpdateHC(){
    var ret = await this.hcrService.VisionApi.UpdateHCContents();
    if(ret.StatusCode!=0){
      this.notification.showWarning(ret.ErrorMsg);
    }else{
      this.notification.showSuccess('更新完成')
      await this.getCardStatus();
    }
  }

  async CheckPINHPC(){
    var result = await this.hpcHelper.CheckPin();
    if(result){
      await this.getCardStatus();
    }
    // var ret = await this.hcrService.VisionApi.VerifyHPCPIN('');
    // if(ret.StatusCode!=0){
    //   this.notification.showWarning(ret.ErrorMsg);
    // }else{
    //   this.notification.showSuccess('驗證成功')
    //   await this.getCardStatus();
    // }
  }
  async resetSAMStatus(){
    var ret = await this.hcrService.VisionApi.SoftwareResetSAM()
    if(ret.StatusCode == 0){
      this.notification.showSuccess('重置SAM完成');
    }else{
      this.notification.showSuccess('重置SAM失敗');
    }
  }
  async resetHPCStatus(){
    var ret = await this.hcrService.VisionApi.SoftwareResetHPC()
    if(ret.StatusCode == 0){
      this.notification.showSuccess('重置醫事人員卡完成');
    }else{
      this.notification.showSuccess('重置醫事人員卡失敗');
    }
  }
  async resetHCStatus(){
    var ret = await this.hcrService.VisionApi.SoftwareResetHC()
    if(ret.StatusCode == 0){
      this.notification.showSuccess('重置IC卡完成');
    }else{
      this.notification.showSuccess('重置IC卡失敗');
    }
  }
  async resetCRStatus(isConnected: boolean){
    //this.isHCREnable = isConnected;
      // if(isConnected){
      //   await this.getHospName();
      //   await this.getCardReader();
      // }
    var ret = await this.hcrService.VisionApi.SoftwareResetCR()
    if(ret.StatusCode == 0){
      this.notification.showSuccess('重置讀卡機完成');
    }else{
      this.notification.showSuccess('重置讀卡機失敗');
    }
  }
  async reload(){
    var ret = await this.hcrService.Reload()
    if(ret.Successed){
      this.notification.showSuccess('重載完成');
    }else{
      this.notification.showSuccess('重載失敗');
    }
  }
  async changeVersion(evt: any){
    // console.log(evt.value)
    var ret = await this.hcrService.changeApiVersion(evt.value);
    if(ret.Successed == true){
      await this.getHcrVersion();
    }
  }
  enableDemo(evt:MatButtonToggleChange ){
    this.hcrService.setDemoMode(evt.source.checked);
    this.notification.showSuccess((evt.source.checked?'啟用':'停用')+'展示模式');
    this.isHcrDemo = evt.source.checked;
    if(this.isHcrDemo==true){
      this.demoPatient = this.hcrService.demoPatient();
    }
  }

  receivePatientChange(evt: any){
    console.log(evt);
  }
  print(){
    this.hcrService.PrintTest();
  }
  update(){
    this.hcrService.Update()
  }
  protocolSupport = false;
  async openApp(){
    var s = await this.openVisionHis();
    if(!s){
      alert('Uninstall');
    }
  }
  async openAppNoUpdate(){
    var s = await this.openVisionHis(true);
    if(!s){
      alert('Uninstall');
    }
  }
  openVisionHis(noUpdate:boolean = false):Promise<boolean>{
    var rs:(boolean)=>void = null;
    var promise = new Promise<boolean>((_rs,_rj)=>{
      rs = _rs
    });
    var onProtocolSupport = ()=>{
      window.removeEventListener('blur',onProtocolSupport);
      this.protocolSupport = true;
      rs(true);
    }
    window.addEventListener('blur',onProtocolSupport);
    setTimeout(() => {
      rs(false);
    }, 500);
    
    var p = BaseConfig.getConfig().webApi.hisBaseUrl+(noUpdate?";noupdate":"");
    if(this.getChromeVersion()>=130){
      p = encodeURIComponent(p);
    }
    
    document.location.assign("visionhis://"+p);
    return promise;
  }
  getChromeVersion () {     
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2], 10) : -1;
  }
  cnhieiiTest(evt){
    var diag = this.matDialog.open(CnhiEiiTestComponent,{
      disableClose:true,
      panelClass:'CnhiEiiTestWidth',
      width:'95%',
      maxWidth:'95vw'
    });
    diag.componentInstance.onClose.subscribe(x=>diag.close());
    //this.cnhi_eii.GetHospType
  }
  async dayliUploadTest(evt){
    var file = await this.hcrService.SelectFiles()
    var ret = await this.hcrService.VisionApi.UploadData(file.FullName,"60");
    if(ret.StatusCode==0){
      if(ret.Data){
        await this.confirmService.showAlert('執行成功',
        '醫事服務機構代碼: \t'+ ret.Data.HospId+'\n'+
        '上傳日期時間: \t'+ ret.Data.UploadDateTime+'\n'+
        '接收日期時間: \t'+ ret.Data.RecieveDateTime+'\n'+
        'DC接收檔案筆數: \t'+ ret.Data.RecieveNumber+'\n'+
        'DC接收檔案大小: \t'+ ret.Data.RecieveSize+' (Byte)\n'+
        '安全模組代碼: \t'+ ret.Data.SAMId+'\n',{'fitContent':true}
        );
      }else{
        this.notification.showSuccess('執行成功');
      }
    }else{
      this.notification.showError(ret.ErrorMsg);
    }
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    setTimeout(() => {
      this.nowWidth = window.innerWidth;
      this.getRatio();
    }, 500);
  }
  tNum:string;
  async getTreat(){
    var hosp = await this.hcrService.VisionApi.GetHospID();
    var cid='HC30575172';
    cid='A123456789'
    var ret = await this.hcrService.VisionApi.webApiGetTreatNumNoIC(cid,hosp.Data);
    var ret2 = await this.hcrService.VisionApi.webApiGetTreatNum(cid,hosp.Data,'1131105080808');

    var tNum = ret.Data;
  }

  useWebApiChecked(evt:MatCheckboxChange){
    HcrService.useWebApi = evt.checked;
  }
  vhcStatus:string = '';
  // async onQRCodeScanResult(code:string,dialog:VisDialogComponent){
  //   dialog.show =false;
  //   var ret = await this.hcrService.VHCApi.uploadqrcode(code)
  //   if(ret.StatusCode!=0){
  //     this.notification.showError(ret.ErrorMsg);
  //   }else{
  //     this.notification.showSuccess(ret.ErrorMsg);
  //   }
  //   var cardStatus = await this.hcrService.VHCApi.GetCardStatusHC();
  //   this.vhcStatus = cardStatus.Data;
  //   console.log(code)
  // }
  // async clearVHC(){
  //   var ret = await this.hcrService.VHCApi.cleanQRCode()
  //   if(ret.StatusCode!=0){
  //     this.notification.showError(ret.ErrorMsg);
  //   }else{
  //     this.notification.showSuccess(ret.ErrorMsg);
  //   }
  // }
  // async VHCStatus(){
  //   var ret = await this.hcrService.VHCApi.GetCardStatusHC()
  //   this.confirmService.showAlert("結果",JSON.stringify(ret));
  // }
  // async VHCBasic(){
  //   var ret = await this.hcrService.VHCApi.GetBasicData()
  //   this.confirmService.showAlert("結果",JSON.stringify(ret));
  // }
  // async VHCRegBasic(){
  //   var ret = await this.hcrService.VHCApi.GetRegisterBasic()
  //   this.confirmService.showAlert("結果",JSON.stringify(ret));
  // }
  // async VHCTreat(){
  //   var ret = await this.hcrService.VHCApi.GetTreatmentNoNeedHPC()
  //   console.log(ret);
  //   var ret2 = await this.hcrService.VHCApi.QuickInsurence('')
  //   console.log(ret2);
  //   this.confirmService.showAlert("結果",JSON.stringify(ret)+'\n\n' + JSON.stringify(ret2));
  // }
  // // 需醫師卡
  // async VHCTreat2(){
  //   var ret = await this.hcrService.VHCApi.GetTreatmentNeedHPC()
  //   console.log(ret);
  //   var ret2 = await this.hcrService.VHCApi.ReadPrescription();
  //   console.log(ret2);
  //   this.confirmService.showAlert("結果",JSON.stringify(ret) + '\n\n' + JSON.stringify(ret2));
  // }
  // async VHCIsq(){
  //   var ret = await this.hcrService.VHCApi.GetLastSeqNum()
  //   console.log(ret);
  //   this.confirmService.showAlert("結果",JSON.stringify(ret));
  // }
  async CreateFakeResult(){
    try{
      await this.labApi.CreateFakeResult();
      this.notification.showSuccess('造假完成');
    }catch(e){
      this.notification.showError(e);
    }
  }
  async GetPaper(){
    var ret  = await this.hcrService.GetPaperSize(7);
    await this.confirmService.showAlert('結果',JSON.stringify(ret));
  }

  treat_hinno:string = '';
  treat_dt:Date = new Date();
  treat_tm:string = '';
  treat_cid:string = '';
  treat_no:string = '';
  async TestITreat(){
      //var a = new VisionHisDemo(null);
      //3532018235
      //HTU4AY G21LNZC 3AF1D3W
      //this.getTreatId('7431110698','G221489822',new Date(2023,7,21,15,11,44))
      this.treat_no = '';
      var dt = new Date(this.treat_dt.toLocaleDateString() + ' ' + this.treat_tm)
      this.treat_no = this.getTreatId(this.treat_hinno,this.treat_cid,dt)
      console.log('目標：HTU4AYG21LNZC3AF1D3W')

      //this.getTreatInfo(this.treat_no);
  }
  ParseTreat(){
    this.getTreatInfo(this.treat_no);
  }
  ClearTreat(){
    this.treat_hinno = '';
    this.treat_dt = new Date();
    this.treat_tm = '';
    this.treat_cid = '';
    this.treat_no = '';
  }
  private getITreatString(n: number) {
    var char = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
    var calcTemp = Math.floor(n);
    var treatIdBuild = ''
    do {
        var a = calcTemp % char.length;
        treatIdBuild = char[a] + treatIdBuild;
        calcTemp = Math.floor(calcTemp / char.length)
    }
    while (calcTemp > 0)
    return treatIdBuild;
  }
  private getITreatNum(s: string) {
    var char = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
    var n  = 0 ;
    for(let c of s){
      var i = char.indexOf(c);
      n *= char.length
      n += i;
    }

    return n;
  }
  /** 第一欄請自行複製6碼 */
  public getTreatId(nhiCode:string,cid:string,dt:Date) {
    //機構代馬 (6)
    var c1 = nhiCode;// this.getITreatString(parseInt(nhiCode));
    // 身分證 前2馬保留
    var cCid1 = cid.substring(0, 2)
    // 身分證 3-9碼 轉成5碼 (身分證檢查碼跳過)
    var code = cid.substring(2, 9)
    var cCid2 = this.getITreatString(parseInt(code));
    // 年份 2碼
    var cYr = this.getITreatString(dt.getFullYear()-1911);
    // 時間5碼
    var secDiff = (dt.getTime() - new Date(dt.getFullYear(), 0, 1, 0, 0, 0).getTime()) / 1000
    var cDt = this.getITreatString(secDiff).padStart(5,'0');

    console.log('轉換：',nhiCode,cid,dt)
    console.log('結果：'+c1 + cCid1 + cCid2 + cYr + cDt);
    return c1 + cCid1 + cCid2 + cYr + cDt;
  }
  public getTreatInfo(treatNo:string) {
    //機構代馬 (6)
    var c1 = treatNo.substring(0,6);// this.getITreatString(parseInt(nhiCode));
    // 身分證 前2馬保留
    var cCid1 = treatNo.substring(6, 8)
    // 身分證 3-9碼 轉成5碼 (身分證檢查碼跳過)
    var code = treatNo.substring(8, 13)
    var cCid2 = this.getITreatNum(code);
    var cid = `${cCid1}${cCid2}0`;
    var cCid3 = verifyTaiwanIdIntermediateStringAndMsg(cid,true).validateNum; // 身分證驗證碼
    var cid = `${cCid1}${cCid2}${cCid3}`;
    // 年份 2碼
    var cYr = this.getITreatNum(treatNo.substring(13,15));
    // 時間5碼
    var cSec = this.getITreatNum(treatNo.substring(15,20))
    var dt = new Date(new Date(cYr+1911,0,1,0,0,0).getTime()+cSec*1000);

    this.treat_hinno = c1;
    this.treat_cid = cid;
    this.treat_dt = dt;
    this.treat_tm = DateHelper.getTimeString(dt,':')
    console.log('結果：'+cid +', ' + dt);

  }
}
