<div style="position: relative;">
    <mat-icon class="tool-icon" title="除錯輔助工具" [appOpenDialog]="tool">
        build
    </mat-icon>
</div>



<app-vis-dialog title="卡機控制軟體設定" (close)="tool.show=false" #tool [width]="600" [height]="500">
    <div class="controlled-font-size-large" style="padding: 5px;">
        <div style="margin: 5px 0px;">
            <div>檢驗測試</div>
            <div class="tool-items">
                <button (click)="CreateFakeResult()">產生今日檢驗報告到檢驗平台</button>
                <button >產生成人健檢報告</button>
            </div>
            <div class="tool-items">
                <input placeholder="集團代碼" style="width: 50px;flex-grow: 1;" [(ngModel)]="companyCode">
                <input placeholder="醫事機構代碼" style="width: 100px;flex-grow: 1;" [(ngModel)]="clinicNhiCode">
                <app-vis-datepicker [(ngModel)]="labImportSt"></app-vis-datepicker>
                ~
                <app-vis-datepicker [(ngModel)]="labImportEd"></app-vis-datepicker>
                <button (click)="importLabFrom()">匯入</button>
            </div>
            <!-- <button (click)="TestITreat()">測試就醫識別馬</button> -->
        </div>
        <!-- <div style="width: 100%;text-align: start;;vertical-align: middle;" class="position-relative">
            <div>虛擬健保卡：</div>
            <div class="tool-items">
                <button [appOpenDialog]="qrcode">掃QR</button>
                <button (click)="clearVHC()">清除</button>
                <button (click)="VHCStatus()">讀取狀態</button>
                <button (click)="VHCBasic()">讀取基資</button>
                <button (click)="VHCRegBasic()">讀取掛號基資</button>
                <button (click)="VHCTreat()">讀取就醫資料</button>
                <button (click)="VHCTreat2()">讀取需醫師卡就醫資料</button>
                <button (click)="VHCIsq()">讀取最後一次就醫序號</button>
                {{vhcStatus}}
            </div>
        </div> -->
        <div style="width: 100%;text-align: start;;vertical-align: middle;" class="position-relative">
            <div>CSHIS 6.0：</div>
            <div class="tool-items">

                <button (click)="CSHIS6Open()">啟動</button>
                <button (click)="CSHIS6Init()">初始化</button>
                <button (click)="CSHIS6Console()">設定</button>
                <button (click)="CSHIS6Close()">關閉</button>
            </div>
        </div>
        <div >
            <div>API測試：</div>
            <div class="tool-items">

                <a style="font-size: 14px;" class="mr-2 position-relative azure cursor-pointer"
                    (click)="cnhieiiTest($event)">
                    <mat-icon class="mat-icon position-absolute vis-combine-icon">storage</mat-icon>
                    <div class="text-lg d-inline-flex" style="text-indent: 24px;">醫療資料傳輸共同介面</div>
                </a>
                <a style="font-size: 14px;" class="position-relative orange cursor-pointer"
                    (click)="dayliUploadTest($event)">
                    <mat-icon class="mat-icon position-absolute vis-combine-icon">upload_for_offline</mat-icon>
                    <div class="text-lg d-inline-flex" style="text-indent: 22px;">每日上傳</div>
                </a>
                <a style="font-size: 14px;" class="position-relative orange cursor-pointer"
                    (click)="referalDownloadTest($event)">
                    <mat-icon class="mat-icon position-absolute vis-combine-icon">upload_for_offline</mat-icon>
                    <div class="text-lg d-inline-flex" style="text-indent: 22px;">轉診單開立</div>
                </a>

                    <mat-checkbox [checked]="useWebApi" (change)="useWebApiChecked($event)">就醫識別碼使用WebApi</mat-checkbox>
                    <!-- <button (click)="getTreat()">取得就醫識別碼</button>
                        {{tNum}} -->

            </div>

        </div>
        <div style="margin: 5px 0px;">
            <div>就醫識別碼模擬器：</div>
            <div class="tool-items">
                <input style="width: 80px;flex-grow: 1;" placeholder="機構碼(6)" [(ngModel)]="treat_hinno">
                <input style="width: 100px;flex-grow: 1;" placeholder="身分證(10)" [(ngModel)]="treat_cid">
                <app-vis-datepicker placeholder="就診日期時間(yyyMMddHHmmss)" [(ngModel)]="treat_dt"></app-vis-datepicker>
                <app-vis-timepicker [showSecond]="true" [(ngModel)]="treat_tm"></app-vis-timepicker>
                <button (click)="TestITreat()">產生</button>
            </div>
            <div class="tool-items">
                結果：<input style="width: 200px;flex-grow: 1;" [(ngModel)]="treat_no">(如有雷同純屬巧合)
                <button (click)="ParseTreat()">解析</button>
                <button (click)="ClearTreat()">清除</button>
            </div>
            <!-- <button (click)="TestITreat()">測試就醫識別馬</button> -->
        </div>
        <div style="width: 100%;text-align: start;;vertical-align: middle;" class="position-relative">
          <div>政府公開資料：</div>
          <div class="tool-items">
              <button (click)="ImportCalendarOpen()">匯入政府辦公日曆</button>
          </div>
        </div>

    </div>


</app-vis-dialog>


<app-vis-dialog [title]="'QRCode掃描器'" [width]="300" (close)="qrcode.show=false" #qrcode>
    <app-qrcode-scanner *ngIf="qrcode.show" (onSuccess)="onQRCodeScanResult($event,qrcode)"></app-qrcode-scanner>
</app-vis-dialog>


<app-vis-dialog title="匯入政府辦公日曆" [height]="200" [width]="400" [show]="isImportCalendarOpened" (close)="isImportCalendarOpened = false">
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%;">
    <div>
      <span i18n="@@CalendarRocYear">現在民國年：</span>
      <input type="number" class="vis-input" [(ngModel)]="nowRocYear" min="112" max="199" maxlength="3" disabled/>
    </div>
    <div>
      <span i18n="@@CalendarRocYear">選擇民國年：</span>
      <input type="number" class="vis-input" [(ngModel)]="importCalendarRocYear" min="112" max="199" maxlength="3" required (change)="CalendarRocYearChange()"/>
    </div>
    <div>
      <span i18n="@@CalendarCount">{{calendarCountHint}}</span>
    </div>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="ImportCalendar()" i18n="@@DialogConfirm">確定</button>
    <button type="button" (click)="isImportCalendarOpened = false" i18n="@@DialogClose">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
