import { ICellData } from "src/app/shared/components/calendar/calendar-month/calendar-month.component";

export class Schedule implements ICellData{
  public Id: number;
  public UserId: number;
  public UserName: string;
  public SecTime: number;
  public RoomCode: string;
  public DefaPharm: number;
  public RoomDisplay: string;
  public ScheduleDate: Date;
  public Year: number;
  public Month: number;
  public Day: number;
  public IsWeekly: boolean;
  public PositionCode:string;
}
