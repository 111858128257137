import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { HistoryComponent } from '../components/order-dosage/history/history.component';
import { ReceiptComponent } from '../components/receipt/receipt.component';
import { RepeatedPrescriptionComponent } from '../components/repeated-prescription/repeated-prescription.component';
import { ReportApi } from '../reportApi';
import { RegisterComponent } from '../components/register/register.component';
import { DrugBagComponent } from '../components/drug-bag/drug-bag.component';
import { CtrdrugsContainerComponent } from '../components/ctrdrugs-container/ctrdrugs-container.component';
import { LabsOutclinicReportComponent } from '../components/labs/labs-outclinic-report/labs-outclinic-report.component';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { PRN001Params } from 'src/app/services/api-service/parameters/PRN001Params';
import { DynamicReciptComponent } from '../design-tools/dynamic-recipt/dynamic-recipt.component';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { MedicationWayReportComponent } from 'src/app/shared/components/report/medication-way-report/medication-way-report.component';
import { MedicationWayAndReceiptReportComponent } from 'src/app/shared/components/report/medication-way-and-receipt-report/medication-way-and-receipt-report.component';
import { RepeatedPrescriptionDynamicComponent } from '../design-tools/repeated-prescription/repeated-pres-dynamic/repeated-pres-dynamic.component';
import { DrugBagInfo } from 'src/app/services/api-service/hist/whole-large-hist';
import { HistOrder } from 'src/app/hist/models/hist-order';

@Component({
  selector: 'app-report-helper',
  templateUrl: './report-helper.component.html',
  styleUrls: ['./report-helper.component.css']
})
export class ReportHelperComponent implements OnInit {

  @ViewChild('drugbag')
  private stdDrugbag:DrugBagComponent;
  @ViewChild('receipt')
  private receipt:ReceiptComponent;
  @ViewChild('cmsReport')
  private cmsReport:DynamicReciptComponent;
  @ViewChild('cmsPresReport')
  private cmsPresReport:RepeatedPrescriptionDynamicComponent;
  @ViewChild('history')
  private history:HistoryComponent;
  // @ViewChild('way')
  // private way:MedicationWayReportComponent;
  @ViewChild('way')
  private way:MedicationWayAndReceiptReportComponent;
  @ViewChild('register')
  private register:RegisterComponent;
  @ViewChild('repPres')
  private repPres:RepeatedPrescriptionComponent;
  // @ViewChild('sampling')
  // private sampling:SamplingOrderListComponent;
  @ViewChild('ctrdrugs')
  private ctrdrugs:CtrdrugsContainerComponent;
  @ViewChild('labOutclinic')
  private labOutclinic:LabsOutclinicReportComponent;

  @ViewChild('UDTmp')
  private UDTmp:ElementRef<HTMLDivElement>;
  @ViewChild('LRTmp')
  private LRTmp:ElementRef<HTMLDivElement>;
  @ViewChild('UDLRTmp')
  private UDLRTmp:ElementRef<HTMLDivElement>;

  private reportTypes: ValueTextPairNumberValue[] = [];
  private param: PRN001Params;
  public drugBagRxIdList: HistOrder[] = [];

  constructor(
    private printer:LocalPrintService,
    private el:ElementRef<HTMLElement>,
    private clinicDataService: ClinicDataService,
    private api: ReportApi,
    private histApi:HistApi,
    private notify:EasyNotificationService) {
    this.el.nativeElement.style.display = 'none';
    this.printer.printHelper = this;
   }

  async ngOnInit() {
    this.param = await this.clinicDataService.getParam("PRN001");
    this.reportTypes = await this.api.getReportType();
    var css = document.createElement('style');
    css.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'+
      '.UDLRTmp>div{width:"50%"}'
    ));
    this.UDLRTmp.nativeElement.appendChild(css);

    var css2 = document.createElement('style');
    css2.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'+
      '.LRTmp>div{width:"50%"}'
    ));
    this.LRTmp.nativeElement.appendChild(css2);

    var css3 = document.createElement('style');
    css3.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'+
      '.UDTmp>div{width:"100%"}'
    ));
    this.UDTmp.nativeElement.appendChild(css3);
  }
  /** tp:1一般處方 2收據 5連處 101掛號單*/
  public async print(regId: number, tp: number[]) {
    //console.log('本地表單['+tp+']列印中...')
    if(tp.length == 1){
      var html = await this.getHtml(regId, tp[0]);
      var name = this.reportTypes.find(x => x.value == tp[0])?.text??'';
      this.printer.printHtml(tp[0],name,html);
    }else if(tp.length == 2){
      tp.forEach( async x => {
        var html = await this.getHtml(regId,x);
        this.LRTmp.nativeElement.innerHTML+=html;
      });
      var html = this.LRTmp.nativeElement.innerHTML;
      var name = this.reportTypes.find(x=>x.value == tp[0])?.text??'';
      this.printer.printHtml(tp[0],name,html);
    }else{
      tp.forEach( async x=>{
        var html = await this.getHtml(regId,x);
        this.UDLRTmp.nativeElement.innerHTML+=html;
      });
      var html = this.UDLRTmp.nativeElement.innerHTML;
      var name = this.reportTypes.find(x=>x.value == tp[0])?.text??'';
      this.printer.printHtml(tp[0],name,html);
    }
  }
  /** 一頁兩格，左右 */
  public async printHorizontal(name:string,...htmls:string[]){
    for(let i= 0; i<htmls.length;i+=2){
      var html = htmls[i];
      this.LRTmp.nativeElement.innerHTML = '';
      this.LRTmp.nativeElement.innerHTML += html;
      if(htmls.length<=i+1){
        html = htmls[i+1];
        this.LRTmp.nativeElement.innerHTML += html;
      }
      this.printer.printHtml(0,name,this.LRTmp.nativeElement.innerHTML);
    }
  }
  /** 一頁兩格，上下 */
  public async printVertical(name:string,...htmls:string[]){
    var css = document.createElement('style');
    css.appendChild(document.createTextNode(
      '@page { size: A4; margin:0cm; }\n'+
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}'
    ));

    this.UDTmp.nativeElement.innerHTML = '';
    this.UDTmp.nativeElement.appendChild(css);

    for(let i= 0; i<htmls.length;i++){
      var html = htmls[i];
      if(i%2==0 && i>0){
        this.printer.printHtml(999,name,this.UDTmp.nativeElement.outerHTML);
        this.UDTmp.nativeElement.innerHTML = '';
        this.UDTmp.nativeElement.appendChild(css);
      }
      this.UDTmp.nativeElement.innerHTML += html;
    }
    if(this.UDTmp.nativeElement.innerHTML!=''){
      this.printer.printHtml(999,name,this.UDTmp.nativeElement.outerHTML);
    }
  }

  /** 叫做grid其實每頁只有4格，左上->右上->左下->右下 */
  public async printGrid(paperSize:'A4', name:string,...htmls:string[]){
    var css = document.createElement('style');
    css.appendChild(document.createTextNode(
      '@page { size: A4; margin:0cm; }\n'+
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'
      // '.UDLRTmp>div{width:"50%"}\n'
    ));
    this.UDLRTmp.nativeElement.innerHTML = '';
    this.UDLRTmp.nativeElement.appendChild(css);

    for(let i= 0; i<htmls.length;i++){
      var html = htmls[i];
      if(i%4==0 && i>0){
        this.printer.printHtml(999,name,this.UDLRTmp.nativeElement.outerHTML);
        this.UDLRTmp.nativeElement.innerHTML = '';
        this.UDLRTmp.nativeElement.appendChild(css);
      }
      this.UDLRTmp.nativeElement.innerHTML += html;
    }
    if(this.UDLRTmp.nativeElement.innerHTML!=''){
      this.printer.printHtml(999,name,this.UDLRTmp.nativeElement.outerHTML);
    }
  }
  public async getPrePrint(regId:number, tp:number){
    switch (tp) {
      // 病歷
      case 1:
        break;
      // 收據
      case 2:
        break;
      // 藥袋
      case 7:
        break;
      // 連處
      case 5:
        this.repPres.isopenQRcode = false;
        break;
      // 交付檢驗單
      case 6:
        break;
      case 81: // FOR Code測試 ok的話回回到一般交付調劑處方籤
        this.repPres.isopenQRcode = true;
        var res = await this.repPres.getQRCode();
        break;
      // 掛號單
      case 101:
        break;
      // 連處
      default:
        break;
    }
  }

  public async getHtml(regId:number, tp:number): Promise<string>{
    var rs:(v:string)=>void = null;
    var prom = new Promise<string>((_rs,rj)=>{
      rs=_rs;
    })
    switch (tp) {
      // 處方
      case 1:
        if(this.param.HistoryCode){
          // 動態收據元件
          try{
            await this.cmsReport.getReport(this.param.HistoryCode)
            await this.cmsReport.setRegId(regId);
            setTimeout(async () => {
              var html = await this.cmsReport.getHtml();
              rs(html);
            }, 0);
          }catch(e){
            this.notify.showError(e)
          }
        }else{
          await this.history.setRegId(regId);
          setTimeout(() => {
            var html = this.history.getHtml();
            rs(html);
          }, 0);
        }
        break;
      // 收據
      case 2:
        if(this.param.ReceiptCode){
          // 動態收據元件
          try{
            await this.cmsReport.getReport(this.param.ReceiptCode)
            await this.cmsReport.setRegId(regId);
            setTimeout(async () => {
              var html = await this.cmsReport.getHtml();
              rs(html);
            }, 0);
          }catch(e){
            this.notify.showError(e)
          }
        }else{
          // 一般收據元件
          await this.receipt.setRegId(regId);
          setTimeout(() => {
            var html = this.receipt.getHtml();
            rs(html);
          }, 0);
        }
        
        break;
        // 服法收據
      case 3:
        if(this.param.WayCode){
          // 動態收據元件
          try{
            await this.cmsReport.getReport(this.param.WayCode)
            await this.cmsReport.setRegId(regId);
            setTimeout(async () => {
              var html = await this.cmsReport.getHtml();
              rs(html);
            }, 0);
          }catch(e){
            this.notify.showError(e)
          }
        }else{
          // 一般收據元件
          await this.way.setRegId(regId);
          setTimeout(() => {
            var html = this.way.getHtml();
            rs(html);
          }, 0);
        }
        
        break;
      // 藥袋
      case 7:
        var p03 = await this.clinicDataService.getParam('PRN003')
        var isCmsDrugbag = !!p03.CustomDrugBug;
        // 判斷要列印的藥品，this.drugBagRxIdList.length == 0 => 全印
        var rxIds: number[] = [];
        if (this.drugBagRxIdList.length > 0) {
          rxIds = this.drugBagRxIdList.map(x => x.RxId);
        } else {
          rxIds.push(0);
        }
        var drugBag = isCmsDrugbag?await this.histApi.getCMSDrugBag(regId, rxIds): await this.histApi.getDrugBag(regId, rxIds);
        var pageNo = 1;
        var html = '';
        for (let h of drugBag.Hrx) {
          var d = Object.assign({},drugBag);
          d.Hrx = [h];
          d.BagNo = pageNo++;
          // 標準藥袋 <-- 已由卡機主控台判斷，這邊不需要判斷(Jason on 2024/06/15)
          // if(d.DrugbagType == 1){
          if(isCmsDrugbag){
            var cmsDrugbagCode = p03.CustomDrugBug;
            // 有多藥一袋版型代碼 且 其中一袋有多項藥品，改為多藥一袋版型代碼
            if(p03.CustomDrugBug2 && drugBag.Hrx.some(bag=>bag.data.length>1)){
              cmsDrugbagCode = p03.CustomDrugBug2;
            }
            await this.cmsReport.getReport(cmsDrugbagCode)
            await this.cmsReport.setRegId(regId,d)
          }else{
            this.stdDrugbag.rxIds = rxIds;
            await this.stdDrugbag.setData(d, rxIds);
          }
          
          html += await new Promise<string>((a, b) => {
            setTimeout(async () => {
              if(isCmsDrugbag){
                a(this.cmsReport.getHtml());
              }else{
                a(this.stdDrugbag.getHtml());
              }
              // var h = this.stdDrugbag.getHtml();
              
              // a(h);
            }, 0);
          });
          // }
        };
        rs(html);

        break;
      // 連處
      case 5:
        if (this.param.RepeatPresCode){
          // 動態收據元件
          try{


            await this.cmsPresReport.getReport(this.param.RepeatPresCode)
            await this.cmsPresReport.setRegId(regId);
            setTimeout(async () => {
              var html = await this.cmsPresReport.getHtml();
              rs(html);
            }, 0);
          }catch(e){
            this.notify.showError(e)
          }
        }else{
          // 一般收據元件
          await this.repPres.setRegId(regId);
          setTimeout(() => {
            var html = this.repPres.getHtml();
            rs(html);
          }, 0);
        }

        break;
      case 6:
        await this.labOutclinic.setRegId(regId);
        setTimeout(() => {
          var html = this.labOutclinic.getHtml();
          rs(html);
        }, 0);
        break;
      case 81: // FOR Code測試 ok的話回回到一般交付調劑處方籤
        await this.repPres.setRegId(regId);
        setTimeout(() => {
        var html = this.repPres.getHtml();
        rs(html);
        }, 1);
        break;
      case 101:
        await this.register.setRegId(regId);
        setTimeout(() => {
          var html = this.register.getHtml();
          rs(html);
        }, 0);
        break;
      case 809:
        await this.ctrdrugs.setRegId(regId);
        setTimeout(async () =>{
           var html = await this.ctrdrugs.getHtml();
          //  console.log(html);
           rs(html);
        },0);
        break;
      //連處
      default:
        rs('');
    }
    return prom;
  }

}
