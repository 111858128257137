

import { Rx } from "src/app/services/api-service/rx/rx";
import { ValueTextPair } from "src/app/shared/models/value-text-pair";

/** 門診醫令輸入Grid 模型 */
export class OrderModel {
    /**
     *
     */
    constructor() {
      this.Id = 0;
      this.RxId = 0;
      this.SPRule = null;
      this.QtyRule = 0;
      this.ToothPos = null;
      this.ToothFace = null;
      this.PCS = null;
      this.TreatCard = null;
      this.StartTreatDate = null;
      this.Plus = null;
      this.QTY = null;
      this.IPrice = null;
      this.Price = null;
      this.Sort = 0;
      this.Dose = null;
      this.DailyDose = null;
      this.TotalDose = null;
      this.Days = null;
      this.TotalBox = null;
      this.FreqQTY = null;
      this.InfoDays = null;
      this.DrugsPerBox = null;
      this.RxType = 0;
      this.CalPrice = null;
      this.RsCode = '';
      this.RxClass = '';
      this.DispTP = null;
      this.RxCode = '';
      this.StdCode = '';
      this.ProdName = '';
      this.SyrupNote = '';
      this.Unit = '';
      this.Freq = '';
      this.Way = '';
      this.BoxUnit = '';
      this.Remark = '';
      this.ATC = '';
      this.OrigOrderCode = '';
      this.STime = '';
      this.ETime = '';
      this.MedID = '';
      this.MedIDName = '';
      this.IsFromHelper = false;
      this.NeedExecutor = false;
      this.NeedExecuteDate = false;
      this.NeedExecuteTime = false;
      this.Additions = null
      this.SDate = null;
      this.EDate = null;
      this.FilterExecutor = null;
      this.Rx = null;
    }
    Id: number;
    // 畫面顯示之欄位
    RxClass: string;
    Sort: number;
    DispTP: string;// OrderDispensingTypeEnum; 來源是systemCode...
    SPRule: number;
    RxId: number;
    RxCode: string;
    StdCode: string;
    ProdName: string;
    IPrice: number;
    Price: number;
    SyrupNote: string;
    QtyRule: number;
    ToothPos?: string;
    ToothFace?: string;
    PCS?: string;
    TreatCard?: string;
    StartTreatDate?: Date;
    Plus?: number;
    QTY: number;
    Unit: string;
    Dose: number;
    DailyDose: number;
    TotalDose: number;
    Freq: string;
    Way: string;
    Days: number;
    TotalBox: number;
    BoxUnit: string;
    Remark: string;
    // 非畫面顯示之欄位
    FreqQTY: number;
    InfoDays: number;
    DrugsPerBox: number;
    RxType: number;
    ATC: string;
    // 輔助欄位
    OrigOrderCode: string;
    IsFromHelper: boolean;
    //SelfDispensing: [''],
    Additions: any;
    SDate: Date;
    EDate: Date;
    STime: string;
    ETime: string;
    MedID: string;
    MedIDName: string;
    FilterExecutor: ValueTextPair[];
    NeedExecutor: boolean;
    NeedExecuteDate: boolean;
    NeedExecuteTime: boolean;
    RsCode: string;
    CalPrice: number;
    // 提供各類規則計算的參考,完整的Rx物件
    Rx:Rx;
    /** 備註2 挪用 */
    ChiFormCode:string;
  }
  