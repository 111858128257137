export class SYS101Params {
  GridTitleColor: string;
  GridPaginatorColor: string;
  GridRowOdd: string;
  GridRowEven: string;
  GridRowSelected: string;
  GridRowHover: string;
  SaveFileAddPassword: boolean;
  UploadFileSettings: boolean;
  UploadFileMIMETypes: string;
  UploadFileMultiSelect: boolean;
  UploadFileMaxFileLimit: number;
  UploadFileMaxFileSizeMB: number;
  AnnouncementUploadFileSettings: boolean;
  AnnouncementUploadFileMIMETypes: string;
  AnnouncementUploadFileMultiSelect: boolean;
  AnnouncementUploadFileMaxFileLimit: number;
  AnnouncementUploadFileMaxFileSizeMB: number;
  SalesOrderCloseEditTime: number;
  NotifyPosition:string;
  /**
   *
   */
  constructor() {
    this.GridTitleColor = "#FFF";
    this.GridPaginatorColor = "#FFF";
    this.GridRowOdd = "#FFF";
    this.GridRowEven = "#FFF";
    this.GridRowSelected = "#DFE7F2"
    this.GridRowHover = "#EAEAEA";
    this.SaveFileAddPassword = true;
    this.UploadFileSettings = true;
    this.UploadFileMIMETypes = 'application/pdf, image/jpeg, image/png';
    this.UploadFileMultiSelect = false;
    this.UploadFileMaxFileLimit = 1;
    this.UploadFileMaxFileSizeMB = 10;
    this.AnnouncementUploadFileSettings = true;
    this.AnnouncementUploadFileMIMETypes = 'application/pdf, image/jpeg, image/png';
    this.AnnouncementUploadFileMultiSelect = false;
    this.AnnouncementUploadFileMaxFileLimit = 1;
    this.AnnouncementUploadFileMaxFileSizeMB = 10;
    this.SalesOrderCloseEditTime = 5;
  }
}
