import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HistService } from 'src/app/hist/services/hist.service';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { RegisterConst } from 'src/app/shared/constants/register-const';
import { MedicationWayAndReceiptReportComponent } from 'src/app/shared/components/report/medication-way-and-receipt-report/medication-way-and-receipt-report.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SessionService } from 'src/app/services/session.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { BaseConfig } from 'src/app/services/base-config.service';
import { DispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.css']
})
export class PrintDialogComponent implements OnInit {

  @ViewChild('medicationWayAndReceipt') medicationWayAndReceiptComponent: MedicationWayAndReceiptReportComponent;

  @Input() isOpenDialog: boolean = false;
  @Input() isDemoMode: boolean = false;
  @Input()
  set regItem(reg:RegisterEditDto){
   if (reg){
    this.reg = reg;
    this.getPrintParaMeter();
   }
  }
  get regItem() {
    return this.reg;
  }
  reg : RegisterEditDto;
  @Input() mode: 'OPD' | 'REG' | 'PAT' | '' = '';

  @Output() close: EventEmitter<void> = new EventEmitter();

  formHelper: FormHelper<RegisterPrintSwitch>;

  formGroup: FormGroup = this.formBuilder.group({
    IsPrintRegister: [false], // 掛號單
    IsPrintPrescription: [true], // 門診處方
    IsPrintDetail: [false], // 門診明細及收據
    IsPrintWay: [true], // 服法及收據
    IsPrintExam: [false], // 院內檢驗單
    IsPrintDispPrescription: [false], // 交付調劑處方箋
    IsPrintDispExam: [false], // 交付檢驗單
    IsPrintDrugBag: [false], // 列印藥袋
    IsPrintDispPrescriptionQRcode: [false], // 交付調劑處方箋 QR Code測試
    IsPrintCtrDrugs: [false] //管制藥品處方箋
  });

  // 網頁列印要產生的div頁面
  sizePage = {
    width: 21, //cm
    height: 30.5 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0, //cm
    left: 0 //cm
  };

  registerConst = RegisterConst;

  isopenCodePin: boolean = false;
  hpcCodePin: string = '';
  isShowQRcodeTest = BaseConfig.isDebug(); //若是正式台，則預設不顯示QRcode列印


  constructor(private formBuilder: FormBuilder,
    private formHelperService: FormHelperService,
    private printService: LocalPrintService,
    private notification: EasyNotificationService,
    private histApi: HistApi,
    private histService: HistService,
    private clinicData: ClinicDataService,
    private sessionService: SessionService,
    private hcrService: HcrService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.Init();
  }

  async Init() {
    this.createFormGroup();
  }

  createFormGroup() {
    this.formHelper = this.formHelperService.Create<RegisterPrintSwitch>().build({
      IsPrintRegister: [false, null], // 掛號單
      IsPrintPrescription: [true, null], // 門診處方
      IsPrintDetail: [false, null], // 門診明細及收據
      IsPrintWay: [true, null], // 服法及收據
      IsPrintExam: [false, null], // 院內檢驗單
      IsPrintDispPrescription: [false, null], // 交付調劑處方箋
      IsPrintDispExam: [false, null], // 交付檢驗單
      IsPrintDrugBag: [false, null], // 列印藥袋
      IsPrintDispPrescriptionQRcode: [false, null], // 交付調劑處方箋 QR Code測試
      IsPrintCtrDrugs: [false, null] //管制藥品處方箋
    });
  }

  onPrint() {
    if (this.isDemoMode) {
      this.printForDemo(this.regItem);
    } else {
      this.onPrintConfirm(this.regItem);
    }
    this.isOpenDialog = false;
  }

  onCancel() {
    this.handleDialogClose();

  }

  async onPrintConfirm(reg: RegisterEditDto) {
    if (this.formHelper.Value.IsPrintRegister) {   // 列印 掛號單
      this.printService.printRegisterForm(reg.Id);
    }

    if (this.formHelper.Value.IsPrintPrescription) {   // 列印 一般處方
      this.printService.printPrescription(reg.Id);
    }

    if (this.formHelper.Value.IsPrintDetail) {   // 列印收據 明細
      this.printService.printReceipt(reg.Id);
    }

    if (this.formHelper.Value.IsPrintWay) { //列印服法
      this.printService.printWay(reg.Id);
      
    }

    if (this.formHelper.Value.IsPrintDispPrescription) {   // 列印交付連處
      var histRcd = await this.histApi.getWholeHist({ regId: reg.Id });
      let countOpt = this.histService.EditOptions.p37;
      let RepeatPres = '';
      // 慢箋時讀p37決定張數
      if (histRcd.Hist.CntPresTot) {
        // "加"印1-9張
        if (/[1-9]/.test(countOpt)) {
          var count = parseInt(countOpt);
          for (var i = 0; i <= count; i++) {
            await this.printService.printRepeatPres(histRcd.Register.Id);
          }
        } else if (countOpt.toLowerCase() == 'z') {
          //總張數根據慢箋總次
          if (/[0-9]/.test(histRcd.Hist.CntPresTot)) {
            var count = parseInt(histRcd.Hist.CntPresTot);
            for (var i = 0; i < count; i++) {
              await this.printService.printRepeatPres(histRcd.Register.Id);
            }
          } else {
            // 無法解析 一張
            await this.printService.printRepeatPres(histRcd.Register.Id);
          }
        } else {
          //預設印一張
          await this.printService.printRepeatPres(histRcd.Register.Id);
        }
      } else {
        //非慢箋一張
        await this.printService.printRepeatPres(histRcd.Register.Id);
      }
    }

    if (this.formHelper.Value.IsPrintDispPrescriptionQRcode) {
      await this.setHcaSession();
      this.printService.printRepeatPresTest(reg.Id);
    }

    if (this.formHelper.Value.IsPrintDrugBag) {  // 列印藥袋
      var histRcd2 = await this.histApi.getWholeHist({ regId: reg.Id });
      this.printService.printDrugbag(reg.Id, histRcd2.Hist.HRxs);
    }

    if (this.formHelper.Value.IsPrintDispExam) {   // 列印 交付檢驗單
      this.printService.printLabOutClinic(reg.Id);
    }
    if (this.formHelper.Value.IsPrintCtrDrugs) { //管制藥品處方箋

      let ctrDrugs = await this.printService.getCtrdrugs(reg.Id);
      if (ctrDrugs.indexOf('[#NODATA#]') >= 0) {
        this.notification.showWarning('尚未設定管制藥品資料,無法列印');
      } else {
        this.printService.printCtrdrugs(reg.Id);
      }
    }

    this.notification.showInfo('您所選取的單據已補印完成');
  }

  async printForDemo(reg: RegisterEditDto) {
    var html = '';
    if (this.formHelper.Value.IsPrintDetail) {
      let ReceiptContent = await this.printService.getReceipt(reg.Id);
      html += this.createHTMLPage(ReceiptContent);
    }
    if (this.formHelper.Value.IsPrintPrescription) {
      let prescriptionContent = await this.printService.getPrescription(reg.Id);
      html += this.createHTMLPage(prescriptionContent);
    }
    if (this.formHelper.Value.IsPrintDispPrescription) {
      var histRcd = await this.histApi.getWholeHist({ regId: reg.Id });
      let countOpt = this.histService.EditOptions.p37;
      let RepeatPres = '';
      // 慢箋時讀p37決定張數
      if (histRcd.Hist.CntPresTot) {
        // "加"印1-9張
        if (/[1-9]/.test(countOpt)) {
          var count = parseInt(countOpt);
          for (var i = 0; i <= count; i++) {
            RepeatPres += await this.printService.getRepeatPres(histRcd.Register.Id);
          }
        } else if (countOpt.toLowerCase() == 'z') {
          //總樟樹根據慢箋總次
          if (/[0-9]/.test(histRcd.Hist.CntPresTot)) {
            var count = parseInt(histRcd.Hist.CntPresTot);
            for (var i = 0; i < count; i++) {
              RepeatPres += await this.printService.getRepeatPres(histRcd.Register.Id);
            }
          } else {
            // 無法解析 一張
            RepeatPres += await this.printService.getRepeatPres(histRcd.Register.Id);
          }
        } else {
          //預設印一張
          RepeatPres += await this.printService.getRepeatPres(histRcd.Register.Id);
        }
      } else {
        //非慢箋一張
        RepeatPres += await this.printService.getRepeatPres(histRcd.Register.Id);
      }
      html += RepeatPres;
    }
    if (this.formHelper.Value.IsPrintDrugBag) {    // 列印藥袋
      let drugBagContent = await this.printService.getDrugbag(reg.Id);
      html += this.createHTMLPage(drugBagContent);
    }
    if (this.formHelper.Value.IsPrintCtrDrugs) {    // 管制藥品處方箋
      let ctrDrugs = await this.printService.getCtrdrugs(reg.Id);
      if (ctrDrugs.indexOf('[#NODATA#]') >= 0) {
        this.notification.showWarning('尚未設定管制藥品資料,無法列印');
      } else {
        html += this.createHTMLPage(ctrDrugs);
      }
    }
    // 有內容才列印
    if (html.length > 0) {
      let htmlContent = "<HTML><head></head><BODY style='word-wrap:break-word' onload='window.print();window.close();'>" + html + "</BODY></HTML>";
      var printPage = window.open("", "printing", "");
      printPage.document.open();
      printPage.document.write(htmlContent);
      printPage.document.close();
    }
    this.notification.showInfo('您所選取的單據已補印完成');
  }

  createHTMLPage(content: string): string {
    return `<div
            style="
            margin: 0px auto;
            height: ` + this.sizePage.height + `cm;
            width: ` + this.sizePage.width + `cm;
            padding-top: ` + this.paddingPage.top + `cm;
            padding-right: ` + this.paddingPage.right + `cm;
            padding-bottom: ` + this.paddingPage.bottom + `cm;
            padding-left: ` + this.paddingPage.left + `cm;
            display: block;
            margin: 0px auto;
            box-sizing: border-box;
            ">
            <div class="content">
            ` + content + `
            </div>
        </div>\n`;
  }

  async getPrintParaMeter() {
    //取得預設參數
    var hst001 = await this.clinicData.getParam("HST001");

    let isPrintPrescription = hst001.IsPrintPrescription;
    let isPrintDetail = hst001.IsPrintDetail;
    let isPrintWay = hst001.IsPrintWay;
    let isPrintExam = hst001.IsPrintExam;
    let isPrintDispPrescription = hst001.IsPrintDispPrescription;
    let isPrintDispExam = hst001.IsPrintDispExam;
    let isPrintDrugBag = hst001.IsPrintDrugBag;
    let isPrintCtrDrugs = hst001.IsPrintCtrDrugs;

    //進階處理
    var printDrugInfo = await this.histApi.getPrintDrug(this.regItem.Id);
    if (printDrugInfo != null) {
      let isoutOutClinic = printDrugInfo?.DispTP == DispensingTypeEnum.OutOfClinic || printDrugInfo.DispTP == DispensingTypeEnum.Clinic;

      isPrintCtrDrugs = printDrugInfo?.IsUseCTRDrugs && isPrintCtrDrugs;
      isPrintDispExam = isPrintDispExam && printDrugInfo.IsRxTypeLab;
      isPrintDispPrescription = (printDrugInfo?.CntPresNo == '1' || (isoutOutClinic && printDrugInfo.IsRxTypeDrug && isPrintDispPrescription));
      isPrintExam = false;
      if (printDrugInfo?.DispTP == DispensingTypeEnum.NoNeed) {
        isPrintDrugBag = false;
      }
    }
    //設定回FormHelper
    this.formHelper.FormGroup.get("IsPrintPrescription").setValue(isPrintPrescription);
    this.formHelper.FormGroup.get("IsPrintDetail").setValue(isPrintDetail);
    this.formHelper.FormGroup.get("IsPrintWay").setValue(isPrintWay);
    this.formHelper.FormGroup.get("IsPrintExam").setValue(isPrintExam);
    this.formHelper.FormGroup.get("IsPrintDispPrescription").setValue(isPrintDispPrescription);
    this.formHelper.FormGroup.get("IsPrintDispExam").setValue(isPrintDispExam);
    this.formHelper.FormGroup.get("IsPrintDrugBag").setValue(isPrintDrugBag);
    this.formHelper.FormGroup.get("IsPrintCtrDrugs").setValue(isPrintCtrDrugs);
  }

  handleDialogClose() {
    this.close.emit();
  }

  qrcodeChange(evt: MatCheckboxChange) {
    if (evt.checked) {
      var sessionData = this.sessionService.getData('doctorPinCode');
      this.isopenCodePin = true;
      this.hpcCodePin = sessionData?.PinCode || '';
    }
  }

  async onHpcCodeDialogClose(status: string) {
    if (status != 'close' && status != 'cancel') {
      var hcaInitialization = await this.hcrService.VisionApi.InitializationAndCheckPasswd(this.hpcCodePin);
      if (hcaInitialization.StatusCode != 0) {
        this.notification.showError(hcaInitialization.ErrorMsg);
        if (hcaInitialization.StatusCode != 9062)
          this.formHelper.FormGroup.get("IsPrintDispPrescriptionQRcode").setValue(false);
      } else {
        var feedback = hcaInitialization.Data;
        this.sessionService.setData('doctorPinCode', { Uesr: '', PinCode: this.hpcCodePin })
        this.sessionService.setData('hcaModule', { InitModule: feedback.InitModule, InitSession: feedback.InitSession })
      }
    }
    this.isopenCodePin = false;
  }

  async setHcaSession() {
    var hcaModule = this.sessionService.getData('hcaModule');

    if (hcaModule?.InitModule > 0 && hcaModule.InitSession > 0) {
      return;
    }

    var hcaInitialization = await this.hcrService.VisionApi.InitializationAndCheckPasswd(this.hpcCodePin);

    if (hcaInitialization.StatusCode != 0) {
      this.notification.showError(hcaInitialization.ErrorMsg);
      if (hcaInitialization.StatusCode != 9062) this.formHelper.FormGroup.get("IsPrintPrescription").setValue(false);
    } else {
      var feedback = hcaInitialization.Data;
      this.sessionService.setData('doctorPinCode', { Uesr: '', PinCode: this.hpcCodePin })
      this.sessionService.setData('hcaModule', { InitModule: feedback.InitModule, InitSession: feedback.InitSession })
    }

    this.cdr.detectChanges();
  }
}

export class RegisterPrintSwitch {
  IsPrintRegister: boolean;
  IsPrintPrescription: boolean;
  IsPrintDetail: boolean;
  IsPrintWay: boolean;
  IsPrintExam: boolean;
  IsPrintDispPrescription: boolean;
  IsPrintDispExam: boolean;
  IsPrintDrugBag: boolean;
  IsPrintDispPrescriptionQRcode: boolean;
  IsPrintCtrDrugs: boolean;
}
