import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegisterResizeService {
  private renderer: Renderer2;

  fontGreater: boolean = false;
  bigRatio: boolean = window.innerWidth < 1251;
  newRegisterWidth: number;
  newRegisterOutWidth: number;
  newRegisterHeight: string;
  scaleNum: number;
  bodyHeight = document.body.clientHeight;
  isPopularScreen: boolean = window.innerWidth >= 1600;
  /** 病歷區行高 */
  histAreaHeight: string = '';
  /** 特別記載行高 */
  ccRmHeight: string = '';


  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  onResize(newRegisterRef: ElementRef<HTMLElement>, newRegisterOutRef: ElementRef<HTMLElement>) {
    this.getRatio();
    this.getPopularScreen();
    this.bodyHeight = this.renderer.selectRootElement('body', true).clientHeight;
    this.rescale(newRegisterRef, newRegisterOutRef);
  }

  getRatio() {
    this.bigRatio = window.innerWidth < 1251;
  }

  getPopularScreen() {
    this.isPopularScreen = window.innerWidth >= 1600;
  }

  rescale(newRegisterRef: ElementRef<HTMLElement>, newRegisterOutRef: ElementRef<HTMLElement>): void {
    const elem = newRegisterRef.nativeElement;
    const elemOut = newRegisterOutRef.nativeElement;
    let realScales = elem.offsetWidth;
    let i = 0;
    const height = elem.offsetHeight;
    const width = elem.offsetWidth;
    let outWidth = elemOut.offsetWidth;

    if (realScales > 0) {
      let scalex = parseFloat(elem.getAttribute('scalex') || '1');
      let scaley = parseFloat(elem.getAttribute('scaley') || '1');
      outWidth = elemOut.offsetWidth;
      // padding 4px 10px , scroll bar 10
      this.newRegisterOutWidth = outWidth - 20 - 10;// - 95;

      i = this.newRegisterOutWidth / realScales;
      this.scaleNum = i;
      scalex = this.scaleNum;
      scaley = this.scaleNum;
      const newHeight = height * scaley;
      this.newRegisterHeight = newHeight + 5 + 'px';
      const newWidth = width * scalex;
      elem.style.transform = `scale(${scalex}, ${scaley})`;
      document.documentElement.style.setProperty('--reg-scale', scaley.toString())
      this.newRegisterWidth = newWidth;

      // 下方病歷區高度 => scroll到底要正好上方對齊IC行
      // 取得電話行的高度
      var phoneRowHeight = (elem.children.item(0) as HTMLElement).offsetHeight
      // 掛號資料扣除電話行高
      var registerRigionHeight = elem.offsetHeight - phoneRowHeight;

      var histAreaHeightPx = (this.bodyHeight - 40 - registerRigionHeight * scaley);
      this.histAreaHeight = histAreaHeightPx + 'px';

      console.log("histAreaHeight",this.histAreaHeight);
      // 醫令/特別記載標題行高 + margin
      this.ccRmHeight = (histAreaHeightPx - 30) + 'px';
    }
  }
}
