import { Component, Input, OnInit } from '@angular/core';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HRxViewDto, HistView } from 'src/app/hist/models/hist-view';
import { Prescription } from 'src/app/opd/prescriptions/models/prescription';
import { UserCache } from 'src/app/services/user-cache';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { PricingApi } from 'src/app/services/api-service/pricing/pricing-api';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { MainSetView } from 'src/app/services/api-service/rx/main-set-view';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';
import { Dosage } from '../../../../opd/dosage/models/dosage';
import { ClinicTypeEnum } from 'src/app/enums/ClinicTypeEnum';
enum DosageEnum {
  Dose = '1',
  DailyDose = '2',
  TotalDose = '3',
  OtherDose = '4'
}

@Component({
  selector: 'app-shared-hist-view-content',
  templateUrl: './hist-view-content.component.html',
  styleUrls: ['./hist-view-content.component.css']
})



export class HistViewContentComponent implements OnInit {
  histView: HistView;
  generalOrderHrx: HRxViewDto[] = [];
  dentalOrderHrx: HRxViewDto[] = [];
  RegDate: string;
  customStyle: any;
  hst001: HST001Params;
  histOrder: number[] = [];
  KDvalue: string;
  //gridHint;
  /** 主治=>SPTX1~SPTX4 */
  //allSPTX = '';
  /** 給類-對應名 */
  //payTPName = '';
  /** 案類-對應名 */
  //caseName = '';
  /** 是否顯示執行人/時間 */
  isShowRxUser = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  /** 醫令區是否顯示日量 */
  isShowDose  = '1';
  /** 案類-對應名 */
  isTmplView = false;

  /** 居護收案別e */
  paySysAOpt: ValueTextPair[] = [{ value: 'NG', text: '一般居家' }, { value: 'NI', text: '整合照護' }, { value: 'N4', text: '呼吸器照護' }];

  regITypeOpt: ValueTextPair[] = [{ value: '0', text: '自費' }, { value: '1', text: 'IC卡' }, { value: '2', text: '異掛' }, { value: '3', text: '欠卡' }, { value: '13', text: '還卡' }, { value: '4', text: '代檢' }];

  isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  isDental = (UserCache.getLoginUser().Clinic.Type == ClinicTypeEnum.DentistClinic);
  nhiPayDetail: ValueTextPairNumberValue[] = [];
  @Input() ClinicName: any;
  @Input() showNhiPay = false;

  @Input() customClass?: string = '';

  @Input() isNarrow: boolean = false;

  @Input()
  set HistView(data: HistView) {
    // console.log('vvv',data);
    this.customStyle = '';
    if (data) {
      //let sortedHRxs = data.HRxs.sort(((a, b) => (a.Sort > b.Sort) ? 1 : ((a.Sort < b.Sort) ? -1 : 0)));
      //data.Hist.HRxs = sortedHRxs;
      // 主治
      //var tmpSPTX = [data.Hist.SPTX1, data.Hist.SPTX2, data.Hist.SPTX3, data.Hist.SPTX4];
      //this.allSPTX = tmpSPTX.filter(f => f != null && f != '').join(',');
      // 取相關
      //this.payTPName = this.mapping(this.opts.PayTP, data.Hist.PayTP);
      //this.caseName = this.mapping(this.opts.CaseTP, data.Hist.Case);
      if (data.HRxs?.length > 0) {
        data.HRxs.forEach(ele => {
          ele.MedIDNameAndDateTime = this.getRxUserAndDateTime(ele, data);
        });
      }
      if (this.showNhiPay) {
        this.nhiPayDetail = [];
        // var nameMap = OrderTypeDesc;
        for (let k in data.NhiPayDetail) {
          // var enumValue = parseInt( k.substring(1));
          // var name = nameMap.find(x=>x.value == enumValue).text; .replace('藥費部份負擔','藥')
          this.nhiPayDetail.push({ text: data.NhiPayDetail[k].Name, value: data.NhiPayDetail[k].Price });
        }
      }
      this.histView = data;

      if (this.isDental && data.HRxs?.length > 0) {
        let dentalRxType = [ 5, 6, 7, 8, 9, 10, 11 ];
        this.dentalOrderHrx = this.histView.HRxs.filter(x => dentalRxType.some(s => s == x.RxType));
        this.generalOrderHrx = this.histView.HRxs.filter(x => !dentalRxType.some(s => s == x.RxType));
      }

      if(this.histView.PE) { this.histView.PE=this.histView.PE.trim(); }
      //this.RegDate = DateHelper.formatDate(this.histView.Register.RegDate);

      if (this.histView.NhiSPExtension)
      {
        this.KDvalue = this.histView.NhiSPExtension.match(/\[KD=(.*?)\]/)[1];
        this.KDvalue = this.KDvalue.replace(/:/g, '：').replace(/;/g, '　　');
      }
    }
  }

  @Input() minHist = false;

  @Input()
  set Prescription(data: Prescription) {
    this.customStyle = { 'height': '100px', 'overflow-y': 'scroll' };

    if (data) {
      const tempHistView = new HistView();
      // tempHistView.Hist = new Hist();
      // tempHistView.Hist.CC = data.CC;
      // tempHistView.Hist.PE = data.PE;
      // tempHistView.Hist.ChiAsk = data.ChiAsk;
      // tempHistView.Hist.ChiBianZheng = data.ChiBianZheng;
      // tempHistView.Hist.ChiLook = data.ChiLook;
      // tempHistView.Hist.ChiPulse = data.ChiPulse;
      // tempHistView.Hist.ChiTreatRule = data.ChiTreatRule;
      // tempHistView.Hist.Dx1Name = data.Diagnosis1Name;
      // tempHistView.Hist.Dx2Name = data.Diagnosis2Name;
      // tempHistView.Hist.Dx3Name = data.Diagnosis3Name;
      // tempHistView.Hist.Dx4Name = data.Diagnosis4Name;
      // tempHistView.Hist.Dx5Name = data.Diagnosis5Name;
      // tempHistView.Hist.Dx6Name = data.Diagnosis6Name;
      this.histView = tempHistView;
    } else {
      this.histView = new HistView();
    }
  }

  @Input()
  set MainSet(data: MainSetView) {
    this.customStyle = '';
    this.isTmplView = true;
    if (data) {
      // console.log(data);
      const tempHistView = new HistView();
      //tempHistView.Hist = new Hist();
      tempHistView.CC = data.CC;
      tempHistView.PE = data.PE;
      tempHistView.DxName =[];
      tempHistView.SurName =[];
      if(data.Dx1){ tempHistView.DxName.push(data.Dx1+" "+data.Dx1Name); }
      if(data.Dx2){ tempHistView.DxName.push(data.Dx2+" "+data.Dx2Name); }
      if(data.Dx3){ tempHistView.DxName.push(data.Dx3+" "+data.Dx3Name); }
      if(data.Dx4){ tempHistView.DxName.push(data.Dx4+" "+data.Dx4Name); }
      if(data.Dx5){ tempHistView.DxName.push(data.Dx5+" "+data.Dx5Name); }
      if(data.Dx6){ tempHistView.DxName.push(data.Dx6+" "+data.Dx6Name); }
      if(data.Sur1){ tempHistView.DxName.push(data.Sur1+" "+data.Sur1Name); }
      if(data.Sur2){ tempHistView.DxName.push(data.Sur2+" "+data.Sur2Name); }
      if(data.Sur3) {tempHistView.DxName.push(data.Sur3 + " " + data.Sur3Name); }

      tempHistView.HRxs = [];
      if (data.Details) {
        for (const odr of data.Details) {
          const tempOrder = new HRxViewDto();
          //tempOrder.RxCode = odr.Rx.RxCode;
          tempOrder.ProdName = odr.ProdName;
          tempOrder.Dose = odr.Dose;

          //UI的量用QTY欄位但是套餐沒有，先把次量帶過去
          tempOrder.QTY = odr.Dose

          tempOrder.Freq = odr.Frequency;
          tempOrder.DailyDose = odr.DailyDose;
          tempOrder.TotalDose = odr.TotalDose;
          tempOrder.Days = odr.Days;
          tempOrder.SPRule = odr.SpecialRule;
          tempOrder.DispTP = odr.DispTP;
          tempHistView.HRxs.push(tempOrder);
        }
      }
      this.histView = tempHistView;
      if (this.isDental) {
        let dentalRxType = [ 5, 6, 7, 8, 9, 10, 11 ];
        this.dentalOrderHrx = this.histView.HRxs.filter(x => dentalRxType.some(s => s == x.RxType));
        this.generalOrderHrx = this.histView.HRxs.filter(x => !dentalRxType.some(s => s == x.RxType));
      }
    } else {
      this.histView = new HistView();
    }
  }
  get dosageEnum() {
    return DosageEnum;
  }
  constructor(private clinicDataService: ClinicDataService,
    private pricingApi: PricingApi,
  ) { }

  async ngOnInit() {
    this.hst001 = await this.clinicDataService.getParam('HST001');
    this.histOrder = this.hst001?.HistOrder;
    if (this.hst001?.HistOrder) {
      this.histOrder = this.hst001?.HistOrder;
    } else {
      this.histOrder = [1, 2, 3, 4, 5];
    }
    /** 取得院所量法規則 */
    this.isShowDose = (Object.values(DosageEnum) as string[]).includes(this.hst001?.DosageRule)?this.hst001?.DosageRule: DosageEnum.Dose;
  }

  // public showTooltip(e: MouseEvent): void {
  //   const element = e.target as HTMLElement;
  //   if (element.nodeName === 'TD' || element.nodeName === 'TH') {
  //     //&& element.offsetWidth < element.scrollWidth) {
  //     this.gridHint = element.innerText;
  //   } else {
  //     this.gridHint = null;
  //   }
  // }

  /** 取得執行人/時間 */
  getRxUserAndDateTime(dataItem: HRxViewDto, histViewData: any) {
    try {
      var sdate = "";
      var edate = "";
      var regDate = "";
      // 看診日
      if (!isNullOrUndefined(histViewData.RegDate)) {
        regDate = DateHelper.getROCDateString(new Date(histViewData.RegDate));
      }
      //執行起始日時間
      if (!isNullOrUndefined(dataItem.BeginDate)) {
        var tmpSdate = new Date(dataItem.BeginDate);
        var sdateRoc = DateHelper.getROCDateString(tmpSdate);
        var stime = DateHelper.getHourMinTime(tmpSdate, true);
        sdate = sdateRoc;
        //如果執行起始時間不等於0:0才顯示
        if (stime != "0:0" && stime != "00:00") {
          sdate = sdateRoc + " " + stime;
        }
      }
      //執行結束日時間
      if (!isNullOrUndefined(dataItem.EndDate)) {
        var tmpEdate = new Date(dataItem.EndDate);
        var edateRoc = DateHelper.getROCDateString(tmpEdate);
        var etime = DateHelper.getHourMinTime(tmpEdate, true);
        edate = edateRoc;
        //如果執行結束時間不等於0:0才顯示
        if (etime != "0:0" && etime != "00:00") {
          edate = edateRoc + " " + etime;
        }

      }

      //如果執行起日(時間)、結束日(時間)等於看診日，則不顯示(清空)
      if (edate == regDate && sdate == regDate) {
        edate = "";
        sdate = "";
      }

      var tmp = [dataItem.MedIDName, sdate, edate];
      return tmp.filter(f => f != "" && f != null).join('\r\n');
    } catch (error) {
      return isNullOrUndefined(dataItem.MedIDName) ? "" : dataItem.MedIDName;
    }
  }

  mapping(opt, value) {
    if (opt && value) {
      var tmp = opt.find(f => f.value == value);
      if (tmp) {
        return tmp.text;
      }
    }
  }

  displayAllergies() {
    //此段與patient-edit.component.ts的displayAllergies()一樣，若有修改請同步
    var tmp = (this.histView.DrugAllergy??'').split(';');
    var result = '';
    result += tmp.length > 0 ? (tmp[0].length > 0 ? ('[藥品代碼]:' + tmp[0] + ' ') : '') : '';
    result += tmp.length > 1 ? (tmp[1].length > 0 ? ('[ATC碼]:' + tmp[1] + ' ') : '') : '';
    result += tmp.length > 2 ? (tmp[2].length > 0 ? ('[過敏藥群組]:' + tmp[2] + ' ') : '') : '';
    // 自己打字可能會包含到;號，將其組回去
    var otherAllergies = tmp.filter((x,i)=>i>=3).join(';');
    result += otherAllergies ? '[其他]:' + otherAllergies : '';
    return result;
  }
}
