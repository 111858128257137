import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HistOrder } from '../models/hist-order';
import { HelperPadEvent, HistHelperPadService, ItemSelectedEvent, AreaSelectedEvent, SetItemEvent } from '../services/hist-helper-pad.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { OrderGridComponent } from '../order-grid/order-grid.component';
import { OrderGridDentalComponent } from '../order-grid-dental/order-grid-dental.component';
import { OrderGridTypeEnum } from '../enums/order-grid-type-enum';
import { OrderTab } from '../models/order-tab';
import { OrderData } from '../models/order-data';
import { RxClass } from '../models/rx-class';
// import { HistEditOption } from 'src/app/services/api-service/hist/hist-edit-option';
import { Subject } from 'rxjs';
// import { takeUntil } from 'rxjs/operators';
// import { HelperEvent } from '../models/helper-event';
import { HelperSourceEnum } from '../enums/helper-source-enum';
// import { HelperEventTypeEnum } from '../enums/helper-event-type-enum';
import { HistService } from '../services/hist.service';
import { OrderGridExeComponent } from '../order-grid -exec/order-grid-exec.component';
import { ValidateMsg } from '../models/hrx-validate-msg';
import { UserCache } from 'src/app/services/user-cache';
import { ClinicTypeEnum } from 'src/app/enums/ClinicTypeEnum';

@Component({
  selector: 'app-hist-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css', '../styles/hist.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersComponent implements OnInit, OnDestroy,AfterViewInit {
  @Input()
  allergies: string[] = [];
  @Input()
  displayTabs: boolean = true;
  @Input()
  maxPanelOpen:boolean = false;
  @Input() isRecordDone : boolean = false;
  recordDone:boolean;

  @Output() emitSelect = new EventEmitter<string>();

  constructor(
    private notification: EasyNotificationService,
    private padService: HistHelperPadService,
    private histService:HistService,
    private cd: ChangeDetectorRef) { }

  //#region 參數 source data --------------------
  private unsubscribe: Subject<void> = new Subject();

  get editOptions(){
    return this.histService.EditOptions;
  }

  //_orders: HistOrder[] = [];
  _orders: HistOrder[] = [];
  /** 小時鐘模式 */
  @Input()
  fillExecuteOnly: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  isCopy: boolean = false;

  @Input()
  surList: string[] = [];

  @Input()
  isReferralIn: boolean = false;

  @Input()
  isInfentUnderFour: boolean = false;

  @Input()
  patientAge: number = 0;

  /** 是否用來為顯示牙科醫令的grid */
  @Input()
  displayDentalOrders: boolean = false;

  @Input()
  set orders(orders: HistOrder[]) {
    try {
      this._orders = orders;
      this.updateOrdersToTab(this._orders)
      // 在這裡呼叫是因為後續可能有來自父層的資料變動要更新到這裡
      this.setOrdersToTab(this._orders, this.getTab());
    } catch (e) {
      this.notification.showErrorById('MSG_HistOrders3');
    }
  }
  get orders() {
    this.mergeOrdersFromTab();
    return this._orders;
  }

  getOrderNoCheck() {
    let gridOrders = this.orderGridComp.fetchOrdersNoCheck();
    var a:HistOrder[] = [];
    this.tabDatas.forEach(x=>{
      if(x.tab == this.getTab()){
        a.push(...gridOrders);
      }else{
        a.push(...x.orders)
      }
    });
    return a;
  }

  //改成async
  // getErrors():ValidateMsg[] {
  //   var ret =  []
  //   // 更新目前Tab的內容
  //   this.mergeOrdersFromTab();
  //   this.tabDatas.forEach(t=>ret.push(...t.err));
  //   return ret;
  // }

  async getErrorsExt():Promise<ValidateMsg[]> {
    var ret =  []
    // 更新目前Tab的內容
    await this.mergeOrdersFromTabExt();
    this.tabDatas.forEach(t=>ret.push(...t.err));
    return ret;
  }
  //#endregion 參數 source data --------------------

  // #region 參數 --------------------
  @ViewChild('orderGridComp') private orderGridComp: OrderGridComponent;
  @ViewChild('orderGridDentalComp') private orderGridDentalComp: OrderGridDentalComponent;
  @ViewChild('orderGridExeComp') private orderGridExeComp: OrderGridExeComponent;

  _tabOrderData: OrderData = new OrderData();
  get tabOrderData() {
    return this._tabOrderData;
  }

  _tabDentalOrderData: OrderData = new OrderData();
  get tabDenOrderData() {
    return this._tabDentalOrderData;
  }
  @Input()
  hiddenWhenOpen:boolean = false;

  // 中藥飲片TAB數量
  numberOfCnSoup = 1;

  // #endregion

  //#region Tab --------------------
  //tabs: OrderTab[] = [];
  tabDatas: OrderData[] = [];
  get tabs(){
    return this.tabDatas.map(x=>x.tab);
  }
  //#endregion
  @Input() isShowRxDialog?: boolean = false;

  ngOnInit() {
    this.createTabs();
    var tab = this.getTab();
    this.padService.focusAtOrderClass(tab.rxClass,tab.rxType);
    this.subscribe_HelperPad();
  }

  ngAfterViewInit(): void {
    var tab = this.getTab();
    this.setOrdersToTab(this._orders, tab);
  }

  /** 訂閱輔助盤 */
  subscribe_HelperPad() {
    this.padService.fromPad.subscribe(
      async (evt: HelperPadEvent) => {
        // console.log('['+new Date().toISOString()+'] '+'Order recieve event '+ JSON.stringify(evt));
        if(evt instanceof AreaSelectedEvent) {
          if(evt.tab == HelperSourceEnum.Order) {
            this.changeTabFromHelper(evt);
          }
        } else if (evt instanceof ItemSelectedEvent) {
          if(evt.type == HelperSourceEnum.Order) {
            this.histService.UIUnReady('Rx');
            this.changeTabFromHelper(evt);
            if (!evt.isInsert) {
              // 設空資料給TAB，用來清除資料。先前有用clear()但不行，因為clear會把rxClass也清除了
              //this.setOrdersToTab([], this.getTab());
              this.orderGridComp.clear();
              if (this.displayDentalOrders) this.orderGridDentalComp.clear();
            }
            if (evt.item instanceof Array) {
              //批次插入, 複製病歷用
              await this.orderGridComp.batchSetCodeAtEmpy(evt.item);
            } else {
              await this.orderGridComp.setCodeByDrugAtEmpty(evt.item, evt.data.includes, evt.isInsert);
            }
            this.histService.UIReady('Rx');
          }
        } else if (evt instanceof SetItemEvent) {
          if (evt.type == HelperSourceEnum.Order) {
            this.histService.UIUnReady('Rx');
            this.changeTabFromHelper(evt);
            if (!evt.isInsert) {
              // 設空資料給TAB，用來清除資料。先前有用clear()但不行，因為clear會把rxClass也清除了
              //this.setOrdersToTab([], this.getTab());
              this.orderGridComp.clear();
            }
            if (evt.item instanceof Array) {
              //批次插入, 複製病歷用
              await this.orderGridComp.batchSetCodeAtEmpy(evt.item);
            } else {
              await this.orderGridComp.setCodeByDrugAtEmpty(evt.item, evt.data.includes, evt.isInsert);
            }
            this.histService.UIReady('Rx');
            //this.orderGridComp.setCodeByDrugAtEmpty(evt.item,evt.data.includes,evt.isInsert);
          }
        }
      }
    );
  }

  patchOrderExec(orders: HistOrder[]) {
    this.tabDatas.forEach(t => {
      t.orders.forEach(odr => {
        var o = orders.find(x => x.RxCode == odr.RxCode && x.RxType == odr.RxType );
        if(o) {
          odr.BeginDate = o.BeginDate;
          odr.EndDate = o.EndDate;
          odr.MedID = o.MedID;
          odr.MedIDName = o.MedIDName;
          odr.MedIDNameAndDateTime = o.MedIDNameAndDateTime;
        }
      });
    });

    this.orderGridComp.refresh();
  }

  patchOrderDisp(orders: HistOrder[]) {
    this.tabDatas.forEach(t => {
      t.orders.forEach(odr => {
        var o = orders.find(x => x.RxCode == odr.RxCode && x.RxType == odr.RxType);
        if(o) {
          odr.DispTP = o.DispTP;
        }
      });
    });
    this.orderGridComp.refresh();
  }

  patchDenOrderDisp(orders: HistOrder[]) {
    this.tabDatas.forEach(t => {
      t.orders.forEach(odr => {
        var o = orders.find(x => x.RxCode == odr.RxCode && x.RxType == odr.RxType);
        if(o) {
          odr.DispTP = o.DispTP;
        }
      });
    });
    this.orderGridDentalComp.refresh();
  }

  async addOrderByHelperEvent(evt: ItemSelectedEvent) {
    this.changeTabFromHelper(evt);
    if(!evt.isInsert) {
      // 設空資料給TAB，用來清除資料。先前有用clear()但不行，因為clear會把rxClass也清除了
      this.setOrdersToTab([], this.getTab());
    }
    if (this.displayDentalOrders) {
      await this.orderGridDentalComp.setCodeByDrugAtEmpty(evt.item,evt.data.includes,evt.isInsert);
    } else {
      await this.orderGridComp.setCodeByDrugAtEmpty(evt.item,evt.data.includes,evt.isInsert);
    }
  }

  // #region public function --------------------

  clear() {
    // 再清資料
    this._orders = [];
    // 清每個tab的資料
    this.tabDatas.forEach(x=>x.orders = []);
    this.setOrdersToTab(this._orders, this.getTab());
    this.cd.detectChanges();
  }
  // #endregion public function --------------------

  // #region form --------------------
  // 把目前FormArray合併回this._orders
  mergeOrdersFromTab() {
    // 只要(1)非目前tab的資料
    // 然後把(2)目前tab資料從child component取回
    // (1)+(2)組成新的資料
    //const tab = this.getTab();
    //const filtered = this._orders.filter(x => !tab.rxType.some(t=>t== x.RxType));
    //const gridOrders = this.orderGridComp.orders;
    let gridOrders = [];
    let validateErr = [];
    if(this.fillExecuteOnly) {
      gridOrders = this.orderGridExeComp.orders
    } else {
      if (!this.displayDentalOrders) {
        gridOrders = this.orderGridComp.fetchOrders();
        validateErr = this.orderGridComp.validateOrder();
      } else {
        gridOrders = this.orderGridDentalComp.fetchOrders();
        validateErr = this.orderGridDentalComp.validateOrder();
      }

    }

    //驗證錯誤先儲存在orderData中
    var orderData = this.tabDatas.find(x => x.tab == this.getTab());
    orderData.orders = gridOrders
    orderData.err = validateErr

    this._orders = [];
    this.tabDatas.forEach(x => this._orders.push(...x.orders));
    // if (gridOrders != null && gridOrders.length > 0) {
    //   filtered.push(...gridOrders);
    // }
    // this._orders = filtered;
  }

  /**
   *多一組async，因有兩項警告要呼叫api才能判斷，原本的mergeOrdersFromTab()，有多處呼叫，不確定流程，先不變動，只是差沒判斷其中兩項警告。
   *之後了解流程後，應可把檢查這一段validateOrderExt()獨立，不要放在一起。
   * @memberof OrdersComponent
   */
  async mergeOrdersFromTabExt() {
    // 只要(1)非目前tab的資料
    // 然後把(2)目前tab資料從child component取回
    // (1)+(2)組成新的資料
    //const tab = this.getTab();
    //const filtered = this._orders.filter(x => !tab.rxType.some(t=>t== x.RxType));
    //const gridOrders = this.orderGridComp.orders;
    let gridOrders = [];
    let validateErr = [];
    if(this.fillExecuteOnly) {
      gridOrders = this.orderGridExeComp.orders
    } else {
      gridOrders = this.orderGridComp.fetchOrders();
      // 改成async
      // validateErr = this.orderGridComp.validateOrder();
      validateErr = await this.orderGridComp.validateOrderExt();
    }

    //驗證錯誤先儲存在orderData中
    var orderData = this.tabDatas.find(x=>x.tab ==  this.getTab());
    orderData.orders = gridOrders
    orderData.err = validateErr

    this._orders = [];
    this.tabDatas.forEach(x => this._orders.push(...x.orders));
    // if (gridOrders != null && gridOrders.length > 0) {
    //   filtered.push(...gridOrders);
    // }
    // this._orders = filtered;
  }

  // 把資料依據Tab過濾然後填入傳遞物件內
  setOrdersToTab(orders: HistOrder[], tab: OrderTab, triggerFocus: boolean = false) {
    if (!tab) {
      return;
    }
    var data = this.tabDatas.find(x => x.tab == tab);

    // // 必須要有新object後續才會傳遞異動
    // const newOrderData: OrderData = new OrderData();
    // newOrderData.tab = tab;
    // newOrderData.triggerFocusEvent = triggerFocus;
    // //newOrderData.orders = orders.filter(x => x.RxClass === tab.rxClass);
    // newOrderData.orders = orders.filter(x => tab.rxType.some(c=>c==x.RxType));
    //this._tabOrderData = newOrderData;
    data.triggerFocusEvent = triggerFocus;
    let dentalRxType = [ 5, 6, 7, 8, 9, 10, 11 ];
    if (!this.displayDentalOrders) {
      // 判斷是否為牙科門診使用的一般醫令(非牙科處置)區
      var dentalOrder = (UserCache.getLoginUser().Clinic.Type == ClinicTypeEnum.DentistClinic);
      if(!dentalOrder) {
        this._tabOrderData = data;
        //強制 set 因為reference沒改
        if(this.fillExecuteOnly){
          this.orderGridExeComp.orderData = this._tabOrderData;
        } else {
          this.orderGridComp.setOrderDataAsync(this._tabOrderData);
        }
      } else {
        let notDentalRxType = tab.rxType.filter(x => !dentalRxType.some(s => s === x));
        var tabGeneral = OrderTab.createTab('醫令', OrderGridTypeEnum.Default, notDentalRxType);
        const generalOrderData: OrderData = new OrderData();
        generalOrderData.tab = tabGeneral;
        generalOrderData.triggerFocusEvent = false;
        generalOrderData.orders = this._orders.filter(x => tabGeneral.rxType.some(s => s == x.RxType));
        this._tabOrderData = generalOrderData;
        this.orderGridComp.setOrderDataAsync(this._tabOrderData);
      }
    } else {
      // 如果是牙科門診的處置
      var tabDental = OrderTab.createTab('處置', OrderGridTypeEnum.Default, dentalRxType);
      const dentalOrderData: OrderData = new OrderData();
      dentalOrderData.tab = tabDental;
      dentalOrderData.triggerFocusEvent = false;
      dentalOrderData.orders = this._orders.filter(x => tabDental.rxType.some(s => s == x.RxType));
      this._tabDentalOrderData = dentalOrderData;
      this.orderGridDentalComp.setOrderDataAsync(this._tabDentalOrderData);
    }

    this.cd.detectChanges();
  }
  /** 更新每一個tab的醫令資料 */
  updateOrdersToTab(orders: HistOrder[], triggerFocus: boolean = false) {
    this.tabDatas.forEach(t => {
      t.orders = orders.filter(x => t.tab.rxType.some(c => c == x.RxType));
    });
  }
  // #endregion form --------------------

  //#region tab --------------------
  get IsDefaultGrid() {
    if(!this.getTab()) {
      // 若尚未創建tab，預設tab為OrderGridTypeEnum.Default
      return true;
    }
    if(this.getTab().orderGridType === OrderGridTypeEnum.Default) {
      return true;
    } else {
      return false;
    }
  }

  // addTab(tab: OrderTab) {
  //   this.tabs.push(tab);
  // }
  addTab(tab: OrderData) {
    this.tabDatas.push(tab);
  }

  createTabs() {
    var tabName = this.editOptions.histParams.OrderPanalTabs.split(',');
    var tabRxTypes = this.editOptions.histParams.OrderPanalTabsTypes.split(';')
                      .map((s, i) => {
                        return {
                          name:tabName[i],
                          types:s.split(',').map(t=>parseInt(t,0))
                        }});
    tabRxTypes.forEach(t => {
      var tab = OrderTab.createTab(t.name, OrderGridTypeEnum.Default, t.types);

      const newOrderData: OrderData = new OrderData();
      newOrderData.tab = tab;
      newOrderData.triggerFocusEvent = false;
      //newOrderData.orders = orders.filter(x => x.RxClass === tab.rxClass);
      newOrderData.orders = this._orders.filter(x => tab.rxType.some(c => c == x.RxType));

      //this.addTab(tab);
      this.addTab(newOrderData);
    });

    this.tabDatas[0].tab.isSelected = true;
  }

  showCnSoupTab2() {
    const odrCnSp2 = this._orders.filter(x => x.RxClass === RxClass.CnSp2);
    if (odrCnSp2 && odrCnSp2.length > 0) {
      const cnSp2 = this.getTabCnSp2();
      cnSp2.isHidden = false;
      this.numberOfCnSoup = 2;
    }
  }

  showCnSoupTab3() {
    const odrCnSp3 = this._orders.filter(x => x.RxClass === RxClass.CnSp3);
    if (odrCnSp3 && odrCnSp3.length > 0) {
      const cnSp3 = this.getTabCnSp3();
      cnSp3.isHidden = false;
      this.numberOfCnSoup = 3;
    }
  }

  getTab(): OrderTab {
    return this.tabDatas.find(x => {
      if (x.tab.isSelected) {
        return true;
      }
    })?.tab;
  }

  // getTabRxClass(): string {
  //   return this.tabs.find(x => {
  //     if (x.isSelected) {
  //       return true;
  //     }
  //   }).rxClass;
  // }

  tabCssClass(isSelected: boolean): string {
    if (isSelected) {
      return 'tab-btn tab-btn-selected';
    } else {
      return 'tab-btn';
    }
  }

  focusTab() {
    if (this.displayDentalOrders) {
      this.orderGridDentalComp.focusLastEmptyCode(false);
    } else {
      this.orderGridComp.focusLastEmptyCode(false);
    }
  }

  changeTabByClick(tab: OrderTab){
    this.mergeOrdersFromTab();
    this.tabDatas.forEach(d => {
      var t = d.tab;
      if (t.rxClass === tab.rxClass) {
        t.isSelected = true;
      } else {
        t.isSelected = false;
      }
    });
    this.padService.focusAtOrderClass(tab.rxClass,tab.rxType);
    this.setOrdersToTab(this._orders, this.getTab(),true);
  }

  onSelected(evt: string) {
    this.emitSelect.emit(evt);
  }

  /** 經由輔助盤切換項目過來的 */
  changeTabFromHelper(evt: AreaSelectedEvent|ItemSelectedEvent|SetItemEvent) {
    // console.log('['+new Date().toISOString()+'] '+'Order start change tab '+ JSON.stringify(evt));
    var rxType = evt.data.rxType;
    // 根據輔助盤事件的rxType指向與tab包含的rxType重疊最多的
    var selectedTab = this.tabDatas[0].tab;
    var maxMatchCount = 0;
    this.tabDatas.forEach(data => {
      var tab = data.tab;
      var matchCount = 0;
      tab.rxType.forEach(t=>{
        rxType.forEach(y=>{
          matchCount += y==t?1:0;
        });
      });
      if(matchCount>maxMatchCount){
        maxMatchCount = matchCount;
        selectedTab = tab;
      }
    });
    // 如果Tab不變，必須手動設定focus
    if (selectedTab == this.getTab()) {
      this.focusTab();
      return;
    }else {
      // 資料合併需在狀態改變之前
      this.mergeOrdersFromTab();
      // console.log('['+new Date().toISOString()+'] '+'Order change tab to'+ JSON.stringify(selectedTab));
      this.tabDatas.forEach(data => {
        var tab = data.tab;
        if (tab == selectedTab) {
        //if (tab.rxClass === rxClass) {
          tab.isSelected = true;
        } else {
          tab.isSelected = false;
        }
      });
      this.setOrdersToTab(this._orders, this.getTab());
    }
  }

  get hasTabSp(): boolean {
    const soupTabs = this.tabDatas.map(x=>x.tab).filter(x => x.rxClass === RxClass.CnSp1 ||
      x.rxClass === RxClass.CnSp2 ||
      x.rxClass === RxClass.CnSp3);
    if (soupTabs && soupTabs.length > 0) {
      return true;
    }
    return false;
  }

  getTabCnSp2(): OrderTab {
    const cnSoupArray = this.tabDatas.map(x=>x.tab).filter(x => x.rxClass === RxClass.CnSp2);
    if (cnSoupArray) {
      return cnSoupArray[0];
    } else {
      return null;
    }
  }

  getTabCnSp3(): OrderTab {
    const cnSoupArray = this.tabDatas.map(x=>x.tab).filter(x => x.rxClass === RxClass.CnSp3);
    if (cnSoupArray) {
      return cnSoupArray[0];
    } else {
      return null;
    }
  }

  showTabSp(cnSoup) {
    if (cnSoup) {
      cnSoup.isHidden = false;
      this.numberOfCnSoup++;
    }
  }

  onAddSoupClick() {
    let cnSoup: OrderTab;
    if (this.numberOfCnSoup === 1) {
      cnSoup = this.getTabCnSp2();
    } else if (this.numberOfCnSoup === 2) {
      cnSoup = this.getTabCnSp3();
    } else {
      // 不能再新增
      this.notification.showWarningById('MSG_HistRecord5');
    }
    this.showTabSp(cnSoup);
  }

  //#endregion

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
