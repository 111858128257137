import { Injectable } from "@angular/core";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { CustomResult } from "../../../shared/models/custom-result";
import { PageData } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { Dx } from "./dx";
import { HisSearchDx } from "./his-searchdx";

export class GetDxQuery {
  code: string;
  name: string;
  type?: 'sdx' | 'sphc' = 'sdx';
  sexCode?: number;
  acuteNote?: number;
  medDeptCode?: string;
  specItem?: string;
  pageNumber?: number;
  pageSkip?: number;
  pageSize?: number;
  diagVersion: string;
}

export class DxEditOptions {
  depart: ValueTextPair[];
  sex: ValueTextPair[];
  acuteNode: ValueTextPair[];
}
export class HistViewDxQueryDto {
  code: string;
  name: string;
  type?: 'sdxHistView' | 'sphc' = 'sdxHistView';
  pageSize: number;
  pageNumber: number;
  condititons: string[] = [];
  orCondititons: string[] = [];
  wordCondititons: string[] = [];
  isEnglish: boolean = false;
  diagVersion: string = '';
}

@Injectable({ providedIn: 'root' })
export class DxApi {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = apiClient.createHisService('opd/dx');
    this.apiService.enableLoader = false;
  }
  async Get(): Promise<Dx> {
    var ret = await this.apiService.get('Get').toPromise<Dx>();
    return ret;
  }

  async Update(data: Dx): Promise<Dx> {
    return this.apiService.put('Update', data).toPromise<Dx>();
  }

  async GetAll(code: string = '', name: string = ''): Promise<Dx[]> {
    return this.apiService.get(`GetAll`,
      {
        code: code,
        name: name,
      }).toPromise();
  }
  async GetEditOption(): Promise<DxEditOptions> {
    return this.apiService.get(`GetEditOption`).toPromise<DxEditOptions>();
  }

  async GetInCompanyCodes(query: GetDxQuery): Promise<PageData> {
    return this.apiService.get(`GetInCompanyCodes`, query).toPromise<PageData>();
  }

  async GetStanderCodes(query: GetDxQuery): Promise<PageData> {
    return this.apiService.get(`GetStanderCodes`, query).toPromise<PageData>();
  }

  async GetHistViewDxCodes(query: HistViewDxQueryDto): Promise<PageData> {
    return this.apiService.post(`GetHistViewDxCodes`, query).toPromise<PageData>();
  }
  async GetHistViewDxFromCode(code: string, diagVersion: string = ''): Promise<HisSearchDx> {
    return this.apiService.get(`GetHistViewDxFromCode?diagVersion=` + diagVersion,{
      code: code,
    }).toPromise<HisSearchDx>();
  }

  async GetPackagesByCode(code: string): Promise<ValueTextPairNumberValue[]> {
    return this.apiService.get(`GetPackagesByCode?code=` + code).toPromise<ValueTextPairNumberValue[]>();
  }

  async GetExistMedDeptCodes(): Promise<ValueTextPair[]> {
    return await this.apiService.get("GetExistMedDeptCodes").toPromise<ValueTextPair[]>();
  }

  async GetExistSpecItems(): Promise<ValueTextPair[]> {
    return await this.apiService.get("GetExistSpecItems").toPromise<ValueTextPair[]>();
  }

  async GetClinicSPHcOptions(): Promise<ValueTextPair[]> {
    return await this.apiService.get("GetClinicSPHcOptions").toPromise<ValueTextPair[]>();
  }

  async ImportNewICD102023(query:any): Promise<CustomResult>{
    var loader = this.apiService.enableLoader;
    this.apiService.enableLoader = true;
    var ret = await this.apiService.post('ImportNewICD102023', query).toPromise();
    this.apiService.enableLoader = loader;
    return ret;
  }
}
