import { Injectable } from '@angular/core';
import { WebApiService } from '../../web-api.service';
import { WebApiClient } from '../../web-api-client.service';
import { PageData, PageData2 } from 'src/app/shared/models/page-data';
import { H10 } from 'src/app/models/H10';
import { H11 } from 'src/app/models/H11';
import { ParameterSetDto } from './param-set-api';
import { H12 } from 'src/app/models/H12';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { RatingPrice } from 'src/app/hist/rating-price/models/RatingPrice';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';

/** 申報人數統計Grid資料 DTO */
export class UserInfoDTO {
  Id: number;
  Name: string;
  MainDept: string;
  CId: string;
  IsRegular: boolean;
  PositionsName: string;
  PositionType: number;
  ArriveDate?: Date;
  LeaveDate?: Date;
  PositionCode: string;
}

/** 核對申報資料 查詢條件 DTO */
export class DeclareCheckQueryDTO {
  ClinicId: number
  Date: string;
  PageSize: number;
  PageNumber: number;
  QueryList: DeclareCheckFilterDTO[];
  GetDDL: boolean;
  AmendNum: string;
  OrderBy: string;
  OrderByDesc: boolean;
}

/** 核對申報資料 查詢條件類別 DTO */
export class DeclareCheckFilterDTO {
  Type: number
  Value: string;
  IsAmountFilter: boolean;
}

/** 核對申報資料 View DTO */
export class DeclareChecViewDTO {
  /** 核對申報資料 PageData DTO */
  PageData: PageData2<DeclareCheckListDTO>;
  /** 案類 */
  CaseList: string[];
  /** 醫師 */
  DoctorList: string[];
  /** 藥師 */
  PharmList: string[];
  /** 調劑 */
  DispTPList: string[];
  /** 科別 */
  SubjectList: string[];
}

/** 門診申報xml DTO */
export class OPDxmlDataDTO {
  H10: H10
  H11List: H11[];
}

/** 參數設定+檢查申報H10,H11,H12 的Azure Table Stroage是否存在 DTO */
export class ParamSetAndCheckTableDto {
  /** 申報H10,H11,H12 的Azure Table Stroage是否存在 */
  IsTableExist: boolean
  /** 參數設定模型 */
  Parameter: ParameterSetDto;
  /** 該月份是否已有下載過xml (有xml Log) */
  HasXmlLog: boolean
}

/** 門診申報總表 報表 DTO */
export class OpdDeclareReportDTO {
  /** 總表段(H10) */
  H10: H10;
  /** 診所機構名稱 */
  ClinicName: string;
  /** 醫事機構地址 */
  ClinicAddress: string;
  /** 醫事機構電話 */
  ClinicPhone: string;
  /** 負責醫師姓名 */
  OwnerName: string;
  /** 醫療院所層級 */
  NHILevel: string;
  /** 1.西醫 2.牙醫 3.中醫 */
  CheckMedicine: number;
}
export interface DeclareCheckListDTO extends H11 {
  /** H12 醫令清單段 */
  H12List: H12[];
  /** 原醫令 */
  HRXList: HistOrder[];
  /** 園主訴 */
  CC: string;
  /** 原批價*/
  OrigDeclare: RatingPrice[]
}

/** 申報案類統計-分頁(醫師分頁才會多筆，否則目前只有一筆) 報表 DTO */
export class DeclareCaseReportPageDTO {
  /** 月份 */
  Month: string;
  /** 醫師代號(身分證) */
  DrID: string;
  /** 醫師姓名 */
  DrName: string;
  /** 每頁統計明細 */
  Detail: DeclareCaseReportDTO[];
}
/** 申報案類統計-By案類 報表 DTO */
export class DeclareCaseReportDTO {
  /** 月份 */
  Month: string;
  /** 案件分類 */
  e11cs: string;
  /** 各保別-明細 */
  Detail: DeclareCaseReportDetailDTO[];
  /** 小計明細 */
  Subtotal: DeclareCaseReportDetailDTO;
}
/** 申報案類統計-明細 報表 DTO */
export class DeclareCaseReportDetailDTO {
  /** 保別-代碼 */
  ICode: string;
  /** 保別-名稱 */
  ICodeName: string;
  //#region  3L  (NHIDeclare)
  /** 診察費 */
  ADx: number;
  /** 內服藥費 */
  ARx2: number;
  /** 外用藥費 */
  ARx3: number;
  /** 注射費 */
  ARx4: number;
  /** 注射技術費 */
  ATx5: number;
  /** 檢查費 */
  ATx6: number;
  /** 放射費 */
  ATx7: number;
  /** 復健費 */
  ATx8: number;
  /** 處置費 */
  ATx9: number;
  /** 手術費 */
  ATx10: number;
  /** 麻醉費 */
  ATx11: number;
  /** 特材費 */
  AMat: number;
  /** 輸血費 */
  ATx13: number;
  /** 透析費 */
  ATx14: number;
  /** 病房費 */
  ATx15: number;
  /** 管灌費 */
  ATx16: number;
  /** 嬰兒費 */
  ATx17: number;
  /** 精療費 */
  ATx18: number;
  /** 藥事費 */
  AMed: number;
  /** 自費總額(真的自費，非健保欄位) */
  AOwn: number;
  /** 申請總額*/
  ADcl: number;
  /** 健保總額*/
  ATot: number;
  /** 其他費1*/
  Aother1: number;
  /** 其他費2*/
  Aother2: number;
  //#endregion
  //#region  申報H11檔
  /** 申報月份 */
  e11ym: string;
  /** 案件分類 */
  e11cs: string;
  /** 醫師代號 */
  DrID: string;

  /** 申請點數(d41) */
  e11ta: number;
  /** 部分負擔費用金額(d40) */
  e1198: number;
  /** 藥事服務費(d38) */
  e1199: number;
  /** 診察費(d36) */
  e11a1: number;
  /** 特殊材料明細點數小計(d34) */
  e11ab: number;
  /** 診療明細點數小計(d33) */
  e11a6: number;
  /** 用藥明細點數小計(d32) */
  e11a2: number;
  /** 合計點數(d39) */
  e11tt: number;
  //#endregion
  Count: number;
  RoomCode: string;
  Case: string;
  DoctorAccount: string;
  DoctorName: string;
}

/** 健保申報明細摘要表 報表DTO */
export class OpdDeclareDetailsRptDTO {
  /** (d1)案件分類-代碼 */
  e11cs: string;
  /** (d1)案件分類-名稱 */
  e11csName: string;
  /** 每頁統計明細 */
  Detail: OpdDeclareDetailsRptSubDTO[];
  /** 合計 dto */
  Subtotal: OpdDeclareDetailsRptSubDTO;
}

export class SameTreatPerMonthListDTO  {
  /** 診斷代碼1~5 */
  PatientNo: string;
  /** 診斷病名~5 */
  PatientName: string;
  /** 醫令 */
  SeqNo: string;
  /** 醫師 */
  CusNoPerMonth: number[][];

}
/** 健保申報明細摘要表-明細 報表DTO */
export interface OpdDeclareDetailsRptSubDTO extends H11 {
  /** 診斷代碼1~5 */
  Dx: string;
  /** 診斷病名~5 */
  DxName: string;
  /** 醫令 */
  e12rx: string;
  /** 醫師 */
  Doctor: string;
}

export declare type NHIDownloadRequestDTO = {
  ClinicIdAndType: string;
  TypeCode: string;
  Timestamp: string;
  Id: string;
  DeclareMonth: string;
  sNHI_ID: string;
  sLocal_ID: string;
  QueryFileName: string;
}

export declare type NHISendRequestDTO = {
  ClinicIdAndType: string;
  TypeCode: string;
  Timestamp: string;
  Id: string;
  Path: string;
  sNHI_ID: string;
  sLocal_ID: string;
  RequestTime: string;
}
/** 申報錯誤訊息，篩選類別Key */
export class DeclareMsgFilterKey {
  static readonly Warning = "▲警告";
  static readonly Error = "★錯誤";
  static readonly All = "";
}

export class AmendGridViewModel {
  Id: number = 0;
  PatientNo: string = '';
  PatientName: string = '';
  Isqno: string = '';
  Days: number = 0;
  RegDate: string = '';
  ICode: string = '';
  Disp: string = '';
  Case: string = '';
  Pay: string = '';
  TP: string = '';
  Dept: string = '';

  checked: boolean = false;
}
@Injectable({
  providedIn: 'root'
})
export class DeclareApi {
  api: WebApiService;
  constructor(
    private apiClient: WebApiClient) {
    this.api = this.apiClient.createHisService('declares/Declare');
    this.api.enableLoader = true;
  }

  public async GetDoctors(): Promise<ValueTextPair[]> {
    var data = await this.api.get('GetDoctors').toPromise<ValueTextPair[]>();
    return data;
  }

  /** 取得各種醫事人員列表 */
  public async GetUserListForDeclare(clinicId: number, filter: string): Promise<UserInfoDTO[]> {
    var data = await this.api.get('GetUserListForDeclare', { clinicId: clinicId, filter: filter }).toPromise<UserInfoDTO[]>();
    return data;
  }

  /** 依照使用者職位取得使用者資訊清單 */
  public async GetUserPageListByPosition(clinicId: number, declareMonth: string, groupType: string, pageSize: number, pageNumber: number): Promise<PageData2<UserInfoDTO>> {
    var data = await this.api.get('GetUserPageListByPosition', {
      clinicId: clinicId,
      declareMonth: declareMonth,
      groupType: groupType,
      pageSize: pageSize,
      pageNumber: pageNumber
    }).toPromise<PageData2<UserInfoDTO>>();
    return data;
  }

  /** 執行申報程式 */
  public async Run(clinicId: number, declareMonth: string, declareWay: string, declareClass: string, nursePlus: number, overTimes: number, isZero: number, connId: string, amendNum: string, amendReason: string): Promise<any> {
    return await this.api.get('Run', {
      clinicId: clinicId,
      date: declareMonth,
      declareWay: declareWay,
      declareClass: declareClass,
      nursePlus: nursePlus,
      overTimes: overTimes,
      isZero: isZero,
      connectionId: connId,
      amendNum: amendNum,
      amendReason: amendReason,
    }).toPromise();
  }

  /** 取得申報3-3 */
  public async Ana33(clinicId: number, declareMonth: string, declareClass: string, connId: string, amendNum: string,opt:string): Promise<any> {
    return await this.api.get('Ana33', {
      clinicId: clinicId,
      date: declareMonth,
      declareClass: declareClass,
      connectionId: connId,
      amendNum: amendNum,
      opt: opt,
    }).toPromise();
  }

  /** 取得核對申報資料 */
  public async GetDeclareCheckPage(clinicId: number, declareMonth: string, pageSize: number, pageNumber: number, queryList: DeclareCheckFilterDTO[], getDDL: boolean, amendNum: string, orderBy:string, orderByDesc:boolean): Promise<DeclareChecViewDTO> {
    var model = new DeclareCheckQueryDTO;
    model.ClinicId = clinicId;
    model.Date = declareMonth;
    model.PageSize = pageSize;
    model.PageNumber = pageNumber;
    model.QueryList = queryList;
    model.GetDDL = getDDL;
    model.AmendNum = amendNum;
    model.OrderBy = orderBy;
    model.OrderByDesc = orderByDesc;
    var data = await this.api.put('GetDeclareCheckPage', model).toPromise<DeclareChecViewDTO>();
    return data;
  }

  /** 取得門診申報xml資料 */
  public async GetOPDxmlData(clinicId: number, declareMonth: string, declareWay: number, declareType: number, isUploadApi: boolean, connectionId: string, amendNum: string): Promise<OPDxmlDataDTO> {
    var data = await this.api.get('GetOPDxmlData', {
      clinicId: clinicId,
      declareMonth: declareMonth,
      declareWay: declareWay,
      declareType: declareType,
      connectionId: connectionId,
      isUploadApi: isUploadApi,
      amendNum: amendNum
    }).toPromise<OPDxmlDataDTO>();
    return data;
  }

  public async CreateNHIDownloadRequest(clinicId: number, typeCode: string, declareMonth: string, localId: string, nhiId: string, queryFileName: string): Promise<void> {
    await this.api.get('CreateNHIDownloadRequest', {
      clinicId: clinicId,
      typeCode,
      declareMonth: declareMonth,
      localId, nhiId, queryFileName
    }).toPromise<void>();
  }

  public async NHISendRequest(clinicId: number, typeCode: string, path: string, localId: string, nhiId: string): Promise<void> {
    await this.api.post('NHISendRequest', {
      clinicId: clinicId,
      typeCode,
      path,
      localId, nhiId
    }).toPromise<void>();
  }

  public async GetNHISendRequest(clinicId: number): Promise<NHISendRequestDTO[]> {
    return await this.api.get('GetNHISend', {
      clinicId: clinicId
    }).toPromise<NHISendRequestDTO[]>();
  }
  public async CheckNHISend(clinicId: number, fileName: string): Promise<boolean> {
    return await this.api.get('CheckNHISend', {
      clinicId: clinicId,
      fileName
    }).toPromise<boolean>();
  }
  public async GetNHIDownloadRequest(clinicId: number): Promise<NHIDownloadRequestDTO[]> {
    return await this.api.get('GetNHIDownloadRequest', {
      clinicId: clinicId
    }).toPromise<NHIDownloadRequestDTO[]>();
  }

  public async GetDeclareResult(clinicId: number, declareMonth: string, includeCC: boolean, ccFirst: boolean, includeVt: boolean, includeRemark: boolean, connectionId: string, amendNum: string): Promise<OPDxmlDataDTO> {
    var data = await this.api.get('GetDeclareResult', {
      clinicId: clinicId,
      declareMonth: declareMonth,
      includeCC: includeCC,
      ccFirst: ccFirst,
      includeVt: includeVt,
      includeRemark: includeRemark,
      connectionId: connectionId,
      amendNum: amendNum,
    }).toPromise<OPDxmlDataDTO>();
    return data;
  }

  /** 取得申報錯誤訊息資料 */
  public async GetDeclareErrTable(clinicId: number, declareMonth: string, amendNum: string): Promise<any> {
    var data = await this.api.get('GetDeclareErrTable', { clinicId: clinicId, declareMonth: declareMonth, amendNum: amendNum }).toPromise<any>();
    return data;
  }

  /** 取得最近一筆參數設定+檢查申報H10,H11,H12 的Azure Table Stroage是否存在 */
  public async GetLastParamAndCheckTable(clinicId: number, month: string, type: number, amenNum: string): Promise<ParamSetAndCheckTableDto> {
    var data = await this.api.get('GetLastParamAndCheckTable', { clinicId: clinicId, month: month, type: type, amenNum }).toPromise<ParamSetAndCheckTableDto>();
    return data;
  }

  public async CheckDeclareXmlLog(clinicId: number, declareMonth: string, isAmend: boolean): Promise<boolean> {
    var data = await this.api.get('CheckDeclareXmlLog', { clinicId: clinicId, declareMonth: declareMonth, isAmend }).toPromise<boolean>();
    return data;
  }

  public async DeleteDeclareXmlLog(clinicId: number, declareMonth: string): Promise<boolean> {
    var data = await this.api.get('DeleteDeclareXmlLog', { clinicId: clinicId, declareMonth: declareMonth }).toPromise<boolean>();
    return data;
  }

  /** 取得門診申報總表 報表資料 */
  public async GetOpdDeclareReport(clinicId: number, declareMonth: any): Promise<OpdDeclareReportDTO> {
    var data = await this.api.get('GetOpdDeclareReport', { clinicId: clinicId, declareMonth: this.onGetDateString(declareMonth) }).toPromise<OpdDeclareReportDTO>();
    return data;
  }

  /** 取得門診申報總表 報表資料 */
  public async GetDeclareCaseReport(clinicId: number, declareMonth: any, isByDoctor: boolean): Promise<DeclareCaseReportPageDTO[]> {
    var data = await this.api.get('GetDeclareCaseReport', { clinicId: clinicId, declareMonth: this.onGetDateString(declareMonth), isByDoctor: isByDoctor }).toPromise<DeclareCaseReportPageDTO[]>();
    return data;
  }

  /** 醫師分診別金額統計 報表資料 */
  public async GetDoctorRoomcodeDeclareReport(clinicId: number, startTime: any, endTime: any, doctorType: number, isCaseGroup: boolean, isShowTotal: boolean): Promise<DeclareCaseReportDTO[]> {
    var data = await this.api.get('GetDoctorRoomcodeDeclareReport', { clinicId: clinicId, startTime: this.onGetDateString(startTime), endTime: this.onGetDateString(endTime), doctorType: doctorType, isCaseGroup: isCaseGroup, isShowTotal: isShowTotal }).toPromise<DeclareCaseReportDTO[]>();
    return data;
  }

  /** 取得健保申報明細摘要表 報表資料 */
  public async GetOpdDeclareDetailsRpt(clinicId: number, declareMonth: any, amenNum: string): Promise<OpdDeclareDetailsRptDTO[]> {
    var data = await this.api.get('GetOpdDeclareDetailsRpt', { clinicId: clinicId, declareMonth: this.onGetDateString(declareMonth), amendNum: amenNum }).toPromise<OpdDeclareDetailsRptDTO[]>();
    return data;
  }

  public async GetExecuteTimeReport(clinicId: number, yearMonth: Date, defMins: number): Promise<any> {
    var data = await this.api.get('GetExecuteTimeReport', { clinicId: clinicId, yearMonth: this.onGetDateString(yearMonth), defMins: defMins }).toPromise<any>();
    return data;
  }

  public async GetDeclareList(clinicId: number, declareMonth: Date): Promise<AmendGridViewModel[]> {
    var data = await this.api.get('GetDeclareList', {
      clinicId: clinicId,
      declareMonth: declareMonth.toLocaleDateString()
    }).toPromise<AmendGridViewModel[]>();
    return data;
  }

  public async GetAmendDeclareList(clinicId: number, declareMonth: Date, addApply: number): Promise<AmendGridViewModel[]> {
    var data = await this.api.get('GetAmendDeclareList', {
      clinicId: clinicId,
      declareMonth: declareMonth.toLocaleDateString(),
      addApply: addApply
    }).toPromise<AmendGridViewModel[]>();
    return data;
  }

  public async UpdateAddApply(clinicId: number, ids: number[], addApply: number | ''): Promise<void> {
    await this.api.post('UpdateAddApply', {
      ClinicId: clinicId,
      DeclareIds: ids,
      AddApply: addApply
    }).toPromise<void>();

  }
  /** 取同療程次數月報表之資料 */
  public async GetSameTreatPerMonthList(declareMonth: Date, cusTp :string,pageRow:number): Promise<SameTreatPerMonthListDTO[]> {
    var data = await this.api.get('GetSameTreatPerMonthList', { cusTp: cusTp, declareMonth: declareMonth.toLocaleDateString(),pageRow:pageRow}).toPromise<SameTreatPerMonthListDTO[]>();
    return data;
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  private onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

  /** 轉換Time To String
*  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
*/
  private onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }
}
