<style>
    @media print {
        body {
            margin: 0;
            color: #000;
            background-color: #fff;
        }
    }
</style>
<div class="a4-print-style mb-3 p-2" #reportContainer [hidden]="isLetterHalf">
    <div *ngIf="clinic&&hist"  style="background-color: white;height: 100%;width: 100%;">
        <div  style="text-align: center;font-weight: bolder;">全民健康保險門診交付檢驗(查)治療處方箋</div>
        <div *ngIf="ic?.Itreatnum && this.isPrintItreatnum" style="display: flex;">
            <table style="width: 40%; margin-top: 10px; margin-left: 20px; border: none; font-size: 12px;">
                <tr>
                    <td>
                        <div>原處方就醫識別碼：{{ic?.Itreatnum}}</div>
                        <div>原處方就醫時間：{{ic?.IRegDT}}</div>
                    </td>
                </tr>
            </table>
            <ngx-barcode6 style="padding-top: 20px;" [bc-format]="'CODE39'" [bc-width]="0.9" [bc-height]="30" [bc-value]="ic?.Itreatnum"></ngx-barcode6>
            <div class="con" >
                <img style="width:80px;height: auto;padding-top: 0px;" #qrcodeItreatnumImg>
            </div>
        </div>
        <div  style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;" class="pres-report">
            <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%">
                    <tr>
                        <td style="width: 20%;">
                            特約醫院、診所 醫事服務機構代號及名稱
                        </td>
                        <td style="width: 80%;">
                            <div>{{clinic?.NHICode}}</div>
                            <div>{{clinic?.Name}}</div>
                        </td>
                    </tr>
                </table>
            </div>
            <table style="margin-top: 5px;width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:20%">姓名：{{hist?.Patient.CName}}</td>
                    <td style="width:10%">性別： {{hist?.Patient.SexName}}</td>
                    <td style="width:45%">身分證字號：{{hist?.Patient.CId }}</td>
                    <td style="width:25%">出生日期：{{hist?.Patient.Birthday | formatROCDate:true}} </td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:20%">就醫科別：{{hist?.Register.MedDeptCode}}</td>
                    <td style="width:10%">案類： {{hist?.Hist.Case}}</td>
                    <td style="width:45%">就醫日期： {{hist?.Register.RegDate | formatROCDate:true}}</td>
                    <td style="width:25%">健保卡就醫序號：{{hist?.Register.ICode=='000' ? '自費' : hist?.Register.Icno}}</td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:75%">免部分負擔代碼及原因：{{hist?.Register.ICode=='000' ? '' : (hist?.Hist.ApartCode + ' ' + hist?.Hist.ApartCodeDescription)}}</td>
                    <td style="width:25%"></td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
                <tr>
                    <td>國際疾病分類代碼：{{dxCodes}}</td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
                <tr>
                    <td>傷病名稱及主要症候：{{dxNames}}</td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:60%;">
                        醫事檢驗、醫事放射檢查名稱
                    </td>
                    <td style="width:16%;">
                        總數量
                    </td>
                    <td style="width:14%;">
                        備註
                    </td>
                </tr>
                <tr *ngFor="let hrx of hist.Hist.HRxs">
                    <td style="width:60%;border-bottom: 0;border-top: 0;">
                        {{hrx.StdCode}} {{hrx.ProdName}}
                    </td>
                    <td style="width:16%;border-bottom: 0;border-top: 0;">
                        <div style="width:50%;display: inline-block;">{{hrx.TotalDose}}</div>
                    </td>
                    <td style="width:14%;border-bottom: 0;border-top: 0;">
                        {{hrx.Remark}}
                    </td>
                </tr>

            </table>

            <table style="width: 100%;">
                <tr>
                    <td style="width: 50%;border-bottom: 0;" rowspan="1">
                        診治醫生代號：{{clinic?.NHICode}}<br>
                    </td>
                    <td style="width: 50%;border-bottom: 0;">
                        執行檢驗(查)醫事人員代號：
                    </td>
                </tr>
                <tr>
                    <td style="width: 50%;border-top: 0;border-bottom: 0;">

                    </td>
                    <td style="width: 50%;border-top: 0;border-bottom: 0;">
                        簽章：
                    </td>
                </tr>

                <tr>
                    <td style="width: 50%;border-top: 0;">
                        簽章：{{hist?.Register.DoctorName}}
                    </td>
                    <td style="width: 50%;border-top: 0;">
                        檢驗(查)日期：
                    </td>
                </tr>
                <tr>
                    <td style="width: 50%;border-bottom: 0;" rowspan="1">
                        特約醫院、診所 核章
                    </td>
                    <td style="width: 50%;border-bottom: 0;">
                        特約醫事檢驗、特約醫事放射機構 核章
                    </td>
                </tr>
                <tr>
                    <td style="width: 50%;border-top: 0;border-bottom: 0;">
                        (醫事服務機構地址、電話及傳真){{space}}電話： {{clinic?.Phone}}
                    </td>
                    <td style="width: 50%;border-top: 0;border-bottom: 0;">
                        (醫事服務機構代號、名稱、地址及電話){{space}}電話： {{labHospInfo?.HTEL}}
                    </td>
                </tr>
                <tr>
                    <td style="width: 50%;border-top: 0;">
                        {{clinicAddress}} {{space}} 傳真：{{clinic?.Fax}}
                    </td>
                    <td style="width: 50%;border-top: 0;">
                        {{labHospInfo?.HID}} {{labHospInfo?.HNAME}} {{labHospInfo?.HAREA}}
                    </td>
                </tr>

            </table>
        </div>
        <div>
        <div style="display: flex;">
            <div style="-webkit-writing-mode: vertical-lr;writing-mode: vertical-lr;width: 3%;margin-top: 10px;">
                <div>注意事項</div>
            </div>
            <table style="margin-top: 5px; margin-left: 10px; border: none;font-size: 12px;">
                <tr>
                    <td>
                        <div>(一)本處方如有塗改，需由原治醫師之蓋章確認，否則無效。</div>

                        <div>(二)本交付檢驗(查)處方為一式兩份，一份交病患供檢驗(查)用，另一份由處方特約醫事機構留存備查。</div>

                        <div>(三)交付檢驗(查)之處方箋自就醫日起一百八十日內有效。</div>
                    </td>
                </tr>
            </table>
        </div>

  </div>
</div>

<div class="a6-print-style" #reportContainerLetterHalf [hidden]="!isLetterHalf">
  <div *ngIf="clinic&&hist"  style="background-color: white;height: 100%;width: 100%;"
  [ngStyle]="{height: sizePageLetterHalf.height + 'cm',
    width: sizePageLetterHalf.width + 'cm',
    paddingTop: paddingPageLetterHalf.top + 'cm',
    paddingRight: paddingPageLetterHalf.right + 'cm',
    paddingBottom: paddingPageLetterHalf.bottom + 'cm',
    paddingLeft: paddingPageLetterHalf.left + 'cm'}">
      <div  style="text-align: center;font-weight: bolder;">全民健康保險門診交付檢驗(查)治療處方箋</div>
      <div *ngIf="ic?.Itreatnum && this.isPrintItreatnum" style="display: block;">
        <table style="width: 100%; margin-top: 0px; border: none;">
            <tr>
                <td>
                  <table style="width: 100%; margin-top: 0px; border: none; font-size: 8pt;">
                    <tr>
                      <td>
                        <div>原處方就醫識別碼：{{ic?.Itreatnum}}</div>
                        <div>原處方就醫時間：{{ic?.IRegDT}}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ngx-barcode6 [bc-format]="'CODE39'" [bc-width]="0.7" [bc-height]="25" [bc-value]="ic?.Itreatnum"></ngx-barcode6>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <div class="con" >
                    <img style="width:80px;height: auto;padding-top: 0px;" #qrcodeItreatnum2Img>
                  </div>
                </td>
            </tr>
        </table>
      </div>
      <div  style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0;" class="pres-report">
          <div style="display: flex;column-gap: 2px;">
              <table style="width: 100%">
                  <tr>
                      <td style="width: 41%;">
                          醫事服務機構代號及名稱
                      </td>
                      <td style="width: 59%;">
                          {{clinic?.NHICode}} {{clinic?.Name}}
                      </td>
                  </tr>
              </table>
          </div>
          <table style="margin-top: 5px;width: 100%;border-bottom: 0;">
              <tr>
                  <td style="width:29%">姓名:{{hist?.Patient.CName}}</td>
                  <td style="width:71%">身分證號:{{hist?.Patient.CId}}{{space}}生日:{{hist?.Patient.Birthday | formatROCDate:true}}</td>
                  <!-- <td style="width:34%">生日：{{hist?.Patient.Birthday | formatROCDate:true}} </td> -->
              </tr>
          </table>
          <table style="width: 100%;border-bottom: 0;">
              <tr>
                  <td style="width:29%">科別:{{hist?.Register.MedDeptCode}}</td>
                  <td style="width:71%">就醫日期:{{hist?.Register.RegDate | formatROCDate:true}}{{space}}卡號:{{hist?.Register.ICode=='000' ? '自費' : hist?.Register.Icno}}</td>
                  <!-- <td style="width:34%">卡號：{{hist?.Register.Icno}}</td> -->
              </tr>
          </table>
          <table style="width: 100%;border-bottom: 0;">
              <tr>
                  <td>傷病名:{{dxNames}}{{space}}疾病碼:{{dxCodes}}</td>
              </tr>
          </table>
          <table style="width: 100%;border-bottom: 0;">
              <tr>
                  <td style="width:70%">免部分負擔代碼及原因:{{hist?.Register.ICode=='000' ? '' : (hist?.Hist.ApartCode + ' ' + hist?.Hist.ApartCodeDescription)}}</td>
                  <td style="width:30%">案類:{{hist?.Hist.Case}}</td>
              </tr>
          </table>
          <table style="width: 100%;border-bottom: 0;">
              <tr>
                  <td style="width:70%;">
                      醫事檢驗、醫事放射檢查名稱
                  </td>
                  <td style="width:16%;">
                      總量
                  </td>
                  <td style="width:14%;">
                      備註
                  </td>
              </tr>
              <tr *ngFor="let hrx of hist.Hist.HRxs">
                  <td style="width:70%;border-bottom: 0;border-top: 0;">
                      {{hrx.StdCode}} {{hrx.ProdName}}
                  </td>
                  <td style="width:16%;border-bottom: 0;border-top: 0;">
                      <div style="width:50%;display: inline-block;">{{hrx.TotalDose}}</div>
                  </td>
                  <td style="width:14%;border-bottom: 0;border-top: 0;">
                      {{hrx.Remark}}
                  </td>
              </tr>
          </table>

          <table style="width: 100%;border-bottom: 0;">
              <tr>
                  <td style="border-bottom: 0;">
                      診治醫代號:{{clinic?.NHICode}}<br>
                  </td>
                  <td style="border-bottom: 0;">
                      執行檢驗(查)醫事人員代號:
                  </td>
              </tr>
              <tr>
                  <td style="border-top: 0;">
                      簽章:{{hist?.Register.DoctorName}}
                  </td>
                  <td style="border-top: 0;">
                      檢驗(查)日期:{{space}}{{space}}{{space}}簽章:
                  </td>
              </tr>
          </table>
          <table style="width: 100%;">
              <tr>
                  <td style="border-bottom: 0;">
                      特約醫院、診所 核章
                  </td>
                  <td style="border-bottom: 0;">
                      特約醫事檢驗機構 核章
                  </td>
              </tr>
              <tr>
                  <td style="border-top: 0;border-bottom: 0;">
                      醫事務機構地址
                  </td>
                  <td style="border-top: 0;border-bottom: 0;">
                      {{labHospInfo?.HNAME}}
                  </td>
              </tr>
              <tr>
                  <td style="border-top: 0;border-bottom: 0;">
                      {{clinicAddress}}
                  </td>
                  <td style="border-top: 0;border-bottom: 0;">
                      {{labHospInfo?.HAREA}}
                  </td>
              </tr>
              <tr>
                  <td style="border-top: 0;border-bottom: 0;">
                      電話:{{clinic?.Phone}}
                  </td>
                  <td style="border-top: 0;border-bottom: 0;">
                      {{labHospInfo?.HTEL}}
                  </td>
              </tr>
              <tr>
                  <td style="border-top: 0;">
                      傳真:{{clinic?.Fax}}
                  </td>
                  <td style="border-top: 0;">
                      {{labHospInfo?.HID}}
                  </td>
              </tr>
          </table>
      </div>
      <div style="display: flex;">
          <!-- <div style="-webkit-writing-mode: vertical-lr;writing-mode: vertical-lr;width: 3%;margin-top: 5px;font-size: 12px;" >
              <div>注意事項</div>
          </div> -->
          <table style="width: 100%;margin-top: 1px; border: none;font-size: 12px;">
              <tr>
                  <td>
                      <div>注意事項：(一)本處方如有塗改，需由原醫師蓋章確認，否則無效。</div>

                      <div>(二)本處方為一式兩份，一份交病患供檢驗(查)用，另一份留存備查。</div>

                      <div>(三)交付檢驗(查)之處方箋自就醫日起三日內有效。</div>
                  </td>
              </tr>
          </table>
      </div>
  </div>
</div>
