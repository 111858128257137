import { Hist } from './hist';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';

export class HistView /*extends WholeHist*/ {
  IsInsert: boolean; // 如果是false，就是Replace
  IsIncludeCC: boolean; // 包含CC
  IsIncludePE: boolean; // 包含PE
  IsIncludeDiag: boolean; // 包含Diagnosis
  IsIncludeOrder: boolean;  // 包含 Rx (Orders)

  //Register: RegisterEditDto;
  //Hist: Hist;

  Id:number;
  RegDate:Date;
  PatientNo: string;
  PatientName: string;
  PatientBirthday: Date;
  DoctorName: string;
  ICode: string;
  RoomName: string;
  DeptCode: string;
  Case: string;
  IsqNo: string;
  Cus: string;
  /**慢箋次別 */
  Pres: string;
  PayTP: string;
  SPTX: string;
  DispTP: string;
  CC: string;
  PE: string;
  ChiAsk: string;
  ChiLook: string;
  ChiPulse: string;
  ChiBianZheng: string;
  ChiTreatRule: string;
  DxName:string[];
  SurName:string[];
  HRxs:HRxViewDto[]
  NhiPayDetail: { [key: string]: PaymentParaProfile };
   /**居護收案別 */
  PaySysA: string;
   /**狀態 */
  RegIType: string;
  /**還卡日 */
  ReCardDate: Date;
  /** 申報醫師 */
  DeclareDoctor:string;
  PresStartDate:Date;
  PresDeadline:Date;
  DeclareDays:number;
  /**3L其他擴充欄位 */
  NhiSPExtension:string = '';
  DrugAllergy:string;
  Remark:string;
  Way: string;
  ToothPos: string;
}
export class HRxViewDto{
  RxCode:string;
  SPRule:number;
  ProdName:string;
  QTY: number;
  Freq:string;
  Dose:number;
  Days: number;
  DailyDose: number;
  TotalDose: number;
  DispTP: string;
  Remark:string;
  MedID:string;
  BeginDate:Date;
  EndDate:Date;
  Sort: number;
  ToothPos?: string;
  PCS?: string;
  TreatCard?: string;
  StartTreatDate?: Date;
  Plus?: number; 
  MedIDName:string;
  MedIDNameAndDateTime:string;
}
export class PaymentParaProfile
{
    Name:string;
    Price?: number;
}
