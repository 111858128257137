import { Injectable } from "@angular/core";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { PageData2 } from "src/app/shared/models/page-data";

export class DengueFormDto {
  Id: number;
  ClinicId: number;
  ClinicCode: string;
  PatientNo: string;
  PatientId: number;
  HistoryId: number;
  ApiUrlType: string;
  QueryTime: Date;
  QueryReturnCode: number;
  QueryReturnMessage: string;
  QueryParameter: string;
  QueryResponse: string;
  UId: string;
  Name: string;
  PersonalIdNumber: string;
  NewbornBirthDate: Date;
  RegInstitutionCode: string;
  RegInstitutionName: string;
  FormCreateTime: Date;
  RecordTime: Date;
  RecordReturnCode: number;
  RecordReturnMessage: string;
  RecordParameter: string;
  RecordResponse: string;
  HISKey: string;
  TOCCFlag: boolean;
}

export class DengueUploadDto {
  SelectItem: boolean;
  Id: number;
  ClinicId: number;
  ClinicCode: string;
  PatientNo: string;
  PatientId: number;
  HistoryId?: number;
  UId: string;
  Name: string;
  PersonalIdNumber: string;
  RecordTime?: Date;
  RecordReturnCode?: number;
  HISKey: string;
  TOCCFlag?: boolean;
  RegDate: Date;
  EditTime: Date;
  Birthday: Date;
  MomCId: string;
  City: string;
  Area: string;
  Street: string;
  Dx1: string;
  Dx2: string;
  Dx3: string;
  Dx4: string;
  Dx5: string;
  Dx6: string;
  Dx1Name: string;
  Dx2Name: string;
  Dx3Name: string;
  Dx4Name: string;
  Dx5Name: string;
  Dx6Name: string;
}

export interface ToccResult {
  Successed: boolean;
  Returns: any;
}

export interface ToccParameter {
  Url: string;
  JsonContent: string;
}

@Injectable({
  providedIn: 'root'
})

export class ToccApi {
  constructor(
    private webApiFactory: WebApiClient) {
    this.api = this.webApiFactory.createHisService('Hist/TOCC');
    // this.api.enableLoader = false;
  }

  api: WebApiService;

  async CreateDengueForm(dto: DengueFormDto): Promise<DengueFormDto> {
    return await this.api.post('CreateDengueForm', dto).toPromise<DengueFormDto>();
  }

  async UpdateDengueForm(dto: DengueFormDto): Promise<DengueFormDto> {
    return await this.api.post('UpdateDengueForm', dto).toPromise<DengueFormDto>();
  }

  async GetLastDengueForm(historyId: number): Promise<DengueFormDto>{
    return await this.api.get('GetLastDengueForm/' + historyId).toPromise<DengueFormDto>();
  }

  async GetLastQueryReturnMessage(historyId: number): Promise<DengueFormDto>{
    return await this.api.get('GetLastQueryReturnMessage/' + historyId).toPromise<DengueFormDto>();
  }

  async GetLastRecordTime(historyId: number): Promise<DengueFormDto>{
    return await this.api.get('GetLastRecordTime/' + historyId).toPromise<DengueFormDto>();
  }

  async GetUploadList(query: any): Promise<PageData2<DengueUploadDto>> {
    return await this.api.post('GetUploadList', query).toPromise<PageData2<DengueUploadDto>>();
  }

  async GetHMAC(data: any): Promise<string> {
    return await this.api.get('GetHMAC?data=' + data).toPromise<string>();
  }

  async CallTocc(data: ToccParameter): Promise<ToccResult> {
    return await this.api.post('CallTocc', data).toPromise<ToccResult>();
  }
}
