import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { IdHelper } from 'src/app/shared/helpers/guid-helper';
import NP from 'number-precision';
import { OrderAutoFillEnum } from '../enums/order-auto-fill-enum';
import { Entity } from 'src/app/shared/models/entity';
import { OrderSubTypeEnum } from 'src/app/shared/enums/order-sub-type-enum';
import { DispensingTypeEnum, OrderDispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';
import { OrderTypeEnum } from 'src/app/enums/OrderTypeEnum';
import { HistService } from '../services/hist.service';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { Rx } from 'src/app/services/api-service/rx/rx';


export class HistOrder extends Entity {

  static QtyRule_Dose = 1;        // 次(劑)量法
  static QtyRule_DailyDose = 2;   // 日量法
  static QtyRule_TotalDose = 3;   // 總量法

  Id: number;
  HistoryId: number;
  Days: number;
  RxClass: string;
  Sort: number;
  DispTP: OrderDispensingTypeEnum;
  RxType: number;
  SPRule: number;
  RxId: number;
  RxCode: string;
  Rx: Rx; //string;
  StdCode: string;
  ProdName: string;
  QtyRule: number;
  QTY: number;
  ToothPos: string;
  ToothFace: string;
  PCS: string;
  TreatCard: string;
  StartTreatDate: Date;
  Unit: string;
  Dose: number;
  DailyDose: number;
  TotalDose: number;
  IPrice: number;
  Price: number;
  Tot: number;
  DiffPrice: number;
  Freq: string;
  FreqQTY: number;
  Plus: number;
  Way: string;
  Dept: string;
  MedID: string;
  MedIDName: string;
  /** 執行人/時間 */
  MedIDNameAndDateTime: string;
  InfoDays: number;
  BeginDate: Date;
  EndDate: Date;
  LotNo: string;
  DrugsPerBox: number = null;
  TotalBox: number;
  BoxUnit: string;
  SDate: Date;
  STime: string;
  EDate: Date;
  ETime: string;
  NeedExecutor: boolean;
  NeedExecuteDate: boolean;
  NeedExecuteTime: boolean;
  FilterExecutor: ValueTextPair[];
  RsCode: string;
  ChiProcessCode: string;
  ChiFormCode: string;
  Remark: string;
  // drug info
  SyrupNote: string;

  //OrderSubType: OrderSubTypeEnum;
  ATC: string;

  // UI
  SelfDispensing: string;
  // UIFreqQTY: number;
  // UIDose: number;
  // UIDailyDose: number;
  // UITotalDose: number;
  // UITotalBox: number;

  // For Calulate
  Additions: { [key: string]: number };
  // 輔助欄位
  OrigOrderCode: string;
  IsFromHelper: boolean;
  OrderCodeName: string;
  ChiProcess: string;

  ChineseName?: string;
  SideEffects?: string;
  Indications?: string;
  OtherInstructions?: string;
  PaymentType?: string;
  CalPrice: number;
  TransOri: string; // 委託或受託機構代號 (p24)
  // #region static --------------------
  // 有時候需要產生empty資料但有別於constructor <- 未檢驗
  static createEmpty(): HistOrder {
    const odr = new HistOrder();
    odr.Id = IdHelper.IdEmpty;
    odr.HistoryId = IdHelper.IdEmpty;
    odr.RxClass = '';
    odr.Sort = 0;
    odr.DispTP = null;
    odr.SPRule = 0;
    odr.RxId = IdHelper.IdEmpty;
    odr.RxCode = '';
    odr.StdCode = '';
    odr.ProdName = '';
    odr.QtyRule = HistOrder.QtyRule_Dose; // 預設Dose
    odr.ToothPos = null;
    odr.ToothFace = null;
    odr.PCS = null;
    odr.TreatCard = null;
    odr.StartTreatDate = null;
    odr.QTY = null;
    odr.Unit = '';
    odr.Dose = null;
    odr.DailyDose = null;
    odr.TotalDose = null;
    odr.IPrice = null;
    odr.Price = null;
    odr.DiffPrice = null;
    odr.Freq = null;
    odr.FreqQTY = null;
    odr.Plus = null;
    odr.Way = '';
    odr.Days = null;
    odr.InfoDays = null;
    odr.BeginDate = null;
    odr.EndDate = null;
    odr.TotalBox = null;
    odr.DrugsPerBox = null;
    odr.BoxUnit = '';
    odr.Remark = '';
    odr.RxType = 0;
    odr.ATC = '';
    odr.ChiProcessCode = '';
    odr.SDate = null;
    odr.STime = '';
    odr.EDate = null;
    odr.ETime = '';
    odr.NeedExecutor = false;
    odr.NeedExecuteDate = false;
    odr.NeedExecuteTime = false;
    odr.RsCode = '';
    odr.ChineseName = null;
    odr.SideEffects = null;
    odr.OtherInstructions = null;
    odr.PaymentType = null;
    odr.CalPrice = null;
    odr.TransOri = null;
    // odr.UIFreqQTY = null;
    // odr.UIDose = null;
    // odr.UIDailyDose = null;
    // odr.UITotalDose = null;
    // odr.UITotalBox = null;
    return odr;
  }

  static copyOrderFields(source: HistOrder, target: HistOrder, isForce: boolean): HistOrder {
    // isForce=false: 用在從last複製到Orders[idx]，如果target - Orders[idx]有資料，就不要覆蓋
    // isForce=true: 用在從Orders[idx]複製到last，不論target - last是否有值都蓋過去，因為要做為新的last值
    if ((isForce || !target.QTY) && source.QTY) {
      target.QTY = source.QTY;
    }
    if ((isForce || !target.Freq) && source.Freq) {
      target.Freq = source.Freq;
    }
    if ((isForce || !target.Days) && source.Days) {
      target.Days = source.Days;
    }
    if ((isForce || !target.Way) && source.Way) {
      target.Way = source.Way;
    }
    return target;
  }
  static fillNotOralOrderFields(target: HistOrder, isForce: boolean): HistOrder {
    // isForce=false: 用在從last複製到Orders[idx]，如果target - Orders[idx]有資料，就不要覆蓋
    // isForce=true: 用在從Orders[idx]複製到last，不論target - last是否有值都蓋過去，因為要做為新的last值
    if ((isForce || !target.QTY)) {
      target.QTY = 1;
    }
    if ((isForce || !target.Freq)) {
      target.Freq = '1';
    }
    if ((isForce || !target.Days)) {
      target.Days = 1;
    }
    return target;
  }
  // 自動補齊醫令
  // 在validate後前補齊，不然缺資料一定驗不過
  static autoFill(fillType: number, orders: HistOrder[], dosages: Dosage[]): HistOrder[] {
    if (!orders || orders.length === 0) {
      return orders;
    }
    // fillType = 2;
    let idxBase = 0;
    let idxDirection = 1;
    // 讀取診所參數[HST001_門診醫令參數 AutoFill]自動補醫令內容方式
    if (fillType === OrderAutoFillEnum.BottomToTop) { // 10由下往上補
      idxBase = orders.length - 1;  // 最後一筆
      idxDirection = -1;            // 由下午上
    } else if (fillType === OrderAutoFillEnum.TopToBottom) { // 2 由上往下補
    } else {  // 0 不補
      return orders;
    }
    // var rxOpt = await this.opdService.getOrderList(odr.RxCode,[]);
    // if(!rxOpt.filter(r=>r.RxCode == odr.RxCode)[0]?.IsByOral)

    let lastOrder = new HistOrder();
    // 處理 RxType = 2
    for (let i = 0; i < orders.length; i++) {
      const idx = idxBase + i * idxDirection;
      // if (orders[idx].QtyRule !== HistOrder.QtyRule_Dose) {
      //   // 只有次劑量法才能用這個運算
      //   continue;
      // }
      if (orders[idx].RxType != 2) {
        //非口服類型填1
        orders[idx] = this.fillNotOralOrderFields(orders[idx], false)
      } else {
        if (lastOrder.RxCode == '') {
          lastOrder = this.copyOrderFields(orders[idx], lastOrder, true);
        } else {
          // 看[idx]缺甚麼，從base補過來
          orders[idx] = this.copyOrderFields(lastOrder, orders[idx], false);
          // 補完後，把[idx]有的放到base
          lastOrder = this.copyOrderFields(orders[idx], lastOrder, true);
        }
      }
      // 重新計算總量
      if (orders[idx].QtyRule == HistOrder.QtyRule_Dose) {
        orders[idx].calcDose_Rule1(dosages);
      } else if (orders[idx].QtyRule == HistOrder.QtyRule_DailyDose) {
        orders[idx].calcDose_NormalRule2(dosages);
      } else if (orders[idx].QtyRule == HistOrder.QtyRule_TotalDose) {
        orders[idx].calcDose_Rule3(dosages);
      }
      // 重新計算盒數
      orders[idx].calculateBox();
    }
    return orders;
  }

  // processOrderFreqQTY(rxType: number): HistOrder[] {
  //   var ret: HistOrder[] = [];

  // }
  // #endregion static --------------------

  //#region Dispensing ----------------
  static toSelfDispensing(dispensingType: OrderDispensingTypeEnum): string {
    if (dispensingType === null) {
      return '';
    } else if (dispensingType === OrderDispensingTypeEnum.T0_Clinic) {
      return 'Y';
    } else if (dispensingType === OrderDispensingTypeEnum.T1_OutOfClinic) {
      return 'N';
    } else {
      return '';
    }
  }
  static toDispensingType(selfDispensing: string): OrderDispensingTypeEnum {
    if (selfDispensing === 'Y') {
      return OrderDispensingTypeEnum.T0_Clinic;
    } else if (selfDispensing === 'N') {
      return OrderDispensingTypeEnum.T1_OutOfClinic;
    } else {
      return null;
    }
  }
  //#endregion

  // #region calc dose --------------------

  findDosage(dosages: Dosage[], freq: string): Dosage {
    if (!dosages || !freq) {
      return null;
    }
    let ds1 = dosages.find(ds => ds.Code.toUpperCase() === freq.toUpperCase());

    if (HistService.EnableFreqFree && ds1 == null) {
      ds1 = dosages.find(ds => ds.Code.toUpperCase() === 'ASORD');
    }
    return ds1;
  }

  // 必要資料 EnterQuantity, Days, Frequency
  // 計算 InfoFrequency, DailyDose, TotalDose
  calcDose_Rule1(dosages: Dosage[]) {
    if (!this.QTY || this.QTY === 0 || !this.Freq || !this.Days || this.Days === 0) {
      this.DailyDose = null;
      this.TotalDose = null;
      this.InfoDays = null;
      return;
    }
    // Frequency 可能是代碼也可能是數字
    // 如果是代碼，需用Dosage資料得到InfoFrequency
    // 如果是數字，直接用數值當InfoFrequency
    this.FreqQTY = 0;
    const ds = this.findDosage(dosages, this.Freq);
    if (this.Freq) {
      // step 1. 先查找代碼是否存在?
      // 有代碼 => 用Dosage資料得到InfoFrequency
      if (ds && ds.TMDay && ds.TMDay !== 0) {
        this.FreqQTY = NP.divide(ds.TMQty, ds.TMDay);
      } else {
        // 沒代碼 => 檢查是否為數字?
        if (!(isNaN(this.Freq as any))) {  // 是數字 => parse成數字
          this.FreqQTY = parseFloat(this.Freq);
        }
      }
    }
    if (this.FreqQTY === 0) {
      // 這裡Frequency不是null，但InfoFrequency = 0，代表是無法定義的狀態，如STAT，因此不去更動Total，讓user自行輸入
      return;
    }
    this.Dose = this.QTY; // 因為是次劑量法
    this.InfoDays = this.Days;
    this.DailyDose = NP.times(this.QTY, this.FreqQTY);
    var cond = [
      (odr: HistOrder) => odr.RxType != 800,
      (odr: HistOrder) => ds.Code != 'ASORD', //按吩咐
      (odr: HistOrder) => ds.Code != 'PRN',   //需要時
      //(odr:HistOrder)=>odr.Freq !='STAT',  //現在立刻馬上
    ]
    var updateTotalDose = true;
    cond.forEach(x => updateTotalDose = updateTotalDose && x(this));
    if (updateTotalDose) {
      this.TotalDose = NP.round(NP.times(this.DailyDose, this.Days), 1);
    }
  }

  // 日量法(中藥用)
  calcDose_Rule2(chiDays: number, chiFrequency: number) {
    // 整體屬性放到各Order，注意null或0也要放入，不然醫令的這些資料會被其他操作蓋掉
    this.Days = chiDays;
    this.InfoDays = chiDays;
    if (chiFrequency) {
      this.Freq = chiFrequency.toString();
      this.FreqQTY = chiFrequency;
      // this.UIFreqQTY = chiFrequency;
    } else {
      this.Freq = null;
      this.FreqQTY = null;
      // this.UIFreqQTY = null;
    }
    // 這裡再判斷是否要做運算
    if (!chiDays || !chiFrequency || chiDays === 0 || chiFrequency === 0) {
      return;
    }
    // Dose計算
    this.DailyDose = this.QTY; // 日量法
    this.Dose = NP.round(NP.divide(this.QTY, this.FreqQTY), 2);
    this.TotalDose = NP.round(NP.times(this.QTY, this.Days), 1);
  }

  // 日量法(一般)
  calcDose_NormalRule2(dosages: Dosage[]) {
    if (!this.QTY || this.QTY === 0 || !this.Freq || !this.Days || this.Days === 0) {
      this.DailyDose = null;
      this.TotalDose = null;
      this.InfoDays = null;
      return;
    }
    this.FreqQTY = 0;
    if (this.Freq) {
      // step 1. 先查找代碼是否存在?
      const ds = this.findDosage(dosages, this.Freq);
      // 有代碼 => 用Dosage資料得到InfoFrequency
      if (ds && ds.TMDay && ds.TMDay !== 0) {
        this.FreqQTY = NP.divide(ds.TMQty, ds.TMDay);
      } else {
        // 沒代碼 => 檢查是否為數字?
        if (!(isNaN(this.Freq as any))) {  // 是數字 => parse成數字
          this.FreqQTY = parseFloat(this.Freq);
        }
      }
    }
    if (this.FreqQTY === 0) {
      // 這裡Frequency不是null，但InfoFrequency = 0，代表是無法定義的狀態，如STAT，因此不去更動Total，讓user自行輸入
      return;
    }
    // Dose計算
    this.DailyDose = this.QTY;  // 日量法 DailyDose = QTY
    this.InfoDays = this.Days;


    this.Dose = NP.round(NP.divide(this.DailyDose, this.FreqQTY), 2);
    this.TotalDose = NP.round(NP.times(this.DailyDose, this.Days), 1);
  }

  // 總量法
  calcDose_Rule3(dosages: Dosage[]) {
    //console.log('!this.Freq',this.Freq);
    if (!this.Freq || this.Freq == '' || this.Freq == null || this.Freq == undefined) this.Freq = '1';
    if (!this.QTY || this.QTY === 0) this.QTY = 1;
    if (!this.Days || this.Days === 0) this.Days = 1;
    // if ( !this.Days || this.Days === 0) {
    //   this.DailyDose = null;
    //   this.TotalDose = null;
    //   this.InfoDays = null;
    //   return;
    // }
    this.FreqQTY = 0;
    if (this.Freq) {
      // step 1. 先查找代碼是否存在?
      const ds = this.findDosage(dosages, this.Freq);
      // 有代碼 => 用Dosage資料得到InfoFrequency
      if (ds && ds.TMDay && ds.TMDay !== 0) {
        this.FreqQTY = NP.divide(ds.TMQty, ds.TMDay);
      } else {
        // 沒代碼 => 檢查是否為數字?
        if (!(isNaN(this.Freq as any))) {  // 是數字 => parse成數字
          this.FreqQTY = parseFloat(this.Freq);
        }
      }
    }
    if (this.FreqQTY === 0) {
      // 這裡Frequency不是null，但InfoFrequency = 0，代表是無法定義的狀態，如STAT，因此不去更動Total，讓user自行輸入
      return;
    }
    // Dose計算
    this.TotalDose = this.QTY;  // 總量法 TotalDose = QTY
    this.InfoDays = this.Days;
    this.DailyDose = NP.divide(this.TotalDose, this.InfoDays);
    this.Dose = NP.round(NP.divide(this.DailyDose, this.FreqQTY), 1);
  }
  // #endregion calc dose --------------------

  /** 內服外用藥之外的 劑量頻率日數為空時補1, 有進行補足的回傳true */
  fillEmpty(): boolean {
    if (![OrderTypeEnum.T2_OralDrugFee, OrderTypeEnum.T3_ExtDrugFee].includes(this.RxType)) {
      if (!this.QTY || !this.Freq || !this.Days) {
        this.QTY = this.QTY || 1;
        this.Freq = this.Freq || '1';
        this.Days = this.Days || 1;
        return true;
      }
    }
    return false;
  }
  // 若此筆缺少必要欄位回傳false
  validateRequired(): boolean {
    // 內服外用外的 空值補1
    if (![OrderTypeEnum.T2_OralDrugFee, OrderTypeEnum.T3_ExtDrugFee].includes(this.RxType)) {
      if (!this.QTY) {
        this.QTY = this.QTY || 1;
        this.Freq = this.Freq || '1';
        this.Days = this.Days || 1;
        return false;
      }
    } else {
      if (!this.QTY || !this.Freq || !this.Days) {
        return false;
      }
    }
    return true;
  }
  calculateBox() {
    if (!this.DrugsPerBox || this.DrugsPerBox <= 0 || !this.TotalDose || this.TotalDose === 0) {
      this.TotalBox = null;
      // this.UITotalBox = null;
      return;
    }

    this.TotalBox = Math.ceil(this.TotalDose / this.DrugsPerBox);
    // this.UITotalBox = Math.ceil(this.TotalDose / this.DrugsPerBox);
  }
}
