import { EnumCachedService } from './../cache-service/enum-cached.service';
import { EventEmitter, Injectable } from "@angular/core";
import { SystemcodeApi } from "../api-service/system-code/systemcode-api";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { HistService } from "src/app/hist/services/hist.service";
import { HistApi, StatOpts } from "../api-service/hist/hist-api";
import { DxOpt, RsOpt, RxOpt, userStat } from "../api-service/hist/hist-edit-option";
import { EasyNotificationService } from "../easy-notification.service";
import { Compare, GroupBy, delay } from "src/app/shared/utilities";
import { Subject } from "rxjs";
import { ParameterProfileCachedService } from "../cache-service/parameter-profile-cached.service";
import { SystemCodeCachedService } from "../cache-service/system-code-cached.service";
import { EnumTypes } from "../cache-service/enum-cached.service";
import { ParamMap, ParamType } from './system-param-cache-define';
import { ParameterApi } from '../api-service/parameters/parameter-api';
import { FormFieldCollection } from 'src/app/shared/components/easy-form/form-define';
import { ParameterDataType } from 'src/app/system/parameter-profile/models/parameter-profile';
import { EasyFormService } from '../easy-form-service';
import { ICacheReset} from '../cache-service/icache-service';

export declare type ValueTextPairBundle = { [key: string]: ValueTextPair[] };
export declare type NumberValueTextPairBundle = { [key: string]: ValueTextPairNumberValue[] };

enum UpdateType {
  Insert = 1,
  Update = 2,
  Delete = 3
}
@Injectable({
  providedIn: 'root'
})

//快取資料
export class ClinicDataService {

  showDataChangedNotify = true;
  rxOption: RxOpt[];
  rsOption: RsOpt[];
  dxOption: DxOpt[];
  dxVersion: string = '';
  spcTOption: ValueTextPair[];
  statOpt: StatOpts = null;
  allICache:ICacheReset[] = [];
  onParamChanged: EventEmitter<ParamType[]> = new EventEmitter();
  onReload:EventEmitter<void> = new EventEmitter();
  constructor(private histApi: HistApi,
    private notify: EasyNotificationService,
    private ParameterProfileCache: ParameterProfileCachedService,
    private SystemCodeCached: SystemCodeCachedService,
    private EnumCached: EnumCachedService,
    private paramApi:ParameterApi,
    private ezForm:EasyFormService) {
      this.allICache.push(SystemCodeCached,EnumCached,ParameterProfileCache);
  }
  async getParam<K extends keyof ParamMap>(type:K): Promise<ParamMap[K]> {
    await this.ParameterProfileCache.load([type]);
    return await this.ParameterProfileCache.get(type);
  }
  async updateParam(type:(keyof ParamMap)[]): Promise<void> {
    await this.ParameterProfileCache.update(type);
    this.onParamChanged.emit(type);
  }
  async getEnum(types: EnumTypes[]): Promise<NumberValueTextPairBundle> {
    await this.EnumCached.load(types);
    var ret: NumberValueTextPairBundle = {};
    for(let tp of types){
      let kerStr = tp.toString();
      ret[kerStr] = await this.EnumCached.get(tp);

    }
    return ret;
  }

  //#region SystemCodeCacheHandle
  async getSystemCodes(types: string[]): Promise<ValueTextPairBundle> {
    await this.SystemCodeCached.load(types);
    var ret: ValueTextPairBundle = {};
    for(let tp of types){
      let kerStr = tp.toString();
      ret[kerStr] = await this.SystemCodeCached.get(tp);
    }
    return ret;
  }

  async UpdateSystemCode(type: string[]) {
    this.SystemCodeCached.update(type);
  }

  async DeleteSystemCode(type: string[]) {
    this.SystemCodeCached.delete(type);
  }
  //#endregion

  clearCache() {
    this.allICache.forEach(x=>x.clearCache());
  }


  async GetRxOption(): Promise<RxOpt[]> {
    await this.rxLock.doInLock(async () => {
      if (!this.rxOption) {
        this.rxOption = await this.histApi.GetRxOptions();
      }
    });
    return this.rxOption;
  }

  async UpdateRx(rxOpt: { UpdateType: UpdateType, Data: RxOpt }[]) {
    if (this.rxOption) {
      rxOpt.forEach(x => {
        if (x.UpdateType == UpdateType.Insert) {
          // 避免重複
          var exist = this.rxOption.some(y => y.Id == x.Data.Id);
          if (!exist) {
            this.rxOption.push(x.Data);
          }

        } else if (x.UpdateType == UpdateType.Update) {
          //更新
          var opt = this.rxOption.find(y => y.Id == x.Data.Id)
          if (opt) {
            Object.assign(opt, x.Data);
          }
        } else if (x.UpdateType == UpdateType.Delete) {
          //留下沒有在移除清單中的
          this.rxOption = this.rxOption.filter(y => y.Id !== x.Data.Id);
        }
      })

      this.rxOption = this.rxOption.sort((a, b) => Compare(a.RxCode, b.RxCode));
      //this.rxOption = await this.histApi.GetRxOptions();
      this.showNotify('院內醫令已變更…');
    }
  }

  async GetRsOption(): Promise<RsOpt[]> {
    await this.rsLock.doInLock(async () => {
      if (!this.rsOption) {
        this.rsOption = await this.histApi.GetRsOptions();
      }
    });
    return this.rsOption;
  }

  async UpdateRs(rsOpt: { UpdateType: UpdateType, Data: RsOpt }[]) {
    if (this.rsOption) {
      rsOpt.forEach(x => {
        if (x.UpdateType == UpdateType.Insert) {
          // 避免重複
          var exist = this.rsOption.some(y => y.MainSetId == x.Data.MainSetId && y.RxId == x.Data.RxId);
          if (!exist) {
            this.rsOption.push(x.Data);
          }
        } else if (x.UpdateType == UpdateType.Update) {
          //更新
          var opt = this.rsOption.find(y => y.MainSetId == x.Data.MainSetId && y.RxId == x.Data.RxId)
          if (opt) {
            Object.assign(opt, x.Data);
          } else {
            this.rsOption.push(x.Data);
          }
        } else if (x.UpdateType == UpdateType.Delete) {
          //留下沒有在移除清單中的
          this.rsOption = this.rsOption.filter(y => y.MainSetId !== x.Data.MainSetId);
        }
      })
      this.rsOption = this.rsOption.sort((a, b) => Compare(a.RsCode, b.RsCode));
      //this.rxOption = await this.histApi.GetRxOptions();
      this.showNotify('組合套餐已變更…');
    }
  }
  dxLock = new loadHelper();
  rxLock = new loadHelper();
  rsLock = new loadHelper();
  statLock = new loadHelper();
  async GetDxOption(diagVersion: string = ''): Promise<DxOpt[]> {
    await this.dxLock.doInLock(async () => {
      var renew = this.dxVersion !== diagVersion;
      this.dxVersion = diagVersion;
      if (!this.dxOption || renew) {
        this.dxOption = await this.histApi.GetDxOptions(diagVersion);
      }
    });
    return this.dxOption;
  }
  async UpdateDx(dxOpt: { UpdateType: UpdateType, Data: DxOpt }[]) {
    if (this.dxOption) {
      dxOpt.forEach(x => {
        if (x.UpdateType == UpdateType.Insert) {
          // 避免重複
          var exist = this.dxOption.some(y => y.ApplyCode == x.Data.ApplyCode);
          if (!exist) {
            this.dxOption.push(x.Data);
          }

        } else if (x.UpdateType == UpdateType.Update) {
          //更新
          var opt = this.dxOption.find(y => y.ApplyCode == x.Data.ApplyCode)
          if (opt) {
            Object.assign(opt, x.Data);
          }
        } else if (x.UpdateType == UpdateType.Delete) {
          //留下沒有在移除清單中的
          this.dxOption = this.dxOption.filter(y => y.ApplyCode !== x.Data.ApplyCode);
        }
      })

      this.dxOption = this.dxOption.sort((a, b) => Compare(a.Code, b.Code));
      //this.rxOption = await this.histApi.GetRxOptions();
      this.showNotify('院內診斷代碼已變更…');
    }
  }
  async GetStatOpt(): Promise<StatOpts> {
    await this.statLock.doInLock(async () => {
      if (!this.statOpt) {
        this.statOpt = await this.histApi.GetStatOptions();
      }
    });
    return this.statOpt;
  }
  async UpdateStat(data: StatOpts) {
    if (this.statOpt) {
      this.statOpt = data;
      this.showNotify('近期常用項目已被更新');
    }

  }
  private showNotify(msg: string) {
    if (this.showDataChangedNotify) {
      this.notify.showInfo(msg);;
    }
  }
  async GetSPCTOption(): Promise<ValueTextPair[]> {
    if (!this.spcTOption) {
      this.spcTOption = await this.histApi.GetSPCOptions();
    }
    return this.spcTOption;
  }

  public cleanRxCache() {
    delete this.rxOption;
    delete this.rsOption;
  }

  public async reload(){
    if(this.rxOption){
      delete this.rxOption;
      await this.GetRxOption();
      this.notify.showSuccess('已重載院內醫令')
    }
    if(this.rsOption){
      delete this.rsOption;
      await this.GetRsOption();
      this.notify.showSuccess('已重載院內套餐')
    }
    if(this.dxOption){
      delete this.dxOption;
      await this.GetDxOption();
      this.notify.showSuccess('已重載院內診斷')
    }
    for(let c of this.allICache){
      await c.reload();
    }
    this.onReload.emit();
  }
  /**
   * 開啟參數編輯畫面(不包含hidden項目)
   * @param paraType 參數群組
   * @param fields 開放編輯的項目們，沒設定表示全開
   */
  async editParam<K extends keyof ParamMap>(paraType:K,fields?:(keyof ParamMap[K])[]){
    var def = await this.paramApi.GetDesc(paraType);
    var p = await this.paramApi.GetParams(paraType);
    var field:FormFieldCollection<ParamMap[K]> = {};
    def.forEach(x=>{
      if(fields && !fields?.some(y=>y == x.Key)){
        return;
      }
      var value = p[x.Key];
      if(value instanceof Array){
        value = value.join(',')
      }
      var d // :FieldDefineT<LAB001Params,''>
        = {
        name: x.Key,
        label:x.Name,
        type: x.DataType== ParameterDataType.Boolean?'check':
              x.DataType== ParameterDataType.Datetime?'date':
              x.DataType== ParameterDataType.Int?'number':
              x.DataType== ParameterDataType.String?'text':
              x.DataType== ParameterDataType.Switch?'check':'text',
        value:value,
        tip:x.Description+' Ex:'+x.Value
      }
      field[x.Key] = d;
    })
    var ret = await this.ezForm.show<ParamMap[K]>({
      title:paraType,
      msg:'參數編輯',
      width:450,
      height:550,
      fields:field,
      closeValue:()=>null
    });
    if(ret){
      p = Object.assign(p,ret);
      try{
        await this.paramApi.UpdateParamGroup(paraType ,p);
        this.notify.showSuccess('更新完成');
      }catch(e){
        this.notify.showSuccess('更新失敗');
      }
    }
  }

}

class loadHelper {
  private isLoadSubject: Subject<boolean> = new Subject<boolean>();
  isLoad = false;
  async doInLock(fn: () => {}) {
    if (this.isLoad) {
      await new Promise<boolean>((rs, rj) => {
        this.isLoadSubject.asObservable().subscribe(x => {
          rs(x);
        })
      })
    }
    this.isLoad = true;
    try {
      await fn()
    } catch (e) {
      throw e;
    } finally {
      this.isLoad = false;
      this.isLoadSubject.next(true);
    }
  }
}
export { EnumTypes };

