import { Injectable } from "@angular/core";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { CustomResult } from "../../../shared/models/custom-result";
import { PageData } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { NhiimiSetting } from "src/app/system/clinic/models/nhiimiSetting";
import { Role } from "src/app/permission/role/models/IRole";
import { ConsoleInfo } from "../../hcr-service.service";

export class NhiimiListDto {
  Id?: number;
  Stype?: number;
  ATC?: string;
  StdCode?: string;
  RxCode: string;
  Sort: number;
};

@Injectable({providedIn:'root'})
export class ClinicApi{
    apiService: WebApiService;
    /**
     *
     */
    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createHisService('system/clinic');
    }
    async Get(id:number): Promise<Clinic>{
        var ret = await this.apiService.get('Get/'+id).toPromise<Clinic>();
        return ret;
    }
    async Update(clinic :Clinic): Promise<CustomResult>{
        return this.apiService.post('Update',clinic).toPromise();
    }
    async CheckMountain(clinic :Clinic): Promise<string>{
        return this.apiService.post('CheckMountain',clinic).toPromise<string>();
    }

    async RepireAll(company:number,clinic:number): Promise<void>{
        return this.apiService.post('SystemRepire?companyId='+company+'&clinicId='+clinic+'&sysCode=true&param=true&sysNum=true&regNumRule=true&disount=true',null).toPromise<void>();
    }
    async RepireSystemCode(company:number,clinic:number): Promise<void>{
        return this.apiService.post('SystemRepire?companyId='+company+'&clinicId='+clinic+'&sysCode=true',null).toPromise<void>();
    }
    async RepireParameters(company:number,clinic:number): Promise<void>{
        return this.apiService.post('SystemRepire?companyId='+company+'&clinicId='+clinic+'&param=true',null).toPromise<void>();
    }
    async RepireSystemNumber(company:number,clinic:number): Promise<void>{
        return this.apiService.post('SystemRepire?companyId='+company+'&clinicId='+clinic+'&sysNum=true',null).toPromise<void>();
    }
    async RepireRegNumberRule(company:number,clinic:number): Promise<void>{
        return this.apiService.post('SystemRepire?companyId='+company+'&clinicId='+clinic+'&regNumRule=true',null).toPromise<void>();
    }
    async RepireDiscount(company:number,clinic:number): Promise<void>{
        return this.apiService.post('SystemRepire?companyId='+company+'&clinicId='+clinic+'&discount=true',null).toPromise<void>();
    }
    async GetAll(): Promise<PageData>{
        return this.apiService.get(`GetAll`,
        {
            code:'',
            name:'',
            pageSkip:0,
            pageSize:1000
        }).toPromise();
    }
    async GetList(pageNum:number,pageSize:number): Promise<PageData>{

        return this.apiService.get(`GetList`,
        {
            pageSkip:(pageNum-1)*pageSize,
            pageSize:pageSize
        }).toPromise();
    }
    async GetNHIIMISettingAsync(clinicId: number): Promise<NhiimiSetting> {
        return this.apiService.get('GetNHIIMISetting?clinicId='+clinicId).toPromise<NhiimiSetting>();
    }
    async UpdateNHIIMISetting(Model: NhiimiSetting): Promise<string> {
        var result = await this.apiService.post('UpdateNHIIMISetting', Model).toPromise<string>();
        return result;
    }
    async GetNHIIMIList(rxCodes: NhiimiListDto[]): Promise<NhiimiListDto[]> {
      var ret: CustomResult = await this.apiService.post('GetNHIIMIList', rxCodes).toPromise<CustomResult>();
      return ret.IsSuccess ? ret.Object : '';
    }
    // async CreateNHIIMISetting(Model: NhiimiSetting): Promise<CustomResult> {
    //     return this.apiService.post('CreateNHIIMISetting', Model).toPromise();
    // }
    async cloneRoleBy(roleId:number, newRole:Role){
        return this.apiService.post('cloneRoleBy',{
            roleId,newRole
        } ).toPromise();
    }
    async UpdateConsoleInfo(info:ConsoleInfo){
        return this.apiService.post('UpdateConsoleInfo',info).toPromise();
    }
    async GetServicePhone(code: string): Promise<string> {
      return this.apiService.get('GetServicePhone/' + code).toPromise<string>();
    }
}
