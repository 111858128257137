import { RegisterWaitingListQueryTypeEnum } from "src/app/enums/RegisterWaitingListQueryTypeEnum";
import { TimeSecEnum } from "src/app/enums/TimeSecEnum";
import { QueryParam } from "../../api-service/queryParam";
import { ReserveDTO } from "../reserve/reserve-api.model";
import { ReserveItem } from "../../reserve.service";
import { RegisterConst } from "src/app/shared/constants/register-const";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { ICNoPHOption } from "../../api-service/register/register-api";
import { RegisterEditDto } from "../../api-service/register/RegisterEditDto";
import { PageData } from "src/app/shared/models/page-data";

export class RegisterWaitingQueryDTO extends QueryParam {
  queryDate: string;
  doctorId?: number;
  timeSec: TimeSecEnum;
  statusCodes: string;
  otherQueryType: RegisterWaitingListQueryTypeEnum;
  otherQueryCondition?: string;
  orderBy: string = "";
  orderDesc: boolean = false;
}

export class RegisterWaitingDTO {
  RegisterSummaryInfo: RegisterSummaryInfoDTO;
  RegisterWaitingItems: PageData; // RegisterWaitingItemDTO[];
  RegisterReserveItems: PageData; // RegisterReserveDTO[];

  constructor() {
    this.RegisterSummaryInfo = new RegisterSummaryInfoDTO();
    this.RegisterWaitingItems = new PageData(); //[];
    this.RegisterReserveItems = new PageData(); //[];
  }
}

export class RegisterSummaryInfoDTO {
  AppointmentCount: number;
  AppointmentCheckInCount: number;
  RegisterOnSiteCount: number;
  NotSpecifiedDoctorCount: number;
  SameDayNewPatientCount: number;
  FinishCount: number;
  TotalCount: number;

  constructor() {
    this.AppointmentCount = 0;
    this.AppointmentCheckInCount = 0;
    this.RegisterOnSiteCount = 0;
    this.NotSpecifiedDoctorCount = 0;
    this.SameDayNewPatientCount = 0;
    this.FinishCount = 0;
    this.TotalCount = 0;
  }
}

export class RegisterWaitingItemDTO {
  RegisterId: number;
  PatientId: number;
  PatientNo: string;
  PatientName: string;
  PatientSex: string;
  PatientAge: string;
  ICode: string;
  TreatType: string;
  PatientRemark: string;
  SameDayRemark: string;
  DoctorId?: number;
  DoctorName: string;
  StatusCode: string;
  StatusCodeValue: string;
  WaitNo: string;
  CheckInTime?: Date;
  EstimatedTime: string;
  RegTime: Date;
  EndTime?: Date;
  PeriodItems: RegisterPeriodItemDTO[];
  ClinicHistoryies: RegisterClinicHistoryDTO[];

  constructor() {
    this.PeriodItems = [];
    this.ClinicHistoryies = [];
  }
}

export class RegisterReserveDTO {
  RegisterId?: number;
  IsCheckedIn: boolean;
  PatientId: number;
  PatientNo: string;
  PatientName: string;
  PatientSex: string;
  PatientAge: string;
  PatientPhone: string;
  ReserveMatters: string;
  DoctorId: number;
  DoctorName: string;
  EstimatedTime: number;
  ReserveDateTime: Date;

  constructor(init?: Partial<RegisterReserveDTO>) {
    Object.assign(this, init);
  }

  static fromReserveItem(reserveItem: ReserveItem[]) {
    let dtos = reserveItem.map(x => {
      let dto = new RegisterReserveDTO();
      dto.IsCheckedIn = x.IsCheckedIn;
      dto.PatientId = x.PatientId;
      dto.PatientNo = x.PatientNo;
      dto.PatientName = x.PatientCName;
      dto.PatientSex = x.SexName;
      dto.PatientAge = x.PatientAge;
      dto.PatientPhone = x.PatientPhone;
      dto.ReserveMatters = x.ReserveMatters;
      dto.DoctorId = x.DoctorId;
      dto.DoctorName = x.DoctorName;
      dto.EstimatedTime = x.EstimatedTime;
      dto.ReserveDateTime = x.RegDate;

      return dto;
    })

    return dtos;
  }
}

export class RegisterPeriodItemDTO {
  RxCodes: string[];
  PeriodItemName: string;
  PeriodMonths: string;
  IsAllowExecute: boolean;
  PreviousDate: string;
  ExecutableDate: string

  constructor(init?: Partial<RegisterPeriodItemDTO>) {
    Object.assign(this, init);
  }
}

export class RegisterClinicHistoryDTO {
  HRxId: number;
  RxCode: string;
  StdCode: string;
  RegDate: string;
  ToothPos: string;
  Way: string;

  constructor(init?: Partial<RegisterClinicHistoryDTO>) {
    Object.assign(this, init);
  }
}

export class RegisterChangeDoctorDTO {
  RegId: number;
  DoctorId: number;
}

/** 掛號頁物件 */
export class RegisterEditPageDTO {
  /** 掛號序號，新增時會是null */
  RegisterId?: number;
  /** 掛號日期 */
  RegDate: Date;
  /** 優待序號 */
  DiscountId?: number;
  /** 保險身分 */
  ICode: string;
  /** 醫師序號 */
  DoctorId?: number;
  /** 時段 */
  TimeSec: TimeSecEnum;
  /** 初複診 */
  VisitSourceCode: string;
  /** 狀態 */
  StatusCode: string;
  /** 護理登錄 */
  Remark: string;
  /** 身分備註 */
  INote: string;
  /** 病患序號 */
  PatientId: number;
  /** 病患編號 */
  PatientNo: string;
  /** 病患姓名 */
  PatientName: string;
  /** 病患生日 */
  PatientBirthday: Date;
  /** 病患身分證 */
  PatientCId: string;
  /** 病患性別 */
  PatientSex: string;
  /** 病患住家電話區碼 */
  PatientPhoneArea: string;
  /** 病患住家電話 */
  PatientPhone: string;
  /** 病患手機號碼 */
  PatientMobile: string;
  /** 病患住址(城市) */
  PatientCity: string;
  /** 病患住址(鄉鎮區) */
  PatientArea: string;
  /** 病患住址(地址) */
  PatientStreet: string;
  /** 病患特別記載 */
  PatientRemark: string;

  /** 就醫類別 */
  Nhictp: string;
  /** 就醫序號 */
  Isqno: string;
  /** 押金 */
  Deposit: number;
  /** 部分負擔 */
  OwnFee: number;
  /** 掛號費 */
  RegisterFee: number;
  /** 自費額 */
  SelfPay: number;
  /** 就醫憑證 */
  IType: string;
  /** 掛號類別/療程 */
  SameTreat: string;
  /** 掛號卡序 */
  Icno: string;
  /** IC註記 */
  RemarkIC: string;
  /** 可用次數 */
  AvailableCount?: number;

  MedDeptCode: string = "40";

  PeriodItems: RegisterPeriodItemDTO[];

}


export class RegisterEditPageOptionsDTO {
  /** 時段 */
  TimeSections: ValueTextPairNumberValue[];
  /** 護理登錄 */
  Remarks: ValueTextPair[];
  /** 身分(ICode) */
  Insurances: ValueTextPair[];
  /** 診斷類型(VisitSourceCode) */
  VisitTypes: ValueTextPair[];
  /** 科別(MedDeptCode) */
  Sections: ValueTextPair[];
  /** 優待
   * extension中放id
  */
  Discounts: ValueTextPair[];
  /** 掛號類別/療程 */
  SameTreats: ValueTextPair[];
  /** 卡序 */
  RegisterSeqs: ICNoPHOption[];
  /** 身分備註 */
  INotes: ValueTextPair[];
  /** 醫師選項 */
  Doctors: ValueTextPairNumberValue[] = [];
  /** 狀態代碼清單 */
  StatusCodes: ValueTextPair[];
  /** 城市清單 */
  Cities: ValueTextPair[] = [];
  /** 鄉鎮區清單 */
  Areas: ValueTextPair[] = [];
  /** 科別清單 */
  Section: ValueTextPair[] = [];
  /** 最大可預約日 */
  MaxAppointmentDate: Date;
}

export class RegisterEditOptionDTO {
  /** 醫師選項 */
  DoctorOptions: ValueTextPairNumberValue[];
  /** 優惠選項 */
  DiscountOptions: ValueTextPair[] = [];

}

