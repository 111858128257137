<div class="a4-rotate-print mb-3 p-4" style="text-align: left; vertical-align: top;" #reportContainer>
    <div *ngIf="type == 2 && doubleSidedPrintType == 0">
        <div style="-webkit-text-size-adjust: none; padding-left: 20px; padding-top: 12px;font-family: '{{ fontType }}';font-size: 13px;line-height: 1.2;" *ngIf="hist && clinic" >
            <div>
                健保代碼:{{clinic.NHICode}} {{clinic.Name}}藥品明細收據
            </div>
            <div style="display:flex" >
              姓名:{{hist.Patient.CName}}&nbsp;&nbsp;
              性別:{{hist.Patient.SexName}}&nbsp;&nbsp;
              身分證:{{hist.Patient.CId| CIdMask:cidMask}}&nbsp;&nbsp;
              生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
              {{'病歷號:' + hist.Patient.PatientNo + '&nbsp;&nbsp;'}}
              {{'日份:' + hist.Hist.Days}}
            </div>
            <div style="display:flex">
                就診日期:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                身分別:{{hist.Register.ICodeName}}&nbsp;&nbsp;
                診别:{{hist.Register.RoomName}}&nbsp;&nbsp;
                科別:{{getMedDeptName(hist.Register.MedDeptCode)}}&nbsp;&nbsp;
                {{'就醫序號:' + isqno + '&nbsp;&nbsp;'}}
                {{'部分負擔代號:' + (hist.Register.ICode==='000' ? '' : hist.Hist.ApartCode)}}
            </div>
            <div>
                -----健保申報項目(點數)　健保申報點數非一點一元給付---------------
            </div>
            <div style="display:flex">
                <div style="text-align: left;">
                    診察:{{hist.Hist.ADx}}&nbsp;
                    藥費:{{hist.Hist.ARx}}&nbsp;
                    藥事:{{hist.Hist.AMed}}&nbsp;
                    注射費:{{hist.Hist.ATx5}}&nbsp;
                    檢驗費:{{hist.Hist.ATx6}}&nbsp;
                    檢查費:{{hist.Hist.ATx7}}&nbsp;
                </div>
                <div style="display:flex">
                    處置手術費:{{hist.Hist.ATx9}}&nbsp;
                    材料費:{{hist.Hist.AMat}}&nbsp;
                    合計:{{hist.Hist.ATot}}&nbsp;
                    健保申報:{{hist.Hist.ADcl}} 點
                </div>
            </div>
            <div>
                -----自付費用項目(金額)------------------------------------------
            </div>
            <div style="display: flex;">
                <div style="text-align: left;">
                    掛號費:{{hist.Register.RegisterFee}}&nbsp;
                    藥品:{{receiptOwnFees.OwnRx}}&nbsp;
                    注射:{{receiptOwnFees.OwnTx5}}&nbsp;
                    檢驗檢查:{{receiptOwnFees.OwnTx67}}&nbsp;
                    基本自付:{{hist.Register.OwnFee}}&nbsp;
                </div>
                <div style="display: flex;">
                    點滴:{{receiptOwnFees.OwnTx30}}&nbsp;
                    保健食品:{{receiptOwnFees.OwnTx31}}&nbsp;
                    處方藥:{{receiptOwnFees.OwnTx32}}&nbsp;
                </div>
            </div>
            <div style="display: flex;">
                {{(hist.Hist.APartRx && hist.Hist.APartRx != 0) ? '★藥自付:' + (hist.Hist.APartRx * -1) + '&nbsp;' : ''}}
                {{(hist.Hist.APartExam && hist.Hist.APartExam != 0) ? '★檢驗自付:' + hist.Hist.APartExam + '&nbsp;' : ''}}
                {{(clinic.Code != '10272'?'押金:'+hist.Register.LAMT+ '&nbsp;' : '' )}}
                {{'其他自費:' + receiptOwnFees.OwnOther + '&nbsp;'}}
            </div>
            <div style="padding-left: 12px;display: flex;">
                ※本次收據共計:{{(hist.Register.RegisterFee + hist.Register.OwnFee + hist.Hist.APartExam + (hist.Hist.APartRx*-1) + hist.Hist.AOwn +(clinic.Code != '10272'?hist.Register.LAMT:0 ))}}
            </div>
            <div>
                ------------------------------------------------------------------
            </div>
            <div  style="display:flex">
                <div style="display:flex">
                    <div style="width: 12px;">
                        N
                    </div>
                    <div style="width: 200px;">
                        藥品名稱
                    </div>
                    <div style="width: 100px;">
                        服法*天數
                    </div>
                    <div style="width: 35px;">
                        劑量
                    </div>
                    <div style="width: 35px;">
                        總數
                    </div>
                    <div style="width: 35px;">
                        單位
                    </div>
                </div>
            </div>
            <div>
                ====================================================================
            </div>
            <div  >
                <ng-container *ngFor="let item of hrxViewModel; let i = index">
                    <div style="display:flex" >
                        <div style="width: 12px;">
                            {{item.SPRule}}
                        </div>
                        <div style="width: 200px;">
                            {{item.ProdName}}
                        </div>
                        <div style="width: 100px;">
                            {{item.Freq}}* {{item.Days}}
                        </div>
                        <div style="width: 35px; text-align: right;">
                           {{item.QTYDose}}
                        </div>
                        <div style="width: 35px; text-align: right;">
                            {{item.TotalDose}}
                        </div>
                        <div style="width: 35px;">
                            {{item.Unit}}
                        </div>
                    </div>
                    <ng-container *ngIf="param2?.ShowWarning && item.Warnings">
                        △警語:{{item.Warnings}}
                        <br />
                    </ng-container>

                    <ng-container *ngIf="param2?.ShowIndications && item.Indications">
                        △適應症:{{item.Indications}}
                        <br />
                    </ng-container>

                    <ng-container *ngIf="param2?.ShowSideEffects && item.SideEffects">
                        △副作用:{{item.SideEffects}}
                        <br />
                    </ng-container>
                </ng-container>
                <br />
            </div>
            <div style="display: flex;">
                <div style="padding-right:1.5rem;">
                    醫師: {{hist.Register.DoctorName}}
                </div>
                <div style="padding-right:1.5rem;" *ngIf="hasPharmacist">
                    藥師: {{hist.Hist.PharmacistName}}
                </div>
                <div style="padding-right:1.5rem;">
                    地址: {{clinicAddress}}
                </div>
                <div style="padding-right:1.5rem;">
                    電話: {{clinic.Phone}}
                </div>
            </div>
        </div>
    </div>
    <div style="text-align: left; vertical-align: top; margin: 0; padding: 0;" *ngIf="type == 1">
    <table style="margin: 0; padding: 0;">
        <tr style="margin: 0; padding: 0;">
            <td style="width: 50%; margin: 0; padding: 0;">
                <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 13px;line-height: 1.2;" *ngIf="hist && clinic" >
                    <div>
                        健保代碼:{{clinic.NHICode}} {{clinic.Name}}藥品明細收據
                    </div>
                    <div style="display:flex" >
                        姓名:{{hist.Patient.CName}}&nbsp;&nbsp;
                        性別:{{hist.Patient.SexName}}&nbsp;&nbsp;
                        身分證:{{hist.Patient.CId| CIdMask:cidMask}}&nbsp;&nbsp;
                        生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                    </div>
                    <div style="display:flex">
                        就診日期:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                        身分別:{{hist.Register.ICodeName}}&nbsp;&nbsp;
                        診别:{{hist.Register.RoomName}}&nbsp;&nbsp;
                        科別:{{getMedDeptName(hist.Register.MedDeptCode)}}&nbsp;&nbsp;
                    </div>
                    <div style="display:flex">
                        病歷號:{{hist.Patient.PatientNo}}&nbsp;&nbsp;
                        就醫序號:{{hist.Register.Icno}}&nbsp;&nbsp;
                        部分負擔代號:{{hist.Hist.ApartCode}}&nbsp;&nbsp;
                        日份:{{hist.Hist.Days}}
                    </div>
                    <div>
                        -----健保申報項目(點數)----------------------------------------
                    </div>
                    <div style="display:flex">
                        <div style="text-align: left;">
                            診察:{{hist.Hist.ADx}}&nbsp;
                            藥費:{{hist.Hist.ARx}}&nbsp;
                            藥事:{{hist.Hist.AMed}}&nbsp;
                            注射費:{{hist.Hist.ATx5}}&nbsp;
                            檢驗費:{{hist.Hist.ATx6}}&nbsp;
                            檢查費:{{hist.Hist.ATx7}}&nbsp;
                        </div>
                    </div>
                    <div style="display:flex">
                        處置手術費:{{hist.Hist.ATx9}}&nbsp;
                        材料費:{{hist.Hist.AMat}}&nbsp;
                        合計:{{hist.Hist.ATot}}&nbsp;
                        健保申報:{{hist.Hist.ADcl}} 點
                    </div>
                    <div>
                        健保申報點數非一點一元給付
                    </div>
                    <div>
                        -----自付費用項目(金額)------------------------------------------
                    </div>
                    <div style="display: flex;">
                        <div style="text-align: left;">
                            掛號費:{{hist.Register.RegisterFee}}&nbsp;
                            藥品:{{receiptOwnFees.OwnRx}}&nbsp;
                            注射:{{receiptOwnFees.OwnTx5}}&nbsp;
                            檢驗檢查:{{receiptOwnFees.OwnTx67}}&nbsp;
                            基本自付:{{hist.Register.OwnFee}}&nbsp;
                        </div>
                    </div>
                    <div style="display: flex;">
                        {{(hist.Hist.APartRx && hist.Hist.APartRx != 0) ? '★藥自付:' + (hist.Hist.APartRx*-1) + '&nbsp;' : ''}}
                        {{(hist.Hist.APartExam && hist.Hist.APartExam != 0) ? '★檢驗自付:' + hist.Hist.APartExam + '&nbsp;' : ''}}
                        {{(clinic.Code != '10272'?'押金:'+hist.Register.LAMT+ '&nbsp;' : '' )}}
                        {{'其他自費:' + receiptOwnFees.OwnOther + '&nbsp;'}}
                    </div>
                    <div style="padding-left: 12px;display: flex;">
                        ※本次收據共計:{{(hist.Register.RegisterFee + hist.Register.OwnFee + hist.Hist.APartExam + (hist.Hist.APartRx*-1) + hist.Hist.AOwn + (clinic.Code != '10272'?hist.Register.LAMT:0 ))}}
                    </div>
                    <div>
                        ------------------------------------------------------------------
                    </div>
                    <div  style="display:flex">
                        <div style="display:flex">
                            <div style="width: 12px;">
                                N
                            </div>
                            <div style="width: 200px;">
                                藥品名稱
                            </div>
                            <div style="width: 100px;">
                                服法*天數
                            </div>
                            <div style="width: 35px;">
                                劑量
                            </div>
                            <div style="width: 35px;">
                                總數
                            </div>
                            <div style="width: 35px;">
                                單位
                            </div>
                        </div>
                    </div>
                    <div>
                        ====================================================================
                    </div>
                    <div  >
                        <ng-container *ngFor="let item of hrxViewModel; let i = index">
                            <div style="display:flex" >
                                <div style="width: 12px;">
                                    {{item.SPRule}}
                                </div>
                                <div style="width: 200px;">
                                    {{item.ProdName}}
                                </div>
                                <div style="width: 100px;">
                                    {{item.Freq}}* {{item.Days}}
                                </div>
                                <div style="width: 35px; text-align: right;">
                                   {{item.QTYDose}}
                                </div>
                                <div style="width: 35px; text-align: right;">
                                    {{item.TotalDose}}
                                </div>
                                <div style="width: 35px;">
                                    {{item.Unit}}
                                </div>
                            </div>
                            <ng-container *ngIf="param2?.ShowWarning && item.Warnings">
                                △警語:{{item.Warnings}}
                                <br />
                            </ng-container>

                            <ng-container *ngIf="param2?.ShowIndications && item.Indications">
                                △適應症:{{item.Indications}}
                                <br />
                            </ng-container>

                            <ng-container *ngIf="param2?.ShowSideEffects && item.SideEffects">
                                △副作用:{{item.SideEffects}}
                                <br />
                            </ng-container>

                        </ng-container>
                        <br />
                    </div>
                    <div style="display: flex;">
                        <div style="padding-right:0.5rem;">
                            醫師: {{hist.Register.DoctorName}}
                        </div>
                        <div style="padding-right:0.5rem;" *ngIf="hasPharmacist">
                            藥師: {{hist.Hist.PharmacistName}}
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div style="padding-right:0.5rem;">
                            地址: {{clinicAddress}}
                        </div>
                        <div style="padding-right:0.5rem;">
                            電話: {{clinic.Phone}}
                        </div>
                    </div>
                </div>
            </td>
            <td style="width: 50%">
                <div>&nbsp;&nbsp;</div>
            </td>
        </tr>
    </table>
    </div>
    <div style="text-align: left; vertical-align: top; margin: 0; padding: 0;" *ngIf="type == 3">
        <table style="width: 100%; margin: 0; padding: 0;">
            <tr style="margin: 0; padding: 0;">
                <td style="margin: 0; padding: 0;">
                    <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 13px;line-height: 1.2;" *ngIf="hist && clinic" >
                        <div>
                            健保代碼:{{clinic.NHICode}} {{clinic.Name}}藥品明細收據
                        </div>
                        <div style="display:flex" >
                            姓名:{{hist.Patient.CName}}&nbsp;&nbsp;
                            性別:{{hist.Patient.SexName}}&nbsp;&nbsp;
                            身分證:{{hist.Patient.CId| CIdMask:cidMask}}&nbsp;&nbsp;
                            生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                        </div>
                        <div style="display:flex">
                            就診日期:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                            身分別:{{hist.Register.ICodeName}}&nbsp;&nbsp;
                            診别:{{hist.Register.RoomName}}&nbsp;&nbsp;
                            科別:{{getMedDeptName(hist.Register.MedDeptCode)}}&nbsp;&nbsp;
                        </div>
                        <div style="display:flex">
                            病歷號:{{hist.Patient.PatientNo}}&nbsp;&nbsp;
                            就醫序號:{{hist.Register.Icno}}&nbsp;&nbsp;
                            部分負擔代號:{{hist.Hist.ApartCode}}&nbsp;&nbsp;
                            日份:{{hist.Hist.Days}}
                        </div>
                        <div>
                            -----健保申報項目(點數)-----------------------------------------
                        </div>
                        <div style="display:flex">
                            <div style="text-align: left;">
                                診察:{{hist.Hist.ADx}}&nbsp;
                                藥費:{{hist.Hist.ARx}}&nbsp;
                                藥事:{{hist.Hist.AMed}}&nbsp;
                                注射費:{{hist.Hist.ATx5}}&nbsp;
                                檢驗費:{{hist.Hist.ATx6}}&nbsp;
                                檢查費:{{hist.Hist.ATx7}}&nbsp;
                            </div>
                        </div>
                        <div style="display:flex" >
                            處置手術費:{{hist.Hist.ATx9}}&nbsp;
                            材料費:{{hist.Hist.AMat}}&nbsp;
                            合計:{{hist.Hist.ATot}}&nbsp;
                            健保申報:{{hist.Hist.ADcl}} 點
                        </div>
                        <div>
                            健保申報點數非一點一元給付
                        </div>
                        <div>
                            -----自付費用項目(金額)-----------------------------------------------
                        </div>
                        <div style="display: flex;">
                            <div style="text-align: left;">
                                掛號費:{{hist.Register.RegisterFee}}&nbsp;
                                藥品:{{receiptOwnFees.OwnRx}}&nbsp;
                                注射:{{receiptOwnFees.OwnTx5}}&nbsp;
                                檢驗檢查:{{receiptOwnFees.OwnTx67}}&nbsp;
                                基本自付:{{hist.Register.OwnFee}}&nbsp;
                            </div>
                        </div>
                        <div style="display: flex;">
                            {{(hist.Hist.APartRx && hist.Hist.APartRx != 0) ? '★藥自付:' + (hist.Hist.APartRx*-1) + '&nbsp;' : ''}}
                            {{(hist.Hist.APartExam && hist.Hist.APartExam != 0) ? '★檢驗自付:' + hist.Hist.APartExam + '&nbsp;' : ''}}
                            {{(clinic.Code != '10272'?'押金:'+hist.Register.LAMT+ '&nbsp;' : '' )}}
                            {{'其他自費:' + receiptOwnFees.OwnOther + '&nbsp;'}}
                        </div>
                        <div style="padding-left: 12px;display: flex;">
                            ※本次收據共計:{{(hist.Register.RegisterFee + hist.Register.OwnFee + hist.Hist.APartExam + (hist.Hist.APartRx*-1) + hist.Hist.AOwn + (clinic.Code != '10272'?hist.Register.LAMT:0 ))}}
                        </div>
                        <div>
                            ----------------------------------------------------------------------
                        </div>
                        <div  style="display:flex">
                            <div style="display:flex">
                                <div style="width: 12px;">
                                    N
                                </div>
                                <div style="width: 200px;">
                                    藥品名稱
                                </div>
                                <div style="width: 100px;">
                                    服法*天數
                                </div>
                                <div style="width: 35px;">
                                    劑量
                                </div>
                                <div style="width: 35px;">
                                    總數
                                </div>
                                <div style="width: 35px;">
                                    單位
                                </div>
                            </div>
                        </div>
                        <div>
                            ========================================================================
                        </div>
                        <div  >
                            <ng-container *ngFor="let item of hrxViewModel; let i = index">
                                <div style="display:flex" >
                                    <div style="width: 12px;">
                                        {{item.SPRule}}
                                    </div>
                                    <div style="width: 200px;">
                                        {{item.ProdName}}
                                    </div>
                                    <div style="width: 100px;">
                                        {{item.Freq}}* {{item.Days}}
                                    </div>
                                    <div style="width: 35px; text-align: right;">
                                       {{item.QTYDose}}
                                    </div>
                                    <div style="width: 35px; text-align: right;">
                                        {{item.TotalDose}}
                                    </div>
                                    <div style="width: 35px;">
                                        {{item.Unit}}
                                    </div>
                                </div>
                                <ng-container *ngIf="param2?.ShowWarning && item.Warnings">
                                    △警語:{{item.Warnings}}
                                    <br />
                                </ng-container>

                                <ng-container *ngIf="param2?.ShowIndications && item.Indications">
                                    △適應症:{{item.Indications}}
                                    <br />
                                </ng-container>

                                <ng-container *ngIf="param2?.ShowSideEffects && item.SideEffects">
                                    △副作用:{{item.SideEffects}}
                                    <br />
                                </ng-container>
                            </ng-container>
                            <br />
                        </div>
                        <div style="display: flex;">
                            <div style="padding-right:0.5rem;">
                                醫師: {{hist.Register.DoctorName}}
                            </div>
                            <div style="padding-right:0.5rem;" *ngIf="hasPharmacist">
                                藥師: {{hist.Hist.PharmacistName}}
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div style="padding-right:0.5rem;">
                                地址: {{clinicAddress}}
                            </div>
                            <div style="padding-right:0.5rem;">
                                電話: {{clinic.Phone}}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div>&nbsp;&nbsp;</div>
                </td>
            </tr>
        </table>
    </div>
    <div style="text-align: left; vertical-align: top; margin: 0; padding: 0;" *ngIf="type == 4">
        <!-- <div style="margin-top: 0.4cm;"></div> -->
        <table style="margin: 0; padding: 0; vertical-align: top;">
            <tr style="margin: 0; padding: 0; vertical-align: top;">
                <td style="width: 50%; margin: 0; padding: 0; vertical-align: top;">
                    <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 13px;line-height: 1.2;" *ngIf="hist && clinic" >
                        <div>
                            健保代碼:{{clinic.NHICode}} {{clinic.Name}}藥品明細收據
                        </div>
                        <div style="display:flex" >
                            姓名:{{hist.Patient.CName}}&nbsp;&nbsp;
                            性別:{{hist.Patient.SexName}}&nbsp;&nbsp;
                            身分證:{{hist.Patient.CId| CIdMask:cidMask}}&nbsp;&nbsp;
                            生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                        </div>
                        <div style="display:flex">
                            就診日期:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                            身分別:{{hist.Register.ICodeName}}&nbsp;&nbsp;
                            診别:{{hist.Register.RoomName}}&nbsp;&nbsp;
                            科別:{{getMedDeptName(hist.Register.MedDeptCode)}}&nbsp;&nbsp;
                        </div>
                        <div style="display:flex">
                            病歷號:{{hist.Patient.PatientNo}}&nbsp;&nbsp;
                            就醫序號:{{hist.Register.Icno}}&nbsp;&nbsp;
                            部分負擔代號:{{hist.Hist.ApartCode}}&nbsp;&nbsp;
                            日份:{{hist.Hist.Days}}
                        </div>
                        <div>
                            -----健保申報項目(點數)--------------------------------------
                        </div>
                        <div style="display:flex">
                            <div style="text-align: left;">
                                診察:{{hist.Hist.ADx}}&nbsp;
                                藥費:{{hist.Hist.ARx}}&nbsp;
                                藥事:{{hist.Hist.AMed}}&nbsp;
                                注射費:{{hist.Hist.ATx5}}&nbsp;
                                檢驗費:{{hist.Hist.ATx6}}&nbsp;
                                檢查費:{{hist.Hist.ATx7}}&nbsp;
                            </div>
                        </div>
                        <div style="display:flex">
                            處置手術費:{{hist.Hist.ATx9}}&nbsp;
                            材料費:{{hist.Hist.AMat}}&nbsp;
                            合計:{{hist.Hist.ATot}}&nbsp;
                            健保申報:{{hist.Hist.ADcl}} 點
                        </div>
                        <div>
                            健保申報點數非一點一元給付
                        </div>
                        <div>
                            -----自付費用項目(金額)--------------------------------------
                        </div>
                        <div style="display: flex;">
                            <div style="text-align: left;">
                                掛號費:{{hist.Register.RegisterFee}}&nbsp;
                                藥品:{{receiptOwnFees.OwnRx}}&nbsp;
                                注射:{{receiptOwnFees.OwnTx5}}&nbsp;
                                檢驗檢查:{{receiptOwnFees.OwnTx67}}&nbsp;
                                基本自付:{{hist.Register.OwnFee}}&nbsp;
                            </div>
                        </div>
                        <div style="display: flex;">
                            {{(hist.Hist.APartRx && hist.Hist.APartRx != 0) ? '★藥自付:' + (hist.Hist.APartRx*-1) + '&nbsp;' : ''}}
                            {{(hist.Hist.APartExam && hist.Hist.APartExam != 0) ? '★檢驗自付:' + hist.Hist.APartExam + '&nbsp;' : ''}}
                            {{(clinic.Code != '10272'?'押金:'+hist.Register.LAMT+ '&nbsp;' : '' )}}
                            {{'其他自費:' + receiptOwnFees.OwnOther + '&nbsp;'}}
                        </div>
                        <div style="padding-left: 12px;display: flex;">
                            ※本次收據共計:{{(hist.Register.RegisterFee + hist.Register.OwnFee + hist.Hist.APartExam +
                            (hist.Hist.APartRx*-1) + hist.Hist.AOwn + (clinic.Code != '10272'?hist.Register.LAMT:0 ))}}
                        </div>
                        <div>
                            -------------------------------------------------------------
                        </div>
                        <div  style="display:flex">
                            <div style="display:flex">
                                <div style="width: 12px;">
                                    N
                                </div>
                                <div style="width: 200px;">
                                    藥品名稱
                                </div>
                                <div style="width: 80px;">
                                    服法*天數
                                </div>
                                <div style="width: 35px;">
                                    劑量
                                </div>
                                <div style="width: 35px;">
                                    總數
                                </div>
                                <div style="width: 35px;">
                                    單位
                                </div>
                            </div>
                        </div>
                        <div>
                            =============================================================
                        </div>
                        <div>
                            <ng-container *ngFor="let item of hrxViewModel; let i = index">
                                <div style="display:flex" >
                                    <div style="width: 12px;">
                                        {{item.SPRule}}
                                    </div>
                                    <div style="width: 200px;">
                                        {{item.ProdName}}
                                    </div>
                                    <div style="width: 80px;">
                                        {{item.Freq}}* {{item.Days}}
                                    </div>
                                    <div style="width: 35px; text-align: right;">
                                       {{item.QTYDose}}
                                    </div>
                                    <div style="width: 35px; text-align: right;">
                                        {{item.TotalDose}}
                                    </div>
                                    <div style="width: 35px;">
                                        {{item.Unit}}
                                    </div>
                                </div>
                                <ng-container *ngIf="param2?.ShowWarning && item.Warnings">
                                    △警語:{{item.Warnings}}
                                    <br />
                                </ng-container>
                                <ng-container *ngIf="param2?.ShowIndications && item.Indications">
                                    △適應症:{{item.Indications}}
                                    <br />
                                </ng-container>
                                <ng-container *ngIf="param2?.ShowSideEffects && item.SideEffects">
                                    △副作用:{{item.SideEffects}}
                                    <br />
                                </ng-container>
                            </ng-container>
                            <br />
                        </div>
                        <div style="display: flex; font-family: '{{ fontType }}';font-size: 12px;">
                            <div style="padding-right:0.2rem;">
                                醫師: {{hist.Register.DoctorName}}
                            </div>
                            <div style="padding-right:0.2rem;" *ngIf="hasPharmacist">
                                藥師: {{hist.Hist.PharmacistName}}
                            </div>
                        </div>
                        <div style="display: flex; font-family: '{{ fontType }}';font-size: 12px;">
                            <div style="padding-right:0.2rem;">
                                地址: {{clinicAddress}}
                            </div>
                            <div style="padding-right:0.2rem;">
                                電話: {{clinic.Phone}}
                            </div>
                        </div>
                    </div>
                </td>
                <td style="width: 50%; margin: 0; padding: 0; vertical-align: top;">
                    <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 13px;line-height: 1.2;" *ngIf="hist && clinic && doubleSidedPrintType == 1" >
                        <div style="display:flex">
                            <div style="width: 174px;">
                                <!-- 30 + 10*6 + 12*6 = -->
                                姓名:{{hist.Patient.PatientNo}}&nbsp;{{hist.Patient.CName}}
                            </div>
                            <div style="width: 24px;">
                                {{hist.Patient.SexName}}
                            </div>
                            <div style="width: 123px;">
                              身分證:{{hist.Patient.CId| CIdMask:cidMask}}
                            </div>
                            <div style="width: 60px;">
                                年齡:{{hist.Patient.Birthday | age}}
                            </div>
                        </div>
                        <div style="display:flex">
                            <div style="width: 96px;">
                                就診日:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}
                            </div>
                            <div style="width: 78px;">
                                身分:{{hist.Register.ICodeName}}
                            </div>
                            <div style="width: 84px;">
                                卡序:{{hist.Register.Icno}}
                            </div>
                            <div style="width: 85px;">
                                生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}
                            </div>
                        </div>
                        <div style="width:354px; height: 15px;">

                        </div>
                        <div style="display:flex">
                            <div style="width: 78px;">
                                科別:{{ getMedDeptName(hist.Register.MedDeptCode) }}
                            </div>
                            <div style="width: 60px;">
                                案類:{{hist.Hist.Case}}
                            </div>
                            <div style="width: 60px;">
                                日份:{{hist.Hist.Days}}
                            </div>
                            <div style="width: 60px;">
                                調劑:{{hist.Hist.DispTP}}
                            </div>
                        </div>
                        <div style="width:354px">
                            {{hist.Hist.CC}}
                        </div>
                        <div style="width:354px">
                            {{hist.Hist.PE}}
                        </div>
                        <div style="display: flex;width: 353px; overflow: hidden;">
                            傷病代:{{dxs()}}　病名:{{hist.Hist.Dx1Name}}
                        </div>
                        <table style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 13px;line-height: 1;">
                            <tr>
                                <td style="width: 12px;">N</td>
                                <td style="width: 42px;">藥代</td>
                                <td style="width: 114px;">藥品名稱</td>
                                <td style="width: 36px; text-align: right;">劑量</td>
                                <td style="width: 24px;">UN</td>
                                <td style="width: 45px;">頻率</td>
                                <td style="width: 18px;">日</td>
                                <td style="width: 30px;">總量</td>
                                <td style="width: 60px;">註</td>
                            </tr>
                            <tr>
                                <td colspan="9">
                                    ------------------------------------------------------------
                                </td>
                            </tr>
                            <ng-container *ngFor="let hrx of hist.Hist.HRxs">
                                <tr>
                                    <td>{{hrx.SPRule == 0 ? '' : hrx.SPRule}}</td>
                                    <td>{{hrx.RxCode}}&nbsp;</td>
                                    <td style="max-width: 114px;overflow: hidden;white-space: nowrap;">{{hrx.ProdName}}</td>
                                    <td style="text-align: right;">{{hrx.Dose}}</td>
                                    <td>{{hrx.Unit}}</td>
                                    <td>{{hrx.Freq}}</td>
                                    <td style="text-align: right;">{{hrx.Days}}</td>
                                    <td style="text-align: right;">{{hrx.TotalDose}}</td>
                                    <td>{{hrx.Remark}}</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td colspan="9" style="height: 15px;">

                                </td>
                            </tr>
                        </table>
                        <div>
                            請遵醫囑服用,若未使用完畢,請予丟棄
                        </div>
                        <div style="display: flex;">
                            <div style="width: 160px;">
                                醫師:{{hist.Register.DoctorCId| CIdMask:cidMask}}{{hist.Register.DoctorName}}
                                <!-- 醫師:{{CIdMask(hist.Register.DoctorCId) + hist.Register.DoctorName}} -->
                            </div>
                            <div style="width: 160px;">
                                藥師: {{hist.Hist.PharmacistName}}
                            </div>
                        </div>
                        <div style="display: flex;width: 354px;">
                            <div style="width: 66px;">
                                診察 {{hist.Hist.ADx}}
                            </div>
                            <div style="width: 66px;">
                                藥費 {{hist.Hist.ARx}}
                            </div>
                            <div style="width: 66px;">
                                治療 {{hist.Hist.ATx8}}
                            </div>
                            <div style="width: 66px;">
                                藥事 {{hist.Hist.AMed}}
                            </div>
                            <div style="width: 90px;">
                                申請 {{hist.Hist.ADcl}}
                            </div>

                        </div>
                        <div style="display: flex;width: 354px;">
                            <div style="width: 66px;">
                                掛費 {{hist.Register.RegisterFee}}
                            </div>
                            <div style="width: 66px;">
                                自付 {{hist.Register.OwnFee}}
                            </div>
                        </div>
                        <div style="display: flex;width: 354px;">
                            調劑日:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div style="text-align: left; vertical-align: top; margin: 0; padding: 0;" *ngIf="type == 2 && doubleSidedPrintType == 1">
        <!-- <div style="margin-top: 0.4cm;"></div> -->
        <table style="margin: 0; padding: 0; vertical-align: top;">
            <tr style="margin: 0; padding: 0; vertical-align: top;">
                <td style="width: 50%; margin: 0; padding: 0; vertical-align: top;">
                    <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 12px;line-height: 1.2;" *ngIf="hist && clinic" >
                        <div style="padding-left: 12px;display:flex">
                            健保代碼:{{clinic.NHICode}} {{clinic.Name}}藥品明細收據
                        </div>
                        <div style="padding-left: 12px;display:flex">
                            姓名:{{hist.Patient.CName}}&nbsp;&nbsp;
                            性別:{{hist.Patient.SexName}}&nbsp;&nbsp;
                            身分證:{{hist.Patient.CId| CIdMask:cidMask}}&nbsp;&nbsp;
                            生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                        </div>
                        <div style="padding-left: 12px;display:flex">
                            就診日期:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
                            身分別:{{hist.Register.ICodeName}}&nbsp;&nbsp;
                            診别:{{hist.Register.RoomName}}&nbsp;&nbsp;
                            科別:{{getMedDeptName(hist.Register.MedDeptCode)}}&nbsp;&nbsp;
                        </div>
                        <div style="padding-left: 12px;display:flex">
                            病歷號:{{hist.Patient.PatientNo}}&nbsp;&nbsp;
                            就醫序號:{{hist.Register.Icno}}&nbsp;&nbsp;
                            部分負擔代號:{{hist.Hist.ApartCode}}&nbsp;&nbsp;
                            日份:{{hist.Hist.Days}}
                        </div>
                        <div>
                            -----健保申報項目(點數)----------------------------------------
                        </div>
                        <div style="padding-left: 12px;display:flex">
                            <div style="text-align: left;">
                                診察:{{hist.Hist.ADx}}&nbsp;
                                藥費:{{hist.Hist.ARx}}&nbsp;
                                藥事:{{hist.Hist.AMed}}&nbsp;
                                注射費:{{hist.Hist.ATx5}}&nbsp;
                                檢驗費:{{hist.Hist.ATx6}}&nbsp;
                                檢查費:{{hist.Hist.ATx7}}&nbsp;
                            </div>
                        </div>
                        <div style="padding-left: 12px;display:flex">
                            處置手術費:{{hist.Hist.ATx9}}&nbsp;
                            材料費:{{hist.Hist.AMat}}&nbsp;
                            合計:{{hist.Hist.ATot}}&nbsp;
                            健保申報:{{hist.Hist.ADcl}} 點
                        </div>
                        <div>
                            &nbsp;&nbsp;健保申報點數非一點一元給付
                        </div>
                        <div>
                            -----自付費用項目(金額)----------------------------------------
                        </div>
                        <div style="padding-left: 12px;display: flex;">
                            <div style="text-align: left;">
                                掛號費:{{hist.Register.RegisterFee}}&nbsp;
                                藥品:{{receiptOwnFees.OwnRx}}&nbsp;
                                注射:{{receiptOwnFees.OwnTx5}}&nbsp;
                                檢驗檢查:{{receiptOwnFees.OwnTx67}}&nbsp;
                                基本自付:{{hist.Register.OwnFee}}&nbsp;
                            </div>
                        </div>
                        <div style="padding-left: 12px;display: flex;">
                            {{(hist.Hist.APartRx && hist.Hist.APartRx != 0) ? '★藥自付:' + (hist.Hist.APartRx*-1) + '&nbsp;' : ''}}
                            {{(hist.Hist.APartExam && hist.Hist.APartExam != 0) ? '★檢驗自付:' + hist.Hist.APartExam + '&nbsp;' : ''}}
                            {{(clinic.Code != '10272'?'押金:'+hist.Register.LAMT+ '&nbsp;' : '' )}}
                            {{'其他自費:' + receiptOwnFees.OwnOther + '&nbsp;'}}
                        </div>
                        <div style="padding-left: 12px;display: flex;">
                            &nbsp;&nbsp;※本次收據共計:{{(hist.Register.RegisterFee + hist.Register.OwnFee + hist.Hist.APartExam +
                                (hist.Hist.APartRx*-1) + hist.Hist.AOwn + (clinic.Code != '10272'?hist.Register.LAMT:0 ))}}
                        </div>
                        <div>
                            ---------------------------------------------------------------
                        </div>
                        <div style="padding-left: 12px;display:flex">
                            <div style="display:flex">
                                <div style="width: 20px;">
                                    N
                                </div>
                                <div style="width: 180px;">
                                    藥品名稱
                                </div>
                                <div style="width: 80px;">
                                    服法*天數
                                </div>
                                <div style="width: 35px;">
                                    劑量
                                </div>
                                <div style="width: 35px;">
                                    總數
                                </div>
                                <div style="width: 35px;">
                                    單位
                                </div>
                            </div>
                        </div>
                        <div>
                            ===============================================================
                        </div>
                        <div>
                            <ng-container *ngFor="let item of hrxViewModel; let i = index">
                                <div style="padding-left: 12px;display:flex" >
                                    <div style="width: 20px;">
                                        {{item.SPRule}}
                                    </div>
                                    <div style="width: 180px;">
                                        {{item.ProdName}}
                                    </div>
                                    <div style="width: 80px;">
                                        {{item.Freq}}* {{item.Days}}
                                    </div>
                                    <div style="width: 35px; text-align: center;">
                                       {{item.QTYDose}}
                                    </div>
                                    <div style="width: 35px; text-align: center;">
                                        {{item.TotalDose}}
                                    </div>
                                    <div style="width: 35px;">
                                        {{item.Unit}}
                                    </div>
                                </div>
                                <ng-container *ngIf="param2?.ShowWarning && item.Warnings">
                                    &nbsp;&nbsp;△警語:{{item.Warnings}}
                                    <br />
                                </ng-container>

                                <ng-container *ngIf="param2?.ShowIndications && item.Indications">
                                    &nbsp;&nbsp;△適應症:{{item.Indications}}
                                    <br />
                                </ng-container>

                                <ng-container *ngIf="param2?.ShowSideEffects && item.SideEffects">
                                    &nbsp;&nbsp;△副作用:{{item.SideEffects}}
                                    <br />
                                </ng-container>
                            </ng-container>
                            <br />
                        </div>
                        <div style="padding-left: 12px;display: flex;">
                            <div style="padding-right:1.5rem;">
                                醫師: {{hist.Register.DoctorName}}
                            </div>
                            <div style="padding-right:1.5rem;" *ngIf="hasPharmacist">
                                藥師: {{hist.Hist.PharmacistName}}
                            </div>
                        </div>
                        <div style="padding-left: 12px;display: flex;">
                            <div style="padding-right:1.5rem;">
                                地址: {{clinicAddress}}
                            </div>
                            <div style="padding-right:1.5rem;">
                                電話: {{clinic.Phone}}
                            </div>
                        </div>
                    </div>
                </td>
                <td style="width: 50%; margin: 0; padding: 0; vertical-align: top;">
                    <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 12px;line-height: 1.2;" *ngIf="hist && clinic && doubleSidedPrintType == 1" >
                        <div style="display:flex">
                            <div style="width: 174px;">
                                <!-- 30 + 10*6 + 12*6 = -->
                                姓名:{{hist.Patient.PatientNo}}&nbsp;{{hist.Patient.CName}}
                            </div>
                            <div style="width: 24px;">
                                {{hist.Patient.SexName}}
                            </div>
                            <div style="width: 123px;">
                              身分證:{{hist.Patient.CId| CIdMask:cidMask}}
                            </div>
                            <div style="width: 60px;">
                                年齡:{{hist.Patient.Birthday | age}}
                            </div>
                        </div>
                        <div style="display:flex">
                            <div style="width: 96px;">
                                就診日:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}
                            </div>
                            <div style="width: 78px;">
                                身分:{{hist.Register.ICodeName}}
                            </div>
                            <div style="width: 84px;">
                                卡序:{{hist.Register.Icno}}
                            </div>
                            <div style="width: 85px;">
                                生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}
                            </div>
                        </div>
                        <div style="width:354px; height: 15px;">

                        </div>
                        <div style="display:flex">
                            <div style="width: 78px;">
                                科別:{{ getMedDeptName(hist.Register.MedDeptCode) }}
                            </div>
                            <div style="width: 60px;">
                                案類:{{hist.Hist.Case}}
                            </div>
                            <div style="width: 60px;">
                                日份:{{hist.Hist.Days}}
                            </div>
                            <div style="width: 60px;">
                                調劑:{{hist.Hist.DispTP}}
                            </div>
                        </div>
                        <div style="width:354px">
                            {{hist.Hist.CC}}
                        </div>
                        <div style="width:354px">
                            {{hist.Hist.PE}}
                        </div>
                        <div style="display: flex;width: 353px; overflow: hidden;">
                            傷病代:{{dxs()}}　病名:{{hist.Hist.Dx1Name}}
                        </div>
                        <table style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 12px;line-height: 1;">
                            <tr>
                                <td style="width: 12px;">N</td>
                                <td style="width: 42px;">藥代</td>
                                <td style="width: 114px;">藥品名稱</td>
                                <td style="width: 36px; text-align: right;">劑量</td>
                                <td style="width: 24px;">UN</td>
                                <td style="width: 60px;">頻率</td>
                                <td style="width: 18px;">日</td>
                                <td style="width: 30px;">總量</td>
                                <td style="width: 48px;">註</td>

                            </tr>
                            <tr>
                                <td colspan="9">
                                    ---------------------------------------------------------------
                                </td>
                            </tr>
                            <ng-container *ngFor="let hrx of hist.Hist.HRxs">
                                <tr>
                                    <td>{{hrx.SPRule == 0 ? '' : hrx.SPRule}}</td>
                                    <td>{{hrx.RxCode}}&nbsp;</td>
                                    <td style="max-width: 114px;overflow: hidden;white-space: nowrap;">{{hrx.ProdName}}</td>
                                    <td style="text-align: right;">{{hrx.Dose}}</td>
                                    <td>{{hrx.Unit}}</td>
                                    <td>{{hrx.Freq}}</td>
                                    <td>{{hrx.Days}}</td>
                                    <td>{{hrx.TotalDose}}</td>
                                    <td>{{hrx.Remark}}</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td colspan="9" style="height: 15px;">

                                </td>
                            </tr>
                        </table>
                        <div>
                            請遵醫囑服用,若未使用完畢,請予丟棄
                        </div>
                        <div style="display: flex;">
                            <div style="width: 160px;">
                                醫師:{{hist.Register.DoctorCId| CIdMask:cidMask}}{{hist.Register.DoctorName}}
                                <!-- 醫師:{{CIdMask(hist.Register.DoctorCId) + hist.Register.DoctorName}} -->
                            </div>
                            <div style="width: 160px;">
                                藥師: {{hist.Hist.PharmacistName}}
                            </div>
                        </div>
                        <div style="display: flex;width: 354px;">
                            <div style="width: 66px;">
                                診察 {{hist.Hist.ADx}}
                            </div>
                            <div style="width: 66px;">
                                藥費 {{hist.Hist.ARx}}
                            </div>
                            <div style="width: 66px;">
                                治療 {{hist.Hist.ATx8}}
                            </div>
                            <div style="width: 66px;">
                                藥事 {{hist.Hist.AMed}}
                            </div>
                            <div style="width: 90px;">
                                申請 {{hist.Hist.ADcl}}
                            </div>

                        </div>
                        <div style="display: flex;width: 354px;">
                            <div style="width: 66px;">
                                掛費 {{hist.Register.RegisterFee}}
                            </div>
                            <div style="width: 66px;">
                                自付 {{hist.Register.OwnFee}}
                            </div>
                        </div>
                        <div style="display: flex;width: 354px;">
                            調劑日:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
