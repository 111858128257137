import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { NonPaymentIndicatorDto } from 'src/app/val-addapp/model/indicator-list';

@Component({
  selector: 'app-report-indicator-list',
  templateUrl: './report-indicator-list.component.html',
  styleUrls: ['./report-indicator-list.component.css']
})
export class ReportIndicatorListComponent implements OnInit, AfterViewInit {

  reportName = '健保署各類指標一覽表';
  forExcel = false;
  //當一隻程式有多張報表時，要將命名區別，否則會互相影響。
  containerId = 'container';
  contentId = 'content';

  sizePage = {
    width: 28.7, //cm
    height: 20 //cm
  };
  paddingPage = {
      top: 0, //cm
      right: 0, //cm
      bottom: 0.5, //cm
      left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  upperboundIndex: number = 0;
  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  dataMonth: Date;
  dataScope: string;
  // visitsReport: NonPaymentIndicatorReportDto;
  listItems: NonPaymentIndicatorDto[] = [];
  summaryPatients: number = 0;
  summaryUsages: number = 0;

  api: WebApiService;

  constructor(
    private localPrintService: LocalPrintService,
    private hcrService:HcrService,
    private notification: EasyNotificationService,
  ) {
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    //***因為此報表的文字說明很長，加上以下讓文字固定在框框內。
    //table-layout: fixed;
    //overflow: hidden; word-wrap: break-word;
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table { border:0px solid black; border-collapse: collapse; table-layout: fixed;}\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px; overflow: hidden; word-wrap: break-word;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '.pres-report2 tbody tr.header { display: none !important; }\n' +
      '.pres-report2 tbody:nth-child(1) tr.header { display: contents!important; }\n' +
      '.pres-report2 tbody th.tr-space { height:5px!important; }\n' +
      '.pres-report2 tbody tr.header-line{ border-bottom: 1px solid #777;height:5px!important; }\n' +
      '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  async fetchData(query: any, data: NonPaymentIndicatorDto[], forExcel = false) {
    this.forExcel = forExcel;

    this.dataMonth = query.dataMonth;
    this.dataScope = query.dataScope;
    this.elContainer = document.getElementById(this.containerId);
    // this.visitsReport = new NonPaymentIndicatorReportDto();
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);

    //資料改由外部傳入

    this.listItems = data;
    this.totalRecord = this.listItems.length;
    this.insertListData(forExcel);
  }

  // #region 組報表
  // 組報表的html內容
  insertListData(forExcel = false) {
    this.forExcel = forExcel;
    if (this.listItems.length == 0) {
      return;
    }

    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);         // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();        // List欄位顯示名稱
    var html_finish = this.forExcel ? '' : this.createHTMLFinish();                  // 報表結束 -- 以下空白
    // var html_total = this.forExcel ? '' : this.createHTMLTotal(this.visitsReport.Items.filter(x => x.IsSummary == true)[0]);
    var html_total = this.forExcel ? '' : this.createHTMLTotal();
    var html_blank = this.forExcel ? '' : this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {
      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listItems[i]);
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (!this.forExcel && elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上合計 和 總計
      if (!this.forExcel && i == this.listItems.length-1) {
        // 總計
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if (!this.forExcel && (this.listItems[i].IsSummary) && !(i == this.listItems.length-1)) {
      if (!this.forExcel && (false) && !(i == this.listItems.length-1)) {
        afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    var page = '';
    //強制儲存格轉文字mso-number-format
    if (this.forExcel) {
      page += `
        <style>
          .excel-format {
            font-size: 12pt;
          }
          .excel-format-text {
            font-size: 12pt;
            mso-number-format: "@";
          }
        </style>`;
    }
    page += `
      <div class="page" id="page-${iPage}"
          style="
          height: ${this.sizePage.height}cm;
          width: ${this.sizePage.width}cm;
          padding-top: ${this.paddingPage.top}cm;
          padding-right: ${this.paddingPage.right}cm;
          padding-bottom: ${this.paddingPage.bottom}cm;
          padding-left: ${this.paddingPage.left}cm;
          display: block;
          margin: 0px auto;
          box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
          box-sizing: border-box;
          ">
          <div class="content" id="page-${iPage}-${this.contentId}">
          </div>
      </div>`;
      return page;
    }

  createHTMLBlankLine() {
    return `<tr class="header-line d-contents">
              <th colspan="6" class="d-block tr-space" style="border-right: none;  border-left: none; border-bottom: none; border-top: none;"></th>
            </tr>`;
  }

  createHTMLListHeader() {
    let commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;';
    let commonClass = this.forExcel ? 'class="excel-format"' : '';
    return `<tr>
              <th style="text-align: center; width: 19%;  ${commonStyle}" ${commonClass}>名稱</th>
              <th style="text-align: center; width: 13%;  ${commonStyle}" ${commonClass}>目前值</th>
              <th style="text-align: center; width: 15%;  ${commonStyle}" ${commonClass}>指標值</th>
              <th style="text-align: center; width: 53%;  ${commonStyle}" ${commonClass}>說明</th>
            </tr>`;
  }

  createHTMLBlock(data: NonPaymentIndicatorDto) {
    // let summaryStyle = data.IsSummary? "border-top: 1px dotted #777;" : "border-top: none;";
    let summaryStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;';
    let commonClass = this.forExcel ? 'class="excel-format"' : '';
    let commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    return `
      <tr>
        <td style="text-align: left;   ${summaryStyle}" ${commonClass}    >${data.IndicatorName ?? ''}</td>
        <td style="text-align: left;   ${summaryStyle}" ${commonClass}    >${data.CurrentValue ?? ''}</td>
        <td style="text-align: left;   ${summaryStyle}" ${commonClass}    >${data.IndicatorValue ?? ''}</td>
        <td style="text-align: left;   ${summaryStyle}" ${commonClass}    >${data.CombineDescription ?? ''}</td>
      </tr>`;
  }

  createHTMLTotal() {
    // return `<tr border="0">
    //             <td style="text-align: left; font-weight: bold; border-top: 1px dotted #777; padding-top:0.6rem">
    //             總計
    //             </td>
    //             <td style="text-align: left; font-weight: bold; border-top: 1px dotted #777; padding-top:0.6rem">
    //
    //             </td>
    //             <td style="text-align: left; font-weight: bold; border-top: 1px dotted #777; padding-top:0.6rem">
    //
    //             </td>
    //             <td style="text-align: left; font-weight: bold; border-top: 1px dotted #777; padding-top:0.6rem">
    //
    //             </td>
    //             <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777; padding-top:0.6rem">
    //             ` + data.TotalPatientAmount + `
    //             </td>
    //             <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777; padding-top:0.6rem">
    //             ` + data.TotalUsageAmount + `
    //             </td>
    //         </tr>`;
    return '';
  }

  createHTMLPageHeader(iPage) {
    var pageHeader = '';
    if (!this.forExcel) {
      pageHeader += `
        <div id="header" style="margin: 0px 10px;">
          <div style="display: flex;column-gap: 5px;">
            <table style="width: 100%; text-align: center; border: none;">
              <tr>
              <td colspan="12" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              ${this.reportName}
              </td>
              </tr>
              <tr>
                  <td colspan="3" style="width: 30%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  印表日期：${this.nowStr}
                  </td>
                  <td colspan="6" style="width: 40%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  指標範圍：${this.dataScope}
                  </td>
                  <td colspan="3" style="width: 30%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  資料年月：${DateHelper.getROCDateString(new Date(this.dataMonth)).slice(0, 5)}
                  </td>
              </tr>
              <tr>
                  <td colspan="3" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  印表時間：${this.timeStr}
                  </td>
                  <td colspan="6" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                  </td>
                  <td colspan="3" style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  頁次：${(iPage + 1)}
                  </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
          <label style="font-weight: bold; height: 23px;">　</label>
        </div>`;
    }
    pageHeader += `
      <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
        <div style="display: flex;column-gap: 5px;">
          <table id="table-${iPage}-${this.contentId}" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
          </table>
        </div>
      </div>`;
    return pageHeader;
  }

  createHTMLFinish() {
    // return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
    //             <label style="font-weight: bold;">以下空白</label>
    //         </div>`;
    return '';
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      //去除邊框, 實際印出到pdf
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.15cm;','');
      html = html.replace('box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0.5cm;','');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, this.reportName, printContents);
      if (ret.Successed) {
        this.notification.showSuccess(this.reportName + '已送出列印!', true);
      } else {
        this.notification.showError(this.reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {
      const table = this.reportContainer.nativeElement;

      //html已有<table>經由ExportTableToExcel()後最外層會加上<table>，導致Excel第一行會空白，所以轉出時把table轉div。
      const html = this.forExcel ? table.innerHTML.replace('table', 'div') : table.innerHTML;

      await this.hcrService.ExportTableToExcel(this.reportName, html);
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }

    this.forExcel = false;
  }

}
