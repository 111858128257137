import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { FormGroup } from '@angular/forms';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HcrService } from 'src/app/services/hcr-service.service';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { ActivatedRoute } from '@angular/router';
import { Discount } from 'src/app/payments/discount/models/discount';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { WebApiService } from 'src/app/services/web-api.service';

@Component({
  selector: 'app-reg-feepay',
  templateUrl: './reg-feepay.component.html',
  styleUrls: ['./reg-feepay.component.css']
})

export class RegFeepayComponent implements OnInit , AfterViewInit {

  reportName = '';
  forExcel = true;

  sizePage = {
    width: 28.7, //cm
    height: 20 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;
  upperboundIndex: number = 0;
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  pageNo: number = 1;
  _sDate: Date;
  _eDate: Date;
  startDate: String;
  endDate: String;
  discountSummary: Discount[] = [];
  // 清單
  queryFormGroup: FormGroup;
  totalRecord: number;  
  filterDrugItems: ValueTextPair[] = [];
  data: Discount[];
  api: WebApiService;

  RegFeebyPaymentListItemsOne: Discount[] = []; //免掛號費及自付名冊報表之掛號檔之明細
  RegFeebyPaymentListItemsTwo: Discount[] = []; //免掛號費及自付名冊報表之掛號檔之總表
  RegFeebyPaymentListItemsThree: Discount[] = []; //免掛號費及自付名冊報表之掛號檔之身分別人數統計

  RegFeebyNHIDeclareListItemsOne: Discount[] = []; //免掛號費及自付名冊報表之批價檔之明細
  RegFeebyNHIDeclareListItemsTwo: Discount[] = []; //免掛號費及自付名冊報表之批價檔之總表
  RegFeebyNHIDeclareListItemsThree: Discount[] = []; //免掛號費及自付名冊報表之批價檔之身分別人數統計

  //檔案選擇
  fileMethod: ValueTextPair[] = [
    {value: '1', text:'掛號檔(實際收費)'},   
    {value: '2', text:'批價檔(健保申請)'}];
  
  //名冊排序
  chooseList: ValueTextPair[] = [
    {value: '1', text:'免掛號費'},
    {value: '2', text:'免自付額'},
    {value: '3', text:'自費身份'},
    {value: '4', text:'免掛號費、免自付額'}];

  //報表內容
  chooseReportMethod: ValueTextPair[] = [
    {value: '1', text:'明細'},
    {value: '2', text:'總表'},
    {value: '3', text:'身份別人數統計'}];

  //其他條件
  otherselect: ValueTextPair[] = [
  {value: '1', text:'65歲以上'},
  {value: '2', text:'自費金額大於0'},
  {value: '3', text:'掛費小於100'},
  {value: '4', text:'掛費小於100不含自費'},
  {value: '5', text:'掛費小於100不含自費欠卡'}];

  doctorType: boolean = false; //是否勾選醫生別，進行排序

  //保別條件
  icodeselect: ValueTextPair[] = [];
  gridView: any;  

  constructor(
    private route: ActivatedRoute,
    private webApiClient: WebApiClient,
    private histApi: HistApi,
    private localPrintService: LocalPrintService,
    private hcrService: HcrService,
    private notification: EasyNotificationService) {
      // this.api = this.webApiClient.createHisService('payments/discount');
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {
  }
 
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // 報表
  async fetchData(data: Discount[], startDate: string, endDate: string, fileMethod: string, chooseReport: string, forExcel = true) {   
    
    this.forExcel = forExcel;
    this.elContainer = document.getElementById("container");

    //顯示印表期間(已將格式改成y/m/d)
    this.startDate = startDate;
    this.endDate = endDate;
    this.startDate = startDate ? DateHelper.getROCDateStringWithSepetator(new Date(startDate)) : "";
    this.endDate = endDate ? DateHelper.getROCDateStringWithSepetator(new Date(endDate)) : "";
    
    //以下是報表表頭欄位, 目前沒用到, 先給預設值。
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    this._sDate = DateHelper.today;
    this._eDate = DateHelper.today;   

    if (fileMethod =="1" && chooseReport =="1") //掛號檔之明細
      {
        this.reportName = "免掛號費及自付名冊(非報稅用)"; 
        this.RegFeebyPaymentListItemsOne =data;
        this.totalRecord = this.RegFeebyPaymentListItemsOne.length; //1
        this.insertListData();
      } 
    else if(fileMethod =="1" && chooseReport =="2") //掛號檔之總表
      {
        this.reportName = "免掛號費及自付名冊(總表)(非報稅用)";
        this.RegFeebyPaymentListItemsTwo =data;
        this.totalRecord = this.RegFeebyPaymentListItemsTwo.length; //2
        this.insertListData2();
      }
    else if(fileMethod =="1" && chooseReport =="3") //掛號檔之身分別人數統計
      {
        this.reportName = "免掛號費人數統計(非報稅用)";
        this.RegFeebyPaymentListItemsThree =data;
        this.totalRecord = this.RegFeebyPaymentListItemsThree.length; //3
        this.insertListData3();
      }
    else if(fileMethod =="2" && chooseReport =="1") //批價檔之明細
      {
        this.reportName = "免掛號費及自付名冊(非報稅用)";
        this.RegFeebyNHIDeclareListItemsOne =data;
        this.totalRecord = this.RegFeebyNHIDeclareListItemsOne.length; //4
        this.insertListData4();
      }
    else if(fileMethod =="2" && chooseReport =="2") //批價檔之總表
      {
        this.reportName = "免掛號費及自付名冊(總表)(非報稅用)";
        this.RegFeebyNHIDeclareListItemsTwo =data;
        this.totalRecord = this.RegFeebyNHIDeclareListItemsTwo.length; //5
        this.insertListData5();
      }
    else if(fileMethod =="2" && chooseReport =="3") //批價檔之身分別人數統計
      {
        this.reportName = "免掛號費人數統計(非報稅用)";
        this.RegFeebyNHIDeclareListItemsThree =data;
        this.totalRecord = this.RegFeebyNHIDeclareListItemsThree.length; //6  
        this.insertListData6();
      }
  }
 
  // #region 組報表
  // 組報表的html內容
  insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;    
    this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
    var html_ListerFinal = this.createHTMLListFinal();      // List欄位顯示名稱-統計   

    // var html_blank = this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.RegFeebyPaymentListItemsOne.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.RegFeebyPaymentListItemsOne[i]);
      elTableContent.innerHTML += html_Block;     

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      
      // 最後一筆要加上總計
      if (i == this.RegFeebyPaymentListItemsOne.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_ListerFinal;
        
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_ListerFinal;          

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

  createHTMLListHeader() {
    return `<tr>   
              <th
                style="text-align: center; width:5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                就診日
              </th>   
              <th
                style="text-align: center; width:5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                卡序
              </th>
              <th
                style="text-align: center; width:5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                姓名
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                生日
              </th>
              <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                身分證
              </th>
              <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                電話
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                身分
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                掛號費
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                應收部分負擔
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                應收藥品自付
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                應收檢驗自付
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                實收部分負擔
              </th>
              <th
                style="text-align: center; width: 18%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                優惠原因
              </th>
              <th
                style="text-align: center; width: 20%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                地址
              </th>
            </tr>`;
  }

  createHTMLBlock(data: Discount) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
    var regDate = data.RegDate ? this.onGetDateString(data.RegDate, true, '', false) : "";

    return `
      <tr>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${regDate == undefined ? '': regDate}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Icno  == undefined ? '': data.Icno}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientNo  == undefined ? '': data.PatientNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.FirstName  == undefined ? '': data.FirstName}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${birthday  == undefined ? '': birthday}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CId  == undefined ? '': data.CId}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Mobile  == undefined ? '': data.Mobile}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.ICode  == undefined ? '': data.ICode}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.OwnPayA0  == undefined ? '': data.OwnPayA0}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.OwnPayA98  == undefined ? '': data.OwnPayA98}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.OwnPayA97  == undefined ? '': data.OwnPayA97}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.OwnPayA67  == undefined ? '': data.OwnPayA67}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.SubtotalA98  == undefined ? '': data.SubtotalA98}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.DiscountName  == undefined ? '': data.DiscountName}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Address  == undefined ? '': data.Address}</td>
      </tr>`;
  }

  createHTMLListFinal() {
    return `
            <tr><td colspan="15" style="width: 100%; height: 5px; border-top: 2px solid black; border-bottom: none;">
            </td></tr>
            <tr><td colspan="15" style="width: 100%; padding-bottom: 5px; border: none;">
            <table width='100%'>
              <tr>  
                <th style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                </th>   
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收掛號費
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                實收掛號費
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收自付
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                藥品自付
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                檢驗自付
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                實收自付
                </th>
                <th style="text-align: center; width: 20%; border-right: 0px solid black;  border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
                </th>  
              </tr>    
               ` + this.createHTMLTotal() +  `         
            </table></td></tr>`;
  }

  createHTMLTotal() {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var TotalPatientCount=0;
    var AllOwnPayA0=0;
    var AllOwnPayA98=0;
    var AllOwnPayA97=0;
    var AllOwnPayA67=0;
    var AllSubtotalA0=0;
    var AllSubtotalA98=0;

    for (let i = 0; i < this.RegFeebyPaymentListItemsOne.length; i++) {
      TotalPatientCount += this.RegFeebyPaymentListItemsOne[i].TotalPatientCount; 
      AllOwnPayA0 += this.RegFeebyPaymentListItemsOne[i].OwnPayA0; // 應收掛號費(合計)
      AllSubtotalA0 += this.RegFeebyPaymentListItemsOne[i].SubtotalA0; // 實收掛號費(合計)
      AllOwnPayA98 += this.RegFeebyPaymentListItemsOne[i].OwnPayA98; // 應收自付(部分負擔)(合計)
      AllOwnPayA97 += this.RegFeebyPaymentListItemsOne[i].OwnPayA97; // 藥品自付(合計)
      AllOwnPayA67 += this.RegFeebyPaymentListItemsOne[i].OwnPayA67; // 檢驗自付(合計)
      AllSubtotalA98 += this.RegFeebyPaymentListItemsOne[i].SubtotalA98;// 實收自付(部分負擔)(合計)
    }

    return `
          <tr>
            <td style="text-align: center;nborder-right: 0px solid black; border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
            </td>
            <td style="text-align: right; border-right: 1px solid black; border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
            <b>總計：</b></td>
            <td style="text-align: right; ${commonStyle}" ${commonClassText}> ${AllOwnPayA0}</td>
            <td style="text-align: right; ${commonStyle}" ${commonClassText}> ${AllSubtotalA0}</td>
            <td style="text-align: right; ${commonStyle}" ${commonClassText}> ${AllOwnPayA98}</td>
            <td style="text-align: right; ${commonStyle}" ${commonClassText}> ${AllOwnPayA97}</td>
            <td style="text-align: right; ${commonStyle}" ${commonClassText}> ${AllOwnPayA67}</td>
            <td style="text-align: right; ${commonStyle}" ${commonClassText}> ${AllSubtotalA98}</td>
            <td style="text-align: center; border-right: 0px solid black;  border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
            </td>
          </tr>
          `;    
  }

  createHTMLPageHeader(iPage) {
    var reportName = this.reportName;
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>                      
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 3px solid black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint() {
    if (this.RegFeebyPaymentListItemsOne.length > 0) {
      const reportName = this.reportName;
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.RegFeebyPaymentListItemsOne.length > 0) {
      const reportName = this.reportName; //'免掛號費及自付名冊報表之掛號檔之明細';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  // #region 組報表
  // 組報表的html內容
  insertListData2() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    var index = 0;  // 新增的序號索引
    this.elContainer.innerHTML = this.createHTMLPage2(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader2(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader2();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish2();                // 報表結束 -- 以下空白
    
    // var html_blank = this.createHTMLBlankLine2();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.RegFeebyPaymentListItemsTwo.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock2(this.RegFeebyPaymentListItemsTwo[i], index);    

      elTableContent.innerHTML += html_Block;
      index++;  // 更新序號索引

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage2(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader2(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.RegFeebyPaymentListItemsTwo.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        // elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage2(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader2(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;          

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm2(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx2(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage2(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine2() {
    return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

  createHTMLListHeader2() {
    return `<tr>   
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                序號
              </th>      
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                姓名
              </th>
               <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                生日
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                身分證
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                電話
              </th>
               <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                身份
              </th>
              <th
                style="text-align: center; width: 25%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                地址
              </th>
              <th
                style="text-align: center; width: 20%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                日期
              </th>              
            </tr>`;
  }

  createHTMLBlock2(data: Discount, index:number) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
    var RegDates = "";

    if (data.RegDates) {
      var formattedDates = data.RegDates.map(date => DateHelper.formatROCDate(date));
      // 使用 join 方法將格式化後的日期用逗號分開
      RegDates = formattedDates.join(', ');
    }
    
    return `
      <tr>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${index+1}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientNo== undefined ? '': data.PatientNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.FirstName== undefined ? '': data.FirstName}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${birthday== undefined ? '': birthday}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CId== undefined ? '': data.CId}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Mobile== undefined ? '': data.Mobile}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.ICode== undefined ? '': data.ICode}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Address== undefined ? '': data.Address}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${RegDates== undefined ? '': RegDates}</td>
      </tr>`;
  }

  createHTMLPageHeader2(iPage) {
    var reportName = this.reportName;
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>                     
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      </td>
                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                      
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish2() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 3px solid black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml2() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint2() {
    if (this.RegFeebyPaymentListItemsTwo.length > 0) {
      const reportName = this.reportName;
      const printContents = this.getHtml2();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel2() {
    if (this.RegFeebyPaymentListItemsTwo.length > 0) {
      const reportName = this.reportName;
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  // #region 組報表
  // 組報表的html內容
  insertListData3() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage3(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader3(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader3();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish3();                // 報表結束 -- 以下空白
    var html_total = this.createHTMLTotal3();                  //組統計行的 HTML 內容
  
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.RegFeebyPaymentListItemsThree.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock3(this.RegFeebyPaymentListItemsThree[i]);      
      elTableContent.innerHTML += html_Block;      

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage3(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader3(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;
        elTableContent.innerHTML += html_total;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }  
      // 最後一筆要加上總計
      if (i == this.RegFeebyPaymentListItemsThree.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage3(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader3(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm3(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx3(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage3(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.width + `cm;
                width: ` + this.sizePage.height + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLListHeader3() {
    return `<tr>   
              <th
                style="text-align: center; width:15%; border-right: 1px solid black; border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                年月
              </th>   
              <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                身分
              </th>
              <th
                style="text-align: center; width:10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                人數
              </th>
                <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收部分負擔
              </th>
               <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收藥品自付
              </th>
              <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收檢驗自付
              </th>
               <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                掛號費
              </th>              
            </tr>`;
  }

  createHTMLBlock3(data: Discount) {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';    
    var month = data.Month ? this.onGetDateString(data.Month, true, '', true) : "";
    
    return `
      <tr>
        <td style="text-align: left ;  ${commonStyle}" ${commonClassText}>${month}</td>
        <td style="text-align: left ;  ${commonStyle}" ${commonClassText}>${data.IcodeType== undefined ? '': data.IcodeType}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.TotalPatientCount}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.AllOwnPayA98}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.AllOwnPayA97}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.AllOwnPayA67}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.AllOwnPayA0}</td>
      </tr>`;
  }

  createHTMLTotal3() {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var TotalPatientCount=0;
    var AllOwnPayA98=0;
    var AllOwnPayA97=0;
    var AllOwnPayA67=0;
    var AllOwnPayA0=0;    

    for (let i = 0; i < this.RegFeebyPaymentListItemsThree.length; i++) {
      TotalPatientCount += this.RegFeebyPaymentListItemsThree[i].TotalPatientCount; // 各月份總掛號人次(合計)
      AllOwnPayA98 += this.RegFeebyPaymentListItemsThree[i].AllOwnPayA98; // 應收部分負擔(合計)
      AllOwnPayA97 += this.RegFeebyPaymentListItemsThree[i].AllOwnPayA97; // 應收藥品自付(合計)
      AllOwnPayA67 += this.RegFeebyPaymentListItemsThree[i].AllOwnPayA67; // 應收檢驗自付次(合計)
      AllOwnPayA0 += this.RegFeebyPaymentListItemsThree[i].AllOwnPayA0; // 掛號費(合計)     
    }

    return `
       <tr>
            <td style="text-align: right; border-right: 0px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;"></td>
            <td style="text-align: right; border-right: 1px solid black;  border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
              <b>合計</b></td>             
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${TotalPatientCount}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllOwnPayA98}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllOwnPayA97}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllOwnPayA67}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllOwnPayA0}</td>
        </tr> 
    `;
  }

  createHTMLPageHeader3(iPage) {
    var reportName = this.reportName;
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                      
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish3() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 3px solid black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml3() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint3() {
    if (this.RegFeebyPaymentListItemsThree.length > 0) {
      const reportName = this.reportName;
      const printContents = this.getHtml3();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel3() {
    if (this.RegFeebyPaymentListItemsThree.length > 0) {
      const reportName = this.reportName;
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  // #region 組報表
  // 組報表的html內容
  insertListData4() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage4(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader4(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader4();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish4();                // 報表結束 -- 以下空白
    var html_ListerFinal = this.createHTMLListFinal4();      // List欄位顯示名稱-統計    

    // var html_blank = this.createHTMLBlankLine4();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.RegFeebyNHIDeclareListItemsOne.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock4(this.RegFeebyNHIDeclareListItemsOne[i]);
      elTableContent.innerHTML += html_Block;      

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage4(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader4(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.RegFeebyNHIDeclareListItemsOne.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_ListerFinal;
        //elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage4(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader4(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_ListerFinal;          

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm4(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx4(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage4(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine4() {
    return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

  createHTMLListHeader4() {
    return `<tr>   
              <th
                style="text-align: center; width:5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                就診日
              </th>   
              <th
                style="text-align: center; width:5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                卡序
              </th>
              <th
                style="text-align: center; width:5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                病歷號
              </th>
                <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                姓名
              </th>
               <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                生日
              </th>
              <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                身分證
              </th>
               <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                電話
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                身分
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                掛號費
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                應收部分負擔
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                應收藥品自付
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                應收檢驗自付
              </th>
              <th
                style="text-align: center; width: 3%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                實收部分負擔
              </th>
              <th
                style="text-align: center; width: 18%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                優惠原因
              </th>
              <th
                style="text-align: center; width: 20%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                地址
              </th>
            </tr>`;
  }

  createHTMLBlock4(data: Discount) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
    var regDate = data.RegDate ? this.onGetDateString(data.RegDate, true, '', false) : "";

    return `
      <tr>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${regDate == undefined ? '': regDate}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Icno  == undefined ? '': data.Icno}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientNo  == undefined ? '': data.PatientNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.FirstName  == undefined ? '': data.FirstName}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${birthday  == undefined ? '': birthday}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CId  == undefined ? '': data.CId}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Mobile  == undefined ? '': data.Mobile}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.ICode  == undefined ? '': data.ICode}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.OwnPayA0  == undefined ? '': data.OwnPayA0}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.AOwn  == undefined ? '': data.AOwn}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.APartRxA97== undefined ? '': data.APartRxA97}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.APartExamA67== undefined ? '': data.APartExamA67}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.AOwn  == undefined ? '': data.AOwn}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.DiscountName  == undefined ? '': data.DiscountName}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Address  == undefined ? '': data.Address}</td>
      </tr>`;
  }

  createHTMLListFinal4() {
    return `
            <tr><td colspan="15" style="width: 100%; height: 5px; border-top: 2px solid black; border-bottom: none;">
            </td></tr>
            <tr><td colspan="15" style="width: 100%; padding-bottom: 5px; border: none;">
            <table width='100%'>
              <tr>  
                <th style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                </th>   
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收掛號費
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                實收掛號費
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收自付
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                藥品自付
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                檢驗自付
                </th>
                <th style="text-align: center; width: 10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                實收自付
                </th>
                <th style="text-align: center; width: 20%; border-right: 0px solid black;  border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
                </th>  
              </tr>    
               ` + this.createHTMLTotal() +  `         
            </table></td></tr>`;
  }

  createHTMLTotal4() {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var TotalPatientCount=0;
    var AllOwnPayA0=0;
    var AllAOwn=0;
    var AllAPartRxA97=0;
    var AllAPartExamA67=0;    
    var AllSubtotalA0=0;

    for (let i = 0; i < this.RegFeebyNHIDeclareListItemsOne.length; i++) {
      TotalPatientCount += this.RegFeebyNHIDeclareListItemsOne[i].TotalPatientCount; 
      AllOwnPayA0 += this.RegFeebyNHIDeclareListItemsOne[i].OwnPayA0; // 應收掛號費(合計)
      AllSubtotalA0 += this.RegFeebyNHIDeclareListItemsOne[i].SubtotalA0; // 實收掛號費(合計)
      AllAOwn += this.RegFeebyNHIDeclareListItemsOne[i].AOwn; // 應收自付(部分負擔)(合計)
      AllAPartRxA97 += this.RegFeebyNHIDeclareListItemsOne[i].APartRxA97; // 藥品自付(合計)
      AllAPartExamA67 += this.RegFeebyNHIDeclareListItemsOne[i].APartExamA67; // 檢驗自付(合計)
      AllAOwn += this.RegFeebyNHIDeclareListItemsOne[i].AOwn;// 實收自付(部分負擔)(合計)
    }

    return `
          <tr>
            <td style="text-align: center;nborder-right: 0px solid black; border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
            </td>
            <td style="text-align: right; border-right: 1px solid black; border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
            <b>總計：</b></td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllOwnPayA0}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllSubtotalA0}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllAOwn}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllAPartRxA97}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllAPartExamA67}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllAOwn}</td>
            <td style="text-align: center; border-right: 0px solid black;  border-left: 0px solid black; border-top: 0px solid black; border-bottom: 0px solid black;">
            </td>
          </tr>
          `;    
  }

  createHTMLPageHeader4(iPage) {
    var reportName = this.reportName;
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                      
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish4() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 3px solid black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml4() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint4() {
    if (this.RegFeebyNHIDeclareListItemsOne.length > 0) {
      const reportName = this.reportName;
      const printContents = this.getHtml4();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel4() {
    if (this.RegFeebyNHIDeclareListItemsOne.length > 0) {
      const reportName = this.reportName;
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  // #region 組報表
  // 組報表的html內容
  insertListData5() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    var index = 0;  // 新增的序號索引
    this.elContainer.innerHTML = this.createHTMLPage5(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader5(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader5();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish5();                // 報表結束 -- 以下空白

    // var html_blank = this.createHTMLBlankLine5();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.RegFeebyNHIDeclareListItemsTwo.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock5(this.RegFeebyNHIDeclareListItemsTwo[i], index);  
      elTableContent.innerHTML += html_Block;
      index++;  // 更新序號索引

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage5(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader5(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.RegFeebyNHIDeclareListItemsTwo.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage5(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader5(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;          

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm5(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx5(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage5(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine5() {
    return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

  createHTMLListHeader5() {
    return `<tr>   
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                序號
              </th>      
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                姓名
              </th>
               <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                生日
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                身分證
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                電話
              </th>
               <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                身份
              </th>
              <th
                style="text-align: center; width: 25%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                地址
              </th>
              <th
                style="text-align: center; width: 20%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                日期
              </th>              
            </tr>`;
  }

  createHTMLBlock5(data: Discount, index: number) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
    var RegDates = "";

    if (data.RegDates) {
      var formattedDates = data.RegDates.map(date => DateHelper.formatROCDate(date));
      // 使用 join 方法將格式化後的日期用逗號分開
      RegDates = formattedDates.join(', ');
    }

    return `
      <tr>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${index+1}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientNo== undefined ? '': data.PatientNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.FirstName== undefined ? '': data.FirstName}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${birthday== undefined ? '': birthday}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CId== undefined ? '': data.CId}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Mobile== undefined ? '': data.Mobile}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.ICode== undefined ? '': data.ICode}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Address== undefined ? '': data.Address}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${RegDates== undefined ? '': RegDates}</td>
      </tr>`;
 }

  createHTMLPageHeader5(iPage) {
    var reportName = this.reportName;
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                      
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish5() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 3px solid black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml5() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint5() {
    if (this.RegFeebyNHIDeclareListItemsTwo.length > 0) {
      const reportName = this.reportName;
      const printContents = this.getHtml5();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel5() {
    if (this.RegFeebyNHIDeclareListItemsTwo.length > 0) {
      const reportName = this.reportName;
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  // #region 組報表
  // 組報表的html內容
  insertListData6() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage6(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader6(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader6();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish6();                // 報表結束 -- 以下空白
    var html_total = this.createHTMLTotal6();                  //組統計行的 HTML 內容
  
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.RegFeebyNHIDeclareListItemsThree.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock6(this.RegFeebyNHIDeclareListItemsThree[i]);      
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage6(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader6(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;
        elTableContent.innerHTML += html_total;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.RegFeebyNHIDeclareListItemsThree.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage6(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader6(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm6(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx6(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage6(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.width + `cm;
                width: ` + this.sizePage.height + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

 createHTMLListHeader6() {
    return `<tr>  
              <th
                style="text-align: center; width:15%; border-right: 1px solid black; border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                年月
              </th>   
              <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                身分
              </th>
              <th
                style="text-align: center; width:10%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                人數
              </th>
                <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收部分負擔
              </th>
               <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收藥品自付
              </th>
              <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                應收檢驗自付
              </th>
               <th
                style="text-align: center; width:15%; border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                掛號費
              </th>              
            </tr>`;
  }

  createHTMLBlock6(data: Discount) {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';    
    var month = data.Month ? this.onGetDateString(data.Month, true, '', true) : "";
   
    return `
      <tr>
        <td style="text-align: left ;  ${commonStyle}" ${commonClassText}>${month}</td>
        <td style="text-align: left ;  ${commonStyle}" ${commonClassText}>${data.IcodeType== undefined ? '': data.IcodeType}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.TotalPatientCount}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.AllAOwn}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.AllAPartRxA97}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.AllAPartExamA67}</td>
        <td style="text-align: right ;  ${commonStyle}" ${commonClassText}>${data.AllOwnPayA0}</td>
      </tr>`;
  }

  createHTMLTotal6() {
    var commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var TotalPatientCount=0;
    var AllAOwn=0;
    var AllAPartRxA97=0;
    var AllAPartExamA67=0;
    var AllOwnPayA0=0;    

    for (let i = 0; i < this.RegFeebyNHIDeclareListItemsThree.length; i++) {
      TotalPatientCount += this.RegFeebyNHIDeclareListItemsThree[i].TotalPatientCount; // 各月份總掛號人次(合計)
      AllAOwn += this.RegFeebyNHIDeclareListItemsThree[i].AllAOwn; // 應收部分負擔(合計)
      AllAPartRxA97 += this.RegFeebyNHIDeclareListItemsThree[i].AllAPartRxA97; // 應收藥品自付(合計)
      AllAPartExamA67 += this.RegFeebyNHIDeclareListItemsThree[i].AllAPartExamA67; // 應收檢驗自付次(合計)
      AllOwnPayA0 += this.RegFeebyNHIDeclareListItemsThree[i].AllOwnPayA0; // 掛號費(合計)     
    }

    return `
       <tr>
            <td style="text-align: right; border-right: 0px solid black;  border-left: 1px solid black; border-top: 1px solid black; border-bottom: 1px solid black;"></td>
            <td style="text-align: right; border-right: 1px solid black;  border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
              <b>合計</b></td> 
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${TotalPatientCount}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllAOwn}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllAPartRxA97}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllAPartExamA67}</td>
            <td style="text-align: right;  ${commonStyle}" ${commonClassText}> ${AllOwnPayA0}</td>
        </tr> 
    `;
  }

  createHTMLPageHeader6(iPage) {
    var reportName = this.reportName;
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                      
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish6() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 3px solid black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml6() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint6() {
    if (this.RegFeebyNHIDeclareListItemsThree.length > 0) {
      const reportName = this.reportName;
      const printContents = this.getHtml6();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel6() {
    if (this.RegFeebyNHIDeclareListItemsThree.length > 0) {
      const reportName = this.reportName;
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

 //就診日切割
 formatRegDates(RegDate: string): string {
  if (RegDate.length === 7) {
      // 切割年份、月份與日期
      const year = RegDate.substring(0, 3);
      const month = RegDate.substring(3, 5);
      const day = RegDate.substring(5, 7);

      // 返回格式化後的字串
      return `${year}/${month}/${day}`;
  } else {
      // 如果 birthday 不是7碼長，返回原始字串或空字串
      return RegDate;
  }
}

 //生日切割
 formatBirthday(birthday: string): string {
  if (birthday.length === 7) {
      // 切割年份、月份與日期
      const year = birthday.substring(0, 3);
      const month = birthday.substring(3, 5);
      const day = birthday.substring(5, 7);

      // 返回格式化後的字串
      return `${year}/${month}/${day}`;
  } else {
      // 如果 birthday 不是7碼長，返回原始字串或空字串
      return birthday;
  }
}

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();
      var years = year.toString();
      if (years.length < 2)
        years = '00' + year; //民國6年7月5日出生 (0060705)
      if (years.length < 3)
        years = '0' + year;
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [years, month].join(symbol);
      } else {
        return [years, month, day].join(symbol);
      }
    }
  }

  /** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }

  onGetDate(ev) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [month, day].join('');
    }
  }
}


