import { Injectable } from "@angular/core";
import { EasyFormService } from "src/app/services/easy-form-service";
import { EasyNotificationService } from "src/app/services/easy-notification.service";
import { HcrService } from "src/app/services/hcr-service.service";
import { CsHis6HPCStatusEnum, VisionHIS6 } from "../hcr-lib/VisionHIS6";

@Injectable({
  providedIn: 'root'
})

export class HPCHelper {

    constructor(
        private hcrService:HcrService,
        private notification:EasyNotificationService,
        private ezForm:EasyFormService
    ) {
    }
    private async isCsHis6(){
        var version = await this.hcrService.VisionApi.GetVersionEx();
        return version.Data>=6000
    }
    private getAPI(){
        return this.hcrService.VisionApi;
    }

    /** 驗PIN (要求輸入) */
    public async CheckPin(){
        var isCsHis6 = await this.isCsHis6();
        var pinCode = '';
        if(isCsHis6){
            var input = await this.ezForm.show({
                msg:'請輸入PIN碼',
                title:'請輸入PIN碼',
                fields:{
                  pin:{
                    name:'pin',
                    type:'text',
                    value:'',
                    label:'PIN'
                  }
                },
                beforeSubmit:async (a)=>{
                    if(a.pin.length!=6){
                        this.notification.showWarning('PIN須為6碼');        
                        return false;
                    }
                    return true;
                },
                closeValue:(a)=>null
              })
            if(input){
                pinCode = input.pin;
            }else{
                //使用者取消
                return false;
            }
        }
        var ret = await this.hcrService.VisionApi.VerifyHPCPIN(pinCode);
        if(ret.StatusCode!=0){
            this.notification.showWarning(ret.ErrorMsg);
            return false;
        }else{
            this.notification.showSuccess('驗證成功')
            return true;
        }
    }
    /** 取得醫師卡狀態 */
    public async CardStatus():Promise<CsHis6HPCStatusEnum>{
        var isCsHis6 = await this.isCsHis6();
        if(isCsHis6){
            var cshis6 = this.getAPI() as VisionHIS6;
            var status = await cshis6.GetCardStatusAll();
            return status.status.hpc.status;
        }else{
            var st = await this.getAPI().GetCardStatusHPC();
            if(st.Data == '卡片未置入'){
                return CsHis6HPCStatusEnum.未置入醫事人員卡;
            }
            if(st.Data == '醫事人員卡尚未與安全模組認證'){
                return CsHis6HPCStatusEnum.醫事人員卡已置入;
            }
            if(st.Data.includes('PIN 尚未認證')){
                return CsHis6HPCStatusEnum.醫事人員卡已驗證;
            }
            if(st.Data == '醫事人員卡 PIN 認證成功'){
                return CsHis6HPCStatusEnum.醫事人員卡已驗PIN;
            }
            return CsHis6HPCStatusEnum.未置入醫事人員卡;
        }
    }
    /** 檢查並驗證醫師卡 */
    async VarifyHPC(){
        var st = await this.CardStatus();
        if(st == CsHis6HPCStatusEnum.未置入醫事人員卡){
            this.notification.showSuccess(st.toString())
            return false;
        }
        // 未驗證先驗證
        if(st == CsHis6HPCStatusEnum.醫事人員卡已置入){
            await this.getAPI().VerifySAMDC();
            st = await this.CardStatus();
        }
        if(st == CsHis6HPCStatusEnum.醫事人員卡已驗證){
            return await this.CheckPin();
        }
        if(st == CsHis6HPCStatusEnum.醫事人員卡已驗PIN){
            return true;
        }
        return false;
      }
}
