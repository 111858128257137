/**掛號類別
 *
 */
export class RegisterTypeEnum {

}

export const RegisterTypeDesc : {text: string, value: string}[]
= [
  {text:'',value:''},
  {text:'洗腎',value:'0'},
  {text:'療六(1格6次)',value:'1'},
  {text:'預保',value:'2'},
  {text:'療三(1格2或3次)',value:'3'},
  {text:'月療(1療1個月)',value:'4'},
  {text:'全療(1療程1格)',value:'5'},
  {text:'內科同療',value:'6'},
  {text:'同日同醫師看診(第2次以後)',value:'7'},
  {text:'慢箋領藥',value:'8'},
]
export const PreventiveHealthCareDesc = {
  /** 成人預防保健服務給付時程: 一、40至65歲 */
  T21_Adult_40Y_Phase1 : '21',
  /** 成人預防保健服務給付時程: 二、超過65歲 */
  T22_Adult_65Y_Phase1 : '22',
  /** 成人預防保健服務給付時程: 一、40至65歲 */
  T23_Adult_40Y_Phase2 : '23',
  /** 成人預防保健服務給付時程: 二、超過65歲 */
  T24_Adult_65Y_Phase2 : '24',
  /** 成人預防保健服務給付時程: 小兒麻痺35歲以上，每年乙次，第一階段 */
  T25_Polio_35Y_Phase1 : '25',
  /** 成人預防保健:罹患小兒麻痺且年在35歲以上者每年補助乙次(第二階段) */
  T26_Polio_35Y_Phase2 : '26',
  /** 成人預防保健服務給付時程: 原住民55歲以上，每年乙次，每年乙次，第一階段 */
  T27_Aboriginal_55Y_Phase1 : '27',
  /** 成人預防保健服務給付時程: 原住民55歲以上，每年乙次，每年乙次，第二階段 */
  T28_Aboriginal_55Y_Phase2 : '28',
  /** BC肝炎擴大篩檢方案: 45(原住民40) ~ 79 終生一次 */
  T29_HepatitisBCExpandedScreening : '29',

  getAdultCareIcnos(): string[] {
    return Object.values(this).filter(value => ['21', '22', '23', '24','25','26','27','28','29'].includes(value as string)) as string[];
  }
} 