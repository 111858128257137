import { BaseConfig } from '../../services/base-config.service';

// 日期幫手
export class DateHelper {
  // 回傳今天0時0分0秒
  static get today(): Date {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return today;
  }
  // 回傳今天23時59分59秒
  static get todayMax(): Date {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    return today;
  }
  /** 取得日期部分 */
  static getDate(dateTime: Date | string): Date {
    var dt = new Date(dateTime);
    var date = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
    return date;
  }

  /**轉成Date */
  static getDateTime(dateTime: Date | string): Date {
    return new Date(dateTime);
  }
  static get dateFormat(): string {
    return BaseConfig.getConfig().ui.dateFormat;
  }
  static isValidDate(d: Date): boolean {
    if (isNaN(d.getTime())) {  // d.valueOf() could also work
      // date is not valid
      return false;
    } else {
      // date is valid
      return true;
    }
  }
  /** yyMMdd */
  static formatShortDate(value: any): string {
    if (!value) {
      return '';
    }
    var date = new Date(value);
    if (date) {
      var year = (date.getFullYear() % 100).toString().padStart(2, '0')
      var month = (date.getMonth() + 1).toString().padStart(2, '0');
      var day = date.getDate().toString().padStart(2, '0');
      return `${year}${month}${day}`
    } else {
      return value;
    }
  }
  /** MMDD, unit=>true, MM月DD日 */
  static formatMonthDay(value: any, unit: boolean = false): string {
    if (!value) {
      return '';
    }
    var date = new Date(value);
    if (date) {
      var month = (date.getMonth() + 1).toString().padStart(2, '0');
      var day = date.getDate().toString().padStart(2, '0');
      if (unit) {
        return `${month}月${day}日`
      } else {
        return `${month}${day}`
      }

    } else {
      return value;
    }
  }
  /** 按照BaseConfig中的格式進行格式化 */
  static formatDate(value: any): string {
    if (!value) {
      return '';
    }
    if (value instanceof Date) {
      if (this.isValidDate(value)) {
        return this.getDateString(value);
      } else {
        return this.getDateString(value);
      }
    } else if (typeof (value) === 'string') {
      if (!isNaN(Date.parse(value))) {
        // this is a valid date string
        const date = new Date(value);
        return this.getDateString(date);
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
  static combine(date: Date, time: string): Date {
    return new Date(date.toLocaleDateString() + ' ' + time);
  }
  /**  yyyMMdd
   * showUnit: true=> yyy年MM月dd日
   */
  static formatROCDate(value: any, showUnit = false, showTime = false): string {
    if (!value) {
      return '';
    }
    if (value instanceof Date) {
      if (showUnit) {
        return this.getROCDateStringWithUnit(value) + (showTime ? (' ' + value.toLocaleTimeString()) : '');
      } else {
        return this.getROCDateString(value) + (showTime ? ('' + value.getHours() + value.getMinutes() + value.getSeconds()) : '');
      }
    } else if (typeof (value) === 'string') {
      if (!isNaN(Date.parse(value))) {
        // this is a valid date string
        const date = new Date(value);
        if (showUnit) {
          return this.getROCDateStringWithUnit(date);
        } else {
          return this.getROCDateString(date) + (showTime ? (' ' + date.toLocaleTimeString()) : '');
        }
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  static formatROCDateTime(value: any, showUnit = false, showTime = false): string {
    if (!value) {
      return '';
    }
    if (value instanceof Date) {
      if (showUnit) {
        return this.getROCDateStringWithUnit(value) + (showTime ? (' ' + value.toLocaleTimeString()) : '');
      } else {
        return this.getROCDateStringTimeWithUnit(value) + (showTime ? ('' + value.getHours() + value.getMinutes() + value.getSeconds()) : '');
      }
    } else if (typeof (value) === 'string') {
      if (!isNaN(Date.parse(value))) {
        // this is a valid date string
        const date = new Date(value);
        if (showUnit) {
          return this.getROCDateStringWithUnit(date);
        } else {
          return this.getROCDateStringTimeWithUnit(date) + (showTime ? (' ' + date.toLocaleTimeString()) : '');
        }
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
  /** yyyMMddHHmmss */
  public static getROCDateTimeString(value: any) {
    let date;
    if (typeof (value) === 'string') {
      if (!isNaN(Date.parse(value))) {
        // this is a valid date string
        date = new Date(value);
      } else {
        return value;
      }
    } else {
      date = value;
    }

    var timeStr = `${date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0') + date.getSeconds().toString().padStart(2, '0')}`;
    // var cc=`${date.getFullYear()-1911}${(date.getMonth()+1).toString().padStart(2,'0')}${date.getDate().toString().padStart(2,'0')}`;
    // console.log(cc+"aa"+timeStr)
    return `${date.getFullYear() - 1911}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}` + timeStr;
  }
  /** yyyMMdd or yyMMdd */
  public static getROCDateString(date: Date) {
    return `${date.getFullYear() - 1911}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
  }
  //yyyMMdd一律7位, or yyy/MM/dd
  public static getROCFullDateString(date: Date, sepetator: string = '') {
    if (!date) {
      return '';
    }

    return `${(date.getFullYear() - 1911).toString().padStart(3, '0')}${sepetator}${(date.getMonth() + 1).toString().padStart(2, '0')}${sepetator}${date.getDate().toString().padStart(2, '0')}`;
  }
  /** YYY/MM/DD */
  public static getROCDateStringWithSepetator(date: Date, sepetator: string = '/') {
    return `${date.getFullYear() - 1911}${sepetator}${(date.getMonth() + 1).toString().padStart(2, '0')}${sepetator}${date.getDate().toString().padStart(2, '0')}`;
  }
  private static getROCDateStringWithUnit(date: Date) {
    var year = date.getFullYear() - 1911;
    var yearText = year < 0 ? ('民前' + year * -1) : year;
    return `${yearText}年${(date.getMonth() + 1).toString().padStart(2, '0')}月${date.getDate().toString().padStart(2, '0')}日`;
  }
  private static getROCDateStringTimeWithUnit(date: Date, sepetator: string = '/') {
    var year = date.getFullYear() - 1911;
    var yearText = year < 0 ? ('民前' + year * -1) : year;
    return `${yearText}${sepetator}${(date.getMonth() + 1).toString().padStart(2, '0')}${sepetator}${date.getDate().toString().padStart(2, '0')}`;
  }
  private static getDateString(date: Date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    let dayStr: string = day.toString();
    if (day < 10) { dayStr = '0' + dayStr; }
    let monthStr: string = (monthIndex + 1).toString();
    if ((monthIndex + 1) < 10) { monthStr = '0' + monthStr; }
    const yearStr = year.toString();

    const format = BaseConfig.getConfig().ui.dateFormat;

    let seperator = '-';
    if (format.indexOf('/') >= 0) {
      seperator = '/';
    } else {
      seperator = '-';
    }
    const formatPart = format.split(seperator);
    let dateString = '';

    for (let i = 0; i < formatPart.length; i++) {
      const part = formatPart[i];
      if (part === 'yyyy') {
        dateString += yearStr;
      } else if (part === 'MM') {
        dateString += monthStr;
      } else if (part === 'dd') {
        dateString += dayStr;
      }
      dateString += seperator;
    }
    if (dateString.endsWith(seperator)) {
      dateString = dateString.substring(0, dateString.length - 1);
    }
    return dateString;
  }
  static formatMonth(value: any): string {
    if (!value) {
      return '';
    }
    if (value instanceof Date) {
      if (this.isValidDate(value)) {
        return this.getMonthString(value);
      } else {
        return this.getMonthString(value);
      }
    } else if (typeof (value) === 'string') {
      if (!isNaN(Date.parse(value))) {
        // this is a valid date string
        const date = new Date(value);
        return this.getMonthString(date);
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
  private static getMonthString(date: Date) {
    let str = this.getDateString(date);
    str = str.substr(0, str.length - 3);
    return str;
  }
  /**
   * @returns yyyy-MM-dd HH:mm:ss 日期部分分隔號吃BaseConfig
   *  */
  static formatDateTime(value: any): string {
    if (value instanceof Date) {
      return this.getDateTimeString(value);
    } else if (typeof (value) === 'string') {
      if (!isNaN(Date.parse(value))) {
        // this is a valid date string
        const date = new Date(value);
        return this.getDateTimeString(date);
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
  /** yyyyMMddHHmmssSSSS */
  static formatFullDateTime(value:any){
    var date:Date = null;
    if (value instanceof Date) {
      date = value;
    } else if (typeof (value) === 'string') {
      if (!isNaN(Date.parse(value))) {
        // this is a valid date string
        date = new Date(value);
      } else {
        return value;
      }
    } else {
      return value;
    }
    
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');
    let milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    let timestamp = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
    return timestamp;
  }
  private static getDateTimeString(datetime: Date) {
    const dateString = this.getDateString(datetime);
    // 趕時間先簡單寫
    let hr = datetime.getHours().toString();
    if (datetime.getHours() < 10) {
      hr = '0' + hr;
    }
    let min = datetime.getMinutes().toString();
    if (datetime.getMinutes() < 10) {
      min = '0' + min;
    }
    let sec = datetime.getSeconds().toString();
    if (datetime.getSeconds() < 10) {
      sec = '0' + sec;
    }
    return dateString + ' ' + hr + ':' + min + ':' + sec;
  }
  static createDate(value: any): Date {
    try {
      if (value) {
        // if(typeof(value) == 'string' && value.indexOf('+')>=0){
        //   value = value.substring(0,value.indexOf('+'));
        // }
        const newDate = new Date(value);
        if (!newDate) {
          return null;
        }
        return newDate;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }


  static getFirstDayOfYear(year: number): Date {
    return new Date(year, 0, 1);
  }
  static getFirstSunDay(year: number): Date {
    const firstSunDay = this.getFirstDayOfYear(year);
    const n = 6 - (firstSunDay.getDay() + 6) % 7;
    firstSunDay.setDate(firstSunDay.getDate() + n);
    return firstSunDay;
  }
  static getDaysOfMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }
  static getWeekOfMonth(year: number, month: number, day: number): number {
    const date = new Date(year, month - 1, day);
    const w = date.getDay();
    const d = date.getDate();
    return Math.ceil((d + 6 - w) / 7);
  }
  /**
   * 取得某月有幾週
   *
   * @static
   * @param {number} year
   * @param {number} month
   * @returns {number}
   * @memberof DateHelper
   */
  static getWeeksOfMonth(year: number, month: number): number {
    return this.getWeekOfMonth(year, month, this.getDaysOfMonth(year, month));
  }
  /**
   * 取得某日期是一年中的第幾週
   *
   * @static
   * @param {Date} date
   * @returns {number}
   * @memberof DateHelper
   */
  static getWeekOfYear(date: Date): number {
    const date2 = this.getFirstDayOfYear(date.getFullYear());
    const d = Math.round((date.valueOf() - date2.valueOf()) / 86400000);
    return Math.ceil((d + ((date2.getDay() + 1) - 1)) / 7);
  }
  /**
   * 取得下週的七個日子
   *
   * @static
   * @param {Date} date
   * @param {number} [flag=0] = 0 >>> 日曆週 ,= 1 >>> 工作週
   * @returns {Date[]}
   * @memberof DateHelper
   */
  static getDaysOfNextWeek(date: Date, flag: number = 0): Date[] {
    const d = date.getDay();
    const date1 = new Date(date.getFullYear(), date.getMonth(), date.getDate() - d + 7 + flag);
    return DateHelper.getDaysAfterDate(date1, 7);
  }
  /**
   * 取得某年某週的七個日子
   *
   * @static
   * @param {number} year
   * @param {number} week
   * @param {number} [flag=0]  = 0 >>> 日曆週 ,= 1 >>> 工作週
   * @returns {Date[]}
   * @memberof DateHelper
   */
  static getDaysOfTheWeek(year: number, week: number, flag: number = 0): Date[] {
    const firstSunDay = this.getFirstSunDay(year);
    firstSunDay.setDate(firstSunDay.getDate() + 7 * (week - 1) + flag);
    return this.getDaysAfterDate(firstSunDay, 7);
  }
  /**
   * 取得某日往後N個日子
   *
   * @static
   * @param {Date} date
   * @param {number} days N days
   * @returns
   * @memberof DateHelper
   */
  static getDaysAfterDate(d: Date, days: number) {
    const result: Date[] = [];
    const date: Date = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    for (let i = 0; i < days; i++) {
      const date1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      result.push(date1);
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  static getIsoStringNoTimeZone(date: Date) {
    var d = new Date(date);
    d.setMinutes(-d.getTimezoneOffset() + d.getMinutes());
    return d.toISOString();

  }
  /** 回傳'HH:mm', 或 '' ，isPadStart:時跟分是否補0到兩位數*/
  static getHourMinTime(date: Date, isPadStart: boolean = false) {
    if (date) {
      var d = new Date(date);
      if (isPadStart) {
        return d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0');
      } else {
        return d.getHours() + ':' + d.getMinutes();
      }
    } else {
      return '';
    }
  }
  /** 回傳'HHmmmss', 或 '' ，isPadStart:時跟分是否補0到兩位數*/
  static getTimeString(date: Date, separator: string = '', isPadStart: boolean = false) {
    if (date) {
      var d = new Date(date);
      if (isPadStart) {
        var valuesStr = [d.getHours().toString().padStart(2, '0'), d.getMinutes().toString().padStart(2, '0'), d.getSeconds().toString().padStart(2, '0')];
        return valuesStr.join(separator);
      } else {
        var values = [d.getHours(), d.getMinutes(), d.getSeconds()];
        return values.join(separator);
      }
    } else {
      return '';
    }
  }

  static addDay(date: Date, days: number): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days, date.getHours(), date.getMinutes(), date.getSeconds());
  }
  static addMinute(date: Date, minute: number): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + minute, date.getSeconds());
  }
  static addMonthDay(date: Date, months: number): Date {
    return new Date(date.getFullYear(), date.getMonth() + months, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  }
  static dayDiff(date1: Date, date2: Date) {
    return (date1.getTime() - date2.getTime()) / 86400000
  }
  /** 差幾分鐘 */
  static minDiff(date1: Date, date2: Date) {
    return (date1.getTime() - date2.getTime()) / 60000
  }

  static getWeekOfDayText(date: Date) {
    var dt = new Date(date)
    if (dt) {
      var numberText = '日一二三四五六'
      return '周' + numberText[dt.getDay()];
    }
    return ''
  }
  /** 轉換Date To String
   *  @param ev 值
   *  @param isRoc:是否轉換成民國年
   *  @param symbol:分隔符號，
   *  @param [isMonth=false] 是否只顯示到月份
   *  @returns
   *      isRoc & isMonth: yyy{symbol}MM
   *
   *      isRoc & !isMonth: yyy{symbol}MMdd
   *
   *      !isRoc & isMonth: yyyy{symbol}MM
   *
   *      !isRoc & !isMonth: yyyy{symbol}MMdd
   */
  public static getFormatedDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var year = (d.getFullYear() - (isRoc ? 1911 : 0)).toString();
      var month = (d.getMonth() + 1).toString().padStart(2, '0');

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        var day = d.getDate().toString().padStart(2, '0');
        return [year, month, day].join(symbol);
      }
    }
  }

  public static compareDate(dt1: Date | string, dt2: Date | string): boolean {
    try {
      var d1 = new Date(dt1);
      var d2 = new Date(dt2);
      return d1 && d2
        && d1.getFullYear() == d2.getFullYear()
        && d1.getMonth() == d2.getMonth()
        && d1.getDate() == d2.getDate()
    } catch (e) {
      return false;
    }
  }
  public static compareToday(dt1: Date | string): boolean {
    try {
      var d1 = new Date(dt1);
      var d2 = new Date();
      return d1 && d2
        && d1.getFullYear() == d2.getFullYear()
        && d1.getMonth() == d2.getMonth()
        && d1.getDate() == d2.getDate()
    } catch (e) {
      return false;
    }
  }
  public static beforeToday(td: Date | string, dt1: Date | string): boolean {
    try {
      var d1 = new Date(dt1);
      var today = new Date(td);
      // 將時間部分歸零以進行日期比較
      today.setHours(0, 0, 0, 0);
      d1.setHours(0, 0, 0, 0);
      return d1 < today;
    } catch (e) {
      return false;
    }
  }
  public static beforeOrCompareToday(td: Date | string, dt1: Date | string): boolean {
    try {
      var d1 = new Date(dt1);
      var today = new Date(td);
      // 將時間部分歸零以進行日期比較
      today.setHours(0, 0, 0, 0);
      d1.setHours(0, 0, 0, 0);
      return d1 <= today;
    } catch (e) {
      return false;
    }
  }
  public static parseROCDateTime(dt: string) {
    var year = dt.substring(0, 3);
    
    let westear = !isNaN(Number(year)) ? Number(year) + 1911 : -1;
    if (westear > 0) year = westear.toString();
    
    var month = dt.substring(3, 5);
    var day = dt.substring(5, 7);
    var hour = dt.substring(7, 9);
    var minute = dt.substring(9, 11);
    var second = dt.substring(11, 13);
    return new Date(`${year}/${month}/${day} ${hour}:${minute}:${second}`);
  }

  public static parseROCDate(date: string) {
    var dt = date + '000000';
    var year = dt.substring(0, 3);
    let westear = !isNaN(Number(year)) ? Number(year) + 1911 : -1;
    if (westear > 0) year = westear.toString();

    var month = dt.substring(3, 5);
    var day = dt.substring(5, 7);
    var hour = dt.substring(7, 9);
    var minute = dt.substring(9, 11);
    var second = dt.substring(11, 13);
    return new Date(`${year}/${month}/${day} ${hour}:${minute}:${second}`);
  }
  /** 轉換生日 沒有帶生日 月份會是14 ; age[1] !=14 */
  public static transformBirthDay(birth: Date | string, regDate: Date | string): number[] {

    if (birth == null || birth == '') {
      return [0, 14, 0];
    }

    var result = "";
    // collect input date
    var dob = new Date(birth);
    var dobYear = dob.getFullYear();
    var dobMonth = dob.getMonth();
    var dobDate = dob.getDate();

    // get the current date from the system
    var now = new Date(regDate);
    // extract the year, month, and date from current date
    var currentYear = now.getFullYear();
    var currentMonth = now.getMonth();
    var currentDate = now.getDate();

    // get years
    var yearAge = currentYear - dobYear;

    // get months
    if (currentMonth >= dobMonth) {
      //get months when current month is greater
      var monthAge = currentMonth - dobMonth;
    } else {
      yearAge--;
      var monthAge = 12 + currentMonth - dobMonth;
    }

    // get days
    if (currentDate >= dobDate) {
      // get days when the current date is greater
      var dateAge = currentDate - dobDate;
    } else {
      monthAge--;
      // get the days in the last month
      var daysInLastMonth = new Date(currentYear, currentMonth, 0).getDate();
      var dateAge = daysInLastMonth + currentDate - dobDate;
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    return [yearAge, monthAge, dateAge];
  }
}
