import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { FormGroup } from '@angular/forms';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { VisitsReport, VisitsReportItem } from 'src/app/opd/opd-report/models/visits-report';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HcrService } from 'src/app/services/hcr-service.service';

@Component({
    selector: 'app-consultation-volume',
    templateUrl: './consultation-volume.component.html',
    styleUrls: ['./consultation-volume.component.css']
})
export class ConsultationVoulmeComponent implements OnInit, AfterViewInit {

  sizePage = {
    width: 20, //cm
    height: 28.7 //cm
  };
  paddingPage = {
      top: 0, //cm
      right: 0, //cm
      bottom: 0.5, //cm
      left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer;
  anchorsBlockValue;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  upperboundIndex: number = 0;
  showDoctorNameColume: boolean;
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  pageNo: number = 1;
  _sDate: Date;
  _eDate: Date;
  visitsReport: VisitsReport;
  // 看診清單
  listItems: VisitsReportItem[] = [];
  totalFirstVisitPatients: number = 0;
  totalRevisitPatients: number = 0;
  totalPatientsInMorning: number = 0;
  totalPatientsInAfternoon: number = 0;
  totalPatientsInEvening: number = 0;
  totalPaidByHealthCare: number = 0;
  totalPaidBySelf: number = 0;

  // api服務
  api: WebApiService;
  // 查詢條件;
  queryFormGroup: FormGroup;
  // data: ConsultationVolumeReportDTO[];

  constructor(private webApiClient: WebApiClient,
              private localPrintService: LocalPrintService,
              private hcrService:HcrService,
              private notification: EasyNotificationService) {
                this.api = this.webApiClient.createHisService('Opd/OpdReport');
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  // 取得統計期間的看診人次資料
  async fetchData(query: any) {
    // this.elContainer = document.getElementById("container");
    this.visitsReport = new VisitsReport();
    this.nowStr = this.onGetDateString(new Date(), true);
    this.timeStr = this.onGetTimeString(new Date());
    this._sDate = query.startDate;
    this._eDate = query.endDate;
    if (query.reportType == 'doctor') {
      this.showDoctorNameColume = true;
    } else {
      this.showDoctorNameColume = false;
    }

    this.api.get('GetVisitsReport', query).subscribe(
      (data: VisitsReport) => {
        this.visitsReport = data;
        this.listItems = this.showDoctorNameColume ? data.Items.filter(x => x.IsSummary == false) : data.Items;
        this.totalRecord = this.showDoctorNameColume ? this.listItems.filter(x => x.IsSummary == false && x.IsDoctorTotal == false).length : this.listItems.filter(x => x.IsSummary == false).length;
        this.totalFirstVisitPatients = 0;
        this.totalRevisitPatients = 0;
        this.totalPatientsInMorning = 0;
        this.totalPatientsInAfternoon = 0;
        this.totalPatientsInEvening = 0;
        this.totalPaidByHealthCare = 0;
        this.totalPaidBySelf = 0;
        var prevName = '';
        this.listItems.forEach(x => {
          if (x.IsSummary||x.IsDoctorTotal) {
            this.totalFirstVisitPatients += x.FirstVisitPatients;
            this.totalRevisitPatients += x.RevisitPatients;
            this.totalPatientsInMorning += x.PatientsInMorning;
            this.totalPatientsInAfternoon += x.PatientsInAfternoon;
            this.totalPatientsInEvening += x.PatientsInEvening;
            this.totalPaidByHealthCare += x.PaidByHealthCare;
            this.totalPaidBySelf += x.PaidBySelf;
          }
          var currName = '';
          if (this.showDoctorNameColume) {
            currName = x.DoctorName;
          } else {
            currName = x.ClinicName;
          }

          if (currName == prevName) {
            if (this.showDoctorNameColume) {
              x.DoctorName = '';
            } else {
              x.ClinicName = '';
            }
          } else {
            prevName = currName;
          }
        });
        // 將查詢的資料組成報表html
        this.insertListData();
      }
    );
  }

  // #region 組報表
  // 組報表的html內容
  insertListData() {
    this.elContainer = document.getElementById("container");
    
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();              // 報表結束 -- 以下空白
    var html_total = this.createHTMLTotal();
    var html_blank = this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {
      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listItems[i]);
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
          this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.listItems.length-1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      if ((this.listItems[i].IsSummary || this.listItems[i].IsDoctorTotal) && !(i == this.listItems.length-1)) {
        afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var tPage = document.getElementById("totalPage-" + i);
      tPage.innerHTML = "" + (this.upperboundIndex + 1);
    }

    // 組完報表將結果指定給父元素的第一個子元素
    const parentElement = document.getElementById("pres-report2");
    parentElement.firstElementChild.innerHTML = this.elContainer.innerHTML;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
      return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="11" style="height: 46px; border-right: none; border-left: none; border-bottom: none; border-top: none;">

              </th>
            </tr>`;
  }

  createHTMLListHeader() {
    var doctoColumn = this.showDoctorNameColume ? "醫師名" : "診所名稱";
    return `<tr>
              <th
                style="text-align: left; width: 22%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                ` + doctoColumn + `
              </th>
              <th
                style="text-align: right; width: 4%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                診
              </th>
              <th
                style="text-align: right; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                日期
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                初診
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                複診
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                早班
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                午班
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                夜診
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                健保
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                自費
              </th>
              <th
                style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                小計
              </th>
            </tr>`;
  }

  createHTMLBlock(data: VisitsReportItem) {
    var summaryStyle = (data.IsSummary || data.IsDoctorTotal) ? "border-top: 1px dotted #777;" : "border-top: none;";
    var showSummaryName = (data.IsSummary || data.IsDoctorTotal) ? "合計" : (this.showDoctorNameColume ? data.DoctorName : data.ClinicName);
    var roomCode = data.RoomCode ?? "";
    var showDate = data.Date ? this.onGetDateString(data.Date, true, '', false) : "";
    var visitorSummary = data.FirstVisitPatients + data.RevisitPatients;
    return '<tr>' +
              '<td style="text-align: left; font-weight: bold; ' + summaryStyle + ' border-bottom: none;">' + showSummaryName + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + 'border-bottom: none;">' + roomCode + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + showDate + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.FirstVisitPatients + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.RevisitPatients + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.PatientsInMorning + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.PatientsInAfternoon + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.PatientsInEvening + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.PaidByHealthCare + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.PaidBySelf + '</td>' +
              '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + visitorSummary + '</td>' +
            '</tr>';
  }

  createHTMLTotal() {
    return `<tr border="0">
              <td style="text-align: left; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                總計
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">

              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">

              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalFirstVisitPatients + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalRevisitPatients + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPatientsInMorning + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPatientsInAfternoon + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPatientsInEvening + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPaidByHealthCare + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + this.totalPaidBySelf + `
              </td>
              <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
                ` + (this.totalFirstVisitPatients + this.totalRevisitPatients) + `
              </td>
            </tr>`;
  }

  createHTMLPageHeader(iPage) {
    var reportName = this.showDoctorNameColume ? '醫師看診人次統計表' : '診所看診人次統計表';
    return `<div id="header" style="margin: 0px 10px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="11" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td colspan="3" style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="5" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      期間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                      </td>
                      <td colspan="3" style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td colspan="3" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="5" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td colspan="3" style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const reportName = this.showDoctorNameColume ? '醫師看診人次統計表' : '診所看診人次統計表';
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {

        const reportName = this.showDoctorNameColume ? '醫師看診人次統計表' : '診所看診人次統計表';

        // const uri = 'data:application/vnd.ms-excel;base64,';
        // const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
        //                     <head>
        //                         <!--[if gte mso 9]>
        //                         <xml>
        //                             <x:ExcelWorkbook>
        //                                 <x:ExcelWorksheets>
        //                                     <x:ExcelWorksheet>
        //                                         <x:Name>{worksheet}</x:Name>
        //                                         <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
        //                                     </x:ExcelWorksheet>
        //                                 </x:ExcelWorksheets>
        //                             </x:ExcelWorkbook>
        //                         </xml>
        //                         <![endif]-->
        //                     </head>
        //                     <body>
        //                         <table>{table}</table>
        //                     </body>
        //                     </html>`;

        // const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
        // const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
        // const table = this.reportContainer.nativeElement;
        // const ctx = { worksheet: reportName, table: table.innerHTML };

        // var fileName = reportName + `_` + this.onGetTimeString(new Date(), '') + `.xls`
        // var content = format(template, ctx);
        // var ret =await this.hcrService.SaveTextAsZipIfPwd(reportName+'\\'+fileName,content)
        // await this.hcrService.ConfirmOpenDir('下載完成','檔案已下載至'+ret.Returns,ret.Returns);

        const table = this.reportContainer.nativeElement;
        await this.hcrService.ExportTableToExcel(reportName,table.innerHTML)


        // const link = document.createElement('a');
        // link.download = reportName + `_` + this.onGetTimeString(new Date(), '') + `.xls`;
        // link.href = uri + base64(format(template, ctx));
        // link.click();
    } else {
        this.notification.showInfo('查無資料可供匯出!');
    }
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

  /** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }

  onGetDate(ev) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [month, day].join('');
    }
  }
}

