import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// kendo
// module
// import { SharedRoutingModule } from './shared-routing.module';
import { EditControlButtonsComponent } from './components/edit-control-buttons/edit-control-buttons.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { ValidationTipComponent } from './components/validation-tip/validation-tip.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { NotifySoundComponent } from './components/notify-sound/notify-sound.component';
import { NumberIntegerOnlyDirective } from './directives/number-integer-only.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { MsgTextComponent } from './components/msg-text/msg-text.component';
import { FormatDateTimePipe, FormatHourMinTimePipe, FormatROCTimePipe, FormatTimePipe } from './pipes/format-date-time.pipe';
import { FormatMonthPipe } from './pipes/format-month.pipe';
import { CheckEditComponent } from './components/check-edit/check-edit.component';
import { UIModule } from './module/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HistViewComponent } from './components/hist/hist-view/hist-view.component';
import { HistViewContentComponent } from './components/hist/hist-view-content/hist-view-content.component';
import { DrugAllergyComponent } from './components/patients/drug-allergy/drug-allergy.component';
import { PatientRemarkDialogComponent } from './components/patients/patient-remark-dialog/patient-remark-dialog.component';
import { VisGridComponent } from './components/grid/vis-grid/vis-grid.component';
import { VisGridColumnComponent } from './components/grid/vis-grid-column/vis-grid-column.component';
import { VisGridCommandColumnComponent } from './components/grid/vis-grid-command-column/vis-grid-command-column.component';
import { VisDropdownlistComponent } from './components/dropdownlist/vis-dropdownlist/vis-dropdownlist.component';
import { VisGridCheckboxColumnComponent } from './components/grid/vis-grid-checkbox-column/vis-grid-checkbox-column.component';
import { VisWindowComponent } from './components/dialog/vis-window/vis-window.component';
import { VisDialogComponent } from './components/dialog/vis-dialog/vis-dialog.component';
import { VisDialogActionsComponent } from './components/dialog/vis-dialog-actions/vis-dialog-actions.component';
import { VisDatepickerComponent } from './components/datepicker/vis-datepicker/vis-datepicker.component';
import { VisComboboxComponent } from './components/dropdownlist/vis-combobox/vis-combobox.component';
import { VisMultiselectComponent } from './components/dropdownlist/vis-multiselect/vis-multiselect.component';
import { VisNumerictextboxComponent } from './components/vis-numerictextbox/vis-numerictextbox.component';

import { DragDropModule} from '@angular/cdk/drag-drop';
import { VisWindowTitlebarComponent } from './components/dialog/vis-window-titlebar/vis-window-titlebar.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component'
import { SearchBarFieldDirective } from './components/search-bar/search-bar-field-directive';
import { VisGridEditableColumnComponent } from './components/grid/vis-grid-editable-column/vis-grid-editable-column.component';
import { EasyFormComponent } from './components/easy-form/easy-form.component';
import { EnterToDirective } from './directives/enter-to.directive';
import { FormatRocDatePipe, FormatRocDateUnitPipe, FormatRocFullDatePipe, FormatRocFullDateTimePipe } from './pipes/format-date-roc.pipe';
import { AgePipe, FullAgePipe, RealAgePipe } from './pipes/age.pipe';
import { TrimPipe } from './pipes/trim-pipe';
import { ChildGrowChartComponent } from './components/child-grow-chart/child-grow-chart.component';
import { ValueToTextPipe } from './pipes/valueToText.pipe';
import { GridPageDirective } from './components/grid/vis-grid-page-handler.directive';
import { KeydownToDirective } from './directives/keydown-to.directive';
import { NumberFixedPipe, NumberThousandFixedPipe, TextPadEndPipe, TextPadStartPipe } from './pipes/text-pad.pipe';
import { CidMaskPipe } from './pipes/cid-mask.pipe';
import { OpenDialogDirective,CloseDialogDirective } from './directives/open-dialog.directive';
import { VisMonthpickerComponent } from './components/datepicker/vis-monthpicker/vis-monthpicker.component';
import { SvgShareModule } from './module/svg-share/svg-share.module';
import { VisTimepickerComponent } from './components/datepicker/vis-timepicker/vis-timepicker.component';
import { VisGridColumnHeaderComponent } from './components/grid/vis-grid-column-header/vis-grid-column-header.component';
import { ClearInputDirective } from './directives/clear-input.directive';
import { VisEditWindowComponent } from './components/dialog/vis-edit-window/vis-edit-window.component';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { VisMomentDateAdapter } from './components/datepicker/VisMomentDateAdapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MonthPickerCalendarHeader } from './components/datepicker/vis-monthpicker/header/header.component';
import { VisDateSearchpickerComponent } from './components/datepicker/vis-datesearchpicker/vis-datesearchpicker.component';
import { MixedCdkDragDropDirective, MixedCdkDropListDirective, MixedCdkDragSizeHelperDirective } from './directives/mixed-cdk-drag-drop.directive';
import { NumberTrimPreZeroDirective } from './directives/number-trim-pre-zero.directive';
import { LocalFileSelectorComponent } from './components/local-file-selector/local-file-selector.component';
import { WordKeepCaptialDirective } from './directives/word-keep-captial.directive';
import { VisMultiComboboxComponent } from './components/dropdownlist/vis-multicombobox/vis-multicombobox.component';
import { VisButtonComponent } from './components/button/button/button.component';
import { RectButtonComponent } from './components/button/rect-button/rect-button.component';
import { GridKeybordDirective } from './directives/gridKeybord.directive';
import { SubmitButtonDirective } from './components/button/submit-button-directive';
import { VisFileUploadComponent } from './components/file-upload/vis-file-upload.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { FileSizeUnitPipe, FileSizeKBPipe, FileSizeMBPipe, FileSizeGBPipe, FileSizeTBPipe } from './pipes/filesize.pipe';
import { UppercaseDirective } from './directives/uppercase.directive';
import { AllowPermissionIfDirective } from './directives/allow-permission-if.directive';
import { VisComboBoxMultipieComponent } from './components/dropdownlist/vis-combo-box-multipie/vis-combo-box-multipie.component';
import { MatChipsModule } from '@angular/material/chips';
import { VisComboboxRealTimeOptionComponent } from './components/dropdownlist/vis-combobox-real-time-option/vis-combobox-real-time-option.component';
import { StdSystemCodeImportComponent } from './components/system-code/std-system-code-import/std-system-code-import.component';
import { SystemCodeAddComponent } from './components/system-code/system-code-add/system-code-add.component';
import { StdSystemCodeImportWithAddComponent } from './components/system-code/std-system-code-import-with-add/std-system-code-import-with-add.component';
import { DualListSelectorComponent } from './components/dual-list-selector/dual-list-selector.component';
import { SystemCodeListGridViewComponent } from './components/system-code/system-code-list-grid-view/system-code-list-grid-view.component';
import { VisComboBoxV2Component } from './components/dropdownlist/vis-combo-box-v2/vis-combo-box-v2.component';
import { ReserveGridComponent } from './components/register/reserve-grid/reserve-grid.component';
import { PeriodicGridComponent } from './components/register/periodic-grid/periodic-grid.component';
import { ClinicHistoryGridComponent } from './components/register/clinic-history-grid/clinic-history-grid.component';
import { NHIHistoryGridComponent } from './components/register/nhihistory-grid/nhihistory-grid.component';
import { PrintDialogComponent } from './components/register/print-dialog/print-dialog.component';
import { MedicationWayAndReceiptReportComponent } from './components/report/medication-way-and-receipt-report/medication-way-and-receipt-report.component';
import { MedicationWayReportComponent } from './components/report/medication-way-report/medication-way-report.component';
import { ReceiptUnitComponent } from './components/report/receipt-unit/receipt-unit.component';
import { VisGridRowComponent } from './components/grid/vis-grid-row/vis-grid-row.component';
import { RegisterHistoryComponent } from './components/register/register-history/register-history.component';
import { RegisterHistInfoComponent } from './components/register/register-hist-info/register-hist-info.component';
import { AbnormalICComponent } from './components/register/abnormal-ic/abnormal-ic.component';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    // Pipes
    AgePipe, FullAgePipe, RealAgePipe, FormatDatePipe, FormatRocDatePipe, FormatRocDateUnitPipe, FormatRocFullDatePipe, FormatRocFullDateTimePipe, TrimPipe, FormatDateTimePipe, FormatMonthPipe, ValueToTextPipe,
    TextPadStartPipe, TextPadEndPipe, NumberFixedPipe, CidMaskPipe, NumberThousandFixedPipe, FormatROCTimePipe, FormatTimePipe,FormatHourMinTimePipe,
    FileSizeUnitPipe, FileSizeKBPipe, FileSizeMBPipe, FileSizeGBPipe, FileSizeTBPipe,
    // Directives
    NumberOnlyDirective,NumberIntegerOnlyDirective,EnterToDirective,SearchBarFieldDirective,GridPageDirective,KeydownToDirective,GridKeybordDirective,OpenDialogDirective,CloseDialogDirective,
    MixedCdkDragDropDirective, MixedCdkDropListDirective, MixedCdkDragSizeHelperDirective,
    ClearInputDirective,SubmitButtonDirective,
    UppercaseDirective,
    //Component
    EditControlButtonsComponent, ConfirmDialogComponent, DeleteDialogComponent, InfoDialogComponent,
    ValidationTipComponent, NotifySoundComponent,
    MsgTextComponent,
    CheckEditComponent, HistViewComponent, HistViewContentComponent, DrugAllergyComponent,
    PatientRemarkDialogComponent,
    VisGridComponent,
    VisGridColumnComponent,
    VisGridRowComponent,
    VisGridCommandColumnComponent,
    VisMultiComboboxComponent,
    VisDropdownlistComponent,
    VisGridCheckboxColumnComponent,
    VisGridColumnHeaderComponent,
    VisWindowComponent,
    VisEditWindowComponent,
    VisDialogComponent,
    VisDialogActionsComponent,
    VisDatepickerComponent,
    VisComboboxComponent,
    VisMultiselectComponent,
    VisNumerictextboxComponent,
    VisWindowTitlebarComponent,
    SearchBarComponent,
    VisGridEditableColumnComponent,
    EasyFormComponent,
    ChildGrowChartComponent,
    VisMonthpickerComponent,
    VisDateSearchpickerComponent,
    VisTimepickerComponent,
    MonthPickerCalendarHeader,
    NumberTrimPreZeroDirective,
    WordKeepCaptialDirective,
    LocalFileSelectorComponent,
    VisButtonComponent,
    RectButtonComponent,
    VisFileUploadComponent,
    DragAndDropDirective,
    AllowPermissionIfDirective,
    VisComboBoxMultipieComponent,
    VisComboboxRealTimeOptionComponent,
    StdSystemCodeImportComponent,
    SystemCodeAddComponent,
    StdSystemCodeImportWithAddComponent,
    DualListSelectorComponent,
    SystemCodeListGridViewComponent,
    VisComboBoxV2Component,
    ReserveGridComponent,
    PeriodicGridComponent,
    ClinicHistoryGridComponent,
    NHIHistoryGridComponent,
    PrintDialogComponent,
    MedicationWayAndReceiptReportComponent,
    MedicationWayReportComponent,
    ReceiptUnitComponent,
    RegisterHistoryComponent,
    RegisterHistInfoComponent,
    AbnormalICComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    MatExpansionModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatChipsModule,
    // kendo
    UIModule,
    // module
    // SharedRoutingModule,
    DragDropModule,
    SvgShareModule,
    MatTooltipModule
],
  exports: [
    // Pipes
    AgePipe, FullAgePipe, RealAgePipe, FormatDatePipe, FormatRocDatePipe, FormatRocDateUnitPipe, FormatRocFullDatePipe, FormatRocFullDateTimePipe, TrimPipe, FormatDateTimePipe, FormatMonthPipe, ValueToTextPipe,
    TextPadStartPipe, TextPadEndPipe, NumberFixedPipe, CidMaskPipe, NumberThousandFixedPipe, FormatROCTimePipe, FormatTimePipe,FormatHourMinTimePipe,
    FileSizeUnitPipe, FileSizeKBPipe, FileSizeMBPipe, FileSizeGBPipe, FileSizeTBPipe,
    // Directives
    NumberOnlyDirective,NumberIntegerOnlyDirective,NumberTrimPreZeroDirective,EnterToDirective,SearchBarFieldDirective,GridPageDirective,KeydownToDirective,GridKeybordDirective,OpenDialogDirective,CloseDialogDirective,
    ClearInputDirective,WordKeepCaptialDirective,
    MixedCdkDragDropDirective, MixedCdkDropListDirective, MixedCdkDragSizeHelperDirective,
    SubmitButtonDirective,
    DragAndDropDirective,
    UppercaseDirective,
    AllowPermissionIfDirective,
    // Components
    EditControlButtonsComponent, ConfirmDialogComponent, DeleteDialogComponent, InfoDialogComponent,
    ValidationTipComponent, NotifySoundComponent, MsgTextComponent, CheckEditComponent,
    HistViewComponent, HistViewContentComponent, DrugAllergyComponent,
    PatientRemarkDialogComponent,
    VisGridComponent,
    VisGridColumnComponent,
    VisGridRowComponent,
    VisGridCommandColumnComponent,
    VisGridCheckboxColumnComponent,
    VisGridEditableColumnComponent,
    VisGridColumnHeaderComponent,
    VisDropdownlistComponent,
    VisMultiComboboxComponent,
    VisComboboxComponent,
    VisMultiselectComponent,
    VisDatepickerComponent,
    VisWindowComponent,
    VisEditWindowComponent,
    VisWindowTitlebarComponent,
    VisDialogComponent,
    VisDialogActionsComponent,
    VisNumerictextboxComponent,
    SearchBarComponent,
    ChildGrowChartComponent,
    VisMonthpickerComponent,
    VisDateSearchpickerComponent,
    VisTimepickerComponent,
    VisButtonComponent,
    RectButtonComponent,
    VisFileUploadComponent,
    AllowPermissionIfDirective,
    VisComboBoxMultipieComponent,
    VisComboboxRealTimeOptionComponent,
    StdSystemCodeImportComponent,
    SystemCodeAddComponent,
    StdSystemCodeImportWithAddComponent,
    DualListSelectorComponent,
    VisComboBoxV2Component,
    ReserveGridComponent,
    PeriodicGridComponent,
    ClinicHistoryGridComponent,
    NHIHistoryGridComponent,
    PrintDialogComponent,
    SystemCodeListGridViewComponent,
    MedicationWayAndReceiptReportComponent,
    MedicationWayReportComponent,
    ReceiptUnitComponent,
    RegisterHistoryComponent,
    AbnormalICComponent,
    // Modules
    CommonModule,
    FormsModule,
    MatExpansionModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    UIModule,
    DragDropModule,
    SvgShareModule,

  ],
  providers: [ {
    provide: DateAdapter,
    useClass: VisMomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  }],
})
export class SharedModule { }
