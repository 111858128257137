
import { Injectable } from "@angular/core";
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { ValueTextPair, ValueTextPairNumberValue } from '../../../shared/models/value-text-pair';
import { HCRxCodes } from "./hcRxCodes";
import { Hist } from "src/app/hist/models/hist";
import { DateHelper } from "src/app/shared/helpers/date-helper";
import { SPCTreatDto } from "./spcTreatDto";
import { BehaviorSubject } from "rxjs";
import { HistCusDto } from "src/app/hist/rating-price/models/RatingPrice";

export interface PricingInfo {
  diagFee: { [key: string]: number };
  partOpd: { [key: string]: number };
  medPay: { [key: string]: number };
  drugPay: { [key: string]: number };
  breaf: { [key: string]: number };
}
export interface PricingEditOpt {
  CaseTP: ValueTextPair[];
  DispTP: ValueTextPair[];
  LABTP: ValueTextPair[];
  PayTP: ValueTextPair[];
  Pharmarcist: ValueTextPairNumberValue[];
  deptList: ValueTextPair[];
  userDept: string;
  referralopt: ValueTextPair[];
  defaultCase: string;
  spcTreatList: ValueTextPair[];
  consultationOpt : ValueTextPair[];
  presNumOpt : ValueTextPair[];
  /**
   * 慢病日
   */
  chronicDay: number;
  /** 慢箋預設總次 */
  chronicFreq: number;
  /**
   * 醫藥分業
   */
  medDiv: boolean;
  // 慢箋提醒
  p13: string;
  rxDailyQuota: number[];
}
export class Hrxfee {
  /** 診察費 */
  DiagFee: number;
  /** 口服藥費 */
  Oral: number;
  /** 外用藥費 */
  External: number;
  /** 注射藥費 */
  Injection: number;
  /** 注射技術費 */
  InjectionService: number;
  /** 檢驗費 */
  Lab: number;
  /** X光費 */
  XRay: number;
  /** 理療費 */
  RehabilitationFee: number;
  /** 處置費 */
  TreatmentFee: number;
  /** 手術費 */
  SurgeryFee: number;
  /** 麻醉費 */
  AnesthesiaFee: number;
  /** 特材費 */
  SpecialMaterialFee: number;
  /** 輸血費 */
  BloodPlasmaFee: number;
  /** 透析費 */
  HemodialysisFee: number;
  /** 病房費 */
  CareFee: number;
  /** 管灌費 */
  TubeFeedingFee: number;
  /** 嬰兒費 */
  BabyFee: number;
  /** 代辦費 */
  ChargeFee: number;
 /** 精神治療費 */
  PsychiatryTreatmentFee: number;
  /** 藥費部份負擔 */
  DrugPart: number;
  /** 委外藥費部份負擔 */
  PDrugPart: number;
  /** 檢驗部分負擔 */
  ExamPart: number;
  /** 部份負擔 */
  OpdPart: number;
  OpdPartFromReg: number;
  /** 藥事服務費 */
  MedicalService: number;

  /** 總計 */
  Total: number;
  /** 小計 */
  SubSum: number;
  /** 自費 */
  OwnPay: number;
  UrgentHCWarning: string;
  IsUseCTRDrugs: boolean = false;
  IsRxDxChanged: boolean = true;
}
export class CashierPreview {
  [key: string]: Hrxfee;
}
@Injectable({
  providedIn: 'root'
})
export class PricingApi {
  api: WebApiService;
  /**editOptions 取得費用項目 */

  private editoptRatingPriceSubject: BehaviorSubject<PricingEditOpt> = new BehaviorSubject<PricingEditOpt>(null);
  get editoptRatingPrice$() {
    return this.editoptRatingPriceSubject.asObservable();
  }


  constructor(private apiClient: WebApiClient) {
    this.api = apiClient.createHisService('Hist/RatingPrice');
    this.api.enableLoader = false;
  }


  /**
   * 取得費用項目
   */
  async GetEditOptions(isRefresh: boolean = false): Promise<PricingEditOpt> {
    if (!this.editoptRatingPriceSubject.value || isRefresh) {
      const options = await this.api.get('GetEditOptions').toPromise();
      this.editoptRatingPriceSubject.next(options);
    }

    return this.editoptRatingPriceSubject.value;
  }
  /**
   * 取得費用項目
   */
  async GetPricing(date: Date): Promise<PricingInfo> {
    return this.api.get('GetPricing?histDate=' + date.toLocaleDateString()).toPromise<PricingInfo>();
  }

  async Preview(data: WholeHist, defaultCase: string = '', is01inCase: boolean = true, is09inCase: boolean = true): Promise<CashierPreview> {
    return this.api.post('Preview?defaultCase=' + defaultCase + '&is01inCase=' + is01inCase + '&is09inCase=' + is09inCase, data).toPromise();
  }
  async PreviewDentist(data: WholeHist, defaultCase: string = ''): Promise<CashierPreview> {
    return this.api.post('PreviewDentist?defaultCase=' + defaultCase , data).toPromise();
  }
  

  async GetHomeCareRxList(data: string[]): Promise<HCRxCodes[]> {
    return this.api.post('GetHomeCareRxList?', data).toPromise();
  }
  async GetCourseTreatmentData(patientId: number, icNo: string, date: Date, cusTp: string, histId: number, isSpecial: boolean = false): Promise<HistCusDto> {
    var strdate = DateHelper.formatDateTime(date);

    // var sDate = format(strdate, 'yyyy-MM-dd HH:mm:ss');
    return await this.api.get(`GetCourseTreatmentData?patientId=${patientId}&icNo=${icNo}&date=${strdate}&cusTp=${cusTp}&histId=${histId}&isSpecial=${isSpecial}`).toPromise<HistCusDto>();
  }
  async GetPriceingPHcsWord(dx:string): Promise<string> {
    var codeArr= await this.api.post('GetPriceingPHcsWord?dx=' + dx,
     {}).toPromise();
     return codeArr[0] ?? '' 
 }
  async GetAllSPCTreatData(): Promise<SPCTreatDto> {
    return await this.api.get(`GetAllSPCTreatData`).toPromise<SPCTreatDto>();
  }


}
