import {  AfterContentInit, AfterViewInit,  Component, ContentChild, ElementRef,  EventEmitter, Input, OnInit, Output,  SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import {  MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { VisDialogActionsComponent } from '../vis-dialog-actions/vis-dialog-actions.component';
import { FontGreatService } from 'src/app/services/font-great.service';

@Component({
  selector: 'app-vis-dialog',
  templateUrl: './vis-dialog.component.html',
  styleUrls: ['./vis-dialog.component.css']
})
export class VisDialogComponent implements OnInit, AfterContentInit, AfterViewInit {

  @ViewChild('temp', { static: false })
  template: TemplateRef<any>
  @ContentChild(VisDialogActionsComponent)
  actionBar: VisDialogActionsComponent;
  @ContentChild('content')
  content:ElementRef<any>;

  @Input()
  title: string;
  @Input()
  showCloseBtn: boolean = true;
  _width = 'none';
  @Input()
  fullSize = false;
  @Input()
  set width(v: number) {
    if (v > 0) {
      this._width = v + 'px';
    } else {
      this._width = 'initial';
    }
  };
  _height = 'initial';
  @Input()
  set height(v: number|string) {
    if (typeof v ==='number' && v > 0) {
      this._height = v + 'px';
    } else if (typeof v === 'string') {
      this._height = v;
    }
  };
  _minWidth = 'none';
  @Input()
  set minWidth(v: number) {
    if (v > 0) {
      this._minWidth = v + 'px';
    } else {
      this._minWidth = 'none';
    }
  };

  _minHeight = 'none';
  @Input()
  set minHeight(v: number) {
    if (v > 0) {
      this._minHeight = v + 'px';
    } else {
      this._minHeight = 'none';
    }
  };
  private _show = false;
  get show(){return this._show;}
  @Input()
  fitContent:boolean = true;
  @Input()
  set show(v: boolean) {
    if (v == true) {
      this._show = true;
      if (!this._dialogRef || this._dialogRef.getState() != MatDialogState.OPEN) {
        setTimeout(() => {
          this._dialogRef = this.openDialog();
          
        }, 0);
      }
    } else {
      this._show = false;
      if (this._dialogRef) {
        this._dialogRef.close();
      }
    }
  }
  /** 拖曳dialog */
  @Input() drag?:boolean = false;

  /** 寬% */
  @Input()
  set superWidth(v:string) {
    if (this._width = 'initial') {
      this._width = v;
    } else this._width;
  }

  /** 定位 */
  _position? = {};
  @Input()
  set position(v:{top:'', bottom:'', left:'', right:''}){
    this._position = v;
  }

  _maxWidth? = '80vw';
  /** max寬 */
  @Input()
  set maxWidth(v:string) {
    this._maxWidth = v;
  }

  @Output()
  open = new EventEmitter<any>(false);
  @Output()
  close = new EventEmitter<any>(false);

  _dialogRef: MatDialogRef<any, any>;
  constructor(private dialog: MatDialog, private fontGreatService:FontGreatService) {

  }


  ngOnInit(): void {

  }
  ngAfterContentInit() {
    //this.dialog.open(this.template)

  }
  openDialog() {
    var opts: MatDialogConfig<any> = {
      'autoFocus': false,
      'disableClose': true,
      panelClass:'no-scrollbar-outside'
    };
    opts.width = this._width;
    opts.height = this._height;
    opts.minHeight = this._minHeight;
    opts.minWidth = this._minWidth;
    opts.maxWidth = this._maxWidth;
    opts.position = this._position;
    opts.restoreFocus = false;
    if(this.fullSize){
      opts.panelClass = 'full-size';
    }

    this._dialogRef = this.dialog.open(this.template, opts);
    this._dialogRef.afterClosed().subscribe(() => this.close.emit());
    this._dialogRef.afterOpened().subscribe(() => this.open.emit());
    return this._dialogRef;
  }

  ngAfterViewInit(): void {
  }
  fontGreater:boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    this.fontGreater = this.fontGreatService.getFontGreat();
  }

  _onClose() {
    this.close.emit();
  }

}
