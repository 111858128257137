import { RxClass } from "src/app/hist/models/rx-class";

export class HST001Params {
  VitalSignDisplay: string;
  DefaultDose: number;
  DefaultDays: number;
  DefaultFrequency: string;
  DefaultWay: string;
  //DefaultSelfDispensing: string;
  DosageRule: string;

  // ChiDefaultFrequency: number;
  // ChiDefaultDays: number;
  // ChiDefaultSelfDispensing: string;

  AutoFill: number;
  EnterToNext: number;
  OrderActiveClassSet: string[];
  // OrderClassChi: string[];
  // OrderClassDrug: number[];
  // OrderClassInj: number[];
  // OrderClassLab: number[];
  // OrderClassXray: number[];

  // OrderClassCnSp1: number[];
  // OrderClassCnSp2: number[];
  // OrderClassCnSp3: number[];
  // OrderClassCnMed: number[];
  // OrderClassAcu: number[];
  // OrderClassTuina: number[];
  // OrderClassCnTrt: number[];

  IsPrintDrugBag: boolean;
  IsPrintMedRecord: boolean;
  IsPrintPrescription: boolean;
  IsPrintDetail: boolean;
  IsPrintWay: boolean;
  IsPrintExam: boolean;
  IsPrintDispPrescription: boolean;
  IsPrintDispExam: boolean;
  IsPrintCtrDrugs: boolean;

  IsEnableDiabetes: boolean;
  // 是否為中醫模式，未來可能依據 診所 + 診間 才能決定
  //IsChineseMode: boolean;
  //IsAutoFillRemarkForChineseSoupSet: boolean;

  IsEnableFrontValidation: boolean;
  // temp
  IsEnableDM: boolean;

  IsWriteToEMR: boolean;
  //IsWriteToIHold: boolean;
  IsWriteToCDR: boolean;

  //門診開藥的庫存提醒
  IsAlertBelowSafeStock: boolean;
  //OrderClass:string[];

  OrderPanalTabs: string;
  OrderPanalTabsTypes: string;

  /** 衛星藥局IP */
  SatellitePhamacyIP: string;
  /** 衛星藥局發送醫令屬性 */
  SatellitePhamacyCodes: string;
  /** 衛星藥局使用醫師身分證 */
  SatellitePhamacyDoctorCId: string;
  /** 電子病歷放置路徑 */
  EMRXmlPath: string;

  /** 是否開啟PACS功能 */
  EnablePACS: boolean;

  /** 身心科院所是否顯示平均藥費提示 */
  DisplayAverageDrugsFee: boolean;

  /** 病歷紀錄顯示順序 */
  HistOrder: number[];

  /** 預設是否寫IC */
  IsWriteICDefault:boolean

  /** 浮動醫令視窗是否顯示停用藥品 */
  DisplayStopDrugsFromFloat: boolean

  /** 配盤醫令是否顯示停用藥品 */
  DisplayStopDrugsFromPad: boolean

  TOCCAddr:string;

  /** TOCC是否啟用 */
  TOCCEnable: boolean;

  /** TOCC結束日期 */
  TOCCEndDate?: Date;

  /** TOCC啟用診所的區域 */
  TOCCClinicArea: string;

  /** TOCC正式網域Internet */
  TOCCInternetSite: string;

  /** TOCC正式網域VPN */
  TOCCVPNSite: string;

  /** TOCC測試網域 */
  TOCCTestSite: string;

  /** TOCC呼叫API模式 */
  TOCCApiMode: number;

  /** 是否啟用巡診 */
  EnableOutVisit: boolean;

  /** 巡診專用衛星藥局IP */
  OutVisitSatellitePhamacyIP: string;

  /** 巡診自動帶入主治 */
  OutVisitSPTX: string;
}
