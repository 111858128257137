import { Injectable, OnDestroy } from '@angular/core';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { Dx, DxWithICD9 } from 'src/app/services/api-service/dx/dx';
import { Router } from '@angular/router';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { PaymentMaster } from 'src/app/payments/models/payment-master';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { MultiPrescriptWrite } from 'src/app/shared/hcr-lib/MultiPrescriptWrite';
import { TreatmentData } from 'src/app/shared/hcr-lib/TreatmentData';
import { Rx } from 'src/app/services/api-service/rx/rx';
import { PageData2 } from 'src/app/shared/models/page-data';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { DxOpt, HistEditOption, RsOpt, RxOpt, userStat } from 'src/app/services/api-service/hist/hist-edit-option';
import { HistActionEnum } from 'src/app/enums/HistActionEnum';
import { VitalSign } from './vital-sign';
import { NhiRecord } from '../NHI/nhiRecord';
import { homecareRx } from './homecareRx';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { RecardListDto } from './recard-list';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { NHIRegIC } from '../register/nhiRegIc';
import { DrugBagInfo, HistPrintDrugDto, ReceiptInfoDto, RepeatedPrescriptionDto, RepeatedPrescriptionQRcode, RepeatedPrescriptionRequest, WholeLargeHist } from './whole-large-hist';
import { PrescriptionSignMultiWrite } from 'src/app/shared/hcr-lib/PrescriptionSignMultiWrite';
import { PrescriptionFeedBack, PrescriptionSave } from 'src/app/hist/models/prescription-save';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { ArrearReceiptViewModel } from 'src/app/system/report/components/arrear-receipt/arrear-receipt.component';
import { decreeRx } from './decreeRx';
import { VisitcountQueryDto, VisitcountReportData } from 'src/app/hist/models/visitcount-report';
import { LabHRxListPageCollection, LabPrescriptionAndHrxListQuery, LabPrescriptionPageCollection } from './hist-api.model';

export class HistToICCardData {
  APartCode: string;
  MultiPrescriptWrite: MultiPrescriptWrite;
  PrescriptionSignMultiWrite: PrescriptionSignMultiWrite;
  TreatmentData: TreatmentData;
  RegicId: number;
  RegId: number;
  HistoryId?: number;
}

export interface ddiData {
  RtnCode: string;
  rtnCode: string;
  sub: ddiSubOne[];
}

export class ddiSubOne {
  oType: string;
  RtnNum: string;
  sub: ddiSubTwo[];

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      oType: '',
      RtnNum: '',
      sub: []
    } as ddiSubOne;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        if (key.toLocaleLowerCase() === 'sub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new ddiSubTwo(data[key][j]));
          }
        } else {
          this[toKeys[idx]] = data[key];
        }
      }
    }
  }
}

export class ddiSubTwo {
  oOrder: string;
  oMsg: string;
  CDrugName: string;
  ATC7Name: string;
  Effect: string;
  Mechanism: string;
  Management: string;
  sub: ddiSubThree[];

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      oOrder: '',
      oMsg: '',
      CDrugName: '',
      ATC7Name: '',
      Effect: '',
      Mechanism: '',
      Management: '',
      sub: []
    } as ddiSubTwo;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        if (key.toLocaleLowerCase() === 'sub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new ddiSubThree(data[key][j]));
          }
        } else {
          this[toKeys[idx]] = data[key];
        }
      }
    }
  }
}

export class ddiSubThree {
  HospName: string;
  FuncDT: string;
  DDIATC7Name: string;
  DDICDrugName: string;
  DDIOrder: string;
  HospSub: hospSubFour[];
  DDISub: ddiSubFour[];

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      HospName: '',
      FuncDT: '',
      DDIATC7Name: '',
      DDICDrugName: '',
      DDIOrder: '',
      HospSub: [],
      DDISub: []
    } as ddiSubThree;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        if (key.toLocaleLowerCase() === 'hospsub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new hospSubFour(data[key][j]));
          }
        } else if (key.toLocaleLowerCase() === 'ddisub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new ddiSubFour(data[key][j]));
          }
        } else {
          this[toKeys[idx]] = data[key];
        }
      }
    }
  }
}

export class hospSubFour {
  HospName: string;
  FuncDT: string;

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      HospName: '',
      FuncDT: ''
    } as hospSubFour;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        this[toKeys[idx]] = data[key];
      }
    }
  }
}

export class ddiSubFour {
  IngName: string;
  DDIIngName: string;
  Effect: string;
  Mechanism: string;
  Management: string;
  Alternatives: string;

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      IngName: '',
      DDIIngName: '',
      Effect: '',
      Mechanism: '',
      Management: '',
      Alternatives: ''
    } as ddiSubFour;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        this[toKeys[idx]] = data[key];
      }
    }
  }
}

export interface medPrtData {
  RtnCode: string;
  rtnCode: string;
  sub: medPrtSubOne[];
}

export class medPrtSubOne {
  oType: string;
  RtnNum: string;
  sub: medPrtSubTwo[];

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      oType: '',
      RtnNum: '',
      sub: []
    } as medPrtSubOne;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        if (key.toLocaleLowerCase() === 'sub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new medPrtSubTwo(data[key][j]));
          }
        } else {
          this[toKeys[idx]] = data[key];
        }
      }
    }
  }
}

export class medPrtSubTwo {
  oOrder: string;
  HRiskOrder: string;
  oMsg: string;
  ATC5EName: string;
  HRiskATCEName: string;
  DrugGroupCName: string;
  PresMedDay: string;
  EDate: string; // 民國年月日YYYMMDD
  OwnQty1: string;
  OwnQty2: string;
  ReasonableInterval: string;
  Interval: string;
  sub: medPrtSubThree[];

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      oOrder: '',
      HRiskOrder: '',
      oMsg: '',
      ATC5EName: '',
      HRiskATCEName: '',
      DrugGroupCName: '',
      PresMedDay: '',
      EDate: '',
      OwnQty1: '',
      OwnQty2: '',
      ReasonableInterval: '',
      Interval: '',
      sub: []
    } as medPrtSubTwo;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        if (key.toLocaleLowerCase() === 'sub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new medPrtSubThree(data[key][j]));
          }
        } else {
          this[toKeys[idx]] = data[key];
        }
      }
    }
  }
}

export class medPrtSubThree {
  NSAIDsType: string;
  HospName: string;
  FuncDT: string;
  Day: string;
  DrugGroupCName: string;
  OrderQty: string;
  StdQty: string;
  Std: string;
  OrderCName: string;
  RealInspectDate: string;
  CurePath: string;

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      NSAIDsType: '',
      HospName: '',
      FuncDT: '',
      Day: '',
      DrugGroupCName: '',
      OrderQty: '',
      StdQty: '',
      Std: '',
      OrderCName: '',
      RealInspectDate: '',
      CurePath: ''
    } as medPrtSubThree;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        this[toKeys[idx]] = data[key];
      }
    }
  }
}

export class allergyData {
  RtnCode: string;
  rtnCode: string;
  sub: allergySubOne[];
}

export class allergySubOne {
  oType: string;
  RtnNum: string;
  sub: allergySubTwo[];

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      oType: '',
      RtnNum: '',
      sub: []
    } as allergySubOne;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        if (key.toLocaleLowerCase() === 'sub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new allergySubTwo(data[key][j]));
          }
        } else {
          this[toKeys[idx]] = data[key];
        }
      }
    }
  }
}

export class allergySubTwo {
  allergyOrder_text: string;
  upload_Flag: string;
  hospName: string;
  upload_date: string;

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      allergyOrder_text: '',
      upload_Flag: '',
      hospName: '',
      upload_date: ''
    } as allergySubTwo;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        this[toKeys[idx]] = data[key];
      }
    }
  }
}

export class specialData {
  RtnCode: string;
  rtnCode: string;
  sub: specialSubOne[];
}

export class specialSubOne {
  oType: string;
  RtnNum: string;
  sub: specialSubTwo[];

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      oType: '',
      RtnNum: '',
      sub: []
    } as specialSubOne;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        if (key.toLocaleLowerCase() === 'sub') {
          this[toKeys[idx]] = [];
          for (let j = 0; j < data[key].length; j++) {
            this[toKeys[idx]].push(new specialSubTwo(data[key][j]));
          }
        } else {
          this[toKeys[idx]] = data[key];
        }
      }
    }
  }
}

export class specialSubTwo {
  DrugGroupCName: string;
  DrugGroupCode: string;
  Dose: string;
  SugDose: string;

  constructor(data: any = null) {
    if (!data) return;
    var fromKeys = Object.keys(data);
    var toKeys = Object.keys(this.emptyObject());
    this.cloneData(fromKeys, toKeys, data);
  }

  emptyObject() {
    return {
      DrugGroupCName: '',
      DrugGroupCode: '',
      Dose: '',
      SugDose: ''
    } as specialSubTwo;
  }

  cloneData(fromKeys: string[], toKeys: string[], data: any) {
    for (let i = 0; i < fromKeys.length; i++) {
      const key = fromKeys[i];
      const idx = toKeys.findIndex(x => x.toLowerCase() === key.toLowerCase());
      if (idx >= 0) {
        this[toKeys[idx]] = data[key];
      }
    }
  }
}

export class nhiReq {
  sHospId: string;
  sHcaId: string;
  sPatId: string;
  sub: nhiReqSubOne[];
}

export class nhiReqV2 extends nhiReq {
  sPatCardType: string;
  sHcaCardId: string;
  sPatCardId: string;
  sClientRandom: string;
  sSignature: string;
  vhcCloudToken: string;
  sSamId: string;
}

export type clinicRx = {
  Code: string;
  Name: string;
  EName: string;
}

export class nhiReqSubOne {
  sType: string;
  sub: nhiReqSubTwo[];
}

export type StatOpts = { statDx: userStat, statRx: userStat, statCC: userStat, statPE: userStat }
export class nhiReqSubTwo {
  sOrder: string;
}
export type HrxInitQuery =
  {
    codes: string[];
    types: number[];
    dateQuery: Date;
    paySysA?: string;
    checkStock: boolean;
  }
export type HrxInitResult =
  {
    Rx: Rx;
    AlternativeRx: Rx;
    CommonSetting: homecareRx;
    SafeStock: number;
    TotalInventory: number;
    Decree: decreeRx
  }
export enum CheckResult {
  Success = 0,
  warning = 1,
  Error = 2
}
export enum CheckResultAction {
  None = 0,
  Alert = 1,
  InsertRx = 2,
  InsertDx = 3,
}
export class HistCheckResult {
  Result: CheckResult;
  Action: CheckResultAction;
  Data: string;
}
export type GetWholeHistQuery =
  {
    regId: number;
  }

export class MedicationWayReportViewModel {
  /**
   *掛號證=病歷號
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  PatientNo: string;

  /**
   *病患姓名
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  PatientName: string;

  /**
 *病患身分證
 *
 * @type {string}
 * @memberof MedicationWayReportViewModel
 */
  PatientCId: string;

  /**
   *掛號身分
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  RegisterICode: string;

  /**
   *就診日=看診日期
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  RegisterDate: string;

  /**
   *看診號
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  RegisterWaitNo: string;

  /**
   *掛號卡序
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  RegisterIcno: string;

  /**
   *院所名稱
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  ClinicName: string;

  /**
   *醫師姓名
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  DoctorName: string;

  /**
   *藥師姓名
   *
   * @type {string}
   * @memberof MedicationWayReportViewModel
   */
  PharmacistName: string;

  /**
   *醫令列表
   *
   * @type {MedicationWayHRxesViewModel[]}
   * @memberof MedicationWayReportViewModel
   */
  HRxes: MedicationWayHRxesViewModel[];
}

export class MedicationWayHRxesViewModel {
  /**
   *院內藥材代碼
   *
   * @type {string}
   * @memberof MedicationWayHRxesViewModel
   */
  RxCode: string;
  /**
   *院內藥材名稱
   *
   * @type {string}
   * @memberof MedicationWayHRxesViewModel
   */
  RxName: string;
  /**
   *醫令頻率
   *
   * @type {string}
   * @memberof MedicationWayHRxesViewModel
   */
  Freq: string;
  /**
   *醫令途徑(服法)
   *
   * @type {string}
   * @memberof MedicationWayHRxesViewModel
   */
  Way: string;
  /**
   *次量
   *
   * @type {string}
   * @memberof MedicationWayHRxesViewModel
   */
  Dose: string;
  /**
   *日
   *
   * @type {string}
   * @memberof MedicationWayHRxesViewModel
   */
  Days: string;
}




@Injectable({
  providedIn: 'root'
})

export class HistApi {
  constructor(private webApiFactory: WebApiClient,
    private router: Router, private routeInfo: RouteInfoService) {
    this.api = this.webApiFactory.createHisService('Hist/hist');
    this.apiView = this.webApiFactory.createHisService('hist/histview');
    this.api.enableLoader = false;
  }

  api: WebApiService;
  apiView: WebApiService;
  EditOpt: HistEditOption;
  //#region Promise寫法
  getLabOutClinicReport(registerId: number, clinic: Clinic): Promise<RepeatedPrescriptionDto> {
    return this.api.post('GetLabOutClinicReport?registerId=' + registerId + '&isLabOutClinic=' + true, clinic).toPromise<RepeatedPrescriptionDto>();
  }
  getReceiptInfo(registerId: number): Promise<ReceiptInfoDto> {
    return this.api.get('GetReceiptInfo?registerId=' + registerId).toPromise<ReceiptInfoDto>();
  }
  getDrugBag(registerId: number, rxIds: number[]): Promise<DrugBagInfo> {
    return this.api.get('GetDrugBag', { 'registerId': registerId, 'rxIds': rxIds.join(',') }).toPromise<DrugBagInfo>();
    // return this.api.get('GetDrugBag?registerId=' + registerId).toPromise<DrugBagInfo>();
  }
  getCMSDrugBag(registerId: number, rxIds: number[]): Promise<DrugBagInfo> {
    return this.api.get('getCMSDrugBag', { 'registerId': registerId, 'rxIds': rxIds.join(',') }).toPromise<DrugBagInfo>();
    // return this.api.get('getCMSDrugBag?registerId=' + registerId).toPromise<DrugBagInfo>();
  }
  getRepeatedPrescription(registerId: number, sign: string, clinic: Clinic, userCid: string, userName: string, isUseQRcode: boolean = false): Promise<RepeatedPrescriptionDto> {
    return this.api.post('GetRepeatedPrescription?registerId=' + registerId + '&sign=' + sign + '&userCid=' + userCid + '&userName=' + userName + '&isUseQRcode=' + isUseQRcode, clinic).toPromise<RepeatedPrescriptionDto>();
  }

  getBy(histId: number[]): Promise<WholeHist[]> {
    return this.api.get('GetBy?histIds=' + histId.join(',')).toPromise<WholeHist[]>();
  }
  getWholeHist(param: GetWholeHistQuery): Promise<WholeHist> {
    return this.api.get('get', param).toPromise<WholeHist>();
  }

  /**取得門診紀錄，補印時，取決是否勾選相關列印的資訊 例如：管制藥、是否有檢驗項目等資訊 */
  getPrintDrug(regId: number): Promise<HistPrintDrugDto> {
    return this.api.get('GetPrintDrug/' + regId).toPromise<HistPrintDrugDto>();
  }

  async getEditOptions(): Promise<HistEditOption> {
    if (!this.EditOpt) {
      this.EditOpt = await this.api.get('GetEditOptions').toPromise<HistEditOption>();
    }
    return this.EditOpt;
  }
  async GetExecutorList(): Promise<ValueTextPair[]> {
    if (this.EditOpt && this.EditOpt?.executor && this.EditOpt?.executor?.length > 0) {
      return this.EditOpt.executor;
    } else {
      return await this.api.get('GetExecutorList').toPromise<ValueTextPair[]>();
    }
  }
  async GetRxOptions(): Promise<RxOpt[]> {
    return await this.api.get('GetRxOptions').toPromise<RxOpt[]>();
  }
  async GetRsOptions(): Promise<RsOpt[]> {
    return await this.api.get('GetRsOptions').toPromise<RsOpt[]>();
  }
  async GetDxOptions(diagVersion: string = ''): Promise<DxOpt[]> {
    return await this.api.get('GetDxOptions?diagVersion=' + diagVersion).toPromise<DxOpt[]>();
  }
  async GetSPCOptions(): Promise<ValueTextPair[]> {
    return await this.api.get('GetSPCOptions').toPromise<ValueTextPair[]>();
  }
  async GetStatOptions(): Promise<StatOpts> {
    return await this.api.get('GetStatOptions').toPromise<StatOpts>();
  }
  async ParseStatFrom(date: Date): Promise<void> {
    return await this.api.get('ParseStatFrom?from=' + new Date(date).toLocaleDateString()).toPromise<void>();
  }
  async GetStatByDx(dx: string, tp: 'rx' | 'cc' | 'pe'): Promise<{ Self: string[], Clinic: string[] }> {
    return await this.api.get('GetStatByDx?dx=' + dx + '&tp=' + tp).toPromise<{ Self: string[], Clinic: string[] }>();
  }
  async SetSelfPay(regId: number) {
    return await this.api.post('SetSelfPay?regId=' + regId, null).toPromise();
  }
  async DeleteHist(regId: number): Promise<any> {
    var p = await this.api.delete('DeleteHist?regId=' + regId, null).toPromise<any>();
    return p;
  }
  async RecoveryHist(regId: number): Promise<any> {
    return await this.api.put('RecoveryHist?regId=' + regId, null).toPromise<any>();
  }
  async GetProphylacticHealth(pId: number, date: Date | string): Promise<ValueTextPair[]> {
    var d = new Date(date).toLocaleDateString();
    return await this.api.get('GetProphylacticHealth?pId=' + pId + '&date=' + d, null).toPromise<ValueTextPair[]>();
  }

  async GetHomecareRxSetting(rxCode: string, paySysA: string): Promise<homecareRx[]> {
    var p = 'GetHomecareRxSetting?rxCode=' + rxCode;
    if (paySysA) p += '&paySysA=' + paySysA;
    return await this.api.get(p).toPromise<homecareRx[]>();
  }

  async GetHistArrearReceipt(regId: number): Promise<ArrearReceiptViewModel> {
    return await this.api.get(`GetHistArrearReceipt?regId=${regId}`).toPromise<ArrearReceiptViewModel>();
  }

  async GetCommonRxSetting(rxCode: string, paySysA: string, dateQuery: Date | string): Promise<homecareRx[]> {
    var p = 'GetCommonRxSetting?rxCode=' + rxCode + '&dateQuery=' + dateQuery;
    if (paySysA) p += '&paySysA=' + paySysA;
    return await this.api.get(p).toPromise<homecareRx[]>();
  }

  async GetHrxInit(query: HrxInitQuery): Promise<HrxInitResult[]> {
    return await this.api.post('GetHrxInit', query).toPromise<HrxInitResult[]>();
  }
  async GetNextRegId(regId: number) {
    return await this.api.get('GetNextRegId?rId=' + regId).toPromise<number>();
  }
  //#endregion
  // #region data service --------------------

  private queryAddCount(query: string, count?: number): string {
    if (count) {
      return query + '&count=' + count;
    } else {
      return query;
    }
  }

  getChiAskOptions(codeName?: string, count?: number) {
    let query = 'getChiAskOptions?name=' + codeName;
    query = this.queryAddCount(query, count);
    return this.api.get(query).toPromise<SystemCode[]>();
  }
  getChiLookOptions(codeName?: string, count?: number) {
    let query = 'getChiLookOptions?name=' + codeName;
    query = this.queryAddCount(query, count);
    return this.api.get(query).toPromise<SystemCode[]>();
  }
  getChiPulseOptions(codeName?: string, count?: number) {
    let query = 'getChiPulseOptions?name=' + codeName;
    query = this.queryAddCount(query, count);
    return this.api.get(query).toPromise<SystemCode[]>();
  }
  getChiBianZhengOptions(codeName?: string, count?: number) {
    let query = 'getChiBianZhengOptions?name=' + codeName;
    query = this.queryAddCount(query, count);
    return this.api.get(query).toPromise<SystemCode[]>();
  }
  getChiTreatRuleOptions(codeName?: string, count?: number) {
    let query = 'getChiTreatRuleOptions?name=' + codeName;
    query = this.queryAddCount(query, count);
    return this.api.get(query).toPromise<SystemCode[]>();
  }

  private dxCache = new Map();
  async getDiagByCode(code: string, diagVersion: string = ''): Promise<Dx> {
    //cache key原本是code，但因為有可能同一個code有不同版本的診斷，所以加上版本號
    // var key = code;
    var key = code + ';' + diagVersion;
    var cache = this.getDiagFromCache(key);
    if (cache) {
      return cache;
    }
    var dx = await this.api.get('GetDiagByCode?code=' + code + '&diagVersion=' + diagVersion).toPromise<Dx>();
    this.dxCache.set(key, dx);
    return dx;
  }
  async getDiagWithICD9ByCode(code: string, diagVersion: string = ''): Promise<DxWithICD9> {
    // var key = code;
    var key = code + ';' + diagVersion;
    var dx = await this.api.get('getDiagWithICD9ByCode?code=' + code + '&diagVersion=' + diagVersion).toPromise<DxWithICD9>();
    this.dxCache.set(key, dx);
    return dx;
  }
  getDiagByApplyCode(code: string): Promise<Dx> {
    //傳入的code是預防保健，或N186，Z23，新舊版本(2014/2023)都有，所以不用判斷版本
    return this.api.get('GetDiagByApplyCode?code=' + code).toPromise<Dx>();
  }
  private getDiagFromCache(code: string) {
    if (this.dxCache.has(code)) {
      return this.dxCache.get(code);
    }
    return null;
  }
  private convertToString(intArray: number[]) {
    let str = '';
    if (!intArray) { return 'UnknownType'; }
    intArray.forEach(x => {
      str += x + ',';
    });
    str = str.substring(0, str.length - 1); // 移除最後逗號
    return str;
  }

  async getOrderByCode(code: string, typeArray: number[]): Promise<Rx> {
    const types = this.convertToString(typeArray);

    return await this.api.get('GetOrderByCode?code=' + code + '&types=' + types).toPromise();
  }
  GetOrderByStdCodeAsync(code: string): Promise<Rx> {
    return this.api.get('GetOrderByStdCodeAsync?code=' + code).toPromise();
  }
  getOrdersOfOrderSet(drugId: number): Promise<Rx[]> {
    return this.api.get('GetOrdersOfOrderSet?drugId=' + drugId).toPromise();
  }

  isDrugsWithSafeStock(drugList: RxOpt[]): Promise<RxOpt[]> {
    return this.api.post('IsDrugsWithSafeStock', drugList).toPromise();
  }
  // #endregion data service

  // #region reg service --------------------
  async updateRegStatusByAction(regId: number, action: HistActionEnum, origRegStatusCode: string) {
    await this.api.get('UpdateRegStatusByAction?id=' + regId + '&action=' + action + '&origRegStatusCode=' + origRegStatusCode)
      .toPromise();
  }
  // #endregion reg service

  // #region NHI service
  async GetDDIData(data: nhiReq): Promise<ddiData> {
    return await this.api.post('GetDDIData', data).toPromise<ddiData>();
  }

  async GetAllergyData(data: nhiReq): Promise<ddiData> {
    return await this.api.post('GetAllergyData', data).toPromise<ddiData>();
  }

  async GetMedPrtData(data: nhiReq): Promise<medPrtData> {
    return await this.api.post('GetMedPrtData', data).toPromise<medPrtData>();
  }
  // #region NHI service

  // #region save --------------------
  async save(submitData: WholeHist): Promise<HistToICCardData> {
    var loaderEnabled = this.api.enableLoader;
    this.api.enableLoader = false;
    var ret = await this.api.put('save', submitData).toPromise<HistToICCardData>();
    this.api.enableLoader = loaderEnabled;
    return ret;
  }
  async HistCheck(submitData: WholeHist): Promise<HistCheckResult[]> {
    var loaderEnabled = this.api.enableLoader;
    this.api.enableLoader = false;
    var ret = await this.api.post('HistCheck', submitData).toPromise<HistCheckResult[]>();
    this.api.enableLoader = loaderEnabled;
    return ret;
  }
  /** 更新主訴 */
  async saveCC(histId: number, cc: string, submitData: WholeHist): Promise<any> {
    var loaderEnabled = this.api.enableLoader;
    this.api.enableLoader = false;
    var ret = await this.api.put('SaveCC?histId=' + histId + '&cc=' + cc, submitData).toPromise<HistToICCardData>();
    this.api.enableLoader = loaderEnabled;
    return ret;
  }

  async SaveReferralIC(ic: NHIRegIC) {
    var loaderEnabled = this.api.enableLoader;
    this.api.enableLoader = false;
    var ret = await this.api.put('SaveReferralIC', ic).toPromise<CustomResult>();
    return ret;
  }

  // #endregion save

  // #region payment --------------------
  calculatePayment(submitData: WholeHist): Promise<PaymentMaster> {
    return this.api.put('calculatePayment', submitData).toPromise<PaymentMaster>();
  }
  // #endregion payment

  // #region route service --------------------

  // #endregion route service

  async getVitalSign(pId: number, pSize: number, pSkip: number): Promise<PageData2<VitalSign>> {
    return await this.api.get('GetVitalSign', {
      pId: pId,
      pageSize: pSize,
      pageSkip: pSkip
    }).toPromise<PageData2<VitalSign>>()
  }
  async GetSPCTreat(data: any): Promise<string> {
    return await this.api.post('GetSPCTreat', data).toPromise<string>();
  }

  // async UpdatelackCardRegic(data: LackNegic): Promise<void> {
  //   return await this.api.post('UpdatelackCardRegic', data).toPromise<void>();
  // }
  async SaveNHIRecord(data: NhiRecord): Promise<void> {
    // debugger
    var id = await this.api.post('SaveNHIRecord', data).toPromise<number>();
    data.Id = id;
  }
  async GetNHIRecord(patientId: number): Promise<NhiRecord> {
    return await this.api.get('GetNHIRecord?patId=' + patientId).toPromise<NhiRecord>();
  }
  async GetReCardList(PatientCId: string): Promise<RecardListDto[]> {
    return await this.api.get('GetReCardList?PatientCId=' + PatientCId).toPromise<RecardListDto[]>();
  }
  async CheckHomeCareUrgent(data: HistOrder[], urgentHC: string): Promise<string> {
    return await this.api.post('CheckHomeCareUrgent?urgentHC=' + urgentHC, data).toPromise<string>();
  }

  async saveMulti(wholeHistData: WholeLargeHist): Promise<HistToICCardData> {
    var loaderEnabled = this.api.enableLoader;
    this.api.enableLoader = false;

    var ret = await this.api.put('saveMulti', wholeHistData).toPromise<HistToICCardData>();
    this.api.enableLoader = loaderEnabled;
    return ret
  }
  async saveIgsn(data: PrescriptionSave[]) {
    var ret = await this.api.put('SaveIgsn', data).toPromise();
    // this.api.enableLoader = loaderEnabled;
    return ret
  }

  async checkIgsn(data: PrescriptionSave[]) {
    var ret = await this.api.post('CheckIgsn', data).toPromise<PrescriptionFeedBack>();
    // this.api.enableLoader = loaderEnabled;
    return ret
  }
  async savedxFromSdx(data: string[]) {

    var ret = await this.api.put('SavedxFromSdx', data).toPromise();
    return ret
  }

  async CallTeleMed(regId: number): Promise<RoomConnectionDTO> {
    var ret = await this.api.get('CallTeleMed?regId=' + regId).toPromise<RoomConnectionDTO>();
    return ret;
  }
  async ReviewTeleMed(regId: number): Promise<RoomConnectionDTO> {
    var ret = await this.api.get('ReviewTeleMed?regId=' + regId).toPromise<RoomConnectionDTO>();
    return ret;
  }

  async GetRepeatedPrescriptSignAndData(sign: string, data: string): Promise<RepeatedPrescriptionQRcode> {
    let request = new RepeatedPrescriptionRequest();
    request.Sign = sign || '';
    request.Data = data || '';
    var ret = await this.api.post('GetRepeatedPrescriptSignAndData', request).toPromise<RepeatedPrescriptionQRcode>();
    return ret;
  }

  async CheckLastPrescribeDate(data: CheckPrescribeDto): Promise<string> {
    return await this.api.post('CheckLastPrescribeDate', data).toPromise<string>();
  }

  async CheckMonthMaxPrescribeQty(data: CheckPrescribeDto): Promise<number> {
    return await this.api.post('CheckMonthMaxPrescribeQty', data).toPromise<number>();
  }

  async GenerateMedicationWayReport(regId: number): Promise<MedicationWayReportViewModel> {
    return await this.api.get(`GenerateMedicationWayReport?regId=${regId}`).toPromise<MedicationWayReportViewModel>();
  }
  async GetICWrite(patientCId: string, regDate: Date): Promise<HistToICCardData[]> {
    return await this.api.get('GetICWrite', { patientCId, regDate: regDate.toLocaleDateString() }).toPromise<HistToICCardData[]>();
  }
  async UpdateICWriteTime(regId: number): Promise<boolean> {
    return await this.api.post('UpdateICWriteTime?regId='+regId, { }).toPromise<boolean>();
  }
  async GetVisitcountReport(data: VisitcountQueryDto): Promise<VisitcountReportData[]> {
    return await this.api.post('GetVisitcountReport', data).toPromise<VisitcountReportData[]>();
  }

  async GenerateLabHrxListReport(data: LabPrescriptionAndHrxListQuery): Promise<LabHRxListPageCollection> {
    return await this.api.post('GenerateLabHrxListReport', data).toPromise<LabHRxListPageCollection>();
  }

  async GenerateLabPrescriptionReport(data: LabPrescriptionAndHrxListQuery): Promise<LabPrescriptionPageCollection> {
    return await this.api.post('GenerateLabPrescriptionReport', data).toPromise<LabPrescriptionPageCollection>();
  }

  async GetICDEncode(data: TreatmentData): Promise<TreatmentData> {
    return await this.api.post('GetICDEncode', data).toPromise<TreatmentData>();
  }

  async GetICDDecode(data: TreatmentData): Promise<TreatmentData> {
    return await this.api.post('GetICDDecode', data).toPromise<TreatmentData>();
  }
}

export class RoomConnectionDTO {
  IsClosed: boolean;
  DoctorUrl: string;
  PatientUrl: string;

}

export class CheckPrescribeDto {
  Id: number;
  ClinicId: number;
  PatientId: number;
  RxCode: string;
  RegDate: Date;
  IntervalDays: number;
}
